import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import { useTranslation } from "react-i18next";
import {
  localizeImg,
  localizeValue,
} from "../../../../../../constants/constants";
import AvatarBasic from "../../../../../../components/common/avatar/AvatarBasic";

const Overview = ({ news, authorInfo }) => {
  const [visibleModalPreview, setVisibleModalPreview] = useState(false);

  const { t, i18n } = useTranslation();

  const [newsModel, setNewsModel] = useState({});

  useEffect(() => {
    setNewsModel(news);
  }, [news]);

  return (
    <>
      <div className={styles.overview}>
        <div className={cn("h4", styles.title)}>{newsModel?.title}</div>
        {/* <div className={styles.info}>{newsModel?.subTitle}</div> */}
        <div className={styles.line} style={{display:'flex',justifyContent:'space-between'}}>
          <div className={styles.author}>
            <div className={styles.avatar}>
              <AvatarBasic
                picture={localizeImg(authorInfo?.companyDesc?.avatar)}
                size={41}
              />
            </div>
            {t("BY")}{" "}
            <span>
              {localizeValue(
                i18n.language,
                authorInfo?.companyDesc?.companyName
              )}
            </span>{" "}
            {t("BY_WHO")}
          </div>
          <div className={styles.rating}>
            {/* <Icon name="star-fill" size="24" />
            4.8 */}
            <span className={styles.counter}>{newsModel?.eventDate}</span>
          </div>
        </div>
        <div className={styles.gallery}>
          {newsModel?.images?.map(
            (x, index) =>
              index < 1 && (
                <div className={styles.preview} key={index}>
                  <img src={localizeImg(x)} alt="Product" />
                </div>
              )
          )}
          {/* <button
            className={cn("button-white", styles.button)}
            onClick={() => setVisibleModalPreview(true)}
          >
            Show all preview
          </button> */}
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red", styles.subtitle)}>
              {t("OVERVIEW")}
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: newsModel?.content }}
            ></div>
          </div>
        </div>
      </div>
      {/* <ModalPreview
        visible={visibleModalPreview}
        onClose={() => setVisibleModalPreview(false)}
        gallery={gallery}
        title="Fleet - Travel shopping UI design kit"
        figcaption="Elegant product mockup for your next project"
        download
      /> */}
    </>
  );
};

export default Overview;
