import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Skeleton, Spin, Space } from "antd";
import { DownOutlined, UpOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { getTimeBefore } from "../../../../utils/date";
import VideoBasic from "../../../common/video/VideoBasic";
import closeIcon from "../../../../resource/icons/feed/close.svg";
import FeedDetail from "../../detail/FeedDetail";
import { deepClone, goToProfilePage } from "../../../../utils/utils";
import { likeCreate, reportFeed } from "../../../../api/feed";
import { useTranslation } from "react-i18next";
import { translate } from "../../../../api/translate";
import FeedForwardChild from "../../forward/FeedForwardChild";
import { contactFollowStatus, follow } from "../../../../api/contact";
import { statusCode } from "../../../../constants/statusCode.enum";
import verifiedIcon from "../../../../resource/icons/user-profile/verified.svg";
import locationIcon from "../../../../resource/icons/user-profile/location.svg";
import "./style/forwardInputSource.less";
import { userDetail } from "../../../../api/user";
import { localizeImg, localizeValue } from "../../../../constants/constants";
import { UserLabel, UserType } from "../../../../constants/usersType.enum";
import {
  companyDetail,
  companyImporterDesc,
  companyWineryDesc,
} from "../../../../api/company";
import { getCountryNameById } from "../../../../api/config";
import { loadProductConfig } from "../../../../api/product";
import { CompanyVerifyStatusEnum } from "../../../../constants/companyVerifyStatus.enum";
import { checkLimit, getTransLanguage } from "../../../../api/common";
import FeedImagePanel from "../../../shared/FeedImagePanel";
import { useAuth } from "../../../../contexts/AuthProvider";
import {
  openCompanyDetail,
  openCompanyImporterDesc,
  openCompanyWineryDesc,
} from "../../../../api/open";
import { RoleLimit } from "../../../../constants/roleType.enum";
import { useUserId } from "../../../../contexts/UserIdProvider";

export default function ForwardInputSource(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const { authTokens } = useAuth();
  const { data, keyword, onRefresh, onRemoveFeedItem } = props;
  const [refData, setRefData] = useState();
  const [feedId, setFeedId] = useState();
  const [orgContent, setOrgContent] = useState();
  const [feedContent, setFeedContent] = useState();
  const [likeCountAmount, setLikeCountAmount] = useState();
  const [forwardCountAmount, setForwardCountAmount] = useState();
  const [commentCountAmount, setCommentCountAmount] = useState();
  const [likeStatus, setLikeStatus] = useState();
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [mentions, setMentions] = useState([]);
  const [sourceFeedId, setSourceFeedId] = useState();
  const [sourceFeed, setSourceFeed] = useState([]);
  const [isFollow, setIsFollow] = useState(false);
  const [userItem, setUserItem] = useState();
  const [contentEllipsis, setContentEllipsis] = useState(false);
  const [contentHide, setContentHide] = useState(true);
  const [transStatus, setTransStatus] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [IsMyPost, setIsMyPost] = useState(false);
  const [translatedContent, setTranslatedContent] = useState();
  const [companyDesc, setCompanyDesc] = useState();
  const [companyVerified, setCompanyVerified] = useState(
    "COMPANY_NOT_VERIFIED"
  );
  const feedContentDiv = useRef(null);
  const [lang, setLang] = useState(i18n.language);
  const [transLoading, setTransLoading] = useState(false);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [locations, setLocations] = useState();

  useEffect(() => {
    initConfigData();
  }, []);

  useEffect(() => {
    setLang(i18n.language);
    setTranslatedContent();
    setTransStatus(false);
  }, [i18n.language]);

  useEffect(() => {
    if (keyword && feedContent) {
      let _content = formatContentKeyword(feedContent, keyword);
      setFeedContent(_content);
    }
  }, [keyword]);

  useEffect(() => {
    if (data && locations) {
      let {
        _id,
        content,
        userId,
        userInfo,
        isLiked,
        images,
        videos,
        createdAt,
        likeCount,
        commentCount,
        mentions,
        sourceFeed,
        sourceFeedId,
        forwardCount,
      } = data;
      // console.log('----------feed list row data:', data);

      setOrgContent(content);
      let _content = formatContentMentions(content, mentions);
      setFeedContent(_content);
      setLikeCountAmount(likeCount);
      setCommentCountAmount(commentCount);
      setForwardCountAmount(forwardCount);
      setLikeStatus(isLiked);

      initFeedUserInfo(data);
      setFeedId(_id);
      setImages(images);
      if (videos && videos.length > 0) {
        setVideo(localizeImg(videos[0]));
      } else {
        setVideo(null);
      }
      setCreatedAt(createdAt);
      setMentions(mentions);
      setSourceFeed(sourceFeed);
      setSourceFeedId(sourceFeedId);

      if (user.userId === userId) {
        setIsMyPost(true);
      }

      let isUnmounted = false;
      const initFollowStatus = async () => {
        let pms = { sourceId: user.userId, targetId: userId };
        let followStatusResult = await contactFollowStatus(pms);
        if (
          followStatusResult &&
          followStatusResult.statusCode === statusCode.SUCCESS &&
          followStatusResult.result
        ) {
          !isUnmounted && setIsFollow(true);
        }
      };
      initFollowStatus();
      setTranslatedContent();
      setTransStatus(false);
      return () => {
        isUnmounted = true;
      };
    }
  }, [data, locations]);

  useEffect(() => {
    if (!feedContent || !feedContentDiv.current) return;
    let element = feedContentDiv.current;
    let clientHeight = element.clientHeight;
    let scrollHeight = element.scrollHeight;
    if (clientHeight < scrollHeight) {
      setContentEllipsis(true);
    } else {
      setContentEllipsis(false);
    }
  }, [feedContent]);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    // console.log('---------configdata ',configData);
    setLocations(locations);
  };

  const initCompanyDesc = async (coId, type) => {
    let result;
    if (coId) {
      const companyDescPms = { coId };
      let companyInfo;
      if (authTokens) {
        companyInfo = await companyDetail(coId);
      } else {
        companyInfo = await openCompanyDetail(coId);
      }
      let companyDesc = {};

      if (authTokens) {
        if (type == UserType.SUPPLIER) {
          companyDesc = await companyWineryDesc(companyDescPms);
        } else {
          companyDesc = await companyImporterDesc(companyDescPms);
        }
      } else {
        if (type == UserType.SUPPLIER) {
          companyDesc = await openCompanyWineryDesc(companyDescPms);
        } else {
          companyDesc = await openCompanyImporterDesc(companyDescPms);
        }
      }

      if (companyInfo.status === CompanyVerifyStatusEnum.VERIFIED) {
        setCompanyVerified(t(type));
      }

      if (
        companyDesc &&
        companyDesc.statusCode === statusCode.SUCCESS &&
        companyDesc.result
      ) {
        setCompanyDesc(companyDesc.result);
        result = companyDesc.result;
      }
    }
    return result;
  };

  const initFeedUserInfo = async (stateData) => {
    let { userId, userInfo } = stateData;
    let temp_user;
    if (userInfo && userInfo.length > 0) {
      temp_user = userInfo[0];
    } else {
      temp_user = await userDetail(userId);
      if (!temp_user) {
        return;
      }
    }
    setUserItem(temp_user);
    temp_user.companyDesc = await initCompanyDesc(
      temp_user.companyId,
      temp_user.type
    );
    let _orgData = deepClone(stateData);
    _orgData.userInfo = [temp_user];
    setRefData(_orgData);
  };

  const formatContentKeyword = (_content, key, _mentions) => {
    if (key) {
      if (_mentions && _mentions.length > 0) {
        let tempContent = _content.replace(/</g, "#@&<").replace(/>/g, "#@&>");
        let strArray = tempContent.split("#@&");
        strArray = strArray.map((item) => {
          if (item.indexOf("<") === -1) {
            item = item.replace(
              key,
              '<span class="keyword-label">' + key + "</span>"
            );
          }
          return item;
        });
        _content = strArray.join("");
      } else {
        _content = _content.replace(
          key,
          '<span class="keyword-label">' + key + "</span>"
        );
      }
    }
    return _content;
  };

  const formatContentMentions = (_content, _mentions) => {
    if (_mentions && _mentions.length > 0) {
      _mentions.forEach((item) => {
        let { id, type, name } = item;
        let path = "profile";
        _content = _content.replace(
          name,
          `<span class="mentions-label" onclick="javascript:location.href='/${path}/${id}'">${name}</span>`
        );
      });
    }
    return _content;
  };

  const onCompanyHome = () => {
    if (checkLimit(user?.userInfo, RoleLimit.CONTACTS, history)) {
      goToProfilePage(userItem, history);
    }
  };

  const resetRefData = (status) => {
    let temp = deepClone(refData);
    temp.isLiked = status;
    setRefData(temp);
  };

  const onTranslateMessage = async () => {
    let currentStatus = !transStatus;
    setTransStatus(currentStatus);
    if (currentStatus && !translatedContent) {
      setTransLoading(true);
      let language = getTransLanguage(lang);
      let beforeTransContent = transContentFilterMention(orgContent, false);
      let result = await translate({
        text: beforeTransContent,
        to: language,
      });
      let afterTransContent = result.result.result;
      afterTransContent = transContentFilterMention(afterTransContent, true);
      setTranslatedContent(afterTransContent);
      setTransLoading(false);
    }
  };

  const transContentFilterMention = (value, afterTrans) => {
    if (value && mentions && mentions.length > 0) {
      mentions.map((item) => {
        item = `<span class="mentions-label">${item}</span>`;
        if (afterTrans) {
          value = value.replace("@@@", item);
        } else {
          value = value.replace(item, "@@@");
        }
      });
    }
    return value;
  };

  const onLike = async () => {
    if (!authTokens) {
      history.push("/login");
      return;
    }

    // set the status of the like before the request (we set the status before for UX purpose).
    setLikeStatus(!likeStatus);
    resetRefData(!likeStatus);

    //The message is already liked  we decrease one else we increase one
    if (likeStatus) {
      setLikeCountAmount(likeCountAmount - 1);
    } else {
      setLikeCountAmount(likeCountAmount + 1);
    }

    // create the like request
    let pms = { userId: user.userId, feedId };
    let result = await likeCreate(pms);

    // if the request is unsuccessful, reset the status of the like, maybe draw error message later
    if (!result) {
      setLikeStatus(!likeStatus);
      resetRefData(!likeStatus);
    }
  };

  const onComment = () => {
    setCommentCountAmount(commentCountAmount + 1);
  };

  const getCloseIcon = () => {
    return (
      <img alt={"Close icon"} src={closeIcon} className="modal-close-icon" />
    );
  };

  return (
    <>
      {!companyDesc ? (
        <div className="feed" style={{ width: "100%" }}>
          {/* loader create post */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic createFeedPlaceHolder"
            }
          >
            <div>
              <Skeleton active={true} avatar={true} paragraph={{ rows: 0 }} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Space>
                <Skeleton.Button active={true} size={"default"} />
                <Skeleton.Button active={true} size={"default"} />
                <Skeleton.Button active={true} size={"default"} />
              </Space>
              <Skeleton.Button active={true} size={"default"} />
            </div>
          </div>
          {/* loader item simple */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 2 }} />
          </div>
          {/* loader item with images */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 2 }} />
            <div className="feed-images-container">
              <Row gutter={[8, 8]} className="feed-images">
                <Col span={8}>
                  <div className="feed-list-image-box-loader">
                    <Skeleton.Image
                      style={{ width: "100%", height: "100%" }}
                      className="ant-image-loader"
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="feed-list-image-box-loader">
                    <Skeleton.Image
                      style={{ width: "100%", height: "100%" }}
                      className="ant-image-loader"
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="feed-list-image-box-loader">
                    <Skeleton.Image
                      style={{ width: "100%", height: "100%" }}
                      className="ant-image-loader"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/* loader item simple */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 3 }} />
          </div>
          {/* loader item simple */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 5 }} />
          </div>
        </div>
      ) : (
        <div className="forward-source-feed-row-item">
          <div className="forward-source-feed-row-item-header">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="meta-wrapper" onClick={onCompanyHome.bind(this)}>
                {/* <AvatarBasic
                  cname="meta-avatar"
                  size={51}
                  picture={localizeImg(companyDesc?.avatar)}
                  displayName={
                    companyDesc?.displayName || companyDesc?.companyName
                  }
                ></AvatarBasic> */}
                <div className="meta-wrapper-content">
                  <div className="meta-title">
                    {companyDesc?.displayName ||
                      localizeValue(i18n.language, companyDesc?.companyName)}
                  </div>
                  <div className="meta-desc">
                    <div style={{ marginRight: "14px" }}>
                      {t(getTimeBefore(createdAt))}
                    </div>
                    {companyDesc?.location && (
                      <div style={{ marginRight: "14px" }}>
                        <img
                          src={locationIcon}
                          style={{
                            width: "8.12px",
                            height: "9.2px",
                            marginRight: "5px",
                          }}
                        />
                        {getCountryNameById(
                          locations,
                          lang,
                          companyDesc?.location?.country
                        )}
                        {getCountryNameById(
                          locations,
                          lang,
                          companyDesc?.location?.city
                        )}
                      </div>
                    )}
                    <div style={{ marginRight: "14px" }}>
                      {t(companyVerified) != t("COMPANY_NOT_VERIFIED") && (
                        <img
                          src={verifiedIcon}
                          style={{
                            width: "8px",
                            height: "9.44px",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      {t(companyVerified)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            ref={feedContentDiv}
            className={
              contentHide ? "feed-row-content-ellipsis" : "feed-row-content"
            }
            dangerouslySetInnerHTML={{ __html: feedContent }}
          ></div>
          {contentEllipsis && (
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              <span
                onClick={() => setContentHide(!contentHide)}
                className="feed-trans-button"
              >
                {contentHide ? t("SHOW_MORE") : t("SHOW_LESS")}{" "}
              </span>
            </div>
          )}
          {feedContent && (
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              <span
                onClick={onTranslateMessage.bind(this)}
                className="feed-trans-button"
              >
                {transStatus ? (
                  <UpOutlined style={{ fontSize: "10px" }} />
                ) : (
                  <DownOutlined style={{ fontSize: "10px" }} />
                )}
                <span>{t("TRANSLATE")}</span>
              </span>
            </div>
          )}

          {transStatus && (
            <div
              className="feed-trans-content"
              dangerouslySetInnerHTML={{ __html: translatedContent }}
            ></div>
          )}
          {transLoading && (
            <div className="feed-trans-content">
              <Spin indicator={loadingIcon} />
            </div>
          )}
          {images && images.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <FeedImagePanel
                images={images}
                onImageClick={() => setShowDetail(true)}
              ></FeedImagePanel>
            </div>
          )}
          {video && (
            <div className="feed-images-container">
              <VideoBasic url={video} width="100%" height={350} />
            </div>
          )}
          {/* {sourceFeedId &&
            (sourceFeed && sourceFeed.length > 0 ? (
              <FeedForwardChild data={sourceFeed[0]} />
            ) : (
              <div className="feed-row-tip">{t("Original text deleted")}</div>
            ))} */}
          <Modal
            destroyOnClose={true}
            title={null}
            visible={showDetail}
            footer={null}
            className={
              (images && images.length > 0) || video
                ? "feed-detail-modal-images"
                : "feed-detail-modal"
            }
            closeIcon={getCloseIcon()}
            onCancel={() => setShowDetail(false)}
          >
            <FeedDetail
              data={refData}
              onLike={onLike.bind(this)}
              onComment={onComment.bind(this)}
            />
          </Modal>
        </div>
      )}
    </>
  );
}
