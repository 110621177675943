import React from "react";
import styles from "./DashboardWarehouses.module.sass";
import TooltipGlodal from "../../../../core-digital-template/components/TooltipGlodal";
import WarehouseList from "./WarehouseList";

const DashboardWarehouses = () => {
    return (
        <>
            <div className={styles.section}>
                <WarehouseList />
            </div>
            <TooltipGlodal />
        </>
    );
};

export default DashboardWarehouses;
