import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Button, Image, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useUserId } from "../../contexts/UserIdProvider";
import { toastInfo } from "../../api/toast";
import { localizeImg } from "../../constants/constants";
import "./style/article.less";
import { dateFormat } from "../../utils/date";
import { articleRemove, getArticles } from "../../api/article";
import { getProductStatusText } from "../../api/common";
import { ProductStatus } from "../../constants/statusCode.enum";

export default function Article() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const [data, setData] = useState([]);
  const history = useHistory();
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    loadData(user.userId);
  }, [pageIndex]);

  const loadData = async () => {
    let pms = {
      userId: user.userId,
      page: pageIndex,
      per_page: pageSize,
    };
    let data = await getArticles(pms);
    if (data && data.total > 0) {
      let list = data.result;
      console.log("--------articles is :", list);
      list = list.map((item) => {
        return { ...item, key: item._id };
      });
      setData(list);
    }
  };

  const onRemove = async (id) => {
    let pms = { id: id };
    await articleRemove(pms);
    loadData(user.userId);
    toastInfo(t("DELETE_SUCCESS"));
  };

  const onEditArticle = async (id) => {
    history.push(`/article/edit/${id}`);
  };

  const toArticleDetail = async (id) => {
    history.push(`/article/show/${id}`);
  };

  const columns = [
    {
      title: t("ARTICLE_TITLE"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("PUBLISH_TIME"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => dateFormat(date),
    },
    {
      title: t("ARTICLE_BANNER"),
      dataIndex: "banner",
      key: ["banner"],
      render: (item) => (
        <Image width={150} height={150} src={localizeImg(item[0])} />
      ),
    },
    {
      title: t("STATUS"),
      dataIndex: "status",
      key: "status",
      render: (val) => t(getProductStatusText(val)),
    },
    {
      title: t("ACTION"),
      key: "_id",
      dataIndex: "_id",
      render: (id, item) => (
        <Space size="middle">
          <a onClick={toArticleDetail.bind(this, id)}>{t("PREVIEW")}</a>
          {item.status !== ProductStatus.VERIFYSUCCESS && (
            <>
              <span className="table-action-item-split">-</span>
              <a onClick={onEditArticle.bind(this, id)}>{t("EDIT")}</a>
            </>
          )}
          <span className="table-action-item-split">-</span>
          <a onClick={onRemove.bind(this, id)}>{t("DELETE")}</a>
        </Space>
      ),
    },
  ];

  return (
    <div className="common-page article-container">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_ACCOUNT")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_NEWS")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management">
        <div className="common-management-title">
          {t("NEWS_MANAGEMENT")}
          <Button
            onClick={() => {
              history.push("/article/create");
            }}
            className="create-button"
          >
            + {t("WRITE_ARTICLE")}
          </Button>
        </div>
        <br />
        <div className="common-management-panel">
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </div>
  );
}
