import {actionRequest} from "./api";
import {RequestType} from "../constants/requestType.enum";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export function createCheckoutSession(pms: any) {
    return actionRequest(RequestType.POST, apiPath.stripeCreateCheckoutSession, pms);
}

/**
 *
 * @param pms
 */
export function upgradeCompanyInfo(pms: any) {
    return actionRequest(RequestType.POST, apiPath.stripeUpgradeCompanyInfo, pms);
}

/**
 *
 * @param pms
 */
export function createExpressAccount(pms: any) {
    return actionRequest(RequestType.POST, apiPath.stripeCreateExpressAccount, pms);
}

export function getStripeAccount(pms: any) {
    return actionRequest(RequestType.GET, apiPath.stripeGetAccountInfo, pms);
}
