import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { localizeImg } from "../../../constants/constants";
import "./style/eventListRow.less";
import { goToProfilePage } from "../../../utils/utils";
import EventDetail from "./EventDetail";

export default function EventListRow(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { data, keyword, showDetail } = props;
  const [detail, setDetail] = useState(data);
  const [title, setTitle] = useState();
  const [address, setAddress] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [detailStatus, setDetailStatus] = useState(showDetail);
  const [picture, setPicture] = useState();
  const [desc, setDesc] = useState();
  const [showDetailVisible, setShowDetailVisible] = useState(false);

  useEffect(() => {
    setDetail(data);
    setDetailStatus(showDetail);
    if (data) {
      let { title, address, picture, startDate, endDate, description } = data;
      formatTitle(title, keyword);
      setPicture(picture);
      setAddress(address);
      setStartDate(startDate);
      setEndDate(endDate);
    }
  }, [data, keyword, showDetail]);

  const formatTitle = (title, key) => {
    if (key && title) {
      title = title.replace(
        key,
        '<span class="keyword-label">' + key + "</span>"
      );
    }
    setTitle(title);
  };

  const onCompanyHome = () => {
    goToProfilePage(detail.userInfo[0], history);
  };

  const onDetailPreview = () => {
    setShowDetailVisible(true);
  }

  return (
    <div className="event-row-item-container">
      <img src={localizeImg(picture)} className="event-image" onClick={onDetailPreview.bind(this)} />
      <div className="event-content">
        <div className="event-title" onClick={onDetailPreview.bind(this)} dangerouslySetInnerHTML={{ __html: title }}></div>
        <div className="event-sub-title" onClick={onDetailPreview.bind(this)} >Locations: {address}</div>
        <div className="event-sub-title" onClick={onDetailPreview.bind(this)} >{startDate} ~ {endDate}</div>
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={showDetailVisible}
        footer={null}
        className={"event-detail-modal"}
        // closeIcon={getCloseIcon()}
        onCancel={() => setShowDetailVisible(false)}
      >
        <EventDetail data={detail} showDetail={true} />
      </Modal>
    </div>
  );
}
