import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Space, Menu } from "antd";
import { useTranslation } from "react-i18next";
import "./style/OrdersListItem.less";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { orderRemove, updateOrder } from "../../../api/orders";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getNativePayCode } from "../../../api/wxPay";
import plusIcon from "../../../resource/icons/dashboard/order/plus.svg";
import {
  localizeImg,
  localizeTitle,
  PayType,
  ProductCurrencyType,
} from "../../../constants/constants";
import { useUserId } from "../../../contexts/UserIdProvider";
import { OrderStatus } from "../../../constants/statusCode.enum";
import { toastError, toastInfo } from "../../../api/toast";
import BankTransfer from "./BankTransfer";
import { getCountryNameById } from "../../../api/config";
import { loadProductConfig } from "../../../api/product";

export default function OrdersListItem(props) {
  const { item, index, itemLoadData, onAliPay } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { isWinery, user } = useUserId();
  const [bankTransferModalVisible, setBankTransferModalVisible] =
    useState(false);
  const [firstPaymentVouchers, setFirstPaymentVouchers] = useState([]);

  const [locations, setLocations] = useState();

  // console.log("-----------------OrdersListItem:", item);

  useEffect(() => {
    initConfigData();
  }, []);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const onGoDetail = (orderId) => {
    history.push("/company/order", { orderId });
  };
  const delOrder = async (order) => {
    let pms = { orderId: order._id };

    let result = await orderRemove(pms);
    if (result) {
      Modal.confirm({
        title: t("CONFIRM_DELETE"),
        icon: <ExclamationCircleOutlined />,
        content: t("ARE_YOU_SURE_TO_DELETE_THIS"),
        okText: t("CONFIRM"),
        cancelText: t("CANCEL"),
        onOk: async () => {
          itemLoadData();
        },
      });
    }
  };
  const onProductDetail = (productId) => {
    history.push("/products/detail/" + productId);
  };

  const onGetProductCount = (orderProducts, productId) => {
    let result = "1";
    if (orderProducts && orderProducts.length > 0) {
      let list = orderProducts.filter((item) => item.productId === productId);
      if (list && list.length > 0) {
        result = list[0].count;
      }
    }
    return result;
  };

  const onGetProductPrice = (orderProducts, productId) => {
    let result = "1";
    if (orderProducts && orderProducts.length > 0) {
      let list = orderProducts.filter((item) => item.productId === productId);
      if (list && list.length > 0) {
        result = list[0].price;
      }
    }
    return result;
  };

  const onMenuItemClick = (item) => {
    switch (item.key) {
      case "1":
        onWxPay();
        break;
      case "2":
        onAliPayClick();
        break;
      case "3":
        onBankTransfer();
        break;
      default:
        break;
    }
  };

  const menuPay = (
    <Menu onClick={onMenuItemClick.bind(this)}>
      <Menu.Item key="1">
        <span>{t("WECHAT_PAY")}</span>
      </Menu.Item>
      <Menu.Item key="2">
        <span>{t("PAY_WITH_ALIPAY")}</span>
      </Menu.Item>
      <Menu.Item key="3">
        <span>{t("BANK_TRANSFER")}</span>
      </Menu.Item>
    </Menu>
  );

  const onAliPayClick = async () => {
    onAliPay && onAliPay(item);
  };

  const onWxPay = async () => {
    if (item) {
      let result = await getNativePayCode(item);
      if (result) {
        history.push("/order/wxpay", { ...item, wxcode: result });
      } else {
        toastError(t("WECHAT_PAY_FAILED"));
      }
    }
  };

  const onBankTransfer = () => {
    setBankTransferModalVisible(true);
  };

  const onBankTransferClose = () => {
    setBankTransferModalVisible(false);
  };

  const onBankTransferPay = async (fileList) => {
    console.log("---- file list:", fileList);
    setFirstPaymentVouchers(fileList);
    onBankTransferClose();
    // let pms = { orderId, payType: PayType.Bank };
    // if (order.relationId) {
    //   pms = { relationId: order.relationId, payType: PayType.Bank };
    // }
    let pms = {
      _id: item._id,
      firstPaymentVouchers: fileList,
      payType: PayType.Bank,
    };
    let result = await updateOrder(pms);
    console.log("---- back pay update order res:", result);
    // let result = await orderConfirm(pms);
    // onRefreshPage(result);
  };

  return (
    <div
      className="order-table-item-container"
      key={index}
      style={{ padding: "20px auto" }}
    >
      <div className="company-panel">
        <span className="company-name">{item.wineryInfo[0].companyName}</span>
        <span className="order-number">
          {t("ORDER_NUMBER")} :{item.orderNumber}
        </span>
      </div>
      {item.products &&
        item.products.map((product, pindex) => (
          <div className="order-info-part" key={pindex}>
            <div className="product-info-panel">
              <div className="product-image-panel">
                <img
                  src={localizeImg(
                    product.images.length > 0
                      ? product.images[0]
                      : product.bottleImages[0]
                  )}
                  className="product-image  cursor-pointer"
                  onClick={onProductDetail.bind(this, product._id)}
                />
              </div>
              <div className="product-pro-panel">
                <div
                  className="product-name cursor-pointer"
                  onClick={onProductDetail.bind(this, product._id)}
                >
                  {localizeTitle(i18n.language, product.title)}
                </div>
                <div className="product-pro" style={{ marginTop: "12px" }}>
                  {t("PACKAGE")}
                  {":"}
                  {t("WOODEN_CASE")}
                </div>
                <div className="product-pro" style={{ marginTop: "8px" }}>
                  {t("SHIPPING_POINT")}
                  {":"}
                  {getCountryNameById(
                    locations,
                    i18n.language,
                    item.orderProducts[pindex].volume.shippingAddressInfo
                      .location.country
                  )}
                </div>
                <div className="order-pick-up-type">{t("SELF_IMPORT")}</div>
              </div>
            </div>
            <div className="product-status-part">
              <div className="product-status">{t(item.status)}</div>
              <div className="product-price">
                {product.currency === ProductCurrencyType.CNY &&
                  product.currency}
                {onGetProductPrice(item.orderProducts, product._id)}
                {product.currency !== ProductCurrencyType.CNY &&
                  product.currency}
              </div>
              <div className="product-order-count">
                *{onGetProductCount(item.orderProducts, product._id)}
              </div>
            </div>
          </div>
        ))}
      <div className="total-price-part">
        <span>{t("TOTAL_PRICE")}:</span>
        <span>
          {item.products[0].currency === ProductCurrencyType.CNY &&
            item.products[0].currency}
          {onGetProductPrice(item.orderProducts, item.products[0]._id) *
            onGetProductCount(item.orderProducts, item.products[0]._id)}
          {item.products[0].currency !== ProductCurrencyType.CNY &&
            item.products[0].currency}
        </span>
      </div>
      <div className="order-action-part">
        <div className="btn-del" onClick={delOrder.bind(this, item)}>
          {t("DELETE")}
        </div>
        <div className="btn-upload-document">
          <img src={plusIcon} style={{ marginRight: "13px" }} />
          {t("UP_LOAD_COPY_OF_BANK_TRANSFER")}
        </div>
        {/* <div className="item-action" onClick={onGoDetail.bind(this, item._id)}>
          {t("DETAIL_OF_THE_ORDER")}
        </div> */}
      </div>
      {/* <div className="table-header">
        <div className="order-info">
          <span className="order-no">
            {t("ORDER_NUMBER")} :{item.orderNumber}
          </span>
          <span className="order-time">
            {t("ORDER_TIME")} :
            {moment(item.orderPrice.createdAt).format("YYYY-MM-DD")}
          </span>
        </div>
        <div className="header-info">
          <div className="header-item1">{t("DEPOSIT_AMOUNT")}</div>
          <div className="header-item2">{t("ORDER_STATUS")}</div>
        </div>
      </div> */}
      {/* <div className="table-content">
        <div className="order-content">
          <div className="table-header">
            <div className="order-info">
              <div className="order-no">
                {t("ORDER_NUMBER")} :{item.orderNumber}
              </div>
              <div className="order-time">
                {t("ORDER_TIME")} :
                {moment(item.orderPrice.createdAt).format("YYYY-MM-DD")}
              </div>
            </div>
          </div>
          {item.products &&
            item.products.map((product, pindex) => (
              <div className="product-item" key={pindex}>
                <div className="product-info">
                  <div className="product-image-panel">
                    <img
                      src={localizeImg(
                        product.images.length > 0
                          ? product.images[0]
                          : product.bottleImages[0]
                      )}
                      className="product-image  cursor-pointer"
                      onClick={onProductDetail.bind(this, product._id)}
                    />
                  </div>
                  <div className="product-info-right">
                    <div
                      className="product-name cursor-pointer"
                      onClick={onProductDetail.bind(this, product._id)}
                    >
                      {localizeTitle(i18n.language, product.title)}
                    </div>
                    <div className={"ItemPrice"}>
                      {product.currency === ProductCurrencyType.CNY &&
                        product.currency}
                      {onGetProductPrice(item.orderProducts, product._id)}
                      {product.currency !== ProductCurrencyType.CNY &&
                        product.currency}
                      {" x "}
                      {onGetProductCount(item.orderProducts, product._id)}
                    </div>
                  </div>
                </div>
                <div className={"ItemPriceCalc"}>
                  {product.currency === ProductCurrencyType.CNY &&
                    product.currency} 
                  {onGetProductPrice(item.orderProducts, product._id)} 
                  {product.currency !== ProductCurrencyType.CNY &&
                    product.currency}
                    {' x '}
                   {onGetProductCount(item.orderProducts, product._id)}
                </div>
              </div>
            ))}
        </div>
        <div className="order-columns">
          <div className="column-item">
            <div className="table-header">
              <div className="header-info">
                <div className="header-item1">{t("DEPOSIT_AMOUNT")}</div>
              </div>
            </div>
            <span className="item-price">
              {ProductCurrencyType.CNY}
              {item.orderPrice.payPrice.toFixed(2)}
            </span>
          </div>
          <div className="column-item">
            <div className="table-header">
              <div className="header-info">
                <div className="header-item2">{t("ORDER_STATUS")}</div>
              </div>
            </div>
            <span className="item-price">{t(item.status)}</span>
          </div>
          <div className="column-item">
            <Space size={10} direction="vertical">
              {!isWinery && (
                <Link
                  to={{ pathname: "-deprecated", state: { userId: item.userId } }}
                >
                  <div className="item-action">{t("CONTACT_THE_SELLER")}</div>
                </Link>
              )}
              {item.status == OrderStatus.WaitingForPay ||
              item.status == OrderStatus.Complete ? (
                <div
                  className="item-del-action"
                  onClick={delOrder.bind(this, item)}
                >
                  {t("DELETE")}
                </div>
              ) : (
                ""
              )}
              {!isWinery && item.status === OrderStatus.WaitingForPay && (
                <Dropdown overlay={menuPay} trigger={["click"]}>
                  <div className="item-action">{t("PAY")}</div>
                </Dropdown>
              )}
              <div
                className="item-action"
                onClick={onGoDetail.bind(this, item._id)}
              >
                {t("DETAIL_OF_THE_ORDER")}
              </div>
            </Space>
          </div>
        </div>
      </div> */}
      <Modal
        destroyOnClose={true}
        title={null}
        visible={bankTransferModalVisible}
        footer={null}
        className="address-create-modal"
        onCancel={onBankTransferClose.bind(this)}
      >
        <BankTransfer
          files={firstPaymentVouchers}
          onComplete={onBankTransferPay.bind(this)}
          onClose={onBankTransferClose.bind(this)}
        />
      </Modal>
    </div>
  );
}
