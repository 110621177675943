import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import AvatarBasic from "../../../../../components/common/avatar/AvatarBasic";
import { localizeImg } from "../../../../../constants/constants";

const Panel = ({ className, news, authorInfo }) => {
  const [newsModel, setNewsModel] = useState({});

  useEffect(() => {
    setNewsModel(news);
  }, [news]);

  return (
    <>
      <div className={cn(styles.panel, className)}>
        <div className={styles.avatar}>
          <AvatarBasic
            picture={localizeImg(authorInfo?.companyDesc?.avatar)}
            size={61}
          />
        </div>
      </div>
    </>
  );
};

export default Panel;
