import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTrans from "./react-i18next/locales/Vinexwork_English.json";
import zhTrans from "./react-i18next/locales/Vinexwork_Chinese_simplified.json";
import frTrans from "./react-i18next/locales/Vinexwork_French.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    debug: false,
    resources: {
      en: {
        translation: enTrans,
      },
      zh: {
        translation: zhTrans,
      },
      fr: {
        translation: frTrans,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  }, () => { console.log("i18n detected language:", i18n.language); });

export default i18n;
