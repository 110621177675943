import React, { useEffect, useState } from "react";
import { Button, Space, Table, Modal, Breadcrumb } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUserId } from "../../../contexts/UserIdProvider";
import InvioceCreate from "./InvoiceCreate";
import "./style/invoice.less";
import { deepClone } from "../../../utils/utils";
import BreadcrumbNormal from "../../common/title/BreadcrumbNormal";
import {
  getOrderBills,
  removeOrderBill,
  setOrderBillDefault,
} from "../../../api/orders";
import { toastError, toastInfo } from "../../../api/toast";

export default function Invoice() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const { userId, userInfo } = user;
  const [data, setData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [createModalVisible, setcreateModalVisible] = useState(false);
  const [addressDetail, setaddressDetail] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    loadData();
  }, []);

  const columns = [
    {
      title: t("COMPANY_NAME"),
      dataIndex: "companyName",
      key: "companyName",
      width: "15%",
    },
    { title: t("TAX_CODE"), dataIndex: "taxCode", key: "taxCode" },
    {
      title: t("COMPANY_ADDRESS"),
      dataIndex: "companyAddress",
      key: "companyAddress",
      render: (val) => {
        return val ? val : t("N/A");
      },
    },
    {
      title: t("CONTACT_PHONE"),
      dataIndex: "contactPhone",
      key: "contactPhone",
      render: (val) => {
        return val ? val : t("N/A");
      },
    },
    // {
    //   title: t("BANK_ACCOUNT"),
    //   dataIndex: "bankAccount",
    //   key: "bankAccount",
    //   render: (val) => {
    //     return val ? val : t("N/A");
    //   },
    // },
    // { title: t("BANK_OF_DEPOSIT"), dataIndex: "bankName", key: "bankName" },
    {
      title: t("TYPE"),
      dataIndex: "billType",
      key: "billType",
      render: (val) => {
        return val === 1
          ? t("VAT_SPECIAL_INVOICE")
          : t("ELECTRONIC_ORDINARY_INVOICE");
      },
    },
    {
      title: t("IS_DEFAULT"),
      dataIndex: "isDefault",
      key: "isDefault",
      width: "10%",
      render: (val) => {
        return val ? t("YES") : t("NO");
      },
    },
    {
      title: t("ACTION"),
      dataIndex: "",
      key: "y",
      render: (item) => {
        return (
          <Space size={6}>
            <Button
              type="text"
              className={"button-primary"}
              onClick={onUpdate.bind(this, item._id)}
            >
              {t("EDIT")}
            </Button>
            <Button
              type="text"
              className={"button-primary"}
              onClick={onDelete.bind(this, item._id)}
            >
              {t("DELETE")}
            </Button>
            {!item.isDefault && (
              <Button
                type="link"
                className={"button-default"}
                onClick={onSetDefault.bind(this, item._id)}
              >
                {t("SET_DEFAULT")}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const loadData = async () => {
    let pms = {
      // userId,
      coId: userInfo.companyId,
      // page: pageIndex,
      // per_page: pageSize
    };
    let list = await getOrderBills(pms);
    console.log("-----getorderbills ;", pms, list);
    if (list && list.length > 0) {
      let orgList = deepClone(list);
      setOrgData(orgList);
      list = list.map((item, index) => {
        return { ...item, key: index };
      });
      setData(list);
    } else {
      setData([]);
    }
  };

  const onCreate = (type) => {
    setcreateModalVisible(true);
  };

  const onDelete = async (id) => {
    let pms = { id };
    await removeOrderBill(pms);
    await loadData();
  };

  const onFinished = () => {
    setcreateModalVisible(false);
    loadData();
  };

  const onUpdate = (id) => {
    let tempList = orgData.filter((item) => item._id === id);
    if (tempList && tempList.length > 0) {
      setaddressDetail(tempList[0]);
      setcreateModalVisible(true);
    }
  };

  const onSetDefault = async (id) => {
    let pms = { id, coId: userInfo.companyId };
    let data = await setOrderBillDefault(pms);
    console.log("-------order bill default set:", pms, data);
    if (data) {
      toastInfo(t("ACTION_SUCCESS"));
      loadData();
    } else {
      toastError(t("ACTION_FAIL"));
    }
  };

  return (
    <div className="common-page ">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_COMPANY")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("MANAGE_INVOICE")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={"common-management address-container"}>
        <div className="common-management-title">
          {t("MANAGE_INVOICE")}
          <Button onClick={onCreate.bind(this)} className="create-button">
            {t("ADD_INVOICE")}
          </Button>
        </div>
        <div style={{ marginTop: "10px" }}></div>
        <div className="common-management-panel">
          <Table
            columns={columns}
            dataSource={data}
            pagination={data.length > pageSize}
            style={{ marginTop: "10px" }}
          />
        </div>
        <Modal
          destroyOnClose={true}
          title={null}
          visible={createModalVisible}
          footer={null}
          className="address-create-modal"
          onCancel={() => setcreateModalVisible(false)}
        >
          <InvioceCreate
            detail={addressDetail}
            isDefault={false}
            onComplete={onFinished.bind(this)}
          />
        </Modal>
      </div>
    </div>
  );
}
