import { actionRequest, formatActionResponse, formatActionResponseArray, formatResponse, formatResponseArray } from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export async function getOrders(pms: any) {
    let data: any = await actionRequest(RequestType.GET, apiPath.orders, pms);
    return formatResponseArray(data);
}
export function getPageOrders(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.orders, pms);
}

export function getImporterGroupOrders(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.orderGroupByImporterCoId, pms);
}

/**
 *
 * @param pms
 */
export async function orderCreate(pms: any) {
    let data: any = await actionRequest(RequestType.POST, apiPath.orderCreate, pms);
    return formatResponse(data);
}

export async function updateOrder(pms: any) {
    let data: any = await actionRequest(RequestType.POST, apiPath.orderUpdate, pms);
    return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function orderCancel(pms: any) {
    let data: any = await actionRequest(RequestType.GET, apiPath.orderCancel, pms);
    return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function orderRemove(pms: any) {
    let data: any = await actionRequest(RequestType.GET, apiPath.orderRemove, pms);
    return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function orderConfirm(pms: any) {
    let data: any = await actionRequest(RequestType.GET, apiPath.orderConfirm, pms);
    return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function orderDetail(pms: any) {
    let data: any = await actionRequest(RequestType.GET, apiPath.orderDetail, pms);
    return formatResponse(data);
}

export async function getOrderDetailsByRelationId(pms: any) {
    let data: any = await actionRequest(RequestType.GET, apiPath.orderDetailsByRelationId, pms);
    return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function getLogisticsList(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.logisticsList, pms);
}

export async function customerWineryPage(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.customerWinerys, pms);
}

export async function customerImporterPage(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.customerImporters, pms);
}

export async function importerOrderProducts(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.orderImporterProducts, pms);
}

export async function importerOrderPay(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.orderImporterPay, pms);
}

export async function getOrderSampleCount(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.orderSampleCount, pms);
}

export async function createOrderBill(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.orderBillCreate, pms);
}

export async function updateOrderBill(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.orderBillUpdate, pms);
}

export async function removeOrderBill(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.orderBillRemove, pms);
}

export async function getOrderBills(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.orderBills, pms);
}

export async function setOrderBillDefault(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.orderBillSetDefault, pms);
}

//#region send sample 
export async function createSendSample(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.sendSampleCreate, pms);
}

export async function updateSendSample(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.sendSampleUpdate, pms);
}

export async function getSendSamples(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.sendSamples, pms);
}

export async function removeSendSamples(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.sendSampleRemove, pms);
}
//#endregion