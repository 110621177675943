import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import { useTranslation } from "react-i18next";

const Panel = ({ onPublish, onSaveDraft }) => {
  const { t } = useTranslation();

  return (
    <div className={cn("panel", styles.panel)}>
      <div className={styles.info}>
        {/* <Icon name="check-all" size="24" />
        Last saved <span>Oct 4, 2021 - 23:32</span> */}
      </div>
      <div className={styles.btns}>
        <button
          className={cn("button", styles.button)}
          onClick={() => onPublish && onPublish()}
        >
          {t("SAVE")}
        </button>
      </div>
    </div>
  );
};

export default Panel;
