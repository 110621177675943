import React, { useState, useEffect } from "react";
import { Input, Button, Space, Select, Col, Tree } from "antd";
import { useTranslation } from "react-i18next";
import "./style/StaffCreatePanel.less";
import { Row } from "antd";
import { companyRoles } from "../../../api/roles";
import { createStaff, updateStaff } from "../../../api/company";
import { statusCode } from "../../../constants/statusCode.enum";
import { toastError, toastInfo } from "../../../api/toast";
import { useUserId } from "../../../contexts/UserIdProvider";
import { isEmail, isMobile } from "../../../utils/regex";
import InputPhoneNumberNormal from "../../common/input/InputPhoneNumberNormal";
import { sendEmail } from "../../../api/user";
import { deepClone } from "../../../utils/utils";
import plusIcon from "../../../resource/icons/common/plus_normal.svg";
import { getCountryForSelect } from "../../../api/config";
import { loadProductConfig } from "../../../api/product";
import { list as fetchUserLimitList } from "../../../api/userlimit";

export default function StaffCreatePanel(props) {
  const {
    currentStaff,
    onStaffChange,
    onComplete,
    onClose,
    onRoleCreate,
    refreshRoleList,
  } = props;
  const { user } = useUserId();
  const { t, i18n } = useTranslation();
  const { TextArea } = Input;

  let [userName, setUserName] = useState("");
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword,setConfirmPassowrd]=useState("");
  let [email, setEmail] = useState("");
  let [phone, setPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  let [roles, setRoles] = useState([]);
  const [id, setId] = useState();
  let [roleId, setRoleId] = useState();
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);

  const [roleConfigData, setRoleConfigData] = useState([]);
  const [limits, setLimits] = useState([]);

  useEffect(() => {
    initUserLimitList();
  }, []);

  useEffect(() => {
    if (refreshRoleList) {
      initRoles();
    }
    console.log("---------------------------refreshRoleList:", refreshRoleList);
  }, [refreshRoleList]);

  useEffect(() => {
    if (currentStaff) {
      setId(currentStaff._id);
      setUserName(currentStaff.userName);
      setEmail(currentStaff.email);
      setPhone(currentStaff.phone);
      setPhoneNumber(currentStaff.phoneNumber);
      setRoleId(currentStaff.roleId);
      setCountry(currentStaff.country);
    }
    initConfigData();
    initRoles();
    let crispDom = document.getElementById("crisp-chatbox");
    crispDom.style.zIndex = 1;
  }, []);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    // setLocations(locations);
    setCountries(getCountryForSelect(locations, i18n.language));
  };

  const initUserLimitList = async () => {
    let pms = {};
    let result = await fetchUserLimitList(pms);
    result = result.result;
    if (result && result.length > 0) {
      console.log(
        "----------------------------initUserLimitList:",
        result,
        user.userInfo.type
      );
      result = result.filter((filterItem) => {
        return (
          filterItem.type.filter(
            (subFilterItem) => subFilterItem == user.userInfo.type
          ).length > 0
        );
      });
      let roleList = [];
      result.map((item) => {
        if (!item.parentId) {
          roleList.push({
            _id: item._id,
            title: t("ROLE_"+item.name),
            key: item.name,
            children: [],
          });
        }
      });
      result.map((item) => {
        if (item.parentId) {
          roleList.map((parentItem) => {
            if (parentItem._id == item.parentId) {
              parentItem.children.push({
                title: t("ROLE_"+item.name),
                key: item.name,
              });
            }
          });
        }
      });
      console.log("----------------------------initUserLimitList:", roleList);
      setRoleConfigData(roleList);
    }
  };

  const initRoles = async () => {
    let pms = { coId: user.userInfo.companyId };
    let result = await companyRoles(pms);
    if (result && result.length > 0) {
      result = result.map((item) => {
        return { label: item.name, value: item._id };
      });
      console.log("-----roles list:", result);
      setRoles(result);
    }
  };

  const onUserNameChange = (e) => {
    let val = e.target.value;
    setUserName(val);
    let item = deepClone(currentStaff);
    item.userName = val;
    onStaffChange(item);
  };

  const onFirstNameChange = (e) => {
    let val = e.target.value;
    setFirstName(val);
    let item = deepClone(currentStaff);
    item.firstName = val;
    onStaffChange(item);
  };

  const onLastNameChange = (e) => {
    let val = e.target.value;
    setLastName(val);
    let item = deepClone(currentStaff);
    item.lastName = val;
    onStaffChange(item);
  };

  const onPasswordChange = (e) => {
    let val = e.target.value;
    setPassword(val);
    let item = deepClone(currentStaff);
    item.passowrd = val;
    onStaffChange(item);
  };

  const onConfirmPasswordChange = (e) => {
    let val = e.target.value;
    setConfirmPassowrd(val);
    let item = deepClone(currentStaff);
    item.confirmPassword = val;
    onStaffChange(item);
  };

  const onEmailChange = (e) => {
    let val = e.target.value;
    setEmail(val);
    let item = deepClone(currentStaff);
    item.email = val;
    onStaffChange(item);
  };

  const onPhoneChange = (val) => {
    setPhone(val);
    setPhoneNumber(val.phone);
    let item = deepClone(currentStaff);
    item.phone = val;
    item.phoneNumber = val.phone;
    onStaffChange(item);
  };

  const onRoleIdChange = (val) => {
    setRoleId(val);
    let item = deepClone(currentStaff);
    item.roleId = val;
    onStaffChange(item);
  };

  const onCountryChange = (val) => {
    setCountry(val);
    let item = deepClone(currentStaff);
    item.country = val;
    onStaffChange(item);
  };

  const onStaffCreate = async () => {
    if (!userName) {
      toastError(t("USER_NAME") + t("IS_REQUIRED"));
      return;
    }
    if (!email) {
      toastError(t("EMAIL") + t("IS_REQUIRED"));
      return;
    }
    if (email && !isEmail(email)) {
      toastError(t("INVALID_EMAIL"));
      return;
    }
    if (!phoneNumber) {
      toastError(t("PHONE_NUMBER") + t("IS_REQUIRED"));
      return;
    }
    // if (phoneNumber && !isMobile(phoneNumber)) {
    //   toastError(t("INVALID_PHONE_NUMBER"));
    //   return;
    // }
    if (!roleId) {
      toastError(t("ROLE") + t("IS_REQUIRED"));
      return;
    }
    let pms = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber,
      phone: phone,
      roleId: roleId,
    };
    let result;
    if (id) {
      pms._id = id;
      result = await updateStaff(pms);
    } else {
      pms.nickName = pms.firstName + pms.lastName;
      pms.type = user.userInfo.type;
      pms.companyId = user.userInfo.companyId;
      pms.password = "123456";
      pms.isStaff = true;
      result = await createStaff(pms);
    }
    console.log("--------save user staff:", result);
    if (result.statusCode === statusCode.SUCCESS) {
      toastInfo(t("SAVE_SUCCESS"));
      if (!id) {
        sendStaffEmail();
      }
      onFinish();
    } else if (result.statusCode === statusCode.PhoneExists) {
      toastError(t("PHONE_ALREADY_EXIST"));
    } else if (result.statusCode === statusCode.EmailExists) {
      toastError(t("EMAIL_ALREADY_EXIST"));
    } else if (result.statusCode === statusCode.CreateIMUserFailed) {
      toastError(t("CREATE_IMUSER_ERROR"));
    } else {
      toastError("SAVE_FAILED");
    }
  };
  const sendStaffEmail = async () => {
    let content = t("COMPANY_REGISTER_STAFF_ACCOUNT_CONTENT");
    let title = t("COMPANY_REGISTER_STAFF_ACCOUNT_TITLE");
    let pms = {
      email: email,
      content: content,
      title: title,
    };
    let data = await sendEmail(pms);
    console.log("-----send email result:", data, pms);
    // if (data.statusCode === statusCode.SUCCESS) {
    //   setCapture(data.result.capture);
    // }
  };
  const onFinish = () => {
    onComplete && onComplete();
  };

  const onCloseClick = () => {
    onClose && onClose();
  };

  const onRoleSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  const onRoleCheck = (checkedKeys, info) => {
    console.log("onCheck", checkedKeys, info);
    let str = checkedKeys.join(",");
    setLimits(str);
    // let temp = deepClone(currentRole) || {};
    // temp.limits = str;
    // onRoleChange(temp);
  };

  const required_tip = <span className="required-tip">*</span>;

  return (
    <div className="staff-create-panel-container">
      <div className="modal-body">
        {/* <div className="modal-title">{t("ADD_OR_UPDATE_STAFF")}</div> */}
        <div className="form-body">
          <div className="form-panel">
            <div className="form-label">{t("ACCOUNT_NAME")}</div>
            <Input
              value={userName}
              placeholder={t("ACCOUNT_NAME")}
              className="content-row-input"
              onInput={onUserNameChange.bind(this)}
            />
          </div>
          {/* <div className="form-panel">
            <div className="form-label">
              {t("LAST_NAME")}
              {required_tip}
            </div>
            <Input
              value={lastName}
              placeholder={t("LAST_NAME")}
              className="content-row-input"
              onInput={onLastNameChange.bind(this)}
            />
          </div> */}
          <div className="form-panel">
            <div className="form-label">
              {t("EMAIL")}
              {/* {required_tip} */}
            </div>
            <Input
              value={email}
              placeholder={t("EMAIL")}
              className="content-row-input"
              onInput={onEmailChange.bind(this)}
            />
          </div>
          <div className="form-panel">
            <div className="form-label">
              {t("PHONE_NUMBER")}
              {/* {required_tip} */}
            </div>
            <InputPhoneNumberNormal
              value={phone}
              onChange={onPhoneChange.bind(this)}
            />
          </div>
          <div className="form-panel">
            <div className="form-label">
              {t("PASSWORD")}
              {/* {required_tip} */}
            </div>
            <Input
              value={password}
              placeholder={t("PASSWORD")}
              className="content-row-input"
              onInput={onPasswordChange.bind(this)}
            />
          </div>
          <div className="form-panel">
            <div className="form-label">
              {t("CONFIRM_THE_PASSWORD")}
              {/* {required_tip} */}
            </div>
            <Input
              value={confirmPassword}
              placeholder={t("CONFIRM_THE_PASSWORD")}
              className="content-row-input"
              onInput={onConfirmPasswordChange.bind(this)}
            />
          </div>
          <div className="form-panel">
            <div className="form-label">
              {t("JOB_POSITION")}
              {required_tip}
            </div>
            <div className="select-panel">
              <Select
                bordered={false}
                showArrow={false}
                className="common-select role-select"
                options={roles}
                onChange={onRoleIdChange.bind(this)}
                value={roleId}
              />
              <img
                src={plusIcon}
                className="action-button"
                onClick={onRoleCreate.bind(this)}
              />
            </div>
          </div>
          <div className="form-panel">
            <div className="form-label">
              {t("WORKING_LOCATION")}
              {/* {required_tip} */}
            </div>
            <Select
              className="content-select"
              value={country}
              onChange={onCountryChange.bind(this)}
              placeholder={t("COUNTRY")}
              options={countries}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
