import { Form, InputNumber } from "antd";

export default function InputNumberBasic(props) {
  let { label, name, required, message, min, max } = props;
  let layout = { label, name };
  required = required ? required : true;
  if (required) {
    layout.rules = [{ required: true, message }];
  }
  let inputLayout = { min: 0, max: 100000 };
  if (!isNaN(min)) {
    inputLayout.min = min;
  }
  if (!isNaN(max)) {
    inputLayout.max = max;
  }

  return (
    <Form.Item {...layout}>
      <InputNumber {...inputLayout} style={{ display: "block" }} />
    </Form.Item>
  );
}
