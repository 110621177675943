import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Button, Col, DatePicker, Input, Row, Select, } from "antd";
import UploadNormal from "../common/upload/UploadNormal";
import UploadPng from "../../resource/images/upload/Upload.PNG";
import ProductImageUpload from "../common/upload/ProductImageUpload";
import { useUserId } from "../../contexts/UserIdProvider";
import { localizeImg } from "../../constants/constants";
import { eventCreate, eventUpdate, getEventDetail } from "../../api/events";
import { loadProductConfig } from "../../api/product";
import { getCountryForSelect } from "../../api/config";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { toastError } from "../../api/toast";

export default function EventCreate() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const { state } = useLocation();
  const id = state?.id;
  const [currentLanguage, setCurrentLanguage] = useState();
  const [title, setTitle] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState();
  const [bannerUrl, setBannerUrl] = useState("");
  const [banner, setBanner] = useState();
  const [editor, setEditor] = useState();
  const [desc, setDesc] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [address, setAddress] = useState();
  const [website, setWebsite] = useState();
  const { TextArea } = Input;

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setConfigData();
  }, [currentLanguage]);

  useEffect(() => {
    if (id) {
      initDetail();
    }
  }, []);

  const initDetail = async () => {
    let pms = { _id: id };
    let item = await getEventDetail(pms);
    console.log("----detail result:", item);
    if (item) {
      setTitle(item.title);
      setCountry(item.country);
      if (item.picture) {
        setBanner(item.picture);
        setBannerUrl(localizeImg(item.picture));
      }
      setDesc(item.description);
      setAddress(item.address);
      setWebsite(item.website);
      setStartDate(item.startDate);
      setEndDate(item.endDate);
    }
  };

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setCountryList(getCountryForSelect(locations, currentLanguage));
  };

  const onFileLoading = (index, percent, url) => {
    // console.log("onFileLoading", url);
    if (percent === 0) {
      setBannerUrl(url);
    }
  };

  const onFileLoaded = (data) => {
    // console.log("onFileLoaded", data);
    if (data.length > 0) {
      setBanner(data[0]);
      setBannerUrl(localizeImg(data[0]));
    }
  };

  const onFormCheck = () => {
    if (!banner) {
      toastError(t('MAIN_EVENT_IMAGE') + t('IS_REQUIRED'));
      return false;
    }
    if (!title) {
      toastError(t('TITLE') + t('IS_REQUIRED'));
      return false;
    }
    if (!country) {
      toastError(t('COUNTRY') + t('IS_REQUIRED'));
      return false;
    }
    if (!startDate) {
      toastError(t('DATE_OF_THE_EVENT') + t('IS_REQUIRED'));
      return false;
    }
    if (!endDate) {
      toastError(t('DATE_OF_THE_EVENT') + t('IS_REQUIRED'));
      return false;
    }
    if (!address) {
      toastError(t('ADDRESS_OF_THE_EVENT') + t('IS_REQUIRED'));
      return false;
    }
    if (!website) {
      toastError(t('WEBSITE') + t('IS_REQUIRED'));
      return false;
    }
    if (!desc) {
      toastError(t('DESCRIPTION') + t('IS_REQUIRED'));
      return false;
    }
    return true;
  };

  const onFinish = async () => {
    if (!onFormCheck()) {
      return;
    }
    let pms = {
      userId: user.userId,
      coId: user.userInfo.companyId,
      title: title,
      description: desc,
      picture: banner,
      country,
      address,
      website,
      startDate,
      endDate,
    };
    let data;
    if (id) {
      pms._id = id;
      data = await eventUpdate(pms);
      console.log("update result:", data, pms);
    } else {
      data = await eventCreate(pms);
    }
    if (data) {
      history.push("/my-events");
    }
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/events"}>{t("EVENTS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t('CREATE_EVENT')}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management modal-body">
        <div className="common-management-title">{t('CREATE_EVENT')}</div>
        <div className="create-article-container common-management-panel">
          <div className="modal-content-column">
            <div className="content-row-label">{t("MAIN_EVENT_IMAGE")}</div>
            <UploadNormal
              onLoading={onFileLoading.bind(this, 2)}
              onFileLoaded={onFileLoaded.bind(this)}
            >
              <ProductImageUpload
                // title={t("UPLOAD_PICTURES")}
                url={bannerUrl}
              />
            </UploadNormal>
          </div>
          <Row>
            <Col span={12}>
              <div className="modal-content-column">
                <div className="content-row-label">{t("NAME_OF_THE_EVENT")}</div>
                <Input
                  className="content-row-select"
                  placeholder={t("NAME_OF_THE_EVENT")}
                  value={title}
                  onChange={(val) => setTitle(val.target.value)}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="modal-content-column">
                <div className="content-row-label">{t("COUNTRY")}</div>
                <Select options={countryList}
                  className="content-row-select"
                  value={country}
                  placeholder={t("COUNTRY")}
                  onChange={(val) => setCountry(val)}
                >
                </Select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div className="modal-content-column">
                <div className="content-row-label">{t("DATE_OF_THE_EVENT")}</div>
                <div>
                  <DatePicker
                    value={startDate && moment(startDate, "YYYY-MM-DD")}
                    className="content-row-date"
                    onChange={(e, val) => setStartDate(val)}
                  />
                  <span className="content-row-date-middle-text">{t("TO")}</span>
                  <DatePicker
                    value={endDate && moment(endDate, "YYYY-MM-DD")}
                    className="content-row-date"
                    onChange={(e, val) => setEndDate(val)}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="modal-content-column">
                <div className="content-row-label">{t("ADDRESS_OF_THE_EVENT")}</div>
                <Input
                  className="content-row-select"
                  placeholder={t("ADDRESS_OF_THE_EVENT")}
                  value={address}
                  onChange={(val) => setAddress(val.target.value)}
                />
              </div>
            </Col>
          </Row>

          <div className="modal-content-column">
            <div className="content-row-label">{t("WEBSITE")}</div>
            <Input
              className="content-row-select"
              placeholder={t("WEBSITE")}
              value={website}
              onChange={(val) => setWebsite(val.target.value)}
            />
          </div>
          <div className="modal-content-column">
            <div className="content-row-label">{t("DESCRIPTION")}</div>
            {/* <RichTextAreaBasic
              value={desc}
              placeholder={t("PLEASE_ENTER_THE")}
              onInit={onDescChange.bind(this)}
            /> */}
            <TextArea rows={6} value={desc} placeholder={t("PLEASE_ENTER_THE")} onChange={(e) => setDesc(e.target.value)}></TextArea>
          </div>

          <div className="page-container">
            <Button
              onClick={onFinish.bind(this)}
              className="container-element-button-importer"
              role="button"
            >
              {t("CREATE")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
