import React, { createContext, useState, useContext, useEffect } from "react";
import { useUserId } from "./UserIdProvider";
import { basicPageFriends, applyFriendList } from "../api/contact";
import { getMeetings } from "../api/chat";
import { getFeedsCount } from "../api/feed";
import { getProductRatings, loadProducts } from "../api/product";
import { receivedCommentPage, receivedMentionPage } from "../api/feed";
import { getCarts } from "../api/carts";
import { getArticles } from "../api/article";
import { CompanyVerifyStatusEnum } from "../constants/companyVerifyStatus.enum";
import { getUserTenderList } from "../api/tender";
import { TableSourceMaxCount } from "../constants/constants";
import { getProductAwards } from "../api/user";
import { getOrders } from "../api/orders";

const stateContext = createContext();

export function DataProvider({ children }) {
  const { isWinery, user } = useUserId();
  const [userId, setUserId] = useState();;
  const [companyId, setCompanyId] = useState();;
  const [contactNum, setContactNum] = useState(0);
  const [feedNum, setFeedNum] = useState({ total: 0, images: 0, video: 0 });
  const [productNum, setProductNum] = useState(0);
  const [productList, setProductList] = useState([]);
  const [notifyMentionNum, setNotifyMentionNum] = useState(0);
  const [notifyCommentNum, setNotifyCommentNum] = useState(0);
  const [notifyFriendApplyNum, setNotifyFriendApplyNum] = useState(0);
  const [notifyArticleNum, setNotifyArticleNum] = useState(0);
  const [notifyCompanyVerifyNum, setNotifyCompanyVerifyNum] = useState(0);
  const [notifyTenderNum, setNotifyTenderNum] = useState(0);
  const [notifyProductNum, setNotifyProductNum] = useState(0);
  const [notifyProductAwardNum, setNotifyProductAwardNum] = useState(0);
  const [notifyProductRatingNum, setNotifyProductRatingNum] = useState(0);
  const [notifyOrderNum, setNotifyOrderNum] = useState(0);
  const [meetings, setMeetings] = useState([]);
  const [poiNum, setPoi] = useState(0);

  useEffect(() => {
    if (user && user.userInfo) {
      setUserId(user.userId);
      setCompanyId(user.userInfo.companyId);
      let { companyInfo } = user.userInfo;
      if (companyInfo && companyInfo.length > 0) {
        let { status, isRead } = companyInfo[0];
        if ((status === CompanyVerifyStatusEnum.VERIFIED || status === CompanyVerifyStatusEnum.VERIFY_FAILED) && !isRead) {
          setNotifyCompanyVerifyNum(1);
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (userId && companyId) {
      loadContacts();
      loadFeedCount();
      loadMeetings();
      initNotifyCount();
      loadOrderCount();
      if (isWinery) {
        loadProductCount();
        initNotifyCount1();
      } else {
        loadPois();
      }
    }
  }, [userId, companyId]);

  const loadPois = async () => {
    let pms = { userId, page: 0, per_page: 20 };
    let result = await getCarts(pms);
    if (result && result.total > 0) {
      let list = result.result.filter((item) => !(item.productInfo && item.productInfo.length > 0 && item.productInfo[0].companyInfo));
      setPoi(result.total - list.length);
    }
  };

  const loadContacts = async () => {
    let pms = { userId };
    let result = await basicPageFriends(pms);    
    // console.log('-------basicpagefriends:',result);
    let value = result ? result.total : 0;
    setContactNum(value);
  };

  const loadFeedCount = async () => {
    let pms = { userId };
    let result = await getFeedsCount(pms);
    if (result) {
      setFeedNum(result);
    }
  };

  const loadOrderCount = async () => {
    let pms = { page: 0, per_page: TableSourceMaxCount, isRead: '-1' };
    if (isWinery) {
      pms.coId = companyId;
    } else {
      pms.userId = userId;
    }
    let result = await getOrders(pms);
    if (result) {
      setNotifyOrderNum(result.length);
    }
  };

  const loadProductCount = async () => {
    let pms = { page: 0, per_page: 10, coId: companyId };
    let result = await loadProducts(pms);
    let value = result ? result.length : 0;
    setProductNum(value);
    result?.length ? setProductList([...result]) : setProductList([]);
  };

  const loadMeetings = async () => {
    let pms = { userId };
    let result = await getMeetings(pms);
    // console.log("-----load meetings:", result);
    if (result && result.length > 0) {
      setMeetings(result);
    }
  };

  const updateMeetings = () => {
    loadMeetings();
  };

  const initNotifyCount = async () => {
    let pms = { userId, page: 0, per_page: 20, status: 0 };
    let data = await receivedMentionPage(pms);
    let num = data ? data.total : 0;
    setNotifyMentionNum(num);

    data = await receivedCommentPage(pms);
    num = data ? data.total : 0;
    setNotifyCommentNum(num);

    data = await applyFriendList(pms);
    num = data ? data.total : 0;
    setNotifyFriendApplyNum(num);

    pms = { userId, page: 0, per_page: 10, isRead: '-1' };
    data = await getArticles(pms);
    num = data ? data.total : 0;
    setNotifyArticleNum(num);

    data = await getUserTenderList(pms);
    num = data ? data.total : 0;
    setNotifyTenderNum(num);
  };

  const initNotifyCount1 = async () => {
    let pms = { coId: companyId, page: 0, per_page: TableSourceMaxCount, isRead: '-1' };
    let result = await loadProducts(pms);
    let num = result && result.length > 0 ? result.length : 0;
    setNotifyProductNum(num);

    pms = { coId: companyId, page: 0, per_page: 10, isRead: '-1' };
    let data = await getProductRatings(pms);
    num = data ? data.total : 0;
    setNotifyProductRatingNum(num);

    data = await getProductAwards(pms);
    num = data ? data.total : 0;
    setNotifyProductAwardNum(num);
  }

  const updateNotifyMentionNum = (num) => {
    setNotifyMentionNum(num);
  };

  const updateNotifyCommentNum = (num) => {
    setNotifyCommentNum(num);
  };

  const updateNotifyFriendApplyNum = (num) => {
    setNotifyFriendApplyNum(num);
  };

  const updateNotifyArticlNum = (num) => {
    setNotifyArticleNum(num);
  };

  const updateNotifyCompanyVerifyNum = (num) => {
    setNotifyCompanyVerifyNum(num);
  };

  const updateNotifyTenderNum = (num) => {
    setNotifyTenderNum(num);
  };

  const updateNotifyProductNum = (num) => {
    setNotifyProductNum(num);
  };

  const updateNotifyProductAwardNum = (num) => {
    setNotifyProductAwardNum(num);
  };

  const updateNotifyProductRatingNum = (num) => {
    setNotifyProductRatingNum(num);
  };

  const updateNotifyOrderNum = (num) => {
    setNotifyOrderNum(num);
  };

  const updatePoiNum = (num) => {
    setPoi(num);
  };

  return (
    <stateContext.Provider
      value={{
        contactNum,
        feedNum,
        productNum,
        productList,
        notifyMentionNum,
        notifyCommentNum,
        notifyFriendApplyNum,
        notifyArticleNum,
        notifyCompanyVerifyNum,
        notifyTenderNum,
        notifyProductNum,
        notifyProductAwardNum,
        notifyProductRatingNum,
        notifyOrderNum,
        meetings,
        poiNum,
        updateNotifyMentionNum,
        updateNotifyCommentNum,
        updateNotifyFriendApplyNum,
        updateNotifyArticlNum,
        updateNotifyCompanyVerifyNum,
        updateNotifyTenderNum,
        updateNotifyProductNum,
        updateNotifyProductAwardNum,
        updateNotifyProductRatingNum,
        updateNotifyOrderNum,
        updateMeetings,
        updatePoiNum,
      }}
    >
      {children}
    </stateContext.Provider>
  );
}

export function useContextData() {
  return useContext(stateContext);
}
