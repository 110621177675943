import {
  actionRequest,
  formatActionResponseArray,
  formatResponse,
} from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";
import { requestGetUrlFormat } from "./common";
import { UserType } from "../constants/usersType.enum";

/**
 * Save the company description for an importer
 * @param pms
 */
export async function saveCompanyInfo(pms: any) {
  return actionRequest(RequestType.POST, apiPath.saveCompanyInfo, pms);
}

/**
 * Add the company description for an importer
 * @param pms
 */
export async function addCompanyInfo(pms: any) {
  return actionRequest(RequestType.POST, apiPath.addCompanyInfo, pms);
}

export async function removeCompanyInfo(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.removeCompanyInfo, pms)
  );
}

/**
 * @description Get the company description of a winery
 * @param pms
 */
export async function companyWineryDesc(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.wineryDescInfo, pms)
  );
}

/**
 * @description Get the company description of a media
 * @param pms
 */
export async function companyMediaDesc(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.mediaDescInfo, pms)
  );
}

/**
 * @description Get the company description of an importer
 * @param pms
 */
export async function companyImporterDesc(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.importerDescInfo, pms)
  );
}

/**
 * @description Get the company details
 * @param coId Company ID
 */
export async function companyDetail(coId: string) {
  let data = await actionRequest(
    RequestType.GET,
    apiPath.companyInfo + "/" + coId
  );
  return formatResponse(data);
}

/**
 * @description Get the company users
 * @param coId Company ID
 */
export async function getCompanyUsers(pms: any) {
  let data = await actionRequest(RequestType.GET, apiPath.companyUsers, pms);
  return formatResponse(data);
}

/**
 * @description Get the company users
 * @param coId Company ID
 */
export async function getCompanyMainUser(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.companyMainUser, pms)
  );
}

/**
 * @description Get the company details
 * @param coId Company ID
 */
export async function staffList(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.staffList, pms)
  );
}

/**
 * Save the company description for a winery
 * @param pms
 */
export async function createStaff(pms: any) {
  return actionRequest(RequestType.POST, apiPath.createStaff, pms);
}

/**
 *
 * @param pms
 */
export async function removeStaff(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.removeStaff, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function updateStaff(pms: any) {
  return await actionRequest(RequestType.POST, apiPath.updateStaff, pms);
}

/**
 *
 * @param pms
 * @param userType
 */
export async function getCompanyDescFromUserType(pms: any, userType: UserType) {
  let data;

  switch (userType) {
    case UserType.SUPPLIER:
      data = await companyWineryDesc(pms);
      break;
    case UserType.BUYER:
      data = await companyImporterDesc(pms);
      break;
    case UserType.SUPPLIERBUYER:
      data = await companyImporterDesc(pms);
      break;
    default:
      console.log("CANNOT_FIND_COMPANY_DESC");
      break;
  }

  return formatResponse(data);
}

/**
 * Save the company description for an importer
 * @param pms
 */
export async function companyBankInfoSave(pms: any) {
  return actionRequest(RequestType.POST, apiPath.saveBankInfo, pms);
}

/**
 * @description Get the company description of a winery
 * @param pms
 */
export async function companyBankInfo(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.bankInfo, pms)
  );
}

/**
 * @description Get the company description of a winery
 * @param pms
 */
export async function companyInvoiceInfo(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.companyInvoiceInfo, pms)
  );
}

/**
 *
 * @param pms
 */
export function companyInvoiceInfoUpdate(pms: any) {
  return actionRequest(RequestType.POST, apiPath.CompanyInvoiceInfoUpdate, pms);
}

/**
 * Save the company description for a winery
 * @param pms
 */
export async function companyBrandSafetyCreate(pms: any) {
  return actionRequest(RequestType.POST, apiPath.companyBrandSafetyCreate, pms);
}

/**
 * @description Get the company description of a winery
 * @param pms
 */
export async function companyBrandSafetyList(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.companyBrandSafetyList, pms)
  );
}
