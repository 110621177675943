import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigProvider } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HeaderPrivateRoute from "./private-route/HeaderPrivateRoute";
import BasePrivateRoute from "./private-route/BasePrivateRoute";
import ProductCreate from "./shop/product/ProductCreate";
import MenuPrivateRoute from "./private-route/MenuPrivateRoute";
import Products from "./shop/product/Products";
import ProductsDraft from "./dashboard/draft/products/ProductsDraft";
import DemandOfferCreate from "./tender/DemandOfferCreate";
import MyContacts from "./contact/MyContacts";
import Address from "./company/address/Address";
import Invoice from "./company/invoice/Invoice";
import Awards from "./shop/award/Awards";
import HighRating from "./shop/high-rating/HighRating";
import HightRatingCreate from "./shop/high-rating/HightRatingCreate";
import Notes from "./shop/note/Note";
import NoteCreate from "./shop/note/NoteCreate";
import Discounts from "./shop/discount/Discounts";
import IMChat from "./imchat/IMChat";
import Login from "./login/Login";
import ForgotPwd from "./others/ForgotPwd";
import Notify from "./notify/Notify";
import UserProfile from "./users/info/UserProfile";
// import ProductInfo from "./shop/product/ProductInfo";
import ShoppingList from "./shop/shopping-cart/ShoppingList";
import OrderConfirm from "./shop/order/OrderConfirm";
import Orders from "./shop/order/Orders";
import OrderInfo from "./shop/order/OrderInfo";
import BrandRegister from "./service/BrandRegister";
import LabelDesign from "./service/LabelDesign";
import PackageDesign from "./service/PackageDesign";
import Tools from "./tools/Tools";
import StaffManagement from "./shop/staff/StaffManagement";
import TenderCreate from "./tender/TenderCreate";
import Dashboard from "./shared/dashboard/Dashboard";
import { useUserId } from "../contexts/UserIdProvider";
import Search from "./search/Search";
import CompanyVerify from "./company/verify/CompanyVerify";
import CompanySettings from "./company/CompanySettings";
import PersonalInfo from "./user-settings/PersonalInfo";
import PasswordManagement from "./user-settings/PasswordManagement";
import WxNativePay from "./shop/order/WxNativePay";
import CloseAccount from "./user-settings/closeAccount";
import OrderSuccess from "./shop/order/OrderSuccess";
import NotFound from "./others/NotFound";
import BrowseRecord from "./browse-record/BrowseRecord";
import ProductBrowseRecord from "./browse-record/ProductBrowseRecord";
import UserBrowseRecord from "./browse-record/UserBrowseRecord";
import FeedBrowseRecord from "./browse-record/FeedBrowseRecord";
import MyCalendar from "./shared/calendar/MyCalendar";
import MyPoint from "./point/MyPoint";
import ClientList from "./shop/client-list/ClientList";
import AllianceList from "./users/importer/AllianceList";
import Samples from "./shop/samples/Samples";
import Wallet from "./shared/wallet/Wallet";
import Settings from "./support/Settings";
import MySubscription from "./shared/subscription/MySubscription";
import SupportCenter from "./support/SupportCenter";
import Leads from "./shared/lead/Leads";
import DistributorList from "./users/importer/DistributorList";
import Article from "./article/article";
import ArticleEditor from "./article/ArticleEditor";
import ArticleShow from "./article/articleShow";
import zhCN from "antd/lib/locale/zh_CN";
import frFR from "antd/lib/locale/fr_FR";
import enUS from "antd/lib/locale/en_US";
import TenderInfo from "./tender/TenderInfo";
import SubscriptionWxPay from "./users/Subscription/SubscriptionWxPay";
import SubscriptionSuccess from "./users/Subscription/SubscriptionSuccess";
import CompanyReturn from "./company/verify/CompanyReturn";
import Explore from "./explore/Explore";
import ExploreFranceWineryRegion from "./explore/region/ExploreFranceWineryRegion";
import ExploreChineseImporterRegion from "./explore/region/ExploreChineseImporterRegion";
import Empty from "./common/empty/Empty";
import Reg from "./register/Reg";
import RegIntro from "./register/RegIntro";
import SubscriptionLogged from "./users/Subscription/SubscriptionLogged";
import ShopApply from "./shop/apply/ShopApply";
import MediaPage from "./users/media/MediaPage";
import Tenders from "./tender/Tenders";
import Privacy from "./others/Privacy";
// import Home from "./home/home";
import Feed from "./feed/Feed";
import FavoriteProducts from "./shop/favorite/favoriteProducts";
import CompanyFavorites from "./shop/favorite/companyFavorites";
import ProductInCartCompanys from "./shop/shopping-cart/productInCartCompanys";
import Discover from "./feed/discover/Discover";
import EventCreate from "./event/eventCreate";
import MyEvents from "./event/myEvents";
// import Events from "./event/events";
import PublicRoute from "./private-route/PublicRoute";
import ProductRatings from "./shop/rating/ProductRatings";
import MyFollowers from "./contact/MyFollowers";
import ReadMine from "./contact/ReadMine";
import MyLikeFeeds from "./contact/MyLikeFeeds";
import { SYS_ENV_LANGUAGE } from "../constants/env.enum";
import Activities from "./activity/activities";
import DiscoverActivities from "./feed/discover/DiscoverActivities";
import TestMarin from "./test/TestMarin";
import MyFollowings from "./contact/MyFollowings";
import CompanyAccount from "./company/account/CompanyAccount";
import SendSamples from "./shop/samples/SendSamples";
import OperationManagement from "./dashboard/operation/OperationManagement";
import OperationScheduleCreate from "./dashboard/operation/OperationScheduleCreate";
import WebsiteRoute from "./private-route/WebsiteRoute";
import StaffCreate from "./shop/staff/StaffCreate";
import PasswordSetting1 from "./company/password-setting/PasswordSetting";
import BankInfoSetting from "./company/bank-info/BankInfoSetting";
import OperationCreateIntro from "./dashboard/operation/OperationCreateIntro";
import LeftWidthRoute from "./private-route/LeftWidthRoute";
import SavedList from "./feed/saved/savedList";
import FeedsDraft from "./dashboard/draft/feeds/FeedsDraft";
import SupplierOrders from "./shop/order/supplier/orders/SupplierOrders";
import CompanyInvoiceSetting from "./company/invoice/CompanyInvoiceSetting";
import SupplierDocuments from "./shop/order/supplier/documents/SupplierDocuments";
import BrandManagement from "./dashboard/brand/BrandManagement";
import BrandCreate from "./dashboard/brand/BrandCreate";
import WarehouseSupplier from "./shop/warehouse/Warehouse";
import Warehouse from "./dashboard/warehouse/Warehouse";
import NotificationSetting from "./dashboard/notification/NotificationSetting";
import PrivacyPolicy from "./dashboard/policy/privacy-policy/PrivacyPolicy";
import CookiePolicy from "./dashboard/policy/cookie-policy/CookiePolicy";
import MyAssets from "./dashboard/assets/MyAssets";
import BuyerDocuments from "./dashboard/documents/BuyerDocuments";
import TestShopify from "./test/TestShopify";
import TestCloudflare from "./test/TestCloudflare";
import TestAmityCo from "./test/TestAmityCo";
import DigitalTemplateRoute from "./private-route/DigitalTemplateRoute";
import DashboardProducts from "../screens/dashboard/Supplier/Products";
import DashboardNewProduct from "../screens/dashboard/Supplier/NewProduct";
import DashboardSupplierOrders from "../screens/dashboard/Supplier/Orders";
import DashboardBankInfo from "../screens/dashboard/Supplier/BankInfo";
import DashboardWarehouses from "../screens/dashboard/Supplier/Warehouse";
import CompInfo from "../screens/CompInfo";
import { Path } from "../constants/path";
import Home from "../screens/Home";
import CompanyInformation from "../screens/dashboard/Supplier/CompanyInformation";
import Messaging from "../screens/Messaging";
import InstantMessaging from "../screens/InstantMessaging";
import SignIn from "../screens/SignIn";
import PasswordSetting from "../screens/Setting/Security";
import Developing from "../screens/Developing";
import ForgotPassword from "../screens/ForgotPassword";
import ResetPassword from "../screens/ResetPassword";
import SignUp from "../screens/SignUp";
import ShopSearch from "../screens/ShopSearch";
import Events from "../screens/Events";
import ProductsDashboard from "../core-digital-template/screens/ProductsDashboard";
import Notification from "../screens/Notification";
import ProfileTemplateRoute from "./private-route/ProfileTemplateRoute";
import SupplierList from "../screens/SupplierList";
import VinbipAddOrUpdateSupplier from "../screens/SupplierList/VinbipAddOrUpdateSupplier";
import WebsiteCompInfo from "../screens/WebsiteCompInfo";
import LanguageSetting from "../screens/LanguageSetting";
import WechatBinding from "../screens/WechatBinding";
import ProductInfo from "../screens/ProductInfo";
import BuyerList from "../screens/BuyerList";
import PartnerSearch from "../screens/PartnerSearch";
import RecommendUsers from "../screens/RecommendUsers";
import ScrollToTop from "./ScrollToTop";
import DashboardStock from "./shop/stocks/legacy-deprecated/DashboardStock";
import StockCreate from "./shop/stocks/legacy-deprecated/StockCreate";
import ProductStock from "../screens/dashboard/Supplier/ProductStock";

export default function VinexworkRouter() {
  const { i18n, t } = useTranslation();
  const { user } = useUserId();
  const { userInfo } = user;

  let [antdLanguage, setAntdLanguage] = useState(zhCN);

  useEffect(() => {
    let _languages = SYS_ENV_LANGUAGE;
    if (userInfo && userInfo.language) {
      _languages = userInfo.language;
    }
    i18n.changeLanguage(_languages);
  }, []);

  useEffect(() => {
    let _languages = i18n.language;
    if (_languages === "en") {
      setAntdLanguage(enUS);
    } else if (_languages === "fr") {
      setAntdLanguage(frFR);
    } else if (_languages === "zh") {
      setAntdLanguage(zhCN);
    }
  }, [i18n.language]);

  return (
    <Router>
      <div className="App">
        <ConfigProvider renderEmpty={Empty} locale={antdLanguage}>
          <Switch>
            {/* <HeaderPublicRoute exact path="/" component={Home} /> */}
            <DigitalTemplateRoute exact path="/" component={Home} />
            <WebsiteRoute exact path="/feed-deprecated" component={Feed} />
            <WebsiteRoute
              exact
              path={Path.emailVerify}
              component={CompanyReturn}
            />
            <LeftWidthRoute
              exact
              path="/discover-deprecated"
              component={Discover}
              default={true}
            />
            <HeaderPrivateRoute
              exact
              path="/product/activities/:id"
              component={DiscoverActivities}
            />
            <HeaderPrivateRoute
              exact
              path="/demandoffers/create"
              component={DemandOfferCreate}
            />

            <HeaderPrivateRoute
              exact
              path="/tender/create"
              component={TenderCreate}
            />
            <HeaderPrivateRoute
              exact
              path="/tender/info"
              component={TenderInfo}
            />

            <MenuPrivateRoute
              exact
              path="/shop/products-deprecated"
              component={Products}
            />
            <MenuPrivateRoute
              exact
              path="/products/draft"
              component={ProductsDraft}
            />
            <MenuPrivateRoute
              exact
              path="/feeds/draft"
              component={FeedsDraft}
            />
            <MenuPrivateRoute
              exact
              path="/shop/products/create-deprecated"
              component={ProductCreate}
            />
            <MenuPrivateRoute exact path="/shop/awards" component={Awards} />
            <MenuPrivateRoute
              exact
              path="/shop/ratings"
              component={ProductRatings}
            />
            <MenuPrivateRoute
              exact
              path="/shop/rating_list"
              component={HighRating}
            />
            <BasePrivateRoute
              exact
              path="/shop/hight-rating/create"
              component={HightRatingCreate}
            />
            <MenuPrivateRoute exact path="/shop/notes" component={Notes} />
            <BasePrivateRoute
              exact
              path="/shop/notes/create"
              component={NoteCreate}
            />
            <MenuPrivateRoute
              exact
              path="/shop/discounts"
              component={Discounts}
            />
            <MenuPrivateRoute exact path="/shop/orders/" component={Orders} />
            <MenuPrivateRoute
              exact
              path="/shop/orders/supplier-deprecated"
              component={SupplierOrders}
            />
            <MenuPrivateRoute
              exact
              path="/shop/documents/supplier"
              component={SupplierDocuments}
            />
            <MenuPrivateRoute
              exact
              path="/shop/documents/buyer/:index"
              component={BuyerDocuments}
            />
            <MenuPrivateRoute
              exact
              path="/dashboard-deprecated/operation-management/"
              component={OperationManagement}
            />
            <MenuPrivateRoute
              exact
              path="/dashboard-deprecated/operation-create-intro/"
              component={OperationCreateIntro}
            />
            <MenuPrivateRoute
              exact
              path="/dashboard-deprecated/operation-create/"
              component={OperationScheduleCreate}
            />
            <MenuPrivateRoute
              exact
              path="/dashboard-deprecated/my-assets"
              component={MyAssets}
            />
            <MenuPrivateRoute
              exact
              path="/dashboard-deprecated/privacy-policy"
              component={PrivacyPolicy}
            />
            <MenuPrivateRoute
              exact
              path="/dashboard-deprecated/cookie-policy"
              component={CookiePolicy}
            />
            <MenuPrivateRoute
              exact
              path="/shop/orders/:index"
              component={Orders}
            />
            <HeaderPrivateRoute
              exact
              path="/shopping-cart"
              component={ShoppingList}
            />
            <MenuPrivateRoute
              exact
              path="/shop/favoriteproducts"
              component={FavoriteProducts}
            />
            <MenuPrivateRoute
              exact
              path="/shop/companyfavorites"
              component={CompanyFavorites}
            />
            <MenuPrivateRoute
              exact
              path="/shop/productincartcompanys"
              component={ProductInCartCompanys}
            />
            <HeaderPrivateRoute exact path="/search" component={Search} />
            <HeaderPrivateRoute exact path="/explore" component={Explore} />
            <HeaderPrivateRoute
              exact
              path="/explore/winery/france"
              component={ExploreFranceWineryRegion}
            />
            <HeaderPrivateRoute
              exact
              path="/explore/importer/china"
              component={ExploreChineseImporterRegion}
            />
            <HeaderPrivateRoute
              exact
              path="/products/detail-deprecated/:id"
              component={ProductInfo}
              default={true}
            />

            <HeaderPrivateRoute
              exact
              path="/purchase/order/confirm/:params"
              component={OrderConfirm}
            />
            <HeaderPrivateRoute
              exact
              path="/purchase/order/confirm"
              component={OrderConfirm}
            />
            <HeaderPrivateRoute
              exact
              path="/order/wxpay"
              component={WxNativePay}
            />
            <HeaderPrivateRoute
              exact
              path="/order/success"
              component={OrderSuccess}
            />
            <HeaderPrivateRoute
              exact
              path="/profile-deprecated/:id"
              component={UserProfile}
            />
            <HeaderPrivateRoute exact path="/media/:id" component={MediaPage} />
            <MenuPrivateRoute exact path="/contacts" component={MyContacts} />
            <MenuPrivateRoute exact path="/followers" component={MyFollowers} />
            <MenuPrivateRoute
              exact
              path="/followusers"
              component={MyFollowings}
            />
            <MenuPrivateRoute exact path="/readmines" component={ReadMine} />
            <MenuPrivateRoute
              exact
              path="/mylikefeeds"
              component={MyLikeFeeds}
            />
            <BasePrivateRoute
              exact
              path="/service/brand-registration"
              component={BrandRegister}
            />
            <BasePrivateRoute
              exact
              path="/service/label-design"
              component={LabelDesign}
            />
            <BasePrivateRoute
              exact
              path="/service/package-design"
              component={PackageDesign}
            />
            <BasePrivateRoute exact path="/discover/tools" component={Tools} />
            <MenuPrivateRoute
              exact
              path="/company/settings-deprecated"
              component={CompanySettings}
            />
            <MenuPrivateRoute
              exact
              path="/company/bankInfoSetting-deprecated"
              component={BankInfoSetting}
            />
            <MenuPrivateRoute
              exact
              path="/company/password-setting-deprecated"
              component={PasswordSetting1}
            />
            <MenuPrivateRoute
              exact
              path="/company/company-invoice-setting-deprecated"
              component={CompanyInvoiceSetting}
            />
            <MenuPrivateRoute
              exact
              path="/company/account"
              component={CompanyAccount}
            />
            <MenuPrivateRoute
              exact
              path="/company/address"
              component={Address}
            />
            <MenuPrivateRoute
              exact
              path="/company/invoice"
              component={Invoice}
            />
            <MenuPrivateRoute
              exact
              path="/company/verify-deprecated"
              component={CompanyVerify}
            />
            <MenuPrivateRoute
              exact
              path="/company/staff-deprecated"
              component={StaffManagement}
            />
            <MenuPrivateRoute
              exact
              path="/company/brand-deprecated"
              component={BrandManagement}
            />
            <MenuPrivateRoute
              exact
              path="/company/brand/create"
              component={BrandCreate}
            />
            <MenuPrivateRoute
              exact
              path="/company/create"
              component={StaffCreate}
            />
            <MenuPrivateRoute
              exact
              path="/company/warehouse/supplier-deprecated"
              component={WarehouseSupplier}
            />
            <MenuPrivateRoute
              exact
              path="/company/warehouse"
              component={Warehouse}
            />
            <MenuPrivateRoute
              exact
              path="/company/notification/setting/:index"
              component={NotificationSetting}
            />
            <MenuPrivateRoute
              exact
              path="/company/order"
              component={OrderInfo}
            />
            <HeaderPrivateRoute
              exact
              path="/imchat-deprecated"
              component={IMChat}
            />
            <HeaderPrivateRoute exact path="/notify" component={Notify} />
            <MenuPrivateRoute
              exact
              path="/usercenter/me"
              component={PersonalInfo}
            />
            <MenuPrivateRoute
              exact
              path="/usercenter/change-password"
              component={PasswordManagement}
            />
            {/* TO DO:deprecated */}
            <MenuPrivateRoute
              exact
              path="/dashboard-deprecated"
              component={Dashboard}
            />
            <DigitalTemplateRoute
              exact
              path={Path.feed}
              component={Home}
              pageTitle={t("HOME")}
            />
            <DigitalTemplateRoute
              exact
              path={Path.recommendUsers}
              component={RecommendUsers}
              pageTitle={t("RECOMMEND")}
            />
            <DigitalTemplateRoute
              exact
              path={Path.partners}
              component={PartnerSearch}
              pageTitle={t("MEET_PARTNERS")}
            />
            <DigitalTemplateRoute
              exact
              path={Path.dashboard}
              component={ProductsDashboard}
              pageTitle={t("DASHBOARD")}
            />
            <DigitalTemplateRoute
              exact
              path={Path.products}
              component={DashboardProducts}
              pageTitle={t("PRODUCT_MANAGEMENT")}
            />
            <DigitalTemplateRoute
              exact
              path={Path.productsAdd}
              component={DashboardNewProduct}
              pageTitle={t("CREATE_PRODUCT")}
            />
            <DigitalTemplateRoute
              exact
              path={Path.orders}
              component={DashboardSupplierOrders}
              pageTitle={t("ORDER_MANAGEMENT")}
            />
            <DigitalTemplateRoute
              exact
              path={Path.supplierBankInfo}
              component={DashboardBankInfo}
              pageTitle={t("BANK_INFORMATION")}
            />
            <DigitalTemplateRoute
              exact
              path={Path.supplierWarehouse}
              component={DashboardWarehouses}
              pageTitle={t("SHIPPING_LOCATIONS")}
            />
            <DigitalTemplateRoute
              exact
              path={Path.supplierStock}
              component={ProductStock}
              pageTitle={t("STOCKS")}
            />
            <DigitalTemplateRoute
              exact
              path={Path.supplierStockAdd}
              component={StockCreate}
              pageTitle={t("STOCKS")}
            />
            <ProfileTemplateRoute
              exact
              path={Path.companyDetail}
              component={WebsiteCompInfo}
            />
            <ProfileTemplateRoute
              exact
              path={Path.productInfo}
              component={ProductInfo}
            />
            <ProfileTemplateRoute
              exact
              path={Path.productAdd}
              component={ProductInfo}
            />
            <WebsiteRoute
              exact
              path={Path.visitorCompanyDetail}
              component={WebsiteCompInfo}
            />
            <DigitalTemplateRoute exact path={Path.events} component={Events} />
            <DigitalTemplateRoute
              exact
              path={Path.shopSearch}
              component={ShopSearch}
            />
            <DigitalTemplateRoute
              exact
              path={Path.messaging}
              // component={Messaging}
              component={InstantMessaging}
            />
            <DigitalTemplateRoute
              exact
              path={Path.instantMessaging}
              component={InstantMessaging}
            />
            <DigitalTemplateRoute
              exact
              path={Path.companyInformation}
              component={VinbipAddOrUpdateSupplier}
            />
            <DigitalTemplateRoute
              exact
              path={Path.companyInvoiceSetting}
              component={CompanyInvoiceSetting}
            />
            <DigitalTemplateRoute
              exact
              path={Path.companyVerify}
              component={CompanyVerify}
            />
            <DigitalTemplateRoute
              exact
              path={Path.brandSafety}
              component={BrandManagement}
            />
            <DigitalTemplateRoute
              exact
              path={Path.settingsUsers}
              component={StaffManagement}
            />
            <DigitalTemplateRoute
              exact
              path={Path.settingsSecurity}
              component={PasswordSetting}
            />
            <DigitalTemplateRoute
              exact
              path={Path.languageSetting}
              component={LanguageSetting}
            />
            <DigitalTemplateRoute
              exact
              path={Path.wechatBinding}
              component={WechatBinding}
            />
            <DigitalTemplateRoute
              exact
              path={Path.settingsPrivacy}
              component={PrivacyPolicy}
            />
            <DigitalTemplateRoute
              exact
              path={Path.settingsNotifications}
              component={OperationManagement}
            />
            <DigitalTemplateRoute
              exact
              path={Path.notifications}
              component={Notification}
            />
            <DigitalTemplateRoute
              exact
              path={Path.vinbipSupplierList}
              component={SupplierList}
            />
            <DigitalTemplateRoute
              exact
              path={Path.vinbipAddOrUpdateSuplier}
              component={VinbipAddOrUpdateSupplier}
            />
            <DigitalTemplateRoute
              exact
              path={Path.vinbipBuyerList}
              component={BuyerList}
            />
            <Route exact path={Path.signIn} component={SignIn} />
            <Route exact path={Path.signUp} component={SignUp} />
            <Route
              exact
              path={Path.forgotPassword}
              component={ForgotPassword}
            />
            <Route exact path={Path.resetPassword} component={ResetPassword} />
            <DigitalTemplateRoute
              exact
              path={Path.developing}
              component={Developing}
            />
            <DigitalTemplateRoute exact component={NotFound} />
            <MenuPrivateRoute
              exact
              path="/usercenter/close-account"
              component={CloseAccount}
            />
            <MenuPrivateRoute exact path="/calendar" component={MyCalendar} />
            <MenuPrivateRoute exact path="/my-points" component={MyPoint} />
            <MenuPrivateRoute exact path="/my-news" component={Article} />
            <HeaderPrivateRoute
              exact
              path="/article/create"
              component={ArticleEditor}
            />
            <MenuPrivateRoute
              exact
              path="/article/edit/:id"
              component={ArticleEditor}
            />
            <MenuPrivateRoute
              exact
              path="/article/show/:id"
              component={ArticleShow}
            />
            <MenuPrivateRoute
              exact
              path="/client-list"
              component={ClientList}
            />
            <MenuPrivateRoute
              exact
              path="/alliance-list"
              component={AllianceList}
            />
            {/* <BasePrivateRoute exact path="/events" component={Events} /> */}
            <MenuPrivateRoute exact path="/my-events" component={MyEvents} />
            <MenuPrivateRoute
              exact
              path="/event/create"
              component={EventCreate}
            />
            <MenuPrivateRoute
              exact
              path="/shop/sendsamples"
              component={SendSamples}
            />
            <MenuPrivateRoute exact path="/shop/samples" component={Samples} />
            <MenuPrivateRoute exact path="/shop/apply" component={ShopApply} />
            <MenuPrivateRoute exact path="/my-tenders" component={Tenders} />
            <MenuPrivateRoute exact path="/activities" component={Activities} />
            <MenuPrivateRoute exact path="/company/wallet" component={Wallet} />
            <MenuPrivateRoute
              exact
              path="/usercenter/my-subscription"
              component={MySubscription}
            />
            <MenuPrivateRoute exact path="/help/guide" component={Settings} />
            <MenuPrivateRoute exact path="/support" component={SupportCenter} />
            <MenuPrivateRoute exact path="/leads" component={Leads} />
            <MenuPrivateRoute
              exact
              path="/distributor-list"
              component={DistributorList}
            />
            <Route path="/sign-in-deprecated" component={Login} />
            {/* <Route path="/wxlogin" component={WxLogin} /> */}
            <Route path="/reg-intro" component={RegIntro} />
            <Route path="/forgot-password" component={ForgotPwd} />
            <WebsiteRoute
              exact
              sensitive
              path="/browse-record"
              component={BrowseRecord}
            />
            <WebsiteRoute
              exact
              sensitive
              path="/saved-list"
              component={SavedList}
            />
            <BasePrivateRoute
              path="/browse/product"
              component={ProductBrowseRecord}
            />
            <BasePrivateRoute
              path="/browse/user"
              component={UserBrowseRecord}
            />
            <BasePrivateRoute
              path="/browse/feed"
              component={FeedBrowseRecord}
            />
            <HeaderPrivateRoute
              exact
              path="/subscription-logged"
              component={SubscriptionLogged}
            />
            <HeaderPrivateRoute
              exact
              path="/subscription/wxpay"
              component={SubscriptionWxPay}
            />
            <HeaderPrivateRoute
              exact
              path="/subscription/success"
              component={SubscriptionSuccess}
            />
            <HeaderPrivateRoute
              exact
              path="/test-marin"
              component={TestMarin}
            />
            <HeaderPrivateRoute
              exact
              path="/test-shopify"
              component={TestShopify}
            />
            <HeaderPrivateRoute
              exact
              path="/test-cloudflare"
              component={TestCloudflare}
            />
            <HeaderPrivateRoute
              exact
              path="/test-amityco"
              component={TestAmityCo}
            />
            <HeaderPrivateRoute exact path="/privacy" component={Privacy} />
          </Switch>
        </ConfigProvider>
        <ScrollToTop />
      </div>
    </Router>
  );
}
