import React, { useState } from "react";
import cn from "classnames";
import styles from "./WebsitePage.module.sass";
import useDarkMode from "use-dark-mode";
import WebsiteSidebar from "../WebsiteSidebar";
import WebsiteHeader from "../WebsiteHeader";

const WebsitePage = ({ wide, children, title }) => {
    const [visible, setVisible] = useState(false);

    //const darkMode = {value: false};
    const darkMode = {value: false};

    return (
        <>
            <div className={styles.page}
                style={{ backgroundColor: darkMode.value ? "#0E1011" : "#F1F1F1" }}>
                <WebsiteSidebar
                    className={cn(styles.sidebar, {
                        [styles.visible]: visible,
                    })}
                    onClose={() => setVisible(false)}
                />
                <WebsiteHeader onOpen={() => setVisible(true)} />
                <div className={styles.inner}>
                    <div
                        className={cn(styles.container, {
                            [styles.wide]: wide,
                        })}
                    >
                        {title && (
                            <div className={cn("h3", styles.title)}>
                                {title}
                            </div>
                        )}
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default WebsitePage;
