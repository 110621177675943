import { Button } from "antd";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export default function ContentEllipsisPanel(props) {
    const { content } = props;
    const { t } = useTranslation();
    const contentDiv = useRef(null);
    const [showMore, setShowMore] = useState(false);
    const [needEllipsis, setNeedEllipsis] = useState(false);

    useEffect(() => {
        if (!content || !contentDiv.current) return;
        let element = contentDiv.current;
        let clientHeight = element.clientHeight;
        let scrollHeight = element.scrollHeight;
        if (clientHeight < scrollHeight) {
            setNeedEllipsis(true);
        } else {
            setNeedEllipsis(false);
        }
    }, [content]);

    return (
        <>
            <div ref={contentDiv} style={showMore ? { height: "auto" } : { maxHeight: "100px", overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: content }}></div>
            {needEllipsis && (
                <div className="feed-trans-button" onClick={() => { setShowMore(!showMore); }}>
                    <span>
                        {!showMore ? t("SHOW_MORE") : t("SHOW_LESS")}
                    </span>
                </div>
            )}
        </>
    );
}