import React, { useEffect, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  placeholder,
}) => {
  const [visible, setVisible] = useState(false);

  const [selectedLabel, setSelectedLabel] = useState("");

  useEffect(() => {
    if (options && options.length > 0 && value) {
      let temp = options.filter((x) => x.value == value);
      if (temp && temp.length > 0) {
        setSelectedLabel(temp[0].label);
      }
    } else if (!value) {
      setSelectedLabel("");
    }
  }, [options, value]);

  const handleClick = (value) => {
    setValue(value.value);
    setVisible(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>{selectedLabel || placeholder}</div>
        </div>
        <div
          className={cn(styles.body, { [styles.bodyUp]: upBody })}
          style={{ maxHeight: "300px", overflowY: "auto" }}
        >
          {options.map((x, index) => (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: x.value === value?.value,
              })}
              onClick={() => handleClick(x, index)}
              key={index}
            >
              {x.label}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
