import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Tabs,
  List,
  Menu,
  Slider,
  BackTop,
  Carousel,
  Select,
  Input,
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../../contexts/UserIdProvider";
import { constants, deepClone } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import alcoholIcon from "../../../resource/icons/discover/alcohol.svg";
import bottleIcon from "../../../resource/icons/discover/bottle.svg";
import bottlesizeIcon from "../../../resource/icons/discover/bottlesize.svg";
import stockIcon from "../../../resource/icons/discover/stock.svg";
import grapeIcon from "../../../resource/icons/discover/grape.svg";
import regionIcon from "../../../resource/icons/discover/region.svg";
import bannerImage1 from "../../../resource/images/discover/banner_1.jpg";
import bannerImage2 from "../../../resource/images/discover/banner_2.jpg";
import bannerImage3 from "../../../resource/images/discover/banner_3.jpg";
import languageIcon from "../../../resource/icons/navbar/language.svg";
import {
  getCountryForSelect,
  getLocationsByParentId,
} from "../../../api/config";
import {
  getProductCategoryTypeForSelect,
  getProductConfigForSelect,
  getProductConfigNameForSelect,
  getProductGrapes,
  getProductSearch,
  loadProductConfig,
} from "../../../api/product";
import ProductDiscoverItem from "../../shop/product/ProductDiscoverItem";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import {
  localizeTitle,
  ProductSampleValue,
  localizePriceUnit,
  localizeImg,
} from "../../../constants/constants";
import { searchImporterSearch } from "../../../api/search";
import { CardType } from "../../../constants/cardType.enum";
import "./style/discoverWinery.less";
import { ProductAlcoholSelectList } from "../../../constants/config";
import { RoleLimit } from "../../../constants/roleType.enum";
import { checkLimit } from "../../../api/common";
import { getCompanyVerifyStatus } from "../../../api/user";
import { useHistory, useParams } from "react-router-dom";
import { CompanyVerifyStatusEnum } from "../../../constants/companyVerifyStatus.enum";
import { toastInfo } from "../../../api/toast";
import { SysEnvLanguageType } from "../../../constants/env.enum";
import { getActivityList, getActivityDetail } from "../../../api/activity";
import SalonListRow from "../SalonListRow";
import { activityProducts } from "../../../api/activity";
import resetImg from "../../../resource/icons/discover/reset.svg";

export default function DiscoverActivities() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState(user?.userInfo);
  const coId = user.userInfo.companyId;
  const { TabPane } = Tabs;
  const [products, setProducts] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  const pageSize = 10;
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [locationList, setLocationList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [volumeList, setVolumeList] = useState([]);
  const [orgGrapeList, setOrgGrapeList] = useState([]);
  const [grapeList, setGrapeList] = useState([]);
  const [country, setCountry] = useState();
  const [region, setRegion] = useState();
  const [category, setCategory] = useState();
  const [type, setType] = useState();
  const [stock, setStock] = useState();
  const [grape, setGrape] = useState();
  const [volume, setVolume] = useState();
  const [alcohol, setAlcohol] = useState([0, 100]);
  const [countryName, setCountryName] = useState();
  const [typeName, setTypeName] = useState();
  const [stockName, setStockName] = useState();
  const [grapeKeyword, setGrapeKeyword] = useState();
  const [importerOptions, setImporterOptions] = useState([]);
  const [priceStep, setPriceStep] = useState(80);
  const [priceMax, setPriceMax] = useState(880);
  const [price, setPrice] = useState([0, 880]);
  const [regionContentVisible, setRegionContentVisible] = useState(false);
  const [grapeContentVisible, setGrapeContentVisible] = useState(false);
  const [alcoholContentVisible, setAlcoholContentVisible] = useState(false);
  const [volumeContentVisible, setVolumeContentVisible] = useState(false);
  const [searchSuggestList, setSearchSuggestList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState();
  const [wineTabVisible, setWineTabVisible] = useState(false);
  const [priceRangeDividers, setPriceRangeDividers] = useState([]);
  const [productLimit, setProductLimit] = useState(false);
  const [companyVerifyStatus, setCompanyVerifyStatus] = useState(0);
  const [activityDetail, setActivityDetail] = useState();
  const [priceVisible, setPriceVisible] = useState(true);

  useEffect(() => {
    loadActivityDetail();
  }, [id]);

  useEffect(() => {
    initPriceRangeDivider(price[0], price[1]);
  }, [price]);

  useEffect(() => {
    if (currentUser) {
      setProductLimit(
        checkLimit(currentUser, RoleLimit.PRODUCT_SEARCH, history)
      );
      setCompanyVerifyStatus(getCompanyVerifyStatus(currentUser));
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      products.length > 0 &&
      !(
        companyVerifyStatus === CompanyVerifyStatusEnum.VERIFIED && productLimit
      )
    ) {
      setPriceVisible(false);
    }
  }, [productLimit, companyVerifyStatus, currentUser, products]);

  useEffect(() => {
    if (user && user.userInfo) {
      setCurrentUser(user.userInfo);
    }
  }, [user]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setConfigData();
    if (regionList.length > 0) {
      onLoadRegionList(country);
    }
    if (!country) {
      setCountryName(t("COUNTRY"));
    }
    if (!stock) {
      setStockName(t("STOCK_LOCATION"));
    }
    if (!type) {
      setTypeName(t("TYPE"));
    }
    initPriceRange();
  }, [currentLanguage]);

  useEffect(() => {
    if (searchKeyword || region || grape || volume || alcohol || id) {
      onProductDiscover(0);
    }
  }, [searchKeyword, region, grape, volume, alcohol, id]);

  useEffect(() => {
    if (country) {
      setCountryName(getLocationName(countryList, country));
    }
  }, [countryList]);

  useEffect(() => {
    if (country) {
      setCountryName(getLocationName(countryList, country));
      setRegion(null);
      setStock(null);
    } else {
      setCountryName(t("COUNTRY"));
    }
  }, [country]);

  useEffect(() => {
    if (stock) {
      setStockName(getLocationName(regionList, stock));
    }
  }, [regionList]);

  useEffect(() => {
    if (stock) {
      setStockName(getLocationName(regionList, stock));
    } else {
      setStockName(t("STOCK_LOCATION"));
    }
  }, [stock]);

  useEffect(() => {
    if (!type) {
      setTypeName(t("TYPE"));
    }
  }, [type]);

  useEffect(() => {
    if (type) {
      setTypeName(getLocationName(typeList, type));
    }
  }, [typeList]);

  useEffect(() => {
    if (grapeKeyword) {
      let list = orgGrapeList.filter(
        (item) =>
          item.label.toUpperCase().indexOf(grapeKeyword.toUpperCase()) > -1
      );
      setGrapeList(list);
    } else {
      setGrapeList(orgGrapeList);
    }
  }, [grapeKeyword]);

  const initPriceRange = () => {
    switch (currentLanguage) {
      case SysEnvLanguageType.EN:
        setPriceStep(30);
        setPriceMax(330);
        setPrice([0, 330]);
        break;
      case SysEnvLanguageType.FR:
        setPriceStep(20);
        setPriceMax(220);
        setPrice([0, 220]);
        break;
      case SysEnvLanguageType.ZH:
        setPriceStep(80);
        setPriceMax(880);
        setPrice([0, 880]);
        break;
      default:
        break;
    }
  };

  const getLocationName = (list, val) => {
    list = list.filter((item) => item.value === val);
    return list.length > 0 ? list[0].label : "";
  };

  const setConfigData = async () => {
    await initProductGrapes();
    let configData = await loadProductConfig();
    let { locations, volumes, types, categories, tags } = configData;
    setLocationList(locations);
    setCountryList(getCountryForSelect(locations, currentLanguage,user.userInfo.type));
    setCategoryList(
      getProductCategoryTypeForSelect(categories, types, currentLanguage)
    );
    setTypeList(getProductConfigForSelect(types, currentLanguage));
    setImporterOptions(getProductConfigForSelect(tags, currentLanguage));
    let list = getProductConfigNameForSelect(volumes, currentLanguage);
    list = list.map((item) => {
      return { ...item, label: item.label + "ML" };
    });
    list.push({ label: t("SAMPLES"), value: ProductSampleValue });
    setVolumeList(list);
  };

  const initProductGrapes = async () => {
    let pms = {
      pms: JSON.stringify({
        page: 0,
        per_page: 1000,
      }),
    };
    let result = await getProductGrapes(pms);
    let list = getProductConfigForSelect(result, currentLanguage);
    setGrapeList(list);
    setOrgGrapeList(list);
  };

  const onRegionChange = (val) => {
    setRegion(val);
  };

  const onGrapeChange = (val) => {
    setGrape(val);
  };

  const onAlcoholChange = (val) => {
    setAlcohol(val);
  };

  const onVolumeChange = (val) => {
    setVolume(val);
  };

  const onProductDiscover = async (index) => {
    let theIndex = index === 0 ? 0 : pageIndex;
    setLoading(true);
    let pms = {
      page: theIndex,
      per_page: pageSize,
      alcoholBegin: alcohol[0],
      alcoholEnd: alcohol[1],
    };
    if (priceStep * 11 > price[1]) {
      pms["priceBegin"] = price[0];
      pms["priceEnd"] = price[1];
    }
    if (searchKeyword) {
      pms["keyword"] = searchKeyword;
    }
    if (country) {
      pms["country"] = country;
    }
    if (stock) {
      pms["stock"] = stock;
    }
    if (grape) {
      pms["grape"] = grape;
    }
    if (type) {
      pms["types"] = type;
    }
    let list = importerOptions
      .filter((item) => item.selected)
      .map((item) => item.value);
    if (list.length > 0) {
      pms["tags"] = list;
    }
    pms["actId"] = id;
    let result = await getProductSearch(pms);
    console.log("---------------------activityProducts:", result, pms);
    if (result && result.length > 0) {
      result.map((item) => {
        item.cardType = CardType.PRODUCT;
        item.id = item._id;
      });
      if (theIndex > 0) {
        let list = deepClone(products);
        result = list.concat(result);
      }
      result = result.filter((item) => item.cardType === CardType.PRODUCT);
      let tempResultList = await loadActivities(result);
      setProducts(tempResultList);
    } else {
      setHasMore(false);
      if (theIndex === 0) {
        setProducts([]);
      }
    }
    setLoading(false);
  };

  const onResetDiscover = () => {
    setCountry();
    setStock();
    setType();
    initPriceRange();
    let list = deepClone(importerOptions);
    list = list.map((item) => {
      item.selected = false;
      return item;
    });
    setImporterOptions(list);
  };

  const fetchSearchResult = async (val) => {
    setSearchSuggestList([]);
    let pms = { keyword: val };
    let result = await searchImporterSearch(pms);
    if (result && result.length > 0) {
      let list = [];
      result.forEach((item) => {
        let searchItem;
        if (item.cardType === CardType.FEED) {
          searchItem = {
            label: item.card.content,
            value: item.card._id,
            cardtype: item.cardType,
          };
        }
        if (item.cardType === CardType.TENDER) {
          searchItem = {
            label: item.card.title,
            value: item.card._id,
            cardtype: item.cardType,
          };
        }
        if (item.cardType === CardType.PRODUCT) {
          searchItem = {
            label: localizeTitle(currentLanguage, item.card.title),
            value: item.card._id,
            cardtype: item.cardType,
          };
        }
        if (searchItem && searchItem.label) {
          list.push(searchItem);
        }
      });
      setSearchSuggestList(list);
    }
  };

  const loadActivities = async (tempFeedList) => {
    let pms = { from: "1", page: 0, per_page: 60 };
    let resultList = await getActivityList(pms);
    if (resultList && resultList.length > 0) {
      resultList = resultList.filter((item) => item._id !== id);
      let list = [];
      let salonIndex = 0;
      for (let index = 0; index < tempFeedList.length; index++) {
        list.push(tempFeedList[index]);
        if (salonIndex < resultList.length && salonIndex % 3 == 0) {
          resultList[salonIndex].cardType = CardType.SALON;
          list.push(resultList[salonIndex]);
        }
        salonIndex++;
      }
      return list;
    }
    return tempFeedList;
  };

  const loadActivityDetail = async () => {
    let pms = { _id: id };
    let result = await getActivityDetail(pms);
    if (result) {
      setActivityDetail(result);
    }
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  const onLoadRegionList = (coutry_val) => {
    let result = getLocationsByParentId(
      locationList,
      coutry_val,
      currentLanguage
    );
    if (!result || result.length === 0) {
      result.push({
        value: "",
        label: t("ALL"),
      });
    }
    setRegionList(result);
  };

  const onCountryChange = (event) => {
    const { key } = event;
    setCountry(key);
    onLoadRegionList(key);
    // let result = getLocationsByParentId(locationList, key, currentLanguage);
    // setRegionList(result);
  };

  const onStockChange = (event) => {
    const { key } = event;
    setStock(key);
  };

  const onCategoryChange = (item) => {
    setCategory(item.value);
    setTypeName(item.label);
    setType(null);
  };

  const onTypeChange = (item) => {
    setType(item.value);
    setTypeName(item.label);
    setCategory(null);
    setWineTabVisible(false);
  };

  const onPriceChange = (price) => {
    setPrice(price);
  };

  const onPriceRangeDividerRangeClick = (index) => {
    let tempPrice = deepClone(price);
    let indexPrice = index * priceStep;
    if (indexPrice < tempPrice[0]) {
      tempPrice[0] = indexPrice;
    } else if (indexPrice > tempPrice[1]) {
      tempPrice[1] = indexPrice + priceStep;
    } else {
      if (
        Math.abs(tempPrice[0] - indexPrice) >
        Math.abs(tempPrice[1] - indexPrice)
      ) {
        tempPrice[1] = indexPrice;
      } else {
        tempPrice[0] = indexPrice;
      }
    }
    setPrice(tempPrice);
  };

  const onMinPriceInputChange = (val) => {
    let tempPrice = deepClone(price);
    onPriceChange([val, tempPrice[1]]);
  };

  const onMaxPriceInputChange = (val) => {
    let tempPrice = deepClone(price);
    onPriceChange([tempPrice[0], val]);
  };

  const initPriceRangeDivider = (start, end) => {
    let temp = [];
    for (let index = 0; index < 12; index++) {
      let indexVal = index * priceStep;
      temp.push({
        index: indexVal,
        selected: indexVal <= end && indexVal >= start ? true : false,
      });
    }
    setPriceRangeDividers(temp);
  };

  const sliderPriceFormatter = (value) => {
    if (value < 880) {
      return `${value}${localizePriceUnit(currentLanguage)}`;
    } else {
      return `${t("UP_TO")}${value}${localizePriceUnit(currentLanguage)}`;
    }
  };

  const getDropDownMenuItem = (val, item, eventClick, index, unitName) => {
    let itemContent = (
      <>
        <div
          className={
            "drop-dot " + (val === item.value ? "drop-dot-selected" : "")
          }
        ></div>
        <span className="drop-item-text">
          {item.label} {unitName}
        </span>
        {val === item.value && (
          <Divider type="vertical" className="item-divider" />
        )}
      </>
    );
    if (eventClick) {
      return (
        <div className="drop-item" onClick={eventClick.bind(this)} key={index}>
          {itemContent}
        </div>
      );
    } else {
      return (
        <div className="drop-item" key={index}>
          {itemContent}
        </div>
      );
    }
  };

  const menu_country = (
    <Menu
      onClick={onCountryChange.bind(this)}
      className="discover-dropdown-menu"
    >
      {countryList.map((item) => (
        <Menu.Item key={item.value}>
          {getDropDownMenuItem(country, item)}
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu_stock = () => {
    return regionList.length > 0 ? (
      <Menu
        onClick={onStockChange.bind(this)}
        className="discover-dropdown-menu"
      >
        {regionList.map((item) => (
          <Menu.Item key={item.value}>
            {getDropDownMenuItem(stock, item)}
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <></>
    );
  };

  const onStockMenuClick = () => {
    if (regionList.length === 0) {
      toastInfo(t("PLEASE_SELECT_COUNTRY"));
    }
    setWineTabVisible(false);
  };

  const onChanelSelect = (index) => {
    let list = deepClone(importerOptions);
    list = list.map((item, i) => {
      if (i == index) {
        item.selected = !item.selected;
      }
      // else {
      //   item.selected = false;
      // }
      return item;
    });

    setImporterOptions(list);
  };

  const onSearchKeyDownchange = (e) => {
    if (e.keyCode == 13) {
      fetchSearchResult();
    }
  };

  const onSearchSelectOption = (val, option) => {
    setSearchKeyword(option.label);
  };

  const onBannerClick = (country, city) => {
    onCountryChange({
      key: constants.chinaCountryId,
    });
    onStockChange({
      key: "62217934550bf01bbc5254c0",
    });
    onProductDiscover(0);
  };

  const onCheckUserLimit = () => {
    return checkLimit(currentUser, RoleLimit.PRODUCT_SEARCH, history);
  };

  const onRegionTitleClick = () => {
    if (regionList.length === 0) {
      toastInfo(t("PLEASE_SELECT_COUNTRY"));
      return;
    }
    setRegionContentVisible(!regionContentVisible);
  };

  return (
    <div className="discover-page">
      <BackTop />
      <div className="discover-content">
        <div className="banner-container">
          <div className="filter-container">
            <div>
              <div
                style={{
                  width: "100%",
                  margin: "10px 0 -10px 0",
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={resetImg}
                  style={{ width: "30px", height: "30px" }}
                  onClick={onResetDiscover.bind(this)}
                />
              </div>
              <div className="filter-tabs">
                <Dropdown
                  overlay={menu_country}
                  trigger={["click"]}
                  className="discover-dorpdown"
                >
                  <div
                    className="filter-tab-item"
                    onClick={() => setWineTabVisible(false)}
                  >
                    <img src={languageIcon} className="select-icon" />
                    <span
                      className={
                        "select-title " +
                        (country ? "select-title-primary" : "")
                      }
                    >
                      {countryName}
                    </span>
                  </div>
                </Dropdown>
                <Divider type="vertical" style={{ height: "auto" }} />
                <div className="filter-tab-item">
                  <img src={bottleIcon} className="select-icon" />
                  <span
                    className={
                      "select-title " + (typeName ? "select-title-primary" : "")
                    }
                    onClick={() => setWineTabVisible(!wineTabVisible)}
                  >
                    {typeName}
                  </span>
                  {wineTabVisible && (
                    <div className="wine-drop-container">
                      <Tabs
                        defaultActiveKey="0"
                        centered
                        className="common-tabs wine-tab-container"
                      >
                        {categoryList.map((item, index) => {
                          if (index < 2) {
                            return (
                              <TabPane tab={item.label} key={index}>
                                <Menu className="common-menus discover-dropdown-menu">
                                  {item.types.map((t) => (
                                    <Menu.Item
                                      key={t.value}
                                      onClick={onTypeChange.bind(this, t)}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {getDropDownMenuItem(type, t)}
                                    </Menu.Item>
                                  ))}
                                </Menu>
                              </TabPane>
                            );
                          }
                        })}
                      </Tabs>
                    </div>
                  )}
                </div>
                <Divider type="vertical" style={{ height: "auto" }} />
                <Dropdown
                  overlay={menu_stock()}
                  trigger={["click"]}
                  className="discover-dorpdown"
                >
                  <div
                    className="filter-tab-item"
                    onClick={onStockMenuClick.bind(this)}
                  >
                    <img src={stockIcon} className="select-icon" />
                    <span
                      className={
                        "select-title " + (stock ? "select-title-primary" : "")
                      }
                    >
                      {stockName}
                    </span>
                  </div>
                </Dropdown>
              </div>
              <div className="filter-item-title">
                {t("PRICE")}
                <span className="price-unit">
                  {t("UNIT")}: {localizePriceUnit(currentLanguage)}
                </span>
              </div>
              <Slider
                range={true}
                step={priceStep}
                value={price}
                max={priceMax}
                tipFormatter={sliderPriceFormatter}
                onChange={onPriceChange.bind(this)}
              />
              <div className="price-range-icon">
                {priceRangeDividers.map((rangeItem, rangeIndex) => (
                  <div
                    key={rangeIndex}
                    className={
                      rangeItem.selected
                        ? "price-range-divider-selected"
                        : "price-range-divider-unselected"
                    }
                    onClick={onPriceRangeDividerRangeClick.bind(
                      this,
                      rangeIndex
                    )}
                  ></div>
                ))}
              </div>
              <div className="filter-item-title">{t("TAGS")}</div>
              <div className="filter-item-content">
                {importerOptions.map((item, index) => (
                  <div
                    key={index}
                    className={
                      "content-item " +
                      (item.selected ? "content-item-selected" : "")
                    }
                    onClick={onChanelSelect.bind(this, index)}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
            <Button
              className="filter-button"
              onClick={onProductDiscover.bind(this, 0)}
            >
              {t("DISCOVER")}
            </Button>
            {/* <Button type="text"
              className="reset-button"
              onClick={onResetDiscover.bind(this)}
            >
              {t('RESET')}
            </Button> */}
          </div>
          {activityDetail ? (
            <div className="banner-pannel">
              <img
                src={localizeImg(activityDetail.picture)}
                className="banner-image-item"
              />
              <div className="banner_item_title">{activityDetail.title}</div>
            </div>
          ) : (
            <Carousel className="banner-pannel" autoplay dots={false}>
              <div onClick={onBannerClick.bind(this, "China", "Beijing")}>
                <img src={bannerImage1} className="banner-image-item" />
                <div className="banner_item_title">Beijing China</div>
              </div>
              <div onClick={onBannerClick.bind(this, "China", "Beijing")}>
                <img src={bannerImage2} className="banner-image-item" />
                <div className="banner_item_title">Beijing China</div>
              </div>
              <div onClick={onBannerClick.bind(this, "China", "Beijing")}>
                <img src={bannerImage3} className="banner-image-item" />
                <div className="banner_item_title">Beijing China</div>
              </div>
            </Carousel>
          )}
        </div>
        <div className="content-container">
          <div className="content-left-container">
            <div className="search-container">
              <span className="search-title">{t("SEARCH")}</span>
              <div className="input-pannel">
                <Select
                  showSearch={true}
                  bordered={false}
                  options={searchSuggestList}
                  value={searchKeyword}
                  // placeholder={t("SEARCH")}
                  showArrow={false}
                  className="input-control"
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  notFoundContent={null}
                  onSearch={fetchSearchResult.bind(this)}
                  onSelect={onSearchSelectOption.bind(this)}
                  // onInputKeyDown={onKeyDownchange.bind(this)}
                />
                {/* <Input bordered={false} className="input-control" /> */}
                <Divider type="vertical" style={{ height: "50%" }} />
              </div>
            </div>
            <div className="type-select-container">
              <div
                className="type-title-pannel"
                onClick={onRegionTitleClick.bind(this)}
              >
                <div className="type-title">
                  <img src={regionIcon} className="title-icon" />
                  <span>{t("REGION")}</span>
                </div>
                {regionList.length === 0 && (
                  <DownOutlined className="flag-icon" />
                )}
                {regionList.length > 0 && !regionContentVisible && (
                  <DownOutlined
                    className="flag-icon"
                    onClick={() => setRegionContentVisible(true)}
                  />
                )}
                {regionContentVisible && (
                  <UpOutlined
                    className="flag-icon"
                    onClick={() => setRegionContentVisible(false)}
                  />
                )}
              </div>
              {regionContentVisible && (
                <div className="type-content-pannel discover-dropdown-menu">
                  {regionList.map((item, index) =>
                    getDropDownMenuItem(
                      region,
                      item,
                      onRegionChange.bind(this, item.value),
                      index
                    )
                  )}
                </div>
              )}
            </div>
            <div className="type-select-container">
              <div
                className="type-title-pannel"
                onClick={() => setGrapeContentVisible(!grapeContentVisible)}
              >
                <div className="type-title">
                  <img src={grapeIcon} className="title-icon" />
                  <span>{t("GRAPE")}</span>
                </div>
                {!grapeContentVisible && (
                  <DownOutlined
                    className="flag-icon"
                    onClick={() => setGrapeContentVisible(true)}
                  />
                )}
                {grapeContentVisible && (
                  <UpOutlined
                    className="flag-icon"
                    onClick={() => setGrapeContentVisible(false)}
                  />
                )}
              </div>
              {grapeContentVisible && (
                <div className="type-content-pannel discover-dropdown-menu">
                  <div className="type-search-pannel">
                    <Input
                      bordered={false}
                      placeholder={t("SEARCH")}
                      value={grapeKeyword}
                      onChange={(e) => setGrapeKeyword(e.target.value)}
                    />
                  </div>
                  {grapeList.map((item, index) =>
                    getDropDownMenuItem(
                      grape,
                      item,
                      onGrapeChange.bind(this, item.value),
                      index
                    )
                  )}
                </div>
              )}
            </div>
            <div className="type-select-container">
              <div
                className="type-title-pannel"
                onClick={() => setAlcoholContentVisible(!alcoholContentVisible)}
              >
                <div className="type-title">
                  <img src={alcoholIcon} className="title-icon" />
                  <span>{t("ALCOHOL_DEGREE")}</span>
                </div>
                {!alcoholContentVisible && (
                  <DownOutlined
                    className="flag-icon"
                    onClick={() => setAlcoholContentVisible(true)}
                  />
                )}
                {alcoholContentVisible && (
                  <UpOutlined
                    className="flag-icon"
                    onClick={() => setAlcoholContentVisible(false)}
                  />
                )}
              </div>
              {alcoholContentVisible && (
                <div className="type-content-pannel discover-dropdown-menu">
                  {ProductAlcoholSelectList.map((item, index) =>
                    getDropDownMenuItem(
                      alcohol,
                      item,
                      onAlcoholChange.bind(this, item.value),
                      index,
                      t("ALCOHOL")
                    )
                  )}
                </div>
              )}
            </div>
            <div className="type-select-container">
              <div
                className="type-title-pannel"
                onClick={() => setVolumeContentVisible(!volumeContentVisible)}
              >
                <div className="type-title">
                  <img src={bottlesizeIcon} className="title-icon" />
                  <span>{t("BOTTLE_SIZE")}</span>
                </div>
                {!volumeContentVisible && (
                  <DownOutlined
                    className="flag-icon"
                    onClick={() => setVolumeContentVisible(true)}
                  />
                )}
                {volumeContentVisible && (
                  <UpOutlined
                    className="flag-icon"
                    onClick={() => setVolumeContentVisible(false)}
                  />
                )}
              </div>
              {volumeContentVisible && (
                <div className="type-content-pannel discover-dropdown-menu">
                  {volumeList.map((item, index) =>
                    getDropDownMenuItem(
                      volume,
                      item,
                      onVolumeChange.bind(this, item.value),
                      index
                    )
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="content-product-right-container">
            <InfiniteScroll
              className="scroll-content"
              dataLength={products.length}
              next={handleInfiniteOnLoad.bind(this)}
              hasMore={hasMore}
            >
              <List
                itemLayout="vertical"
                size="large"
                dataSource={products}
                renderItem={(item) => (
                  <div className="product-list-item-container">
                    {item.cardType == CardType.PRODUCT && (
                      <ProductDiscoverItem
                        item={item}
                        onCheckLimit={onCheckUserLimit.bind(this)}
                        showDiscount={true}
                        priceVisible={priceVisible}
                      />
                    )}
                    {item.cardType == CardType.SALON && (
                      <SalonListRow data={item} />
                    )}
                  </div>
                )}
                loading={loading}
              ></List>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}
