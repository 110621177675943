import { useTranslation } from "react-i18next";
import styles from "./Message.module.sass";
import { ChatContentsType } from "../../../../../constants/chatContentsType.enum";
import { EMOTIONS_NAMES } from "../../../../../constants/emotions";
import { getTransLanguage } from "../../../../../api/common";
import { translate } from "../../../../../api/translate";
import { DownOutlined, LoadingOutlined, UpOutlined } from "@ant-design/icons";
import { Image, Spin } from "antd";
import { localizeImg } from "../../../../../constants/constants";
import VideoBasic from "../../../../../components/common/video/VideoBasic";
import VoiceBasic from "../../../../../components/common/video/VoiceBasic";
import { useState } from "react";
import { deepClone } from "../../../../../utils/utils";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import AvatarBasic from "../../../../../components/common/avatar/AvatarBasic";

const Message = ({ item, activeUser }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const { user } = useUserId();
  const { userId, userInfo } = user;

  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [messageItem, setMessageItem] = useState(item);

  const msgTemplate = (from) => {
    let { contentsType, customExts, transStatus, translatedContent } =
      messageItem;
    let result = "";
    if (contentsType === ChatContentsType.TEXT) {
      result = (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: parseEmotionMessage(messageItem.data),
            }}
            className={from ? styles.mySelfText : styles.friendText}
          ></div>
          <div className="translate" onClick={transMessage.bind(this)}>
            {t("TRANSLATE")}
            {transStatus ? (
              <UpOutlined style={{ fontSize: "10px" }} />
            ) : (
              <DownOutlined style={{ fontSize: "10px" }} />
            )}
          </div>
          {transStatus && (
            <div className="trans-content">{translatedContent}</div>
          )}
          {transStatus && !translatedContent && (
            <div className="trans-content">
              <Spin indicator={loadingIcon} />
            </div>
          )}
        </div>
      );
    } else if (contentsType === ChatContentsType.CUSTOM) {
      let { url, fileType, name } = customExts;
      switch (fileType) {
        case ChatContentsType.IMAGE:
          result = (
            <Image src={localizeImg(url)} className={styles.chatImage} />
          );
          break;
        case ChatContentsType.VIDEO:
          result = (
            <VideoBasic url={localizeImg(url)} width={200} height={150} />
          );
          break;
        case ChatContentsType.VOICE:
          result = <VoiceBasic url={localizeImg(url)} />;
          break;
        case ChatContentsType.FILE:
          result = <a href={localizeImg(url)}>{name}</a>;
          break;
        case ChatContentsType.AUDIOCALL:
          result = (
            <div className={from ? "rightText" : "leftText"}>
              {t("AUDIO_CALL_DONE")}
            </div>
          );
          break;
        case ChatContentsType.VIDEOCALL:
          result = (
            <div className={from ? "rightText" : "leftText"}>
              {t("VIDEO_CALL_DONE")}
            </div>
          );
          break;
        case ChatContentsType.CANCELCALL:
          result = (
            <div className={from ? "rightText" : "leftText"}>
              {t("CALL_CANCELED")}
            </div>
          );
          break;
        case ChatContentsType.REFUSECALL:
          result = (
            <div className={from ? "rightText" : "leftText"}>
              {t("CALL_REJECTED")}
            </div>
          );
          break;
        default:
          break;
      }
    }
    return result;
  };

  const parseEmotionMessage = (msg) => {
    EMOTIONS_NAMES.forEach((emotionItem) => {
      if (msg.indexOf(emotionItem.name) > -1) {
        let icon = `<img src='${emotionItem.path}' class='emotion-img' style="width:30px;margin:0px 3px"/>`;
        msg = msg.replace(emotionItem.name, icon);
      }
    });
    // console.log('--------parseEmotionMessage:', msg);
    return msg;
  };

  const transMessage = async () => {
    let temp = deepClone(messageItem);

    if (!temp.transStatus) {
      temp.transStatus = true;
      setMessageItem(temp);

      temp = deepClone(messageItem);
    }

    if (!temp.translatedContent) {
      let _transContent = await translateMessage(temp.data);
      temp.translatedContent = _transContent;
      temp.transStatus = true;
    }

    setMessageItem(temp);
  };

  const translateMessage = async (content) => {
    let language = getTransLanguage(lang);
    let data = await translate({
      text: content,
      to: language,
    });
    return data.result.result;
  };

  return (
    <div className={styles.message}>
      <div className={styles.avatar}>
        <AvatarBasic picture={activeUser?.companyInfo?.avatar} size={41} />
      </div>
      <div className={styles.details}>
        <div className={styles.head}>
          <div className={styles.man}>{t(activeUser?.companyInfo?.companyName)}</div>
          <div className={styles.time}>{messageItem?.ext?.time}</div>
        </div>
        <div
          className={styles.content}
          // dangerouslySetInnerHTML={{ __html: item.sourceMsg }}
        >
          {msgTemplate(messageItem.from === userId ? true : false)}
        </div>
      </div>
    </div>
  );
};

export default Message;
