import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../../../core-digital-template/components/Card";
import Icon from "../../../../../core-digital-template/components/Icon";
import TextInput from "../../../../../core-digital-template/components/TextInput";
import Editor from "../../../../../core-digital-template/components/Editor";
import { useTranslation } from "react-i18next";
import { companyBankInfoFields } from "../../../../../constants/usersType.enum";
import FormNormalW from "../../../../../core-digital-template/components/FormNormalW";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { companyBankInfo } from "../../../../../api/company";
import { statusCode } from "../../../../../constants/statusCode.enum";
import { deepClone } from "../../../../../utils/utils";

const NameAndDescription = ({ className, onChange }) => {
    const { t } = useTranslation();
    const { user } = useUserId();

    const [fields, setFields] = useState(companyBankInfoFields);

    useEffect(() => {
        initEditStatus(user.userInfo.companyId);
    }, []);

    const initEditStatus = async (coId) => {
        let pms = { coId };

        let bankInfo = await companyBankInfo(pms);

        if (bankInfo && bankInfo.statusCode == statusCode.SUCCESS) {
            let result = bankInfo.result;
            let tempFields = deepClone(fields);
            tempFields.map((fieldItem) => {
                for (const key in result) {
                    if (fieldItem.name == key) {
                        fieldItem.value = result[key];
                    }
                }
            });
            setFields(tempFields);
        }
    };

    const onFieldsChange = (fields) => {
        setFields(fields);
        onChange(fields);
    }

    return (
        <Card
            className={cn(styles.card, className)}
            title={t("BANK_INFORMATION_DETAILS")}
            classTitle="title-red"
            head={
                <Link
                    className={cn("button-stroke button-small", styles.button)}
                    to="/products/dashboard"
                >
                    <Icon name="arrow-left" size="24" />
                    <span>Back</span>
                </Link>
            }
        >
            <div className={styles.description}>
                <FormNormalW items={fields} onChange={onFieldsChange.bind(this)} />
            </div>
        </Card>
    );
};

export default NameAndDescription;
