import React, { useState, useEffect } from "react";
import { Image, Row, Col } from "antd";
import "./style/imageListRow.less";
import { localizeImg } from "../../constants/constants";
import { useTranslation } from "react-i18next";

export default function ImageListRow(props) {
  const { data } = props;
  const { t, i18n } = useTranslation();

  let [month, setMonth] = useState("");
  let [year, setYear] = useState("");

  useEffect(() => {
    setYear(data.key.substr(0, 4));
    setMonth(data.key.substr(5, 2));
  }, [data]);

  return (
    <div className="feed-images-row-container">
      <div className="feed-images-index">
        <div className="image-month">
          {year}
          {t("YEAR")}
          {month} {t("MONTH")}
        </div>
      </div>
      <div className="feed-images">
        {data.items.map((item, index) => {
          return (
            <div className="image-list-image-box">
              <Image src={localizeImg(item)} className="feed-list-image" />
            </div>
          );
        })}
      </div>
    </div>
  );
}
