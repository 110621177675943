import { Form, Select } from "antd";

export default function SelectBasic(props) {
  let { data, label, name, onChange, required, message, disabled, className } =
    props;
  let layout = { label, name };
  if (required) {
    layout.rules = [{ required: true, message }];
  }

  let selectLayout = { options: data };
  if (disabled) {
    selectLayout.disabled = true;
  }

  return (
    <Form.Item {...layout}>
      <Select
        {...selectLayout}
        className={className}
        onChange={(value) => {
          onChange && onChange(value);
        }}
      />
    </Form.Item>
  );
}
