import React, { useState, useEffect } from "react";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import enWorldJson from "world_countries_lists/data/countries/en/world.json";
import frWorldJson from "world_countries_lists/data/countries/fr/world.json";
import zhWorldJson from "world_countries_lists/data/countries/zh/world.json";
import { useTranslation } from "react-i18next";
import './style/inputPhoneNumberNormal.less';

export default function InputPhoneNumberNormal(props) {
  let { label, value, index, onChange, onBlur , disabled } = props;
  const { t, i18n } = useTranslation();
  const [currentPhoneTranslation, setCurrentPhoneTranslation] = useState(enWorldJson);
  let layout = value ? { value } : {};
  useEffect(() => {
    let language = i18n.language;
    if (language === "en") {
      setCurrentPhoneTranslation(enWorldJson);
    }
    if (language === "fr") {
      setCurrentPhoneTranslation(frWorldJson);
    }
    if (language === "zh") {
      setCurrentPhoneTranslation(zhWorldJson);
    }
  }, [i18n.language]);

  const onInputChange = (val) => {
    onChange(index, val);
  }
  const handlePhoneChange = (value) => {
    if (value) {
      onChange(value, index);
      // setPhone(`${value.code}${value.phone}`);
    }
  };

  const onInputBlur = (e) => {
    onBlur && onBlur(e.target.value);
  }

  return (
    <div className="input-phone-number-normal" >
      <ConfigProvider locale={currentPhoneTranslation}>
        <CountryPhoneInput {...layout}
          className="common-select"
          placeholder={label}
          disabled={disabled}
          bordered={false}
          onChange={(value) => handlePhoneChange(value)}
          onBlur={onInputBlur.bind(this)}
        />
      </ConfigProvider>
    </div>

  );
}
