export const ChatContentsType = {
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    CUSTOM: 'CUSTOM',
    VIDEO: 'VIDEO',
    VOICE: 'VOICE',
    FILE: 'FILE',
    AUDIOCALL: 'AUDIOCALL',
    VIDEOCALL: 'VIDEOCALL',
    CANCELCALL: 'CANCELCALL',
    REFUSECALL: 'REFUSECALL',
}

export enum AudioCallStatus {
    CALLING,
    WAITING,
    CONNECTING,
    CANCELLED,
}
