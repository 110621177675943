import { formatActionResponse, formatActionResponseArray } from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";

/**
 * @description Get the meetings
 * @param pms
 */
export async function getMeetings(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.meetings, pms);
}

/**
 * @description Create a meeting
 * @param pms
 */
export async function createMeeting(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.meetingCreate, pms);
}

/**
 * @description get agora token
 * @param pms
 */
export async function getAgoraToken(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.meetingChatToken, pms);
}

/**
 * @description Get the groups
 * @param pms
 */
export async function getGroups(pms: any) {
    return formatActionResponseArray(RequestType.POST, apiPath.imGroups, pms);
}

/**
 * @description Create a group
 * @param pms
 */
export async function createGroup(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.imGroupCreate, pms);
}

/**
 * @description update group name
 * @param pms
 */
 export async function updateGroupName(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.imGroupUpdateName, pms);
}

/**
 * @description Create a group member
 * @param pms
 */
export async function addGroupMember(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.imGroupMemberAdd, pms);
}

/**
 * @description remove a group member
 * @param pms
 */
export async function removeGroupMember(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.imGroupMemberRemove, pms);
}

/**
 * @description Get single group
 * @param pms
 */
 export async function getGroupInfo(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.imGroupInfo, pms);
}

/**
 * @description remove group
 * @param pms
 */
 export async function removeGroup(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.imGroupRemove, pms);
}