import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import "./style/archivedHistoryItem.less";
import { useAuth } from "../../../contexts/AuthProvider";
import { dateFormat } from "../../../utils/date";
import AvatarBasic from "../../common/avatar/AvatarBasic";
import { localizeImg } from "../../../constants/constants";
import moreIcon from "./images/more.svg";
import { Dropdown, Menu } from "antd";
import { BrowseRecordType } from "../../../constants/browseRecord.enum";
import { getCountryNameById } from "../../../api/config";
import { loadProductConfig } from "../../../api/product";

export default function ArchivedHistoryItem(props) {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const { userId } = user;
  const { data } = props;

  const [countries, setCountries] = useState([]);

  useEffect(() => {}, [i18n.language]);

  useEffect(() => {
    setConfigData();
  }, []);

  useEffect(() => {
    if (data) {
    }
  }, [data]);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;

    setCountries(locations);
  };

  const onBrowseRecordDelete = (e) => {};

  const menu_country = (
    <Menu
      onClick={onBrowseRecordDelete.bind(this)}
      className="discover-dropdown-menu"
    >
      <Menu.Item key={0}>{t("DELETE")}</Menu.Item>
    </Menu>
  );

  const renderContentType = (item) => {
    let { busType, userInfo } = item;

    if (userInfo && userInfo.length > 0) {
      let userItem = userInfo[0];

      let contentType = userItem._id == userId ? t("YOU") : userItem.nickName;
      contentType += " ";

      switch (busType) {
        case BrowseRecordType.POST_FEED:
          contentType += t("POST_FEED");
          break;
        case BrowseRecordType.POST_VIDEO:
          contentType += t("POST_VIDEO");
          break;
        case BrowseRecordType.FEED_COMMENT:
          contentType += t("REPLY_COMMENT_FROM");
          break;
        case BrowseRecordType.SHARE_FEED:
          contentType += t("SHARED_FEED");
          break;
        case BrowseRecordType.SHARE_VIDEO:
          contentType += t("SHARED_VIDEO");
          break;
        case BrowseRecordType.REPLAY_COMMENT:
          contentType += t("REPLIED_COMMENT_FROM");
          break;
        case BrowseRecordType.ADD_PRODUCT:
          contentType += t("ADD_A_PRODUCT");
          break;
        case BrowseRecordType.EDIT_PRODUCT:
          contentType += t("EDIT_A_PRODUCT");
          break;
        case BrowseRecordType.DELETE_PRODUCT:
          contentType += t("DELETE_A_PRODUCT");
          break;
        case BrowseRecordType.SEARCH_PRODUCT:
          contentType += t("SEARCHED");
          break;
        default:
          break;
      }

      return <div className="content-type">{contentType}</div>;
    }
  };

  const renderContent = (item) => {
    console.log("renderContent:", item);
    let { busType, content } = item;

    let contentType = "";

    switch (busType) {
      case BrowseRecordType.POST_FEED:
        break;
      case BrowseRecordType.POST_VIDEO:
        break;
      case BrowseRecordType.FEED_COMMENT:
        break;
      case BrowseRecordType.SHARE_FEED:
        break;
      case BrowseRecordType.SHARE_VIDEO:
        break;
      case BrowseRecordType.REPLAY_COMMENT:
        break;
      case BrowseRecordType.ADD_PRODUCT:
        let { productInfo } = item;
        if (productInfo && productInfo.length > 0) {
          let productItem = productInfo[0];
          contentType += productItem.title;
          contentType += " ";
          contentType += getCountryNameById(
            countries,
            i18n.language,
            productItem.country
          );
        }
        break;
      case BrowseRecordType.EDIT_PRODUCT:
        let productInfoAdd=item.productInfo;
        if (productInfoAdd && productInfoAdd.length > 0) {
          let productItem = productInfoAdd[0];
          contentType += productItem.title;
          contentType += " ";
          contentType += getCountryNameById(
            countries,
            i18n.language,
            productItem.country
          );
        }
        break;
      case BrowseRecordType.DELETE_PRODUCT:
        break;
      case BrowseRecordType.SEARCH_PRODUCT:
        if (content && content.alcoholBegin != undefined) {
          contentType += t("ALCOHOL_DEGREE");
          contentType += ":";
          contentType += content.alcoholBegin;
          contentType += "-";
          contentType += content.alcoholEnd;
        }
        break;
      default:
        break;
    }

    return <div className="content-type-2">{contentType}</div>;
  };

  return (
    <div className="archived-history-item-panel">
      <div className="item-date">
        {dateFormat(data.createdAt, "yyyy-MM-dd")}
      </div>
      <div className="user-info-panel">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <AvatarBasic
            picture={localizeImg(data.userInfo[0].avatar)}
          ></AvatarBasic>
          <div className="user-name">{data.userInfo[0].nickName}</div>
        </div>
        <Dropdown overlay={menu_country} trigger={["click"]}>
          <img src={moreIcon} />
        </Dropdown>
      </div>
      <div>{renderContentType(data)}</div>
      <div>{renderContent(data)}</div>
      <div className="item-time">
        {dateFormat(data.createdAt, "hh-mm-ss")}
      </div>
    </div>
  );
}
