import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PostTargetType } from "@amityco/js-sdk";
import { FormattedMessage } from "react-intl";
import { isAdmin, isModerator } from "../../../../helpers/permissions";
import usePost from "../../../../social/hooks/usePost";
import useCommunity from "../../../../social/hooks/useCommunity";
import { useNavigation } from "../../../../social/providers/NavigationProvider";
import UIPostHeader from "./UIPostHeader";
import useCommunityOneMember from "../../../../social/hooks/useCommunityOneMember";
import { userDetail } from "../../../../../../api/user";
import {
  localizeImg,
  localizeValue,
} from "../../../../../../constants/constants";
import { useHistory } from "react-router";
import { Path, PathTools } from "../../../../../../constants/path";
import { useTranslation } from "react-i18next";

const PostHeader = ({ postId, hidePostTarget, loading }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const { onClickCommunity, onClickUser } = useNavigation();
  const { post, file, user } = usePost(postId);

  const { targetId, targetType, postedUserId, createdAt, editedAt } = post;

  // If the post is targetting a community feed, get the name of that community.
  const isCommunityPost = targetType === PostTargetType.CommunityFeed;
  const { community } = useCommunity(targetId, () => !isCommunityPost);
  const postTargetName = isCommunityPost ? community?.displayName : null;
  const handleClickCommunity = isCommunityPost
    ? () => onClickCommunity(targetId)
    : null;

  const { isCommunityModerator } = useCommunityOneMember(
    community?.communityId,
    user.userId,
    community?.userId
  );
  const handleClickUser = () => {
    // TODO:在Vinbip Profile和Amity Profile之间切换
    // onClickUser(postedUserId);
    history.push(
      PathTools.joinPath(
        Path.companyDetail,
        localizeValue(i18n.language, userInfo?.companyDesc?.companyName)
      ),
      {
        id: postedUserId,
      }
    );
  };

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (user && user.userId) {
      loadUserDetail(user.userId);
    }
  }, [user]);

  const loadUserDetail = async (userId) => {
    let userDetailInfo = await userDetail(userId);
    // console.log("-----------------loadUserDetail",userDetailInfo);
    if (userDetailInfo) {
      setUserInfo(userDetailInfo);
    }
  };

  return (
    <UIPostHeader
      avatarFileUrl={localizeImg(userInfo?.companyDesc?.avatar)}
      postAuthorName={
        localizeValue(i18n.language, userInfo?.companyDesc?.companyName) || (
          <FormattedMessage id="anonymous" />
        )
      }
      postTargetName={postTargetName}
      timeAgo={createdAt}
      companyDesc={userInfo?.companyDesc}
      userInfo={userInfo}
      isModerator={
        isCommunityModerator || isModerator(user.roles) || isAdmin(user.roles)
      }
      isEdited={createdAt < editedAt}
      isBanned={user.isGlobalBan}
      hidePostTarget={hidePostTarget}
      loading={loading}
      onClickCommunity={handleClickCommunity}
      onClickUser={handleClickUser.bind(this)}
    />
  );
};

PostHeader.propTypes = {
  postId: PropTypes.string,
  hidePostTarget: PropTypes.bool,
  loading: PropTypes.bool,
};

PostHeader.defaultProps = {
  hidePostTarget: false,
  loading: false,
};

export { UIPostHeader };
export default memo(PostHeader);
