import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./FilterPanel.module.sass";
import Form from "../../../core-digital-template/components/Form";
import Dropdown from "../../../core-digital-template/components/Dropdown";
import Checkbox from "../../../core-digital-template/components/Checkbox";
import Icon from "../../../core-digital-template/components/Icon";
import { Range, getTrackBackground } from "react-range";
import useDarkMode from "use-dark-mode";
import FilterItem from "../FilterItem";
import {
  getProductCategoryTypeForSelect,
  getProductConfigForSelect,
  getProductConfigNameForSelect,
  getProductGrapes,
  loadProductConfig,
} from "../../../api/product";
import {
  getCountryAndCityForSelect,
  getLocationsByParentId,
} from "../../../api/config";
import { useTranslation } from "react-i18next";
import { ProductSampleValue } from "../../../constants/constants";
import { deepClone } from "../../../utils/utils";
import { ProductAlcoholSelectList } from "../../../constants/config";

const FilterPanel = ({ filterRegion, onConfirm, onReset }) => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [searchForm, setSearchForm] = useState({});

  const [searchKeyword, setSearchKeyword] = useState("");

  const [subRegionList, setSubRegionList] = useState([]);
  const [oriRegionList, setOriRegionList] = useState([]);
  const [classificationList, setClassificationList] = useState([]);

  const [locationList, setLocationList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [alcoholList, setAlcoholList] = useState(ProductAlcoholSelectList);
  const [volumeList, setVolumeList] = useState([]);
  const [grapeList, setGrapeList] = useState([]);
  const [oriGrapeList, setOriGrapeList] = useState([]);

  useEffect(() => {
    setConfigData();
  }, []);

  useEffect(() => {
    if (countryList && countryList.length > 0) {
      console.log("filterRegion", filterRegion);
      if (filterRegion&&filterRegion.value) {
        let ids = [filterRegion?.value];
        onLoadAllRegionList(ids);
      } else {
        let ids = [];
        countryList.map((item) => {
          ids.push(item.value);
        });
        onLoadAllRegionList(ids);
      }
    }
  }, [countryList, filterRegion]);

  const handleSubmit = (e) => {
    alert();
  };

  const setConfigData = async () => {
    await initProductGrapes();
    let configData = await loadProductConfig();
    let { locations, volumes, types, categories, tags, classifications } =
      configData;
    setLocationList(locations);
    setCountryList(getCountryAndCityForSelect(locations, currentLanguage));
    let tempCategoryList = getProductCategoryTypeForSelect(
      categories,
      types,
      currentLanguage
    );
    if (tempCategoryList && tempCategoryList.length > 0) {
      tempCategoryList[0].selected = true;
    }
    setClassificationList(
      getProductConfigForSelect(classifications, currentLanguage)
    );
    let list = getProductConfigNameForSelect(volumes, currentLanguage);
    list = list.map((item) => {
      return { ...item, label: item.label + "ML" };
    });
    list.push({ label: t("SAMPLES"), value: ProductSampleValue });
    setVolumeList(list);
  };

  const onLoadAllRegionList = (coutry_vals) => {
    let list = [];
    coutry_vals.map((coutry_val) => {
      let result = getLocationsByParentId(
        locationList,
        coutry_val,
        currentLanguage
      );
      list.push(...result);
    });

    setSubRegionList(list);
    setOriRegionList(deepClone(list));
  };

  const initProductGrapes = async () => {
    let pms = {
      pms: JSON.stringify({
        page: 0,
        per_page: 1000,
      }),
    };
    let result = await getProductGrapes(pms);
    let list = getProductConfigForSelect(result, currentLanguage);
    setGrapeList(list);
    setOriGrapeList(list);
  };

  const onFilterListItemSelected = (dataSource, setDataSource, key, item) => {
    console.log(item);
    let temp = deepClone(dataSource);
    temp.map((mapItem) => {
      if (Array.isArray(mapItem.value)) {
        JSON.stringify(mapItem.value) === JSON.stringify(item.value)
          ? (mapItem.selected = true)
          : (mapItem.selected = false);
      } else {
        if (mapItem.value == item.value) {
          mapItem.selected = true;

          let tempSearchForm = deepClone(searchForm);
          tempSearchForm[key] = mapItem.value;
          setSearchForm(tempSearchForm);
        } else {
          mapItem.selected = false;
        }
      }
    });
    setDataSource(temp);
  };

  const onFilterSearchChange = (
    dataSource,
    setDataSource,
    oriDataSource,
    e
  ) => {
    let temp = deepClone(dataSource);
    temp = [];
    if (e && e.length > 0) {
      oriDataSource.map((mapItem) => {
        if (mapItem.label.toUpperCase().indexOf(e.toUpperCase()) != -1) {
          temp.push(deepClone(mapItem));
        }
      });
    } else {
      temp = deepClone(oriDataSource);
    }
    console.log(oriDataSource);
    setDataSource(temp);
  };

  const onConfirmClick = () => {
    onConfirm && onConfirm(searchForm);
  };

  const onResetClick = () => {
    onReset && onReset();
  };

  const onKeywordChange = (e) => {
    console.log(e);
    setSearchKeyword(e);

    let temp = deepClone(searchForm);
    temp["keyword"] = e;
    setSearchForm(temp);
  };

  return (
    <div className={styles.settings}>
      <Form
        className={styles.form}
        value={searchKeyword}
        setValue={onKeywordChange.bind(this)}
        onSubmit={() => handleSubmit()}
        placeholder="Search for products"
        type="text"
        name="search"
        icon="search"
      />
      {subRegionList.length > 0 && (
        <FilterItem
          title={t("SUB_REGION")}
          dataSource={subRegionList}
          showSearch={true}
          onSearch={onFilterSearchChange.bind(
            this,
            subRegionList,
            setSubRegionList,
            oriRegionList
          )}
          onSelected={onFilterListItemSelected.bind(
            this,
            subRegionList,
            setSubRegionList,
            "subRegion"
          )}
        />
      )}
      <FilterItem
        title={t("CLASSIFICATIONS")}
        dataSource={classificationList}
        onSelected={onFilterListItemSelected.bind(
          this,
          classificationList,
          setClassificationList,
          "region"
        )}
      />
      <FilterItem
        title={t("ALCOHOL")}
        dataSource={alcoholList}
        onSelected={onFilterListItemSelected.bind(
          this,
          alcoholList,
          setAlcoholList,
          "alcohol"
        )}
      />
      <FilterItem
        title={t("BOTTLE_FORMAT")}
        dataSource={volumeList}
        onSelected={onFilterListItemSelected.bind(
          this,
          volumeList,
          setVolumeList,
          "volume"
        )}
      />
      <FilterItem
        title={t("GRAPE")}
        dataSource={grapeList}
        showSearch={true}
        onSearch={onFilterSearchChange.bind(
          this,
          grapeList,
          setGrapeList,
          oriGrapeList
        )}
        onSelected={onFilterListItemSelected.bind(
          this,
          grapeList,
          setGrapeList,
          "grape"
        )}
      />
      <div className={styles.group}>
        <div className={styles.btns}>
          <button
            className={cn("button-stroke", styles.button)}
            onClick={onResetClick.bind(this)}
          >
            {t("RESET")}
          </button>
          <button
            className={cn("button", styles.button)}
            onClick={onConfirmClick.bind(this)}
          >
            {t("CONFIRM")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
