const apiPath = {
  login: "auth/login",
  wxLogin: "auth/wxlogin",
  loginByUserId: "auth/loginByUserId",
  wxAccessToken: "auth/getwxaccesstoken",
  wxUserInfo: "auth/getwxuserinfo",
  users: "users",
  userNamesById:"users/userNamesById",
  createGuest: "users/createGuest",
  convertGuestToUser: "users/convertGuestToUser",
  userUpdate: "users/updateUser",
  userResetPassword: "users/resetPassword",
  userPage: "users/userPage",
  userMain: "users/mainuser",
  userExists: "users/exists",
  userSupplierList: "Company/supplierList",
  adminSupplierList: "Company/adminSupplierList",
  adminBuyerList: "Company/adminBuyerList",
  buyerSearch: "Company/buyerSearch",
  sendSms: "Sms",
  sendEmail: "Email/sendEmail",
  getEmailCapture: "Email/getCapture",
  sendResetPasswordEmail: "Email/sendResetPasswordEmail",
  resetPwd: "users/resetPwd",
  resetPasswordByEmail: "users/resetPasswordByEmail",
  passwordSetting: "users/passwordSetting",
  feeds: "Feeds/feeds",
  feedsGet: "Feeds/getFeeds",
  feedsGetDraft: "Feeds/getFeedsDraft",
  feedImages: "Feeds/feedImages",
  feedVideos: "Feeds/feedVideos",
  feedsCount: "Feeds/feedmedia",
  feedRemove: "Feeds/removeFeed",
  feedUserLikes: "Feeds/userLikes",
  recommandFeeds: "Feeds/recommandFeeds",
  recommandTenders: "Feeds/recommandTenders",
  recommandArticles: "Feeds/recommandArticles",
  feedCreate: "Feeds/createFeed",
  feedDetail: "Feeds/findFeed",
  feedLikes: "Feeds/findLikes",
  feedComments: "Feeds/findComments",
  feedForwards: "Feeds/findForwards",
  recommandUsers: "Feeds/recommandUsers",
  feedLikeCreate: "Feeds/like",
  feedCommentCreate: "Feeds/createComment",
  feedCommentUpdate: "Feeds/updateFeedComment",
  feedCommentsReceived: "Feeds/receivedComments",
  feedMentionReceived: "Feeds/receivedMentions",
  feedMentionUpdate: "Feeds/updateFeedMention",
  feedReport: "Feeds/report",
  feedFavoriteAdd: "/Feeds/addFavorite",
  feedFavoriteRemove: "/Feeds/removeFavorite",
  feedFavoriteStatus: "/Feeds/favoriteStatus",
  feedFavoriteList: "/Feeds/listFavorite",
  createMessageTranslation: "/Feeds/createMessageTranslation",
  messageTranslationInfo: "/Feeds/messageTranslationInfo",
  uploadFile: "file/upload",
  uploadToCloudflare: "file/upload_to_cloudflare",
  openuploadFile: "file/openupload",
  editorupload: "file/editorupload",
  friends: "users/friends",
  verifyAdd: "users/cn/verify",
  verifyUpdate: "users/cn/updateVerify",
  updateCompanyDesc: "users/updateCompanyDesc",
  verifyFind: "users/findVerify/",
  userSearch: "users/searchImporter/",
  userFriendSetOnTop: "UserFriend/setOnTop",
  userFriendSetBlock: "UserFriend/setBlock",
  tendersUser: "Tender/userTenders",
  tenderCreate: "Tender/createTender",
  tenderConfig: "Tender/tenderConfig",
  tenderUpdate: "Tender/updateTender",
  tenderRemove: "Tender/removeTender",
  tenderDetail: "Tender/detail",
  tenderDemandOffers: "Tender/demandoffers",
  tenderDemandOfferUpdateStatus: "/Tender/setDemandOfferStatus",
  tenderDemandOfferCreate: "Tender/createDemandOffer",
  tenderDemandOfferCancel: "Tender/cancelDemandOffer",
  tenderDemandOfferUpdate: "Tender/updateDemandOffer",
  applyFriendCreate: "Contact/applyFriend",
  applyFriendList: "Contact/friendApplies",
  applyFriendSelf: "Contact/myApplies",
  applyFriendHandle: "Contact/handleApply",
  removeFriend: "Contact/removeFriend",
  contactFriends: "Contact/friends",
  contactFriendStatus: "Contact/friendStatus",
  contactFollowStatus: "Contact/followStatus",
  contactOnLineStatus: "Contact/usersStatus",
  follow: "Contact/follow",
  contactUserFollowCount: "Contact/userFollowCount",
  contactUserFansCount: "Contact/userFansCount",
  contactUserFans: "Contact/userFans",
  blockUser: "Contact/blocksUsers",
  blockUserList: "Contact/getBlocksUsers",
  // subscriptionTypes: 'Subscription/subscriptionTypes',
  // subscriptions: 'Subscription/subscriptions',
  // subscriptionCreate: 'Subscription/createSubscription',
  // subscriptionDetail: 'Subscription/subscription',
  // subscriptionUpdate: 'Subscription/updateSubscription',
  subscriptionTypes: "Subscribe/list",
  subscriptions: "UserSubscribe/list",
  subscriptionCreate: "UserSubscribe/create",
  subscriptionDetail: "UserSubscribe/detail",
  subscriptionUpdate: "UserSubscribe/update",
  productCategories: "Product/categories",
  productCategoryCreate: "Product/createCategory",
  productCategoryRemove: "Product/removeCategory",
  productCategoryUpdate: "Product/updateCategory",
  productConfig: "Product/props",
  productCreate: "Product/createProduct",
  productUpdate: "Product/updateProduct",
  productRemove: "Product/removeProduct",
  products: "Product/products",
  openProducts: "open/products",
  productsCount: "Product/productsCount",
  productSearch: "Product/search",
  productStocks: "Product/productstocks",
  productWarehouseStock: "Product/productwarehousestock",
  productDetail: "Product/detail",
  productTypes: "/Product/types",
  productTags: "Product/tags",
  productVintages: "Product/vintages",
  productVintageBind: "Product/bindVintage",
  productVintageUnBind: "Product/unBindVintage",
  productUpdateStatus: "Product/updateProductStatus",
  productGrapes: "Product/grapes",
  productGrspeDetail: "Product/grapeDetail",
  productExchangeRate: "Product/exchangeRate",
  warehousesAll: "Product/allWarehouses",
  warehouses: "Product/warehouses",
  warehouseCreate: "Product/createWarehouse",
  warehouseUpdate: "Product/updateWarehouse",
  warehouseRemove: "Product/removeWarehouse",
  stocks: "Product/stocks",
  stockCreate: "Product/createStock",
  stockUpdate: "Product/updateStock",
  stockRemove: "Product/removeStock",
  productClickCreate: "Product/createClick",
  productClicks: "Product/productClicks",
  productFavorite: "Product/favorite",
  productFavorites: "Product/productFavorites",
  productFavoriteCount: "Product/favoriteCount",
  productFavoriteStatus: "Product/favoriteStatus",
  productsFilterItems: "Product/productsFilterItems",
  recipients: "Recipient/recipients",
  recipientCreate: "Recipient/createRecipient",
  recipientUpdate: "Recipient/updateRecipient",
  recipientRemove: "Recipient/removeRecipient",
  recipientDefault: "Recipient/defaultRecipient",
  recipientSetDefault: "Recipient/recipientSetDefault",
  awards: "Product/awards",
  awardCreate: "Product/createAward",
  awardUpdate: "Product/updateAward",
  awardRemove: "Product/removeAward",
  productRatings: "Product/ratings",
  productRatingCreate: "Product/createrating",
  productRatingRemove: "Product/removerating",
  productRatingUpdate: "Product/updaterating",
  productRatingDetail: "Product/ratingdetail",
  highRatings: "Product/highRatings",
  highRatingCreate: "Product/createHighRating",
  highRatingRemove: "Product/removeHighRating",
  notes: "Product/notes",
  noteCreate: "Product/createNote",
  noteRemove: "Product/removeNote",
  companyInfo: "users/companyInfo",
  profileClickCreate: "Users/createClick",
  profileClicks: "Users/profileClicks",
  saveCompanyInfo: "Company/saveCompanyInfo",
  addCompanyInfo:"Company/addCompanyInfo",
  removeCompanyInfo:"Company/removeCompanyInfo",
  wineryDescInfo: "company/wineryDescInfo",
  mediaDescInfo: "company/mediaDescInfo",
  importerDescInfo: "company/importerDescInfo",
  saveBankInfo: "Company/saveBankInfo",
  bankInfo: "company/bankInfo",
  companyUsers: "Company/companyusers",
  companyMainUser: "Company/companyMainUser",
  companyInvoiceInfo: "Company/companyInvoiceInfo",
  CompanyInvoiceInfoUpdate: "Company/updateInvoiceInfo",
  companyBrandSafetyCreate: "Company/createBrandSafety",
  companyBrandSafetyList: "Company/brandSafetyList",
  staffList: "Permission/staff",
  createStaff: "Permission/createStaff",
  removeStaff: "Permission/removeStaff",
  updateStaff: "Permission/updateStaff",
  discountCreate: "Discount/createDiscount",
  discounts: "Discount/discounts",
  discountDetail: "Discount/detail",
  discountProductDetail: "Discount/detailproduct",
  discountUpdate: "Discount/updateDiscount",
  discountRemove: "Discount/removeDiscount",
  discountEvents: "Discount/discountevents",
  imGetToken: "Message/getToken",
  orders: "/Order/orders",
  orderGroupByImporterCoId: "/Order/importerGroupOrders",
  orderCreate: "/Order/createOrder",
  orderCancel: "/Order/cancelOrder",
  orderUpdate: "/Order/updateOrder",
  orderRemove: "/Order/removeOrder",
  orderDetail: "/Order/details",
  orderDetailsByRelationId: "/Order/detailByRelationId",
  orderConfirm: "/Order/confirmOrder",
  orderImporterProducts: "/Order/importerorderproducts",
  orderImporterPay: "Order/importerorderpay",
  orderSampleCount: "Order/importerordersamplecount",
  orderBillCreate: "Order/createOrderBill",
  orderBillUpdate: "Order/updateOrderBill",
  orderBillRemove: "Order/removeOrderBill",
  orderBills: "Order/orderBills",
  orderBillSetDefault: "Order/orderBillSetDefault",
  sendSampleCreate: "Order/createSendSample",
  sendSampleUpdate: "Order/updateSendSample",
  sendSampleRemove: "Order/removeSendSample",
  sendSamples: "Order/sendSamples",
  carts: "/Order/carts",
  cartsByProduct: "Order/productcarts",
  cartCreate: "/Order/createCart",
  cartUpdate: "/Order/updateCart",
  cartRemove: "/Order/removeCart",
  keywordProducts: "/Keyword/keywordProducts",
  locations: "/Location/locations",
  locationCountries: "/Location/countries",
  locationAreas: "/Location/areas",
  meetingCreate: "/Meeting/createMeeting",
  meetings: "/Meeting/meetings",
  meetingChatToken: "/Meeting/generateRTCToken",
  imGroups: "/Group/groups",
  imGroupCreate: "/Group/createGroup",
  imGroupMemberAdd: "/Group/addMember",
  imGroupMemberRemove: "/Group/removeMember",
  imGroupInfo: "/Group/members",
  imGroupRemove: "Group/removeGroup",
  imGroupUpdateName: "/Group/updateName",
  stripeCreateCheckoutSession: "/stripe/create-checkout-session",
  stripeCreateExpressAccount: "/stripe/create-express-account",
  stripeGetAccountInfo: "/stripe/get-stripe-account-info",
  stripeUpgradeCompanyInfo: "/stripe/upgrade-company-info",
  translate: "Translate/translate",
  translateContent: "Translate/translateContent",
  wxPay: "Payment/wxpay",
  aliPay: "Payment/alipay",
  browseRecordVisitedMe: "BrowseRecord/visitedMe",
  browseRecordCreate: "BrowseRecord/create",
  browseRecordPage: "BrowseRecord/page",
  browseRecordRemove: "BrowseRecord/remove",
  emailVerify: "/users/emailVerify",
  phoneVerify: "/users/phoneVerify",
  reportingUser: "/Contact/reportingUser",
  reportingUserlist: "/Contact/reportingUserList",
  logisticsList: "/Logistics/list",
  actionLogger: "/UserNavigationHistory",
  article: "Article/articles",
  oneArticle: "Article/oneArticle",
  articleCreate: "Article/create",
  articleUpdate: "Article/updateArticle",
  articleRemove: "Article/removeArticle",
  articleDetail: "Article/detailArticle",
  articleMediaList: "Article/articleMediaList",
  customerWinerys: "/Customer/winerylist",
  customerImporters: "/Customer/importerlist",
  searchPage: "/Search/searchpage",
  searchSelectList: "/Search/search",
  searchHistoryAdd:"/Search/addHistory",
  searchHistorys:"/Search/searchHistorys",
  closeAccount: "/users/closeAccount/confirm",
  favoriteAdd: "/Favorite/add",
  favoriteRemove: "/Favorite/remove",
  favoriteStatus: "/Favorite/status",
  favoriteList: "/Favorite/list",
  favoritePageList: "/Favorite/pagelist",
  onboardingGet: "/onboarding/get",
  onboardingUpdate: "/onboarding/update",
  searchImporterSearch: "searchImporter/search",
  eventList: "Events/list",
  eventDetail: "Events/detail",
  eventCreate: "Events/create",
  eventUpdate: "Events/update",
  eventRemove: "Events/remove",
  eventComEvents: "Events/comEvents",
  eventSuggests: "Events/suggestEvents",
  configProduct: "config/productconfig",
  sponsorPage: "Sponsors/page",
  roles: "Roles/roles",
  activityList: "Activities/list",
  activityDetail: "Activities/detail",
  activityUserJoin: "Activities/userJoin",
  userActivities: "Activities/userActivities",
  activityProducts: "Activities/products",
  userLimitList: "UserLimit/list",
  roleCreate: "Roles/createRole",
  rolesCompanyRoles: "Roles/companyRoles",
  operationScheduleCreate: "OperationSchedule/create",
  operationScheduleList: "OperationSchedule/list",
  operationScheduleRemove: "OperationSchedule/remove",
  operationScheduleCancel: "OperationSchedule/cancel",
  openFeeds: "open/feeds",
  openCompanyInfo: "open/companyInfo",
  companyInfoByName: "open/companyInfoByName",
  openWineryDescInfo: "open/wineryDescInfo",
  openImporterDescInfo: "open/importerDescInfo",
  openRecommandUsers: "open/recommandUsers",
  openSponrsors: "open/getSponrsors",
  openShopifyStorefrontAccesstoken: "open/shopify/storefront/accesstoken",
  amapSearch:
    "https://restapi.amap.com/v3/assistant/inputtips?output=json&key=0aeb583a6e2eaea5bf447efa7807d0a8",
  amityChannelId:'amity/channel/amityChannelId',
  amityCreateChannel:'amity/channel/createChannel',
  amityChannelInfo:'amity/channel/channelInfo'
};
export default apiPath;
