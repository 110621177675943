import { ProductCurrencyData } from "./config";
import { FormControls } from "./constants";
import { SysEnvLanguageType } from "./env.enum";

export enum UserType {
  SUPPLIER = "SUPPLIER",
  BUYER = "BUYER",
  SUPPLIERBUYER = "SUPPLIERBUYER",

  ALL = "ALL",
  ADMIN="ADMIN"
}

export enum UserLabel {
  MEDIA = "MEDIA",
  //Winery
  WINERY = "WINERY",
  FAMILY_ESTATE = "FAMILY_ESTATE",
  //Cooperative
  COOPERATIVE = "COOPERATIVE",
  //Negociant
  NEGOCIANT = "NEGOCIANT",
  //Importer
  IMPORTER = "IMPORTER",
  //Distributor
  DISTRIBUTOR = "DISTRIBUTOR",
  //Corporate Business
  CORPORATEBUSINESS = "CORPORATEBUSINESS",
}

export const companySettingBuyerBasicFields = [
  {
    name: "location",
    label: "COMPANY_ADDRESS",
    type: FormControls.ADDRESSINPUT,
  },
  {
    name: "label",
    label: "COMPANY_CATEGORY",
    type: FormControls.RADIOGROUP,
    data: [
      {
        label: "IMPORTER",
        value: "IMPORTER",
      },
      {
        label: "DISTRIBUTOR",
        value: "DISTRIBUTOR",
      },
      {
        label: "CORPORATEBUSINESS",
        value: "CORPORATEBUSINESS",
      },
    ],
  },
];

export const companySettingSupplierBasicFields = [
  {
    name: "coName",
    label: "COMPANY_NAME",
    type: FormControls.TEXT,
  },
  {
    name: "location",
    label: "COMPANY_ADDRESS",
    type: FormControls.ADDRESSINPUT,
  },
  {
    name: "label",
    label: "COMPANY_CATEGORY",
    type: FormControls.SELECT,
    data: [
      { label: "FAMILY_ESTATE", value: "FAMILY_ESTATE" },
      { label: "WINERY", value: "WINERY" },
      { label: "COOPERATIVE", value: "COOPERATIVE" },
      { label: "NEGOCIANT", value: "NEGOCIANT" },
    ],
  },
];

export const userLabelFields = {
  buyer: {
    importerFields: [
      {
        name: "productCategory",
        title: "INTERNETING_PRODUCT_CATEGORY",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "mainImportingCountry",
        title: "IMPORTING_COUNTRY",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "importingVolume",
        title: "WHAT_IS_YOUR_AVERAGE_IMPORTING_YEAR",
        type: FormControls.NUMBERUNIT,
      },
      {
        name: "purchaseAmount",
        label: "NUMBER_OF_PRODUCT_UNIT",
        type: FormControls.TEXTEXT,
        after: "TEN_THOUSAND",
      },
      {
        name: "distributionChannel",
        title: "WHAT_IS_YOUR_MAIN_SALES_CHANNEL",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "businessCitys",
        title: "WHAT_CITIES_ARE_YOUR_BUSINESS_INVOLVED",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "numberOfEmployees",
        label: "NUMBER_OF_EMPLOYEES",
        type: FormControls.TEXT,
      },
    ],
    distributorFields: [
      {
        name: "productCategory",
        title: "INTERNETING_PRODUCT_CATEGORY",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "mainImportingCountry",
        title: "IMPORTING_COUNTRY",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "importingVolume",
        title: "WHAT_IS_YOUR_AVERAGE_IMPORTING_YEAR",
        type: FormControls.NUMBERUNIT,
      },
      {
        name: "purchaseAmount",
        label: "NUMBER_OF_PRODUCT_UNIT",
        type: FormControls.TEXTEXT,
        after: "TEN_THOUSAND",
      },
      {
        name: "distributionChannel",
        title: "WHAT_IS_YOUR_MAIN_SALES_CHANNEL",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "businessCitys",
        title: "WHAT_CITIES_ARE_YOUR_BUSINESS_INVOLVED",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "numberOfEmployees",
        label: "NUMBER_OF_EMPLOYEES",
        type: FormControls.TEXT,
      },
    ],
    businessFields: [
      {
        name: "productCategory",
        title: "INTERNETING_PRODUCT_CATEGORY",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "mainImportingCountry",
        title: "IMPORTING_COUNTRY",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "importingVolume",
        title: "WHAT_IS_YOUR_AVERAGE_IMPORTING_YEAR",
        type: FormControls.NUMBERUNIT,
      },
      {
        name: "purchaseAmount",
        label: "NUMBER_OF_PRODUCT_UNIT",
        type: FormControls.TEXTEXT,
        after: "TEN_THOUSAND",
      },
      // {
      //   name: "distributionChannel",
      //   title: "WHAT_IS_YOUR_MAIN_SALES_CHANNEL",
      //   type: FormControls.SELECTMULTIPLE,
      // },

      {
        name: "importingCount",
        label: "WHAT_IS_YOUR_COUNT_IMPORTING_YEAR",
        type: FormControls.TEXT,
      },
      {
        name: "numberOfEmployees",
        label: "NUMBER_OF_EMPLOYEES",
        type: FormControls.TEXT,
      },
    ],
  },
  supplier: {
    wineryFields: [
      {
        name: "productCategory",
        title: "PRODUCT_CATEGORY",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "product_area",
        title: "PRODUCT_AREA",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "size",
        label: "VINEYARD_SIZE",
        type: FormControls.NUMBER,
        unit: "YARD",
      },
      {
        name: "production",
        title: "TOTAL_PRODUCTION",
        type: FormControls.NUMBERUNIT,
      },
      {
        name: "brandAmount",
        label: "NUMBER_OF_BRAND",
        type: FormControls.NUMBER,
      },
      {
        name: "exportionVolume",
        title: "WHAT_IS_YOUR_AVERAGE_EXPORT_YEAR",
        type: FormControls.NUMBERUNIT,
      },
      {
        name: "market",
        title: "MAIN_MARKET",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "privateLabel",
        label: "CAN_PROVIDE_PRODUCT_WITH_PRIVATE_LABEL",
        type: FormControls.RADIOGROUP,
      },
    ],
    cooperativeFields: [
      {
        name: "size",
        label: "VINEYARD_SIZE",
        type: FormControls.NUMBER,
        unit: "YARD",
      },
      {
        name: "production",
        title: "TOTAL_PRODUCTION",
        type: FormControls.NUMBERUNIT,
      },
      {
        name: "exportionVolume",
        title: "WHAT_IS_YOUR_AVERAGE_EXPORT_YEAR",
        type: FormControls.NUMBERUNIT,
      },
      {
        name: "brandAmount",
        label: "NUMBER_OF_BRAND",
        type: FormControls.NUMBER,
      },
      {
        name: "productCategory",
        title: "PRODUCT_CATEGORY",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "market",
        title: "MAIN_MARKET",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "product_area",
        title: "PRODUCT_AREA",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "privateLabel",
        label: "CAN_PROVIDE_PRODUCT_WITH_PRIVATE_LABEL",
        type: FormControls.RADIOGROUP,
      },
    ],
    negociantFields: [
      {
        name: "size",
        label: "VINEYARD_SIZE",
        type: FormControls.NUMBER,
        unit: "YARD",
      },
      {
        name: "production",
        title: "TOTAL_PRODUCTION",
        type: FormControls.NUMBERUNIT,
      },
      {
        name: "exportionVolume",
        title: "WHAT_IS_YOUR_AVERAGE_EXPORT_YEAR",
        type: FormControls.NUMBERUNIT,
      },
      {
        name: "brandAmount",
        label: "NUMBER_OF_BRAND",
        type: FormControls.NUMBER,
      },
      {
        name: "productCategory",
        title: "PRODUCT_CATEGORY",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "market",
        title: "MAIN_MARKET",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "product_area",
        title: "PRODUCT_AREA",
        type: FormControls.SELECTMULTIPLE,
      },
      {
        name: "privateLabel",
        label: "CAN_PROVIDE_PRODUCT_WITH_PRIVATE_LABEL",
        type: FormControls.RADIOGROUP,
      },
    ],
  },
};

export const userTypeFields = {
  buyer: [
    {
      label: "IMPORTER",
      value: UserLabel.IMPORTER,
    },
    {
      label: "DISTRIBUTOR",
      value: UserLabel.DISTRIBUTOR,
    },
    {
      label: "CORPORATEBUSINESS",
      value: UserLabel.CORPORATEBUSINESS,
    },
  ],
  supplier: [
    {
      label: "WINERY",
      value: UserLabel.WINERY,
    },
    {
      label: "IMPORTER",
      value: UserLabel.IMPORTER,
    },
    {
      label: "COOPERATIVE",
      value: UserLabel.COOPERATIVE,
    },
    {
      label: "NEGOCIANT",
      value: UserLabel.NEGOCIANT,
    },
    {
      label: "DISTRIBUTOR",
      value: UserLabel.DISTRIBUTOR,
    },
    {
      label: "CORPORATEBUSINESS",
      value: UserLabel.CORPORATEBUSINESS,
    },
  ],
};

export const chinaCompanyVerifyFiels = {
  basicInfo: [
    {
      name: "coName",
      label: "COMPANY_NAME",
      type: FormControls.TEXT,
      descT: "INPUT_COMPANY_NAME_DESC",
    },
    {
      name: "coNameEn",
      label: "COMPANY_NAME_EN",
      type: FormControls.TEXT,
    },
    {
      name: "location",
      label: "COUNTRY",
      language: SysEnvLanguageType.ZH,
      type: FormControls.ADDRESSINPUT,
    },
    {
      name: "locationEn",
      label: "COUNTRY",
      language: SysEnvLanguageType.EN,
      type: FormControls.ADDRESSINPUT,
    },
    {
      name: "zipcode",
      label: "ZIPCODE",
      type: FormControls.TEXT,
    },
    {
      name: "email",
      label: "EMAIL",
      type: FormControls.EMAIL,
      descT: "INPUT_COMPANY_EMAIL_DESC",
    },
    {
      name: "website",
      label: "WEBSITE_OPTION",
      type: FormControls.TEXT,
    },
    {
      name: "userName",
      label: "CONTACT_USER_NAME",
      type: FormControls.TEXT,
    },
    {
      name: "phoneNumber",
      label: "COMPANY_CONTACT_PHONE",
      type: FormControls.TEXT,
    },
  ],
  detailInfo: [
    {
      name: "userName",
      label: "CONTACT_USER_NAME",
      type: FormControls.TEXT,
    },
    {
      name: "gender",
      label: "GENDER",
      type: FormControls.RADIOGROUP,
      data: [
        {
          label: "MALE",
          value: true,
        },
        {
          label: "FEMALE",
          value: false,
        },
      ],
    },
    {
      name: "phoneNumber",
      label: "CONTACT_PHONE",
      type: FormControls.TEXT,
    },
    {
      name: "location",
      label: "COUNTRY",
      type: FormControls.ADDRESSINPUT,
    },
    {
      name: "wechatId",
      label: "WECHAT_ID",
      type: FormControls.TEXT,
    },
    {
      name: "address",
      label: "DETAIL_ADDRESS",
      type: FormControls.ADDRESSSELECT,
    },
  ],
  licenseInfo: [
    // {
    //   name: "chargeId",
    //   label: "PERSON_IN_CHARGE_ID",
    //   type: FormControls.TEXT,
    // },
    {
      name: "chargeLicenseCopy",
      type: FormControls.UPLOADUSERIDCOPY,
    },
    // {
    //   name: "coNo",
    //   label: "INPUT_COMPANY_LICENSE_ID",
    //   type: FormControls.TEXT,
    // },
    {
      name: "companyLicenseCopy",
      type: FormControls.UPLOADCOMPANYLICENSECOPY,
    },
  ],
  deliveryAddresses: {
    name: "deliveryAddress",
    type: FormControls.TABLE,
    spans: [4, 6, 8, 4],
    action: ["EDIT", "DELETE"],
  },
  addDeliveryAddressInfo: [
    {
      name: "location",
      label: "REGISTER_ADDRESS",
      type: FormControls.ADDRESSINPUT,
      descT: "SPECIAL_INVOICE_COMPANY_ADDRESS_TIPS",
    },
    {
      name: "contactUserName",
      label: "CARGO_RECEIVER",
      type: FormControls.TEXT,
      descT: "SPECIAL_INVOICE_COMPANY_NAME_TIPS",
    },
    {
      name: "contactPhoneNumber",
      label: "CONTACT_PHONE",
      type: FormControls.TEXT,
      descT: "SPECIAL_INVOICE_COMPANY_ID_TIPS",
    },
  ],
  invoiceInfo: [
    {
      name: "coName",
      label: "COMPANY_NAME",
      type: FormControls.TEXT,
      descT: "SPECIAL_INVOICE_COMPANY_NAME_TIPS",
    },
    {
      name: "companyId",
      label: "COMPANY_CODE",
      type: FormControls.TEXT,
      descT: "SPECIAL_INVOICE_COMPANY_ID_TIPS",
    },
  ],
  specialInvoiceInfo: [
    {
      name: "coName",
      label: "COMPANY_NAME",
      type: FormControls.TEXT,
      descT: "SPECIAL_INVOICE_COMPANY_NAME_TIPS",
    },
    {
      name: "coNo",
      label: "COMPANY_CODE",
      type: FormControls.TEXT,
      descT: "SPECIAL_INVOICE_COMPANY_ID_TIPS",
    },
    {
      name: "location",
      label: "REGISTER_ADDRESS",
      type: FormControls.ADDRESSINPUT,
      descT: "SPECIAL_INVOICE_COMPANY_ADDRESS_TIPS",
    },
    {
      name: "phoneNumber",
      label: "REGISTER_PHONE",
      type: FormControls.TEXT,
      descT: "SPECIAL_INVOICE_COMPANY_PHONE_TIPS",
    },
    {
      name: "bankName",
      label: "BANK_OF_DEPOSIT",
      type: FormControls.TEXT,
      descT: "SPECIAL_INVOICE_BANK_NAME_TIPS",
    },
    {
      name: "bankId",
      label: "BANK_ACCOUNT",
      type: FormControls.TEXT,
      descT: "SPECIAL_INVOICE_BANK_ID_TIPS",
    },
  ],
  specialInvoiceDeliveryAddress: [
    {
      name: "userName",
      label: "SPECIAL_INVOICE_CONTACT_USERNAME",
      type: FormControls.TEXT,
      descT: "SPECIAL_INVOICE_DELIVER_CONTACT_USERNAME_TIPS",
    },
    {
      name: "phoneNumber",
      label: "SPECIAL_INVOICE_CONTACT_PHONE",
      type: FormControls.TEXT,
      descT: "SPECIAL_INVOICE_DELIVER_CONTACT_PHONE_TIPS",
    },
    {
      name: "location",
      label: "SPECIAL_INVOICE_CONTACT_LOCATION",
      type: FormControls.ADDRESSINPUT,
    },
    {
      name: "zipcode",
      label: "ZIPCODE",
      type: FormControls.TEXT,
    },
    {
      name: "email",
      label: "EMAIL",
      type: FormControls.EMAIL,
      descT: "INPUT_COMPANY_EMAIL_DESC",
    },
  ],
};

export const addWarehouseFields = [
  {
    name: "userName",
    label: "CONTACT_USER_NAME",
    type: FormControls.TEXT,
  },
  {
    name: "phoneNumber",
    label: "PHONE_NUMBER",
    type: FormControls.PHONENUMBER,
  },
  {
    name: "province",
    type: FormControls.PROVINCESELECT,
  },
  {
    name: "address",
    label: "DETAIL_ADDRESS",
    type: FormControls.ADDRESSSELECT,
  },
];

export const passwordSettingFields = [
  {
    name: "oldPassword",
    label: "OLD_PASSWORD",
    type: FormControls.TEXT,
    placeholder: " ",
    textType:'password'
  },
  {
    name: "newPassword",
    label: "NEW_PASSWORD",
    type: FormControls.TEXT,
    placeholder: " ",
    textType:'password'
  },
  {
    name: "confirmPassword",
    label: "CONFIRM_THE_PASSWORD",
    type: FormControls.TEXT,
    placeholder: " ",
    textType:'password'
  },
];

export const companyBankInfoFields = [
  {
    name: "name",
    label: "BANK_OF_DEPOSIT",
    type: FormControls.TEXT,
  },
  {
    name: "address",
    label: "BANK_ADDRESS",
    type: FormControls.TEXT,
  },
  {
    name: "accountName",
    label: "BANK_ACCOUNT_NAME",
    type: FormControls.TEXT,
  },
  {
    name: "accountNo",
    label: "BANK_ACCOUNT",
    type: FormControls.TEXT,
  },
  {
    name: "swiftCode",
    label: "SWIFT_CODE",
    type: FormControls.TEXT,
  },
];
