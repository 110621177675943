import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "antd";

export default function DistributorList() {
  const { t } = useTranslation();

  return (
    <div className="common-management">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("DISTRIBUTOR_LIST")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={"normal-shadow-padding10-white"}>
        <div className="common-management-title">{t("DISTRIBUTOR_LIST")}</div>
        <div
          style={{ width: "800px", height: "600px", backgroundColor: "white" }}
        ></div>
      </div>
    </div>
  );
}
