import { RequestType } from "../constants/requestType.enum";
import { formatActionResponse, formatActionResponseArray } from "./api";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export function getPageFavorites(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.favoriteList, pms);
}

export function getPostPageFavorites(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.favoritePageList, pms);
}

export function getFavorites(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.favoriteList, pms);
}


/**
 *
 * @param pms
 */
export function addFavorite(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.favoriteAdd, pms);
}

/**
 *
 * @param pms
 */
export function getFavoriteStatus(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.favoriteStatus, pms);
}

/**
 *
 * @param pms
 */
export function removeFavorite(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.favoriteRemove, pms);
}


