import { Divider, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sendSms } from "../../../api/login";
import { toastError, toastInfo } from "../../../api/toast";
import './style/inputCapture.less';

export default function InputCapture(props) {
  const { t } = useTranslation();
  let { phoneNumber, index, onChange } = props;
  const [phone, setPhone] = useState(phoneNumber);
  const [capture, setCapture] = useState();
  let countNum = 60;
  const [countDown, setCountDown] = useState(countNum);
  const [timerStatus, setTimerStatus] = useState(false);
  let timer;

  useEffect(() => {
    setPhone(phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    if (timerStatus) {
      timer = setInterval(() => {
        setCountDown(countDown - 1);
        if (countNum === 0) {
          clearInterval(timer);
          setCountDown(60);
          setTimerStatus(false);
        } else {
          countNum--;
          setCountDown(countNum);
        }
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [timerStatus]);

  const onCaptureChange = async (e) => {
    let temp = e.target.value;
    onChange({ value: temp, status: temp === capture ? true : false }, index);
  };

  const onSendSms = async () => {
    if (phone === false) {
      return;
    }
    if (!phone) {
      toastInfo(t("PHONE_NUMBER_IS_EMPTY"));
    } else if (isNaN(Number(phone))) {
      toastError(t("INVALID_PHONE_NUMBER"));
      return;
    } else {
      toastInfo(t("CODE_SEND_SUCCESS"));
      setTimerStatus(true);
      let pms = {
        phone: phone,//'+' + phone?.code +
        smsType: "Login",
      };
      let result = await sendSms(pms);
      console.log('-----sendsms code:',result);
      if (result) {
        setCapture(result.capture);
      }
    }
  };

  return (
    <div className="capture-panel-container">
      <Input
        className=" login-input"
        type="text"
        style={{ border: "none" }}
        placeholder={t("VERIFICATION_CODE")}
        onChange={onCaptureChange.bind(this)}
        autocomplete="one-time-code"
      />
      <Divider type="vertical" className="" />
      {timerStatus ? (
        <div className="count-down">
          {t("RETRY_IN")} : {countDown} {t("SECOND")}
        </div>
      ) : (
        <div className="capture-btn" onClick={onSendSms.bind(this)}>
          {t("SEND_CODE")}
        </div>
      )}
    </div>
  );
}
