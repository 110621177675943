import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";
import UploadNormal from "../upload/UploadNormal";
import "./style/companyLicenseCopyUploader.less";
import sampleImg from "./images/sample.png";
import plusIcon from "./images/plus.svg";
import { useEffect, useState } from "react";
import { deepClone } from "../../../utils/utils";
import { localizeImg } from "../../../constants/constants";

export default function CompanyLicenseCopyUploader(props) {
  const { t } = useTranslation();
  const { label, value, index, onChange, disabled } = props;

  const [companyLicense, setCompanyLicense] = useState("");
  const [licenseModel, setLicenseModel] = useState({});

  useEffect(() => {
    if (value) {
      setLicenseModel(value);
      setCompanyLicense(localizeImg(value));
    }
  }, [value]);

  const onFileLoading = (index, percent, url) => {
    setCompanyLicense(url);
  };

  const onFileLoaded = (data) => {
    if (data.length > 0) {
      let temp = deepClone(licenseModel);
      temp = data[0];
      setLicenseModel(temp);
      onChange && onChange(temp, index);
    }
  };

  return (
    <div className="company-license-copy-uploader-panel">
      <div>
        <span className="title">{t("PLEASE_UPLOAD_COMPANY_LICENSE_COPY")}</span>
        <span className="sub-title">{t("UPLOAD_LICENSE_COPY_TITLE_TIPS")}</span>
      </div>
      <div style={{ display: "flex", marginTop: "18px" }}>
        <div style={{ marginRight: "46px" }}>
          {!disabled ? (
            <UploadNormal
              onLoading={onFileLoading.bind(this, 2)}
              onFileLoaded={onFileLoaded.bind(this)}
            >
              {!companyLicense ? (
                <div className="company-license-copy-sample">
                  <img src={plusIcon} />
                </div>
              ) : (
                <img
                  src={companyLicense}
                  className="company-license-copy-sample"
                />
              )}
            </UploadNormal>
          ) : (
            <img src={companyLicense} className="company-license-copy-sample" />
          )}
        </div>
        <img src={sampleImg} className="company-license-copy-sample" />
      </div>
    </div>
  );
}
