import { actionRequest } from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export function translate(pms: any) {
  pms.to = pms.to === "zh" ? "zh-Hans" : pms.to;
  return actionRequest(RequestType.GET, apiPath.translate, pms);
}

/**
 *
 * @param pms
 */
export function translateContent(pms: any) {
  return actionRequest(RequestType.GET, apiPath.translateContent, pms);
}

export function translateText(text: string, lang: string) {
  let pms = {
    text,
    to: lang === "zh" ? "zh-Hans" : lang,
  };
  return actionRequest(RequestType.GET, apiPath.translate, pms);
}
