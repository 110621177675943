import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../../core-digital-template/components/Card";
import Icon from "../../../../core-digital-template/components/Icon";
import { useTranslation } from "react-i18next";
import { passwordSettingFields } from "../../../../constants/usersType.enum";
import FormNormalW from "../../../../core-digital-template/components/FormNormalW";
import { useUserId } from "../../../../contexts/UserIdProvider";

const NameAndDescription = ({ className, onChange }) => {
  const { t } = useTranslation();
  const { user } = useUserId();

  const history = useHistory();

  const [fields, setFields] = useState(passwordSettingFields);

  const onFieldsChange = (fields) => {
    setFields(fields);
    onChange(fields);
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title={t("PASSWORD_SETTING")}
      classTitle="title-red"
      head={
        <Link
          className={cn("button-stroke button-small", styles.button)}
          onClick={() => history.go(-1)}
        >
          <Icon name="arrow-left" size="24" />
          <span>Back</span>
        </Link>
      }
    >
      <div className={styles.description}>
        <FormNormalW items={fields} onChange={onFieldsChange.bind(this)} />
      </div>
    </Card>
  );
};

export default NameAndDescription;
