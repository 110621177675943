import React, { useEffect, useState } from "react";
import { Breadcrumb, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import "./style/myContacts.less";
import { Link } from "react-router-dom";
import { deepClone } from "../../utils/utils";
import { getUserLikeFeeds, removeFeed } from "../../api/feed";
import FeedListRow from "../feed/FeedListRow";

export default function MyLikeFeeds() {
  const { user } = useUserId();
  const { userId } = user;
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 20;

  useEffect(() => {
    loadFans();
  }, []);

  const loadFans = async () => {
    let pms = { userId, page: pageIndex, per_page: pageSize };
    let result = await getUserLikeFeeds(pms);
    console.log("------getUserLikeFeeds :", result, pms);
    if (result && result.length > 0) {
      result = result
        .filter((item) => item.feedInfo && item.feedInfo.length > 0)
        .map((item) => item.feedInfo[0]);
      if (pageIndex > 0) {
        let list = deepClone(dataList);
        result = list.concat(list);
      }
      setDataList(result);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  const pageRefresh = () => {
    if (pageIndex === 0) {
      setPageIndex(-1);
    }
    setPageIndex(0);
  };

  const onRemoveFeedItem = async (feedItem) => {
    await removeFeed({ feedId: feedItem._id });
    pageRefresh();
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_CONTACT")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_LIKE_FEEDS")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="myContact-container common-management feed">
        <div
          className={"common-management-title"}
          onClick={() => setPageIndex(-1)}
        >
          {t("MY_LIKE_FEEDS")}
        </div>
        <div className="common-management-panel">
          <InfiniteScroll
            className="scroll-content"
            dataLength={dataList.length}
            next={handleInfiniteOnLoad.bind(this)}
            hasMore={hasMore}
          >
            <List
              className="fensi"
              itemLayout="vertical"
              size="large"
              dataSource={dataList}
              renderItem={(item) => (
                <div className="feed-list-item-container">
                  <FeedListRow
                    data={item}
                    onRefresh={pageRefresh.bind(this)}
                    onRemoveFeedItem={onRemoveFeedItem.bind(this)}
                  />
                </div>
              )}
            >
              {loading && (
                <div>
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                </div>
              )}
            </List>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
