import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import { useTranslation } from "react-i18next";

const Panel = ({ onSave }) => {
  const { t } = useTranslation();

  const onSaveClick = () => {
    onSave && onSave();
  }

  return (
    <div className={cn("panel", styles.panel)}>
      <div className={styles.info}>

      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={onSaveClick.bind(this)}>{t("SAVE")}</button>
      </div>
    </div>
  );
};

export default Panel;
