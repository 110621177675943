import "./style/rightSiderNotify.less";
import React, { useState, useEffect } from "react";
import { Layout, Skeleton } from "antd";
import adsPlaceholder from "../../../resource/images/ads/godartm-fr.png";
import Ads from "../../common/ads/Ads";

export default function RightSiderNotify() {
  const { Sider } = Layout;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Sider width={320} className="right-sider-notify-container scroll-content">
      {!loading && (
        <div className="sider-item sponsors-item normal-shadow ">
          <div className="vinbip-partner-container">
            <Ads />
          </div>
        </div>
      )}
      {loading && (
        <div
          className="sider-item sponsors-item normal-shadow sider-loading-item"
          style={{ marginTop: "20px" }}
        >
          <Skeleton.Image style={{ width: "280px", height: "280px" }} />
        </div>
      )}
    </Sider>
  );
}
