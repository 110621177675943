import React, { useEffect, useState } from "react";
import styles from "./WarehouseCreate.module.sass";
import Panel from "./Panel";
import TextInput from "../../../../../core-digital-template/components/TextInput";
import cn from "classnames";
import Card from "../../../../../core-digital-template/components/Card";
import Dropdown from "../../../../../core-digital-template/components/Dropdown";
import { useTranslation } from "react-i18next";
import { loadProductConfig } from "../../../../../api/product";
import { getCountryForSelect } from "../../../../../api/config";
import { constants, deepClone } from "../../../../../utils/utils";
import { chinaCityList } from "../../../../../constants/area-data-min";
import AddressSelect from "../../../../../components/common/location/addressSelect/AddressSelect";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { warehouseCreate, warehouseUpdate } from "../../../../../api/warehouse";
import { statusCode } from "../../../../../constants/statusCode.enum";

const WarehouseCreate = (props) => {
  const { currentWarehouse, onComplete, onChange } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState({});
  const [province, setProvince] = useState({});
  const [city, setCity] = useState({});
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [inChina, setInChina] = useState(true);

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [purchase, setPurchase] = useState({});

  const [warehouseItem,setWarehouseItem]=useState(currentWarehouse||{});

  useEffect(() => {
    // if (currentWarehouse) {
    //   setId(currentWarehouse._id);
    //   setName(currentWarehouse.name);
    //   setPhone(currentWarehouse.phone);
    //   setPhoneNumber(currentWarehouse.phoneNumber);
    //   setCountry(currentWarehouse.country);
    //   setProvince(currentWarehouse.province);
    //   setCity(currentWarehouse.city);
    //   setAddress(currentWarehouse.address);
    //   setZipcode(currentWarehouse.zipcode);
    // }
    initConfigData();
    // let crispDom = document.getElementById("crisp-chatbox");
    // crispDom.style.zIndex = 1;
  }, []);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    // console.log("locations", getCountryForSelect(locations, i18n.language));
    setCountries(
      getCountryForSelect(locations, i18n.language, user.userInfo.type)
    );

    let tempProvinces = deepClone(provinces);
    tempProvinces = deepClone(chinaCityList);
    setProvinces(tempProvinces);
  };

  const onCountryChange = (val) => {
    setCountry(val);
    setInChina(val == constants.chinaCountryId);
    let item = deepClone(warehouseItem);
    item.country = val;
    setWarehouseItem(item);
    // onChange(item);
  };

  const onProvinceChange = (val) => {
    setProvince(val);
    setCity("");

    let selectedProvinces = chinaCityList.filter(
      (filterItem) => filterItem.value == val
    );
    if (selectedProvinces && selectedProvinces.length > 0) {
      let tempCities = deepClone(cities);
      tempCities = deepClone(selectedProvinces[0].children);
      setCities(tempCities);

      let item = deepClone(currentWarehouse);
      item.province = val;
      onChange(item);
    }
  };

  const onCityChange = (val) => {
    setCity(val);
    let item = deepClone(currentWarehouse);
    item.city = val;
    onChange(item);
  };

  const onAddressChange = (val) => {
    setAddress(val);
    let item = deepClone(warehouseItem);
    item.address = val;
    setWarehouseItem(item);
    onChange(item);
  };

  const onFieldValueChange = (name, val) => {
    console.log("onFieldValueChange", name, val);
    let item = deepClone(warehouseItem);
    item[name] = val;
    setWarehouseItem(item);
    onChange(item);
  };

  const onSave = async (val) => {
    let tempPms = deepClone(warehouseItem);
    tempPms.location = {
      country: tempPms.country,
      city: [tempPms.province, tempPms.city],
      address: tempPms.address,
    };
    let pms = {
      userId: user.userId,
      coId: user.userInfo.companyId,
      ...tempPms,
    };
    console.log("pms:", pms);
    let data;
    if (currentWarehouse._id) {
      data = await warehouseUpdate(pms);
    } else {
      data = await warehouseCreate(pms);
    }
    console.log("result:", data);
    if (data && data.statusCode === statusCode.SUCCESS) {
        onComplete&&onComplete();
    }
  };

  return (
    <>
      <Card className={cn(styles.container, styles.card)}>
        <TextInput
          className={`${styles.field} mb-4`}
          label={t("SHIPPING_NAME")}
          name="title"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          onChange={onFieldValueChange.bind(this, "name")}
        />
        <TextInput
          className={`${styles.field} mb-4`}
          label={t("PHONE_NUMBER")}
          name="title"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          onChange={onFieldValueChange.bind(this, "phone")}
        />
        <div className={`${styles.dropdown_field} mb-4`}>
          <Dropdown
            className={styles.dropdown_field}
            label={t("COUNTRY")}
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            value={country}
            setValue={onCountryChange.bind(this)}
            options={countries}
          />
        </div>
        {/* {inChina && (
          <div className={`${styles.dropdown_field} mb-4`}>
            <Dropdown
              className={styles.dropdown_field}
              label={t("PROVINCE")}
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              value={province}
              setValue={onProvinceChange.bind(this)}
              options={provinces}
            />
          </div>
        )}
        {inChina && (
          <div className={`${styles.dropdown_field} mb-4`}>
            <Dropdown
              className={styles.dropdown_field}
              label={t("CITY")}
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              value={city}
              setValue={onCityChange.bind(this)}
              options={cities}
            />
          </div>
        )} */}
        <div className="form-panel mb-4">
          <div className="form-label">{t("ADDRESS")}</div>
          <div
            style={{
              border: inChina ? "1px solid #dedede" : "0px",
              width: "441px",
            }}
          >
            <AddressSelect
              country={country}
              onChange={onAddressChange.bind(this)}
            />
          </div>
        </div>
        <TextInput
          className={styles.field}
          label={t("ZIPCODE")}
          name="title"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          onChange={onFieldValueChange.bind(this, "zipcode")}
        />
        <Panel onSave={onSave.bind(this)} />
      </Card>
    </>
  );
};

export default WarehouseCreate;
