import cn from "classnames";
import styles from "./Details.module.sass";
import Icon from "../../../Icon";
import Overview from "./Overview";
import Products from "./Products";

const navigation = ["Product", "Comments"];

const Details = ({
  className,
  setValue,
  activeIndex,
  setActiveIndex,
  news,
  authorInfo,
}) => {
  const handleClick = (index) => {
    setActiveIndex(index);
    index === 0 && setValue(false);
    index === 1 && setValue(true);
  };

  return (
    <div className={cn(styles.details, className)}>
      {/* <div className={styles.head}>
        <div className={styles.nav}></div>
        <div className={styles.btns}>
          <button className={cn("button-stroke", styles.favorite)}>
            <Icon name="heart-fill" size="24" />
            <span>32</span>
          </button>
        </div>
      </div> */}
      <Overview news={news} authorInfo={authorInfo} />
      {/* <Products /> */}
    </div>
  );
};

export default Details;
