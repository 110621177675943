import React, { useState } from "react";
import { useIntl } from "react-intl";

import customizableComponent from "../../../core/hocs/customization";
import userAddIcon from "../../../../../resource/icons/instant-messaging/image.svg";

import {
  MessageComposeBarContainer,
  MessageComposeBarInput,
  // ImageMessageIcon,
  // FileMessageIcon,
  SendMessageIcon,
} from "./styles";
import UploadAmity from "../../../../common/upload/UploadAmity";

const MessageComposeBar = ({ onSubmit, onSendImageMessage }) => {
  const [textMessage, setTextMessage] = useState("");

  const key = 'updatable';

  const { formatMessage } = useIntl();

  const sendMessage = () => {
    if (textMessage === "") return;
    onSubmit(textMessage);
    setTextMessage("");
  };

  const addCustomMsg = (fileType, url, name) => {};

  const onSendCustomMessage = (liveObject) => {
    onSendImageMessage && onSendImageMessage(liveObject?.fileId);
  };

  return (
    <MessageComposeBarContainer>
      <UploadAmity
        onLoading={addCustomMsg.bind(this)}
        onLoaded={onSendCustomMessage.bind(this)}
      >
        <img
          src={userAddIcon}
          style={{
            width: "24px",
            height: "24px",
            marginLeft: "12px",
            marginTop: "12px",
            cursor: "pointer",
          }}
        />
      </UploadAmity>
      <MessageComposeBarInput
        data-qa-anchor="message-compose-bar-input"
        type="text"
        value={textMessage}
        placeholder={formatMessage({ id: "MessageComposeBar.placeholder" })}
        onChange={(e) => setTextMessage(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && sendMessage()}
      />
      <SendMessageIcon
        data-qa-anchor="message-compose-bar-send-message-button"
        onClick={sendMessage}
      />
    </MessageComposeBarContainer>
  );
};

export default customizableComponent("MessageComposeBar", MessageComposeBar);
