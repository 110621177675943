import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedTime } from "react-intl";
import { MessageType } from "@amityco/js-sdk";

import customizableComponent from "../../../core/hocs/customization";
import { backgroundImage as UserImage } from "../../../icons/User";

import Options from "./Options";
import MessageContent from "./MessageContent";

import {
  Avatar,
  AvatarWrapper,
  MessageReservedRow,
  MessageWrapper,
  MessageContainer,
  GeneralMessageBody,
  DeletedMessageBody,
  UnsupportedMessageBody,
  UserName,
  BottomLine,
  MessageDate,
} from "./styles";
import { messageTranslationInfo } from "../../../../../api/feed";
import { useTranslation } from "react-i18next";
import { SysEnvLanguageType } from "../../../../../constants/env.enum";
import { deepClone } from "../../../../../utils/utils";
import { TranslationContainer } from "../../../social/components/post/Post/styles";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const MessageBody = ({
  isDeleted,
  type,
  isSupportedMessageType,
  ...otherProps
}) => {
  if (isDeleted) {
    return (
      <DeletedMessageBody
        {...otherProps}
        data-qa-anchor="message-body-deleted"
      />
    );
  }

  if (!isSupportedMessageType) {
    return (
      <UnsupportedMessageBody
        {...otherProps}
        data-qa-anchor="message-body-unsupported"
      />
    );
  }

  return (
    <GeneralMessageBody {...otherProps} data-qa-anchor="message-body-general" />
  );
};

const Message = ({
  messageId,
  avatar,
  type,
  data,
  file,
  createdAt,
  isDeleted,
  isIncoming,
  isConsequent,
  userDisplayName,
  containerRef,
}) => {
  const { t, i18n } = useTranslation();

  const shouldShowUserName = isIncoming && !isConsequent && userDisplayName;
  const isSupportedMessageType = [
    MessageType.Text,
    MessageType.Custom,
  ].includes(type);

  const [postContent, setPostContent] = useState(data);
  const [translationInfo, setTranslationInfo] = useState();
  const [transStatus, setTransStatus] = useState(false);

  const getAvatarProps = () => {
    if (avatar) return { avatar };
    return { backgroundImage: UserImage };
  };

  useEffect(() => {
    loadMessageTranslationInfo(messageId);
  }, [messageId]);

  const loadMessageTranslationInfo = async (message_id) => {
    let pms = { message_id };
    let result = await messageTranslationInfo(pms);
    if (result && result?.translations) {
      let temp = deepClone(postContent);
      if (i18n.language == SysEnvLanguageType.ZH) {
        temp.text = result?.translations?.zh;
      } else if (i18n.language == SysEnvLanguageType.EN) {
        temp.text = result?.translations?.en;
      } else if (i18n.language == SysEnvLanguageType.FR) {
        temp.text = result?.translations?.fr;
      }
      setPostContent(temp);
      setTranslationInfo(result);
    }
  };

  const onTranslateMessage = async () => {
    setTransStatus(!transStatus);
  };

  return (
    <MessageReservedRow isIncoming={isIncoming}>
      <MessageWrapper>
        {isIncoming && (
          <AvatarWrapper>
            {!isConsequent && <Avatar {...getAvatarProps()} />}
          </AvatarWrapper>
        )}

        <MessageContainer data-qa-anchor="message">
          {shouldShowUserName && <UserName>{userDisplayName}</UserName>}
          <MessageBody
            type={type}
            isIncoming={isIncoming}
            isDeleted={isDeleted}
            isSupportedMessageType={isSupportedMessageType}
          >
            <MessageContent
              data={isIncoming ? postContent : data}
              type={type}
              file={file}
              isDeleted={isDeleted}
            />
            {!isDeleted && (
              <BottomLine>
                <MessageDate>
                  <FormattedTime value={createdAt} />
                </MessageDate>
                <Options
                  messageId={messageId}
                  data={data}
                  isIncoming={isIncoming}
                  isSupportedMessageType={isSupportedMessageType}
                  popupContainerRef={containerRef}
                />
              </BottomLine>
            )}
          </MessageBody>
          {translationInfo?.source && isIncoming && (
            <TranslationContainer onClick={onTranslateMessage.bind(this)}>
              {transStatus ? (
                <UpOutlined style={{ fontSize: "10px", marginRight: "10px" }} />
              ) : (
                <DownOutlined
                  style={{ fontSize: "10px", marginRight: "10px" }}
                />
              )}
              {t("SHOW_ORIGINAL")}
            </TranslationContainer>
          )}
          {transStatus && (
            <div
              className="feed-trans-content"
              style={{ marginBottom: "24px" }}
              dangerouslySetInnerHTML={{ __html: translationInfo?.source }}
            ></div>
          )}
        </MessageContainer>
      </MessageWrapper>
    </MessageReservedRow>
  );
};

Message.propTypes = {
  messageId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(MessageType)).isRequired,
  data: PropTypes.object.isRequired,
  createdAt: PropTypes.instanceOf(Date),
  userDisplayName: PropTypes.string,
  isDeleted: PropTypes.bool,
  isIncoming: PropTypes.bool,
  isConsequent: PropTypes.bool,
  avatar: PropTypes.string,
  containerRef: PropTypes.object.isRequired,
};

Message.defaultProps = {
  userDisplayName: "",
  avatar: "",
  isDeleted: false,
  isIncoming: false,
  isConsequent: false,
};

export default customizableComponent("Message", Message);
