import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import wiboIcon from "../../../resource/icons/logo/wibo.svg";
import { useTranslation } from "react-i18next";
import { localizeImg } from "../../../constants/constants";
import "./style/eventDetail.less";
import { goToProfilePage } from "../../../utils/utils";

export default function EventDetail(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { data, showDetail } = props;
  const [detail, setDetail] = useState(data);
  const [address, setAddress] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [website, setWebsite] = useState();
  const [avatar, setAvatar] = useState();
  const [title, setTitle] = useState();
  const [detailStatus, setDetailStatus] = useState(showDetail);
  const [picture, setPicture] = useState();
  const [desc, setDesc] = useState();
  const [shareUrl, setShareUrl] = useState();

  useEffect(() => {
    setDetail(data);
    setDetailStatus(showDetail);
    if (data) {
      let { title, address, picture, startDate,endDate, website, description } = data;
      setTitle(title);
      setPicture(picture);
      setAddress(address);
      setStartDate(startDate);
      setEndDate(endDate);
      setDesc(description);
      setWebsite(website);
      let url = `http://service.weibo.com/share/share.php?appkey=&title=${title}&url=${website}&pic=${localizeImg(picture)}&style=simple`;
      setShareUrl(url);
    }
  }, [data, showDetail]);

  const onCompanyHome = () => {
    goToProfilePage(detail.userInfo[0], history);
  };

  return (
    <div className="event-detail-container">
      <div className="event-title">{title}</div>
      <img src={localizeImg(picture)} className="event-image" />
      <div className="event-row-content">
        <span className="event-row-title">Date:</span> {startDate} ~ {endDate}
      </div>
      <div className="event-row-content">
        <span className="event-row-title">Locations:</span> {address}
      </div>
      <div className="event-row-content">
        <span className="event-row-title">Website:</span>
        <a href={website}>{website}</a>
      </div>
      <div className="event-row-content">
        <span className="event-row-title">Intro:</span>
        <div className="event-desc">{desc}</div>
      </div>
      <div className="event-row-share">
        <a href={shareUrl} target="_blank">
          <img src={wiboIcon} className="event-logo" />
        </a>
      </div>
    </div>
  );
}
