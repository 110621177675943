import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { BackTop, Layout } from "antd";
import { authValidate } from "../../api/common";
import { logUserAction } from "../../action-loggers/action-loggers";
import { useUserId } from "../../contexts/UserIdProvider";
import "./style/baseprivateroute.less";
import Page from "../../core-digital-template/components/Page";

export default function DigitalTemplateRoute({
  component: Component,
  pageTitle,
  ...rest
}) {
  const validate = authValidate();

  const { user } = useUserId();
  const { userId } = user;

  useEffect(() => {
    logUserAction(userId, rest.path, rest.computedMatch.params.id);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (validate) {
          return (
            <Page title={pageTitle}>
              <Component {...props} />
            </Page>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { refer: props.location, clearToken: true },
              }}
            />
          );
        }
      }}
    />
  );
}
