import { RequestType } from "../constants/requestType.enum";
import { formatActionResponseArray } from "./api";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export async function getSponsorPage(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.sponsorPage, pms);
}

