import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Button, Image, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useUserId } from "../../contexts/UserIdProvider";
import { toastInfo } from "../../api/toast";
import { localizeImg, TableSourceMaxCount } from "../../constants/constants";
import { dateFormat } from "../../utils/date";
import { eventRemove, getEventList } from "../../api/events";
import "./style/events.less";
import { getProductStatusText } from "../../api/common";
import { ProductStatus } from "../../constants/statusCode.enum";

export default function MyEvents() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let pms = {
      page: 0,
      per_page: TableSourceMaxCount,
      userId: user.userId,
    };
    let list = await getEventList(pms);
    if (list && list.result) {
      setData(list.result);
    }
  };

  const onRemove = async (item) => {
    let pms = { id: item._id };
    await eventRemove(pms);
    loadData();
    toastInfo(t("DELETE_SUCCESS"));
  };

  const onEdit = async (item) => {
    history.push(`/event/create`, { id: item._id });
  };

  const columns = [
    {
      title: t("EVENT_TITLE"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("PUBLISH_TIME"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => dateFormat(date),
    },
    {
      title: t("IMAGES"),
      dataIndex: "picture",
      key: ["picture"],
      render: (item) => <Image className="eventIcon" src={localizeImg(item)} />,
    },
    {
      title: t("STATUS"),
      dataIndex: "status",
      key: "status",
      render: (val) => t(getProductStatusText(val)),
    },
    {
      title: t("ACTION"),
      key: "_id",
      dataIndex: "_id",
      render: (id, item) => (
        <Space size="middle">
          {item.status !== ProductStatus.VERIFYSUCCESS && (
            <a onClick={onEdit.bind(this, item)}>{t("EDIT")}</a>
          )}
          <span className="table-action-item-split">-</span>
          <a onClick={onRemove.bind(this, item)}>{t("DELETE")}</a>
        </Space>
      ),
    },
  ];

  return (
    <div className="common-page events-page-container">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_BUSINESS")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("EVENT")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management">
        <div className="common-management-title">
          {t("EVENTS_MANAGEMENT")}
          <Button
            onClick={() => {
              history.push("/event/create");
            }}
            className="create-button"
          >
            + {t("CREATE")}
          </Button>
        </div>
        <br />
        <div className="common-management-panel">
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </div>
  );
}
