import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import cx from "classnames";
import TruncateMarkup from "react-truncate-markup";
import Skeleton from "../../../../core/components/Skeleton";
import customizableComponent from "../../../../core/hocs/customization";
import Time from "../../../../core/components/Time";
import Avatar from "../../../../core/components/Avatar";
import BanIcon from "../../../../icons/Ban";
import { backgroundImage as UserImage } from "../../../../icons/User";
import {
  Name,
  PostInfo,
  ShieldIcon,
  ModeratorBadge,
  AdditionalInfo,
  ArrowSeparator,
  PostHeaderContainer,
  PostNamesContainer,
  MessageContainer,
  UserExtendInfo,
  UserExtendItem,
} from "./styles";
import { loadProductConfig } from "../../../../../../api/product";
import { useTranslation } from "react-i18next";
import {
  getChinaCityNameById,
  getCountryNameById,
} from "../../../../../../api/config";
import locationIcon from "../../../../../../resource/icons/user-profile/location.svg";
import verifiedIcon from "../../../../../../resource/icons/user-profile/verified.svg";

export default function UIPostHeader(props) {
  const {
    avatarFileUrl,
    postAuthorName,
    postTargetName,
    timeAgo,
    isModerator,
    isEdited,
    onClickCommunity,
    onClickUser,
    hidePostTarget,
    loading,
    isBanned,
    companyDesc,
    userInfo,
  } = props;

  const { t, i18n } = useTranslation();

  const [locations, setLocations] = useState();

  useEffect(() => {
    initConfigData();
  }, []);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const renderPostNames = () => {
    return (
      <PostNamesContainer data-qa-anchor="post-header-post-names">
        <TruncateMarkup lines={3}>
          <Name
            data-qa-anchor="post-header-post-name"
            className={cx({ clickable: !!onClickUser })}
            onClick={onClickUser}
          >
            {postAuthorName}
          </Name>
        </TruncateMarkup>

        {isBanned && <BanIcon height={14} width={14} />}

        {postTargetName && !hidePostTarget && (
          <>
            <ArrowSeparator />
            <Name
              data-qa-anchor="post-header-post-target-name"
              className={cx({ clickable: !!onClickCommunity })}
              onClick={onClickCommunity}
            >
              {postTargetName}
            </Name>
          </>
        )}
      </PostNamesContainer>
    );
  };

  const renderAdditionalInfo = () => {
    return (
      <AdditionalInfo
        data-qa-anchor="post-header-additional-info"
        showTime={!!timeAgo}
      >
        {isModerator && (
          <ModeratorBadge data-qa-anchor="post-header-additional-info-moderator-badge">
            <ShieldIcon /> <FormattedMessage id="moderator" />
          </ModeratorBadge>
        )}

        {timeAgo && (
          <UserExtendInfo>
            <Time
              data-qa-anchor="post-header-additional-info-time-ago"
              date={timeAgo}
            />
            {companyDesc?.location?.country && (
              <UserExtendItem>
                <img
                  src={locationIcon}
                  style={{
                    width: "8.12px",
                    height: "9.2px",
                    marginRight: "5px",
                  }}
                />
                {getCountryNameById(
                  locations,
                  i18n.language,
                  companyDesc?.location?.country
                )}
                {/* {getChinaCityNameById(location?.city)} */}
              </UserExtendItem>
            )}
            <UserExtendItem>
              <img
                src={verifiedIcon}
                style={{
                  width: "8.12px",
                  height: "9.2px",
                  marginRight: "5px",
                }}
              />
              {t(companyDesc?.label || companyDesc?.type)}
            </UserExtendItem>
          </UserExtendInfo>
        )}

        {isEdited && (
          <MessageContainer data-qa-anchor="post-header-additional-info-edited-label">
            <FormattedMessage id="post.edited" />
          </MessageContainer>
        )}
      </AdditionalInfo>
    );
  };

  return (
    <PostHeaderContainer data-qa-anchor="post-header">
      <Avatar
        data-qa-anchor="post-header-avatar"
        avatar={avatarFileUrl}
        backgroundImage={UserImage}
        loading={loading}
        onClick={onClickUser}
      />
      <PostInfo data-qa-anchor="post-header-post-info">
        {loading ? (
          <>
            <div>
              <Skeleton width={96} style={{ fontSize: 8 }} />
            </div>
            <Skeleton width={189} style={{ fontSize: 8 }} />
          </>
        ) : (
          <>
            {renderPostNames()}
            {renderAdditionalInfo()}
          </>
        )}
      </PostInfo>
    </PostHeaderContainer>
  );
}

UIPostHeader.propTypes = {
  avatarFileUrl: PropTypes.string,
  postAuthorName: PropTypes.node,
  postTargetName: PropTypes.string,
  timeAgo: PropTypes.instanceOf(Date),
  isModerator: PropTypes.bool,
  isEdited: PropTypes.bool,
  hidePostTarget: PropTypes.bool,
  loading: PropTypes.bool,
  isBanned: PropTypes.bool,
  onClickCommunity: PropTypes.func,
  onClickUser: PropTypes.func,
};

UIPostHeader.defaultProps = {
  avatarFileUrl: "",
  postAuthorName: "",
  postTargetName: "",
  timeAgo: null,
  isModerator: false,
  isEdited: false,
  hidePostTarget: false,
  loading: false,
  isBanned: false,
  onClickCommunity: null,
  onClickUser: null,
};

// export default customizableComponent('UIPostHeader', UIPostHeader);
