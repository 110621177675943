export enum statusCode {
    SUCCESS = 200,
    EXISTED = 601,
    PARAMSINVALID = 602,
    Failed = 605,
    CreateIMUserFailed = 608,
    EmailExists = 610,
    PhoneExists = 611,
    NOTEXISTS = 7
}

export enum EnumLoginStatus {
    LOADING,
    SUCCESS,
    ERROR
}

export enum FriendApplyStatus {
    ADDED = 5,
    APPLIED = 0,
    REFUSED = 2,
    IGNORE = 1,
    NOTADDED = 99
}

export enum OrderStatus {
    WaitingForPay = 'WAITING_FOR_PAY',
    Payed = 'PAYED',
    Confirmed = 'CONFIRMED',
    Refused = 'REFUSED',
    Sendout = 'SENDOUT',
    Transiting = 'TRANSITING',
    Delivered = 'DELIVERED',
    Received = 'RECEIVED',
    Cancelled = 'CANCELLED',
    Expired = 'EXPIRED',
    Complete = 'COMPLETE',
}

export const VerifyStatusText= {
    VERIFYWAITING : "NOT_VERIFIED",
    VERIFYSUCCESS : "VERIFIED",
    VERIFYFAIL : "VERIFICATION_FAILED",
    STOCK : "ON_SALE",
    UNSTOCK : "OFF_SALE",
    DRAFT : "DRAFT",
}

export enum ProductStatus {
    VERIFYWAITING = 0,
    VERIFYFAIL = 1,
    VERIFYSUCCESS = 2,
    STOCK = 3,
    UNSTOCK = 4,
    DRAFT=5,
}

export enum PaymentType {
    WXPAY,
    ALIPAY,
}
