import { Alert, Button, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUserId } from "../../../contexts/UserIdProvider";

export default function AlertCompanyNotVerified() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const { userInfo } = user;

  if (!userInfo?.companyInfo.status == 0) {
    return (
      <Alert
        message={t("PLEASE_VERIFY_YOUR_COMPANY")}
        type="error"
        action={
          <Space>
            <Button size="small" type="ghost">
              <Link to="/company/verify-deprecated">{"GO_TO_VERIFY"}</Link>
            </Button>
          </Space>
        }
        closable
      />
    );
  } else {
    return null;
  }
}
