import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./StockList.module.sass";
import Card from "../../../../../core-digital-template/components/Card";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { loadProductConfig } from "../../../../../api/product";
import { getCountryNameById } from "../../../../../api/config";
import { useTranslation } from "react-i18next";
import {
  OrderStatus,
  statusCode,
} from "../../../../../constants/statusCode.enum";
import { warehouseRemove, warehouses } from "../../../../../api/warehouse";
import removeIcon from "../../../../../resource/icons/common/remove.svg";
import editIcon from "../../../../../resource/icons/common/edit.svg";
import Icon from "../../../../../core-digital-template/components/Icon";
import { Modal } from "antd";
import StockCreate from "../StockCreate";
import { stockRemove, stocks } from "../../../../../api/stocks";
import { localizeTitle } from "../../../../../constants/constants";
import { constants } from "../../../../../utils/utils";
import DashboardNewProduct from "../../../../../screens/dashboard/Supplier/NewProduct";

function StockList({ className }) {
  const { t, i18n } = useTranslation();
  const { user, isWinery } = useUserId();

  const [items, setItems] = React.useState([]);
  const [locations, setLocations] = React.useState([]);

  const [warehouseList, setWarehouseList] = useState([]);

  const [currentWarehouse, setCurrentWarehouse] = useState({});
  const [createModalVisible, setCreateModalVisible] = useState(false);

  useEffect(() => {
    loadWarehouse();
    initConfigData();
  }, [user]);

  useEffect(() => {
    if (warehouseList.length > 0) {
      loadData();
    }
  }, [warehouseList]);

  const loadData = async () => {
    let pms = { coId: user.userInfo.companyId };
    let data = await stocks(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      let list = data.result.result;
      if (list && list.length > 0) {
        list = list.map((item, index) => {
          let name = "";
          let warehouseNames = [];
          let prices = [];
          let inChina = false;
          if (item.productInfo && item.productInfo.length > 0) {
            name =
              localizeTitle(i18n.language, item.productInfo[0].name) +
              ", " +
              item.productInfo[0].vintage[0];
            if (item.productInfo[0].volume) {
              name += ", " + item.productInfo[0].volume + "ml";
            }
            if (item.productInfo[0].package) {
              name += ", " + item.productInfo[0].package;
            }
            if (
              item.productInfo[0].prices &&
              item.productInfo[0].prices.length > 0
            ) {
              item.productInfo[0].prices.map((price) => {
                inChina = (price.shippingAddress == constants.chinaCountryId);
                let warehouseItem = warehouseList.find(
                  (whItem) => whItem._id == price.shippingAddress
                );
                if (
                  warehouseItem && 
                  !warehouseNames.includes(warehouseItem.name)
                ) {
                  warehouseNames.push(warehouseItem.name);
                }
                prices.push(price.price);
              });
            }
          }
          return {
            ...item,
            key: index,
            name,
            warehouse: warehouseNames.join(","),
            price: prices.join("-")+" "+(inChina?"￥":"€")
          };
        });
        console.log("list:", list);
        setItems(list);
      }else{
        setItems([]);
      }
    }else{
      setItems([]);
    }
  };

  const loadWarehouse = async () => {
    let pms = { coId: user.userInfo.companyId };
    let data = await warehouses(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      let list = data.result.result;
      if (list && list.length > 0) {
        list = list.map((item, index) => {
          return { ...item, key: index };
        });
        console.log("warehouse list:", list);
        setWarehouseList(list);
      }
    }
  };

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const onDelete = async (id) => {
    let pms = { stockId: id };
    let data = await stockRemove(pms);
    // if (data && data.statusCode === statusCode.SUCCESS) {
      loadData();
    // }
  };

  const onComplete = async () => {
    loadData(user.userId);
    setCreateModalVisible(false);
  };

  const onClose = () => {};

  const onChange = (val) => {
    console.log("onChange:", val);
    setCurrentWarehouse(val);
    setCreateModalVisible(true);
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title={t("STOCKS")}
      classTitle="title-red"
      head={
        <div onClick={() => setCreateModalVisible(true)}>
          <Icon name="plus-circle" size="24" />
        </div>
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>{t("PRODUCT")}</div>
            <div className={styles.col}>{t("WAREHOUSE")}</div>
            <div className={styles.col}>{t("AVAILABLE_STOCK")}</div>
            <div className={styles.col}>{t("PRICE")}</div>
            <div className={styles.col}>{t("OPERATIONS")}</div>
          </div>
          {items.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>{x.name}</div>
              <div className={styles.col}>{x.warehouse}</div>
              <div className={styles.col}>{x.count}</div>
              <div className={styles.col}>{x.price}</div>
              <div className={styles.colActionPanel}>
                <div className={cn("button-stroke button-small", styles.item)}
                  onClick={onChange.bind(this, x)}
                >
                  <span>{t("EDIT")}</span>
                </div>
                <div className={cn("button-stroke button-small", styles.item)}
                  onClick={onChange.bind(this, x)}
                >
                  <span>{t("TRADING_CONDITIONS")}</span>
                </div>
                <div className={cn("button-stroke button-small", styles.item)}
                  onClick={onDelete.bind(this, x._id)}
                >
                  <span>{t("DELETE")}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title={t("CREATE_STOCKS")}
        visible={createModalVisible}
        footer={null}
        width={501}
        height={756}
        closable={false}
        className="warehouse-create-modal"
        onCancel={() => setCreateModalVisible(false)}
      >
        <StockCreate
          onComplete={onComplete.bind(this)}
          onClose={onClose.bind(this)}
          onChange={onChange.bind(this)}
          currentStock={currentWarehouse}
        ></StockCreate>
      </Modal>
      <Modal
        destroyOnClose={true}
        title={t("TRADING_CONDITIONS")}
        visible={createModalVisible}
        footer={null}
        width={501}
        height={756}
        closable={false}
        className="warehouse-create-modal"
        onCancel={() => setCreateModalVisible(false)}
      >
        <DashboardNewProduct
          editProductTradingCondition={true}
          isAddMode={null}
          editProductId={null}
          onEditCompleted={null}
        ></DashboardNewProduct>
      </Modal>
    </Card>
  );
}

export default StockList;
