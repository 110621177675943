import "./style/userItem.less";
import { useTranslation } from "react-i18next";
import { Badge } from "antd";
import { localizeImg } from "../../constants/constants";
import AvatarBasic from "../../components/common/avatar/AvatarBasic.js";
import { goToProfilePage } from "../../utils/utils";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { loadProductConfig } from "../../api/product";
import { getCountryNameById } from "../../api/config";

export default function UserItem(props) {
  const { detail, dotStatus, color } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  let { picture, name, nickName, _id, type, country, roleInfo } = detail;
  name = name ? name : nickName;

  const [locations, setLocations] = useState();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    initConfigData();
  }, []);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const formatName = () => {
    if (dotStatus) {
      return (
        // <Badge dot color={dotStatus === "on" ? "#15ef45" : "#eeeeee"}>
        <div className="name">{name}</div>
        // </Badge>
      );
    } else {
      return <div className="name">{name}</div>;
    }
  };

  const onCompanyHome = (userItem) => {
    // goToProfilePage(userItem, history);
    history.push("/imchat-deprecated", { id: userItem._id });
  };
  return (
    <div
      className="user-item-container"
      key={_id}
      onClick={onCompanyHome.bind(this, { type, _id })}
    >
      <AvatarBasic
        size={60}
        cname="cursor-pointer"
        picture={localizeImg(picture)}
        displayName={name}
      />
      <div className="content">
        <div className="content-name">
          {formatName()}
          <div className={"desc " + (color && "color")}>
            {roleInfo?.name == "ADMIN" ? t("ADMIN") : roleInfo?.name}
          </div>
          {/* {country && (
            <div className={"desc " + (color && "color")}>
              {t("LOCATION")} : {getCountryNameById(locations, lang, country)}
            </div>
          )} */}
        </div>
      </div>
      <div>
        {dotStatus ? (
          <div
            style={{
              color: "#78EE2F",
              fontSize: "14px",
              fontWeight: "700",
            }}
          >
            {t("ON_LINE")}
          </div>
        ) : (
          <div
            style={{
              color: "#D55053",
              fontSize: "14px",
              fontWeight: "700",
            }}
          >
            {t("OFF_LINE")}
          </div>
        )}
      </div>
    </div>
  );
}
