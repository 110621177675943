import {
  SysEnvLanguageType,
  SYS_ENV_LANGUAGE,
  CN_IMAGE_URL_PREFIX,
  GLOBAL_IMAGE_URL_PREFIX,
  FORCE_IMAGE_URL_PREFIX,
} from "./env.enum";

export const tokenExpireHour = 3;
export const ProductSampleValue = "Sample";
export const TableSourceMaxCount = 99999;
export const ProductTableMaxCount = 10000;

export const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

export const itemLayout = {
  wrapperCol: { span: 8, offset: 8 },
};

export const CacheKey = {
  tokens: "tokens",
  tenderConfig: "tenderConfig-" + process.env.REACT_APP_CACHE_VERSION_NO,
  user: "user",
  ProductConfig: "ProductConfig-" + process.env.REACT_APP_CACHE_VERSION_NO,
  ProductTags: "ProductTags-" + process.env.REACT_APP_CACHE_VERSION_NO,
  IMTOKEN: "IMToken",
};

export const discountTypeArray = [
  { label: "NORMAL", value: "NORMAL" },
  { label: "DESTOCKS", value: "DESTOCKS" },
  // { label: "DEGRESSIVE", value: "DEGRESSIVE" },
  // { label: "EVENT", value: "EVENT" },
];

export enum LoginType {
  ACCOUNTNUMBER,
  EMAIL,
  PHONECAPTURE,
  WECHAT,
  GOOGLE,
}

export enum UploadFileType {
  IMAGE,
  VIDEO,
  FILE,
}

export enum ProductConfig {
  TYPES,
  BOTTLES,
  CAPSULES,
  SEALEDS,
  VOLUMES,
  CLASSIFICATIONS,
  CATEGORIES,
  GRAPES,
  TAGS,
}

export enum FormControls {
  TEXT,
  PASSWORD,
  NUMBER,
  NUMBERUNIT,
  SELECT,
  SELECTMULTIPLE,
  TEXTAREA,
  RADIOGROUP,
  PHONENUMBER,
  CAPTURE,
  EMAIL,
  ADDRESSINPUT,
  COUNTRYSELECT,
  PROVINCESELECT,
  ADDRESSSELECT,
  TEXTEXT,
  UPLOADUSERIDCOPY,
  UPLOADCOMPANYLICENSECOPY,
  TABLE,
}

export const WxAppId = "wx74283f9c72a0c4f5";
export const WxPayAppId = "wx365a0c57c9758769";
export const WxMchId = "1615286120"; //1615286120
export const WxAppSecret = "09e96914f7ed008ad282183ef2c0b949"; //'813443f63929b658f9c5714eed798cb3';
// export const SysBaseUrl = 'https://www.test.vinexwork.com/';
// export const SysServiceUrl = 'https://api.vinexwork.com/';
export const SysBaseUrl = process.env.REACT_APP_URL;
export const SysServiceUrl = process.env.REACT_APP_BASE_URL;
export const AmityApiKey = "b0e8ea5e398ff0614e34dc1b5300138ed9008ce0bb3d3c24";

export const VinbipAdminService = {
  "6530dd1a340d76508f6524a2": "Vinbip-Bruce",
  "6530dc82340d76508f65249f": "Vinbip-Xuyi",
  "6530dce9340d76508f6524a1": "Vinbip-Pierre",
  "6530dcc7340d76508f6524a0": "Vinbip-David",
  "6534e843dbf6d7b007639d6f": "Vinbip-Zhangwei",
};

export enum ProductCurrencyType {
  USD = "$",
  EUR = "€",
  CNY = "¥",
}
export function localizePriceUnit(lang?: string) {
  lang = lang ? lang : SYS_ENV_LANGUAGE;
  let result = ProductCurrencyType.USD;
  if (lang === SysEnvLanguageType.ZH) {
    result = ProductCurrencyType.CNY;
  } else if (lang === SysEnvLanguageType.EN) {
    result = ProductCurrencyType.EUR;
  }
  return result;
}

export function localizeTitle(lng: string, title: any) {
  if (typeof title === "object") {
    let result = title?.en;
    if (lng === SysEnvLanguageType.ZH) {
      result = title?.zh;
    } else if (lng === SysEnvLanguageType.FR) {
      result = title?.fr;
    }
    return result as string;
  } else {
    return title;
  }
}

export function localizeImg(imgObj: any) {
  const domain = window.location.hostname;
  let isCnDomain = checkIsCnDomain(domain);

  if (imgObj && imgObj.cn) {
    if (isCnDomain) {
      return imgObj.cn;
    }
    return imgObj.global;
  }
  if (
    FORCE_IMAGE_URL_PREFIX.toString() !== "" &&
    imgObj &&
    imgObj.length < 200
  ) {
    return FORCE_IMAGE_URL_PREFIX + imgObj;
  }
  if (isCnDomain && imgObj && imgObj.length < 200) {
    return CN_IMAGE_URL_PREFIX + imgObj;
  }
  if (imgObj && imgObj.length < 200) {
    return GLOBAL_IMAGE_URL_PREFIX + imgObj;
  }
  return imgObj;
}

export function localizeImgs(imgs: any) {
  if (imgs && imgs.length > 0) {
    imgs = imgs.map((img: any) => {
      return localizeImg(img);
    });
  } else {
    imgs = [];
  }
  return imgs;
}

export function localizeValue(lng: string, obj: any) {
  let result = obj?.en || obj?.zh || obj?.fr;
  if (lng === SysEnvLanguageType.ZH ) {
    result = obj?.zh;
  } else if (lng === SysEnvLanguageType.FR) {
    result = obj?.fr;
  }
  if (result === undefined) {
    result = obj?.en;
  }
  if (result === undefined) {
    result = obj?.fr;
  }
  if (result === undefined) {
    result = "";
  }
  return result as string;
}

const checkIsCnDomain = (domain: string) => {
  const pattern = /\.cn$/i; // Regular expression to match ".cn" at the end of the domain
  return pattern.test(domain);
};

export enum PayType {
  Alipay = "ALIPAY",
  Wechat = "WECHAT",
  Bank = "BANK",
}

export enum ExchangeRateType {
  USD = "USD",
  EUR = "EUR",
  CNY = "CNY",
}

export const OrderProofFiles = [
  { name: "BILL_OF_LADING" },
  { name: "PACKING_LIST" },
  { name: "INVOICE" },
  { name: "CONTRACT" },
  { name: "CERTIFICATE_OF_ORIGIN" },
  { name: "HEALTH_CERTIFICATE" },
  { name: "CERTIFICATE_OF_ANALYSIS" },
  { name: "BOTTLING_OF_CERTIFICATE" },
  { name: "CERTIFICATE_OF_TREATMENT_FOR_WOODEN_PACKING" },
  { name: "FRONT_LABEL_BACK_LABEL" },
];

export class ProductPropertiesConfig {
  static body = [
    { label: "LIGHT_WINE", value: "LIGHT_WINE" },
    { label: "AGING_WINE", value: "AGING_WINE" },
    { label: "FULL_BODY_WINE", value: "FULL_BODY_WINE" },
    { label: "FRUITY_WINE", value: "FRUITY_WINE" },
    { label: "SPICY_WINE", value: "SPICY_WINE" },
    { label: "SMOOTH_WINE", value: "SMOOTH_WINE" },
    { label: "DESERT_WINE", value: "DESERT_WINE" },
  ];

  static acidity = [
    { label: "HIGH", value: "HIGH" },
    { label: "MEDIUM", value: "MEDIUM" },
    { label: "LOW", value: "LOW" },
  ];

  static package = [
    { label: "WOODEN_CASE", value: "WOODEN_CASE" },
    { label: "CARDBOARD_BOXES", value: "CARDBOARD_BOXES" },
    { label: "PLASTIC_BOTTLES", value: "PLASTIC_BOTTLES" },
    { label: "METAL_CANS", value: "METAL_CANS" },
    { label: "GLASS_BARRELS", value: "GLASS_BARRELS" },
  ];
}

export function log(...args: any[]) {
  // console.log(...args);
}
