import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Events.module.sass";
import { useUserId } from "../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import RightSider from "../../core-digital-template/components/RightSider";
import { suggestEvents } from "../../api/events";
import EventItem from "../CompInfo/EventItem";
import Slider from "react-slick";
import { localizeImg } from "../../constants/constants";
import { deepClone } from "../../utils/utils";
import ModalNews from "../../core-digital-template/components/ModalNews";

export default function Events() {
  const { t } = useTranslation();

  const [events, setEvents] = useState([]);

  const [showNewsModal, setShowNewsModal] = useState(false);
  const [activeEvent, setActiveEvent] = useState({});

  useEffect(() => {
    loadSuggestEvents();
  }, []);

  const loadSuggestEvents = async () => {
    let pms = {
      page: 0,
      per_page: 2,
    };
    let result = await suggestEvents(pms);
    if (result && result.total > 0) {
      setEvents(result.result);
    }
  };

  const settings = {
    infinite: true,
    className: "center",
    //autoplaySpeed: 5000,
    //speed: 1000,
    //autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    //centerMode: true,
    //variableWidth: true,
    //adaptiveHeight: true,
    swipeToSlide: true,
    arrows: false,
  };

  const onSliderItemClick = (item) => {
    let temp = deepClone(activeEvent);
    temp = item;
    setActiveEvent(temp);
    setShowNewsModal(true);
  };

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {/* <div className={styles.sliderContainer}>
          {events.slice(0,2).map((x, index) => (
            <div
              className={styles.imgWrapper}
              key={index}
              onClick={onSliderItemClick.bind(this, x)}
            >
              <div className={styles.imgInfo}>
                <span>{x.title}</span>
                <span>{x.eventDate}</span>
              </div>
              <div className={styles.preview}>
                <img src={localizeImg(x.images[0])} alt="Gallery" style={{height:'260px'}}/>
              </div>
            </div>
          ))}
        </div> */}
        {events.map((x, index) => (
          <div className={styles.eventItem}>
            <EventItem item={x} key={index} />
          </div>
        ))}
        {/* </Card> */}
      </div>
      <div className={styles.col}>
        <RightSider />
      </div>
      <ModalNews
        visible={showNewsModal}
        news={activeEvent}
        onClose={() => setShowNewsModal(false)}
      />
    </div>
  );
}
