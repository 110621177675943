import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar, Button, Divider, Image, Skeleton } from "antd";
import { useUserId } from "../../contexts/UserIdProvider";
import {
  demandOfferPage,
  getTenderDetail,
  tenderUpdate,
} from "../../api/tender";
import { getTimeBefore } from "../../utils/date";
import { Link } from "react-router-dom";
import {
  localizeImg,
  localizePriceUnit,
  ProductCurrencyType,
} from "../../constants/constants";
import { UserType } from "../../constants/usersType.enum";
import { getProductConfigNameById, loadProductConfig } from "../../api/product";
import { getCountryNameById } from "../../api/config";
import { goToProfilePage } from "../../utils/utils";
import "./style/tenderInfo.less";
import AvatarBasic from "../common/avatar/AvatarBasic";
import { checkLimit, getProductStatusText } from "../../api/common";
import { getCompanyVerifyStatus, userDetail } from "../../api/user";
import { RoleLimit } from "../../constants/roleType.enum";
import { ProductStatus } from "../../constants/statusCode.enum";

export default function TenderInfo() {
  const { t, i18n } = useTranslation();
  const { isWinery, user } = useUserId();
  const { userId, userInfo } = user;
  const { state } = useLocation();
  const history = useHistory();
  const [detail, setDetail] = useState();
  const [tenderUser, setTenderUser] = useState();
  const [demandList, setDemandList] = useState([]);
  const [demanded, setDemanded] = useState(false);
  const [tenderStatus, setTenderStatus] = useState(true);
  const [userType, setUserType] = useState();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [countryName, setCountryName] = useState();
  const [typeName, setTypeName] = useState();
  const [currentUser, setCurrentUser] = useState(userInfo);
  const [currency, setCurrency] = useState();

  useEffect(() => {
    if (user && user.userInfo) {
      setCurrentUser(user.userInfo);
    }
  }, [user]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (detail) {
      initConfigData();
    }
    setCurrency(localizePriceUnit(currentLanguage));
  }, [currentLanguage]);

  useEffect(() => {
    console.log("------tender info:", state);
    if (state) {
      let { detail, id } = state;
      if (detail) {
        setDetail(detail);
      }
      if (id) {
        loadTenderDetail(id);
      }
    }
    if (userInfo) {
      setUserType(userInfo.type);
    }
  }, [state]);

  useEffect(() => {
    if (detail) {
      if (detail.userInfo && detail.userInfo.length > 0) {
        setTenderUser(detail.userInfo[0]);
      } else {
        initTenderUser(detail.userId);
      }

      setTenderStatus(detail.status === 0);
      loadDemandOffers();
      initConfigData();
    }
  }, [detail]);

  useEffect(() => {
    if (tenderUser) {
      if (tenderUser._id === userInfo._id) {
        loadDemandOffers();
      }
    }
  }, [tenderUser]);

  const initConfigData = async () => {
    let result = await loadProductConfig();
    if (result) {
      let { locations, types } = result;
      if (detail.country) {
        setCountryName(
          getCountryNameById(locations, currentLanguage, detail.country)
        );
      }
      if (detail.types) {
        setTypeName(
          getProductConfigNameById(types, currentLanguage, detail.types)
        );
      }
    }
  };

  const initTenderUser = async (uid) => {
    if (uid) {
      let result = await userDetail(uid);
      if (result) {
        setTenderUser(result);
      }
    }
  };

  const loadTenderDetail = async (id) => {
    let pms = { id };
    let result = await getTenderDetail(pms);
    console.log("-------tender detail:", result);
    if (result) {
      setDetail(result);
    }
  };

  const loadDemandOffers = async () => {
    let pms = { tenderId: detail?._id };
    let result = await demandOfferPage(pms);
    console.log("-----demandOfferPage", result);
    if (result && result.length > 0) {
      if (isWinery) {
        result = result.filter((item) => item.coId === userInfo.companyId);
        setDemanded(result && result.length > 0 ? true : false);
      } else {
        // result = result.filter((item) => item.status === 2);
      }
      setDemandList(result);
    }
  };

  const onDemandOfferCreate = () => {
    if (checkLimit(currentUser, RoleLimit.PURCHASE_DEMAND, history)) {
      history.push("/demandoffers/create", { tenderId: detail?._id });
    }
  };

  const toggleTenderStatus = async () => {
    detail.status = tenderStatus ? 1 : 0;
    await tenderUpdate(detail);
    setTenderStatus(!tenderStatus);
  };

  const onCompanyHome = (item) => {
    goToProfilePage(item, history);
  };

  const onChat = (item) => {
    if (item && item.userId) {
      history.push("/imchat-deprecated", { userId: item.userId });
    }
  };

  return (
    <div className="tender-info-container">
      <div className="info-banner"></div>
      <div className="info-title">{t("VINBIP")}</div>
      <div className="info-content">
        <div className="info-content-left">
          {tenderUser && (
            <div className="info-user">
              <Link to={"/profile-deprecated/" + tenderUser?._id} className="info-user">
                <AvatarBasic
                  cname="user-avatar"
                  picture={localizeImg(tenderUser?.picture)}
                  displayName={
                    tenderUser?.nickName ||
                    tenderUser.firstName + tenderUser.lastName
                  }
                ></AvatarBasic>
                <span className="user-name">{tenderUser?.nickName}</span>
              </Link>
              <Divider type="vertical" />
              <span className="user-signature">{tenderUser?.signature}</span>
            </div>
          )}
          {!tenderUser && (
            <Skeleton active={true} avatar={true} paragraph={{ rows: 2 }} />
          )}
          <div className="info-content-title">{detail?.title}</div>
          <div className="info-content-product">
            <ul className="product-detail">
              <li className="detail-li">
                <div className="detail-title">{t("ORIGIN_OF_THE_PRODUCT")}</div>
                <div className="detail-value">{countryName}</div>
              </li>
              <li className="detail-li">
                <div className="detail-title">{t("PRODUCT_TYPE")}</div>
                <div className="detail-value">{typeName}</div>
              </li>
              <li className="detail-li">
                <div className="detail-title">
                  {t("TENDER_NUMBER_OF_BOTTLE")}
                </div>
                <div className="detail-value">
                  <span className="detail-number">{detail?.amount}</span>{" "}
                  {t("BOTTLES")}
                </div>
              </li>
              <li className="detail-li">
                <div className="detail-title">{t("TARGET_PRICE")}</div>
                <div className="detail-value">
                  <span className="detail-number">
                    {currency === ProductCurrencyType.CNY && currency}{" "}
                    {detail?.priceBegin}-{detail?.priceEnd}{" "}
                    {currency !== ProductCurrencyType.CNY && currency}
                  </span>
                </div>
              </li>
              {/* <li className="detail-li">
                <div className="detail-title">{t("ALCOHOL_DEGREE")}</div>
                <div className="detail-value">
                  {detail?.alcoholBegin}-{detail?.alcoholEnd}
                </div>
              </li>
              <li className="detail-li">
                <div className="detail-title">{t("YEARS")}</div>
                <div className="detail-value">{detail?.year}</div>
              </li>
              <li className="detail-li">
                <div className="detail-title">{t("BOTTLE_SIZE")}</div>
                <div className="detail-value">{detail?.volume}ml</div>
              </li> */}
              <li className="detail-li">
                <div className="detail-title">
                  {t("TENDER_DESCRIPTION_OF_DEMAND")}
                </div>
                <div className="detail-value">{detail?.comments}</div>
              </li>
            </ul>
          </div>
          {detail?.verifyStatus === ProductStatus.VERIFYSUCCESS ? (
            <div className="info-content-btn">
              {userId === detail?.userId && (
                <Button
                  className="btn-default"
                  onClick={toggleTenderStatus.bind(this)}
                >
                  {tenderStatus
                    ? t("SUSPEND_THE_QUOTATION")
                    : t("RECEIVE_QUOTATION")}
                </Button>
              )}
              {userId !== detail?.userId && !tenderStatus && (
                <Button type="text" className="btn-default">
                  {t("QUOTATION_PAUSED")}
                </Button>
              )}
              {/* {tenderStatus && demanded && } */}
              {isWinery &&
                tenderStatus &&
                (!demanded ? (
                  <Button
                    className="btn-primary"
                    onClick={onDemandOfferCreate.bind(this)}
                  >
                    {t("SEND_QUOTATION")}
                  </Button>
                ) : (
                  <Button type="text" className="btn-default">
                    {t("SENT_QUOTATION")}
                  </Button>
                ))}
            </div>
          ) : (
            <div className="info-content-btn">
              <Button type="text" className="btn-default">
                {t(getProductStatusText(detail?.verifyStatus))}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="info-offer">
        <div className="offer-title">{t("QUOTATION")}</div>
        <div className="offer-title-sub">{t("QUOTATION_PRIVACY_MESSAGE")}</div>
        {demandList.map((item, index) => (
          <div className="offer-item" key={index}>
            <div className="offer-item-userinfo">
              <div className="userinfo">
                <AvatarBasic
                  onClick={onCompanyHome.bind(this, item)}
                  cname="avatar"
                  picture={localizeImg(item.companyInfo[0]?.avatar)}
                  displayName={item.companyInfo[0]?.companyName}
                ></AvatarBasic>
                <div className="desc">
                  <div className="name">{item.companyInfo[0]?.companyName}</div>
                  <div className="time">{t(getTimeBefore(item.createdAt))}</div>
                </div>
              </div>
              <div className="priceinfo">
                <div className="info">
                  <div className="price">
                    {currency === ProductCurrencyType.CNY && currency}{" "}
                    {item.price}{" "}
                    {currency !== ProductCurrencyType.CNY && currency}
                  </div>
                </div>
                {userInfo.type != UserType.SUPPLIER && (
                  <div className="btn-chat" onClick={onChat.bind(this, item)}>
                    <span className="text">{t("SEND_MESSAGE")}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="offer-item-content">
              <div className="content-title">{item.comments}</div>
              <div className="content-product">
                <ul>
                  <li className="detail-li">
                    <div className="detail-title">{t("COUNTRY")} :</div>
                    <div className="detail-value">{item.country?.name}</div>
                  </li>
                  <li className="detail-li">
                    <div className="detail-title">{t("TYPE")} :</div>
                    <div className="detail-value">{item.type?.name}</div>
                  </li>
                  <li className="detail-li">
                    <div className="detail-title">{t("PRICE")} :</div>
                    <div className="detail-value">
                      {currency === ProductCurrencyType.CNY && currency}{" "}
                      {item.price}{" "}
                      {currency !== ProductCurrencyType.CNY && currency}
                    </div>
                  </li>
                  <li className="detail-li">
                    <div className="detail-title">{t("ALCOHOL_DEGREE")}</div>
                    <div className="detail-value">{item.alcohol} %</div>
                  </li>
                  <li className="detail-li">
                    <div className="detail-title">{t("YEARS")} :</div>
                    <div className="detail-value">{item?.vintage}</div>
                  </li>
                  <li className="detail-li">
                    <div className="detail-title">{t("BOTTLE_SIZE")} :</div>
                    <div className="detail-value">
                      {item.bottleSize?.name}&nbsp;ml
                    </div>
                  </li>
                </ul>
              </div>
              <div className="content-images">
                {item.images &&
                  item.images.length > 0 &&
                  item.images.map((image, imageIndex) => (
                    <Image
                      className="image"
                      src={localizeImg(image)}
                      key={imageIndex}
                    />
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
