import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Icon from "../../../Icon";
import ModalPreview from "../../../ModalPreview";
import { useTranslation } from "react-i18next";
import { constants, deepClone, removeDuplicates } from "../../../../../utils/utils";
import {
  formatProductConfigItem,
  getProductConfigNameById,
  getProductGrapeDetailName,
  loadProductConfig,
  loadProductTags,
  productFavorite,
  productFavoriteCount,
  productFavoriteStatus,
} from "../../../../../api/product";
import {
  getCountryNameById,
  getLocationNameById,
} from "../../../../../api/config";
import { Tag, Menu, Modal, Dropdown } from "antd";
import downArrowIcon from "../../../../../resource/icons/shop/info/down_arrow.svg";
import { useUserId } from "../../../../../contexts/UserIdProvider";
//import wechat from "../../../../../resource/icons/user/login/wechat.svg";
import wechat from "../../../../../resource/images/wechat/vinbip-wechat-qr.png";
import { localizeValue, localizeImg } from "../../../../../constants/constants";
import AvatarBasic from "../../../../../components/common/avatar/AvatarBasic";
import VideoBasic from "../../../../../components/common/video/VideoBasic";
import Card from "../../../Card";
import ImageMagnifier from "../../../../../components/common/imageMagnifier/ImageMagnifier";
import DashboardNewProduct from "../../../../../screens/dashboard/Supplier/NewProduct";
import { statusCode } from "../../../../../constants/statusCode.enum";
import { UserType } from "../../../../../constants/usersType.enum";
import { useParams } from "react-router";
import { remove } from "../../../../../api/tender";
import defaultImage from "../../../../../resource/images/wine-botlle/bottle-default-gray.jpeg";

export default function Overview(props) {
  const { product } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const { coname } = useParams();

  const [proInfo, setProInfo] = useState({});

  const [classificationName, setClassificationName] = useState("");

  const [currentImagePreviewIndex, setCurrentImagePreviewIndex] = useState(0);
  const [currentProductPriceIndex, setCurrentProductPriceIndex] = useState(0);

  const [visibleModalPreview, setVisibleModalPreview] = useState(false);
  const [showProductEditor, setShowProductEditor] = useState(false);

  const [productFeatures1, setProductFeatures1] = useState([]);
  const [productFeatures2, setProductFeatures2] = useState([]);

  const [localtionList, setLocaltionList] = useState([]);
  const [classificationsList, setClassificationsList] = useState([]);

  const [isMyself, setIsMyself] = useState(false);

  //trading condition
  const [volumeList, setVolumeList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [shippingList, setShippingList] = useState([]);

  const [selectedShippingAddressIndex, setSelectedShippingAddressIndex] =
    useState(0);
  const [selectedPriceIndex, setSelectedIndex] = useState(0);

  const [volumeName, setVolumeName] = useState();

  const [favoriteCount, setFavoriteCount] = useState(0);
  const [favoriteStatus, setFavoriteStatus] = useState(false);

  const [grapeStr, setGrapeStr] = useState("");
  const [productTags, setProductTags] = useState([]);

  const [showWechatQrCode, setShowWechatQrCode] = useState(false);
  const [isAddMode, setIsAddMode] = useState(coname?.length > 0);

  const [tagColors, setTagColors] = useState([
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ]);

  const [productEditForm, setProductEditForm] = useState({
    editProductName: false,
    editProductTags: false,
    editProductImages: false,
    editProductTradingCondition: false,
    editProductTechInfo: false,
    editProductTestingNote: false,
    editProductDetails: false,
  });

  useEffect(() => {
    setProInfo(product);
  }, [product]);

  useEffect(async () => {
    if (proInfo && proInfo) {
      initProductFeatures(proInfo);
      initPricesList(proInfo?.stocks);
      getProductFavoriteCount();
      getFavoriteStatus();

      initProductTags(proInfo?.tags);

      let flag = user?.userInfo?.companyId == proInfo?.companyInfo?._id;
      setIsMyself(flag);
    }
  }, [proInfo]);

  const initProductFeatures = async (productInfo) => {
    let configData = await loadProductConfig();
    let {
      locations,
      types,
      bottles,
      capsules,
      sealed,
      classifications,
      categories,
      tags,
    } = configData;

    setLocaltionList(locations);
    setClassificationsList(classifications);

    let temp = deepClone(productFeatures1);
    temp = [];

    let getCountryName = getCountryNameById(
      locations,
      i18n.language,
      productInfo?.country
    );
    if (getCountryName) {
      temp.push(t("PRODUCT_COUNTRY") + ": " + getCountryName);
    }

    let getCategoryName = getProductConfigNameById(
      categories,
      i18n.language,
      proInfo?.category
    );
    if (getCategoryName) {
      temp.push(t("PRODUCT_CATEGORY") + ": " + getCategoryName);
    }

    let getRegionName = getProductConfigNameById(
      locations,
      i18n.language,
      proInfo?.region
    );
    if (getRegionName) {
      temp.push(t("REGION") + ": " + getRegionName);
    }

    let grapeList = await getProductGrapes(productInfo);
    if (grapeList.length > 0) {
      setGrapeStr(grapeList.join(", "));
      temp.push(t("GRAPES") + ": " + grapeList.join(", "));
    }
    if (productInfo?.ageOfVine) {
      temp.push(t("AGE_OF_VINE") + ": " + productInfo?.ageOfVine);
    }
    if (productInfo?.vintage && productInfo?.vintage.length > 0) {
      temp.push(t("VINTAGE") + ": " + productInfo?.vintage);
    }
    if (productInfo?.body) {
      temp.push(t("BODY") + ": " + t(productInfo?.body));
    }
    setProductFeatures1(temp);

    let temp2 = deepClone(productFeatures2);
    temp2 = [];
    let classificationName = getProductConfigNameById(
      classificationsList,
      i18n.language,
      proInfo?.classification
    );
    if (classificationName) {
      temp2.push(t("CLASSIFICATIONS") + ": " + classificationName);
    }
    setClassificationName(classificationName);
    if (productInfo && productInfo.agingPeriod) {
      temp2.push(
        t("AGING_PERIOD") +
          ": " +
          productInfo?.agingPeriod +
          " " +
          t("MONTHS_IN_OAK_BARREL")
      );
    }
    let packageList = [];
    let bottleVolume = [];
    productInfo?.prices?.map((mapItem, mapIndex) => {
      packageList.push(t(mapItem?.package));
      bottleVolume.push(mapItem?.volume);
    });
    packageList = removeDuplicates(packageList);
    bottleVolume = removeDuplicates(bottleVolume);

    if (productInfo?.alcoholDegree) {
      temp2.push(t("ALCOHOL_LEVEL") + ": " + productInfo?.alcoholDegree);
    }
    if (productInfo?.servingTemperature) {
      temp2.push(
        t("SERVING_TEMPERATURE") + ": " + productInfo?.servingTemperature
      );
    }
    if (productInfo?.acidity) {
      temp2.push(t("ACIDITY") + ": " + t(productInfo?.acidity));
    }
    setProductFeatures2(temp2);
  };

  const initPricesList = (prices) => {
    if (prices && prices.length > 0) {
      console.log("--------------------prices:", prices);
      //volument list.
      let tempVolumeList = [];
      //package list.
      let tempPackageList = [];
      //temp shipping list.
      let tempShippingList = [];
      prices.map((item) => {
        if (item.volume) {
          tempVolumeList.push({
            label: item.volume + " ML",
            value: item.volume,
          });
        }
        if (item.package) {
          tempPackageList.push({
            label: t(item.package),
            value: item.package,
          });
        }
        if (item.shippingAddress) {
          tempShippingList.push({
            value: item.shippingAddress,
            label: item.shippingAddressInfo?.name,
          });
        }
      });
      setVolumeList(tempVolumeList);
      setPackageList(tempPackageList);
      setShippingList(tempShippingList);
    }
  };

  const getProductGrapes = async (productInfo) => {
    let list = [];
    if (productInfo.grape && productInfo.grape.length > 0) {
      for (let index = 0; index < productInfo.grape.length; index++) {
        const grapeId = productInfo.grape[index];
        let pms = { _id: grapeId };
        let name = await getProductGrapeDetailName(pms, i18n.language);
        if (name) {
          list.push(name);
        }
      }
    }
    return list;
  };

  const initProductTags = async (tags) => {
    let result = await loadProductTags();
    if (result.statusCode == statusCode.SUCCESS && tags) {
      let temp = [];
      tags.map((mapItem) => {
        let tag = result.result.result.find((obj) => obj._id == mapItem);
        if (tag) {
          temp.push(formatProductConfigItem(tag, i18n.language));
        }
      });
      setProductTags(temp);
    }
  };

  const menu_other_volumes = (menu_list, action) => {
    return (
      <Menu>
        {menu_list.map((item, index) => (
          <Menu.Item
            key={index}
            className="mapboxgl-ctrl-geocoder--suggestion"
            onClick={() =>
              action ? action(item.value, index) : setSelectedIndex(index)
            }
          >
            <span>
              {/* {item.value === ProductSampleValue
                ? t("SAMPLES")
                : item.label + "ML"} */}
              {item.label}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const menu_office_wechat_qr = () => {
    return (
      <Menu>
        <Menu.Item key={1} className={styles.wechatqr}>
          {/* <div>{t("SCAN_WITH_WECHAT")}</div> */}
          <img src={wechat} style={{ width: "108px", marginLeft: "10px" }} />
        </Menu.Item>
      </Menu>
    );
  };

  const onShippingAddressMenuItemClick = (value, index) => {
    setSelectedShippingAddressIndex(index);
    setSelectedIndex(index);

    if (proInfo?.prices) {
      //volument list.
      let tempVolumeList = [];
      //package list.
      let tempPackageList = [];
      proInfo?.prices.map((item) => {
        if (item.shippingAddress == value) {
          if (item.volume) {
            tempVolumeList.push({
              label: item.volume + " ML",
              value: item.volume,
            });
          }
          if (item.package) {
            tempPackageList.push({
              label: t(item.package),
              value: item.package,
            });
          }
        }
      });
      setVolumeList(tempVolumeList);
      setPackageList(tempPackageList);
    }
  };

  const getProductFavoriteCount = async () => {
    let pms = { productId: proInfo?._id };
    let result = await productFavoriteCount(pms);
    setFavoriteCount(result);
  };

  const getFavoriteStatus = async () => {
    let pms = {
      productId: proInfo?._id,
      coId: user.userInfo.companyId,
    };

    let result = await productFavoriteStatus(pms);
    setFavoriteStatus(result);
  };

  const toggleProductFavorite = async () => {
    let pms = {
      productId: proInfo?._id,
      coId: user.userInfo.companyId,
      userId: user.userId,
    };
    await productFavorite(pms);
    getProductFavoriteCount();
    getFavoriteStatus();
  };

  const setProductEditFormValue = (key) => {
    const updatedForm = {};
    // Loop through each key and set the value to false
    Object.keys(productEditForm).forEach((itemKey) => {
      updatedForm[itemKey] = false;
    });
    updatedForm[key] = true;
    setProductEditForm(updatedForm);

    setShowProductEditor(true);
  };

  const onEditCompleted = (items) => {
    setShowProductEditor(false);
    setProInfo(items);
  };

  return (
    <>
      <Card className={styles.overview}>
        <div className={styles.titleRow}>
          <div className={`${styles.titleRow} edit-product-info-name`}>
            <div className={styles.title}>
              {localizeValue(i18n.language, proInfo?.name)}
            </div>
            {(isMyself || isAddMode) && !proInfo?.name && (
              <div className={styles.title}>{t("PRODUCT_NAME")}</div>
            )}
            {(isMyself || isAddMode) && (
              <div
                className={styles.editButton}
                onClick={() => setProductEditFormValue("editProductName")}
              >
                <Icon name="edit" size="24" />
              </div>
            )}
          </div>
          <div className={styles.btns}>
            <button
              className={cn("button-stroke", styles.favorite)}
              onClick={toggleProductFavorite.bind(this)}
            >
              <Icon name={favoriteStatus ? "heart-fill" : "heart"} size="24" />
              <span>{favoriteCount}</span>
            </button>
            {/* <button className={cn("button", styles.buy)}>
            <span className={styles.price}>$89</span>
            <span className={styles.inner}>
              Download<span> now</span>
              <Icon name="download" size="24" />
            </span>
          </button> */}
          </div>
        </div>
        <div className={styles.line}>
          {!isMyself && !isAddMode && (
            <div className={styles.author}>
              <div className={styles.avatar}>
                <AvatarBasic
                  picture={
                    Array.isArray(proInfo?.companyInfo?.avatar)
                      ? localizeImg(proInfo?.companyInfo?.avatar[0])
                      : localizeImg(proInfo?.companyInfo?.avatar)
                  }
                  size={33}
                />
              </div>
              {t("BY")}{" "}
              <span>
                {localizeValue(
                  i18n.language,
                  proInfo?.companyInfo?.companyName
                )}
              </span>
            </div>
          )}
          {(isMyself || isAddMode) && (
            <div className={styles.author}>
              <div className={styles.avatar}>
                <AvatarBasic
                  picture={
                    Array.isArray(user?.userInfo?.companyDesc?.avatar)
                      ? localizeImg(user?.userInfo?.companyDesc?.avatar[0])
                      : localizeImg(user?.userInfo?.companyDesc?.avatar)
                  }
                  size={33}
                />
              </div>
              {t("BY")}{" "}
              <span>
                {localizeValue(
                  i18n.language,
                  user?.userInfo?.companyDesc.companyName
                )}
              </span>
            </div>
          )}
        </div>
        <div
          className={`${styles.tagsLine} flex flex-row justify-start edit-product-info-tag`}
        >
          <div className={styles.tags}>
            {productTags.map((item) => {
              return <Tag className={styles.tag}>#{item.label}</Tag>;
            })}
            {(isMyself || isAddMode) &&
              (!productTags || productTags.length == 0) && (
                <div>{t("ADD_TAGS")}</div>
              )}
          </div>
          {(isMyself || isAddMode) && (
            <div
              className={styles.editButton}
              onClick={() => setProductEditFormValue("editProductTags")}
            >
              <Icon name="edit" size="24" />
            </div>
          )}
        </div>
        <div className={styles.galleryRow}>
          <div className={styles.galleryIndex}>
            {proInfo?.images?.map((x, index) => (
              <div
                className={styles.galleryItem}
                key={index}
                onMouseEnter={() => setCurrentImagePreviewIndex(index)}
              >
                <img src={localizeImg(x)} alt="Product" />
              </div>
            ))}
            {(isMyself || isAddMode) && (
              <>
                <div
                  className={cn(
                    styles.galleryItem,
                    "flex justify-center items-center edit-product-info-images"
                  )}
                  onClick={() => setProductEditFormValue("editProductImages")}
                >
                  <div className="ml-0">
                    <Icon name="plus" size="16" />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.galleryPreview}>
            {proInfo?.images && proInfo?.images.length > 0 && (
              <ImageMagnifier
                src={localizeImg(proInfo?.images[currentImagePreviewIndex])}
                height={"400px"}
                width={"auto"}
              />
            )}
            {!proInfo?.images && (
              <ImageMagnifier
                src={defaultImage}
                height={"400px"}
                width={"auto"}
              />
            )}
            {/* <button
              className={cn("button-white", styles.button)}
              onClick={() => setVisibleModalPreview(true)}
            >
              {t("VIEW_ALL")}
            </button> */}
          </div>
          <div className={styles.tradingConditions}>
            <div className={cn("title-red")}>
              <div className={styles.subtitle}>
                {(user?.userInfo?.type != UserType.SUPPLIER ||
                  isMyself ||
                  isAddMode) && (
                  <>
                    {proInfo?.stocks && proInfo?.stocks.length > 0 && (
                      <>
                        {/* {(proInfo?.stock[selectedPriceIndex].currency == "¥" &&
                          "¥ ") ||
                          (proInfo?.stock[selectedPriceIndex].currency ==
                            "$" &&
                            "$ ")}
                        {proInfo?.stock[selectedPriceIndex].price}
                        {proInfo?.stock[selectedPriceIndex].currency == "€" &&
                          "€"}
                        {"  EXW"} */}
                      </>
                    )}
                    {/* {(!proInfo?.stocks || proInfo?.stocks.length == 0) && ( */}
                    <>{t("TRADING_CONDITIONS")}</>
                    {/* )} */}
                  </>
                )}
                {(isMyself || isAddMode) && (
                  <div
                    className={`${styles.editButton} edit-product-info-trading-condition`}
                    onClick={() =>
                      setProductEditFormValue("editProductTradingCondition")
                    }
                  >
                    <Icon name="edit" size="24" />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.productPropInfo}>
              <div className={styles.productPropLabel}>
                {t("SHIPPING_WAREHOUSE")}
              </div>
              <div className={styles.productPropValue}>
                <Dropdown
                  overlay={menu_other_volumes(
                    shippingList,
                    onShippingAddressMenuItemClick
                  )}
                  trigger={["click"]}
                >
                  <div className="cursor-pointer flex flex-row">
                    {shippingList[selectedShippingAddressIndex]?.label}{" "}
                    <img className="ml-2" src={downArrowIcon} />
                  </div>
                </Dropdown>
              </div>
            </div>
            {proInfo?.stocks && proInfo?.stocks.length > 0 && (
              <>
                {proInfo?.stocks[selectedPriceIndex]?.prices?.map(
                  (priceItem) => {
                    return (
                      <div className={styles.productPropInfo}>
                        <div className={styles.productPropLabel}>
                          {priceItem.samples?t("SAMPLES"):priceItem.minCount}
                        </div>
                        <div className={styles.productPropValue}>
                          {priceItem.price}{proInfo?.stocks[selectedPriceIndex].shippingAddress==constants.chinaCountryId?"￥":"€"}
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            )}
            <div className={styles.productPropInfo}>
              <button
                className={cn("button", styles.askButton)}
                onClick={() => setShowWechatQrCode(true)}
              >
                {t("ASK_FOR_SAMPLE")}
              </button>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red")}>
              <div className={styles.subtitle}>
                {t("TECHNICAL_INFORMATION")}
                {(isMyself || isAddMode) && (
                  <div
                    className={`${styles.editButton} edit-product-info-tech-info`}
                    onClick={() =>
                      setProductEditFormValue("editProductTechInfo")
                    }
                  >
                    <Icon name="edit" size="24" />
                  </div>
                )}
              </div>
            </div>
            {(isMyself || isAddMode) &&
              productFeatures1.length == 0 &&
              productFeatures2.length == 0 && (
                <div>{t("ADD_TECHNICAL_INFORMATION")}</div>
              )}
            <div className={styles.displayRow}>
              <ul className={styles.features}>
                {productFeatures1.map((x, index) => (
                  <li key={index}>{x}</li>
                ))}
              </ul>
              <ul className={styles.features}>
                {productFeatures2.map((x, index) => (
                  <li key={index}>{x}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red")}>
              <div className={styles.subtitle}>
                {t("TASTING_NOTE")}
                {(isMyself || isAddMode) && (
                  <div
                    className={`${styles.editButton} edit-product-info-testing-note`}
                    onClick={() =>
                      setProductEditFormValue("editProductTestingNote")
                    }
                  >
                    <Icon name="edit" size="24" />
                  </div>
                )}
              </div>
            </div>
            {proInfo?.tastingNote ? (
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: localizeValue(i18n.language, proInfo?.tastingNote),
                }}
              ></div>
            ) : (
              (isMyself || isAddMode) && <div>{t("ADD_TASTING_NOTE")}</div>
            )}
          </div>
        </div>
        {(proInfo?.details || isAddMode) && (
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={cn("title-red")}>
                <div className={styles.subtitle}>
                  {t("PRODUCT_DETAIL")}
                  {(isMyself || isAddMode) && (
                    <div
                      className={`${styles.editButton} edit-product-info-details`}
                      onClick={() =>
                        setProductEditFormValue("editProductDetails")
                      }
                    >
                      <Icon name="edit" size="24" />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.content}>
                {proInfo?.details?.map((mapItem) => {
                  return <img src={localizeImg(mapItem)} alt="Product" />;
                })}
                {(isMyself || isAddMode) && !proInfo?.details && (
                  <>{t("ADD_PRODUCT_DETAIL")}</>
                )}
              </div>
            </div>
          </div>
        )}
        {proInfo?.videos && (
          <div className={styles.row}>
            <div className={styles.col}>
              <div className="flex flex-row">
                <div className={cn("title-red", styles.subtitle)}>
                  {t("PRODUCT_VIDEOS")}
                </div>
                {proInfo?.videos.map((mapItem) => {
                  return (
                    <VideoBasic url={mapItem} width="100%" height="100%" />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Card>
      <ModalPreview
        visible={visibleModalPreview}
        onClose={() => setVisibleModalPreview(false)}
        gallery={proInfo?.images}
        title={proInfo?.title}
        figcaption={classificationName}
        // download
      />
      <Modal
        visible={showWechatQrCode}
        footer={null}
        width={501}
        height={756}
        // closable={true}
        onCancel={() => setShowWechatQrCode(false)}
      >
        <div className={styles.wechatqr}>
          {/* <div>{t("SCAN_WITH_WECHAT")}</div> */}
          <img src={wechat} style={{ width: "444px", marginLeft: "10px" }} />
        </div>
      </Modal>
      <Modal
        visible={showProductEditor}
        footer={null}
        title={null}
        width={800}
        closable={false}
        destroyOnClose={true}
        onCancel={() => setShowProductEditor(false)}
      >
        <DashboardNewProduct
          editProductName={productEditForm?.editProductName}
          editProductTags={productEditForm?.editProductTags}
          editProductImages={productEditForm?.editProductImages}
          editProductTradingCondition={
            productEditForm?.editProductTradingCondition
          }
          editProductTechInfo={productEditForm?.editProductTechInfo}
          editProductTestingNote={productEditForm?.editProductTestingNote}
          editProductDetails={productEditForm?.editProductDetails}
          isAddMode={isAddMode}
          editProductId={proInfo?._id}
          onEditCompleted={onEditCompleted.bind(this)}
        ></DashboardNewProduct>
      </Modal>
    </>
  );
}
