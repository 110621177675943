import { AudioOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Col,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
} from "antd";
import { useTranslation } from "react-i18next";
import "./style/productsPanel.less";
import searchIcon from "./images/search_icon.svg";
import { useEffect, useState } from "react";
import {
  ProductStatus,
  statusCode,
} from "../../../../constants/statusCode.enum";
import {
  localizeTitle,
  ProductTableMaxCount,
} from "../../../../constants/constants";
import {
  productRemove,
  productUpdateStatus,
  loadProducts as reqProducts,
  loadProductConfig,
  getProductConfigNameById,
} from "../../../../api/product";
import { dateFormat } from "../../../../utils/date";
import { getProductStatusText } from "../../../../api/common";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { toastInfo } from "../../../../api/toast";
import { useHistory } from "react-router";
import { deepClone } from "../../../../utils/utils";

export default function ProductsPanel(props) {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  let [data, setData] = useState([]);
  let [sourceData, setSourceData] = useState([]);
  const [productConfig, setProductConfig] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initProducts();
    initProductConfig();
  }, []);

  const initProducts = async () => {
    setLoading(true);
    let pms = {
      page: 0,
      per_page: ProductTableMaxCount,
      coId: user.userInfo.companyId,
    };
    let result = await reqProducts(pms);
    console.log("-----products:", result, pms);
    if (result && result.length > 0) {
      // result = result.map((item) => item._source);
      for (let i = 0; i < result.length; i++) {
        result[i].key = "product" + i;
        if (result[i].id) {
          result[i]._id = result[i].id;
        } else {
          result[i].id = result[i]._id;
        }
        result[i].createdAt = result[i].createdAt
          ? dateFormat(result[i].createdAt, "yyyy-MM-dd")
          : "-";
        result[i].statusText = t(getProductStatusText(result[i].status));
        result[i].typeName = await formatProductType(result[i].types);
      }
      result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setData(result);
      setSourceData(deepClone(result));
    }
    setLoading(false);
  };

  const initProductConfig = async () => {
    let configData = await loadProductConfig();
    setProductConfig(configData);
    return configData;
  };

  const formatProductType = async (val) => {
    let configData = productConfig ? productConfig : await initProductConfig();
    let { types } = configData;
    return getProductConfigNameById(types, currentLanguage, val);
  };

  const columns = [
    {
      dataIndex: "title",
      key: "title",
      render: (val, rowIndex) => {
        return (
          <span
            style={{
              color: "#333333",
              fontSize: "14px",
              fontWeight: "700",
              fontFamily: "Inter",
            }}
          >
            {rowIndex + 1}
          </span>
        );
      },
    },
    {
      title: t("PRODUCT_NAME"),
      dataIndex: "title",
      key: "title",
      colspan: 3,
      render: (val) => {
        return (
          <span
            style={{
              color: "#333333",
              fontSize: "14px",
              fontWeight: "700",
              fontFamily: "Inter",
            }}
          >
            {localizeTitle(currentLanguage, val)}
          </span>
        );
      },
    },
    {
      title: t("YEAR"),
      dataIndex: "year",
      key: "year",
      render: (val) => {
        return val;
      },
    },
    {
      title: t("PRICE_MIN"),
      dataIndex: "volumes",
      key: "volumes",
      render: (val, record) => {
        if (val && val.length > 0) {
          return val[0].price;
        } else {
          return "-";
        }
      },
    },
    {
      title: t("PRICE_MAX"),
      dataIndex: "volumes",
      key: "volumes",
      render: (val, record) => {
        if (val && val.length > 0) {
          return <div>{val[val.length - 1].price}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: t("SHIPPING_ADDRESS"),
      dataIndex: "volumes",
      key: "volumes",
      colspan: 3,
      render: (val, record) => {
        return (
          <div>
            {val.map((valItem) => {
              return <div>{valItem.shippingAddress}</div>;
            })}
          </div>
        );
      },
    },
    {
      title: t("STOCK_AVAILABLE"),
      dataIndex: "volumes",
      key: "volumes",
      render: (val, record) => {
        let availableStock = 0;
        if (val && val.length > 0) {
          val.map((valItem) => {
            availableStock += parseInt(valItem.stock);
          });
        }
        return <div>{availableStock}</div>;
      },
    },
    {
      title: t("SOLD_VOLUME"),
      dataIndex: "volumes",
      key: "volumes",
      render: (val, record) => {
        return <div>0</div>;
      },
    },
    // {
    //   title: t("TYPE"),
    //   dataIndex: "typeName",
    //   key: "typeName",
    // },
    {
      title: t("CREATE_AT"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (val) => {
        return val;
      },
    },
    {
      title: t("PRODUCT_STATUS"),
      dataIndex: "statusText",
      key: "statusText",
      onFilter: (value, record) => record.status === value,
      render: (val, record) => {
        if (val.status === ProductStatus.VERIFYFAIL) {
          return (
            <Badge dot={true} size="default">
              <span
                className="cursor-pointer"
                // onClick={openNotification.bind(this, record.reason)}
              >
                {val}
              </span>
            </Badge>
          );
        } else {
          return val;
        }
      },
    },
    {
      title: t("ACTION"),
      dataIndex: "action",
      key: "action",
      // width: 280,
      align: "center",
      render: (text, rowIndex) => getActionColumn(text, rowIndex),
    },
  ];

  const getActionColumn = (text, rowIndex) => {
    // console.log("getActionColumn:",data[rowIndex]);
    if (data[rowIndex]) {
      let id = data[rowIndex].id || data[rowIndex]._id;

      return (
        <div className="products-panel-table-action">
          <div
            className="action-item"
            onClick={onEditProduct.bind(this, data[rowIndex])}
          >
            {t("EDIT")}
          </div>
          {/* <div onClick={toProductDetail.bind(this, data[rowIndex])}>
              {t("DETAIL")}
            </div> */}
          {data[rowIndex].status == ProductStatus.STOCK && (
            <div
              className="action-item"
              onClick={onUpdateProductStatus.bind(
                this,
                id,
                ProductStatus.UNSTOCK
              )}
            >
              {t("OFF_SALE")}
            </div>
          )}
          {(data[rowIndex].status === ProductStatus.VERIFYSUCCESS ||
            data[rowIndex].status === ProductStatus.UNSTOCK) && (
            <div
              className="action-item"
              onClick={onUpdateProductStatus.bind(
                this,
                id,
                ProductStatus.STOCK
              )}
            >
              {t("ON_SALE")}
            </div>
          )}
          {/* <div onClick={onEditProduct.bind(this, data[rowIndex])}>
              {t("EDIT")}
            </div> */}
          <div className="action-item" onClick={onDeleteProduct.bind(this, id)}>
            {t("DELETE")}
          </div>
          {/* {products.length > 1 && (
              <>
                <span className="table-action-item-split">-</span>
                <span onClick={onBindVintage.bind(this, id)}>
                  {t("BIND_PRODUCTS")}
                </span>
              </>
            )} */}
        </div>
      );
    }
  };

  const toProductDetail = (item) => {
    history.push("/products/detail/" + (item.id || item._id));
  };

  const onUpdateProductStatus = async (id, status) => {
    let pms = {
      _id: id,
      status: status,
    };

    let result = await productUpdateStatus(pms);
    console.log("result:", result);
    if (result) {
      let list = deepClone(data);
      list = list.map((item) => {
        if (item.id === id || item._id === id) {
          item.status = status;
          item.statusText = t(getProductStatusText(status));
        }
        return item;
      });
      setData(list);
      setSourceData(deepClone(list));
      toastInfo(t("success"));
    }
  };

  const onEditProduct = (item) => {
    history.push("/shop/products/create-deprecated", { productId: item.id || item._id });
  };

  const onDeleteProduct = async (id) => {
    Modal.confirm({
      title: t("CONFIRM_DELETE"),
      icon: <ExclamationCircleOutlined />,
      content: t("ARE_YOU_SURE_TO_DELETE_THIS"),
      okText: t("CONFIRM"),
      cancelText: t("CANCEL"),
      onOk: async () => {
        let pms = { id: id, lng: i18n.language };
        let data = await productRemove(pms);
        if (data && data.statusCode === statusCode.SUCCESS) {
          toastInfo(t("success"));
          let list = deepClone(data);
          list = list.filter(
            (item) =>
              (item.id && item.id !== id) || (item._id && item._id !== id)
          );
          setData(list);
          setSourceData(deepClone(list));
        } else {
          message.error(t("fail"));
        }
      },
    });
  };

  const getData = (rowItem, columnItem, rowIndex) => {
    let value = columnItem.render(rowItem[columnItem.key], rowIndex);
    // console.log("getData:", value);
    return value;
  };

  const onSearchKeyChange = (e) => {
    let value=e.target.value;
    let temp=deepClone(data);
    temp=sourceData.filter((filterItem)=>filterItem.title.indexOf(value)!=-1);
    setData(temp);
  };

  return (
    <div className="products-panel-root">
      <div className="content-panel">
        <div className="table-header">
          <Row
            className="table-header-row"
            style={{
              width: data.length > 8 ? "1319px" : "100%",
            }}
          >
            {columns.map((columnItem, columnIndex) => {
              return (
                <Col
                  span={columnItem.colspan ? columnItem.colspan : 2}
                  className="table-header-item"
                  key={columnIndex}
                  style={{
                    borderRight:
                      columnIndex == columns.length - 1
                        ? "0px solid #FFFFFF"
                        : "1px solid #D9D9D9",
                  }}
                >
                  {columnItem.title}
                </Col>
              );
            })}
          </Row>
        </div>
        <div className="table-body">
          {data.map((rowItem, rowIndex) => {
            return (
              <Row className="table-row" key={rowIndex}>
                {columns.map((columnItem, columnIndex) => {
                  return (
                    <Col
                      span={columnItem.colspan ? columnItem.colspan : 2}
                      className="table-body-cell"
                      key={columnIndex}
                      style={{
                        borderRight:
                          columnIndex == columns.length - 1
                            ? "0px solid #FFFFFF"
                            : "1px solid #D9D9D9",
                      }}
                    >
                      <div>{getData(rowItem, columnItem, rowIndex)}</div>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </div>
      </div>
      <div className="search-panel"></div>
      <div className="orders-panel-footer">
        <div className="search-row">
          <Input
            // placeholder={t("DASHBOARD_TOTAL_ORDERS_PLACEHOLDER")}
            className="search-condition-input"
            onChange={onSearchKeyChange.bind(this)}
          />
          <img src={searchIcon} className="suffix" />
        </div>
      </div>
    </div>
  );
}
