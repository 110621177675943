import React from "react";
import cn from "classnames";
import styles from "./Suggestion.module.sass";
import Icon from "../../../Icon";
import { useTranslation } from "react-i18next";
import { Image } from "antd";

const Suggestion = ({ className, item, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.link, className)} onClick={onClick}>
      <div className={styles.icon}>
        {item?.icon?.length < 20 && <Icon name={item?.icon} size="24" />}
        {item?.icon?.length > 20 && (
          <Image
            src={item?.icon}
            size="24"
            style={{
              width: "41px",
              height: "41px",
              borderRadius: "22px",
            }}
          />
        )}
      </div>
      <div className={styles.details}>
        <div className={styles.title}>{item?.label}</div>
        <div className={styles.content}>
          {t("TYPE")}: {t(item?.cardType)}
        </div>
      </div>
      <div className={styles.arrow}>
        <Icon name="arrow-next" size="24" />
      </div>
    </div>
  );
};

export default Suggestion;
