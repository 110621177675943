import { useTranslation } from "react-i18next";

export default function AllianceList() {
  const { t } = useTranslation();

  return (
    <div className="common-management">
      {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><Link to={"/dashboard"}>{t("DASHBOARDS")}</Link></Breadcrumb.Item>
          <Breadcrumb.Item>{t("MY_CONTACT")}</Breadcrumb.Item>
          <Breadcrumb.Item>{t("MY_ALLIANCE")}</Breadcrumb.Item>
        </Breadcrumb> */}
      <div className="common-management-title">{t("MY_ALLIANCE")}</div>
      <div
        style={{ width: "1000px", height: "700px", backgroundColor: "white" }}
      ></div>
    </div>
  );
}
