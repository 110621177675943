import { Form, Input, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style/InputNumberUnit.less";

export default function InputNumberUnit(props) {
  const { t, i18n } = useTranslation();
  let { label, title, value, index, units, onChange } = props;
  let inputLayout = { min: 0, max: 9999999999 };
  const [num, setNumber] = useState();
  const [unit, setUnit] = useState();
  const [unitData, setUnitData] = useState([]);

  useEffect(() => {
    setUnitData([
      {
        label: t("BOTTLES"),
        value: "BOTTLE",
      },
      // {
      //   label: 'Litter',
      //   value: 'Litter',
      // },
      {
        label: t("CONTAINER"),
        value: "CONTAINER",
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    if (unitData.length > 0 && !unit) {
      setUnit(unitData[0].value);
    }
  }, [unitData]);

  useEffect(() => {
    if (value) {
      let { number, unit } = value;
      setNumber(number);
      setUnit(unit);
    }
  }, [value]);

  const onInputChange = (val) => {
    onChange({ number: val, unit }, index);
  };

  const onUnitChange = (val) => {
    onChange({ number: num, unit: val }, index);
  };

  return (
    <>
      <div className="input-title">
        <span
          style={{
            color: "#333333",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {t(title)}
        </span>
      </div>
      <div
        style={{
          border: "1px solid #DEDEDE",
          // width: "350px",
          height: "32px",
          marginTop: "10px",
          display: "flex",
        }}
        className="input-number-unit"
      >
        <InputNumber
          placeholder={t(label)}
          onChange={onInputChange.bind(this)}
          value={num}
          controls={false}
          bordered={false}
          {...inputLayout}
          style={{
            width: "90%",
          }}
        />
        <Select
          options={unitData}
          value={unit}
          bordered={false}
          onChange={onUnitChange.bind(this)}
          style={{
            width: "auto !important",
          }}
        />
      </div>
    </>
    // <div className="control-normal-container" key={index}>
    //   <div className="title-pannel">{t(title)}</div>
    //   <Input.Group compact style={{ display: 'flex' }}>
    //     <InputNumber {...inputLayout} controls={false} value={num} bordered={false} placeholder={t(label)} onChange={onInputChange.bind(this)} style={{ flex: 1 }} />
    //     <Select options={unitData} value={unit} bordered={false} className='common-select' onChange={onUnitChange.bind(this)} style={{ minWidth: '100px' }} />
    //   </Input.Group>

    // </div>
  );
}
