import React, { useEffect, useState } from "react";
import { Button, Avatar, Input, Carousel } from "antd";
import { useUserId } from "../../../contexts/UserIdProvider";
import nextIcon from "../../../resource/icons/feed/next.svg";
import preIcon from "../../../resource/icons/feed/pre.svg";
import FeedCommentItem from "../comment/FeedCommentItem";
import { create } from "../../../api/feed";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./style/feedForward.less";
import { localizeImg } from "../../../constants/constants";
import { useHistory } from "react-router-dom";
import { checkLimit } from "../../../api/common";
import { RoleLimit } from "../../../constants/roleType.enum";
import AvatarBasic from "../../common/avatar/AvatarBasic";
import VideoBasic from "../../common/video/VideoBasic";
import FeedListRow from "../FeedListRow";
import ForwardInputSource from "./forwardInputSource/ForwardInputSource";
import closeIcon from "../../../resource/icons/feed/detail/close.svg";

export default function FeedForward(props) {
  const { user } = useUserId();
  const { t } = useTranslation();
  const { data, onLike, onClose, onRefresh } = props;

  const { TextArea } = Input;

  const feedId = data._id;
  const { userInfo, images, videos } = data;
  const userItem = userInfo && userInfo.length > 0 ? userInfo[0] : {};
  const [content, setContent] = useState();
  let _carousel;
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(user?.userInfo);

  useEffect(() => {
    if (user && user.userInfo) {
      setCurrentUser(user.userInfo);
    }
  }, [user]);

  const onSave = async () => {
    if (!checkLimit(currentUser, RoleLimit.FEED, history)) {
      return;
    }
    if (!content) {
      toast.info("THE_CONTENT_CANNOT_BE_NULL", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    let pms = {
      userId: user.userId,
      sourceFeedId: feedId,
      content,
      visible: true,
      // mentions, images, videos
    };
    let result = await create(pms);
    onClose && onClose();
    onRefresh && onRefresh();
  };

  const initCarousel = (e) => {
    _carousel = e;
  };

  const onCarouselNext = () => {
    _carousel.next();
  };

  const onCarouselPre = () => {
    _carousel.prev();
  };

  const onKeyDownchange = (e) => {
    if (e.keyCode == 13) {
      onSave && onSave();
    }
  };

  return (
    <div className="feed-forward-cover">
      <div className="title-panel">
        <div className="title">{t("PUBLISH")}</div>
        <img src={closeIcon} onClick={() => onClose && onClose()}  className="cursor-pointer"/>
      </div>
      <div>
        <TextArea
          className="forward-feed-input-panel"
          value={content}
          placeholder={t("FORWARD")}
          onChange={(e) => setContent(e.target.value)}
          onKeyDown={(e) => onKeyDownchange(e)}
        />
      </div>
      <div className="feed-forward-source-item-panel">
        <ForwardInputSource data={data}></ForwardInputSource>
      </div>
      <div className="forward-panel">
        <div className="forward-right">
          <div className="forward-comment-panel">
            <Button className="forward-comment-btn" onClick={onSave.bind(this)}>
              {t("PUBLISH")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
