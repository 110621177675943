import React, { useEffect, useState } from "react";
import { Image } from "antd";
import { FormattedMessage } from "react-intl";
import { FileRepository, ImageSize } from '@amityco/js-sdk';

const ImageCom = ({ data, fileInfo }) => {
  const [mediaUrl,setMediaUrl] = useState("");
  
  useEffect(() => {
    if (fileInfo?.fileId) {
      requestFileInfo(fileInfo?.fileId);
    }
  }, [fileInfo]);

  const requestFileInfo = async () => {
    // console.log("requestFileInfo fileInfo?.fileId:", fileInfo?.fileId);
    // const resp = await FileRepository.getFile(fileInfo?.fileId);
    const resp = await FileRepository.getFileUrlById({
      fileId: fileInfo?.fileId,
      imageSize: ImageSize.Medium,
    });
    setMediaUrl(resp)
    // console.log("requestFileInfo:", resp);
  };

  return <Image src={mediaUrl} />;
};

export default ImageCom;
