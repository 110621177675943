import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./BuyerList.module.sass";
import Card from "../../core-digital-template/components/Card";
import { numberWithCommas } from "../../core-digital-template/utils.js";
import { useUserId } from "../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "../../constants/statusCode.enum";
import Icon from "../../core-digital-template/components/Icon";
import { useHistory } from "react-router";
import { Path, PathTools } from "../../constants/path";
import { adminBuyerList } from "../../api/user";
import { localizeValue } from "../../constants/constants";
import { NavLink } from "react-router-dom";
import { removeCompanyInfo } from "../../api/company";
import { deepClone } from "../../utils/utils";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";

const BuyerList = ({ className }) => {
  const { t, i18n } = useTranslation();
  const { user, isWinery } = useUserId();

  const history = useHistory();

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    loadData();
  }, [user]);

  const loadData = async () => {
    let pms = { userId: user.userId };
    let result = await adminBuyerList(pms);
    console.log("BuyerList result", result);
    if (result && result.length > 0) {
      let temp = deepClone(items);
      temp = result;
      setItems(temp);
    }
  };

  const onDeleteCompany = async (e) => {
    const pms = { coId: e._id };
    console.log("pms", pms);
    await removeCompanyInfo(pms);
    loadData();
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title={t("BUYER_LIST")}
      classTitle="title-blue"
      // head={
      //   <div onClick={() => history.push(Path.vinbipAddOrUpdateSuplier)}>
      //     <Icon name="plus-circle" size="24" />
      //   </div>
      // }
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>{t("COMPANY_NAME")}</div>
            <div className={styles.col}>{t("NICK_NAME")}</div>
            <div className={styles.col}>{t("EMAIL")}</div>
            <div className={styles.col}>{t("PHONE_NUMBER")}</div>
            <div className={styles.col}>{t("OPERATIONS")}</div>
          </div>
          {items.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>
                {/* {localizeValue(i18n.language, x.companyName)} */}
                {localizeValue("fr", x.companyName)}
              </div>
              <div className={styles.col}>{x?.userInfo?.nickName}</div>
              <div className={styles.col}>{x?.userInfo?.email}</div>
              <div className={styles.col}>{x?.userInfo?.phoneNumber}</div>
              {/* <div className={styles.col}>{x.size}</div> */}
              <div className={styles.col} style={{ cursor: "pointer" }}>
                <NavLink
                  to={PathTools.joinPath(
                    Path.visitorCompanyDetail,
                    localizeValue("fr", x.companyName)
                  )}
                >
                  {t("VIEW_PROFILE")}
                </NavLink>
                <Popconfirm
                  title={t("CONFIRM_DELETE")}
                  description={t("ARE_YOU_SURE_TO_DELETE_THIS")}
                  okText={t("CONFIRM_DELETE")}
                  cancelText={t("NO")}
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                  onConfirm={onDeleteCompany.bind(this, x)}
                >
                  <span
                    style={{ marginLeft: "10px" }}
                  >
                    {t("DELETE")}
                  </span>
                </Popconfirm>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default BuyerList;
