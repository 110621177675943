import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useHistory, useLocation } from "react-router-dom";
import TextInput from "../../core-digital-template/components/TextInput";
import VinbipLogo from "../../core-digital-template/components/VinbipLogo";
import { Checkbox } from "antd";
import { toastError, toastInfo } from "../../api/toast";
import { login } from "../../api/login";
import { setBearerToken } from "../../api/api";
import { getTokenExpireTime } from "../../utils/jwt";
import { useAuth } from "../../contexts/AuthProvider";
import { useUserId } from "../../contexts/UserIdProvider";
import { BrowseType } from "../../constants/browseType.enum";
import { browseRecordCreate } from "../../api/browseRecord";
import { useTranslation } from "react-i18next";
import { deepClone } from "../../utils/utils";
import { createUser } from "../../api/user";
import { statusCode } from "../../constants/statusCode.enum";
import Dropdown from "../../core-digital-template/components/Dropdown";
import { Path } from "../../constants/path";
import InputCapture from "../../core-digital-template/components/InputCapture";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "./signUp.less";
import { SYS_ENV_LANGUAGE } from "../../constants/env.enum";
import { LanguagesKey } from "../../constants/languages.enum";

export default function SignUp() {
  const { createToken } = useAuth();
  const { loadUserInfo } = useUserId();
  const heightWindow = use100vh();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { state } = useLocation();

  const [referUrl, setReferUrl] = useState("/");

  const [termsAgreed, setTermsAgreed] = useState(false);

  const [formData, setFormData] = useState({
    type: "SUPPLIER",
  });

  const [userType, setUserType] = useState([
    { value: "SUPPLIER", label: t("I_AM_SUPPLIER") },
    { value: "BUYER", label: t("I_AM_BUYER") },
  ]);

  const [currentLanguage, setCurrentLanguage] = useState(SYS_ENV_LANGUAGE);

  const [languages, setLanguages] = useState([
    {
      label: LanguagesKey.ZH,
      value: "zh",
    },
    {
      label: LanguagesKey.EN,
      value: "en",
    },
    {
      label: LanguagesKey.FR,
      value: "fr",
    },
  ]);

  useEffect(() => {
    if (state) {
      let { refer, clearToken } = state;
      if (clearToken) {
        localStorage.clear();
      }
      if (refer) {
        setReferUrl(refer);
      }
    }
  }, []);

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
    }
  }, [currentLanguage]);

  const onInputValueChange = async (name, e) => {
    let temp = deepClone(formData);
    temp[name] = e;
    setFormData(temp);
  };

  const onPhoneNumberChange = (phone, meta) => {
    const countrycodeRegex = /^\+(\d+)/;
    const match = meta?.inputValue?.match(countrycodeRegex);
    if (!match || match.length < 2) {
      return;
    }
    const countrycode = match ? match[1] : null;

    const remainingDigitsRegex = /\s(.*)/;
    const matchRemainingDigits = meta?.inputValue?.match(remainingDigitsRegex);
    if (!matchRemainingDigits || matchRemainingDigits.length < 2) {
      return;
    }
    const remainingDigits = matchRemainingDigits
      ? matchRemainingDigits[1]
      : null;

    let temp = deepClone(formData);
    temp["phoneNumber"] = remainingDigits.replace(/\s/g, "");
    temp["phone"] = {
      code: countrycode,
      phone: remainingDigits.replace(/\s/g, ""),
      iso2: meta?.country?.iso2,
      name: meta?.country?.name,
    };
    setFormData(temp);
  };

  const onAgreeTermsChange = (e) => {
    console.log("-------onAgreeTermsChange:", e.target.checked);
    setTermsAgreed(e.target.checked);
  };

  // const onCheckEmailExists = async (e) => {
  //   let val = e.target.value;
  //   if (val && isEmail(val)) {
  //     let pms = { email: val };
  //     let result = await userExists(pms);
  //     if (result.statusCode !== statusCode.SUCCESS) {
  //       toastError(t("EMAIL_ALREADY_EXIST"));
  //       setEmailExists(true);
  //     } else {
  //       setEmailExists(false);
  //     }
  //   } else {
  //     toastError(t("EMAIL_FORMAT_ERROR"));
  //     setEmailExists(false);
  //   }
  // };

  // const onCheckPhoneExists = async (e) => {
  //   let val = e.target.value;
  //   if (val) {
  //     let pms = { phone: val };
  //     let result = await userExists(pms);
  //     if (result.statusCode !== statusCode.SUCCESS) {
  //       toastError(t("PHONE_ALREADY_EXIST"));
  //       setPhoneExists(true);
  //     } else {
  //       setPhoneExists(false);
  //     }
  //   }
  // };

  const onFinish = async () => {
    console.log("------form data:", formData);

    if (!termsAgreed) {
      toastError(t("NEED_AGREE_USER_TERMS"));
      return;
    }

    if (!formData.firstName || !formData.lastName) {
      toastError(t("ACCOUNT_IS_EMPTY"));
      return;
    }
    if (!formData.password) {
      toastError(t("PASSWORD_IS_EMPTY"));
      return;
    }

    if (!formData.email) {
      toastError(t("EMAIL_ERROR_MSG"));
      return;
    }

    if (!formData.phoneNumber) {
      toastError(t("INVALID_PHONE_NUMBER"));
      return;
    }

    // if (emailExists) {
    //   toastError(t("EMAIL_ALREADY_EXIST"));
    //   return;
    // }

    // if (phoneExists) {
    //   toastError(t("PHONE_ALREADY_EXIST"));
    //   return;
    // }

    let temp = deepClone(formData);

    temp.name = temp.lastName + temp.firstName;

    let resp = await createUser(temp);
    if (resp) {
      let flag = resp.statusCode;
      switch (flag) {
        case statusCode.SUCCESS:
          toastInfo(t("VINBIP_SEND_EMAIL_PLAESE_CHECK"));
          setTimeout(() => {
            onLogin(temp.email, temp.password);
          }, 1000);
          break;
        case statusCode.EmailExists:
          toastInfo(t("EMAIL_ALREADY_EXIST"));
          break;
        case statusCode.PhoneExists:
          toastInfo(t("PHONE_ALREADY_EXIST"));
          break;
        case statusCode.CreateIMUserFailed:
          toastInfo(t("CREATE_IMUSER_ERROR"));
          break;
        case statusCode.PARAMSINVALID:
          toastInfo(t("CAPTURE_ERROR_MESSAGE"));
          break;
        case statusCode.Failed:
          toastInfo(t("SAVE_FAILED"));
          break;
        default:
          break;
      }
    } else {
      toastError(t("SAVE_FAILED"));
    }
  };

  const onLogin = async (email, pwd) => {
    const pmsLogin = { email, password: pwd };
    let result = await login(pmsLogin);
    if (result) {
      const { access_token, user } = result;
      const userId = user._id;
      await setBearerToken(access_token);

      const expireTime = getTokenExpireTime();
      createToken({ value: access_token, expireTime });
      await loadUserInfo(userId);
      addLoginRecord(userId);
      history.replace(
        Path.companyDetail.replace(":coname", formData?.companyName)
      );
    }
  };

  const addLoginRecord = (userId) => {
    let pms = {
      userId,
      busType: BrowseType.LOGIN,
    };
    browseRecordCreate(pms);
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className="absolute right-5 top-5">
        <Dropdown
          className={styles.dropdown_field}
          tooltip=""
          value={currentLanguage}
          setValue={(e) => setCurrentLanguage(e)}
          options={languages}
        />
      </div>
      <div className={styles.wrapper}>
        <Link to="/">
          <VinbipLogo withText />
        </Link>

        <div className={cn("h4", styles.title)} style={{ marginTop: "50px" }}>
          {t("SIGN_UP")}
        </div>
        <div className={styles.body}>
          <div className={styles.note}>{t("LOGIN_TIPS")}</div>
          <TextInput
            className={styles.field}
            name="lastName"
            type="text"
            placeholder={t("LAST_NAME")}
            required
            icon="profile-circle"
            onChange={onInputValueChange.bind(this, "lastName")}
          />
          <TextInput
            className={styles.field}
            name="firstName"
            type="text"
            placeholder={t("FIRST_NAME")}
            required
            icon="profile-circle"
            onChange={onInputValueChange.bind(this, "firstName")}
          />
          <TextInput
            className={styles.field}
            name="companyName"
            type="text"
            placeholder={t("COMPANY_NAME")}
            required
            icon="store"
            onChange={onInputValueChange.bind(this, "companyName")}
          />
          <TextInput
            className={styles.field}
            name="email"
            type="email"
            placeholder={t("EMAIL")}
            required
            icon="mail"
            onChange={onInputValueChange.bind(this, "email")}
          />
          <TextInput
            className={styles.field}
            name="password"
            type="password"
            placeholder={t("PASSWORD")}
            required
            icon="lock"
            onChange={onInputValueChange.bind(this, "password")}
          />
          <PhoneInput
            defaultCountry="fr"
            className={`${styles.field} w-full h-12`}
            onChange={onPhoneNumberChange.bind(this)}
          />
          <InputCapture
            icon="lock"
            phoneNumber={formData?.phone?.code + formData?.phone?.phone}
            name="capture"
            type="capture"
            className={styles.field}
            placeholder={t("CAPTURE_CODE")}
            onChange={onInputValueChange.bind(this, "capture")}
          />
          <Dropdown
            tooltip={t("PLEASE_SELECT_YOUR_ROLE")}
            setValue={onInputValueChange.bind(this, "type")}
            value={formData.type}
            options={userType}
          />
          <div style={{ height: "24px" }}></div>
          <button
            className={cn("button", styles.button)}
            onClick={onFinish.bind(this)}
          >
            {t("SIGN_UP")}
          </button>
          <div className={styles.info}>
            <Checkbox onChange={onAgreeTermsChange.bind(this)}>
              {t("AGREE_TERMS")}
              &nbsp;<a target="_blank" href="https://vinbip.com/terms">{t("TERMS_SERVICE")}</a>
              ,&nbsp;<a target="_blank" href="https://vinbip.com/privacy">{t("PRIVACY_POLICY")}</a>
            </Checkbox>
            
          </div>
        </div>
      </div>
    </div>
  );
}
