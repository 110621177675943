import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import jiantou from "../../resource/images/france-region/jiantou.svg";
import china from "../../resource/images/country/china.jpg";
import { useTranslation } from "react-i18next";
import "./style/explore.less";

export default function ExploreImporter() {
  const { t } = useTranslation();

  return (
    <div className="explore-page">
      <div className="explore-banner">
        <div className="explore-title">Explore importer around the world</div>
      </div>
      <div className="explore-content">
        <div className="img">
          <div className="middle-content">
            <img
              src={china}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-button">
              <Link to={"/explore/importer/china"}>
                <Button className="button">
                  Discover
                  <img src={jiantou} className="jiantou" />
                </Button>
              </Link>
            </div>
            <div className="zi">{t("CHINA_MAINLAND")}</div>
          </div>
          {/* <div className="middle-content">
                        <img src={landscapeFrance} alt="landscape-france" className="landscape-france" />
                        <div className="middle-content-button">
                            <Button className="button">Discover
                            <img src={jiantou} className="jiantou"/>
                            </Button>
                        </div>
                        <div className="zi">意大利</div>
                    </div> */}
        </div>
        {/* <div className="img">
                    <div className="middle-content">
                        <img src={landscapeFrance} alt="landscape-france" className="landscape-france" />
                        <div className="middle-content-button">
                            <Button className="button">Discover
                            <img src={jiantou} className="jiantou"/>
                            </Button>
                        </div>
                        <div className="zi">西班牙</div>
                    </div>
                    <div className="middle-content">
                        <img src={landscapeFrance} alt="landscape-france" className="landscape-france" />
                        <div className="middle-content-button">
                            <Button className="button">Discover
                            <img src={jiantou} className="jiantou"/>
                            </Button>
                        </div>
                        <div className="zi">瑞士</div>
                    </div>
                </div>
                <div className="img">
                    <div className="middle-content">
                        <img src={landscapeFrance} alt="landscape-france" className="landscape-france" />
                        <div className="middle-content-button">
                            <Button className="button">Discover
                            <img src={jiantou} className="jiantou"/>
                            </Button>
                        </div>
                        <div className="zi">葡萄牙</div>
                    </div>
                    <div className="middle-content">
                        <img src={landscapeFrance} alt="landscape-france" className="landscape-france" />
                        <div className="middle-content-button">
                            <Button className="button">Discover
                            <img src={jiantou} className="jiantou"/>
                            </Button>
                        </div>
                        <div className="zi">德国</div>
                    </div>
                </div> */}
      </div>
    </div>
  );
}
