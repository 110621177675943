import { RequestType } from "../constants/requestType.enum";
import { actionRequest, formatActionResponse, formatResponse, formatResponseArray } from "./api";
import { requestGetUrlFormat } from "./common";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export async function getArticles(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.article, pms);
}

/**
 *
 * @param pms
 */
export async function oneArticle(pms: any) {
    let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.oneArticle, pms))
    return formatResponseArray(data);
}

/**
 *
 * @param pms
 */
export async function articleCreate(pms: any) {
    let data = await actionRequest(RequestType.POST, apiPath.articleCreate, pms);
    return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function articleUpdate(pms: any) {
    let data = await actionRequest(RequestType.POST, apiPath.articleUpdate, pms);
    return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function articleRemove(pms: any) {
    let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.articleRemove, pms));
    return formatResponse(data);
}


/**
 *
 * @param pms
 */
export async function articleDetail(pms: any) {
    let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.articleDetail, pms));
    return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function articleMediaList(pms: any) {
    let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.articleMediaList, pms));
    return formatResponse(data);
}
