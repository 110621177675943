import { useState, useEffect } from 'react';
import AgoraRTC, {
  IAgoraRTCClient, 
  IAgoraRTCRemoteUser, 
  MicrophoneAudioTrackInitConfig, 
  CameraVideoTrackInitConfig, 
  IMicrophoneAudioTrack, 
  ICameraVideoTrack, 
  ILocalVideoTrack, 
  ILocalAudioTrack
} from 'agora-rtc-sdk-ng';
import { getAgoraToken } from "../api/chat";

export default function useAgora(client: IAgoraRTCClient | undefined)
  : {
    localAudioTrack: ILocalAudioTrack | undefined,
    localVideoTrack: ILocalVideoTrack | undefined,
    leave: Function,
    joinAudio: Function,
    joinVideo: Function,
    remoteUsers: IAgoraRTCRemoteUser[],
    unPublish: Function,
  } {
  const [localVideoTrack, setLocalVideoTrack] = useState<ILocalVideoTrack | undefined>(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState<ILocalAudioTrack | undefined>(undefined);
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);

  const getToken = async (channel: string) => {
    let pms = { channelName: channel };
    let result = await getAgoraToken(pms);
    return result;
  };

  async function createLocalVideoTracks(audioConfig?: MicrophoneAudioTrackInitConfig, videoConfig?: CameraVideoTrackInitConfig)
    : Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]> {
    const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(audioConfig, videoConfig);

    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);
    return [microphoneTrack, cameraTrack];
  }

  async function createLocalAudioTracks(audioConfig?: MicrophoneAudioTrackInitConfig, videoConfig?: CameraVideoTrackInitConfig)
    : Promise<[IMicrophoneAudioTrack]> {
    // 采集麦克风
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(audioConfig);
    setLocalAudioTrack(microphoneTrack);
    return [microphoneTrack];
  }

  async function joinVideo(channel: string, uid?: string | number | null) {
    if (!client) return;
    try {
      const [microphoneTrack, cameraTrack] = await createLocalVideoTracks();
      const token = await getToken(channel);

      await client.join(process.env.REACT_APP_AGORA_APP_ID!, channel, token, uid);
      await client.publish([microphoneTrack, cameraTrack]);
    } catch (error) {
      console.log('-------------join video error:', error);
    }
  }

  async function joinAudio(channel: string, uid?: string | number | null) {
    if (!client) return;
    const [microphoneTrack] = await createLocalAudioTracks();
    const token = await getToken(channel);

    await client.join(process.env.REACT_APP_AGORA_APP_ID!, channel, token, uid);
    await client.publish([microphoneTrack]);
  }

  async function unPublish() {
    if (!client) return;
    await client.unpublish();
  }

  async function leave() {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    // setJoinState(false);
    await client?.leave();
  }

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
      await client.subscribe(user, mediaType);
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);
    client.on('user-joined', handleUserJoined);
    client.on('user-left', handleUserLeft);

    return () => {
      client.off('user-published', handleUserPublished);
      client.off('user-unpublished', handleUserUnpublished);
      client.off('user-joined', handleUserJoined);
      client.off('user-left', handleUserLeft);
    };
  });

  return {
    localAudioTrack,
    localVideoTrack,
    remoteUsers,
    leave,
    joinAudio,
    joinVideo,
    unPublish
  };
}
