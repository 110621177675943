import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "antd";

export default function MyPoint() {
  const { t } = useTranslation();

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_POINT")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management normal-shadow-padding10-white">
        <div className="common-management-title">{t("MY_POINT")}</div>
        <div style={{ width: "1000px", backgroundColor: "white" }}></div>
      </div>
    </div>
  );
}
