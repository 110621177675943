import { createCheckoutSession } from "../../api/stripe";

export default function PackageDesign() {
  const submitToStripe = async () => {
    let data = await createCheckoutSession({
      priceId: "price_1JjOZ4CTcstZ5itJnRHDI6Dk",
    });
    if (data.message === "REDIRECT") {
      window.location.href = data.result;
    }
  };

  return (
    <section>
      <div className="product">
        <img
          src="https://i.imgur.com/EHyR2nP.png"
          alt="The cover of Stubborn Attachments"
        />
        <div className="description">
          <h3>Package design</h3>
          <h5>€49.99</h5>
        </div>
      </div>
      <button onClick={submitToStripe}>Checkout</button>
    </section>
  );
}
