import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { deepClone, getScrollHeight } from "../../../utils/utils";
import { useUserId } from "../../../contexts/UserIdProvider";
import { customerImporterPage } from "../../../api/orders";
import CompanyItemNormal from "../../shared/CompanyItemNormal";

export default function ClientList() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const currentUser = user?.userInfo;

  const [dataList, setDataList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const scrollHeight = getScrollHeight(document);

  useEffect(() => {
    loadData();
  }, [pageIndex]);

  const loadData = async () => {
    setLoading(true);
    let pms = {
      page: pageIndex,
      per_page: pageSize,
      coId: currentUser.companyId,
    };
    let data = await customerImporterPage(pms);
    console.log("--------customerImporterPage", data, pms);
    if (data && data.total > 0) {
      let { result } = data;
      let _list = [];
      if (pageIndex > 0) {
        _list = deepClone(dataList);
      }
      result.forEach((item) => {
        if (item.wineryInfo && item.wineryInfo.length > 0) {
          _list.push(item.importerInfo[0]);
        }
      });
      setDataList(_list);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("CLIENT_LIST")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management">
        <InfiniteScroll
          className="scroll-content"
          height={scrollHeight}
          dataLength={dataList.length}
          next={handleInfiniteOnLoad.bind(this)}
          hasMore={hasMore}
        >
          <div className={"common-management-title"}>{t("CLIENT_LIST")}</div>
          <div className="common-management-panel">
            <List
              itemLayout="vertical"
              size="large"
              dataSource={dataList}
              renderItem={(item) => <CompanyItemNormal detail={item} />}
            >
              {loading && hasMore && (
                <div>
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                </div>
              )}
            </List>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}
