import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useUserId } from "../../../contexts/UserIdProvider";
import { getStripeAccount, upgradeCompanyInfo } from "../../../api/stripe";
import { userDetail } from "../../../api/user";
import { useTranslation } from "react-i18next";
import { Button, Result } from "antd";

const qs = require("query-string");

export default function CompanyReturn() {
  // let { user, loadUserInfo } = useUserId();
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const [accountId, setAccountId] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    if (search) {
      let searchObj = qs.parse(search);

      let { accountId } = searchObj;

      if (accountId) {
        setAccountId(accountId);
      }
      let { email } = searchObj;
      if (accountId) {
        setEmail(email);
      }
    }
  }, []);

  useEffect(() => {
    if (accountId) {
      onAccountConfirm();
    }
  }, [accountId]);

  const onAccountConfirm = async () => {
    // const pms = {
    //   accountId: accountId,
    // };
    // const result = await getStripeAccount(pms);
    // if (
    //   result.result.details_submitted &&
    //   result.result.currently_due?.length === 0
    // ) {
    //   const pms = {
    //     accountId: accountId,
    //     userId: user.userId,
    //   };
    //   let result = await upgradeCompanyInfo(pms);
    //   console.log("-------upgradeCompanyInfo:", result);
    //   if (result.statusCode == 200) {
    //     loadUserInfo();
    //     history.push("/feed");
    //   }
    // } else {
    //   history.push("/company/verify-deprecated");
    // }
  };

  return (
    <Result
      status="success"
      title={t("SUCCESS")}
      subTitle={t("EMAIL_VERIFIED_BY_LINK")}
      extra={
        <Link to={"/"}>
          <Button type="secondary">{t("BACK")}</Button>
        </Link>
      }
    />
  );
}
