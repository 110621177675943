import React, { useEffect, useState } from "react";
import styles from "./CompanyMessaging.module.sass";
import cn from "classnames";
import Users from "./Users";
import Messages from "./Messages";
import { getBlockList, pageFriends } from "../../../api/contact";
import { useUserId } from "../../../contexts/UserIdProvider";
import { getGroups } from "../../../api/chat";
import { useIMContext } from "../../../contexts/IMProvider";
import useIM from "../../../hooks/useIM";
import { deepClone } from "../../../utils/utils";
import { getCompanyUsers } from "../../../api/company";

const navigation = [
  {
    title: "Customers",
    icon: "profile-circle",
  },
  {
    title: "Everyone",
    icon: "lightning",
  },
];

const actions = [
  {
    title: "Mark as read",
    icon: "check",
    action: () => console.log("Mark as read"),
  },
  {
    title: "Delete message",
    icon: "trash",
    action: () => console.log("Delete message"),
  },
];

const parameters = [
  {
    title: "Customer since",
    content: "Sep 2021",
  },
  {
    title: "Purchased",
    content: "21 items",
  },
  {
    title: "Lifetime",
    content: "$1,235.00",
  },
];

const CompanyMessaging = ({ coId,companyInfo }) => {
  const { user } = useUserId();
  const { userId, userInfo } = user;

  const {
    webIM,
    imLoginStatus,
    newMsg,
    sessionMessageList,
    initIMLogin,
    getSessionMessage,
    setChatCallMsg,
  } = useIMContext();
  const {
    messageList,
    newGroup,
    fetchHistoryMessage,
    readChannelMessage,
    sendTextMessage,
    sendCustomMessage,
    createGroup,
    quitGroup,
    dissolveGroup,
    removeGroupMember,
    addGroupMember,
  } = useIM(webIM);

  const [users, setUsers] = useState([]);

  const [activeUser, setActiveUser] = useState({});

  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");

  let keyword = "";

  useEffect(() => {
    loadCompanyUsers(coId);
  }, [coId]);

  useEffect(() => {
    if (webIM) {
      initIMLogin(userInfo);
    }
  }, [webIM, imLoginStatus]);

  useEffect(() => {
    let list = messageList;
    console.log("------messagelist:", messageList, users);
  }, [messageList]);

  useEffect(() => {
    if (newMsg) {
      let temp = deepClone(users);
      temp.map((mapItem) => {
        if (mapItem._id == newMsg.from && newMsg.from != activeUser?.id) {
          mapItem.new = true;
        }
      });
      setUsers(temp);
    }
  }, [newMsg]);

  const loadCompanyUsers = async (coId) => {
    let pms = { coId: coId };
    let result = await getCompanyUsers(pms);
    if (result && result.total > 0) {
      let list = deepClone(users);
      list = result.result;
      list.map((mapItem) => {
        mapItem.id = mapItem._id;
      });
      setUsers(list);
    }
  };

  const handleSubmit = (e) => {
    alert();
  };

  const onSwitchUser = (e) => {
    setActiveUser(e);

    let temp = deepClone(users);
    temp.map((mapItem) => {
      if (mapItem.id === e.id) {
        mapItem.new = false;
      }
    });
    console.log("onSwitchUser:", temp);
    setUsers(temp);
  };

  return (
    <div className={cn(styles.wrapper, { [styles.active]: visible })}>
      <Users
        className={styles.users}
        navigation={navigation}
        items={users}
        setVisible={setVisible}
        search={search}
        setSearch={setSearch}
        onSubmit={() => handleSubmit()}
        onSwitchUser={onSwitchUser.bind(this)}
      />
      <Messages
        className={styles.messages}
        visible={visible}
        setVisible={setVisible}
        actions={actions}
        parameters={parameters}
        // messages={messages}
        activeUser={activeUser}
        companyInfo={companyInfo}
      />
    </div>
  );
};

export default CompanyMessaging;
