import React, { useState, useEffect } from "react";
import { Layout, Skeleton, Button, Popover, Dropdown, Menu, Modal } from "antd";
import { useHistory } from "react-router-dom";
import bottleWine from "../../../resource/icons/left-sider/bottle_wine.svg";
import shipIcon from "../../../resource/icons/left-sider/ship.svg";
import networksIcon from "../../../resource/icons/left-sider/contact_icon.png";
// import contactIcon from "../../../resource/icons/left-sider/contact_book.svg";
import invoiceIcon from "../../../resource/icons/navbar/invoices.svg";
import vip from "../../../resource/icons/left-sider/vip.svg";
import supportIcon from "../../../resource/icons/left-sider/support_icon.png";
import cancelIcon from "../../../resource/icons/left-sider/cancel.svg";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { contactUserFans } from "../../../api/contact";
import { statusCode } from "../../../constants/statusCode.enum";
import { Link } from "react-router-dom";
import "./style/leftSiderSmall.less";
import { useContextData } from "../../../contexts/DataProvider";
import { getLogs } from "../../../api/actionLoggers";
import { checkLimit, getFileTypeByPath } from "../../../api/common";
import { RoleLimit } from "../../../constants/roleType.enum";
import {} from "antd";
import { localizeImg } from "../../../constants/constants";
import { companyImporterDesc, companyWineryDesc } from "../../../api/company";
import { goToProfilePage } from "../../../utils/utils";
import { loadProductConfig } from "../../../api/product";
import AvatarBasic from "../../common/avatar/AvatarBasic";
import { UserNavigationHistoryEnum } from "../../../constants/UserNavigationHistory.enum";
import editIcon from "../../../resource/icons/home/edit.svg";
import { useAuth } from "../../../contexts/AuthProvider";

import homeIcon from "../../../resource/icons/left-sider/home/home.svg";
import messageIcon from "../../../resource/icons/left-sider/home/message.svg";
import contactIcon from "../../../resource/icons/left-sider/home/contact.svg";
import poiIcon from "../../../resource/icons/left-sider/home/poi.svg";
import dashboardIcon from "../../../resource/icons/left-sider/home/dashboard.svg";
import archiveHistoryIcon from "../../../resource/icons/left-sider/home/archive_history.svg";
import partnersIcon from "../../../resource/icons/left-sider/home/member_partners.svg";
import discoverIcon from "../../../resource/icons/left-sider/home/discover.svg";
import myShopIcon from "../../../resource/icons/left-sider/home/my_shop.svg";
import savedIcon from "../../../resource/icons/left-sider/home/saved.svg";
import plusIcon from "../../../resource/icons/left-sider/home/plus.svg";
import editProductIcon from "../../../resource/icons/left-sider/home/edit_product.svg";
import FriendsPanel from "../../common/friends/FriendsPanel";
import { UserType } from "../../../constants/usersType.enum";
import ShoppingList from "../../shop/shopping-cart/ShoppingList";
import PoiPanel from "../../common/poi/PoiPanel";
import MessagePanel from "../../common/message/MessagePanel";
import { useIMContext } from "../../../contexts/IMProvider";
import { imClose } from "../../../utils/imHelper";

export default function LeftSiderSmall(props) {
  const { t, i18n } = useTranslation();
  const { Sider } = Layout;
  const history = useHistory();
  const { isWinery, user } = useUserId();
  const { authTokens } = useAuth();
  const { imLoginStatus, webIM } = useIMContext();
  const { userInfo, userId } = user;
  let { showText } = props;
  // console.log("----------------------LeftSider:", props);
  const [isShowText, setIsShowText] = useState(true);
  const [currentUser, setCurrentUser] = useState(user?.userInfo);
  const { contactNum, feedNum, productNum } = useContextData();
  const [fans, setFans] = useState([]);
  const [fansCount, setFansCount] = useState(0);
  const [step, setStep] = useState([]);

  const [loadingStep, setLoadingStep] = useState(true);
  const [loadingFans, setLoadingFans] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [userCompanyDetail, setUserCompanyDetail] = useState();
  const [locations, setLocations] = useState([]);
  const [lang, setLang] = useState(i18n.language);

  const [logoutConfirmModal, setLogoutConfirmModal] = useState(false);
  const [poiPanelVisible, setPoiPanelVisible] = useState(false);

  useEffect(() => {
    let list = document.getElementsByClassName("ant-popover");
    if (list && list.length > 0) {
      list[0].parentElement.parentElement.style.zIndex = 999;
    }
  }, []);

  useEffect(() => {
    initConfigData();
    if (authTokens) {
      loadStep();
      loadFans();
    } else {
      setLoadingCompany(false);
      setLoadingFans(false);
      setLoadingStep(false);
    }
  }, []);

  useEffect(() => {
    if (user?.userInfo) {
      setCurrentUser(user.userInfo);
    }
  }, [user]);

  useEffect(() => {
    if (currentUser) {
      loadCompany();
    }
  }, [currentUser]);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const loadFans = async () => {
    let pms = { userId };
    let data = await contactUserFans(pms);
    if (data) {
      if (data.statusCode === statusCode.SUCCESS && data.result.total > 0) {
        let { result, total } = data.result;
        result = result
          .filter(
            (item) => item.sourceUserInfo && item.sourceUserInfo.length > 0
          )
          .map((item) => {
            return item.sourceUserInfo[0];
          });
        setFans(result);
        setFansCount(total);
      }
    }
    setLoadingFans(false);
  };

  const loadCompany = async () => {
    let data;
    const pms = { coId: currentUser.companyId };
    if (isWinery) {
      data = await companyWineryDesc(pms);
    } else {
      data = await companyImporterDesc(pms);
    }
    if (data && data.statusCode === statusCode.SUCCESS && data.result) {
      if (data.result.banner) {
        data.result.bannerType = getFileTypeByPath(
          localizeImg(data.result.banner)
        );
      }
      setUserCompanyDetail(data.result);
    }
    setLoadingCompany(false);
  };

  const loadStep = async () => {
    let pms = {
      page: 0,
      per_page: 30,
      id: userId,
      from: 0,
    };
    let result = await getLogs(pms);
    if (result && result.total > 0) {
      let list = [];
      result.result.forEach((item) => {
        let filterList = list.filter((f) => f.type === item.type);
        if (filterList.length === 0) {
          list.push(item);
        }
        if (list.length === 5) {
          return;
        }
      });
      setStep(list);
      // setStepCount(result.total);
      setLoadingStep(false);
    } else {
      setLoadingStep(false);
    }
  };

  const onCompanySetting = (userItem) => {
    history.push("/company/settings-deprecated");
  };

  const onCompanyHome = () => {
    let path = "/profile-deprecated/";
    history.push(path + userId);
  };

  const onProductEdit = () => {
    if (checkLimit(currentUser, RoleLimit.PRODUCT_MANAGEMENT, history)) {
      history.push("/shop/products");
    }
  };

  const getStepIcon = (flag) => {
    let result = networksIcon;
    switch (flag) {
      case UserNavigationHistoryEnum.IMPORTER:
        result = shipIcon;
        break;
      case UserNavigationHistoryEnum.WINERY:
        result = bottleWine;
        break;
      case UserNavigationHistoryEnum.SHOPPING_CART:
        result = invoiceIcon;
        break;
      default:
        break;
    }
    return result;
  };

  const messageContent = () => {
    return <MessagePanel></MessagePanel>;
  };

  const contactContent = () => {
    return <FriendsPanel></FriendsPanel>;
  };

  const poiContent = () => {
    return <PoiPanel></PoiPanel>;
  };

  function logOut() {
    if (imLoginStatus) {
      imClose(webIM);
    }
    localStorage.clear();
    history.push("/login");
  }

  const menu_language = (
    <Menu>
      <Menu.Item key="zh" onClick={onCompanyHome.bind(this)}>
        <span className="">{t("MY_PROFILE")}</span>
      </Menu.Item>
      <Menu.Item key="en" onClick={logOut.bind(this)}>
        <span className="">
          {isWinery ? t("SWITCH_TO_BUYER") : t("SWITCH_TO_SUPPLIER")}
        </span>
      </Menu.Item>
      <Menu.Item key="fr" onClick={() => setLogoutConfirmModal(true)}>
        <span className="">{t("LOG_OUT")}</span>
      </Menu.Item>
    </Menu>
  );

  const menu_my_shop = (
    <Menu>
      <Menu.Item
        key="zh"
        onClick={() =>
          history.push("/shop/products", { jump: "/shop/products/create-deprecated" })
        }
      >
        <img src={plusIcon} style={{ marginRight: "11px" }} />
        <span className="">{t("CREATE_NEW_PRODUCT")}</span>
      </Menu.Item>
      <Menu.Item key="fr" onClick={() => history.push("/shop/products")}>
        <img src={editProductIcon} style={{ marginRight: "11px" }} />
        <span className="">{t("EDIT_PRODUCT")}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Sider width={60} className="left-sider-small-container scroll-content">
      <div className="left-sider-content-panel">
        <div
          className="row-panel panel-hover"
          onClick={() => history.push("/")}
        >
          <img src={homeIcon} className="icon" />
        </div>
        {user?.userInfo?.type != UserType.BUYER && userCompanyDetail && (
          <div
            className="row-panel  panel-hover"
            onClick={() => history.push("/discover-deprecated")}
          >
            <img src={partnersIcon} className="icon" />
          </div>
        )}
        {user?.userInfo?.type == UserType.BUYER && userCompanyDetail && (
          <div
            className="row-panel  panel-hover"
            onClick={() => history.push("/discover-deprecated")}
          >
            <img src={discoverIcon} className="icon" />
          </div>
        )}
        {!loadingCompany && userCompanyDetail && (
          <Dropdown
            overlay={menu_language}
            trigger={["click"]}
            placement="bottomRight"
          >
            <div className="row-panel panel-hover">
              <AvatarBasic
                picture={localizeImg(userCompanyDetail.avatar)}
                size={26}
              />
            </div>
          </Dropdown>
        )}
        <div className="row-panel panel-hover">
          <Popover
            placement="rightTop"
            title={null}
            content={messageContent}
            trigger="click"
          >
            <img src={messageIcon} className="icon" />
          </Popover>
        </div>
        <div className="row-panel panel-hover">
          <Popover
            placement="rightTop"
            title={null}
            content={contactContent}
            trigger="click"
          >
            <img src={contactIcon} className="icon" />
          </Popover>
        </div>
        {/* {user?.userInfo?.type == UserType.BUYER && (
          <div
            className="row-panel panel-hover"
            onClick={() => setPoiPanelVisible(true)}
          >
            <img src={poiIcon} className="icon" />
          </div>
        )} */}
        {user?.userInfo?.type != UserType.BUYER && (
          <Dropdown
            overlay={menu_my_shop}
            trigger={["click"]}
            placement="bottomRight"
            className="left-sider-user-profile-dropdown"
          >
            <div className="row-panel  panel-hover">
              <img src={myShopIcon} className="icon" />
            </div>
          </Dropdown>
        )}
        <div
          className="row-panel  panel-hover"
          onClick={() => history.push("/saved-list")}
        >
          <img src={savedIcon} className="icon" />
        </div>
        <div
          className="row-panel  panel-hover"
          onClick={() => history.push("/browse-record")}
        >
          <img src={archiveHistoryIcon} className="icon" />
        </div>
        <div
          className="row-panel  panel-hover"
          onClick={() => history.push("/dashboard")}
        >
          <img src={dashboardIcon} className="icon" />
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={poiPanelVisible}
        footer={null}
        className="left-sider-poi-panel"
        closable={false}
        onCancel={() => setPoiPanelVisible(false)}
      >
        <PoiPanel></PoiPanel>
      </Modal>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={logoutConfirmModal}
        footer={null}
        className="left-sider-logout-confirm-modal"
        closable={false}
      >
        <div style={{ padding: "20px" }}>
          <img
            src={cancelIcon}
            onClick={() => setLogoutConfirmModal(false)}
            style={{
              cursor: "pointer",
            }}
          />
          <div
            style={{
              marginTop: "20px",
              marginLeft: "40px",
            }}
          >
            <div className="title">{t("LOG_OUT")}</div>
            <div className="desc">{t("WELCOME_BACK_AGAIN")}</div>
            <div className="btn-confirm" onClick={logOut.bind(this)}>
              {t("LOG_OUT")}
            </div>
            <div
              className="btn-cancel"
              onClick={() => setLogoutConfirmModal(false)}
            >
              {t("CANCEL")}
            </div>
          </div>
        </div>
      </Modal>
    </Sider>
  );
}
