import {RequestType} from "../constants/requestType.enum";
import {actionRequest, formatActionResponseArray, formatResponse, formatResponseArray} from "./api";
import {requestGetUrlFormat} from "./common";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export async function discounts(pms: any) {
    let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.discounts, pms));
    return formatResponseArray(data);
}

/**
 *
 * @param pms
 */
export async function discountCreate(pms: any) {
    let data = await actionRequest(RequestType.POST, apiPath.discountCreate, pms);
    return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function discountUpdate(pms: any) {
    let data = await actionRequest(RequestType.POST, apiPath.discountUpdate, pms);
    return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function discountRemove(pms: any) {
    let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.discountRemove, pms));
    return formatResponse(data);
}

export async function discountEvents() {
    let data = await actionRequest(RequestType.GET, apiPath.discountEvents);
    return formatResponseArray(data);
}

/**
 *
 * @param pms
 */
export async function getDiscountDetail(pms: any) {
    let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.discountDetail, pms));
    return formatResponse(data);
}

export function discountProductDetail(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.discountProductDetail, pms);
}