import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import QRCode from "qrcode";
import wxpic from "../../../resource/images/wechat/wxpic.png";
import "../../shared/style/middlePage.less";
import "./style/subscriptionWxPay.less";
import { subscriptionDetail } from "../../../api/subscription";
import { useUserId } from "../../../contexts/UserIdProvider";
import { userDetail } from "../../../api/user";

export default function SubscriptionWxPay() {
  let { user, loadUserInfo } = useUserId();
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();
  const { wxcode, price, name, _id } = state;
  const payPrice = price;
  const orderNum = name;
  const orderId = _id;

  const [dataUrl, setDataUrl] = useState();
  const [payStatus, setPayStatus] = useState(false);

  let orderTimer;
  useEffect(() => {
    generateQR(wxcode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    orderTimer = setInterval(getOrder, 30000);

    return () => {
      clearInterval(orderTimer);
    };
  }, []);

  useEffect(() => {
    if (payStatus) {
      history.push("/feed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payStatus]);

  const generateQR = async (text) => {
    try {
      let result = await QRCode.toDataURL(text);
      setDataUrl(result);
    } catch (err) {
      console.error("generateQR error", err);
    }
  };

  const getOrder = async () => {
    let pms = { _id: orderId };
    let result = await subscriptionDetail(pms);
    if (result && result.status === 1) {
      await loadUserInfo(user.userId);
      clearInterval(orderTimer);
      setPayStatus(true);
    }
  };

  return (
    <div className="middle-page-container">
      <div className="middle-page wxpay-page-container">
        <div className="page-title">{t("WECHAT_PAY")}</div>
        <div className="order-desc">
          <div>
            {t("ORDER_SUBMISSION_SUCCEED_PLEASE_PAY_ASAP")} {t("ORDER_NUMBER")}
            :{orderNum}
          </div>
          <div>
            {t("AMOUNT_TO_PAY")} <span className="order-price">{payPrice}</span>{" "}
            元
          </div>
        </div>
        <div className="pay-content">
          <div className="wxpay-code-container ">
            {dataUrl && (
              <img
                alt={"The payment QR code"}
                src={dataUrl}
                className="code-image"
              />
            )}
            <div className="wxpay-tip">
              {t("PLEASE_USE_WECHAT_TO_SCAN_THE_QR_CODE_AND_PAY")}
            </div>
          </div>
          <img alt={"Wechat pay"} src={wxpic} className="wxpay-image" />
        </div>
      </div>
    </div>
  );
}
