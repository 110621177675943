import React, { useState, useEffect } from "react";
import {
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  DatePicker,
  Row,
  Col,
} from "antd";
import "./style/tenderCreate.less";
import { useUserId } from "../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { create } from "../../api/tender";
import { statusCode } from "../../constants/statusCode.enum";
import { toast } from "react-toastify";
import { toastError, toastInfo } from "../../api/toast";
import {
  getProductConfigForSelect,
  loadProductConfig,
} from "../../api/product";
import { localizePriceUnit } from "../../constants/constants";
import { formatConfigForSelect, getCountryForSelect } from "../../api/config";
import { ProductStockData } from "../../constants/config";

const { TextArea } = Input;

function TenderCreate(props, ref) {
  const { i18n, t } = useTranslation();
  const { user } = useUserId();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const history = useHistory();

  const [configData, setConfigData] = useState();
  const [title, setTitle] = useState();
  const [countries, setCountries] = useState([]);
  const [types, setTypes] = useState([]);
  const [country, setCountry] = useState("");
  const [type, setType] = useState("");
  const [priceBegin, setPriceBegin] = useState(100);
  const [priceEnd, setPriceEnd] = useState(2000);
  const [amount, setAmount] = useState(6000);
  const [comments, setComments] = useState("");
  const [pickupDate, setPickupDate] = useState(new Date());
  const [stockList, setStockList] = useState([]);
  const [stock, setStock] = useState();

  const [bottles, setBottles] = useState([]);
  const [capsules, setCapsules] = useState([]);
  const [volumes, setVolumes] = useState([]);
  const [sealeds, setSealeds] = useState([]);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("");
  const [bottle, setBottle] = useState("");
  const [capsule, setCapsule] = useState("");
  const [sealed, setSealed] = useState("");
  const [year, setYear] = useState(2018); //年份
  const [alcoholBegin, setAlcoholBegin] = useState(0);
  const [alcoholEnd, setAlcoholEnd] = useState(0);
  const [classification, setClassification] = useState([]);
  const [classificationId, setClassificationId] = useState("");
  const [volume, setVolume] = useState("");

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    initConfigData();
  }, [currentLanguage]);

  const initConfigData = async () => {
    let result;
    if (configData) {
      result = configData;
    } else {
      result = await loadProductConfig();
    }
    if (result) {
      let { types, locations } = result;
      setCountries(getCountryForSelect(locations, currentLanguage,user.userInfo.type));
      setTypes(getProductConfigForSelect(types, currentLanguage));
    }
    setStockList(formatConfigForSelect(ProductStockData, currentLanguage));
  };

  const onSwitchCountry = (id) => {
    setCountry(id);
    // countries.map((item, index) => {
    //   if (item.country == id) {
    //     setAreas(item.region);
    //     setClassification(item.classifications);
    //   }
    // });
  };

  const onSwitchArea = (id) => {
    setArea(id);
  };
  const onSwitchClassification = (id) => {
    setClassificationId(id);
  };
  //#endregion

  const getInputLabel = (text) => {
    return <span className="input-label">{text}</span>;
  };

  const onYearChange = (date, dateString) => {
    // let temp = e.target.value;
    setYear(dateString);
  };

  const onSubmitNewProduct = async () => {
    if (!title) {
      toastInfo(t("TITLE") + t("IS_REQUIRED"));
      return;
    }
    if (!comments) {
      toastInfo(t("PRODUCT_DESCRIPTION") + t("IS_REQUIRED"));
      return;
    }
    let pms = {
      userId: user.userId,
      title,
      country,
      types: type,
      priceBegin: priceBegin,
      priceEnd: priceEnd,
      amount: amount,
      pickupDate,
      stock,
      comments,

      // region: area,
      // alcoholBegin: alcoholBegin,
      // alcoholEnd: alcoholEnd,
      // sealed: sealed,
      // capsule: capsule,
      // volume: volume,
      // bottle: bottle,
      // currency: moneyUnit,
      // year: year,
      // classification: classificationId,
    };
    const data = await create(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      toastInfo(t("SUCCESS"));
      history.goBack();
    } else {
      toastError(t("ERROR"));
    }
  };

  const onDateChange = (e, val) => {
    // console.log("--------onDate change :", e, val);
    setPickupDate(val);
  };

  return (
    <div className="common-page">
      <div className="tender-create">
        <Form layout="vertical">
          <div className="create-content">
            <div className="content-title">
              <Divider type="vertical" className="content-title-divider" />
              {t("TITLE")}
            </div>
            <div className="content-item">
              <Input
                maxLength={50}
                value={title}
                className="input-title"
                onChange={(value) => {
                  setTitle(value.target.value);
                }}
              />
            </div>
          </div>
          <div className="create-content">
            <div className="content-title">
              <Divider type="vertical" className="content-title-divider" />
              {t("PURCHASING_DEMAND")}
            </div>
            <div className="content-item">
              <div className="content-item-title-sub">
                {getInputLabel(t("PRODUCT_DETAIL"))}
              </div>
              <div className="product-detail">
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <div>{getInputLabel(t("ORIGIN_COUNTRY"))}</div>
                    <Select
                      value={country}
                      options={countries}
                      className="product-country"
                      onChange={(value) => {
                        onSwitchCountry(value);
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <div>{t("PRODUCT_TYPE")}</div>
                    <Select
                      value={type}
                      options={types}
                      className="product-type"
                      onChange={(value) => {
                        setType(value);
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <div>{t("PRODUCT_STOCK")}</div>
                    <Select
                      value={stock}
                      options={stockList}
                      className="product-type"
                      onChange={(value) => {
                        setStock(value);
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <div>
                      {t("PRICE_MIN")}({localizePriceUnit()})
                    </div>
                    <div>
                      <InputNumber
                        value={priceBegin}
                        min={1}
                        max={priceEnd}
                        className="product-type"
                        onChange={(value) => {
                          setPriceBegin(value);
                        }}
                      />
                      {/* <span className="form-input-unit">
                        
                      </span> */}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      {t("PRICE_MAX")}({localizePriceUnit()})
                    </div>
                    <div>
                      <InputNumber
                        value={priceEnd}
                        min={priceBegin}
                        className="product-type"
                        onChange={(value) => {
                          setPriceEnd(value);
                        }}
                      />
                      {/* <span className="form-input-unit">
                        
                      </span> */}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      {t("QUANTITY")}({t("BOTTLES")})
                    </div>
                    <div>
                      <InputNumber
                        value={amount}
                        min={100}
                        className="product-type"
                        onChange={(value) => {
                          setAmount(value);
                        }}
                      />
                      {/* <span className="form-input-unit"></span> */}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>{t("PICK_UP_DATE")}</div>
                    <div>
                      <DatePicker
                        value={
                          pickupDate ? moment(pickupDate, "YYYY-MM-DD") : ""
                        }
                        className="product-type"
                        onChange={onDateChange.bind(this)}
                      />
                      {/* <DatePicker
                        value={moment(year, "YYYY")}
                        onChange={onYearChange.bind(this)}
                        picker="year"
                        placeholder={t("PLEASE_WRITE_YEARS")}
                      /> */}
                    </div>
                  </Col>
                </Row>
                {/* <div>{getInputLabel(t("PRODUCT_AREA"))}</div>
                <Select
                  value={area}
                  className="product-country"
                  onChange={(value) => {
                    onSwitchArea(value);
                  }}
                >
                  {areas.map((item, index) => {
                    return <Option key={item.value}>{t(item.label)}</Option>;
                  })}
                </Select>
                <div>{getInputLabel(t("CLASSIFICATION"))}</div>

                <Select
                  value={classificationId}
                  className="product-country"
                  onChange={(value) => {
                    onSwitchClassification(value);
                  }}
                >
                  {classification.map((item, index) => {
                    return <Option key={item.value}>{t(item.label)}</Option>;
                  })}
                </Select>
                <div className="product-detail-row">
                  <div>
                    <div>{t("WINE_CAP")}</div>
                    <Select
                      value={capsule}
                      className="product-type"
                      onChange={(value) => {
                        setCapsule(value);
                      }}
                    >
                      {capsules.map((item, index) => {
                        return <Option key={item.value}>{item.label}</Option>;
                      })}
                    </Select>
                  </div>
                  <div className="product-input-item">
                    <div>{t("BOTTLE_TYPE")}</div>
                    <Select
                      value={bottle}
                      className="product-input-number"
                      onChange={(value) => {
                        setBottle(value);
                      }}
                    >
                      {bottles.map((item, index) => {
                        return <Option key={item.value}>{item.label}</Option>;
                      })}
                    </Select>
                  </div>
                  <div>
                    <div>{t("VOLUME")}</div>
                    <Select
                      value={volume}
                      className="product-type"
                      onChange={(value) => {
                        setVolume(value);
                      }}
                    >
                      {volumes.map((item, index) => {
                        return <Option key={item.value}>{item.label}</Option>;
                      })}
                    </Select>
                  </div>
                </div> */}
                {/* <div className="product-detail-row">
                  <div>
                    <div>封口</div>
                    <Select
                      value={sealed}
                      className="product-type"
                      onChange={(value) => {
                        setSealed(value);
                      }}
                    >
                      {sealeds.map((item, index) => {
                        return <Option key={item.value}>{item.label}</Option>;
                      })}
                    </Select>
                  </div>
                  <div className="product-input-item">
                    <div>{getInputLabel(t("YEARS"))}</div>
                    <div>
                      <DatePicker
                        value={moment(year, "YYYY")}
                        onChange={onYearChange.bind(this)}
                        picker="year"
                        className="product-input-number"
                        placeholder={t("PLEASE_WRITE_YEARS")}
                      />
                    </div>
                  </div>
                </div>
                <div className="product-detail-row">
                  <div>
                    <div>{getInputLabel(t("ALCOHOL_DEGREE"))}</div>
                    <div>
                      <InputNumber
                        defaultValue={1}
                        className="product-input-vol"
                        onChange={(value) => {
                          setAlcoholBegin(value);
                        }}
                      />
                      <span className="form-input-unit">Vol</span>
                    </div>
                  </div>
                  <div className="product-input-item">
                    <div>{getInputLabel(t("ALCOHOL_DEGREE"))}</div>
                    <div>
                      <InputNumber
                        defaultValue={1}
                        className="product-input-vol"
                        onChange={(value) => {
                          setAlcoholEnd(value);
                        }}
                      />
                      <span className="form-input-unit">Vol</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="create-content">
            <div className="content-title">
              <Divider type="vertical" className="content-title-divider" />
              {t("PRODUCT_DESCRIPTION")}
            </div>
            <div className="content-item">
              <TextArea
                rows={4}
                value={comments}
                maxLength={532}
                onChange={(value) => {
                  setComments(value.target.value);
                }}
              />
            </div>
          </div>
        </Form>
      </div>
      <div className="public-btn-container-flex">
        <div className="public-btn-container">
          <Button
            className="form-button"
            onClick={onSubmitNewProduct.bind(this)}
          >
            {t("SAVE")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TenderCreate;
