import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { localizeImg } from "../../constants/constants";
import "./style/userItem.less";
import { goToProfilePage } from "../../utils/utils";
import AvatarBasic from "../common/avatar/AvatarBasic";
import {
  companyDetail,
  companyImporterDesc,
  companyMediaDesc,
  companyWineryDesc,
} from "../../api/company";
import { UserLabel, UserType } from "../../constants/usersType.enum";
import { CompanyVerifyStatusEnum } from "../../constants/companyVerifyStatus.enum";
import { useTranslation } from "react-i18next";
import verifiedIcon from "../../resource/icons/verify/verified.svg";

export default function UserItemBasic(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [picture, setPicture] = useState();
  const [nickName, setNickName] = useState();
  const [signature, setSignature] = useState();
  const [userId, setUserId] = useState();
  const [type, setType] = useState();

  const [companyVerified, setCompanyVerified] = useState(
    "COMPANY_NOT_VERIFIED"
  );

  useEffect(() => {
    const { detail, keyword } = props;
    const { picture, nickName, signature, companyId, type } = detail;

    initCompanyDesc(companyId, type);

    setUserId(detail._id);
    setType(detail.type);
    formatName(nickName, keyword);
    setPicture(picture);
    setSignature(signature);
  }, [props]);

  const formatName = (title, key) => {
    if (key && title) {
      title = title.replace(
        key,
        '<span class="keyword-label">' + key + "</span>"
      );
    }
    setNickName(title);
  };

  const onCompanyHome = () => {
    const userItem = {
      _id: userId,
      type: type,
    };

    goToProfilePage(userItem, history);
  };

  const initCompanyDesc = async (coId, type) => {
    if (coId) {
      const companyDescPms = { coId };
      const companyInfo = await companyDetail(coId);
      let companyDesc = {};

      if (type == UserType.SUPPLIER) {
        companyDesc = await companyWineryDesc(companyDescPms);
      } else {
        companyDesc = await companyImporterDesc(companyDescPms);
      }

      /**
       * Check if winery company type
       */
      if (companyInfo.label === UserLabel.WINERY) {
        if (companyInfo.status === CompanyVerifyStatusEnum.VERIFIED) {
          setCompanyVerified("VERIFIED_AS_WINERY");
        }
      } else if (companyInfo.label === UserLabel.NEGOCIANT) {
        if (companyInfo.status === CompanyVerifyStatusEnum.VERIFIED) {
          setCompanyVerified("VERIFIED_AS_NEGOCIANT");
        }
      } else if (companyInfo.label === UserLabel.COOPERATIVE) {
        if (companyInfo.status === CompanyVerifyStatusEnum.VERIFIED) {
          setCompanyVerified("VERIFIED_AS_COOPERATIVE");
        }
      } else if (companyInfo.label === UserLabel.IMPORTER) {
        if (companyInfo.status === CompanyVerifyStatusEnum.VERIFIED) {
          setCompanyVerified("VERIFIED_AS_IMPORTER");
        }
      } else if (companyInfo.label === UserLabel.CORPORATEBUSINESS) {
        if (companyInfo.status === CompanyVerifyStatusEnum.VERIFIED) {
          setCompanyVerified("VERIFIED_AS_CORPORATE_BUSINESS");
        }
      } else if (companyInfo.label === UserLabel.DISTRIBUTOR) {
        if (companyInfo.status === CompanyVerifyStatusEnum.VERIFIED) {
          setCompanyVerified("VERIFIED_AS_DISTRIBUTOR");
        }
      } else if (companyInfo.label === UserLabel.MEDIA) {
        companyDesc = await companyMediaDesc(companyDescPms);
        if (companyInfo.status === CompanyVerifyStatusEnum.VERIFIED) {
          setCompanyVerified("VERIFIED_AS_MEDIA");
        }
      }
    }
  };

  return (
    <div className="user-item-container" onClick={onCompanyHome.bind(this)}>
      <AvatarBasic
        cname="avatar"
        picture={localizeImg(picture)}
        displayName={nickName}
      ></AvatarBasic>
      <div className="content">
        <div className="content-name">
          <div
            className="name"
            dangerouslySetInnerHTML={{ __html: nickName }}
          ></div>
          <div className="desc">{signature}</div>
          <div className="desc">
            {t(companyVerified) != t("COMPANY_NOT_VERIFIED") && (
              <img src={verifiedIcon} />
            )}
            &nbsp;
            {t(companyVerified)}
          </div>
        </div>
      </div>
    </div>
  );
}
