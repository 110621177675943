import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, {
  useState,
  useEffect,
} from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment-lunar";
import { Modal, Button, Form, Input, DatePicker } from "antd";
import { useTranslation } from "react-i18next";

import { useContextState } from "../../../contexts/StateProvider";
export default function MyCalendar() {
  const { t } = useTranslation();
  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });
  let [state, dispatch] = useContextState();
  const [title, setTitle] = useState("");
  const [eventsList, setEventsList] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [isModalVisible, setIsModalVisible] = useState(false);

  const messages = {
    previous: t("PREVIOUS"),
    next: t("NEXT"),
    today: t("TODAY"),
    month: t("MONTH"),
    week: t("WEEK"),
    day: t("DAYS"),
    date: t("DATE"),
    agenda: t("SCHEDULE"),
  };

  let newDate = new Date();
  let year = newDate.getFullYear();

  const defaultEvents = [
    {
      title: "NOUVEL AN CHINOIS",
      start: moment().year(year).month(0).date(1).solar().format("YYYY-MM-DD"),
      end: moment().year(year).month(0).date(1).solar().format("YYYY-MM-DD"),
    },
    {
      title: "LANTERNES",
      start: moment().year(year).month(0).date(15).solar().format("YYYY-MM-DD"),
      end: moment().year(year).month(0).date(15).solar().format("YYYY-MM-DD"),
    },
    {
      title: "ST VALENTIN",
      start: moment().year(year).month(1).date(14).format("YYYY-MM-DD"),
      end: moment().year(year).month(1).date(14).format("YYYY-MM-DD"),
    },
    {
      title: "FETE TRAVAIL",
      start: moment().year(year).month(4).date(1).format("YYYY-MM-DD"),
      end: moment().year(year).month(4).date(1).format("YYYY-MM-DD"),
    },
    {
      title: "QINGMING",
      start: moment().year(year).month(3).date(5).format("YYYY-MM-DD"),
      end: moment().year(year).month(3).date(5).format("YYYY-MM-DD"),
    },
    {
      title: "BATEAU DRAGON",
      start: moment().year(year).month(4).date(5).solar().format("YYYY-MM-DD"),
      end: moment().year(year).month(4).date(5).solar().format("YYYY-MM-DD"),
    },
    {
      title: "FETE LUNE",
      start: moment().year(year).month(7).date(15).solar().format("YYYY-MM-DD"),
      end: moment().year(year).month(7).date(15).solar().format("YYYY-MM-DD"),
    },
    {
      title: "DOUBLE 9",
      start: moment().year(year).month(8).date(9).solar().format("YYYY-MM-DD"),
      end: moment().year(year).month(8).date(9).solar().format("YYYY-MM-DD"),
    },
    {
      title: "DOUBLE 7",
      start: moment().year(year).month(6).date(7).solar().format("YYYY-MM-DD"),
      end: moment().year(year).month(6).date(7).solar().format("YYYY-MM-DD"),
    },
    {
      title: "CELIBATAIRES",
      start: moment().year(year).month(10).date(11).format("YYYY-MM-DD"),
      end: moment().year(year).month(10).date(11).format("YYYY-MM-DD"),
    },
    {
      title: "FETE NATIONALE",
      start: moment().year(year).month(9).date(1).format("YYYY-MM-DD"),
      end: moment().year(year).month(9).date(1).format("YYYY-MM-DD"),
    },
    {
      title: "NOEL",
      start: moment().year(year).month(11).date(24).format("YYYY-MM-DD"),
      end: moment().year(year).month(11).date(24).format("YYYY-MM-DD"),
    },
    {
      title: "NOUVEL AN",
      start: moment().year(year).month(11).date(31).format("YYYY-MM-DD"),
      end: moment().year(year).month(11).date(31).format("YYYY-MM-DD"),
    },
  ];

  const Event = [
    {
      _id: "23383882823",
      title: "Importation de vin",
      start: moment().year(year).month(0).date(5).format("YYYY-MM-DD"),
      end: moment().year(year).month(2).date(6).format("YYYY-MM-DD"),
      color: "blue",
      userId: "2832373772",
      type: "import",
    },
    {
      _id: "2737172828",
      title: "Exportation de vin",
      start: moment().year(year).month(1).date(23).format("YYYY-MM-DD"),
      end: moment().year(year).month(1).date(28).format("YYYY-MM-DD"),
      color: "red",
      userId: "122872712",
      type: "export",
    },
    {
      _id: "2191992929",
      title: "Meeting",
      start: moment().year(year).month(0).date(6).format("YYYY-MM-DD"),
      end: moment().year(year).month(0).date(6).format("YYYY-MM-DD"),
      color: "purple",
      userId: "27273773",
      type: "meeting",
    },
    {
      _id: "2727273818",
      title: "Meeting 2",
      start: moment().year(year).month(0).date(20).format("YYYY-MM-DD"),
      end: moment().year(year).month(1).date(12).format("YYYY-MM-DD"),
      color: "green",
      userId: "281828288",
      type: "meeting",
    },
    {
      _id: "1172727277",
      title: "Importation de vin",
      start: moment().year(year).month(4).date(23).format("YYYY-MM-DD"),
      end: moment().year(year).month(5).date(3).format("YYYY-MM-DD"),
      color: "blue",
      userId: "3737728838",
      type: "import",
    },
  ];

  useEffect(() => {
    if (state.title && state.start && state.end) {
      var newEvent = {
        start: state.start,
        end: state.end,
        title: state.title,
      };
      let updateEventsList = eventsList;
      for (let i = 0; defaultEvents[i]; i++) {
        updateEventsList.push(defaultEvents[i]);
      }
      updateEventsList.push(newEvent);
      setEventsList(updateEventsList);
    }
  }, []);

  const addEvent = () => {
    dispatch({ type: "title", value: title });
    dispatch({ type: "start", value: startDate });
    dispatch({ type: "end", value: endDate });
    if (state.title && state.start && state.end) {
      var newEvent = {
        start: state.start,
        end: state.end,
        title: state.title,
      };
      let updateEventsList = eventsList;
      updateEventsList.push(newEvent);
      setEventsList(updateEventsList);
    }
  };
  const cod = Event.map((Ev, i) => Ev);
  const hideModal = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };


  function eventStyleGetter(event, start, end, isSelected) {
    let backgroundColor;
    let style = [];
    event = Event.map(
      (Ev, i) => (
        (backgroundColor = Ev.color),
        console.log(Ev.color),
        (style = {
          backgroundColor: Ev.color,
          borderRadius: "0px",
          opacity: 0.8,
          color: "black",
          border: "0px",
          display: "block",
        }) // try to set for any event, the background color selected in Ev.color, but not work, its only take the first color of array and change all events color
      )
    );
    return {
      style: style,
    };
  }

  eventStyleGetter();
  
    return (
      <div className="common-management normal-shadow-padding10-white">
        <Modal
          title="Basic Modal"
          visible={isModalVisible}
          onOk={() => {
            addEvent();
            hideModal();
          }}
          onCancel={hideModal}
        >
          <Form>
            <Input type="text" onChange={(e) => setTitle(e.target.value)} />
            <Button
              onClick={(e) => {
                addEvent();
                hideModal();
              }}
            >
              {t("SUBMIT")}
            </Button>
          </Form>
          <DatePicker
            defaultValue={moment()}
            onChange={(date) => setStartDate(date)}
          />
          <DatePicker
            defaultValue={moment()}
            onChange={(date) => setEndDate(date)}
          />
        </Modal>
        <Calendar
          messages={messages}
          localizer={localizer}
          events={defaultEvents}
          onSelectEvent={cod}
          eventPropGetter={eventStyleGetter}
          //onSelectEvent={showModal}
          onSelectSlot={showModal}
          selectable
          style={{ height: '100%', width: '100%' }}
        //eventPropGetter={eventStyleGetter}
        />
      </div>
    );
  
}
