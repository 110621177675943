import { Form, Input } from "antd";

export default function InputBasic(props) {
  let { label, name, required, message } = props;
  required = required ? required : true;
  let layout = { label, name };
  if (required) {
    layout.rules = [{ required: true, message }];
  }

  return (
    <Form.Item {...layout}>
      <Input maxLength={50} />
    </Form.Item>
  );
}
