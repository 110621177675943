import { Upload } from "antd";
import { useUserId } from "../../../contexts/UserIdProvider";
import { getBase64 } from "../../../utils/utils";
import { getUploadFileAction } from "../../../api/common";
import { getCacheTokens } from "../../../utils/cache";
import ImgCrop from "antd-img-crop";
import { toastError } from "../../../api/toast";
import { useTranslation } from "react-i18next";

export default function UploadNormal(props) {
  const { fileType, children, onLoading, onFileLoaded } = props;
  let { scaleToWidth, scaleToHeight } = props;
  const { user } = useUserId();
  const { t } = useTranslation();
  const token = getCacheTokens();
  const action = getUploadFileAction(user.userId);

  if (!scaleToWidth) {
    scaleToWidth = 800;
  }
  if (!scaleToHeight) {
    scaleToHeight = 500;
  }

  const scaleProps = {
    aspect: 16 / 9,
    grid: true,
    // rotate: true,//旋转
    // shape:'round',
    // modalTitle: "上传图片", //弹窗标题
    modalWidth: 1000, //弹窗宽度
  };

  const layout_upload = {
    action: action,
    showUploadList: false,
    headers: {
      authorization: `Bearer ${token.value}`,
    },
    onChange: async (info) => {
      let { fileList, file } = info;
      let { status, percent } = file;
      if (status === "uploading") {
        // if (percent === 0) {
        let tempImage = await getBase64(file);
        onLoading && onLoading(percent, tempImage);
        // }
      } else if (status === "done") {
        fileList = fileList.map((file) => file.response.result);
        onFileLoaded && onFileLoaded(fileList);
      } else if (status === "error") {
      }
    },
    beforeUpload: (file) => {
      if (fileType === "video") {
        const imageType = "video/mp4,video/webm,video/ogg,video/quicktime";
        const validate = imageType.indexOf(file.type) === -1;
        if (validate) {
          toastError(`${file.name} ${t('IS_NOT_VIDEO_FILE')}`);
          return Upload.LIST_IGNORE;
        }
      }
      return true;
    },
    onPreview: async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
    },
  };

  return (
    // <ImgCrop {...scaleProps} >
      <Upload {...layout_upload} maxCount={1}>
        {children}
      </Upload>
    // </ImgCrop>
  );
}
