import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./CompanyAboutUs.module.sass";
import { useTranslation } from "react-i18next";

import AMapLoader from "@amap/amap-jsapi-loader";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { constants, deepClone } from "../../../utils/utils";
import { Button } from "antd";
import Card from "../Card";
import { Link, useHistory } from "react-router-dom";
import Icon from "../Icon";
import {
  getProductConfigNameById,
  loadProductConfig,
} from "../../../api/product";
import { getChinaCityNameById, getCountryNameById } from "../../../api/config";
import { Path, PathTools } from "../../../constants/path";
import { localizeImg, localizeValue } from "../../../constants/constants";
import { UserType } from "../../../constants/usersType.enum";
import AboutUsEditor from "./AboutUsEditor";
import CompInfoEditor from "./CompInfoEditor";
import { useUserId } from "../../../contexts/UserIdProvider";
import ReadMoreLess from "../../../components/readmoreless/ReadMoreLess";

export default function CompanyAboutUs(props) {
  const { companyInfo, onSaved } = props;
  const introContentDiv = useRef(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();

  const [currentLanguage, setCurrentLanguage] = useState();

  const [useAmap, setUseAMap] = useState(false);
  //const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom, setZoom] = useState(4);

  let amap = null;

  const [productFeatures1, setProductFeatures1] = useState([]);
  // const [productFeatures2, setProductFeatures2] = useState([]);

  const [userType, setUserType] = useState("");

  const [showAboutUsEditor, setShowAboutUsEditor] = useState(false);
  const [showCompInfoEditor, setShowCompInfoEditory] = useState(false);

  const [isMyself, setIsMyself] = useState(false);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (companyInfo && companyInfo.location) {
      // setUseAMap(companyInfo.location.country == constants.chinaCountryId);
      initProductFeatures();
    }
    setUserType(companyInfo?.type);

    let flag = user?.userInfo?.companyId == companyInfo?._id;
    setIsMyself(flag);
  }, [companyInfo]);

  useEffect(() => {
    if (companyInfo && companyInfo.location) {
      const address = companyInfo?.location?.address?.name;
      let lnglat = companyInfo?.location?.address?.location?.split(",");

      if (!lnglat || lnglat.length == 0) {
        lnglat = ["-1.0392", "45.3939"];
      }

      // if (!useAmap && companyInfo) {
      //   mapboxgl.accessToken = constants.mapBoxAccessToken;

      //   if (map.current) return; // initialize map only once.
      //   map.current = new mapboxgl.Map({
      //     container: mapContainer.current,
      //     //style: "mapbox://styles/vinbip/clk7h6cdi00mw01pfcaqc0745",
      //     //style: "mapbox://styles/vinbip/clmvtrek402md01nzds3k3pcz",
      //     style: "mapbox://styles/vinbip/clnrbwkzn009s01pa5q764x8d",
      //     center: lnglat,
      //     zoom: zoom,
      //     pitch: 0,
      //     bearing: 0,
      //   });

      //   map.current.addControl(
      //     new mapboxgl.Marker({
      //       draggable: false,
      //       color: "#C4321D",
      //     })
      //       .setLngLat(lnglat)
      //       .setPopup(
      //         new mapboxgl.Popup({ offset: 25 }).setHTML(
      //           "<h3>" + address + "</h3>"
      //         )
      //       )
      //       .addTo(map.current)
      //   );
      // } else if (companyInfo) {
      //   AMapLoader.load({
      //     key: constants.amapJsKey, // 申请好的Web端开发者Key，首次调用 load 时必填
      //     version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      //     plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      //   })
      //     .then((AMap) => {
      //       amap = new AMap.Map("container", {
      //         //设置地图容器id
      //         viewMode: "2D", //是否为3D地图模式
      //         zoom: 11, //初始化地图级别
      //         center: lnglat, //初始化地图中心点位置
      //       });

      //       // 创建一个 Marker 实例:
      //       var marker = new AMap.Marker({
      //         position: new AMap.LngLat(lnglat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      //         title: address,
      //         // icon:sampleMapImg
      //       });

      //       // 将创建的点标记添加到已有的地图实例:
      //       amap.add(marker);
      //       // marker.setMap(amap);
      //     })
      //     .catch((e) => {
      //       console.log("amap error:", e);
      //     });
      // }
    }
  }, [companyInfo]);
  // }, [useAmap]);

  const initProductFeatures = async () => {
    let configData = await loadProductConfig();
    let { locations, types } = configData;

    let temp = deepClone(productFeatures1);
    
    temp = [];
    
    temp.push({
      key: t("COMPANY_CATEGORY"),
      value: t(companyInfo?.label),
    });

    temp.push({
      key: t("LOCATION"),
      value: getCountryNameById(
        locations,
        i18n.language,
        companyInfo?.location?.country
      ),
    });

    temp.push({
      key: t("PROVINCE"),
      value:
        companyInfo?.location?.country == constants.chinaCountryId
          ? getChinaCityNameById(companyInfo?.location?.city)
          : getCountryNameById(
              locations,
              i18n.language,
              companyInfo?.location?.region
            ),
    });

    let productCategoryArr = [];
    companyInfo?.productCategory?.map((item) => {
      productCategoryArr.push(
        getProductConfigNameById(types, currentLanguage, item)
      );
    });
    temp.push({
      key: t("PRODUCTS"),
      value: productCategoryArr.length > 0 ? productCategoryArr.join(", ") : "-"
    });

    if (companyInfo?.production) {
      temp.push({
        key: t("TOTAL_PRODUCTION"),
        value: companyInfo.production + " " + t("BOTTLES"),
      });
    }

    if (companyInfo?.brandAmount) {
      temp.push({
        key: t("TOTAL_NUMBER_OF_BRAND"),
        value: companyInfo?.brandAmount,
      });
    }

    if (companyInfo?.size && companyInfo?.size > 0) {
      temp.push({
        key: t("VINEYARD_SIZE"),
        value: companyInfo?.size,
      });
    }

    let mainProductArr = [];
    if (companyInfo?.market && companyInfo?.market.length > 0) {
      companyInfo?.market?.map((item) => {
        mainProductArr.push(getCountryNameById(locations, i18n.language, item));
      });
      temp.push({
        key: t("MAIN_MARKET"),
        value: mainProductArr.length > 0 ? mainProductArr.join(", ") : "-",
      });
    }
    

    setProductFeatures1(temp);
  };

  const onEditCompanyInformation = () => {
    history.push(
      PathTools.joinPath(Path.companyInformation, companyInfo?.companyName)
    );
  };

  return (
    <div>
      <div className={styles.aboutUs}>
        <Card
          className={cn(styles.card)}
          title={t("ABOUT")}
          classTitle="title-red"
          head={
            isMyself ? (
              <div
              className={`${styles.editButton} profile-abous-us-tour`}
              onClick={() => setShowAboutUsEditor(true)}
            >
              <Icon name="edit" size="24" />
            </div>
            ) : (
              <></>
            )
          }
        >
          {companyInfo && companyInfo.desc && (
            <div>
              <ReadMoreLess
                htmlText={localizeValue(i18n.language, companyInfo?.desc)}
              />
            </div>
          )}
        </Card>
        {userType == UserType.SUPPLIER && (
          <Card
            className={cn(styles.card)}
            title={t("DETAILS")}
            classTitle="title-red"
            head={
              isMyself ? (
                <div
                className={`${styles.editButton} profile-detail-tour`}
                onClick={() => setShowCompInfoEditory(true)}
              >
                <Icon name="edit" size="24" />
              </div>
              ) : (
                <></>
              )
            }
          >
            {productFeatures1.length > 0 && (
              <div className={styles.grid}>
                {productFeatures1.map((x, index) => (
                  <div className={styles.gridItem}>
                    {x.key}: <b>{x.value}</b>
                  </div>
                ))}
              </div>
            )}
          </Card>
        )}
      </div>
      <AboutUsEditor
        visible={showAboutUsEditor}
        dataSource={companyInfo}
        onCloseModal={() => setShowAboutUsEditor(false)}
        onSaved={onSaved}
      />
      <CompInfoEditor
        visible={showCompInfoEditor}
        dataSource={companyInfo}
        onCloseModal={() => setShowCompInfoEditory(false)}
        onSaved={onSaved}
      />
    </div>
  );
}
