import React, { useState, useEffect, useRef } from "react";
import { Button, Spin, Row, Col, Image } from "antd";
import { useTranslation } from "react-i18next";
import { translateText } from "../../api/translate";
import { LoadingOutlined } from "@ant-design/icons";
import VideoBasic from "../common/video/VideoBasic";
import UploadBase from "../common/upload/UploadBase";
import { localizeImg } from "../../constants/constants";
import uploadIcon from "../../resource/icons/user-profile/upload.svg";
import "./style/feedImageItem.less";

export default function FeedImageItem(props) {
  const { images } = props;

  // console.log("---------------------------FeedImageItem:", images);

  return (
    <div
      style={{ marginTop: "20px" }}
    >
      {images && images.length === 1 && (
        <div className="feed-item-images-container">
          <Row gutter={[8, 8]} className="feed-images">
            {images.map((item, index) => {
              return (
                <Col span={24} key={index}>
                  <div className="feed-list-image-box">
                    <Image
                      preview={false}
                      src={localizeImg(item)}
                      className="feed-list-image"
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      )}
      {images && images.length === 2 && (
        <div className="feed-item-images-container">
          <div className="feed-item-two-image-box">
            {images.map((item, index) => {
              return (
                <div key={index}>
                  <div>
                    <Image
                      preview={false}
                      src={localizeImg(item)}
                      className="feed-list-image-two-item"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {images && images.length === 3 && (
        <div className="feed-item-images-container">
          <Row gutter={[8, 8]} className="feed-images">
            {images.map((item, index) => {
              return (
                <Col span={8} key={index}>
                  <div className="feed-list-image-box">
                    <Image
                      preview={false}
                      src={localizeImg(item)}
                      className="feed-list-image"
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      )}
      {images && images.length === 4 && (
        <div className="feed-item-images-container">
          <div className="feed-item-four-image-box">
            <div>
              <div>
                <Image
                  preview={false}
                  src={localizeImg(images[0])}
                  className="feed-list-image-four-one"
                />
              </div>
            </div>
            <div>
              <div>
                <div>
                  <Image
                    preview={false}
                    src={localizeImg(images[1])}
                    className="feed-list-image-four-two"
                  />
                </div>
                <div>
                  <Image
                    preview={false}
                    src={localizeImg(images[2])}
                    className="feed-list-image-four-two"
                  />
                </div>
                <div>
                  <Image
                    preview={false}
                    src={localizeImg(images[3])}
                    className="feed-list-image-four-two"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {images && images.length > 4 && (
        <div className="feed-item-images-container">
          <Row gutter={[8, 8]} className="feed-images">
            {images.map((item, index) => {
              return (
                <Col span={8} key={index}>
                  <div className="feed-list-image-box">
                    <Image
                      preview={false}
                      src={localizeImg(item)}
                      className="feed-list-image"
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </div>
  );
}
