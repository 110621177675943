import React, { useState, useEffect } from "react";
import { Form, Button, Select, Space, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { statusCode } from "../../../constants/statusCode.enum";
import { awardCreate } from "../../../api/user";
import ProductImageUpload from "../../common/upload/ProductImageUpload";
import UploadNormal from "../../common/upload/UploadNormal";
import { localizeImg, localizeTitle } from "../../../constants/constants";
import { formatUploadFileFormValues } from "../../../api/common";
import { loadProducts } from "../../../api/product";

export default function AwardCreate(props) {
  const { onComplete, onClose } = props;
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  let [award, setAward] = useState("");
  let [awardUrl, setAwardUrl] = useState("");
  let [name, setName] = useState("");
  let [desc, setDesc] = useState("");
  const [productId, setProductId] = useState();
  const [products, setProducts] = useState();
  const [submitStatus, setSubmitStatus] = useState(false);

  useEffect(() => {
    initProducts();
  }, []);

  useEffect(() => {
    if (productId && award && name) {
      setSubmitStatus(true);
    } else {
      setSubmitStatus(false);
    }
  }, [productId, award, name]);

  const initProducts = async () => {
    let pms = {
      page: 0,
      per_page: 100,
      coId: user.userInfo.companyId,
    };
    let result = await loadProducts(pms);
    if (result && result.length > 0) {
      result = result.map((item) => item._source);
      result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      result = result.map(item => {
        return { label: localizeTitle(i18n.language, item.title), value: (item._id || item.id) }
      });
      setProducts(result);
    }
  }

  const onFinish = async () => {
    let pms = {
      coId: user.userInfo.companyId,
      reward: award,
      productId,
      // reward: formatUploadFileFormValues(award),
      //proof: formatUploadFileFormValues(proofs)[0],
      name,
      desc,
    };
    let data = await awardCreate(pms);
    if (data) {
      onComplete();
    }
  };

  const onFileLoading = (index, percent, url) => {
    if (percent === 0) {
      setAwardUrl(url);
    }
  };

  const onFileLoaded = (data) => {
    if (data.length > 0) {
      setAward(data[0]);
      setAwardUrl(localizeImg(data[0]));
    }
  };

  const onCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div>
      <div className="modal-body">
        <div className="modal-title">{t("CREATE_AWARD")}</div>
        <div className="modal-content-column">
          <div className="content-row-label">{t("PRODUCT")}</div>
          <Select className="content-row-select"
            options={products}
            value={productId}
            onChange={(val) => setProductId(val)}
          />
        </div>
        <div className="modal-content-column">
          <div className="content-row-label">{t("AWARDS_LIST")}</div>
          <UploadNormal
            onLoading={onFileLoading.bind(this, 2)}
            onFileLoaded={onFileLoaded.bind(this)}
            className="awardIcon"
          >
            <ProductImageUpload title={t("AWARDS_LIST")} url={awardUrl} />
          </UploadNormal>
        </div>
        <div className="modal-content-column">
          <div className="content-row-label">{t("AWARD_NAME")}</div>
          <Input
            className="content-row-select"
            onChange={(val) => setName(val.target.value)}
          />
        </div>
        <div className="modal-content-column">
          <div className="content-row-label">{t("DESCRIPTION")}</div>
          <Input
            className="content-row-select"
            onChange={(val) => setDesc(val.target.value)}
          />
        </div>
        <Space size={8} className="modal-confirm-row">
          <Button
            className="modal-confirm-btn"
            onClick={onCloseClick.bind(this)}
          >
            {t("CANCEL")}
          </Button>
          <Button
            htmlType="submit"
            className="modal-confirm-btn-primary"
            onClick={onFinish.bind(this)}
            disabled={!submitStatus}
          >
            {t("SAVE")}
          </Button>
        </Space>
      </div>
    </div>
  );
}
