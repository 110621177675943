import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../../../core-digital-template/components/Card";
import Icon from "../../../../../core-digital-template/components/Icon";
import Editor from "../../../../../core-digital-template/components/Editor";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { deepClone } from "../../../../../utils/utils";
import TextInput from "../../../TextInput";

export default function NameAndDescription(props) {
  const { className, onChange, onClose, dataSource } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();

  const [formData, setFormData] = useState({});

  const languageNavigation = [
    // { label: t("LANGUAGE_FRENCH"), key: "fr" },
    // { label: t("LANGUAGE_CHINESE"), key: "zh" },
    { label: t("LANGUAGE_ENGLISH"), key: "en" },
  ];
  const [nameActiveTab, setNameActiveTab] = useState(languageNavigation[0]);
  const [descActiveTab, setDescActiveTab] = useState(languageNavigation[0]);
  const [desc, setDesc] = useState("");

  useEffect(() => {
    if (dataSource) {
      let temp = deepClone(formData);
      temp = dataSource;
      setFormData(temp);

      setTimeout(() => {
        setDesc(temp.desc);
      }, 1000);
    }
  }, [dataSource]);

  useEffect(() => {
    if (formData) {
      setDesc(formData.desc);
    }
  }, [descActiveTab]);

  const setCompanyNameFormDataValue = (name, value) => {
    let temp = deepClone(formData);
    if (!temp["companyName"]) {
      temp["companyName"] = {};
    }
    if (name == "en") {
      temp["companyId"] = value.toLowerCase().replace(/ /g, "-");
    }
    temp["companyName"][name] = value;
    setFormData(temp);
    onChange && onChange(temp);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={t("COMPANY_NAME")}
        classTitle="title-red"
        head={
          <Link
            className={cn("button-small", styles.button)}
            onClick={() => onClose && onClose()}
          >
            <Icon name="arrow-left" size="24" />
            <span>{t("CLOSE")}</span>
          </Link>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              {languageNavigation.map((x, index) => (
                <div>
                  {x.key === nameActiveTab.key && (
                    <TextInput
                      className={styles.field}
                      label={
                        <div className={styles.labelWithTranslations}>
                          <span>{t("COMPANY_NAME")}</span>
                          {/* {languageNavigation.map((x, index) => (
                            <button
                              className={cn(styles.translationLink, {
                                [styles.active]: x.key === nameActiveTab.key,
                              })}
                              onClick={() => setNameActiveTab(x)}
                              key={index}
                            >
                              {x.label}
                            </button>
                          ))} */}
                        </div>
                      }
                      name="companyName"
                      type="text"
                      tooltip=""
                      required
                      onChange={setCompanyNameFormDataValue.bind(this, x.key)}
                      value={
                        formData?.companyName
                          ? formData?.companyName[x.key]
                          : ""
                      }
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
