import { useTranslation } from "react-i18next";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

export default function SupportCenter() {
  const { t } = useTranslation();

  return (
    <div className="common-management">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        {/* <Breadcrumb.Item>{t("HELP_AND_FEEDBACK")}</Breadcrumb.Item> */}
        <Breadcrumb.Item>{t("SUPPORT_CENTER")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="normal-shadow-padding10-white">
        <div className="common-management-title">{t("SUPPORT_CENTER")}</div>
        <div style={{ backgroundColor: "white" }}></div>
      </div>
    </div>
  );
}
