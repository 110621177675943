import { Select, Dropdown, Menu, Space } from "antd";
import "./style/vSelectMultipleNormal.less";
import { useTranslation } from "react-i18next";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import arrowDownIcon from "./resources/arrow_down.svg";
import itemSelectedIcon from "./resources/item_selected.svg";
import itemUnSelectedIcon from "./resources/item_unselected.svg";
import { deepClone } from "../../../utils/utils";

export default function VSelectMultipleNormal(props) {
  const { t } = useTranslation();
  const { data, label, value, index, title, onChange, width } = props;

  const [selectedValue, setSelectedValue] = useState("");

  const [dataSource, setDataSource] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (value) {
      data.map((dataItem) => {
        dataItem.selected = false;
      });
      value.map((valueItem) => {
        data.map((dataItem) => {
          if (dataItem.value == valueItem) {
            dataItem.selected = true;
          }
        });
      });
      setSelectedValueFunc(data);
    }
    setDataSource(data);
  }, [data]);

  const onSelectChange = (dataIndex, e) => {
    let temp = deepClone(dataSource);
    let tempSelectedValue = [];

    temp[dataIndex].selected = !temp[dataIndex].selected;
    temp.map((mapItem) => {
      if (mapItem.selected) {
        tempSelectedValue.push(mapItem.value);
      }
    });
    setDataSource(temp);

    setSelectedValueFunc(temp);
    onChange(tempSelectedValue, index);
    e.preventDefault();
  };

  const setSelectedValueFunc = (arr) => {
    let tempSelectedLabel = [];
    arr.map((mapItem) => {
      if (mapItem.selected) {
        tempSelectedLabel.push(t(mapItem.label));
      }
    });
    setSelectedValue(tempSelectedLabel.join(","));
  };

  const formatMenuMore = () => {
    return (
      <div className="v-select-multi-menu-dropdown-container">
        {dataSource &&
          dataSource.map((dataItem, dataIndex) => {
            return (
              <div
                key={dataIndex}
                onClick={onSelectChange.bind(this, dataIndex)}
                className={
                  dataItem.selected
                    ? "menu-item cursor-pointer menu-item-selected"
                    : "menu-item cursor-pointer"
                }
              >
                <img
                  src={
                    dataItem.selected ? itemSelectedIcon : itemUnSelectedIcon
                  }
                  className="menu-icon"
                />
                {t(dataItem.label)}
              </div>
            );
          })}
      </div>
    );
  };

  const formatMenuMore1 = () => {
    return (
      <Menu className="v-select-multi-menu-dropdown-container">
        {dataSource &&
          dataSource.map((dataItem, dataIndex) => {
            return (
              <Menu.Item
                key={dataIndex}
                onClick={onSelectChange.bind(this, dataIndex)}
                className={
                  dataItem.selected
                    ? "menu-item cursor-pointer menu-item-selected"
                    : "menu-item cursor-pointer"
                }
              >
                <img
                  src={
                    dataItem.selected ? itemSelectedIcon : itemUnSelectedIcon
                  }
                  className="menu-icon"
                />
                {t(dataItem.label)}
              </Menu.Item>
            );
          })}
      </Menu>
    );
  };

  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  return (
    <div
      className="v-select-multiple-normal-container"
      style={{
        width: width ? width + "px" : "100%",
      }}
    >
      <div className="input-title">
        <span
          style={{
            color: "#333333",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {t(title)}
        </span>
        <span
          style={{
            color: "#333333",
            fontSize: "12px",
            fontWeight: "400",
            marginLeft: "6px",
          }}
        >
          ({t("MULTIPLE_CHOICE")})
        </span>
      </div>
      <div>
        <Dropdown
          // overlay={formatMenuMore()}
          overlay={formatMenuMore1()}
          trigger={["click"]}
          onOpenChange={handleOpenChange.bind(this)}
          open={open}
        >
          <div
            className={
              selectedValue
                ? "v-select-multiple-normal-value-container v-select-multiple-normal-value-container-focused"
                : "v-select-multiple-normal-value-container"
            }
          >
            <div className="ellipsis-text">
              {selectedValue ? selectedValue : t(title)}
            </div>
            <img src={arrowDownIcon} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
}
