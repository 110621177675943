import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Modal, Table, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { getOrders } from "../../../api/orders";
import { dateFormat } from "../../../utils/date";
import "./style/myAssets.less";
import { useHistory, useParams } from "react-router-dom";
import { useUserId } from "../../../contexts/UserIdProvider";
import { localizeValue } from "../../../constants/constants";

export default function MyAssets() {
  const { index } = useParams();
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const { isWinery, user } = useUserId();
  const history = useHistory();
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const pageSize = 20;
  const [pageIndex, setPageIndex] = useState(0);

  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    loadData();
  }, [user]);

  const loadData = async () => {
    let pms = { page: pageIndex, per_page: pageSize };
    if (isWinery) {
      pms.coId = user.userInfo.companyId;
    } else {
      pms.userId = user.userId;
    }

    let result = await getOrders(pms);
    // console.log("--------orders :", pms, result);
    if (!result) return;
    setOrderList(result);
    if (result && result.length > 0) {
      let tempTotalBalance = 0;
      result.map((mapItem) => {
        tempTotalBalance += mapItem.orderPrice.totalPrice;
      });
      setTotalBalance(tempTotalBalance);
    }

    setLoading(false);
  };

  const columns = [
    {
      title: t("ORDER_DATE"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: "120px",
      render: (val, record) => {
        return (
          <div className="text-align-center">
            {dateFormat(val, "yyyy.MM.dd")}
          </div>
        );
      },
    },
    {
      title: t("SUPPLIER"),
      dataIndex: "wineryInfo",
      key: "wineryInfo",
      width: "120px",
      render: (val, record) => {
        if (val && val.length > 0) {
          return (
            <div className="text-align-center">
              {localizeValue(i18n.language, val[0].companyName)}
            </div>
          );
        } else {
          return <div className="text-align-center">-</div>;
        }
      },
    },
    {
      title: t("ORDER_NUMBER"),
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: "120px",
      render: (val, record) => {
        return <div className="text-align-center">{val}</div>;
      },
    },
    {
      title: t("TOTAL_AMOUNT"),
      dataIndex: "orderPrice",
      key: "orderPrice",
      width: "120px",
      render: (val, record) => {
        return <div className="text-align-center">{val.totalPrice}</div>;
      },
    },
    {
      title: t("ORDER_STATUS"),
      dataIndex: "status",
      key: "status",
      width: "120px",
      render: (val, record) => {
        return <div className="text-align-center">{t(val)}</div>;
      },
    },
  ];

  return (
    <div
      className="common-page"
      style={{
        backgroundColor: "#FFFFFF",
      }}
    >
      <div className="my-assets-management-panel common-management ">
        <div className="top-panel">
          <div className="label">
            {t("TOTAL_BALANCE")}:<span className="balance">{totalBalance}</span>
          </div>
          <div
            className="label"
            style={{
              marginTop: "13px",
              marginBottom: "10px",
            }}
          >
            {t("TRANSFER_HISTORY")}
          </div>
        </div>
        <Table
          dataSource={orderList}
          columns={columns}
          className="orders-supplier-table"
          loading={loading}
          pagination={false}
        />
      </div>
    </div>
  );
}
