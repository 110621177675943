export enum OperationScheduleType {
  NEW_PRODUCT_ONLINE = 'NEW_PRODUCT_ONLINE_NOTIFICATION',
  JOINED_ACTIVITY = 'JOINED_ACTIVITY_NOTIFICATION',
  REPLENISH = 'REPLENISH_NOTIFICATION',
  WAITING_FOR_PAY = 'WAITING_FOR_PAY_NOTIFICATION',
  NEW_ORDER = 'NEW_ORDER_NOTIFICATION',
  START_STOCK = 'START_STOCK_NOTIFICATION',
  START_DELIVERY = 'START_DELIVERY_NOTIFICATION',
  WAITING_FOR_PICKUP = 'WAITING_FOR_PICKUP_NOTIFICATION',
  WAITING_FOR_COMPLETE = 'WAITING_FOR_COMPLETE_NOTIFICATION'
}
