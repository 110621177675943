import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useUserId } from "../../../contexts/UserIdProvider";
import "./style/uploadPictureCard.less";
import { getFileType, getUploadFileAction } from "../../../api/common";
import { getCacheTokens } from "../../../utils/cache";
import { useTranslation } from "react-i18next";
import { UploadFileType } from "../../../constants/constants";
import { toastError } from "../../../api/toast";

export default function UploadVideo(props) {
  const { onLoading, onLoaded, fileType } = props;
  const { t } = useTranslation();
  const { user } = useUserId();
  const action = getUploadFileAction(user.userId);
  const token = getCacheTokens();
  const tokenValue = token ? token.value : "";
  const authorization = "Bearer " + tokenValue;

  const layout_upload = {
    name: "file",
    action: action,
    headers: { authorization },
    showUploadList: false,
    maxCount: 1,
    onChange: async (info) => {
      let { file } = info;
      let { percent, status, response, type, name } = file;
      let _fileType = getFileType(type);
      if (status === "uploading") {
        console.log("---upload base loading:", info);
        onLoading && onLoading(percent);
      } else if (status === "done") {
        console.log("---upload base loaded:", info);
        let { result } = response;
        onLoaded && onLoaded(_fileType, result, name);
        //dialog.success(`${info.file.name} file uploaded successfully`);
      } else {
        console.log("----file upload fail", status);
        //dialog.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      if (fileType) {
        let _filetype = getFileType(file.type);
        if (fileType === UploadFileType.IMAGE && fileType !== _filetype) {
          toastError(t("IS_NOT_IMAGE_FILE"));
          return Upload.LIST_IGNORE;
        } else if (
          fileType === UploadFileType.VIDEO &&
          fileType !== _filetype
        ) {
          toastError(t("IS_NOT_VIDEO_FILE"));
          return Upload.LIST_IGNORE;
        }
      }
      return true;
    },
  };

  return (
    <Upload
      {...layout_upload}
      listType="picture-card"
    >
      <PlusOutlined style={{ fontSize: "32px", color: "#C8CAD4" }} />
    </Upload>
  );
}
