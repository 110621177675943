import { RequestType } from "../constants/requestType.enum";
import { formatActionResponse, formatActionResponseArray } from "./api";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export function subscriptionTypes(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.subscriptionTypes, pms);
}

/**
 *
 * @param pms
 */
export function getSubscriptions(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.subscriptions, pms);
}

/**
 *
 * @param pms
 */
export function subscriptionCreate(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.subscriptionCreate, pms);
}

/**
 *
 * @param pms
 */
export function subscriptionDetail(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.subscriptionDetail, pms);
}

/**
 *
 * @param pms
 */
export function subscriptionUpdate(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.subscriptionUpdate, pms);
}



