import React, { useEffect, useState } from "react";
import { List, Spin, Table, Input, Select, Button, Skeleton } from "antd";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { deepClone } from "../../../../utils/utils";
import { pageFriends } from "../../../../api/contact";
import { useTranslation } from "react-i18next";
import "./style/friendSelect.less";
import backIcon from "../../../../resource/icons/feed/detail/back.svg";
import closeIcon from "../../../../resource/icons/common/close.svg";
import selectedIcon from "./images/selected.svg";
import searchIcon from "./images/search.svg";
import AvatarBasic from "../../../common/avatar/AvatarBasic";
import { localizeImg } from "../../../../constants/constants";

export default function FriendSelect(props) {
  const { onComplete, onClose, selectedItems } = props;
  const { user } = useUserId();
  const { userId } = user;
  const [friendList, setFriendList] = useState([]);
  const [oriFriendList, setOriFriendList] = useState([]);
  const pageSize = 30;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    if (!friendList || friendList.length == 0) {
      loadData();
    }
  }, []);

  const loadData = async (index) => {
    setLoading(true);
    let pms = { page: 0, per_page: pageSize, keyword: "" };
    let result = await pageFriends(userId, pms);
    console.log("-------------------select friends:", result);
    if (result && result.length > 0) {
      result.map((item) => {
        item.key = item.id;
      });
      if (index > 0) {
        let _list = deepClone(friendList);
        result = _list.concat(result);
      }
      setFriendList(result);
      setOriFriendList(deepClone(result));
    }
    setLoading(false);
  };

  const onSearckKeywordChange = (e) => {
    let tempKeyword = e.target.value;
    setKeyword(tempKeyword);
    let temp = deepClone(friendList);
    temp = oriFriendList.filter(
      (filterItem) => filterItem.name.indexOf(tempKeyword) != -1
    );
    setFriendList(temp);
  };

  const onFriendItemClick = (friendItem, friendIndex) => {
    let temp = deepClone(friendList);
    temp[friendIndex].selected = !temp[friendIndex].selected;
    setFriendList(temp);
  };

  const onCloseClick = () => {
    onClose && onClose();
  };

  const onConfirmClick = () => {
    let temp = deepClone(friendList);
    onComplete && onComplete(temp.filter((filterItem) => filterItem.selected));
  };

  return (
    <div className="post-feed-friend-select-panel">
      <div className="title-panel">
        <img
          src={backIcon}
          style={{ cursor: "pointer" }}
          onClick={onCloseClick.bind()}
        />
        <div className="title">{t("SELECT_FEED_SCOPE")}</div>
        <img
          src={closeIcon}
          style={{ cursor: "pointer" }}
          onClick={onCloseClick.bind()}
        />
      </div>
      <div className="content-panel">
        <div className="search-panel">
          <img src={searchIcon} />
          <Input
            className="search-keyword-input"
            placeholder={t("SEARCH")}
            value={keyword}
            onChange={onSearckKeywordChange.bind(this)}
          />
        </div>
        {loading && (
          <div
            style={{
              margin: "0px 30px",
            }}
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 0 }} />
            <Skeleton active={true} avatar={true} paragraph={{ rows: 0 }} />
          </div>
        )}
        {!loading &&
          friendList.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  mapItem.selected
                    ? "feed-scope-item-panel-selected"
                    : "feed-scope-item-panel"
                }
                onClick={onFriendItemClick.bind(this, mapItem, mapIndex)}
              >
                <div className="left-part">
                  <AvatarBasic
                    picture={localizeImg(mapItem.avatar)}
                    size={31}
                  ></AvatarBasic>
                  <div className="scope-desc">
                    <div className="label">{mapItem.name}</div>
                  </div>
                </div>
                {mapItem.selected ? (
                  <img src={selectedIcon} />
                ) : (
                  <div className="right-dot"></div>
                )}
              </div>
            );
          })}
      </div>
      <div className="footer-panel">
        <div className="btn btn-cancel" onClick={onCloseClick.bind()}>
          {t("CANCEL")}
        </div>
        <div className="btn btn-confirm" onClick={onConfirmClick.bind(this)}>
          {t("CONFIRM")}
        </div>
      </div>
    </div>
  );
}
