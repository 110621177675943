import React, { useState } from "react";
import cn from "classnames";
import styles from "./ReadMoreLess.module.sass";
import { useTranslation } from "react-i18next";

function ReadMoreLess(props) {
  const { htmlText } = props;
  const [isShowMore, setIsShowMore] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <div className={styles.readmoreless}>
      <div
        className={isShowMore ? cn(styles.readmore) : cn(styles.readless)}
        dangerouslySetInnerHTML={{
          __html: htmlText,
      }}
      ></div>
      <button className={styles.rmlButton} onClick={toggleReadMoreLess}>
      {isShowMore ? t("READ_LESS") : t("READ_MORE")}
      </button>
    </div>
  );
}

export default ReadMoreLess;