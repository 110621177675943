import React, { useState } from "react";
import styles from "./DashboardBankInfo.module.sass";
import TooltipGlodal from "../../../../core-digital-template/components/TooltipGlodal";
import Modal from "../../../../core-digital-template/components/Modal";
import Schedule from "../../../../core-digital-template/components/Schedule";
import NameAndDescription from "./NameAndDescription";
import Panel from "./Panel";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { companyBankInfoSave } from "../../../../api/company";
import { statusCode } from "../../../../constants/statusCode.enum";
import { toastInfo } from "../../../../api/toast";
import { useTranslation } from "react-i18next";

const DashboardBankInfo = () => {
    const { user } = useUserId();
    const { t } = useTranslation();

    const [visiblePreview, setVisiblePreview] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    const [infoFormData, setInfoFormData] = useState();

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    const onFieldsChange = (fields) => {
        console.log("fields:", fields);
        setComInfoFormData(fields);
    }

    const setComInfoFormData = (vals) => {
        let obj = {};
        vals.map((item) => {
            obj[item.name] = item.value;
        });
        setInfoFormData(obj);
    };

    const onSaveValue = async () => {
        let pms = { ...infoFormData };

        pms.coId = user.userInfo.companyId;
        console.log("--------------onSubmitCompanyBankInfo:", pms);
        let result = await companyBankInfoSave(pms);

        if (result && result.statusCode == statusCode.SUCCESS) {
            toastInfo(t("SAVE_SUCCESS"));
        } else {
            toastInfo(t("SAVE_FAILED"));
        }
    }

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <NameAndDescription className={styles.card} onChange={onFieldsChange.bind(this)} />
                </div>
            </div>
            <Panel
                setVisiblePreview={setVisiblePreview}
                setVisibleSchedule={setVisibleModal}
                onSave={onSaveValue.bind(this)}
            />
            <TooltipGlodal />
            <Modal
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
            >
                <Schedule
                    startDate={startDate}
                    setStartDate={setStartDate}
                    startTime={startTime}
                    setStartTime={setStartTime}
                />
            </Modal>
        </>
    );
};

export default DashboardBankInfo;
