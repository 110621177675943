import React from "react";
import Slider from "react-slick";
import godartmLogo from "../../../resource/images/ads/godartm-fr.png";
import cassidyLogo from "../../../resource/images/ads/cassidy_logo.png";
import placeholderBottle from "../../../resource/images/ads/placeholderBottle.png";
import label from "../../../resource/images/ads/label.png";

export default function Ads() {
  const styleOfAds = {
    objectFit: "contain",
    width: "100%",
    height: "auto",
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    adaptiveHeight: true,
  };

  return (
    <div>
      <Slider {...settings}>
        <div>
          <img style={styleOfAds} src={cassidyLogo} />
        </div>
        <div>
          <img style={styleOfAds} src={godartmLogo} />
        </div>
        <div>
          <img style={styleOfAds} src={placeholderBottle} />
        </div>
        <div>
          <img style={styleOfAds} src={label} />
        </div>
      </Slider>
    </div>
  );
}
