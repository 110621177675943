import React from "react";
import cn from "classnames";
import styles from "./Card.module.sass";
import { useHistory } from "react-router";

const Card = ({
  className,
  title,
  classTitle,
  classCardHead,
  head,
  children,
  linkUrl,
}) => {
  const history = useHistory();

  return (
    <div className={cn(styles.card, className)}>
      {(title || title == "OPERATION_PLACEHOLDER") && (
        <div
          className={cn(styles.head, classCardHead)}
          onClick={() => (linkUrl ? history.push(linkUrl) : "")}
          style={{ cursor: linkUrl ? "pointer" : "" }}
        >
          {title != "OPERATION_PLACEHOLDER" && (
            <div className={cn(classTitle, styles.title)}>{title}</div>
          )}
          {head && head}
        </div>
      )}
      {(children) && (
        <div className={styles.body}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Card;
