import { deepClone } from "../utils/utils";

export function initCompanyFieldsData(fields: any, params: any) {
  let {
    businessCountryData,
    businessCityData,
    companyCountryData,
    companyCityData,
    city,
    categoryList,
    knowVinbipList,
    achiveList,
    distributionChannelList,
  } = params;

  let list = deepClone(fields);
  list = list.map((fieldItem: any) => {
    if (
      fieldItem.name === "country" ||
      fieldItem.name === "market" ||
      fieldItem.name === "location" ||
      fieldItem.name == "product_area"
    ) {
      fieldItem.data = deepClone(companyCountryData);
    } else if (fieldItem.name === "region") {
      fieldItem.data = companyCityData;
    } else if (fieldItem.name === "productCategory") {
      fieldItem.data = categoryList;
    } else if (fieldItem.name === "knowVinbip") {
      fieldItem.data = knowVinbipList;
    } else if (
      fieldItem.name === "mainImportingCountry" &&
      businessCountryData &&
      businessCountryData.length > 0
    ) {
      fieldItem.data = businessCountryData;
    } else if (
      fieldItem.name === "businessCitys" &&
      businessCityData &&
      businessCityData.length > 0
    ) {
      fieldItem.data = businessCityData;
      fieldItem.value = [];
    } else if (fieldItem.name === "distributionChannel") {
      fieldItem.data = distributionChannelList;
    } else if (
      fieldItem.name === "businessCountries" &&
      businessCountryData &&
      businessCountryData.length > 0
    ) {
      fieldItem.data = businessCountryData;
    } else if (fieldItem.name === "archive") {
      fieldItem.data = achiveList;
    }

    return fieldItem;
  });

  return list;
}

export function initCompanyFieldsValue(fields: any, companyDesc: any) {
  let list = deepClone(fields);
  list = list.map((fieldItem: any) => {
    if (companyDesc[fieldItem.name]) {
      fieldItem.value = companyDesc[fieldItem.name];
    }

    return fieldItem;
  });

  return list;
}

export function fieldValueToString(fields: any, params: any) {
  let {
    businessCountryData,
    businessCityData,
    companyCountryData,
    companyCityData,
    city,
    categoryList,
    knowVinbipList,
    achiveList,
    distributionChannelList,
  } = params;
  console.log("-----------------fieldValueToString fields:", fields);
  console.log("-----------------fieldValueToString params:", params);

  let result: any = [];
  let list = deepClone(fields);
  list = list.map((fieldItem: any) => {
    let fieldLabel = fieldItem.label ? fieldItem.label : fieldItem.title;
    let fieldValue = "";
    if (
      fieldItem.name === "location" &&
      fieldItem.value &&
      fieldItem.value.country
    ) {
      fieldValue = companyCountryData.filter(
        (filterItem: any) => filterItem.value == fieldItem.value.country
      )[0].label;
    } else if (
      (fieldItem.name === "market" && fieldItem.data) ||
      fieldItem.name == "product_area"
    ) {
      let tempValues: any = [];
      fieldItem.data.map((mapItem: any) => {
        let tempResult = companyCountryData.filter(
          (filterItem: any) => mapItem.value == filterItem.value
        );
        if (tempResult && tempResult.length > 0) {
          tempValues.push(tempResult[0].label);
        }
      });

      fieldValue = tempValues.join(",");
    } else if (fieldItem.name === "productCategory" && fieldItem.value) {
      let tempValues: any = [];
      fieldItem.value.map((mapItem: any) => {
        let tempResult = categoryList.filter(
          (filterItem: any) => mapItem == filterItem.value
        );
        if (tempResult && tempResult.length > 0) {
          tempValues.push(tempResult[0].label);
        }
      });

      fieldValue = tempValues.join(",");
    } else if (fieldItem.name === "knowVinbip") {
      fieldItem.data = knowVinbipList;
    } else if (
      fieldItem.name === "mainImportingCountry" &&
      fieldItem.value &&
      businessCountryData &&
      businessCountryData.length > 0
    ) {
      let tempValues: any = [];
      fieldItem.value.map((mapItem: any) => {
        let tempResult = companyCountryData.filter(
          (filterItem: any) => mapItem == filterItem.value
        );
        if (tempResult && tempResult.length > 0) {
          tempValues.push(tempResult[0].label);
        }
      });

      fieldValue = tempValues.join(",");
    } else if (
      fieldItem.name === "businessCitys" &&
      businessCityData &&
      businessCityData.length > 0
    ) {
      fieldItem.data = businessCityData;
      fieldItem.value = [];
    } else if (fieldItem.name === "distributionChannel" && fieldItem.value) {
      let tempValues: any = [];
      fieldItem.value.map((mapItem: any) => {
        let tempResult = distributionChannelList.filter(
          (filterItem: any) => mapItem == filterItem.value
        );
        if (tempResult && tempResult.length > 0) {
          tempValues.push(tempResult[0].label);
        }
      });

      let tempFieldValue = tempValues.join(",");
      fieldValue =
        tempFieldValue.length < 18
          ? tempFieldValue
          : tempFieldValue.substr(0, 18) + "...";
    } else if (
      fieldItem.name === "businessCountries" &&
      fieldItem.value &&
      businessCountryData &&
      businessCountryData.length > 0
    ) {
      fieldItem.data = businessCountryData;
    } else if (fieldItem.name === "archive" && fieldItem.value) {
      let tempValues: any = [];
      fieldItem.value.map((mapItem: any) => {
        let tempResult = achiveList.filter(
          (filterItem: any) => mapItem == filterItem.value
        );
        if (tempResult && tempResult.length > 0) {
          tempValues.push(tempResult[0].label);
        }
      });

      fieldValue = tempValues.join(",");
    } else if (fieldItem.type === 3 && fieldItem.value) {
      fieldValue = fieldItem.value.number;
    } else {
      fieldValue = fieldItem.value;
    }

    if (fieldLabel) {
      result.push({
        label: fieldLabel,
        value: fieldValue,
      });
    }
  });

  return result;
}
