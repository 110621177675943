import React, { useEffect, useState } from "react";
import { Button, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import "./style/ProductDiscoverItem.less";
import { useHistory } from "react-router-dom";
import {
  localizeImg,
  localizeTitle,
  ProductCurrencyType,
} from "../../../constants/constants";
import { useUserId } from "../../../contexts/UserIdProvider";
import {
  getProductConfigNameById,
  getProductPriceRangeText,
  loadProductConfig,
  getProductActivityPriceRangeText,
  getProductPriceText,
  getProductIsLocalStock,
  getProductActivityPriceText,
} from "../../../api/product";
import stockIcon from "../../../resource/icons/product-detail/stock.svg";
import castleIcon from "../../../resource/icons/product-detail/castle.svg";
import awardIcon from "../../../resource/icons/product-detail/award.svg";
import { getCountryNameById } from "../../../api/config";
import { addFavorite, removeFavorite } from "../../../api/favorite";
import { discountType } from "../../../constants/discountType.enum";
import { getProductAwards } from "../../../api/user";

export default function ProductDiscoverItem(props) {
  const { item, showDiscount, onCheckLimit, priceVisible } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const [title, setTitle] = useState();
  const [countryName, setCountryName] = useState();
  const [regionName, setRegionName] = useState();
  const [typeName, setTypeName] = useState();
  const [classificationName, setClassificationName] = useState();
  const [currentLanguage, setCurrentLanguage] = useState();
  const [favorited, setFavoriteStatus] = useState(false);
  const [priceRangeText, setPriceRangeText] = useState();
  const [activityPriceText, setActivityPriceText] = useState();
  const [awardList, setAwardList] = useState([]);
  const [isLocalStock, setIsLocalStock] = useState(false);

  useEffect(() => {
    const { favorited, volumes, _id } = item;
    setIsLocalStock(getProductIsLocalStock(volumes));
    setPriceRangeText(getProductPriceText(item));
    setFavoriteStatus(favorited);
    getAcitvityPrice(item);
    setConfigData();
    initProductAwards(_id);
  }, [item]);

  useEffect(() => {
    if (currentLanguage) {
      setConfigData();
    }
  }, [currentLanguage]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const initProductAwards = async (id) => {
    let pms = {
      productId: id,
      page: 0,
      per_page: 10,
    };
    let data = await getProductAwards(pms);
    if (data && data.total > 0) {
      let { result } = data;
      setAwardList(result);
    }
  };

  const setConfigData = async () => {
    setTitle(localizeTitle(currentLanguage, item.title));
    let configData = await loadProductConfig();
    let { locations, types, classifications, categories, tags } = configData;
    setCountryName(
      getCountryNameById(locations, currentLanguage, item.country)
    );
    setRegionName(getCountryNameById(locations, currentLanguage, item.region));
    setTypeName(getProductConfigNameById(types, currentLanguage, item.types));
    setClassificationName(
      getProductConfigNameById(
        classifications,
        currentLanguage,
        item.classifications
      )
    );
  };

  const onGoDetail = () => {
    if (onCheckLimit()) {
      history.push("/products/detail/" + item._id, { showDiscount });
    }
  };

  const onSwitchFavorite = () => {
    const pms = {
      coId: user.userInfo.companyId,
      productId: item._id,
    };

    if (!favorited) {
      addFavorite(pms);
    } else {
      removeFavorite(pms);
    }
    setFavoriteStatus(!favorited);
  };

  const getAcitvityPrice = (pro_item) => {
    let { discount } = pro_item;
    if (discount) {
      const type = discount.discountType;
      let percent = 0;
      if (type == discountType.NORMAL) {
        percent = discount.normal.percent;
      } else if (type == discountType.DESTOCKS) {
        percent = discount.destocks.percent;
      } else if (type == discountType.DEGRESSIVE) {
      } else if (type == discountType.EVENT) {
      }
      setActivityPriceText(getProductActivityPriceText(pro_item, percent));
    }
  };

  return (
    <>
      {item ? (
        <div className="product-discover-item">
          <div className="product-item-left">
            <img
              className="product-image cursor-pointer"
              src={localizeImg(item.bottleImages[0])}
              onClick={onGoDetail.bind(this)}
            />
          </div>
          <div className="product-item-right">
            <div className="product-title">
              {title}
              <span className="product-sub-title"></span>
            </div>
            <div className="product-flag-pannel">
              {countryName && <div className={"flag-item"}>{countryName}</div>}
              <div className={"flag-item"}>
                <img src={castleIcon} className="flag-icon" />
                {t("WINERY")}
              </div>
              {awardList.length > 0 && (
                <div className={"flag-item"}>
                  <img src={awardIcon} className="flag-icon" />
                  {t("AWARDS_LIST")}
                </div>
              )}
              <div className={"flag-item"}>
                <img src={stockIcon} className="flag-icon" />
                {isLocalStock ? t("LOCAL_STOCK") : t("STOCK_ABROAD")}
              </div>
            </div>
            <div className="info-desc-item-panel">
              <div className="info-desc-item-title">
                <span className="title-text">{t("YEAR")}</span>
              </div>
              <div className="info-desc-item-content">
                <div className={"desc-year-item "}>{item.year}</div>
              </div>
            </div>
            <div className="info-desc-item-panel">
              <div className="info-desc-item-title">
                <span className="title-text">{t("TYPE")}</span>
              </div>
              <div className="info-desc-item-content">
                <div className={"tip-text "}>{typeName}</div>
              </div>
            </div>
            <div className="info-desc-item-panel">
              <div className="info-desc-item-title">
                <span className={"title-text"}>{t("PRICE")}</span>
              </div>
              <div className="info-desc-item-content">
                {priceVisible && (
                  <div className="price-value">
                    {showDiscount && activityPriceText ? (
                      <>
                        <span>{activityPriceText}</span>
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "#999999",
                            fontSize: "16px",
                            marginLeft: "10px",
                          }}
                        >
                          {priceRangeText}&nbsp;&nbsp;
                        </span>
                        {/* <span className={"price-tip-text"}>({t("EXW")})</span> */}
                      </>
                    ) : (
                      <span>{priceRangeText}</span>
                    )}
                  </div>
                )}
                {!priceVisible && (
                  <div
                    className="price-value cursor-pointer"
                    onClick={() => onCheckLimit && onCheckLimit()}
                  >
                    <span className={"price-tip-text"}>
                      ({t("PRICE_SHOW")})
                    </span>
                  </div>
                )}
              </div>
              <Button
                className="button-add-order"
                onClick={onSwitchFavorite.bind(this)}
              >
                {favorited ? t("FAVORITED") : t("FAVORITE")}
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button className="button-normal" onClick={onGoDetail.bind(this)}>
                {t("SEE_MORE")}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton avatar={true} active={true} paragraph={{ rows: 1 }} />
      )}
    </>
  );
}
