import React, { useEffect, useState } from "react";
import styles from "./News.module.sass";
import cn from "classnames";
import Details from "./Details";
import Comments from "./Comments";
import Panel from "./Panel";
import Icon from "../../Icon";
import { userDetail } from "../../../../api/user";

const News = ({ news }) => {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [authorInfo, setAuthorInfo] = useState({});

  const handleClose = () => {
    setActiveIndex(0);
    setVisible(false);
  };

  useEffect(() => {
    loadUserDetail(news.userId);
  }, [news]);

  const loadUserDetail = async (userId) => {
    let result = await userDetail(userId);
    if (result) {
      console.log("authorInfo:",result);
      setAuthorInfo(result);
    }
  };

  return (
    <div className={cn(styles.product, { [styles.active]: visible })}>
      <Details
        className={styles.details}
        setValue={setVisible}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        news={news}
        authorInfo={authorInfo}
      />
      {/* <Comments className={styles.comments} onClose={() => handleClose()} /> */}
      <Panel className={styles.panel} news={news} authorInfo={authorInfo}/>
      {/* <button className={styles.next}>
        <Icon name="arrow-right" size="24" />
      </button> */}
    </div>
  );
};

export default News;
