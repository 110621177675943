import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "../../Icon";
import { useUserId } from "../../../../contexts/UserIdProvider";
import {
  companyImporterDesc,
  companyWineryDesc,
} from "../../../../api/company";
import { localizeImg } from "../../../../constants/constants";
import { getFileTypeByPath } from "../../../../api/common";
import { statusCode } from "../../../../constants/statusCode.enum";
import { Path, PathTools } from "../../../../constants/path";

const Dropdown = ({ className, item, visibleSidebar, setValue, onClose }) => {
  const { user, isWinery } = useUserId();
  const [userCompanyDetail, setUserCompanyDetail] = useState({});

  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    loadCompany();
  }, [user]);

  const loadCompany = async () => {
    let data;
    const pms = { coId: user?.userInfo?.companyId };
    if (isWinery) {
      data = await companyWineryDesc(pms);
    } else {
      data = await companyImporterDesc(pms);
    }
    if (data && data.statusCode === statusCode.SUCCESS && data.result) {
      if (data.result.banner) {
        data.result.bannerType = getFileTypeByPath(
          localizeImg(data.result.banner)
        );
      }
      setUserCompanyDetail(data.result);
    }
  };

  const handleClick = () => {
    setVisible(!visible);
    setValue(true);
  };

  const Head = () => {
    return (
      <button
        className={cn(
          styles.head,
          {
            [styles.active]: pathname.includes(item.slug),
          },
          { [styles.wide]: visibleSidebar }
        )}
        onClick={() => handleClick()}
      >
        {item.iconType == "svg" ? (
          <img src={item.icon} width={20} style={{ marginRight: "12px" }} />
        ) : (
          <Icon name={item.icon} size="20" />
        )}
        {item.title}
        <Icon name="arrow-down" size="20" />
      </button>
    );
  };

  return (
    <div
      className={cn(
        styles.dropdown,
        className,
        { [styles.active]: visible },
        {
          [styles.active]: pathname.includes(item.slug),
        },
        { [styles.wide]: visibleSidebar }
      )}
    >
      {item.add ? (
        <div
          className={cn(styles.top, {
            [styles.active]: pathname.endsWith("/products/add"),
          })}
        >
          <Head />
          <Link
            className={cn(styles.add, {
              [styles.active]: pathname.endsWith("/products/add"),
            })}
            to={PathTools.joinPath(
              Path.productsAdd,
              userCompanyDetail?.companyName
            )}
            onClick={onClose}
          >
            <Icon name="plus" size="10" />
          </Link>
        </div>
      ) : (
        <Head />
      )}
      <div className={styles.body}>
        {item.dropdown.map((x, index) => (
          <NavLink
            className={cn(styles.link, {
              [styles.active]: pathname === x.url,
            })}
            to={x.developing ? Path.developing : x.url}
            key={index}
            onClick={onClose}
          >
            {x.title}
            {x.counter ? (
              <div
                className={styles.counter}
                style={{ backgroundColor: x.colorCounter }}
              >
                {x.counter}
              </div>
            ) : (
              <Icon name="arrow-next" size="20" />
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
