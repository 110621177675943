import { Link, useHistory } from "react-router-dom";
import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";

export default function Developing() {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <Result
      status="404"
      title={t("DEVELOPING")}
      subTitle={t("DEVELOPING_WAITING")}
      extra={
        <Button type="primary" onClick={() => history.go(-1)}>
          {t("BACK")}
        </Button>
      }
    />
  );
}
