import React, { useEffect, useState } from "react";
import { Button, Space, Table, Modal, Breadcrumb } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUserId } from "../../../contexts/UserIdProvider";
import {
  recipientRemove,
  getRecipients,
  setDefaultRecipient,
} from "../../../api/user";
import AddressCreate from "./AddressCreate";
import "./style/address.less";
import { deepClone } from "../../../utils/utils";
import { toastError, toastInfo } from "../../../api/toast";
import { formatPhone } from "../../../api/common";

export default function Address() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const { userId } = user;
  const [data, setData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [createModalVisible, setcreateModalVisible] = useState(false);
  const [addressDetail, setaddressDetail] = useState();
  const pageSize = 10;

  useEffect(() => {
    loadData();
  }, []);

  const columns = [
    { title: t("COUNTRY"), dataIndex: "country", key: "name", width: "10%" },
    { title: t("PROVINCE"), dataIndex: "state", key: "state", width: "10%" },
    { title: t("CITY"), dataIndex: "city", key: "city", width: "10%" },
    {
      title: t("ADDRESS"),
      dataIndex: "addressLineOne",
      key: "addressLineOne",
      width: "20%",
    },
    // { title: t("ZIPCODE"), dataIndex: "zipcode", key: "zipcode", width: '10%' },
    {
      title: t("CONSIGNEE_NAME"),
      dataIndex: "userName",
      key: "userName",
      width: "15%",
    },
    {
      title: t("PHONE_NUMBER"),
      dataIndex: "phone",
      key: "phone",
      width: "15%",
      render: (val) => {
        return formatPhone(val);
      },
    },
    {
      title: t("IS_DEFAULT"),
      dataIndex: "isDefault",
      key: "isDefault",
      width: "10%",
      render: (val) => {
        return val ? t("YES") : t("NO");
      },
    },
    {
      title: t("ACTION"),
      dataIndex: "",
      key: "y",
      render: (item) => {
        return (
          <Space size={6}>
            <Button
              type="link"
              className={"button-primary"}
              onClick={onUpdate.bind(this, item._id)}
            >
              {t("EDIT")}
            </Button>
            <Button
              type="link"
              className={"button-primary"}
              onClick={onDelete.bind(this, item._id)}
            >
              {t("DELETE")}
            </Button>
            {!item.isDefault && (
              <Button
                type="link"
                className={"button-default"}
                onClick={onSetDefault.bind(this, item._id)}
              >
                {t("SET_DEFAULT")}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const loadData = async () => {
    let pms = { userId };
    let list = await getRecipients(pms);
    if (list && list.length > 0) {
      let orgList = deepClone(list);
      setOrgData(orgList);
      list = list.map((item, index) => {
        return {
          key: index,
          status: item.status,
          ...item.recipientAddress,
          ...item.recipientUser,
          _id: item._id,
          isDefault: item.isDefault,
        };
      });
      console.log("-----adderss result:", list);
      setData(list);
    } else {
      setData([]);
    }
  };

  const onDelete = async (id) => {
    let pms = { recipientId: id };
    await recipientRemove(pms);
    await loadData();
  };

  const onFinished = () => {
    setcreateModalVisible(false);
    loadData();
  };

  const onUpdate = (id) => {
    let tempList = orgData.filter((item) => item._id === id);
    if (tempList && tempList.length > 0) {
      setaddressDetail(tempList[0]);
      setcreateModalVisible(true);
    }
  };

  const onSetDefault = async (id) => {
    let pms = { id, userId };
    let data = await setDefaultRecipient(pms);
    if (data) {
      toastInfo(t("ACTION_SUCCESS"));
      loadData();
    } else {
      toastError(t("ACTION_FAIL"));
    }
  };

  return (
    <div className="common-page ">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_COMPANY")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("ADDRESS_MANAGEMENT")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={"common-management address-container"}>
        <div className="common-management-title">
          {t("ADDRESS_MANAGEMENT")}
          <Button
            onClick={() => setcreateModalVisible(true)}
            className="create-button"
          >
            {t("CREATE_ADDRESS")}
          </Button>
        </div>
        <div style={{ marginTop: "10px" }}></div>
        <div className="common-management-panel">
          <Table
            columns={columns}
            dataSource={data}
            pagination={data.length > pageSize}
            style={{ marginTop: "10px" }}
          />
        </div>
        <Modal
          destroyOnClose={true}
          title={null}
          visible={createModalVisible}
          footer={null}
          className="address-create-modal"
          onCancel={() => setcreateModalVisible(false)}
        >
          <AddressCreate
            detail={addressDetail}
            isDefault={data.length === 0}
            onComplete={onFinished.bind(this)}
          />
        </Modal>
      </div>
    </div>
  );
}
