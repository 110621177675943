import React from "react";
import { Input, InputNumber } from "antd";
import "./style/inputCountBasic.less";

export default function InputCountBasic(props) {
  let { minCount, maxCount, count, onChange } = props;
  // console.log('----input number control:', minCount, maxCount, count)
  maxCount = isNaN(maxCount) ? 999999 : maxCount;
  minCount = isNaN(minCount) ? 0 : minCount;
  const onMinusClick = () => {
    if (count > minCount) {
      onCountChange(count - 1);
    }
  };

  const onPlusClick = () => {
    if (count < maxCount) {
      onCountChange(count + 1);
    }
  };

  const onCountChange = (val) => {
    onChange && onChange(val);
  };

  return (
    <div className="input-count-basic">
      <div className="btn-minus" onClick={onMinusClick.bind(this)}>
        —
      </div>
      <InputNumber
        className="input-number"
        max={maxCount}
        min={minCount}
        bordered={false}
        controls={false}
        value={count}
        onChange={onCountChange.bind(this)}
      />
      <div className="btn-plus" onClick={onPlusClick.bind(this)}>
        +
      </div>
    </div>
  );
}
