import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Price.module.sass";
import Card from "../../../../../core-digital-template/components/Card";
import Tooltip from "../../../../../core-digital-template/components/Tooltip";
import TextInput from "../../../../../core-digital-template/components/TextInput";
import Switch from "../../../../../core-digital-template/components/Switch";
import Dropdown from "../../../../../core-digital-template/components/Dropdown";
import { useTranslation } from "react-i18next";
import { deepClone } from "../../../../../utils/utils";
import {
  getProductConfigNameForSelect,
  loadProductConfig,
} from "../../../../../api/product";
import {
  ProductPropertiesConfig,
  ProductSampleValue,
} from "../../../../../constants/constants";
import { warehouses } from "../../../../../api/warehouse";
import { statusCode } from "../../../../../constants/statusCode.enum";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { Link } from "react-router-dom";
import Icon from "../../../../../core-digital-template/components/Icon";
import closeIcon from "../../../../../resource/icons/product-create/close.svg";

const Price = ({ className, onChange, dataSource }) => {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [tradingConditions, setTradingConditions] = useState([]);

  const [warehouseList, setWarehouseList] = useState([]);
  const [volumes, setVolumes] = useState([]);
  const [currencys, setCurrencys] = useState();
  const [packages, setPackages] = useState([]);
  const [resolution, setResolution] = useState(false);

  useEffect(() => {
    setConfigData();

    let tempPackages = deepClone(packages);
    tempPackages = [];
    ProductPropertiesConfig.package.map((mapItem) => {
      mapItem.label = t(mapItem.label);
      tempPackages.push(mapItem);
    });
    setPackages(tempPackages);
  }, []);

  useEffect(() => {
    if (dataSource) {
      console.log("dataSource:", dataSource);
      let temp = deepClone(tradingConditions);
      temp = dataSource;
      setTradingConditions(temp);

      if (temp.length == 0) {
        onAddTradingCondition();
      }
    }
  }, [dataSource]);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { volumes, currencys } = configData;

    let list = getProductConfigNameForSelect(volumes, currentLanguage);
    list.push({ label: ProductSampleValue, value: ProductSampleValue });
    list.map((item) => {
      if (item.label == "Sample") {
        item.label = t("SAMPLES");
      } else {
        item.label = item.label + " ML";
      }
    });
    setVolumes(list);

    setCurrencys(currencys);

    let pms = { coId: user.userInfo.companyId };
    let data = await warehouses(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      let list = data.result.result;
      if (list && list.length > 0) {
        list = list.map((item, index) => {
          return { ...item, key: index, label: item.name, value: item._id };
        });
        setWarehouseList(list);
      }
    }
  };

  const setTradingConditionsValue = (name, index, value) => {
    let temp = deepClone(tradingConditions);
    temp[index][name] = value;
    setTradingConditions(temp);

    onChange && onChange(temp);
  };

  const setTradingConditionPricesValue = (name, index, priceIndex, value) => {
    let temp = deepClone(tradingConditions);
    temp[index]["prices"][priceIndex][name] = value;
    setTradingConditions(temp);

    onChange && onChange(temp);
  };

  const setTradingConditionPriceSampleValue = (
    name,
    index,
    priceIndex,
    value
  ) => {
    let temp = deepClone(tradingConditions);
    temp[index]["prices"][priceIndex][name] =
      !temp[index]["prices"][priceIndex][name];
    setTradingConditions(temp);

    onChange && onChange(temp);
  };

  const onAddNewPrices = (index) => {
    let temp = deepClone(tradingConditions);
    temp[index]["prices"].push({});
    setTradingConditions(temp);

    onChange && onChange(temp);
  };

  const onRemovePrices = (index, priceIndex) => {
    let temp = deepClone(tradingConditions);
    temp[index]["prices"].splice(priceIndex, 1);
    setTradingConditions(temp);

    onChange && onChange(temp);
  };

  const onAddTradingCondition = () => {
    let temp = deepClone(tradingConditions);
    temp.push({ prices: [{}] });
    setTradingConditions(temp);
  };

  const onRemoveTradingCondition = (index) => {
    console.log("onRemoveTradingCondition:", index);
    let temp = deepClone(tradingConditions);
    temp = temp.filter((item, i) => index !== i);
    setTradingConditions(temp);
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title={t("PRICE")}
      classTitle="title-red"
    >
      {tradingConditions.map((item, index) => {
        return (
          <div
            className={styles.price}
            style={{ marginBottom: "10px" }}
            index={index}
          >
            <div
              className={styles.table}
              style={{
                //border: "1px solid rgba(111, 118, 126, 0.4)",
                padding: "10px",
                borderRadius: "5px",
                position: "relative",
              }}
            >
              <img
                src={closeIcon}
                style={{
                  width: "24px",
                  position: "absolute",
                  right: "-10px",
                  top: "-10px",
                  cursor: "pointer",
                }}
                onClick={onRemoveTradingCondition.bind(this, index)}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "48%" }}>
                  <Dropdown
                    className={styles.field}
                    label={t("SHIPPING_WAREHOUSE")}
                    value={item.shippingAddress}
                    style={{ width: "50%" }}
                    setValue={setTradingConditionsValue.bind(
                      this,
                      "shippingAddress",
                      index
                    )}
                    options={warehouseList}
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <TextInput
                    className={styles.field}
                    label={t("QUANTITY") + "(" + t("BOTTLES") + ")"}
                    name="amount"
                    type="text"
                    required
                    onChange={setTradingConditionsValue.bind(
                      this,
                      "stock",
                      index
                    )}
                    value={item?.stock}
                  />
                </div>
              </div>
              <div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div>{t("PRICE_RANGE")}</div>
                    <div
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        marginLeft: "16px",
                        cursor: "pointer",
                      }}
                      onClick={onAddNewPrices.bind(this, index)}
                    >
                      +
                    </div>
                  </div>
                  <div>
                    {item?.prices?.map((priceItem, priceIndex) => {
                      return (
                        <div className={styles.row}>
                          <div>
                            <TextInput
                              className={styles.field}
                              classLabel={styles.label}
                              name="minimum-amount"
                              type="text"
                              required
                              placeholder={t("MINIMUM_ORDER")}
                              addonAfter={t("BOTTLES")}
                              onChange={setTradingConditionPricesValue.bind(
                                this,
                                "minCount",
                                index,
                                priceIndex
                              )}
                              value={priceItem?.minCount}
                            />
                          </div>
                          <div className={styles.col}>
                            <TextInput
                              className={styles.field}
                              classLabel={styles.label}
                              name="suggested-amount"
                              type="text"
                              required
                              placehoder={t("PRICE")}
                              currency={item?.currency?.label}
                              onChange={setTradingConditionPricesValue.bind(
                                this,
                                "price",
                                index,
                                priceIndex
                              )}
                              value={priceItem?.price}
                            />
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>Samples</div>
                            <Switch
                              className={styles.switch}
                              value={priceItem?.samples}
                              onChange={setTradingConditionPriceSampleValue.bind(
                                this,
                                "samples",
                                index,
                                priceIndex
                              )}
                            />
                            <Link
                              className={cn(
                                "button-stroke",
                                styles.deletebutton
                              )}
                              style={{ marginLeft: "10px" }}
                              onClick={onRemovePrices.bind(
                                this,
                                index,
                                priceIndex
                              )}
                            >
                              <Icon name="trash" size="24" />
                              <span>{t("DELETE")}</span>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <button
        className={cn("button", styles.button)}
        onClick={onAddTradingCondition.bind(this)}
      >
        {t("ADD_TRADING_CONDITION")}
      </button>
    </Card>
  );
};

export default Price;
