import { useEffect, useState } from "react";
import { localizeImg } from "../../../constants/constants";

export default function VideoBasic(props) {
  let { width, height, url } = props;
  // console.log("-----------------------------VideoBasic:",url);
  const [path, setPath] = useState(url);
  useEffect(() => {
    if (url) {
      setPath(localizeImg(url));
    }
  }, [url]);

  const onVideoPlay = (e) => {
    let list = document.getElementsByClassName("video_basic");
    for (let index = 0; index < list.length; index++) {
      const item = list[index];
      if (item !==e.target) {
        item.pause();
      }      
    }
  };

  return (
    <video
      width={width}
      height={height}
      controls
      
      onPlay={onVideoPlay.bind(this)}
      className="video_basic"
    >
      <source src={path} type="video/mp4" />
      <source src={path} type="video/ogg" />
      <source src={path} type="video/webm" />
    </video>
  );
}
