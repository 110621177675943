export enum UserNavigationHistoryEnum {
  NOT_FOUND = 'NOT_FOUND',
  FEED = 'FEED',
  DISCOVER = 'DISCOVER',
  DEMANDOFFER_CREATE = 'DEMANDOFFER_CREATE',
  TENDER_CREATE = 'TENDER_CREATE',
  TENDER = 'TENDER',
  TENDERS = 'TENDERS',
  SHOP_PRODUCT = 'SHOP_PRODUCT',
  SHOP_PRODUCT_CREATE = 'SHOP_PRODUCT_CREATE',
  SHOP_PRODUCT_DETAIL = 'SHOP_PRODUCT_DETAIL',
  SHOP_AWARD = 'AWARD',
  SHOP_AWARD_CREATE = 'SHOP_AWARD_CREATE',
  SHOP_DISCOUNTS = 'SHOP_DISCOUNTS',
  SHOP_DISCOUNTS_CREATE = 'SHOP_DISCOUNTS_CREATE',
  SHOP_SAMPLES = 'SHOP_SAMPLES',
  SHOP_ORDERS = 'SHOP_ORDERS',
  PRODUCTS_LIST = 'PRODUCTS',
  PRODUCT = 'PRODUCT',
  PRODUCTS_MYLIST = 'PRODUCTS_MYLIST',
  SEARCH = 'SEARCH',
  SHOPPINGCART = 'SHOPPINGCART',
  SHOPPING_CART = 'SHOPPING_CART',
  WINERY = 'WINERY',
  IMPORTER = 'IMPORTER',
  CONTACTS = 'CONTACTS',
  MYTRENDS = 'MYTRENDS',
  SERVICE_BRANDSREGISTRATION = 'SERVICE_BRANDSREGISTRATION',
  SERVICE_LABELDESIGN = 'SERVICE_LABELDESIGN',
  SERVICE_PACKAGEDESIGN = 'SERVICE_PACKAGEDESIGN',
  COMPANY_SETTINGS = 'COMPANY_SETTINGS',
  COMPANY_ADDRESS = 'COMPANY_ADDRESS',
  COMPANY_VERIFY = 'COMPANY_VERIFY',
  COMPANY_CERTIFICATION= 'COMPANY_CERTIFICATION',
  COMPANY_STAFF = 'COMPANY_STAFF',
  STAFF_MANAGEMENT= 'STAFF_MANAGEMENT',
  IMCHAT = 'IMCHAT',
  NOTIFY = 'NOTIFY',
  USERCENTER_ME = 'USERCENTER_ME',
  PERSONAL_INFORMATION= 'PERSONAL_INFORMATION',
  USERCENTER_CHANGEPASSWORD = 'USERCENTER_CHANGEPASSWORD',
  CHANGE_PASSWORD= 'CHANGE_PASSWORD',
  USERCENTER_MYSUBSCRIPTION = 'USERCENTER_MYSUBSCRIPTION',
  USERCENTER_CLOSEACCOUNT = 'USERCENTER_CLOSEACCOUNT',
  DASHBOARD = 'DASHBOARD',
  CALENDAR = 'CALENDAR',
  SHIPMENT = 'SHIPMENT',
  INQUIRES = 'INQUIRE',
  MYPOINTS = 'MYPOINTS',
  MYNEWS = 'MYNEWS',
  ARTICLE_CREATE = 'ARTICLE_CREATE',
  SUPPLIERLIST = 'SUPPLIERLIST',
  CLIENTLIST = 'CLIENTLIST',
  ALLIANCELIST = 'ALLIANCELIST',
  COMPANY_WALLET = 'COMPANY_WALLET',
  HELP_GUIDE = 'HELP_GUIDE',
  SUPPORT = 'SUPPORT',
  LEADS = 'LEADS',
  OPPORTUNITY = 'OPPORTUNITY',
  DISTRUBUTORLIST = 'DISTRUBUTORLIST',
}
