import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";
import Balance from "../../../components/Balance";
import Chart from "./Chart";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { TableSourceMaxCount } from "../../../../constants/constants";
import { OrderStatus } from "../../../../constants/statusCode.enum";
import { customerImporterPage, getPageOrders } from "../../../../api/orders";
import { deepClone } from "../../../../utils/utils";

const intervals = ["This week", "This month", "This year"];

const items = [
  {
    title: "EARNING",
    counter: "128k",
    icon: "activity",
    value: 37.8,
    background: "#edf8f2",
    chartColor: "#83BF6E",
    data: [
      {
        name: "1",
        earning: 500,
      },
      {
        name: "2",
        earning: 800,
      },
      {
        name: "3",
        earning: 1100,
      },
      {
        name: "4",
        earning: 900,
      },
      {
        name: "5",
        earning: 1300,
      },
      {
        name: "6",
        earning: 800,
      },
      {
        name: "7",
        earning: 1000,
      },
    ],
  },
  {
    title: "CUSTOMERS",
    counter: "512",
    icon: "shopping-bag",
    value: -37.8,
    background: "#ecf9fe",
    chartColor: "#2A85FF",
    data: [
      {
        name: "1",
        earning: 1300,
      },
      {
        name: "2",
        earning: 800,
      },
      {
        name: "3",
        earning: 1000,
      },
      {
        name: "4",
        earning: 500,
      },
      {
        name: "5",
        earning: 800,
      },
      {
        name: "6",
        earning: 1100,
      },
      {
        name: "7",
        earning: 900,
      },
    ],
  },
  {
    title: "TOTAL_ORDERS",
    counter: "64k",
    icon: "payment",
    value: 37.8,
    background: "#f2efff",
    chartColor: "#8E59FF",
    data: [
      {
        name: "1",
        earning: 1200,
      },
      {
        name: "2",
        earning: 800,
      },
      {
        name: "3",
        earning: 1300,
      },
      {
        name: "4",
        earning: 600,
      },
      {
        name: "5",
        earning: 1300,
      },
      {
        name: "6",
        earning: 800,
      },
      {
        name: "7",
        earning: 1000,
      },
    ],
  },
];

const Overview = ({ className }) => {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const [totalTransaction, setTotalTransaction] = useState(0);
  const [customerNum, setCustomerNum] = useState(0);
  const [orderNum, setOrderNum] = useState(0);

  const [sorting, setSorting] = useState(intervals[0]);

  const [dataSource, setDataSource] = useState(items);

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    let temp = deepClone(dataSource);

    const orderObj = await loadOrdersInfo();
    temp[0]["counter"] = orderObj.earning.counter;
    temp[0]["data"] = orderObj.earning.data;

    temp[2]["counter"] = orderObj.order.counter;
    temp[2]["data"] = orderObj.order.data;

    const customerObj = await loadCustomersInfo();
    temp[1]["counter"] = customerObj.counter;
    temp[1]["data"] = customerObj.data;

    setDataSource(temp);
  };

  const loadOrdersInfo = async () => {
    let ordersInfo = {};
    let counterEarning = 0;
    let dataArrEarning = zeroDataArr();

    let counterOrders = 0;
    let dataArrOrders = zeroDataArr();

    let pms = {
      coId: user.userInfo.companyId,
      page: 0,
      per_page: TableSourceMaxCount,
      payStatus: OrderStatus.Payed,
    };
    let data = await getPageOrders(pms);
    console.log("getPageOrders:", data);
    if (data && data.total > 0) {
      counterOrders = data.total;
      let num = 0;
      for (let i = 0; i < data.result.length; i++) {
        const element = data.result[i];
        for (let j = 0; j < element.orderProducts.length; j++) {
          const pro = element.orderProducts[j];
          num += pro.price;
        }
      }
      counterEarning = num;
    } else {
      counterEarning = 0;
      counterOrders = 0;
      dataArrEarning = zeroDataArr();
      dataArrOrders = zeroDataArr();
    }

    ordersInfo = {
      earning: {
        counter: counterEarning,
        data: dataArrEarning,
      },
      order: {
        counter: counterOrders,
        data: dataArrOrders,
      },
    };

    return ordersInfo;
  };

  const loadCustomersInfo = async () => {
    let pms = { page: 0, per_page: 20, coId: user.userInfo.companyId };
    let result = await customerImporterPage(pms);

    let counter = 0;
    let dataArr = zeroDataArr();

    if (result && result.total > 0) {
      counter = result.total;
    }
    return {
      counter: counter,
      data: dataArr,
    };
  };

  const setDataSourceValue = (counter) => {
    let temp = deepClone(dataSource);
    temp[0]["counter"] = counter;
    setDataSource(temp);
  };

  const zeroDataArr = () => {
    let dataArr = [];
    for (let index = 0; index < 7; index++) {
      dataArr.push({
        name: index + 1,
        earning: 0,
      });
    }
    return dataArr;
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={t("OVERVIEW")}
        classTitle="title-red"
        head=""
        //"{
          // <Dropdown
          //   className={styles.dropdown}
          //   classDropdownHead={styles.dropdownHead}
          //   value={sorting}
          //   setValue={setSorting}
          //   options={intervals}
          //   small
          // />
        //}"
      >
        <div className={styles.overview}>
          <div className={styles.list}>
            {dataSource.map((x, index) => (
              <div
                className={styles.item}
                key={index}
                style={{ backgroundColor: x.background }}
              >
                <div className={styles.icon}>
                  <Icon name={x.icon} size="24" />{" "}
                </div>
                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.category}>
                      {t(x.title)}
                      <Tooltip
                        className={styles.tooltip}
                        title="Small description"
                        icon="info"
                        place="right"
                      />
                    </div>
                    <div className={styles.counter}>{x.counter}</div>
                    <div className={styles.indicator}>
                      <Balance className={styles.balance} value={x.value} />
                      <span>{t("THIS_WEEK")}</span>
                    </div>
                  </div>
                  <Chart className={styles.chart} item={x} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
