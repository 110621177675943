import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./InputCapture.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { useTranslation } from "react-i18next";
import { toastError, toastInfo } from "../../../api/toast";
import { sendSms } from "../../../api/login";

const InputCapture = ({
  phoneNumber,
  className,
  classLabel,
  classInput,
  label,
  icon,
  copy,
  currency,
  addonAfter,
  tooltip,
  place,
  onChange,
  index,
  ...props
}) => {
  const { t, i18n } = useTranslation();

  const [capture, setCapture] = useState();
  let countNum = 60;
  const [countDown, setCountDown] = useState(countNum);
  const [timerStatus, setTimerStatus] = useState(false);
  let timer;

  useEffect(() => {
    if (timerStatus) {
      timer = setInterval(() => {
        setCountDown(countDown - 1);
        if (countNum === 0) {
          clearInterval(timer);
          setCountDown(60);
          setTimerStatus(false);
        } else {
          countNum--;
          setCountDown(countNum);
        }
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [timerStatus]);

  const onInputChange = (e) => {
    onChange && onChange(e.target.value);
  };

  const onSendSms = async () => {
    console.log("onSendSms...");

    console.log("phoneNumber:", phoneNumber);

    if (!phoneNumber) {
      toastInfo(t("PHONE_NUMBER_IS_EMPTY"));
    } else {
      toastInfo(t("CODE_SEND_SUCCESS"));
      setTimerStatus(true);
      let pms = {
        phone: phoneNumber,
        language: i18n.language,
      };
      let result = await sendSms(pms);
      console.log("-----sendsms code:", result);
      if (result) {
        setCapture(result.capture);
      }
    }
  };

  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency },
        { [styles.fieldAddonAfter]: addonAfter },
        className
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        <input
          onChange={onInputChange.bind(this)}
          className={cn(classInput, styles.input)}
          {...props}
        />
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} size="24" />{" "}
          </div>
        )}
        {timerStatus ? (
          <div className={styles.actionBtn}>
            {t("RETRY_IN")} : {countDown} {t("SECOND")}
          </div>
        ) : (
          <button className={styles.actionBtn} onClick={onSendSms.bind(this)}>
            {t("SEND_CODE")}
          </button>
        )}
      </div>
    </div>
  );
};

export default InputCapture;
