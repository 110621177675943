import React from "react";
import "./style/userItem.less";
import { useHistory } from "react-router-dom";
import { localizeImg } from "../../constants/constants";
import {goToProfilePage} from "../../utils/utils";
import AvatarBasic from "../common/avatar/AvatarBasic";

export default function CompanyItemBasic(props) {
  const history = useHistory();
  const { detail } = props;

  const onCompanyHome = () => {

    if(detail?.userId && detail?.type){
      const userItem = {
        _id : detail.userId,
        type : detail.type,
      };

      goToProfilePage(userItem, history);
    }

  };

  return (
    <div className="user-item-container" onClick={onCompanyHome.bind(this)}>
      <AvatarBasic 
      cname="avatar" picture={localizeImg(detail?.picture)}
      displayName={detail?.name || 'LO'} ></AvatarBasic>
      <div className="content">
        <div className="content-name">
          <div className="name">{detail?.name}</div>
          <div className="desc">{detail?.signature}</div>
        </div>
      </div>
    </div>
  );
}
