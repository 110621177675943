import React, { useEffect } from "react";
import { MessageRepository, ChannelRepository } from "@amityco/js-sdk";

import MessageList from "../../../chat/components/MessageList";
import MessageComposeBar from "../../../chat/components/MessageComposeBar";
import customizableComponent from "../../../core/hocs/customization";

import ChatHeader from "../../../chat/components/ChatHeader";

import { ChannelContainer } from "./styles";
import { createMessageTranslation } from "../../../../../api/feed";

const channelRepo = new ChannelRepository();

const Chat = ({ channelId, onChatDetailsClick, shouldShowChatDetails }) => {
  useEffect(() => {
    const channelLiveObject = channelRepo.joinChannel({ channelId });

    // TODO call startReading once on join, everytime a new message is received and a message list is scrolled to very bottom
    if (channelLiveObject.model?.membership) {
      channelLiveObject.model.membership.startReading();
    }

    channelLiveObject.on("dataUpdated", (channelModel) => {
      if (!channelModel?.membership) {
        return;
      }

      channelModel.membership.startReading();
    });

    return () => {
      if (channelLiveObject?.model?.membership) {
        channelLiveObject.model.membership.stopReading();
      }

      channelLiveObject.dispose();
    };
  }, [channelId]);

  const sendMessage = async (text) => {
    const liveObject = await MessageRepository.createTextMessage({
      channelId,
      text,
    });
    liveObject.on("dataUpdated", (message) => {
      console.log("message is created", message);

      let pms = {
        type: "chat",
        message_id: message?.messageId,
        source: message?.data?.text,
      };

      createMessageTranslation(pms);
    });
  };

  const sendImageMessage = (fileId) => {
    MessageRepository.createImageMessage({
      channelId,
      imageId: fileId,
    });
  };

  return (
    <ChannelContainer>
      <ChatHeader
        channelId={channelId}
        shouldShowChatDetails={shouldShowChatDetails}
        onChatDetailsClick={onChatDetailsClick}
      />
      <MessageList channelId={channelId} />
      <MessageComposeBar
        onSubmit={sendMessage}
        onSendImageMessage={sendImageMessage}
      />
    </ChannelContainer>
  );
};

export default customizableComponent("Chat", Chat);
