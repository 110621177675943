import React, { useEffect, useState } from "react";
import "./style/languageSelect.less";
import { Dropdown, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import languageIcon from "../../../resource/icons/navbar/language.svg";
import { LanguagesKey } from "../../../constants/languages.enum.ts";
import { useTranslation } from "react-i18next";
import { SYS_ENV_LANGUAGE } from "../../../constants/env.enum";

export default function LanguageSelect(props) {
  let { value } = props;
  const { i18n, t } = useTranslation();
  const [languageKey, setLanguageKey] = useState(i18n.language);
  const [languageName, setLanguageName] = useState();

  useEffect(() => {
    if (value) {
      setLanguageKey(value);
    }
  }, [value]);

  useEffect(() => {
    if (i18n.language !== languageKey) {
      setLanguageKey(i18n.language);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (languageKey) {
      // console.log('-----LanguageSelect languageKey', languageKey);
      setLanguageName(LanguagesKey[languageKey.toUpperCase()]);
    }
  }, [languageKey]);

  const setLanguageItemStyle = (item) => {
    return languageKey === item ? "dropdown-menu-item-selected" : "dropdown-menu-item";
  };

  const onLanguageChange = (event) => {
    const { key } = event;
    setLanguageKey(key);
    i18n.changeLanguage(key);
  };

  const menu_language = (
    <Menu onClick={onLanguageChange.bind(this)}>
      <Menu.Item key="zh">
        <span className={setLanguageItemStyle("zh")}>{LanguagesKey.ZH}</span>
      </Menu.Item>
      <Menu.Item key="en">
        <span className={setLanguageItemStyle("en")}>{LanguagesKey.EN}</span>
      </Menu.Item>
      <Menu.Item key="fr">
        <span className={setLanguageItemStyle("fr")}>{LanguagesKey.FR}</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu_language} trigger={["click"]} placement="bottomCenter">
      <div className="language-select-container">
        {/* <img src={languageIcon} className="language-icon" /> */}
        <span className="language-title">{languageName}</span>
        {/* <CaretDownOutlined style={{ color: "#d9d9d9", fontSize: "8px" }} /> */}
      </div>
    </Dropdown>
  );
}
