import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../contexts/UserIdProvider";
import ListBasic from "../common/list/ListBasic";
import BreadcrumbNormal from "../common/title/BreadcrumbNormal";
import InfiniteScroll from "react-infinite-scroll-component";
import ActivityItem from "./activityItem";
import {
  getActivityList,
  getUserActivities,
  userJoinActivity,
} from "../../api/activity";
import { Col, Row } from "antd";
import "./styles/activities.less";
import { checkLimit } from "../../api/common";
import { RoleLimit } from "../../constants/roleType.enum";
import { useHistory } from "react-router-dom";

export default function Activities() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();
  const [activities, setActivities] = useState([]);
  const [userActivities, setUserActivities] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const pageSize = 20;

  useEffect(() => {
    loadData();
  }, [pageIndex]);

  const initUserActivities = async () => {
    let pms = { coId: user.userInfo.companyId };
    let resultList = await getUserActivities(pms);
    if (resultList && resultList.length > 0) {
      setUserActivities(resultList);
    } else {
      setUserActivities([]);
    }
    return resultList;
  };

  const loadData = async () => {
    setLoading(true);
    let pms = { page: pageIndex, per_page: pageSize };
    let resultList = await getActivityList(pms);
    if (resultList && resultList.length > 0) {
      let list = userActivities;
      if (!list) {
        list = await initUserActivities();
      }
      if (list.length > 0) {
        resultList = resultList.map((item) => {
          let flist = list.filter((f) => f.actId === item._id);
          item.joined = flist.length === 0 ? false : true;
          return item;
        });
      }
      if (pageIndex > 0) {
        setActivities(activities.concat(resultList));
      } else {
        setActivities(resultList);
      }
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const onJoin = async (id) => {
    console.log(user.userInfo);
    if (checkLimit(user.userInfo, RoleLimit.JOIN_ACTIVITY, history)) {
      let pms = {
        userId: user.userId,
        coId: user.userInfo.companyId,
        actId: id,
      };
      await userJoinActivity(pms);
      let list = activities.map((item) => {
        if (item._id === id) {
          item.joined = true;
        }
        return item;
      });
      setActivities(list);
    }
  };

  const onFormatItem = (item, index) => {
    return (
      <div key={index}>
        <ActivityItem data={item} onJoin={onJoin.bind(this)} />
      </div>
    );
  };

  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div className="common-page activities-page">
      <BreadcrumbNormal title={t("SALON")} parent={t("E-COMMERCE")} />
      <div className="common-management">
        <div className="common-management-title">{t("SALON")}</div>
        <div className="common-management-panel">
          <br />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Metus
            vulputate eu scelerisque felis imperdiet proin fermentum leo vel.
            Turpis cursus in hac habitasse. Sed turpis tincidunt id aliquet
            risus. Aliquet sagittis id consectetur purus ut faucibus pulvinar
            elementum integer. Lectus sit amet est placerat in. Sit amet mauris
            commodo quis imperdiet massa. Amet est placerat in egestas erat
            imperdiet. Quis viverra nibh cras pulvinar mattis nunc. Neque vitae
            tempus quam pellentesque nec. Dui id ornare arcu odio ut sem.
            Ultrices tincidunt arcu non sodales neque sodales. In nibh mauris
            cursus mattis molestie a. Massa massa ultricies mi quis hendrerit
            dolor. Massa placerat duis ultricies lacus sed turpis. Tincidunt
            augue interdum velit euismod in pellentesque massa.
          </p>
          <InfiniteScroll
            className="scroll-content"
            dataLength={activities.length}
            next={handleInfiniteOnLoad.bind(this)}
            hasMore={hasMore}
          >
            <Row>
              <Col span={4} className="table-title">
                {t("PHOTO")}
              </Col>
              <Col span={8} className="table-title">
                {t("NAME")}
              </Col>
              <Col span={8} className="table-title">
                {t("DATE")}
              </Col>
              <Col span={4} className="table-title">
                {t("ACTION")}
              </Col>
            </Row>
            <ListBasic
              data={activities}
              renderItem={onFormatItem.bind(this)}
              loading={loading}
            />
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
