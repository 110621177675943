import React, { useState } from "react";
import PropTypes from "prop-types";
import { PostTargetType } from "@amityco/js-sdk";

import withSDK from "../../../core/hocs/withSDK";
import * as utils from "../../../helpers/utils";
import Feed from "../../../social/components/Feed";
import useFollow from "../../../core/hooks/useFollow";
import { Wrapper } from "./styles";

const UserFeed = ({ userId, currentUserId, networkSettings }) => {
  const isPrivateNetwork = utils.isPrivateNetwork(networkSettings);

  const isMe = userId === currentUserId;

  const { isFollowAccepted } = useFollow(currentUserId, userId);
  const isHiddenProfile = isPrivateNetwork && !isFollowAccepted && !isMe;

  return (
    // key prop is necessary here, without it this part will never re-render !!!
    <Wrapper>
      <Feed
        targetType={isMe ? PostTargetType.MyFeed : PostTargetType.UserFeed}
        targetId={"64259fbf160bdf0c55d6168b"}
        targetUserId={userId}
        showPostCreator={false}
        isHiddenProfile={isHiddenProfile}
      />
    </Wrapper>
  );
};

UserFeed.propTypes = {
  userId: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  networkSettings: PropTypes.object.isRequired,
};

export default withSDK(UserFeed);
