import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ResetPassword.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useHistory, useLocation } from "react-router-dom";
import TextInput from "../../core-digital-template/components/TextInput";
import Image from "../../core-digital-template/components/Image";
import VinbipLogo from "../../core-digital-template/components/VinbipLogo";
import { Checkbox } from "antd";
import { toastError, toastInfo } from "../../api/toast";
import { login, resetPasswordByEmail } from "../../api/login";
import { useTranslation } from "react-i18next";
import { Path } from "../../constants/path";
import { statusCode } from "../../constants/statusCode.enum";

export default function ResetPassword() {
  const heightWindow = use100vh();
  const history = useHistory();
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onPasswordChange = (e) => {
    let temp = e;
    setPassword(temp);
  };

  const onConfirmPasswordChange = (e) => {
    let temp = e;
    setConfirmPassword(temp);
  };

  const onFinish = async () => {
    const searchParams = new URLSearchParams(window.location.search);

    const href = window.location.href;

    if (!password) {
      toastError(t("PASSWORD_IS_EMPTY"));
      return;
    }

    if (password != confirmPassword) {
      toastError(t("PASSWORD_DID_NOT_MATCH"));
      return;
    }

    const regex = /setting\/(.*)\/reset-password/;
    const matches = href.match(regex);

    const urlEmail = matches[1];

    let pms = {
      email: urlEmail,
      random: searchParams.get("random"),
      password: password,
    };

    let result = await resetPasswordByEmail(pms);
    if (result && result.statusCode == statusCode.SUCCESS) {
      toastInfo(t("PASSWORD_RESET_SUCCESSFUL"));
      setTimeout(() => {
        history.replace(Path.signIn);
      }, 1000);
    } else {
      toastError(t("SAVE_FAILED"));
    }
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          {/* <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          /> */}
          <VinbipLogo />
        </Link>
        <div className={cn("h2", styles.title)}>{t("RESET_YOUR_PASSWORD")}</div>
        <div className={styles.body}>
          <TextInput
            className={styles.field}
            name="password"
            type="password"
            placeholder={t("PASSWORD")}
            required
            icon="lock"
            onChange={onPasswordChange.bind(this)}
          />
          <TextInput
            className={styles.field}
            name="confirmPassword"
            type="password"
            placeholder={t("CONFIRM_THE_PASSWORD")}
            required
            icon="lock"
            onChange={onConfirmPasswordChange.bind(this)}
          />
          <button
            className={cn("button", styles.button)}
            onClick={onFinish.bind(this)}
          >
            {t("CONFIRM")}
          </button>
        </div>
      </div>
    </div>
  );
}
