import { CacheKey } from '../constants/constants';

const PREFIX = 'Vinbip-';

export function setCache(key: string, value: any) {
    if (typeof value === 'object') {
        value = JSON.stringify(value);
    }
    localStorage.setItem(PREFIX + key, value);
}

export function getCache(key: string) {
    let result = null;
    const value = localStorage.getItem(PREFIX + key);
    if (value) {
        try {
            result = JSON.parse(value);
        } catch (error) {
            result = value;
        }
    }
    return result;
}

export function getCacheBasic(key: string) {
    let result = null;
    const value = localStorage.getItem(key);
    if (value) {
        result = value;
    }
    return result;
}

export function getCacheTokens() {
    return getCache(CacheKey.tokens);
}
