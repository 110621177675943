import React from "react";
import { Space } from "antd";
import { useHistory } from "react-router-dom";
import { subscriptionTips } from "../../../api/toast";
import { useTranslation } from "react-i18next";
import { localizeImg, localizeTitle } from "../../../constants/constants";
import "./style/productItem.less";
import vineDefault from "../../../resource/images/wine-botlle/vin-001.jpeg";
import { getProductPriceRangeText } from "../../../api/product";

export default function ProductItem(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { item, productLimit } = props;
  const productId = item.id || item._id;
  let { title, price, year, alcohol, volumes, currency, tags, bottleImages } =
    item;
  // console.log("-----product item:", item);
  const productThumbnail =
    bottleImages && bottleImages.length > 0
      ? localizeImg(bottleImages[0])
      : vineDefault;

  const getTagStyle = (index) => {
    index++;
    let style = "tag-blue";
    if (index % 2 === 0) {
      style = "tag-yellow";
    } else if (index % 3 === 0) {
      style = "tag-pink";
    }
    return style;
  };

  const toProductDetail = () => {
    if (!productLimit) {
      subscriptionTips(t("YOU_NEED_TO_VERIFY_YOUR_COMPANY_FIRST"), history);
      return;
    }
    history.push("/products/detail/" + productId);
  };

  return (
    <div
      className="product-item-container"
      onClick={toProductDetail.bind(this)}
      key={productId}
    >
      <div>
        <img src={productThumbnail} className="product-image" alt={"Product"} />
      </div>
      <div className="product-detail">
        <div className="detail-title">
          {localizeTitle(i18n.language, title)}
        </div>
        <Space className="detail-desc">
          <span>{year}</span>
          <span>{alcohol}Vol</span>
          {volumes.map((item, index) => (
            <span key={index}>{item.name}ml</span>
          ))}
        </Space>
        <Space className="detail-tags">
          {tags &&
            tags.map((tag, index) => {
              return (
                <div key={index} className={getTagStyle(index)}>
                  {tag}
                </div>
              );
            })}
        </Space>
        {productLimit && (
          <div className="detail-price">
            {getProductPriceRangeText(volumes)}{currency}
          </div>
        )}
        {!productLimit && (
          <div className="detail-price">
            <span className="price-icon">{t("See Price")}</span>
          </div>
        )}
      </div>
    </div>
  );
}
