import { List, Skeleton } from "antd";

export default function ListBasic(props) {
  const { renderItem, loading, data } = props;

  return (
    <List
      itemLayout="vertical"
      size="large"
      dataSource={data}
      loading={loading}
      renderItem={(item, index) => renderItem && renderItem(item, index)}
    >
      {loading && (
        <div style={{ marginTop: '20px' }}>
          <Skeleton avatar={true} active={true} paragraph={{ rows: 1 }} />
          <Skeleton avatar={true} active={true} paragraph={{ rows: 1 }} />
          <Skeleton avatar={true} active={true} paragraph={{ rows: 1 }} />
        </div>
      )}
    </List>
  );
}
