import React, { useEffect, useState } from "react";
import ContactNotify from "./ContactNotify";
import "./style/notify.less";
import { useUserId } from "../../contexts/UserIdProvider";
import { friendApplyPage } from "../../api/contact";
import { useTranslation } from "react-i18next";
import { Menu, List, Row, Col } from "antd";
import FeedListRow from "../feed/FeedListRow";
import commentIcon from "../../resource/icons/left-sider/comment.svg";
import commentSelectedIcon from "../../resource/icons/left-sider/comment_selected.svg";
import useradd from "../../resource/icons/left-sider/new_contact.svg";
import useraddSelected from "../../resource/icons/left-sider/new_contact_selected.svg";
import awardIcon from "../../resource/icons/notify/award.svg";
import bottleIcon from "../../resource/icons/notify/Wine-Bottle.svg";
import meicon from "../../resource/icons/left-sider/me.svg";
import meSelectedicon from "../../resource/icons/left-sider/me_selected.svg";
import {
  receivedCommentPage,
  feedCommentUpdate,
  receivedMentionPage,
  feedMentionUpdate,
} from "../../api/feed";
import InfiniteScroll from "react-infinite-scroll-component";
import { deepClone, getScrollHeight } from "../../utils/utils";
import { useContextData } from "../../contexts/DataProvider";
import RightSiderNotify from "../shared/righ-sider/RightSiderNotify";
import { useHistory } from "react-router-dom";
import discoverIcon from "../../resource/icons/left-sider/discover_icon.png";
import landIcon from "../../resource/icons/left-sider/land.svg";
import storeIcon from "../../resource/icons/left-sider/store.svg";
import storeSelectedIcon from "../../resource/icons/left-sider/store_selected.svg";
import networksIcon from "../../resource/icons/left-sider/contact_book.svg";
import companyIcon from "../../resource/icons/left-sider/company.svg";
import companySelectedIcon from "../../resource/icons/left-sider/company_selected.svg";
import articleIcon from "../../resource/icons/left-sider/article.svg";
import articleSelectedIcon from "../../resource/icons/left-sider/article_selected.svg";
import operationIcon from "../../resource/icons/left-sider/tender_icon.svg";
import operationSelectedIcon from "../../resource/icons/left-sider/tender_icon_selected.svg";
import ratingIcon from "../../resource/icons/notify/rating.svg";
import vip from "../../resource/icons/left-sider/vip.svg";
import supportIcon from "../../resource/icons/left-sider/support_icon.png";
import { articleUpdate, getArticles } from "../../api/article";
import ListBasic from "../common/list/ListBasic";
import ArticleListRow from "../feed/article/ArticleListRow";
import { getCompanyVerifyStatusText } from "../../api/common";
import { awardUpdate, getProductAwards, verifyUpdate } from "../../api/user";
import { CompanyVerifyStatusEnum } from "../../constants/companyVerifyStatus.enum";
import { getUserTenderList, tenderUpdate } from "../../api/tender";
import TenderVerifyItemListRow from "../tender/TenderVerifyItemListRow";
import ProductListItemForNotify from "../shop/product/ProductListItemForNotify";
import {
  getProductRatings,
  loadProducts,
  productUpdate,
  updateProductRating,
} from "../../api/product";
import { TableSourceMaxCount } from "../../constants/constants";
import AwardListRow from "../shop/award/AwardListRow";
import RatingListRow from "../shop/high-rating/RatingListRow";
import { getOrders, updateOrder } from "../../api/orders";
import OrdersListItemForNotify from "../shop/order/OrdersListItemForNotify";
import { VerifyStatusText } from "../../constants/statusCode.enum";
import verifyIngIcon from "../../resource/icons/notify/verify_ing.svg";
import verifySuccessedIcon from "../../resource/icons/notify/verify_successed.svg";
import verifyFailedIcon from "../../resource/icons/notify/verify_failed.svg";

export default function Notify() {
  const { t } = useTranslation();
  const history = useHistory();
  const { isWinery, user } = useUserId();
  const { userInfo } = user;
  const coId = userInfo?.companyId;
  const [company, setCompany] = useState();
  const [companyStatus, setCompanyStatus] = useState(0);
  const {
    notifyMentionNum,
    notifyCommentNum,
    notifyFriendApplyNum,
    notifyArticleNum,
    notifyCompanyVerifyNum,
    notifyTenderNum,
    notifyProductNum,
    notifyProductAwardNum,
    notifyProductRatingNum,
    notifyOrderNum,
    updateNotifyMentionNum,
    updateNotifyCommentNum,
    updateNotifyFriendApplyNum,
    updateNotifyArticlNum,
    updateNotifyCompanyVerifyNum,
    updateNotifyTenderNum,
    updateNotifyProductNum,
    updateNotifyProductAwardNum,
    updateNotifyProductRatingNum,
    updateNotifyOrderNum,
  } = useContextData();
  const MenuTabType = {
    MENTION: "1",
    COMMENT: "2",
    CONTACT: "3",
    ARTICLE: "4",
    ORDER: "5",
    COMPANYVERIFY: "6",
    TENDER: "7",
    PRODUCT: "8",
    PRODUCTAWARD: "9",
    PRODUCTRATING: "10",
  };
  const [currentTab, setCurrentTab] = useState(MenuTabType.MENTION);
  const [mentionFeeds, setMentionFeeds] = useState([]);
  const [mentionTotal, setMentionTotal] = useState(0);
  const [commentFeeds, setCommentFeeds] = useState([]);
  const [commentTotal, setCommentTotal] = useState(0);
  const [friendApplies, setFriendApplies] = useState([]);
  const [friendApplyTotal, setFriendApplyTotal] = useState(0);
  const [articleList, setArticleList] = useState([]);
  const [articleTotal, setArticleTotal] = useState(0);
  const [tenderList, setTenderList] = useState([]);
  const [tenderTotal, setTenderTotal] = useState(0);
  const [productList, setProductList] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [productAwardList, setProductAwardList] = useState([]);
  const [productAwardTotal, setProductAwardTotal] = useState(0);
  const [productRatingList, setProductRatingList] = useState([]);
  const [productRatingTotal, setProductRatingTotal] = useState(0);
  const [orderList, setOrderList] = useState([]);
  const [orderTotal, setorderTotal] = useState(0);
  const [scrollLength, setScrollLength] = useState(0);
  const pageSize = 20;
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const scrollHeight = getScrollHeight(document);

  useEffect(() => {
    if (user && user.userInfo) {
      let { companyInfo } = user.userInfo;
      if (companyInfo && companyInfo.length > 0) {
        setCompany(companyInfo[0]);
        let { status } = companyInfo[0];
        setCompanyStatus(status);
      }
    }
  }, [user]);

  useEffect(() => {
    loadFriendApplys();
    loadCommentFeeds();
    loadMentionFeeds();
    loadArticles();
    loadTenders();
    loadProductList();
    loadProductAwards();
    loadProductRatings();
    loadOrders();
  }, [pageIndex]);

  useEffect(() => {
    switch (currentTab) {
      case MenuTabType.MENTION:
        setScrollLength(mentionTotal);
        setHasMore(mentionTotal > mentionFeeds.length);
        readFeedMention();
        break;
      case MenuTabType.COMMENT:
        setScrollLength(commentTotal);
        setHasMore(commentTotal > commentFeeds.length);
        readFeedComment();
        break;
      case MenuTabType.CONTACT:
        setScrollLength(friendApplyTotal);
        setHasMore(friendApplyTotal > friendApplies.length);
        break;
      case MenuTabType.ARTICLE:
        setScrollLength(articleTotal);
        setHasMore(articleTotal > articleList.length);
        readArticle();
        break;
      case MenuTabType.COMPANYVERIFY:
        setScrollLength(0);
        setHasMore(false);
        readCompanyVerify();
        break;
      case MenuTabType.TENDER:
        setScrollLength(tenderTotal);
        setHasMore(tenderTotal > tenderList.length);
        readTenders();
        break;
      case MenuTabType.PRODUCT:
        setScrollLength(productTotal);
        setHasMore(false);
        readProducts();
        break;
      case MenuTabType.PRODUCTAWARD:
        setScrollLength(productAwardTotal);
        setHasMore(productAwardTotal > productAwardList.length);
        readProductAwards();
        break;
      case MenuTabType.PRODUCTRATING:
        setScrollLength(productRatingTotal);
        setHasMore(productRatingTotal > productRatingList.length);
        readProductRatings();
        break;
      case MenuTabType.ORDER:
        setScrollLength(orderTotal);
        setHasMore(true);
        readOrders();
        break;
      default:
        break;
    }
  }, [currentTab]);

  const onReadedNotify = () => {
    switch (currentTab) {
      case MenuTabType.MENTION:
        updateNotifyMentionNum(0);
        break;
      case MenuTabType.COMMENT:
        updateNotifyCommentNum(0);
        break;
      case MenuTabType.CONTACT:
        updateNotifyFriendApplyNum(
          notifyFriendApplyNum - 1 < 0 ? 0 : notifyFriendApplyNum - 1
        );
        break;
      case MenuTabType.ARTICLE:
        updateNotifyArticlNum(0);
        break;
      case MenuTabType.COMPANYVERIFY:
        updateNotifyCompanyVerifyNum(0);
        break;
      case MenuTabType.TENDER:
        updateNotifyTenderNum(0);
        break;
      case MenuTabType.PRODUCT:
        updateNotifyProductNum(0);
        break;
      case MenuTabType.PRODUCTAWARD:
        updateNotifyProductAwardNum(0);
        break;
      case MenuTabType.PRODUCTRATING:
        updateNotifyProductRatingNum(0);
        break;
      case MenuTabType.ORDER:
        updateNotifyOrderNum(0);
        break;
      default:
        break;
    }
  };

  const readFeedComment = async () => {
    if (notifyCommentNum > 0) {
      for (let i = 0; i < commentFeeds.length; i++) {
        const element = commentFeeds[i];
        let pms = { feedId: element._id, status: 1 };
        await feedCommentUpdate(pms);
      }
      onReadedNotify();
    }
  };

  const readFeedMention = async () => {
    if (notifyMentionNum > 0) {
      for (let i = 0; i < mentionFeeds.length; i++) {
        const element = mentionFeeds[i];
        let pms = { feedId: element._id, status: 1 };
        await feedMentionUpdate(pms);
      }
      onReadedNotify();
    }
  };

  const readArticle = async () => {
    if (notifyArticleNum > 0) {
      for (let i = 0; i < articleList.length; i++) {
        const element = articleList[i];
        let pms = { _id: element._id, isRead: true };
        let result = await articleUpdate(pms);
        console.log("-------article update:", result);
      }
      onReadedNotify();
    }
  };

  const readTenders = async () => {
    if (notifyTenderNum > 0) {
      for (let i = 0; i < tenderList.length; i++) {
        const element = tenderList[i];
        let pms = { _id: element._id, isRead: true };
        let result = await tenderUpdate(pms);
        console.log("-------article update:", result);
      }
      onReadedNotify();
    }
  };

  const readProducts = async () => {
    if (notifyProductNum > 0) {
      for (let i = 0; i < productList.length; i++) {
        const element = productList[i];
        let pms = { ...element, isRead: true };
        let result = await productUpdate(pms);
        console.log("-------productUpdate update:", result);
      }
      onReadedNotify();
    }
  };

  const readOrders = async () => {
    if (notifyOrderNum > 0) {
      for (let i = 0; i < orderList.length; i++) {
        const element = orderList[i];
        let pms = { _id: element._id, isRead: true };
        let result = await updateOrder(pms);
        console.log("-------orderList update:", result);
      }
      onReadedNotify();
    }
  };

  const readProductAwards = async () => {
    if (notifyProductAwardNum > 0) {
      for (let i = 0; i < productAwardList.length; i++) {
        const element = productAwardList[i];
        let pms = { _id: element._id, isRead: true };
        let result = await awardUpdate(pms);
        console.log("-------awardUpdate:", result);
      }
      onReadedNotify();
    }
  };

  const readProductRatings = async () => {
    if (notifyProductRatingNum > 0) {
      for (let i = 0; i < productRatingList.length; i++) {
        const element = productRatingList[i];
        let pms = { _id: element._id, isRead: true };
        let result = await updateProductRating(pms);
        console.log("-------updateProductRating:", result);
      }
      onReadedNotify();
    }
  };

  const readCompanyVerify = async () => {
    if (notifyCompanyVerifyNum > 0) {
      let pms = company
        ? { ...company, isRead: true }
        : {
            _id: userInfo.companyId,
            status: CompanyVerifyStatusEnum.WAITING_VERIFY,
            isRead: true,
          };
      let result = await verifyUpdate(pms);
      console.log("-------verifyUpdate update:", result);
      onReadedNotify();
    }
  };

  const loadFriendApplys = async () => {
    setLoading(true);
    let pms = {
      userId: user.userId,
      page: pageIndex,
      per_page: pageSize,
      status: -1,
    };
    let data = await friendApplyPage(pms);
    if (data && data.total > 0) {
      let { total, result } = data;
      setFriendApplyTotal(total);
      if (pageIndex > 0) {
        let list = deepClone(friendApplies);
        result = list.concat(result);
      }
      setFriendApplies(result);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const loadCommentFeeds = async () => {
    setLoading(true);
    let pms = {
      userId: user.userId,
      page: pageIndex,
      per_page: pageSize,
      status: -1,
    };
    let data = await receivedCommentPage(pms);
    if (data && data.total > 0) {
      let { total, result } = data;
      setCommentTotal(total);
      let list = [];
      if (pageIndex > 0) {
        list = deepClone(commentFeeds);
      }
      result.forEach((item) => {
        let temp = list.filter((a) => a._id === item.feedId);
        if (temp.length === 0) {
          let feedItem = item.feedInfo[0];
          list.push(feedItem);
        }
      });
      setCommentFeeds(list);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const loadMentionFeeds = async () => {
    setLoading(true);
    let pms = {
      userId: user.userId,
      page: pageIndex,
      per_page: pageSize,
      status: -1,
    };
    let data = await receivedMentionPage(pms);
    if (data && data.total > 0) {
      let { total, result } = data;
      setMentionTotal(total);
      let list = [];
      if (pageIndex > 0) {
        list = deepClone(mentionFeeds);
      }
      result.forEach((item) => {
        let temp = list.filter((a) => a._id === item.feedId);
        if (temp.length === 0) {
          if (item.feedInfo[0]) {
            list.push(item.feedInfo[0]);
          }
        }
      });
      setMentionFeeds(list);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const loadArticles = async () => {
    setLoading(true);
    let pms = {
      userId: user.userId,
      page: pageIndex,
      per_page: pageSize,
      isRead: "1",
    };
    let data = await getArticles(pms);
    console.log("--------articles is :", data, pms);
    if (data && data.total > 0) {
      let { total, result } = data;
      setArticleTotal(total);
      let list = result.map((item) => {
        return { ...item, key: item._id };
      });
      if (pageIndex > 0) {
        let list1 = deepClone(articleList);
        list = list1.concat(list);
      }
      setArticleList(list);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const loadTenders = async () => {
    setLoading(true);
    let pms = {
      userId: user.userId,
      page: pageIndex,
      per_page: pageSize,
      isRead: "1",
    };
    let data = await getUserTenderList(pms);
    console.log("--------getUserTenderList :", data, pms);
    if (data && data.total > 0) {
      let { total, result } = data;
      setTenderTotal(total);
      let list = result.map((item) => {
        return { ...item, key: item._id, userInfo: [userInfo] };
      });
      if (pageIndex > 0) {
        let list1 = deepClone(tenderList);
        list = list1.concat(list);
      }
      setTenderList(list);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const loadOrders = async () => {
    setLoading(true);
    let pms = { page: pageIndex, per_page: pageSize, isRead: "1" };
    if (isWinery) {
      pms.coId = coId;
    } else {
      pms.userId = user.userId;
    }
    let result = await getOrders(pms);
    if (result && result.length > 0) {
      let list = result.map((item) => {
        return { ...item, key: item._id };
      });
      if (pageIndex > 0) {
        let list1 = deepClone(orderList);
        list = list1.concat(list);
      }
      setorderTotal(list.length);
      setOrderList(list);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const loadProductAwards = async () => {
    setLoading(true);
    let pms = {
      coId,
      page: pageIndex,
      per_page: pageSize,
      isRead: "1",
    };
    let data = await getProductAwards(pms);
    console.log("--------getProductAwards :", data, pms);
    if (data && data.total > 0) {
      let { total, result } = data;
      setProductAwardTotal(total);
      let list = result.map((item) => {
        return { ...item, key: item._id };
      });
      if (pageIndex > 0) {
        let list1 = deepClone(productAwardList);
        list = list1.concat(list);
      }
      setProductAwardList(list);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const loadProductRatings = async () => {
    setLoading(true);
    let pms = {
      coId,
      page: pageIndex,
      per_page: pageSize,
      isRead: "1",
    };
    let data = await getProductRatings(pms);
    console.log("--------getProductRatings :", data, pms);
    if (data && data.total > 0) {
      let { total, result } = data;
      setProductRatingTotal(total);
      let list = result.map((item) => {
        return { ...item, key: item._id };
      });
      if (pageIndex > 0) {
        let list1 = deepClone(productRatingList);
        list = list1.concat(list);
      }
      setProductRatingList(list);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const loadProductList = async () => {
    setLoading(true);
    let pms = {
      coId,
      page: 0,
      per_page: TableSourceMaxCount,
      isRead: "1",
    };
    let result = await loadProducts(pms);
    console.log("--------loadProducts :", result, pms);
    if (result && result.length > 0) {
      setProductTotal(result.length);
      let list = result.map((item) => item._source);
      // list = list.concat(list);
      setProductList(list);
    }
    setHasMore(false);
    setLoading(false);
  };

  const onFriendApplyHandle = () => {
    onReadedNotify();
    loadFriendApplys();
  };

  const handleClick = (e) => {
    console.log("----------------handleClick:", e);
    setCurrentTab(e.key);
  };

  const pageRefresh = () => {
    setPageIndex(0);
  };

  const onScrollNext = () => {
    setPageIndex(pageIndex + 1);
  };

  const getVerifyResult = (item) => {
    if (item.reason) {
      return (
        <div className="notify-result">
          {t("VERIFY_RESULT")}:
          <span className="notify-reason">{item.reason}</span>
        </div>
      );
    }
  };

  const getCompanyVerifyResult = (companyStatus, company) => {
    console.log(
      "------------------------------getCompanyVerifyResult:",
      company
    );
    if (
      getCompanyVerifyStatusText(companyStatus) ==
      VerifyStatusText.VERIFYSUCCESS
    ) {
      return (
        <div className="notify-panel-content">
          <img src={verifySuccessedIcon} />
          <span className="company_verify_result_title">
            {t("INFORMATION_VERIFY_SUCCESSED")}
          </span>
          <span className="company_verify_result_desc">
            {t("INFORMATION_VERIFY_SUCESSSED_DESC")}
          </span>
        </div>
      );
    } else if (
      getCompanyVerifyStatusText(companyStatus) == VerifyStatusText.VERIFYFAIL
    ) {
      return (
        <div className="notify-panel-content">
          <img src={verifyFailedIcon} />
          <span className="company_verify_result_title">
            {t("INFORMATION_VERIFY_FAILED")}
          </span>
          <span className="company_verify_result_desc">
            {t("INFORMATION_VERIFY_FAILED_DESC")}
            {":"}
            {company}
          </span>
        </div>
      );
    } else {
      return (
        <div className="notify-panel-content">
          <img src={verifyIngIcon} />
          <span className="company_verify_result_title">
            {t("INFORMATION_UNDER_REVIEW")}
          </span>
          <span className="company_verify_result_desc">
            {t("INFORMATION_UNDER_REVIEW_NEED_WAIT")}
          </span>
        </div>
      );
    }
  };

  return (
    <div className="notify-page">
      <div className="notify-left">
        <Menu
          onClick={handleClick.bind(this)}
          selectedKeys={[currentTab]}
          mode="vertical"
          className="normal-shadow"
          style={{
            borderRadius: "8px",
            padding: "10",
            backgroundColor: "#ffffff",
            minHeight: "200px",
            width: "250px",
          }}
        >
          <Menu.Item
            key={MenuTabType.MENTION}
            className={
              "notify-left-title " +
              (currentTab === MenuTabType.MENTION &&
                " notify-left-title-selected")
            }
          >
            <Row align="middle">
              <Col span={3}>
                <img
                  src={
                    currentTab === MenuTabType.MENTION ? meSelectedicon : meicon
                  }
                  className="icon-notify"
                />
              </Col>
              <Col span={20}>{t("ME")}</Col>
              <Col span={1}>{notifyMentionNum > 0 && notifyMentionNum}</Col>
            </Row>
          </Menu.Item>
          <Menu.Item
            key={MenuTabType.COMMENT}
            className={
              "notify-left-title " +
              (currentTab === MenuTabType.COMMENT &&
                " notify-left-title-selected")
            }
          >
            <Row align="middle">
              <Col span={3}>
                <img
                  src={
                    currentTab === MenuTabType.COMMENT
                      ? commentSelectedIcon
                      : commentIcon
                  }
                  className="icon-notify"
                />
              </Col>
              <Col span={20}>{t("COMMENT")}</Col>
              <Col span={1}>{notifyCommentNum > 0 && notifyCommentNum}</Col>
            </Row>
          </Menu.Item>
          <Menu.Item
            key={MenuTabType.CONTACT}
            className={
              "notify-left-title " +
              (currentTab === MenuTabType.CONTACT &&
                " notify-left-title-selected")
            }
          >
            <Row align="middle">
              <Col span={3}>
                <img
                  src={
                    currentTab === MenuTabType.CONTACT
                      ? useraddSelected
                      : useradd
                  }
                  className="icon-notify"
                />
              </Col>
              <Col span={20}>{t("FRIEND_REQUEST")}</Col>
              <Col span={1}>
                {notifyFriendApplyNum > 0 && notifyFriendApplyNum}
              </Col>
            </Row>
          </Menu.Item>
          <Menu.Item
            key={MenuTabType.ARTICLE}
            className={
              "notify-left-title " +
              (currentTab === MenuTabType.ARTICLE &&
                " notify-left-title-selected")
            }
          >
            <Row align="middle">
              <Col span={3}>
                <img
                  src={
                    currentTab === MenuTabType.ARTICLE
                      ? articleSelectedIcon
                      : articleIcon
                  }
                  className="icon-notify"
                />
              </Col>
              <Col span={20}>{t("ARTICLE")}</Col>
              <Col span={1}>{notifyArticleNum > 0 && notifyArticleNum}</Col>
            </Row>
          </Menu.Item>
          <Menu.Item
            key={MenuTabType.ORDER}
            className={
              "notify-left-title " +
              (currentTab === MenuTabType.ORDER &&
                " notify-left-title-selected")
            }
          >
            <Row align="middle">
              <Col span={3}>
                <img
                  src={
                    currentTab === MenuTabType.ORDER
                      ? storeSelectedIcon
                      : storeIcon
                  }
                  className="icon-notify"
                />
              </Col>
              <Col span={20}>{t("SHOP_ORDERS")}</Col>
              <Col span={1}>{notifyOrderNum > 0 && notifyOrderNum}</Col>
            </Row>
          </Menu.Item>
          {!isWinery && (
            <>
              <Menu.Item
                key={MenuTabType.COMPANYVERIFY}
                className={
                  "notify-left-title " +
                  (currentTab === MenuTabType.COMPANYVERIFY &&
                    " notify-left-title-selected")
                }
              >
                <Row align="middle">
                  <Col span={3}>
                    <img
                      src={
                        currentTab === MenuTabType.COMPANYVERIFY
                          ? companySelectedIcon
                          : companyIcon
                      }
                      className="icon-notify"
                    />
                  </Col>
                  <Col span={20}>{t("COMPANY_VERIFICATION")}</Col>
                  <Col span={1}>
                    {notifyCompanyVerifyNum > 0 && notifyCompanyVerifyNum}
                  </Col>
                </Row>
              </Menu.Item>
              <Menu.Item
                key={MenuTabType.TENDER}
                className={
                  "notify-left-title " +
                  (currentTab === MenuTabType.TENDER &&
                    " notify-left-title-selected")
                }
              >
                <Row align="middle">
                  <Col span={3}>
                    <img
                      src={
                        currentTab === MenuTabType.TENDER
                          ? operationSelectedIcon
                          : operationIcon
                      }
                      className="icon-notify"
                    />
                  </Col>
                  <Col span={20}>{t("OPPORTUNITY")}</Col>
                  <Col span={1}>{notifyTenderNum > 0 && notifyTenderNum}</Col>
                </Row>
              </Menu.Item>
            </>
          )}
          {isWinery && (
            <>
              <Menu.Item
                key={MenuTabType.PRODUCT}
                className={
                  "notify-left-title " +
                  (currentTab === MenuTabType.PRODUCT &&
                    " notify-left-title-selected")
                }
              >
                <Row align="middle">
                  <Col span={3}>
                    <img src={bottleIcon} className="icon-notify" />
                  </Col>
                  <Col span={20}>{t("PRODUCT")}</Col>
                  <Col span={1}>{notifyProductNum > 0 && notifyProductNum}</Col>
                </Row>
              </Menu.Item>
              <Menu.Item
                key={MenuTabType.PRODUCTAWARD}
                className={
                  "notify-left-title " +
                  (currentTab === MenuTabType.PRODUCTAWARD &&
                    " notify-left-title-selected")
                }
              >
                <Row align="middle">
                  <Col span={3}>
                    <img src={awardIcon} className="icon-notify" />
                  </Col>
                  <Col span={20}>{t("AWARD")}</Col>
                  <Col span={1}>
                    {notifyProductAwardNum > 0 && notifyProductAwardNum}
                  </Col>
                </Row>
              </Menu.Item>
              <Menu.Item
                key={MenuTabType.PRODUCTRATING}
                className={
                  "notify-left-title " +
                  (currentTab === MenuTabType.PRODUCTRATING &&
                    " notify-left-title-selected")
                }
              >
                <Row align="middle">
                  <Col span={3}>
                    <img src={ratingIcon} className="icon-notify" />
                  </Col>
                  <Col span={20}>{t("RATING")}</Col>
                  <Col span={1}>
                    {notifyProductRatingNum > 0 && notifyProductRatingNum}
                  </Col>
                </Row>
              </Menu.Item>
            </>
          )}
        </Menu>
        <div className="left-sider-container">
          <div
            className="sider-item links-item normal-shadow"
            style={{ paddingBottom: "0px" }}
          >
            <div className="sider-title">
              <span className="title">{t("QUICK_NAVIGATION")}</span>
            </div>
            <div className="sider-content">
              {isWinery && (
                <div
                  className="sider-link-list-item"
                  onClick={() => {
                    history.push("/subscription-logged");
                  }}
                >
                  <img src={vip} className="icon" />
                  <span className="title">{t("SUBSCRIBE_NOW")}</span>
                </div>
              )}
              {!isWinery && (
                <div
                  className="sider-link-list-item"
                  onClick={() => {
                    history.push("/shop/apply");
                  }}
                >
                  <img src={vip} className="icon" />
                  <span className="title">{t("APPLY_AS_SUPPLIER")}</span>
                </div>
              )}
              <div
                className="sider-link-list-item"
                onClick={() => {
                  history.push("/contacts");
                }}
              >
                <img src={networksIcon} className="icon" />
                <span className="title">{t("MY_CONTACT")}</span>
              </div>
              {/**
              <div
                className="sider-link-list-item"
                onClick={() => {
                  history.push("/find-friends");
                }}
              >
                <img src={peopleFind} className="icon" />
                <span className="title">{t("FIND_FRIEND")}</span>
              </div>
              */}
              <div
                className="sider-link-list-item"
                onClick={() => {
                  history.push("/discover-deprecated");
                }}
              >
                <img src={discoverIcon} className="icon" />
                <span className="title">
                  {t(isWinery ? "DISCOVER" : "PRODUCT")}
                </span>
              </div>
              {/*
              {!isWinery && (
                <div
                  className="sider-link-list-item"
                  onClick={() => {
                    history.push("/tender/create");
                  }}
                >
                  <img src={landIcon} className="icon" />
                  <span className="title">{t("OPPORTUNITY")}</span>
                </div>
              )}

               {!isWinery && (
                <div
                  className="sider-link-list-item"
                  onClick={() => {
                    history.push("/products/list");
                  }}
                >
                  <img src={productOncIcon} className="icon" />
                  <span className="title">{t("PRODUCT")}</span>
                </div>
              )} */}
              <div
                className="sider-link-list-item"
                onClick={() => {
                  window.open("https://vinbip.crisp.help/en/");
                }}
              >
                <img src={supportIcon} className="icon" />
                <span className="title">{t("SUPPORT_CENTER")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="notify-right-container">
        <InfiniteScroll
          className="scroll-content"
          height={scrollHeight}
          dataLength={scrollLength}
          next={onScrollNext.bind(this)}
          hasMore={hasMore}
        >
          {currentTab === MenuTabType.MENTION && (
            <div className="feed-notify">
              <List
                itemLayout="vertical"
                size="large"
                loading={loading}
                dataSource={mentionFeeds}
                renderItem={(item, index) => (
                  <div key={index} className="feed-list-item-container ">
                    <FeedListRow
                      data={item}
                      onRefresh={pageRefresh.bind(this)}
                    />
                  </div>
                )}
              ></List>
            </div>
          )}
          {currentTab === MenuTabType.COMMENT && (
            <div className="feed-notify">
              <List
                itemLayout="vertical"
                size="large"
                loading={loading}
                dataSource={commentFeeds}
                renderItem={(item, index) => (
                  <div key={index} className="feed-list-item-container">
                    <FeedListRow
                      data={item}
                      onRefresh={pageRefresh.bind(this)}
                    />
                  </div>
                )}
              ></List>
            </div>
          )}
          {currentTab === MenuTabType.CONTACT && (
            <div
              className={
                friendApplies.length > 0
                  ? "notify-right normal-shadow-white contact-notify-panel"
                  : "feed-notify normal-shadow-white contact-notify-panel"
              }
            >
              <List
                itemLayout="vertical"
                size="large"
                loading={loading}
                dataSource={friendApplies}
                renderItem={(item, index) => (
                  <div key={index}>
                    <ContactNotify
                      item={item}
                      onRefresh={onFriendApplyHandle.bind(this)}
                    />
                  </div>
                )}
              ></List>
            </div>
          )}
          {currentTab === MenuTabType.ARTICLE && (
            <div
              className={
                articleList.length > 0 ? "notify-right" : "feed-notify"
              }
            >
              <ListBasic
                data={articleList}
                renderItem={(item, index) => (
                  <div
                    key={index}
                    className="notify-item normal-shadow-generic"
                  >
                    <ArticleListRow data={item} notify={true} />
                    {getVerifyResult(item)}
                  </div>
                )}
                loading={loading}
              />
            </div>
          )}
          {currentTab === MenuTabType.TENDER && (
            <div
              className={
                articleList.length > 0 ? "notify-right" : "feed-notify"
              }
            >
              <ListBasic
                data={tenderList}
                renderItem={(item, index) => (
                  <div key={index} className="notify-item">
                    <TenderVerifyItemListRow data={item} notify={true} />
                    {getVerifyResult(item)}
                  </div>
                )}
                loading={loading}
              />
            </div>
          )}
          {currentTab === MenuTabType.PRODUCT && (
            <div className="feed-notify">
              <ListBasic
                data={productList}
                renderItem={(item, index) => (
                  <div key={index}>
                    <ProductListItemForNotify item={item} notify={true} />
                    {getVerifyResult(item)}
                  </div>
                )}
                loading={loading}
              />
            </div>
          )}
          {currentTab === MenuTabType.PRODUCTAWARD && (
            <div
              className={
                productAwardList.length > 0 ? "notify-right" : "feed-notify"
              }
            >
              <ListBasic
                data={productAwardList}
                renderItem={(item, index) => (
                  <div key={index} className="notify-item">
                    <AwardListRow data={item} notify={true} />
                    {getVerifyResult(item)}
                  </div>
                )}
                loading={loading}
              />
            </div>
          )}
          {currentTab === MenuTabType.PRODUCTRATING && (
            <div
              className={
                productRatingList.length > 0 ? "notify-right" : "feed-notify"
              }
            >
              <ListBasic
                data={productRatingList}
                renderItem={(item, index) => (
                  <div key={index} className="notify-item">
                    <RatingListRow data={item} notify={true} />
                    {/* {getVerifyResult(item)} */}
                  </div>
                )}
                loading={loading}
              />
            </div>
          )}
          {currentTab === MenuTabType.ORDER && (
            <div className="feed-notify">
              <ListBasic
                data={orderList}
                renderItem={(item, index) => (
                  <div key={index}>
                    <OrdersListItemForNotify item={item} />
                  </div>
                )}
                loading={loading}
              />
            </div>
          )}
          {currentTab === MenuTabType.COMPANYVERIFY && (
            <div className="feed-notify">
              <div className="notify-panel">
                {t("COMPANY_VERIFICATION_STATUS")}:
                {getCompanyVerifyResult(companyStatus)}
              </div>
              {/* {getVerifyResult(company)} */}
            </div>
          )}
        </InfiniteScroll>
      </div>
      <RightSiderNotify />
    </div>
  );
}
