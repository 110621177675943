import React, { useEffect, useState } from "react";
import styles from "./StockCreate.module.sass";
import Panel from "./Panel";
import TextInput from "../../../../../core-digital-template/components/TextInput";
import cn from "classnames";
import Card from "../../../../../core-digital-template/components/Card";
import Dropdown from "../../../../../core-digital-template/components/Dropdown";
import { useTranslation } from "react-i18next";
import { loadProductsForSelect } from "../../../../../api/product";
import { deepClone } from "../../../../../utils/utils";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { warehouses } from "../../../../../api/warehouse";
import { statusCode } from "../../../../../constants/statusCode.enum";
import { stockCreate, stockUpdate } from "../../../../../api/stocks";

const StockCreate = (props) => {
  const { currentStock, onComplete, onChange } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const [products, setProducts] = useState([]);
  const [productId, setProductId] = useState("");
  const [availableStock, setAvailableStock] = useState(0);
  const [warehouseList, setWarehouseList] = React.useState([]);
  const [stockItem,setStockItem]= useState(currentStock||{});

  useEffect(() => {
    loadProductSelectOptions(user.userInfo.companyId);
    loadWarehouse(user.userInfo.companyId);
  }, []);

  const loadProductSelectOptions = async (coId) => {
    let result = await loadProductsForSelect(coId);
    setProducts(result);
  };

  const loadWarehouse = async () => {
    let pms = { coId: user.userInfo.companyId };
    let data = await warehouses(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      let list = data.result.result;
      if (list && list.length > 0) {
        list = list.map((item, index) => {
          return { key: index, label: item.name, value: item._id };
        });
        setWarehouseList(list);
      }
    }
  };

  const onProductChange = (val) => {
    setProductId(val);
    let item = deepClone(stockItem);
    item.productId = val;
    setStockItem(item);
    // onChange(item);
  };

  const onFieldValueChange = (name, val) => {
    setAvailableStock(val);
    let item = deepClone(stockItem);
    item[name] = val;
    setStockItem(item);
    // onChange(item);
  };

  const onSave = async (val) => {
    let tempPms = deepClone(stockItem);
    let pms = {
      coId: user.userInfo.companyId,
      ...tempPms,
    };
    console.log("pms:", pms);
    let data;
    if (stockItem._id) {
      data = await stockUpdate(pms);
    } else {
      data = await stockCreate(pms);
    }
    console.log("result:", data);
    if (data && data.statusCode === statusCode.SUCCESS) {
      onComplete && onComplete();
    }
  };

  return (
    <>
      <Card className={cn(styles.container, styles.card)}>
        <div className={styles.field}>
          <Dropdown
            className={styles.dropdown_field}
            label={t("PRODUCT")}
            value={stockItem?.productId}
            setValue={onProductChange.bind(this)}
            options={products}
          />
        </div>
        <div className={styles.field}>
          <Dropdown
            className={styles.dropdown_field}
            label={t("WAREHOUSE")}
            value={stockItem?.warehouseId}
            setValue={onFieldValueChange.bind(this, "warehouseId")}
            options={warehouseList}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            label={t("AVAILABLE_STOCK")}
            name="count"
            value={stockItem?.count}
            type="number"
            required
            onChange={onFieldValueChange.bind(this, "count")}
          />
        </div>
        <Panel onSave={onSave.bind(this)} />
      </Card>
    </>
  );
};

export default StockCreate;
