import { toast } from "react-toastify";
import VerifyButton from "../components/common/button/VerifyButton";
import SubscribeButton from "../components/common/button/SubscribeButton";
import EditComInfoButton from "../components/common/button/EditComInfoButton";

export function toastError(msg: string) {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function toastSuccess(msg: string) {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function toastInfo(msg: string) {
  toast.info(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function subscriptionTips(msg:string,history:any){
  toast((<SubscribeButton text={msg} history={history}/>), {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function companyVerifyTips(msg:string,history:any){
  toast((<VerifyButton text={msg} history={history}/>), {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function editCompanyInfoTips(msg:string,history:any){
  toast((<EditComInfoButton text={msg} history={history}/>), {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}







