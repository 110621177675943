import React, { useEffect, useState } from "react";
import { Avatar, Button, Carousel, Input, message } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../../contexts/UserIdProvider";
import nextIcon from "../../../resource/icons/feed/next.svg";
import preIcon from "../../../resource/icons/feed/pre.svg";
import FeedCommentItem from "../comment/FeedCommentItem";
import { deepClone } from "../../../utils/utils";
import { feedCommentCreate, feedComments } from "../../../api/feed";
import { useTranslation } from "react-i18next";
import { BrowseType } from "../../../constants/browseType.enum";
import { browseRecordCreate as browseCreate } from "../../../api/browseRecord";
import "./style/feedDetail.less";
import { localizeImg, localizeValue } from "../../../constants/constants";
import { useHistory } from "react-router-dom";
import { checkLimit } from "../../../api/common";
import { RoleLimit } from "../../../constants/roleType.enum";
import { getCompanyVerifyStatus } from "../../../api/user";
import AvatarBasic from "../../common/avatar/AvatarBasic";
import VideoBasic from "../../common/video/VideoBasic";
import { getCompanyDescFromUserType } from "../../../api/company";
import { toastInfo } from "../../../api/toast";

export default function FeedDetail(props) {
  const { user } = useUserId();
  const { t,i18n } = useTranslation();
  const { data, onLike, onComment } = props;
  const feedId = data._id;
  const { userInfo, images, videos } = data;
  const [currentUser, setCurrentUser] = useState(user?.userInfo);
  const userItem = userInfo && userInfo.length > 0 ? userInfo[0] : {};
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 10;
  const [hasMore, setHasMore] = useState(true);
  let _carousel;
  const history = useHistory();
  const [companyDesc, setCompanyDesc] = useState();

  useEffect(() => {
    addRecords();
  }, [currentUser]);

  useEffect(() => {
    if (user && user.userInfo) {
      setCurrentUser(user.userInfo);
    }
  }, [user]);

  useEffect(() => {
    loadComments();
  }, [pageIndex]);

  useEffect(() => {
    if (userItem && userItem.companyDesc) {
      console.log("------feedDetail:", userItem, data);
      setCompanyDesc(userItem.companyDesc);
    } else {
      initCompanyDesc();
    }
  }, [userItem]);

  const initCompanyDesc = async () => {
    let pms = { coId: userItem.companyId };
    let result = await getCompanyDescFromUserType(pms, userItem.type);
    if (result) {
      setCompanyDesc(result);
    }
  };

  const loadComments = async () => {
    let pms = { feedId, page: pageIndex, per_page: pageSize };
    let result = await feedComments(pms);
    if (result && result.length > 0) {
      if (result.length < pageSize) {
        setHasMore(false);
      }
      result = result.filter(
        (filterItem) => filterItem.userInfo && filterItem.userInfo.length > 0
      );
      setComments(result);
    } else {
      setHasMore(false);
    }
  };

  const addRecords = async () => {
    let pms = {
      userId: user.userId,
      busType: BrowseType.FEED,
      busId: feedId,
    };
    let result = await browseCreate(pms);
  };

  const onSaveComment = async () => {
    if (!checkLimit(currentUser, RoleLimit.FEED, history)) {
      return;
    }
    if (!comment) {
      toastInfo(t("THE_CONTENT_CANNOT_BE_NULL"));
      return;
    }
    let pms = { userId: user.userId, feedId, comment, status: 0 };
    let result = await feedCommentCreate(pms);
    onAddComment(result);
  };

  const onAddComment = (_id) => {
    let newComment = {
      _id,
      userId: user.userId,
      feedId,
      comment: comment,
      userInfo: [user.userInfo],
      createdAt: new Date(),
    };
    let list = deepClone(comments);
    list.push(newComment);
    setComments(list);
    setComment("");

    if (onComment) {
      onComment();
    }
  };

  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  const initCarousel = (e) => {
    _carousel = e;
  };

  const onCarouselNext = () => {
    _carousel.next();
  };

  const onCarouselPre = () => {
    _carousel.prev();
  };

  const onKeyDownchange = (e) => {
    if (e.keyCode == 13) {
      onSaveComment && onSaveComment();
    }
  };

  return (
    <div className="feed-detail-cover">
      <div className="detail-panel">
        {images && images.length > 0 && (
          <div className="detail-left">
            <Carousel
              className="detail-img"
              dots={false}
              ref={initCarousel.bind(this)}
            >
              {images.length > 0 &&
                images.map((item, index) => (
                  <img
                    alt={"Feed image"}
                    key={index}
                    className="detail-img"
                    src={localizeImg(item)}
                  />
                ))}
              {images.length === 0 && (
                <img
                  className="detail-img"
                  src="https://vinexwork.s3.cn-northwest-1.amazonaws.com.cn/bf8b73f0-0305-11ec-9477-83b316802cc0.jpe"
                />
              )}
            </Carousel>
            {images.length > 1 && (
              <img
                className="image-pre"
                src={preIcon}
                onClick={onCarouselPre.bind(this)}
              />
            )}
            {images.length > 1 && (
              <img
                className="image-next"
                src={nextIcon}
                onClick={onCarouselNext.bind(this)}
              />
            )}
          </div>
        )}
        {videos && videos.length > 0 && (
          <div className="detail-left">
            <VideoBasic
              url={localizeImg(videos[0])}
              width="100%"
              height="100%"
            />
          </div>
        )}
        <div className="detail-right">
          <div className="detail-author">
            <AvatarBasic
              cname="detail-author-"
              picture={localizeImg(companyDesc?.avatar)}
              displayName={localizeValue(i18n.language,companyDesc?.companyName) || companyDesc?.displayName}
            ></AvatarBasic>
            <div className="detail-author-info">
              <div className="detail-author-name">
                {localizeValue(i18n.language,companyDesc?.companyName) || companyDesc?.displayName}
              </div>
              {/* <div className="detail-author-desc">{userItem.signature}</div> */}
            </div>
          </div>
          <InfiniteScroll
            className="scroll-content"
            height={452}
            dataLength={comments.length}
            next={handleInfiniteOnLoad.bind(this)}
            hasMore={hasMore}
          >
            {data.content && (
              <FeedCommentItem
                data={data}
                author={userItem._id}
                onLike={onLike.bind(this)}
              />
            )}
            {comments.map((item, index) => (
              <div key={index}>
                <FeedCommentItem data={item} />
              </div>
            ))}
          </InfiniteScroll>
          <div className="detail-comment-panel">
            <AvatarBasic
              cname="detail-comment-avator"
              picture={localizeImg(user.userInfo?.companyDesc?.avatar)}
              displayName={localizeValue(i18n.language,user.userInfo?.companyDesc?.companyName)}
            ></AvatarBasic>
            <Input
              value={comment}
              type="text"
              className="detail-comment-input"
              placeholder={t("ADD_A_COMMENT")}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={(e) => onKeyDownchange(e)}
            />
            <Button
              className="detail-comment-btn"
              onClick={onSaveComment.bind(this)}
            >
              {t("SEND")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
