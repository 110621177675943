import React, { useEffect, useState } from "react";
import { Breadcrumb, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../contexts/UserIdProvider";
import { contactUserFans } from "../../api/contact";
import { useTranslation } from "react-i18next";
import "./style/myContacts.less";
import { Link } from "react-router-dom";
import { statusCode } from "../../constants/statusCode.enum";
import UserItem from "../shared/UserItem";
import { browseRecordVisitedMe } from "../../api/browseRecord";
import { deepClone } from "../../utils/utils";

export default function ReadMine() {
  const { user } = useUserId();
  const { userId } = user;
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 20;

  useEffect(() => {
    loadFans();
  }, []);

  const loadFans = async () => {
    let pms = { userId, page: pageIndex, per_page: pageSize };
    let result = await browseRecordVisitedMe(pms);
    console.log("------visited me :", result, pms);
    if (result && result.length > 0) {
      // result = result.map(item => item.userInfo);
      if (pageIndex > 0) {
        let list = deepClone(dataList);
        result = list.concat(list);
      }
      setDataList(result);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_CONTACT")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("READ_MINE")}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className={
          "myContact-container common-management normal-shadow-padding10-white"
        }
      >
        <div
          className={"common-management-title"}
          onClick={() => setPageIndex(-1)}
        >
          {t("READ_MINE")}
        </div>
        <div className="common-management-panel">
          <InfiniteScroll
            className="scroll-content"
            dataLength={dataList.length}
            next={handleInfiniteOnLoad.bind(this)}
            hasMore={hasMore}
          >
            <List
              className="fensi"
              itemLayout="vertical"
              size="large"
              dataSource={dataList}
              renderItem={(item) => <UserItem detail={item} />}
            >
              {loading && (
                <div>
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                </div>
              )}
            </List>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
