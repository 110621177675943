import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Space, Dropdown, Menu, Modal, Table } from "antd";
import "./style/orderInfo.less";
import { useLocation, useHistory } from "react-router-dom";
import {
  getLogisticsList,
  orderConfirm,
  orderDetail,
  updateOrder,
} from "../../../api/orders";
import { dateFormat } from "../../../utils/date";
import { OrderStatus, statusCode } from "../../../constants/statusCode.enum";
import { toastError, toastInfo } from "../../../api/toast";
import { getNativePayCode } from "../../../api/wxPay";
import { getAliPayForm } from "../../../api/aliPay";
import {
  localizeImg,
  localizeTitle,
  OrderProofFiles,
  PayType,
  ProductCurrencyType,
} from "../../../constants/constants";
import { useUserId } from "../../../contexts/UserIdProvider";
import BankTransfer from "./BankTransfer";
import UploadFileNormal from "../../common/upload/UploadFileNormal";
import { deepClone } from "../../../utils/utils";
import rightIcon from "../../../resource/icons/common/right.svg";
import leftIcon from "../../../resource/icons/common/left.svg";
import backIcon from "../../../resource/icons/dashboard/order/back.svg";
import { formatPhone } from "../../../api/common";
import {
  productDetail,
  loadProductConfig,
  getProductConfigNameById,
} from "../../../api/product";
import { getCountryNameById } from "../../../api/config";
import attachedDocumentIcon from "../../../resource/icons/dashboard/order/attached_document.svg";
import downArrowIcon from "../../../resource/icons/shop/info/down_arrow.svg";

export default function OrderInfo() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();
  const { isWinery, user } = useUserId();

  const [orderId, setOrderId] = useState();
  const [order, setOrder] = useState();
  const [address, setAddress] = useState();
  const [orderProducts, setOrderProducts] = useState([]);
  const [logisticsList, setLogisticsList] = useState([]);
  const [aliPayForm, setAliPayForm] = useState();
  const [processIndex, setProcessIndex] = useState(1);
  const [theOrderStatus, setOrderStatus] = useState(OrderStatus.WaitingForPay);
  const [files, setFiles] = useState([]);
  const [bankTransferModalVisible, setBankTransferModalVisible] =
    useState(false);
  const [firstPaymentVouchers, setFirstPaymentVouchers] = useState([]);
  const orderProgress = useRef();
  const [orderProgressScroll, setOrderProgressScroll] = useState(false);
  const [shippingPoint, setShippingPoint] = useState("");

  useEffect(() => {
    if (state && state.orderId) {
      setOrderId(state.orderId);
    }
  }, []);

  useEffect(() => {
    console.log("-----------orderProgress out:", orderProgress);
    if (orderProgress && orderProgress.current) {
      let outDiv = orderProgress.current;
      console.log(
        "-----------orderProgress in:",
        outDiv.scrollWidth,
        outDiv.clientWidth,
        outDiv.offsetWidth
      );
      if (
        outDiv.scrollWidth > outDiv.clientWidth ||
        outDiv.offsetWidth > outDiv.clientWidth
      ) {
        setOrderProgressScroll(true);
      }
    }
  }, [orderProgress]);

  useEffect(() => {
    if (orderId) {
      loadOrderDetails();
      loadLogistics();
    }
  }, [orderId]);

  useEffect(() => {
    formatProcessIndex(theOrderStatus);
  }, [theOrderStatus]);

  const loadLogistics = async () => {
    let pms = { orderId };
    let list = await getLogisticsList(pms);
    setLogisticsList(list);
  };

  const loadOrderDetails = async () => {
    let pms = { orderId };
    let result = await orderDetail(pms);
    console.log("---------order detail :", result);
    setOrder(result);
    setOrderStatus(result.status);
    if (result.files && result.files.length > 0) {
      setFiles(result.files);
    } else {
      setFiles(OrderProofFiles);
    }
    let { recipientInfo, orderProducts, products } = result;
    if (recipientInfo && recipientInfo.length > 0) {
      let _temp = recipientInfo[0];
      setAddress({
        name: _temp.recipientUser.userName,
        phone: formatPhone(_temp.recipientUser.phone),
        address:
          _temp.recipientAddress.addressLineOne +
          " " +
          _temp.recipientAddress.city +
          " " +
          _temp.recipientAddress.state +
          " " +
          _temp.recipientAddress.country,
        zipcode: _temp.recipientAddress.zipcode,
      });
    }
    if (orderProducts && orderProducts.length > 0) {
      let list = [];
      let configData = await loadProductConfig();
      let { locations, types, classifications } = configData;

      setShippingPoint(
        getCountryNameById(
          locations,
          i18n.language,
          result?.orderProducts[0]?.volume?.shippingAddressInfo?.location
            ?.country
        )
      );

      for (let i = 0; i < orderProducts.length; i++) {
        const item = orderProducts[i];
        let title, image, price, currency;
        let _temp = products.filter((pro) => pro._id === item.productId);
        if (_temp && _temp.length > 0) {
          // console.log("temps  ", _temp);
          title = localizeTitle(i18n.language, _temp[0].title);
          price = item.price;
          currency = _temp[0].currency;
          image =
            _temp[0].bottleImages.length > 0
              ? _temp[0].bottleImages[0]
              : _temp[0].images[0];
        }
        //产品详情
        let pms = { id: item.productId };
        let data = await productDetail(pms);
        if (data && data.statusCode === statusCode.SUCCESS) {
          let productInfo = data.result;
          console.log("--------product info:", productInfo);
          item.year = productInfo.year;
          item.countryName = getCountryNameById(
            locations,
            i18n.language,
            productInfo.country
          );
          item.regionName = getCountryNameById(
            locations,
            i18n.language,
            productInfo.region
          );
          item.typeName = getProductConfigNameById(
            types,
            i18n.language,
            productInfo.types
          );

          item.classificationName = getProductConfigNameById(
            classifications,
            i18n.language,
            productInfo.classifications
          );
        }
        list.push({
          ...item,
          title,
          price,
          image,
          currency,
        });
      }
      console.log("item", list);
      setOrderProducts(list);
    }
  };

  const onUpdateOrder = async (status) => {
    let pms = { _id: orderId, status };
    let result = await updateOrder(pms);
    onRefreshPage(result);
  };

  const onRefreshPage = (result) => {
    if (result) {
      toastInfo(t("ACTION_SUCCESS"));
      loadOrderDetails();
    } else {
      toastError(t("ACTION_FAIL"));
    }
  };

  const formatProcessIndex = (status) => {
    let result = 1;
    switch (status) {
      case OrderStatus.WaitingForPay:
        result = 1;
        break;
      case OrderStatus.Expired:
        result = 2;
        break;
      case OrderStatus.Payed:
        result = 3;
        break;
      case OrderStatus.Refused:
        result = 4;
        break;
      case OrderStatus.Confirmed:
        result = 5;
        break;
      case OrderStatus.Sendout:
        result = 6;
        break;
      case OrderStatus.Transiting:
        result = 7;
        break;
      case OrderStatus.Delivered:
        result = 8;
        break;
      case OrderStatus.Received:
        result = 9;
        break;
      case OrderStatus.Cancelled:
        result = 10;
        break;
      case OrderStatus.Complete:
        result = 11;
        break;
      default:
        break;
    }
    setProcessIndex(result);
  };

  const onWxPay = async () => {
    if (order) {
      let result = await getNativePayCode(order);
      if (result) {
        order.wxcode = result;
        history.push("/order/wxpay", { ...order });
      } else {
        toastError(t("WECHAT_PAY_FAILED"));
      }
    }
  };

  const onAliPay = async () => {
    if (order) {
      let result = await getAliPayForm(order);
      if (result) {
        setAliPayForm(result);
      }
    }
  };

  useEffect(() => {
    if (aliPayForm) {
      let forms = document.forms;
      if (forms && forms.length > 0) {
        forms[0].submit();
      }
    }
  }, [aliPayForm]);

  const onMenuItemClick = (item) => {
    switch (item.key) {
      case "1":
        onWxPay();
        break;
      case "2":
        onAliPay();
        break;
      case "3":
        onBankTransfer();
        break;
      default:
        break;
    }
  };

  const menuPay = (
    <Menu onClick={onMenuItemClick.bind(this)}>
      <Menu.Item key="1">
        <span>{t("WECHAT_PAY")}</span>
      </Menu.Item>
      <Menu.Item key="2">
        <span>{t("PAY_WITH_ALIPAY")}</span>
      </Menu.Item>
      <Menu.Item key="3">
        <span>{t("BANK_TRANSFER")}</span>
      </Menu.Item>
    </Menu>
  );

  const onFilesLoaded = async (fileList) => {
    console.log("---- file list:", fileList);
    setFiles(fileList);
    let pms = { _id: orderId, files: fileList };
    let result = await updateOrder(pms);
    console.log("-----update order:", result, pms);
  };

  const onFileLoaded = async (index, fileList) => {
    console.log("---- file:", fileList);
    let list = deepClone(files);
    list[index].file = fileList.length > 0 ? fileList[0] : null;
    setFiles(list);
    let pms = { _id: orderId, files: list };
    let result = await updateOrder(pms);
    console.log("-----update order:", result, pms);
  };

  const onBankTransferPay = async (fileList) => {
    console.log("---- file list:", fileList);
    setFirstPaymentVouchers(fileList);
    onBankTransferClose();
    // let pms = { orderId, payType: PayType.Bank };
    // if (order.relationId) {
    //   pms = { relationId: order.relationId, payType: PayType.Bank };
    // }
    let pms = {
      _id: orderId,
      firstPaymentVouchers: fileList,
      payType: PayType.Bank,
    };
    let result = await updateOrder(pms);
    // let result = await orderConfirm(pms);
    onRefreshPage(result);
  };

  const onBankTransfer = () => {
    setBankTransferModalVisible(true);
  };

  const onBankTransferClose = () => {
    setBankTransferModalVisible(false);
  };

  const onOrderProgressScrollRight = () => {
    if (orderProgress.current) {
      let outDiv = orderProgress.current;
      outDiv.scrollLeft += 50;
    }
  };

  const onOrderProgressScrollLeft = () => {
    if (orderProgress.current) {
      let outDiv = orderProgress.current;
      outDiv.scrollLeft -= 50;
    }
  };

  const columns = [
    {
      title: t("PRODUCT_NAME"),
      dataIndex: "title",
      key: "createdAt",
      width: "120px",
      render: (val, record) => {
        return <div>{localizeTitle(i18n.language, val)}</div>;
      },
    },
    {
      title: t("ORIGIN_PRICE"),
      dataIndex: "price",
      key: "price",
      width: "120px",
      render: (val, record) => {
        return (
          <div>
            {val}
            {record.currency}
          </div>
        );
      },
    },
    {
      title: t("PRICE_SOLD"),
      dataIndex: "price",
      key: "price",
      width: "120px",
      render: (val, record) => {
        return (
          <div>
            {val}
            {record.currency}
          </div>
        );
      },
    },
    {
      title: t("PURCHASING_BOTTLE"),
      dataIndex: "count",
      key: "count",
      width: "120px",
    },
    {
      title: t("TOTAL_PRICE"),
      dataIndex: "count",
      key: "count",
      width: "120px",
      render: (val, record) => {
        return (
          <div>
            {record.count * record.price}
            {record.currency}
          </div>
        );
      },
    },
    // {
    //   title: t("YEAR"),
    //   dataIndex: "year",
    //   key: "year",
    // },
    // {
    //   title: t("PRICE_MIN"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     if (val && val.length > 0) {
    //       return (
    //         <div>
    //           {val[0].price} {val[0].currency}
    //         </div>
    //       );
    //     } else {
    //       return <div>-</div>;
    //     }
    //   },
    // },
    // {
    //   title: t("PRICE_MAX"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     if (val && val.length > 0) {
    //       return (
    //         <div>
    //           {val[val.length - 1].price} {val[val.length - 1].currency}
    //         </div>
    //       );
    //     } else {
    //       return <div>-</div>;
    //     }
    //   },
    // },
    // {
    //   title: t("SHIPPING_ADDRESS"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     return (
    //       <div>
    //         {val.map((valItem) => {
    //           return <div>{valItem.shippingAddressName}</div>;
    //         })}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: t("STOCK_AVAILABLE"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     let availableStock = 0;
    //     if (val && val.length > 0) {
    //       val.map((valItem) => {
    //         availableStock += parseInt(valItem.stock);
    //       });
    //     }
    //     return (
    //       <div>
    //         {availableStock}
    //         {t("BOTTLES")}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: t("SOLD_VOLUME"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     return <div>0{t("BOTTLES")}</div>;
    //   },
    // },
    // // {
    // //   title: t("TYPE"),
    // //   dataIndex: "typeName",
    // //   key: "typeName",
    // // },
    // {
    //   title: t("CREATE_AT"),
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    // },
    // {
    //   title: t("PRODUCT_STATUS"),
    //   dataIndex: "statusText",
    //   key: "statusText",
    //   // filters: [
    //   //   { text: t("ON_SALE"), value: ProductStatus.STOCK },
    //   //   { text: t("OFF_SALE"), value: ProductStatus.UNSTOCK },
    //   // ],
    //   onFilter: (value, record) => record.status === value,
    //   render: (val, record) => {
    //     if (record.status === ProductStatus.VERIFYFAIL) {
    //       return (
    //         <Badge dot={true} size="default">
    //           <span
    //             className="cursor-pointer"
    //             // onClick={openNotification.bind(this, record.reason)}
    //           >
    //             {val}
    //           </span>
    //         </Badge>
    //       );
    //     } else {
    //       return val;
    //     }
    //   },
    // },
    // {
    //   title: t("ACTION"),
    //   dataIndex: "action",
    //   key: "action",
    //   // width: 280,
    //   align: "center",
    //   // render: (text, record) => getActionColumn(text, record),
    // },
  ];

  const onRemoveAttachedDocument = async (index) => {
    let list = deepClone(files);
    // list[index].file = fileList.length > 0 ? fileList[0] : null;
    list = list.splice(index, 1);
    setFiles(list);
    let pms = { _id: orderId, files: list };
    let result = await updateOrder(pms);
    console.log("-----update order:", result, pms);
  };


  const menu_attached_document = (index) => {
    return (
      <div className="order-info-edit-menu-panel">
        <div
          className="menu-item"
          onClick={onRemoveAttachedDocument.bind(index)}
        >
          {t("DELETE")}
        </div>
      </div>
    );
  };

  return (
    <div className="common-page">
      <div className="order-info-container">
        <div className="navigator-panel">
          <img
            src={backIcon}
            style={{ marginRight: "20px", cursor: "pointer" }}
            onClick={() => history.go(-1)}
          />
          <div className="title">{t("ORDER_MANAGEMENT")}</div>
        </div>
        {order && (
          <div className="order-content">
            <div className="header-panel">
              <div className="order-date">
                {t("ORDER_DATE")}:{dateFormat(order.createdAt, "MM/dd/yyyy")}
              </div>
              <div className="order-info-row">
                <div className="order-info-item">
                  <div className="label">{t("CLIENT")}:</div>
                  <div className="label">{order.userInfo[0].nickName}</div>
                </div>
                <div className="order-info-item">
                  <div className="label">{t("SHIPPING_STATUS")}:</div>
                  <div className="value" style={{ color: "#3E6FA4" }}>
                    {order.shippingStatus ? t(order.shippingStatus) : "-"}
                  </div>
                </div>
              </div>
              <div className="order-info-row">
                <div className="order-info-item">
                  <div className="label">{t("ORDER_NUMBER")}:</div>
                  <div className="value">{order.orderNumber}</div>
                </div>
                <div className="order-info-item">
                  <div className="label">{t("PAYMENT_STATUS")}:</div>
                  <div className="value" style={{ color: "#D55053" }}>
                    {t(order.payStatus)}
                  </div>
                </div>
              </div>
              <div className="order-info-row">
                <div className="order-info-item">
                  <div className="label">{t("SHIPPING_POINT")}:</div>
                  <div className="value">{shippingPoint}</div>
                </div>
                <div className="order-info-item">
                  <div className="label">{t("ORDER_STATUS")}:</div>
                  <div className="value" style={{ color: "#D55053" }}>
                    {t(order.status)}
                  </div>
                </div>
              </div>
            </div>
            <Table
              dataSource={orderProducts}
              columns={columns}
              className="orders-details-table"
              pagination={false}
            />
            <div className="bottom-container">
              {t("TOTAL_AMOUNT")}:{order?.orderPrice?.totalPrice}$
            </div>
            {files.filter((filterItem) => filterItem.file).length > 0 && (
              <div className="attached-document-panel">
                <div className="title-panel">
                  <div className="title">{t("ATTACHED_DOCUMENT")}</div>
                  <img src={attachedDocumentIcon} />
                </div>
                <div className="attached-panel">
                  {files
                    .filter((filterItem) => filterItem.file)
                    .map((fileItem, fileIndex) => {
                      return (
                        <div className="attached-item">
                          <div className="left-part">
                            <div className="banner"></div>
                            <div className="attach-info">
                              <div>
                                {t(fileItem.name)}-{fileItem?.file?.name}
                              </div>
                              <div>
                                {Math.ceil(fileItem?.file?.size / 1000)}
                                {" KB"}
                              </div>
                            </div>
                          </div>
                          <Dropdown
                            overlay={menu_attached_document(fileIndex)}
                            trigger={["click"]}
                          >
                            <img
                              src={downArrowIcon}
                              style={{ cursor: "pointer" }}
                            />
                          </Dropdown>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        )}
        {/* <div className="page-title">{t("ORDER_DETAIL")}</div> */}
        {/* {order && (
          <div className="page-content">
            <div className="page-item">
              <div className="order-status">
                <div>
                  <div className="item-title">{t(theOrderStatus)}</div>
                  <div className="order-no">
                    {t("ORDER_NUMBER")} :{order.orderNumber}
                  </div>
                </div>
                <Space size={10}>
                  {theOrderStatus === OrderStatus.WaitingForPay && !isWinery && (
                    <Dropdown overlay={menuPay} trigger={["click"]}>
                      <Button className="button-primary">{t("PAY")}</Button>
                    </Dropdown>
                  )}
                  {theOrderStatus === OrderStatus.Payed && isWinery && (
                    <Button
                      className="button-orange"
                      onClick={onUpdateOrder.bind(this, OrderStatus.Confirmed)}
                    >
                      {t("CONFIRM_ORDER")}
                    </Button>
                  )}
                  {theOrderStatus === OrderStatus.Payed && isWinery && (
                    <Button
                      className="button-primary"
                      onClick={onUpdateOrder.bind(this, OrderStatus.Refused)}
                    >
                      {t("REFUSE_ORDER")}
                    </Button>
                  )}
                  {theOrderStatus === OrderStatus.Confirmed && isWinery && (
                    <Button
                      className="button-primary"
                      onClick={onUpdateOrder.bind(this, OrderStatus.Sendout)}
                    >
                      {t("SEND_OUT")}
                    </Button>
                  )}
                  {theOrderStatus === OrderStatus.Delivered && !isWinery && (
                    <Button
                      className="button-primary"
                      onClick={onUpdateOrder.bind(this, OrderStatus.Received)}
                    >
                      {t("SIGNED")}
                    </Button>
                  )}
                  {theOrderStatus === OrderStatus.Sendout &&
                    !order.pickService &&
                    !isWinery && (
                      <Button
                        className="button-primary"
                        onClick={onUpdateOrder.bind(this, OrderStatus.Received)}
                      >
                        {t("SIGNED")}
                      </Button>
                    )}
                  <Button
                    className="button-primary margin-left-10"
                    onClick={() => {
                      $crisp.push(["do", "chat:open"]);
                    }}
                  >
                    {t("ASK_VINBIP_FOR_HELP")}
                  </Button>
                </Space>
              </div>
              <div className="order-progress" ref={orderProgress}>
                <div className="progress-item">
                  <div className="progress-banner progress-banner-begin">
                    {t("PLACE_AN_ORDER")}
                  </div>
                  <div className="progress-desc">
                    <div className="progress-desc-text">
                      {dateFormat(order.createdAt, "yyyy-MM-dd HH:mm")}
                    </div>
                    <div className="progress-desc-text">
                      {t("ORDER_CREATED")}
                    </div>
                  </div>
                </div>
                {order.firstPaymentVouchers &&
                  order.firstPaymentVouchers.length > 0 && (
                    <div className="progress-item">
                      <div className="progress-banner">
                        {t("UPLOAD_VOUCHER")}
                      </div>
                      <div className="progress-desc">
                        <div className="progress-desc-text">
                          {dateFormat(
                            order.uploadFirstPaymentVouchersTime,
                            "yyyy-MM-dd HH:mm"
                          )}
                        </div>
                        <div className="progress-desc-text">
                          {t("VERIFICATION_PROCESSING")}
                        </div>
                      </div>
                    </div>
                  )}
                {processIndex === 2 && (
                  <div className="progress-item">
                    <div className="progress-banner">
                      {t(OrderStatus.Expired)}
                    </div>
                    <div className="progress-desc">
                      <div className="progress-desc-text">
                        {dateFormat(order.expireTime, "yyyy-MM-dd HH:mm")}
                      </div>
                      <div className="progress-desc-text">
                        {t("ORDER_EXPIRED")}
                      </div>
                    </div>
                  </div>
                )}
                <div className="progress-item">
                  <div
                    className={
                      "progress-banner " +
                      (processIndex < 3 ? "progress-banner-disabled" : "")
                    }
                  >
                    {t(OrderStatus.Payed)}
                  </div>
                  <div className="progress-desc">
                    <div className="progress-desc-text">
                      {dateFormat(order.payTime, "yyyy-MM-dd HH:mm")}
                    </div>
                    <div className="progress-desc-text">
                      {t("PAYMENT_OF_DEPOSIT_SUCCEED")}
                    </div>
                  </div>
                </div>
                {processIndex === 4 && (
                  <div className="progress-item">
                    <div className="progress-banner">
                      {t(OrderStatus.Refused)}
                    </div>
                    <div className="progress-desc">
                      <div className="progress-desc-text">
                        {dateFormat(order.confirmTime, "yyyy-MM-dd HH:mm")}
                      </div>
                      <div className="progress-desc-text">
                        {t("WINERY_REFUSE_ORDER")}
                      </div>
                    </div>
                  </div>
                )}
                {processIndex >= 5 && (
                  <div className="progress-item">
                    <div className="progress-banner">
                      {t(OrderStatus.Confirmed)}
                    </div>
                    <div className="progress-desc">
                      <div className="progress-desc-text">
                        {dateFormat(order.confirmTime, "yyyy-MM-dd HH:mm")}
                      </div>
                      <div className="progress-desc-text">
                        {t("WINERY_CONFIRM_ORDER")}
                      </div>
                    </div>
                  </div>
                )}
                <div className="progress-item">
                  <div
                    className={
                      "progress-banner " +
                      (processIndex < 6 ? "progress-banner-disabled" : "")
                    }
                  >
                    {t(OrderStatus.Sendout)}
                  </div>
                  <div className="progress-desc">
                    <div className="progress-desc-text">
                      {dateFormat(order.sendoutTime, "yyyy-MM-dd HH:mm")}
                    </div>
                    <div className="progress-desc-text">
                      {t("WINERY_SEND_OUT")}
                    </div>
                  </div>
                </div>
                <div className="progress-item">
                  <div
                    className={
                      "progress-banner " +
                      (processIndex < 7 ? "progress-banner-disabled" : "")
                    }
                  >
                    {t(OrderStatus.Transiting)}
                  </div>
                  <div className="progress-desc">
                    <div className="progress-desc-text">
                      {dateFormat(order.transitTime, "yyyy-MM-dd HH:mm")}
                    </div>
                    <div className="progress-desc-text">
                      {t("PRODUCT_TRANSITING")}
                    </div>
                  </div>
                </div>
                <div className="progress-item">
                  <div
                    className={
                      "progress-banner " +
                      (processIndex < 8 ? "progress-banner-disabled" : "")
                    }
                  >
                    {t(OrderStatus.Delivered)}
                  </div>
                  <div className="progress-desc">
                    <div className="progress-desc-text">
                      {dateFormat(order.deliveredTime, "yyyy-MM-dd HH:mm")}
                    </div>
                    <div className="progress-desc-text">
                      {t("PRODUCT_DELIVERED")}
                    </div>
                  </div>
                </div>
                <div className="progress-item">
                  <div
                    className={
                      "progress-banner " +
                      (processIndex < 9 ? "progress-banner-disabled" : "")
                    }
                  >
                    {t(OrderStatus.Received)}
                  </div>
                  <div className="progress-desc">
                    <div className="progress-desc-text">
                      {dateFormat(order.receivedTime, "yyyy-MM-dd HH:mm")}
                    </div>
                    <div className="progress-desc-text">
                      {t("CUSTOMER_RECEIVED")}
                    </div>
                  </div>
                </div>
                {processIndex >= 10 && (
                  <div className="progress-item">
                    <div className="progress-banner">
                      {t(OrderStatus.Cancelled)}
                    </div>
                    <div className="progress-desc">
                      <div className="progress-desc-text">
                        {dateFormat(order.cancelTime, "yyyy-MM-dd HH:mm")}
                      </div>
                      <div className="progress-desc-text">
                        {t("ORDER_CANCELLED")}
                      </div>
                    </div>
                  </div>
                )}
                <div className="progress-item">
                  <div
                    className={
                      "progress-banner progress-banner-end " +
                      (processIndex < 11 ? "progress-banner-disabled" : "")
                    }
                  >
                    {t(OrderStatus.Complete)}
                  </div>
                  <div className="progress-desc">
                    <div className="progress-desc-text">
                      {dateFormat(order.completeTime, "yyyy-MM-dd HH:mm")}
                    </div>
                    <div className="progress-desc-text">
                      {t("ORDER_FINISHED")}
                    </div>
                  </div>
                </div>
              </div>
              {orderProgressScroll && (
                <div className="order-progress-scroll">
                  <img
                    src={leftIcon}
                    className="scroll-icon"
                    onClick={onOrderProgressScrollLeft.bind(this)}
                  />
                  <img
                    src={rightIcon}
                    className="scroll-icon"
                    onClick={onOrderProgressScrollRight.bind(this)}
                  />
                </div>
              )}
            </div>
            <div className="page-item">
              <div className="item-title">{t("LOGISTIC_INFORMATION")}</div>
              <div className="item-content">
                <Space direction="vertical" size={8}>
                  <div className="item-content-row">
                    <div className="row-title">{t("SHIPPING_METHOD")}:</div>
                    <div className="row-content">
                      {order.pickService
                        ? t("VINBIP_LOGISTIC")
                        : t("SELF_PICK_UP")}
                    </div>
                  </div>
                  {!order.pickService && (
                    <div className="item-content-row">
                      <div className="row-title">{t("PICK_UP_DATE")}:</div>
                      <div className="row-content">
                        {dateFormat(order.pickDate)}
                      </div>
                    </div>
                  )}
                  {order.pickService && (
                    <div className="item-content-row">
                      <div className="row-title">
                        {t("LOGISTIC_TRACKING")}:
                      </div>
                      <Space direction="vertical" size={8}>
                        {logisticsList.map((item, index) => (
                          <div
                            className={
                              index === logisticsList.length - 1
                                ? "row-content row-content-current"
                                : "row-content"
                            }
                            key={index}
                          >
                            {dateFormat(item.time, "yyyy-MM-dd HH:mm:ss")}
                            <span className="margin-left-10">
                              {item.message}
                            </span>{" "}
                          </div>
                        ))}
                      </Space>
                    </div>
                  )}
                </Space>
              </div>
            </div>
            <div className="page-item">
              <div className="item-title">{t("DELIVERY_ADDRESS")}</div>
              {address && (
                <div className="item-content">
                  <Space direction="vertical" size={8}>
                    <div className="item-content-row">
                      <div className="row-title">{t("CARGO_RECEIVER")}:</div>
                      <div className="row-content">{address.name}</div>
                    </div>
                    <div className="item-content-row">
                      <div className="row-title">{t("PHONE_NUMBER")}:</div>
                      <div className="row-content">{address.phone}</div>
                    </div>
                    <div className="item-content-row">
                      <div className="row-title">{t("ZIPCODE")}:</div>
                      <div className="row-content">{address.zipcode}</div>
                    </div>
                    <div className="item-content-row">
                      <div className="row-title">{t("DELIVERY_ADDRESS")}:</div>
                      <div className="row-content">{address.address}</div>
                    </div>
                  </Space>
                </div>
              )}
            </div>
            <div className="page-item">
              <div className="item-title">{t("BUYER_INFORMATION")}</div>
              <div className="item-content">
                <Space direction="vertical" size={8}>
                  <div className="item-content-row">
                    <div className="row-title">{t("BUYER_NAME")}:</div>
                    <div className="row-content">
                      {order.userInfo[0].nickName}
                    </div>
                  </div>
                  {order.userInfo[0].phoneNumber && (
                    <div className="item-content-row">
                      <div className="row-title">{t("PHONE_NUMBER")}:</div>
                      <div className="row-content">
                        {order.userInfo[0].phoneNumber}
                      </div>
                    </div>
                  )}
                  {order.userInfo[0].email && (
                    <div className="item-content-row">
                      <div className="row-title">{t("EMAIL")}:</div>
                      <div className="row-content">
                        {order.userInfo[0].email}
                      </div>
                    </div>
                  )}
                </Space>
              </div>
            </div>
            {!isWinery && order.files && order.files.length > 0 && (
              <div className="page-item">
                <div className="item-title">{t("FILE_INFORMATION")}</div>
                <div className="item-content">
                  {order.files.map((item, index) => (
                    <div key={index}>
                      <a href={localizeImg(item.file)}>{t(item.name)}</a>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {isWinery && processIndex > 3 && (
              <div className="page-item">
                <div className="item-title">{t("FILE_INFORMATION")}</div>
                <div className="item-content">
                   <Space direction="vertical" size={10}>
                    {files.map((item, index) => (
                      <div key={index}>
                        <UploadFileNormal
                          file={item.file}
                          title={item.name}
                          onSuccess={onFileLoaded.bind(this, index)}
                        />
                      </div>
                    ))}
                  </Space>
                </div>
              </div>
            )}
            <div className="order-balance">
              <div className="item-title">{t("PRODUCT_INFORMATION")}</div>
              <div className="item-content">
                {orderProducts.map((item, index) => (
                  <div className="product-item" key={index}>
                    <div className="product-info">
                      <div className="product-image-panel">
                        <img
                          src={localizeImg(item.image)}
                          className="product-image"
                        />
                      </div>
                      <div className="product-name">
                        <p>{item.title}</p>
                        <p className="product-attr">{item.year}</p>
                        <p className="product-attr">{item.typeName}</p>
                      </div>
                    </div>
                    <div>
                      {item.currency === ProductCurrencyType.CNY &&
                        item.currency}{" "}
                      {item.price}{" "}
                      {item.currency !== ProductCurrencyType.CNY &&
                        item.currency}{" "}
                      x {item.count}
                    </div>
                  </div>
                ))}
                <div className="balance-info">
                  <Space direction="vertical" size={8}>
                    <div className="balance-info-item">
                      <div className="balance-title">{t("TOTAL_PRICE")}:</div>
                      <div className="balance-title">
                        {ProductCurrencyType.CNY}{" "}
                        {order.orderPrice.totalPrice.toFixed(2)}
                      </div>
                    </div>
                    {theOrderStatus === OrderStatus.WaitingForPay && !isWinery && (
                      <div className="balance-info-item">
                        <div className="balance-title balance-title-price">
                          {t("AMOUNT_TO_PAY")}:
                        </div>
                        <div className="balance-title balance-title-price balance-value-price">
                          {ProductCurrencyType.CNY}{" "}
                          {order.orderPrice.payPrice.toFixed(2)}
                        </div>
                      </div>
                    )}
                    {theOrderStatus === OrderStatus.WaitingForPay && isWinery && (
                      <div className="balance-info-item">
                        <div className="balance-title balance-title-price">
                          {t("DEPOSIT_AMOUNT")}:
                        </div>
                        <div className="balance-title balance-title-price balance-value-price">
                          {ProductCurrencyType.CNY}{" "}
                          {order.orderPrice.payPrice.toFixed(2)}
                        </div>
                      </div>
                    )}
                    {theOrderStatus !== OrderStatus.WaitingForPay && (
                      <div className="balance-info-item">
                        <div className="balance-title balance-title-price">
                          {t("DEPOSIT_AMOUNT")}:
                        </div>
                        <div className="balance-title balance-title-price balance-value-price">
                          {ProductCurrencyType.CNY}{" "}
                          {order.orderPrice.payPrice.toFixed(2)}
                        </div>
                      </div>
                    )}
                  </Space>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </div>
      <div dangerouslySetInnerHTML={{ __html: aliPayForm }}></div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={bankTransferModalVisible}
        footer={null}
        className="address-create-modal"
        onCancel={onBankTransferClose.bind(this)}
      >
        <BankTransfer
          files={firstPaymentVouchers}
          onComplete={onBankTransferPay.bind(this)}
          onClose={onBankTransferClose.bind(this)}
        />
      </Modal>
    </div>
  );
}
