import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {IRole} from "../../interfaces/IRole";
import {IPageInfo} from "../../interfaces/PageInfo";
import {companyDetail} from "../../api/company";

const role:IRole = {
    _id : '',
    desc: '',
    name : '',
    createdAt : null,
    updatedAt : null,
}

const contact = {
    userId : '',
    contact: '',
    contactPhone : '',
    contactId : '',
    contactIdPath1 : '',
    contactIdPath2 : '',
    user : {
        _id : '',
        email : '',
        firstName : '',
        lastName : '',
        type : '',
        role : role

    }
}

const initialState = {
    companyId: '',
    coName: '',
    coNo: '',
    coRep: '',
    licensePath: "http://www.taognrt.com",
    contact : contact,
    status: 1,
}


export const getCompanyInformation:any = createAsyncThunk(
    'company/getInformation',
    async ({ userId }:any, thunkAPI) => {
        try {

            let result: IPageInfo = await companyDetail(userId) as IPageInfo;

            //Company didn't exist
            if (result.statusCode === 7) {

                /**
                 * The company didnt exist , we keep the initialState
                 */
                return initialState;
             } else if (result.statusCode === 200) {

                return result.result

            } else {
                //  return thunkAPI.rejectWithValue(result);
            }

        } catch (e) {
            thunkAPI.rejectWithValue(e );
        }
    }
);


export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        //Reducer comes here
    },
    extraReducers:{
        [getCompanyInformation.fulfilled]: (state, {payload}) => {

            state.companyId = payload._id ? payload._id : '';
            state.coName = payload.coName ? payload.coName : '';
            state.coNo = payload.coNo ? payload.coNo : '';
            state.coRep = payload.coRep ? payload.coRep : '';
            state.licensePath = payload.licensePath ? payload.licensePath : '';
            state.contact = payload.contact ? payload.contact : '';
            state.status = payload.status ? payload.status : '';

            return state;
        },
    }
})


export const companySelector = (state:any) => state.company;
