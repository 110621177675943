import React, { useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import Truncate from "react-truncate-markup";
import { findChunks } from "../../../helpers/utils";
import MentionHighlightTag from "../../../core/components/MentionHighlightTag";
import ChunkHighlighter from "../../../core/components/ChunkHighlighter";
import Linkify from "../../../core/components/Linkify";
import { CommentContent, ReadMoreButton } from "./styles";
import { useTranslation } from "react-i18next";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { TranslationContainer } from "../post/Post/styles";

const COMMENT_MAX_LINES = 8;

const CommentText = ({
  text,
  className,
  mentionees,
  maxLines = COMMENT_MAX_LINES,
  translationInfo,
}) => {
  const { t, i18n } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const chunks = useMemo(() => findChunks(mentionees), [mentionees]);

  const [transStatus, setTransStatus] = useState(false);

  const expand = () => setIsExpanded(true);

  const textContent = text && (
    <CommentContent data-qa-anchor="comment-content" className={className}>
      <Truncate.Atom>
        <ChunkHighlighter
          textToHighlight={text}
          chunks={chunks}
          highlightNode={(props) => (
            <MentionHighlightTag {...props} mentionees={mentionees} />
          )}
          unhighlightNode={Linkify}
        />
      </Truncate.Atom>
    </CommentContent>
  );

  if (isExpanded) return textContent;

  const onTranslateMessage = async () => {
    setTransStatus(!transStatus);
  };

  return (
    <Truncate.Atom
      lines={maxLines}
      ellipsis={
        <ReadMoreButton onClick={expand}>
          <FormattedMessage id="comment.readmore" />
        </ReadMoreButton>
      }
    >
      {textContent}
      <TranslationContainer onClick={onTranslateMessage.bind(this)}>
        {transStatus ? (
          <UpOutlined style={{ fontSize: "10px", marginRight: "10px" }} />
        ) : (
          <DownOutlined style={{ fontSize: "10px", marginRight: "10px" }} />
        )}
        {t("SHOW_ORIGINAL")}
      </TranslationContainer>
      {transStatus && (
        <div
          className="feed-trans-content"
          style={{ marginBottom: "24px" }}
          dangerouslySetInnerHTML={{ __html: translationInfo?.source }}
        ></div>
      )}
    </Truncate.Atom>
  );
};

export default CommentText;
