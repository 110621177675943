import { Select, Cascader } from "antd";
import "./style/addressInput.less";
import { useTranslation } from "react-i18next";
import { formatLocationForSelect, getCountries } from "../../../../api/config";
import { useEffect, useState } from "react";
import { chinaCityList } from "../../../../constants/area-data-min";
import { constants, deepClone } from "../../../../utils/utils";
import AddressSelect from "../addressSelect/AddressSelect";
import { SysEnvLanguageType } from "../../../../constants/env.enum";

export default function AddressInput(props) {
  const { t, i18n } = useTranslation();
  const { data, label, value, index, title, onChange, disabled, language } =
    props;

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);

  const [showCityCascader, setShowCityCascader] = useState(true);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);
  const [address, setAddress] = useState("");

  const [addressItemInfo, setAddressItemInfo] = useState({
    name: "address",
    label:
      language != SysEnvLanguageType.EN
        ? "DETAIL_ADDRESS"
        : "DETAIL_ADDRESS_ENGLISH",
  });

  useEffect(() => {
    if (value && value.city) {
      setSelectedCity(value.city);
    }
  }, [value]);

  useEffect(() => {
    setCurrentLanguage(
      language != SysEnvLanguageType.EN ? i18n.language : "en"
    );
  }, [i18n.language]);

  useEffect(() => {
    initLocatioinData();
  }, [currentLanguage]);

  const initLocatioinData = async () => {
    let list = await getCountries();
    list = formatLocationForSelect(list, currentLanguage);

    setCountryData(list);
    setCityData(chinaCityList);
  };

  const onCountryChange = (val) => {
    console.log("-------------", val);

    if (val == constants.chinaCountryId) {
      setShowCityCascader(true);
    } else {
      setSelectedCity("");
      setShowCityCascader(false);
    }

    setSelectedCountry(val);
    onChange(
      {
        country: val,
        city: selectedCity,
        address: address,
      },
      index
    );
  };

  const onCityChange = (e) => {
    console.log(e);
    setSelectedCity(e);
    onChange(
      {
        country: selectedCountry,
        city: e,
        address: address,
      },
      index
    );
  };

  const onAddressChange = (e) => {
    console.log(e);
    setAddress(e);
    onChange(
      {
        country: selectedCountry,
        city: selectedCity,
        address: e,
      },
      index
    );
  };

  return (
    <div className="address-input-container">
      <div className="input-title">
        <span
          style={{
            color: "#333333",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {language != SysEnvLanguageType.EN
            ? t("COUNTRY")
            : t("COUNTRY_ENGLISH")}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Select
          className="select-basic"
          value={value?.country}
          placeholder={t(label)}
          options={countryData}
          disabled={disabled}
          onChange={onCountryChange.bind(this)}
          style={{
            width: "30% !important",
          }}
        ></Select>
        {showCityCascader && (
          <Cascader
            value={selectedCity}
            placeholder={t("SELECT_PROVINCE_CITY")}
            options={chinaCityList}
            disabled={disabled}
            onChange={onCityChange.bind(this)}
            className="city-select"
            style={{
              width: "69%",
              marginTop: "8px",
            }}
          />
        )}
      </div>
      <div
        style={{
          marginTop: "18px",
        }}
      >
        <AddressSelect
          {...addressItemInfo}
          language={language}
          country={selectedCountry}
          index={index}
          disabled={disabled}
          onChange={onAddressChange.bind(this)}
          // onChange={onControlChange.bind(this)}
        />
      </div>
    </div>
  );
}
