import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Input, Button, message, Modal, Divider, Checkbox } from "antd";
import { useAuth } from "../../contexts/AuthProvider";
import { useUserId } from "../../contexts/UserIdProvider";
import { LoginType, WxAppId, WxAppSecret } from "../../constants/constants";
import loginBg from "../../resource/images/login/login_bg1.png";
import phonelogin from "../../resource/icons/user/phonelogin.svg";
import passwordlogin from "../../resource/icons/user/passwordlogin.svg";
import userPrefixIcon from "../../resource/icons/user/login/user_prefix.svg";
import passwordPrefixIcon from "../../resource/icons/user/login/password_prefix.svg";
import seePasswordIcon from "../../resource/icons/user/login/see_password.svg";
import companyIcon from "../../resource/icons/user/reg/company.svg";
import emailIcon from "../../resource/icons/user/reg/email.svg";
import phoneIcon from "../../resource/icons/user/reg/phone.svg";
import wechat from "../../resource/icons/user/login/wechat.svg";
import { getTokenExpireTime } from "../../utils/jwt";
import { setBearerToken } from "../../api/api";
import { browseRecordCreate as browseCreate } from "../../api/browseRecord";
import { BrowseType } from "../../constants/browseType.enum";
import {
  getWxAccessToken,
  getWxUserInfo,
  login,
  wxLogin,
  loginByPhone,
  sendSms,
} from "../../api/login";
import { createUser, userExists } from "../../api/user";
import { useTranslation } from "react-i18next";
import { toastError, toastInfo } from "../../api/toast";
import { isEmail } from "../../utils/regex";
// import GoogleLogin from "./GoogleLogin";
// import FacebookLogin from "@greatsumini/react-facebook-login";
import "./style/Reg.less";
// import WxLogin from "./WxLogin";
import { statusCode } from "../../constants/statusCode.enum";
import InputPhoneNumberSimple from "../common/input/InputPhoneNumberSimple";
import { SysEnvLanguageType, SYS_ENV_LANGUAGE } from "../../constants/env.enum";
import LanguageSelect2 from "../common/languages/LanguageSelect2";
import { deepClone } from "../../utils/utils";

const qs = require("query-string");

export default function Reg() {
  const { t } = useTranslation();
  const history = useHistory();
  const { state, search } = useLocation();
  const { createToken } = useAuth();
  const { loadUserInfo } = useUserId();
  const [referUrl, setReferUrl] = useState("/discover-deprecated");
  const [type, setType] = useState(
    SYS_ENV_LANGUAGE === SysEnvLanguageType.ZH ? "phone" : "email"
  );
  const [account, setAccount] = useState();
  const [timerStatus, setTimerStatus] = useState(false);
  let countNum = 60;
  const [countDown, setCountDown] = useState(countNum);

  const [formData, setFormData] = useState({
    type: state?.type,
  });
  const [emailExists, setEmailExists] = useState(false);
  const [phoneExists, setPhoneExists] = useState(false);

  const [termsAgreed, setTermsAgreed] = useState(false);

  let timer;

  useEffect(() => {
    console.log("state:", state);
    if (state) {
    }
  }, []);

  useEffect(() => {
    if (timerStatus) {
      timer = setInterval(() => {
        setCountDown(countDown - 1);
        if (countNum === 0) {
          clearInterval(timer);
          setCountDown(60);
          setTimerStatus(false);
        } else {
          countNum--;
          setCountDown(countNum);
        }
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [timerStatus]);

  /**
   * callback
   * @param {string} result :server return(包含token和userid)
   */
  const onStoreUser = async (result) => {
    let { access_token, user } = result;
    let userId = user._id;

    await setBearerToken(access_token);

    let expireTime = getTokenExpireTime();
    createToken({ value: access_token, expireTime });
    await loadUserInfo(userId);
    addLoginRecord(userId);
    history.replace(referUrl);
  };

  const addLoginRecord = (userId) => {
    let pms = {
      userId,
      busType: BrowseType.LOGIN,
    };
    browseCreate(pms);
  };

  const onCheckEmailExists = async (e) => {
    let val = e.target.value;
    if (val && isEmail(val)) {
      let pms = { email: val };
      let result = await userExists(pms);
      if (result.statusCode !== statusCode.SUCCESS) {
        toastError(t("EMAIL_ALREADY_EXIST"));
        setEmailExists(true);
      } else {
        setEmailExists(false);
      }
    } else {
      toastError(t("EMAIL_FORMAT_ERROR"));
      setEmailExists(false);
    }
  };

  const onCheckPhoneExists = async (e) => {
    let val = e.target.value;
    if (val) {
      let pms = { phone: val };
      let result = await userExists(pms);
      if (result.statusCode !== statusCode.SUCCESS) {
        toastError(t("PHONE_ALREADY_EXIST"));
        setPhoneExists(true);
      } else {
        setPhoneExists(false);
      }
    }
  };

  const onFinish = async () => {
    console.log("------form data:", formData);

    if (!termsAgreed) {
      toastError(t("NEED_AGREE_USER_TERMS"));
      return;
    }

    if (!formData.firstName || !formData.lastName) {
      toastError(t("ACCOUNT_IS_EMPTY"));
      return;
    }
    if (!formData.password) {
      toastError(t("PASSWORD_IS_EMPTY"));
      return;
    }

    if (!formData.email) {
      toastError(t("EMAIL_ERROR_MSG"));
      return;
    }

    if (!formData.capture) {
      toastError(t("INPUT_CAPTURE_CODE"));
      return;
    }

    if (!formData.phoneNumber) {
      toastError(t("INVALID_PHONE_NUMBER"));
      return;
    }

    if (emailExists) {
      toastError(t("EMAIL_ALREADY_EXIST"));
      return;
    }

    if (phoneExists) {
      toastError(t("PHONE_ALREADY_EXIST"));
      return;
    }

    let temp = deepClone(formData);

    if (temp.phoneNumber) {
      temp.phone = {
        code: "86",
        phone: temp.phoneNumber,
      };
    }

    temp.name = temp.lastName + temp.firstName;

    let resp = await createUser(temp);
    if (resp) {
      let flag = resp.statusCode;
      switch (flag) {
        case statusCode.SUCCESS:
          toastInfo(t("SAVE_SUCCESS"));
          onLogin(temp.email, temp.password);
          break;
        case statusCode.EmailExists:
          toastInfo(t("EMAIL_ALREADY_EXIST"));
          break;
        case statusCode.PhoneExists:
          toastInfo(t("PHONE_ALREADY_EXIST"));
          break;
        case statusCode.CreateIMUserFailed:
          toastInfo(t("CREATE_IMUSER_ERROR"));
          break;
        case statusCode.Failed:
          toastInfo(t("SAVE_FAILED"));
          break;
        default:
          break;
      }
    } else {
      toastError(t("SAVE_FAILED"));
    }
  };

  const onLogin = async (email, pwd) => {
    const pmsLogin = { email, password: pwd };
    let result = await login(pmsLogin);
    if (result) {
      const { access_token, user } = result;
      const userId = user._id;
      await setBearerToken(access_token);

      const expireTime = getTokenExpireTime();
      createToken({ value: access_token, expireTime });
      await loadUserInfo(userId);
      addLoginRecord(userId);
      history.replace("/profile-deprecated/" + userId);
    }
  };

  const onInputValueChange = async (name, e) => {
    let temp = deepClone(formData);
    temp[name] = e.target.value;
    setFormData(temp);
  };

  const onSendSms = async () => {
    if (!formData.phoneNumber) {
      toastInfo(t("PHONE_NUMBER_IS_EMPTY"));
    } else if (isNaN(parseInt(formData.phoneNumber))) {
      toastError(t("INVALID_PHONE_NUMBER"));
      return;
    } else {
      toastInfo(t("CODE_SEND_SUCCESS"));
      setTimerStatus(true);
      let pms = {
        phone: formData.phoneNumber, //'+' + phone?.code +
        smsType: "Login",
      };
      await sendSms(pms);
    }
  };

  const onAgreeTermsChange = (e) => {
    console.log("-------onAgreeTermsChange:", e.target.checked);
    setTermsAgreed(e.target.checked);
  };

  const inputPrefix = (prefixIcon) => {
    return (
      <div
        style={{
          width: "39px",
          height: "32px",
          display: "flex",
          position: "relative",
        }}
      >
        <img
          src={prefixIcon}
          style={{
            width: "18px",
            height: "18px",
            marginLeft: "10px",
            marginTop: "7px",
          }}
        ></img>
        <div
          style={{
            width: "1px",
            height: "18px",
            backgroundColor: "#D9D9D9",
            position: "absolute",
            right: "0px",
            top: "7px",
          }}
        ></div>
      </div>
    );
  };

  const inputPrefixEmpty = () => {
    return (
      <div
        style={{
          width: "4px",
          height: "32px",
          display: "flex",
          position: "relative",
        }}
      ></div>
    );
  };

  return (
    <div className="reg1-page">
      <img className="reg-bg-img" src={loginBg} />
      <div className="reg-bg-container">
        <div className="reg-content">
          <div className="lang-panel">
            <LanguageSelect2 />
          </div>
          <div className="reg-left-panel">
            <img src={loginBg} className="reg-left-img" />
            <div className="reg-left-content">
              <div className="reg-left-title">
                <div>WINE B2B</div>
                <div>TRADING</div>
                <div>NETWORKING</div>
              </div>
            </div>
          </div>
          <div className="reg-right">
            <div className="reg-content-wrapper">
              <div className="reg-tips-panel">
                <div className="reg-tips">Vinbip</div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "65%",
                }}
              >
                <div
                  className="phone-panel"
                  style={{
                    marginRight: "10px",
                  }}
                >
                  <div className="phone-input-before">
                    <div className="input-label">
                      {t("LAST_NAME")}
                      <span style={{ marginRight: "5px" }}>:</span>
                    </div>
                    <Input
                      bordered={false}
                      value={formData?.name}
                      prefix={inputPrefix(userPrefixIcon)}
                      className="reg-input"
                      placeholder={t("LAST_NAME")}
                      onInput={onInputValueChange.bind(this, "lastName")}
                      autoComplete="off"
                      name="last_name"
                    />
                  </div>
                </div>
                <div className="phone-panel">
                  <div className="phone-input-before">
                    <div className="input-label">
                      {t("FIRST_NAME")}
                      <span style={{ marginRight: "5px" }}>:</span>
                    </div>
                    <Input
                      bordered={false}
                      value={formData?.name}
                      prefix={inputPrefixEmpty()}
                      className="reg-input"
                      placeholder={t("FIRST_NAME")}
                      onInput={onInputValueChange.bind(this, "firstName")}
                      autoComplete="off"
                      name="first_name"
                    />
                  </div>
                </div>
              </div>
              <div className="phone-panel">
                <div className="phone-input-before">
                  <div className="input-label">
                    {t("COMPANY_NAME")}
                    <span style={{ marginRight: "5px" }}>:</span>
                  </div>
                  <Input
                    bordered={false}
                    value={account}
                    prefix={inputPrefix(companyIcon)}
                    className="reg-input"
                    placeholder={t("PLEASE_INPUT_REAL_INFO")}
                    onInput={onInputValueChange.bind(this, "companyName")}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="phone-panel">
                <div className="phone-input-before">
                  <div className="input-label">
                    {t("EMAIL")}
                    <span style={{ marginRight: "5px" }}>:</span>
                  </div>
                  <Input
                    bordered={false}
                    value={account}
                    className="reg-input"
                    placeholder={t("EMAIL")}
                    prefix={inputPrefix(emailIcon)}
                    onInput={onInputValueChange.bind(this, "email")}
                    onBlur={onCheckEmailExists.bind(this)}
                    autoComplete="new-password"
                  />
                </div>
              </div>
              <div className="phone-panel">
                <div className="phone-input-before">
                  <div className="input-label">
                    {t("PASSWORD")}
                    <span style={{ marginRight: "5px" }}>:</span>
                  </div>
                  <Input.Password
                    bordered={false}
                    className="reg-input"
                    style={{ border: "none" }}
                    placeholder={t("PASSWORD")}
                    onInput={onInputValueChange.bind(this, "password")}
                    prefix={inputPrefix(passwordPrefixIcon)}
                    iconRender={(visible) => <img src={seePasswordIcon} />}
                    autoComplete="new-password"
                  />
                  <Input.Password
                    bordered={false}
                    className="reg-input"
                    style={{ border: "none", display: "none" }}
                    placeholder={t("PASSWORD")}
                    onInput={onInputValueChange.bind(this, "password")}
                    prefix={inputPrefix(passwordPrefixIcon)}
                    iconRender={(visible) => <img src={seePasswordIcon} />}
                  />
                </div>
              </div>
              <div className="capture-panel">
                <div className="input-label">
                  {t("PHONE_NUMBER")}
                  <span style={{ marginRight: "5px" }}>:</span>
                </div>
                <div className="capture-panel-phone">
                  <Input
                    className=" reg-input"
                    type="text"
                    style={{ border: "none", paddingLeft: "10px !important" }}
                    placeholder={t("PHONE_NUMBER")}
                    prefix={inputPrefix(phoneIcon)}
                    onInput={onInputValueChange.bind(this, "phoneNumber")}
                    onBlur={onCheckPhoneExists.bind(this)}
                    autoComplete="off"
                  />
                  {timerStatus ? (
                    <span className="count-down">
                      {t("RETRY_IN")} : {countDown} {t("SECOND")}
                    </span>
                  ) : (
                    <span
                      className="capture-btn"
                      onClick={onSendSms.bind(this)}
                    >
                      {t("SEND_CODE")}
                    </span>
                  )}
                </div>
              </div>
              <div className="phone-panel">
                <div className="phone-input-before">
                  <div className="input-label">
                    {t("CAPTURE_CODE")}
                    <span style={{ marginRight: "5px" }}>:</span>
                  </div>
                  <Input
                    bordered={false}
                    value={account}
                    className="reg-input"
                    placeholder={t("CAPTURE_CODE")}
                    prefix={inputPrefix(phoneIcon)}
                    onInput={onInputValueChange.bind(this, "capture")}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="terms-privacy-checkbox">
                <Checkbox onChange={onAgreeTermsChange.bind(this)}>
                  {t("AGREE_TERMS")}
                </Checkbox>
              </div>
              <Button onClick={onFinish.bind(this)} className="btn-reg">
                {t("SIGN_UP")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
