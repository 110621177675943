import React, { useState } from "react";
import cn from "classnames";
import styles from "./Send.module.sass";
import Icon from "../../../../../core-digital-template/components/Icon";
import Smile from "../../../../../core-digital-template/components/Smile";
import { useTranslation } from "react-i18next";
import UploadBase from "../../../../../components/common/upload/UploadBase";
import imageIcon from "../../../../../resource/icons/instant-messaging/image.svg";
import folderIcon from "../../../../../resource/icons/instant-messaging/folder.svg";
import videoCallIcon from "../../../../../resource/icons/instant-messaging/video_call.svg";
import audioCallIcon from "../../../../../resource/icons/instant-messaging/audio.svg";
import emotionIcon from "../../../../../resource/icons/instant-messaging/emotion.svg";
import { ChatContentsType } from "../../../../../constants/chatContentsType.enum";
import EmotionPanel from "../../../../../components/common/emotion/EmotionPanel";

const Send = ({
  onTextMessage,
  onAddMessage,
  onSendCustomMessage,
  onAudioCall,
  onVideoCall,
}) => {
  const { t } = useTranslation();

  const [textMessage, setTextMessage] = useState("");
  const [emotionPanelVisible, setEmotionPanelVisible] = useState(false);

  const onTextMessageChange = (e) => {
    setTextMessage(e.target.value);
  };

  const onSendClick = () => {
    onTextMessage && onTextMessage(textMessage);
    setTextMessage("");
  };

  const addCustomMsg = (fileType, url, name) => {
    let msg = {
      customExts: { url, fileType: ChatContentsType.IMAGE, name },
      contentsType: ChatContentsType.CUSTOM,
    };
    onAddMessage && onAddMessage(msg);
  };

  const sendImageMessage = (fileType, url, name) => {
    console.log("sendImageMessage:", fileType);
    onSendCustomMessage &&
      onSendCustomMessage(ChatContentsType.IMAGE, url, name);
  };

  const sendVideoMessage = (fileType, url, name) => {
    console.log("sendVideoMessage:", fileType);
    onSendCustomMessage &&
      onSendCustomMessage(ChatContentsType.VIDEO, url, name);
  };

  const onEmotionPress = (emotionItem) => {
    console.log(emotionItem);
    const textMsg = textMessage + emotionItem.name;
    setTextMessage(textMsg);

    setEmotionPanelVisible(!emotionPanelVisible);
  };

  const onAudioCallPress = () => {
    onAudioCall && onAudioCall();
  };

  const onVideoCallPress = () => {
    onVideoCall && onVideoCall();
  };

  return (
    <div className={styles.send}>
      <UploadBase
        onLoading={addCustomMsg.bind(this)}
        onLoaded={sendImageMessage.bind(this)}
      >
        <img src={imageIcon} className={styles.actionIcon} />
      </UploadBase>
      <UploadBase onLoaded={sendVideoMessage.bind(this)}>
        <img src={folderIcon} className={styles.actionIcon} />
      </UploadBase>
      <img
        src={audioCallIcon}
        className={styles.actionIcon}
        onClick={onAudioCallPress.bind(this)}
      />
      <img
        src={videoCallIcon}
        className={styles.actionIcon}
        onClick={onVideoCallPress.bind(this)}
      />
      {/* <div className={styles.file}>
        <input type="file" />
        <Icon name="file-add" size="24" />
      </div>
      <Smile
        className={styles.smile}
        up
        onEmotionClick={onEmotionClick.bind(this)}
      /> */}
      <img
        src={emotionIcon}
        className={styles.actionIcon}
        onClick={() => setEmotionPanelVisible(!emotionPanelVisible)}
      />
      <div className={styles.form}>
        <input
          className={styles.input}
          type="text"
          name="message"
          placeholder="Message"
          required
          value={textMessage}
          onChange={onTextMessageChange.bind(this)}
        />
        <button
          className={cn("button-small", styles.button)}
          onClick={onSendClick.bind(this)}
        >
          {t("SEND")}
        </button>
      </div>
      {emotionPanelVisible && (
        <EmotionPanel onEmotionPress={onEmotionPress.bind(this)} />
      )}
    </div>
  );
};

export default Send;
