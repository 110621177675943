import { actionRequest, formatActionResponse, formatResponse, formatResponseArray } from "./api";
import { RequestType } from "../constants/requestType.enum";
import { formatDefaultArrayForSelect, formatWineCategoryArrayForSelect, requestGetUrlFormat } from "./common";
import apiPath from "./apipath";
import { getCache, setCache } from "../utils/cache";
import { CacheKey } from "../constants/constants";

/**
 *
 * @param pms
 */
export function getUserTenderList(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.tendersUser, pms);
}

/**
 *
 * @param pms
 */
export async function create(pms: any) {
  return await actionRequest(RequestType.POST, apiPath.tenderCreate, pms);
}

/**
 *
 * @param pms
 */
export function getTenderDetail(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.tenderDetail, pms);
}


export async function tenderConfig() {
  let data = await actionRequest(RequestType.GET, apiPath.tenderConfig);
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export function tenderUpdate(pms: any) {
  return actionRequest(RequestType.POST, apiPath.tenderUpdate, pms);
}

/**
 *
 * @param pms
 */
export function remove(pms: any) {
  return actionRequest(RequestType.DELETE, requestGetUrlFormat(apiPath.tenderRemove, pms));
}

/**
 *
 * @param pms
 */
export async function demandOfferPage(pms: any) {
  let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.tenderDemandOffers, pms));
  return formatResponseArray(data);
}

export async function demandOffers(pms: any) {
  let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.tenderDemandOffers, pms));
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function demandOfferCreate(pms: any) {
  return await actionRequest(RequestType.POST, apiPath.tenderDemandOfferCreate, pms);
}

/**
 *
 * @param pms
 */
export async function demandOfferUpdate(pms: any) {
  let data = await actionRequest(RequestType.POST, apiPath.tenderDemandOfferUpdate, pms);
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function demandOfferUpdateStatus(pms: any) {
  let data = await actionRequest(RequestType.POST, apiPath.tenderDemandOfferUpdateStatus, pms);
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function demandOfferRemove(pms: any) {
  let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.tenderDemandOfferCancel, pms));
  return formatResponse(data);
}

export async function loadTenderConfig() {
  let result = getCache(CacheKey.tenderConfig);
  if (!result) {
    let data = await tenderConfig();
    result = formatTenderConfig(data);
    setCache(CacheKey.tenderConfig, result);
  }
  return result;
}

/**
 *
 * @param data
 */
function formatTenderConfig(data: any) {
  let { locations, wineCategory } = data;
  let countries = locations.map((item: any) => ({ label: item.country, value: item.country }));
  locations = locations.map((item: any) => {
    let { classifications, region } = item;
    classifications = formatDefaultArrayForSelect(classifications);
    region = formatDefaultArrayForSelect(region);
    return { ...item, classifications, region };
  });
  let { bottles, capsules, sealed, types, volumes } = wineCategory;
  bottles = formatWineCategoryArrayForSelect(bottles);
  capsules = formatWineCategoryArrayForSelect(capsules);
  sealed = formatWineCategoryArrayForSelect(sealed);
  types = formatWineCategoryArrayForSelect(types);
  volumes = formatWineCategoryArrayForSelect(volumes);
  return { locations, countries, bottles, capsules, sealed, types, volumes };
}
