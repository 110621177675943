import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Image,
  Menu,
  Modal,
  Row,
  Skeleton,
  Spin,
  Space,
} from "antd";
import {
  DownOutlined,
  EnvironmentFilled,
  UpOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../contexts/UserIdProvider";
import { getTimeBefore } from "../../utils/date";
import VideoBasic from "../common/video/VideoBasic";
import likeIcon from "../../resource/icons/feed/like_icon.svg";
import likedIcon from "../../resource/icons/feed/liked_icon.svg";
import shareIcon from "../../resource/icons/feed/share_icon.svg";
import commentIcon from "../../resource/icons/feed/comment_icon.svg";
import closeIcon from "../../resource/icons/feed/close.svg";
import reportIcon from "../../resource/icons/feed/report.svg";
import unFavoriteIcon from "../../resource/icons/feed/un_favorite.svg";
import favoritedIcon from "../../resource/icons/feed/favorited.svg";
import FeedDetail from "./detail/FeedDetail";
import { deepClone, goToProfilePage } from "../../utils/utils";
import {
  addFeedFavorite,
  getFeedFavoriteStatus,
  likeCreate,
  removeFeedFavorite,
  reportFeed,
} from "../../api/feed";
import { useTranslation } from "react-i18next";
import { translate } from "../../api/translate";
import FeedForward from "./forward/FeedForward";
import FeedForwardChild from "./forward/FeedForwardChild";
import { contactFollowStatus, follow } from "../../api/contact";
import { statusCode } from "../../constants/statusCode.enum";
import moreIcon from "../../resource/icons/feed/more.svg";
import verifiedIcon from "../../resource/icons/user-profile/verified.svg";
import locationIcon from "../../resource/icons/user-profile/location.svg";
import rightArrowIcon from "../../resource/icons/feed/detail/right_arrow.svg";
import backIcon from "../../resource/icons/feed/detail/back.svg";
import "./style/feedListRow.less";
import FeedLikeList from "./like/FeedLikeList";
import { userDetail } from "../../api/user";
import { localizeImg, localizeValue } from "../../constants/constants";
import { toastInfo } from "../../api/toast";
import { UserLabel, UserType } from "../../constants/usersType.enum";
import {
  companyDetail,
  companyImporterDesc,
  companyWineryDesc,
} from "../../api/company";
import { getChinaCityNameById, getCountryNameById } from "../../api/config";
import { loadProductConfig } from "../../api/product";
import { CompanyVerifyStatusEnum } from "../../constants/companyVerifyStatus.enum";
import AvatarBasic from "../common/avatar/AvatarBasic";
import { checkLimit, getTransLanguage } from "../../api/common";
import FeedImagePanel from "../shared/FeedImagePanel";
import { useAuth } from "../../contexts/AuthProvider";
import {
  openCompanyDetail,
  openCompanyImporterDesc,
  openCompanyWineryDesc,
} from "../../api/open";
import { RoleLimit } from "../../constants/roleType.enum";

export default function FeedListRow(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const { authTokens } = useAuth();
  const { data, keyword, onRefresh, onRemoveFeedItem } = props;
  const [refData, setRefData] = useState();
  const [feedId, setFeedId] = useState();
  const [orgContent, setOrgContent] = useState();
  const [feedContent, setFeedContent] = useState();
  const [likeCountAmount, setLikeCountAmount] = useState();
  const [forwardCountAmount, setForwardCountAmount] = useState();
  const [commentCountAmount, setCommentCountAmount] = useState();
  const [likeStatus, setLikeStatus] = useState();
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [mentions, setMentions] = useState([]);
  const [sourceFeedId, setSourceFeedId] = useState();
  const [sourceFeed, setSourceFeed] = useState([]);
  const [isFollow, setIsFollow] = useState(false);
  const [userItem, setUserItem] = useState();
  const [contentEllipsis, setContentEllipsis] = useState(false);
  const [contentHide, setContentHide] = useState(true);
  const [transStatus, setTransStatus] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showForward, setShowForward] = useState(false);
  const [showLike, setShowLike] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [IsMyPost, setIsMyPost] = useState(false);
  const [translatedContent, setTranslatedContent] = useState();
  const [companyDesc, setCompanyDesc] = useState();
  const [companyVerified, setCompanyVerified] = useState(
    "COMPANY_NOT_VERIFIED"
  );
  const feedContentDiv = useRef(null);
  const [lang, setLang] = useState(i18n.language);
  const [transLoading, setTransLoading] = useState(false);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [locations, setLocations] = useState();
  const [favorited, setFavorited] = useState(false);

  const [reportMenus, setReportMenus] = useState([]);
  const [subReportMenus, setSubReportMenus] = useState([]);

  useEffect(() => {
    initConfigData();
    initReportMenus();
  }, []);

  useEffect(() => {
    setLang(i18n.language);
    setTranslatedContent();
    setTransStatus(false);
  }, [i18n.language]);

  useEffect(() => {
    if (keyword && feedContent) {
      let _content = formatContentKeyword(feedContent, keyword);
      setFeedContent(_content);
    }
  }, [keyword]);

  useEffect(() => {
    if (data && locations) {
      let {
        _id,
        content,
        userId,
        userInfo,
        isLiked,
        images,
        videos,
        createdAt,
        likeCount,
        commentCount,
        mentions,
        sourceFeed,
        sourceFeedId,
        forwardCount,
      } = data;
      // console.log('----------feed list row data:', data);

      setOrgContent(content);
      let _content = formatContentMentions(content, mentions);
      setFeedContent(_content);
      setLikeCountAmount(likeCount);
      setCommentCountAmount(commentCount);
      setForwardCountAmount(forwardCount);
      setLikeStatus(isLiked);

      initFeedUserInfo(data);
      setFeedId(_id);
      loadFavoriteStatus(_id);
      setImages(images);
      if (videos && videos.length > 0) {
        setVideo(localizeImg(videos[0]));
      } else {
        setVideo(null);
      }
      setCreatedAt(createdAt);
      setMentions(mentions);
      setSourceFeed(sourceFeed);
      setSourceFeedId(sourceFeedId);

      if (user.userId === userId) {
        setIsMyPost(true);
      }

      let isUnmounted = false;
      const initFollowStatus = async () => {
        let pms = { sourceId: user.userId, targetId: userId };
        let followStatusResult = await contactFollowStatus(pms);
        if (
          followStatusResult &&
          followStatusResult.statusCode === statusCode.SUCCESS &&
          followStatusResult.result
        ) {
          !isUnmounted && setIsFollow(true);
        }
      };
      if (authTokens) {
        initFollowStatus();
      }

      setTranslatedContent();
      setTransStatus(false);
      return () => {
        isUnmounted = true;
      };
    }
  }, [data, locations]);

  useEffect(() => {
    if (!feedContent || !feedContentDiv.current) return;
    let element = feedContentDiv.current;
    let clientHeight = element.clientHeight;
    let scrollHeight = element.scrollHeight;
    if (clientHeight < scrollHeight) {
      setContentEllipsis(true);
    } else {
      setContentEllipsis(false);
    }
  }, [feedContent]);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    // console.log('---------configdata ',configData);
    setLocations(locations);
  };

  const initCompanyDesc = async (coId, type) => {
    let result;
    if (coId) {
      const companyDescPms = { coId };
      let companyInfo;
      if (authTokens) {
        companyInfo = await companyDetail(coId);
      } else {
        companyInfo = await openCompanyDetail(coId);
      }
      let companyDesc = {};

      if (authTokens) {
        if (type == UserType.SUPPLIER) {
          companyDesc = await companyWineryDesc(companyDescPms);
        } else {
          companyDesc = await companyImporterDesc(companyDescPms);
        }
      } else {
        if (type == UserType.SUPPLIER) {
          companyDesc = await openCompanyWineryDesc(companyDescPms);
        } else {
          companyDesc = await openCompanyImporterDesc(companyDescPms);
        }
      }

      if (
        companyInfo &&
        companyInfo.status === CompanyVerifyStatusEnum.VERIFIED
      ) {
        setCompanyVerified(t(type));
      }

      if (
        companyDesc &&
        companyDesc.statusCode === statusCode.SUCCESS &&
        companyDesc.result
      ) {
        setCompanyDesc(companyDesc.result);
        result = companyDesc.result;
      }
    }
    return result;
  };

  const initFeedUserInfo = async (stateData) => {
    let { userId, userInfo } = stateData;
    let temp_user;
    if (userInfo && userInfo.length > 0) {
      temp_user = userInfo[0];
    } else {
      temp_user = await userDetail(userId);
      if (!temp_user) {
        return;
      }
    }
    setUserItem(temp_user);
    temp_user.companyDesc = await initCompanyDesc(
      temp_user.companyId,
      temp_user.type
    );
    let _orgData = deepClone(stateData);
    _orgData.userInfo = [temp_user];
    setRefData(_orgData);
  };

  const initReportMenus = () => {
    if (reportMenus.length == 0) {
      let temp = deepClone(reportMenus);
      temp = [];
      temp.push(
        ...[
          {
            label: t("EXPOSED_CONTENT"),
            value: "EXPOSED_CONTENT",
            children: [
              { label: t("ADULT_NUDITY"), value: "ADULT_NUDITY" },
              { label: t("SEXUAL_SUGGESTION"), value: "SEXUAL_SUGGESTION" },
              { label: t("SEXUAL_BEHAVIOR"), value: "SEXUAL_BEHAVIOR" },
              { label: t("SEXUAL_EXPLOITATION"), value: "SEXUAL_EXPLOITATION" },
              { label: t("SEXUAL_SERVICES"), value: "SEXUAL_SERVICES" },
              { label: t("INVOLVING_CHILDREN"), value: "INVOLVING_CHILDREN" },
              {
                label: t("SHARE_PRIVATE_PICTURES"),
                value: "SHARE_PRIVATE_PICTURES",
              },
            ],
          },
          {
            label: t("VIOLENCE"),
            value: "VIOLENCE",
            children: [
              { label: t("BLOODY_VIOLENCE"), value: "BLOODY_VIOLENCE" },
              {
                label: t("DEATH_OR_SERIOUS_INJURY"),
                value: "DEATH_OR_SERIOUS_INJURY",
              },
              { label: t("THREAT_OF_VIOLENCE"), value: "THREAT_OF_VIOLENCE" },
              { label: t("ANIMAL_ABUSE"), value: "ANIMAL_ABUSE" },
              { label: t("SEXUAL_VIOLENCE"), value: "SEXUAL_VIOLENCE" },
            ],
          },
          {
            label: t("VIOLENT_HARASSMENT"),
            value: "VIOLENT_HARASSMENT",
            children: [
              { label: t("HARASS_ME"), value: "HARASS_ME" },
              { label: t("HARASSMENT"), value: "HARASSMENT" },
            ],
          },
          {
            label: t("FALSE_INFORMATION"),
            value: "FALSE_INFORMATION",
            children: [
              { label: t("HEALTHY"), value: "HEALTHY" },
              { label: t("POLITICS"), value: "POLITICS" },
              { label: t("SOCIAL_ISSUES"), value: "SOCIAL_ISSUES" },
              { label: t("OTHER"), value: "OTHER" },
            ],
          },
          {
            label: t("RACIAL_DISCRIMINATION"),
            value: "RACIAL_DISCRIMINATION",
            children: [
              { label: t("RACE_OR_ETHNICITY"), value: "RACE_OR_ETHNICITY" },
              { label: t("OTHER"), value: "OTHER" },
            ],
          },
          {
            label: t("HATE_SPEECH"),
            value: "HATE_SPEECH",
            children: [
              { label: t("RACE_OR_ETHNICITY"), value: "RACE_OR_ETHNICITY" },
              {
                label: t("ORIGINAL_NATIONALITY"),
                value: "ORIGINAL_NATIONALITY",
              },
              { label: t("RELIGIOUS_BELIEF"), value: "RELIGIOUS_BELIEF" },
              { label: t("CASTE_OF_INDIA"), value: "CASTE_OF_INDIA" },
              { label: t("SEXUAL_ORIENTATION"), value: "SEXUAL_ORIENTATION" },
              { label: t("GENDER_IDENTITY"), value: "GENDER_IDENTITY" },
              {
                label: t("DISABILITY_OR_DISEASE"),
                value: "DISABILITY_OR_DISEASE",
              },
              { label: t("OTHER"), value: "OTHER" },
            ],
          },
          {
            label: t("INFRINGEMENT_OF_INTELLECTUAL_PROPERTY"),
            value: "INFRINGEMENT_OF_INTELLECTUAL_PROPERTY",
            children: [
              {
                label: t("INFRINGEMENT_OF_INTELLECTUAL_PROPERTY"),
                value: "INFRINGEMENT_OF_INTELLECTUAL_PROPERTY",
              },
              { label: t("OTHER"), value: "OTHER" },
            ],
          },
          {
            label: t("TERRORISM"),
            value: "TERRORISM",
            children: [
              { label: t("TERRORISM"), value: "TERRORISM" },
              { label: t("OTHER"), value: "OTHER" },
            ],
          },
          {
            label: t("FRAUD"),
            value: "FRAUD",
            children: [
              { label: t("CHEAT"), value: "CHEAT" },
              { label: t("FRAUD"), value: "FRAUD" },
              { label: t("OTHER"), value: "OTHER" },
            ],
          },
          {
            label: t("ABUSE"),
            value: "ABUSE",
            children: [
              { label: t("CHILD_ABUSE"), value: "CHILD_ABUSE" },
              { label: t("ABUSE_OF_ANIMALS"), value: "ABUSE_OF_ANIMALS" },
              { label: t("OTHER"), value: "OTHER" },
            ],
          },
          {
            label: t("SUICIDE_OR_SELF_INJURY"),
            value: "SUICIDE_OR_SELF_INJURY",
            children: [
              { label: t("COMMIT_SUICIDE"), value: "COMMIT_SUICIDE" },
              { label: t("SELF_MUTILATION"), value: "SELF_MUTILATION" },
              { label: t("OTHER"), value: "OTHER" },
            ],
          },
          {
            label: t("INVASION_OF_PRIVACY"),
            value: "INVASION_OF_PRIVACY",
            children: [
              {
                label: t("INFRINGEMENT_OF_PRIVACY"),
                value: "INFRINGEMENT_OF_PRIVACY",
              },
              { label: t("OTHER"), value: "OTHER" },
            ],
          },
          {
            label: t("BULLY"),
            value: "BULLY",
            children: [
              { label: t("BULLYING"), value: "BULLYING" },
              { label: t("OTHER"), value: "OTHER" },
            ],
          },
          {
            label: t("ABOUT_BULLYING_A_MINOR"),
            value: "ABOUT_BULLYING_A_MINOR",
            children: [
              { label: t("VULGAR_SOFT_PORN"), value: "VULGAR_SOFT_PORN" },
              { label: t("INDUCE_BAD_BEHAVIOR"), value: "INDUCE_BAD_BEHAVIOR" },
              { label: t("INJURY_TO_MINORS"), value: "INJURY_TO_MINORS" },
            ],
          },
          {
            label: t("POOR_CONTENT_QUALITY"),
            value: "POOR_CONTENT_QUALITY",
            children: [
              {
                label: t("POOR_QUALITY_THROUGHOUT"),
                value: "POOR_QUALITY_THROUGHOUT",
              },
              { label: t("TITLE_PARTY"), value: "TITLE_PARTY" },
              {
                label: t("COVER_CAUSES_DISCOMFORT"),
                value: "COVER_CAUSES_DISCOMFORT",
              },
            ],
          },
        ]
      );
      setReportMenus(temp);
    }
  };

  const formatContentKeyword = (_content, key, _mentions) => {
    if (key) {
      if (_mentions && _mentions.length > 0) {
        let tempContent = _content.replace(/</g, "#@&<").replace(/>/g, "#@&>");
        let strArray = tempContent.split("#@&");
        strArray = strArray.map((item) => {
          if (item.indexOf("<") === -1) {
            item = item.replace(
              key,
              '<span class="keyword-label">' + key + "</span>"
            );
          }
          return item;
        });
        _content = strArray.join("");
      } else {
        _content = _content.replace(
          key,
          '<span class="keyword-label">' + key + "</span>"
        );
      }
    }
    return _content;
  };

  const formatContentMentions = (_content, _mentions) => {
    if (_mentions && _mentions.length > 0) {
      _mentions.forEach((item) => {
        let { id, type, name } = item;
        let path = "profile";
        _content = _content.replace(
          name,
          `<span class="mentions-label" onclick="javascript:location.href='/${path}/${id}'">${name}</span>`
        );
      });
    }
    return _content;
  };

  const onCompanyHome = () => {
    if (checkLimit(user?.userInfo, RoleLimit.CONTACTS, history)) {
      goToProfilePage(userItem, history);
    }
  };

  const resetRefData = (status) => {
    let temp = deepClone(refData);
    temp.isLiked = status;
    setRefData(temp);
  };

  const onTranslateMessage = async () => {
    let currentStatus = !transStatus;
    setTransStatus(currentStatus);
    if (currentStatus && !translatedContent) {
      setTransLoading(true);
      let language = getTransLanguage(lang);
      let beforeTransContent = transContentFilterMention(orgContent, false);
      let result = await translate({
        text: beforeTransContent,
        to: language,
      });
      let afterTransContent = result.result.result;
      afterTransContent = transContentFilterMention(afterTransContent, true);
      setTranslatedContent(afterTransContent);
      setTransLoading(false);
    }
  };

  const transContentFilterMention = (value, afterTrans) => {
    if (value && mentions && mentions.length > 0) {
      mentions.map((item) => {
        item = `<span class="mentions-label">${item}</span>`;
        if (afterTrans) {
          value = value.replace("@@@", item);
        } else {
          value = value.replace(item, "@@@");
        }
      });
    }
    return value;
  };

  const onLike = async () => {
    if (!authTokens) {
      history.push("/login");
      return;
    }

    // set the status of the like before the request (we set the status before for UX purpose).
    setLikeStatus(!likeStatus);
    resetRefData(!likeStatus);

    //The message is already liked  we decrease one else we increase one
    if (likeStatus) {
      setLikeCountAmount(likeCountAmount - 1);
    } else {
      setLikeCountAmount(likeCountAmount + 1);
    }

    // create the like request
    let pms = { userId: user.userId, feedId };
    let result = await likeCreate(pms);

    // if the request is unsuccessful, reset the status of the like, maybe draw error message later
    if (!result) {
      setLikeStatus(!likeStatus);
      resetRefData(!likeStatus);
    }
  };

  const onComment = () => {
    setCommentCountAmount(commentCountAmount + 1);
  };

  const getCloseIcon = () => {
    return (
      <img alt={"Close icon"} src={closeIcon} className="modal-close-icon" />
    );
  };

  const onFeedRefresh = () => {
    onRefresh && onRefresh();
  };

  const reportMessage = () => {
    initReportMenus();
    setShowReportModal(true);
  };

  const onRemoveItem = () => {
    onRemoveFeedItem && onRemoveFeedItem(refData);
  };

  const onSwitchFavorite = () => {
    const pms = {
      coId: user.userInfo.companyId,
      feedId: data._id,
    };

    if (!favorited) {
      addFeedFavorite(pms);
    } else {
      removeFeedFavorite(pms);
    }
    setFavorited(!favorited);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={onSwitchFavorite.bind(this)}
        >
          <img
            src={favorited ? favoritedIcon : unFavoriteIcon}
            style={{
              marginRight: "5px",
            }}
          />
          {favorited ? t("CANCEL") + t("SAVED") : t("SAVED")}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={reportMessage}>
          <img
            src={reportIcon}
            style={{
              marginRight: "5px",
            }}
          />
          {t("REPORT_POST")}
        </a>
      </Menu.Item>
    </Menu>
  );

  const menu_my = (
    <Menu>
      <Menu.Item>
        <div onClick={onRemoveItem.bind(this)}>
          <img
            src={reportIcon}
            style={{
              marginRight: "5px",
            }}
          />
          {t("DELETE")}
        </div>
      </Menu.Item>
    </Menu>
  );

  const loadFavoriteStatus = async (_id) => {
    const pms = {
      coId: user?.userInfo?.companyId,
      feedId: _id,
    };

    const result = await getFeedFavoriteStatus(pms);
    setFavorited(result ? true : false);
  };

  const onReportItemClick = (reportItem, reportIndex) => {
    let tempReportMenus = deepClone(reportMenus);
    tempReportMenus.map((mapItem) => {
      mapItem.selected = false;
    });
    tempReportMenus[reportIndex].selected = true;
    setReportMenus(tempReportMenus);

    let temp = deepClone(subReportMenus);
    temp = deepClone(reportItem.children);
    setSubReportMenus(temp);
  };

  const onSubReportItemClick = (reportItem, reportItemIndex) => {
    let temp = deepClone(subReportMenus);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[reportItemIndex].selected = true;
    setSubReportMenus(temp);
  };

  const onSubReportBack = () => {
    let temp = deepClone(subReportMenus);
    temp = [];
    setSubReportMenus(temp);
  };

  const onCloseReportModal = () => {
    let temp = deepClone(subReportMenus);
    temp = [];
    setSubReportMenus(temp);
    setShowReportModal(false);
  };

  const onReportFeedClick = () => {
    console.log("onReportFeedClick...");

    let firstLevels = reportMenus.filter((filterItem) => filterItem.selected);
    if (firstLevels && firstLevels.length > 0) {
      let secondLevels = subReportMenus.filter(
        (filterItem) => filterItem.selected
      );
      if (secondLevels && secondLevels.length > 0) {
        const pms = {
          feedId: feedId,
          firstLevel:firstLevels[0].value,
          secondLevel:secondLevels[0].value
        };
        reportFeed(pms);

        toastInfo(t("REPORT_SUCCESFULY_SEND"));
      }
    }
  };

  return (
    <>
      {!companyDesc ? (
        <div className="feed" style={{ width: "100%" }}>
          {/* loader create post */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic createFeedPlaceHolder"
            }
          >
            <div>
              <Skeleton active={true} avatar={true} paragraph={{ rows: 0 }} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Space>
                <Skeleton.Button active={true} size={"default"} />
                <Skeleton.Button active={true} size={"default"} />
                <Skeleton.Button active={true} size={"default"} />
              </Space>
              <Skeleton.Button active={true} size={"default"} />
            </div>
          </div>
          {/* loader item simple */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 2 }} />
          </div>
          {/* loader item with images */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 2 }} />
            <div className="feed-images-container">
              <Row gutter={[8, 8]} className="feed-images">
                <Col span={8}>
                  <div className="feed-list-image-box-loader">
                    <Skeleton.Image
                      style={{ width: "100%", height: "100%" }}
                      className="ant-image-loader"
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="feed-list-image-box-loader">
                    <Skeleton.Image
                      style={{ width: "100%", height: "100%" }}
                      className="ant-image-loader"
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="feed-list-image-box-loader">
                    <Skeleton.Image
                      style={{ width: "100%", height: "100%" }}
                      className="ant-image-loader"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/* loader item simple */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 3 }} />
          </div>
          {/* loader item simple */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 5 }} />
          </div>
        </div>
      ) : (
        <div className="feed-row-item">
          <div className="feed-row-item-header">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="meta-wrapper" onClick={onCompanyHome.bind(this)}>
                <AvatarBasic
                  cname="meta-avatar"
                  size={51}
                  picture={localizeImg(companyDesc?.avatar)}
                  displayName={
                    companyDesc?.displayName || localizeValue(i18n.language,companyDesc?.companyName)
                  }
                ></AvatarBasic>
                <div className="meta-wrapper-content">
                  <div className="meta-title">
                    {companyDesc?.displayName || localizeValue(i18n.language,companyDesc?.companyName)}
                  </div>
                  <div className="meta-desc">
                    <div style={{ marginRight: "14px" }}>
                      {t(getTimeBefore(createdAt))}
                    </div>
                    {companyDesc?.location && (
                      <div style={{ marginRight: "14px" }}>
                        <img
                          src={locationIcon}
                          style={{
                            width: "8.12px",
                            height: "9.2px",
                            marginRight: "5px",
                          }}
                        />
                        {getCountryNameById(
                          locations,
                          lang,
                          companyDesc?.location?.country
                        )}
                        {getChinaCityNameById(companyDesc?.location?.city)}
                      </div>
                    )}
                    <div style={{ marginRight: "14px" }}>
                      {t(companyVerified) != t("COMPANY_NOT_VERIFIED") && (
                        <img
                          src={verifiedIcon}
                          style={{
                            width: "8px",
                            height: "9.44px",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      {t(companyVerified)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
            // style={{
            //   height: "64px",
            // }}
            >
              <div className="feed-userinfo-action">
                <Dropdown
                  overlay={IsMyPost ? menu_my : menu}
                  placement="bottomCenter"
                >
                  <div className="feed-row-item-more">
                    <img src={moreIcon} className="icon-feed" />
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
          <div
            ref={feedContentDiv}
            className={
              contentHide ? "feed-row-content-ellipsis" : "feed-row-content"
            }
            dangerouslySetInnerHTML={{ __html: feedContent }}
          ></div>
          {contentEllipsis && (
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              <span
                onClick={() => setContentHide(!contentHide)}
                className="feed-trans-button"
              >
                {contentHide ? t("SHOW_MORE") : t("SHOW_LESS")}{" "}
              </span>
            </div>
          )}
          {feedContent && (
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              <span
                onClick={onTranslateMessage.bind(this)}
                className="feed-trans-button"
              >
                {transStatus ? (
                  <UpOutlined style={{ fontSize: "10px" }} />
                ) : (
                  <DownOutlined style={{ fontSize: "10px" }} />
                )}
                <span>{t("TRANSLATE")}</span>
              </span>
            </div>
          )}

          {transStatus && (
            <div
              className="feed-trans-content"
              dangerouslySetInnerHTML={{ __html: translatedContent }}
            ></div>
          )}
          {transLoading && (
            <div className="feed-trans-content">
              <Spin indicator={loadingIcon} />
            </div>
          )}
          {images && images.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <FeedImagePanel
                images={images}
                onImageClick={() => setShowDetail(true)}
              ></FeedImagePanel>
            </div>
          )}
          {video && (
            <div className="feed-images-container">
              <VideoBasic url={video} width="100%" height={350} />
            </div>
          )}
          {sourceFeedId &&
            (sourceFeed && sourceFeed.length > 0 ? (
              <FeedForwardChild data={sourceFeed[0]} />
            ) : (
              <div className="feed-row-tip">{t("Original text deleted")}</div>
            ))}
          <div className="feed-item-action">
            <div className="feed-item-action-upper">
              <div className="like-number">
                <img src={likedIcon} className="icon-feed" />
                <span onClick={() => setShowLike(true)}>
                  {" "}
                  {likeCountAmount}
                </span>
              </div>
              <div className="comment-wrapper">
                <div
                  className="like-number"
                  onClick={() => setShowDetail(true)}
                >
                  <span>
                    {commentCountAmount} {t("COMMENT")}
                  </span>
                </div>
              </div>
            </div>
            <div className="feed-item-actions-wrapper">
              <div
                className={`feed-action-col ${likeStatus ? "liked" : ""}`}
                onClick={onLike.bind(this)}
              >
                {likeStatus ? (
                  <img
                    alt={"liked icon"}
                    src={likedIcon}
                    className="icon-feed-create"
                    style={{
                      width: "24px",
                      height: "24.01px",
                    }}
                  />
                ) : (
                  <img
                    alt={"like icon"}
                    src={likeIcon}
                    className="icon-feed-create"
                    style={{
                      width: "24px",
                      height: "24.01px",
                    }}
                  />
                )}
                <span className="feed-action-num">{t("LIKE")}</span>
              </div>

              <div
                className="feed-action-col"
                onClick={() => {
                  if (!authTokens) {
                    history.push("/login");
                    return;
                  }
                  setShowDetail(true);
                }}
              >
                <img
                  alt={"Comment icon"}
                  src={commentIcon}
                  className="icon-feed-create"
                  style={{
                    width: "29.92px",
                    height: "24px",
                  }}
                />
                <span className="feed-action-num"> {t("COMMENT")}</span>
              </div>
              <div
                className="feed-action-col"
                onClick={() => {
                  if (!authTokens) {
                    history.push("/login");
                    return;
                  }
                  setShowForward(true);
                }}
              >
                <img
                  alt={"SHare icon"}
                  src={shareIcon}
                  className="icon-feed-create"
                  style={{
                    width: "24px",
                    height: "22.56px",
                  }}
                />

                <span className="feed-action-num">{t("FORWARD")}</span>
              </div>
            </div>
          </div>
          <Modal
            destroyOnClose={true}
            title={null}
            visible={showDetail}
            footer={null}
            className={
              (images && images.length > 0) || video
                ? "feed-detail-modal-images"
                : "feed-detail-modal"
            }
            closeIcon={getCloseIcon()}
            onCancel={() => setShowDetail(false)}
          >
            <FeedDetail
              data={refData}
              onLike={onLike.bind(this)}
              onComment={onComment.bind(this)}
            />
          </Modal>
          <Modal
            destroyOnClose={true}
            title={null}
            visible={showForward}
            footer={null}
            className={"feed-detail-modal"}
            onCancel={() => setShowForward(false)}
          >
            <FeedForward
              data={refData}
              onLike={onLike.bind(this)}
              onClose={() => setShowForward(false)}
              onRefresh={onFeedRefresh.bind(this)}
            />
          </Modal>
          <Modal
            destroyOnClose={true}
            title={null}
            visible={showLike}
            footer={null}
            closeIcon={getCloseIcon()}
            onCancel={() => setShowLike(false)}
          >
            <FeedLikeList feedId={feedId} />
          </Modal>
          <Modal
            destroyOnClose={true}
            title={null}
            visible={showReportModal}
            footer={null}
            closable={false}
            onCancel={onCloseReportModal.bind(this)}
            width={425}
            className="feed-list-row-report-modal"
          >
            <div className="feed-list-row-report-modal-panel">
              <div className="title-panel">
                {subReportMenus.length > 0 ? (
                  <img
                    src={backIcon}
                    style={{ cursor: "pointer" }}
                    onClick={onSubReportBack.bind(this)}
                  />
                ) : (
                  <div style={{ width: "18px", height: "1px" }}></div>
                )}
                <div className="title">{t("REPORT_POST")}</div>
                <img
                  src={closeIcon}
                  onClick={onCloseReportModal.bind(this)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="desc">{t("SELECT_REPORT_REASON")}</div>
              <div className="content-panel">
                {subReportMenus.length == 0 &&
                  reportMenus.map((mapItem, mapIndex) => {
                    return (
                      <div
                        className="report-reason-item-panel"
                        onClick={onReportItemClick.bind(
                          this,
                          mapItem,
                          mapIndex
                        )}
                      >
                        <span>{mapItem.label}</span>
                        <img
                          src={rightArrowIcon}
                          style={{
                            width: "24px",
                          }}
                        />
                      </div>
                    );
                  })}
                {subReportMenus.map((mapItem, mapIndex) => {
                  return (
                    <div
                      className={
                        mapItem.selected
                          ? "report-reason-item-panel-selected"
                          : "report-reason-item-panel"
                      }
                      onClick={onSubReportItemClick.bind(
                        this,
                        mapItem,
                        mapIndex
                      )}
                    >
                      <span>{mapItem.label}</span>
                    </div>
                  );
                })}
                {subReportMenus.length > 0 && (
                  <div
                    className={
                      subReportMenus.filter((filterItem) => filterItem.selected)
                        .length > 0
                        ? "btn-submit"
                        : "btn-submit-disabled"
                    }
                    onClick={onReportFeedClick.bind(this)}
                  >
                    {t("SUBMIT")}
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}
