import { Player } from "@lottiefiles/react-lottie-player";
import emptyAnimation from "./style/empty_animation.json";
import emptyImg from "../../../resource/images/common/empty.png";
export default function Empty() {
  /**
   * Todo fix the i18n issue
   */
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={emptyImg}
        style={{
          width: "300px",
          height: "228.6px",
          marginTop: "24px",
        }}
      />
      {/* <Player
        autoplay
        src={emptyAnimation}
        style={{ height: "300px", width: "300px" }}
      ></Player> */}
    </div>
  );
}
