import { createContext, useContext, useState, useEffect } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import websdk from "easemob-websdk";
import IMConfig from "../constants/webIMConfig";
import { imLogin } from "../utils/imHelper";

export const IMContext = createContext();

export function useIMContext() {
  return useContext(IMContext);
}

export function IMProvider({ children }) {
  // const [imLoginStatus, setImLoginStatus] = useLocalStorage("imLoginStatus", "");
  const [imLoginStatus, setImLoginStatus] = useState(false);
  const [webIM, setWebIM] = useState();
  const [newMsg, setNewMessage] = useState();
  const [chatCallMsg, setCallMsg] = useState();
  const [sessionMessageList, setSessionMessageList] = useState([]);
  const agoraClient = AgoraRTC.createClient({ codec: "vp8", mode: "rtc" });

  useEffect(() => {
    createWebIM();
  }, []);

  function createWebIM() {
    let WebIM = window.WebIM || {};
    WebIM.config = IMConfig;
    WebIM.conn = new websdk.connection({
      appKey: WebIM.config.appkey,
      isHttpDNS: WebIM.config.isHttpDNS,
      isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
      host: WebIM.config.Host,
      https: WebIM.config.https,
      url: WebIM.config.xmppURL,
      apiUrl: WebIM.config.apiURL,
      isAutoLogin: false,
      heartBeatWait: WebIM.config.heartBeatWait,
      autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
      autoReconnectInterval: WebIM.config.autoReconnectInterval,
      isStropheLog: WebIM.config.isStropheLog,
      delivery: WebIM.config.delivery,
    });
    WebIM.conn.listen({
      onOpened: function (message) {
        setImLoginStatus(true);
      },
      onClosed: function (message) {
        setImLoginStatus(false);
      },
      onError: function (message) {
        setImLoginStatus(false);
      },
      onTextMessage: function (message) {
        setNewMessage(message);
      },
      onCustomMessage: function (message) {
        setNewMessage(message);
      },
      onReceivedMessage: function (message) {
      },
      onPresence: function (message) {
        switch (message.type) {
          case "subscribe":
            let truthBeTold = window.confirm(
              message.from + "申请添加您为好友:"
            );
            if (truthBeTold) {
              WebIM.conn.subscribed({
                to: message.from,
                message: "[resp:true]",
              });
            } else {
              WebIM.conn.unsubscribed({
                to: message.from,
                message: "rejectAddFriend",
              });
            }
            break;
          default:
            console.log("Got default in switch case");
            break;
        }
      },
    });
    setWebIM(WebIM);
  }

  function initIMLogin(userInfo) {
    imLogin(webIM, userInfo);
  }
  
  function setChatCallMsg(msg) {
    setCallMsg(msg);
  }

  function setChatNewMsg(msg) {
    setNewMessage(msg);
  }

  const getSessionMessage = () => {
    try {
      // webIM.conn.getSessionList().then((res) => {
      //   console.log('------session list :', res);
      //   if (res && res.data && res.data.channel_infos) {
      //     setSessionMessageList(res.data.channel_infos);
      //   }
      // });
    } catch (error) {
      console.log('------session list error:', error);
    }
  };

  return (
    <IMContext.Provider
      value={{
        imLoginStatus,
        webIM,
        newMsg,
        sessionMessageList,
        chatCallMsg,
        getSessionMessage,
        initIMLogin,
        createWebIM,
        setChatCallMsg,
        setChatNewMsg,
        agoraClient,
      }}
    >
      {children}
    </IMContext.Provider>
  );
}
