import React, { useEffect, useState } from "react";
import { Layout, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../../contexts/UserIdProvider";
import { deepClone } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import "./style/productInCartCompanys.less";
import { getPageFavorites, getPostPageFavorites } from "../../../api/favorite";
import CompanyItemBasic from "../../shared/CompanyItemBasic";
import { useLocation } from "react-router-dom";
import { getProductCarts } from "../../../api/carts";
import BreadcrumbNormal from "../../common/title/BreadcrumbNormal";

export default function ProductInCartCompanys() {
  const { user } = useUserId();
  const { userId } = user;
  const { state } = useLocation();
  const productId = state?.productId;
  const [dataList, setDataList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const { Content } = Layout;
  const { t } = useTranslation();

  useEffect(() => {
    if (productId) {
      loadData();
    }
  }, [pageIndex]);

  const loadData = async () => {
    setLoading(true);
    let pms = { productId, page: pageIndex, per_page: pageSize };
    let result = await getProductCarts(pms);
    if (result) {
      result = result.result;
      let list = pageIndex > 0 ? deepClone(dataList) : [];
      result.forEach((item) => {
        if (item.userInfo && item.userInfo.length > 0) {
          let _userInfo = item.userInfo[0];
          _userInfo.name = _userInfo.nickName;
          list.push(_userInfo);
        }
      });
      setDataList(list);
      console.log("----result : ", list);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div className="common-page">
      <div
        className={
          "myContact-container common-management normal-shadow-padding10-white"
        }
      >
        <div className={"common-management-title"}>
          {t("COMPANY_WITH_PRODUCT_IN_SHOPPING_CART")}
        </div>
        <div className="common-management-panel">
          <InfiniteScroll
            className="scroll-content"
            dataLength={dataList.length}
            next={handleInfiniteOnLoad.bind(this)}
            hasMore={hasMore}
          >
            <List
              className="fensi"
              itemLayout="vertical"
              size="large"
              dataSource={dataList}
              renderItem={(item) => <CompanyItemBasic detail={item} />}
            >
              {loading && hasMore && (
                <div>
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                </div>
              )}
            </List>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
