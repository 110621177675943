import React, { useEffect, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./MultiSelect.module.sass";
import Tooltip from "../Tooltip";
import { deepClone } from "../../../utils/utils";
import TextInput from "../TextInput";
import selectIcon from "../../../resource/icons/common/select.svg";
import unSelectIcon from "../../../resource/icons/common/un_select.svg";

const MultiSelect = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  options,
  label,
  tooltip,
  small,
  upBody,
  onChange,
  placeholder,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState(deepClone(options));
  const [oriDataSource, setOriDataSource] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    console.log("options:", options);
    setDataSource(deepClone(options));
    setOriDataSource(deepClone(options));
  }, [options]);

  useEffect(() => {
    if (options && options.length > 0 && value) {
      let selectedArray = options.map((item) => {
        const selected = value.includes(item.value);
        return { ...item, selected };
      });
      selectedArray = selectedArray.filter((item) => item.selected);
      let tempLabel =
        selectedArray
          ?.map((val) => val.label)
          .join(", ")
          .slice(0, 25) +
        (selectedArray?.length > 1 &&
        selectedArray?.map((val) => val.label).join(", ").length > 25
          ? "..."
          : "");
      if (tempLabel && tempLabel.length > 0) {
        tempLabel += ",";
      }
      setSelectedLabel(tempLabel);
    }
  }, [value]);

  const handleClick = (value, index) => {
    let temp = deepClone(dataSource);
    temp[index].selected = !temp[index].selected;
    setDataSource(temp);

    console.log("temp:", temp);

    let tempOriDataSource = deepClone(oriDataSource);
    tempOriDataSource.map((mapItem) => {
      if (mapItem.value === value.value) {
        mapItem.selected = !mapItem.selected;
      }
    });

    let selectedValuesTemp = deepClone(selectedValues);
    selectedValuesTemp = tempOriDataSource
      .filter((x) => x.selected)
      .map((item) => item.value);
    setSelectedValues(selectedValuesTemp);
    setOriDataSource(tempOriDataSource);
    console.log("selectedValuesTemp:", selectedValuesTemp);

    onInputChange({ target: { value: "" } });

    onChange(selectedValuesTemp);
  };

  const onInputChange = (e) => {
    setSearchText(e.target.value);
    let temp = deepClone(dataSource);
    if (e.target.value && e.target.value.length > 0) {
      temp = temp.filter((x) =>
        x.label.toLowerCase().includes(e.target.value.toLowerCase())
      );
    }
    console.log("onInputChange:",temp)
    // setDataSource(temp);
    setVisible(true);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>
            <div className={styles.wrap}>
              {selectedLabel || placeholder}
              <input
                onChange={onInputChange.bind(this)}
                className={cn(styles.input)}
                value={searchText}
              />
            </div>
          </div>
        </div>
        <div
          className={cn(styles.body, { [styles.bodyUp]: upBody })}
          style={{ maxHeight: "300px", overflowY: "auto" }}
          onBlur={() => setVisible(false)}
        >
          {dataSource.map((x, index) => (
            <div
              className={`${cn(styles.option, {
                [styles.selectioned]: x.selected,
              })} flex items-center`}
              onClick={() => handleClick(x, index)}
              key={index}
            >
              <img src={x.selected ? selectIcon : unSelectIcon} className="h-3 mr-1"/>
              {x.label}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default MultiSelect;
