import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { BackTop, Layout } from "antd";
import { authValidate } from "../../api/common";
import { logUserAction } from "../../action-loggers/action-loggers";
import { useUserId } from "../../contexts/UserIdProvider";
import "./style/baseprivateroute.less";
import Page from "../../core-digital-template/components/Page";
import ProfilePage from "../../core-digital-template/components/ProfilePage";
import { Path } from "../../constants/path";

export default function ProfileTemplateRoute({
  component: Component,
  pageTitle,
  ...rest
}) {
  const validate = authValidate();

  const { user } = useUserId();
  const { userId } = user;

  const href = window.location.href;

  useEffect(() => {
    if (userId) {
      logUserAction(userId, rest.path, rest?.computedMatch?.params?.id);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (validate) {
          return (
            <Page title={pageTitle}>
              <Component {...props} />
            </Page>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: !href.endsWith(Path.signUp)
                  ? Path.signIn
                  : Path.signUp,
                state: { refer: props.location, clearToken: true },
              }}
            />
          );
        }
      }}
    />
  );
}
