import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { deepClone } from "../../../../utils/utils";
import "./style/cookiePolicy.less";
import policyTopImg from "../../../../resource/images/dashboard/policy/policy_top.png";
import cookieContent1Img from "../../../../resource/images/dashboard/policy/cookie_content_1.png";
import cookieContent2Img from "../../../../resource/images/dashboard/policy/cookie_content_2.png";
import cookieContent3Img from "../../../../resource/images/dashboard/policy/cookie_content_3.png";
import cookieContent4Img from "../../../../resource/images/dashboard/policy/cookie_content_4.png";

export default function CookiePolicy() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  useEffect(() => {}, []);

  return (
    <div className="common-page">
      <div className="common-management privacy-policy-panel">
        <div>
          <img src={policyTopImg} />
        </div>
        <img src={cookieContent1Img} />
        <img src={cookieContent2Img} />
        <img src={cookieContent3Img} />
        <img src={cookieContent4Img} />
      </div>
    </div>
  );
}
