import React, { useState, useEffect } from "react";
import { Input, Button, Space, Select, Col, Tree } from "antd";
import { useTranslation } from "react-i18next";
import "./style/warehouseCreate.less";
import { useUserId } from "../../../contexts/UserIdProvider";
import InputPhoneNumberNormal from "../../common/input/InputPhoneNumberNormal";
import { constants, deepClone } from "../../../utils/utils";
import { getCountryForSelect } from "../../../api/config";
import { loadProductConfig } from "../../../api/product";
import AddressSelect from "../../common/location/addressSelect/AddressSelect";
import { chinaCityList } from "../../../constants/area-data-min";

export default function WarehouseCreate(props) {
  const {
    currentWarehouse,
    onComplete,
    onChange,
  } = props;
  const { user } = useUserId();
  const { t, i18n } = useTranslation();
  
  const [id, setId] = useState();

  let [name, setName] = useState("");
  let [phone, setPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const [province, setProvince] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [inChina, setInChina] = useState(true);

  useEffect(() => {
    if (currentWarehouse) {
      setId(currentWarehouse._id);
      setName(currentWarehouse.name);
      setPhone(currentWarehouse.phone);
      setPhoneNumber(currentWarehouse.phoneNumber);
      setCountry(currentWarehouse.country);
      setProvince(currentWarehouse.province);
      setCity(currentWarehouse.city);
      setAddress(currentWarehouse.address);
      setZipcode(currentWarehouse.zipcode);
    }
    initConfigData();
    let crispDom = document.getElementById("crisp-chatbox");
    crispDom.style.zIndex = 1;
  }, []);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    // setLocations(locations);
    setCountries(getCountryForSelect(locations, i18n.language));

    let tempProvinces = deepClone(provinces);
    tempProvinces = deepClone(chinaCityList);
    setProvinces(tempProvinces);
  };

  const onNameChange = (e) => {
    let val = e.target.value;
    setName(val);
    let item = deepClone(currentWarehouse);
    item.name = val;
    onChange(item);
  };

  const onPhoneChange = (val) => {
    console.log(val);
    setPhone(val);
    setPhoneNumber(val.phone);
    let item = deepClone(currentWarehouse);
    item.phone = val;
    item.phoneNumber = val.phone;
    onChange(item);
  };

  const onCountryChange = (val) => {
    setCountry(val);
    setInChina(val == constants.chinaCountryId);
    setProvince("");
    let item = deepClone(currentWarehouse);
    item.country = val;
    onChange(item);
  };

  const onProvinceChange = (val) => {
    setProvince(val);
    setCity("");

    let selectedProvinces = chinaCityList.filter(
      (filterItem) => filterItem.value == val
    );
    if (selectedProvinces && selectedProvinces.length > 0) {
      let tempCities = deepClone(cities);
      tempCities = deepClone(selectedProvinces[0].children);
      setCities(tempCities);

      let item = deepClone(currentWarehouse);
      item.province = val;
      onChange(item);
    }
  };

  const onCityChange = (val) => {
    setCity(val);
    let item = deepClone(currentWarehouse);
    item.city = val;
    onChange(item);
  };

  const onAddressChange = (val) => {
    setAddress(val);
    let item = deepClone(currentWarehouse);
    item.address = val;
    onChange(item);
  };

  const onZipcodeChange = (e) => {
    setZipcode(e.target.value);
    let item = deepClone(currentWarehouse);
    item.zipcode = e.target.value;
    onChange(item);
  };

  const onFinish = () => {
    onComplete && onComplete();
  };

  return (
    <div className="warehouse-create-panel-container">
      <div className="modal-body">
        {/* <div className="modal-title">{t("ADD_OR_UPDATE_STAFF")}</div> */}
        <div className="form-body">
          <div className="form-panel">
            <div className="form-label">{t("SHIPPING_NAME")}</div>
            <Input
              value={name}
              placeholder={t("SHIPPING_NAME")}
              className="content-row-input"
              onInput={onNameChange.bind(this)}
            />
          </div>
          <div className="form-panel">
            <div className="form-label">
              {t("PHONE_NUMBER")}
              {/* {required_tip} */}
            </div>
            <InputPhoneNumberNormal
              value={phone}
              onChange={onPhoneChange.bind(this)}
            />
          </div>
          <div className="form-panel">
            <div className="form-label">
              {t("COUNTRY")}
              {/* {required_tip} */}
            </div>
            <Select
              className="content-select"
              value={country}
              onChange={onCountryChange.bind(this)}
              placeholder={t("COUNTRY")}
              options={countries}
            />
          </div>
          {inChina && (
            <div className="form-panel">
              <div className="form-label">
                {t("PROVINCE")}
                {/* {required_tip} */}
              </div>
              <Select
                className="content-select"
                value={province}
                onChange={onProvinceChange.bind(this)}
                placeholder={t("PROVINCE")}
                options={provinces}
              />
            </div>
          )}
          {inChina && (
            <div className="form-panel">
              <div className="form-label">
                {t("CITY")}
                {/* {required_tip} */}
              </div>
              <Select
                className="content-select"
                value={city}
                onChange={onCityChange.bind(this)}
                placeholder={t("CITY")}
                options={cities}
              />
            </div>
          )}
          <div className="form-panel">
            <div className="form-label">
              {t("ADDRESS")}
              {/* {required_tip} */}
            </div>
            <div
              style={{
                border: inChina ? "1px solid #dedede" : "0px",
                width: "441px",
              }}
            >
              <AddressSelect
                country={country}
                onChange={onAddressChange.bind(this)}
              />
            </div>
          </div>
          <div className="form-panel">
            <div className="form-label">
              {t("ZIPCODE")}
              {/* {required_tip} */}
            </div>
            <Input
              value={zipcode}
              placeholder={t("ZIPCODE")}
              className="content-row-input"
              onInput={onZipcodeChange.bind(this)}
            />
          </div>
          <Button onClick={onFinish.bind(this)} className="save-button">
            {t("SAVE")}
          </Button>
        </div>
      </div>
    </div>
  );
}
