import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../contexts/UserIdProvider";
import {
  AmityUiKitChat,
  AmityUiKitFeed,
  AmityUiKitProvider,
  AmityUiKitSocial,
} from "../amity-uikit";
import { FeedType, PostTargetType } from "@amityco/js-sdk";
import { AmityApiKey } from "../../constants/constants";

// import { AmityUiKitProvider, AmityUiKitSocial,AmityUiKitChat } from "@amityco/ui-kit";

export default function TestAmityCo() {
  const { t, i18n } = useTranslation();

  const apiKey = AmityApiKey;
  const [userId, setUserId] = useState("bruce.admin");

  useEffect(() => {}, []);

  return (
    <div>
      <AmityUiKitProvider
        key={userId}
        apiKey={apiKey}
        userId={userId}
        // displayName={userId}
        // authToken={"1f97f4c66e8b7512d3fa2603349c94299f66c7a6fce2d732527cf113a7853543f79541804bf9c1795405df7be265ebe5578cdcf36d3bfc697152807422"}
      >
        <AmityUiKitSocial />
        {/* <AmityUiKitChat/> */}
        {/* <AmityUiKitFeed
          feedType={FeedType.Published}
          targetId={userId}
          targetType={PostTargetType.CommunityFeed}
        /> */}

      </AmityUiKitProvider>
    </div>
  );
}
