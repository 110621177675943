import React from "react";
import { PostTargetType } from "@amityco/js-sdk";

import { PageTypes } from "../../../social/constants";
import Feed from "../../../social/components/Feed";

import { useNavigation } from "../../../social/providers/NavigationProvider";

import { Wrapper } from "./styles";

const NewsFeed = ({ userId }) => {
  const { onChangePage } = useNavigation();

  return (
    <Wrapper data-qa-anchor="news-feed">
      <Feed
        targetId={userId}
        targetType={PostTargetType.GlobalFeed}
        goToExplore={() => onChangePage(PageTypes.Explore)}
        showPostCreator={false}
      />
    </Wrapper>
  );
};

export default NewsFeed;
