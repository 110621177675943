import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../../core-digital-template/components/Card";
import Icon from "../../../../core-digital-template/components/Icon";
import TextInput from "../../../../core-digital-template/components/TextInput";
import Editor from "../../../../core-digital-template/components/Editor";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../../core-digital-template/components/Dropdown";
import {
  getProductConfigForSelect,
  getProductGrapes,
  loadProductConfig,
} from "../../../../api/product";
import { ProductConfig, localizeValue } from "../../../../constants/constants";
import {
  getCountryForSelect,
  getLocationsByParentId,
} from "../../../../api/config";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { deepClone } from "../../../../utils/utils";
import MultiSelect from "../../../../core-digital-template/components/MultiSelect";
import {
  companyDetail,
  getCompanyDescFromUserType,
} from "../../../../api/company";
import { LanguagesKey } from "../../../../constants/languages.enum";
import ImageUploader from "../../../../core-digital-template/components/ImageUploader";
import AddressSelect from "../../../../components/common/location/addressSelect/AddressSelect";
import Award from "../Award";
import { UserType } from "../../../../constants/usersType.enum";

export default function NameAndDescription(props) {
  const { className, onChange, onAddressChange, dataSource, companyId } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [formData, setFormData] = useState({});
  const [addressFormData, setAddressFormData] = useState({});

  const [companyLabels, setCompanyLabels] = useState([
    { label: "PRODUCER", value: "PRODUCER" },
    // { label: "WINERY", value: "WINERY" },
    // { label: "COOPERATIVE", value: "COOPERATIVE" },
    { label: "NEGOCIANT", value: "NEGOCIANT" },
  ]);

  const [buyerLabels, setBuyerLabels] = useState([
    { label: t("RESTAURANT_AND_BAR"), value: "RESTAURANT_AND_BAR" },
    { label: t("WHOLE_SALE"), value: "WHOLE_SALE" },
    { label: t("HOTEL"), value: "HOTEL" },
    { label: t("TOBACO_AND_LIQUOR_STORE"), value: "TOBACO_AND_LIQUOR_STORE" },
    { label: t("MULTICHANNEL"), value: "MULTICHANNEL" },
    { label: t("E-COMMERCE"), value: "E-COMMERCE" },
    { label: t("SUPERMARKET"), value: "SUPERMARKET" },
    { label: t("CORPORATEBUSINESS"), value: "CORPORATEBUSINESS" },
  ]);

  const [types, setTypes] = useState([]);

  const [areas, setAreas] = useState([]);
  const [countries, setCountries] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [grapes, setGrapes] = useState([]);
  const [regions, setRegions] = useState([]);
  // const [subRegions, setSubRegions] = useState([]);
  const [languages, setLanguages] = useState([
    {
      label: LanguagesKey.ZH,
      value: "zh",
    },
    {
      label: LanguagesKey.EN,
      value: "en",
    },
    {
      label: LanguagesKey.FR,
      value: "fr",
    },
  ]);
  const languageNavigation = [
    { label: t("LANGUAGE_FRENCH"), key: "fr" },
    { label: t("LANGUAGE_CHINESE"), key: "zh" },
    { label: t("LANGUAGE_ENGLISH"), key: "en" },
  ];
  const [nameActiveTab, setNameActiveTab] = useState(languageNavigation[0]);
  const [descActiveTab, setDescActiveTab] = useState(languageNavigation[0]);
  const [teamDescActiveTab, setTeamDescActiveTab] = useState(
    languageNavigation[0]
  );
  const [wineActiveTab, setWineActiveTab] = useState(languageNavigation[0]);
  const [awardActiveTab, setAwardActiveTab] = useState(languageNavigation[0]);
  const [desc, setDesc] = useState("");
  const [teamContent, setTeamContent] = useState("");
  const [wineContent, setWineContent] = useState("");

  const [userType, setUserType] = useState("");

  // useEffect(() => {
  //   if (companyId) {
  //     loadCompanyDesc(companyId);
  //   } else if (user?.userInfo?.type != UserType.ADMIN) {
  //     loadCompanyDesc(user?.userInfo?.companyId);
  //   }
  // }, [companyId]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (dataSource) {
      let temp = deepClone(formData);
      temp = dataSource;
      console.log("temp:", temp);
      setFormData(temp);
    }
  }, [dataSource]);

  useEffect(() => {
    if (formData) {
      setDesc(formData.desc);

      console.log("formData:", formData.team?.content);

      if (formData.team) {
        setTeamContent(formData?.team?.content);
      }

      if (formData.wine) {
        setWineContent(formData?.wine?.content);
      }
    }
  }, [descActiveTab, teamDescActiveTab, wineActiveTab]);

  useEffect(() => {
    if (formData?.location?.country && areas && areas.length > 0) {
      onCountrySelect(areas, formData?.location?.country);
    }
  }, [formData?.location?.country]);

  // useEffect(() => {
  //   if (formData?.location?.region && areas && areas.length > 0) {
  //     onRegionSelect(areas, formData?.location?.region);
  //   }
  // }, [formData?.location?.region]);

  useEffect(() => {
    setConfigData();
    initConfigData();
  }, [currentLanguage]);

  const loadCompanyDesc = async (coId) => {
    let tempCompanyDesc = await companyDetail(coId);
    console.log("tempCompanyDesc:", tempCompanyDesc);

    setDesc(tempCompanyDesc.desc);

    if (tempCompanyDesc.team) {
      setTeamContent(tempCompanyDesc?.team?.content);
    }

    if (tempCompanyDesc.wine) {
      setWineContent(tempCompanyDesc?.wine?.content);
    }

    setUserType(tempCompanyDesc.type);
    setFormData(tempCompanyDesc);
  };

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let {
      locations,
      types,
      bottles,
      capsules,
      sealed,
      classifications,
      categories,
    } = configData;

    loadCountry(locations.result);
    loadSelectData(types, ProductConfig.TYPES);
    loadSelectData(classifications, ProductConfig.CLASSIFICATIONS);

    let grapeList = await initProductGrapes();
    loadSelectData(grapeList, ProductConfig.GRAPES);
  };

  const initConfigData = async () => {
    let temp = deepClone(companyLabels);
    temp.map((mapItem) => {
      mapItem.label = t(mapItem.value);
    });
    setCompanyLabels(temp);
  };

  const loadSelectData = (data, flag) => {
    let result = getProductConfigForSelect(data, currentLanguage);
    if (result && result.length > 0) {
      switch (flag) {
        case ProductConfig.TYPES:
          setTypes(result);
          break;
        case ProductConfig.CLASSIFICATIONS:
          setClassifications(result);
          break;
        case ProductConfig.GRAPES:
          setGrapes(result);
          break;
        default:
          break;
      }
    }
  };

  const initProductGrapes = async () => {
    let pms = {
      pms: JSON.stringify({
        page: 0,
        per_page: 1000,
      }),
    };
    let result = await getProductGrapes(pms);
    return result;
  };

  const loadCountry = (locations) => {
    setAreas(locations);
    let result = getCountryForSelect(locations, currentLanguage,user.userInfo.type);
    setCountries(result);
  };

  const onCountrySelect = (locations, id) => {
    let result = getLocationsByParentId(locations, id, currentLanguage);
    setRegions(result);
  };

  // const onRegionSelect = (locations, id) => {
  //   console.log("onRegionSelect:", locations, id);
  //   let result = getLocationsByParentId(locations, id, currentLanguage);
  //   console.log("onRegionSelect:", result);
  //   setSubRegions(result);
  // };

  const setFormDataValue = (name, value) => {
    let temp = deepClone(formData);
    temp[name] = value;
    setFormData(temp);

    onChange && onChange(temp);

    console.log("setFormDataValue:", temp);
  };

  const setCompanyNameFormDataValue = (name, value) => {
    let temp = deepClone(formData);
    if (!temp["companyName"]) {
      temp["companyName"] = {};
    }
    if (name == "en") {
      temp["companyId"] = value.toLowerCase().replace(/ /g, "-");
    }
    temp["companyName"][name] = value;
    setFormData(temp);
    onChange && onChange(temp);
  };

  const setContentFormDataValue = (name, value) => {
    if (value == "<p></p>") {
      return;
    }

    console.log("setContentFormDataValue:", name, value);
    let temp = deepClone(formData);
    if (!temp["desc"]) {
      temp["desc"] = {};
    }
    temp["desc"][name] = value;
    setFormData(temp);
    onChange && onChange(temp);
  };

  const setLocationFormDataValue = (name, value) => {
    let temp = deepClone(formData);
    if (!temp.location) {
      temp.location = {};
    }
    temp.location[name] = value;
    setFormData(temp);

    onChange && onChange(temp);
  };

  const setLocationAddressFormDataValue = (name, value) => {
    let temp = deepClone(addressFormData);
    temp = value;
    setAddressFormData(temp);

    onChange && onChange(deepClone(formData));
    onAddressChange && onAddressChange(temp);
  };

  const setTeamFormDataValue = (name, value) => {
    let temp = deepClone(formData);
    if (!temp.team) {
      temp.team = {};
    }

    temp.team[name] = value;
    setFormData(temp);
    onChange && onChange(temp);
  };

  const setContentTeamFormDataValue = (name, value) => {
    if (value == "<p></p>") {
      return;
    }

    let temp = deepClone(formData);
    if (!temp.team) {
      temp.team = {};
    }
    if (!temp.team["content"]) {
      temp.team["content"] = {};
    }
    temp.team["content"][name] = value;
    setFormData(temp);
    console.log("setContentTeamFormDataValue:", temp);
    onChange && onChange(temp);
  };

  const setContentWineFormDataValue = (name, value) => {
    if (value == "<p></p>") {
      return;
    }

    let temp = deepClone(formData);
    if (!temp.wine) {
      temp.wine = {};
    }
    if (!temp.wine["content"]) {
      temp.wine["content"] = {};
    }
    temp.wine["content"][name] = value;
    setFormData(temp);
    console.log("setContentTeamFormDataValue:", temp);
    onChange && onChange(temp);
  };

  const setWineFormDataValue = (name, value) => {
    let temp = deepClone(formData);
    if (!temp.wine) {
      temp.wine = {};
    }

    temp.wine[name] = value;
    setFormData(temp);
    onChange && onChange(temp);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={t("ABOUT_US")}
        classTitle="title-red"
        head={
          <Link
            className={cn("button-stroke button-small", styles.button)}
            onClick={() => history.goBack()}
          >
            <Icon name="arrow-left" size="24" />
            <span>{t("BACK")}</span>
          </Link>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col} style={{ display: "none" }}>
              <TextInput
                className={styles.field}
                label={t("COMPANY_ID")}
                placeholder={t("COMPANY_ID")}
                name="companyId"
                type="text"
                tooltip=""
                required
                // onChange={setFormDataValue.bind(this, "companyId")}
                value={formData?.companyId}
                disabled={true}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              {languageNavigation.map((x, index) => (
                <div>
                  {x.key === nameActiveTab.key && (
                    <TextInput
                      className={styles.field}
                      label={
                        <div className={styles.labelWithTranslations}>
                          <span>{t("COMPANY_NAME")}</span>
                          {languageNavigation.map((x, index) => (
                            <button
                              className={cn(styles.translationLink, {
                                [styles.active]: x.key === nameActiveTab.key,
                              })}
                              onClick={() => setNameActiveTab(x)}
                              key={index}
                            >
                              {x.label}
                            </button>
                          ))}
                        </div>
                      }
                      name="companyName"
                      type="text"
                      tooltip=""
                      required
                      onChange={setCompanyNameFormDataValue.bind(this, x.key)}
                      value={
                        formData?.companyName
                          ? formData?.companyName[x.key]
                          : ""
                      }
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* <div
            className={cn(styles.nav, "tablet-hide")}
            style={{ marginBottom: "20px" }}
          >
            {languageNavigation.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x.key === descActiveTab.key,
                })}
                onClick={() => setDescActiveTab(x)}
                key={index}
              >
                {x.label}
              </button>
            ))}
          </div> */}
          <div className={styles.row}>
            <div className={styles.col}>
              {languageNavigation.map((x, index) => (
                <div>
                  {x.key === descActiveTab.key && (
                    <Editor
                      state={desc?.[descActiveTab.key]}
                      onChange={setContentFormDataValue.bind(this, x.key)}
                      classEditor={styles.editor}
                      label={
                        <div className={styles.labelWithTranslations}>
                          <span>{t("ABOUT_COMPANY")}</span>
                          {languageNavigation.map((x, index) => (
                            <button
                              className={cn(styles.translationLink, {
                                [styles.active]: x.key === descActiveTab.key,
                              })}
                              onClick={() => setDescActiveTab(x)}
                              key={index}
                            >
                              {x.label}
                            </button>
                          ))}
                        </div>
                      }
                      //tooltip={t("ABOUT_COMPANY_TIPS")}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
      <Card
        className={cn(styles.card, className)}
        title={t("COMPANY_INFORMATION")}
        classTitle="title-red"
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Dropdown
                className={styles.dropdown_field}
                label={t("COUNTRY")}
                tooltip=""
                value={formData?.location?.country}
                setValue={setLocationFormDataValue.bind(this, "country")}
                options={countries}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <Dropdown
                className={styles.dropdown_field}
                label={t("REGION")}
                tooltip=""
                value={formData?.location?.region}
                setValue={setLocationFormDataValue.bind(this, "region")}
                options={regions}
              />
            </div>
          </div>
          {/* <div className={styles.row}>
            <div className={styles.col}>
              <Dropdown
                className={styles.dropdown_field}
                label={t("SUB_REGION")}
                tooltip=""
                value={formData?.location?.subRegion}
                setValue={setLocationFormDataValue.bind(this, "subRegion")}
                options={subRegions}
              />
            </div>
          </div> */}
          <div className={styles.row}>
            <div className={styles.col}>
              {/* <TextInput
                className={styles.field}
                label={t("ADDRESS")}
                placeholder={t("ADDRESS")}
                name="address"
                tooltip=""
                required
                onChange={setLocationFormDataValue.bind(this, "address")}
                value={formData?.location?.address}
              /> */}
              <AddressSelect
                label={t("ADDRESS")}
                value={formData?.location?.address}
                country={formData?.location?.country}
                onChange={setLocationAddressFormDataValue.bind(this, "address")}
              />
            </div>
          </div>
          <div className={styles.table}>
            {userType != UserType.BUYER && (
              <>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <Dropdown
                      label={t("COMPANY_CATEGORY")}
                      tooltip=""
                      value={formData.label}
                      setValue={setFormDataValue.bind(this, "label")}
                      options={companyLabels}
                    />
                  </div>
                  <div className={styles.col}>
                    <TextInput
                      className={styles.field}
                      label={t("TOTAL_PRODUCTION")}
                      placeholder={t("TOTAL_PRODUCTION")}
                      name="production"
                      type="number"
                      tooltip=""
                      currency={t("BOTTLES")}
                      required
                      onChange={setFormDataValue.bind(this, "production")}
                      value={formData?.production}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <MultiSelect
                      className={styles.dropdown_field}
                      label={
                        t("PRODUCT_TYPE") + " (" + t("MULTIPLE_CHOICE") + ")"
                      }
                      placeholder={
                        t("PRODUCT_TYPE") + " (" + t("MULTIPLE_CHOICE") + ")"
                      }
                      tooltip=""
                      value={formData.productCategory}
                      onChange={setFormDataValue.bind(this, "productCategory")}
                      options={types}
                    />
                  </div>
                  <div className={styles.col}>
                    <TextInput
                      className={styles.field}
                      label={t("NUMBER_OF_BRAND")}
                      placeholder={t("NUMBER_OF_BRAND")}
                      name="brandAmount"
                      type="number"
                      tooltip=""
                      required
                      onChange={setFormDataValue.bind(this, "brandAmount")}
                      value={formData?.brandAmount}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  {/* <div className={styles.col}>
                    <MultiSelect
                      className={styles.dropdown_field}
                      label={t("PRODUCT_AREA")}
                      placeholder={t("PRODUCT_AREA")}
                      tooltip=""
                      value={formData.product_area}
                      onChange={setFormDataValue.bind(this, "product_area")}
                      options={countries}
                    />
                  </div> */}
                  <div className={styles.col}>
                    <MultiSelect
                      className={styles.dropdown_field}
                      label={
                        t("MAIN_MARKET") + " (" + t("MULTIPLE_CHOICE") + ")"
                      }
                      placeholder={
                        t("MAIN_MARKET") + " (" + t("MULTIPLE_CHOICE") + ")"
                      }
                      tooltip=""
                      value={formData.market}
                      onChange={setFormDataValue.bind(this, "market")}
                      options={countries}
                    />
                  </div>
                  <div className={styles.col}>
                    <TextInput
                      className={styles.field}
                      label={t("VINEYARD_SIZE")}
                      placeholder={t("VINEYARD_SIZE")}
                      name="VINEYARD_SIZE"
                      type="number"
                      currency={t("HECTARE")}
                      required
                      onChange={setFormDataValue.bind(this, "size")}
                      value={formData?.size}
                    />
                  </div>
                </div>
                {/* <div className={styles.row}>
                  <div className={styles.col}>
                    <Dropdown
                      label={t("CLASSIFICATIONS")}
                      tooltip=""
                      value={formData.classifications}
                      setValue={setFormDataValue.bind(this, "classifications")}
                      options={classifications}
                    />
                  </div>
                  <div className={styles.col}>
                    <MultiSelect
                      className={styles.dropdown_field}
                      label={t("GRAPE")}
                      placeholder={t("GRAPE")}
                      tooltip=""
                      value={formData.grapes}
                      onChange={setFormDataValue.bind(this, "grapes")}
                      options={grapes}
                    />
                  </div>
                </div> */}
                {/* <div className={styles.row}>
                  <div className={styles.col}>
                    <TextInput
                      className={styles.field}
                      label={t("WEBSITE")}
                      placeholder={t("WEBSITE")}
                      name="webSite"
                      tooltip=""
                      required
                      onChange={setFormDataValue.bind(this, "webSite")}
                      value={formData?.webSite}
                    />
                  </div>
                  <div className={styles.col}>
                    <TextInput
                      className={styles.field}
                      label={t("INSTAGRAM")}
                      placeholder={t("INSTAGRAM")}
                      name="instagram"
                      tooltip=""
                      required
                      onChange={setFormDataValue.bind(this, "instagram")}
                      value={formData?.instagram}
                    />
                  </div>
                </div> */}
                {/* <div className={styles.row}>
                  <div className={styles.col}>
                    <TextInput
                      className={styles.field}
                      label={t("FACEBOOK")}
                      placeholder={t("FACEBOOK")}
                      name="facebook"
                      tooltip=""
                      required
                      onChange={setFormDataValue.bind(this, "facebook")}
                      value={formData?.facebook}
                    />
                  </div>
                  <div className={styles.col}>
                    <TextInput
                      className={styles.field}
                      label={t("TWITTER")}
                      placeholder={t("TWITTER")}
                      name="twitter"
                      tooltip=""
                      required
                      onChange={setFormDataValue.bind(this, "twitter")}
                      value={formData?.twitter}
                    />
                  </div>
                </div> */}
              </>
            )}
            {userType == UserType.BUYER && (
              <>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <Dropdown
                      label={t("COMPANY_CATEGORY")}
                      tooltip=""
                      value={formData.label}
                      setValue={setFormDataValue.bind(this, "label")}
                      options={buyerLabels}
                    />
                  </div>
                  <div className={styles.col}>
                    <TextInput
                      className={styles.field}
                      label={t("WEBSITE")}
                      placeholder={t("WEBSITE")}
                      name="webSite"
                      tooltip=""
                      required
                      onChange={setFormDataValue.bind(this, "webSite")}
                      value={formData?.webSite}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <TextInput
                      className={styles.field}
                      label={t("INSTAGRAM")}
                      placeholder={t("INSTAGRAM")}
                      name="instagram"
                      tooltip=""
                      required
                      onChange={setFormDataValue.bind(this, "instagram")}
                      value={formData?.instagram}
                    />
                  </div>
                  <div className={styles.col}>
                    <TextInput
                      className={styles.field}
                      label={t("FACEBOOK")}
                      placeholder={t("FACEBOOK")}
                      name="facebook"
                      tooltip=""
                      required
                      onChange={setFormDataValue.bind(this, "facebook")}
                      value={formData?.facebook}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <TextInput
                      className={styles.field}
                      label={t("TWITTER")}
                      placeholder={t("TWITTER")}
                      name="twitter"
                      tooltip=""
                      required
                      onChange={setFormDataValue.bind(this, "twitter")}
                      value={formData?.twitter}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
      {/* <Card
        className={cn(styles.card, className)}
        title={t("MEDIAS")}
        classTitle="title-red"
      >
        <div className={styles.images} style={{ marginBottom: "20px" }}>
          <ImageUploader
            className={styles.field}
            title={t("CLICK_OR_DROP_IMAGE")}
            label={t("MODIFY_AVATAR")}
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            onChange={setFormDataValue.bind(this, "avatar")}
            dataSource={formData?.avatar}
            maxCount={1}
          />
        </div>
        <div className={styles.images}>
          {userType != UserType.BUYER && (
            <ImageUploader
              className={styles.field}
              title={t("CLICK_OR_DROP_IMAGE")}
              label={t("PRODUCT_PHOTOS")}
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              onChange={setFormDataValue.bind(this, "medias")}
              dataSource={formData?.medias}
            />
          )}
          {userType == UserType.BUYER && (
            <ImageUploader
              className={styles.field}
              title={t("COVER_PICTURES")}
              label={t("COVER_PICTURES")}
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              onChange={setFormDataValue.bind(this, "medias")}
              dataSource={formData?.medias}
            />
          )}
        </div>
      </Card> */}
      {userType != UserType.BUYER && (
        <>
          {/* <Card
            className={cn(styles.card, className)}
            title={t("OUR_TEAM")}
            classTitle="title-red"
          >
            <div className={styles.images} style={{ marginBottom: "20px" }}>
              {languageNavigation.map((x, index) => (
                <div>
                  {x.key === teamDescActiveTab.key && (
                    <Editor
                      state={teamContent?.[teamDescActiveTab.key]}
                      onChange={setContentTeamFormDataValue.bind(this, x.key)}
                      classEditor={styles.editor}
                      label={
                        <div className={styles.labelWithTranslations}>
                          <span>{t("ABOUT_TEAM")}</span>
                          {languageNavigation.map((x, index) => (
                            <button
                              className={cn(styles.translationLink, {
                                [styles.active]:
                                  x.key === teamDescActiveTab.key,
                              })}
                              onClick={() => setTeamDescActiveTab(x)}
                              key={index}
                            >
                              {x.label}
                            </button>
                          ))}
                        </div>
                      }
                      //tooltip={t("ABOUT_COMPANY_TIPS")}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className={styles.images}>
              <ImageUploader
                className={styles.field}
                title={t("CLICK_OR_DROP_IMAGE")}
                label={t("TEAM_PHOTO")}
                //tooltip=""
                onChange={setTeamFormDataValue.bind(this, "medias")}
                maxCount={1}
                dataSource={formData?.team?.medias}
              />
            </div>
          </Card> */}
          {/* <Card
            className={cn(styles.card, className)}
            title={t("WINES")}
            classTitle="title-red"
          >
            <div className={styles.images} style={{ marginBottom: "20px" }}>
              {languageNavigation.map((x, index) => (
                <div>
                  {x.key === wineActiveTab.key && (
                    <Editor
                      state={wineContent?.[wineActiveTab.key]}
                      onChange={setContentWineFormDataValue.bind(this, x.key)}
                      classEditor={styles.editor}
                      label={
                        <div className={styles.labelWithTranslations}>
                          <span>{t("ABOUT_WINE")}</span>
                          {languageNavigation.map((x, index) => (
                            <button
                              className={cn(styles.translationLink, {
                                [styles.active]: x.key === wineActiveTab.key,
                              })}
                              onClick={() => setWineActiveTab(x)}
                              key={index}
                            >
                              {x.label}
                            </button>
                          ))}
                        </div>
                      }
                      //tooltip=""
                    />
                  )}
                </div>
              ))}
            </div>
            <div className={styles.images}>
              <ImageUploader
                className={styles.field}
                title={t("CLICK_OR_DROP_IMAGE")}
                label={t("WINE_PHOTO")}
                //tooltip=""
                onChange={setWineFormDataValue.bind(this, "medias")}
                maxCount={1}
                dataSource={formData?.wine?.medias}
              />
            </div>
            <div className={styles.table} style={{ marginTop: "20px" }}>
              <div className={styles.row} style={{ marginTop: "20px" }}>
                <div className={styles.col}>
                  <TextInput
                    className={styles.field}
                    label={t("PRICE_MIN")}
                    name="name"
                    type="text"
                    tooltip=""
                    required
                    onChange={setWineFormDataValue.bind(this, "priceStart")}
                    value={formData?.wine?.priceStart}
                  />
                </div>
                <div className={styles.col}>
                  <TextInput
                    className={styles.field}
                    label={t("PRICE_MAX")}
                    name="name"
                    type="text"
                    tooltip=""
                    required
                    onChange={setWineFormDataValue.bind(this, "priceEnd")}
                    value={formData?.wine?.priceEnd}
                  />
                </div>
              </div>
            </div>
          </Card> */}
          {/* <Award
            onChange={setFormDataValue.bind(this, "awards")}
            dataSource={formData?.awards}
          /> */}
        </>
      )}
      <br />
      {/* <Card
        className={cn(styles.card, className)}
        title={t("LANGUAGE_SETTINGS")}
        classTitle="title-red"
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Dropdown
                className={styles.dropdown_field}
                label={t("LANGUAGE")}
                tooltip=""
                value={formData?.language}
                setValue={setFormDataValue.bind(this, "language")}
                options={languages}
              />
            </div>
          </div>
        </div>
      </Card> */}
    </>
  );
}
