import React, { useState, useEffect } from "react";
import { Form, Button } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { stockCreate, stockUpdate } from "../../../../api/stocks";
import InputNumberBasic from "../../../common/input/InputNumberBasic";
import SelectBasic from "../../../common/select/SelectBasic";
import { statusCode } from "../../../../constants/statusCode.enum";
import { loadProductsForSelect } from "../../../../api/product";

export default function StockCreate() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const { state } = useLocation();
  const [form] = Form.useForm();

  const [warehouseId, setWarehouseId] = useState("");
  const [id, setId] = useState("");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    loadProductSelectOptions(user.userInfo.companyId);
    if (state) {
      let { warehouseId, id, productId, count } = state;
      if (warehouseId) {
        setWarehouseId(warehouseId);
      }
      if (id) {
        setId(id);
        form.setFieldsValue({ product: productId, count });
      }
    }
  }, [form, state]);

  const loadProductSelectOptions = async (coId) => {
    let result = await loadProductsForSelect(coId);
    setProducts(result);
  };

  const onFinish = async (values) => {
    let { product, count } = values;
    let selectProduct = products.filter((item) => {
      return item.value === product;
    });
    if (selectProduct && selectProduct.length > 0) {
      let { value, label } = selectProduct[0];
      let pms = {
        warehouseId,
        productId: value,
        productTitle: label,
        count,
        status: 0,
      };
      let data;
      if (id) {
        pms._id = id;
        data = await stockUpdate(pms);
      } else {
        data = await stockCreate(pms);
      }
      if (data && data.statusCode === statusCode.SUCCESS) {
      }
    }
  };

  return (
    <div>
      <div>{t("StockCreate")}</div>
      <Form form={form} onFinish={onFinish}>
        <SelectBasic
          name="product"
          label="Product"
          required={true}
          data={products}
        />
        <InputNumberBasic
          name="count"
          label="Amount"
          required={true}
          max={20000}
          min={0}
        />
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
