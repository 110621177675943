import React, { useEffect, useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../Icon";
import { useHistory } from "react-router";
import { Path, PathTools } from "../../../../constants/path";

const Control = ({ className, productId, isMyself }) => {

  const history = useHistory();

  const [mySelf, setMySelf] = useState(false);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    setMySelf(isMyself);
    initActions(isMyself);
  }, [isMyself]);

  const initActions = (mySelfVal) => {
    let temp = [];
    if (mySelfVal) {
      temp = [
        // {
        //   icon: "edit",
        //   action: () => console.log("edit"),
        // },
        // {
        //   icon: "trash",
        //   action: () => console.log("remove"),
        // },
        {
          icon: "arrow-right",
          action: () => onNavigationToProductInfo(productId),
        },
      ];
    } else {
      temp = [
        {
          icon: "arrow-right",
          action: () => onNavigationToProductInfo(productId),
        },
      ];
    }

    setActions(temp);
  };

  const onNavigationToProductInfo = (id) => {
    history.push(PathTools.joinPath(Path.productInfo, id));
  };

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button className={styles.button} key={index} onClick={x.action}>
            <Icon name={x.icon} size="20" />
          </button>
        ))}
      </div>
    </>
  );
};

export default Control;
