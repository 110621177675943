import { useUserId } from "../../../contexts/UserIdProvider";
import { useEffect, useState } from "react";
import DashboardWinery from "./DashboardWinery";
import DashboardImporter from "./DashboardImporter";
import NotAuthorized from "../../others/NotAuthorized";
import { UserType } from "../../../constants/usersType.enum";
import "../../../components/private-route/style/baseprivateroute-dashboard.less";
import "./style/dashboard.less";
import { useTranslation } from "react-i18next";
import totalSalesIcon from "../../../resource/icons/dashboard/common/total_sales.svg";
import numOfClientsIcon from "../../../resource/icons/dashboard/common/num_of_clients.svg";
import totalOrdersIcon from "../../../resource/icons/dashboard/common/total_orders.svg";
import totalProductsIcon from "../../../resource/icons/dashboard/common/total_products.svg";
import productClicksIcon from "../../../resource/icons/dashboard/common/product_clicks.svg";
import pageViewIcon from "../../../resource/icons/dashboard/common/page_views.svg";
import newContactsIcon from "../../../resource/icons/dashboard/common/new_contacts.svg";
import lockIcon from "../../../resource/icons/dashboard/common/lock.svg";
import postVideoIcon from "../../../resource/icons/dashboard/common/post_video.svg";
import postPicturesIcon from "../../../resource/icons/dashboard/common/post_pictures.svg";
import addContactIcon from "../../../resource/icons/dashboard/common/add_contact.svg";
import sendMessageIcon from "../../../resource/icons/dashboard/common/send_message.svg";
import replayedCommentIcon from "../../../resource/icons/dashboard/common/replayed_comment.svg";
import usersIcon from "../../../resource/icons/dashboard/common/users.svg";
import { RoleLimit } from "../../../constants/roleType.enum";
import {
  Chart,
  Interval,
  Axis,
  Tooltip,
  Coordinate,
  Legend,
  View,
  Annotation,
  getTheme,
  Line,
  Point,
  Interaction,
} from "bizcharts";
import { customerImporterPage, getPageOrders } from "../../../api/orders";
import {
  ProductTableMaxCount,
  TableSourceMaxCount,
} from "../../../constants/constants";
import { OrderStatus } from "../../../constants/statusCode.enum";
import {
  fetchProductClicks,
  loadProducts as reqProducts,
} from "../../../api/product";
import { fetchProfileClicks } from "../../../api/user";
import { browseRecordList } from "../../../api/browseRecord";
import { dateFormat } from "../../../utils/date";
import { BrowseRecordType } from "../../../constants/browseRecord.enum";
import { getCompanyUsers } from "../../../api/company";
import { contactOnLineStatus } from "../../../api/contact";
import AvatarBasic from "../../common/avatar/AvatarBasic";
import { Modal } from "antd";
import OrdersPanel from "./orders-panel/OrdersPanel";
import SalesPanel from "./sales-panel/SalesPanel";
import ProductsPanel from "./products-panel/ProductsPanel";
import { checkLimit } from "../../../api/common";
import { useHistory } from "react-router";
import { toastError } from "../../../api/toast";
import ClientsPanel from "./clients-panel/ClientsPanel";

export default function Dashboard() {
  const { t, i18n } = useTranslation();
  let user = useUserId();
  const history = useHistory();
  const { userId } = user;
  const [isSupplier, setIsSupplier] = useState(false);
  const [isWinery, setIsWinery] = useState(false);
  const [isImporter, setIsImporter] = useState(false);
  const [haveRight, setHaveRight] = useState(false);

  const [totalTransaction, setTotalTransaction] = useState(0);
  const [customerNum, setCustomerNum] = useState(0);
  const [orderNum, setOrderNum] = useState(0);
  const [companyUserList, setCompanyUserList] = useState([]);
  const [companyUserOnLineList, setCompanyUserOnLineList] = useState([]);
  const [productsCount, setProductsCount] = useState(0);
  const [productClickCount, setProductClickCount] = useState(0);
  const [profileClickCount, setProfileClickCount] = useState(0);
  const [profileComplete, setProfileComplete] = useState(0);
  const [profileCompletePercent, setProfileCompletePercent] = useState([]);

  const [recentActivities, setRecnetActivities] = useState([]);

  const [salesPanelVisible, setSalesPanelVisible] = useState(false);
  const [ordersPanelVisible, setOrdersPanelVisible] = useState(false);
  const [productsPanelVisible, setProductsPanelVisible] = useState(false);
  const [clientsPanelVisible, setClientsPanelVisible] = useState(false);

  const brandFill = getTheme().colors10[0];

  useEffect(() => {
    // loadFans();
    // loadFollowed();
    // loadSteps();
    loadCustomers();
    // loadLoginRecord();
    // loadDemandOffers();
    loadOrders();
    initProducts();
    loadProductClicks();
    loadProfileClicks();
    loadRecentActivities();
    loadCompanyUsers(user.user.userInfo.companyId);
    loadCompanyUserOnLineStatus(user.user.userInfo.companyId);
    // loadImporterGroupOrders();
    let userInfo = user.user.userInfo;
    if (userInfo) {
      let total = 0;
      if (userInfo.firstName) {
        total += 10;
      }
      if (userInfo.lastName) {
        total += 10;
      }
      if (userInfo.email) {
        total += 10;
      }
      if (userInfo.nickName) {
        total += 10;
      }
      if (userInfo.emailVerified) {
        total += 10;
      }
      if (userInfo.picture) {
        total += 10;
      }
      if (userInfo.signature) {
        total += 10;
      }
      if (userInfo.phoneNumber) {
        total += 10;
      }
      if (userInfo.country) {
        total += 10;
      }

      setProfileComplete(total);
      let complete = {
        type: t("PROFILE_COMPLETE"),
        percent: total,
      };
      let complete1 = {
        type: t("PROFILE_COMPLETE1"),
        percent: 100 - total,
      };
      setProfileCompletePercent([complete, complete1]);
    }
  }, []);

  useEffect(() => {
    setIsSupplier(user.user.userInfo.type == UserType.SUPPLIER ? true : false);
    if (user.user.userInfo.type == UserType.SUPPLIER) {
      setIsWinery(true);
    } else if (
      user.user.userInfo.type == UserType.BUYER ||
      user.user.userInfo.type == UserType.SUPPLIERBUYER
    ) {
      setIsImporter(true);
    } else {
      setHaveRight(true);
    }
  });

  const loadCustomers = async () => {
    let pms = { page: 0, per_page: 20, coId: user.user.userInfo.companyId };
    let result = await customerImporterPage(pms);
    if (result && result.total > 0) {
      setCustomerNum(result.total);
    }
  };

  const loadOrders = async () => {
    let pms = {
      coId: user.user.userInfo.companyId,
      page: 0,
      per_page: TableSourceMaxCount,
      payStatus: OrderStatus.Payed,
    };
    let data = await getPageOrders(pms);
    if (data && data.total > 0) {
      setOrderNum(data.total);
      let num = 0;
      for (let i = 0; i < data.result.length; i++) {
        const element = data.result[i];
        for (let j = 0; j < element.orderProducts.length; j++) {
          const pro = element.orderProducts[j];
          num += pro.price;
        }
      }
      setTotalTransaction(num);
    }
  };

  const initProducts = async () => {
    let pms = {
      page: 0,
      per_page: ProductTableMaxCount,
      coId: user.user.userInfo.companyId,
    };
    let result = await reqProducts(pms);
    setProductsCount(result?.length);
  };

  const loadCompanyUsers = async (coId) => {
    let pms = { coId: coId };
    let result = await getCompanyUsers(pms);
    if (result && result.length > 0) {
      setCompanyUserList(result.reverse());
    }
  };

  const loadCompanyUserOnLineStatus = async (coId) => {
    let pms = { coId };
    let result = await contactOnLineStatus(pms);
    if (result && result.data && result.data.length > 0) {
      let list = [];
      result.data.forEach((item) => {
        for (const key in item) {
          if (item[key] === "online") {
            list.push(key);
          }
        }
      });
      setCompanyUserOnLineList(list);
    }
  };

  const getUserOnLineStatus = (_id) => {
    return companyUserOnLineList.includes(_id) ? "on" : "off";
  };

  const loadProductClicks = async () => {
    let pms = {
      coId: user.user.userInfo.companyId,
    };
    let result = await fetchProductClicks(pms);
    setProductClickCount(result.total);
  };

  const loadProfileClicks = async () => {
    let pms = {
      coId: user.user.userInfo.companyId,
    };
    let result = await fetchProfileClicks(pms);
    console.log("loadProfileClicks:", result);
    setProfileClickCount(result ? result?.total : 0);
  };

  const loadRecentActivities = async () => {
    let pms = {
      coId: user.user.userInfo.companyId,
      page: 0,
      per_page: 20,
    };
    let list = await browseRecordList(pms);
    console.log("recentActivities list:", list);
    if (list && list.length > 0) {
      setRecnetActivities(list);
    }
  };

  const getRecentActivityContent = (activityItem) => {
    return (
      <div className="activity-item">
        <div className="activity-content">
          <div className="activity-time">
            {dateFormat(activityItem.createdAt, "hh-mm-ss")}
          </div>
          <img
            src={renderContentImage(activityItem.busType)}
            className="activity-icon"
          />
          <div className="activity-info">
            <div className="activity-type">
              {renderContentType(activityItem)}
            </div>
            <div>
              <text className="user-name">
                {activityItem.userInfo[0].nickName}
              </text>
              <text className="desc">{renderContentType(activityItem)}</text>
            </div>
          </div>
        </div>
        <div className="activity-divider"></div>
      </div>
    );
  };

  const renderContentType = (item) => {
    let { busType } = item;

    let contentType = "";

    switch (busType) {
      case BrowseRecordType.POST_FEED:
        contentType += t("POST_FEED");
        break;
      case BrowseRecordType.POST_VIDEO:
        contentType += t("POST_VIDEO");
        break;
      case BrowseRecordType.FEED_COMMENT:
        contentType += t("REPLY_COMMENT_FROM");
        break;
      case BrowseRecordType.SHARE_FEED:
        contentType += t("SHARED_FEED");
        break;
      case BrowseRecordType.SHARE_VIDEO:
        contentType += t("SHARED_VIDEO");
        break;
      case BrowseRecordType.REPLAY_COMMENT:
        contentType += t("REPLIED_COMMENT_FROM");
        break;
      case BrowseRecordType.ADD_PRODUCT:
        contentType += t("ADD_A_PRODUCT");
        break;
      case BrowseRecordType.EDIT_PRODUCT:
        contentType += t("EDIT_A_PRODUCT");
        break;
      case BrowseRecordType.DELETE_PRODUCT:
        contentType += t("DELETE_A_PRODUCT");
        break;
      case BrowseRecordType.SEARCH_PRODUCT:
        contentType += t("SEARCHED");
        break;
      default:
        break;
    }

    return <div className="content-type">{contentType}</div>;
  };

  const renderContentImage = (busType) => {
    let result;

    switch (busType) {
      case BrowseRecordType.POST_FEED:
        result = postPicturesIcon;
        break;
      case BrowseRecordType.POST_VIDEO:
        result = postVideoIcon;
        break;
      case BrowseRecordType.FEED_COMMENT:
        result = replayedCommentIcon;
        break;
      case BrowseRecordType.SHARE_FEED:
        result = postPicturesIcon;
        break;
      case BrowseRecordType.SHARE_VIDEO:
        result = postVideoIcon;
        break;
      case BrowseRecordType.REPLAY_COMMENT:
        result = replayedCommentIcon;
        break;
      case BrowseRecordType.ADD_PRODUCT:
        result = postVideoIcon;
        break;
      // case BrowseRecordType.EDIT_PRODUCT:
      //   contentType += t("EDIT_A_PRODUCT");
      //   break;
      // case BrowseRecordType.DELETE_PRODUCT:
      //   contentType += t("DELETE_A_PRODUCT");
      //   break;
      // case BrowseRecordType.SEARCH_PRODUCT:
      //   contentType += t("SEARCHED");
      //   break;
      default:
        break;
    }

    return result;
  };

  const myData = [
    { type: t("IMPORTER"), percent: 0.7 },
    { type: t("DISTRIBUTORS"), percent: 0.1 },
    { type: t("COMPANY"), percent: 0.1 },
    { type: t("WINERY"), percent: 0.1 },
  ];
  const myContent = {
    siteCode: "电商",
    title: "注册成功率",
    percent: "66.66%",
  };

  // 数据源
  const data = [
    {
      month: "Jan",
      city: "Tokyo",
      temperature: 7,
    },
    {
      month: "Jan",
      city: "London",
      temperature: 3.9,
    },
    {
      month: "Feb",
      city: "Tokyo",
      temperature: 6.9,
    },
    {
      month: "Feb",
      city: "London",
      temperature: 4.2,
    },
    {
      month: "Mar",
      city: "Tokyo",
      temperature: 9.5,
    },
    {
      month: "Mar",
      city: "London",
      temperature: 5.7,
    },
    {
      month: "Apr",
      city: "Tokyo",
      temperature: 14.5,
    },
    {
      month: "Apr",
      city: "London",
      temperature: 8.5,
    },
    {
      month: "May",
      city: "Tokyo",
      temperature: 18.4,
    },
    {
      month: "May",
      city: "London",
      temperature: 11.9,
    },
    {
      month: "Jun",
      city: "Tokyo",
      temperature: 21.5,
    },
    {
      month: "Jun",
      city: "London",
      temperature: 15.2,
    },
    {
      month: "Jul",
      city: "Tokyo",
      temperature: 25.2,
    },
    {
      month: "Jul",
      city: "London",
      temperature: 17,
    },
    {
      month: "Aug",
      city: "Tokyo",
      temperature: 26.5,
    },
    {
      month: "Aug",
      city: "London",
      temperature: 16.6,
    },
    {
      month: "Sep",
      city: "Tokyo",
      temperature: 23.3,
    },
    {
      month: "Sep",
      city: "London",
      temperature: 14.2,
    },
    {
      month: "Oct",
      city: "Tokyo",
      temperature: 18.3,
    },
    {
      month: "Oct",
      city: "London",
      temperature: 10.3,
    },
    {
      month: "Nov",
      city: "Tokyo",
      temperature: 13.9,
    },
    {
      month: "Nov",
      city: "London",
      temperature: 6.6,
    },
    {
      month: "Dec",
      city: "Tokyo",
      temperature: 9.6,
    },
    {
      month: "Dec",
      city: "London",
      temperature: 4.8,
    },
  ];

  function ActiveChart() {
    return (
      <Chart
        scale={{ temperature: { min: 0 } }}
        padding={[10, 20, 50, 40]}
        autoFit
        height={132}
        data={data}
      >
        <Line shape="smooth" position="month*temperature" color="#1B3BAC" />
        <Point position="month*temperature" color="#1B3BAC" />
        {/* <Tooltip shared={true} showCrosshairs /> */}
        <Legend
          visible={false}
          name="city"
          filter={(city) => {
            return city === "London";
          }}
          itemName={{
            style: {
              fill: "1B3BAC",
            },
          }}
        />
        <Interaction type="legend-filter" />
      </Chart>
    );
  }

  function VisitorChart() {
    return (
      <Chart
        placeholder={false}
        height={120}
        width={120}
        padding="auto"
        autoFit
      >
        <Legend visible={false} />
        {/* 绘制图形 */}
        <View
          data={myData}
          scale={{
            percent: {
              formatter: (val) => {
                return (val * 100).toFixed(2) + "%";
              },
            },
          }}
        >
          <Coordinate type="theta" innerRadius={0.45} />
          <Interval
            position="percent"
            adjust="stack"
            // color="type"
            // color={["type", ["rgba(100, 100, 255, 0.6)", "#eee"]]}
            color={["type", ["#52B9CE", "#3E6FA4", "#F3B139", "#63AE64"]]}
            size={32}
            // style={{ fillOpacity: 0.6 }}
            // label={['type', {offset: 40}]}
            // {...intervalConfig}
          />
        </View>
      </Chart>
    );
  }

  const showPanel = (fun) => {
    if (checkLimit(user.user.userInfo, RoleLimit.PRODUCT_MANAGEMENT, history)) {
      fun(true);
    }
  };

  const onUserItemIMChat = (userItem) => {
    history.push("/imchat-deprecated", { id: userItem._id });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "0px auto",
      }}
    >
      <div className="dashboard-panel">
        <div className="basic-stat">
          <div className="stat-part">
            <div className="stat-row">
              <div
                className="stat-item total-sales"
                onClick={showPanel.bind(this, setSalesPanelVisible)}
              >
                <div className="title">{t("TOTAL_SALES")}</div>
                <div className="stat-item-content">
                  <img src={totalSalesIcon} />
                  <div className="stat-number">${totalTransaction}</div>
                </div>
                <div className="desc">{t("THIS_MONTH")}</div>
              </div>
              <div
                className="stat-item num-of-clients"
                onClick={showPanel.bind(this, setClientsPanelVisible)}
              >
                <div className="title">{t("NUMBER_OF_CLIENTS")}</div>
                <div className="stat-item-content">
                  <img src={numOfClientsIcon} />
                  <div className="stat-number">{customerNum}</div>
                </div>
                <div className="desc">{t("IN_TOTAL")}</div>
              </div>
              <div
                className="stat-item total-orders"
                onClick={showPanel.bind(this, setOrdersPanelVisible)}
              >
                <div className="title">{t("TOTAL_ORDERS")}</div>
                <div className="stat-item-content">
                  <img src={totalOrdersIcon} />
                  <div className="stat-number">{orderNum}</div>
                </div>
                <div className="desc">{t("THIS_MONTH")}</div>
              </div>
              <div
                className="stat-item total-products"
                onClick={showPanel.bind(this, setProductsPanelVisible)}
              >
                <div className="title">{t("TOTAL_PRODUCTS")}</div>
                <div className="stat-item-content">
                  <img src={totalProductsIcon} />
                  <div className="stat-number">{productsCount}</div>
                </div>
                <div className="desc">{t("THIS_MONTH")}</div>
              </div>
            </div>
            <div
              className="stat-row"
              style={{
                marginTop: "15px",
              }}
            >
              <div className="stat-item1">
                <div className="title-container">
                  <div className="title-placeholder"></div>
                  <div className="title">{t("PRODUCT_CLICKS")}</div>
                  <img src={lockIcon} />
                </div>
                <div className="stat-item-content">
                  <img src={productClicksIcon} />
                  <div className="stat-number">{productClickCount}</div>
                </div>
                <div className="desc">{t("THIS_WEEK")}</div>
              </div>
              <div className="stat-item1">
                <div className="title-container">
                  <div className="title-placeholder"></div>
                  <div className="title">{t("PAGE_VIEWS")}</div>
                  <img src={lockIcon} />
                </div>
                <div className="stat-item-content">
                  <img src={pageViewIcon} />
                  <div className="stat-number">{profileClickCount}</div>
                </div>
                <div className="desc">{t("THIS_WEEK")}</div>
              </div>
              <div className="stat-item1">
                <div className="title-container">
                  <div className="title-placeholder"></div>
                  <div className="title">{t("NEW_CONTACTS")}</div>
                  <img src={lockIcon} />
                </div>
                <div className="stat-item-content">
                  <img src={newContactsIcon} />
                  <div className="stat-number">{customerNum}</div>
                </div>
                <div className="desc">{t("TODAY")}</div>
              </div>
            </div>
          </div>
          <div className="users-part">
            <div className="users-basic-info">
              <div className="title">
                <div className="label">{t("SYSTEM_USERS")}</div>
                <img src={usersIcon} />
                <div className="label" style={{ color: "#FFFFFF" }}>
                  {t("SYSTEM_USERS")}
                </div>
              </div>
              <div className="count-panel">
                {t("TOTAL")}
                {companyUserList.length}
              </div>
            </div>
            <div className="users-part-divider"></div>
            {companyUserList.length == 1 && (
              <div
                className="user-list-one-panel"
                onClick={onUserItemIMChat.bind(this, companyUserList[0])}
              >
                <AvatarBasic
                  picture={companyUserList[0].avatar}
                  size={61}
                ></AvatarBasic>
                <div className="user-name">{companyUserList[0].nickName}</div>
                <div className="role-name">
                  {companyUserList[0].roleInfo.name == "ADMIN"
                    ? t("ADMIN")
                    : companyUserList[0].roleInfo.name}
                </div>
                <div className="online-status">
                  {getUserOnLineStatus(companyUserList[0]._id) == "on"
                    ? t("ON_LINE")
                    : t("OFF_LINE")}
                </div>
              </div>
            )}
            {companyUserList.length > 1 && (
              <div className="user-list-multi-panel">
                {companyUserList.map((mapItem, mapIndex) => {
                  return (
                    <div
                      className="user-item-panel"
                      onClick={onUserItemIMChat.bind(this, mapItem)}
                    >
                      <AvatarBasic
                        picture={mapItem.avatar}
                        size={51}
                      ></AvatarBasic>
                      <div className="center-part">
                        <div className="user-name">
                          {mapItem.nickName
                            ? mapItem.nickName
                            : mapItem.userName}
                        </div>
                        <div className="role-name">
                          {mapItem.roleInfo.name == "ADMIN"
                            ? t("ADMIN")
                            : mapItem.roleInfo.name}
                        </div>
                      </div>
                      <div className="online-status">
                        {getUserOnLineStatus(mapItem._id) == "on"
                          ? t("ON_LINE")
                          : t("OFF_LINE")}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="profile-stat">
          <div className="profile-stat-row">
            <div className="profile-stat-item">
              <div>
                <div className="title-container">
                  <div className="sider"></div>
                  <div className="title">{t("PROFILE_COMPLETE")}</div>
                </div>
                <div className="profile-number">{profileComplete}%</div>
              </div>
              <div className="stat-chart">
                <Chart placeholder={false} height={150} padding="auto" autoFit>
                  <Legend visible={false} />
                  {/* 绘制图形 */}
                  <View
                    data={profileCompletePercent}
                    scale={{
                      percent: {
                        formatter: (val) => {
                          return (val * 100).toFixed(2) + "%";
                        },
                      },
                    }}
                  >
                    <Coordinate type="theta" innerRadius={0.45} />
                    <Interval
                      position="percent"
                      adjust="stack"
                      // color="type"
                      // color={["type", ["rgba(100, 100, 255, 0.6)", "#eee"]]}
                      color={["type", ["#F3B139", "#FBF0F1"]]}
                      size={32}
                      // style={{ fillOpacity: 0.6 }}
                      // label={['type', {offset: 40}]}
                      // {...intervalConfig}
                    />
                  </View>
                </Chart>
                {/* {profileRing(myData, myContent, {
                  style: { fillOpacity: 0.6 },
                  size: [
                    "type",
                    (type) => {
                      return type === "已完成" ? 12 : 6;
                    },
                  ],
                })} */}
              </div>
            </div>
            <div className="profile-stat-item active-stat-item">
              <div className="title-container">
                <div className="sider"></div>
                <div className="title">{t("ACTIVE")}</div>
              </div>
              <div className="active-chart">
                <ActiveChart />
              </div>
            </div>
            <div className="profile-stat-item visitor-stat-item">
              <div className="title-container">
                <div className="sider"></div>
                <div className="title">{t("VISITOR_ANALYS")}</div>
              </div>
              <div
                className="active-chart"
                style={{
                  display: "flex",
                }}
              >
                <VisitorChart />
                <div
                  style={{
                    marginLeft: "20px",
                  }}
                >
                  <div className="item-row">
                    <div className="dot"></div>
                    <div className="type">{t("IMPORTERS")}</div>
                  </div>
                  <div className="item-row">
                    <div
                      className="dot"
                      style={{
                        backgroundColor: "#3E6FA4",
                      }}
                    ></div>
                    <div className="type">{t("DISTRIBUTORS")}</div>
                  </div>
                  <div className="item-row">
                    <div
                      className="dot"
                      style={{
                        backgroundColor: "#F3B139",
                      }}
                    ></div>
                    <div className="type">{t("COMPANY")}</div>
                  </div>
                  <div className="item-row">
                    <div
                      className="dot"
                      style={{
                        backgroundColor: "#63AE64",
                      }}
                    ></div>
                    <div className="type">{t("WINERY")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-part">
          <div className="recent-activities">
            <div className="title">{t("RECENT_ACTIVITIES")}</div>
            <div className="content-panel">
              {recentActivities.map((mapItem, mapIndex) => {
                {
                  return <div>{getRecentActivityContent(mapItem)}</div>;
                }
              })}
              {/* <div className="activity-item">
                <div className="activity-content">
                  <div className="activity-time">42 Mins Ago</div>
                  <img src={postVideoIcon} className="activity-icon" />
                  <div className="activity-info">
                    <div className="activity-type">Post video</div>
                    <div>
                      <text className="user-name">Xuyi</text>
                      <text className="desc">Posted a video</text>
                    </div>
                  </div>
                </div>
                <div className="activity-divider"></div>
              </div>
              <div className="activity-item">
                <div className="activity-content">
                  <div className="activity-time">1Day Ago</div>
                  <img src={postPicturesIcon} className="activity-icon" />
                  <div className="activity-info">
                    <div className="activity-type">Post Picturs</div>
                    <div>
                      <text className="user-name">Xuyi</text>
                      <text className="desc">Posted Pictures</text>
                    </div>
                  </div>
                </div>
                <div className="activity-divider"></div>
              </div>
              <div className="activity-item">
                <div className="activity-content">
                  <div className="activity-time">42 Mins Ago</div>
                  <img src={addContactIcon} className="activity-icon" />
                  <div className="activity-info">
                    <div className="activity-type">Post video</div>
                    <div>
                      <text className="user-name">Xuyi</text>
                      <text className="desc">Posted a video</text>
                    </div>
                  </div>
                </div>
                <div className="activity-divider"></div>
              </div>
              <div className="activity-item">
                <div className="activity-content">
                  <div className="activity-time">42 Mins Ago</div>
                  <img src={sendMessageIcon} className="activity-icon" />
                  <div className="activity-info">
                    <div className="activity-type">Post video</div>
                    <div>
                      <text className="user-name">Xuyi</text>
                      <text className="desc">Posted a video</text>
                    </div>
                  </div>
                </div>
                <div className="activity-divider"></div>
              </div>
              <div className="activity-item">
                <div className="activity-content">
                  <div className="activity-time">42 Mins Ago</div>
                  <img src={replayedCommentIcon} className="activity-icon" />
                  <div className="activity-info">
                    <div className="activity-type">Post video</div>
                    <div>
                      <text className="user-name">Xuyi</text>
                      <text className="desc">Posted a video</text>
                    </div>
                  </div>
                </div>
                <div className="activity-divider"></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={null}
        visible={salesPanelVisible}
        footer={null}
        closable={false}
        className="orders-panel-modal"
        onCancel={() => setSalesPanelVisible(false)}
        width={1005}
        height={542}
      >
        <SalesPanel />
      </Modal>
      <Modal
        title={null}
        visible={ordersPanelVisible}
        footer={null}
        closable={false}
        className="orders-panel-modal"
        onCancel={() => setOrdersPanelVisible(false)}
        width={1005}
        height={542}
      >
        <OrdersPanel />
      </Modal>
      <Modal
        title={null}
        visible={productsPanelVisible}
        footer={null}
        closable={false}
        className="orders-panel-modal"
        onCancel={() => setProductsPanelVisible(false)}
        width={1339}
        height={542}
      >
        <ProductsPanel />
      </Modal>
      <Modal
        title={null}
        visible={clientsPanelVisible}
        footer={null}
        closable={false}
        className="orders-panel-modal"
        onCancel={() => setClientsPanelVisible(false)}
        width={1339}
        height={542}
      >
        <ClientsPanel />
      </Modal>
      {/* {isWinery && <DashboardWinery />}
      {isImporter && <DashboardImporter />}
      {haveRight && <NotAuthorized />} */}
    </div>
  );
}
