import React, { useEffect, useState } from "react";
import { Button, Modal, Row } from "antd";
import { useUserId } from "../../../contexts/UserIdProvider";
import "./style/companyInvoiceSetting.less";
import { verifyAdd, verifyUpdate } from "../../../api/user";
import { useTranslation } from "react-i18next";
import { toastInfo, toastError, toastSuccess } from "../../../api/toast";
import {
  companyInvoiceInfo,
  companyInvoiceInfoUpdate,
  companyWineryDesc,
} from "../../../api/company";
import {
  chinaCompanyVerifyFiels,
  UserType,
} from "../../../constants/usersType.enum";
import { constants, deepClone } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import { statusCode } from "../../../constants/statusCode.enum";
import FormNormalW from "../../common/form/FormNormalW";
import specialInvoiceIcon from "../../../resource/icons/dashboard/verify/special_invoice.svg";
import invoiceDeliveryIcon from "../../../resource/icons/dashboard/verify/invoice_delivery_address.svg";

export default function CompanyInvoiceSetting(props) {
  const { user } = useUserId();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const verifyId = user?.userInfo?.companyId;

  //企业认证信息
  const [verfiyCompanyInfo, setVerfiyCompanyInfo] = useState({});
  const [normalInvoice, setNormalInvoice] = useState(false);
  //发票信息
  const [verifyInvoiceInfos, setVerifyInvoiceInfos] = useState(
    chinaCompanyVerifyFiels.invoiceInfo
  );
  //专票信息
  const [verifySpecialInvoiceInfos, setVerifySpecialInvoiceInfos] = useState(
    chinaCompanyVerifyFiels.specialInvoiceInfo
  );
  //专票收货地址
  const [
    verifySpecialInvoiceDeliveryAddressInfos,
    setVerifySpecialInvoiceDeliveryAddressInfos,
  ] = useState(chinaCompanyVerifyFiels.specialInvoiceDeliveryAddress);
  const [status,setStatus]=useState("");

  useEffect(() => {
    initCompanyInvoiceInfo();
  }, []);

  const initCompanyInvoiceInfo = async () => {
    if (!verifyId) return;
    console.log("-------company invoice verifyId:", verifyId);
    let result = await companyInvoiceInfo({ coId: verifyId });
    console.log("-------company invoice info:", result);
    if (result && result.statusCode == statusCode.SUCCESS) {
      let item = result.result;

      setVerfiyCompanyInfo(item);
      setStatus(item.status);

      initVerifyInfo(item);
    }
  };

  const initVerifyInfo = (info) => {
    let {
      invoiceInfo,
      specialInvoiceDeliveryAddress,
      specialInvoiceInfo,
      status,
    } = info;
    setVerifyInvoiceInfos(
      initVerifyInfoValue(invoiceInfo, verifyInvoiceInfos, status)
    );
    setVerifySpecialInvoiceInfos(
      initVerifyInfoValue(specialInvoiceInfo, verifySpecialInvoiceInfos, status)
    );
    setVerifySpecialInvoiceDeliveryAddressInfos(
      initVerifyInfoValue(
        specialInvoiceDeliveryAddress,
        verifySpecialInvoiceDeliveryAddressInfos,
        status
      )
    );
  };

  const initVerifyInfoValue = (source, target, status) => {
    let tempVerifyInfos = deepClone(target);
    for (const key in source) {
      for (let index = 0; index < tempVerifyInfos.length; index++) {
        const element = tempVerifyInfos[index];
        if (element.name == key) {
          element.value = source[key];
          // element.disabled =
          //   status == CompanyVerifyStatusEnum.VERIFIED ||
          //   status == CompanyVerifyStatusEnum.WAITING_VERIFY
          //     ? true
          //     : false;
          break;
        }
      }
    }
    return tempVerifyInfos;
  };

  async function save() {
    let pms = deepClone(verfiyCompanyInfo);
    pms.coId = user.userInfo.companyId;

    console.log("----------------------------pms:", pms);

    // let pms = checkForm();
    if (!pms) return;

    let result = await companyInvoiceInfoUpdate(pms);

    console.log(result);
    if (result) {
      toastSuccess(t("SAVE_SUCCESS"));
    } else {
      toastError(t("SAVE_FAILED"));
    }
  }

  const onVerifyInvoiceInfoFieldsChange = (vals) => {
    setVerifyInvoiceInfos(vals);
    setVerifyComInfo("invoiceInfo", vals);
  };

  const onVerifySpecialInvoiceInfoFieldsChange = (vals) => {
    setVerifySpecialInvoiceInfos(vals);
    setVerifyComInfo("specialInvoiceInfo", vals);
  };

  const onVerifySpecialInvoiceDeliveryAddressFieldsChange = (vals) => {
    setVerifySpecialInvoiceDeliveryAddressInfos(vals);
    setVerifyComInfo("specialInvoiceDeliveryAddress", vals);
  };

  const setVerifyComInfo = (name, vals) => {
    console.log("setVerifyComInfo:", vals);
    let temp = deepClone(verfiyCompanyInfo);
    let value = {};
    vals.map((mapItem) => {
      // console.log(mapItem);
      value[mapItem.name] = mapItem.value;
    });
    temp[name] = value;
    // console.log(value);
    setVerfiyCompanyInfo(temp);
  };

  const switchInvoiceType = () => {
    setNormalInvoice(!normalInvoice);
  };

  return (
    <div className="company-invoice-setting-page">
      <div className="common-management" style={{ paddingBottom: "128px" }}>
        <div className="invoice-setting-part-navigator">
          <img src={specialInvoiceIcon} className="navigator-icon" />
          <span className="navigator-text">
            {t("INPUT_SPECIAL_INVOICE_INFO")}
          </span>
          <Button
            onClick={switchInvoiceType.bind(this)}
            className={
              normalInvoice
                ? "navigator-func-button-selected"
                : "navigator-func-button"
            }
          >
            {t("ORDINARY_INVOICE")}
          </Button>
          <Button
            onClick={switchInvoiceType.bind(this)}
            className={
              normalInvoice
                ? "navigator-func-button"
                : "navigator-func-button-selected"
            }
          >
            {t("VAT_SPECIAL_INVOICE")}
          </Button>
        </div>
        <div className="special-invoice-panel">
          <div
            style={{
              marginTop: "10px",
            }}
          >
            {!normalInvoice && (
              <FormNormalW
                items={verifySpecialInvoiceInfos}
                onChange={onVerifySpecialInvoiceInfoFieldsChange.bind(this)}
              />
            )}
            {normalInvoice && (
              <FormNormalW
                items={verifyInvoiceInfos}
                onChange={onVerifyInvoiceInfoFieldsChange.bind(this)}
              />
            )}
          </div>
        </div>
        <div className="invoice-setting-part-navigator">
          <img src={invoiceDeliveryIcon} className="navigator-icon" />
          <span className="navigator-text">
            {t("SPECIAL_INVOICE_DELIVERY_ADDRESS")}
          </span>
        </div>
        <div className="special-invoice-panel">
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <FormNormalW
              items={verifySpecialInvoiceDeliveryAddressInfos}
              onChange={onVerifySpecialInvoiceDeliveryAddressFieldsChange.bind(
                this
              )}
            />
          </div>
        </div>
      </div>
      <Row
        className="row-submit-button"
        style={{
          width: window.innerWidth - 569 + "px",
        }}
      >
        <Button onClick={save.bind(this)} className="save-btn">
          {t("SUBMIT")}
        </Button>
        {/* <Button
          onClick={() => history.go(-1)}
          className="cancel-btn"
          disabled={isVerified}
        >
          {t("CANCEL")}
        </Button> */}
      </Row>
    </div>
  );
}
