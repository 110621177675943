import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BreadcrumbNormal from "../../common/title/BreadcrumbNormal";
import { companyDetail } from "../../../api/company";
import { useUserId } from "../../../contexts/UserIdProvider";
import {
  UserType,
} from "../../../constants/usersType.enum";
import { getCompanyVerifyStatus } from "../../../api/user";
import { CompanyVerifyStatusEnum } from "../../../constants/companyVerifyStatus.enum";
import { dateFormat } from "../../../utils/date";
import "./style/companyAccount.less";

export default function CompanyAccount() {
  //Default data
  const { user } = useUserId();
  const { userInfo, userId } = user;
  const [currentUser, setCurrentUser] = useState(userInfo);
  const [isWinery, setIsWinery] = useState(false);
  const [isImporter, setIsImporter] = useState(false);
  const { t } = useTranslation();

  //Company data
  const [companyData, setCompanyData] = useState([]);
  const [companyVerifyStatus, setCompanyVerifyStatus] = useState(0);

  //Company Permission list
  const [haveImOpen, setHaveImOpen] = useState(false);
  const [haveProductSearchOpen, setHaveProductSearchOpen] = useState(false);
  const [haveShopOpen, setHaveShopOpen] = useState(false);
  const [haveCreateTenderOpen, setHaveCreateTenderOpen] = useState(false); //Importer only , they create
  const [haveTenderOpen, setHaveTenderOpen] = useState(false); //Winery only , they can respond
  const [haveBuyerSearchOpen, setHaveBuyerSearchOpen] = useState(false); // Winery only , they can found importer
  const [haveSellerSearchOpen, setHaveSellerSearchOpen] = useState(false); //Importer only , they can found winery
  const [haveBuyerAddOpen, setHaveBuyerAddOpen] = useState(false); // Winery only , they can found importer
  const [haveSellerAddOpen, setHaveSellerAddOpen] = useState(false); //Importer only , they can found winery
  const [haveCommonFeatureOpen, setHaveCommonFeatureOpen] = useState(false); //Post articles, pictures, videos....

  useEffect(() => {
    loadData(user.userInfo.companyId);

    if ((user.userInfo.type = UserType.SUPPLIER)) {
      setIsWinery(true);
      loadWineryPermission();
    } else {
      setIsImporter(true);
      loadImporterPermission();
    }
  }, []);

  /**
   * Load the company data
   * @param {*} coId
   */
  const loadData = async (coId) => {
    const companyDetailResult = await companyDetail(coId);
    setCompanyVerifyStatus(getCompanyVerifyStatus(currentUser));
    console.log("companyDetailResult : ", companyDetailResult);
    setCompanyData(companyDetailResult);
  };

  /**
   * Load the winery permission
   */
  const loadWineryPermission = () => {
    const isCompanyVerified =
      getCompanyVerifyStatus(currentUser) === 3 ? true : false; //If the company status === 3 the company is verified
    if (isCompanyVerified) {
      setHaveCommonFeatureOpen(true);
      setHaveImOpen(true);
      setHaveSellerSearchOpen(true);
      setHaveSellerAddOpen(true);
    }
    if (currentUser.subscription) {
      setHaveShopOpen(true);
      setHaveTenderOpen(true);
    }
  };

  /**
   * Load the importer permission
   */
  const loadImporterPermission = () => {
    const isCompanyVerified =
      getCompanyVerifyStatus(currentUser) === 3 ? true : false; //If the company status === 3 the company is verified
    if (isCompanyVerified) {
      setHaveCommonFeatureOpen(true);
      setHaveImOpen(true);
      setHaveBuyerSearchOpen(true);
      setHaveBuyerAddOpen(true);
    }
    if (currentUser.subscription) {
      setHaveCreateTenderOpen(true);
      setHaveProductSearchOpen(true);
    }

    //Todo  , if the user have the shop open THEN he can do all the things related to selling wine
  };

  return (
    <div className="common-page">
      <BreadcrumbNormal title={t("COMPANY_ACCOUNT_MANAGEMENT")} />
      <div className="common-management products-management">
        <div className="common-management-title">
          {t("COMPANY_ACCOUNT_MANAGEMENT")}
        </div>
        {user.userInfo.type==UserType.SUPPLIER ? (
          <div className="common-management-panel">
            <table>
              <tr>
                <td>{t("COMPANY_ACCOUNT_NAME")}</td>
                <td>{companyData.coName}</td>
              </tr>
              <tr>
                <td>{t("COMPANY_ACCOUNT_TYPE")}</td>
                <td>
                  {companyVerifyStatus === CompanyVerifyStatusEnum.VERIFIED ? (
                    <span>{t("VERIFIED")}</span>
                  ) : (
                    t("NOT_VERIFIED")
                  )}
                </td>
              </tr>
              <tr>
                <td>{t("VERIFICATION_TYPE")}</td>
                <td>
                  {companyVerifyStatus === CompanyVerifyStatusEnum.VERIFIED
                    ? companyData.remark === "STRIPE"
                      ? t("VERIFIED_BY_STRIPE")
                      : t("MANUAL_VERIFY")
                    : t("COMPANY_IS_NOT_VERIFIED")}
                </td>
              </tr>
              <tr>
                <td>{t("ACCOUNT_CREATION_TIME")}</td>
                <td>{dateFormat(currentUser.createdAt)}</td>
              </tr>
            </table>
          </div>
        ) : (
          <div className="common-management-panel">
            <table>
              <tr>
                <td>{t("COMPANY_ACCOUNT_NAME")}</td>
                <td>{companyData.coName}</td>
              </tr>
              <tr>
                <td>{t("COMPANY_ACCOUNT_TYPE")}</td>
                <td>
                  {companyVerifyStatus === CompanyVerifyStatusEnum.VERIFIED ? (
                    <span>{t("VERIFIED")}</span>
                  ) : (
                    t("NOT_VERIFIED")
                  )}
                </td>
              </tr>
              <tr>
                <td>{t("VERIFICATION_TYPE")}</td>
                <td>
                  {companyVerifyStatus === CompanyVerifyStatusEnum.VERIFIED
                    ? companyData.remark === "STRIPE"
                      ? t("VERIFIED_BY_STRIPE")
                      : t("MANUAL_VERIFY")
                    : t("COMPANY_IS_NOT_VERIFIED")}
                </td>
              </tr>
              <tr>
                <td>{t("ACCOUNT_CREATION_TIME")}</td>
                <td>{dateFormat(currentUser.createdAt)}</td>
              </tr>
            </table>
          </div>
        )}

        <>
          <h2>{t("COMPANY_ACCOUNT_PERMISSION")}</h2>
          <p>
            <span className={haveImOpen ? "green-dot" : "red-dot"}></span>{" "}
            {t("HAVE_IM")}{" "}
          </p>
          <p>
            <span
              className={haveProductSearchOpen ? "green-dot" : "red-dot"}
            ></span>{" "}
            {t("HAVE_PRODUCT_SEARCH")}{" "}
          </p>
          <p>
            <span className={haveShopOpen ? "green-dot" : "red-dot"}></span>{" "}
            {t("HAVE_SHOP_OPEN")}{" "}
          </p>
          <p>
            <span
              className={haveCreateTenderOpen ? "green-dot" : "red-dot"}
            ></span>{" "}
            {t("HAVE_CREATE_TENDER")}{" "}
          </p>
          <p>
            <span className={haveTenderOpen ? "green-dot" : "red-dot"}></span>{" "}
            {t("HAVE_TENDER_OPEN")}{" "}
          </p>
          <p>
            <span
              className={haveBuyerSearchOpen ? "green-dot" : "red-dot"}
            ></span>{" "}
            {t("HAVE_BUYER_SEARCH")}{" "}
          </p>
          <p>
            <span
              className={haveSellerSearchOpen ? "green-dot" : "red-dot"}
            ></span>{" "}
            {t("HAVE_SELLER_SEARCH")}{" "}
          </p>
          <p>
            <span className={haveBuyerAddOpen ? "green-dot" : "red-dot"}></span>{" "}
            {t("HAVE_BUYER_ADD")}{" "}
          </p>
          <p>
            <span
              className={haveSellerAddOpen ? "green-dot" : "red-dot"}
            ></span>{" "}
            {t("HAVE_SELLER_ADD")}{" "}
          </p>
          <p>
            <span
              className={haveCommonFeatureOpen ? "green-dot" : "red-dot"}
            ></span>{" "}
            {t("HAVE_COMMON_FEATURE")}{" "}
          </p>
        </>
      </div>
    </div>
  );
}
