import React, { useEffect, useState } from "react";
import styles from "./VinbipAddOrUpdateSupplier.module.sass";
import NameAndDescription from "./NameAndDescription";
import Panel from "./Panel";
import { deepClone } from "../../../utils/utils";
import { toastError, toastInfo, toastSuccess } from "../../../api/toast";
import { useUserId } from "../../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import { statusCode } from "../../../constants/statusCode.enum";
import { useHistory, useLocation } from "react-router";
import { UserLabel } from "../../../constants/usersType.enum";
import { addCompanyInfo, saveCompanyInfo } from "../../../api/company";

export default function VinbipAddOrUpdateSupplier(props) {
  const { state } = useLocation();

  const { user } = useUserId();
  const { t, i18n } = useTranslation();

  const history = useHistory();

  const [formData, setFormData] = useState({});
  const [addressFormData, setAddressFormData] = useState();

  const [productId, setProductId] = useState();
  const [productDetailInfo, setProductDetailInfo] = useState({});

  const [companyId, setCompanyId] = useState("");

  // useEffect(() => {
  //   if (state && state.companyId) {
  //     setCompanyId(state.companyId);
  //   } else {
  //     // onAddNewCompany();
  //   }
  // }, []);

  useEffect(() => {
    // onUpdateCompany();
  }, [formData]);

  const onNameAndDescriptionChange = (fields) => {
    setFormDataValue(fields);
  };

  const onAddressChange = (addressInfo) => {
    let temp = deepClone(addressFormData);
    temp = addressInfo;
    setAddressFormData(temp);
  };

  const setFormDataValue = (fields) => {
    let clone = deepClone(formData);
    clone = Object.assign({}, clone, fields);
    setFormData(clone);
  };

  const onSubmit = () => {
    if (companyId) {
      onUpdateCompany();
    } else {
      onAddNewCompany();
    }
  };

  const onAddNewCompany = async () => {
    let pms = formData;

    pms.connected = false;
    pms.type = "SUPPLIER";

    if (addressFormData) {
      if (!pms.location) {
        pms.location = {};
      }
      pms.location["address"] = addressFormData;
    }

    console.log("--------------onSubmitCompanyInfo:", pms);
    let result = await addCompanyInfo(pms);
    console.log("--------------onSubmitCompanyInfo result:", result);
    if (result && result.statusCode == statusCode.SUCCESS) {
      setCompanyId(result.result);
      toastInfo(t("SAVE_SUCCESS"));
      setTimeout(() => {
        history.go(-1);
      }, 500);
    } else {
      toastInfo(t("SAVE_FAILED"));
    }
  };

  const onSaveDraftProduct = () => {
    onUpdateCompany(5);
  };

  const onUpdateCompany = async () => {
    let pms = formData;

    pms._id=companyId;

    pms.connected = false;
    if (!pms.type) {
      pms.type = "SUPPLIER";
    }

    if (addressFormData) {
      if (!pms.location) {
        pms.location = {};
      }
      pms.location["address"] = addressFormData;
    }

    console.log("--------------onSubmitCompanyInfo:", pms);
    let result;
    if (companyId) {
      result = await saveCompanyInfo(pms);

      console.log("--------------onSubmitCompanyInfo result:", result);
      if (result && result.statusCode == statusCode.SUCCESS) {
        i18n.changeLanguage(pms.language);
        toastInfo(t("SAVE_SUCCESS"));
        setTimeout(() => {
          history.go(-1);
        }, 500);
      } else {
        toastInfo(t("SAVE_FAILED"));
      }
    }
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription
            className={styles.card}
            onChange={onNameAndDescriptionChange.bind(this)}
            onAddressChange={onAddressChange.bind(this)}
            dataSource={productDetailInfo}
            companyId={companyId}
          />
        </div>
      </div>
      <Panel
        onPublish={onSubmit.bind(this)}
        onSaveDraft={onSaveDraftProduct.bind(this)}
      />
    </>
  );
}
