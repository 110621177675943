import React, { useEffect, useState } from "react";
import styles from "./PriceModal.module.sass";

import { useTranslation } from "react-i18next";
import { deepClone } from "../../../utils/utils";
import { Modal } from "antd";
import { Range, getTrackBackground } from "react-range";
import { SysEnvLanguageType } from "../../../constants/env.enum";
import cn from "classnames";

const STEP = 1;
const MIN = 0;
const MAX = 110;

const PriceModal = ({ isShow, onClose, onChange }) => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [values, setValues] = useState([0, 110]);
  const [priceStep, setPriceStep] = useState(1);
  const [priceMax, setPriceMax] = useState(110);
  const [currency, setCurrency] = useState("￥");

  useEffect(() => {
    initPriceRange();
  }, []);

  const initPriceRange = () => {
    switch (currentLanguage) {
      case SysEnvLanguageType.EN:
        setPriceMax(110);
        setValues([0, 110]);
        setCurrency("$");
        break;
      case SysEnvLanguageType.FR:
        setPriceMax(110);
        setValues([0, 110]);
        setCurrency("€");
        break;
      case SysEnvLanguageType.ZH:
        setPriceMax(880);
        setValues([0, 880]);
        setCurrency("￥");
        break;
      default:
        break;
    }
  };

  const onCloseClick = () => {
    console.log("onCloseClick:", values);
    onChange &&
      onChange({
        prices: values,
        currency: currency,
      });
    onClose && onClose();
  };

  return (
    <Modal visible={isShow} footer={null} onCancel={onCloseClick.bind(this)}>
      <div className={cn("title-red", styles.title)}>{t("PRICE_RANGE")}</div>
      <div className={styles.distributionChannelSelectModal}>
        <div className={styles.distributionChannelPanel}>
          <Range
            values={values}
            step={priceStep}
            min={MIN}
            max={priceMax}
            onChange={(values) => {
              setValues(values);
            }}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  width: "100%",
                  paddingTop: 20,
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "4px",
                    width: "100%",
                    borderRadius: "2px",
                    background: getTrackBackground({
                      values,
                      colors: ["#EFEFEF", "#2A85FF", "#EFEFEF"],
                      min: MIN,
                      max: MAX,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ index, props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "16px",
                  width: "16px",
                  borderRadius: "50%",
                  backgroundColor: "#FFF",
                  border: "2px solid #2A85FF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "inset 0px 2px 2px #FFFFFF",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: "calc(100% + 5px)",
                    color: "#fff",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontFamily: "Inter",
                    padding: "4px 8px",
                    borderRadius: "8px",
                    backgroundColor: "#272B30",
                  }}
                >
                  {values[index].toFixed(0)}
                  {currency}
                </div>
              </div>
            )}
          />
          {/* {dataList.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  mapItem.selected
                    ? styles.channelItemSelected
                    : styles.channelItem
                }
                onClick={onPriceRangeChange.bind(this, mapIndex)}
              >
                {mapItem.label}
              </div>
            );
          })} */}
        </div>
      </div>
    </Modal>
  );
};

export default PriceModal;
