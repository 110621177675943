import { actionRequest, formatActionResponse, formatResponse } from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";

/**
 * @description Get the carts
 * @param pms
 */
export async function getCarts(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.carts, pms);
}

export async function getProductCarts(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.cartsByProduct, pms);
}

/**
 * @description Create a cart
 * @param pms
 */
export async function cartCreate(pms: any) {
    let data: any = await actionRequest(RequestType.POST, apiPath.cartCreate, pms);
    return formatResponse(data);
}

/**
 * @description Update the cart
 * @param pms
 */
export async function cartUpdate(pms: any) {
    let data: any = await actionRequest(RequestType.GET, apiPath.cartUpdate, pms);
    return formatResponse(data);
}

/**
 * @description Get the carts
 * @param pms
 */
export async function cartRemove(pms: any) {
    let data: any = await actionRequest(RequestType.GET, apiPath.cartRemove, pms);
    return formatResponse(data);
}

