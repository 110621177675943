import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory, useLocation } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../core-digital-template/components/Card";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../core-digital-template/components/Dropdown";
import { useUserId } from "../../../contexts/UserIdProvider";
import { deepClone } from "../../../utils/utils";
import { companyDetail } from "../../../api/company";
import { LanguagesKey } from "../../../constants/languages.enum";
import { Button } from "antd";
import { SysBaseUrl, WxAppId, WxAppSecret } from "../../../constants/constants";
import { getTimeStamp } from "../../../utils/date";
import { getWxAccessToken } from "../../../api/login";
import { userDetail, userUpdate } from "../../../api/user";
import { toastError, toastSuccess } from "../../../api/toast";
import { Path } from "../../../constants/path";

const qs = require("query-string");

const redirectUrl = SysBaseUrl + Path.wechatBinding.replace('/', '');;

export default function NameAndDescription(props) {
  const { className, onChange, dataSource, companyId } = props;

  const { search } = useLocation();

  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const [wxOpenId, setWxOpenId] = useState();
  const [wxQrVisible, setWxQrVisible] = useState(false);
  const [wxLoginLoadTime, setWxLoginLoadTime] = useState(0);

  useEffect(() => {
    loadUserDetail(user.userId);
  }, [i18n.language]);

  useEffect(() => {
    initWxLoginScript();
  }, []);

  useEffect(() => {
    let result = createWxLogin();
    if (wxLoginLoadTime < 200 && !result) {
      setWxLoginLoadTime(wxLoginLoadTime + 1);
    }
  }, [wxLoginLoadTime]);

  useEffect(() => {
    if (search) {
      let searchObj = qs.parse(search);
      let { code } = searchObj;
      console.log("-----wx code:", search, code);
      if (code) {
        getWxOpenId(code);
      }
    }
  }, [search]);

  function initWxLoginScript() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = false;
    script.src =
      "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
    document.head.appendChild(script);
  }

  function createWxLogin() {
    let flag = false;
    try {
      new window.WxLogin({
        self_redirect: false,
        id: "login",
        appid: WxAppId,
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(redirectUrl),
        state: getTimeStamp(),
        // style: "",
        // href: ""
      });
      flag = true;
    } catch (error) {
      // console.log('------create wxlogin error:',wxLoginLoadTime, error);
    }
    return flag;
  }

  const getWxOpenId = async (code) => {
    let pms = { appid: WxAppId, appsecret: WxAppSecret, code };
    let result = await getWxAccessToken(pms);
    console.log("-------wx access_token:", result);
    if (result && result.data) {
      let { openid, access_token } = result.data;
      if (openid) {
        setWxOpenId(openid);
        onSubmitUserInfo(openid);
      }
    }
  };

  const loadUserDetail = async (userId) => {
    let userInfo = await userDetail(userId);
    if (userInfo && userInfo.wxOpenId) {
      setWxOpenId(userInfo.wxOpenId);
    }
  };

  const onSubmitUserInfo = async (openid) => {
    let pms = {
      _id: user.userId,
      wxOpenId: openid,
    };
    let result = await userUpdate(pms);
    if (result) {
      toastSuccess(t("SAVE_SUCCESS"));
    } else {
      toastError(t("SAVE_FAILED"));
    }
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={t("WECHAT_BIND")}
        classTitle="title-red"
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className="modal-content-column">
                <div className="content-row-label">
                  {t("WECHAT_BIND")} : {wxOpenId ? t("BOUND") : t("NO")}
                  {wxQrVisible ? (
                    <Button type="link" onClick={() => setWxQrVisible(false)}>
                      {t("SHOW_LESS")}
                    </Button>
                  ) : (
                    <Button type="link" onClick={() => setWxQrVisible(true)}>
                      {t("BIND")}
                    </Button>
                  )}
                </div>
                <div
                  id="login"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: wxQrVisible ? "block" : "none",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
