import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Form, Input, Row, Col, Modal } from "antd";
import UploadNormal from "../common/upload/UploadNormal";
import { useUserId } from "../../contexts/UserIdProvider";
import {
  emailVerify,
  userDetail,
  userUpdate as reqUserUpdate,
  userExists,
} from "../../api/user";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useHistory } from "react-router-dom";
import { format } from "date-fns";
import { localizeImg, SysBaseUrl } from "../../constants/constants";
import { toastError, toastInfo, toastSuccess } from "../../api/toast";
import { getWxAccessToken, sendSms } from "../../api/login";
import { statusCode } from "../../constants/statusCode.enum";
import InputPhoneNumberNormal from "../common/input/InputPhoneNumberNormal";
import { Dropdown, Menu } from "antd";
import { WxAppId, WxAppSecret } from "../../constants/constants";
import { getTimeStamp } from "../../utils/date";
import "./style/personalInfo.less";
import AvatarBasic from "../common/avatar/AvatarBasic";
import { getLocationNameById } from "../../api/config";
import PasswordManagement from "./PasswordManagement";
import CloseAccount from "./closeAccount";
import { SettingOutlined } from "@ant-design/icons";
import UploadNormalCrop from "../common/upload/UploadNormalCrop";
const qs = require("query-string");
const redirectUrl = SysBaseUrl + "usercenter/me";
// const baseURL = "https://api.weixin.qq.com/";
// const api = create({
//   baseURL: baseURL,
//   headers: { Accept: 'application/json' },
// });

export default function PersonalInfo() {
  const { search } = useLocation();
  const [uploadStatus, setUploadStatus] = useState(true);
  const { user, loadUserInfo } = useUserId();
  const [userData, setUserData] = useState();
  const [userAvatar, setUserAvatar] = useState();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [orgPhoneNumber, setOrgPhoneNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneVerificationCode, setPhoneVerificationCode] = useState();
  const [verificationToken, setVerificationToken] = useState("");
  const [timerStatus, setTimerStatus] = useState(false);
  let countNum = 60;
  const [countDown, setCountDown] = useState(countNum);
  const [phoneExists, setPhoneExists] = useState(false);
  const [avatarStatus, setAvatarStatus] = useState(false);
  const [wxOpenId, setWxOpenId] = useState();
  const [wxQrVisible, setWxQrVisible] = useState(false);
  const [wxLoginLoadTime, setWxLoginLoadTime] = useState(0);
  const [nickName, setNickname] = useState("loading");

  /**
   * Password management
   */
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);

  /**
   * Close account
   */
  const [closeAccountVisible, setCloseAccountVisible] = useState(false);

  let timer;
  useEffect(() => {
    if (timerStatus) {
      timer = setInterval(() => {
        setCountDown(countDown - 1);
        if (countNum === 0) {
          clearInterval(timer);
          setCountDown(60);
          setTimerStatus(false);
        } else {
          countNum--;
          setCountDown(countNum);
        }
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [timerStatus]);

  useEffect(() => {
    initWxLoginScript();
  }, []);

  useEffect(() => {
    loadUserDetail(user.userId);
  }, [i18n.language]);

  useEffect(() => {
    let result = createWxLogin();
    if (wxLoginLoadTime < 200 && !result) {
      setWxLoginLoadTime(wxLoginLoadTime + 1);
    }
  }, [wxLoginLoadTime]);

  useEffect(() => {
    if (search) {
      let searchObj = qs.parse(search);
      let { code } = searchObj;
      console.log("-----wx code:", search, code);
      if (code) {
        getWxOpenId(code);
      }
    }
  }, [search]);

  const getWxOpenId = async (code) => {
    let pms = { appid: WxAppId, appsecret: WxAppSecret, code };
    let result = await getWxAccessToken(pms);
    console.log("-------wx access_token:", result);
    if (result && result.data) {
      let { openid, access_token } = result.data;
      if (openid) {
        setWxOpenId(openid);
      }
    }
  };

  function initWxLoginScript() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = false;
    script.src =
      "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
    document.head.appendChild(script);
  }

  function createWxLogin() {
    let flag = false;
    try {
      new window.WxLogin({
        self_redirect: false,
        id: "login",
        appid: WxAppId,
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(redirectUrl),
        state: getTimeStamp(),
        // style: "",
        // href: ""
      });
      flag = true;
    } catch (error) {
      // console.log('------create wxlogin error:',wxLoginLoadTime, error);
    }
    return flag;
  }

  const loadUserDetail = async (userId) => {
    let userInfo = await userDetail(userId);
    setNickname(userInfo.nickName);
    setUserData(userInfo);
    if (userInfo._id) {
      if (userInfo.picture) {
        setUserAvatar(userInfo.picture);
      }
      let countryName = await getLocationNameById(
        userInfo.country,
        i18n.language
      );
      form.setFieldsValue({
        nickName: userInfo.nickName,
        email: email,
        phoneNumber: userInfo.phoneNumber,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        country: countryName,
        company: userInfo.company,
        signature: userInfo.signature,
        creationDate: format(new Date(userInfo.createdAt), t("DATE_FORMAT")),
      });
      setEmailVerified(userInfo.emailVerified);
      setEmail(userInfo.email);
      setPhone(userInfo.phone);
      if (userInfo.phone) {
        setOrgPhoneNumber(userInfo.phone.phone);
        setPhoneNumber(userInfo.phone.phone);
      } else {
        setOrgPhoneNumber(userInfo.phoneNumber);
        setPhoneNumber(userInfo.phoneNumber);
        setPhone({ short: "CN", code: "86", phone: userInfo.phoneNumber });
      }
      setVerificationToken(userInfo.emailVerificationToken);
      setPhoneVerified(userInfo.phoneNumber);
      if (userInfo.wxOpenId) {
        setWxOpenId(userInfo.wxOpenId);
      }
      console.log("------userinfo openid:", userInfo);
    }
  };

  const onFileLoading = () => {
    setUploadStatus(false);
  };

  const onFileLoaded = (data) => {
    setUploadStatus(true);
    if (data.length > 0) {
      setUserAvatar(data[0]);
    }
  };

  const onSubmitUserInfo = async (formValues) => {
    let {
      nickName,
      email,
      firstName,
      lastName,
      country,
      company,
      signature,
      emailVerified,
      creationDate,
    } = formValues;

    if (!nickName) {
      toastInfo(t("NICKNAME_MISSING"));
      return;
    }
    if (phoneExists) {
      toastError(t("PHONE_ALREADY_EXIST"));
      return;
    }
    if (phoneNumber != orgPhoneNumber && !phoneVerificationCode) {
      toastError(t("CAPTURE_REQUIRED"));
      return;
    }
    let pms = {
      _id: user.userId,
      firstName,
      lastName,
      // country,
      company,
      signature,
      nickName,
      email,
      phoneNumber,
      phone,
      picture: userAvatar,
      emailVerified,
      creationDate,
      capture: phoneVerificationCode,
      wxOpenId,
    };
    let result = await reqUserUpdate(pms);
    if (result) {
      loadUserInfo(user.userId);
      toastSuccess(t("SAVE_SUCCESS"));
      history.push("/feed");
    } else {
      toastError(t("SAVE_FAILED"));
      // toastError(t("CAPTURE_ERROR_MESSAGE"));
    }
  };

  const onSendSms = async () => {
    if (phoneNumber) {
      toastInfo(t("CODE_SEND_SUCCESS"));
      setTimerStatus(true);
      let pms = {
        phone: phoneNumber,
        smsType: "Login",
      };
      await sendSms(pms);
    } else {
      toastInfo(t("PHONE_NUMBER_IS_EMPTY"));
    }
  };

  const OnEmailClick = async () => {
    let pms = {
      emailVerificationToken: verificationToken,
    };
    let result = await emailVerify(pms);
    if (result.statusCode === 200) {
      toastSuccess(t("SAVE_SUCCESS"));
      setEmailVerified(true);
    } else {
      toastError(t("SAVE_FAILED"));
    }
  };

  const onPhoneChange = (val) => {
    setPhone(val);
    setPhoneNumber(val.phone);
  };

  const onInputPhoneBlur = async (val) => {
    if (val && val !== orgPhoneNumber) {
      let pms = { phone: val };
      let result = await userExists(pms);
      if (result.statusCode !== statusCode.SUCCESS) {
        toastError(t("PHONE_ALREADY_EXIST"));
        setPhoneExists(true);
      }
    }
  };

  const settingMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setChangePasswordVisible(true);
        }}
      >
        <span>{t("CHANGE_PASSWORD")}</span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setCloseAccountVisible(true);
        }}
      >
        <span>{t("CLOSE_MY_ACCOUNT")}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="common-page personal-info">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_SETTINGS")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("PERSONAL_INFORMATION")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management modal-body">
        <div className="common-management-title">
          {t("PERSONAL_INFORMATION")}
          <Dropdown overlay={settingMenu} trigger={["click"]}>
            <SettingOutlined
              style={{
                marginLeft: "auto",
                marginRight: "20px",
              }}
            />
          </Dropdown>
        </div>

        <div className="info-content common-management-panel">
          <div className="modal-content-column">
            <div className="content-row-label">
              {t("WINERY_PROFILE_AVATAR")} :
            </div>
            <UploadNormalCrop
              onLoading={onFileLoading.bind(this)}
              onFileLoaded={onFileLoaded.bind(this)}
              scaleToWidth={160}
              scaleToHeight={160}
            >
              <div
                className="avatar-item-upload content-body-center cursor-pointer"
                onMouseOver={() => setAvatarStatus(true)}
                onMouseLeave={() => setAvatarStatus(false)}
              >
                <AvatarBasic
                  cname="avatar-item"
                  size={80}
                  picture={localizeImg(userAvatar)}
                  displayName={nickName}
                ></AvatarBasic>
                {avatarStatus && (
                  <div className="image-title">{t("MODIFY_AVATAR")}</div>
                )}
              </div>
              {/* <div className="avatar-item-upload">
                <img
                  alt={"User avatar"}
                  src={localizeImg(userAvatar)}
                  className="avatar-item"
                  width="80px"
                  height="80px"
                />
                <div className="image-title-wrapper">
                  {t("MODIFY_THE_AVATAR")}
                </div>
              </div> */}
            </UploadNormalCrop>
          </div>

          <Form form={form} onFinish={onSubmitUserInfo.bind(this)}>
            <Row>
              <Col span={12}>
                <div className="modal-content-column">
                  <div className="content-row-label">{t("NICKNAME")}</div>
                  <Form.Item name="nickName">
                    <Input className="input-basic" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="modal-content-column">
                  <div className="content-row-label">{t("LAST_NAME")}</div>
                  <Form.Item name="lastName">
                    <Input
                      bordered={true}
                      disabled={true}
                      className="input-basic"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div className="modal-content-column">
                  <div className="content-row-label">{t("FIRST_NAME")}</div>
                  <Form.Item name="firstName">
                    <Input
                      bordered={true}
                      disabled={true}
                      className="input-basic"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="modal-content-column">
                  <div className="content-row-label">{t("EMAIL")}</div>
                  <Form.Item>
                    <Input
                      className="input-basic"
                      bordered={true}
                      disabled={true}
                      value={email}
                    />
                    {!emailVerified && (
                      <Button
                        type="default"
                        className="verify-button"
                        onClick={OnEmailClick}
                      >
                        {t("VERIFY")}
                      </Button>
                    )}
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div className="modal-content-column">
                  <div className="content-row-label">{t("COUNTRY")}</div>
                  <Form.Item name="country">
                    <Input disabled={true} className="input-basic" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="modal-content-column">
                  <div className="content-row-label">{t("PHONE_NUMBER")}</div>
                  <InputPhoneNumberNormal
                    disabled={true}
                    value={phone}
                    onChange={onPhoneChange.bind(this)}
                    onBlur={onInputPhoneBlur.bind(this)}
                  />
                  {/*<Form.Item>
                     <Input value={phoneNumber} className="input-basic" />
                 <Button type="default" className="verify-button" onClick={OnPhoneClick}>
                  {t("VERIFY")}
                </Button>
                  </Form.Item>*/}
                </div>
              </Col>
            </Row>

            <div>
              {t("SIGNATURE")}
              <Form.Item name="signature">
                <Input.TextArea rows={3} bordered={true} />
              </Form.Item>
            </div>

            <div className="modal-content-column">
              <div className="content-row-label">
                {t("WECHAT_BIND")} : {wxOpenId ? t("BOUND") : t("NO")}
                {wxQrVisible ? (
                  <Button type="link" onClick={() => setWxQrVisible(false)}>
                    {t("SHOW_LESS")}
                  </Button>
                ) : (
                  <Button type="link" onClick={() => setWxQrVisible(true)}>
                    {t("BIND")}
                  </Button>
                )}
              </div>
              <div
                id="login"
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: wxQrVisible ? "block" : "none",
                }}
              ></div>
            </div>
            <div className="button">
              <Button htmlType="submit" className="button-primary">
                {t("SAVE_THE_CHANGE")}
              </Button>
            </div>
          </Form>
        </div>
        <Modal
          destroyOnClose={true}
          title={null}
          footer={null}
          visible={changePasswordVisible}
          onCancel={() => setChangePasswordVisible(false)}
        >
          <PasswordManagement />
        </Modal>
        <Modal
          destroyOnClose={true}
          title={null}
          footer={null}
          visible={closeAccountVisible}
          onCancel={() => setCloseAccountVisible(false)}
        >
          <CloseAccount />
        </Modal>
      </div>
    </div>
  );
}
