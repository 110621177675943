import { Avatar, Button, Dropdown, Input, Menu, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  applyFriendHandle,
  friendApplyPage,
  pageFriends,
} from "../../../api/contact";
import { localizeImg } from "../../../constants/constants";
import { useUserId } from "../../../contexts/UserIdProvider";
import AvatarBasic from "../avatar/AvatarBasic";
import "./style/friendsPanel.less";
import moreIcon from "../../../resource/icons/left-sider/home/more.svg";
import starIcon from "../../../resource/icons/left-sider/home/star.svg";
import blockIcon from "../../../resource/icons/left-sider/home/block.svg";
import deleteIcon from "../../../resource/icons/left-sider/home/delete.svg";
import starEmptyIcon from "../../../resource/icons/left-sider/home/star_empty.svg";
import blockEmptyIcon from "../../../resource/icons/left-sider/home/block_empty.svg";
import deleteEmptyIcon from "../../../resource/icons/left-sider/home/delete_empty.svg";
import { setBlock, setOnTop } from "../../../api/userFriends";
import searchIcon from "./images/search.svg";
import {
  FriendApplyStatus,
  statusCode,
} from "../../../constants/statusCode.enum";
import { toast } from "react-toastify";
import { deepClone, goToProfilePage } from "../../../utils/utils";
import { useHistory } from "react-router";

export default function FriendsPanel(props) {
  const { user } = useUserId();
  const { t } = useTranslation();
  const history = useHistory();

  const [oriFriendList, setOriFriendList] = useState([]);
  const [oriFriendApplyList, setOriFriendApplyList] = useState([]);
  const [friendList, setFriendList] = useState([]);
  const [friendApplyList, setFriendApplyList] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    await loadFriends();
    await loadFriendApplys();
    setLoading(false);
  };

  const loadFriendApplys = async () => {
    let pms = {
      userId: user.userId,
      page: 0,
      per_page: 100,
      status: 0,
    };
    let data = await friendApplyPage(pms);
    if (data && data.total > 0) {
      let { result } = data;
      let temp = [];
      result.map((mapItem) => {
        temp.push(mapItem.userInfo);
      });
      setFriendApplyList(temp);
      setOriFriendApplyList(temp);
    }
  };

  const loadFriends = async () => {
    let pms = { page: 0, per_page: 100 };
    let result = await pageFriends(user.userId, pms);
    console.log("friend list:", result);
    if (result && result.length > 0) {
      setFriendList(result);
      setOriFriendList(result);
    }
  };

  const onHandleApply = (sourceId, status) => {
    let pms = { sourceId, targetId: user.userId, status };
    applyFriendHandle(pms).then((data) => {
      loadFriends();
      if (data && data.statusCode == statusCode.SUCCESS) {
        let temp = deepClone(friendApplyList);
        temp = temp.filter((filterItem) => filterItem._id != sourceId);
        setFriendApplyList(temp);

        let temp1 = deepClone(oriFriendApplyList);
        temp1 = temp1.filter((filterItem) => filterItem._id != sourceId);
        setOriFriendApplyList(temp1);
      } else {
        toast.error(
          t("SYS_ERROR", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
      }
    });
  };

  const setFriendOnTop = async (friendItem) => {
    console.log(friendItem);
    let pms = {
      userId: user.userId,
      friendId: friendItem.id,
    };
    await setOnTop(pms);
  };

  const setFriendBlock = async (friendItem) => {
    console.log(friendItem);
    let pms = {
      userId: user.userId,
      friendId: friendItem.id,
    };
    await setBlock(pms);
  };

  const onSearchContentChange = (e) => {
    let key = e.target.value;

    let temp = deepClone(friendList);
    temp = oriFriendList.filter(
      (filterItem) => filterItem.name.indexOf(key) != -1
    );
    setFriendList(deepClone(temp));

    let temp1 = deepClone(friendApplyList);
    temp1 = oriFriendApplyList.filter(
      (filterItem) => filterItem.nickName.indexOf(key) != -1
    );
    setFriendApplyList(deepClone(temp1));
  };

  const onGoToProfile = (friendItem) => {
    friendItem._id = friendItem.id;
    goToProfilePage(friendItem, history);
  };

  const menu_language = (friendItem) => {
    return (
      <Menu className="contact-item-action-panel">
        <Menu.Item
          key="MESSAGE_TOP"
          onClick={setFriendOnTop.bind(this, friendItem)}
        >
          <div className="contact-action-panel">
            <img src={starEmptyIcon} className="contact-action-icon" />
            <span>{t("MESSAGE_TOP")}</span>
          </div>
        </Menu.Item>
        <Menu.Item
          key="BLOCK_CONTACT"
          onClick={setFriendBlock.bind(this, friendItem)}
        >
          <div className="contact-action-panel">
            <img src={blockEmptyIcon} className="contact-action-icon" />
            <span>{t("BLOCK_CONTACT")}</span>
          </div>
        </Menu.Item>
        <Menu.Item key="DELETE">
          <div className="contact-action-panel">
            <img src={deleteEmptyIcon} className="contact-action-icon" />
            <span>{t("DELETE")}</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className="friends-panel">
      <div
        style={{
          maxHeight: "573px",
          overflowX: "auto",
        }}
      >
        <div className="contact-category-title">{t("NEW_CONTACT_REQUEST")}</div>
        {loading && (
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 0 }} />
            <Skeleton active={true} avatar={true} paragraph={{ rows: 0 }} />
          </div>
        )}
        {friendApplyList.length == 0 && !loading && (
          <div className="no-content-tips">{t("NO_FRIEND_REQUEST_YET")}</div>
        )}
        {!loading &&
          friendApplyList.map((friendItem, friendIndex) => {
            return (
              <div className="friend-item panel-hover" key={friendIndex}>
                <div
                  className="flex-display align-items-center"
                  onClick={onGoToProfile.bind(this, friendItem)}
                >
                  <AvatarBasic
                    picture={localizeImg(friendItem?.companyDesc?.avatar)}
                    cname="avatar"
                    displayName={friendItem?.nickName}
                    size={41}
                  />
                  <div className="user-name-panel">
                    <div className="user-name">{friendItem?.nickName}</div>
                    <div className="user-role-name">
                      {friendItem?.roleInfo?.name == "ADMIN"
                        ? t(friendItem?.roleInfo?.name)
                        : friendItem?.roleInfo?.name}
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    className="button-accept"
                    onClick={onHandleApply.bind(
                      this,
                      friendItem._id,
                      FriendApplyStatus.ADDED
                    )}
                  >
                    {t("ACCEPT")}
                  </Button>
                  <Button
                    className="button-remove"
                    onClick={onHandleApply.bind(
                      this,
                      friendItem._id,
                      FriendApplyStatus.IGNORE
                    )}
                  >
                    {t("REMOVE")}
                  </Button>
                </div>
              </div>
            );
          })}
        <div className="contact-category-title">{t("ALL_CONTACT")}</div>
        {loading && (
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 0 }} />
            <Skeleton active={true} avatar={true} paragraph={{ rows: 0 }} />
          </div>
        )}
        {friendList.length == 0 && !loading && (
          <div className="no-content-tips">{t("NO_FRIEND_YET")}</div>
        )}
        {!loading &&
          friendList.map((friendItem, friendIndex) => {
            return (
              <div className="friend-item panel-hover" key={friendIndex}>
                <div
                  className="flex-display align-items-center cursor-pointer"
                  onClick={onGoToProfile.bind(this, friendItem)}
                >
                  <AvatarBasic
                    picture={localizeImg(friendItem.companyInfo.avatar)}
                    cname="avatar"
                    displayName={friendItem.name}
                    size={41}
                  />
                  <div className="user-name-panel">
                    <div className="user-name">{friendItem.name}</div>
                    <div className="user-role-name">
                      {friendItem?.roleInfo?.name == "ADMIN"
                        ? t(friendItem?.roleInfo?.name)
                        : friendItem?.roleInfo?.name}
                    </div>
                  </div>
                </div>
                <Dropdown
                  overlay={menu_language(friendItem)}
                  trigger={["click"]}
                >
                  <img src={moreIcon} />
                </Dropdown>
              </div>
            );
          })}
      </div>
      <div className="friends-panel-footer align-items-center">
        <img src={searchIcon} className="search-icon" />
        <Input
          placeholder={t("SEARCH_CONTACT")}
          className="search-contact"
          onChange={onSearchContentChange.bind(this)}
        />
      </div>
    </div>
  );
}
