import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Icon from "../../../../Icon";
import ModalPreview from "../../../../ModalPreview";
import {
  ProductSampleValue,
  localizeImg,
  localizeValue,
  log,
} from "../../../../../../constants/constants";
import { useTranslation } from "react-i18next";
import { deepClone } from "../../../../../../utils/utils";
import {
  getProductConfigNameById,
  getProductGrapeDetailName,
  loadProductConfig,
  productFavorite,
  productFavoriteCount,
  productFavoriteStatus,
} from "../../../../../../api/product";
import {
  getCountryNameById,
  getLocationNameById,
} from "../../../../../../api/config";
import { Dropdown, Menu } from "antd";
import downArrowIcon from "../../../../../../resource/icons/shop/info/down_arrow.svg";
import { useUserId } from "../../../../../../contexts/UserIdProvider";
//import wechat from "../../../../../../resource/icons/user/login/wechat.svg";
import wechat from "../../../../../../resource/images/wechat/vinbip-wechat-qr.png";

export default function Overview(props) {
  const { product } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const [classificationName, setClassificationName] = useState("");

  const [currentImagePreviewIndex, setCurrentImagePreviewIndex] = useState(0);
  const [currentProductPriceIndex, setCurrentProductPriceIndex] = useState(0);

  const [visibleModalPreview, setVisibleModalPreview] = useState(false);

  const [productFeatures1, setProductFeatures1] = useState([]);
  const [productFeatures2, setProductFeatures2] = useState([]);

  const [localtionList, setLocaltionList] = useState([]);
  const [classificationsList, setClassificationsList] = useState([]);

  //trading condition
  const [volumeList, setVolumeList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [shippingList, setShippingList] = useState([]);

  const [selectedShippingAddressIndex, setSelectedShippingAddressIndex] =
    useState(0);
  const [selectedPriceIndex, setSelectedIndex] = useState(0);

  const [volumeName, setVolumeName] = useState();

  const [favoriteCount, setFavoriteCount] = useState(0);
  const [favoriteStatus, setFavoriteStatus] = useState(false);

  const [grapeStr, setGrapeStr] = useState("");

  useEffect(async () => {
    if (product) {
      initProductFeatures(product);
      initPricesList(product?.prices);
      getProductFavoriteCount();
      getFavoriteStatus();
    }
  }, [product]);

  const initProductFeatures = async (productInfo) => {
    let configData = await loadProductConfig();
    let {
      locations,
      types,
      bottles,
      capsules,
      sealed,
      classifications,
      categories,
      tags,
    } = configData;

    setLocaltionList(locations);
    setClassificationsList(classifications);

    let temp = deepClone(productFeatures1);
    temp = [];
    temp.push(
      t("PRODUCT_COUNTRY") +
        ": " +
        getCountryNameById(locations, i18n.language, productInfo?.country)
    );
    temp.push(
      t("PRODUCT_CATEGORY") +
        ": " +
        getProductConfigNameById(categories, i18n.language, product.category)
    );
    temp.push(
      t("REGION") +
        ": " +
        getProductConfigNameById(locations, i18n.language, product.region)
    );
    let grapeList = await getProductGrapes(productInfo);
    setGrapeStr(grapeList.join(","));
    temp.push(t("GRAPE") + ": " + grapeList.join(","));
    temp.push(
      t("AGE_OF_VINE") + ": " + productInfo?.ageOfVine + " " + t("YEAR")
    );
    temp.push(t("VINTAGE") + ": " + productInfo?.vintage?.join(" "));
    temp.push(t("BODY") + ": " + t(productInfo?.body));

    setProductFeatures1(temp);

    let temp2 = deepClone(productFeatures2);
    temp2 = [];
    temp2.push(
      t("CLASSIFICATIONS") +
        ": " +
        getProductConfigNameById(
          locations,
          i18n.language,
          product?.classification
        )
    );
    setClassificationName(
      getProductConfigNameById(
        classifications,
        i18n.language,
        product?.classification
      )
    );
    temp2.push(
      t("AGING_PERIOD") +
        ": " +
        productInfo?.agingPeriod +
        " " +
        t("MONTHS_IN_OAK_BARREL")
    );
    let packageList = [];
    let bottleVolume = [];
    productInfo?.prices?.map((mapItem, mapIndex) => {
      packageList.push(t(mapItem?.package));
      bottleVolume.push(mapItem?.volume);
    });
    temp2.push(t("PACKAGE") + ": " + packageList.join(","));
    temp2.push(t("BOTTLE_VOLUME") + ": " + bottleVolume.join(","));
    temp2.push(t("ALCOHOL_LEVEL") + ": " + productInfo?.alcoholDegree);
    temp2.push(
      t("SERVING_TEMPERATURE") + ": " + productInfo?.servingTemperature
    );
    temp2.push(t("ACIDITY") + ": " + t(productInfo?.acidity));

    setProductFeatures2(temp2);
  };

  const initPricesList = (prices) => {
    if (prices && prices.length > 0) {
      console.log("--------------------prices:", prices);
      //volument list.
      let tempVolumeList = [];
      //package list.
      let tempPackageList = [];
      //temp shipping list.
      let tempShippingList = [];
      prices.map((item) => {
        if (item.volume) {
          tempVolumeList.push({
            label: item.volume + " ML",
            value: item.volume,
          });
        }
        if (item.package) {
          tempPackageList.push({
            label: t(item.package),
            value: item.package,
          });
        }
        if (
          item.shippingAddress &&
          !tempShippingList.some((obj) => obj.value === item.shippingAddress)
        ) {
          tempShippingList.push({
            value: item.shippingAddress,
            label: item.shippingAddressInfo?.name,
          });
        }
      });
      setVolumeList(tempVolumeList);
      setPackageList(tempPackageList);
      setShippingList(tempShippingList);
    }
  };

  const getProductGrapes = async (productInfo) => {
    let list = [];
    if (productInfo.grape && productInfo.grape.length > 0) {
      for (let index = 0; index < productInfo.grape.length; index++) {
        const grapeId = productInfo.grape[index];
        let pms = { _id: grapeId };
        let name = await getProductGrapeDetailName(pms, i18n.language);
        if (name) {
          list.push(name);
        }
      }
    }
    return list;
  };

  const menu_other_volumes = (menu_list, action) => {
    return (
      <Menu>
        {menu_list.map((item, index) => (
          <Menu.Item
            key={index}
            className="mapboxgl-ctrl-geocoder--suggestion"
            onClick={() =>
              action ? action(item.value, index) : setSelectedIndex(index)
            }
          >
            <span>
              {/* {item.value === ProductSampleValue
                ? t("SAMPLES")
                : item.label + "ML"} */}
              {item.label}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const menu_office_wechat_qr = () => {
    return (
      <Menu>
        <Menu.Item key={1}>
          <img src={wechat} style={{ width: "108px", marginLeft: "10px" }} />
        </Menu.Item>
      </Menu>
    );
  };

  const onShippingAddressMenuItemClick = (value, index) => {
    setSelectedShippingAddressIndex(index);

    if (product?.prices) {
      //volument list.
      let tempVolumeList = [];
      //package list.
      let tempPackageList = [];
      product?.prices.map((item) => {
        if (item.shippingAddress == value) {
          if (item.volume) {
            tempVolumeList.push({
              label: item.volume + " ML",
              value: item.volume,
            });
          }
          if (item.package) {
            tempPackageList.push({
              label: t(item.package),
              value: item.package,
            });
          }
        }
      });
      setVolumeList(tempVolumeList);
      setPackageList(tempPackageList);
    }
  };

  const getProductFavoriteCount = async () => {
    let pms = { productId: product._id };
    let result = await productFavoriteCount(pms);
    setFavoriteCount(result);
  };

  const getFavoriteStatus = async () => {
    let pms = {
      productId: product._id,
      coId: user.userInfo.companyId,
    };

    let result = await productFavoriteStatus(pms);
    setFavoriteStatus(result);
  };

  const toggleProductFavorite = async () => {
    let pms = {
      productId: product._id,
      coId: user.userInfo.companyId,
      userId: user.userId,
    };
    await productFavorite(pms);
    getProductFavoriteCount();
    getFavoriteStatus();
  };

  return (
    <>
      <div className={styles.overview}>
        <div className={cn("h4", styles.title)}>
          <span>{localizeValue(i18n.language, product?.name)}</span>
          <div className={styles.btns}>
            <button
              className={cn("button-stroke", styles.favorite)}
              onClick={toggleProductFavorite.bind(this)}
            >
              <Icon name={favoriteStatus ? "heart-fill" : "heart"} size="24" />
              <span>{favoriteCount}</span>
            </button>
            {/* <button className={cn("button", styles.buy)}>
            <span className={styles.price}>$89</span>
            <span className={styles.inner}>
              Download<span> now</span>
              <Icon name="download" size="24" />
            </span>
          </button> */}
          </div>
        </div>
        <div className={styles.info}>{classificationName}</div>
        <div className={styles.line}>
          <div className={styles.author}>
            <div className={styles.avatar}>
              <img
                src={
                  Array.isArray(product?.companyInfo?.avatar)
                    ? localizeImg(product?.companyInfo?.avatar[0])
                    : localizeImg(product?.companyInfo?.avatar)
                }
                alt={localizeValue(
                  i18n.language,
                  product?.companyInfo?.companyName
                )}
              />
            </div>
            by{" "}
            <span>
              {localizeValue(i18n.language, product?.companyInfo?.companyName)}
            </span>
          </div>
          {/* <div className={styles.rating}>
            <Icon name="star-fill" size="24" />
            4.8
            <span className={styles.counter}>(87)</span>
          </div> */}
        </div>
        <div className={styles.gallery}>
          <div className={styles.galleryIndex}>
            {product?.images?.map((x, index) => (
              <div
                className={styles.galleryItem}
                key={index}
                onMouseEnter={() => setCurrentImagePreviewIndex(index)}
              >
                <img src={localizeImg(x)} alt="Product" />
              </div>
            ))}
          </div>
          <div className={styles.preview}>
            {product?.images && product?.images.length > 0 && (
              <img
                src={localizeImg(product?.images[currentImagePreviewIndex])}
                alt="Product"
              />
            )}
            <button
              className={cn("button-white", styles.button)}
              onClick={() => setVisibleModalPreview(true)}
            >
              {t("VIEW_ALL")}
            </button>
          </div>
          <div className={styles.previewDetails}>
            {product?.prices && product?.prices.length > 0 && (
              <div
                className={cn("title-red", styles.subtitle)}
                style={{ marginBottom: "0px" }}
              >
                {product?.prices[selectedPriceIndex].price}
                {/* {product?.prices.length > 1
                  ? "-" + product?.prices[product?.prices.length - 1]
                  : ""}{" "} */}
                {product?.prices[selectedPriceIndex].currency} {t("EXW")}
              </div>
            )}
            <div className={styles.row} style={{ marginTop: "32px" }}>
              <div className={styles.col}>
                <div className={styles.productPropInfo}>
                  {t("VINTAGE")}
                  {" : "}
                  <div className={styles.productPropValue}>
                    {product?.vintage?.join(" ")}
                  </div>
                </div>
                <div className={styles.productPropInfo}>
                  {t("MINIMUM_ORDER")}
                  {" : "}
                  <div className={styles.productPropValue}>
                    <div>
                      {product?.prices && product?.prices.length > 0
                        ? product?.prices[currentProductPriceIndex].minCount
                        : ""}
                    </div>
                  </div>
                </div>
                <div className={styles.productPropInfo}>
                  {t("REGION")}
                  {" : "}
                  <div className={styles.productPropValue}>
                    <div>
                      {getProductConfigNameById(
                        localtionList,
                        i18n.language,
                        product.region
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.productPropInfo}>
                  {t("GRAPE")}
                  {" : "}
                  <div className={styles.productPropValue}>
                    <div>{grapeStr}</div>
                  </div>
                </div>
                <div className={styles.productPropInfo}>
                  {t("ALCOHOL_LEVEL")}
                  {" : "}
                  <div className={styles.productPropValue}>
                    <div>
                      {product?.alcoholDegree}
                      {"%"}
                    </div>
                  </div>
                </div>
                <div className={styles.productPropInfo}>
                  {t("CLASSIFICATIONS")}
                  {" : "}
                  <div className={styles.productPropValue}>
                    <div>
                      {getProductConfigNameById(
                        classificationsList,
                        i18n.language,
                        product?.classification
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.productPropInfo}>
                  {t("SERVING_TEMPERATURE")}
                  {" : "}
                  <div className={styles.productPropValue}>
                    <div>{product?.servingTemperature}</div>
                  </div>
                </div>
                {/* <div className={styles.productPropInfo}>{t("WAREHOUSE")}</div>
                <div className={styles.productPropInfo}>{t("NET_VOL")}</div>
                <div className={styles.productPropInfo}>{t("PACKAGE")}</div>

                <div className={styles.productPropInfo}>
                  {t("AVAILABLE_STOCK")}
                </div>

                <div className={styles.productPropInfo}>
                  {t("TRADING_CURRENCY")}
                </div> */}
                <Dropdown overlay={menu_office_wechat_qr()} trigger={["click"]}>
                  <button className={cn("button", styles.askButton)}>
                    {t("ASK_FOR_SAMPLE")}
                  </button>
                </Dropdown>
              </div>
              {/* <div className={styles.col}>
                <div className={styles.productPropValue}>
                  {product?.vintage?.join(" ")}
                </div>
                <div className={styles.productPropValue}>
                  <div>
                    {product?.prices && product?.prices.length > 0
                      ? product?.prices[currentProductPriceIndex].minCount
                      : ""}
                  </div>
                </div>
                <div className={styles.productPropValue}>
                  <div>
                    {getProductConfigNameById(
                      localtionList,
                      i18n.language,
                      product.region
                    )}
                  </div>
                </div>
                <div className={styles.productPropValue}>
                  <div>{grapeStr}</div>
                </div>
                <div className={styles.productPropValue}>
                  <div>
                    {product?.alcoholDegree}
                    {"%"}
                  </div>
                </div>
                <div className={styles.productPropValue}>
                  <div>
                    {getProductConfigNameById(
                      classificationsList,
                      i18n.language,
                      product?.classification
                    )}
                  </div>
                </div>
                <div className={styles.productPropValue}>
                  <div>{product?.servingTemperature}</div>
                </div>
                <div className={styles.productPropValue}>
                  <Dropdown
                    overlay={menu_other_volumes(
                      shippingList,
                      onShippingAddressMenuItemClick
                    )}
                    trigger={["click"]}
                  >
                    <div className={styles.dropdownPlaceholder}>
                      {shippingList[selectedShippingAddressIndex]?.label}{" "}
                      <img src={downArrowIcon} />
                    </div>
                  </Dropdown>
                </div>
                <div className={styles.productPropValue}>
                  <Dropdown
                    overlay={menu_other_volumes(volumeList)}
                    trigger={["click"]}
                  >
                    <div className={styles.dropdownPlaceholder}>
                      {volumeList[selectedPriceIndex]?.label}{" "}
                      <img src={downArrowIcon} />
                    </div>
                  </Dropdown>
                </div>
                <div className={styles.productPropValue}>
                  <Dropdown
                    overlay={menu_other_volumes(packageList)}
                    trigger={["click"]}
                  >
                    <div className={styles.dropdownPlaceholder}>
                      {packageList[selectedPriceIndex]?.label}{" "}
                      <img src={downArrowIcon} />
                    </div>
                  </Dropdown>
                </div>
                <div className={styles.productPropValue}>
                  <div>
                    {product?.prices && product?.prices.length > 0
                      ? product?.prices[currentProductPriceIndex].stock
                      : ""}
                  </div>
                </div>

                <div className={styles.productPropValue}>
                  <div>
                    {product?.prices && product?.prices.length > 0
                      ? product?.prices[currentProductPriceIndex].currency
                      : ""}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-purple", styles.subtitle)}>
              {t("TECHNICAL_INFORMATION")}
            </div>
            <div className={styles.displayRow}>
              <ul className={styles.features}>
                {productFeatures1.map((x, index) => (
                  <li key={index}>{x}</li>
                ))}
              </ul>
              <ul className={styles.features}>
                {productFeatures2.map((x, index) => (
                  <li key={index}>{x}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red", styles.subtitle)}>
              {t("TASTING_NOTE")}
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: localizeValue(i18n.language, product?.tastingNote),
              }}
            ></div>
          </div>
        </div>
        {product?.details && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "24px",
            }}
          >
            {product?.details.map((mapItem) => {
              return <img src={localizeImg(mapItem)} alt="Product" />;
            })}
          </div>
        )}
      </div>
      <ModalPreview
        visible={visibleModalPreview}
        onClose={() => setVisibleModalPreview(false)}
        gallery={product?.images}
        title={product?.title}
        figcaption={classificationName}
        // download
      />
    </>
  );
}
