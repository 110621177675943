import React, { useState } from "react";
import PropTypes from "prop-types";
import { PostTargetType } from "@amityco/js-sdk";

import withSDK from "../../../core/hocs/withSDK";
import * as utils from "../../../helpers/utils";
import UserMediaGallery from "../../components/UserMediaGallery";

import UserInfo from "../../components/UserInfo";
import FeedHeaderTabs from "../../components/FeedHeaderTabs";

import Feed from "../../components/Feed";
import Followers from "../UserGallery/Followers";

import { tabs, UserFeedTabs } from "./constants";
import { FollowersTabs } from "../UserGallery/Followers/constants";
import useFollow from "../../../core/hooks/useFollow";
import { Wrapper } from "./styles";

const UserGallery = ({ userId, currentUserId, networkSettings, type }) => {
  const isPrivateNetwork = utils.isPrivateNetwork(networkSettings);

  const [activeTab, setActiveTab] = useState(UserFeedTabs.TIMELINE);
  const [followActiveTab, setFollowActiveTab] = useState(
    FollowersTabs.FOLLOWINGS
  );

  const isMe = userId === currentUserId;

  const { isFollowAccepted } = useFollow(currentUserId, userId);
  const isHiddenProfile = isPrivateNetwork && !isFollowAccepted && !isMe;

  const filteredTabs = isHiddenProfile
    ? tabs.filter(({ value }) => value === UserFeedTabs.TIMELINE)
    : tabs;

  return (
    // key prop is necessary here, without it this part will never re-render !!!
    <Wrapper>
      <UserMediaGallery targetType={"user"} targetId={userId} mediaType={type} />
    </Wrapper>
  );
};

UserGallery.propTypes = {
  userId: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  networkSettings: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default withSDK(UserGallery);
