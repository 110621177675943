import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { FormControls } from "../../../constants/constants";
import { deepClone } from "../../../utils/utils";
import InputCapture from "../input/InputCapture";
import InputEmail from "../input/InputEmail";
import InputNormal from "../input/InputNormal";
import InputNormalExt from "../input/InputNormalExt";
import InputNumberBase from "../input/InputNumberBase";
import InputNumberUnit from "../input/InputNumberUnit";
import InputPhoneNumber from "../input/InputPhoneNumber";
import InputPwdNormal from "../input/InputPwdNormal";
import AddressInput from "../location/addressInput/AddressInput";
import SelectMultipleNormal from "../select/SelectMultipleNormal";
import SelectNormal from "../select/SelectNormal";
import VSelectMultipleNormal from "../VSelectMultiple/VSelectMultipleNormal";
import "../style/formNormalW.less";
import RadioGroupNormal from "./RadioGroupNormal";
import { useTranslation } from "react-i18next";
import UserLicenseCopyUploader from "../userLicenseCopyUploader/UserLicenseCopyUploader";
import CompanyLicenseCopyUploader from "../companyLicenseCopyUploader/CompanyLicenseCopyUploader";

export default function FormNormalW(props) {
  const { t, i18n } = useTranslation();

  const { phoneNumber, items, onChange, onCheckEmail, onCheckPhone,disabled } = props;
  const [fields, setFields] = useState(items);

  useEffect(() => {
    setFields(items);
  }, [items]);

  const onControlChange = (val, key) => {
    // console.log('-------control change:', key, val);
    let list = deepClone(fields);
    list[key].value = val;
    onChange(list);
  };

  const onInputEmailBlur = (val) => {
    onCheckEmail && onCheckEmail(val);
  };

  const onInputPhoneBlur = (val) => {
    onCheckPhone && onCheckPhone(val);
  };

  return (
    <>
      {fields.map((item, index) => (
        // <Row className="input-row">
          <Col
            span={24}
            style={{ marginBottom: "10px" }}
            key={index}
          >
            {item.type === FormControls.TEXT && (
              <InputNormal
                {...item}
                index={index}
                disabled={disabled}
                onChange={onControlChange.bind(this)}
              />
            )}
            {item.type === FormControls.TEXTEXT && (
              <InputNormalExt
                {...item}
                index={index}
                onChange={onControlChange.bind(this)}
              />
            )}
            {item.type === FormControls.EMAIL && (
              <InputEmail
                {...item}
                index={index}
                disabled={disabled}
                onChange={onControlChange.bind(this)}
                onBlur={onInputEmailBlur.bind(this)}
              />
            )}
            {item.type === FormControls.PASSWORD && (
              <InputPwdNormal
                value={item.value}
                label={item.label}
                index={index}
                onChange={onControlChange.bind(this)}
              />
            )}
            {item.type === FormControls.SELECT && (
              <SelectNormal
                {...item}
                index={index}
                onChange={onControlChange.bind(this)}
              />
            )}
            {item.type === FormControls.ADDRESSINPUT && (
              <AddressInput
                {...item}
                index={index}
                disabled={disabled}
                onChange={onControlChange.bind(this)}
              />
            )}
            {item.type === FormControls.SELECTMULTIPLE && (
              <VSelectMultipleNormal
                {...item}
                index={index}
                onChange={onControlChange.bind(this)}
              />
            )}
            {item.type === FormControls.NUMBER && (
              <InputNumberBase
                value={item.value}
                label={item.label}
                index={index}
                unit={item.unit}
                onChange={onControlChange.bind(this)}
              />
            )}
            {item.type === FormControls.NUMBERUNIT && (
              <InputNumberUnit
                {...item}
                index={index}
                onChange={onControlChange.bind(this)}
              />
            )}
            {item.type === FormControls.RADIOGROUP && (
              <RadioGroupNormal
                value={item.value}
                label={item.label}
                index={index}
                data={item.data}
                disabled={item.disabled}
                onChange={onControlChange.bind(this)}
              />
            )}
            {item.type === FormControls.PHONENUMBER && (
              <InputPhoneNumber
                {...item}
                index={index}
                onChange={onControlChange.bind(this)}
                onBlur={onInputPhoneBlur.bind(this)}
              />
            )}
            {item.type === FormControls.CAPTURE && (
              <InputCapture
                {...item}
                phoneNumber={phoneNumber}
                index={index}
                onChange={onControlChange.bind(this)}
              />
            )}
            {item.type === FormControls.UPLOADUSERIDCOPY && (
              <UserLicenseCopyUploader
                {...item}
                index={index}
                disabled={disabled}
                onChange={onControlChange.bind(this)}
              />
            )}
            {item.type === FormControls.UPLOADCOMPANYLICENSECOPY && (
              <CompanyLicenseCopyUploader
                {...item}
                index={index}
                disabled={disabled}
                onChange={onControlChange.bind(this)}
              />
            )}
          </Col>
        //   {(item.descT || item.desc || item.descT == "PLACE_HOLDER") && (
        //     <Col
        //       style={{
        //         padding: "0px 46px",
        //         display: "flex",
        //         alignItems: "center",
        //         color: "#838383",
        //         fontSize: "14px",
        //         fontWeight: "400",
        //         flex: "1",
        //         borderTopLeftRadius: index == 0 ? "10px" : "0px",
        //         borderTopRightRadius: index == 0 ? "10px" : "0px",
        //         borderBottomLeftRadius:
        //           index == fields.length - 1 ? "10px" : "0px",
        //         borderBottomRightRadius:
        //           index == fields.length - 1 ? "10px" : "0px",
        //         display: "flex",
        //         alignItems: "center",
        //       }}
        //     >
        //       <div>
        //         {item.descT
        //           ? t(item.descT != "PLACE_HOLDER" ? item.descT : "")
        //           : item.desc}
        //       </div>
        //     </Col>
        //   )}
        // </Row>
      ))}
    </>
  );
}
