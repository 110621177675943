import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Button, Col, Input, Row, Progress } from "antd";
import RichTextAreaBasic from "../common/text-area/RichTextAreaBasic";
import UploadNormal from "../common/upload/UploadNormal";
import UploadPng from "../../resource/images/upload/Upload.PNG";
import ArticleImageUpload from "../common/upload/ArticleImageUpload";
import { useUserId } from "../../contexts/UserIdProvider";
import { articleCreate } from "../../api/article.ts";
import { localizeImg } from "../../constants/constants";
import { articleUpdate, oneArticle } from "../../api/article";
import "./style/articleEditor.less";
import { toastError } from "../../api/toast";

export default function ArticleEditor() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [language, setLanguage] = useState(i18n.language);
  const [bannerUrl, setBannerUrl] = useState("");
  const [banner, setBanner] = useState();
  const [editor, setEditor] = useState();
  const [desc, setDesc] = useState("");

  useEffect(() => {
    if (id) {
      initArticleDetail();
      setUploadPercent(100);
    }
  }, [id]);

  useEffect(() => {
    if (editor && desc) {
      editor.setData(desc);
    }
  }, [editor, desc]);

  const initArticleDetail = async () => {
    let pms = { id };
    let result = await oneArticle(pms);
    if (result && result.length > 0) {
      let item = result[0];
      setTitle(item.title);
      setLanguage(item.language);
      if (item.banner && item.banner.cn) {
        setBanner(item.banner);
        setBannerUrl(localizeImg(item.banner));
      } else if (item.banner && item.banner.length > 0) {
        setBanner(item.banner[0]);
        setBannerUrl(localizeImg(item.banner[0]));
      }
      setDesc(item.content);
    }
  };

  const [uploadPercent, setUploadPercent] = useState(0);

  const onFileLoading = (index, percent, url) => {
    setUploadPercent(percent);
    if (percent === 0) {
      setBannerUrl(url);
    }
  };

  const onFileLoaded = (data) => {
    if (data.length > 0) {
      setBanner(data[0]);
      setBannerUrl(localizeImg(data[0]));
    }
  };
  const onDescChange = (e) => {
    setEditor(e);
  };

  const onFinish = async () => {
    let text = editor.getData();
    let pms = {
      userId: user.userId,
      title: title,
      content: text,
      banner: banner,
      language: language,
    };

    if (!text || !title || !banner) {
      toastError(t("TITLE_CONTENT_BANNER_REQUIRED"));
      return;
    }

    let data;
    if (id) {
      pms._id = id;
      data = await articleUpdate(pms);
      console.log("update result:", data, pms);
    } else {
      data = await articleCreate(pms);
    }
    if (data) {
      history.push("/my-news");
    }
  };

  return (
    <div className="common-page article-editor-container">
      <div className="common-management modal-body">
        <div className="common-management-title">{t("ARTICLE_CREATE")}</div>

        <div className="create-article-container common-management-panel">
          <div
            className="modal-content-column"
            style={{
              alignItems: "center",
            }}
          >
            {/* <div className="content-row-label">{t("ARTICLE_TITLE")}</div> */}
            <Input
              className="article-title-input"
              placeholder={t("ARTICLE_TITLE")}
              value={title}
              onChange={(val) => setTitle(val.target.value)}
            />
          </div>
          <div
            className="modal-content-column custom-editor"
            style={{
              alignItems: "center",
            }}
          >
            {/* <div className="content-row-label">{t("ARTICLE_CONTENT")}</div> */}
            <RichTextAreaBasic
              value={desc}
              placeholder={"PLEASE_ENTER_THE"}
              onInit={onDescChange.bind(this)}
            />
          </div>
          <div
            className="article-ext-container"
            style={{
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "800px",
                display: "flex",
              }}
            >
              <div
                className="content-row-label"
                style={{
                  marginRight: "20px",
                }}
              >
                {t("ARTICLE_BANNER")}
              </div>
              <UploadNormal
                onLoading={onFileLoading.bind(this, 2)}
                onFileLoaded={onFileLoaded.bind(this)}
              >
                <ArticleImageUpload url={bannerUrl} />
                <Progress percent={uploadPercent}></Progress>
                <span>{t("UPLOAD_ARTICLE_BANNER_IMG_TIPS")}</span>
              </UploadNormal>
            </div>
          </div>
        </div>
      </div>
      <div
        className="article-create-publish-btn"
        style={{
          alignItems: "center",
        }}
      >
        <div>
          <Button
            onClick={onFinish.bind(this)}
            className="common-primary-button"
            style={{
              width: "250px",
            }}
            role="button"
          >
            {t("PUBLISH")}
          </Button>
        </div>
      </div>
    </div>
  );
}
