import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./CompInfoEditor.module.sass";
import { useTranslation } from "react-i18next";
import NameAndDescription from "./NameAndDescription";
import Panel from "./Panel";
import { Modal } from "antd";
import { deepClone } from "../../../../utils/utils";
import { eventCreate } from "../../../../api/events";
import { toastError, toastInfo } from "../../../../api/toast";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { saveCompanyInfo } from "../../../../api/company";

const CompInfoEditor = ({
  visible,
  onClose,
  onCloseModal,
  onSaved,
  dataSource,
}) => {
  const { t } = useTranslation();
  const { user } = useUserId();

  const [formData, setFormData] = useState({});

  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal-product");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  const onNameAndDescriptionChange = (fields) => {
    console.log("onNameAndDescriptionChange:", fields);
    setFormDataValue(fields);
  };

  const setFormDataValue = (fields) => {
    let clone = deepClone(formData);
    clone = Object.assign({}, clone, fields);
    setFormData(clone);
  };

  const onPublishProduct = async () => {
    const pms = {
      ...formData,
    };
    console.log("saveCompanyInfo pms:", pms);
    const result = await saveCompanyInfo(pms);
    if (result) {
      toastInfo(t("SAVE_SUCCESS"));
      onSaved && onSaved();
      onCloseModal && onCloseModal();
    } else {
      toastError(t("SAVE_FAILED"));
    }
  };

  return createPortal(
    <Modal
      visible={visible}
      footer={null}
      title={null}
      width={664}
      height={138}
      closable={false}
      destroyOnClose={true}
      // onCancel={() => onCloseModal && onCloseModal()}
    >
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription
            className={styles.card}
            onChange={onNameAndDescriptionChange.bind(this)}
            dataSource={dataSource}
            onClose={() => onCloseModal && onCloseModal()}
          />
        </div>
      </div>
      <Panel onPublish={onPublishProduct.bind(this)} />
    </Modal>,
    document.body
  );
};

export default CompInfoEditor;
