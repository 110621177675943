import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Divider,
  message,
  Modal,
  Popconfirm,
  Row,
} from "antd";
import InputCountBasic from "../../common/input/InputCountBasic";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../contexts/UserIdProvider";
import { getCarts, cartRemove } from "../../../api/carts";
import { deepClone, constants } from "../../../utils/utils";
import checkedIcon from "./images/checked.svg";
import uncheckedIcon from "./images/uncheck.svg";
import tipIcon from "../../../resource/icons/common/tip.svg";
import { useTranslation } from "react-i18next";
import "./style/poi.less";
import {
  ExchangeRateType,
  localizeImg,
  localizeTitle,
  ProductCurrencyType,
  ProductSampleValue,
} from "../../../constants/constants";
import ListBasic from "../../common/list/ListBasic";
import InfiniteScroll from "react-infinite-scroll-component";
import { SYS_ENV_PRODUCT_DEPOSIT } from "../../../constants/env.enum";
import { toastInfo } from "../../../api/toast";
import { getExchangeRate } from "../../../api/product";
import { dateFormat } from "../../../utils/date";
import { checkLimit } from "../../../api/common";
import { RoleLimit } from "../../../constants/roleType.enum";
import rightArrowIcon from "./images/right_arrow.svg";
import messageIcon from "./images/message.svg";
import { getCompanyMainUser } from "../../../api/company";
import { statusCode } from "../../../constants/statusCode.enum";
import AvatarBasic from "../avatar/AvatarBasic";
import OrderConfirmPanel from "../order/orderConfirm/OrderConfirmPanel";

export default function PoiPanel() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const { userId } = user;
  const history = useHistory();

  const [carts, setCarts] = useState([]);
  const [groupCarts, setGroupCarts] = useState([]);
  const [selecteCount, setSelecteCount] = useState(0);
  const [selectTotalPrice, setSelectTotalPrice] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 20;
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  const [localStock, setLocalStock] = useState(false);
  const [overseaStockCarts, setOverseaStockCarts] = useState([]);
  const [overseaGroupCarts, setOverseaGroupCarts] = useState([]);
  const [localStockCarts, setLocalStockCarts] = useState([]);
  const [localGroupCarts, setLocalGroupCarts] = useState([]);

  const [orderList, setOrderList] = useState([]);

  const [chooseServiceVisible, setChooseServiceVisible] = useState(false);
  const [orderConfirmVisible, setOrderConfirmVisible] = useState(false);
  const [importTypeVisible, setImportTypeVisible] = useState(false);
  const [importByOwnVisible, setImportByOwnVisible] = useState(false);
  const [importByVinbipVisible, setImportByVinbipVisible] = useState(false);

  useEffect(() => {
    loadCarts();
  }, [pageIndex]);

  useEffect(() => {
    if (groupCarts && groupCarts.length > 0) {
      onGroupCartsChange();
    }
  }, [groupCarts]);

  const onGroupCartsChange = async () => {
    let totalPrice = 0;
    let totalCount = 0;
    for (let i = 0; i < groupCarts.length; i++) {
      const element = groupCarts[i];
      for (let j = 0; j < element.products.length; j++) {
        const p = element.products[j];
        if (p.selected) {
          let itemPrice = Number(p.orderPrice.payPrice);
          console.log("------payprice:", itemPrice, p.currency);
          itemPrice = await getExchangeRatePrice(itemPrice, p.currency);
          console.log("------itemprice:", itemPrice);
          totalPrice += itemPrice;
          totalCount++;
        }
      }
    }
    setSelecteCount(totalCount);
    setSelectTotalPrice(totalPrice.toFixed(2));
  };

  const getExchangeRatePrice = async (itemPrice, currency) => {
    if (
      currency === ProductCurrencyType.EUR ||
      currency === ProductCurrencyType.USD
    ) {
      let pms = {
        from:
          currency === ProductCurrencyType.EUR
            ? ExchangeRateType.EUR
            : ExchangeRateType.USD,
        to: ExchangeRateType.CNY,
      };
      let rate = await getExchangeRate(pms);
      if (rate > 0) {
        itemPrice = itemPrice * rate;
      }
    }
    return itemPrice;
  };

  const loadCarts = async () => {
    let pms = { userId: userId, page: pageIndex, per_page: pageSize };
    let result = await getCarts(pms);
    console.log("------load carts:", result);
    if (result && result.total > 0) {
      result = result.result
        .filter(
          (item) =>
            item.productInfo &&
            item.productInfo.length > 0 &&
            item.productInfo[0].companyInfo
        )
        .map((item) => {
          item.disabled =
            dateFormat(item.productInfo[0].updatedAt) >
            dateFormat(item.createdAt);
          item.selected = false;
          item.productInfo[0].title = localizeTitle(
            i18n.language,
            item.productInfo[0].title
          );
          item.orderPrice.payPrice =
            item.orderPrice.totalPrice *
            (item.orderProduct.volume.volume === ProductSampleValue
              ? 1
              : SYS_ENV_PRODUCT_DEPOSIT);
          item.currency = item.productInfo[0].currency;
          item.companyInfo = item.productInfo[0].companyInfo;
          item.companyInfo.companyName =
            item.companyInfo.displayName || item.companyInfo.companyName;
          return item;
        });
      if (pageIndex > 0) {
        let list = carts.map((item) => item);
        result = list.concat(result);
      }
      console.log("tempLocalStockResult:", result);

      let tempLocalStockResult = deepClone(
        result.filter(
          (filterItem) =>
            filterItem.orderProduct.volume.stockCountry ==
            constants.chinaCountryId
        )
      );
      let tempOverseaStockResult = deepClone(
        result.filter(
          (filterItem) =>
            filterItem.orderProduct.volume.stockCountry !=
            constants.chinaCountryId
        )
      );

      tempLocalStockResult = deepClone(tempLocalStockResult);
      tempOverseaStockResult = deepClone(tempOverseaStockResult);
      console.log("tempLocalStockResult:", tempOverseaStockResult);
      tempLocalStockResult.map((mapItem) => {
        mapItem.companyInfo = mapItem.productInfo[0].companyInfo;
      });
      tempOverseaStockResult.map((mapItem) => {
        mapItem.companyInfo = mapItem.productInfo[0].companyInfo;
      });

      let tempLocalGroupCarts = getGroupCarts(tempLocalStockResult);
      let tempOverseaGroupCarts = getGroupCarts(tempOverseaStockResult);

      setGroupCarts(tempOverseaGroupCarts);
      setCarts(tempOverseaStockResult);

      setLocalStockCarts(tempLocalStockResult);
      setOverseaStockCarts(tempOverseaStockResult);
      setLocalGroupCarts(tempLocalGroupCarts);
      setOverseaGroupCarts(tempOverseaGroupCarts);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const getGroupCarts = (source) => {
    let groupList = [];
    if (source && source.length > 0) {
      source.forEach((item) => {
        console.log("getGroupCarts:", item);
        let list = groupList.filter((g) => g.coId === item.companyInfo.coId);
        if (list.length === 0) {
          groupList.push({
            coId: item.companyInfo.coId,
            companyName: item.companyInfo.companyName,
            avatar: item.companyInfo.avatar,
          });
        }
      });
      groupList = groupList.map((item) => {
        let list = source
          .filter((r) => r.companyInfo.coId === item.coId)
          .map((item) => {
            return {
              _id: item._id,
              orderPrice: item.orderPrice,
              orderProduct: item.orderProduct,
              productInfo: item.productInfo,
              selected: item.selected === true ? true : false,
              currency: item.currency,
              disabled: item.disabled,
            };
          });
        item.products = list;
        return item;
      });
    }

    return groupList;
  };

  const removePOI = async (id, gindex, index) => {
    const pms = { cartId: id };
    const result = await cartRemove(pms);
    if (result) {
      let list = deepClone(groupCarts);
      list[gindex].products = list[gindex].products.filter(
        (n, nindex) => nindex !== index
      );
      if (list[gindex].products.length > 0) {
        let fList = list[gindex].products.filter(
          (item) => item.selected === true
        );
        if (fList.length === list[gindex].products.length) {
          list[gindex].selected = true;
        } else {
          list[gindex].selected = false;
        }
      } else {
        list = list.filter((f, findex) => findex !== gindex);
      }
      setGroupCarts(list);
      onCompanySelectChange(list);
    }
  };

  const onCompanySelectChange = async (list) => {
    let fList = list.filter((item) => item.selected === true);
    if (fList.length === list.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const onPOIChange = (gindex, index) => {
    let list = deepClone(groupCarts);
    list[gindex].products[index].selected =
      !list[gindex].products[index].selected;
    let fList = list[gindex].products.filter((item) => item.selected === true);

    let sampleList = list[gindex].products.filter(
      (item) =>
        item.orderProduct.volume.volume === ProductSampleValue && item.selected
    );
    // console.log('-------sample list :', sampleList);
    if (sampleList.length > 0) {
      let fList1 = list[gindex].products.filter((item) => item.selected);
      // console.log('-------fList list :', fList1);
      if (fList1.length > sampleList.length) {
        toastInfo(t("CAN_NOT_BUY_SAMPLE_AND_WINE_IN_ONE_COMPANY"));
        return;
      }
    }

    if (fList.length === list[gindex].products.length) {
      list[gindex].selected = true;
    } else {
      list[gindex].selected = false;
    }
    console.log("-----onPOIchange :", list[gindex].products, fList);
    setGroupCarts(list);
    onCompanySelectChange(list);
  };

  const onGoToCompany = async (e) => {
    // console.log(e);
    let result = await getCompanyMainUser({ coId: e.coId });
    if (result && result.statusCode == statusCode.SUCCESS) {
      history.push("/profile-deprecated/" + result.result.adminUserId);
    }
  };

  const onCompanyChange = (gindex) => {
    let list = deepClone(groupCarts);
    let status = !list[gindex].selected;
    list[gindex].selected = status;
    list[gindex].products = list[gindex].products.map((item) => {
      item.selected = status;
      return item;
    });

    if (status) {
      if (checkCompanyProductsHaveSample(list[gindex])) return;
      if (checkCompanyProductsExpired(list[gindex])) return;
    }

    setGroupCarts(list);
    onCompanySelectChange(list);
  };

  const checkCompanyProductsExpired = (item) => {
    let list = item.products.filter((item) => item.disabled);
    if (list.length > 0) {
      toastInfo(t("PRODUCT_CHANGE_WHEN_CHECK"));
      return true;
    }
    return false;
  };

  const checkCompanyProductsHaveSample = (item) => {
    let sampleList = item.products.filter(
      (item) => item.orderProduct.volume.volume === ProductSampleValue
    );
    if (sampleList.length > 0 && item.products.length > sampleList.length) {
      toastInfo(t("CAN_NOT_BUY_SAMPLE_AND_WINE_IN_ONE_COMPANY"));
      return true;
    }
    return false;
  };

  const onCheckAll = async () => {
    const currentStatus = !selectAll;
    let list = deepClone(groupCarts);

    if (currentStatus) {
      let flag = false;
      list.forEach((item) => {
        flag = checkCompanyProductsHaveSample(item);
        if (flag) {
          return;
        }
        flag = checkCompanyProductsExpired(item);
        if (flag) {
          return;
        }
      });
      if (flag) return;
    }

    list = list.map((item) => {
      item.selected = currentStatus;
      item.products = item.products.map((p) => {
        p.selected = currentStatus;
        return p;
      });
      return item;
    });
    setGroupCarts(list);
    setSelectAll(currentStatus);
  };

  const onCountChange = (gindex, index, val) => {
    // console.log('-------oncountchange:', gindex, index, val);
    let list = deepClone(groupCarts);
    let item = list[gindex].products[index];
    item.orderProduct.count = val;
    let { priceRange } = item.orderProduct.volume;
    if (priceRange && priceRange.length > 1) {
      priceRange = priceRange
        .filter((p) => p.minCount <= item.orderProduct.count)
        .sort((a, b) => b.minCount - a.minCount);
      if (priceRange && priceRange.length > 0) {
        item.orderPrice.price = priceRange[0].price.value;
      }
    }
    item.orderPrice.totalPrice =
      item.orderPrice.price * item.orderProduct.count;
    item.orderPrice.payPrice =
      item.orderPrice.totalPrice *
      (item.volume.volume === ProductSampleValue ? 1 : SYS_ENV_PRODUCT_DEPOSIT);
    setGroupCarts(list);
  };

  const toOrderConfirm = () => {
    if (checkLimit(user.userInfo, RoleLimit.PRODUCT_ORDER, history)) {
      setImportTypeVisible(false);
      setImportByVinbipVisible(false);

      let list = [];
      groupCarts.forEach((g) => {
        g.products.forEach((item) => {
          if (item.selected) {
            list.push({
              orderId: item._id,
              productId: item.productInfo[0]._id,
              count: item.orderProduct.count,
              price: item.orderProduct.price,
              moq: item.orderProduct.moq,
              volume: item.orderProduct.volume,
            });
          }
        });
      });
      if (list.length === 0) {
        toastInfo(t("PLEASE_SELECT_PRODUCT"));
        return;
      }
      // history.push("/purchase/order/confirm/" + JSON.stringify(list));
      setOrderList(list);
      setOrderConfirmVisible(true);
    }
  };

  const onContactSupplierClick = () => {
    setImportTypeVisible(false);
    setImportByOwnVisible(false);
    history.push("/imchat-deprecated");
    // if (checkLimit(user.userInfo, RoleLimit.PRODUCT_ORDER, history)) {
    //   let list = [];
    //   groupCarts.forEach((g) => {
    //     g.products.forEach((item) => {
    //       if (item.selected) {
    //        console.log("item:",item);
    //       }
    //     });
    //   });
    // }
  };

  const toProductDetail = (productId) => {
    history.push("/products/detail/" + productId);
  };

  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  const onTipConfirm = (id, gindex, index) => {
    removePOI(id, gindex, index);
    toProductDetail(id);
  };

  const onChangeStockLocation = (status) => {
    setLocalStock(status);

    let temp = deepClone(carts);
    let tempGroupCarts = deepClone(groupCarts);
    if (status) {
      temp = deepClone(localStockCarts);
      tempGroupCarts = deepClone(localGroupCarts);
    } else {
      temp = deepClone(overseaStockCarts);
      tempGroupCarts = deepClone(overseaGroupCarts);
    }
    setCarts(temp);
    setGroupCarts(tempGroupCarts);
  };

  return (
    <div className="poi-list-page">
      <div className="poi-list">
        <div className="content-table">
          <div
            style={{ padding: "10px 20px 0px 20px" }}
            className="flex-display"
          >
            <div className="poi-type-select">
              <div
                onClick={onChangeStockLocation.bind(this, false)}
                className={
                  !localStock
                    ? "poi-type-item poi-type-item-selected left-border-radius"
                    : "poi-type-item left-border-radius"
                }
              >
                {t("STOCK_ABROAD")}
              </div>
              <div
                onClick={onChangeStockLocation.bind(this, true)}
                className={
                  localStock
                    ? "poi-type-item right-border-radius poi-type-item-selected"
                    : "poi-type-item right-border-radius"
                }
              >
                {t("LOCAL_STOCK")}
              </div>
            </div>
          </div>
          <div className="content-table-products">
            <InfiniteScroll
              className="scroll-content"
              dataLength={groupCarts.length}
              next={handleInfiniteOnLoad.bind(this)}
              hasMore={hasMore}
            >
              <ListBasic
                data={groupCarts}
                renderItem={(g, gindex) => (
                  <div className="company-item-panel" key={gindex}>
                    <div className="content-table-header">
                      <img
                        src={g.selected ? checkedIcon : uncheckedIcon}
                        className="check-box"
                        onClick={onCompanyChange.bind(this, gindex)}
                      />
                      <div
                        className="align-items-center cursor-pointer"
                        onClick={onGoToCompany.bind(this, g)}
                      >
                        <AvatarBasic
                          picture={localizeImg(g.avatar)}
                          cname="header-avatar"
                          displayName={g.companyName}
                          size={41}
                        />
                        <div className="header-name">{g.companyName}</div>
                        <img src={rightArrowIcon} />
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#DEDEDE",
                        height: "1px",
                      }}
                    ></div>
                    {g.products.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        key={index}
                      >
                        <div className="table-product-item">
                          <div className="table-product-item-left">
                            {!item.disabled && (
                              <img
                                src={
                                  item.selected ? checkedIcon : uncheckedIcon
                                }
                                className="check-box"
                                onClick={onPOIChange.bind(this, gindex, index)}
                              />
                            )}
                            {item.disabled && (
                              <Popconfirm
                                placement="rightTop"
                                title={t("PRODUCT_CHANGE_WHEN_CHECK")}
                                showCancel={false}
                                onConfirm={onTipConfirm.bind(
                                  this,
                                  item._id,
                                  gindex,
                                  index
                                )}
                              >
                                <img src={tipIcon} className="check-box" />
                              </Popconfirm>
                            )}
                            <div
                              className="header-name product-name"
                              onClick={toProductDetail.bind(
                                this,
                                item.productInfo[0]._id
                              )}
                            >
                              <div className="product-image-panel">
                                <img
                                  src={
                                    item.productInfo &&
                                    localizeImg(
                                      item.productInfo[0].bottleImages[0]
                                    )
                                  }
                                  className="product-image"
                                  alt={"Product Image"}
                                  onClick={toProductDetail.bind(
                                    this,
                                    item.productInfo[0]._id
                                  )}
                                />
                              </div>
                              <div className="product-info-panel">
                                <span className="ellipsis-text-line2">
                                  {item.productInfo &&
                                    item.productInfo[0].title}
                                </span>
                                <span className="product-prop">
                                  {item.orderProduct &&
                                    item.orderProduct.volume &&
                                    (item.orderProduct.volume.volume ===
                                    ProductSampleValue
                                      ? t("SAMPLES")
                                      : item.orderProduct.volume.volume + "ML")}
                                </span>
                                {/* <span>
                              {t("YEAR")}:
                              {item.productInfo && item.productInfo[0].year}
                            </span> */}
                              </div>
                            </div>
                            <div className="header-price-unit">
                              {item.currency === ProductCurrencyType.CNY &&
                                item.currency + " "}
                              {item.orderProduct.price || item.orderPrice.price}
                              {item.currency !== ProductCurrencyType.CNY &&
                                item.currency + " "}
                            </div>
                          </div>
                          {item.orderProduct.volume.volume ===
                          ProductSampleValue ? (
                            <div className="header-count">
                              {item.orderProduct.count}{" "}
                              {/* {t(item.orderProduct.volume.stock.unit)} */}
                            </div>
                          ) : (
                            <div className="header-count">
                              <InputCountBasic
                                count={item.orderProduct.count}
                                maxCount={item.orderProduct.volume.stock.value}
                                minCount={item.orderProduct.moq}
                                onChange={onCountChange.bind(
                                  this,
                                  gindex,
                                  index
                                )}
                              />
                              {/* <span className="margin-left-10">
                              {t(item.orderProduct.volume.stock.unit)}
                            </span> */}
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "40px",
                          }}
                        >
                          <div
                            className="header-price-unit"
                            style={{ fontSize: "16px" }}
                          >
                            {item.currency === ProductCurrencyType.CNY &&
                              item.currency + " "}
                            {item.orderPrice.payPrice.toFixed(2)}
                            {item.currency !== ProductCurrencyType.CNY &&
                              " " + item.currency}
                          </div>
                        </div>
                        {/* <div className="header-price-amount product-price">
                          {item.currency === ProductCurrencyType.CNY &&
                            item.currency + " "}
                          {item.orderPrice.totalPrice.toFixed(2)}
                          {item.currency !== ProductCurrencyType.CNY &&
                            " " + item.currency}
                        </div>
                        <div className="header-price product-price">
                          {item.currency === ProductCurrencyType.CNY &&
                            item.currency + " "}
                          {item.orderPrice.payPrice.toFixed(2)}
                          {item.currency !== ProductCurrencyType.CNY &&
                            " " + item.currency}
                        </div> */}
                        {/* <div
                          className="header-action"
                          onClick={removePOI.bind(
                            this,
                            item._id,
                            gindex,
                            index
                          )}
                        >
                          {t("DELETE")}
                        </div> */}
                      </div>
                    ))}
                  </div>
                )}
                loading={loading}
              />
            </InfiniteScroll>
          </div>

          <div className="poi-panel-footer ">
            <div className="totalprice-content">
              <div className="totalprice-right">
                <div className="totalprice-price">
                  <span className="totalprice-price-name">
                    {t("TOTAL_PRICE")}:
                  </span>
                  <span className="totalprice-price-value">
                    {ProductCurrencyType.CNY} {selectTotalPrice}
                  </span>
                </div>
                {localStock ? (
                  <div
                    className="totalprice-btn"
                    onClick={() => setImportTypeVisible(true)}
                    // onClick={toOrderConfirm.bind(this)}
                  >
                    {t("PAY")}
                  </div>
                ) : (
                  <div
                    className="totalprice-btn"
                    onClick={() => setImportTypeVisible(true)}
                    // onClick={toOrderConfirm.bind(this)}
                  >
                    {t("START_IMPORTING_PROCESS")}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={chooseServiceVisible}
        footer={null}
        title={null}
        width={442}
        height={138}
        closable={false}
        onCancel={() => setChooseServiceVisible(false)}
      >
        <div className="poi-panel-choose-importing-type-panel">
          <span className="poi-panel-choose-importing-type-title">
            {t("CHOOSE_IMPORTING_TYPE")}
          </span>
          <div className="importing-type-action-panel">
            <Button className="button-importing-type button-importing-by-myself">
              {t("IMPORTING_BY_MYSELF")}
            </Button>
            <Button className="button-importing-type button-importing-by-vinbip">
              {t("IMPORTING_BY_VINBIP")}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={orderConfirmVisible}
        footer={null}
        title={null}
        width={520}
        height={386}
        closable={false}
        onCancel={() => setOrderConfirmVisible(false)}
        className="poi-order-confirm-modal"
      >
        <OrderConfirmPanel orderData={orderList}></OrderConfirmPanel>
      </Modal>
      <Modal
        visible={importTypeVisible}
        footer={null}
        title={null}
        width={442}
        closable={false}
        onCancel={() => setImportTypeVisible(false)}
        className="poi-order-import-type-modal"
      >
        <div className="import-type-panel">
          <div className="title">
            {t("CHOOSE_HOW_YOU_WANT_TO_START_YOUR_IMPORTATION")}
          </div>
          <div className="action-panel">
            <div
              className="action-btn action-by-own"
              onClick={() => setImportByOwnVisible(true)}
            >
              {t("IMPORT_BY_MY_OWN")}
            </div>
            <div
              className="action-btn action-by-vinbip"
              onClick={() => setImportByVinbipVisible(true)}
            >
              {t("VINBIP_LOGISTIC")}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={importByOwnVisible}
        footer={null}
        title={null}
        width={490}
        closable={false}
        onCancel={() => setImportByOwnVisible(false)}
        className="poi-order-import-by-own-modal"
      >
        <div className="import-type-panel">
          <div className="title">{t("IMPORT_BY_MY_OWN")}</div>
          <div className="desc">{t("IMPORT_BY_MY_OWN_DESC")}</div>
          <div
            className="content-desc"
            dangerouslySetInnerHTML={{
              __html: t("IMPORT_BY_OWN_PREPARE_DOCUMENTS"),
            }}
          ></div>
          <div className="action-panel">
            <div
              className="action-btn-contact-supplier"
              onClick={onContactSupplierClick.bind(this)}
            >
              <img src={messageIcon} />
              <div className="label">{t("CONTACT_SUPPLIER")}</div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={importByVinbipVisible}
        footer={null}
        title={null}
        width={490}
        closable={false}
        onCancel={() => setImportByVinbipVisible(false)}
        className="poi-order-import-by-own-modal"
      >
        <div className="import-type-panel">
          <div className="title">{t("IMPORT_BY_VINBIP_LOGISTIC_SERVICE")}</div>
          <div className="desc">{t("IMPORT_BY_VINBIP_LOGISTIC_DESC")}</div>
          <div className="content-desc">
            {t("IMPORT_BY_VINBIP_LOGISTIC_DOCUMENTS")}
          </div>
          <div className="action-panel">
            <div
              className="action-btn-contact-supplier"
              onClick={toOrderConfirm.bind(this)}
            >
              <img src={messageIcon} />
              <div className="label">{t("CONFIRM")}</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
