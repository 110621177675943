import jwt_decode from 'jwt-decode';
import { tokenExpireHour } from '../constants/constants';

export function decodeToken(token: any) {
    return jwt_decode(token);
}

export function getTokenExpireTime() {
    const curDate = new Date();
    curDate.setHours(curDate.getHours() + tokenExpireHour);
    return curDate.getTime();
}
