import { UserNavigationHistoryEnum } from "../constants/UserNavigationHistory.enum";
import { createLogs } from "../api/actionLoggers";

export async function logUserAction(
  userId: string,
  uri: string,
  id: string | null
) {
  let cleanedUri = uri.toUpperCase().replaceAll("/", "_").replaceAll("-", "");
  // console.log(
  //   "--------logUserAction:",
  //   cleanedUri,
  //   uri,
  //   UserNavigationHistoryEnum.COMPANY_ADDRESS,
  //   cleanedUri.includes(UserNavigationHistoryEnum.COMPANY_ADDRESS)
  // );
  /**
   * We should avoid log the page related to payment
   */
  if (cleanedUri === "PURCHASE_ORDER_CONFIRM") {
    return;
  }
  if (cleanedUri === "ORDER_WXPAY") {
    return;
  }
  if (cleanedUri === "ORDER_SUCCESS") {
    return;
  }
  if (cleanedUri === "PURCHASE_ORDER_CONFIRM") {
    return;
  }
  if (cleanedUri === "SERVICE_PAYMENTSUCCESS") {
    return;
  }

  /**
   * We should avoid log the testing page
   */
  if (cleanedUri === "DISCOVER_TOOLS") {
    return;
  }
  if (cleanedUri === "TESTMARIN") {
    return;
  }
  if (cleanedUri === "TEST") {
    return;
  }
  if (cleanedUri === "COUNTER") {
    return;
  }

  /**
   * If the URI should contain a parameter we need to confirm the id exist.
   */
  // let type = "COMMON";
  let type = "";
  if (uri === "/") {
    type = UserNavigationHistoryEnum.FEED;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.FEED)) {
    type = UserNavigationHistoryEnum.FEED;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.WINERY)) {
    type = UserNavigationHistoryEnum.WINERY;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.IMPORTER)) {
    type = UserNavigationHistoryEnum.IMPORTER;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.PRODUCTS_LIST)) {
    type = UserNavigationHistoryEnum.PRODUCTS_LIST;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.PRODUCT)) {
    type = UserNavigationHistoryEnum.PRODUCT;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.SHOPPINGCART)) {
    type = UserNavigationHistoryEnum.SHOPPING_CART;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.DISCOVER)) {
    type = UserNavigationHistoryEnum.DISCOVER;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.TENDER_CREATE)) {
    type = UserNavigationHistoryEnum.TENDER_CREATE;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.TENDERS)) {
    type = UserNavigationHistoryEnum.TENDERS;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.TENDER)) {
    type = UserNavigationHistoryEnum.TENDER;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.SHOP_AWARD)) {
    type = UserNavigationHistoryEnum.SHOP_AWARD;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.SHOP_ORDERS)) {
    type = UserNavigationHistoryEnum.SHOP_ORDERS;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.SEARCH)) {
    type = UserNavigationHistoryEnum.SEARCH;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.CONTACTS)) {
    type = UserNavigationHistoryEnum.CONTACTS;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.COMPANY_SETTINGS)) {
    type = UserNavigationHistoryEnum.COMPANY_SETTINGS;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.COMPANY_ADDRESS)) {
    type = UserNavigationHistoryEnum.COMPANY_ADDRESS;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.COMPANY_VERIFY)) {
    type = UserNavigationHistoryEnum.COMPANY_CERTIFICATION;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.COMPANY_STAFF)) {
    type = UserNavigationHistoryEnum.STAFF_MANAGEMENT;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.NOTIFY)) {
    type = UserNavigationHistoryEnum.NOTIFY;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.USERCENTER_ME)) {
    type = UserNavigationHistoryEnum.PERSONAL_INFORMATION;
  } else if (
    cleanedUri.includes(UserNavigationHistoryEnum.USERCENTER_CHANGEPASSWORD)
  ) {
    type = UserNavigationHistoryEnum.CHANGE_PASSWORD;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.COMPANY_WALLET)) {
    type = UserNavigationHistoryEnum.COMPANY_WALLET;
  } else if (cleanedUri.includes(UserNavigationHistoryEnum.DASHBOARD)) {
    type = UserNavigationHistoryEnum.DASHBOARD;
  }
  if (type) {
    if (id) {
      uri = uri.replace(":id", id);
    }
    const pms = {
      userId: userId,
      uri: uri,
      targetId: id,
      type,
    };
    createLogs(pms);
  }
}

export async function logUserAction1(
  userId: string,
  uri: string,
  id: string | null
) {
  let cleanedUri = sanitizeUri(uri);

  /**
   * We should avoid log the page related to payment
   */
  if (cleanedUri === "PURCHASE_ORDER_CONFIRM") {
    return;
  }
  if (cleanedUri === "ORDER_WXPAY") {
    return;
  }
  if (cleanedUri === "ORDER_SUCCESS") {
    return;
  }
  if (cleanedUri === "PURCHASE_ORDER_CONFIRM") {
    return;
  }
  if (cleanedUri === "SERVICE_PAYMENTSUCCESS") {
    return;
  }

  /**
   * We should avoid log the testing page
   */
  if (cleanedUri === "DISCOVER_TOOLS") {
    return;
  }
  if (cleanedUri === "TESTMARIN") {
    return;
  }
  if (cleanedUri === "TEST") {
    return;
  }
  if (cleanedUri === "COUNTER") {
    return;
  }

  /**
   * If the URI should contain a parameter we need to confirm the id exist.
   */
  if (cleanedUri.includes("   ")) {
    if (id) {
      const pms = {
        userId: userId,
        uri: cleanedUri,
        targetId: id,
        type: "WINERY",
      };
      createLogs(pms);
      return;
    } else {
      return;
    }
  } else if (cleanedUri.includes("IMPORT")) {
    if (id) {
      const pms = {
        userId: userId,
        uri: cleanedUri,
        targetId: id,
        type: "IMPORT",
      };
      createLogs(pms);
    } else {
      return;
    }
  } else if (cleanedUri.includes("PRODUCT_DETAIL")) {
    if (id) {
      const pms = {
        userId: userId,
        uri: cleanedUri,
        targetId: id,
        type: "PRODUCT_DETAIL",
      };
      createLogs(pms);
    } else {
      return;
    }
  } else if (cleanedUri.includes("SHOPPINGCART")) {
    const pms = {
      userId: userId,
      uri: cleanedUri,
      targetId: null,
      type: "SHOPPINGCART",
    };
    createLogs(pms);
    return;
  }

  // @ts-ignore
  if (Object.values(UserNavigationHistoryEnum).includes(cleanedUri)) {
    const pms = {
      userId: userId,
      uri: cleanedUri,
      targetId: null,
      type: "COMMON",
    };
    createLogs(pms);
  } else {
    console.log("Unknown uri : ", cleanedUri);
    return;
  }
}

export function sanitizeUri(uri: string) {
  if (uri === undefined || uri === null) {
    return "NOT_FOUND";
  }
  /**
   * We first check if we go on the feed page
   */
  if (uri === "/") {
    return UserNavigationHistoryEnum.FEED;
  }

  /**
   * as we know it's not the feed page , we can safely remove the first '/'
   */
  uri = uri.substring(1);

  /**
   * we replace all the '/' with '_' in order to be able to use it as a key in the enum
   * we also remove the '-' in order to be able to use it as a key in the enum
   * we also remove the id part of the uri
   */
  uri = uri.replaceAll("/", "_");
  uri = uri.replaceAll("-", "");
  uri = uri.replaceAll("_:id", "");
  uri = uri.replaceAll("_:params", "");

  return uri.toUpperCase();
}

export function logToUri(uri: string, type: string, id: string) {
  if (type === "COMMON") {
    if (uri === "SERVICE_BRANDSREGISTRATION") {
      return "/service/brands-registration";
    }
    if (uri === "SERVICE_LABELDESIGN") {
      return "/service/label-design";
    }
    if (uri === "SERVICE_PACKAGEDESIGN") {
      return "/service/package-design";
    }
    if (uri === "USERCENTER_CHANGEPASSWORD") {
      return "/change-password";
    }
    if (uri === "USERCENTER_MYSUBSCRIPTION") {
      return "usercenter/my-subscription";
    }
    if (uri === "USERCENTER_CLOSEACCOUNT") {
      return "usercenter/my-subscription";
    }
    if (uri === "MYPOINTS") {
      return "my-points";
    }
    if (uri === "MYNEWS") {
      return "my-news";
    }
    if (uri === "SUPPLIERLIST") {
      return "supplier-list";
    }
    if (uri === "CLIENTLIST") {
      return "client-list";
    }
    if (uri === "ALLIANCELIST") {
      return "alliance-list";
    }
    if (uri === "DISTRUBUTORLIST") {
      return "distributor-list";
    }

    uri.replaceAll("_", "/");
    uri = "/" + uri;

    return uri.toLowerCase();
  }

  if (type === "WINERY") {
    return "/profile-deprecated/" + id;
  }
  if (type === "IMPORT") {
    return "/profile-deprecated/" + id;
  }
  if (type === "SHOPPINGCART") {
    return "/shopping-cart";
  }
  if (type === "PRODUCT_DETAIL") {
    return "/products/detail/" + id;
  }
}
