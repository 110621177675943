import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { Affix, BackTop, Layout } from "antd";
import "./style/headPrivateRoute.less";
import { authValidate } from "../../api/common";
import MyNavbar from "../navbar/MyNavbar";
import { logUserAction } from "../../action-loggers/action-loggers";
import { useUserId } from "../../contexts/UserIdProvider";
import LeftSiderSmall from "../shared/left-sider-small/LeftSiderSmall";

export default function HeaderPrivateRoute({ component: Component, ...rest }) {
  let validate = authValidate();
  const { Header, Content } = Layout;
  const { user } = useUserId();
  const { userId } = user;
  const headerLayout = rest.default === true ? true : false;

  useEffect(() => {
    logUserAction(userId, rest.path, rest.computedMatch.params.id);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (validate) {
          return (
            <div className="header-route">
              <Header className="header-nav">
                <MyNavbar />
              </Header>
              <Layout
                className={"layout-default"}
                // className={headerLayout ? "layout-default" : "layout-margintop"}
              >
                <Affix offsetTop={56}>
                  <LeftSiderSmall />
                </Affix>
                <Content className="layout-content">
                  <BackTop />
                  <Component {...props} />
                </Content>
              </Layout>
            </div>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { refer: props.location, clearToken: true },
              }}
            />
          );
        }
      }}
    />
  );
}
