import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Input, Row, Select } from "antd";
import "./style/passwordSetting.less";
import { useTranslation } from "react-i18next";
import { passwordSettingFields } from "../../../constants/usersType.enum";
import FormNormalW from "../../common/form/FormNormalW";
import { passwordSetting } from "../../../api/login";
import { useUserId } from "../../../contexts/UserIdProvider";
import { statusCode } from "../../../constants/statusCode.enum";
import { toastError, toastInfo } from "../../../api/toast";

export default function PasswordSetting() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const [fields, setFields] = useState(passwordSettingFields);
  const [formData, setFormData] = useState();

  const onCompanyUsersFieldsChange = (vals) => {
    console.log("onCompanyUsersFieldsChange:", vals);

    setFields(vals);
    setComInfoFormData(vals);
  };

  const setComInfoFormData = (vals) => {
    let obj = {};
    vals.map((item) => {
      obj[item.name] = item.value;
    });
    setFormData(obj);
  };

  const onSave = async () => {
    if (!formData || !formData.newPassword) {
      toastError(t("NEW_PASSWORD_IS_EMPTY"));
      return;
    }

    if (formData.newPassword != formData.confirmPassword) {
      toastError(t("PASSWORD_DID_NOT_MATCH"));
      return;
    }

    console.log(formData);
    let pms = {
      ...formData,
      userId: user.userId,
      password: formData.newPassword,
    };
    let result = await passwordSetting(pms);
    console.log("password setting result:", result);
    if (result.statusCode == statusCode.SUCCESS) {
      toastInfo(t("SAVE_SUCCESS"));
    } else {
      toastError(t("SAVE_FAILED"));
    }
  };

  return (
    <div className="company-management-page">
      <div className="common-management">
        <div className="password-setting-panel">
          <div className="password-setting-container">
            <FormNormalW
              items={fields}
              onChange={onCompanyUsersFieldsChange.bind(this)}
            />
          </div>
        </div>
        <div className="bottom-bar">
          <Button
            className="btn-save"
            onClick={onSave.bind(this)}
            style={{
              width: "441px",
            }}
          >
            {t("SAVE")}
          </Button>
        </div>
      </div>
    </div>
  );
}
