import React, { Suspense, useEffect } from "react";
import { AuthProvider } from "./contexts/AuthProvider";
import { IMProvider } from "./contexts/IMProvider";
import { UserIdProvider } from "./contexts/UserIdProvider";
import { StateProvider } from "./contexts/StateProvider";
import { DataProvider } from "./contexts/DataProvider";
import store from "./redux/store";
import { Provider } from "react-redux";
import VinexworkRouter from "./components/VinexworkRouter";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import "moment/locale/zh-cn";
import "antd/dist/antd.less";
//import "./App.less";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import "./api/api";
import "./core-digital-template/styles/app.sass";

moment.locale("zh-cn");

function App() {
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "f68d0ce3-fd53-4f29-8875-e4361249b7a0";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  });
  return (
    <Suspense fallback="loading">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Provider store={store}>
        <AuthProvider>
          <UserIdProvider>
            <StateProvider>
              <DataProvider>
                <IMProvider>
                  <VinexworkRouter />
                </IMProvider>
              </DataProvider>
            </StateProvider>
          </UserIdProvider>
        </AuthProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
