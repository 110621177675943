import React, { useEffect, useState } from "react";
import styles from "./StockModal.module.sass";

import { useTranslation } from "react-i18next";
import {
  loadProductConfig,
} from "../../../api/product";
import { constants, deepClone } from "../../../utils/utils";
import { Modal } from "antd";
import { getCountryAndCityForSelect } from "../../../api/config";

const StockModal = ({ isShow, onClose,onChange }) => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [dataList, setDataList] = useState([
    {
      label: t("CHINA"),
      selected: true,
    },
    {
      label: t("OVERSEAS"),
      selected: false,
    },
    {
      label: t("ALL"),
      selected: false,
    },
  ]);
  const [subDataList, setSubDataList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    setConfigData();
  }, []);

  useEffect(() => {
    if (countryList && countryList.length > 0) {
      setSubDataList(
        countryList.filter(
          (filterItem) => filterItem.value == constants.chinaCountryId
        )[0].children
      );
    }
  }, [countryList]);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setCountryList(getCountryAndCityForSelect(locations, currentLanguage));
  };

  const onStockNavigatorClick = (index) => {
    let temp = deepClone(dataList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setDataList(temp);

    let tempSubStockList = deepClone(subDataList);
    tempSubStockList = [];
    if (index == 0 || index == 1) {
      let filterResult = countryList.filter((filterItem) =>
        index == 0
          ? filterItem.value == constants.chinaCountryId
          : filterItem.value != constants.chinaCountryId
      );
      if (filterResult.length > 0) {
        filterResult.map((mapItem) => {
          tempSubStockList.push(...mapItem.children);
        });
      }
    } else {
      countryList.map((countryItem) => {
        tempSubStockList.push(...countryItem.children);
      });
    }
    setSubDataList(tempSubStockList);
  };

  const onNewStockChange = (item, index) => {
    let temp = deepClone(subDataList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setSubDataList(temp);

    onChange && onChange(temp[index]);
    onClose && onClose();
  };

  return (
    <Modal visible={isShow} footer={null} onCancel={onClose}>
      <div className={styles.distributionChannelSelectModal}>
        <div>
          <div className={styles.navigatorPanel}>
            {dataList.map((mapItem, mapIndex) => {
              return (
                <div
                  onClick={onStockNavigatorClick.bind(this, mapIndex)}
                  className={
                    mapItem.selected
                      ? styles.navigatorItemSelected
                      : styles.navigatorItem
                  }
                >
                  {mapItem.label}
                </div>
              );
            })}
          </div>
          <div
            className={styles.distributionChannelPanel}
            style={{ marginTop: "20px" }}
          >
            {subDataList.map((mapItem, mapIndex) => {
              return (
                <div
                  className={
                    !mapItem.selected
                      ? styles.channelItem
                      : styles.channelItemSelected
                  }
                  onClick={onNewStockChange.bind(this, mapItem, mapIndex)}
                >
                  {mapItem.label}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StockModal;
