import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./WebsiteCompInfo.module.sass";
import Profile from "./Profile";
import Settings from "./Settings";
import Card from "../../core-digital-template/components/Card";
import Dropdown from "../../core-digital-template/components/Dropdown";
import Filters from "../../core-digital-template/components/Filters";
import Product from "../../core-digital-template/components/Product";
import Menu from "../../core-digital-template/components/Menu";
import Follower from "./Follower";

// data
import { followers } from "../../core-digital-template/mocks/followers";
import { useHistory, useLocation, useParams } from "react-router";
import { useUserId } from "../../contexts/UserIdProvider";
import {
  AmityApiKey,
  ProductTableMaxCount,
  localizeImg,
} from "../../constants/constants";
import { useTranslation } from "react-i18next";
import { getCompanyMainUser, userDetail } from "../../api/user";
import { companyDetail, saveCompanyInfo } from "../../api/company";
import { CompanyVerifyStatusEnum } from "../../constants/companyVerifyStatus.enum";
import { UserType } from "../../constants/usersType.enum";
import { ProductStatus, statusCode } from "../../constants/statusCode.enum";
import { getFileTypeByPath } from "../../api/common";
import {
  createProductClick,
  loadOpenProducts,
  loadProducts,
} from "../../api/product";
import CompanyAboutUs from "../../core-digital-template/components/CompanyAboutUs";
import UserFeedPage from "../../components/amity-uikit/social/pages/UserFeed";
import UserGalleryPage from "../../components/amity-uikit/social/pages/UserGallery";
import MainLayout from "../../components/amity-uikit/social/layouts/Main";
import {
  AmityUiKitProvider,
  AmityUiKitSocial,
} from "../../components/amity-uikit";
import Slider from "react-slick";
import Icon from "../../core-digital-template/components/Icon";
// import { Button, Skeleton, Tour } from "antd";
import ModalEventCreateor from "../../core-digital-template/components/ModalEventCreateor";
import { getCompanyEvents } from "../../api/events";
import EventItem from "./EventItem";
import CompanyMessaging from "../../core-digital-template/components/CompanyMessaging";
import { openCompanyInfoByName } from "../../api/open";
import { Path } from "../../constants/path";
import cameraIcon from "../../resource/icons/user-profile/camera.svg";
import "./WebsiteCompInfo.less";
import UploadImageNormal from "../../components/common/upload/UploadImageNormal";
import { deepClone } from "../../utils/utils";
import CompanyMap from "../../components/company/company-map/CompanyMap";
import LocationEditor from "../../core-digital-template/components/CompanyAboutUs/LocationEditor";

import Joyride, { STATUS } from "react-joyride";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={styles.sliderArrowRight} onClick={onClick}>
      <Icon name="arrow-right" size="36" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={styles.sliderArrowLeft} onClick={onClick}>
      <Icon name="arrow-left" size="36" />
    </div>
  );
}

export default function WebsiteCompInfo() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const { state } = useLocation();
  const { coname } = useParams();

  const history = useHistory();

  const [userLogined, setUserLogined] = useState(user && user.userId);

  const [navigation, setNavigation] = useState([
    { label: t("ABOUT"), value: "ABOUT" },
    { label: t("POSTS"), value: "POSTS" },
    { label: t("PRODUCTS"), value: "PRODUCTS" },
    { label: t("IMAGES"), value: "IMAGES" },
    { label: t("VIDEOS"), value: "VIDEOS" },
    //TODO:Comment for V1.0
    { label: t("EVENTS"), value: "EVENTS" },
    // t("CONTACT"),
  ]);
  const intervals = ["Most recent", "Most new", "Most popular"];

  const [companyInfo, setCompanyInfo] = useState({});

  // const [companyVerified, setCompanyVerified] = useState(false);
  const [isMyself, setIsMyself] = useState(false);
  const [targetUserId, setTargetUserId] = useState("");

  const [activeIndex, setActiveIndex] = useState("ABOUT");
  const [productList, setProductList] = useState([]);
  const [events, setEvents] = useState([]);

  const [modalEventCreatorVisible, setModalEventCreatorVisible] = useState(false);

  const [showLocationEditor, setShowLocationEditor] = useState(false);

  const [onLoading, setOnLoading] = useState(true);

  const [tourOpen, setTourOpen] = useState(false);

  const stepsLocale = {
    skip: <strong aria-label="skip">{t("SKIP")}</strong>,
    next: <strong aria-label="skip">{t("NEXT")}</strong>,
    back: <strong aria-label="skip">{t("BACK")}</strong>,
  };
  const steps = [
    {
      content: <h2>{t("TOUR_EDIT_PROFILE_WELCOME")}</h2>,
      locale: stepsLocale,
      placement: "center",
      target: "body",
      title: t("TOUR_EDIT_PROFILE_TITLE"),
    },
    {
      target: ".profile-avatar-tour",
      content: t("TOUR_EDIT_PROFILE_AVATAR"),
      locale: stepsLocale,
      spotlightClicks: true,
    },
    {
      target: ".banner-btn-container",
      content: t("TOUR_EDIT_PROFILE_BANNER"),
      spotlightClicks: true,
    },
    {
      target: ".profile-company-name-tour",
      content: t("TOUR_EDIT_PROFILE_COMPANY_NAME"),
      locale: stepsLocale,
      spotlightClicks: true,
    },
    {
      target: ".profile-address-tour",
      content: t("TOUR_EDIT_PROFILE_COMPANY_ADDRESS"),
      locale: stepsLocale,
      spotlightClicks: true,
    },
    {
      target: ".profile-abous-us-tour",
      content: t("TOUR_EDIT_PROFILE_COMPANY_ABOUT_US"),
      locale: stepsLocale,
      spotlightClicks: true,
    },
    {
      target: ".profile-detail-tour",
      content: t("TOUR_EDIT_PROFILE_COMPANY_DETAIL"),
      locale: stepsLocale,
      spotlightClicks: true,
    },
  ];

  useEffect(() => {
    console.log("WebsiteCompInfo coname:", coname);
    if (coname) {
      loadCompanyDesc(coname);
    }
  }, [coname]);

  useEffect(() => {
    if (companyInfo?._id && (!productList || productList.length == 0)) {
      getProducts(companyInfo?._id);
    }
    if (activeIndex == "EVENTS" && (!events || events.length == 0)) {
      getEvents(companyInfo?._id);
    }
  }, [activeIndex]);

  useEffect(() => {
    let flag = user?.userInfo?.companyId == companyInfo?._id;
    setIsMyself(flag);
  }, [companyInfo]);

  const loadCompanyDesc = async (coName) => {
    let pms = { coName };
    const tempCompanyInfo = await openCompanyInfoByName(pms);
    console.log("loadCompanyDesc tempCompanyInfo:", tempCompanyInfo);
    if (
      tempCompanyInfo &&
      tempCompanyInfo.medias &&
      tempCompanyInfo.medias.length == 1
    ) {
      tempCompanyInfo.medias.push(tempCompanyInfo.medias[0]);
    }
    if (tempCompanyInfo && tempCompanyInfo.userInfo) {
      setTargetUserId(tempCompanyInfo.userInfo._id);

      if (tempCompanyInfo?.userInfo?.type == UserType.SUPPLIER) {
        getProducts(tempCompanyInfo._id);
      }
    }
    setCompanyInfo(tempCompanyInfo);

    let flag = user?.userInfo?.companyId == tempCompanyInfo?._id;
    if (!tempCompanyInfo?.toured&&flag) {
      setTourOpen(true);
    }

    if (tempCompanyInfo.type == UserType.SUPPLIER) {
      setNavigation([
        { label: t("ABOUT"), value: "ABOUT" },
        { label: t("POSTS"), value: "POSTS" },
        { label: t("PRODUCTS"), value: "PRODUCTS" },
        { label: t("IMAGES"), value: "IMAGES" },
        { label: t("VIDEOS"), value: "VIDEOS" },
        { label: t("EVENTS"), value: "EVENTS" },
      ]);
    } else {
      setNavigation([
        { label: t("ABOUT"), value: "ABOUT" },
        { label: t("POSTS"), value: "POSTS" },
        { label: t("IMAGES"), value: "IMAGES" },
        { label: t("VIDEOS"), value: "VIDEOS" },
      ]);
    }

    setTimeout(() => {
      setOnLoading(false);
    }, 1000);
  };

  const getProducts = async (coId) => {
    console.log("loadCompanyDesc coId:", coId);
    let pms = {
      page: 0,
      per_page: ProductTableMaxCount,
      coId,
      status: ProductStatus.STOCK,
    };
    let result = await loadOpenProducts(pms);
    if (result && result.length > 0) {
      let list = [];
      for (let i = 0; i < result.length; i++) {
        list.push(result[i]);
      }
      setProductList(list);
    } else {
      setProductList([]);
    }
  };

  const getEvents = async (coId) => {
    let pms = {
      coId,
    };
    let result = await getCompanyEvents(pms);
    if (result && result.total > 0) {
      setEvents(result.result);
    } else {
      setEvents([]);
    }
  };

  const onBannerLoaded = (data) => {
    if (data.length > 0) {
      let temp = deepClone(companyInfo);
      if (!temp.medias) {
        temp.medias = [];
      }
      temp.medias[0] = data[0];
      // setCompanyDesc(temp);
      onChangeBanner(temp);
    }
  };

  const onBannerLoading = (e) => {
    let temp = deepClone(companyInfo);
    if (!temp.medias) {
      temp.medias = [];
    }
    temp.medias[0] = e;
    setCompanyInfo(temp);
  };

  const onChangeBanner = async (pms) => {
    let result = await saveCompanyInfo(pms);
    // if (result.statusCode === 200) {
    //   toastInfo(t("SAVE_SUCCESS"));
    // } else {
    //   toastInfo(t("SAVE_FAILED"));
    // }
  };

  const onCompanyInfoSaved = (companyId) => {
    loadCompanyDesc(companyId || coname);
  };

  const settings = {
    infinite: true,
    // className: "center",
    //autoplaySpeed: 5000,
    speed: 1000,
    //lazyLoad: true,
    //autoplay: true,
    //slidesToShow: 1,
    // centerMode: true,
    variableWidth: true,
    // adaptiveHeight: true,
    // swipeToSlide: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const amityPhotosTheme = {
    palette: {
      system: {
        background: "transparent",
        borders: "transparent",
      },
    },
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      console.log("finishedStatuses:", status);
      let temp=deepClone(companyInfo);
      temp.toured=true;
      saveCompanyInfo(temp)
    }
  };

  return (
    <>
      <div className={styles.CompInfo}>
        <div className={styles.background}>
          {!onLoading && companyInfo && companyInfo.medias ? (
            <img
              src={localizeImg(companyInfo?.medias[0])}
              alt="Background"
              className={styles.banner}
            />
          ) : (
            <div className={styles.emptyBackground}></div>
          )}
          {isMyself && (
            <UploadImageNormal
              onFileLoaded={onBannerLoaded.bind(this)}
              onLoading={onBannerLoading.bind(this)}
              scaleAspect={2}
              cropShape="rect"
            >
              <div className="banner-btn-container">
                <div className="banner-btn">
                  <img
                    alt={"Camera icon"}
                    src={cameraIcon}
                    className="btn-icon"
                  />
                </div>
              </div>
            </UploadImageNormal>
          )}
        </div>
        <Profile
          companyInfo={companyInfo}
          targetUserId={targetUserId}
          onSaved={onCompanyInfoSaved.bind(this)}
        />
        {!userLogined && productList.length > 0 && (
          <>
            <div
              style={{
                marginBottom: "24px",
                marginTop: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className={cn(styles.head)}>
                <div className={cn("title-purple", styles.title)}>
                  {t("PRODUCTS")}
                </div>
              </div>
              <button
                className={cn("button", styles.subButton)}
                onClick={() => history.push(Path.signIn)}
              >
                {t("SEE_ALL")}
              </button>
            </div>
            <div className={styles.products}>
              {productList.map((x, index) => (
                <Product
                  className={styles.product}
                  item={x}
                  key={index}
                  withoutСheckbox
                  isMyself={isMyself}
                />
              ))}
            </div>
          </>
        )}
        {userLogined && (
          <div className={styles.navigationBar}>
            {navigation.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x.value == activeIndex,
                })}
                onClick={() => setActiveIndex(x.value)}
                key={index}
              >
                {x.label}
              </button>
            ))}
          </div>
        )}
        {activeIndex == "ABOUT" && targetUserId && (
          <div className={styles.row}>
            <div className={styles.col1}>
              <CompanyAboutUs
                companyInfo={companyInfo}
                isMyself={isMyself}
                onSaved={onCompanyInfoSaved.bind(this)}
              ></CompanyAboutUs>
              <Card
                className={styles.photosCard}
                title={t("Photos")}
                classTitle="title-red"
              >
                <AmityUiKitProvider
                  key={targetUserId}
                  apiKey={AmityApiKey}
                  userId={targetUserId}
                  displayName={targetUserId}
                  theme={amityPhotosTheme}
                  // authToken={"1f97f4c66e8b7512d3fa2603349c94299f66c7a6fce2d732527cf113a7853543f79541804bf9c1795405df7be265ebe5578cdcf36d3bfc697152807422"}
                >
                  <UserGalleryPage userId={targetUserId} type="image" />
                </AmityUiKitProvider>
              </Card>
            </div>
            <div className={styles.col2}>
              <Card
                className={styles.mapCard}
                title={t("LOCATION")}
                classTitle="title-red"
                head={
                  isMyself ? (
                    <div
                      className={styles.editButton}
                      onClick={() => setShowLocationEditor(true)}
                    >
                      <Icon name="edit" size="24" />
                    </div>
                  ) : (
                    <></>
                  )
                }
              >
                {/* <iframe width="300" height="300" src="https://api.maptiler.com/maps/basic-v2/?key=H8IqOWdIa87FMebX5omk#1.0/0.00000/0.00000"></iframe> */}
                
                {!onLoading && companyInfo?.location?.address && (
                  <div className="mb-5">{companyInfo.location.address.name}</div>
                )}
                <CompanyMap companyDesc={companyInfo} />
              </Card>
              <Card
                className={styles.productsCard}
                title={t("PRODUCTS")}
                classTitle="title-red"
              />
              <div className={styles.products}>
                {productList.map((x, index) => (
                  <Product
                    className={styles.product}
                    item={x}
                    key={index}
                    withoutСheckbox
                    isMyself={isMyself}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        {activeIndex == "POSTS" && targetUserId && (
          <>
            <AmityUiKitProvider
              key={targetUserId}
              apiKey={AmityApiKey}
              userId={targetUserId}
              displayName={targetUserId}
              // authToken={"1f97f4c66e8b7512d3fa2603349c94299f66c7a6fce2d732527cf113a7853543f79541804bf9c1795405df7be265ebe5578cdcf36d3bfc697152807422"}
            >
              <UserFeedPage userId={targetUserId} />
            </AmityUiKitProvider>
          </>
        )}
        {activeIndex == "PRODUCTS" && (
          <div className={styles.productsLarge}>
            {productList.map((x, index) => (
              <Product
                className={styles.product}
                item={x}
                key={index}
                withoutСheckbox
                isMyself={isMyself}
              />
            ))}
          </div>
        )}
        {activeIndex == "IMAGES" && (
          <AmityUiKitProvider
            key={targetUserId}
            apiKey={AmityApiKey}
            userId={targetUserId}
            displayName={targetUserId}
            theme={amityPhotosTheme}
            // authToken={"1f97f4c66e8b7512d3fa2603349c94299f66c7a6fce2d732527cf113a7853543f79541804bf9c1795405df7be265ebe5578cdcf36d3bfc697152807422"}
          >
            <UserGalleryPage userId={targetUserId} type="image" />
          </AmityUiKitProvider>
        )}
        {activeIndex == "VIDEOS" && (
          <>
            <AmityUiKitProvider
              key={targetUserId}
              apiKey={AmityApiKey}
              userId={targetUserId}
              displayName={targetUserId}
            >
              <UserGalleryPage userId={targetUserId} type="video" />
            </AmityUiKitProvider>
          </>
        )}
        {activeIndex == "EVENTS" && (
          <>
            {isMyself && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  className={cn(
                    "button-stroke button-small",
                    styles.deletebutton
                  )}
                  onClick={() => setModalEventCreatorVisible(true)}
                >
                  <Icon name="add" size="24" />
                  <span>{t("CREATE_EVENT")}</span>
                </div>
              </div>
            )}
            {events.map((x, index) => (
              <EventItem item={x} key={index} />
            ))}
          </>
        )}
        {activeIndex === 6 && (
          <>
            <CompanyMessaging
              coId={companyInfo?._id}
              companyInfo={companyInfo}
            />
          </>
        )}
        {/* <div className={styles.navigationBarMobile}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x.value === activeIndex,
              })}
              onClick={() => setActiveIndex(x.value)}
              key={index}
            >
              {x.label}
            </button>
          ))}
        </div> */}
        <ModalEventCreateor
          visible={modalEventCreatorVisible}
          onCloseModal={() => setModalEventCreatorVisible(false)}
        />
        <LocationEditor
        visible={showLocationEditor}
        dataSource={companyInfo}
        onCloseModal={() => setShowLocationEditor(false)}
        onSaved={onCompanyInfoSaved.bind(this)}
        />
        <Joyride
          run={tourOpen}
          continuous
          hideCloseButton
          scrollToFirstStep
          showProgress
          showSkipButton
          disableScrolling
          steps={steps}
          callback={handleJoyrideCallback}
        />
      </div>
    </>
  );
}
