import React, { useState, useEffect } from "react";
import { SysBaseUrl, WxAppId } from "../../constants/constants";
import { getTimeStamp } from "../../utils/date";

export default function WxLogin() {
  const redirectUrl = SysBaseUrl + 'login';
  const [wxLoginLoadTime, setWxLoginLoadTime] = useState(0);

  useEffect(() => {
    initWxLoginScript();
  }, []);

  useEffect(() => {
    let result = createWxLogin();
    if (wxLoginLoadTime < 500 && !result) {
      setWxLoginLoadTime(wxLoginLoadTime + 1);
    }
  }, [wxLoginLoadTime]);

  function initWxLoginScript() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
    document.head.appendChild(script);
  }

  function createWxLogin() {
    let flag = false;
    try {
      new window.WxLogin({
        self_redirect: false,
        id: "login",
        appid: WxAppId,
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(redirectUrl),
        state: getTimeStamp(),
        // style: "",
        // href: ""
      });
      flag = true;
    } catch (error) {
      console.log('------create wxlogin error:', wxLoginLoadTime, error);
    }
    return flag;
  }

  return (
    <div id="login" style={{ textAlign: 'center' }}></div>
  );
}
