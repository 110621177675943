import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../contexts/UserIdProvider";

import { Stream } from "@cloudflare/stream-react";

// import {
//   ApiVersion,
//   DataType,
//   LATEST_API_VERSION,
//   LogSeverity,
//   shopifyApi,
//   Shopify,
//   ShopifyAPI,
// } from "@shopify/shopify-api";
// import "@shopify/shopify-api/adapters/node";
import { openShopifyStorefrontAccesstoken } from "../../api/open";
// import { restResources } from "@shopify/shopify-api/rest/admin/2023-01";
import { statusCode } from "../../constants/statusCode.enum";
import TestCloudflareUploadImage from "../common/upload/TestCloudflareUploadImage";
// import Client from "shopify-buy"; //shopify-buy:https://github.com/Shopify/js-buy-sdk#fetching-products
// import Client from "graphql-js-client";
// import {gql} from 'babel-plugin-graphql-js-client-transform';
// import typeBundle from './types';

import "vidstack/styles/base.css";
// the following styles are optional - remove to go headless.
import "vidstack/styles/ui/buttons.css";
import "vidstack/styles/ui/sliders.css";

import { MediaPlayer, MediaOutlet } from "@vidstack/react";

export default function TestCloudflare() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const [products, setProducts] = useState([]);

  let [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {}, []);

  const onAvatarLoaded = (data) => {
    if (data.length > 0) {
      let url =
        "https://customer-ywjyoxy2m0rkmiou.cloudflarestream.com/" +
        data[0] +
        "/manifest/video.mpd";
      console.log("Uploaded:", url);
      setVideoUrl(url);
      //   let temp = deepClone(companyDesc);
      //   // temp.avatarCn = data[0].cn;
      //   // temp.avatarGlobal = data[0].global;
      //   temp.avatar = data[0];
      //   setCompanyDesc(temp);
      //   onChangeAvatar(temp);
    }
  };

  const onAvatarUploadLoading = (e) => {
    // let temp = deepClone(companyDesc);
    // temp.avatar = e;
    // setCompanyDesc(temp);
  };

  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
      }}
    >
      <div>
        <div>
          <TestCloudflareUploadImage
            onFileLoaded={onAvatarLoaded.bind(this)}
            onLoading={onAvatarUploadLoading.bind(this)}
            scaleAspect={1}
          >
            <div
              style={{
                border: "1px solid #333333",
                padding: "10px 20px",
                marginBottom: "10px",
              }}
            >
              Click to upload Video
            </div>
          </TestCloudflareUploadImage>
        </div>
        <MediaPlayer
          src={videoUrl}
          poster="https://customer-m033z5x00ks6nunl.cloudflarestream.com/b236bde30eb07b9d01318940e5fc3eda/thumbnails/thumbnail.jpg"
          controls
          aspectRatio={16 / 9}
          style={{
            width: "400px",
          }}
        >
          <MediaOutlet />
        </MediaPlayer>
      </div>
    </div>
  );
}
