import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ForgotPassword.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useHistory, useLocation } from "react-router-dom";
import TextInput from "../../core-digital-template/components/TextInput";
import Image from "../../core-digital-template/components/Image";
import VinbipLogo from "../../core-digital-template/components/VinbipLogo";
import { Checkbox } from "antd";
import { toastError, toastInfo } from "../../api/toast";
import { isEmail } from "../../utils/regex";
import { login, sendResetPasswordEmail } from "../../api/login";
import { setBearerToken } from "../../api/api";
import { getTokenExpireTime } from "../../utils/jwt";
import { useAuth } from "../../contexts/AuthProvider";
import { useUserId } from "../../contexts/UserIdProvider";
import { BrowseType } from "../../constants/browseType.enum";
import { browseRecordCreate } from "../../api/browseRecord";
import { useTranslation } from "react-i18next";
import { statusCode } from "../../constants/statusCode.enum";

export default function ForgotPassword() {
  const { createToken } = useAuth();
  const { loadUserInfo } = useUserId();
  const heightWindow = use100vh();
  const history = useHistory();
  const { t } = useTranslation();
  const { state } = useLocation();

  const [referUrl, setReferUrl] = useState("/");

  const [account, setAccount] = useState();
  const [password, setPassword] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);

  useEffect(() => {
    if (state) {
      let { refer, clearToken } = state;
      if (clearToken) {
        localStorage.clear();
      }
      if (refer) {
        setReferUrl(refer);
      }
    }
  }, []);

  const onEmailChange = (e) => {
    let temp = e;
    console.log(temp);
    setAccount(temp);
  };

  const onFinish = async () => {
    if (!account) {
      toastError(t("ACCOUNT_IS_EMPTY"));
      return;
    }

    if (!isEmail(account)) {
      toastError(t("INVALID_EMAIL"));
      return;
    }

    let pms = { email: account.trim() };

    let result = await sendResetPasswordEmail(pms);
    console.log("sendResetPasswordEmail:", result);
    if (result && result.statusCode == statusCode.NOTEXISTS) {
      toastError(t("NOT_THE_USER"));
    } else if (result && result.statusCode == statusCode.SUCCESS) {
      toastInfo(t("EMAIL_ALREADY_SENT"));
    } else {
      toastError(t("ACCOUNT_OR_PASSWORD_INCORRECT"));
    }
  };

  /**
   * callback
   * @param {string} result ：server return(包含token和userid)
   */
  const onStoreUser = async (result) => {
    let { access_token, user } = result;
    let userId = user._id;

    await setBearerToken(access_token);

    let expireTime = getTokenExpireTime();
    createToken({ value: access_token, expireTime });
    await loadUserInfo(userId);
    addLoginRecord(userId);
    history.replace(referUrl);
  };

  const addLoginRecord = (userId) => {
    let pms = {
      userId,
      busType: BrowseType.LOGIN,
    };
    browseRecordCreate(pms);
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link to="/">
          {/* <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          /> */}
          <VinbipLogo />
        </Link>
        <div className={cn("h4", styles.title)} style={{ marginTop: "50px" }}>
          {t("RESET_PASSWORD")}
          {"?"}
        </div>
        <div className={styles.body}>
          <div className={styles.note}>{t("RESET_PASSWORD_TIPS")}</div>
          <TextInput
            className={styles.field}
            name="email"
            type="email"
            placeholder={t("EMAIL")}
            required
            icon="mail"
            onChange={onEmailChange.bind(this)}
          />
          <button
            className={cn("button", styles.button)}
            onClick={onFinish.bind(this)}
          >
            {t("CONTINUE")}
          </button>
          <div className={styles.info}>
            <Link className={styles.link} onClick={() => history.go(-1)}>
              {t("BACK")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
