import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "antd";
import customer from "../../../resource/images/lead/customer.jpg";
import { Button } from "antd";
import "./style/Leads.less";
import { useHistory } from "react-router-dom";
import { Row, Col } from "antd";

export default function Leads() {
  const { t } = useTranslation();

  const history = useHistory();
  const toSearchDetail = () => {
    history.push("/search");
  };
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("LEADS")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={"common-management normal-shadow-padding10-white"}>
        <div className="common-management-title">{t("LEADS")}</div>
        <div style={{ width: "1000px", backgroundColor: "white" }}>
          {/* <h4 className="customer-text">You dont have any leads now </h4> */}
          <h4 className="customer-text">{t("YOU_NOT_HAVE_LEADS_NOW")} </h4>
          <img src={customer} className="customer-img" />
        </div>
        <Row className="row">
          <Button
            className="customer-button"
            onClick={toSearchDetail.bind(this)}
          >
            Search user now !{" "}
          </Button>
        </Row>
      </div>
    </div>
  );
}
