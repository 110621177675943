import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Home.module.sass";
import { useUserId } from "../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import {
  AmityUiKitFeed,
  AmityUiKitProvider,
  AmityUiKitSocial,
} from "../../components/amity-uikit";
import RightSider from "../../core-digital-template/components/RightSider";
import Card from "../../core-digital-template/components/Card";
import { Link, useHistory } from "react-router-dom";
import Icon from "../../core-digital-template/components/Icon";
import { Path, PathTools } from "../../constants/path";
import { recommandUsers } from "../../api/feed";
import {
  AmityApiKey,
  VinbipAdminService,
  localizeImg,
  localizeValue,
} from "../../constants/constants";
import AvatarBasic from "../../components/common/avatar/AvatarBasic";
import { PostTargetType, FeedType } from "@amityco/js-sdk";
import UserItem from "../RecommendUsers/UserItem";

import Slider from "react-slick";
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";


function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={styles.sliderArrowRight} onClick={onClick}>
      <Icon name="arrow-right" size="20" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={styles.sliderArrowLeft} onClick={onClick}>
      <Icon name="arrow-left" size="20" />
    </div>
  );
}

export default function Home() {
  const { t, i18n } = useTranslation();
  const { user, isImporter } = useUserId();

  const history = useHistory();

  const apiKey = AmityApiKey;
  const [userId, setUserId] = useState(user.userId);
  const [newBuyers, setNewBuyers] = useState([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    let pms = {
      userId: user?.userId,
    };
    let result = await recommandUsers(pms);
    if (result && result.total > 0) {
      result.result.map((mapItem) => {
        if (mapItem && mapItem.companyInfo && mapItem.companyInfo.length > 0) {
          mapItem.companyInfo = mapItem.companyInfo[0];
        }
      });
      setNewBuyers(result.result.slice(0, 8));
    }
  };

  const onCompanyHome = (userId, companyName) => {
    history.push(PathTools.joinPath(Path.companyDetail, companyName), {
      id: userId,
    });
  };
  
  // Duplicate items in newBuyers array if it contains less than 5 items
  // Temporary until we have enough Buyers in data.
  if (newBuyers.length < 5) {
    const duplicatedItems = newBuyers.slice();
    newBuyers.push(...duplicatedItems);
  }

  const sliderSettings = {
      infinite: true,
      speed: 1000,
      autoplaySpeed: 5000,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 660,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
          }
        },
      ]
  };
  
  const onPostCreated=(e)=>{
    console.log("---------onPostCreated:",e);
  }

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {/* <Card
          title={
            isImporter ? t("WELCOME_NEW_SUPPLIERS") : t("WELCOME_NEW_BUYERS")
          }
          classTitle="title-red"
          linkUrl={Path.recommendUsers}
        >
            <Slider {...sliderSettings}>
            {newBuyers?.map((item, index) => {
              return (
                  <UserItem item={item} />
              );
            })}
          </Slider>
          
        </Card> */}
        <Card title="Vinbip Social Feed" classTitle="title-red" />
        <AmityUiKitProvider
          key={userId}
          apiKey={apiKey}
          userId={userId}
          // displayName={
          //   VinbipAdminService[userId] ||
          //   localizeValue(
          //     i18n.language,
          //     user?.userInfo?.companyDesc.companyName
          //   )
          // }
          // authToken={"1f97f4c66e8b7512d3fa2603349c94299f66c7a6fce2d732527cf113a7853543f79541804bf9c1795405df7be265ebe5578cdcf36d3bfc697152807422"}
        >
          {/* <AmityUiKitSocial /> */}
          {/* <AmityUiKitChat/> */}
          <AmityUiKitFeed
            feedType={FeedType.Published}
            targetId={"64259fbf160bdf0c55d6168b"}
            targetType={PostTargetType.CommunityFeed}
            onPostCreated={onPostCreated}
          />
        </AmityUiKitProvider>
      </div>
      {/* <div className={styles.rightSider}> */}
      <div className={styles.col}>
        <RightSider />
      </div>
    </div>
  );
}




