import React, { useEffect, useState } from "react";
import { Col, Image, List, Modal, Row } from "antd";
import { useHistory } from "react-router-dom";
import { getTimeBefore } from "../../../utils/date";
import { useTranslation } from "react-i18next";
import closeIcon from "../../../resource/icons/feed/close.svg";
import { localizeImg, localizeValue } from "../../../constants/constants";
import "./style/articleListRow.less";
import { formatKeyword, getProductStatusText } from "../../../api/common";
import { goToProfilePage } from "../../../utils/utils";
import AvatarBasic from "../../common/avatar/AvatarBasic";
import { ProductStatus } from "../../../constants/statusCode.enum";
import agreeSelectedIcon from "../../../resource/icons/notify/agree_selected.svg";
import refuseSelectedIcon from "../../../resource/icons/notify/refuse_selected.svg";
import { getCompanyDescFromUserType } from "../../../api/company";

export default function ArticleListRow(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { keyword, data, notify } = props;
  const [showDetail, setShowDetail] = useState(false);
  const [title, setTitle] = useState();
  const [name, setName] = useState();
  const [avatar, setAvatar] = useState();
  const [userItem, setUserItem] = useState();
  const [companyDesc, setCompanyDesc] = useState();

  useEffect(() => {
    if (data) {
      let _title = data.title;
      if (keyword && _title) {
        _title = formatKeyword(_title, keyword);
      }
      setTitle(_title);
      if (data.userItem && data.userItem.length > 0) {
        setUserItem(data.userItem[0]);
      }
    }
  }, [keyword, data]);

  useEffect(() => {
    if (userItem) {
      setName(userItem.nickName);
      setAvatar(userItem.picture);
      if (userItem.companyDesc) {
        setCompanyDesc(userItem.companyDesc);
      } else {
        initCompanyDesc();
      }
    }
  }, [userItem]);

  const initCompanyDesc = async () => {
    let pms = { coId: userItem.companyId };
    let result = await getCompanyDescFromUserType(pms, userItem.type);
    // console.log("------articlelist row company desc :", result, pms);
    if (result) {
      setCompanyDesc(result);
      setName(localizeValue(i18n.language, result.companyName));
      setAvatar(result.avatar);
    }
  };

  const onCompanyHome = () => {
    const userItem = {
      _id: data.userItem[0]._id,
      type: data.userItem[0].type,
    };

    goToProfilePage(userItem, history);
  };

  const getCloseIcon = () => {
    return (
      <img alt={"Close icon"} src={closeIcon} className="modal-close-icon" />
    );
  };

  const getStatusIcon = (status) => {
    let result = <div className="userinfo-btn-selected"></div>;
    //处理产品状态
    switch (Number(status)) {
      case ProductStatus.VERIFYWAITING:
        break;
      case ProductStatus.VERIFYSUCCESS:
        result = <img src={agreeSelectedIcon} />;
        break;
      case ProductStatus.VERIFYFAIL:
        result = <img src={refuseSelectedIcon} />;
        break;
      case ProductStatus.STOCK:
        break;
      case ProductStatus.UNSTOCK:
        break;
      default:
        break;
    }
    return result;
  };

  return (
    <List.Item
      className="article-row-item"
      actions={[<div className="article-item-action"></div>]}
    >
      <div className="article-row-item-header">
        <List.Item.Meta
          className="meta-wrapper meta-wrapper-avatar"
          avatar={
            <AvatarBasic
              cname="createFeedAvatar"
              size={40}
              picture={localizeImg(avatar)}
              displayName={name}
            ></AvatarBasic>
          }
          title={<div className="meta-title">{name}</div>}
          description={
            <div className="meta-desc">
              <div>{t(getTimeBefore(data.createdAt))}</div>
            </div>
          }
          onClick={onCompanyHome.bind(this)}
        />
        {data && (
          <div>
            {/* {t(getProductStatusText(data.status))} */}
            {getStatusIcon(data.status)}
          </div>
        )}
      </div>

      <div
        className="article-row-content"
        style={{ marginLeft: "15px" }}
        dangerouslySetInnerHTML={{ __html: title }}
      ></div>
      <div className="article-images-container">
        <Row
          gutter={[8, 8]}
          className="article-images"
          onClick={() => setShowDetail(true)}
        >
          <Col span={24}>
            <div className="article-list-image-box">
              <Image
                preview={false}
                src={localizeImg(data.banner[0])}
                className="article-list-image"
              />
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        destroyOnClose={true}
        title={null}
        visible={showDetail}
        footer={null}
        className={"article-detail-modal"}
        closeIcon={getCloseIcon()}
        onCancel={() => setShowDetail(false)}
      >
        <div className="article-row-item-header">
          <List.Item.Meta
            className="meta-wrapper meta-wrapper-avatar"
            avatar={
              <AvatarBasic
                cname="createFeedAvatar"
                size={50}
                picture={localizeImg(data.userItem[0]?.picture)}
                displayName={
                  data.userItem[0]?.firstName || data.userItem[0]?.nickName
                }
              ></AvatarBasic>
            }
            title={
              <div className="meta-title">{data.userItem[0]?.nickName}</div>
            }
            description={
              <div className="meta-desc">
                <div>{t(getTimeBefore(data.createdAt))}</div>
              </div>
            }
            onClick={onCompanyHome.bind(this)}
          />
        </div>
        <div
          className="article-row-content"
          style={{ marginLeft: "20px", marginTop: "20px" }}
        >
          <b>{data.title}</b>
        </div>
        <div className="article-images-container">
          <div className="article-list-image-box">
            <Image
              preview={false}
              src={localizeImg(data.banner[0])}
              className="article-list-image"
            />
          </div>
        </div>
        <div
          className="product-story"
          dangerouslySetInnerHTML={{ __html: data.content }}
        ></div>
      </Modal>
    </List.Item>
  );
}
