import { actionRequest, formatActionResponse, formatResponse } from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export function getEventList(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.eventList, pms);
}

/**
 *
 * @param pms
 */
export function getEventDetail(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.eventDetail, pms);
}

/**
 * @description Create a event
 * @param pms
 */
export async function eventCreate(pms: any) {
    let data: any = await actionRequest(RequestType.POST, apiPath.eventCreate, pms);
    return formatResponse(data);
}

/**
 * @description Update the event
 * @param pms
 */
export async function eventUpdate(pms: any) {
    let data: any = await actionRequest(RequestType.POST, apiPath.eventUpdate, pms);
    return formatResponse(data);
}

/**
 * @description Get the event
 * @param pms
 */
export async function eventRemove(pms: any) {
    let data: any = await actionRequest(RequestType.GET, apiPath.eventRemove, pms);
    return formatResponse(data);
}

/**
 *
 * @param pms
 */
export function getCompanyEvents(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.eventComEvents, pms);
}

/**
 *
 * @param pms
 */
export function suggestEvents(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.eventSuggests, pms);
}