import React, { useEffect, useState } from "react";
import styles from "./NewProduct.module.sass";
import TooltipGlodal from "../../../../core-digital-template/components/TooltipGlodal";
import Modal from "../../../../core-digital-template/components/Modal";
import Schedule from "../../../../core-digital-template/components/Schedule";
import NameAndDescription from "./NameAndDescription";
import ImagesAndVideo from "./ImagesAndVideo";
import Price from "./Price";
import CategoryAndAttibutes from "./CategoryAndAttibutes";
import ProductFiles from "./ProductFiles";
import Preview from "./Preview";
import Panel from "./Panel";
import { deepClone } from "../../../../utils/utils";
import { toastError, toastSuccess } from "../../../../api/toast";
import { useUserId } from "../../../../contexts/UserIdProvider";
import {
  productCreate,
  productDetail,
  productUpdate,
} from "../../../../api/product";
import { BrowseRecordType } from "../../../../constants/browseRecord.enum";
import { browseRecordCreate } from "../../../../api/browseRecord";
import { useTranslation } from "react-i18next";
import { statusCode } from "../../../../constants/statusCode.enum";
import { useHistory, useLocation } from "react-router";
import { Path, PathTools } from "../../../../constants/path";
import { translateContent } from "../../../../api/translate";

export default function DashboardNewProduct(props) {
  const { state } = useLocation();

  const {
    editProductName,
    editProductTags,
    editProductImages,
    editProductTradingCondition,
    editProductTechInfo,
    editProductTestingNote,
    editProductDetails,
    editProductId,
    onEditCompleted,
    isAddMode,
  } = props;

  console.log("editProductName:", editProductName);

  const [editModel, setEditModel] = useState(
    editProductName ||
      editProductTags ||
      editProductImages ||
      editProductTradingCondition ||
      editProductTechInfo ||
      editProductTestingNote ||
      editProductDetails
  );

  console.log("editModel:", editModel);

  const { user } = useUserId();
  const { t } = useTranslation();

  const history = useHistory();

  const [formData, setFormData] = useState({});

  const [productId, setProductId] = useState();
  const [productDetailInfo, setProductDetailInfo] = useState({});

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  useEffect(() => {
    if ((state && state.productId) || editProductId) {
      setProductId(state?.productId || editProductId);
      initProductDetail(state?.productId || editProductId);
    }
  }, []);

  const initProductDetail = async (id) => {
    let pms = { id };
    let data = await productDetail(pms);

    if (data.statusCode === statusCode.SUCCESS && data.result) {
      let temp = deepClone(productDetailInfo);
      temp = data.result;
      setProductDetailInfo(temp);
      setFormData(temp);
    }
  };

  const onNameAndDescriptionChange = (fields) => {
    setFormDataValue(fields);
  };

  const onImagesChange = (value) => {
    setFormDataValue({ images: value });
  };

  const onVideoChange = (value) => {
    setFormDataValue({ videos: value });
  };

  const onPriceChange = (value) => {
    setFormDataValue({ stocks: value });
  };

  const onTagsChange = (value) => {
    setFormDataValue({ tags: value });
  };

  const onProductFilesChange = (value) => {
    setFormDataValue({ details: value });
  };

  const setFormDataValue = (fields) => {
    let clone = deepClone(formData);
    clone = Object.assign({}, clone, fields);
    setFormData(clone);
  };

  const onPublishProduct = () => {
    onSubmitNewProduct(3);
  };

  const onSaveDraftProduct = () => {
    onSubmitNewProduct(5);
  };

  const onSubmitNewProduct = async (productStatus) => {
    let tempFormData = deepClone(formData);

    if (editProductName && !tempFormData.name) {
      toastError(t("NAME") + t("IS_REQUIRED"));
      return;
    }

    if (editProductTechInfo) {
      if (!tempFormData.classification) {
        toastError(t("CLASSIFICATIONS") + t("IS_REQUIRED"));
        return;
      }

      if (!tempFormData.category) {
        toastError(t("PRODUCT_CATEGORY") + t("IS_REQUIRED"));
        return;
      }
      if (!tempFormData.type) {
        toastError(t("PRODUCT_TYPE") + t("IS_REQUIRED"));
        return;
      }
      if (!tempFormData.country) {
        toastError(t("COUNTRY") + t("IS_REQUIRED"));
        return;
      }
      if (!tempFormData.region) {
        toastError(t("REGION") + t("IS_REQUIRED"));
        return;
      }
      // if (!tempFormData.subRegion) {
      //   toastError(t("SUB_REGION") + t("IS_REQUIRED"));
      //   return;
      // }
      if (!tempFormData.body) {
        toastError(t("BODY") + t("IS_REQUIRED"));
        return;
      }
      if (!tempFormData.grape || tempFormData.grape.length === 0) {
        toastError(t("GRAPE") + t("IS_REQUIRED"));
        return;
      }
      if (!tempFormData.ageOfVine) {
        toastError(t("AGE_OF_VINE") + t("IS_REQUIRED"));
        return;
      }
      // if (!tempFormData.acidity) {
      //   toastError(t("ACIDITY") + t("IS_REQUIRED"));
      //   return;
      // }
      if (!tempFormData.vintage) {
        toastError(t("VINTAGE") + t("IS_REQUIRED"));
        return;
      }
      if (!tempFormData.agingPeriod) {
        toastError(t("AGING_PERIOD") + t("IS_REQUIRED"));
        return;
      }
      if (
        !tempFormData.alcoholDegree ||
        Number(tempFormData.alcoholDegree) <= 0
      ) {
        toastError(t("ALCOHOL_DEGREE") + t("IS_REQUIRED"));
        return;
      }
      if (isNaN(tempFormData.servingTemperature)) {
        toastError(t("SERVING_TEMPERATURE") + t("IS_REQUIRED"));
        return;
      }
      if (!tempFormData.cork) {
        toastError(t("CORK") + t("IS_REQUIRED"));
        return;
      }
    }
    if (editProductTestingNote && !tempFormData.tastingNote) {
      toastError(t("TASTING_NOTE") + t("IS_REQUIRED"));
      return;
    }
    if (
      editProductTestingNote &&
      tempFormData.tastingNote &&
      !tempFormData.tastingNote.en
    ) {
      let pms = {
        text: tempFormData.tastingNote["source"],
      };
      let result = await translateContent(pms);
      console.log("translateContent:", result);
      if (result?.statusCode === statusCode.SUCCESS) {
        tempFormData.tastingNote = result.result;
      }
    }

    // if (!tempFormData.capsule) {
    //   toastError(t("WINE_SEALED") + t("IS_REQUIRED"));
    //   return;
    // }

    if (
      editProductImages &&
      (!tempFormData.images || tempFormData.images.length < 2)
    ) {
      toastError(t("PRODUCT_CREATE_COVER_PICTURE_REQUIREMENT_BOTTLE"));
      return;
    }

    // TAGS not required
    // if (!tempFormData.tags || tempFormData.tags.length === 0) {
    //   toastError(t("TAGS" + "IS_REQUIRED"));
    //   return;
    // }

    if (!tempFormData?.name?.en) {
      if (tempFormData?.name?.fr) {
        tempFormData.name.en = tempFormData?.name?.fr;
      } else if (tempFormData?.name?.zh) {
        tempFormData.name.en = tempFormData?.name?.zh;
      }
    }

    if (!tempFormData?.name?.zh) {
      if (tempFormData?.name?.fr) {
        tempFormData.name.zh = tempFormData?.name?.fr;
      } else if (tempFormData?.name?.en) {
        tempFormData.name.zh = tempFormData?.name?.en;
      }
    }

    if (!tempFormData?.name?.fr) {
      if (tempFormData?.name?.en) {
        tempFormData.name.fr = tempFormData?.name?.en;
      } else if (tempFormData?.name?.zh) {
        tempFormData.name.fr = tempFormData?.name?.zh;
      }
    }

    let list = tempFormData?.stocks?.filter(
      (item) =>
        !item.shippingAddress ||
        !item.currency ||
        !item.package ||
        !item.stock ||
        Number(item.stock) <= 0 ||
        !item.minCount ||
        Number(item.minCount) <= 0 ||
        !item.price ||
        Number(item.price) <= 0
    );

    if (editProductTradingCondition && !list) {
      toastError(t("PLEASE_INPUT_PRODUCT_PRICE_INFORMATION"));
      return;
    }

    let pms = getProductPms(tempFormData);
    pms.status = productStatus;
    console.log("----product submit:", pms);
    if (pms) {
      if (!productId) {
        console.log("----product create:", pms);
        const createProductId = await productCreate(pms);
        if (createProductId) {
          setProductId(createProductId);
          toastSuccess(t("SAVE_SUCCESS"));

          let browseRecordPms = {
            coId: user.userInfo.companyId,
            userId: user.userId,
            busType: BrowseRecordType.ADD_PRODUCT,
            busId: createProductId,
          };
          browseRecordCreate(browseRecordPms);

          // 获取当前页面路径
          const currentPath = window.location.pathname;

          // 判断是否以 "/product/add" 结尾
          const isProductAddPage = currentPath.endsWith("/product/add");
          if (isProductAddPage && isAddMode) {
            onEditCompleted && onEditCompleted();
            history.push(PathTools.joinPath(Path.productInfo, createProductId));
            return;
          }

          if (!editModel) {
            history.go(-1);
          } else {
            onEditCompleted && onEditCompleted();
          }
        } else {
          toastError(t("SAVE_FAILED"));
        }
      } else {
        console.log("----product update:", pms);
        let data = await productUpdate(pms);
        if (data) {
          toastSuccess(t("SAVE_SUCCESS"));

          let browseRecordPms = {
            coId: user.userInfo.companyId,
            userId: user.userId,
            busType: BrowseRecordType.EDIT_PRODUCT,
            busId: productId,
          };
          browseRecordCreate(browseRecordPms);

          if (!editModel) {
            history.go(-1);
          } else {
            onEditCompleted && onEditCompleted(pms);
          }
        } else {
          toastError(t("SAVE_FAILED"));
        }
      }
    }
  };

  const getProductPms = (tempFormData) => {
    let pms = null;
    pms = {
      coId: user.userInfo.companyId,
      ...tempFormData,
    };
    if (productId) {
      pms._id = productId;
    }
    return pms;
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {(!editModel ||
            editProductName ||
            editProductTechInfo ||
            editProductTestingNote) && (
            <NameAndDescription
              className={styles.card}
              onChange={onNameAndDescriptionChange.bind(this)}
              dataSource={productDetailInfo}
              editModel={editModel}
              editProductName={editProductName}
              editProductTechInfo={editProductTechInfo}
              editProductTestingNote={editProductTestingNote}
            />
          )}
          {(!editModel || editProductImages) && (
            <ImagesAndVideo
              className={styles.card}
              onImagesChange={onImagesChange.bind(this)}
              onVideoChange={onVideoChange.bind(this)}
              dataSource={productDetailInfo}
              editModel={editModel}
              editProductDetails={editProductDetails}
            />
          )}
          {(!editModel || editProductTradingCondition) && (
            <Price
              className={styles.card}
              onChange={onPriceChange.bind(this)}
              dataSource={productDetailInfo?.stocks}
            />
          )}
          {(!editModel || editProductTags) && (
            <CategoryAndAttibutes
              className={styles.card}
              onChange={onTagsChange.bind(this)}
              dataSource={productDetailInfo?.tags}
            />
          )}
          {(!editModel || editProductDetails) && (
            <ProductFiles
              className={styles.card}
              onChange={onProductFilesChange.bind(this)}
              dataSource={productDetailInfo?.details}
            />
          )}
        </div>
        {/* <div className={styles.col}>
          <Preview
            visible={visiblePreview}
            onClose={() => setVisiblePreview(false)}
            product={formData}
          />
        </div> */}
      </div>
      <Panel
        onPublish={onPublishProduct.bind(this)}
        onSaveDraft={onSaveDraftProduct.bind(this)}
        editModel={editModel}
      />
      <TooltipGlodal />
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <Schedule
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Modal>
    </>
  );
}
