import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./WarehouseList.module.sass";
import Card from "../../../../../core-digital-template/components/Card";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { loadProductConfig } from "../../../../../api/product";
import { getCountryNameById } from "../../../../../api/config";
import { useTranslation } from "react-i18next";
import {
  OrderStatus,
  statusCode,
} from "../../../../../constants/statusCode.enum";
import { warehouseRemove, warehouses } from "../../../../../api/warehouse";
import removeIcon from "../../../../../resource/icons/common/remove.svg";
import Icon from "../../../../../core-digital-template/components/Icon";
import { Modal } from "antd";
import WarehouseCreate from "../WarehouseCreate";
import { Link, useHistory } from "react-router-dom";


function WarehouseList({ className }) {
  const { t, i18n } = useTranslation();
  const { user, isWinery } = useUserId();

  const [items, setItems] = React.useState([]);
  const [locations, setLocations] = React.useState([]);

  const [currentWarehouse, setCurrentWarehouse] = useState({});
  const [createModalVisible, setCreateModalVisible] = useState(false);

  useEffect(() => {
    loadData();
    initConfigData();
  }, [user]);

  const loadData = async () => {
    let pms = { coId: user.userInfo.companyId };
    let data = await warehouses(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      let list = data.result.result;
      if (list && list.length > 0) {
        list = list.map((item, index) => {
          return { ...item, key: index };
        });
        console.log("list:", list);
        setItems(list);
      }
    }
  };

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const onDelete = async (id) => {
    let pms = { warehouseId: id };
    let data = await warehouseRemove(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      loadData();
    }
  };

  const onComplete = async () => {
    // let tempPms = deepClone(currentWarehouse);
    // tempPms.location = {
    //   country: tempPms.country,
    //   city: [tempPms.province, tempPms.city],
    //   address: tempPms.address,
    // };
    // let pms = {
    //   userId: user.userId,
    //   coId: user.userInfo.companyId,
    //   ...tempPms,
    // };
    // console.log("pms:", pms);
    // let data;
    // if (currentWarehouse._id) {
    //   data = await warehouseUpdate(pms);
    // } else {
    //   data = await warehouseCreate(pms);
    // }
    // console.log("result:", data);
    // if (data && data.statusCode === statusCode.SUCCESS) {
    loadData(user.userId);
    setCreateModalVisible(false);
    // }
  };

  const onClose = () => {};

  const onChange = (val) => {
    console.log("onChange:", val);
    setCurrentWarehouse(val);
  };

  return (
    <Card
      className={styles.card}
      title={t("WAREHOUSE_LIST")}
      classTitle={cn("title-red", styles.title)}
      classCardHead={styles.head}
      head={
        <Link
        className={cn("button", styles.deletebutton)}
        onClick={() => setCreateModalVisible(true)}
      >
        <Icon name="add" size="24" />
        <span>{t("CREATE_WAREHOUSE")}</span>
      </Link>
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>{t("WAREHOUSE_NAME")}</div>
            <div className={styles.col}>{t("WAREHOUSE_ADDRESS")}</div>
            <div className={styles.colHide}>{t("TRADING_CURRENCY")}</div>
            <div className={styles.col}>{t("OPERATIONS")}</div>
          </div>
          {items.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>{x.name}</div>
              <div className={styles.col}><span>{x.location?.address?.name}</span></div>
              <div className={styles.colHide}>€</div>
              <div className={styles.colActionPanel}>
                <div className={cn("button-stroke button-small", styles.item)}
                  onClick={onChange.bind(this, x)}
                >
                  <span>{t("EDIT")}</span>
                </div>
                <div className={cn("button-stroke button-small", styles.item)}
                  onClick={onDelete.bind(this, x._id)}
                >
                  <span>{t("DELETE")}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title={t("ADD_SHIPPING_ADDRESS")}
        visible={createModalVisible}
        footer={null}
        width={501}
        height={756}
        closable={false}
        className="warehouse-create-modal"
        onCancel={() => setCreateModalVisible(false)}
      >
        <WarehouseCreate
          onComplete={onComplete.bind(this)}
          onClose={onClose.bind(this)}
          onChange={onChange.bind(this)}
          currentWarehouse={currentWarehouse}
        ></WarehouseCreate>
      </Modal>
    </Card>
  );
}

export default WarehouseList;
