import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "./Control";
import Icon from "../Icon";
import Checkbox from "../Checkbox";
import { localizeImg, localizeValue } from "../../../constants/constants";
import { useTranslation } from "react-i18next";
import AvatarBasic from "../../../components/common/avatar/AvatarBasic";
import {
  getProductConfigNameById,
  loadProductConfig,
  productFavoriteCount,
  productFavoriteStatus,
} from "../../../api/product";
import { getCountryNameById } from "../../../api/config";
import { companyDetail } from "../../../api/company";
import { useUserId } from "../../../contexts/UserIdProvider";
import { useHistory } from "react-router";
import { Path, PathTools } from "../../../constants/path";
import { UserType } from "../../../constants/usersType.enum";

const Product = ({
  className,
  item,
  value,
  onChange,
  released,
  withoutСheckbox,
  isMyself,
}) => {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [regionName, setRegionName] = useState("");
  const [subRegionName, setSubRegionName] = useState("");
  const [classificationName, setClassificationName] = useState();

  const [companyInfo, setCompanyInfo] = useState({});

  const [favoriteCount, setFavoriteCount] = useState(0);
  const [favoriteStatus, setFavoriteStatus] = useState(false);

  useEffect(() => {
    setConfigData();
    if (item && item.companyInfo && item.companyInfo.length > 0) {
      setCompanyInfo(item.companyInfo[0]);
    } else {
      loadCompanyInfo(item.coId);
    }
    getProductFavoriteCount();
    getFavoriteStatus();
  }, []);

  const handleClick = () => {
    onChange();
    setVisible(!visible);
  };

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations, types, classifications, categories, tags } = configData;
    setRegionName(getCountryNameById(locations, currentLanguage, item.region));
    setSubRegionName(
      getCountryNameById(locations, currentLanguage, item.subRegion)
    );
    setClassificationName(
      getProductConfigNameById(
        classifications,
        currentLanguage,
        item.classification
      )
    );
  };

  const loadCompanyInfo = async (coId) => {
    const result = await companyDetail(coId);
    if (result) {
      setCompanyInfo(result);
    }
  };

  const getProductFavoriteCount = async () => {
    let pms = { productId: item._id };
    let result = await productFavoriteCount(pms);
    setFavoriteCount(result);
  };

  const getFavoriteStatus = async () => {
    let pms = {
      productId: item._id,
      coId: user.userInfo.companyId,
    };

    let result = await productFavoriteStatus(pms);
    setFavoriteStatus(result);
  };

  const onNavToComProfile = (companyName) => {
    history.push(
      PathTools.joinPath(
        Path.companyDetail,
        localizeValue(i18n.language, companyName)
      )
    );
  };

  return (
    <div
      className={cn(styles.product, className, {
        [styles.active]: visible,
      })}
    >
      <div className={styles.line1}>
        <div className={styles.preview}>
          <Control
            className={styles.control}
            productId={item?.productId || item?._id}
            isMyself={isMyself}
          />
          {item?.images && (
            <img
              srcSet={`${localizeImg(item?.images[0])} 2x`}
              src={localizeImg(item?.images[0])}
              alt="Product"
            />
          )}
        </div>
        <div className={styles.column}>
          <div className={styles.title}>
            {localizeValue(i18n.language, item?.name)}
          </div>
          <div className={styles.categories}>
            {regionName}
            <br />
            {subRegionName && <span>{subRegionName}</span>}
            <br />
            {classificationName} {item.alcoholDegree}%
          </div>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: localizeValue(
                i18n.language,
                item?.tastingNote
              )?.substring(0, 60),
            }}
          ></div>
        </div>
      </div>
      {user?.userInfo?.type != UserType.SUPPLIER ? (
        <div className={styles.line2}>
          {item?.prices?.length > 1 ? (
            <div className={styles.price}>
              {item.prices[0].price}
              {item.prices[0].currency}
              {"-"}
              {item.prices[item.prices.length - 1].price}
              {item.prices[item.prices.length - 1].currency}
              {"  EXW"}
            </div>
          ) : (
            <div className={styles.price}>
              {item.prices[0].currency == "¥" && <>{item.prices[0].currency}</>}
              {item.prices[0].price}
              {item.prices[0].currency != "¥" && <>{item.prices[0].currency}</>}
              {"  EXW"}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.line2}>
          <div className={styles.price}>?</div>
        </div>
      )}
      <div className={styles.line3}>
        {companyInfo && (
          <div
            className={styles.avatar}
            onClick={onNavToComProfile.bind(this, companyInfo?.companyName)}
          >
            <AvatarBasic
              picture={localizeImg(companyInfo?.avatar)}
              size={"100%"}
            />
          </div>
        )}
        <div
          className={styles.company}
          onClick={onNavToComProfile.bind(this, companyInfo?.companyName)}
        >
          {localizeValue(i18n.language, companyInfo?.companyName)}
        </div>
        {favoriteCount > 0 && (
          <div className={styles.rating}>
            <span className={styles.counter}>{favoriteCount} </span>
            <Icon name={favoriteStatus ? "heart-fill" : "heart"} size="20" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Product;
