import React, { useEffect, useState } from "react";
import { DownOutlined, UpOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Image,
  Input,
  List,
  Modal,
  Space,
  Spin,
} from "antd";
import { useUserId } from "../../contexts/UserIdProvider";
import "./style/imchat.less";
import searchIcon from "../../resource/icons/instant-messaging/search.svg";
import imageIcon from "../../resource/icons/instant-messaging/image.svg";
import folderIcon from "../../resource/icons/instant-messaging/folder.svg";
import videoCallIcon from "../../resource/icons/instant-messaging/video_call.svg";
import audioCallIcon from "../../resource/icons/instant-messaging/audio.svg";
import avatarIcon from "../../resource/icons/user/default-avatar.svg";
import moreIcon from "../../resource/icons/instant-messaging/option.svg";
import emotionIcon from "../../resource/icons/instant-messaging/emotion.svg";
import {
  getMeetings,
  createMeeting,
  getGroupInfo,
  getGroups,
  removeGroup,
  addGroupMember as addGroupMember_server,
  removeGroupMember as removeGroupMember_server,
  updateGroupName,
} from "../../api/chat";
import { translate } from "../../api/translate";
import { useTranslation } from "react-i18next";
import useIM from "../../hooks/useIM";
import { deepClone } from "../../utils/utils";
import { getTimeStamp } from "../../utils/date";
import { blockUser, getBlockList, pageFriends } from "../../api/contact";
import {
  ChatContentsType,
  AudioCallStatus,
} from "../../constants/chatContentsType.enum";
import VideoBasic from "../common/video/VideoBasic";
import UploadBase from "../common/upload/UploadBase";
import GroupChatManage from "./GroupChatManage";
import RemoveGroupMember from "./RemoveGroupMember";
import AddMeeting from "../meeting/AddMeeting";
import SelectFriends from "./SelectFriends";
import FriendChatManage from "./FriendChatManage";
import { useLocation } from "react-router-dom";
import { useContextData } from "../../contexts/DataProvider";
import { useIMContext } from "../../contexts/IMProvider";
import { ActionType } from "../../constants/actionType.enum";
import { localizeImg, localizeValue } from "../../constants/constants";
import { toastInfo } from "../../api/toast";
import VoiceBasic from "../common/video/VoiceBasic";
import EmotionPanel from "../common/emotion/EmotionPanel";
import { EMOTIONS_NAMES } from "../../constants/emotions";
import { FriendApplyStatus } from "../../constants/statusCode.enum";
import { checkLimit, getTransLanguage } from "../../api/common";
import AvatarBasic from "../common/avatar/AvatarBasic";
import { RoleLimit } from "../../constants/roleType.enum";
import { useHistory } from "react-router-dom";

export default function IMChat() {
  const { t, i18n } = useTranslation();
  const {
    webIM,
    imLoginStatus,
    newMsg,
    sessionMessageList,
    initIMLogin,
    getSessionMessage,
    setChatCallMsg,
  } = useIMContext();
  const {
    messageList,
    newGroup,
    fetchHistoryMessage,
    readChannelMessage,
    sendTextMessage,
    sendCustomMessage,
    createGroup,
    quitGroup,
    dissolveGroup,
    removeGroupMember,
    addGroupMember,
  } = useIM(webIM);
  const { updateMeetings } = useContextData();
  const { state } = useLocation();
  const [userIdParam, setUserIdParam] = useState(state?.userId);
  const { user } = useUserId();
  const history = useHistory();
  const { userId, userInfo } = user;
  const [friends, setFriends] = useState([]);
  const [oriFriends, setOriFriends] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState();
  const [messages, setMessages] = useState([]);
  const [textMsg, setTextMsg] = useState("");
  const { TextArea } = Input;
  let keyword = "";
  const [newMsgStatus, setNewMsgStatus] = useState(true);
  const [selectFriendModalVisible, setSelectFriendModalVisible] =
    useState(false);
  const [groupDrawerVisible, setGroupDrawerVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();
  const [removeMemberModalVisible, setRemoveMemberModalVisible] =
    useState(false);
  const [addMemberModalVisible, setAddMemberModalVisible] = useState(false);
  const [meetingModalVisible, setMeetingModalVisible] = useState(false);
  const [friendDrawerVisible, setFriendDrawerVisible] = useState(false);
  const [emotionPanelVisible, setEmotionPanelVisible] = useState(false);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [lang, setLang] = useState(i18n.language);

  const connectionCount = 10;

  useEffect(() => {
    getFriends();
  }, []);

  useEffect(() => {
    setLang(i18n.language);
    if (messages.length > 0) {
      let list = deepClone(messages);
      list = list.map((item) => {
        item.translatedContent = "";
        item.transStatus = false;
        return item;
      });
      setMessages(list);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (webIM) {
      initIMLogin(userInfo);
    }
  }, [webIM, imLoginStatus]);

  useEffect(() => {
    if (imLoginStatus) {
      getSessionMessage();
    }
  }, [imLoginStatus]);

  useEffect(() => {
    if (imLoginStatus && oriFriends && oriFriends.length > 0) {
      let _list = [];
      if (userIdParam) {
        _list = oriFriends.filter((item) => item.id === userIdParam);
      }
      if (_list && _list.length > 0) {
        onSelectFriend(_list[0]);
      }
      setUserIdParam(null);
    }
  }, [imLoginStatus, oriFriends]);

  useEffect(() => {
    if (sessionMessageList.length > 0 && oriFriends.length > 0) {
      let list = deepClone(oriFriends);
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        let temp = sessionMessageList.filter(
          (item) => item.channel_id.indexOf(element.id) > 0
        );
        if (temp && temp.length > 0) {
          element.unreadNum = temp[0].unread_num;
        }
      }
      //setFriends(list);
    }
  }, [sessionMessageList, oriFriends]);

  useEffect(() => {
    console.log("-------------------messages:", messages);
    if (newMsgStatus) {
      var div = document.getElementById("chatContent");
      div.scrollTop = div.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    let list = messageList;
    console.log("------messagelist:", messageList);
    if (!newMsgStatus) {
      let res = deepClone(messages);
      list = list.concat(res);
    }
    setMessages(list);
  }, [messageList]);

  useEffect(() => {
    if (newMsg) {
      console.log("onReceivedMsg body:", newMsg);
      if (
        newMsg.type === "chat" &&
        selectedFriend &&
        selectedFriend.id === newMsg.from
      ) {
        let res = deepClone(messages);
        res.push(newMsg);
        setMessages(res);
      } else if (
        newMsg.type === "groupchat" &&
        selectedFriend &&
        selectedFriend.groupStatus &&
        selectedFriend.id === newMsg.to
      ) {
        let res = deepClone(messages);
        res.push(newMsg);
        setMessages(res);
      }
    }
  }, [newMsg]);

  useEffect(() => {
    if (newGroup) {
      let _list = deepClone(friends);
      _list.push(formatGroupToFriend(newGroup));
      //setFriends(_list);
    }
  }, [newGroup]);

  //#region
  const loadContacts = async (searchVal) => {
    searchVal = searchVal ? searchVal : "";
    let pms = { page: 0, per_page: 20, keyword: searchVal };
    let result = await pageFriends(userId, pms);
    if (result) {
      let pms = { userId };
      let blackList = await getBlockList(pms);
      result = result.filter((item) => {
        return blackList.filter((b) => b.targetId === item.id).length === 0;
      });
    }
    return result;
  };

  const concatGroups = async (list) => {
    let groupList = await loadGroups();
    list = list.concat(groupList);
    await setFriends(list);
    await setOriFriends(list);
  };

  const getFriends = async (e) => {
    if (e) {
      keyword = e.target.value;
    }
    let list = await loadContacts(keyword);
    console.log("getFriends:", list);
    if (list && list.length > 0) {
      await concatGroups(list);
    }
  };

  const filterContacts = (e) => {
    if (e) {
      keyword = e.target.value;
    }
    if (keyword) {
      const list = oriFriends.filter((item) => {
        return item.name.indexOf(keyword) != -1;
      });
      setFriends(list);
    } else {
      setFriends(oriFriends);
    }
  };

  const readFriendMessage = (id) => {
    const list = friends.map((item) => {
      if (item.id === id) {
        item.unreadNum = 0;
      }
      return item;
    });
    setFriends(list);
  };

  const onSelectFriend = async (item) => {
    if (selectedFriend?.id === item.id) {
      return;
    }
    setSelectedFriend(item);
    setNewMsgStatus(true);
    fetchHistoryMessage(item.id, true, item.groupStatus);
    let chatType;
    if (item.groupStatus) {
      loadGroupInfo(item.serverId);
      chatType = true;
    }
    readChannelMessage(item.id, chatType);
    readFriendMessage(item.id);
  };

  //#endregion

  //#region 消息
  const onSendTextMessage = () => {
    console.log("onSendTextMessage to:", selectedFriend.id);
    if (selectedFriend && textMsg) {
      sendTextMessage(selectedFriend.id, textMsg, selectedFriend.groupStatus);
      addTextMsg();
      setTextMsg("");
    }
  };

  const onKeyDownchange = (e) => {
    if (e.keyCode == 13) {
      onSendTextMessage();
    }
  };

  const onSendCustomMessage = (fileType, url, name) => {
    let content = { fileType, url, name };
    console.log("----chat file :", fileType, url, name);
    sendCustomMessage(selectedFriend.id, content, selectedFriend.groupStatus);
    if (fileType === ChatContentsType.FILE) {
      addCustomMsg(fileType, url, name);
    }
  };

  const addMessage = (newMsg) => {
    if (selectedFriend) {
      let msg = {
        id: getTimeStamp(),
        from: userId,
        to: selectedFriend.id,
        ...newMsg,
      };
      let list = deepClone(messages);
      list.push(msg);
      setNewMsgStatus(true);
      setMessages(list);
    }
  };

  const addTextMsg = () => {
    let msg = { data: textMsg, contentsType: ChatContentsType.TEXT };
    addMessage(msg);
  };

  const addCustomMsg = (fileType, url, name) => {
    let msg = {
      customExts: { url, fileType, name },
      contentsType: ChatContentsType.CUSTOM,
    };
    addMessage(msg);
  };

  const translateMessage = async (content) => {
    let language = getTransLanguage(lang);
    let data = await translate({
      text: content,
      to: language,
    });
    return data.result.result;
  };

  const msgFormat = (item, from) => {
    let { contentsType, customExts, transStatus, translatedContent } = item;
    let result = "";
    if (contentsType === ChatContentsType.TEXT) {
      result = (
        <div className={from ? "rightText" : "leftText"}>
          {/* {item.data} */}
          <div
            dangerouslySetInnerHTML={{ __html: parseEmotionMessage(item.data) }}
          ></div>
          <div className="translate" onClick={transMessage.bind(this, item)}>
            {t("TRANSLATE")}
            {transStatus ? (
              <UpOutlined style={{ fontSize: "10px" }} />
            ) : (
              <DownOutlined style={{ fontSize: "10px" }} />
            )}
          </div>
          {transStatus && (
            <div className="trans-content">{translatedContent}</div>
          )}
          {transStatus && !translatedContent && (
            <div className="trans-content">
              <Spin indicator={loadingIcon} />
            </div>
          )}
        </div>
      );
    } else if (contentsType === ChatContentsType.CUSTOM) {
      let { url, fileType, name } = customExts;
      switch (fileType) {
        case ChatContentsType.IMAGE:
          result = <Image src={localizeImg(url)} className="image" />;
          break;
        case ChatContentsType.VIDEO:
          result = (
            <VideoBasic url={localizeImg(url)} width={200} height={150} />
          );
          break;
        case ChatContentsType.VOICE:
          result = <VoiceBasic url={localizeImg(url)} />;
          break;
        case ChatContentsType.FILE:
          result = <a href={localizeImg(url)}>{name}</a>;
          break;
        case ChatContentsType.AUDIOCALL:
          result = (
            <div className={from ? "rightText" : "leftText"}>
              {t("AUDIO_CALL_DONE")}
            </div>
          );
          break;
        case ChatContentsType.VIDEOCALL:
          result = (
            <div className={from ? "rightText" : "leftText"}>
              {t("VIDEO_CALL_DONE")}
            </div>
          );
          break;
        case ChatContentsType.CANCELCALL:
          result = (
            <div className={from ? "rightText" : "leftText"}>
              {t("CALL_CANCELED")}
            </div>
          );
          break;
        case ChatContentsType.REFUSECALL:
          result = (
            <div className={from ? "rightText" : "leftText"}>
              {t("CALL_REJECTED")}
            </div>
          );
          break;
        default:
          break;
      }
    }
    return result;
  };

  const transMessage = async (message) => {
    let list = [];
    let flag = false;
    if (messages && messages.length > 0) {
      list = messages.map((item) => {
        if (item.id === message.id) {
          flag = item.transStatus;
          item.transStatus = !flag;
        }
        return item;
      });
      setMessages(list);
      list = [];
      for (let index = 0; index < messages.length; index++) {
        let item = messages[index];
        if (item.id === message.id) {
          if (!flag && !item.translatedContent) {
            let _transContent = await translateMessage(item.data);
            item.translatedContent = _transContent;
          }
        }
        list.push(item);
      }
      setMessages(list);
    }
  };

  //#endregion

  const getUserAvatar = (from) => {
    let _avatar = localizeImg(
      from === userId ? userInfo.picture : selectedFriend.picture
    );
    return _avatar ? _avatar : avatarIcon;
  };

  //#region 群组
  const loadGroups = async () => {
    let pms = { coId: userInfo.companyId, memberId: userId };
    let result = await getGroups(pms);
    if (result) {
      result = result.filter((group) => (group.imGroupId ? true : false));
      result = result.map((item) => {
        return formatGroupToFriend(item);
      });
    }
    return result;
  };

  const loadGroupInfo = async (groupId) => {
    let pms = { groupId };
    let result = await getGroupInfo(pms);
    if (result) {
      let group = result[0];
      group.members = group.members.filter((member) => member);
      setSelectedGroup(group);
    }
  };

  const formatGroupToFriend = (item) => {
    let { _id, name, imGroupId } = item;
    return { id: imGroupId, serverId: _id, name, groupStatus: true };
  };

  const onCreateGroup = (groupName, groupDesc, members) => {
    let memberList = members.map((member, index) => {
      return {
        memberId: member,
        agoraId: (10000001 + index).toString(),
        isOwner: "2",
      };
    });
    memberList.unshift({
      memberId: userId,
      agoraId: "10000000",
      isOwner: "1",
    });
    let pms = {
      owner: userId,
      coId: userInfo.companyId,
      type: "Normal",
      name: groupName,
      members: memberList,
    };
    createGroup(groupName, groupDesc, members, pms);

    setSelectFriendModalVisible(false);
    getFriends();
  };

  const onQuitGroup = () => {
    quitGroup(selectedFriend.id);
    serverRemoveGroupMember(selectedGroup._id, userId);
    removeFriendItem();
  };

  const serverRemoveGroupMember = (groupId, memberId) => {
    let pms = {
      groupId,
      member: { memberId },
    };
    removeGroupMember_server(pms);
  };

  const onDissolveGroup = () => {
    dissolveGroup(selectedFriend.id);
    removeFriendItem();
    let pms = { id: selectedGroup._id };
    removeGroup(pms);
  };

  const removeFriendItem = () => {
    let list = friends.filter((item) => item.id !== selectedFriend.id);
    setFriends(list);
    setSelectedFriend(null);
  };

  const onRemoveGroupMember = (members) => {
    members.map((member) => {
      removeGroupMember(selectedFriend.id, member);
      serverRemoveGroupMember(selectedGroup._id, member);
    });
    let group = deepClone(selectedGroup);
    let list = group.members;
    list = list.filter((item) => {
      let tempList = members.filter((m) => m === item.memberId);
      if (tempList && tempList.length > 0) {
        return false;
      } else {
        return true;
      }
    });
    group.members = list;
    setSelectedGroup(group);
  };

  const onAddGroupMember = (keys, members) => {
    addGroupMember(selectedFriend.id, keys);
    let maxNum = getAgoraId() + 1;
    keys.map((key, index) => {
      serverAddGroupMembers(key, maxNum + index);
    });
    let group = deepClone(selectedGroup);
    let list = group.members;
    members.map((item, index) => {
      let member = {
        memberId: item.id,
        agoraId: maxNum + index,
        isOwner: "2",
        userInfo: {
          nickName: item.name,
          picture: item.picture,
          _id: item.id,
        },
      };
      list.push(member);
    });
    group.members = list;
    setSelectedGroup(group);
  };

  const serverAddGroupMembers = (key, agoraId) => {
    let pms = {
      groupId: selectedFriend.serverId,
      member: {
        memberId: key,
        agoraId,
        isOwner: "2",
      },
    };
    addGroupMember_server(pms);
  };

  const getAgoraId = () => {
    let { members } = selectedGroup;
    let list = members
      .filter((item) => item)
      .map((item) => Number(item.agoraId))
      .sort();
    return list[list.length - 1];
  };

  const onGroupNameChange = async (groupName) => {
    var pms = { _id: selectedFriend.serverId, name: groupName };
    await updateGroupName(pms);
    selectedFriend.name = groupName;
    setSelectedFriend(selectedFriend);
    getFriends();
  };

  //#endregion

  //#region meeting
  const onMeetingAdd = async (meeting) => {
    if (meeting) {
      meeting.groupId = selectedFriend.serverId;
      let list = [];
      // let owner;
      let { members } = selectedGroup;
      members.map((item) => {
        list.push(item.memberId);
        // if (item.isOwner === "1") {
        //   owner = item.memberId;
        // }
      });
      meeting.owner = userId;
      meeting.status = "0";
      meeting.members = list;
      await createMeeting(meeting);
      updateMeetings();
    }
  };
  //#endregion

  //#region 一对一通话
  const onAudioCall = () => {
    if (selectedFriend) {
      if (selectedFriend.groupStatus) {
        setMeetingModalVisible(true);
      } else {
        setChatCallMsg({
          type: ChatContentsType.AUDIOCALL,
          from: userId,
          to: selectedFriend.id,
        });
      }
    }
  };

  const onVideoCall = () => {
    if (selectedFriend) {
      if (selectedFriend.groupStatus) {
        setMeetingModalVisible(true);
      } else {
        setChatCallMsg({
          type: ChatContentsType.VIDEOCALL,
          from: userId,
          to: selectedFriend.id,
        });
      }
    }
  };
  //#endregion

  const onChatManage = () => {
    if (selectedFriend.groupStatus) {
      setGroupDrawerVisible(true);
    } else {
      setFriendDrawerVisible(true);
    }
  };

  const onAddUserBlack = async () => {
    let targetId = selectedFriend.id;
    // addBlackList(targetId);
    let pms = { sourceId: userId, targetId };
    await blockUser(pms);
    let list1 = friends.filter((item) => {
      return item.id !== targetId;
    });
    setFriends(list1);
    let list2 = oriFriends.filter((item) => {
      return item.id !== targetId;
    });
    setOriFriends(list2);
    setFriendDrawerVisible(false);
  };

  const onRemoveFriendItem = () => {
    let targetId = selectedFriend.id;
    let list = friends.filter((item) => {
      return item.id !== targetId;
    });
    setFriends(list);
    list = oriFriends.filter((item) => {
      return item.id !== targetId;
    });
    setOriFriends(list);
    setFriendDrawerVisible(false);
    setSelectedFriend(null);
    setMessages([]);
  };

  const onEmotionPress = (emotionItem) => {
    console.log("-----------onEmotionPress:", emotionItem);
    // setEmotionCode(emotionItem.name);
    setTextMsg(textMsg + emotionItem.name);
    setEmotionPanelVisible(false);
  };

  const parseEmotionMessage = (msg) => {
    EMOTIONS_NAMES.forEach((item) => {
      if (msg.indexOf(item.name) > -1) {
        let icon = `<img src='${item.path}' class='emotion-img'/>`;
        msg = msg.replace(item.name, icon);
      }
    });
    // console.log('--------parseEmotionMessage:', msg);
    return msg;
  };

  const onTryCreateGroup = () => {
    if (checkLimit(userInfo, RoleLimit.CREATE_GROUP, history)) {
      setSelectFriendModalVisible(true);
    }
  };

  return (
    <div className="imchat-container">
      <div className="imchat">
        <div className="left">
          <div className="top_title">{t("MESSAGE")}</div>
          <Divider type="horizontal" />

          <div className="search">
            <Input
              placeholder={t("SEARCH_CONTACT")}
              className="input"
              onPressEnter={getFriends.bind(this)}
              onChange={filterContacts.bind(this)}
            />
            <img src={searchIcon} className="icon" />
          </div>
          <List
            className="list"
            dataSource={friends}
            renderItem={(item, index) => (
              <List.Item
                key={index}
                className={
                  "listItem " +
                  (selectedFriend &&
                    selectedFriend.id === item.id &&
                    "listItemSelected")
                }
                onClick={onSelectFriend.bind(this, item)}
              >
                <List.Item.Meta
                  avatar={
                    <div>
                      {/* {item.companyInfo.avatar && (
                        <Avatar
                          src={
                            localizeImg(item.companyInfo.avatar) || avatarIcon
                          }
                        />
                      )}
                      {!item.companyInfo.avatar && (

                      )}*/}
                      <AvatarBasic
                        cname="meta-avatar"
                        size={50}
                        picture={localizeImg(item.companyInfo?.avatar)}
                        displayName={
                          item.companyInfo?.displayName ||
                          localizeValue(i18n.language,item.companyInfo?.companyName)
                        }
                      ></AvatarBasic>
                    </div>
                  }
                  title={item.name}
                  description={item.signature}
                />
                {item.unreadNum > 0 && (
                  <div className="user-tip">{item.unreadNum}</div>
                )}
              </List.Item>
            )}
          />
        </div>
        <div className="right">
          <div className="header">
            {selectedFriend && (
              <div className="header-row">
                <Space size={8}>
                  <span className="userName">{selectedFriend.name}</span>
                  <Divider type="vertical" />
                  <span className="userDesc">{selectedFriend.signature}</span>
                </Space>
                <img
                  src={moreIcon}
                  className="header-more"
                  onClick={onChatManage.bind(this)}
                />
              </div>
            )}
          </div>
          <div className="content" id="chatContent">
            {messages.map((item) => (
              <div
                key={item.id}
                className={"message " + (item.from === userId && "flexEnd")}
              >
                <Space size={14}>
                  {item.from === userId && msgFormat(item, true)}
                  <AvatarBasic
                    cname="meta-avatar"
                    size={50}
                    picture={""}
                    displayName={userInfo.nickName}
                  ></AvatarBasic>
                  {item.to === userId && msgFormat(item, false)}
                  {item.from !== userId &&
                    item.type === "groupchat" &&
                    msgFormat(item, false)}
                </Space>
              </div>
            ))}
          </div>
          <div className="footer">
            <div className="iconButtonBar">
              <Space size={3}>
                <UploadBase
                  onLoading={addCustomMsg.bind(this)}
                  onLoaded={onSendCustomMessage.bind(this)}
                >
                  <img src={imageIcon} className="icon" />
                </UploadBase>
                <UploadBase onLoaded={onSendCustomMessage.bind(this)}>
                  <img src={folderIcon} className="icon" />
                </UploadBase>
                <img
                  src={audioCallIcon}
                  className="icon"
                  onClick={onAudioCall.bind(this)}
                />
                <img
                  src={videoCallIcon}
                  className="icon"
                  onClick={onVideoCall.bind(this)}
                />
                <img
                  src={emotionIcon}
                  className="icon"
                  onClick={() => setEmotionPanelVisible(!emotionPanelVisible)}
                />
              </Space>
            </div>
            <TextArea
              placeholder={t("WRITE_A_MESSAGE")}
              className="textarea message-input"
              autoSize={{ minRows: 1, maxRows: 4 }}
              value={textMsg}
              onChange={(e) => setTextMsg(e.target.value)}
              onKeyDown={(e) => onKeyDownchange(e)}
            />
            <Button className="button" onClick={onSendTextMessage.bind(this)}>
              {t("SEND")}
            </Button>
          </div>
          {emotionPanelVisible && (
            <EmotionPanel onEmotionPress={onEmotionPress.bind(this)} />
          )}
        </div>
        <Modal
          destroyOnClose={true}
          title={null}
          visible={selectFriendModalVisible}
          footer={null}
          className="imchat-select-friend-modal"
          onCancel={() => setSelectFriendModalVisible(false)}
        >
          <SelectFriends
            selectedFriend={selectedFriend}
            friends={friends}
            onClose={() => setSelectFriendModalVisible(false)}
            onCreateGroup={onCreateGroup.bind(this)}
          />
        </Modal>
        <Modal
          destroyOnClose={true}
          title={null}
          visible={addMemberModalVisible}
          footer={null}
          className="imchat-select-friend-modal"
          onCancel={() => setAddMemberModalVisible(false)}
        >
          <SelectFriends
            selectedFriend={selectedFriend}
            friends={friends}
            group={selectedGroup}
            onClose={() => setAddMemberModalVisible(false)}
            onAddGroupMember={onAddGroupMember.bind(this)}
          />
        </Modal>
        <Modal
          destroyOnClose={true}
          title={null}
          visible={removeMemberModalVisible}
          footer={null}
          className="imchat-select-friend-modal"
          onCancel={() => setRemoveMemberModalVisible(false)}
        >
          <RemoveGroupMember
            group={selectedGroup}
            onClose={() => setRemoveMemberModalVisible(false)}
            onSubmit={onRemoveGroupMember.bind(this)}
          />
        </Modal>
        <Modal
          destroyOnClose={true}
          title={null}
          visible={meetingModalVisible}
          footer={null}
          maskClosable={false}
          className="imchat-select-friend-modal"
          onCancel={() => setMeetingModalVisible(false)}
        >
          <AddMeeting
            onClose={() => setMeetingModalVisible(false)}
            onSubmit={onMeetingAdd.bind(this)}
          />
        </Modal>
        <Drawer
          title={t("GROUP_SETTINGS")}
          width={380}
          bodyStyle={{ padding: 0 }}
          placement="right"
          onClose={() => setGroupDrawerVisible(false)}
          visible={groupDrawerVisible}
        >
          {selectedFriend && (
            <GroupChatManage
              group={selectedGroup}
              onClose={() => setGroupDrawerVisible(false)}
              onQuitGroup={onQuitGroup.bind(this)}
              onDissolveGroup={onDissolveGroup.bind(this)}
              onRemoveGroupMember={() => setRemoveMemberModalVisible(true)}
              onAddGroupMember={() => setAddMemberModalVisible(true)}
              onGroupNameChange={onGroupNameChange.bind(this)}
            />
          )}
        </Drawer>
        <Drawer
          title={selectedFriend?.name}
          width={380}
          bodyStyle={{ padding: 0 }}
          placement="right"
          onClose={() => setFriendDrawerVisible(false)}
          visible={friendDrawerVisible}
        >
          {selectedFriend && (
            <FriendChatManage
              friend={selectedFriend}
              onClose={() => setFriendDrawerVisible(false)}
              onCreateGroup={onTryCreateGroup.bind(this)}
              onAddBlackList={onAddUserBlack.bind(this)}
              onRemoveFriendItem={onRemoveFriendItem.bind(this)}
            />
          )}
        </Drawer>
      </div>
    </div>
  );
}
