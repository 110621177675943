import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style/search.less";
import { Input, List, Spin, Tabs, Button, Menu, Row, Col } from "antd";
import { recommandTenders, searchPage } from "../../api/feed";
import { useUserId } from "../../contexts/UserIdProvider";
import { deepClone } from "../../utils/utils";
import FeedListRow from "../feed/FeedListRow";
import ProductCardItem from "../shop/product/ProductCardItem";
import { useLocation } from "react-router-dom";
import { userPage } from "../../api/user";
import { UserLabel, UserType } from "../../constants/usersType.enum";
import UserItemBasic from "../shared/UserItemBasic";
import { CardType } from "../../constants/cardType.enum";
import ArticleListRow from "../feed/article/ArticleListRow";
import { getArticles } from "../../api/article";
import TenderListRow from "../tender/TenderListRow";
import { getEventList } from "../../api/events";
import EventListRow from "../event/components/EventListRow";
import InfiniteScroll from "react-infinite-scroll-component";
import { ProductStatus } from "../../constants/statusCode.enum";
import ListBasic from "../common/list/ListBasic";
import feedIcon from "../../resource/icons/search/feed.svg";
import feedSelectedIcon from "../../resource/icons/search/feed_selected.svg";
import articleIcon from "../../resource/icons/search/article.svg";
import articleSelectedIcon from "../../resource/icons/search/article_selected.svg";
import importerIcon from "../../resource/icons/search/importer.svg";
import importerSelectedIcon from "../../resource/icons/search/importer_selected.svg";
import wineryIcon from "../../resource/icons/search/winery.svg";
import winerySelectedIcon from "../../resource/icons/search/winery_selected.svg";
import productIcon from "../../resource/icons/search/product.svg";
import productSelectedIcon from "../../resource/icons/search/product_selected.svg";
import mediaIcon from "../../resource/icons/search/media.svg";
import mediaSelectedIcon from "../../resource/icons/search/media_selected.svg";
import tenderIcon from "../../resource/icons/search/tender.svg";
import tenderSelectedIcon from "../../resource/icons/search/tender_selected.svg";
import activityIcon from "../../resource/icons/search/activity.svg";
import activitySelectedIcon from "../../resource/icons/search/activity_selected.svg";
import negociantIcon from "../../resource/icons/search/negociant.svg";
import negociantSelectedIcon from "../../resource/icons/search/negociant_selected.svg";
import cooperativeIcon from "../../resource/icons/search/cooperative.svg";
import cooperativeSelectedIcon from "../../resource/icons/search/cooperative_selected.svg";
import distributorIcon from "../../resource/icons/search/distributor.svg";
import distributorSelectedIcon from "../../resource/icons/search/distributor_selected.svg";
import corporatorIcon from "../../resource/icons/search/corporator.svg";
import corporatorSelectedIcon from "../../resource/icons/search/corporator_selected.svg";
import RightSider from "../shared/righ-sider/RightSider";

const qs = require("query-string");

export default function Search() {
  const { search } = useLocation();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { user } = useUserId();

  const MenuTabType = {
    FEED: "FEED",
    PRODUCT: "PRODUCT",
    ARTICLE: "ARTICLE",
    WINERY: "WINERY",
    IMPORTER: "IMPORTER",
    NEGOCIANT: "NEGOCIANT",
    COOPERATIVE: "COOPERATIVE",
    DISTRIBUTORS: "DISTRIBUTORS",
    CORPORATEBUSINESS: "CORPORATEBUSINESS",
    MEDIA: "MEDIA",
    OPPORTUNITY: "OPPORTUNITY",
    EVENT: "EVENT",
  };

  const MenuItems = [
    {
      name: MenuTabType.FEED,
      icon: feedIcon,
      iconSelected: feedSelectedIcon,
      type: UserType.ALL,
    },
    {
      name: MenuTabType.PRODUCT,
      icon: productIcon,
      iconSelected: productSelectedIcon,
      type: UserType.BUYER,
    },
    {
      name: MenuTabType.ARTICLE,
      icon: articleIcon,
      iconSelected: articleSelectedIcon,
      type: UserType.ALL,
    },
    {
      name: MenuTabType.WINERY,
      icon: wineryIcon,
      iconSelected: winerySelectedIcon,
      type: UserType.BUYER,
    },
    {
      name: MenuTabType.IMPORTER,
      icon: importerIcon,
      iconSelected: importerSelectedIcon,
      type: UserType.SUPPLIER,
    },
    {
      name: MenuTabType.NEGOCIANT,
      icon: negociantIcon,
      iconSelected: negociantSelectedIcon,
      type: UserType.BUYER,
    },
    {
      name: MenuTabType.COOPERATIVE,
      icon: cooperativeIcon,
      iconSelected: cooperativeSelectedIcon,
      type: UserType.BUYER,
    },
    {
      name: MenuTabType.DISTRIBUTORS,
      icon: distributorIcon,
      iconSelected: distributorSelectedIcon,
      type: UserType.SUPPLIER,
    },
    {
      name: MenuTabType.CORPORATEBUSINESS,
      icon: corporatorIcon,
      iconSelected: corporatorSelectedIcon,
      type: UserType.SUPPLIER,
    },
    {
      name: MenuTabType.MEDIA,
      icon: mediaIcon,
      iconSelected: mediaSelectedIcon,
      type: UserType.ALL,
    },
    {
      name: MenuTabType.OPPORTUNITY,
      icon: tenderIcon,
      iconSelected: tenderSelectedIcon,
      type: UserType.ALL,
    },
    {
      name: MenuTabType.EVENT,
      icon: activityIcon,
      iconSelected: activitySelectedIcon,
      type: UserType.ALL,
    },
  ];

  const [currentTab, setCurrentTab] = useState(MenuTabType.FEED);

  const [products, setProducts] = useState([]);
  const [feedList, setFeedList] = useState([]);
  const [articleList, setArticleList] = useState([]);
  const [tenderList, setTenderList] = useState([]);
  const [eventList, setEventList] = useState([]);

  const [importerUsers, setImporterUsers] = useState([]);
  const [distributorUsers, setDistributorUsers] = useState([]);
  const [corporaterUsers, setCorporateUsers] = useState([]);
  const [wineryUsers, setWineryUsers] = useState([]);
  const [negociantUsers, setNegociantUsers] = useState([]);
  const [cooperativeUsers, setCooperativeUsers] = useState([]);
  const [mediaUsers, setMediaUsers] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 10;
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [keyword, setKeyword] = useState();

  const [scrollDataLength, setScrollDataLength] = useState(0);

  useEffect(() => {
    onLoadData();
  }, []);

  useEffect(() => {
    if (state) {
      setKeyword(state.searchKey);
    }
  }, [state]);

  useEffect(() => {
    onLoadData();
  }, [pageIndex]);

  useEffect(() => {
    if (pageIndex === 0) {
      onLoadData();
    } else {
      setPageIndex(0);
    }
  }, [currentTab]);

  const onLoadData = () => {
    switch (currentTab) {
      case MenuTabType.FEED:
        loadFeeds();
        break;
      case MenuTabType.PRODUCT:
        loadProductData();
        break;
      case MenuTabType.ARTICLE:
        loadArticle();
        break;
      case MenuTabType.WINERY:
        loadWineryUsers();
        break;
      case MenuTabType.NEGOCIANT:
        loadNegociantUsers();
        break;
      case MenuTabType.COOPERATIVE:
        loadCooperativeUsers();
        break;
      case MenuTabType.IMPORTER:
        loadImporterUsers();
        break;
      case MenuTabType.DISTRIBUTORS:
        loadDistributorUsers();
        break;
      case MenuTabType.CORPORATEBUSINESS:
        loadCorporateUsers();
        break;
      case MenuTabType.MEDIA:
        loadMediaUsers();
        break;
      case MenuTabType.OPPORTUNITY:
        loadTenders();
        break;
      case MenuTabType.EVENT:
        loadEvent();
        break;
      default:
        break;
    }
  };

  const loadFeeds = async () => {
    let pms = {
      keyword: keyword,
      type: CardType.FEED,
      page: pageIndex,
      per_page: pageSize,
    };
    let result = await searchPage(pms);

    if (result) {
      result = result.map((item) => {
        return { ...item.card };
      });
      if (pageIndex > 0) {
        let _list = deepClone(feedList);
        result = _list.concat(result);
      }
      setFeedList(result);
      setScrollDataLength(result.length);
      setHasMore(true);
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setFeedList([]);
      }
    }
    setLoading(false);
  };

  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };
  const pageRefresh = () => {
    setPageIndex(0);
  };

  const loadArticle = async () => {
    setLoading(true);
    let pms = {
      page: pageIndex,
      per_page: pageSize,
      sort: -1,
      status: ProductStatus.VERIFYSUCCESS,
    };
    if (keyword) {
      pms["keyword"] = keyword;
    }
    let data = await getArticles(pms);

    if (data && data.total > 0) {
      let { result } = data;
      if (pageIndex > 0) {
        let _list = deepClone(articleList);
        result = _list.concat(result);
      }
      setArticleList(result);
      setScrollDataLength(result.length);
      setHasMore(true);
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setArticleList([]);
      }
    }
    setLoading(false);
  };

  const loadProductData = async () => {
    setLoading(true);
    let pms = {
      keyword: keyword,
      type: CardType.PRODUCT,
      page: pageIndex,
      per_page: pageSize,
    };
    let result = await searchPage(pms);
    if (result && result.length > 0) {
      result = result.map((item) => {
        let info = item.card;
        return info;
      });
      if (pageIndex > 0) {
        let list = deepClone(products);
        result = list.concat(result);
      }
      setProducts(result);
      setScrollDataLength(result.length);
      setHasMore(true);
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setProducts([]);
      }
    }
    setLoading(false);
  };

  const loadImporterUsers = async () => {
    setLoading(true);
    let pms = {
      page: pageIndex,
      per_page: 50,
      // type: UserType.BUYER,
      type: UserLabel.IMPORTER,
      keyword,
    };
    let result = await userPage(pms);
    console.log("load IMPORTER res...", result, pms);
    if (result && result.length > 0) {
      if (pageIndex > 0) {
        let _list = deepClone(importerUsers);
        result = _list.concat(result);
      }
      setImporterUsers(result);
      setScrollDataLength(result.length);
      setHasMore(true);
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setImporterUsers([]);
      }
    }
    setLoading(false);
  };

  const loadDistributorUsers = async () => {
    setLoading(true);
    let pms = {
      page: pageIndex,
      per_page: pageSize,
      // type: UserType.DistributorOwner,
      type: UserLabel.DISTRIBUTOR,
      keyword,
    };
    let result = await userPage(pms);
    if (result && result.length > 0) {
      if (pageIndex > 0) {
        let _list = deepClone(distributorUsers);
        result = _list.concat(result);
      }
      setDistributorUsers(result);
      setScrollDataLength(result.length);
      setHasMore(true);
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setDistributorUsers([]);
      }
    }
    setLoading(false);
  };

  const loadCorporateUsers = async () => {
    setLoading(true);
    let pms = {
      page: pageIndex,
      per_page: pageSize,
      type: UserLabel.CORPORATEBUSINESS,
      keyword,
    };
    let result = await userPage(pms);
    if (result && result.length > 0) {
      if (pageIndex > 0) {
        let _list = deepClone(corporaterUsers);
        result = _list.concat(result);
      }
      setCorporateUsers(result);
      setScrollDataLength(result.length);
      setHasMore(true);
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setCorporateUsers([]);
      }
    }
    setLoading(false);
  };

  const loadWineryUsers = async () => {
    setLoading(true);
    let pms = {
      page: pageIndex,
      per_page: pageSize,
      type: UserType.WINERY,
      keyword,
    };
    let result = await userPage(pms);
    if (result && result.length > 0) {
      if (pageIndex > 0) {
        let _list = deepClone(wineryUsers);
        result = _list.concat(result);
      }
      setWineryUsers(result);
      setScrollDataLength(result.length);
      setHasMore(true);
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setWineryUsers([]);
      }
    }
    setLoading(false);
  };

  const loadNegociantUsers = async () => {
    setLoading(true);
    let pms = {
      page: pageIndex,
      per_page: pageSize,
      type: UserLabel.NEGOCIANT,
      keyword,
    };
    let result = await userPage(pms);
    if (result && result.length > 0) {
      if (pageIndex > 0) {
        let _list = deepClone(negociantUsers);
        result = _list.concat(result);
      }
      setNegociantUsers(result);
      setScrollDataLength(result.length);
      setHasMore(true);
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setNegociantUsers([]);
      }
    }
    setLoading(false);
  };

  const loadCooperativeUsers = async () => {
    setLoading(true);
    let pms = {
      page: pageIndex,
      per_page: pageSize,
      type: UserLabel.COOPERATIVE,
      keyword,
    };
    let result = await userPage(pms);
    if (result && result.length > 0) {
      if (pageIndex > 0) {
        let _list = deepClone(cooperativeUsers);
        result = _list.concat(result);
      }
      setCooperativeUsers(result);
      setScrollDataLength(result.length);
      setHasMore(true);
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setCooperativeUsers([]);
      }
    }
    setLoading(false);
  };

  const loadMediaUsers = async () => {
    setLoading(true);
    let pms = {
      page: pageIndex,
      per_page: pageSize,
      type: UserLabel.MEDIA,
      keyword,
    };
    let result = await userPage(pms);
    if (result && result.length > 0) {
      if (pageIndex > 0) {
        let _list = deepClone(mediaUsers);
        result = _list.concat(result);
      }
      setMediaUsers(result);
      setScrollDataLength(result.length);
      setHasMore(true);
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setMediaUsers([]);
      }
    }
    setLoading(false);
  };

  const loadTenders = async () => {
    setLoading(true);
    let pms = {
      page: pageIndex,
      per_page: pageSize,
      status: 0,
    };
    if (keyword) {
      pms["keyword"] = keyword;
    }
    let data = await recommandTenders(pms);
    console.log("------recommand tenders:", pms, data);
    if (data && data.total > 0) {
      let { result, total } = data;
      if (pageIndex > 0) {
        let _list = deepClone(tenderList);
        result = _list.concat(result);
      }
      setTenderList(result);
      setScrollDataLength(total);
      setHasMore(true);
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setTenderList([]);
      }
    }
    setLoading(false);
  };

  const loadEvent = async () => {
    setLoading(true);
    let pms = { page: pageIndex, per_page: pageSize, keyword };
    let data = await getEventList(pms);
    if (data && data.total > 0) {
      let { result, total } = data;
      if (result && result.length > 0) {
        if (pageIndex > 0) {
          let _list = deepClone(eventList);
          result = _list.concat(result);
        }
        setEventList(result);
        setScrollDataLength(total);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
      if (pageIndex === 0) {
        setEventList([]);
      }
    }
    setLoading(false);
  };

  const handleClick = (e) => {
    console.log("----------------handleClick:", e);
    setCurrentTab(e.key);
  };

  return (
    <div className="search-page-root">
      <div className="search-page">
        <div className="left-side">
          <div className="left-side-title">{t("SEARCH_RESULT")}</div>
          <Menu
            onClick={handleClick.bind(this)}
            selectedKeys={[currentTab]}
            mode="vertical"
            style={{
              borderRadius: "8px",
              padding: "10",
              backgroundColor: "#ffffff",
              minHeight: "200px",
              width: "198px",
            }}
          >
            {MenuItems.filter(
              (filterItem) =>
                filterItem.type == UserType.ALL ||
                filterItem.type == user.userInfo.type
            ).map((menuItem, menuIndex) => {
              return (
                <Menu.Item
                  key={menuItem.name}
                  className={
                    currentTab === menuItem.name
                      ? " search-left-title-selected"
                      : "search-left-title "
                  }
                >
                  <Row align="middle">
                    <Col span={3}>
                      <img
                        src={
                          currentTab === menuItem.name
                            ? menuItem.iconSelected
                            : menuItem.icon
                        }
                        className="icon-notify"
                      />
                    </Col>
                    <Col span={20}>
                      <span style={{ marginLeft: "14px" }}>
                        {t(menuItem.name)}
                      </span>
                    </Col>
                    {/* <Col span={1}>{notifyMentionNum > 0 && notifyMentionNum}</Col> */}
                  </Row>
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
        <div className="search-content">
          <div className="middle-content">
            <InfiniteScroll
              className="scroll-content"
              dataLength={scrollDataLength}
              next={handleInfiniteOnLoad.bind(this)}
              hasMore={hasMore}
            >
              {currentTab == MenuTabType.FEED && (
                <ListBasic
                  data={feedList}
                  renderItem={(item, index) => (
                    <div key={index} className="feed-list-item-container">
                      {item.cardType === "FEED" && (
                        <FeedListRow
                          data={item}
                          onRefresh={pageRefresh.bind(this)}
                          keyword={keyword}
                        />
                      )}
                    </div>
                  )}
                  loading={loading}
                />
              )}
              {currentTab == MenuTabType.PRODUCT && (
                <div className="search-product">
                  <List
                    // itemLayout="vertical"
                    // size="large"
                    grid={{ gutter: 0, column: 3 }}
                    loading={loading}
                    dataSource={products}
                    renderItem={(item, index) => (
                      <div key={index}>
                        <ProductCardItem item={item} keyword={keyword} />
                      </div>
                    )}
                  ></List>
                </div>
              )}
              {currentTab == MenuTabType.ARTICLE && (
                <div className="search-product">
                  <List
                    grid={{ gutter: 10, column: 1 }}
                    loading={loading}
                    dataSource={articleList}
                    renderItem={(item, index) => (
                      <List.Item key={index}>
                        <div className={"hover"}>
                          <ArticleListRow data={item} keyword={keyword} />
                        </div>
                      </List.Item>
                    )}
                  ></List>
                </div>
              )}
              {currentTab == MenuTabType.WINERY && (
                <div className="search-product">
                  <List
                    // grid={{ gutter: 10, column: 3 }}
                    loading={loading}
                    dataSource={wineryUsers}
                    renderItem={(item, index) => (
                      <List.Item key={index}>
                        <div className={"hover"} style={{ width: "100%" }}>
                          <UserItemBasic detail={item} keyword={keyword} />
                        </div>
                      </List.Item>
                    )}
                  ></List>
                </div>
              )}
              {currentTab == MenuTabType.NEGOCIANT && (
                <div className="search-product">
                  <List
                    // grid={{ gutter: 10, column: 3 }}
                    loading={loading}
                    dataSource={negociantUsers}
                    renderItem={(item, index) => (
                      <List.Item key={index}>
                        <div className={"hover"} style={{ width: "100%" }}>
                          <UserItemBasic detail={item} keyword={keyword} />
                        </div>
                      </List.Item>
                    )}
                  ></List>
                </div>
              )}
              {currentTab == MenuTabType.COOPERATIVE && (
                <div className="search-product">
                  <List
                    // grid={{ gutter: 10, column: 3 }}
                    loading={loading}
                    dataSource={cooperativeUsers}
                    renderItem={(item, index) => (
                      <List.Item key={index}>
                        <div className={"hover"} style={{ width: "100%" }}>
                          <UserItemBasic detail={item} keyword={keyword} />
                        </div>
                      </List.Item>
                    )}
                  ></List>
                </div>
              )}
              {currentTab == MenuTabType.IMPORTER && (
                <div className="search-product">
                  <List
                    // grid={{ gutter: 10, column: 3 }}
                    loading={loading}
                    dataSource={importerUsers}
                    renderItem={(item, index) => (
                      <List.Item key={index}>
                        <div className={"hover"} style={{ width: "100%" }}>
                          <UserItemBasic detail={item} keyword={keyword} />
                        </div>
                      </List.Item>
                    )}
                  ></List>
                </div>
              )}
              {currentTab == MenuTabType.DISTRIBUTORS && (
                <div className="search-product">
                  <List
                    // grid={{ gutter: 10, column: 3 }}
                    loading={loading}
                    dataSource={distributorUsers}
                    renderItem={(item, index) => (
                      <List.Item key={index}>
                        <div className={"hover"} style={{ width: "100%" }}>
                          <UserItemBasic detail={item} keyword={keyword} />
                        </div>
                      </List.Item>
                    )}
                  ></List>
                </div>
              )}
              {currentTab == MenuTabType.CORPORATEBUSINESS && (
                <div className="search-product">
                  <List
                    // grid={{ gutter: 10, column: 3 }}
                    loading={loading}
                    dataSource={corporaterUsers}
                    renderItem={(item, index) => (
                      <List.Item key={index}>
                        <div className={"hover"} style={{ width: "100%" }}>
                          <UserItemBasic detail={item} keyword={keyword} />
                        </div>
                      </List.Item>
                    )}
                  ></List>
                </div>
              )}
              {currentTab == MenuTabType.MEDIA && (
                <div className="search-product">
                  <List
                    // grid={{ gutter: 10, column: 3 }}
                    loading={loading}
                    dataSource={mediaUsers}
                    renderItem={(item, index) => (
                      <List.Item key={index}>
                        <div className={"hover"} style={{ width: "100%" }}>
                          <UserItemBasic detail={item} keyword={keyword} />
                        </div>
                      </List.Item>
                    )}
                  ></List>
                </div>
              )}
              {currentTab == MenuTabType.OPPORTUNITY && (
                <div className="feed search-feed">
                  <ListBasic
                    data={tenderList}
                    renderItem={(item, index) => (
                      <div key={index} className="feed-list-item-container">
                        <TenderListRow data={item} />
                      </div>
                    )}
                    loading={loading}
                  />
                </div>
              )}
              {currentTab == MenuTabType.EVENT && (
                <div className="feed search-feed">
                  <ListBasic
                    data={eventList}
                    renderItem={(item, index) => (
                      <div key={index} className="feed-list-item-container">
                        <EventListRow data={item} keyword={keyword} />
                      </div>
                    )}
                    loading={loading}
                  />
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
        <RightSider />
      </div>
    </div>
  );
}
