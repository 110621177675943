import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Tabs,
  List,
  Menu,
  Slider,
  BackTop,
  Carousel,
  Select,
  Input,
  Modal,
  Popover,
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../../contexts/UserIdProvider";
import { constants, deepClone } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import alcoholIcon from "../../../resource/icons/discover/alcohol.svg";
import bottleIcon from "../../../resource/icons/discover/bottle.svg";
import bottlesizeIcon from "../../../resource/icons/discover/bottlesize.svg";
import grapeIcon from "../../../resource/icons/discover/grape.svg";
import regionIcon from "../../../resource/icons/discover/region.svg";
import classificationIcon from "../../../resource/icons/discover/classification.svg";
import bannerImage1 from "../../../resource/images/discover/banner_1.jpg";
import bannerImage2 from "../../../resource/images/discover/banner_2.jpg";
import bannerImage3 from "../../../resource/images/discover/banner_3.jpg";
import productIcon from "../../../resource/icons/discover/product.svg";
import stockIcon from "../../../resource/icons/discover/stock.svg";
import priceIcon from "../../../resource/icons/discover/price.svg";
import resetImg from "../../../resource/icons/discover/reset.svg";
import languageIcon from "../../../resource/icons/navbar/language.svg";
import countryIcon from "../../../resource/icons/discover/country.svg";
import searchIcon from "../../../resource/icons/discover/search.svg";
import {
  getCountryAndCityForSelect,
  getCountryForSelect,
  getLocationsByParentId,
} from "../../../api/config";
import {
  getProductCategoryTypeForSelect,
  getProductConfigForSelect,
  getProductConfigNameForSelect,
  getProductGrapes,
  getProductSearch,
  loadProductConfig,
} from "../../../api/product";
import ProductDiscoverItem from "../../shop/product/ProductDiscoverItem";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import {
  localizeTitle,
  ProductSampleValue,
  localizePriceUnit,
} from "../../../constants/constants";
import { searchImporterSearch } from "../../../api/search";
import { CardType } from "../../../constants/cardType.enum";
import "./style/discoverWinery.less";
import { ProductAlcoholSelectList } from "../../../constants/config";
import { RoleLimit } from "../../../constants/roleType.enum";
import { checkLimit } from "../../../api/common";
import { getCompanyVerifyStatus } from "../../../api/user";
import { useHistory } from "react-router-dom";
import { CompanyVerifyStatusEnum } from "../../../constants/companyVerifyStatus.enum";
import { toastInfo } from "../../../api/toast";
import { SysEnvLanguageType } from "../../../constants/env.enum";
import { getActivityList } from "../../../api/activity";
import SalonListRow from "../SalonListRow";
import { browseRecordCreate } from "../../../api/browseRecord";
import { BrowseRecordType } from "../../../constants/browseRecord.enum";

export default function DiscoverImporter() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const [currentUser, setCurrentUser] = useState(user?.userInfo);
  const coId = user.userInfo.companyId;
  const { TabPane } = Tabs;
  const [products, setProducts] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  const pageSize = 10;
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [locationList, setLocationList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stockList, setStockList] = useState([
    {
      label: t("CHINA"),
      selected: true,
    },
    {
      label: t("OVERSEAS"),
      selected: false,
    },
    {
      label: t("ALL"),
      selected: false,
    },
  ]);
  const [subStockList, setSubStockList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [oriRegionList, setOriRegionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [classificationList, setClassificationList] = useState([]);
  const [volumeList, setVolumeList] = useState([]);
  const [orgGrapeList, setOrgGrapeList] = useState([]);
  const [grapeList, setGrapeList] = useState([]);
  const [filterModel, setFilterModel] = useState({});
  const [country, setCountry] = useState();
  const [region, setRegion] = useState();
  const [category, setCategory] = useState();
  const [type, setType] = useState();
  const [stockCountry, setStockCountry] = useState();
  const [stock, setStock] = useState();
  const [grape, setGrape] = useState();
  const [volume, setVolume] = useState();
  const [alcohol, setAlcohol] = useState([0, 100]);
  const [countryName, setCountryName] = useState();
  const [typeName, setTypeName] = useState();
  const [stockName, setStockName] = useState();
  const [regionKeyword, setRegionKeyword] = useState();
  const [grapeKeyword, setGrapeKeyword] = useState();
  const [importerOptions, setImporterOptions] = useState([]);
  const [priceStep, setPriceStep] = useState(80);
  const [priceMax, setPriceMax] = useState(880);
  const [price, setPrice] = useState([0, 880]);
  const [regionContentVisible, setRegionContentVisible] = useState(false);
  const [classificationContentVisible, setClassificationContentVisible] =
    useState(false);
  const [grapeContentVisible, setGrapeContentVisible] = useState(false);
  const [alcoholContentVisible, setAlcoholContentVisible] = useState(false);
  const [volumeContentVisible, setVolumeContentVisible] = useState(false);
  const [searchSuggestList, setSearchSuggestList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState();
  const [priceRangeDividers, setPriceRangeDividers] = useState([]);
  const [productLimit, setProductLimit] = useState(false);
  const [companyVerifyStatus, setCompanyVerifyStatus] = useState(0);
  const [priceVisible, setPriceVisible] = useState(true);

  const [showCountrySelectVisible, setShowCountrySelectVisible] =
    useState(false);
  const [wineSelectVisible, setWineSelectVisible] = useState(false);
  const [stockSelectVisible, setStockSelectVisible] = useState(false);
  const [showStockSelectVisible, setShowStockSelectVisible] = useState(false);
  const [showPriceSelectVisible, setShowPriceSelectVisible] = useState(false);

  const [selectedChanelsValue, setSelectedChanelsValue] = useState([]);

  useEffect(() => {
    initPriceRangeDivider(price[0], price[1]);
  }, [price]);

  useEffect(() => {
    if (currentUser) {
      setProductLimit(
        checkLimit(currentUser, RoleLimit.PRODUCT_SEARCH, history)
      );
      setCompanyVerifyStatus(getCompanyVerifyStatus(currentUser));
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      products.length > 0 &&
      !(
        companyVerifyStatus === CompanyVerifyStatusEnum.VERIFIED && productLimit
      )
    ) {
      setPriceVisible(false);
    }
  }, [productLimit, companyVerifyStatus, currentUser, products]);

  useEffect(() => {
    if (user && user.userInfo) {
      setCurrentUser(user.userInfo);
    }
  }, [user]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setConfigData();
    if (regionList.length > 0) {
      onLoadRegionList(country);
    }
    if (!country) {
      setCountryName(t("COUNTRY"));
    }
    if (!stock) {
      setStockName(t("STOCK_LOCATION"));
    }
    if (!type) {
      setTypeName(t("TYPE"));
    }
    initPriceRange();
  }, [currentLanguage]);

  useEffect(() => {
    if (searchKeyword || grape || volume || alcohol) {
      onProductDiscover(0);
    }
  }, [searchKeyword, grape, volume, alcohol]);

  useEffect(() => {
    if (region) {
      onProductDiscover(0);
    }
  }, [region]);

  useEffect(() => {
    onProductDiscover();
  }, [pageIndex]);

  useEffect(() => {
    if (country) {
      setCountryName(getLocationName(countryList, country));
    }
    // console.log("--------------------------onCountryChange:",countryList);
    let ids = [];
    countryList.map((item) => {
      ids.push(item.value);
    });
    onLoadAllRegionList(ids);
    if (countryList && countryList.length > 0) {
      setSubStockList(
        countryList.filter(
          (filterItem) => filterItem.value == constants.chinaCountryId
        )[0].children
      );
    }
  }, [countryList]);

  useEffect(() => {
    if (country) {
      setCountryName(getLocationName(countryList, country));
      setRegion(null);
      setStock(null);
    } else {
      setCountryName(t("COUNTRY"));
    }
  }, [country]);

  useEffect(() => {
    if (filterModel.country) {
      setCountryName(getLocationName(countryList, filterModel.country));
      setRegion(null);
      setStock(null);
    } else {
      setCountryName(t("COUNTRY"));
    }
  }, [filterModel]);

  // useEffect(() => {
  //   if (stock) {
  //     setStockName(getLocationName(regionList, stock));
  //   }
  // }, [regionList]);

  useEffect(() => {
    // if (stock) {
    //   setStockName(getLocationName(regionList, stock));
    // } else {
    //   setStockName(t("STOCK_LOCATION"));
    // }
    if (!stock) {
      setStockName(t("STOCK_LOCATION"));
    }
  }, [stock]);

  useEffect(() => {
    if (!type) {
      setTypeName(t("TYPE"));
    }
  }, [type]);

  // useEffect(() => {
  //   if (type) {
  //     setTypeName(getLocationName(typeList, type));
  //   }
  // }, [typeList]);

  useEffect(() => {
    if (grapeKeyword) {
      let list = orgGrapeList.filter(
        (item) =>
          item.label.toUpperCase().indexOf(grapeKeyword.toUpperCase()) > -1
      );
      setGrapeList(list);
    } else {
      setGrapeList(orgGrapeList);
    }
  }, [grapeKeyword]);

  useEffect(() => {
    if (regionKeyword) {
      let list = oriRegionList.filter(
        (item) =>
          item.label.toUpperCase().indexOf(regionKeyword.toUpperCase()) > -1
      );
      setRegionList(list);
    } else {
      setRegionList(oriRegionList);
    }
  }, [regionKeyword]);

  const initPriceRange = () => {
    switch (currentLanguage) {
      case SysEnvLanguageType.EN:
        setPriceStep(10);
        setPriceMax(110);
        setPrice([0, 110]);
        break;
      case SysEnvLanguageType.FR:
        setPriceStep(10);
        setPriceMax(110);
        setPrice([0, 110]);
        break;
      case SysEnvLanguageType.ZH:
        setPriceStep(80);
        setPriceMax(880);
        setPrice([0, 880]);
        break;
      default:
        break;
    }
  };

  const getLocationName = (list, val) => {
    list = list.filter((item) => item.value === val);
    return list.length > 0 ? list[0].label : "";
  };

  const setConfigData = async () => {
    await initProductGrapes();
    let configData = await loadProductConfig();
    let { locations, volumes, types, categories, tags, classifications } =
      configData;
    setLocationList(locations);
    setCountryList(getCountryAndCityForSelect(locations, currentLanguage));
    let tempCategoryList = getProductCategoryTypeForSelect(
      categories,
      types,
      currentLanguage
    );
    if (tempCategoryList && tempCategoryList.length > 0) {
      tempCategoryList[0].selected = true;
    }
    setCategoryList(tempCategoryList);
    setTypeList(getProductConfigForSelect(types, currentLanguage));
    setClassificationList(
      getProductConfigForSelect(classifications, currentLanguage)
    );
    setImporterOptions(getProductConfigForSelect(tags, currentLanguage));
    let list = getProductConfigNameForSelect(volumes, currentLanguage);
    list = list.map((item) => {
      return { ...item, label: item.label + "ML" };
    });
    list.push({ label: t("SAMPLES"), value: ProductSampleValue });
    setVolumeList(list);
  };

  const initProductGrapes = async () => {
    let pms = {
      pms: JSON.stringify({
        page: 0,
        per_page: 1000,
      }),
    };
    let result = await getProductGrapes(pms);
    let list = getProductConfigForSelect(result, currentLanguage);
    setGrapeList(list);
    setOrgGrapeList(list);
  };

  const onRegionChange = (val) => {
    setRegion(val === region ? null : val);
  };

  const onGrapeChange = (val) => {
    setGrape(val === grape ? null : val);
  };

  const onAlcoholChange = (val) => {
    console.log("-----onAlcoholChange:", val);
    if (val[0] === alcohol[0] && val[1] === alcohol[1]) {
      setAlcohol([0, 100]);
    } else {
      setAlcohol(val);
    }
  };

  const onVolumeChange = (val) => {
    setVolume(val === volume ? null : val);
  };

  const onProductDiscover = async (index) => {
    setSelectedChanelsValue([
      countryName,
      typeName,
      stockName,
      filterModel?.price?.label,
    ]);

    setLoading(true);
    let page = index === 0 ? 0 : pageIndex;
    let pms = {
      page,
      per_page: pageSize,
      alcoholBegin: alcohol[0],
      alcoholEnd: alcohol[1],
    };
    if (price[0] > 0) {
      pms["priceBegin"] = price[0];
    }
    if (priceStep * 11 > price[1] && price[1] >= price[0]) {
      pms["priceEnd"] = price[1];
    }
    if (searchKeyword) {
      pms["keyword"] = searchKeyword;
    }
    if (country) {
      pms["country"] = country;
    }
    if (stock) {
      pms["stock"] = stock;
    }
    if (region) {
      pms["region"] = region;
    }
    if (grape) {
      pms["grape"] = grape;
    }
    if (type) {
      pms["types"] = type;
    }
    if (volume) {
      pms["volume"] = volume;
    }
    let list = importerOptions
      .filter((item) => item.selected)
      .map((item) => item.value);
    if (list.length > 0) {
      pms["tags"] = list;
    }
    // console.log("-----products discover pms:", pms);
    let result = await getProductSearch(pms);
    console.log("-----products discover:", result);
    if (result && result.length > 0) {
      // result = result.map((item) => item._source);
      result.map((item) => {
        item.cardType = CardType.PRODUCT;
      });
      if (page > 0) {
        let list = deepClone(products);
        result = list.concat(result);
      }
      result = result.filter((item) => item.cardType === CardType.PRODUCT);
      let tempResultList = await loadActivities(result);
      setProducts(tempResultList);
    } else {
      setHasMore(false);
      if (page === 0) {
        setProducts([]);
      }
    }
    setLoading(false);

    let browseRecordPms = {
      coId,
      userId: user.userId,
      busType: BrowseRecordType.SEARCH_PRODUCT,
      content: pms,
    };
    browseRecordCreate(browseRecordPms);
  };

  const onResetDiscover = () => {
    // setCountry();
    setStock();
    setType();
    initPriceRange();
    let list = deepClone(importerOptions);
    list = list.map((item) => {
      item.selected = false;
      return item;
    });
    setImporterOptions(list);

    let tempFilterModel=deepClone(filterModel);
    tempFilterModel={};
    setFilterModel(tempFilterModel);

    let tempSelectedChanelsValue=deepClone(selectedChanelsValue);
    tempSelectedChanelsValue=[];
    setSelectedChanelsValue(tempSelectedChanelsValue);
  };

  const fetchSearchResult = async (val) => {
    setSearchSuggestList([]);
    let pms = { keyword: val };
    let result = await searchImporterSearch(pms);
    if (result && result.length > 0) {
      let list = [];
      result.forEach((item) => {
        let searchItem;
        if (item.cardType === CardType.FEED) {
          searchItem = {
            label: item.card.content,
            value: item.card._id,
            cardtype: item.cardType,
          };
        }
        if (item.cardType === CardType.TENDER) {
          searchItem = {
            label: item.card.title,
            value: item.card._id,
            cardtype: item.cardType,
          };
        }
        if (item.cardType === CardType.PRODUCT) {
          searchItem = {
            label: localizeTitle(currentLanguage, item.card.title),
            value: item.card._id,
            cardtype: item.cardType,
          };
        }
        if (searchItem && searchItem.label) {
          list.push(searchItem);
        }
      });
      setSearchSuggestList(list);
    }
  };

  const onSearchKeyword = (e) => {
    //todo Optimize the logic here to avoid flooding the API.
    setSearchKeyword(e.target.value);
  };

  const loadActivities = async (tempFeedList) => {
    let pms = { from: "1", page: 0, per_page: 60 };
    let resultList = await getActivityList(pms);
    if (resultList && resultList.length > 0) {
      let list = [];
      let salonIndex = 0;
      for (let index = 0; index < tempFeedList.length; index++) {
        list.push(tempFeedList[index]);
        if (salonIndex < resultList.length && salonIndex % 3 == 0) {
          resultList[salonIndex].cardType = CardType.SALON;
          list.push(resultList[salonIndex]);
        }
        salonIndex++;
      }
      return list;
    }
    return tempFeedList;
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  const onLoadRegionList = (coutry_val) => {
    let result = getLocationsByParentId(
      locationList,
      coutry_val,
      currentLanguage
    );
    if (!result || result.length === 0) {
      result.push({
        value: "",
        label: t("ALL"),
      });
    }
    setRegionList(result);
    setOriRegionList(deepClone(result));
  };

  const onLoadAllRegionList = (coutry_vals) => {
    let list = [];
    coutry_vals.map((coutry_val) => {
      let result = getLocationsByParentId(
        locationList,
        coutry_val,
        currentLanguage
      );
      list.push(...result);
    });

    setRegionList(list);
    setOriRegionList(deepClone(list));
  };

  const onCountryChange = (event, itemIndex) => {
    let temp = deepClone(countryList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[itemIndex].selected = true;
    setCountryList(temp);

    const { value } = event;
    // setCountry(value);
    onLoadRegionList(value);

    let tempFilterModel = deepClone(filterModel);
    tempFilterModel.country = value;
    setFilterModel(tempFilterModel);
  };

  const onStockChange = (event) => {
    const { key } = event;
    setStock(key);
  };

  const onCategoryChange = (item) => {
    setCategory(item.value);
    setTypeName(item.label);
    setType(null);
  };

  const onTypeChange = (item, index) => {
    setType(item.value);
    setTypeName(item.label);
    setCategory(null);
    setWineSelectVisible(false);

    let temp = deepClone(typeList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setTypeList(temp);
  };

  const onStockNavigatorClick = (index) => {
    let temp = deepClone(stockList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setStockList(temp);

    let tempSubStockList = deepClone(subStockList);
    tempSubStockList = [];
    if (index == 0 || index == 1) {
      let filterResult = countryList.filter((filterItem) =>
        index == 0
          ? filterItem.value == constants.chinaCountryId
          : filterItem.value != constants.chinaCountryId
      );
      if (filterResult.length > 0) {
        filterResult.map((mapItem) => {
          tempSubStockList.push(...mapItem.children);
        });
      }
    } else {
      countryList.map((countryItem) => {
        tempSubStockList.push(...countryItem.children);
      });
    }
    setSubStockList(tempSubStockList);
  };

  const onCategorykNavigatorClick = (index) => {
    let temp = deepClone(categoryList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setCategoryList(temp);

    let tempTypeList = deepClone(typeList);
    tempTypeList = temp[index].types;
    setTypeList(tempTypeList);
  };

  const onNewStockChange = (item, index) => {
    setStock(item.value);
    setStockName(item.label);
    setStockSelectVisible(false);

    let temp = deepClone(subStockList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setSubStockList(temp);
  };

  const onPriceChange = (price) => {
    setPrice(price);
  };

  const onPriceRangeDividerRangeClick = (index) => {
    let tempPrice = deepClone(price);
    let indexPrice = index * priceStep;
    if (indexPrice < tempPrice[0]) {
      tempPrice[0] = indexPrice;
    } else if (indexPrice > tempPrice[1]) {
      tempPrice[1] = indexPrice + priceStep;
    } else {
      if (
        Math.abs(tempPrice[0] - indexPrice) >
        Math.abs(tempPrice[1] - indexPrice)
      ) {
        tempPrice[1] = indexPrice;
      } else {
        tempPrice[0] = indexPrice;
      }
    }
    setPrice(tempPrice);
  };

  const onMinPriceInputChange = (val) => {
    let tempPrice = deepClone(price);
    onPriceChange([val, tempPrice[1]]);
  };

  const onMaxPriceInputChange = (val) => {
    let tempPrice = deepClone(price);
    onPriceChange([tempPrice[0], val]);
  };

  const initPriceRangeDivider = (start, end) => {
    let temp = [];
    temp.push(
      {
        priceBegin: 0,
        priceEnd: 1,
        selected: false,
      },
      {
        priceBegin: 1,
        priceEnd: 2,
        selected: false,
      },
      {
        priceBegin: 2,
        priceEnd: 3,
        selected: false,
      },
      {
        priceBegin: 3,
        priceEnd: 5,
        selected: false,
      },
      {
        priceBegin: 5,
        priceEnd: 7,
        selected: false,
      },
      {
        priceBegin: 7,
        priceEnd: 12,
        selected: false,
      },
      {
        priceBegin: 12,
        priceEnd: 20,
        selected: false,
      },
      {
        priceBegin: 20,
        priceEnd: 30,
        selected: false,
      },
      {
        priceBegin: 30,
        priceEnd: 50,
        selected: false,
      },
      {
        priceBegin: 50,
        priceEnd: 100,
        selected: false,
      },
      {
        priceBegin: 100,
        priceEnd: 200,
        selected: false,
      },
      {
        priceBegin: 200,
        priceEnd: 9999999,
        selected: false,
      }
    );
    // for (let index = 0; index < 12; index++) {
    //   let indexVal = index * priceStep;
    //   temp.push({
    //     label: indexVal,
    //     selected: false,
    //   });
    // }

    temp.map((mapItem, mapIndex) => {
      if (mapIndex != temp.length - 1) {
        mapItem.label = mapItem.priceBegin + "-" + mapItem.priceEnd + " $";
      } else {
        mapItem.label = t("ABOVE") + " " + mapItem.priceBegin;
      }
    });
    setPriceRangeDividers(temp);
  };

  const sliderPriceFormatter = (value) => {
    if (value < 880) {
      return `${value}`; //${localizePriceUnit(currentLanguage)}${localizePriceUnit(currentLanguage)}
    } else {
      return `${t("UP_TO")}${value}`;
    }
  };

  const getDropDownMenuItem = (val, item, eventClick, index, unitName) => {
    let itemContent = (
      <>
        <div
          className={
            "drop-dot " + (val === item.value ? "drop-dot-selected" : "")
          }
        ></div>
        <span className="drop-item-text">
          {item.label} {unitName}
        </span>
        {/* {val === item.value && (
          <Divider type="vertical" className="item-divider" />
        )} */}
      </>
    );
    if (eventClick) {
      return (
        <div className="drop-item" onClick={eventClick.bind(this)} key={index}>
          {itemContent}
        </div>
      );
    } else {
      return (
        <div className="drop-item" key={index}>
          {itemContent}
        </div>
      );
    }
  };

  const menu_country = (
    <Menu
      onClick={onCountryChange.bind(this)}
      className="discover-dropdown-menu"
    >
      {countryList.map((item) => (
        <Menu.Item key={item.value}>
          {getDropDownMenuItem(country, item)}
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu_stock = () => {
    return regionList.length > 0 ? (
      <Menu
        onClick={onStockChange.bind(this)}
        className="discover-dropdown-menu"
      >
        {regionList.map((item) => (
          <Menu.Item key={item.value}>
            {getDropDownMenuItem(stock, item)}
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <></>
    );
  };

  const onStockMenuClick = () => {
    if (regionList.length === 0) {
      toastInfo(t("PLEASE_SELECT_COUNTRY"));
    }
    setWineSelectVisible(false);
  };

  const onChanelSelect = (index) => {
    let list = deepClone(importerOptions);
    list.map((mapItem) => {
      mapItem.selected = false;
    });
    list[index].selected = true;

    setImporterOptions(list);
  };

  const onPriceRangeChange = (index) => {
    let temp = deepClone(priceRangeDividers);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setPriceRangeDividers(temp);
    let tempFilterModel = deepClone(filterModel);
    tempFilterModel.price = temp[index];
    setFilterModel(tempFilterModel);
  };

  const onSearchKeyDownchange = (e) => {
    if (e.keyCode == 13) {
      fetchSearchResult();
    }
  };

  const onSearchSelectOption = (val, option) => {
    setSearchKeyword(option.label);
  };

  const onBannerClick = (country, city) => {
    onCountryChange({
      key: constants.chinaCountryId,
    });
    onStockChange({
      key: "62217934550bf01bbc5254c0",
    });
    onProductDiscover(0);
  };

  const onCheckUserLimit = () => {
    return checkLimit(currentUser, RoleLimit.PRODUCT_SEARCH, history);
  };

  const onRegionTitleClick = () => {
    if (regionList.length === 0) {
      toastInfo(t("PLEASE_SELECT_COUNTRY"));
      return;
    }
    setRegionContentVisible(!regionContentVisible);
  };

  const onClassificationTitleClick = () => {
    setClassificationContentVisible(!classificationContentVisible);
  };

  const getSearchResultNameWidth = () => {
    if (i18n.language == "en") {
      return "50%";
    }
    if (i18n.language == "fr") {
      return "80%";
    }
    return "12%";
  };

  const menu_country_select = () => {
    return (
      <div className="distribution-channel-select-modal">
        <div className="distribution-channel-panel">
          {countryList.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  "channel-item " +
                  (mapItem.selected ? "channel-item-selected" : "")
                }
                onClick={onCountryChange.bind(this, mapItem, mapIndex)}
              >
                {getDropDownMenuItem(country, mapItem)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const menu_wine_type_select = () => {
    return (
      <div className="distribution-channel-select-modal">
        <div className="wine-drop-container">
          <div className="navigator-panel">
            {categoryList.map((mapItem, mapIndex) => {
              return (
                <div
                  onClick={onCategorykNavigatorClick.bind(this, mapIndex)}
                  className={
                    mapItem.selected
                      ? "navigator-item-selected"
                      : "navigator-item"
                  }
                >
                  {mapItem.label}
                </div>
              );
            })}
          </div>
          <div
            className="distribution-channel-panel"
            style={{ marginTop: "20px" }}
          >
            {typeList.map((mapItem, mapIndex) => {
              return (
                <div
                  className={
                    "channel-item " +
                    (mapItem.selected ? "channel-item-selected" : "")
                  }
                  onClick={onTypeChange.bind(this, mapItem, mapIndex)}
                >
                  {mapItem.label}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const menu_shipping_point_select = () => {
    return (
      <div className="distribution-channel-select-modal">
        <div className="wine-drop-container">
          <div className="navigator-panel">
            {stockList.map((mapItem, mapIndex) => {
              return (
                <div
                  onClick={onStockNavigatorClick.bind(this, mapIndex)}
                  className={
                    mapItem.selected
                      ? "navigator-item-selected"
                      : "navigator-item"
                  }
                >
                  {mapItem.label}
                </div>
              );
            })}
          </div>
          <div
            className="distribution-channel-panel"
            style={{ marginTop: "20px" }}
          >
            {subStockList.map((mapItem, mapIndex) => {
              return (
                <div
                  className={
                    "channel-item " +
                    (mapItem.selected ? "channel-item-selected" : "")
                  }
                  onClick={onNewStockChange.bind(this, mapItem, mapIndex)}
                >
                  {getDropDownMenuItem(stock, mapItem)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const menu_price_select = () => {
    return (
      <div className="distribution-channel-select-modal">
        <div className="distribution-channel-panel">
          {priceRangeDividers.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  "channel-item " +
                  (mapItem.selected ? "channel-item-selected" : "")
                }
                onClick={onPriceRangeChange.bind(this, mapIndex)}
              >
                {mapItem.label}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="discover-page">
      <div
        className="discover-content"
        style={{
          overflowY: "auto",
          height: window.innerHeight - 60 + "px",
        }}
      >
        <div className="banner-container">
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              width: "100%",
            }}
          >
            <div className="filter-container">
              {/* <div>
                <div
                  style={{
                    width: "100%",
                    margin: "10px 0 -10px 0",
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src={resetImg}
                    style={{ width: "30px", height: "30px" }}
                    onClick={onResetDiscover.bind(this)}
                  />
                </div> */}
              <div className="filter-tabs">
                <Dropdown overlay={menu_country_select} trigger={["click"]}>
                  <div
                    className="filter-tab-item"
                    // onClick={() => setShowCountrySelectVisible(true)}
                    style={{ width: "165px" }}
                  >
                    <img src={countryIcon} className="select-icon" />
                    <span
                      className={
                        "select-title "
                      }
                    >
                      {countryName}
                    </span>
                  </div>
                </Dropdown>
                <Divider type="vertical" style={{ height: "auto" }} />
                <Popover
                  placement="bottom"
                  title={null}
                  content={menu_wine_type_select}
                  trigger="click"
                  className="row-panel"
                >
                  <div
                    className="filter-tab-item"
                    style={{ width: "150px" }}
                    // onClick={() => setWineSelectVisible(!wineSelectVisible)}
                  >
                    <img src={productIcon} className="select-icon" />
                    <span
                      className={
                        "select-title "
                      }
                    >
                      {typeName}
                    </span>
                  </div>
                </Popover>
                <Divider type="vertical" style={{ height: "auto" }} />
                <Popover
                  placement="bottom"
                  title={null}
                  content={menu_shipping_point_select}
                  trigger="click"
                  className="row-panel"
                >
                  <div
                    className="filter-tab-item"
                    style={{ width: "145px" }}
                    // onClick={() => setStockSelectVisible(!stockSelectVisible)}
                  >
                    <img src={stockIcon} className="select-icon" />
                    <span
                      className={
                        "select-title " 
                      }
                    >
                      {stockName}
                    </span>
                  </div>
                </Popover>
                <Divider type="vertical" style={{ height: "auto" }} />
                <Dropdown
                  overlay={menu_price_select}
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <div
                    className="filter-tab-item"
                    style={{ width: "140px" }}
                    // onClick={() => setShowPriceSelectVisible(true)}
                  >
                    <img src={priceIcon} className="select-icon" />
                    <span
                      className={
                        "select-title "
                      }
                    >
                      {filterModel?.price
                        ? filterModel?.price?.label
                        : t("PRICE")}
                    </span>
                  </div>
                </Dropdown>
                <Divider type="vertical" style={{ height: "auto" }} />
                <div className="filter-tab-item">
                  <Input
                    className="search-keyword-input"
                    placeholder={t("SEARCH_BY_NAME")}
                    // value={title}
                    // onChange={(val) => setTitle(val.target.value)}
                  />
                  <img
                    src={searchIcon}
                    onClick={onProductDiscover.bind(this)}
                    style={{ marginRight: "10px" }}
                  />
                </div>
              </div>
            </div>
            <div className="content-tags-filter">
              {importerOptions.map((mapItem, index) => {
                return (
                  <div
                    key={index}
                    className={
                      "tag-item " +
                      (mapItem.selected ? "tag-item-selected" : "")
                    }
                    onClick={onChanelSelect.bind(this, index)}
                  >
                    {mapItem.label}
                  </div>
                );
              })}
            </div>
            <div
              className="content-right-filter-title"
              style={{
                position: "relative",
                zIndex: "99",
                height: "54px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  padding: "10px 80px",
                  boxShadow: "0 14px 80px rgba(34, 35, 58, 0.2)",
                  borderRadius: "0px 0px 10px 10px",
                  color: "#FFFFFF",
                  justifyContent:'space-between'
                }}
              >
                <span>
                  <span
                    style={{
                      marginRight: "20px",
                      fontSize: "18px",
                      fontWeight: 400,
                      width: getSearchResultNameWidth(),
                    }}
                  >
                    {t("SEARCHING_RESULT_FROM")} :{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {selectedChanelsValue.length > 0 &&
                      selectedChanelsValue.map((item, index) => (
                        <span
                          key={index}
                          style={{
                            marginRight: "20px",
                          }}
                        >
                          {t(item)}
                        </span>
                      ))}
                    {country && (
                      <span
                        style={{
                          marginRight: "20px",
                        }}
                      >
                        {countryName}
                      </span>
                    )}
                    {region && (
                      <span
                        style={{
                          marginRight: "20px",
                        }}
                      >
                        {stockName}
                      </span>
                    )}
                  </span>
                </span>
                <img src={resetImg} onClick={onResetDiscover.bind(this)} style={{cursor:'pointer'}}/>
              </div>
            </div>
            {/* <Button
                className="filter-button"
                onClick={onProductDiscover.bind(this, 0)}
              >
                {t("DISCOVER")}
              </Button> */}
            {/* <Button type="text"
              className="reset-button"
              onClick={onResetDiscover.bind(this)}
            >
              {t('RESET')}
            </Button> */}
            {/* </div> */}
          </div>
          <Carousel className="banner-pannel" autoplay dots={false}>
            <div onClick={onBannerClick.bind(this, "China", "Beijing")}>
              <img src={bannerImage1} className="banner-image-item" />
              <div className="banner_item_title">
                {t("BEIJING")} - {t("CHINA")}{" "}
              </div>
            </div>
            <div onClick={onBannerClick.bind(this, "China", "Beijing")}>
              <img src={bannerImage2} className="banner-image-item" />
              <div className="banner_item_title">
                {t("BEIJING")} - {t("CHINA")}{" "}
              </div>
            </div>
            <div onClick={onBannerClick.bind(this, "China", "Beijing")}>
              <img src={bannerImage3} className="banner-image-item" />
              <div className="banner_item_title">
                {t("BEIJING")} - {t("CHINA")}{" "}
              </div>
            </div>
          </Carousel>
        </div>
        <div className="content-container">
          <div className="content-product-right-container">
            <InfiniteScroll
              className="scroll-content"
              dataLength={products.length}
              next={handleInfiniteOnLoad.bind(this)}
              hasMore={hasMore}
            >
              <List
                itemLayout="vertical"
                size="large"
                dataSource={products}
                renderItem={(item) => (
                  <div className="product-list-item-container">
                    {item.cardType == CardType.PRODUCT && (
                      <ProductDiscoverItem
                        item={item}
                        onCheckLimit={onCheckUserLimit.bind(this)}
                        priceVisible={priceVisible}
                      />
                    )}
                    {item.cardType == CardType.SALON && (
                      <SalonListRow data={item} />
                    )}
                  </div>
                )}
                loading={loading}
              ></List>
            </InfiniteScroll>
          </div>
          <div className="content-left-container">
            {/* <div className="search-container">
              <span className="search-title">{t("SEARCH")}</span>
              <div className="input-pannel">
                <Input
                  bordered={false}
                  className="input-control"
                  onKeyDown={onSearchKeyword.bind(this)}
                />
                <Divider type="vertical" style={{ height: "50%" }} />
              </div>
            </div> */}
            <div className="type-select-container">
              <div
                className="type-title-pannel"
                onClick={onRegionTitleClick.bind(this)}
              >
                <div className="type-title">
                  <img src={regionIcon} className="title-icon" />
                  <span>{t("REGION")}</span>
                </div>
                {regionList.length === 0 && (
                  <DownOutlined className="flag-icon" />
                )}
                {regionList.length > 0 && !regionContentVisible && (
                  <DownOutlined
                    className="flag-icon"
                    onClick={() => setRegionContentVisible(true)}
                  />
                )}
                {regionContentVisible && (
                  <UpOutlined
                    className="flag-icon"
                    onClick={() => setRegionContentVisible(false)}
                  />
                )}
              </div>
              {regionContentVisible && (
                <div className="type-content-pannel discover-dropdown-menu">
                  <div className="type-search-pannel">
                    <Input
                      bordered={false}
                      placeholder={t("SEARCH")}
                      value={regionKeyword}
                      onChange={(e) => setRegionKeyword(e.target.value)}
                    />
                  </div>
                  {regionList.map((item, index) =>
                    getDropDownMenuItem(
                      region,
                      item,
                      onRegionChange.bind(this, item.value),
                      index
                    )
                  )}
                </div>
              )}
            </div>
            <div className="type-select-container">
              <div
                className="type-title-pannel"
                onClick={onClassificationTitleClick.bind(this)}
              >
                <div className="type-title">
                  <img src={classificationIcon} className="title-icon" />
                  <span>{t("CLASSIFICATIONS")}</span>
                </div>
                {classificationList.length === 0 && (
                  <DownOutlined className="flag-icon" />
                )}
                {classificationList.length > 0 &&
                  !classificationContentVisible && (
                    <DownOutlined
                      className="flag-icon"
                      onClick={() => onClassificationTitleClick(true)}
                    />
                  )}
                {classificationContentVisible && (
                  <UpOutlined
                    className="flag-icon"
                    onClick={() => onClassificationTitleClick(false)}
                  />
                )}
              </div>
              {classificationContentVisible && (
                <div className="type-content-pannel discover-dropdown-menu">
                  {classificationList.map((item, index) =>
                    getDropDownMenuItem(
                      region,
                      item,
                      onRegionChange.bind(this, item.value),
                      index
                    )
                  )}
                </div>
              )}
            </div>
            <div className="type-select-container">
              <div
                className="type-title-pannel"
                onClick={() => setGrapeContentVisible(!grapeContentVisible)}
              >
                <div className="type-title">
                  <img src={grapeIcon} className="title-icon" />
                  <span>{t("GRAPE")}</span>
                </div>
                {!grapeContentVisible && (
                  <DownOutlined
                    className="flag-icon"
                    onClick={() => setGrapeContentVisible(true)}
                  />
                )}
                {grapeContentVisible && (
                  <UpOutlined
                    className="flag-icon"
                    onClick={() => setGrapeContentVisible(false)}
                  />
                )}
              </div>
              {grapeContentVisible && (
                <div className="type-content-pannel discover-dropdown-menu">
                  <div className="type-search-pannel">
                    <Input
                      bordered={false}
                      placeholder={t("SEARCH")}
                      value={grapeKeyword}
                      onChange={(e) => setGrapeKeyword(e.target.value)}
                    />
                  </div>
                  {grapeList.map((item, index) =>
                    getDropDownMenuItem(
                      grape,
                      item,
                      onGrapeChange.bind(this, item.value),
                      index
                    )
                  )}
                </div>
              )}
            </div>
            <div className="type-select-container">
              <div
                className="type-title-pannel"
                onClick={() => setAlcoholContentVisible(!alcoholContentVisible)}
              >
                <div className="type-title">
                  <img src={alcoholIcon} className="title-icon" />
                  <span>{t("ALCOHOL_DEGREE")}</span>
                </div>
                {!alcoholContentVisible && (
                  <DownOutlined
                    className="flag-icon"
                    onClick={() => setAlcoholContentVisible(true)}
                  />
                )}
                {alcoholContentVisible && (
                  <UpOutlined
                    className="flag-icon"
                    onClick={() => setAlcoholContentVisible(false)}
                  />
                )}
              </div>
              {alcoholContentVisible && (
                <div className="type-content-pannel discover-dropdown-menu">
                  {ProductAlcoholSelectList.map((item, index) =>
                    getDropDownMenuItem(
                      alcohol,
                      item,
                      onAlcoholChange.bind(this, item.value),
                      index,
                      t("ALCOHOL")
                    )
                  )}
                </div>
              )}
            </div>
            <div className="type-select-container">
              <div
                className="type-title-pannel"
                onClick={() => setVolumeContentVisible(!volumeContentVisible)}
              >
                <div className="type-title">
                  <img src={bottlesizeIcon} className="title-icon" />
                  <span>{t("BOTTLE_SIZE")}</span>
                </div>
                {!volumeContentVisible && (
                  <DownOutlined
                    className="flag-icon"
                    onClick={() => setVolumeContentVisible(true)}
                  />
                )}
                {volumeContentVisible && (
                  <UpOutlined
                    className="flag-icon"
                    onClick={() => setVolumeContentVisible(false)}
                  />
                )}
              </div>
              {volumeContentVisible && (
                <div className="type-content-pannel discover-dropdown-menu">
                  {volumeList.map((item, index) =>
                    getDropDownMenuItem(
                      volume,
                      item,
                      onVolumeChange.bind(this, item.value),
                      index
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        destroyOnClose={true}
        title={null}
        visible={showCountrySelectVisible}
        footer={null}
        closable={false}
        className={"distribution-channel-select-modal"}
        onCancel={() => setShowCountrySelectVisible(false)}
      >
        <div className="distribution-channel-panel">
          {countryList.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  "channel-item " +
                  (mapItem.selected ? "channel-item-selected" : "")
                }
                onClick={onCountryChange.bind(this, mapItem, mapIndex)}
              >
                {getDropDownMenuItem(country, mapItem)}
              </div>
            );
          })}
        </div>
      </Modal> */}
      {/* <Modal
        destroyOnClose={true}
        title={null}
        visible={wineSelectVisible}
        footer={null}
        closable={false}
        className={"distribution-channel-select-modal"}
        onCancel={() => setWineSelectVisible(false)}
      >
        <div className="wine-drop-container">
          <div className="navigator-panel">
            {categoryList.map((mapItem, mapIndex) => {
              return (
                <div
                  onClick={onCategorykNavigatorClick.bind(this, mapIndex)}
                  className={
                    mapItem.selected
                      ? "navigator-item-selected"
                      : "navigator-item"
                  }
                >
                  {mapItem.label}
                </div>
              );
            })}
          </div>
          <div
            className="distribution-channel-panel"
            style={{ marginTop: "20px" }}
          >
            {typeList.map((mapItem, mapIndex) => {
              return (
                <div
                  className={
                    "channel-item " +
                    (mapItem.selected ? "channel-item-selected" : "")
                  }
                  onClick={onTypeChange.bind(this, mapItem, mapIndex)}
                >
                  {mapItem.label}
                </div>
              );
            })}
          </div>
        </div>
      </Modal> */}
      {/* <Modal
        destroyOnClose={true}
        title={null}
        visible={stockSelectVisible}
        footer={null}
        closable={false}
        className={"distribution-channel-select-modal"}
        onCancel={() => setStockSelectVisible(false)}
      >
        <div className="wine-drop-container">
          <div className="navigator-panel">
            {stockList.map((mapItem, mapIndex) => {
              return (
                <div
                  onClick={onStockNavigatorClick.bind(this, mapIndex)}
                  className={
                    mapItem.selected
                      ? "navigator-item-selected"
                      : "navigator-item"
                  }
                >
                  {mapItem.label}
                </div>
              );
            })}
          </div>
          <div
            className="distribution-channel-panel"
            style={{ marginTop: "20px" }}
          >
            {subStockList.map((mapItem, mapIndex) => {
              return (
                <div
                  className={
                    "channel-item " +
                    (mapItem.selected ? "channel-item-selected" : "")
                  }
                  onClick={onNewStockChange.bind(this, mapItem, mapIndex)}
                >
                  {getDropDownMenuItem(stock, mapItem)}
                </div>
              );
            })}
          </div>
        </div>
      </Modal> */}
      {/* <Modal
        destroyOnClose={true}
        title={null}
        visible={showStockSelectVisible}
        footer={null}
        closable={false}
        className={"distribution-channel-select-modal"}
        onCancel={() => setShowPriceSelectVisible(false)}
      >
        <div className="distribution-channel-panel">
          {priceRangeDividers.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  "channel-item " +
                  (mapItem.selected ? "channel-item-selected" : "")
                }
                onClick={onPriceRangeChange.bind(this, mapIndex)}
              >
                {mapItem.label}
              </div>
            );
          })}
        </div>
      </Modal> */}
      <Modal
        destroyOnClose={true}
        title={null}
        visible={showPriceSelectVisible}
        footer={null}
        closable={false}
        className={"distribution-channel-select-modal"}
        onCancel={() => setShowPriceSelectVisible(false)}
      >
        <div className="distribution-channel-panel">
          {priceRangeDividers.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  "channel-item " +
                  (mapItem.selected ? "channel-item-selected" : "")
                }
                onClick={onPriceRangeChange.bind(this, mapIndex)}
              >
                {mapItem.label}
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
}
