import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../../core-digital-template/components/Card";
import Icon from "../../../../core-digital-template/components/Icon";
import TextInput from "../../../../core-digital-template/components/TextInput";
import Editor from "../../../../core-digital-template/components/Editor";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../../core-digital-template/components/Dropdown";
import {
  getProductConfigForSelect,
  loadProductConfig,
} from "../../../../api/product";
import { ProductConfig } from "../../../../constants/constants";
import { getCountryForSelect } from "../../../../api/config";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { constants, deepClone } from "../../../../utils/utils";
import { DatePicker } from "antd";
import moment from "moment";
import { chinaCityList } from "../../../../constants/area-data-min";
import AddressSelect from "../../../../components/common/location/addressSelect/AddressSelect";

export default function NameAndDescription(props) {
  const { className, onChange, dataSource, onBack } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [formData, setFormData] = useState({});

  const [content, setContent] = useState("");

  const [categories, setCategories] = useState([]);

  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  const [inChina, setInChina] = useState(true);

  useEffect(() => {
    // if (dataSource) {
    //   let temp = deepClone(formData);
    //   temp = dataSource;
    //   console.log("temp:", temp);
    //   setFormData(temp);
    //   setContent(temp?.content);
    // }
  }, [dataSource]);

  useEffect(() => {
    setConfigData();
  }, [currentLanguage]);

  useEffect(() => {
    onChange && onChange(formData);
  }, [formData]);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations, categories } = configData;

    setCountries(getCountryForSelect(locations, i18n.language,user.userInfo.type));

    let tempProvinces = deepClone(provinces);
    tempProvinces = deepClone(chinaCityList);
    setProvinces(tempProvinces);

    loadSelectData(categories, ProductConfig.CATEGORIES);
  };

  const loadSelectData = (data, flag) => {
    let result = getProductConfigForSelect(data, currentLanguage);
    if (result && result.length > 0) {
      let defaultValue = result[0].value;
      switch (flag) {
        case ProductConfig.CATEGORIES:
          setCategories(result);
          break;
        default:
          break;
      }
    }
  };

  const setFormDataValue = (name, value) => {
    let temp = deepClone(formData);
    temp[name] = value;
    setFormData(temp);
  };

  const onCountryChange = (val) => {
    console.log("onCountryChange", val);
    setFormDataValue("country", val);
    setInChina(val == constants.chinaCountryId);
  };

  const onProvinceChange = (val) => {
    setFormDataValue("province", val);

    let selectedProvinces = chinaCityList.filter(
      (filterItem) => filterItem.value == val
    );
    if (selectedProvinces && selectedProvinces.length > 0) {
      let tempCities = deepClone(cities);
      tempCities = deepClone(selectedProvinces[0].children);
      setCities(tempCities);
    }
  };

  const onCityChange = (val) => {
    setFormDataValue("city", val);
  };

  const onAddressChange = (val) => {
    setFormDataValue("address", val);
  };

  return (
    <Card
      className={cn(styles.card, className, styles.container)}
      title={t("CREATE_EVENT")}
      classTitle="title-blue"
      head={
        <Link
          className={cn("button-stroke button-small", styles.button)}
          onClick={() => onBack && onBack()}
        >
          <Icon name="arrow-left" size="24" />
          <span>{t("BACK")}</span>
        </Link>
      }
    >
      <TextInput
        className={styles.field}
        label={t("EVENT_TITLE")}
        name="title"
        type="text"
        tooltip="Maximum 100 characters. No HTML or emoji allowed"
        required
        onChange={setFormDataValue.bind(this, "title")}
        value={formData?.title}
      />
      <DatePicker
        value={formData?.eventDate && moment(formData?.eventDate, "YYYY-MM-DD")}
        className={styles.datePicker}
        onChange={(e, val) => {
          console.log("DatePicker:", val);
          setFormDataValue("eventDate", val);
        }}
      />
      <div className={styles.dropdown_field}>
        <Dropdown
          label={t("CATEGORY")}
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          value={formData.category}
          setValue={setFormDataValue.bind(this, "category")}
          options={categories}
        />
      </div>
      <div className={styles.dropdown_field}>
        <Dropdown
          className={styles.dropdown_field}
          label={t("COUNTRY")}
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          value={formData?.country}
          setValue={onCountryChange.bind(this)}
          options={countries}
        />
      </div>
      {inChina && (
        <div className={styles.dropdown_field}>
          <Dropdown
            className={styles.dropdown_field}
            label={t("PROVINCE")}
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            value={formData?.province}
            setValue={onProvinceChange.bind(this)}
            options={provinces}
          />
        </div>
      )}
      {inChina && (
        <div className={styles.dropdown_field}>
          <Dropdown
            className={styles.dropdown_field}
            label={t("CITY")}
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            value={formData?.city}
            setValue={onCityChange.bind(this)}
            options={cities}
          />
        </div>
      )}
      <div className={styles.dropdown_field}>
        <div className="form-panel">
          <div className="form-label">
            {t("ADDRESS")}
            {/* {required_tip} */}
          </div>
          <div
            style={{
              border: inChina ? "0px" : "0px",
              marginTop: "10px",
            }}
          >
            <AddressSelect
              country={formData?.country}
              onChange={onAddressChange.bind(this)}
            />
          </div>
        </div>
      </div>
      <div className={styles.dropdown_field}>
        <Editor
          state={content}
          onChange={setFormDataValue.bind(this, "content")}
          classEditor={styles.editor}
          label={t("CONTENT")}
          tooltip={t("CONTENT")}
        />
      </div>
    </Card>
  );
}
