export const RoleLimit = {
  FEED: "FEED",
  CONTACTS: "CONTACTS",
  CREATE_GROUP: "CREATE_GROUP",
  MY_CUSTOMERS: "MY_CUSTOMERS",
  ALL_CUSTOMERS: "ALL_CUSTOMERS",
  EDIT_COMPANY: "EDIT_COMPANY",
  PRODUCT_MANAGEMENT: "PRODUCT_MANAGEMENT",
  JOIN_ACTIVITY: "JOIN_ACTIVITY",
  AFTER_SALES: "AFTER_SALES",
  PURCHASE_DEMAND: "PURCHASE_DEMAND",
  MY_SUPPLIERS:"MY_SUPPLIERS",
  ALL_SUPPLIERS:"ALL_SUPPLIERS",
  PRODUCT_SEARCH:"PRODUCT_SEARCH",
  PRODUCT_ORDER:"PRODUCT_ORDER"
};
