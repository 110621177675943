import React, { useState, useEffect } from "react";
import { Avatar, Image, Row, Col, Modal } from "antd";
import { useHistory } from "react-router-dom";
import VideoBasic from "../../common/video/VideoBasic";
import { deepClone, goToProfilePage } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { getTimeBefore } from "../../../utils/date";
import { userDetail } from "../../../api/user";
import FeedDetail from "../detail/FeedDetail";
import closeIcon from "../../../resource/icons/feed/close.svg";
import "./style/feedForwardChild.less";
import { localizeImg } from "../../../constants/constants";
import { getLocationNameById } from "../../../api/config";
import AvatarBasic from "../../common/avatar/AvatarBasic";
import ForwardUserInfo from "./forwardUserInfo/ForwardUserInfo";
import FeedImagePanel from "../../shared/FeedImagePanel";

export default function FeedForwardChild(props) {
  const history = useHistory();
  const { data } = props;
  const { _id, content, images, videos, createdAt, mentions } = data;

  const [commentCountAmount, setCommentCountAmount] = useState(0);
  const [showDetail, setShowDetail] = useState(false);
  const [refData, setRefData] = useState(data);
  const [contentEllipsis, setContentEllipsis] = useState(false);
  const [contentHide, setContentHide] = useState(true);
  const [userInfo, setUserInfo] = useState({
    picture: null,
    nickName: "loading",
    type: "SUPPLIER",
    _id: "",
  });

  const { t, i18n } = useTranslation();

  useEffect(() => {
    let isUnmounted = false;
    const initData = async () => {
      let userData = await userDetail(data.userId);
      if (!isUnmounted) {
        let countryName = await getLocationNameById(
          userData.country,
          i18n.language
        );
        userData.countryName = countryName ? countryName : t(userData.country);
        setUserInfo(userData);
        let _refData = deepClone(refData);
        _refData.userInfo = [userData];
        setRefData(_refData);
      }
    };
    initData();

    let element = document.getElementById(_id);
    let clientHeight = element.clientHeight;
    let scrollHeight = element.scrollHeight;
    if (clientHeight < scrollHeight) {
      setContentEllipsis(true);
    }
    return () => {
      isUnmounted = true;
    };
  }, []);

  const onCompanyHome = () => {
    goToProfilePage(userInfo, history);
  };

  const getCloseIcon = () => {
    return <img src={closeIcon} className="modal-close-icon" />;
  };

  const onLike = async () => {};

  const onComment = () => {
    setCommentCountAmount(commentCountAmount + 1);
  };

  const onDetail = () => {
    setShowDetail(true);
  };

  const contentFilter = (value) => {
    if (value && mentions && mentions.length > 0) {
      mentions.map((item) => {
        item = `<span class="mentions-label">${item}</span>`;
        value = value.replace(item, "");
      });
    }
    return value;
  };

  return (
    <div className="forward-feed-row-item feed-forward-child">
      {images.length == 0 && (
        <div
          onClick={onCompanyHome.bind(this)}
          className="feed-forward-userinfo"
        >
          <ForwardUserInfo
            userId={data.userId}
            createdAt={createdAt}
          ></ForwardUserInfo>
        </div>
      )}
      <div
        className="forward-feed-forward-content"
        onClick={onDetail.bind(this)}
        style={{ marginLeft: images.length > 0 ? "20px" : "48px" }}
      >
        <div
          id={_id}
          className={
            contentHide
              ? "forward-feed-row-content-ellipsis"
              : "forward-feed-row-content"
          }
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        {images && images.length > 0 && (
          <FeedImagePanel
            images={images}
            onImageClick={() => setShowDetail(true)}
          ></FeedImagePanel>
        )}
        {videos && videos.length > 0 && (
          <VideoBasic url={videos[0]} width={'100%'} height={'100%'} />
        )}
      </div>
      {images.length > 0 && (
        <div
          onClick={onCompanyHome.bind(this)}
          className="feed-forward-userinfo"
          style={{ marginTop: "10px" }}
        >
          <ForwardUserInfo
            userId={data.userId}
            createdAt={createdAt}
          ></ForwardUserInfo>
        </div>
      )}
      <Modal
        destroyOnClose={true}
        title={null}
        visible={showDetail}
        footer={null}
        className={
          images && images.length > 0
            ? "feed-detail-modal-images"
            : "feed-detail-modal"
        }
        closeIcon={getCloseIcon()}
        onCancel={() => setShowDetail(false)}
      >
        <FeedDetail
          data={refData}
          onLike={onLike.bind(this)}
          onComment={onComment.bind(this)}
        />
      </Modal>
    </div>
  );
}
