import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    language: 'en',
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        changeLanguage: (state) => {

            state.language = 'fr'

            return state;
        },    },
    extraReducers:{
     }
})

export const { changeLanguage } = settingsSlice.actions;
export const settingsSelector = (state:any) => state.settings;
