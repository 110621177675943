import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import AMapLoader from "@amap/amap-jsapi-loader";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { constants } from "../../../utils/utils";

export default function CompanyMap(props) {
  const { companyDesc } = props;
  const [useAmap, setUseAMap] = useState(true);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom, setZoom] = useState(11);

  useEffect(() => {
    
    if (!companyDesc || !companyDesc?.location?.address) {
      return;
    }

    const address = companyDesc?.location?.address?.name;
    let lnglat = companyDesc?.location?.address?.location?.split(",");
    console.log("company address:", address);
    console.log("company lnglat:", lnglat);

    if (!lnglat || lnglat.length == 0) {
      lnglat = ["116.38", "39.9"];
    }

    if (map.current) return;

    mapboxgl.accessToken = constants.mapBoxAccessToken;
    
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: lnglat,
      zoom: zoom,
    });
    map.current.addControl(
      new mapboxgl.Marker({
        draggable: false,
        color: "#C4321D",
      })
      .setLngLat(lnglat)
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }).setHTML(
          "<h3>" + address + "</h3>"
        )
      )
      .addTo(map.current)
    );
  });

  return (
    <div ref={mapContainer} className="map-container" />
  )

};

// import React, { useEffect, useState } from "react";
// import { Map, MapApiLoaderHOC, BMapGL } from 'react-bmapgl'
// import { Marker, NavigationControl, InfoWindow, ZoomControl } from 'react-bmapgl';

// class CompanyMap extends React.Component {
//   render() {
//     return (
//       <Map 
//         center={{lat: 44.84, lng: -0.58}} 
//         zoom={9}
//         >
//         <Marker position={{lng: 44.84, lat: -0.58}} />
//         {/* <NavigationControl />  */}
//         <ZoomControl />
//         {/* <InfoWindow position={{lng: 116.402544, lat: 39.928216}} text="内容" title="标题"/> */}
//       </Map>
//     )
//   }
// }

// export default MapApiLoaderHOC({ak: 'SMzzdbk2dljTyGoRdgxvDmkeyOsyWUG2'})(CompanyMap)


