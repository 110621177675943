import "./style/backIcon.less";
import backIcon from "../../../resource/icons/common/back.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BackIcon(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const onBack = () => {
    history.go(-1);
  }

  return (
    <div className="back-panel-container" onClick={onBack.bind(this)}>
      <img src={backIcon} className="back-icon" />
      {t("PREVIOUS")}
    </div>
  );
}
