import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { deepClone } from "../../../../utils/utils";
import "./style/privacyPolicy.less";
import policyTopImg from "../../../../resource/images/dashboard/policy/policy_top.png";
import privacyContentImg from "../../../../resource/images/dashboard/policy/privacy_content.png";

export default function PrivacyPolicy() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  useEffect(() => {}, []);

  const [privacyContent, setPrivayContent] = useState(`<h1>Privacy Policy</h1>
  Before you scroll, read this
  It's really hard to make everyone happy with a Privacy Policy. Most people who use Vinbip want something short and easy to understand. While we wish we could fit everything you need to know into a Vinbip, our regulators ask us to meet our legal obligations by describing them all in a lot of detail.
  With that in mind, we've written our Privacy Policy as simply as possible to empower you to make informed decisions when you use Vinbip by making sure you understand and have control over the information we collect, how it's used, and when it's shared.
  So if you skip reading every word of the Privacy Policy, at least know this:
  <li> Vinbip is a public platform</li>
  <li> We collect some data about you</li>
  <li> Affiliate services may have their own policies</li>
  <li> We use your data to make Vinbip better</li>
  <li> You can control your experience</li>
  <li> If you have questions about how we use data, just ask</li>`);

  return (
    <div className="common-page">
      <div className="common-management privacy-policy-panel">
        <div
          style={{ marginBottom: "24px" }}
          dangerouslySetInnerHTML={{ __html: privacyContent }}
        ></div>
      </div>
    </div>
  );
}
