import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Modal, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { getSendSamples, removeSendSamples } from "../../../api/orders";
import { Link } from "react-router-dom";
import "../order/style/orders.less";
import { TableSourceMaxCount } from "../../../constants/constants";
import SendSampleCreate from "./SendSampleCreate";
import { dateFormat } from "../../../utils/date";

export default function SendSamples() {
  const { t } = useTranslation();
  const { isWinery, user } = useUserId();
  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const pageSize = 20;
  const [createModalVisible, setcreateModalVisible] = useState(false);
  const [detail, setDetail] = useState();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    loadData();
    let list = [
      {
        title: t("PRODUCT"),
        dataIndex: "coId",
        key: "coId",
        width: "20%",
        render: (val, item) => {
          return item.products[0].title;
        },
      },
    ];
    if (isWinery) {
      list.push({
        title: t("CONTACT"),
        dataIndex: "userId",
        key: "userId",
        width: "20%",
        render: (val, item) => {
          return item.userInfo[0].nickName;
        },
      });
    } else {
      list.push({
        title: t("CONTACT"),
        dataIndex: "productCoId",
        key: "productCoId",
        width: "20%",
        render: (val, item) => {
          return item.companyWinery[0].companyName;
        },
      });
    }
    list = list.concat([
      { title: t("QUANTITY"), dataIndex: "count", key: "count", width: "20%" },
      {
        title: t("CREATE_AT"),
        dataIndex: "createdAt",
        key: "createdAt",
        width: "20%",
        render: (val) => {
          return dateFormat(val);
        },
      },
      {
        title: t("ACTION"),
        dataIndex: "",
        key: "y",
        render: (item) => {
          return (
            <Space size={6}>
              <Button
                type="link"
                className={"button-primary"}
                onClick={onUpdate.bind(this, item)}
              >
                {t("EDIT")}
              </Button>
              <Button
                type="link"
                className={"button-primary"}
                onClick={onDelete.bind(this, item._id)}
              >
                {t("DELETE")}
              </Button>
            </Space>
          );
        },
      },
    ]);
    setColumns(list);
  }, []);

  const loadData = async () => {
    let pms = { page: 0, per_page: TableSourceMaxCount };
    if (isWinery) {
      pms.coId = user.userInfo.companyId;
    } else {
      pms.userId = user.userId;
    }
    let result = await getSendSamples(pms);
    console.log("-------orders samples:", pms, result);
    if (result) {
      result = result
        .filter((item) => item.products && item.products.length > 0)
        .map((item) => {
          return { ...item, key: item._id };
        });
      setOrderList(result);
    }
    setLoading(false);
  };

  const onDelete = async (id) => {
    let pms = { id };
    await removeSendSamples(pms);
    await loadData();
  };

  const onUpdate = (item) => {
    setDetail(item);
    setcreateModalVisible(true);
  };

  const onFinished = () => {
    setcreateModalVisible(false);
    loadData();
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("SEND_SAMPLE")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="company-orders-container common-management ">
        <div className="orders-title common-management-title">
          {t("SEND_SAMPLE")}
          <Button
            onClick={() => setcreateModalVisible(true)}
            className="create-button"
          >
            {t("CREATE")}
          </Button>
        </div>
        <div style={{ marginTop: "10px" }}></div>
        <div className="common-management-panel">
          <Table
            columns={columns}
            dataSource={orderList}
            pagination={orderList.length > pageSize}
            style={{ marginTop: "10px" }}
            loading={loading}
          />
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={createModalVisible}
        footer={null}
        className="address-create-modal"
        onCancel={() => setcreateModalVisible(false)}
      >
        <SendSampleCreate
          detail={detail}
          onComplete={onFinished.bind(this)}
          onClose={() => setcreateModalVisible(false)}
        />
      </Modal>
    </div>
  );
}
