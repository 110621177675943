import { getWxPay, postWxPay } from "./apiWxPay";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";
import { actionRequest } from "./api";
import { WxMchId, WxPayAppId, SysServiceUrl } from "../constants/constants";

/**
 * @description Get the carts
 * @param id
 */
export async function findOrder(id: string) {
    let data: any = await getWxPay('out-trade-no/' + id);
    return data;
}

/**
 * @description native pay
 * @param pms
 */
export async function nativePay1(pms: any) {
    let data: any = await postWxPay('native', pms);
    return data;
}

/**
 * @description native pay
 * @param pms
 */
export async function nativePay(pms: any) {
    let data: any = await actionRequest(RequestType.POST, apiPath.wxPay, pms);
    return data;
}

/**
 * @description native pay
 * @param order
 */
export async function getNativePayCode(order: any) {
    let result;
    if (order) {
        let { _id, } = order;
        let pms = {
            "appid": WxPayAppId,
            "mchid": WxMchId,
            "out_trade_no": _id,
            "description": "VinnexWork",
            "notify_url": "https://dev-api.vinexwork.com/Notify/wxpaynotify",
            //"notify_url": SysServiceUrl + "Notify/wxpaynotify",
            "amount": {
                // "total": payPrice,
                "total": 1,
                "currency": "CNY"
            }
        };
        result = await nativePay(pms);
    }
    return result;
}

export async function basicWxPayCode(order: any) {
    let result;
    if (order) {
        let { _id, } = order;
        let pms = {
            "mchid": WxMchId,
            "out_trade_no": _id,
            "appid": WxPayAppId,
            "description": "VinnexWork",
            "notify_url": SysServiceUrl + "Notify/wxpaynotify",
            "amount": {
                // "total": payPrice,
                "total": 0.01,
                "currency": "CNY"
            }
        };
        result = await nativePay(pms);
    }
    return result;
}
