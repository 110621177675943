import { Button, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { localizeImg } from "../../constants/constants";
import { goToProfilePage } from "../../utils/utils";
import AvatarBasic from "../common/avatar/AvatarBasic";
import "./style/contactItem.less";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { loadProductConfig } from "../../api/product";
import { getCountryNameById } from "../../api/config";

export default function ContactItem(props) {
  const { detail, onRemoveFriend, onBlockFriend, onReportingFriend,color } = props;
  let { id, name, signature, picture, type, country } = detail;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [locations, setLocations] = useState();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    initConfigData();
  }, []);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const onMenuItemClick = (item) => {
    switch (item.key) {
      case "1":
        onReportingFriend && onReportingFriend(id);
        break;
      case "2":
        onRemoveFriend && onRemoveFriend(id);
        break;
      case "3":
        onBlockFriend && onBlockFriend(id);
        break;
      default:
        break;
    }
  };

  const menu = (
    <Menu onClick={onMenuItemClick.bind(this)}>
      <Menu.Item key="1">
        <span className="action-drop-down">{t("REPORTING_CONTACT")}</span>
      </Menu.Item>
      <Menu.Item key="2">
        <span className="action-drop-down">{t("DELETE_CONTACT")}</span>
      </Menu.Item>
      <Menu.Item key="3">
        <span className="action-drop-down">{t("BLOCK_CONTACT")}</span>
      </Menu.Item>
    </Menu>
  );

  const onCompanyHome = () => {
    console.log("------avatar click:", detail);
    goToProfilePage({ ...detail }, history);
  };

  return (
    <div className="contact-item">
      <AvatarBasic
        cname="avatar cursor-pointer"
        picture={localizeImg(picture)}
        displayName={name}
        onClick={onCompanyHome.bind(this)}
      />
      <div className="content">
        <div className="content-name">
          <div className="name">{name}</div>
          {/* <div className="desc">{signature}</div> */}
          <div className={"desc " + (color && "color")}>
            {t(type?.toUpperCase())}
          </div>
          <div className={"desc " + (color && "color")}>
            {t("LOCATION")} : {getCountryNameById(locations, lang, country)}
          </div>
        </div>
        <div className="content-action">
          <Button className="button-primary">
            <Link to={"/imchat-deprecated"}>{t("SEND_MESSAGE")}</Link>
          </Button>
          <Dropdown overlay={menu} trigger={["click"]}>
            <div className="more">...</div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
