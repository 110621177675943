import { Input } from "antd";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import React, { useEffect } from "react";
import { getTimeStamp } from "../../../utils/date";
import { getEditorUploadFileAction } from "../../../api/common";
import { useUserId } from "../../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";

export default function RichTextAreaBasic(props) {
  const { t, i18n } = useTranslation();
  const { TextArea } = Input;
  const { id, value, placeholder, className, onInit } = props;
  const editorID = getTimeStamp() + (id ? id : "");

  const { user } = useUserId();
  const action = getEditorUploadFileAction(user.userId);

  useEffect(() => {
    //let p = document.getElementsByClassName('ck-placeholder');
    //if (p.length > 0) {
    //  p[0].setAttribute('data-placeholder', t(placeholder));
    //}
  }, [i18n.language]);

  useEffect(() => {
    ClassicEditor.create(document.querySelector("#editor" + editorID), {
      toolbar: [
        // 标题 加粗 斜体 撤销 重做 超链接 项目符号列表 项目编号列表
        "heading",
        "|",
        "bold",
        "italic",
        "undo",
        "redo",
        "link",
        "bulletedList",
        "numberedList",
        //
        // 插入图像 更改图片替换文本 图片通栏显示 图片侧边显示
        "|",
        "imageUpload",
        "imageTextAlternative",
        "imageStyle:full",
        "imageStyle:side",
      ],
      ckfinder: {
        uploadUrl: action,
      },
    })
      .then((editor) => {
        const doc = editor.model.document;

        // 远程图片上传
        // 参考 https://github.com/ckeditor/ckeditor5-image/blob/master/src/imageupload/imageuploadediting.js#L78
        editor.model.document.on("change", () => {
          const changes = doc.differ.getChanges();

          for (const entry of changes) {
            if (entry.type === "insert" && entry.name === "upload") {
              const item = entry.position.nodeAfter;

              // Check if the image element still has upload id.
              const uploadId = item.getAttribute("uploadId");
              const uploadStatus = item.getAttribute("uploadStatus");

              if (!uploadId && !uploadStatus) {
                //CatchRemoteImage(editor, item);
              }
            }
          }
        });
        onInit && onInit(editor);
      })
      .catch((error) => {
        console.error("-----create ckeditor error: ", error);
      });
    // ClassicEditor.create(document.querySelector("#editor" + id), {
    //   cloudServices: {
    //     tokenUrl: authorization,
    //     uploadUrl: action
    //   }
    // }).then((editor) => {
    //   onInit && onInit(editor);
    // }).catch((error) => {
    //   console.error(error);
    // });
  }, []);

  return (
    <TextArea
      id={"editor" + editorID}
      value={value}
      placeholder={t(placeholder)}
      maxLength={500}
      bordered={false}
      maxWidth={1000}
      className={"form-textarea " + className}
    />
  );
}
