import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useHistory, useLocation } from "react-router-dom";
import TextInput from "../../core-digital-template/components/TextInput";
import Image from "../../core-digital-template/components/Image";
import VinbipLogo from "../../core-digital-template/components/VinbipLogo";
import { Checkbox, Modal } from "antd";
import { toastError, toastInfo } from "../../api/toast";
import { isEmail, isMobile } from "../../utils/regex";
import {
  getWxAccessToken,
  getWxUserInfo,
  login,
  wxLogin,
} from "../../api/login";
import { setBearerToken } from "../../api/api";
import { getTokenExpireTime } from "../../utils/jwt";
import { useAuth } from "../../contexts/AuthProvider";
import { useUserId } from "../../contexts/UserIdProvider";
import { BrowseType } from "../../constants/browseType.enum";
import { browseRecordCreate } from "../../api/browseRecord";
import { useTranslation } from "react-i18next";
import { Path } from "../../constants/path";
import InputCapture from "../../core-digital-template/components/InputCapture";
import WxLogin from "../../components/login/WxLogin";
import { SysBaseUrl, WxAppId, WxAppSecret } from "../../constants/constants";
import Dropdown from "../../core-digital-template/components/Dropdown";
import { LanguagesKey } from "../../constants/languages.enum";
import { SYS_ENV_LANGUAGE } from "../../constants/env.enum";

const qs = require("query-string");

export default function SignIn() {
  const { createToken } = useAuth();
  const { loadUserInfo } = useUserId();
  const heightWindow = use100vh();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { state, search } = useLocation();

  const [referUrl, setReferUrl] = useState("/");

  const [account, setAccount] = useState();
  const [password, setPassword] = useState("");
  const [capture, setCapture] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);

  const [loginAsEmail, setLoginAsEmail] = useState(true);
  const [wxloginVisible, setWxloginVisible] = useState(false);

  const [isCnDomain, setIsCnDomain] = useState(false);

  const [currentLanguage, setCurrentLanguage] = useState(SYS_ENV_LANGUAGE);

  // 获取当前浏览器的语言
  const userLanguage = navigator.language || navigator.userLanguage;

  const [languages, setLanguages] = useState([
    {
      label: LanguagesKey.ZH,
      value: "zh",
    },
    {
      label: LanguagesKey.EN,
      value: "en",
    },
    {
      label: LanguagesKey.FR,
      value: "fr",
    },
  ]);

  useEffect(() => {
    const domain = window.location.hostname;
    setIsCnDomain(checkIsCnDomain(domain));

    if (state) {
      let { refer, clearToken } = state;
      if (clearToken) {
        localStorage.clear();
      }
      if (refer) {
        setReferUrl(refer);
      }
    }
  }, []);

  useEffect(() => {
    console.log("userLanguage:", userLanguage);
    if (userLanguage.indexOf("zh") >= 0) {
      setCurrentLanguage("zh");
    }
    if (userLanguage.indexOf("en") >= 0) {
      setCurrentLanguage("en");
    }
    if (userLanguage.indexOf("fr") >= 0) {
      setCurrentLanguage("fr");
    }
  }, [userLanguage]);

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
    }
  }, [currentLanguage]);

  useEffect(() => {
    if (search) {
      let searchObj = qs.parse(search);
      let { code } = searchObj;
      if (code) {
        getWxOpenId(code);
      }
    }
    console.log("login search:", search);
  }, [search]);

  const checkIsCnDomain = (domain) => {
    const pattern = /\.cn$/i; // Regular expression to match ".cn" at the end of the domain
    return pattern.test(domain);
  };

  const onEmailChange = (e) => {
    let temp = e;
    console.log(temp);
    setAccount(temp);
  };

  const onPasswordChange = (e) => {
    let temp = e;
    setPassword(temp);
  };

  const onAgreeTermsChange = (e) => {
    console.log("-------onAgreeTermsChange:", e.target.checked);
    setTermsAgreed(e.target.checked);
  };

  const onFinish = async () => {
    if (!termsAgreed) {
      toastError(t("NEED_AGREE_USER_TERMS"));
      return;
    }

    if (!account) {
      toastError(t("ACCOUNT_IS_EMPTY"));
      return;
    }
    if (!password && !capture) {
      toastError(t("PASSWORD_IS_EMPTY"));
      return;
    }
    let pms;
    if (account.indexOf("@") >= 0) {
      let email = account.trim();
      if (!isEmail(email)) {
        toastError(t("INVALID_EMAIL"));
        return;
      }
      pms = { email, password };
    } else {
      let phoneNumber = account.trim();
      // if (!isMobile(account)) {
      //   toastError(t("INVALID_PHONE_NUMBER"));
      //   return;
      // }
      pms = { phoneNumber, password, capture };
    }

    let result = await login(pms);
    if (result) {
      console.log("login res...", result);
      onStoreUser(result);
    } else {
      toastError(t("ACCOUNT_OR_PASSWORD_INCORRECT"));
    }
  };

  /**
   * callback
   * @param {string} result ：server return(包含token和userid)
   */
  const onStoreUser = async (result) => {
    let { access_token, user } = result;
    let userId = user._id;

    await setBearerToken(access_token);

    let expireTime = getTokenExpireTime();
    createToken({ value: access_token, expireTime });
    await loadUserInfo(userId);
    addLoginRecord(userId);
    history.replace(referUrl);
  };

  const addLoginRecord = (userId) => {
    let pms = {
      userId,
      busType: BrowseType.LOGIN,
    };
    browseRecordCreate(pms);
  };

  const getWxOpenId = async (code) => {
    let pms = { appid: WxAppId, appsecret: WxAppSecret, code };
    let result = await getWxAccessToken(pms);
    if (result && result.data) {
      let { openid, access_token } = result.data;
      if (openid && access_token) {
        loadWxUserInfo(access_token, openid);
      }
    }
  };

  const loadWxUserInfo = async (token, openid) => {
    let pms = { token, openid };
    let result = await getWxUserInfo(pms);
    console.log("-------wx userinfo:", result);
    if (result && result.data) {
      let { nickname, headimgurl } = result.data;
      onWxLogin(openid, nickname, headimgurl);
    }
  };

  const onWxLogin = async (openId, nickName, picture) => {
    let pms = { openId };
    let result = await wxLogin(pms);
    if (result) {
      onStoreUser(result);
    } else {
      toastInfo("not regist yet");
      history.replace(Path.signUp, { openId, nickName, picture });
    }
  };

  const onLoginWithWechat = () => {
    if (!isMobile) {
      setWxloginVisible(true);
    } else {
      const redirect_uri = SysBaseUrl + "login";
      let temp_uri = encodeURIComponent(redirect_uri);
      console.log("loginByH5Wechat temp uri:", temp_uri);
      window.location =
        // window.location.href + '?code=xxx'
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx74283f9c72a0c4f5&redirect_uri=" +
        temp_uri +
        "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
    }
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.languageSelector}>
        <Dropdown
          className={styles.dropdown_field}
          tooltip=""
          value={currentLanguage}
          setValue={(e) => setCurrentLanguage(e)}
          options={languages}
        />
      </div>
      <div className={styles.wrapper}>
        <Link to="/">
          <VinbipLogo withText />
        </Link>

        <div className={cn("h4", styles.title)} style={{ marginTop: "50px" }}>
          {t("SIGN_IN")}
        </div>
        <div className={styles.body}>
          <div className={styles.note}>{t("LOGIN_TIPS")}</div>
          <TextInput
            className={styles.field}
            name="email"
            type="email"
            placeholder={loginAsEmail ? t("EMAIL") : t("PHONE_NUMBER")}
            required
            icon="mail"
            onChange={onEmailChange.bind(this)}
          />
          {loginAsEmail && (
            <TextInput
              className={styles.field}
              name="password"
              type="password"
              placeholder={t("PASSWORD")}
              required
              icon="lock"
              onChange={onPasswordChange.bind(this)}
            />
          )}
          {!loginAsEmail && (
            <InputCapture
              icon="lock"
              phoneNumber={account}
              name="capture"
              type="capture"
              placeholder={t("CAPTURE_CODE")}
              onChange={(e) => setCapture(e)}
            />
          )}
          <button
            className={cn("button", styles.button)}
            onClick={onFinish.bind(this)}
          >
            {t("SIGN_IN")}
          </button>
          {/* <div className={styles.note}>
            This site is protected by reCAPTCHA and the Google Privacy Policy.
          </div> */}
          <div className={styles.info}>
            <Checkbox onChange={onAgreeTermsChange.bind(this)}>
              {t("AGREE_TERMS")}
              &nbsp;
              <a target="_blank" href="https://vinbip.com/terms">
                {t("TERMS_SERVICE")}
              </a>
              ,&nbsp;
              <a target="_blank" href="https://vinbip.com/privacy">
                {t("PRIVACY_POLICY")}
              </a>
            </Checkbox>
            <div style={{ marginTop: "20px" }}>
              {t("FORGOT_PASSWORD")}{" "}
              <Link className={styles.link} to={Path.forgotPassword}>
                {t("RESET_YOUR_PASSWORD")}
              </Link>
            </div>
          </div>
          {loginAsEmail && (
            <button
              className={cn("button", styles.subButton)}
              onClick={() => setLoginAsEmail(!loginAsEmail)}
            >
              {t("PHONE_SIGN_IN")}
            </button>
          )}
          {!loginAsEmail && (
            <button
              className={cn("button", styles.subButton)}
              onClick={() => setLoginAsEmail(!loginAsEmail)}
            >
              {t("EMAIL_SIGN_IN")}
            </button>
          )}
          {/* {isCnDomain && ( */}
          <button
            className={cn("button", styles.wechatLoginButton)}
            onClick={onLoginWithWechat.bind(this)}
          >
            {t("WECHAT")} {t("SIGN_IN")}
          </button>
          {/* )} */}
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={wxloginVisible}
        footer={null}
        className="select-friend-modal"
        onCancel={() => setWxloginVisible(false)}
      >
        <WxLogin />
      </Modal>
    </div>
  );
}
