import {configureStore} from "@reduxjs/toolkit";
import chatSlice from "./features/chat.slice";
import {usersSlice} from "./features/users.slice";
import {companySlice} from "./features/company.slice";
import {settingsSlice} from "./features/settings.slice";
import {friendsSlice} from "./features/friends.slice";

export default configureStore({
  reducer: {
    users: usersSlice.reducer,
    company: companySlice.reducer,
    settings : settingsSlice.reducer,
    friends : friendsSlice.reducer,

    chat: chatSlice,
   }
})
