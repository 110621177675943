import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, Input, Space, Table } from "antd";
import "../shop/product/style/productConnect.less";
import { toast } from "react-toastify";

export default function RemoveGroupMember(props) {
  const { group, onClose, onSubmit } = props;
  const { t } = useTranslation();
  const { Search } = Input;
  const [dataList, setDataList] = useState([]);
  const [selectFriends, setSelectFriends] = useState([]);
  const [selectFriendKeys, setSelectFriendKeys] = useState([]);

  useEffect(() => {
    if (group) {
      let { members } = group;
      let list = members.filter((item) => item && item.isOwner === "2");
      list = list.map((item) => {
        return {
          name: item.userInfo?.nickName,
          signature: item.userInfo?.signature,
          key: item.memberId,
        };
      });
      setDataList(list);
    }
    // let _list = friends.map(item => {
    //   return { ...item, key: item.id };
    // });
    // _list = _list.filter(item => !item.isOwner);
    // setDataList(_list);
  }, [group]);

  const columns = [
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "认证",
      dataIndex: "signature",
      key: "signature",
    },
  ];

  const rowSelection = {
    type: "checkbox",
    selectedRowKeys: selectFriendKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectFriendKeys(selectedRowKeys);
      setSelectFriends(selectedRows);
    },
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const onRemoveGroupMember = async () => {
    onSubmit && onSubmit(selectFriendKeys);
    toast.success(t("SUCCESS"), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (onClose) {
      onClose();
    }
  };

  const unBindFriends = (item) => {
    let _list = selectFriendKeys.filter((n) => item.id !== n);
    setSelectFriendKeys(_list);
    _list = selectFriends.filter((n) => item.id !== n.id);
    setSelectFriends(_list);
  };

  return (
    <div className="product-connect">
      <div className="connect-title">删除成员</div>
      <div className="connect-content">
        <div className="content-list">
          <Search placeholder="搜索联系人" className="content-search" />
          <Table
            dataSource={dataList}
            columns={columns}
            className="content-table"
            rowSelection={rowSelection}
          />
        </div>
        <div className="content-result">
          <div className="result-title">删除联系人 (02)</div>
          {selectFriends.map((item, index) => {
            return (
              <div className="result-item" key={index}>
                <span>{item.name}</span>
                <span
                  className="table-action-button"
                  onClick={unBindFriends.bind(this, item)}
                >
                  删除
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <Space size={8} className="modal-confirm-row">
        <Button className="modal-confirm-btn" onClick={onCancel.bind(this)}>
          {t("CANCEL")}
        </Button>
        <Button
          className="modal-confirm-btn-primary"
          onClick={onRemoveGroupMember.bind(this)}
        >
          {t("SAVE")}
        </Button>
      </Space>
    </div>
  );
}
