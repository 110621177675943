import { Radio } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function RadioGroupNormal(props) {
  const { t, i18n } = useTranslation();
  const { label, value, index, data, onChange, disabled } = props;
  const [radioData, setRadioData] = useState([]);

  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    console.log("--------radio group normal:", value, label, data);
    setSelectedValue(value);
  }, [value, radioData]);

  useEffect(() => {
    if (data) {
      data.map((dataItem) => {
        dataItem.label = t(dataItem.label);
      });
      setRadioData(data);
    } else {
      setRadioData([
        {
          label: t("YES"),
          value: true,
        },
        {
          label: t("NO"),
          value: false,
        },
      ]);
    }
  }, [i18n.language, data]);

  const onInputChange = (e) => {
    console.log("-----------onRadioGroupChange:", e.target.value);
    onChange(e.target.value, index);
  };

  return (
    <>
      <div
        className="input-title"
        style={{
          display: "flex",
        }}
      >
        <span
          style={{
            color: "#333333",
            fontSize: "14px",
            fontWeight: "400",
            marginRight: "90px",
          }}
        >
          {t(label)}
        </span>
        <div>
          <Radio.Group
            disabled={disabled}
            value={selectedValue}
            onChange={onInputChange.bind(this)}
            options={radioData}
          />
        </div>
      </div>
    </>
    // <div className="control-normal-container" key={index}>
    //   <div className="title-pannel">{t(label)}</div>
    //   <Radio.Group value={value} onChange={onInputChange.bind(this)} options={radioData} />
    // </div>
  );
}
