import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { subscriptionUpdate } from "../../../api/subscription";
import "./style/subscriptionSuccess.less";
import { useUserId } from "../../../contexts/UserIdProvider";
import { userDetail } from "../../../api/user";

const qs = require("query-string");

export default function SubscriptionSuccess() {
  let { user, loadUserInfo } = useUserId();
  const { search } = useLocation();
  const history = useHistory();
  const [orderId, setOrderId] = useState();
  useEffect(() => {
    if (search) {
      let searchObj = qs.parse(search);
      let { out_trade_no } = searchObj;
      if (out_trade_no) {
        setOrderId(out_trade_no);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderId) {
      onOrderConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const onOrderConfirm = async () => {
    let pms = { _id: orderId, status: 1 };
    await subscriptionUpdate(pms);
    await loadUserInfo(user.userId);
    setTimeout(() => {
      history.push("/feed");
    }, 1000);
  };

  return (
    <div className="subscription-success-page">
      <div className="success-content">支付成功,正在跳转首页......</div>
    </div>
  );
}
