import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "antd";
import { getOrders, importerOrderPay } from "../../../api/orders";
import { OrderStatus } from "../../../constants/statusCode.enum";
import { useUserId } from "../../../contexts/UserIdProvider";
import React, { useEffect, useState } from "react";
import OrdersListItem from "../../shop/order/OrdersListItem";
import { deepClone } from "../../../utils/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import ListBasic from "../../common/list/ListBasic";
import { ProductCurrencyType, TableSourceMaxCount } from "../../../constants/constants";

export default function Wallet() {
  const { t } = useTranslation();
  const { isWinery, user } = useUserId();
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [orderList, setOrderList] = useState([]);
  const pageSize = 20;
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadData();
  }, [pageIndex]);

  useEffect(() => {
    initTotalCount();
  }, []);

  const initTotalCount = async () => {
    if (!isWinery) {
      let pms = { userId: user.userId };
      let result = await importerOrderPay(pms);
      if (result) {
        if (result.usable > 0) {
          setTotalWithdraw(result.usable);
        }
        if (result.locked > 0) {
          setTotalDeposit(result.locked);
        }
      }
    }
  };

  const loadData = async () => {
    let pms = { page: pageIndex, per_page: 100, payStatus: OrderStatus.Payed };
    if (isWinery) {
      pms.coId = user.userInfo.companyId;
    } else {
      pms.userId = user.userId;
    }
    let result = await getOrders(pms);
    if (result && result.length > 0) {
      // result = result.filter((item) => item.payStatus === OrderStatus.Payed);
      if (pageIndex > 0) {
        let list = deepClone(orderList);
        result = list.concat(result);
      }
      setOrderList(result);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_WALLET")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="company-orders-container common-management ">
        <div className="orders-title common-management-title">
          {t("MY_WALLET")}
        </div>
        <div className="orders-banner common-management-panel">
          <div className="orders-banner-item">
            <div className="item-title">{t("TOTAL_AVAILABLE")}({ProductCurrencyType.CNY})</div>
            <div className="item-number">
              {totalWithdraw > 0 ? totalWithdraw.toFixed(2) : 0}              
            </div>
          </div>
          <div className="orders-banner-item">
            <div className="item-title">{t("TOTAL_LOCKED_DEPOSIT")}({ProductCurrencyType.CNY})</div>
            <div className="item-number">
              {totalDeposit > 0 ? totalDeposit.toFixed(2) : 0}
            </div>
          </div>
        </div>
        <InfiniteScroll
          className="scroll-content"
          dataLength={orderList.length}
          next={handleInfiniteOnLoad.bind(this)}
          hasMore={hasMore}
        >
          <ListBasic
            data={orderList}
            renderItem={(item, index) => (
              <div key={index}>
                <OrdersListItem item={item} index={index} />
              </div>
            )}
            loading={loading}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
}
