import { Button, Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { orderDetail, orderConfirm, getOrders } from "../../../api/orders";
import { localizeImg, localizeTitle, PayType } from "../../../constants/constants";
import "./style/orderSuccess.less";
import { useTranslation } from "react-i18next";
import { dateFormat } from "../../../utils/date";
const qs = require("query-string");

export default function OrderSuccess() {
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [orderId, setOrderId] = useState();
  const [orderList, setOrderList] = useState([]);
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    if (search) {
      let searchObj = qs.parse(search);
      let { out_trade_no } = searchObj;
      if (out_trade_no) {
        setOrderId(out_trade_no);
      }
    }
  }, []);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (orderId) {
      loadOrderDetails();
    }
  }, [orderId]);

  const loadOrderDetails = async () => {
    let pms = { relationId: orderId, payType: PayType.Alipay };
    let result = await orderConfirm(pms);
    console.log('-----orderConfirm', pms, result);
    if (result && result.length > 0) {
      setOrderList(result);
    }
    // pms = { relationId: orderId, page: 0, per_page: 2 };
    // result = await getOrders(pms);
    // console.log('-----orderConfirm', pms, result);
    // setTimeout(() => {   userId:'62288d7f5cd61d1f537514c1',             
    //   history.push("/company/order", { orderId });
    // }, 1000);
  };

  const onGoDetail = (orderId) => {
    history.push("/company/order", { orderId });
  };

  const onGoOrderList = () => {
    history.push("/shop/orders");
  };

  return (
    <div className="order-success-page">
      <div className="success-content">{t('PAYMENT_OF_DEPOSIT_SUCCEED')}!
        <Button type="link" onClick={onGoOrderList.bind(this)}>{t('CHECK_NOW')}{t('MANAGE_ORDER')}.</Button>
      </div>
      <div className="product-content">
        {orderList.map((item, index) =>
          <div key={index} className="margin-top-20">
            <Row justify="space-between">
              <div>{t("ORDER_NUMBER")}:{item.orderNumber}</div>
              <div>下单时间:{dateFormat(item.createdAt, 'yyyy-MM-dd hh:mm')}</div>
            </Row>
            {item.products.map((p, pindex) =>
              <Row align="middle" key={pindex} className="margin-top-10">
                <Col span={6}>
                  <div className="product-img-panel">
                    <img src={localizeImg(p.bottleImages[0])} className="product-img" />
                  </div>
                </Col>
                <Col span={6}>
                  <div className="product-title">{localizeTitle(lang, p.title)}</div>
                  <div>{t('YEAR')} : {p.year}</div>
                </Col>
                <Col span={6}>
                  <div>{t("PRODUCT_PRICE")}:{item.orderProducts[pindex].price}{p.currency}</div>
                  <div>{t("QUANTITY")}:{item.orderProducts[pindex].count}{t(item.orderProducts[pindex].volume.stock.unit)}</div>
                </Col>
                <Col span={6}>
                  <div className="item-action" onClick={onGoDetail.bind(this, item._id)}>
                    {t("DETAIL_OF_THE_ORDER")}
                  </div>
                </Col>
              </Row>
            )}
            <Row justify="end">
              <div>{t("TOTAL_PRICE")}:{item.orderPrice.totalPrice}</div>
              <div className="margin-left-25"> {t("DEPOSIT_AMOUNT")}:{item.orderPrice.payPrice}</div>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
}
