import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ImageUploader.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { getUploadFileAction } from "../../../api/common";
import { useUserId } from "../../../contexts/UserIdProvider";
import { uploadFile } from "../../../api/file";
import { statusCode } from "../../../constants/statusCode.enum";
import { localizeImg } from "../../../constants/constants";
import { deepClone } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

export default function ImageUploader(props) {
  const { className, label, tooltip, title, onChange, dataSource, maxCount } =
    props;

  const { t } = useTranslation();
  const { user } = useUserId();

  const [imgUrlListShow, setImgUrlListShow] = useState([]); // 用于展示的图片列表
  const [imgUrlList, setImgUrlList] = useState([]);

  const [imageUploading, setImageUploading] = useState(false);

  useEffect(() => {
    if (dataSource && imgUrlListShow.length==0) {
      let tempImgUrlListShow = deepClone(imgUrlListShow);
      tempImgUrlListShow = deepClone(dataSource);
      setImgUrlListShow(tempImgUrlListShow);

      let tempImgUrlList = deepClone(imgUrlList);
      tempImgUrlList = deepClone(dataSource);
      setImgUrlList(tempImgUrlList);
    }
  }, [dataSource]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    // console.log(file);
    formData.append("file", file);

    const reader = new FileReader();
    reader.onload = (event) => {
      setImageUploading(true);

      const dataUrl = event.target.result;

      let temp = deepClone(imgUrlListShow);
      temp.push(dataUrl);
      setImgUrlListShow(temp);
    };
    reader.readAsDataURL(file);

    try {
      let url = getUploadFileAction(user.userId);
      let data = await uploadFile(url, formData, (progress) => {
        console.log(progress);
      });
      console.log("uploadFile:", data);
      setImageUploading(false);
      if (data.statusCode == statusCode.SUCCESS) {
        let temp = deepClone(imgUrlList);
        temp.push(data.result);
        setImgUrlList(temp);

        onChange && onChange(temp);
      }
    } catch (error) {
      console.error(error);
      setImageUploading(false);
    }
  };

  const handleDelete = (index) => {
    console.log(index);
    let temp = deepClone(imgUrlListShow);
    temp.splice(index, 1);
    setImgUrlListShow(temp);

    let temp2 = deepClone(imgUrlList);
    temp2.splice(index, 1);
    setImgUrlList(temp2);
    onChange && onChange(temp2);
  };

  return (
    <>
      <div className={cn(styles.file, className)}>
        {label && (
          <div className={styles.label}>
            {label}{" "}
            {tooltip && (
              <Tooltip
                className={styles.tooltip}
                title={tooltip}
                icon="info"
                place="right"
              />
            )}
          </div>
        )}
        <div className={styles.wrap}>
          {(maxCount
            ? imgUrlListShow.length < maxCount
            : imgUrlListShow.length < 9) &&
            !imageUploading && (
              <input
                className={styles.input}
                type="file"
                onChange={handleFileUpload}
              />
            )}
          {imgUrlListShow.length > 0 && (
            <div className={styles.img_container}>
              {imgUrlListShow.map((item, index) => {
                return (
                  <div style={{ position: "relative" }} key={index}>
                    <img
                      src={localizeImg(item)}
                      className={styles.img}
                      key={index}
                    />
                    <div onClick={handleDelete.bind(this, index)}>
                      <Icon
                        name="close-circle"
                        size="24"
                        className={styles.closeIcon}
                        style={{ position: "absolute" }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {(maxCount
            ? imgUrlListShow.length < maxCount
            : imgUrlListShow.length < 9) &&
            !imageUploading && (
              <div className={styles.box}>
                <Icon name="upload" size="24" />
                {title}
              </div>
            )}
          {imageUploading && (
            <div className={styles.box}>
              <Icon name="arrow_up_circle" size="24" />
              {t("UPLOADING")}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
