import { RequestType } from "../constants/requestType.enum";
import { formatActionResponse } from "./api";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export async function list(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.userLimitList, pms);
}
