import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./CategoryAndAttibutes.module.sass";
import Card from "../../../../../core-digital-template/components/Card";
import Tooltip from "../../../../../core-digital-template/components/Tooltip";
import Checkbox from "../../../../../core-digital-template/components/Checkbox";
import { useTranslation } from "react-i18next";
import {
  getProductConfigForSelect,
  loadProductConfig,
} from "../../../../../api/product";
import { ProductConfig } from "../../../../../constants/constants";

const CategoryAndAttibutes = ({ className, onChange, dataSource }) => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [tagData, setTagData] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    onChange && onChange(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    if (dataSource) {
      setSelectedFilters(dataSource);
    }
  }, [dataSource]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  useEffect(() => {
    setConfigData();
  }, []);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { tags } = configData;

    loadSelectData(tags, ProductConfig.TAGS);
  };

  const loadSelectData = (data, flag) => {
    let result = getProductConfigForSelect(data, currentLanguage);
    if (result && result.length > 0) {
      switch (flag) {
        case ProductConfig.TAGS:
          setTagData(result);
          break;
      }
    }
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title={t("ADD_TAGS")}
      classTitle="title-purple"
    >
      <div className={styles.images}>
        <div className={styles.label}>
          {t("TAGS")}
          <Tooltip
            className={styles.tooltip}
            title="Maximum 100 characters. No HTML or emoji allowed"
            icon="info"
            place="right"
          />
        </div>
        <div className={styles.list}>
          {tagData.map((x, index) => (
            <Checkbox
              className={styles.checkbox}
              content={x.label}
              value={selectedFilters.includes(x.value)}
              onChange={() => handleChange(x.value)}
              key={index}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default CategoryAndAttibutes;
