import { useEffect, useRef, useState } from "react";

function ImageMagnifier({
    src,
    width,
    height,
    magnifierHeight = 1000,
    magnifierWidth = 1000,
    zoomLevel = 3
  }: {
    src: string;
    width?: string;
    height?: string;
    magnifierHeight?: number;
    magnifierWidth?: number;
    zoomLevel?: number;
  }) {
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);
    return (
      <div className="image-magnifier"
        style={{
          position: "relative",
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
        onMouseEnter={(e) => {
          // update image size and turn-on magnifier
          const elem = e.currentTarget;
          const imgContainer = elem.firstChild as HTMLDivElement;
          const { width, height } = imgContainer.getBoundingClientRect();
          setSize([width, height]);
          setShowMagnifier(true);
        }}
        onMouseMove={(e) => {
          // update cursor position
          const elem = e.currentTarget;
          const imgContainer = elem.firstChild as HTMLDivElement;
          const { top, left } = imgContainer.getBoundingClientRect();

          // calculate cursor position on the image
          const x = e.pageX - left - window.scrollX;
          const y = e.pageY - top - window.scrollY;
          setXY([x, y]);
        }}
        onMouseLeave={() => {
          // close magnifier
          setShowMagnifier(false);
        }}
        >
        <div
          className="image-container"
          style={{
            position: "relative",
            height: height,
            width: width
          }}
        >
          <img
            src={src}
            style={{ height: height, width: width }}
          />
    
          <div
            style={{
              display: showMagnifier ? "" : "none",
              position: "absolute",
    
              // prevent magnifier blocks the mousemove event of img
              pointerEvents: "none",
              // set size of magnifier
              height: `${magnifierHeight}px`,
              width: `${magnifierWidth}px`,
              // move element center to cursor pos
              top: `${y - magnifierHeight / 2}px`,
              left: `${x - magnifierWidth / 2}px`,
              opacity: "1", // reduce opacity so you can verify position
              border: "1px solid lightgray",
              backgroundColor: "white",
              backgroundImage: `url('${src}')`,
              backgroundRepeat: "no-repeat",
    
              //calculate zoomed image size
              backgroundSize: `${imgWidth * zoomLevel}px ${
                imgHeight * zoomLevel
              }px`,
    
              //calculate position of zoomed image.
              backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
              backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
            }}
          ></div>
        </div>
      </div>
    );
  }

  export default ImageMagnifier;