import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { FormattedMessage } from "react-intl";

import ChatItem from "../../../chat/components/ChatItem";
import customizableComponent from "../../../core/hocs/customization";
import useChannelsList from "../../../chat/hooks/useChannelsList";
import promisify from "../../../helpers/promisify";
import { ChannelRepository } from "@amityco/js-sdk";

import {
  CreateNewChatIcon,
  RecentContainer,
  RecentHeader,
  RecentHeaderLabel,
  InfiniteScrollContainer,
} from "./styles";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { localizeValue } from "../../../../../constants/constants";
import { useTranslation } from "react-i18next";

const RecentChat = ({
  onChannelSelect,
  onAddNewChannelClick,
  selectedChannelId,
}) => {
  const [channels, hasMore, loadMore] = useChannelsList();

  const { user } = useUserId();
  const { userId } = user;

  const { i18n } = useTranslation();

  let [isAutoCreatingChannel, setIsAutoCreatingChannel] = useState(false);

  useEffect(() => {
    initImChannels();
  }, [channels]);

  const initImChannels = async () => {
    if (isAutoCreatingChannel) {
      return;
    }
    setIsAutoCreatingChannel(true);
    if (channels.length == 0) {
      let data = {
        channelId: "VINBIP_SERVICE_GROUP_" + userId,
        type: "conversation",
        displayName:
          "Vinbip 在线服务-" +
          localizeValue(i18n.language, user?.userInfo?.companyDesc.companyName),
        userIds: [
          "6530dc82340d76508f65249f",
          "6530dce9340d76508f6524a1",
          "6530dcc7340d76508f6524a0",
          "6534e843dbf6d7b007639d6f",
          userId,
        ],
      };

      await promisify(ChannelRepository.createChannel(data));
    }
  };

  return (
    <RecentContainer>
      <RecentHeader>
        <RecentHeaderLabel>
          <FormattedMessage id="chat.chats" />
        </RecentHeaderLabel>
        {/* this component work only with Callback and User selector on Eko Side, during Personal Mode
        development selector was not add as there is not specific suitable design for UI Kit.
        Need to be done internaly by ASC when needed. */}
        {/* <CreateNewChatIcon
          data-qa-anchor="chat-create-chat-button"
          onClick={onAddNewChannelClick}
        /> */}
      </RecentHeader>
      <InfiniteScrollContainer data-qa-anchor="chat-list">
        <InfiniteScroll
          initialLoad={false}
          hasMore={hasMore}
          loadMore={loadMore}
          useWindow={false}
          // TODO: REMOVE when SDK Provide filter by membership
          threshold={1}
          loader={hasMore && <span key={0}>Loading...</span>}
        >
          {Array.isArray(channels) &&
            channels.map((channel) => (
              <ChatItem
                key={channel.channelId}
                channel={channel}
                isSelected={selectedChannelId === channel.channelId}
                onSelect={onChannelSelect}
              />
            ))}
        </InfiniteScroll>
      </InfiniteScrollContainer>
    </RecentContainer>
  );
};

export default customizableComponent("RecentChat", RecentChat);
