import { Select } from "antd";
import "../style/formNormal.less";
import { useTranslation } from "react-i18next";

export default function SelectNormal(props) {
  const { t } = useTranslation();
  const { data, label, value, index, title, onChange } = props;

  const onSelectChange = (val) => {
    onChange(val, index);
  };

  return (
    <>
      <div className="input-title">
        {t(label)}
      </div>
      <div>
        <Select
          className="select-basic"
          value={value}
          placeholder={t(label)}
          options={data}
          onChange={onSelectChange.bind(this)}
          style={{
            width:'100%',
            marginTop:'8px'
          }}
        ></Select>
      </div>
    </>
    // <div className="control-normal-container" key={index}>
    //   {title &&
    //     <div className="title-pannel">{title}</div>
    //   }
    //   <Select
    //     className="common-select"
    //     bordered={false}
    //     options={data}
    //     placeholder={t(label)}
    //     value={value}
    //     onChange={onSelectChange.bind(this)}
    //   />
    // </div>
  );
}
