import React, { useState, useEffect } from "react";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";
import UploadFileCard from "../../common/upload/UploadFileCard";
import { SYS_ENV_BANK_ACCOUNT_NAME, SYS_ENV_BANK_ACCOUNT_NUMBER, SYS_ENV_BANK_OF_DEPOSIT, SYS_ENV_PHONE_NUMBER } from "../../../constants/env.enum";

export default function BankTransfer(props) {
  const { files, onComplete, onClose } = props;
  const { t } = useTranslation();
  const [voucherList, setVoucherList] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false);

  useEffect(() => {
    if (files && files.length > 0) {
      setVoucherList(files);
    }
  }, [files]);

  useEffect(() => {
    if (voucherList && voucherList.length > 0) {
      setSubmitStatus(true);
    } else {
      setSubmitStatus(false);
    }
  }, [voucherList]);

  const onFinish = () => {
    onComplete && onComplete(voucherList);
  };

  const onFileLoaded = (data) => {
    setVoucherList(data);
  };

  const onCloseClick = () => {
    onClose && onClose();
  };

  return (
    <div className="modal-body">
      <div className="modal-title">{t("BANK_TRANSFER")}</div>
      <div className="modal-content-column">
        <div className="content-row-label">{t("BANK_ACCOUNT_NAME")}:&nbsp;&nbsp;{SYS_ENV_BANK_ACCOUNT_NAME}</div>
      </div>
      <div className="modal-content-column">
        <div className="content-row-label">{t("BANK_ACCOUNT")}:&nbsp;&nbsp;{SYS_ENV_BANK_ACCOUNT_NUMBER}</div>
      </div>
      <div className="modal-content-column">
        <div className="content-row-label">{t("BANK_OF_DEPOSIT")}:&nbsp;&nbsp;{SYS_ENV_BANK_OF_DEPOSIT}</div>
      </div>
      <div className="modal-content-column">
        <div className="content-row-label">{t("PHONE_NUMBER")}:&nbsp;&nbsp;{SYS_ENV_PHONE_NUMBER}</div>
      </div>
      <div className="modal-content-column">
        <UploadFileCard files={voucherList} title={t('UPLOAD_VOUCHER')} maxCount={3} onSuccess={onFileLoaded.bind(this)}></UploadFileCard>
      </div>

      <Space size={8} className="modal-confirm-row">
        <Button
          className="modal-confirm-btn"
          onClick={onCloseClick.bind(this)}
        >
          {t("CANCEL")}
        </Button>
        <Button
          htmlType="submit"
          className="modal-confirm-btn-primary"
          onClick={onFinish.bind(this)}
          disabled={!submitStatus}
        >
          {t("SAVE")}
        </Button>
      </Space>
    </div>
  );
}
