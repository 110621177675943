import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./RecommendUsers.module.sass";
import { useUserId } from "../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import {
  AmityUiKitFeed,
  AmityUiKitProvider,
  AmityUiKitSocial,
} from "../../components/amity-uikit";
import RightSider from "../../core-digital-template/components/RightSider";
import Card from "../../core-digital-template/components/Card";
import { Link, useHistory } from "react-router-dom";
import Icon from "../../core-digital-template/components/Icon";
import { Path, PathTools } from "../../constants/path";
import { recommandUsers } from "../../api/feed";
import { AmityApiKey, localizeImg } from "../../constants/constants";
import AvatarBasic from "../../components/common/avatar/AvatarBasic";
import { PostTargetType, FeedType } from "@amityco/js-sdk";
import UserItem from "./UserItem";
import { List, Skeleton } from "antd";

export default function RecommendUsers() {
  const { t } = useTranslation();
  const { user, isImporter } = useUserId();

  const history = useHistory();

  const apiKey = AmityApiKey;
  const [newBuyers, setNewBuyers] = useState([]);

  const [onLoading, setOnLoading] = useState(true);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    let pms = {
      userId: user?.userId,
    };
    let result = await recommandUsers(pms);
    if (result && result.total > 0) {
      result.result.map((mapItem) => {
        if (mapItem && mapItem.companyInfo && mapItem.companyInfo.length > 0) {
          mapItem.companyInfo = mapItem.companyInfo[0];
        }
      });

      setNewBuyers(result.result);
      setOnLoading(false);
    }
  };

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <Card
          className={cn(styles.card)}
          title={
            isImporter ? t("WELCOME_NEW_SUPPLIERS") : t("WELCOME_NEW_BUYERS")
          }
          classTitle="title-red"
        >
          <div className={styles.list}>
            {onLoading && (
              <>
                {Array.from({ length: 4 }, (_, index) => index + 1).map(() => {
                  return (
                    <Skeleton loading={true} active avatar>
                      <List.Item.Meta />
                    </Skeleton>
                  );
                })}
              </>
            )}
            {newBuyers?.map((x, index) => (
              <UserItem item={x} />
            ))}
          </div>
        </Card>
      </div>
      <div className={styles.col}>
        <RightSider />
      </div>
    </div>
  );
}
