import "./style/rightSider.less";
import React, { useState, useEffect } from "react";
import { Layout, Avatar, Divider } from "antd";
import changeIcon from "../../../resource/icons/common/change.svg";
import { useTranslation } from "react-i18next";
import { recommandUsers } from "../../../api/feed";
import { statusCode } from "../../../constants/statusCode.enum";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../contexts/UserIdProvider";
import { follow } from "../../../api/contact";
import { deepClone, goToProfilePage } from "../../../utils/utils";
import { toast } from "react-toastify";
import RightFooter from "../../others/RightFooter";
import { Skeleton } from "antd";
import { localizeImg } from "../../../constants/constants";
import Ads from "../../common/ads/Ads";
import { loadProductConfig } from "../../../api/product";
import { getSponsorPage } from "../../../api/sponsor";
import { useAuth } from "../../../contexts/AuthProvider";
import { openRecommandUsers, openSponsorPage } from "../../../api/open";
import SuggestUserItem from "../SuggestUserItem";

export default function RightSider() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { Sider } = Layout;
  const { user } = useUserId();
  const { authTokens } = useAuth();

  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [lang, setLang] = useState(i18n.language);

  const [loading, setLoading] = useState(true);
  const [sponsorList, setSponsorList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 5;

  useEffect(() => {
    initConfigData();
    loadReommandUsers();
    initSponsors();
  }, []);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (allUsers.length > 0) {
      if (allUsers.length > 5) {
        let list = allUsers.slice(pageIndex * pageSize, pageSize);
        // console.log("--------user slice:", pageIndex, list, allUsers);
        setUsers(
          allUsers.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)
        );
      } else {
        setUsers(allUsers);
      }
    }
  }, [pageIndex, allUsers]);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const loadReommandUsers = async () => {
    let pms = {
      userId: user.userId,
    };
    let result;
    if (authTokens) {
      result = await recommandUsers(pms);
    } else {
      result = await openRecommandUsers();
    }
    if (result && result.length > 0) {
      setAllUsers(result);
    }

    setLoading(false);
  };

  const initSponsors = async () => {
    let pms = { page: 0, per_page: 10 };
    let result;
    if (authTokens) {
      result = await getSponsorPage(pms);
    } else {
      result = await openSponsorPage(pms);
    }
    if (result && result.length > 0) {
      setSponsorList(result);
    }
  };

  const onChangeUsers = () => {
    let index = pageIndex + 1;
    if (allUsers.length <= index * pageSize) {
      setPageIndex(0);
    } else {
      setPageIndex(index);
    }
  };

  const onAddFriendRequest=(suggestUserItem)=>{
    loadReommandUsers();
  }

  return (
    <Sider width={338} className="right-sider-container scroll-content">
      {users.length > 0 && !loading && (
        <div
          className="sider-item recommends-item normal-shadow"
          style={{ marginBottom: 20 }}
        >
          <div className="sider-title">
            <span className="title">{t("YOU_MIGHT_INTERSTED_IN")}</span>
            {allUsers.length > pageSize && (
              // <span className="title-tip-btn">{t('CHANGE')}</span>
              <img
                src={changeIcon}
                className="change-icon"
                onClick={onChangeUsers.bind(this)}
              />
            )}
          </div>
          <div className="right-sider-content">
            {users.map((item, index) => {
              return (
                <SuggestUserItem
                  item={item}
                  onAddFriendRequest={onAddFriendRequest.bind(this)}
                ></SuggestUserItem>
              );
            })}
          </div>

          {allUsers.length > 0 && (
            <div
              className="follower-more"
              style={{
                height: "38px",
              }}
            >
              {t("SEE_MORE")}
            </div>
          )}
        </div>
      )}
      {loading && (
        <div className="sider-item recommends-item normal-shadow sider-loading-item">
          <div style={{ textAlign: "center" }}>
            <Skeleton.Button active={true} size={"small"} />
          </div>
          <div>
            <Skeleton avatar active={true} paragraph={{ rows: 0 }} />
            <Skeleton.Button
              active={true}
              size={"default"}
              style={{ display: "block", marginLeft: "56px" }}
            />
          </div>
          <div>
            <Skeleton avatar active={true} paragraph={{ rows: 0 }} />
            <Skeleton.Button
              active={true}
              size={"default"}
              style={{ display: "block", marginLeft: "56px" }}
            />
          </div>
          <div>
            <Skeleton avatar active={true} paragraph={{ rows: 0 }} />
            <Skeleton.Button
              active={true}
              size={"default"}
              style={{ display: "block", marginLeft: "56px" }}
            />
          </div>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <Skeleton.Button active={true} size={"small"} block={true} />
          </div>
        </div>
      )}
      {!loading && sponsorList.length > 0 && (
        <div className="sider-item sponsors-item normal-shadow">
          <div className="sider-title">
            <span className="title">{t("SPONSORS")}</span>
          </div>
          <div
            className="sider-content"
            style={{
              padding: "0px 20px",
              borderTop: "1px solid #dedede",
              borderBottom: "1px solid #dedede",
            }}
          >
            {sponsorList.map((item, index) => (
              <a href={item.website} key={index}>
                <div className="sponsor-item">
                  <img
                    src={localizeImg(item.picture)}
                    className="avatar"
                    style={{ width: "50px", height: "50px" }}
                  />

                  <div className="user-info">
                    <div className="user-name">{item.title}</div>
                    <div className="user-desc">{item.description} </div>
                    {/* <div className="user-desc">{item.description} </div> */}
                  </div>
                </div>
                {/* {index < sponsorList.length - 1 && <Divider />} */}
              </a>
            ))}
          </div>
          <div
            className="follower-more"
            style={{
              height: "38px",
            }}
          >
            {t("SEE_MORE")}
          </div>
        </div>
      )}
      {loading && (
        <div
          className="sider-item recommends-item normal-shadow sider-loading-item"
          style={{ marginTop: "20px" }}
        >
          <div style={{ textAlign: "center" }}>
            <Skeleton.Button active={true} size={"small"} />
          </div>
          <div>
            <Skeleton avatar active={true} paragraph={{ rows: 0 }} />
          </div>
          <div>
            <Skeleton avatar active={true} paragraph={{ rows: 0 }} />
          </div>
          <div>
            <Skeleton avatar active={true} paragraph={{ rows: 0 }} />
          </div>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <Skeleton.Button active={true} size={"small"} block={true} />
          </div>
        </div>
      )}
      {!loading && (
        <div
          className="sider-item sponsors-item normal-shadow "
          style={{ marginTop: 20 }}
        >
          <div className="vinbip-partner-container">
            <Ads />
          </div>
        </div>
      )}
      {loading && (
        <div
          className="sider-item sponsors-item normal-shadow sider-loading-item"
          style={{ marginTop: "20px" }}
        >
          <Skeleton.Image style={{ width: "280px", height: "280px" }} />
        </div>
      )}

      <div className={"sponsors-item sponsors-item-fixed"}>
        <RightFooter />
      </div>
    </Sider>
  );
}
