import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { Button, Row, Col } from "antd";
import MediaPlayer from "../common/media-player/MediaPlayer";
import { useIMContext } from "../../contexts/IMProvider";
import useAgora from "../../hooks/useAgora";
import "./style/audioChat.less";

export default function VideoMeeting(props) {
  const { groupId, onClose } = props;
  const { t } = useTranslation();
  const { agoraClient } = useIMContext();
  const {  localVideoTrack, remoteUsers, leave, joinVideo } =
    useAgora(agoraClient);

  useEffect(() => {
    if (groupId) {
      joinVideo(groupId);
    }
  }, []);

  const onHangOff = async () => {
    await leave();
    onClose && onClose();
  };

  return (
    <div className="video-meeting">
      <Row gutter={[16, 16]}>
        {localVideoTrack && (
          <Col span={12}>
            <div className="video-current">
              <MediaPlayer videoTrack={localVideoTrack} />
            </div>
          </Col>
        )}
        {remoteUsers.map((user) => (
          <Col span={12}>
            <div className="video-remote">
              <MediaPlayer
                videoTrack={user.videoTrack}
                audioTrack={user.audioTrack}
              ></MediaPlayer>
            </div>
          </Col>
        ))}
      </Row>
      <div className="action-container">
        <Button onClick={onHangOff.bind(this)}>退出会议</Button>
      </div>
    </div>
  );
}
