import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { goToProfilePage } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";
import { getTimeBefore } from "../../../../utils/date";
import { userDetail } from "../../../../api/user";
import "./style/forwardUserInfo.less";
import { localizeImg } from "../../../../constants/constants";
import AvatarBasic from "../../../common/avatar/AvatarBasic";
import forwardIcon from "./images/forward.svg";

export default function ForwardUserInfo(props) {
  const history = useHistory();
  const { userId, createdAt } = props;

  const [userInfo, setUserInfo] = useState({
    picture: null,
    nickName: "loading",
    type: "SUPPLIER",
    _id: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    initData();
  }, [userId]);

  const initData = async () => {
    let userData = await userDetail(userId);
    setUserInfo(userData);
  };

  const onCompanyHome = () => {
    // goToProfilePage(userInfo, history);
  };

  return (
    <div className="forward-user-info-panel cursor-pointer" onClick={onCompanyHome.bind(this)}>
      <div style={{ display: "flex" }}>
        <img src={forwardIcon} style={{ marginRight: "10px" }} />
        <div>
          <span style={{ fontSize: "14px", fontWeight: "500" }}>
            {t("FORWARD_FROM")}
          </span>
          {"  "}
          <span style={{ fontSize: "16px", fontWeight: "600" }}>
            {userInfo?.nickName}
          </span>
        </div>
      </div>
      <div
        style={{
          color: "#838383",
          fontSize: "14px",
          fontWeight: "400",
          marginLeft: "28px",
        }}
      >
        {t(getTimeBefore(createdAt))}
      </div>
    </div>
  );
}
