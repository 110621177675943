import React, { useState } from "react";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import useDarkMode from "use-dark-mode";

const Page = ({ wide, children, title }) => {
    const [visible, setVisible] = useState(false);

    const darkMode = {value: false};

    return (
        <>
            <div className={styles.page}>
                <Header onOpen={() => setVisible(!visible)} />
                
                <div className={styles.twocol}>
                    <Sidebar
                        className={cn(styles.sidebar, {
                            [styles.visible]: visible,
                        })}
                        onClose={() => setVisible(false)}
                    />
                    <div className={styles.inner}>    
                        {children}
                    </div>
                </div>
                    {/* <div
                        className={cn(styles.container, {
                            [styles.wide]: wide,
                        })}
                    >
                        {title && (
                            <div className={cn("h3", styles.title)}>
                                {title}
                            </div>
                        )}
                        <div className={styles.row}>
                            
                            <div className={styles.contentcol}> 
                                {children}
                             </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default Page;
