import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./PayoutHistory.module.sass";
import Card from "../../../../../core-digital-template/components/Card";
import { numberWithCommas } from "../../../../../core-digital-template/utils.js";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { loadProductConfig } from "../../../../../api/product";
import { getOrders } from "../../../../../api/orders";
import { dateFormat } from "../../../../../utils/date";
import { getCountryNameById } from "../../../../../api/config";
import { deepClone } from "../../../../../utils/utils";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "../../../../../constants/statusCode.enum";

const items = [
  {
    date: "Oct 2021",
    status: true,
    method: "Paypal",
    earnings: 128899,
    amount: 128899,
  },
  {
    date: "Sep 2021",
    status: false,
    method: "SWIFT",
    earnings: 85123,
    amount: 85123,
  },
  {
    date: "Aug 2021",
    status: true,
    method: "Paypal",
    earnings: 128899,
    amount: 128899,
  },
  {
    date: "Jul 2021",
    status: false,
    method: "SWIFT",
    earnings: 85123,
    amount: 85123,
  },
  {
    date: "Jun 2021",
    status: true,
    method: "Paypal",
    earnings: 128899,
    amount: 128899,
  },
  {
    date: "May 2021",
    status: false,
    method: "SWIFT",
    earnings: 85123,
    amount: 85123,
  },
  {
    date: "Oct 2022",
    status: true,
    method: "Paypal",
    earnings: 128899,
    amount: 128899,
  },
  {
    date: "Jun 2022",
    status: false,
    method: "SWIFT",
    earnings: 85123,
    amount: 85123,
  },
  {
    date: "May 2022",
    status: true,
    method: "Paypal",
    earnings: 128899,
    amount: 128899,
  },
  {
    date: "Sep 2022",
    status: false,
    method: "SWIFT",
    earnings: 85123,
    amount: 85123,
  },
  {
    date: "Oct 2022",
    status: true,
    method: "Paypal",
    earnings: 128899,
    amount: 128899,
  },
  {
    date: "Sep 2022",
    status: false,
    method: "SWIFT",
    earnings: 85123,
    amount: 85123,
  },
];

function PayoutHistory({ className }) {
  const { t, i18n } = useTranslation();
  const { user, isWinery } = useUserId();

  const [items, setItems] = React.useState([]);
  const [locations, setLocations] = React.useState([]);

  const pageSize = 20;
  const [pageIndex, setPageIndex] = useState(0);

  //filter list data
  const [dateFilterList, setDateFilterList] = useState([]);
  const [clientFilterList, setClientFilterList] = useState([]);
  const [shippingLocationFilterList, setShippingLocationFilterList] = useState(
    []
  );
  const [orderStatusFilterList, setOrderStatusFilterList] = useState([]);
  const [paymentStatusFilterList, setPaymentStatusFilterList] = useState([]);
  const [shippingDestinationFiterList, setShippingDestionationFilterList] =
    useState([]);

  useEffect(() => {
    loadData();
    initConfigData();
  }, [user]);

  const loadData = async () => {
    let pms = { page: pageIndex, per_page: pageSize };
    if (isWinery) {
      pms.coId = user.userInfo.companyId;
    } else {
      pms.userId = user.userId;
    }

    let result = await getOrders(pms);
    console.log("--------orders :", pms, result);
    if (!result) return;
    setItems(result);
    result = result.filter((item) => item.products && item.products.length > 0);

    let tempDateFilterList = deepClone(dateFilterList);
    let tempClientFilterList = deepClone(clientFilterList);
    let tempLocationFilterList = deepClone(shippingLocationFilterList);
    let tempOrderStatusFilterList = deepClone(orderStatusFilterList);
    let tempPaymentStatusFilterList = deepClone(paymentStatusFilterList);
    let tempDestinationFilterList = deepClone(shippingDestinationFiterList);

    tempDateFilterList = [];
    tempClientFilterList = [];
    tempLocationFilterList = [];
    tempOrderStatusFilterList = [];
    tempPaymentStatusFilterList = [];
    tempDestinationFilterList = [];

    result.map((mapItem) => {
      let dateValue = dateFormat(mapItem.createdAt, "MM/dd/yyyy");
      if (
        tempDateFilterList.filter(
          (filterItem) => filterItem.value == filterItem.value
        ).length == 0
      ) {
        tempDateFilterList.push({
          label: dateValue,
          value: dateValue,
        });
      }

      let clientIdValue = mapItem.userInfo[0]._id;
      if (
        tempClientFilterList.filter(
          (filterItem) => filterItem.value == clientIdValue
        ).length == 0
      ) {
        tempClientFilterList.push({
          label: mapItem.userInfo[0].nickName,
          value: clientIdValue,
        });
      }

      let shippingLocationId =
        mapItem.orderProducts[0].volume.shippingAddressInfo.location.country;
      if (
        tempLocationFilterList.filter(
          (filterItem) => filterItem.value == shippingLocationId
        ).length == 0
      ) {
        tempLocationFilterList.push({
          label: getCountryNameById(
            locations,
            i18n.language,
            mapItem.orderProducts[0].volume.shippingAddressInfo.location.country
          ),
          value: shippingLocationId,
        });
      }

      let shippingDestinationId = mapItem.recipient.location.country;
      if (
        tempLocationFilterList.filter(
          (filterItem) => filterItem.value == shippingDestinationId
        ).length == 0
      ) {
        tempLocationFilterList.push({
          label: getCountryNameById(
            locations,
            i18n.language,
            mapItem.recipient.location.country
          ),
          value: shippingDestinationId,
        });
      }
      setShippingDestionationFilterList(tempLocationFilterList);
    });

    setDateFilterList(tempDateFilterList);
    setClientFilterList(tempClientFilterList);
    setShippingLocationFilterList(tempLocationFilterList);

    tempOrderStatusFilterList.push(
      ...[
        {
          label: t(OrderStatus.Confirmed),
          value: OrderStatus.Confirmed,
        },
        {
          label: t(OrderStatus.Sendout),
          value: OrderStatus.Sendout,
        },
        {
          label: t(OrderStatus.Cancelled),
          value: OrderStatus.Cancelled,
        },
        {
          label: t(OrderStatus.Complete),
          value: OrderStatus.Complete,
        },
        {
          label: t(OrderStatus.Delivered),
          value: OrderStatus.Delivered,
        },
        {
          label: t(OrderStatus.Transiting),
          value: OrderStatus.Transiting,
        },
        {
          label: t(OrderStatus.Received),
          value: OrderStatus.Received,
        },
      ]
    );
    setOrderStatusFilterList(tempOrderStatusFilterList);

    tempPaymentStatusFilterList.push(
      ...[
        {
          label: t(OrderStatus.WaitingForPay),
          value: OrderStatus.WaitingForPay,
        },
        {
          label: t(OrderStatus.Payed),
          value: OrderStatus.Payed,
        },
      ]
    );
    setPaymentStatusFilterList(tempPaymentStatusFilterList);
  };

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title={t("ORDER_LIST")}
      classTitle="title-blue"
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>{t("ORDER_DATE")}</div>
            <div className={styles.col}>{t("ORDER_NUMBER")}</div>
            <div className={styles.col}>{t("CLIENT")}</div>
            <div className={styles.col}>{t("STATUS")}</div>
            <div className={styles.col}>{t("TOTAL_AMOUNT")}</div>
            <div className={styles.col}>{t("PAYMENT_STATUS")}</div>
            <div className={styles.col}>{t("OPERATIONS")}</div>
          </div>
          {items.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>{dateFormat(x.createdAt)}</div>
              <div className={styles.col}>{x.orderNumber}</div>
              <div className={styles.col}>{x.userInfo[0].nickName}</div>
              <div className={styles.col}>
                <div
                  className={x.status != 'WAITING_FOR_PAY' ? cn(
                    { "status-green-dark": x.status != 'WAITING_FOR_PAY' },
                    styles.status
                  ) : cn(
                    { "status-yellow": x.status == 'WAITING_FOR_PAY' },
                    styles.status
                  )}
                >
                  {t(x.status)}
                </div>
              </div>
              <div className={styles.col}>
                <div
                  className={cn(
                    { "status-green": true },
                    styles.status
                  )}
                >
                  {numberWithCommas(x.orderPrice?.totalPrice?.toFixed(2))}
                </div>
              </div>
              <div className={styles.col}>
                <div
                  className={x.payStatus != 'WAITING_FOR_PAY' ? cn(
                    { "status-green-dark": x.payStatus != 'WAITING_FOR_PAY' },
                    styles.payStatus
                  ) : cn(
                    { "status-yellow": x.payStatus == 'WAITING_FOR_PAY' },
                    styles.payStatus
                  )}
                >
                  {t(x.payStatus)}
                </div>
              </div>
              <div className={styles.col}>
                {t("OPERATIONS")}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default PayoutHistory;
