import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Row,
  Progress,
  Radio,
  Calendar,
  Dropdown,
  Drawer,
  Modal,
} from "antd";
import "./style/operationScheduleCreate.less";
import { useUserId } from "../../../contexts/UserIdProvider";
import { deepClone } from "../../../utils/utils";
import SelectFriend from "./components/SelectFriend";
import SelectProduct from "./components/SelectProduct";
import { OperationScheduleType } from "../../../constants/operation/OperationScheduleType.enum";
import { OperationScheduleStatus } from "../../../constants/operation/operationScheduleStatus";
import defaultChatStylePNG from "../../../resource/images/dashboard/operation/default_chat_style.png";
import { operationScheduleCreate } from "../../../api/operation";
import backIcon from "../../../resource/icons/dashboard/operation/back.svg";
import warningIcon from "../../../resource/icons/dashboard/operation/warning.svg";

export default function OperationScheduleCreate() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const { id } = useParams();
  const { state } = useLocation();

  const [pageTitle, setPageTitle] = useState("");
  const [scheduleType, setScheduleType] = useState("");

  const [formData, setFormData] = useState({
    sendTimeType: "IMMEDIATELY",
    targetProductsRange: "ALL",
  });

  const [showSendTimePicker, setShowSendTimePicker] = useState(false);
  const [showProductPicker, setShowProductPicker] = useState(false);

  const [scheduleContent, setScheduleContent] = useState(
    '<span className="content">尊敬的客户您好，我们有新的产品上架，不要错过哦！<span style="color:#3E6FA4">[产品详情页链接]</span></span>'
  );
  const [sampleScheduleContent, setSampleScheduleContent] = useState();
  const [formScheduleContent, setFormScheduleContent] = useState("");

  const [showFriendSelect, setShowFriendSelect] = useState(false);
  const [showProductSelect, setShowProductSelect] = useState(false);

  const [selectedFriends, setSelectedFriends] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  let status = OperationScheduleStatus.WATING;

  const [showCancelConfirmModalVisible, setShowCancelConfirmModalVisible] =
    useState(false);

  useEffect(() => {
    document
      .getElementById("schedule-content")
      .addEventListener("DOMSubtreeModified", onContentChange, false);

    onContentChange();

    return () => {
      if (document.getElementById("schedule-content")) {
        document
          .getElementById("schedule-content")
          .removeEventListener("DOMSubtreeModified", onContentChange);
      }
    };
  }, []);

  useEffect(() => {
    if (state && state.type) {
      setScheduleType(state.type);
    }
    if (state && state.scheduleItem) {
      console.log("-----------scheduleItem:", state.scheduleItem);
      setFormData(state.scheduleItem);
      onFriendSelecedComplete(state.scheduleItem.targetUids);
      onProductsSelecedComplete(state.scheduleItem.targetProductIds);
    }
  }, [state]);

  useEffect(() => {
    switch (scheduleType) {
      case OperationScheduleType.NEW_PRODUCT_ONLINE:
        setPageTitle(t("NEW_PRODUCT_ONLINE_NOTIFICATION"));
        setScheduleContent(
          '<span className="content">尊敬的客户您好，我们有新的产品上架，不要错过哦！<span style="color:#3E6FA4">[产品详情页链接]</span></span>'
        );
        break;
      case OperationScheduleType.JOINED_ACTIVITY:
        setPageTitle(t("JOINED_ACTIVITY_NOTIFICATION"));
        break;
      case OperationScheduleType.REPLENISH:
        setPageTitle(t("REPLENISH_NOTIFICATION"));
        setScheduleContent(
          '<span className="content">距离您上次进货已经30天了，别等到货架空空才抱怨备货不足。<span style="color:#3E6FA4">[产品详情页链接]</span></span>'
        );
        break;
      case OperationScheduleType.WAITING_FOR_PAY:
        setPageTitle(t("WAITING_FOR_PAY_NOTIFICATION"));
        setScheduleContent(
          '<span className="content">您的订单还未完成支付，订单将在10分钟内关闭，请尽快处理。<span style="color:#3E6FA4">[订单详情页链接]</span></span>'
        );
        break;
      case OperationScheduleType.NEW_ORDER:
        setPageTitle(t("NEW_ORDER_NOTIFICATION"));
        setScheduleContent(
          '<span className="content">感谢您的下单，会尽快安排发货，谢谢支持！。<span style="color:#3E6FA4">[订单详情页链接]</span></span>'
        );
        break;
      case OperationScheduleType.START_STOCK:
        setPageTitle(t("START_STOCK_NOTIFICATION"));
        setScheduleContent(
          '<span className="content">您的订单信息已收到，（预计备货时间为3天）感谢您对我们的支持，我们将尽快备货为您发出，您也可随时在我的订单中查询物流。<span style="color:#3E6FA4">[订单详情页链接]</span></span>'
        );
        break;
      case OperationScheduleType.START_DELIVERY:
        setPageTitle(t("START_DELIVERY_NOTIFICATION"));
        setScheduleContent(
          '<span className="content">您在vinbip购买的产品已发货，可在我的订单中查询物流。<span style="color:#3E6FA4">[订单详情页链接]</span></span>'
        );
        break;
      case OperationScheduleType.WAITING_FOR_PICKUP:
        setPageTitle(t("WAITING_FOR_PICKUP_NOTIFICATION"));
        setScheduleContent(
          '<span className="content">您在vinbip购买的产品已送达，记得签收，可在“我的订单”中查询物流。<span style="color:#3E6FA4">[订单详情页链接]</span></span>'
        );
        break;
      case OperationScheduleType.WAITING_FOR_COMPLETE:
        setPageTitle(t("WAITING_FOR_COMPLETE_NOTIFICATION"));
        setScheduleContent(
          '<span className="content">您在vinbip购买的产品已经安全抵达，记得签收，有任何问题欢迎咨询我们。<span style="color:#3E6FA4">[订单详情页链接]</span></span>'
        );
        break;
      default:
        break;
    }
  }, [scheduleType]);

  const onScheduleNameChange = (name) => {
    let temp = deepClone(formData);
    temp.name = name;
    setFormData(temp);
  };

  const onSendTimeChange = (e) => {
    let temp = deepClone(formData);
    temp.sendTimeType = e.target.value;
    setFormData(temp);

    setShowSendTimePicker(e.target.value == "SCHEDULED");
  };

  const onProductRangeChange = (e) => {
    let temp = deepClone(formData);
    temp.targetProductsRange = e.target.value;
    setFormData(temp);

    setShowProductPicker(e.target.value == "CUSTOM");
  };

  const formatSendTimeMenu = () => {
    return (
      <div style={{ width: "280px" }}>
        <Calendar fullscreen={false} onSelect={onCalendarSelect.bind(this)} />
      </div>
    );
  };

  const onCalendarSelect = (value) => {
    let selectedMonth = value.format("YYYY-MM-DD");
    let temp = deepClone(formData);
    temp.scheduledTime = selectedMonth;
    setFormData(temp);
  };

  const onFriendsSelectedClose = () => {
    setShowFriendSelect(false);
  };

  const onProductsSelectedClose = () => {
    setShowProductSelect(false);
  };

  const onFriendSelecedComplete = (items) => {
    let temp = deepClone(selectedFriends);
    temp = items;
    setSelectedFriends(temp);

    let tempFormData = deepClone(formData);
    tempFormData.targetUids = items;
    setFormData(tempFormData);

    onFriendsSelectedClose();
  };

  const onProductsSelecedComplete = (items) => {
    let temp = deepClone(selectedProducts);
    temp = items;
    setSelectedProducts(temp);

    let tempFormData = deepClone(formData);
    tempFormData.targetProductIds = items;
    setFormData(tempFormData);

    onProductsSelectedClose();
  };

  const onContentChange = (e) => {
    let innerText = document.getElementById("schedule-content").innerText;

    //async sample.
    if (innerText && innerText.indexOf("[") != -1) {
      let splitStart = innerText.indexOf("[");
      // let splitEnd = innerText.indexOf("]");
      let partOne = innerText.substring(0, splitStart);
      let partTwo = innerText.substring(splitStart);

      let tempScheduleContent = '<span className="content">';
      tempScheduleContent += partOne;
      tempScheduleContent += '<span style="color:#3E6FA4">';
      tempScheduleContent += partTwo;
      tempScheduleContent += "</span></span>";
      setSampleScheduleContent(tempScheduleContent);
    }

    setFormScheduleContent(innerText);
  };

  const onSave = async () => {
    let pms = {
      ...formData,
      type: scheduleType,
      content: formScheduleContent,
      coId: user?.userInfo.companyId,
      status: status,
    };
    console.log(pms);

    let result = await operationScheduleCreate(pms);
    console.log("save result:", result);
    history.go(-1);
  };

  const onSaveDraft = () => {
    status = OperationScheduleStatus.DRAFT;
    onSave();
  };

  return (
    <div className="common-page operation-schedule-create-container">
      <div className="">
        <div className="common-management-title">
          <img src={backIcon} onClick={() => history.go(-1)} />
          <span className="title-level1">
            {t("CREATE_NEW_PRODUCT_NOTIFICATION")}
            {pageTitle}
          </span>
        </div>
        <div className="card-panel">
          <div className="card-title">{t("BASIC_INFORMATION")}</div>
          <div className="card-spliter"></div>
          <div className="card-content">
            <div className="card-content-row">
              <div className="row-label">{t("SCHEDULE_NAME")}</div>
              <Input
                className="row-input"
                placeholder={t("SCHEDULE_NAME")}
                value={formData.name}
                onChange={(val) => onScheduleNameChange(val.target.value)}
              />
            </div>
            <div className="card-content-row1" style={{ marginTop: "12px" }}>
              <div className="row-label">{t("TARGET_USERS")}</div>
              <div>
                <div>{t("SELECT_TARGET_USERS")}</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "6px",
                    cursor:'pointer'
                  }}
                >
                  <div
                    className="row-button"
                    onClick={() => setShowFriendSelect(true)}
                  >
                    {selectedFriends && selectedFriends.length == 0 ? (
                      <span>{t("SELECT_TARGET_USERS")}</span>
                    ) : (
                      <span>{t("RE_SELECT")}</span>
                    )}
                  </div>
                  {selectedFriends && selectedFriends.length > 0 && (
                    <span className="selected-tips">
                      {t("ALREADY_SELECTED")}
                      {selectedFriends.length}
                      {t("PEOPLE")}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-panel">
          <div className="card-title">{t("TARGET_PRODUCTS")}</div>
          <div className="card-spliter"></div>
          <div className="card-content">
            <div className="card-content-row1">
              <div className="row-label">{t("PRODUCT_RANGE")}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Radio.Group
                  onChange={onProductRangeChange.bind(this)}
                  value={formData.targetProductsRange}
                >
                  <Radio
                    defaultChecked={formData.targetProductsRange == "ALL"}
                    value={"ALL"}
                  >
                    {t("ALL_PRODUCTS")}
                  </Radio>
                  <Radio
                    defaultChecked={formData.targetProductsRange == "CUSTOM"}
                    value={"CUSTOM"}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {t("SELECT_PRODUCTS_MANUAL")}
                      {showProductPicker && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="row-button"
                            onClick={() => setShowProductSelect(true)}
                            style={{
                              marginTop: "8px",
                            }}
                          >
                            {selectedProducts &&
                            selectedProducts.length == 0 ? (
                              <span>
                                {t("CHOOSE_EXISTING")}
                                {t("PRODUCT")}
                              </span>
                            ) : (
                              <span>{t("RE_SELECT")}</span>
                            )}
                          </div>
                          {selectedProducts && selectedProducts.length > 0 && (
                            <span className="selected-tips">
                              {t("ALREADY_SELECTED")}
                              {selectedProducts.length}
                              {t("SUFFIX_PRODUCT")}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="card-panel">
          <div className="card-title">{t("CONTENT_WILL_SEND")}</div>
          <div className="card-spliter"></div>
          <div
            className="card-content"
            style={{
              display: "flex",
            }}
          >
            <div
              contentEditable="true"
              className="card-content-content"
              id="schedule-content"
              dangerouslySetInnerHTML={{ __html: scheduleContent }}
            >
              {/* <span className="content">
                尊敬的客户您好，我们有新的产品上架，不要错过哦！
              </span>
              <span className="link">【订单详情页链接】</span> */}
            </div>
            <div className="default-image-panel">
              <img src={defaultChatStylePNG} className="default-image" />
              <div className="default-avatar"></div>
              <div
                className="content-sample"
                dangerouslySetInnerHTML={{ __html: sampleScheduleContent }}
              ></div>
            </div>
          </div>
        </div>
        <div className="card-panel">
          <div className="card-title">{t("SNED_AVAILABLE_TIME")}</div>
          <div className="card-spliter"></div>
          <div className="card-content">
            <div className="card-content-row">
              <div className="row-label">{t("SEND_TIME")}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Radio.Group
                  onChange={onSendTimeChange.bind(this)}
                  value={formData.sendTimeType}
                >
                  <Radio defaultChecked={false} value={"IMMEDIATELY"}>
                    {t("SNED_IMMEDIATELY")}
                  </Radio>
                  <Radio defaultChecked value={"SCHEDULED"}>
                    {t("SNED_SCHEDULED")}
                  </Radio>
                </Radio.Group>
                {!showSendTimePicker && (
                  <div className="warning-panel">
                    <img src={warningIcon} style={{ marginRight: "5px" }} />
                    <div>{t("SYSTEM_WILL_START_IN_FIVE_MINUTES")}</div>
                  </div>
                )}
                {showSendTimePicker && (
                  <Dropdown overlay={formatSendTimeMenu()} trigger={["click"]}>
                    <Input
                      className="row-input"
                      placeholder={t("SEND_TIME")}
                      style={{ marginTop: "10px" }}
                      value={formData.scheduledTime}
                    />
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="article-create-publish-btn">
        <div style={{ display: "flex" }}>
          {/* <Button
            onClick={onSaveDraft.bind(this)}
            className="save-draft-btn"
            role="button"
          >
            {t("SAVE_AS_DRAFT")}
          </Button> */}
          <Button
            onClick={() => setShowCancelConfirmModalVisible(true)}
            className="cancel-btn"
            role="button"
          >
            {t("CANCEL")}
          </Button>
          <Button
            onClick={onSave.bind(this)}
            className="save-btn"
            role="button"
          >
            {t("SEND")}
          </Button>
        </div>
      </div>
      <Drawer
        destroyOnClose={true}
        width={616}
        closable={true}
        onClose={onFriendsSelectedClose.bind(this)}
        visible={showFriendSelect}
        extra={<span>{t("SELECT_FRIENDS")}</span>}
      >
        <div>
          <SelectFriend
            onCancel={onFriendsSelectedClose.bind(this)}
            onComplete={onFriendSelecedComplete.bind(this)}
            selectedItems={selectedFriends}
          ></SelectFriend>
        </div>
      </Drawer>
      <Drawer
        destroyOnClose={true}
        width={654}
        closable={true}
        onClose={onProductsSelectedClose.bind(this)}
        visible={showProductSelect}
        extra={<span>{t("SELECT_PRODUCT")}</span>}
      >
        <div>
          <SelectProduct
            onCancel={onProductsSelectedClose.bind(this)}
            onComplete={onProductsSelecedComplete.bind(this)}
            selectedItems={selectedProducts}
          ></SelectProduct>
        </div>
      </Drawer>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={showCancelConfirmModalVisible}
        footer={null}
        className="product-desc-modal"
        onCancel={() => setShowCancelConfirmModalVisible(false)}
      >
        <div className="save-draft-modal-panel">
          <div className="title">{t("SAVE_AS_DRAFT")}</div>
          <div className="desc">{t("SAFE_DRAFT_TIPS")}</div>
          <div className="action-panel">
            <div className="btn-save" onClick={onSaveDraft.bind(this)}>
              {t("SAVE")}
            </div>
            <div className="btn-cancel" onClick={() => history.go(-1)}>
              {t("GIVE_UP")}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
