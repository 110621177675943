import { Button, Form, Input, message } from "antd";
import React from "react";
import { userResetPassword as reqUserResetPassword } from "../../api/user";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../contexts/UserIdProvider";
import "./style/password-management.less";
import { useHistory } from "react-router-dom";
import { toastError, toastInfo } from "../../api/toast";

export default function PasswordManagement() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useUserId();
  const history = useHistory();

  const onSubmitResetPassword = async (formValues) => {
    let { password, newPassword, confirmPassword } = formValues;
    if (!password) {
      toastInfo(t("OLD_PASSWORD_IS_EMPTY"));
      return;
    }
    if (!newPassword) {
      toastInfo(t("NEW_PASSWORD_IS_EMPTY"));
      return;
    }
    if (!confirmPassword) {
      toastInfo(t("CONFIRM_PASSWORD_IS_EMPTY"));
      return;
    }
    if (newPassword !== confirmPassword) {
      toastInfo(t("PASSWORD_DID_NOT_MATCH"));
      return;
    }

    let pms = {
      _id: user.userId,
      password,
      newPassword,
    };


    let result = await reqUserResetPassword(pms);

    if (result.statusCode === 7 || result.statusCode === "7") {
      toastInfo(t("INVALID_OLD_PASSWORD"));
    } else if (result.statusCode === 200 || result.statusCode === "200") {
      toastInfo(t("SAVE_SUCCESS"));
      history.push("/dashboard");
      console.log('aabbccdddddddddddd')
    } else {
      toastError(t("SAVE_FAILED"));
    }
  };

  return (
    <>
          <Form form={form} onFinish={onSubmitResetPassword.bind(this)}>
            <div className={"OLD_PASSWORD"}>
              {t("OLD_PASSWORD")}
              <Form.Item name="password">
                <Input bordered={true} type="password" className={"old"} />
              </Form.Item>
            </div>
            <div className={"NEW_PASSWORD"}>
              {t("NEW_PASSWORD")}
              <Form.Item name="newPassword">
                <Input bordered={true} type="password" className={"new"} />
              </Form.Item>
            </div>
            <div className={"CONFIRM_THE_PASSWORD"}>
              {t("CONFIRM_THE_PASSWORD")}
              <Form.Item name="confirmPassword">
                <Input bordered={true} type="password" className={"confirm"} />
              </Form.Item>
            </div>

            <div className="row">
              <Button htmlType="submit" className={"button-primary"}>
                <span>{t("SAVE_THE_CHANGE")}</span>
              </Button>
            </div>
          </Form>
    </>
        
  );
}
