import {actionRequest} from "./api";
import {RequestType} from "../constants/requestType.enum";
import apiPath from "./apipath";
import {requestGetUrlFormat} from "./common";

/**
 * @param pms
 */
export async function createLogs(pms: any) {
  return await actionRequest(RequestType.POST, apiPath.actionLogger, pms);
}

/**
 *
 * @param pms
 */
export function getLogs(pms: any) {
  return actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.actionLogger, pms));
}
