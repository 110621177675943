import { Carousel } from 'antd';
import React, { useEffect, useState } from 'react';
import { EMOTIONS_CANCEL, EMOTIONS_NAMES } from '../../../constants/emotions';
import './style/emotionPanel.less';

const PAGE_EMOTION_COUNT = 53;

export default function EmotionPanel(props) {
  const { onEmotionPress, onEmotionDelete } = props;

  const [emotions1, setEmotions1] = useState([]);
  const [emotions2, setEmotions2] = useState([]);

  useEffect(() => {
    initEmotions();
  }, []);

  const onPress = (emotionItem) => {
    if (emotionItem.name.indexOf('ic_emoji_del') == -1) {
      onEmotionPress && onEmotionPress(emotionItem);
    } else {
      onEmotionDelete && onEmotionDelete();
    }
  }

  const initEmotions = () => {
    let temp1 = EMOTIONS_NAMES.slice(0, PAGE_EMOTION_COUNT);
    setEmotions1(temp1.concat(EMOTIONS_CANCEL));

    let temp2 = EMOTIONS_NAMES.slice(PAGE_EMOTION_COUNT + 1, PAGE_EMOTION_COUNT + 1 + PAGE_EMOTION_COUNT);
    setEmotions2(temp2.concat(EMOTIONS_CANCEL));
  }

  return (
    <div className='emotion-panel-container'>
      <Carousel >
        <div key="1" className='emotion-item-panel'>
          {emotions1.map((emotionItem, emotionIndex) => {
            return <img key={emotionIndex} src={emotionItem.path} className="emotion-icon cursor-pointer" onClick={onPress.bind(this, emotionItem)} />
          })}
        </div>
        <div key="2" className='emotion-item-panel'>
          {emotions2.map((emotionItem, emotionIndex) => {
            return <img key={emotionIndex} src={emotionItem.path} className="emotion-icon cursor-pointer" onClick={onPress.bind(this, emotionItem)} />
          })}
        </div>
      </Carousel>
    </div>

  )
}
