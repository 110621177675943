import { formatActionResponse, formatActionResponseArray } from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";

/**
 * @description page
 * @param pms
 */
export async function browseRecordList(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.browseRecordPage, pms);
}

/**
 * @description Create 
 * @param pms
 */
export async function browseRecordCreate(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.browseRecordCreate, pms);
}

/**
 * @description remove
 * @param pms
 */
 export async function browseRecordRemove(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.browseRecordRemove, pms);
}

export async function browseRecordVisitedMe(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.browseRecordVisitedMe, pms);
}

