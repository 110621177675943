import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";
import InputBasic from "../../common/input/InputBasic";
import { useUserId } from "../../../contexts/UserIdProvider";
import { recipientCreate, recipientUpdate } from "../../../api/user";
import "./style/AddressCreate.less";
import InputPhoneNumberNormal from "../../common/input/InputPhoneNumberNormal";
import { toastError } from "../../../api/toast";

export default function AddressCreate(props) {
  const { detail, isDefault, onComplete } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useUserId();
  const [phone, setPhone] = useState();

  useEffect(() => {
    if (detail) {
      let { recipientAddress, recipientUser } = detail;
      form.setFieldsValue({
        ...recipientAddress,
        ...recipientUser,
        province: recipientAddress.state,
      });
      if (typeof recipientUser.phone === "object") {
        setPhone(recipientUser.phone);
      } else {
        setPhone({ phone: recipientUser.phone, code: "86", short: "CN" });
      }
    }
  }, []);

  const onFinish = async (values) => {
    if (!(phone && phone.phone)) {
      toastError(t("PHONE_NUMBER_IS_EMPTY"));
      return;
    }
    let { country, province, city, addressLineOne, userName, zipcode } = values;
    let pms = {
      userId: user.userId,
      recipientAddress: {
        addressLineOne,
        country,
        state: province,
        city,
        zipcode,
      },
      recipientUser: {
        userName,
        phone,
      },
    };
    let data;
    if (detail) {
      pms._id = detail._id;
      data = await recipientUpdate(pms);
    } else {
      pms["isDefault"] = isDefault;
      pms["status"] = "0";
      data = await recipientCreate(pms);
    }
    onComplete && onComplete();
  };

  const onPhoneChange = (val) => {
    setPhone(val);
    // setPhoneNumber(val.phone);
  };

  return (
    <div className="address-create-modal">
      <div className="address">{t("CREATE_ADDRESS")}</div>
      <div className="address-content">
        <Form form={form} onFinish={onFinish.bind(this)}>
          <InputBasic name="country" label={t("COUNTRY")} required={true} />
          <InputBasic name="province" label={t("PROVINCE")} required={true} />
          <InputBasic name="city" label={t("CITY")} required={true} />
          <InputBasic
            name="addressLineOne"
            label={t("ADDRESS")}
            required={true}
          />
          <InputBasic name="zipcode" label={t("ZIPCODE")} required={true} />
          <InputBasic
            name="userName"
            label={t("CONSIGNEE_NAME")}
            required={true}
          />
          {/* <InputBasic name="phone" label={t("PHONE_NUMBER")} required={true} /> */}
          <div className="content-row-panel">
            <div className="content-row-label">
              <span className="required-tip1">*</span>
              {t("PHONE_NUMBER")}:
            </div>
            <div style={{ width: "300px" }}>
              <InputPhoneNumberNormal
                value={phone}
                onChange={onPhoneChange.bind(this)}
              />
            </div>
          </div>

          <div className="primary-row">
            <Button
              type="primary"
              htmlType="submit"
              className={"button-primary create-address-btn"}
            >
              {t("SUBMIT")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
