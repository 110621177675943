import { Input } from "antd";
import { useTranslation } from "react-i18next";
import "./style/inputNormalExt.less";

export default function InputNormalExt(props) {
  const { t } = useTranslation();
  const { label, value, index, onChange, after } = props;
  // console.log('------input normal:', label, value, index);
  const onInputChange = (e) => {
    onChange(e.target.value, index);
  };

  return (
    <>
      <div className="input-title">{t(label)}</div>
      <div
        className="input-normal-ext-content-container"
      >
        <Input
          bordered={false}
          maxLength={200}
          placeholder={t(label)}
          onChange={onInputChange.bind(this)}
          autocomplete="one-time-code"
        />
        <span className="content-ext">{t(after)}</span>
      </div>
    </>
  );
}
