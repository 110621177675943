import React from "react";
import "./style/explore.less";
import ParisFrance from "../../resource/images/country/france.jpeg";
import VeniseItaly from "../../resource/images/country/italy.jpeg";
import Spain from "../../resource/images/country/spain.jpeg";
import Swiss from "../../resource/images/country/swiss.jpeg";
import Portugal from "../../resource/images/country/portugal.webp";
import Germany from "../../resource/images/country/germany.jpeg";
import { Button } from "antd";
import jiantou from "../../resource/images/france-region/jiantou.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ExploreWinery() {
  const { t } = useTranslation();

  return (
    <div className="explore-page">
      <div className="explore-banner">
        <div className="explore-title">
          Explore wine regions around the world
        </div>
      </div>
      <div className="explore-content">
        <div className="img">
          <div className="middle-content">
            <img
              src={ParisFrance}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-button">
              <Link to={"/explore/winery/france"}>
                <Button className="button">
                  Discover
                  <img src={jiantou} className="jiantou" />
                </Button>
              </Link>
            </div>
            <div className="zi">{t("FRANCE")}</div>
          </div>
          <div className="middle-content">
            <img
              src={VeniseItaly}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-button">
              <Button className="button" disabled>
                Discover
                <img src={jiantou} className="jiantou" />
              </Button>
            </div>
            <div className="zi">{t("意大利")}</div>
          </div>
        </div>
        <div className="img">
          <div className="middle-content">
            <img
              src={Spain}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-button">
              <Button className="button" disabled>
                Discover
                <img src={jiantou} className="jiantou" />
              </Button>
            </div>
            <div className="zi">{t("SPAIN")}</div>
          </div>
          <div className="middle-content">
            <img
              src={Swiss}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-button">
              <Button className="button" disabled>
                Discover
                <img src={jiantou} className="jiantou" />
              </Button>
            </div>
            <div className="zi">{t("瑞士")}</div>
          </div>
        </div>
        <div className="img">
          <div className="middle-content">
            <img
              src={Portugal}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-button">
              <Button className="button" disabled>
                Discover
                <img src={jiantou} className="jiantou" />
              </Button>
            </div>
            <div className="zi">{t("葡萄牙")}</div>
          </div>
          <div className="middle-content">
            <img
              src={Germany}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-button">
              <Button className="button" disabled>
                Discover
                <img src={jiantou} className="jiantou" />
              </Button>
            </div>
            <div className="zi">{t("德国")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
