import { useTranslation } from "react-i18next";

export default function Privacy() {
  const { t } = useTranslation();

  const style = {
    maxWidth: "1300px",
    margin: "0 auto",
  };

  const titleStyle = {
    textAlign: "center",
  };

  return (
    <div
      className="normal-shadow-padding10-white notSubscribeContainer"
      style={style}
    >
      <h1 style={titleStyle}>
        {t("PRIVACY_POLICY")} & {t("TERMS_OF_USE")}
      </h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Venenatis tellus in
        metus vulputate eu scelerisque felis imperdiet. Ornare massa eget
        egestas purus. Dictum varius duis at consectetur lorem donec massa
        sapien. Venenatis tellus in metus vulputate eu scelerisque felis.
        Convallis aenean et tortor at risus viverra adipiscing at in. Lacus sed
        viverra tellus in hac habitasse platea dictumst vestibulum. Quam viverra
        orci sagittis eu volutpat odio facilisis. Faucibus interdum posuere
        lorem ipsum dolor sit amet consectetur. Interdum varius sit amet mattis
        vulputate. Bibendum est ultricies integer quis. Ultrices gravida dictum
        fusce ut placerat. Fermentum et sollicitudin ac orci phasellus egestas.
        At risus viverra adipiscing at in tellus integer. Volutpat odio
        facilisis mauris sit amet massa vitae. Neque laoreet suspendisse
        interdum consectetur libero id faucibus nisl tincidunt. Sed blandit
        libero volutpat sed cras ornare. Bibendum at varius vel pharetra vel
        turpis. Mattis aliquam faucibus purus in. Nulla malesuada pellentesque
        elit eget gravida cum sociis natoque penatibus. Et tortor at risus
        viverra adipiscing at in tellus integer. Iaculis at erat pellentesque
        adipiscing commodo. Semper feugiat nibh sed pulvinar. Venenatis cras sed
        felis eget velit aliquet sagittis. Fermentum posuere urna nec tincidunt
        praesent. Fringilla ut morbi tincidunt augue. Ultrices sagittis orci a
        scelerisque purus semper. Sed velit dignissim sodales ut eu sem integer
        vitae justo. Arcu vitae elementum curabitur vitae. Ipsum dolor sit amet
        consectetur adipiscing elit ut aliquam. Dolor morbi non arcu risus quis
        varius quam quisque. Volutpat diam ut venenatis tellus in metus
        vulputate eu. Tincidunt arcu non sodales neque sodales ut etiam sit
        amet. Maecenas pharetra convallis posuere morbi leo. In mollis nunc sed
        id semper risus in hendrerit. Suspendisse interdum consectetur libero id
        faucibus nisl tincidunt eget nullam. Dui id ornare arcu odio ut.
        Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec. Purus
        sit amet volutpat consequat mauris nunc. Vel fringilla est ullamcorper
        eget nulla facilisi etiam dignissim. Ullamcorper dignissim cras
        tincidunt lobortis feugiat vivamus at. Scelerisque viverra mauris in
        aliquam sem fringilla ut. A diam sollicitudin tempor id eu nisl nunc.
        Aliquam faucibus purus in massa tempor nec feugiat. Leo in vitae turpis
        massa sed elementum tempus. Blandit aliquam etiam erat velit scelerisque
        in dictum non. Placerat in egestas erat imperdiet. Risus sed vulputate
        odio ut enim blandit volutpat. Commodo viverra maecenas accumsan lacus
        vel facilisis volutpat est. Dolor purus non enim praesent. Viverra justo
        nec ultrices dui sapien eget mi. Nulla aliquet porttitor lacus luctus.
        Vitae suscipit tellus mauris a diam maecenas sed. Pharetra massa massa
        ultricies mi quis hendrerit dolor magna. Facilisi cras fermentum odio eu
        feugiat pretium. Quam adipiscing vitae proin sagittis nisl. Vulputate
        odio ut enim blandit volutpat maecenas volutpat blandit. Condimentum
        lacinia quis vel eros donec ac. Ac tortor dignissim convallis aenean et
        tortor. Et netus et malesuada fames ac. Dictum non consectetur a erat.
        Sed nisi lacus sed viverra tellus. In cursus turpis massa tincidunt dui
        ut ornare. Phasellus vestibulum lorem sed risus ultricies tristique.
        Sociis natoque penatibus et magnis dis parturient montes nascetur
        ridiculus. Etiam erat velit scelerisque in dictum non consectetur. Amet
        commodo nulla facilisi nullam. Pharetra diam sit amet nisl suscipit. Nec
        dui nunc mattis enim ut tellus. Arcu odio ut sem nulla. Facilisis leo
        vel fringilla est ullamcorper eget. Semper auctor neque vitae tempus
        quam pellentesque nec. Cursus sit amet dictum sit amet. Amet nisl purus
        in mollis. Risus at ultrices mi tempus imperdiet. Vel turpis nunc eget
        lorem. Amet nisl purus in mollis. Sit amet luctus venenatis lectus magna
        fringilla urna porttitor rhoncus. Lectus nulla at volutpat diam ut
        venenatis tellus in metus. Adipiscing at in tellus integer feugiat.
        Malesuada bibendum arcu vitae elementum curabitur. Integer vitae justo
        eget magna. Est pellentesque elit ullamcorper dignissim. Facilisi cras
        fermentum odio eu feugiat pretium nibh ipsum consequat. Nunc mi ipsum
        faucibus vitae aliquet nec ullamcorper sit. Fusce id velit ut tortor
        pretium. Lacus sed viverra tellus in hac habitasse platea. Natoque
        penatibus et magnis dis. Semper risus in hendrerit gravida rutrum
        quisque. Curabitur vitae nunc sed velit dignissim sodales ut. Arcu dui
        vivamus arcu felis bibendum ut. Pulvinar mattis nunc sed blandit libero
        volutpat sed cras. Congue quisque egestas diam in arcu cursus euismod.
        Non nisi est sit amet facilisis magna. Mauris in aliquam sem fringilla
        ut morbi. Nulla malesuada pellentesque elit eget. Senectus et netus et
        malesuada fames ac turpis. Justo nec ultrices dui sapien eget mi proin
        sed libero. Ut lectus arcu bibendum at varius vel pharetra. Est ante in
        nibh mauris cursus mattis molestie a iaculis. Penatibus et magnis dis
        parturient montes nascetur ridiculus mus. Nunc lobortis mattis aliquam
        faucibus. Dictumst quisque sagittis purus sit amet volutpat consequat
        mauris nunc. Libero volutpat sed cras ornare arcu dui vivamus. At
        ultrices mi tempus imperdiet. Praesent elementum facilisis leo vel
        fringilla est ullamcorper. Duis at tellus at urna condimentum mattis
        pellentesque. Vulputate eu scelerisque felis imperdiet proin. Sagittis
        nisl rhoncus mattis rhoncus. Congue mauris rhoncus aenean vel elit
        scelerisque mauris. Pellentesque habitant morbi tristique senectus et
        netus et malesuada. Amet nulla facilisi morbi tempus iaculis urna.
        Euismod lacinia at quis risus sed vulputate. Quis auctor elit sed
        vulputate mi sit amet mauris. Facilisis volutpat est velit egestas dui
        id ornare. Id semper risus in hendrerit gravida rutrum quisque. Id
        venenatis a condimentum vitae sapien pellentesque. Orci sagittis eu
        volutpat odio facilisis mauris sit amet massa. Diam donec adipiscing
        tristique risus nec feugiat in fermentum. Libero nunc consequat interdum
        varius. Aliquet porttitor lacus luctus accumsan tortor. Ultrices
        tincidunt arcu non sodales neque sodales. Purus faucibus ornare
        suspendisse sed nisi lacus sed. Ipsum suspendisse ultrices gravida
        dictum. Massa id neque aliquam vestibulum morbi blandit cursus.
        Hendrerit dolor magna eget est lorem ipsum dolor. Ut pharetra sit amet
        aliquam id diam maecenas ultricies mi. Ac ut consequat semper viverra
        nam libero justo. Ultrices in iaculis nunc sed augue. Nec feugiat in
        fermentum posuere urna nec tincidunt praesent. Posuere lorem ipsum dolor
        sit amet consectetur. Arcu vitae elementum curabitur vitae nunc sed
        velit dignissim sodales. Enim neque volutpat ac tincidunt vitae semper
        quis lectus nulla. Ut aliquam purus sit amet luctus venenatis lectus
        magna. Maecenas pharetra convallis posuere morbi leo urna. Vel facilisis
        volutpat est velit egestas dui id ornare. Rhoncus urna neque viverra
        justo. Ornare quam viverra orci sagittis. Malesuada nunc vel risus
        commodo viverra maecenas. Malesuada proin libero nunc consequat interdum
        varius. Facilisi morbi tempus iaculis urna id. Nam aliquam sem et tortor
        consequat id porta nibh venenatis. Viverra tellus in hac habitasse
        platea dictumst. Diam vulputate ut pharetra sit amet. Ut consequat
        semper viverra nam libero justo laoreet. Pellentesque sit amet porttitor
        eget dolor morbi non. Feugiat scelerisque varius morbi enim. Cras
        pulvinar mattis nunc sed blandit libero volutpat. In hac habitasse
        platea dictumst vestibulum rhoncus est pellentesque elit. Eget nunc
        lobortis mattis aliquam faucibus purus in massa tempor. Urna molestie at
        elementum eu facilisis sed odio. Ac turpis egestas integer eget aliquet
        nibh. Integer malesuada nunc vel risus commodo viverra maecenas accumsan
        lacus. Mi proin sed libero enim sed faucibus turpis. Feugiat in
        fermentum posuere urna nec tincidunt praesent. Non curabitur gravida
        arcu ac tortor. Nulla facilisi cras fermentum odio eu. Imperdiet massa
        tincidunt nunc pulvinar sapien et ligula. Etiam dignissim diam quis enim
        lobortis scelerisque fermentum dui faucibus. Vel elit scelerisque mauris
        pellentesque pulvinar pellentesque habitant. Morbi tincidunt ornare
        massa eget egestas purus viverra accumsan in. Felis bibendum ut
        tristique et egestas quis ipsum. Nunc pulvinar sapien et ligula
        ullamcorper malesuada proin libero nunc. Est ullamcorper eget nulla
        facilisi etiam dignissim diam. Arcu non sodales neque sodales ut etiam
        sit amet. Metus aliquam eleifend mi in nulla posuere. Ipsum faucibus
        vitae aliquet nec ullamcorper. Lorem sed risus ultricies tristique nulla
        aliquet. Urna molestie at elementum eu facilisis sed odio morbi. Ipsum
        faucibus vitae aliquet nec ullamcorper sit. Dui vivamus arcu felis
        bibendum ut tristique et egestas. Eu augue ut lectus arcu. Ultrices
        vitae auctor eu augue. Leo duis ut diam quam nulla porttitor massa id
        neque. Laoreet non curabitur gravida arcu ac tortor dignissim convallis.
        Imperdiet dui accumsan sit amet. Turpis egestas pretium aenean pharetra.
        Faucibus purus in massa tempor nec feugiat nisl pretium fusce. Vitae
        congue eu consequat ac felis donec. Urna porttitor rhoncus dolor purus
        non enim. Posuere ac ut consequat semper viverra. Odio morbi quis
        commodo odio aenean sed adipiscing diam donec. Sed nisi lacus sed
        viverra tellus in hac habitasse. Urna molestie at elementum eu facilisis
        sed. Ultricies integer quis auctor elit sed vulputate. Tellus id
        interdum velit laoreet id donec ultrices tincidunt arcu. Aliquet nec
        ullamcorper sit amet risus. Auctor eu augue ut lectus arcu bibendum.
        Donec pretium vulputate sapien nec sagittis. Mattis vulputate enim nulla
        aliquet porttitor lacus luctus. Amet aliquam id diam maecenas ultricies
        mi eget mauris. Tempor commodo ullamcorper a lacus vestibulum sed. Ut
        ornare lectus sit amet. Eu mi bibendum neque egestas congue quisque. A
        arcu cursus vitae congue. Sit amet consectetur adipiscing elit. Mauris
        in aliquam sem fringilla ut morbi tincidunt augue. Id diam maecenas
        ultricies mi eget mauris pharetra et ultrices. Mattis vulputate enim
        nulla aliquet porttitor lacus luctus accumsan tortor. At varius vel
        pharetra vel. Ac tortor vitae purus faucibus ornare. Sociis natoque
        penatibus et magnis dis. Volutpat blandit aliquam etiam erat velit
        scelerisque in. Aliquam ut porttitor leo a diam sollicitudin. Eget magna
        fermentum iaculis eu non diam phasellus. Felis eget nunc lobortis mattis
        aliquam. Dui faucibus in ornare quam viverra orci sagittis. Sollicitudin
        nibh sit amet commodo nulla facilisi. Enim nulla aliquet porttitor lacus
        luctus accumsan tortor posuere ac. Orci a scelerisque purus semper eget
        duis at tellus. Dictum sit amet justo donec. Fusce ut placerat orci
        nulla pellentesque dignissim enim sit amet. Sapien nec sagittis aliquam
        malesuada. Adipiscing commodo elit at imperdiet dui. Sed id semper risus
        in hendrerit gravida. Nibh nisl condimentum id venenatis a. Gravida cum
        sociis natoque penatibus et magnis. Lectus arcu bibendum at varius vel
        pharetra. Tempor orci eu lobortis elementum nibh tellus molestie. Diam
        quis enim lobortis scelerisque. Non sodales neque sodales ut. Tortor
        posuere ac ut consequat. Sed id semper risus in. Viverra nam libero
        justo laoreet sit amet cursus. Volutpat consequat mauris nunc congue
        nisi. Tellus molestie nunc non blandit. In nibh mauris cursus mattis
        molestie. Aliquam id diam maecenas ultricies mi eget mauris pharetra et.
        Lectus urna duis convallis convallis tellus. Vitae auctor eu augue ut
        lectus arcu bibendum at. Tristique nulla aliquet enim tortor at auctor
        urna nunc id. Tristique risus nec feugiat in. Duis at consectetur lorem
        donec massa sapien faucibus et molestie. Risus sed vulputate odio ut
        enim blandit volutpat. Vel pretium lectus quam id.
      </p>
    </div>
  );
}
