import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./FormNormalW.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { deepClone } from "../../../utils/utils";
import TextInput from "../TextInput";
import { useTranslation } from "react-i18next";
import { FormControls } from "../../../constants/constants";

function FormNormalW(props) {
  const { t, i18n } = useTranslation();

  const { phoneNumber, items, onChange, onCheckEmail, onCheckPhone, disabled } =
    props;
  const [fields, setFields] = useState(items);

  useEffect(() => {
    setFields(items);
    console.log("fields:", items);
  }, [items]);

  const onControlChange = (val, key) => {
    console.log("-------control change:", key, val);
    let list = deepClone(fields);
    list[key].value = val;
    onChange(list);
  };

  const onInputEmailBlur = (val) => {
    onCheckEmail && onCheckEmail(val);
  };

  const onInputPhoneBlur = (val) => {
    onCheckPhone && onCheckPhone(val);
  };

  return (
    <>
      {fields.map((item, index) => (
        // <Row className="input-row">
        <div className={styles.description}>
          {item.type === FormControls.TEXT && (
            <TextInput
              className={styles.field}
              label={t(item.label)}
              name={item.name}
              type={item.textType ? item.textType : "text"}
              tooltip={item.tooltip}
              value={item.value}
              index={index}
              onChange={onControlChange.bind(this)}
              required
            />
          )}
          {/* {item.type === FormControls.TEXTEXT && (
            <InputNormalExt
              {...item}
              index={index}
              onChange={onControlChange.bind(this)}
            />
          )}
          {item.type === FormControls.EMAIL && (
            <InputEmail
              {...item}
              index={index}
              disabled={disabled}
              onChange={onControlChange.bind(this)}
              onBlur={onInputEmailBlur.bind(this)}
            />
          )}
          {item.type === FormControls.PASSWORD && (
            <InputPwdNormal
              value={item.value}
              label={item.label}
              index={index}
              onChange={onControlChange.bind(this)}
            />
          )}
          {item.type === FormControls.SELECT && (
            <SelectNormal
              {...item}
              index={index}
              onChange={onControlChange.bind(this)}
            />
          )}
          {item.type === FormControls.ADDRESSINPUT && (
            <AddressInput
              {...item}
              index={index}
              disabled={disabled}
              onChange={onControlChange.bind(this)}
            />
          )}
          {item.type === FormControls.SELECTMULTIPLE && (
            <VSelectMultipleNormal
              {...item}
              index={index}
              onChange={onControlChange.bind(this)}
            />
          )}
          {item.type === FormControls.NUMBER && (
            <InputNumberBase
              value={item.value}
              label={item.label}
              index={index}
              unit={item.unit}
              onChange={onControlChange.bind(this)}
            />
          )}
          {item.type === FormControls.NUMBERUNIT && (
            <InputNumberUnit
              {...item}
              index={index}
              onChange={onControlChange.bind(this)}
            />
          )}
          {item.type === FormControls.RADIOGROUP && (
            <RadioGroupNormal
              value={item.value}
              label={item.label}
              index={index}
              data={item.data}
              disabled={item.disabled}
              onChange={onControlChange.bind(this)}
            />
          )}
          {item.type === FormControls.PHONENUMBER && (
            <InputPhoneNumber
              {...item}
              index={index}
              onChange={onControlChange.bind(this)}
              onBlur={onInputPhoneBlur.bind(this)}
            />
          )}
          {item.type === FormControls.CAPTURE && (
            <InputCapture
              {...item}
              phoneNumber={phoneNumber}
              index={index}
              onChange={onControlChange.bind(this)}
            />
          )}
          {item.type === FormControls.UPLOADUSERIDCOPY && (
            <UserLicenseCopyUploader
              {...item}
              index={index}
              disabled={disabled}
              onChange={onControlChange.bind(this)}
            />
          )}
          {item.type === FormControls.UPLOADCOMPANYLICENSECOPY && (
            <CompanyLicenseCopyUploader
              {...item}
              index={index}
              disabled={disabled}
              onChange={onControlChange.bind(this)}
            />
          )} */}
        </div>
        //   {(item.descT || item.desc || item.descT == "PLACE_HOLDER") && (
        //     <Col
        //       style={{
        //         padding: "0px 46px",
        //         display: "flex",
        //         alignItems: "center",
        //         color: "#838383",
        //         fontSize: "14px",
        //         fontWeight: "400",
        //         flex: "1",
        //         borderTopLeftRadius: index == 0 ? "10px" : "0px",
        //         borderTopRightRadius: index == 0 ? "10px" : "0px",
        //         borderBottomLeftRadius:
        //           index == fields.length - 1 ? "10px" : "0px",
        //         borderBottomRightRadius:
        //           index == fields.length - 1 ? "10px" : "0px",
        //         display: "flex",
        //         alignItems: "center",
        //       }}
        //     >
        //       <div>
        //         {item.descT
        //           ? t(item.descT != "PLACE_HOLDER" ? item.descT : "")
        //           : item.desc}
        //       </div>
        //     </Col>
        //   )}
        // </Row>
      ))}
    </>
  );
}

export default FormNormalW;
