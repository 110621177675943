import React, { useEffect, useState } from "react";
import styles from "./ProductViews.module.sass";
import Card from "../../../components/Card";
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { fetchProductClicks } from "../../../../api/product";
import { deepClone } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";

const data = [
  {
    name: "Mo",
    views: 20,
  },
  {
    name: "Tu",
    views: 60,
  },
  {
    name: "We",
    views: 45,
  },
  {
    name: "Th",
    views: 16,
  },
  {
    name: "Fr",
    views: 20,
  },
  {
    name: "Sa",
    views: 115,
  },
  {
    name: "Su",
    views: 25,
  },
];

const ProductViews = () => {
  const { user } = useUserId();
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    loadProductClicks();
  }, []);

  const loadProductClicks = async () => {
    let pms = {
      coId: user.userInfo.companyId,
    };
    let result = await fetchProductClicks(pms);
    const currentDate = new Date();
    const oneWeekAgo = new Date(
      currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
    );

    const groupedData = result.result.reduce((groups, data) => {
      const date = new Date(data.createdAt);
      if (date >= oneWeekAgo) {
        const weekday = date.toLocaleString("en-US", { weekday: "short" });

        if (!groups[weekday]) {
          groups[weekday] = []; // Create a new group
        }

        groups[weekday].push(data); // Add data to the corresponding group
      }

      return groups;
    }, {});

    const sortedWeekMap = new Map(
      Object.entries(groupedData).sort((a, b) => {
        const dayIndexA = [
          "Mon",
          "Tue",
          "Wed",
          "Thu",
          "Fri",
          "Sat",
          "Sun",
        ].indexOf(a[0]);
        const dayIndexB = [
          "Mon",
          "Tue",
          "Wed",
          "Thu",
          "Fri",
          "Sat",
          "Sun",
        ].indexOf(b[0]);
        return dayIndexA - dayIndexB;
      })
    );

    let list = deepClone(dataSource);
    list = [];
    Array.from(sortedWeekMap).map(([key, value]) => {
      list.push({
        name: key,
        views: value.length,
      });
    });
    setDataSource(list);
  };

  return (
    <Card
      className={styles.card}
      title={t("PRODUCT_VIEWS")}
      classTitle="title-blue"
    >
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={dataSource}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            barSize={30}
            barGap={8}
          >
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
              padding={{ left: 10 }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#272B30",
                borderColor: "rgba(255, 255, 255, 0.12)",
                borderRadius: 8,
                boxShadow:
                  "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
              }}
              labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
              itemStyle={{
                padding: 0,
                textTransform: "capitalize",
                fontSize: 12,
                fontWeight: "600",
                color: "#fff",
              }}
              cursor={{ fill: "#f3f2f3" }}
            />
            <Bar dataKey="views" fill="#B5E4CA" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default ProductViews;
