import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import Icon from "../../../core-digital-template/components/Icon";
import { useUserId } from "../../../contexts/UserIdProvider";
import { localizeImg, localizeValue } from "../../../constants/constants";
import { loadProductConfig } from "../../../api/product";
import { useTranslation } from "react-i18next";
import { getChinaCityNameById, getCountryNameById } from "../../../api/config";
import UploadImageNormal from "../../../components/common/upload/UploadImageNormal";
import cameraIcon from "../../../resource/icons/user-profile/camera.svg";
import { deepClone } from "../../../utils/utils";
import { UserType } from "../../../constants/usersType.enum";
import { saveCompanyInfo } from "../../../api/company";
import "./profile.less";
import CompInfoEditor from "../../../core-digital-template/components/CompanyAboutUs/CompInfoEditor";
import LocationEditor from "../../../core-digital-template/components/CompanyAboutUs/LocationEditor";
import CompanyNameEditor from "../../../core-digital-template/components/CompanyAboutUs/CompanyNameEditor";
import { Link, useHistory } from "react-router-dom";
import { Path, PathTools } from "../../../constants/path";
import { Button } from "antd";
import { createAmityChannel, getAmityChannelId } from "../../../api/amity";
import { ChannelRepository } from "@amityco/js-sdk";
import promisify from "../../../components/amity-uikit/helpers/promisify";

export default function Profile(props) {
  const { targetUserId, companyInfo, onSaved } = props;

  const { user, loadUserInfo } = useUserId();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const currentUserId = user.userId;

  const [isMyself, setIsMyself] = useState(false);
  const [editProfile, setEditProfile] = useState(false);

  const [locations, setLocations] = useState([]);

  const [showCompanyNameEditor, setShowCompanyNameEditor] = useState(false);
  const [showLocationEditor, setShowLocationEditor] = useState(false);

  const [_companyInfo, setCompanyInfo] = useState(companyInfo);

  useEffect(() => {
    initConfigData();
  }, []);

  useEffect(() => {
    let flag = user?.userInfo?.companyId == companyInfo?._id;
    setIsMyself(flag);
    setCompanyInfo(companyInfo);
    console.log("Profile companyInfo:", companyInfo);
    console.log("Profile user:", user);
  }, [companyInfo]);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations, types, classifications, tags } = configData;
    setLocations(locations);
  };

  const onMouseEnterAvatar = () => {
    setEditProfile(true);
  };

  const onMouseLeaveAvatar = () => {
    setEditProfile(false);
  };

  const onAvatarLoaded = (data) => {
    console.log("onAvatarLoaded:", data);
    if (data.length > 0) {
      let temp = deepClone(_companyInfo);
      if (!temp.avatar) {
        temp.avatar = [];
      }
      temp.avatar[0] = data[0];
      // setCompanyInfo(temp);
      onChangeAvatar(temp);
    }
  };

  const onAvatarUploadLoading = (e) => {
    let temp = deepClone(_companyInfo);
    if (!temp.avatar) {
      temp.avatar = [];
    }
    temp.avatar[0] = e;
    setCompanyInfo(temp);
  };

  const onChangeAvatar = async (pms) => {
    pms.coId = pms._id;
    let result = await saveCompanyInfo(pms);
    await loadUserInfo(targetUserId);
    if (result.statusCode === 200) {
      // toastInfo(t("SAVE_SUCCESS"));
    } else {
      // toastInfo(t("SAVE_FAILED"));
    }
  };

  const onSendMessage = async () => {
    // console.log("onSendMessage:", targetUserId, user?.userInfo?._id,localizeValue(i18n.language,user?.userInfo?.companyDesc?.companyName));
    // PathTools.joinPath(Path.messaging, user?.userInfo?.companyDesc?.companyId)
    // .concat("?contact=")
    // .concat(_companyInfo?.companyId);

    let pms = {
      buyerId: user?.userInfo?._id,
      supplierId: targetUserId,
    };
    let result = await getAmityChannelId(pms);
    console.log("getAmityChannelId:", result);
    if (result?.result) {
      // let channelResult = await getChannel(result?.result);
      // console.log("channelResult:", channelResult, result?.result);
      // navigation.navigate('PersonalChat', { channel: channelResult })
      history.push(
        PathTools.joinPath(
          Path.messaging,
          localizeValue(i18n.language, _companyInfo?.companyName)
        )
      );
    } else {
      let amityCreateChannelResult = await createChannel(
        localizeValue(i18n.language, _companyInfo?.companyName),
        [user?.userInfo?._id, targetUserId]
      );

      console.log("amityCreateChannelResult:", amityCreateChannelResult);

      if (amityCreateChannelResult?.channelId) {
        pms["amityChannelId"] = amityCreateChannelResult?.channelId;
        pms["members"] = [user?.userInfo?._id, targetUserId];

        let result = await createAmityChannel(pms);
        console.log("createAmityChannel:", result);

        if (result?.result) {
          history.push(
            PathTools.joinPath(
              Path.messaging,
              localizeValue(i18n.language, _companyInfo?.companyName)
            )
          );
        }
      }
    }
  };

  const createChannel = async (displayName, userIds) => {
    const param = {
      displayName: displayName,
      type: "community",
      userIds: userIds,
      tags: userIds,
    };

    console.log("createChannel:", param);
    // const channel = await ChannelRepository.createChannel(param);
    const channel = await promisify(ChannelRepository.createChannel(param));
    return channel;
  };

  const getChannel = (channelId) => {
    return new Promise((resolve) => {
      ChannelRepository.getChannel(
        channelId,
        ({ data: channel, loading, error }) => {
          resolve(channel);
        }
      );
    });
  };

  return (
    <div className={styles.profile}>
      <div className={`${styles.avatar} profile-avatar-tour`}>
        <>
          <div
            onMouseEnter={onMouseEnterAvatar.bind(this)}
            className={styles.avatarImg}
          >
            {_companyInfo?.avatar && _companyInfo?.avatar.length > 0 && (
              <img src={localizeImg(_companyInfo?.avatar[0])} alt="Avatar" />
            )}
          </div>
          <UploadImageNormal
            onFileLoaded={onAvatarLoaded.bind(this)}
            onLoading={onAvatarUploadLoading.bind(this)}
            scaleAspect={1}
            cropShape="round"
          >
            <div
              onMouseLeave={onMouseLeaveAvatar.bind(this)}
              className={
                isMyself && editProfile
                  ? "image-title-wrapper"
                  : "image-title-wrapper ele-hide"
              }
            >
              {/* <p>{t("MODIFY_THE_AVATAR")}</p> */}
              <img src={cameraIcon} />
            </div>
          </UploadImageNormal>
        </>
      </div>
      <div className={styles.details}>
        <div className={styles.detailsCol1}>
          <div
            className={cn(
              styles.compName,
              styles.man,
              "profile-company-name-tour"
            )}
          >
            {localizeValue("en", _companyInfo?.companyName)}
            {isMyself && (
              <div
                className={styles.editButton}
                onClick={() => setShowCompanyNameEditor(true)}
              >
                <Icon name="edit" size="24" />
              </div>
            )}
          </div>
          {/* {_companyInfo &&
            localizeValue("zh", _companyInfo?.companyName) !=
              localizeValue("en", _companyInfo?.companyName) && (
              <div className={cn(styles.compTranslation, styles.man)}>
                {localizeValue("zh", _companyInfo?.companyName)}
              </div>
            )} */}
          {_companyInfo &&
          _companyInfo.location &&
          _companyInfo?.location?.country ? (
            <div className={`${styles.location} profile-address-tour`}>
              <Icon name="pin" />
              <span>
                &nbsp;
                {getCountryNameById(
                  locations,
                  i18n.language,
                  _companyInfo?.location?.country
                )}
              </span>
              {_companyInfo?.location?.region && (
                <span>
                  &nbsp;/&nbsp;
                  {getCountryNameById(
                    locations,
                    i18n.language,
                    _companyInfo?.location?.region
                  )}
                </span>
              )}
              {_companyInfo?.location?.subRegion && (
                <span>
                  &nbsp;/&nbsp;
                  {getCountryNameById(
                    locations,
                    i18n.language,
                    _companyInfo?.location?.subRegion
                  )}
                </span>
              )}
              {isMyself && (
                <div
                  className={styles.editButton}
                  onClick={() => setShowLocationEditor(true)}
                >
                  <Icon name="edit" size="20" />
                </div>
              )}
            </div>
          ) : (
            <>
              {isMyself && (
                <div className="profile-address-tour flex flex-row">
                  <div
                    className={styles.editButton}
                    onClick={() => setShowLocationEditor(true)}
                  >
                    {t("COMPANY_ADDRESS")}<span> </span>
                    <Icon name="edit" size="20" />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.detailsCol2}>
          <div
            className={cn("button", styles.button)}
            onClick={onSendMessage.bind(this)}
          >
            <Icon name="message" size="20" />
            <span>{t("CONTACT")}</span>
          </div>
        </div>
      </div>
      <CompanyNameEditor
        visible={showCompanyNameEditor}
        dataSource={_companyInfo}
        onCloseModal={() => setShowCompanyNameEditor(false)}
        onSaved={onSaved}
      />
      <LocationEditor
        visible={showLocationEditor}
        dataSource={_companyInfo}
        onCloseModal={() => setShowLocationEditor(false)}
        onSaved={onSaved}
      />
    </div>
  );
}
