import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";
import InputBasic from "../../common/input/InputBasic";
import { useUserId } from "../../../contexts/UserIdProvider";
import "./style/InvoiceCreate.less";
import { createOrderBill, updateOrderBill } from "../../../api/orders";

export default function InvoiceCreate(props) {
  const { detail, isDefault, onComplete, billType } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useUserId();
  const [billTypeNum, setBillTypeNum] = useState(0);

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({ ...detail });
    }
    if (!isNaN(billType)) {
      setBillTypeNum(billType);
    }
  }, []);

  const onFinish = async (values) => {
    let {
      companyName,
      taxCode,
      companyAddress,
      contactPhone,
      bankAccount,
      bankName,
    } = values;
    let pms = {
      coId: user.userInfo.companyId,
      companyName,
      taxCode,
      companyAddress,
      contactPhone,
      bankAccount,
      bankName,
      billType: billType >= 0 ? billType : billTypeNum,
    };
    let data;
    if (detail) {
      pms._id = detail._id;
      data = await updateOrderBill(pms);
    } else {
      data = await createOrderBill(pms);
    }
    onComplete && onComplete();
  };
  return (
    <div className="address-create-modal">
      <div className="address">{t("ADD_INVOICE")}</div>
      {isNaN(billType) && (
        <div className="content">
          <div
            className={
              "pickservice-item " +
              (billTypeNum === 0 && "pickservice-item-selected")
            }
            onClick={() => setBillTypeNum(0)}
          >
            {t("ELECTRONIC_ORDINARY_INVOICE")}
          </div>
          <div
            className={
              "pickservice-item " +
              (billTypeNum === 1 && "pickservice-item-selected")
            }
            onClick={() => setBillTypeNum(1)}
          >
            {t("VAT_SPECIAL_INVOICE")}
          </div>
        </div>
      )}
      <div className="address-content">
        <Form form={form} onFinish={onFinish.bind(this)}>
          <InputBasic
            name="companyName"
            label={t("COMPANY_NAME")}
            required={true}
          />
          <InputBasic name="taxCode" label={t("TAX_CODE")} required={true} />
          {billTypeNum == 1 && (
            <>
              <InputBasic
                name="companyAddress"
                label={t("COMPANY_ADDRESS")}
                required={true}
              />
              <InputBasic
                name="contactPhone"
                label={t("CONTACT_PHONE")}
                required={true}
              />
              <InputBasic
                name="bankAccount"
                label={t("BANK_ACCOUNT")}
                required={true}
              />
              <InputBasic
                name="bankName"
                label={t("BANK_OF_DEPOSIT")}
                required={true}
              />
            </>
          )}
          <div className="primary-row">
            <Button
              type="primary"
              htmlType="submit"
              className={"button-primary create-address-btn"}
            >
              {t("SUBMIT")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
