import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Input, Table, Button, Space } from "antd";
import { deepClone } from "../../../utils/utils";
import {
  productVintages,
  vintageBind,
  vintageUnBind,
} from "../../../api/product";
import { localizeTitle } from "../../../constants/constants";
import "./style/productConnect.less";

export default function ProductConnect(props) {
  let { products, productId, onClose } = props;
  const { i18n, t } = useTranslation();
  const [vintages, setVintages] = useState([]);
  const [unBindVintages, setUnBindVintages] = useState([]);
  const [sourceProducts, setSourceProducts] = useState([]);

  useEffect(() => {
    loadVintages(productId);
  }, []);

  const loadVintages = async (productId) => {
    let list = products.filter(item => item.id !== productId);
    let pms = { id: productId };
    let result = await productVintages(pms);
    console.log('-----loadVintages:', list, result);
    if (result && result.length > 0) {
      result = result.map((item) => {
        list = list.map(p => {
          if (p.id === item._id) {
            p.vintage = true;
          }
          return p;
        });
        return { ...item, isNew: false };
      });
      setVintages(result);
    }
    setSourceProducts(list);
  };

  const toBindVintage = async (item) => {
    var tempVintages = deepClone(vintages);
    tempVintages.push({ ...item, _id: item.id, isNew: true });
    setVintages(tempVintages);

    let tempSourceProducts = deepClone(sourceProducts);
    for (let i = 0; i < tempSourceProducts.length; i++) {
      if (tempSourceProducts[i].id === item.id) {
        tempSourceProducts[i].vintage = true;
        return;
      }
    }
    setSourceProducts(tempSourceProducts);
  };

  const toUnBandVintage = async (item) => {
    let tempVintages = deepClone(vintages);
    tempVintages = tempVintages.filter(p => p._id !== item._id);
    setVintages(tempVintages);

    let tempUnBindVintages = deepClone(unBindVintages);
    if (!item.isNew) {
      tempUnBindVintages.push(item);
      setUnBindVintages(tempUnBindVintages);
    }

    let tempSourceProducts = deepClone(sourceProducts);
    tempSourceProducts = tempSourceProducts.map(p => {
      if (p.id === item._id) {
        p.vintage = false;
      }
      return p;
    });
    setSourceProducts(tempSourceProducts);
  };

  const onSubmitVintage = async () => {
    for (let i = 0; i < unBindVintages.length; i++) {
      let ele = unBindVintages[i];
      let pms = {
        publicProductId: ele._id,
        productId,
      };
      await vintageUnBind(pms);
    }

    for (let i = 0; i < vintages.length; i++) {
      let ele = vintages[i];
      if (ele.isNew) {
        let pms = {
          publicProductId: ele._id,
          productId,
        };
        await vintageBind(pms);
      }
    }

    onCancel();
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const columns = [
    // {
    //   title: t("PRODUCT_SERIAL_NUMBER"),
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: t("PRODUCT_NAME"),
      dataIndex: "title",
      key: "title",
      render: (val) => {
        //todo fix localize for the item (title)
        return localizeTitle(i18n.language, val);;
      },
    },
    {
      title: t("ACTION"),
      key: "action",
      render: (item) => {
        if (!item.vintage) {
          return (
            <span
              className="table-action-button"
              onClick={() => toBindVintage(item)}
            >
              {t("BIND")}
            </span>
          );
        } else {
          return <span className="table-action-text">{t("BOUND")}</span>;
        }
      },
    },
  ];

  return (
    <div className="product-connect">
      <div className="connect-title">{t("BIND_PRODUCTS")}</div>
      <div className="connect-content">
        <div className="content-list">
          <Table
            dataSource={sourceProducts}
            columns={columns}
            className="content-table"
          />
        </div>
        <div className="content-result">
          {vintages.map((item, index) => {
            return (
              <div className="result-item" key={index}>
                <span>{localizeTitle(i18n.language, item.title)}</span>
                <span
                  className="table-action-button"
                  onClick={toUnBandVintage.bind(this, item)}
                >
                  {t("UNBOUND")}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <Space size={8} className="modal-confirm-row">
        <Button className="modal-confirm-btn" onClick={onCancel.bind(this)}>
          {t("CANCEL")}
        </Button>
        <Button
          className="modal-confirm-btn-primary"
          onClick={onSubmitVintage.bind(this)}
          disabled={unBindVintages.length === 0 && vintages.length === 0}
        >
          {t("SAVE")}
        </Button>
      </Space>
    </div>
  );
}
