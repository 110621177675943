import React, { useEffect, useState } from "react";
import styles from "./Menu.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
//import Image from "../Image";
import { useUserId } from "../../../contexts/UserIdProvider";
import { companyDetail } from "../../../api/company";
import { statusCode } from "../../../constants/statusCode.enum";
import { getFileTypeByPath } from "../../../api/common";
import { localizeImg, localizeValue } from "../../../constants/constants";
import { deepClone } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { Path, PathTools } from "../../../constants/path";
import { UserType } from "../../../constants/usersType.enum";
import VinbipLogo from "../VinbipLogo";
import { useIMContext } from "../../../contexts/IMProvider";
import useIM from "../../../hooks/useIM";
import { getCache } from "../../../utils/cache";
import Card from "../Card";

function Sidebar({ className, onClose }) {
  const { t, i18n } = useTranslation();
  const { user, isWinery } = useUserId();
  const { pathname } = useLocation();

  const {
    webIM,
    imLoginStatus,
    newMsg,
    sessionMessageList,
    initIMLogin,
    getSessionMessage,
    setChatCallMsg,
  } = useIMContext();
  const {
    messageList,
    newGroup,
    fetchHistoryMessage,
    readChannelMessage,
    sendTextMessage,
    sendCustomMessage,
    createGroup,
    quitGroup,
    dissolveGroup,
    removeGroupMember,
    addGroupMember,
  } = useIM(webIM);

  const [userCompanyDetail, setUserCompanyDetail] = useState({});

  const [navigation, setNavigation] = useState([]);
  const [navigationIndex, setNavigationIndex] = useState(0);

  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    loadCompany();
  }, [user]);

  useEffect(() => {
    if (userCompanyDetail && userCompanyDetail._id) {
      initNavigation(userCompanyDetail);
    }
  }, [userCompanyDetail]);

  useEffect(() => {
    if (webIM) {
      initIMLogin(user?.userInfo);
    }
  }, [webIM, imLoginStatus]);

  useEffect(() => {
    if (sessionMessageList.length > 0) {
      let tempNum = 0;
      sessionMessageList.filter((item) => {
        tempNum += item.unread_num;
      });

      setMessageUnreadNum(tempNum);
    }
  }, [sessionMessageList]);

  useEffect(() => {
    if (newMsg && navigation.length > 2 && navigationIndex != 2) {
      console.log("Sidebar newMsg:", newMsg);
      setMessageUnreadNum(navigation[2].counter + 1);
    }
  }, [newMsg]);

  const setMessageUnreadNum = (num) => {
    let tempNavigation = deepClone(navigation);
    tempNavigation[2].counter = num;
    setNavigation(tempNavigation);
  };

  const loadCompany = async () => {
    let data = await companyDetail(user?.userInfo?.companyId);
    if (data) {
      if (data.banner) {
        data.bannerType = getFileTypeByPath(localizeImg(data.banner));
      }
      setUserCompanyDetail(data);
    }
  };

  const initNavigation = () => {
    let tempNavigation = deepClone(navigation);

    const navigationVal = [];

    if (user?.userInfo?.type == UserType.BUYER) {
      navigationVal.push(
        {
          title: t("HOME"),
          icon: "home",
          url: "/",
        },
        {
          title: t("PRODUCTS"),
          icon: "store",
          url: PathTools.joinPath(
            Path.shopSearch,
            localizeValue(i18n.language, userCompanyDetail?.companyName)
          ),
        },
        {
          title: t("MESSAGING"),
          icon: "message",
          url: PathTools.joinPath(
            Path.messaging,
            localizeValue(i18n.language, userCompanyDetail?.companyName)
          ),
          counter: 0,
          colorCounter: "#00BC99",
        },
        {
          title: t("MY_PROFILE"),
          slug: "profile-circle",
          icon: "profile-circle",
          url: PathTools.joinPath(
            Path.companyDetail,
            localizeValue(
              i18n.language,
              user?.userInfo?.companyDesc?.companyName
            )
          ),
          params: { id: user.userId },
          // dropdown: [
          //   {
          //     title: t("MY_PROFILE"),
          //     icon: "profile-circle",
          //     url: PathTools.joinPath(
          //       Path.companyDetail,
          //       localizeValue(
          //         i18n.language,
          //         user?.userInfo?.companyDesc?.companyName
          //       )
          //     ),
          //     params: { id: user.userId },
          //   },
          //   {
          //     title: t("EDIT_PROFILE"),
          //     icon: "edit",
          //     url: PathTools.joinPath(
          //       Path.companyInformation,
          //       localizeValue(
          //         i18n.language,
          //         user?.userInfo?.companyDesc?.companyName
          //       )
          //     ),
          //     params: {
          //       companyId:
          //         user?.userInfo?.companyDesc.id ||
          //         user?.userInfo?.companyDesc._id,
          //     },
          //   },
          // ],
        },
        {
          title: t("EVENTS"),
          slug: "vinbip_services",
          icon: "schedule",
          url: Path.events,
        },
        {
          title: t("VINBIP_MEMBERSHIP"),
          slug: "vinbip_services",
          icon: "diamond",
          developing: true,
        },
        {
          title: t("Settings"),
          slug: "settings",
          icon: "setting",
          dropdown: [
            {
              title: t("PASSWORD_SETTING"),
              url: PathTools.joinPath(
                Path.settingsSecurity,
                localizeValue(i18n.language, userCompanyDetail?.companyName)
              ),
            },
            {
              title: t("LANGUAGE_SETTINGS"),
              url: Path.languageSetting,
            },
            {
              title: t("WECHAT_BIND"),
              url: Path.wechatBinding,
            },
            {
              title: t("PRIVACY_POLICY"),
              url: Path.settingsPrivacy,
            },
          ],
        }
      );
    }

    if (user?.userInfo?.type == UserType.SUPPLIER) {
      navigationVal.push(
        {
          title: t("HOME"),
          icon: "home",
          url: "/",
        },
        {
          title: t("MY_NETWORK"),
          icon: "partners",
          url: PathTools.joinPath(
            isWinery ? Path.partners : Path.shopSearch,
            localizeValue(i18n.language, userCompanyDetail?.companyName)
          ),
        },
        {
          title: t("MESSAGING"),
          icon: "message",
          url: PathTools.joinPath(
            Path.messaging,
            localizeValue(i18n.language, userCompanyDetail?.companyName)
          ),
          counter: 0,
          colorCounter: "#00BC99",
        },
        {
          title: t("MY_PROFILE"),
          slug: "profile-circle",
          icon: "profile-circle",
          url: PathTools.joinPath(
            Path.companyDetail,
            localizeValue(
              i18n.language,
              user?.userInfo?.companyDesc?.companyName
            )
          ),
          params: { id: user.userId },
          // dropdown: [
          //   {
          //     title: t("MY_PROFILE"),
          //     icon: "profile-circle",
          //     url: PathTools.joinPath(
          //       Path.companyDetail,
          //       localizeValue(
          //         i18n.language,
          //         user?.userInfo?.companyDesc?.companyName
          //       )
          //     ),
          //     params: { id: user.userId },
          //   },
          //   {
          //     title: t("EDIT_PROFILE"),
          //     icon: "edit",
          //     url: PathTools.joinPath(
          //       Path.companyInformation,
          //       localizeValue(
          //         i18n.language,
          //         user?.userInfo?.companyDesc?.companyName
          //       )
          //     ),
          //     params: {
          //       companyId:
          //         user?.userInfo?.companyDesc.id ||
          //         user?.userInfo?.companyDesc._id,
          //     },
          //   },
          // ],
        },
        {
          title: t("MARKET_PLACE"),
          icon: "store",
          url: PathTools.joinPath(
            Path.products,
            localizeValue(i18n.language, userCompanyDetail?.companyName)
          ),
          // dropdown: [
          //   {
          //     title: t("ROLE_PRODUCT_MANAGEMEENT"),
          //     icon: "store",
          //     url: PathTools.joinPath(
          //       Path.products,
          //       localizeValue(i18n.language, userCompanyDetail?.companyName)
          //     ),
          //   },
          // {
          //   title: t("ORDERS"),
          //   icon: "pallet",
          //   url: PathTools.joinPath(
          //     Path.orders,
          //     localizeValue(i18n.language, userCompanyDetail?.companyName)
          //   ),
          // },
          // {
          //   title: t("SHIPPING_LOCATIONS"),
          //   icon: "warehouse",
          //   url: PathTools.joinPath(
          //     Path.supplierWarehouse,
          //     localizeValue(i18n.language, userCompanyDetail?.companyName)
          //   ),
          // },
          // ],
        },
        {
          title: t("SHIPPING_LOCATIONS"),
          icon: "warehouse",
          url: PathTools.joinPath(
            Path.supplierWarehouse,
            localizeValue(i18n.language, userCompanyDetail?.companyName)
          ),
        },
        // {
        //   title: t("INSIGHTS"),
        //   icon: "leaderboard",
        //   url: PathTools.joinPath(
        //     Path.dashboard,
        //     localizeValue(i18n.language, userCompanyDetail?.companyName)
        //   ),
        // },
        {
          title: t("VINBIP_MEMBERSHIP"),
          slug: "vinbip_services",
          icon: "diamond",
          developing: true,
        },
        {
          title: t("Settings"),
          slug: "settings",
          icon: "setting",
          dropdown: [
            {
              title: t("PASSWORD_SETTING"),
              url: PathTools.joinPath(
                Path.settingsSecurity,
                localizeValue(i18n.language, userCompanyDetail?.companyName)
              ),
            },
            {
              title: t("LANGUAGE_SETTINGS"),
              url: Path.languageSetting,
            },
            {
              title: t("WECHAT_BIND"),
              url: Path.wechatBinding,
            },
            // {
            //   title: t("PRIVACY_POLICY"),
            //   url: Path.settingsPrivacy,
            // },
          ],
        },
        {
          title: t("PRIVACY_POLICY"),
          slug: "privacy_policy",
          icon: "help",
          url: Path.settingsPrivacy,
        }
      );
    }

    const loginFromAdmin = "LOGIN_IN_FROM_ADMIN";
    let isLoginFromAdmin = getCache(loginFromAdmin);
    if (user?.userInfo?.type == UserType.ADMIN || isLoginFromAdmin) {
      navigationVal.push({
        title: "Admin tools",
        icon: "edit",
        dropdown: [
          {
            title: t("HOME"),
            icon: "home",
            url: "/",
            //iconType: "svg",
          },
          {
            title: t("MESSAGING"),
            icon: "message",
            url: PathTools.joinPath(
              Path.messaging,
              localizeValue(i18n.language, userCompanyDetail?.companyName)
            ),
            counter: 0,
            colorCounter: "#00BC99",
          },
          {
            title: t("SUPPLIER_LIST"),
            icon: "edit",
            url: PathTools.joinPath(
              Path.vinbipSupplierList,
              localizeValue(i18n.language, userCompanyDetail?.companyName)
            ),
          },
          {
            title: t("BUYER_LIST"),
            icon: "edit",
            url: PathTools.joinPath(
              Path.vinbipBuyerList,
              localizeValue(i18n.language, userCompanyDetail?.companyName)
            ),
          },
        ],
      });
    }

    tempNavigation = navigationVal;
    setNavigation(tempNavigation);
  };

  const onNavigationItemClick = (index) => {
    onClose && onClose();
    setNavigationIndex(index);
    if (index == 2) {
      setMessageUnreadNum(0);
    }
  };

  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
        })}
      >
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            !x.dropdown ? (
              <NavLink
                className={cn(styles.item, {
                  [styles.active]: pathname === x.url,
                })}
                to={{
                  pathname: x.developing ? Path.developing : x.url,
                  state: x.params,
                }}
                key={index}
                onClick={onNavigationItemClick.bind(this, index)}
              >
                {x.iconType == "svg" ? (
                  <img
                    src={x.icon}
                    width={24}
                    style={{ marginRight: "12px" }}
                  />
                ) : (
                  <div>
                    <Icon name={x.icon} size="20" />
                  </div>
                )}
                {x.title}
                {x.counter != 0 && (
                  <div
                    className={styles.counter}
                    style={{ backgroundColor: x.colorCounter }}
                  >
                    {x.counter}
                  </div>
                )}
              </NavLink>
            ) : (
              // <></>
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
