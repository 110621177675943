import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import "./style/Reg-Intro.less";
import regBgImg from "../../resource/images/user/reg/reg_bg.png";

export default function RegIntro() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="reg-intro-page">
      <img className="reg-intro-bg-img" src={regBgImg} />
      <div className="reg-intro-bg-container">
        <div className="reg-intro">
          <div className="slog">WINE B2B TRADING NETWORKING</div>
          <div className="navigator-container">
            <Button
              className="supplier-btn"
              onClick={() => history.push("sign-up", { type: "SUPPLIER" })}
            >
              {t("I_AM_SUPPLIER")}
            </Button>
            <Button
              className="buyer-btn"
              onClick={() => history.push("sign-up", { type: "BUYER" })}
            >
              {t("I_AM_BUYER")}
            </Button>
          </div>
          <div className="login-imme ">
            {t("ALREADY_ACCOUNT")}
            <span onClick={() => history.go(-1)}>{t("QUICK_LOGIN")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
