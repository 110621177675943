import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { BackTop, Layout } from "antd";
import { authValidate } from "../../api/common";
import RightSider from "../shared/righ-sider/RightSider";
import LeftSider from "../shared/left-sider/LeftSider";
import AlertCompanyNotVerified from "../common/alert/AlertCompanyNotVerified";
import MyNavbar from "../navbar/MyNavbar";
import { logUserAction } from "../../action-loggers/action-loggers";
import { useUserId } from "../../contexts/UserIdProvider";
import "./style/baseprivateroute.less";

export default function BasePrivateRoute({ component: Component, ...rest }) {
  const validate = authValidate();
  const { Header, Content } = Layout;
  const { user } = useUserId();
  const { userId } = user;

  useEffect(() => {
    logUserAction(userId, rest.path, rest.computedMatch.params.id);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (validate) {
          return (
            <Layout className="base-route">
              <Header className="header-nav">
                <MyNavbar />
              </Header>
              <AlertCompanyNotVerified />
              <Layout className="layout">
                <LeftSider showText={true}/>
                <Content className="content-middle">
                  <BackTop />
                  <Component {...props}/>
                </Content>
                <RightSider />
              </Layout>
            </Layout>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { refer: props.location, clearToken: true },
              }}
            />
          );
        }
      }}
    />
  );
}
