import React from "react";
import { Breadcrumb, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { Link } from "react-router-dom";
import shopApplyImage from "../../../resource/images/shop/apply/company_qr_code_sale.jpg";
export default function ShopApply() {
  const { t } = useTranslation();
  const { user } = useUserId();

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("APPLY_AS_SUPPLIER")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management">
        <div className="orders-title common-management-title">
          {t("APPLY_AS_SUPPLIER")}
        </div>
        <Row>
          <Col lg={12}>
            <br></br>
            <h1>Lorem ipsum dolor sit amet :</h1>
            <ol>
              <li>Donec placerat quam et sodales lobortis</li>
              <li>
                elementum condimentum erat. Donec ultricies justo sit amet
                laoreet pellentesque.
              </li>
              <li>
                Donec vitae bibendum ante, a fringilla nisi. Nullam id lectus et
                nunc sollicitudin venenatis.{" "}
              </li>
              <li>Maecenas maximus ex id rutrum finibus. </li>
              <li>
                Integer porttitor maximus lorem viverra auctor. Nunc ultrices
                commodo dolor,
              </li>
              <li>Nullam libero erat, consectetur fringilla rhoncus quis</li>
            </ol>
          </Col>
          <Col lg={12}>
            <img
              src={shopApplyImage}
              alt="Company QR CODE"
              style={{
                width: "80%",
              }}
            ></img>
          </Col>
        </Row>
      </div>
    </div>
  );
}
