import { message, Upload } from "antd";
import { useUserId } from "../../../contexts/UserIdProvider";
import { getFileType, getUploadFileAction } from "../../../api/common";
import { getCacheTokens } from "../../../utils/cache";
import { getBase64 } from "../../../utils/utils";
import { UploadFileType } from "../../../constants/constants";
import { useTranslation } from "react-i18next";

export default function UploadBase(props) {
  const { children, onLoading, onLoaded, fileType } = props;
  const { t } = useTranslation();
  const { user } = useUserId();
  const action = getUploadFileAction(user.userId);
  const token = getCacheTokens();
  const tokenValue = token ? token.value : "";
  const authorization = "Bearer " + tokenValue;

  const layout_upload = {
    name: "file",
    action: action,
    headers: { authorization },
    showUploadList: false,
    maxCount: 1,
    onChange: async (info) => {
      let { file } = info;
      let { percent, status, response, type, name, size } = file;
      let _fileType = getFileType(type);
      console.log("------------upload base:", percent, status, file);
      if (status === "uploading") {
        if (percent === 0) {
          let previewImage = await getBase64(file);
          onLoading && onLoading(_fileType, previewImage);
        }
      } else if (status === "done") {
        console.log("------upload base loaded:", info);
        let { result } = response;
        onLoaded && onLoaded(_fileType, result, name, size);
        //dialog.success(`${info.file.name} file uploaded successfully`);
      } else if (status === "error") {
        console.log("file upload fail");
        //dialog.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      if (fileType) {
        let _filetype = getFileType(file.type);
        if (fileType === UploadFileType.IMAGE && fileType !== _filetype) {
          message.error(t("IS_NOT_IMAGE_FILE"));
          return Upload.LIST_IGNORE;
        } else if (
          fileType === UploadFileType.VIDEO &&
          fileType !== _filetype
        ) {
          message.error(t("IS_NOT_VIDEO_FILE"));
          return Upload.LIST_IGNORE;
        }
      }
      return true;
    },
  };

  return <Upload {...layout_upload}>{children}</Upload>;
}
