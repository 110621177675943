import React, { useState, useEffect } from "react";
import { Image, Row, Col } from "antd";
import "./style/feedImagePanel.less";
import { localizeImg } from "../../constants/constants";

export default function FeedImagePanel(props) {
  const { images, width, onImageClick } = props;

  return (
    <div
      className="feed-images-panel"
      style={{ width: width ? width + "px" : "100%" }}
    >
      {images && images.length === 1 && (
        <div
          className="feed-images-container"
          onClick={() => onImageClick && onImageClick()}
        >
          {images.map((item, index) => {
            return (
              <Image
                preview={false}
                src={localizeImg(item)}
                className="feed-list-image"
                key={index}
              />
            );
          })}
        </div>
      )}
      {images && images.length === 2 && (
        <div className="feed-images-container">
          <div
            className="feed-item-two-image-box"
            onClick={() => onImageClick && onImageClick()}
          >
            {images.map((item, index) => {
              return (
                <div
                  className="feed-list-image-two-item-panel feed-image-item-box"
                  key={index}
                >
                  <Image
                    preview={false}
                    src={localizeImg(item)}
                    className="feed-list-image-two-item"
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {images && images.length === 3 && (
        <div className="feed-images-container">
          <div
            className="feed-item-three-image-box"
            onClick={() => onImageClick && onImageClick()}
          >
            <div className="feed-list-image-three-one-item-panel feed-item-three-image-box">
              <Image
                preview={false}
                src={localizeImg(localizeImg(images[0]))}
                className="feed-list-image-three-one-item"
              />
            </div>
            <div className="feed-list-image-three-right-panel">
              <div className="feed-list-image-three-two-item-panel feed-item-three-image-box">
                <Image
                  preview={false}
                  src={localizeImg(localizeImg(images[1]))}
                  className="feed-list-image-three-two-item"
                />
              </div>
              <div className="feed-list-image-three-two-item-panel feed-item-three-image-box">
                <Image
                  preview={false}
                  src={localizeImg(localizeImg(images[2]))}
                  className="feed-list-image-three-two-item"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {images && images.length === 4 && (
        <div className="feed-images-container">
          <div
            className="feed-item-four-image-box"
            onClick={() => onImageClick && onImageClick()}
          >
            <div className="feed-item-four-one-image-box feed-image-item-box">
              <Image
                preview={false}
                src={localizeImg(images[0])}
                className="feed-image-item-four-content"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="feed-item-four-two-image-box feed-image-item-box">
                <Image
                  preview={false}
                  src={localizeImg(images[1])}
                  className="feed-image-item-content"
                />
              </div>
              <div className="feed-item-four-two-image-box feed-image-item-box">
                <Image
                  preview={false}
                  src={localizeImg(images[2])}
                  className="feed-image-item-content"
                />
              </div>
              <div className="feed-item-four-two-image-box feed-image-item-box">
                <Image
                  preview={false}
                  src={localizeImg(images[3])}
                  className="feed-image-item-content"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {images && images.length > 4 && (
        <div className="feed-images-container">
          <div className="feed-list-nine-image-panel">
            <div className="feed-list-nine-image-left-panel">
              {images.slice(0, 2).map((item, index) => {
                return (
                  <div
                    className="feed-list-nine-image-left-box feed-image-item-box"
                    onClick={() => onImageClick && onImageClick()}
                    key={index}
                  >
                    <Image
                      preview={false}
                      src={localizeImg(item)}
                      className="feed-list-nine-image-left-item"
                    />
                  </div>
                );
              })}
            </div>
            <div className="feed-list-nine-image-right-panel">
              {images.slice(2, 5).map((item, index) => {
                return (
                  <div
                    className="feed-list-nine-image-right-box feed-image-item-box"
                    onClick={() => onImageClick && onImageClick()}
                    key={index}
                  >
                    <Image
                      preview={false}
                      src={localizeImg(item)}
                      className="feed-list-nine-image-right-item"
                    />
                    {index == 2 && (
                      <div className="feed-list-nine-image-last-cover">
                        <div className="feed-list-nine-image-last-bg feed-list-nine-image-last-tips">
                          {"+ "}
                          {images.length - 5}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
