import { Button } from "antd";
import { useTranslation } from "react-i18next";

export default function VerifyButton(props) {
  let { text, history } = props;
  console.log(text)
  const { t } = useTranslation();

  return (
    <>
      {"🔥 "} {t(text)}
      <Button
        type="primary"
        className={"button-primary"}
        style={{
          width: "100%",
          marginTop: 16,
        }}
        onClick={() => {
          history.push("/company/verify-deprecated");
        }}
      >
        {t("VERIFY_NOW")}
      </Button>
    </>
  );
}
