import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../contexts/UserIdProvider";

// import {
//   ApiVersion,
//   DataType,
//   LATEST_API_VERSION,
//   LogSeverity,
//   shopifyApi,
//   Shopify,
//   ShopifyAPI,
// } from "@shopify/shopify-api";
// import "@shopify/shopify-api/adapters/node";
import { openShopifyStorefrontAccesstoken } from "../../api/open";
// import { restResources } from "@shopify/shopify-api/rest/admin/2023-01";
import { statusCode } from "../../constants/statusCode.enum";
// import Client from "shopify-buy"; //shopify-buy:https://github.com/Shopify/js-buy-sdk#fetching-products
// import Client from "graphql-js-client";
// import {gql} from 'babel-plugin-graphql-js-client-transform';
// import typeBundle from './types';

export default function TestShopify() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const [products, setProducts] = useState([]);

  useEffect(() => {
    getShopifyStorefrontAccessToken();
  }, []);

  const getShopifyStorefrontAccessToken = async () => {
    // let result = await openShopifyStorefrontAccesstoken();
    // if (result.statusCode == statusCode.SUCCESS) {
    //   let accessTokenObj = JSON.parse(result.result);
    //   console.log(
    //     "getShopifyStorefrontAccessToken result:",
    //     accessTokenObj.storefront_access_token.access_token
    //   );
    //   productQueryByShopifyBy(
    //     accessTokenObj.storefront_access_token.access_token
    //   );
    // }
  };

  /***
   * query by shopify-buy
   */
  const productQueryByStorefront = async (storefrontAccessToken) => {
    /*
    let client = new Client(typeBundle, {
      url: "https://graphql.myshopify.com/api/graphql",
      fetcherOptions: {
        headers: {
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
      },
    });

    client.send(gql(client)`
      mutation {
        checkoutCreate(input: {}) {
          userErrors {
            message
            field
          }
          checkout {
            id
            webUrl
            subtotalPrice
            totalTax
            totalPrice
            lineItems (first:250) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                node {
                  title
                  variant {
                    title
                    image {
                      src
                    }
                    price
                  }
                  quantity
                }
              }
            }
          }
        }
      }
    `).then((res) => {
      console.log("checkout:",res);
      // this.setState({
      //   checkout: res.model.checkoutCreate.checkout,
      // });
    });

    client.send(gql(client)`
      query {
        shop {
          name
          description
          products(first:20) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                title
                options {
                  name
                  values
                }
                variants(first: 250) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  edges {
                    node {
                      title
                      selectedOptions {
                        name
                        value
                      }
                      image {
                        src
                      }
                      price
                    }
                  }
                }
                images(first: 250) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  edges {
                    node {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `).then((res) => {
      console.log("products:",res);
      // this.setState({
      //   shop: res.model.shop,
      //   products: res.model.shop.products,
      // });
    });
    */
  };

  /***
   * query by shopify-buy
   */
  const productQueryByShopifyBy = async (storefrontAccessToken) => {
    // const client = Client.buildClient({
    //   storefrontAccessToken: storefrontAccessToken,
    //   domain: "vinbip-test.myshopify.com",
    // });

    // client.collection.fetchAllWithProducts().then((res) => {
    //   console.log("Product from shopify:", res);
    //   setProducts(res);
    // });
  };

  const getShopifyInstance = () => {
    // const shopify = shopifyApi({
    //   apiKey: "47785c8899f3c6397190653dd5ab9e5b",
    //   apiSecretKey: "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",
    //   apiVersion: LATEST_API_VERSION,
    //   isCustomStoreApp: true, // this MUST be set to true (default is false)
    //   scopes: [],
    //   isEmbeddedApp: false,
    //   hostName: "vinbip-test.myshopify.com",
    //   // Mount REST resources.
    // });

    // return shopify;
  };

  return (
    <div>
      {products.map((productItem, productIndex) => {
        return (
          <div
            key={productIndex}
            style={{
              marginBottom: "20px",
              padding: "20px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <div>Product title:{productItem.title}</div>
            <div>Product id:{productItem.id}</div>
            <div>Product desc:{productItem.products[0].description}</div>
            <div>Product type:{productItem.products[0].productType}</div>
            <div>Product vendor:{productItem.products[0].vendor}</div>
            <div>Published at:{productItem.products[0].publishedAt}</div>
            <div>
              Product variants:
              {JSON.stringify(productItem.products[0].variants)}
            </div>
          </div>
        );
      })}
    </div>
  );
}
