import { Space } from "antd";
import "./style/pageTitle.less";

export default function PageTitle(props) {
  let { title, num } = props;
  return (
    <div className="list-title">
      <Space size={3}>
        {title}
        <span className="title-num">{num}</span>
      </Space>
    </div>
  );
}
