import { useState, useEffect } from "react";
import { Button, Input, Upload, Divider, Modal, Switch } from "antd";
import { useUserId } from "../../../../contexts/UserIdProvider";
import photoIcon from "../../../../resource/icons/feed/photo.svg";
import photoUnSelectIcon from "../../../../resource/icons/feed/photo_unselect.svg";
import videoIcon from "../../../../resource/icons/feed/video.svg";
import videoUnSelectIcon from "../../../../resource/icons/feed/video_unselect.svg";
import atIcon from "../../../../resource/icons/feed/mention.svg";
import atUnSelectIcon from "../../../../resource/icons/feed/mention_unselect.svg";
import writeIcon from "../../../../resource/icons/feed/write.svg";
import writeUnSelectIcon from "../../../../resource/icons/feed/write_unselect.svg";
import downloadArrowIcon from "../../../../resource/icons/feed/download_arrow.svg";
import closeIcon from "../../../../resource/icons/common/close.svg";
import optionIcon from "../../../../resource/icons/feed/option.svg";
import downArrowSolid from "../../../../resource/icons/feed/down_arrow_solid.svg";
import UploadPictureCard from "../../../common/upload/UploadPictureCard";
import {
  localizeImg,
  localizeValue,
  UploadFileType,
} from "../../../../constants/constants";
import VideoBasic from "../../../common/video/VideoBasic";
import { confirmModal } from "../../../../utils/utils";
import { create } from "../../../../api/feed";
import { useTranslation } from "react-i18next";
import SelectFriend from "../../mention/SelectFriend";
import { deepClone } from "../../../../utils/utils";
import { wordBreak } from "../../../../utils/wordBreak";
import { Prompt, useHistory } from "react-router-dom";
import "./style/feedPost.less";
import SelectFriendMultiple from "../../mention/SelectFriendMultiple";
import { checkLimit } from "../../../../api/common";
import { RoleLimit } from "../../../../constants/roleType.enum";
import UploadVideo from "../../../common/upload/UploadVideo";
import UploadBase from "../../../common/upload/UploadBase";
import { PlusOutlined } from "@ant-design/icons";
import AvatarBasic from "../../../common/avatar/AvatarBasic";
import {
  companyImporterDesc,
  companyWineryDesc,
} from "../../../../api/company";
import { statusCode } from "../../../../constants/statusCode.enum";
import { browseRecordCreate } from "../../../../api/browseRecord";
import { BrowseRecordType } from "../../../../constants/browseRecord.enum";

export default function FeedPost(props) {
  const { onSuccess, onClose, onSelectFeedScope, feedScopeItem } = props;
  const { user, isWinery } = useUserId();
  const { userId } = user;
  const coId = user?.userInfo?.companyId;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { TextArea } = Input;

  const [currentUser, setCurrentUser] = useState(user?.userInfo);
  const [content, setContent] = useState("");
  const [imageUploadVisible, setImageUploadVisible] = useState(false);
  const [videoUploadVisible, setVideoUploadVisible] = useState(false);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [type, setType] = useState("");

  const [onHoverPhoto, setOnHoverPhoto] = useState(false);
  const [onHoverVideo, setOnHoverVideo] = useState(false);
  const [onHoverAt, setOnHoverAt] = useState(false);
  const [onHoverWrite, setOnHoverWrite] = useState(false);

  const [userCompanyDetail, setUserCompanyDetail] = useState();

  const [selectFriendVisible, setSelectFriendVisible] = useState(false);
  const [selectFriendMultipleVisible, setSelectFriendMultipleVisible] =
    useState(false);
  let visible = true;
  const [mentions, setMentions] = useState([]);
  const [mentionIds, setMentionIds] = useState([]);
  const [shieldUserIds, setShieldUserIds] = useState([]);
  const [scope, setScope] = useState({});

  const [showBackPrompt, setShowBackPrompt] = useState(undefined);
  const [showSaveDraftModal, setShowSaveDraftModal] = useState(false);
  const [navPathName, setNavPathName] = useState("");

  const [feedStatus, setFeedStatus] = useState(1);

  const fileList = [
    {
      uid: "-xxx",
      percent: 50,
      name: "image.png",
      status: "uploading",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ];

  useEffect(() => {
    if (showBackPrompt != undefined && !showBackPrompt && navPathName) {
      if (feedStatus == 2) {
        createFeed();
      }
      history.push(navPathName);
    }
  }, [showBackPrompt]);

  useEffect(() => {
    setScope(feedScopeItem);
  }, [feedScopeItem]);

  useEffect(() => {
    if (user && user.userInfo) {
      setCurrentUser(user.userInfo);
    }
  }, [user]);

  useEffect(() => {
    if (currentUser) {
      loadCompany();
    }
  }, [currentUser]);

  useEffect(() => {
    setShowBackPromptPro();
  }, [content, images, videos]);

  const createFeed = async () => {
    if (content || images.length > 0 || videos.length > 0) {
      let pms = {
        userId,
        content,
        visible,
        mentions,
        mentionIds,
        images,
        videos,
        shieldUserIds,
        status: feedStatus,
        // type: type,
      };

      let result = await create(pms);
      if (result) {
        pms._id = result;

        let browseRecordPms = {
          coId,
          userId: user.userId,
          busType: videos.length
            ? BrowseRecordType.POST_VIDEO
            : BrowseRecordType.POST_FEED,
          busId: result,
        };
        browseRecordCreate(browseRecordPms);

        onSuccess && onSuccess(pms);
      }
      setImageUploadVisible(false);
      setVideoUploadVisible(false);
      setImages([]);
      setVideos([]);
      setContent("");
    }
  };

  const onImageSuccess = (files) => {
    setImages(files);
  };

  const onConfirm = (type) => {
    if (type === UploadFileType.IMAGE) {
      if (images && images.length > 0) {
        confirmModal(
          t("TIPS"),
          t("CONFIRM_REMOVE_UPLOAD_CONTENT"),
          t("CONFIRM"),
          t("CANCEL"),
          onSetImageUploadVisible
        );
      } else {
        onSetImageUploadVisible();
      }
    } else {
      if (videos && videos.length > 0) {
        confirmModal(
          t("TIPS"),
          t("CONFIRM_REMOVE_UPLOAD_CONTENT"),
          t("CONFIRM"),
          t("CANCEL"),
          onSetVideoUploadVisible
        );
      } else {
        onSetVideoUploadVisible();
      }
    }
  };

  const loadCompany = async () => {
    let data;
    const pms = { coId: currentUser.companyId };
    if (isWinery) {
      data = await companyWineryDesc(pms);
    } else {
      data = await companyImporterDesc(pms);
    }
    if (data && data.statusCode === statusCode.SUCCESS && data.result) {
      setUserCompanyDetail(data.result);
    }
  };

  const onSetImageUploadVisible = () => {
    console.log("----------------onSetImageUploadVisible:", currentUser);
    if (checkLimit(currentUser, RoleLimit.FEED, history)) {
      let status = !imageUploadVisible;
      if (status) {
        setImages([]);
      }
      setImageUploadVisible(status);
    }
  };

  const onSetVideoUploadVisible = () => {
    if (checkLimit(currentUser, RoleLimit.FEED, history)) {
      let status = !videoUploadVisible;
      setVideoUploadVisible(status);
      setVideos([]);
      setPercent(0);
    }
  };

  const OnVideoLoading = (percent) => {
    // setVideoUploadVisible(true);
    console.log("-------video loading:", percent);

    setLoading(true);
    setVideos([]);
    if (percent) {
      setPercent(percent);
    }
  };

  const OnVideoSuccess = (fileType, file) => {
    console.log("-------video success:", fileType, file);
    setLoading(false);
    setVideos([file]);
  };

  const onSelectFriend = (name, id, userType) => {
    if (name) {
      name = "@" + name;
      let list = deepClone(mentions);
      list.push({ id, name, type: userType });
      setMentions(list);
      let _content = content + name;
      setContent(_content);
    }
    if (id) {
      let list = deepClone(mentionIds);
      list.push(id);
      setMentionIds(list);
    }
    setSelectFriendVisible(false);
  };

  const onShieldFriends = (list) => {
    console.log("------shield userids:", list);
    if (list && list.length > 0) {
      setShieldUserIds(list);
    }
    setSelectFriendMultipleVisible(false);
  };

  const onKeyDownchange = (e) => {
    if (e.altKey && e.keyCode == 13) {
      //Alt+Enter换行
      wordBreak("msgbox");
    } else if (e.keyCode == 13) {
      createFeed();
    }
  };

  const onTextChange = (e) => {
    if (checkLimit(currentUser, RoleLimit.FEED, history)) {
      setContent(e.target.value);
    }
  };

  const setShowBackPromptPro = () => {
    if (content.length > 0 || images.length > 0 || videos.length > 0) {
      setShowBackPrompt(undefined);
    } else {
      setShowBackPrompt(false);
    }
  };

  const onPublishTender = () => {
    history.push("/tender/create/");
  };

  const onEmojiClick = (event, emojiObject) => {
    /**
     * chosenEmoji.emoji = 😀 value
     * todo : Logic for emoji goes here
     */
  };

  const onSaveDraftClick = () => {
    setFeedStatus(2);
    // setShowBackPrompt(false);
    onClose && onClose();
  };

  const onDiscardDraftClick = () => {
    // setShowBackPrompt(false);
    onClose && onClose();
  };

  const onSelectFeedScopeClick = () => {
    onSelectFeedScope && onSelectFeedScope();
  };

  return (
    <div className="publish-post-container">
      <div className="publish-post-header">
        <div style={{ width: "18px", height: "18px" }}></div>
        <div>{t("CREATE_FEEDS")}</div>
        <img
          src={closeIcon}
          className="icon"
          onClick={() => setShowSaveDraftModal(true)}
        />
      </div>
      <div className="publish-post-content">
        <div className="user-info-panel">
          <AvatarBasic
            picture={localizeImg(userCompanyDetail?.avatar)}
            cname="avatar"
            displayName={
              userCompanyDetail?.displayName ||
              localizeValue(i18n.language, userCompanyDetail?.companyName)
            }
            size={41}
          />
          <div className="right-part">
            <span className="user-name">
              {userCompanyDetail?.displayName || localizeValue(i18n.language, userCompanyDetail?.companyName)}
            </span>
            <div
              className="feed-scope"
              onClick={onSelectFeedScopeClick.bind(this)}
            >
              <img src={optionIcon} />
              <span className="option-label">
                {scope && scope.label ? scope.label : t("PUBLIC")}
              </span>
              <img src={downArrowSolid} />
            </div>
          </div>
        </div>
        <TextArea
          id="msgbox"
          className="publish-content"
          value={content}
          placeholder={t("START_A_POST")}
          onChange={onTextChange.bind(this)}
          onKeyDown={(e) => onKeyDownchange(e)}
        />
      </div>
      {/* <div className="publish-content-wrapper">
        {userCompanyDetail && (
          <div
            style={{
              width: "51px",
              height: "51px",
              marginRight: "15px",
            }}
          >
            <AvatarBasic
              picture={localizeImg(userCompanyDetail.avatar)}
              cname="avatar"
              displayName={
                userCompanyDetail?.displayName || userCompanyDetail?.companyName
              }
              size={51}
            />
          </div>
        )}
        <TextArea
          id="msgbox"
          className="publish-content"
          value={content}
          placeholder={t("START_A_POST")}
          onChange={onTextChange.bind(this)}
          onKeyDown={(e) => onKeyDownchange(e)}
        />
      </div> */}

      <div className="publish-post-footer">
        <div className="ext-container">
          <Button
            type="text"
            disabled={videoUploadVisible}
            onClick={onSetImageUploadVisible.bind(this)}
            onMouseEnter={() => setOnHoverPhoto(true)}
            onMouseLeave={() => setOnHoverPhoto(false)}
            style={{
              width: "50px",
            }}
          >
            <div
              className={
                "ext-thumb-container " +
                (imageUploadVisible && "ext-thumb-container-selected")
              }
            >
              <img
                src={onHoverPhoto ? photoIcon : photoUnSelectIcon}
                className="icon-feed-create"
                title={t("PHOTO")}
              />
            </div>
          </Button>
          <Button
            type="text"
            disabled={imageUploadVisible}
            onClick={onSetVideoUploadVisible.bind(this)}
            onMouseEnter={() => setOnHoverVideo(true)}
            onMouseLeave={() => setOnHoverVideo(false)}
            style={{
              width: "50px",
            }}
          >
            <div className="ext-thumb-container">
              <img
                src={onHoverVideo ? videoIcon : videoUnSelectIcon}
                className="icon-feed-create"
                title={t("VIDEO")}
              />
            </div>
          </Button>
          <Button
            type="text"
            onClick={() => setSelectFriendVisible(true)}
            onMouseEnter={() => setOnHoverAt(true)}
            onMouseLeave={() => setOnHoverAt(false)}
            style={{
              width: "50px",
            }}
          >
            <div className="ext-thumb-container">
              <img
                src={onHoverAt ? atIcon : atUnSelectIcon}
                className="icon-feed-create"
                title={t("MENTION")}
              />
            </div>
          </Button>
          {/* <div
            className="ext-thumb-container"
            onClick={() => setSelectFriendVisible(true)}
            onMouseEnter={() => setOnHoverAt(true)}
            onMouseLeave={() => setOnHoverAt(false)}
            style={{
              width:'50px'
            }}
          >
            <img
              src={onHoverAt ? atIcon : atUnSelectIcon}
              className="icon-feed-create"
              title={t("MENTION")}
            />
          </div> */}
        </div>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <Button
          onClick={createFeed.bind(this)}
          className={
            !loading && (content || images.length > 0 || videos.length > 0)
              ? "btn-publish"
              : "btn-publishing"
          }
          style={{
            marginLeft: "50px",
          }}
          disabled={loading}
        >
          {t("POST")}
        </Button>
      </div>
      {imageUploadVisible && (
        <div className="ext-container-file">
          <div
            className="ext-container-file-close"
            onClick={onConfirm.bind(this, UploadFileType.IMAGE)}
          >
            ×
          </div>
          <div className="ext-container-file-title">
            {t("UPLOAD_UP_TO_PICTURES")}
            {process.env.REACT_APP_UPLAOD_IMAGE_COUNT}
            {t("PICTURE_UNIT")}
          </div>
          <UploadPictureCard onSuccess={onImageSuccess.bind(this)} />
        </div>
      )}
      {videoUploadVisible && (
        <div className="ext-container-file ext-container-video">
          <div
            className="ext-container-file-close"
            onClick={onConfirm.bind(this, UploadFileType.VIDEO)}
          >
            ×
          </div>
          {/* <div className="ext-container-file-title">已上传{percent}%</div> */}
          <div className="ext-container-file-video">
            {loading && <Upload listType="picture-card" fileList={fileList} />}
            <div style={{ visibility: loading ? "hidden" : "visible" }}>
              <UploadVideo
                onLoaded={OnVideoSuccess.bind(this)}
                onLoading={OnVideoLoading.bind(this)}
                fileType={UploadFileType.VIDEO}
              />
            </div>
            {videos && videos.length > 0 && (
              <VideoBasic
                url={localizeImg(videos[0])}
                width={290}
                height={163}
              />
            )}
          </div>
        </div>
      )}
      <Modal
        destroyOnClose={true}
        title={null}
        visible={selectFriendVisible}
        footer={null}
        className="select-friend-modal"
        onCancel={() => setSelectFriendVisible(false)}
      >
        <SelectFriend onSelected={onSelectFriend.bind(this)} />
      </Modal>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={selectFriendMultipleVisible}
        footer={null}
        className="select-friend-modal"
        onCancel={() => setSelectFriendMultipleVisible(false)}
      >
        <SelectFriendMultiple
          friends={shieldUserIds}
          onSelected={onShieldFriends.bind(this)}
        />
      </Modal>
      {/* <Prompt
        when={showBackPrompt}
        message={(location, action) => {
          if (!showBackPrompt && showBackPrompt != undefined) {
            return true;
          }
          console.log("--------------location.pathname:", location.pathname);
          setNavPathName(location.pathname);
          setShowSaveDraftModal(true);

          return false;
        }}
      /> */}
      <Modal
        destroyOnClose={true}
        title={null}
        visible={showSaveDraftModal}
        footer={null}
        closable={false}
        className="save-feed-draft-modal"
        onCancel={() => setShowSaveDraftModal(false)}
      >
        <div className="title">{t("SAVE_POST")}</div>
        <div className="desc">{t("SAVE_POST_TIPS")}</div>
        <div className="bottom-container">
          <div className="btn-save" onClick={onSaveDraftClick.bind(this)}>
            {t("SAVE")}
          </div>
          <div className="btn-discard" onClick={onDiscardDraftClick.bind(this)}>
            {t("DISCARD")}
          </div>
        </div>
      </Modal>
    </div>
  );
}
