import {
  actionRequest,
  formatActionResponseArray,
  formatActionResponse,
} from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";
import { requestGetUrlFormat } from "./common";
import { dateFormat } from "../utils/date";

/**
 *
 * @param userid
 * @param pms
 */
export async function pageFriends(userid: string, pms: any) {
  let url = apiPath.friends + "/" + userid;
  let list = await formatActionResponseArray(RequestType.GET, url, pms);
  return formatFriends(list, userid);
}

export function formatFriends(friends: Array<any>, userId: string) {
  let list: Array<any> = [];
  if (friends) {
    friends.forEach((item) => {
      let userItem;
      if (item.sourceId === userId) {
        userItem = item.targetUserInfo[0];
      } else {
        userItem = item.sourceUserInfo[0];
      }
      let id = userItem._id;
      let type = userItem.type;
      let temp = list.filter((n) => n.id === id);
      if (temp.length === 0) {
        let {
          picture,
          signature,
          country,
          companyId,
          company,
          companyInfo,
          roleId,
          roleInfo,
        } = userItem;
        let name = userItem.nickName
          ? userItem.nickName
          : userItem.firstName + userItem.lastName;
        let time = dateFormat(userItem.createdAt, "MM-dd");
        list.push({
          _id: id,
          id,
          type,
          name,
          signature,
          time,
          picture,
          country,
          companyId,
          company,
          companyInfo,
          roleId,
          roleInfo,
        });
      }
    });
  }
  return list;
}

export function formatApplies(applies: Array<any>, userId: string) {
  let list: Array<any> = [];
  if (applies) {
    console.log("--format apply list:", applies);
    applies.forEach((item) => {
      let userItem =
        item.sourceId === userId
          ? item.targetUserInfo[0]
          : item.sourceUserInfo[0];
      if (userItem) {
        let temp = list.filter((n) => n.userInfo === userItem._id);
        if (temp.length === 0) {
          let { createdAt, sourceId, targetId, status, _id } = item;
          list.push({
            _id,
            status,
            sourceId,
            targetId,
            createdAt,
            userInfo: userItem,
          });
        }
      }
    });
  }
  return list;
}

/**
 *
 * @param userid
 * @param pms
 */
export async function loadFriendsTotal(userid: string, pms: any) {
  let url = apiPath.friends + "/" + userid;
  return formatActionResponse(RequestType.GET, url, pms);
}

export async function basicPageFriends(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.contactFriends, pms);
}

/**
 *
 * @param pms
 */
export function applyFriendCreate(pms: any) {
  return actionRequest(RequestType.POST, apiPath.applyFriendCreate, pms);
}

/**
 *
 * @param pms
 */
export async function applyFriendList(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.applyFriendList, pms);
}

export async function friendApplyPage(pms: any) {
  let data = await formatActionResponse(
    RequestType.GET,
    apiPath.applyFriendList,
    pms
  );
  if (data && data.total > 0) {
    data.result = formatApplies(data.result, pms.userId);
  }
  return data;
}

/**
 *
 * @param pms
 */
export function applyFriendHandle(pms: any) {
  return actionRequest(RequestType.POST, apiPath.applyFriendHandle, pms);
}

/**
 *
 * @param pms
 */
export function removeFriend(pms: any) {
  return actionRequest(RequestType.POST, apiPath.removeFriend, pms);
}

/**
 *
 * @param pms
 */
export function follow(pms: any) {
  return actionRequest(RequestType.POST, apiPath.follow, pms);
}

/**
 *
 * @param pms
 */
export function applyFriendSelf(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.applyFriendSelf, pms)
  );
}

/**
 *
 * @param pms
 */
export function contactOnLineStatus(pms: any) {
  return formatActionResponse(
    RequestType.GET,
    apiPath.contactOnLineStatus,
    pms
  );
}

/**
 *
 * @param pms
 */
function contactFriendStatus(pms: any) {
  return formatActionResponse(
    RequestType.POST,
    apiPath.contactFriendStatus,
    pms
  );
}

export async function getContactFriendStatus(sourceId: any, targetId: any) {
  let pms = { sourceId, targetId };
  let result = await contactFriendStatus(pms);
  if (!result) {
    pms = { sourceId: targetId, targetId: sourceId };
    result = await contactFriendStatus(pms);
  }
  return result;
}

/**
 *
 * @param pms
 */
export function contactFollowStatus(pms: any) {
  return actionRequest(RequestType.POST, apiPath.contactFollowStatus, pms);
}

/**
 *
 * @param pms
 */
export function contactUserFollowCount(pms: any) {
  return formatActionResponse(
    RequestType.GET,
    apiPath.contactUserFollowCount,
    pms
  );
}

/**
 *
 * @param pms
 */
export function contactUserFansCount(pms: any) {
  return formatActionResponse(
    RequestType.GET,
    apiPath.contactUserFansCount,
    pms
  );
}

/**
 *
 * @param pms
 */
export function contactUserFans(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.contactUserFans, pms)
  );
}

/**
 *
 * @param pms
 */
export function getReportingUserList(pms: any) {
  return formatActionResponseArray(
    RequestType.GET,
    apiPath.reportingUserlist,
    pms
  );
}

/**
 *
 * @param pms
 */
export function reportingUser(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.reportingUser, pms);
}

/**
 *
 * @param pms
 */
export function getBlockList(pms: any) {
  return formatActionResponseArray(RequestType.GET, apiPath.blockUserList, pms);
}

/**
 *
 * @param pms
 */
export function blockUser(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.blockUser, pms);
}
