import { createContext, useContext, useEffect, useState } from "react";
import { userDetail } from "../api/user";
import { UserType } from "../constants/usersType.enum";
import useLocalStorage from "../hooks/useLocalStorage";
import { useTranslation } from "react-i18next";

export const UserIdContext = createContext();

export function useUserId() {
  return useContext(UserIdContext);
}

export function UserIdProvider({ children }) {
  const [user, setUserInfo] = useLocalStorage("user", "");
  const [isWinery, setIsWinery] = useState(true);
  const [isImporter, setIsImporter] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    loadUserInfo();
  }, []);

  useEffect(() => {
    if (user && user.userInfo && user.userInfo.type) {
      setIsWinery(user.userInfo.type == UserType.SUPPLIER);
      setIsImporter(user.userInfo.type != UserType.SUPPLIER);
    }
  }, [user]);

  function createUserInfo(info) {
    setUserInfo(info);
  }

  async function loadUserInfo(userId) {
    userId = userId ? userId : user.userId;
    if (userId) {
      let userInfo = await userDetail(userId);
      if (userInfo && userInfo.language) {
        i18n.changeLanguage(userInfo.language);
      }
      setUserInfo({ userId, userInfo });
    }
  }

  return (
    <UserIdContext.Provider
      value={{ isImporter, isWinery, user, createUserInfo, loadUserInfo }}
    >
      {children}
    </UserIdContext.Provider>
  );
}
