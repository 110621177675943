import { Upload } from "antd";
import { useUserId } from "../../../contexts/UserIdProvider";
import { getBase64 } from "../../../utils/utils";
import { getUploadFileAction } from "../../../api/common";
import { getCacheTokens } from "../../../utils/cache";
import ImgCrop from "antd-img-crop";
import { toastError } from "../../../api/toast";
import { useTranslation } from "react-i18next";

export default function UploadImageNormal(props) {
  const { children, onLoading, onFileLoaded, scaleAspect, cropShape, aspectSlider } = props;
  const { user } = useUserId();
  const { t } = useTranslation();
  const token = getCacheTokens();
  const action = getUploadFileAction(user.userId);

  const layout_upload = {
    action: action,
    showUploadList: false,
    headers: {
      authorization: `Bearer ${token.value}`,
    },
    onChange: async (info) => {
      let { fileList, file } = info;
      let { status, percent } = file;
      console.log(
        "-------------------------UploadImageNormal:",
        status,
        percent
      );
      if (status === "uploading") {
        if (percent === 0) {
          let tempImage = await getBase64(file);
          onLoading && onLoading(tempImage);
        }
      } else if (status === "done") {
        fileList = fileList.map((file) => file.response.result);
        onFileLoaded && onFileLoaded(fileList);
      } else if (status === "error") {
        toastError(t("UPLOAD") + t("FAILED"));
      }
    },
  };

  return (
    <ImgCrop 
      rotationSlider 
      aspectSlider={false}
      showReset 
      aspect={scaleAspect}
      cropShape={cropShape}
    >
      <Upload {...layout_upload} maxCount={1}>
        {children}
      </Upload>
    </ImgCrop>
  );
}
