import { actionRequest, formatActionResponseArray } from "./api";
import { RequestType } from "../constants/requestType.enum";
import { requestGetUrlFormat } from "./common";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export function warehouses(pms: any) {
    return actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.warehouses, pms));
}

export function getAllWarehouses() {
    return formatActionResponseArray(RequestType.GET, apiPath.warehousesAll, null);
}

/**
 *
 * @param pms
 */
export function warehouseCreate(pms: any) {
    return actionRequest(RequestType.POST, apiPath.warehouseCreate, pms);
}

/**
 *
 * @param pms
 */
export function warehouseUpdate(pms: any) {
    return actionRequest(RequestType.POST, apiPath.warehouseUpdate, pms);
}

/**
 *
 * @param pms
 */
export function warehouseRemove(pms: any) {
    return actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.warehouseRemove, pms));
}
