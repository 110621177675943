import { React, useState } from "react";
import { Alert, Table, Slider } from "antd";

import { landingCost } from "../../utils/utils";
import { Pie } from "react-chartjs-2";
import "./style/Tools.less";
export default function Tools() {
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [counter, setCounter] = useState(0);
  const [margin, setMargin] = useState(0);
  const [initialTotal, setInitialTotal] = useState(0);
  const currency = 9;
  const shippingFee = 3;
  const tax = 1.5;

  const sourceColumns = [
    {
      title: "Shipping Fees",
      dataIndex: "shippingFee",
      key: "shippingFee",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "tax",
      dataIndex: "tax",
      key: "tax",
    },
  ];

  const ssource = [
    {
      key: "1",
      shippingFee: shippingFee,
      currency: currency,
      price: price,
      quantity: quantity,
      tax: tax,
    },
  ];

  const PieColors = ["purple", "pink", "orange", "yellow", "crimson", "wine"];

  const dataPie = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["Shipping Fee", "Currency", "Price", "Quantity", "Margin"],
    datasets: [
      {
        data: [shippingFee, currency, price, quantity, margin],
        backgroundColor: PieColors,
        color: "red",
        hoverBackgroundColor: PieColors,
      },
    ],
  };

  const pieOptions = {
    legend: {
      display: false,
      position: "right",
      legendCallback: function () {
        return null;
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    maintainAspectRatio: true,
  };

  const CalculatePrice = () => {
    let intPrice = +price;
    let intQuantity = +quantity;

    if (price && quantity) {
      if (intPrice < 0 || Number.isNaN(intPrice) === true) {
        return (
          <Alert message="Veuillez entrer un nombre positif" type="error" />
        );
      }
      if (intQuantity < 0 || Number.isInteger(intQuantity) === false) {
        return (
          <Alert
            message="Veuillez entrer un nombre positif entier"
            type="error"
          />
        );
      }
      return (
        <div className="table-form-tools">
          <Table
            style={{ width: "550px", height: "110px", borderWidth: "5px" }}
            bordered={true}
            pagination={false}
            dataSource={ssource}
            columns={sourceColumns}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const updateAll = (e) => {
    setCounter(e);
    setMargin((parseFloat((initialTotal * e) / 100).toFixed(2) * e) / e);
  };

  const MarginMsg = () => {
    if (margin && counter) {
      return (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
        >
          Pour une marge de {counter}% vous gagnerez {margin} Yuan
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="normal-shadow-padding10-white" style={{ width: "700px" }}>
      <div style={{ marginTop: "20px" }}>
        <form>
          <label>Enter a price:</label>
          <input
            type="text"
            onChange={(e) => {
              setPrice(e.target.value);
              updateAll(e);
            }}
          />
        </form>
      </div>
      <div style={{ marginTop: "20px" }}>
        <form>
          <label>Enter quantity:</label>
          <input
            type="text"
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
              setInitialTotal(() => landingCost(price, e.target.value));
              updateAll(e);
            }}
          />
        </form>
      </div>
      <CalculatePrice />
      {price && quantity && (
        <div>
          <Slider
            defaultValue={0}
            min={0}
            max={100}
            step={1}
            onChange={(e) => updateAll(e)}
          />
          <MarginMsg />
          <div style={{ position: "relative", width: "550px" }}>
            <Pie
              data={dataPie}
              options={pieOptions}
              style={{ backgroundColor: "#F5F5F5" }}
              type={"pie"}
            />
          </div>
        </div>
      )}
    </div>
  );
}
