import { Input } from "antd";
import { useTranslation } from "react-i18next";
import './style/inputPwdNormal.less';

export default function InputPwdNormal(props) {
  const { t } = useTranslation();
  const { label, value, index, onChange } = props;
  // console.log('------input normal:', label, value, index);
  const onInputChange = (e) => {
    onChange(e.target.value, index);
  };

  return (
    <>
      <div className="input-title">
        <span
          style={{
            color: "#333333",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {t(label)}
        </span>
      </div>
      <div>
        <Input.Password
          bordered={false}
          maxLength={200}
          placeholder={t(label)}
          onChange={onInputChange.bind(this)}
        />
      </div>
    </>
  );
}
