import cn from "classnames";
import styles from "./Item.module.sass";
import { localizeImg } from "../../../../../constants/constants";
import AvatarBasic from "../../../../../components/common/avatar/AvatarBasic";

const Item = ({ item, setVisible, activeItem, setActiveItem }) => {
  const handleClick = (id) => {
    setVisible(true);
    setActiveItem(id);
  };

  return (
    <div
      className={cn(
        styles.item,
        { [styles.new]: item.new },
        { [styles.online]: item.online },
        { [styles.active]: activeItem.id === item.id }
      )}
      onClick={() => handleClick(item)}
    >
      <div className={styles.avatar}>
        <AvatarBasic
          picture={localizeImg(item?.avatar)}
          size={57}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.head}>
          <div className={styles.man}>
            {item?.nickName||item?.userName}
          </div>
          <div className={styles.time}>{item?.time}</div>
        </div>
        <div
          className={styles.message}
          dangerouslySetInnerHTML={{ __html: item.content }}
        ></div>
      </div>
    </div>
  );
};

export default Item;
