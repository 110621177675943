import React, { useState, useEffect } from "react";
import { Divider } from "antd";
import { useHistory } from "react-router-dom";
import "./style/productCardItem.less";
import { localizeImg, localizeTitle, ProductCurrencyType } from "../../../constants/constants";
import vineDefault from "../../../resource/images/wine-botlle/vin-001.jpeg";
import { useTranslation } from "react-i18next";
import {
  getProductConfigNameById,
  getProductPriceRangeText,
  loadProductConfig,
} from "../../../api/product";

export default function ProductCardItem(props) {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const { item, keyword } = props;

  const [productTitle, setProductTitle] = useState();
  const [productId, setProductId] = useState();
  // const [keyword, setKeyword] = useState();
  const [productPrice, setProductPrice] = useState();
  const [productThumbnail, setProductThumbnail] = useState();
  const [classificationsName, setClassificationName] = useState();
  const [categoryName, setCategoryName] = useState();

  useEffect(() => {
    const {
      title,
      volumes,
      currency,
      classifications,
      category,
      bottleImages,
    } = item;
    // console.log("------product card item:", item);
    formatProductTitle(localizeTitle(i18n.language, title), keyword);

    setProductId(item.id || item._id);
    let priceText = getProductPriceRangeText(volumes);
    if (priceText) {
      setProductPrice(currency === ProductCurrencyType.CNY ? (currency + ' ' + priceText) : (priceText + ' ' + currency));
    }

    const productThumbnail =
      bottleImages && bottleImages.length > 0
        ? localizeImg(bottleImages[0])
        : vineDefault;
    setProductThumbnail(productThumbnail);
    setConfigData(i18n.language, category, classifications);
  }, [keyword, i18n.language]);

  const setConfigData = async (currentLanguage, category, classification) => {
    let configData = await loadProductConfig();
    let {
      locations,
      types,
      bottles,
      capsules,
      sealed,
      classifications,
      categories,
      tags,
    } = configData;
    setCategoryName(
      getProductConfigNameById(categories, currentLanguage, category)
    );
    setClassificationName(
      getProductConfigNameById(classifications, currentLanguage, classification)
    );
  };

  const formatProductTitle = (title, key) => {
    if (key) {
      title = title.replace(
        key,
        '<span class="keyword-label">' + key + "</span>"
      );
    }
    setProductTitle(title);
  };

  const toProductDetail = () => {
    history.push("/products/detail/" + productId);
  };

  return (
    <div
      className="product-card-item"
      key={productId}
      onClick={toProductDetail.bind(this)}
    >
      <div className="other-image-container">
        <img src={localizeImg(productThumbnail)} className="other-image" />
      </div>
      <div className="other-desc">
        <div
          className="other-desc-title"
          dangerouslySetInnerHTML={{ __html: productTitle }}
        ></div>
        <div className="other-desc-content">
          {classificationsName}
          <Divider type="vertical" />
          {categoryName}
        </div>
        {productPrice &&
          <div className="other-desc-price">{productPrice}</div>
        }

      </div>
    </div>
  );
}
