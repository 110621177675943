import { ProductCurrencyType } from "./constants";

export const CategoryData = [
  {
    name: "Wine",
    value: "Wine",
    language: { zh: "葡萄酒", fr: "Wine" },
  },
  {
    name: "Spirit",
    value: "Spirit",
    language: { zh: "烈酒", fr: "Spirit" },
  },
  {
    name: "Other",
    value: "Other",
    language: { zh: "其他", fr: "Other" },
  },
];

export const KnowVinbipData = [
  {
    name: "Facebook",
    value: "Facebook",
    language: { zh: "脸书", fr: "Facebook" },
  },
  {
    name: "Twitter",
    value: "Twitter",
    language: { zh: "推特", fr: "Twitter" },
  },
  {
    name: "Website",
    value: "Website",
    language: { zh: "网站", fr: "Website" },
  },
  {
    name: "Adversitement",
    value: "Adversitement",
    language: { zh: "广告", fr: "Adversitement" },
  },
];

export const DistributionChannelData = [
  {
    name: "wholesale",
    value: "WHOLE_SALE",
    language: { zh: "批发", fr: "wholesale" },
  },
  {
    name: "E-Commerce",
    value: "E_COMMERCE",
    language: { zh: "电子商务", fr: "E-Commerce" },
  },
  {
    name: "On trade(restaurants,clubs,bars)",
    value: "ON_TRADE",
    language: {
      zh: "即饮渠道（餐厅，俱乐部，酒吧等)",
      fr: "HORECA distribution",
    },
  },
  {
    name: "Off trade(wine shops,supermarkets,other retail shops)",
    value: "OFF_TRADE",
    language: {
      zh: "非即饮渠道（专卖店，超市，其他零售店)",
      fr: "Grande Distribution(cavistes,supermarchés,commerce de details)",
    },
  },
  {
    name: "Corporate business",
    value: "CORPORATE_SUPPLIES",
    language: { zh: "公司业务", fr: "Corporate business" },
  },
  {
    name: "other",
    value: "OTHER",
    language: { zh: "其他", fr: "other" },
  },
];

export const AchiveData = [
  {
    name: "Have direct cooperation with producers",
    value: "DIRECT_COOPERATION_WITH_PRODUCER",
    language: {
      zh: "与生产商直接合作",
      fr: "Have direct cooperation with producers",
    },
  },
  {
    name: "Enlarge portfolio",
    value: "ENLARGE_PORTFOLIO",
    language: { zh: "扩大投资组合", fr: "Enlarge portfolio" },
  },
  {
    name: "Increase sale",
    value: "INCREASE_SALES",
    language: { zh: "增加销售额", fr: "Increase sale" },
  },
  {
    name: "Stabilize distribution",
    value: "STABILIZE_DISTRIBUTION",
    language: { zh: "稳定分布", fr: "Stabilize distribution" },
  },
  {
    name: "Reach more ressource",
    value: "REACH_MORE_RESSOURCES",
    language: { zh: "获取更多资源", fr: "Reach more ressource" },
  },
  {
    name: "Avoid fake products",
    value: "AVOID_FAKE",
    language: { zh: "避免假冒产品", fr: "Avoid fake products" },
  },
];

export const ProductStockData = [
  {
    name: "Any locations",
    value: "Any locations",
    language: { zh: "任何位置", fr: "Any locations" },
  },
  {
    name: "Local stock",
    value: "Local stock",
    language: { zh: "国内发货", fr: "Local stock" },
  },
  {
    name: "Stock abroad",
    value: "Stock abroad",
    language: { zh: "国外发货", fr: "Stock abroad" },
  },
];

export const ProductAlcoholSelectList = [
  { label: "0 ~ 10", value: [0, 10] },
  { label: "11 ~ 30", value: [11, 30] },
  { label: "31 ~ 50", value: [31, 50] },
  { label: "> 51", value: [51, 100] },
];

export const ProductCurrencyData = [
  {
    label: ProductCurrencyType.USD,
    value: "1",
  },
  {
    label: ProductCurrencyType.CNY,
    value: "2",
  },
  {
    label: ProductCurrencyType.EUR,
    value: "3",
  },
];
