import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ProductInfo.module.sass";
import Profile from "./Profile";
import Settings from "./Settings";
import Card from "../../core-digital-template/components/Card";
import { useHistory, useLocation, useParams } from "react-router";
import { useUserId } from "../../contexts/UserIdProvider";
import { localizeImg } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import { UserType } from "../../constants/usersType.enum";
import { ProductStatus, statusCode } from "../../constants/statusCode.enum";
import {
  createProductClick,
  getProductConfigNameById,
  getProductTotalStock,
  loadProductConfig,
  loadProducts,
  productDetail,
} from "../../api/product";
import Slider from "react-slick";
import Icon from "../../core-digital-template/components/Icon";
import { openCompanyDetail } from "../../api/open";
import { getCountryNameById } from "../../api/config";
import ProductDetail from "../../core-digital-template/components/ProductDetail";
import Menu from "../../core-digital-template/components/Menu";
import { Skeleton } from "antd";
//import "./productInfo.less";
import Joyride, { STATUS } from "react-joyride";
import { deepClone } from "../../utils/utils";
import { saveCompanyInfo } from "../../api/company";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={styles.sliderArrowRight} onClick={onClick}>
      <Icon name="arrow-right" size="36" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={styles.sliderArrowLeft} onClick={onClick}>
      <Icon name="arrow-left" size="36" />
    </div>
  );
}

export default function ProductInfo() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const { state } = useLocation();
  const { coname } = useParams();

  const history = useHistory();

  const { userId, userInfo } = user;

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [productId, setProductId] = useState("");
  const [product, setProduct] = useState({});
  const [relativeProducts, setRelativeProducts] = useState([]);

  const [companyInfo, setCompanyInfo] = useState({});

  // const [companyVerified, setCompanyVerified] = useState(false);
  const [targetUserId, setTargetUserId] = useState("");

  const [onLoading, setOnLoading] = useState(true);

  const [isAddMode, setIsAddMode] = useState(coname?.length > 0);

  const [tourOpen, setTourOpen] = useState(false);
  const stepsLocale = {
    skip: <strong aria-label="skip">{t("SKIP")}</strong>,
    next: <strong aria-label="skip">{t("NEXT")}</strong>,
    back: <strong aria-label="skip">{t("BACK")}</strong>,
  };

  const steps = [
    {
      content: <h2>{t("TOUR_EDIT_PRODUCT_WELCOME")}</h2>,
      locale: stepsLocale,
      placement: "center",
      target: "body",
    },
    {
      target: ".edit-product-info-name",
      content: t("TOUR_EDIT_PRODUCT_NAME"),
      locale: stepsLocale,
    },
    {
      target: ".edit-product-info-tag",
      content: t("TOUR_EDIT_PRODUCT_TAGS"),
      locale: stepsLocale,
    },
    {
      target: ".edit-product-info-images",
      content: t("TOUR_EDIT_IMAGES"),
      locale: stepsLocale,
    },
    {
      target: ".edit-product-info-trading-condition",
      content: t("TOUR_EDIT_TRADING_CONDITION"),
      locale: stepsLocale,
    },
    {
      target: ".edit-product-info-tech-info",
      content: t("TOUR_EDIT_TECH_INFO"),
      locale: stepsLocale,
    },
    {
      target: ".edit-product-info-testing-note",
      content: t("TOUR_EDIT_TESTING_NOTE"),
      locale: stepsLocale,
    },
    {
      target: ".edit-product-info-details",
      content: t("TOUR_EDIT_DETAILS"),
      locale: stepsLocale,
    },
  ];

  useEffect(() => {
    if (isAddMode) {
      loadCompanyDesc(user.userInfo.companyId);
    }

    // window.scrollTo(0, 0);

    // 如果你使用React Router，可以监听路由变化，然后在每次路由变化时滚动到页面顶部
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    // 在组件卸载时取消监听
    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    if (coname) {
      loadCompanyDesc(coname);
    }
  }, [coname]);

  useEffect(() => {
    if (coname) {
      initProduct(coname);
    }
  }, [coname]);

  const initProduct = async (id) => {
    let pms = { id };
    let data = await productDetail(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      let productInfo = data.result;
      if (
        productInfo &&
        productInfo.companyInfo &&
        productInfo.companyInfo.length > 0
      ) {
        productInfo.companyInfo = productInfo.companyInfo[0];
      }

      setProductId(productInfo._id);
      setProduct(productInfo);
      loadCompanyDesc(productInfo?.companyInfo?._id);
      loadRelativeProducts(productInfo.coId, productInfo._id);
    }
  };

  const loadRelativeProducts = async (coId, id) => {
    let configData = await loadProductConfig();
    let { locations, categories } = configData;

    let pms = {
      page: 0,
      per_page: 100,
      coId,
      params: JSON.stringify({ showBindVintag: false }),
      lng: i18n.language,
    };
    let result = await loadProducts(pms);
    if (result && result.length > 0) {
      let list = [];
      for (let i = 0; i < result.length; i++) {
        let item = result[i];
        let flag =
          (userInfo.type != UserType.SUPPLIER &&
            item.status === ProductStatus.STOCK) ||
          userInfo.type == UserType.SUPPLIER;
        if (flag && item.id !== id) {
          item.countryName = getCountryNameById(
            locations,
            currentLanguage,
            item.country
          );
          item.categoryName = getProductConfigNameById(
            categories,
            currentLanguage,
            item.category
          );
          item.stock = getProductTotalStock(item.volumes);
          list.push(item);
        }
      }
      setRelativeProducts(list);
    }
  };

  const loadCompanyDesc = async (coId) => {
    const tempCompanyInfo = await openCompanyDetail(coId);
    if (
      tempCompanyInfo &&
      tempCompanyInfo.medias &&
      tempCompanyInfo.medias.length == 1
    ) {
      tempCompanyInfo.medias.push(tempCompanyInfo.medias[0]);
    }
    let flag = user?.userInfo?.companyId == tempCompanyInfo?._id;
    if (!tempCompanyInfo?.productToured && flag) {
      setTourOpen(true);
    }
    setCompanyInfo(tempCompanyInfo);
    setTimeout(() => {
      setOnLoading(false);
    }, 1000);
  };

  const settings = {
    infinite: true,
    // className: "center",
    //autoplaySpeed: 5000,
    speed: 1000,
    //lazyLoad: true,
    //autoplay: true,
    //slidesToShow: 1,
    // centerMode: true,
    variableWidth: true,
    // adaptiveHeight: true,
    // swipeToSlide: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      console.log("finishedStatuses:", status);
      let temp = deepClone(companyInfo);
      temp.productToured = true;
      saveCompanyInfo(temp);
    }
  };

  return (
    <>
      <div>
        {!onLoading && (
          <div className={styles.background}>
            {companyInfo && !companyInfo.medias && (
              <div className={styles.emptyBackground}></div>
            )}
            {companyInfo && companyInfo.medias && (
              <img
                src={localizeImg(companyInfo?.medias[0])}
                alt="Background"
                className={styles.banner}
              />
            )}
          </div>
        )}

        <ProductDetail product={product} relativeProducts={relativeProducts} />

        <Joyride
          run={tourOpen}
          continuous
          hideCloseButton
          scrollToFirstStep
          showProgress
          showSkipButton
          disableScrolling
          steps={steps}
          callback={handleJoyrideCallback}
        />
      </div>
    </>
  );
}
