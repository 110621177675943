import { PlusOutlined } from "@ant-design/icons";
import "./style/productImageUpload.less";
import { useTranslation } from "react-i18next";

export default function ProductImageUpload(props) {
  const { t } = useTranslation();
  let { title, url } = props;
  return (
    <div className="product-image-upload-container">
      {url ? (
        <img src={url} className="image-item" />
      ) : (
        <div className="image-upload">
          <PlusOutlined
            style={{ fontSize: "32px", color: "#DDDDDD", marginBottom: 20 }}
          />
          <div className="image-upload-text">{t("UPLOAD_IMAGE")}</div>
        </div>
      )}
      <div className="image-title">{title}</div>
    </div>
  );
}
