import React, { useState, useEffect } from "react";
import { Button, Table, Space, Modal, Breadcrumb } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { noteRemove, notes } from "../../../api/user";
import "./style/notes.less";
import NoteCreate from "../note/NoteCreate";
import { Link } from "react-router-dom";
import { localizeImg } from "../../../constants/constants";

export default function Notes() {
  const { t } = useTranslation();
  const { user } = useUserId();

  const [data, setData] = useState([]);

  const [createModalVisible, setCreateModalVisible] = useState(false);

  useEffect(() => {
    loadData(user.userInfo.companyId);
  }, []);

  const columns = [
    {
      title: t("NOTE_PHOTO"),
      dataIndex: "note",
      key: "note",
      render: (val) => {
        return <img src={localizeImg(val)} className="rewardIcon" />;
      },
    },
    { title: t("NAME"), dataIndex: "name", key: "name" },
    { title: t("DESCRIPTION"), dataIndex: "desc", key: "desc" },
    {
      title: t("ACTION"),
      dataIndex: "",
      key: "y",
      render: (val) => {
        return (
          <Space size="middle">
            <Button type="link" onClick={onRemove.bind(this, val._id)}>
              {t("DELETE")}
            </Button>
          </Space>
        );
      },
    },
  ];

  const loadData = async (coId) => {
    let pms = { coId };
    let result = await notes(pms);
    setData(result);
  };

  const onRemove = async (noteId) => {
    let pms = { noteId };
    await noteRemove(pms);
    loadData(user.userInfo.companyId);
  };

  const onModelClose = () => {
    setCreateModalVisible(false);
    loadData(user.userInfo.companyId);
  };

  return (
    <div className="notes-page-container">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("PRODUCT_NOTE")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management">
        <div className="common-management-title">{t("NOTE_LIST")}</div>
        <div className="products-filter common-management-panel">
          <Button
            className="common-primary-button"
            onClick={() => setCreateModalVisible(true)}
          >
            {t("CREATE_NOTE")}
          </Button>
        </div>
        <div>
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
        <Modal
          destroyOnClose={true}
          title={null}
          visible={createModalVisible}
          footer={null}
          className="discount-create-modal"
          onCancel={onModelClose.bind(this)}
        >
          <NoteCreate
            onClose={onModelClose.bind(this)}
            onComplete={onModelClose.bind(this)}
          />
        </Modal>
      </div>
    </div>
  );
}
