import { create } from 'apisauce';

const baseURL = "https://api.mch.weixin.qq.com/v3/pay/transactions/";
const api = create({
    baseURL: baseURL,
    headers: {
        Accept: 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE"
    },
});
api.setHeader('Access-Control-Allow-Origin', '*');
api.setHeader('Access-Control-Allow-Headers', 'X-Request-With');
api.setHeader("Access-Control-Allow-Methods", "PUT,POST,GET,DELETE,OPTIONS");
api.setHeader("X-Powered-By", ' 3.2.1');
api.setHeader("Content-Type", "text/html");

/**
 * @description Make a Get request with the token in the header
 * @param url
 * @param data
 */
export async function getWxPay(url: string, data?: any) {
    return api.get(url, data);
}

/**
 * @description Make a post request with the token in the header
 * @param url
 * @param data
 * @constructor
 */
export async function postWxPay(url: string, data?: any) {
    return api.post(url, data);
}

