import React, { useEffect, useState } from "react";
import { Breadcrumb, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../contexts/UserIdProvider";
import { deepClone } from "../../utils/utils";
import {
  blockUser,
  pageFriends,
  removeFriend,
  getBlockList,
  reportingUser,
  contactUserFollowCount,
} from "../../api/contact";
import ContactItem from "../shared/ContactItem";
import { useTranslation } from "react-i18next";
import "./style/myContacts.less";
import { userPage } from "../../api/user";
import { Link } from "react-router-dom";
import UserItem from "../shared/UserItem";

export default function MyFollowings() {
  const { user } = useUserId();
  const { userId } = user;
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (pageIndex >= 0) {
      loadData();
    } else {
      setPageIndex(0);
    }
  }, [pageIndex]);

  const loadData = async () => {
    setLoading(true);
    let pms = { userId };
    let data = await contactUserFollowCount(pms);
    console.log("follow result : ", data, pms);
    if (data && data.total > 0) {
      let { result } = data;
      let list = [];
      result.map((a) => {
        console.log("a.targetUserInfo[0]", a.targetUserInfo);
        if (a.targetUserInfo && a.targetUserInfo.length > 0) {
          list.push(a.targetUserInfo[0]);
        }
      });
      if (pageIndex > 0) {
        let _list = deepClone(dataList);
        list = _list.concat(list);
      }
      setDataList(list);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_CONTACT")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("FOLLOWING")}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className={
          "myContact-container common-management normal-shadow-padding10-white"
        }
      >
        <div
          className={"common-management-title"}
          onClick={() => setPageIndex(-1)}
        >
          {t("FOLLOWING")}
        </div>
        <div className="common-management-panel">
          <InfiniteScroll
            className="scroll-content"
            dataLength={dataList.length}
            next={handleInfiniteOnLoad.bind(this)}
          >
            <List
              className="fensi"
              itemLayout="vertical"
              size="large"
              dataSource={dataList}
              renderItem={(item) => <UserItem detail={item} />}
            >
              {loading && (
                <div>
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                </div>
              )}
            </List>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
