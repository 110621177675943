import React, { useState, useEffect } from "react";
import { Input, Button, Space, Tree, Col } from "antd";
import { useTranslation } from "react-i18next";
import "./style/RoleCreate.less";
import { Row } from "antd";
import { createStaff, updateStaff } from "../../../api/company";
import { createRole } from "../../../api/roles";
import { statusCode } from "../../../constants/statusCode.enum";
import { toastError, toastInfo } from "../../../api/toast";
import { useUserId } from "../../../contexts/UserIdProvider";
import { list as fetchUserLimitList } from "../../../api/userlimit";
import { deepClone } from "../../../utils/utils";

export default function RoleCreate(props) {
  const { currentRole, onComplete, onClose, onRoleChange } = props;
  const { user } = useUserId();
  const { t, i18n } = useTranslation();
  const { TextArea } = Input;

  let [name, setName] = useState("");
  let [desc, setDesc] = useState("");
  const [id, setId] = useState();

  const [roleConfigData, setRoleConfigData] = useState([]);
  const [limits, setLimits] = useState([]);

  useEffect(() => {
    if (currentRole) {
      setId(currentRole._id);
      setName(currentRole.name);
      setDesc(currentRole.desc);
    }
    initUserLimitList();
  }, []);

  const initUserLimitList = async () => {
    let pms = {};
    let result = await fetchUserLimitList(pms);
    result = result.result;
    if (result && result.length > 0) {
      console.log(
        "----------------------------initUserLimitList:",
        result,
        user.userInfo.type
      );
      result = result.filter((filterItem) => {
        return (
          filterItem.type.filter(
            (subFilterItem) => subFilterItem == user.userInfo.type
          ).length > 0
        );
      });
      let roleList = [];
      result.map((item) => {
        if (!item.parentId) {
          roleList.push({
            _id: item._id,
            title: t("ROLE_" + item.name),
            key: item.name,
            children: [],
          });
        }
      });
      result.map((item) => {
        if (item.parentId) {
          roleList.map((parentItem) => {
            if (parentItem._id == item.parentId) {
              parentItem.children.push({
                title: t("ROLE_" + item.name),
                key: item.name,
              });
            }
          });
        }
      });
      console.log("----------------------------initUserLimitList:", roleList);
      setRoleConfigData(roleList);
    }
  };

  const onNameChange = (e) => {
    let val = e.target.value;
    setName(val);
    let temp = deepClone(currentRole) || {};
    temp.name = val;
    onRoleChange(temp);
  };

  const onDescChange = (e) => {
    let val = e.target.value;
    setDesc(val);
    let temp = deepClone(currentRole) || {};
    temp.desc = val;
    onRoleChange(temp);
  };

  const onRoleCreate = async () => {
    if (!name) {
      toastError(t("ROLE_NAME") + t("ROLE_DESC"));
      return;
    }
    if (!desc) {
      toastError(t("ROLE_NAME") + t("ROLE_DESC"));
      return;
    }

    let pms = {
      name: name,
      desc: desc,
      limits: limits,
      coId: user.userInfo.companyId,
    };
    let result;
    if (id) {
      pms._id = id;
      result = await updateStaff(pms);
    } else {
      result = await createRole(pms);
    }
    console.log("-------------------update role result:", result);
    if (result.statusCode === statusCode.SUCCESS) {
      toastInfo(t("SAVE_SUCCESS"));

      onFinish();
    }
  };

  const onFinish = () => {
    onComplete && onComplete();
  };

  const onCloseClick = () => {
    onClose && onClose();
  };

  const onRoleSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  const onRoleCheck = (checkedKeys, info) => {
    console.log("onCheck", checkedKeys, info);
    let str = checkedKeys.join(",");
    setLimits(str);
    let temp = deepClone(currentRole) || {};
    temp.limits = str;
    onRoleChange(temp);
  };

  const required_tip = <span className="required-tip">*</span>;

  return (
    <div className="role-create-container">
      <div className="modal-body">
        <div className="form-body">
          <div className="form-panel">
            <div className="form-label">
              {t("ROLE_NAME")}
              {/* {required_tip} */}
            </div>
            <Input
              value={name}
              placeholder={t("ROLE_NAME")}
              className="content-row-input"
              onInput={onNameChange.bind(this)}
            />
          </div>
          <div
            className="form-panel"
            style={{
              alignItems: "flex-start",
            }}
          >
            <div className="form-label">
              {t("ROLE_DESC")}
              {/* {required_tip} */}
            </div>
            <TextArea
              value={desc}
              placeholder={t("ROLE_DESC")}
              maxLength={400}
              style={{
                height: "90px",
              }}
              className="content-row-input"
              onChange={onDescChange.bind(this)}
            />
          </div>
          <div
            className="form-panel"
            style={{
              alignItems: "flex-start",
            }}
          >
            <div className="form-label" style={{ width: "116px" }}>
              {t("ROLE_CONFIG")}
              {/* {required_tip} */}
            </div>
            <Tree
              checkable
              onSelect={onRoleSelect.bind(this)}
              onCheck={onRoleCheck.bind(this)}
              treeData={roleConfigData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
