import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCurrencyAscii } from "../../utils/utils";
import { translateText } from "../../api/translate";
import { Skeleton } from "antd";
import { localizeImg, localizePriceUnit } from "../../constants/constants";
import { loadProductConfig } from "../../api/product";
import { getCountryNameById } from "../../api/config";
import "./style/TenderVerifyItemListRow.less";
import AvatarBasic from "../common/avatar/AvatarBasic";
import { getProductStatusText } from "../../api/common";
import { ProductStatus } from "../../constants/statusCode.enum";
import { userDetail } from "../../api/user";
import agreeSelectedIcon from "../../resource/icons/notify/agree_selected.svg";
import refuseSelectedIcon from "../../resource/icons/notify/refuse_selected.svg";
import { SysEnvLanguageType } from "../../constants/env.enum";

export default function TenderVerifyItemListRow(props) {
  const { data, keyword, notify } = props;
  const [country, setCountry] = useState();
  const [title, setTitle] = useState();
  const [amount, setAmount] = useState();
  const [priceBegin, setPriceBegin] = useState();
  const [priceEnd, setPriceEnd] = useState();
  const [userId, setUserId] = useState();
  const [userInfo, setUserInfo] = useState();
  const [currency, setCurrency] = useState();
  const { t, i18n } = useTranslation();
  const [tenderUser, setTenderUser] = useState();
  const [avatar, setAvatar] = useState();
  const [nickname, setNickname] = useState("This account dont exist anymore");
  const [translatedTitle, setTranslatedTitle] = useState(title);
  const [loading, setLoading] = useState(false);
  const [countryName, setCountryName] = useState();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const {
      country,
      title,
      amount,
      priceBegin,
      priceEnd,
      userId,
      userInfo,
      currency,
    } = data;
    setCountry(country);
    setTitle(title);
    setAmount(amount);
    setPriceBegin(priceBegin);
    setPriceEnd(priceEnd);
    setUserId(userId);
    setUserInfo(userInfo);
    setCurrency(currency);
  }, [data, keyword]);

  useEffect(() => {
    if (title) {
      translateMessage();
    }
    if (country) {
      initConfigData();
    }
  }, [currentLanguage, title, country]);

  useEffect(() => {
    if (userId) {
      if (userInfo && userInfo.length > 0) {
        setTenderUser(userInfo[0]);
      } else {
        initTenderUser();
      }
    }
  }, [userId]);

  useEffect(() => {
    if (tenderUser) {
      setAvatar(tenderUser.picture);
      setNickname(tenderUser.nickName);
    }
  }, [tenderUser]);

  const formatTitle = (title, key) => {
    if (key && title) {
      title = title.replace(
        key,
        '<span class="keyword-label">' + key + "</span>"
      );
    }
    return title;
  };

  const translateMessage = async () => {
    setLoading(true);
    let result = await translateText(title, currentLanguage);
    if (result && result.result && result.result.result) {
      setTranslatedTitle(formatTitle(result.result.result, keyword));
    }
    setLoading(false);
  };

  const initConfigData = async () => {
    let result = await loadProductConfig();
    if (result) {
      let { locations } = result;
      if (country) {
        setCountryName(getCountryNameById(locations, currentLanguage, country));
      }
    }
  };

  const initTenderUser = async () => {
    if (userId) {
      let result = await userDetail(userId);
      if (result) {
        setTenderUser(result);
      }
    }
  };

  const getStatusIcon = (status) => {
    let result = <div className="userinfo-btn-selected"></div>;
    //处理产品状态
    switch (Number(status)) {
      case ProductStatus.VERIFYWAITING:
        break;
      case ProductStatus.VERIFYSUCCESS:
        result = <img src={agreeSelectedIcon} />;
        break;
      case ProductStatus.VERIFYFAIL:
        result = <img src={refuseSelectedIcon} />;
        break;
      case ProductStatus.STOCK:
        break;
      case ProductStatus.UNSTOCK:
        break;
      default:
        break;
    }
    return result;
  };

  return (
    <div className="tenderListRowItem">
      {!loading && (
        <>
          <div className="tenderSourceUserInfo">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <AvatarBasic
                cname="tenderAvatar"
                picture={localizeImg(avatar)}
                displayName={nickname}
                size={30}
              ></AvatarBasic>
              <span className="tenderUserName">{nickname}</span>
            </div>
            {notify && (
              <div
                className={
                  "verify-result " +
                  (data.verifyStatus === ProductStatus.VERIFYFAIL &&
                    "verify-fail")
                }
              >
                {getStatusIcon(data.verifyStatus)}
              </div>
            )}
            {/* <span className="tenderPriceUnit">
              {getCurrencyAscii(currency)}
            </span> */}
          </div>
          <div className="tender-title-panel">
            <Link
              to={{
                pathname: "/tender/info",
                state: { detail: data },
              }}
            >
              <div
                className="tenderTitle"
                dangerouslySetInnerHTML={{ __html: translatedTitle }}
              ></div>
            </Link>
            {/* {notify && (
              <div
                className={
                  "verify-result " +
                  (data.verifyStatus === ProductStatus.VERIFYFAIL &&
                    "verify-fail")
                }
              >
                {t(getProductStatusText(data.verifyStatus))}
              </div>
            )} */}
          </div>

          <div
            className="tenderLabel"
            style={{
              marginTop: "20px",
            }}
          >
            {t("TENDER_NUMBER_OF_BOTTLE")} :
            <span className="tenderLabel">
              {amount} {t("BOTTLES")}
            </span>
          </div>
          <div className="tenderLabel">
            {t("TARGET_PRICE")} :
            <span className="tenderLabel">
              {currentLanguage === SysEnvLanguageType.ZH &&
                localizePriceUnit(currentLanguage)}
              {priceBegin}-{priceEnd}&nbsp;
              {currentLanguage !== SysEnvLanguageType.ZH &&
                localizePriceUnit(currentLanguage)}
            </span>
          </div>
          <div className="tenderLabel">
            {t("ORIGIN_OF_THE_PRODUCT")} :
            <span className="tenderLabel">{countryName}</span>
          </div>
        </>
      )}
      {loading && <Skeleton />}
    </div>
  );
}
