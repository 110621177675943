import React, { useEffect, useState } from "react";
import {
  Badge,
  Col,
  Dropdown,
  Menu,
  Modal,
  Popover,
  Row,
  Select,
  Tooltip,
  Input,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../contexts/UserIdProvider";
import { dateFormat } from "../../utils/date";
import "./style/navbar-logged.less";
import homeIcon from "../../resource/icons/navbar/home.svg";
import messageIcon from "../../resource/icons/navbar/message_light.svg";
import notificationIcon from "../../resource/icons/navbar/notification.svg";
import invoiceIcon from "../../resource/icons/navbar/invoices.svg";
import caseIcon from "../../resource/icons/navbar/management.svg";
import searchIcon from "../../resource/icons/navbar/search.svg";
import searchSelectedIcon from "../../resource/icons/navbar/search_selected.svg";
import notificationSound from "../../resource/audio/notification.mp3";
import { useContextData } from "../../contexts/DataProvider";
import { useIMContext } from "../../contexts/IMProvider";
import { toastInfo } from "../../api/toast";
import {
  AudioCallStatus,
  ChatContentsType,
} from "../../constants/chatContentsType.enum";
import AudioCall from "../imchat/AudioCall";
import VideoCall from "../imchat/VideoCall";
import VideoMeeting from "../imchat/VideoMeeting";
import { searchSelectList } from "../../api/feed";
import { imClose } from "../../utils/imHelper";
import useAgora from "../../hooks/useAgora";
import { CardType } from "../../constants/cardType.enum";
import { localizeImg, localizeTitle } from "../../constants/constants";
import { UserType } from "../../constants/usersType.enum";
import AvatarBasic from "../common/avatar/AvatarBasic";
import { getCompanyVerifyStatus } from "../../api/user";
import { CompanyVerifyStatusEnum } from "../../constants/companyVerifyStatus.enum";
import LanguageSelect from "../common/languages/LanguageSelect";
import { deepClone } from "../../utils/utils";

var moment = require("moment-timezone");

export default function Navbar() {
  const { isImporter, isWinery, loadUserInfo, user } = useUserId();
  const { userId, userInfo } = user;
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const [_avatar, setAvatar] = useState();
  const [messageCount, setMessageCount] = useState(0);
  const {
    imLoginStatus,
    webIM,
    agoraClient,
    sessionMessageList,
    newMsg,
    chatCallMsg,
    initIMLogin,
    getSessionMessage,
    setChatCallMsg,
    setChatNewMsg,
  } = useIMContext();
  const { leave } = useAgora(agoraClient);

  const {
    meetings,
    notifyMentionNum,
    notifyCommentNum,
    notifyFriendApplyNum,
    notifyArticleNum,
    notifyCompanyVerifyNum,
    notifyTenderNum,
    notifyProductNum,
    notifyProductAwardNum,
    notifyProductRatingNum,
    notifyOrderNum,
    poiNum,
  } = useContextData();
  const [searchKey, setSearchKey] = useState();
  const [audioModalVisible, setAudioModalVisible] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [callStatus, setCallStatus] = useState();
  const [callMessage, setCallMessage] = useState();
  const [meetingGroupId, setMeetingGroupId] = useState();
  const [videoMeetingModalVisible, setVideoMeetingModalVisible] =
    useState(false);
  const [searchList, setSearchList] = useState([]);
  const [searchInputFocused, setSearchInputFocused] = useState(false);

  useEffect(() => {
    if (userInfo) {
      let status = getCompanyVerifyStatus(userInfo);
      if (status === CompanyVerifyStatusEnum.WAITING_VERIFY) {
        loadUserInfo();
      }
    }
  }, []);

  useEffect(() => {
    if (meetingGroupId) {
      setVideoMeetingModalVisible(true);
    }
  }, [meetingGroupId]);

  useEffect(() => {
    if (chatCallMsg) {
      setCallMessage(chatCallMsg);
      setCallStatus(AudioCallStatus.CALLING);
      if (chatCallMsg.type === ChatContentsType.AUDIOCALL) {
        setAudioModalVisible(true);
      } else {
        setVideoModalVisible(true);
      }
    }
  }, [chatCallMsg]);

  useEffect(() => {
    if (newMsg) {
      if (newMsg.contentsType === ChatContentsType.TEXT) {
        new Audio(notificationSound).play();
        setMessageCount(messageCount + 1);
      }
      if (newMsg.contentsType === ChatContentsType.CUSTOM) {
        switch (newMsg.customExts.fileType) {
          case ChatContentsType.AUDIOCALL:
            setCallMessage(newMsg);
            setCallStatus(AudioCallStatus.WAITING);
            setAudioModalVisible(true);
            break;
          case ChatContentsType.VIDEOCALL:
            setCallMessage(newMsg);
            setCallStatus(AudioCallStatus.WAITING);
            setVideoModalVisible(true);
            break;
          case ChatContentsType.CANCELCALL:
            onCallModalClose();
            break;
          case ChatContentsType.REFUSECALL:
            leave();
            onCallModalClose();
            break;
          default:
            break;
        }
      }
      // setChatNewMsg(null);
    }
  }, [newMsg]);

  useEffect(() => {
    if (imLoginStatus) {
      getSessionMessage();
    } else {
      initIMLogin(userInfo);
    }
  }, [webIM, imLoginStatus]);

  useEffect(() => {
    if (sessionMessageList.length > 0) {
      let msgCount = 0;
      for (let index = 0; index < sessionMessageList.length; index++) {
        const element = sessionMessageList[index];
        msgCount += element.unread_num;
      }
      setMessageCount(msgCount);
    }
  }, [sessionMessageList]);

  useEffect(() => {
    if (
      user &&
      user.userInfo &&
      user.userInfo.companyDesc &&
      user.userInfo.companyDesc.avatar
    ) {
      setAvatar(user.userInfo.companyDesc.avatar);
    }
  }, [user]);

  const onCompanyHome = async () => {
    let path = "/profile-deprecated/";
    history.push(path + userId);
  };

  const menuProfile = (
    <Menu className="dropdown-menu-language">
      <Menu.Item key="my-profile">
        <a onClick={onCompanyHome.bind(this)}>{t("MY_PROFILE")}</a>
      </Menu.Item>
      <Menu.Item key="shop-order">
        <Link to="/dashboard">{t("CONSOLE_PANEL")}</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="log-out">
        <Link to="/login" onClick={logOut.bind(this)}>
          {t("LOG_OUT")}
        </Link>
      </Menu.Item>
    </Menu>
  );

  const getMeetingMenu = () => {
    if (meetings.length > 0) {
      return (
        <Menu className="dropdown-menu-language">
          {meetings.map((item) => {
            let { meetingId, meetingInfo } = item;
            let { groupId, date } = meetingInfo[0];
            let currentDate = moment().utc();
            let itemDate = moment(date);
            let diff = itemDate.diff(currentDate, "minutes");
            let meetingDate = dateFormat(itemDate.format(), "yyyy-MM-dd HH:mm");
            return (
              <Menu.Item key={meetingId}>
                {diff > 30 && (
                  <span
                    className="dropdown-menu-item"
                    onClick={() => {
                      toastInfo(t("MEETING_NOT_STARTED"));
                    }}
                  >
                    {meetingDate}
                  </span>
                )}
                {diff <= 30 && diff >= -30 && (
                  <span
                    className="dropdown-menu-item"
                    onClick={() => setMeetingGroupId(groupId)}
                  >
                    {meetingDate}
                  </span>
                )}
                {diff < -30 && (
                  <span
                    className="dropdown-menu-item"
                    onClick={() => {
                      toastInfo(t("MEETING_IS_OVER"));
                    }}
                  >
                    {meetingDate}
                  </span>
                )}
              </Menu.Item>
            );
          })}
        </Menu>
      );
    } else {
      return (
        <Menu className="dropdown-menu-language">
          <Menu.Item key="0">
            {t("YOU_DONT_HAVE_ANY_MEETING_PLANNED")}
          </Menu.Item>
        </Menu>
      );
    }
  };

  const onMeetingClose = () => {
    setMeetingGroupId(null);
    setVideoMeetingModalVisible(false);
  };

  function logOut() {
    if (imLoginStatus) {
      imClose(webIM);
    }
    localStorage.clear();
  }

  const onKeyDownchange = (e) => {
    if (e.keyCode == 13) {
      onSearch();
    }
  };

  const onSearch = (e) => {
    history.push("/search", { searchKey });
  };

  const onCallModalClose = () => {
    leave();
    setChatCallMsg(null);
    setChatNewMsg(null);
    setAudioModalVisible(false);
    setVideoModalVisible(false);
    getSessionMessage();
  };

  const onSelectSearch = async (e) => {
    let val = e.target.value;
    setSearchKey(val);
    let pms = { keyword: val };
    let result = await searchSelectList(pms);
    // console.log('----------search list:', result);
    let list = [];
    result.forEach((item) => {
      let searchItem;
      if (item.cardType === CardType.FEED) {
        searchItem = {
          label: item.card.content,
          value: item.card._id,
          cardtype: item.cardType,
        };
      }
      if (
        item.cardType === CardType.WINERY ||
        item.cardType === CardType.IMPORTER
      ) {
        searchItem = {
          label: item.card.nickName,
          value: item.card._id,
          cardtype: item.cardType,
        };
      }
      if (item.cardType === CardType.TENDER) {
        searchItem = {
          label: item.card.title,
          value: item.card._id,
          cardtype: item.cardType,
        };
      }
      if (item.cardType === CardType.PRODUCT && isImporter) {
        searchItem = {
          label: localizeTitle(i18n.language, item.card.title),
          value: item.card._id,
          cardtype: item.cardType,
        };
      }
      if (item.cardType === CardType.EVENT) {
        searchItem = {
          label: item.card.title,
          value: item.card._id,
          cardtype: item.cardType,
        };
      }
      if (searchItem && searchItem.label) {
        if (!isWinery && item.cardtype === CardType.TENDER) {
          return;
        }
        searchItem.label = t(item.cardType) + "-" + searchItem.label;
        list.push(searchItem);
      }
    });
    console.log("-------------search list:", list);
    setSearchList(list);
  };

  const onSelectOption = (option) => {
    setSearchKey(option.label);

    let val=option.value;

    switch (option.cardtype) {
      case CardType.TENDER:
        history.push("/tender/info", { id: val });
        break;
      case CardType.PRODUCT:
        history.push("/products/detail/" + val);
        break;
      case CardType.WINERY:
        history.push("/profile-deprecated/" + val);
        break;
      case CardType.IMPORTER:
        history.push("/profile-deprecated/" + val);
        break;
      case CardType.EVENT:
        history.push("/search", { searchKey: option.label });
        break;
      default:
        history.push("/search", { searchKey: option.label });
        break;
    }
  };

  const onSearchSuggetItemMouseOver = (index, e) => {
    let temp = deepClone(searchList);
    temp.map((item) => {
      item.hover = false;
    });
    temp[index].hover = true;
    setSearchList(temp);
  };

  const onSearchSuggestItemMouseLeave = () => {
    let temp = deepClone(searchList);
    temp.map((item) => {
      item.hover = false;
    });
    setSearchList(temp);
  };

  const menuBannerEdit = (
    <Menu
      style={{
        width: "300px",
      }}
    >
      {searchList.map((searchItem, searchIndex) => {
        return (
          <Menu.Item key={searchIndex}>
            <div
              className="search-menu-item"
              onMouseOver={onSearchSuggetItemMouseOver.bind(this, searchIndex)}
              onMouseLeave={onSearchSuggestItemMouseLeave.bind(this)}
              onClick={onSelectOption.bind(this,searchItem)}
            >
              <img src={searchItem.hover ? searchSelectedIcon : searchIcon} />
              <span className="result-text">{searchItem.label}</span>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className="navbar-container">
      <Row justify="space-between" style={{ height: "100%", width: "100%" }}>
        <Col span={20} className="navbar-item">
          <span className="logo">
            <Link to={"/"}>
              <span className="logo">V</span>
              <span className="logo">i</span>
              <span className="logo">n</span>
              <span className="logo">b</span>
              <span className="logo">i</span>
              <span className="logo">p</span>
            </Link>
          </span>
          <div className="search">
            <Dropdown overlay={menuBannerEdit} trigger={["click"]}>
              <div
                className={
                  searchInputFocused
                    ? "nav-search-bar-normal nav-search-bar-focused"
                    : "nav-search-bar-normal"
                }
              >
                <Input
                  value={searchKey}
                  placeholder={t("SEARCH")}
                  className={
                    searchInputFocused
                      ? "search-input search-input-focused"
                      : "search-input"
                  }
                  onChange={onSelectSearch.bind(this)}
                  onKeyDown={(e) => onKeyDownchange(e)}
                  onFocus={() => setSearchInputFocused(true)}
                  onBlur={() => setSearchInputFocused(false)}
                />
                <img
                  src={searchInputFocused ? searchSelectedIcon : searchIcon}
                  className="search-icon"
                  onClick={onSearch.bind(this)}
                />
              </div>
            </Dropdown>

            {/* <Select
              options={searchList}
              showSearch={true}
              value={searchKey}
              placeholder={t("SEARCH")}
              className="search-input"
              defaultActiveFirstOption={false}
              showArrow={true}
              suffixIcon={
                <img
                  src={searchIcon}
                  onClick={onSearch.bind(this)}
                />
              }
              filterOption={false}
              notFoundContent={null}
              onSearch={onSelectSearch.bind(this)}
              onSelect={onSelectOption.bind(this)}
              onInputKeyDown={onKeyDownchange.bind(this)}
            /> */}
          </div>
        </Col>
        {/* <Col span={1} className="navbar-item">
          <Link to={"/"} className="navbar-link">
            <Tooltip
              title={t("HOME")}
              trigger="hover"
              destroyTooltipOnHide={true}
              placement="bottom"
            >
              <img src={homeIcon} className="navbar-link-icon" />
            </Tooltip>
          </Link>
        </Col> */}
        {/* <Col span={1} className="navbar-item">
          <Link to={"/imchat-deprecated"} className="navbar-link">
            <Tooltip
              title={t("MESSAGING")}
              trigger="hover"
              destroyTooltipOnHide={true}
              placement="bottom"
            >
              <Badge
                size={20}
                shape="circle"
                style={{ backgroundColor: "#D55053" }}
                count={messageCount}
              >
                <img src={messageIcon} className="navbar-link-icon" />
              </Badge>
            </Tooltip>
          </Link>
        </Col> */}
        <Col span={1} className="navbar-item">
          <Link to={"/notify"} className="navbar-link">
            <Tooltip
              title={t("NOTIFICATIONS")}
              trigger="hover"
              destroyTooltipOnHide={true}
              placement="bottom"
            >
              <Badge
                size={20}
                shape="circle"
                style={{ backgroundColor: "#333333" }}
                // count={
                //   notifyMentionNum +
                //   notifyCommentNum +
                //   notifyFriendApplyNum +
                //   notifyArticleNum +
                //   (isWinery
                //     ? notifyProductNum +
                //       notifyProductAwardNum +
                //       notifyProductRatingNum
                //     : notifyCompanyVerifyNum + notifyTenderNum) +
                //   notifyOrderNum
                // }
              >
                <img src={notificationIcon} className="navbar-link-icon" />
              </Badge>
            </Tooltip>
          </Link>
        </Col>
        {/* {isImporter && (
          <Col span={1} className="navbar-item">
            <Link to={"/shopping-cart"} className="navbar-link">
              <Tooltip
                title={t("PURCHASE_ORDER")}
                trigger="hover"
                destroyTooltipOnHide={true}
                placement="bottom"
              >
                <Badge
                  size={20}
                  shape="circle"
                  style={{ backgroundColor: "#D55053" }}
                  count={poiNum}
                >
                  <img src={invoiceIcon} className="navbar-link-icon" />
                </Badge>
              </Tooltip>
            </Link>
          </Col>
        )} */}
        {/**
           *         <Col span={1} className="navbar-item">
          <Dropdown overlay={getMeetingMenu()} trigger={["click"]}>
            <Badge
              size="small"
              style={{ backgroundColor: "#CF3556" }}
              count={meetings.length}
            >
              <img src={meetingIcon} className="navbar-link-icon" />
            </Badge>
          </Dropdown>
        </Col>
           */}

        {/* <Col span={1} className="navbar-item">
          <Link to={"/dashboard"} className="navbar-link">
            <Tooltip
              title={t("DASHBOARDS")}
              trigger="hover"
              destroyTooltipOnHide={true}
              placement="bottom"
            >
              <img src={caseIcon} className="navbar-link-icon" />
            </Tooltip>
          </Link>
        </Col> */}
        {/* <Col span={1} className={"navbar-item"}>
          <Dropdown overlay={menuProfile} trigger={["click"]}>
            <span
              className="ant-dropdown-link navbar-link user-avatar"
              onClick={(e) => e.preventDefault()}
            >
              <AvatarBasic
                cname="avatar"
                picture={localizeImg(_avatar)}
                displayName={user?.userInfo?.firstName || "LO"}
              ></AvatarBasic>
            </span>
          </Dropdown>
        </Col> */}
        <Col span={1.5} className={"navbar-item"}>
          <LanguageSelect />
        </Col>
      </Row>
      <Modal
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        visible={audioModalVisible}
        title={null}
        footer={null}
        className="audio-modal"
        onCancel={onCallModalClose.bind(this)}
      >
        <AudioCall
          callMessage={callMessage}
          callStatus={callStatus}
          onClose={onCallModalClose.bind(this)}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={videoModalVisible}
        footer={null}
        maskClosable={false}
        className="video-modal"
        onCancel={onCallModalClose.bind(this)}
      >
        <VideoCall
          callMessage={callMessage}
          callStatus={callStatus}
          onClose={onCallModalClose.bind(this)}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={videoMeetingModalVisible}
        footer={null}
        className="video-modal"
        onCancel={onMeetingClose.bind(this)}
      >
        <VideoMeeting
          onClose={onMeetingClose.bind(this)}
          groupId={meetingGroupId}
        />
      </Modal>
    </div>
  );
}
