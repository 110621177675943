import React, { useEffect, useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../../core-digital-template/components/Checkbox";
import Balance from "../../../../../../core-digital-template/components/Balance";
import Control from "../../Control";

import { numberWithCommas } from "../../../../../../core-digital-template/utils.js";
import {
  localizeImg,
  localizeValue,
  log,
} from "../../../../../../constants/constants";
import { useHistory } from "react-router";
import {
  ProductStatus,
  statusCode,
} from "../../../../../../constants/statusCode.enum";
import {
  productRemove,
  productUpdateStatus,
} from "../../../../../../api/product";
import { deepClone } from "../../../../../../utils/utils";
import { getProductStatusText } from "../../../../../../api/common";
import { toastInfo, toastSuccess } from "../../../../../../api/toast";
import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../../../../contexts/UserIdProvider";
import { Path, PathTools } from "../../../../../../constants/path";

export default function Row(props) {
  const { item, value, onChange, up } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();

  const [currentProductId, setCurrentProductId] = useState(item.id);

  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  useEffect(() => {
    if (item.id) {
      setCurrentProductId(item.id);
    } else if (item._id) {
      setCurrentProductId(item._id);
    }
  }, [item]);

  const onUpdateProductStatus = async (id, status) => {
    let pms = {
      _id: id,
      status: status,
    };

    let data = await productUpdateStatus(pms);
    if (data) {
      onChange && onChange();
      toastSuccess(t("SUCCESS"));
    }
  };

  const onEditProduct = (item) => {
    history.push(
      PathTools.joinPath(
        Path.productsAdd,
        localizeValue(i18n.language, user?.userInfo?.companyDesc.companyName)
      ),
      {
        productId: item.id || item._id,
      }
    );
  };

  const onDeleteProduct = async (id) => {
    onChange && onChange();
    Modal.confirm({
      title: t("CONFIRM_DELETE"),
      icon: <ExclamationCircleOutlined />,
      content: t("ARE_YOU_SURE_TO_DELETE_THIS"),
      okText: t("CONFIRM"),
      cancelText: t("CANCEL"),
      onOk: async () => {
        let pms = { id: id, lng: i18n.language };
        let data = await productRemove(pms);
        if (data && data.statusCode === statusCode.SUCCESS) {
          toastInfo(t("SUCCESS"));
          // let list = deepClone(products);
          // list = list.filter(
          //   (item) =>
          //     (item.id && item.id !== id) || (item._id && item._id !== id)
          // );
          // setProducts(list);
        } else {
          message.error(t("fail"));
        }
      },
    });
  };

  const onNavigationToProductInfo = (id) => {
    history.push(PathTools.joinPath(Path.productInfo, id));
  };

  const getActionColumn = (record) => {
    log("record", record);
    let id = record.id || record._id;
    return (
      <div
        className={styles.colActionPanel}
      >
        <div
          className={cn("button-stroke button-small", styles.item)}
          onClick={() => onNavigationToProductInfo(record.productId || id)}
        >
          <span>{t("EDIT")}</span>
        </div>
        {record.status == ProductStatus.STOCK && (
          <div
            className={cn("button-stroke button-small", styles.item)}
            onClick={onUpdateProductStatus.bind(
              this,
              id,
              ProductStatus.UNSTOCK
            )}
          >
            <span>{t("OFF_SALE")}</span>
          </div>
        )}
        {(record.status === ProductStatus.VERIFYSUCCESS ||
          record.status === ProductStatus.UNSTOCK) && (
          <div
            className={cn("button-stroke button-small", styles.item)}
            onClick={onUpdateProductStatus.bind(this, id, ProductStatus.STOCK)}
          >
            <span>{t("ON_SALE")}</span>
          </div>
        )}
        <div
          className={cn("button-stroke button-small", styles.item)}
          onClick={onDeleteProduct.bind(this, id)}
        >
          <span>{t("DELETE")}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          {/* <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          /> */}
        </div>
        <div
          className={styles.col}
          onClick={() => onNavigationToProductInfo(item.productId||item.id||item._id)}
        >
          <div className={styles.details}>
            <div className={styles.productTitle}>
              {localizeValue(i18n.language, item.name)}
            </div>
            <div className={styles.productOtherInfo}>{item.typeName}</div>
            <div className={styles.productOtherInfo}>{item.countryName}</div>
          </div>
        </div>
        <div
          className={styles.col}
          onClick={() => onNavigationToProductInfo(item.productId||item.id||item._id)}
        >
          <div className={styles.preview}>
            <img
              srcSet={`${localizeImg(
                item.images?.length > 0 ? item.images[0] : ""
              )} 2x`}
              src={localizeImg(item.images?.length > 0 ? item.images[0] : "")}
              alt={item.title}
            />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>{t("VINTAGE")}</div>
          <div
            className={styles.colLabel}
            style={{ display: "flex", alignItems: "center" }}
          >
            {item?.vintage}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>{t("PRICE")}</div>
          <div
            className={styles.colLabel}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className={cn("status-green", styles.status)}>
              {item.prices ? item.prices[0].currency : ""}
              {item.prices ? item.prices[0].price : ""}
              {/* {item.prices ? item.prices[item.prices.length - 1].currency : ""}
              {item.prices ? item.prices[item.prices.length - 1].price : ""} */}
            </div>
          </div>
        </div>
        {/* <div className={styles.col}>
          <div className={styles.label}>Status</div>
          {item.status ? (
            <div className={cn("status-green", styles.status)}>Active</div>
          ) : (
            <div className={cn("status-red", styles.status)}>Deactive</div>
          )}
          <Control
            className={styles.control}
            visibleActions={visibleActions}
            setVisibleActions={setVisibleActions}
            up={up}
          />
        </div> */}
        <div className={styles.col}>
          <div className={styles.label}>{t("STOCK_AVAILABLE")}</div>
          <div
            className={styles.colLabel}
            style={{ display: "flex", alignItems: "center" }}
          >
            {item.availableStock}
          </div>
          {/* <div className={styles.label}>Views</div>
          <div className={[styles.wrap, styles.box]}>
            <div className={styles.number}>{item.availableStock}</div>
            {item.prices.map((vollumeItem) => {
              return (
                <div className={styles.category}>
                  {vollumeItem.shippingAddressName}
                </div>
              );
            })}
          </div> */}
        </div>
        {/* <div className={styles.col}>
          <div className={styles.label}>Sales</div>
          <div className={styles.sales}>
            <div className={styles.number}>{numberWithCommas(0)}</div>
            <Balance className={styles.balance} value={item.balance} />
          </div>
        </div> */}
        <div className={styles.col}>
          <div className={styles.label}>{t("CREATE_AT")}</div>
          <div
            className={styles.colLabel}
            style={{ display: "flex", alignItems: "center" }}
          >
            {item.createdAt}
          </div>
          {/* <div className={styles.label}>Likes</div>
          <div className={styles.box}>
            <div className={styles.number}>{item.createdAt}</div>
            <div className={styles.line}>
              <div
                className={styles.progress}
                style={{
                  backgroundColor: "#8E59FF",
                  width: item.likesPercent,
                }}
              ></div>
            </div>
          </div> */}
        </div>
        <div className={styles.col}>{getActionColumn(item)}</div>
      </div>
    </>
  );
}
