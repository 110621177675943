import { Button } from "antd";
import { useTranslation } from "react-i18next";

export default function SubscribeButton(props) {
  let { text, history } = props;
  const { t } = useTranslation();
  
  return (
    <>
      {"🔥 "} {t(text)}
      <Button
        type="primary"
        className={"button-primary"}
        style={{
          width: "100%",
          marginTop: 16,
        }}
        onClick={() => {
          history.push("/subscription-logged");
        }}
      >
        {t("SUBSCRIBE_NOW")}
      </Button>
    </>
  );
}
