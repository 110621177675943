import { Button } from "antd";
import { useRef } from "react";
import testMp3 from './test.mp3';

export default function VoiceBasic(props) {
  let { url } = props;
  const currentAudio = useRef(null);

  const onPlay = () => {
    let list = document.getElementsByClassName('voice');
    console.log('------voice paly:', list, url, currentAudio)
    // if (list && list.length > 0) {
    //   for (let index = 0; index < list.length; index++) {
    //     const item = list[index];
    //     item.pause();
    //     item.currentTime = 0;
    //     item = null;
    //   }
    // }
    currentAudio.current.play();
  }

  return (
    // <Button onClick={onPlay.bind(this)}>

    // </Button>
    <audio controls className="voice" src={url} ref={currentAudio}>
      not ok
    </audio>
  );
}
