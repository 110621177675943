import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";
import UploadNormal from "../upload/UploadNormal";
import "./style/userLicenseCopyUploader.less";
import userLicenseCopySample1Img from "./images/user_license_copy_sample_1.png";
import userLicenseCopySample2Img from "./images/user_license_copy_sample_2.png";
import plusIcon from "./images/plus.svg";
import { useEffect, useState } from "react";
import { localizeImg } from "../../../constants/constants";
import { deepClone } from "../../../utils/utils";

export default function UserLicenseCopyUploader(props) {
  const { t } = useTranslation();
  const { label, value, index, onChange, disabled } = props;

  const [licenseCopy1, setLicenseCopy1] = useState("");
  const [licenseCopy2, setLicenseCopy2] = useState("");

  const [licenseModel, setLicenseModel] = useState({});

  useEffect(() => {
    if (value) {
      setLicenseModel(value);
      setLicenseCopy1(localizeImg(value.licenseCopy1));
      setLicenseCopy2(localizeImg(value.licenseCopy2));
    }
  }, [value]);

  const onLicenseCopy1Loading = (index, percent, url) => {
    setLicenseCopy1(url);
  };

  const onLicenseCopy1Loaded = (data) => {
    console.log("onLicenseCopy1Loaded:", data);
    if (data.length > 0) {
      setLicenseCopy1(localizeImg(data[0]));

      let temp = deepClone(licenseModel);
      temp.licenseCopy1 = data[0];
      setLicenseModel(temp);
      onChange && onChange(temp, index);
    }
  };

  const onLicenseCopy2Loading = (index, percent, url) => {
    setLicenseCopy2(url);
  };

  const onLicenseCopy2Loaded = (data) => {
    if (data.length > 0) {
      setLicenseCopy2(localizeImg(data[0]));

      let temp = deepClone(licenseModel);
      temp.licenseCopy2 = data[0];
      setLicenseModel(temp);
      onChange && onChange(temp, index);
    }
  };

  return (
    <div className="user-license-copy-uploader-panel">
      <div>
        <span className="title">{t("UPLOAD_LICENSE_COPY_TITLE")}</span>
        <span className="sub-title">{t("UPLOAD_LICENSE_COPY_TITLE_TIPS")}</span>
      </div>
      <div style={{ display: "flex", marginTop: "18px" }}>
        <div style={{ marginRight: "46px" }}>
          {!disabled ? (
            <UploadNormal
              onLoading={onLicenseCopy1Loading.bind(this, 2)}
              onFileLoaded={onLicenseCopy1Loaded.bind(this)}
            >
              {!licenseCopy1 ? (
                <div>
                  <div className="user-license-copy-upload-panel">
                    <img src={plusIcon} />
                  </div>
                  <div className="user-license-copy-upload-button-ext">
                    {t("UPLOAD_USER_LICENSE_COPY_1")}
                  </div>
                </div>
              ) : (
                <img
                  src={licenseCopy1}
                  className="user-license-copy-upload-panel"
                />
              )}
            </UploadNormal>
          ) : (
            <img
              src={licenseCopy1}
              className="user-license-copy-upload-panel"
            />
          )}
        </div>
        <img
          src={userLicenseCopySample1Img}
          className="user-license-copy-sample"
        />
      </div>
      <div style={{ display: "flex", marginTop: "16px" }}>
        <div style={{ marginRight: "46px" }}>
          {!disabled ? (
            <UploadNormal
              onLoading={onLicenseCopy2Loading.bind(this, 2)}
              onFileLoaded={onLicenseCopy2Loaded.bind(this)}
            >
              {!licenseCopy2 ? (
                <div>
                  <div className="user-license-copy-upload-panel">
                    <img src={plusIcon} />
                  </div>
                  <div className="user-license-copy-upload-button-ext">
                    {t("UPLOAD_USER_LICENSE_COPY_2")}
                  </div>
                </div>
              ) : (
                <img
                  src={licenseCopy2}
                  className="user-license-copy-upload-panel"
                />
              )}
            </UploadNormal>
          ) : (
            <img
              src={licenseCopy2}
              className="user-license-copy-upload-panel"
            />
          )}
        </div>
        <img
          src={userLicenseCopySample2Img}
          className="user-license-copy-sample"
        />
      </div>
    </div>
  );
}
