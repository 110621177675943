import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Logo from "../../resource/icons/vinbip.svg";
//import "../shared/style/rightFooter.less";

export default function RightFooter() {
  const { t } = useTranslation();

  return (
    <div className="right-root-container">
      <div className="about-copyright">
        <span>
          <img src={Logo} className="logo" alt="vinbip-logo-footer" /> - Vinbip
          © 2022
        </span>
      </div>
      <div className="about-copyright">
        <span>沪ICP Filing Number: 京ICP备2021020671号</span>
        <span>京PSB Filing Number: 京ICP备2021020671号</span>
      </div>
      <ul className="about-items">
        {/*        <li className="about-item">
          <Link to={"/about"}>
            <span>{t('ABOUT')}</span>""
          </Link>
        </li>*/}
        <li className="about-item">
          <a
            onClick={() => {
              window.open("https://vinbip.crisp.help/en/");
            }}
          >
            Help center&nbsp;
          </a>
        </li>
        <li className="about-item">
          <Link to={"/privacy"}>
            <span>
              {t("PRIVACY_POLICY")} & {t("TERMS_OF_USE")}
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
}
