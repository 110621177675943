import { actionRequest, formatActionResponse, formatActionResponseArray, formatResponseArray } from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";
import { requestGetUrlFormat } from "./common";

/**
 *
 * @param pms
 */
export function getActivityList(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.activityList, pms);
}


/**
 *
 * @param pms
 */
 export async function activityProducts(pms: any) {
    let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.activityProducts, pms));
    return formatResponseArray(data);
}



/**
 *
 * @param pms
 */
export function getActivityDetail(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.activityDetail, pms);
}

export function userJoinActivity(pms: any) {
    return formatActionResponse(RequestType.POST, apiPath.activityUserJoin, pms);
}

export function getUserActivities(pms: any) {
    return formatActionResponseArray(RequestType.GET, apiPath.userActivities, pms);
}


