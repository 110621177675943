import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../Card";
import Icon from "../../../Icon";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../Dropdown";
import {
  getProductConfigForSelect,
  getProductGrapes,
  loadProductConfig,
} from "../../../../../api/product";
import {
  getCountryForSelect,
  getLocationsByParentId,
} from "../../../../../api/config";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { deepClone } from "../../../../../utils/utils";
import AddressSelect from "../../../../../components/common/location/addressSelect/AddressSelect";

export default function NameAndDescription(props) {
  const {
    className,
    onChange,
    onAddressChange,
    dataSource,
    companyId,
    onClose,
  } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [formData, setFormData] = useState({});
  const [addressFormData, setAddressFormData] = useState({});

  const [areas, setAreas] = useState([]);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (dataSource) {
      let temp = deepClone(formData);
      temp = dataSource;
      console.log("temp:", temp);
      setFormData(temp);
    }
  }, [dataSource]);

  useEffect(() => {
    if (formData?.location?.country && areas && areas.length > 0) {
      onCountrySelect(areas, formData?.location?.country);
    }
  }, [formData?.location?.country, areas]);

  // useEffect(() => {
  //   if (formData?.location?.region && areas && areas.length > 0) {
  //     onRegionSelect(areas, formData?.location?.region);
  //   }
  // }, [formData?.location?.region]);

  useEffect(() => {
    setConfigData();
  }, [currentLanguage]);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let {
      locations,
      types,
      bottles,
      capsules,
      sealed,
      classifications,
      categories,
    } = configData;

    loadCountry(locations.result);
  };

  const loadCountry = (locations) => {
    setAreas(locations);
    let result = getCountryForSelect(locations, currentLanguage,user.userInfo.type);
    setCountries(result);
  };

  const onCountrySelect = (locations, id) => {
    let result = getLocationsByParentId(locations, id, currentLanguage);
    setRegions(result);
  };

  const setLocationFormDataValue = (name, value) => {
    let temp = deepClone(formData);
    if (!temp.location) {
      temp.location = {};
    }
    temp.location[name] = value;
    setFormData(temp);

    onChange && onChange(temp);
  };

  const setLocationAddressFormDataValue = (name, value) => {
    let temp = deepClone(addressFormData);
    temp = value;
    setAddressFormData(temp);

    onChange && onChange(deepClone(formData));
    onAddressChange && onAddressChange(temp);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={t("LOCATION")}
        classTitle="title-red"
        head={
          <Link
            className={cn("button-small", styles.button)}
            onClick={() => onClose && onClose()}
          >
            <Icon name="arrow-left" size="24" />
            <span>{t("CLOSE")}</span>
          </Link>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Dropdown
                className={styles.dropdown_field}
                label={t("COUNTRY")}
                tooltip=""
                value={formData?.location?.country}
                setValue={setLocationFormDataValue.bind(this, "country")}
                options={countries}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <Dropdown
                className={styles.dropdown_field}
                label={t("PROVINCE")}
                tooltip=""
                value={formData?.location?.region}
                setValue={setLocationFormDataValue.bind(this, "region")}
                options={regions}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <AddressSelect
                label={t("ADDRESS")}
                value={formData?.location?.address}
                country={formData?.location?.country}
                onChange={setLocationAddressFormDataValue.bind(this, "address")}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
