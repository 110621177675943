import React, { useEffect, useState } from "react";
import { List, Space, Row, Col, Tabs, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../contexts/UserIdProvider";
import FeedListRow from "./FeedListRow";
import SalonListRow from "./SalonListRow";
import { deepClone, goToProfilePage } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { pageFeeds, recommandTenders, removeFeed } from "../../api/feed";
import { Modal, Avatar, Skeleton } from "antd";
import { Link, useHistory } from "react-router-dom";
import { checkLimit } from "../../api/common";
import { RoleLimit } from "../../constants/roleType.enum";
import { UserType } from "../../constants/usersType.enum";
import TenderListRow from "../tender/TenderListRow";
import ArticleListRow from "./article/ArticleListRow";
import FeedCreate from "./create/FeedCreate";
import { articleMediaList as getArticleMediaList } from "../../api/article";
import "./style/feed.less";
import { getEventList } from "../../api/events";
import EventListRow from "../event/components/EventListRow";
import ListBasic from "../common/list/ListBasic";
import { getActivityList } from "../../api/activity";
import { CardType } from "../../constants/cardType.enum";
import { openFeeds } from "../../api/open";
import { useAuth } from "../../contexts/AuthProvider";
import FeedPost from "./post/feed-post/FeedPost";
import FeedScope from "./post/feed-scope/FeedScope";
import FriendSelect from "./post/friend-select/FriendSelect";
import { FeedScopeType } from "../../constants/feed.enum";

export default function Feed() {
  const history = useHistory();
  const { t } = useTranslation();
  const { isImporter, user } = useUserId();
  const [currentUser, setCurrentUser] = useState(user?.userInfo);
  const { authTokens } = useAuth();
  const [avatar, setAvatar] = useState();

  // console.log("--------------user:",user);

  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState("1");

  const pageSize = 10;
  const [pageIndex, setPageIndex] = useState(0);
  const [feedLoading, setFeedLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const [feedList, setFeedList] = useState([]);
  const [feedListTotal, setFeedListTotal] = useState(0);
  const [activities, setActivities] = useState([]);
  
  const [opportunityList, setOpportunityList] = useState([]);
  const [articleMediaList, setArticleMediaList] = useState([]);
  const [eventList, setEventList] = useState([]);

  const [showFeedPostModal, setShowFeedPostModal] = useState(false);
  const [showFeedPostPanel, setShowFeedPostPanel] = useState(true);
  const [showFeedScopePanel, setShowFeedScopePanel] = useState(false);
  const [showFriendSelectPanel, setShowFriendSelectPanel] = useState(false);
  
  const [feedScopeItem, setFeedScopeItem] = useState({});

  useEffect(() => {
    if (user && user.userInfo) {
      setCurrentUser(user.userInfo);
    }
    if (
      user &&
      user.userInfo &&
      user.userInfo.companyDesc &&
      user.userInfo.companyDesc.avatar
    ) {
      setAvatar(user.userInfo.companyDesc.avatar);
    }
  }, [user]);

  useEffect(() => {
    if (pageIndex >= 0) {
      switch (activeKey) {
        case "1":
          loadRecommand();
          break;
        case "2":
          loadTenders();
          break;
        case "3":
          loadMedia();
          break;
        case "4":
          loadEvent();
          break;
        default:
          break;
      }
    } else {
      let list = deepClone(feedList);
      list = list.map((item) => {
        item.videos = [];
        return item;
      });
      setFeedList(list);
    }
  }, [pageIndex, activeKey]);

  const loadRecommand = async () => {
    setLoading(true);

    let data;
    if (authTokens && currentUser) {
      let pms = {
        userId: currentUser._id,
        page: pageIndex,
        per_page: pageSize,
      };
      data = await pageFeeds(pms);
      console.log("--------------load recommands:1111");
    } else {
      let pms = {
        page: pageIndex,
        per_page: pageSize,
      };
      data = await openFeeds(pms);
      console.log("--------------load recommands:22222");
    }

    console.log("--------------load recommands:", data);

    if (data) {
      let { result } = data;
      result = result.filter((item) => {
        item.cardType = CardType.FEED;
        if (item.shieldUserIds && item.shieldUserIds.length) {
          return item.shieldUserIds.indexOf(user.userId) === -1 ? true : false;
        }
        return true;
      });
      if (user && user.userInfo && user?.userInfo.type != UserType.SUPPLIER) {
        result = await loadActivities(result);
      }
      if (pageIndex > 0) {
        let _list = deepClone(feedList);
        result = _list.concat(result);
      }
      setFeedListTotal(result.length);

      setFeedList(result);
    } else {
      setHasMore(false);
    }
    setLoading(false);
    setFeedLoading(false);
  };

  const loadActivities = async (tempFeedList) => {
    let pms = { from: "1", page: 0, per_page: 60 };
    let resultList = await getActivityList(pms);
    if (resultList && resultList.length > 0) {
      let list = [];
      let salonIndex = 0;
      for (let index = 0; index < tempFeedList.length; index++) {
        list.push(tempFeedList[index]);
        if (
          index < tempFeedList.length &&
          index % 3 == 0 &&
          salonIndex < resultList.length
        ) {
          resultList[salonIndex].cardType = CardType.SALON;
          list.push(resultList[salonIndex]);
          salonIndex++;
          console.log("---------------------------salonIndex:", salonIndex);
        }
        // console.log("---------------------------index :", index);
        // console.log("---------------------------index % 3:", index % 3);
      }
      return list;
    }
    return tempFeedList;
  };

  const loadTenders = async () => {
    setLoading(true);
    let pms = {
      userId: currentUser._id,
      page: pageIndex,
      per_page: pageSize,
      status: 0,
    };
    let data = await recommandTenders(pms);
    console.log("-----recommand tenders:", data, pms);
    if (data && data.total > 0) {
      let { result } = data;
      if (pageIndex > 0) {
        let _list = deepClone(opportunityList);
        result = _list.concat(result);
      }
      setOpportunityList(result);
    }
    setLoading(false);
  };

  const loadMedia = async () => {
    setLoading(true);
    let pms = { userId: currentUser._id, page: pageIndex, per_page: pageSize };
    let result = await getArticleMediaList(pms);
    if (result) {
      if (result.length < pageSize) {
        setHasMore(false);
      }
      if (pageIndex > 0) {
        let _list = deepClone(articleMediaList);
        result = _list.concat(result);
      }
      setFeedListTotal(result.length);
      setArticleMediaList(result);
    } else {
      if (pageIndex > 0) {
        setHasMore(false);
      } else {
        setHasMore(false);
      }
    }
    setLoading(false);
  };

  const loadEvent = async () => {
    setLoading(true);
    let pms = { page: pageIndex, per_page: pageSize };
    let data = await getEventList(pms);
    console.log("-------event result:", data);
    if (data) {
      let { result, total } = data;
      setFeedListTotal(total);
      if (result && result.length > 0) {
        if (pageIndex > 0) {
          let _list = deepClone(eventList);
          result = _list.concat(result);
        }
        setEventList(result);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const onCreateFeed = () => {
    // setFeedCreateModalVisible(false);
    pageRefresh();
  };

  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  const pageRefresh = () => {
    if (pageIndex === 0) {
      setPageIndex(-1);
    }
    setPageIndex(0);
  };

  const onRemoveFeedItem = async (feedItem) => {
    await removeFeed({ feedId: feedItem._id });
    pageRefresh();
  };

  const onWriteArticle = () => {
    if (checkLimit(currentUser, RoleLimit.FEED, history)) {
      history.push("/article/create");
    }
  };

  const onProductDiscover = () => {
    if (!authTokens) {
      history.push("/login");
      return;
    }
    history.push("/discover-deprecated");
  };

  const onWineryTenderShow = (tabNum) => {
    if (!authTokens) {
      history.push("/login");
      return;
    }
    if (checkLimit(currentUser, RoleLimit.PURCHASE_DEMAND, history)) {
      changeTab(tabNum);
    }
  };

  const onImporterTenderShow = () => {
    if (checkLimit(currentUser, RoleLimit.PURCHASE_DEMAND, history)) {
      history.push("/tender/create");
    }
  };

  function changeTab(key) {
    if (!authTokens) {
      history.push("/login");
      return;
    }
    setActiveKey(key);
    setPageIndex(0);
  }

  const onSetShowFeedScopePanel = () => {
    setShowFeedPostPanel(false);
    setShowFeedScopePanel(true);
  };

  const onCloseFeedScopePanel = () => {
    setShowFeedPostPanel(true);
    setShowFeedScopePanel(false);
  };

  const onScopeItemSelected = (scopeItem) => {
    console.log(scopeItem);
    setFeedScopeItem(scopeItem);
    if (scopeItem.value == FeedScopeType.FRIEND_SCOPE_LIMIT) {
      setShowFeedScopePanel(false);
      setShowFriendSelectPanel(true);
    } else if (scopeItem.value == FeedScopeType.SPECIAL_FRIEND) {
      setShowFeedScopePanel(false);
      setShowFriendSelectPanel(true);
    }
  };

  const onFriendSelectClose = () => {
    setShowFeedScopePanel(true);
    setShowFriendSelectPanel(false);
  };

  const onFriendSelectComplete = (e) => {
    console.log(e,feedScopeItem);
    onFriendSelectClose();
  };

  return (
    <div className="feed">
      {feedLoading && (
        <div>
          {/* loader create post */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content feed normal-shadow-generic createFeedPlaceHolder"
            }
          >
            <div>
              <Skeleton active={true} avatar={true} paragraph={{ rows: 0 }} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Space>
                <Skeleton.Button active={true} size={"default"} />
                <Skeleton.Button active={true} size={"default"} />
                <Skeleton.Button active={true} size={"default"} />
              </Space>
              <Skeleton.Button active={true} size={"default"} />
            </div>
          </div>
          {/* loader item simple */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 2 }} />
          </div>
          {/* loader item with images */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 2 }} />
            <div className="feed-images-container">
              <Row gutter={[8, 8]} className="feed-images">
                <Col span={8}>
                  <div className="feed-list-image-box-loader">
                    <Skeleton.Image
                      style={{ width: "100%", height: "100%" }}
                      className="ant-image-loader"
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="feed-list-image-box-loader">
                    <Skeleton.Image
                      style={{ width: "100%", height: "100%" }}
                      className="ant-image-loader"
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="feed-list-image-box-loader">
                    <Skeleton.Image
                      style={{ width: "100%", height: "100%" }}
                      className="ant-image-loader"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/* loader item simple */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 3 }} />
          </div>
          {/* loader item simple */}
          <div
            style={{
              backgroundColor: "#fff",
            }}
            className={
              "scroll-content normal-shadow-generic feed-loading-component"
            }
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 5 }} />
          </div>
        </div>
      )}
      {!feedLoading && (
        <div
          className="feed-list-panel"
          style={{ overflowY: "auto", height: window.innerHeight - 75 + "px" }}
        >
          <InfiniteScroll
            className="scroll-content"
            dataLength={feedListTotal}
            next={handleInfiniteOnLoad.bind(this)}
            hasMore={hasMore}
          >
            <div>
              <FeedCreate
                // onSuccess={onCreateFeed.bind(this)}
                // onWriteArticle={onWriteArticle.bind(this)}
                onItemClick={() => setShowFeedPostModal(true)}
              />
              <Tabs
                activeKey={activeKey}
                defaultActiveKey={1}
                centered
                className="search-content-tabs"
                // onChange={changeTab.bind(this)}
                renderTabBar={() => <></>} // remove tab bar
              >
                <TabPane key="1">
                  <ListBasic
                    data={feedList}
                    renderItem={(item) => (
                      <div className="feed-list-item-container">
                        {item.cardType == CardType.FEED && (
                          <FeedListRow
                            data={item}
                            onRefresh={pageRefresh.bind(this)}
                            onRemoveFeedItem={onRemoveFeedItem.bind(this)}
                          />
                        )}
                        {item.cardType == CardType.SALON && (
                          <SalonListRow data={item} />
                        )}
                      </div>
                    )}
                    loading={loading}
                  />
                </TabPane>
                <TabPane key="2">
                  <ListBasic
                    data={opportunityList}
                    renderItem={(item) => (
                      <div className="feed-list-item-container">
                        <TenderListRow data={item} />
                      </div>
                    )}
                    loading={loading}
                  />
                </TabPane>
                <TabPane key="3">
                  <ListBasic
                    data={articleMediaList}
                    renderItem={(item) => (
                      <div className="feed-list-item-container">
                        <ArticleListRow
                          data={item}
                          onRefresh={pageRefresh.bind(this)}
                        />
                      </div>
                    )}
                    loading={loading}
                  />
                </TabPane>
                <TabPane key="4">
                  <ListBasic
                    data={eventList}
                    renderItem={(item) => (
                      <div className="feed-list-item-container">
                        <EventListRow data={item} />
                      </div>
                    )}
                    loading={loading}
                  />
                </TabPane>
              </Tabs>
            </div>
          </InfiniteScroll>
        </div>
      )}
      <Modal
        destroyOnClose={true}
        title={null}
        visible={showFeedPostModal}
        footer={null}
        className={"feed-post-panel-modal"}
        // onCancel={() => setShowFeedPostModal(false)}
        width={500}
        height={505}
        closable={false}
      >
        {showFeedPostPanel && (
          <FeedPost
            onClose={() => setShowFeedPostModal(false)}
            onSelectFeedScope={onSetShowFeedScopePanel.bind(this)}
            feedScopeItem={feedScopeItem}
          />
        )}
        {showFeedScopePanel && (
          <FeedScope
            selectedFeedScopeItem={feedScopeItem}
            onClose={onCloseFeedScopePanel.bind(this)}
            onItemSelected={onScopeItemSelected.bind(this)}
          />
        )}
        {showFriendSelectPanel && (
          <FriendSelect
            onClose={onFriendSelectClose.bind()}
            onComplete={onFriendSelectComplete.bind(this)}
          />
        )}
      </Modal>
    </div>
  );
}
