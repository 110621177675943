import React from "react";
import { MessageType } from "@amityco/js-sdk";

import Deleted from "./Deleted";
import Text from "./Text";
import ImageCom from "./Image";
import Custom from "./Custom";
import Unsupported from "./Unsupported";

const RENDERERS = {
  [MessageType.Text]: Text,
  [MessageType.Image]: ImageCom,
  [MessageType.Custom]: Custom,
};

const MessageContent = ({ data, type, file, isDeleted }) => {
  if (isDeleted) {
    return <Deleted />;
  }

  // console.log("-----------type", type, data);

  const Renderer = RENDERERS[type] ?? Unsupported;

  return <Renderer data={data} fileInfo={file} />;
};

export default MessageContent;
