import { Avatar, Button, Dropdown, Input, Menu, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../../contexts/UserIdProvider";
import "./style/vmenu.less";
import { useHistory, useParams } from "react-router";

import overviewIcon from "./images/overview.svg";
import settingIcon from "./images/setting.svg";
import accountmanagementIcon from "./images/account_management.svg";
import shopmanagementIcon from "./images/shop_management.svg";
import myOrdersIcon from "./images/my_orders.svg";
import businessoperationIcon from "./images/business_operation.svg";
import datacenterIcon from "./images/data_center.svg";
import cashflowIcon from "./images/cash_flow.svg";
import draftIcon from "./images/draft.svg";
import brandsafeIcon from "./images/brand_safe.svg";
import rightarrowIcon from "./images/right_arrow.svg";
import companyVerifyIcon from "./images/company_verify.svg";
import myAssetsIcon from "./images/my_assets.svg";
import membershipIcon from "./images/membership.svg";
import policyIcon from "./images/policy.svg";
import myOrdersBIcon from "./images/my_orders_b.svg";
import myFilesIcon from "./images/my_files.svg";
import myAddressIcon from "./images/my_address.svg";
import notificationsIcon from "./images/notifications.svg";

import { deepClone } from "../../../../utils/utils";
import { UserType } from "../../../../constants/usersType.enum";

export default function VMenu(props) {
  const { user } = useUserId();
  const userType = user.userInfo.type;
  const { t, i18n } = useTranslation();
  let { rest } = props;
  const history = useHistory();
  let routeParams = useParams();

  const [menuItems, setMenuItems] = useState([]);
  const [subMenuItems, setSubMenuItems] = useState([]);

  useEffect(() => {
    initMenu(rest.path);
  }, []);

  useEffect(() => {
    initMenu(rest.path);
  }, [i18n.language]);

  useEffect(() => {
    if (
      subMenuItems.length > 0 &&
      subMenuItems.filter((filterItem) => filterItem.selected).length == 0
    ) {
      onSubMenuItemClick(subMenuItems[0], 0);
    }
  }, [subMenuItems]);

  const initMenu = (path) => {
    let temp = deepClone(menuItems);
    temp = [];
    temp.push(
      ...[
        {
          icon: overviewIcon,
          label: t("OVERVIEW"),
          url: "/dashboard",
          selected: false,
        },
        {
          icon: settingIcon,
          label: t("SETTINGS"),
          selected: false,
          children: [
            {
              label: t("CONPANY_INFORMATION"),
              url: "/company/settings-deprecated",
              selected: path ? false : true,
            },
            {
              label: t("PASSWORD_SETTING"),
              url: "/company/password-setting-deprecated",
              selected: false,
            },
            {
              label: t("INVOICE_INFORMATION"),
              url: "/company/company-invoice-setting-deprecated",
              selected: false,
            },
          ],
        },
        {
          icon: accountmanagementIcon,
          label: t("ACCOUNT_MANAGEMENT"),
          selected: false,
          children: [
            {
              label: t("CREATE_ACCOUNT"),
              url: "/company/create",
              selected: true,
            },
            {
              label: t("POSITION_MANAGEMENT"),
              url: "/company/staff-deprecated",
              selected: false,
            },
          ],
        },
        {
          icon: companyVerifyIcon,
          label: t("COMPANY_VERIFICATION"),
          selected: false,
          children: [
            {
              label: t("COMPANY_VERIFICATION"),
              url: "/company/verify-deprecated",
              selected: false,
            },
          ],
        },
      ]
    );
    if (userType != UserType.SUPPLIER) {
      temp.push(
        ...[
          {
            icon: myOrdersBIcon,
            label: t("MY_ORDER"),
            selected: false,
            children: [
              {
                label: t("ORDER_MANAGEMENT"),
                url: "/shop/orders/",
                selected: false,
              },
              {
                label: t("WAITING_FOR_PAY"),
                url: "/shop/orders/2",
                selected: false,
              },
              {
                label: t("TRANSITING"),
                url: "/shop/orders/4",
                selected: false,
              },
              {
                label: t("DELIVERED"),
                url: "/shop/orders/5",
                selected: false,
              },
              {
                label: t("RECEIVED"),
                url: "/shop/orders/8",
                selected: false,
              },
            ],
          },
          {
            icon: myFilesIcon,
            label: t("MY_FILES"),
            selected: false,
            children: [
              {
                label: t("I_SENDED"),
                url: "/shop/documents/buyer/0",
                selected: false,
              },
              {
                label: t("I_RECEIVED"),
                url: "/shop/documents/buyer/1",
                selected: false,
              }
            ],
          },
          {
            icon: myAddressIcon,
            label: t("MY_ADDRESS"),
            selected: false,
            children: [
              {
                label: t("MY_ADDRESS"),
                url: "/company/warehouse",
                selected: false,
              }
            ],
          },
          {
            icon: notificationsIcon,
            label: t("NOTIFICATIONS"),
            selected: false,
            children: [
              {
                label: t("GENERAL_NOTIFICATION"),
                url: "/company/notification/setting/0",
                selected: false,
              },
              {
                label: t("NOTIFICATION_FROM_VINBIP"),
                url: "/company/notification/setting/1",
                selected: false,
              }
            ],
          },
        ]
      );
    }
    temp.push(
      ...[
        {
          icon: draftIcon,
          label: t("DRAFT"),
          selected: false,
          children: [
            {
              label: t("PRODUCT"),
              url: "/products/draft",
              selected: true,
            },
            {
              label: t("FEED"),
              url: "/feeds/draft",
              selected: false,
            },
          ],
        },
        {
          icon: brandsafeIcon,
          label: t("BRAND_SAFETY"),
          selected: false,children: [
            {
              label: t("BRAND_SAFETY"),
              url: "/company/brand/company/brand-deprecated",
              selected: true,
            }
          ],
        },
      ]
    );
    if (userType == UserType.SUPPLIER) {
      temp.push(
        ...[
          {
            icon: shopmanagementIcon,
            label: t("SHOP_MANAGEMENT"),
            selected: false,
            children: [
              {
                label: t("PRODUCT_MANAGEMENT"),
                url: "/shop/products-deprecated",
                selected: false,
              },
              {
                label: t("ORDER_MANAGEMENT"),
                url: "/shop/orders/supplier-deprecated",
                selected: false,
              },
              {
                label: t("BANK_INFORMATION"),
                url: "/company/bankInfoSetting-deprecated",
                selected: false,
              },
              {
                label: t("WAREHOUSE_ADDRESS"),
                url: "/company/warehouse/supplier-deprecated",
                selected: false,
              },
              {
                label: t("DOCUMENTS"),
                url: "/shop/documents/buyer/0",
                selected: false,
              },
            ],
          },
          {
            icon: businessoperationIcon,
            label: t("BUSINESS_MANAGEMENT"),
            selected: false,
            children: [
              {
                label: t("NEW_SCHEDULE"),
                url: "/dashboard/operation-create-intro/",
                selected: true,
              },
              {
                label: t("SCHEDULE_LIST"),
                url: "/dashboard/operation-management/",
                selected: false,
              },
            ],
          },
          {
            icon: cashflowIcon,
            label: t("MY_ASSETS"),
            selected: false,
            children: [
              {
                label: t("MY_ASSETS"),
                url: "/dashboard/my-assets",
                selected: true,
              },
            ],
          },
        ]
      );
    }
    if (userType != UserType.SUPPLIER) {
      temp.push({
        icon: myAssetsIcon,
        label: t("MY_ASSETS"),
        selected: false,
        children: [
          {
            label: t("MY_ASSETS"),
            url: "/dashboard/my-assets",
            selected: true,
          },
        ],
      });
    }
    temp.push(
      ...[
        {
          icon: membershipIcon,
          label: t("VINBIP_MEMBERSHIP"),
          selected: false,
        },
        {
          icon: policyIcon,
          label: t("PRIVACY_POLICY"),
          selected: false,
          children: [
            {
              label: t("PRIVACY_POLICY"),
              url: "/dashboard/privacy-policy",
              selected: true,
            },
            {
              label: t("COOKIE_POLICY"),
              url: "/dashboard/cookie-policy",
              selected: false,
            },
          ],
        },
      ]
    );

    let tempSub = deepClone(subMenuItems);
    tempSub=[];
    console.log("params url:", path);
    temp.map((menuItem) => {
      if (menuItem.url == path) {
        menuItem.selected = true;
      } else if (menuItem.children) {
        menuItem.children.map((subMenuItem) => {
          if (subMenuItem.url == path) {
            tempSub = menuItem.children;
            menuItem.selected = true;
            subMenuItem.selected = true;
          }
        });
      }
    });

    console.log("temp menu itemms:", temp);
    setMenuItems(temp);
    setSubMenuItems(tempSub);
  };

  const onMenuItemClick = (menuItem, menuIndex) => {
    console.log(menuIndex);
    let temp = deepClone(menuItems);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[menuIndex].selected = true;
    setMenuItems(temp);

    let subTemp = deepClone(subMenuItems);
    if (menuItem.children) {
      subTemp = deepClone(menuItem.children);
      subTemp.map((mapItem) => {
        mapItem.selected = false;
      });
    } else {
      subTemp = [];
      history.push(menuItem.url);
    }
    setSubMenuItems(subTemp);
  };

  const onSubMenuItemClick = (menuItem, menuIndex) => {
    let temp = deepClone(subMenuItems);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[menuIndex].selected = true;
    setSubMenuItems(temp);

    history.push(menuItem.url);
  };

  return (
    <div className="vmenu-panel">
      <div className="menu-list-panel">
        {menuItems.map((menuItem, menuIndex) => {
          return (
            <div
              className={
                menuItem.selected ? "menu-item-selected menu-item" : "menu-item"
              }
              onClick={onMenuItemClick.bind(this, menuItem, menuIndex)}
            >
              <img src={menuItem.icon} />
              <span className="menu-label">{menuItem.label}</span>
            </div>
          );
        })}
      </div>
      {subMenuItems.length > 0 && (
        <div className="menu-list-panel">
          {subMenuItems.map((menuItem, menuIndex) => {
            return (
              <div
                className={
                  menuItem.selected
                    ? "sub-menu-item-selected sub-menu-item"
                    : "sub-menu-item"
                }
                onClick={onSubMenuItemClick.bind(this, menuItem, menuIndex)}
              >
                <span className="menu-label ellipsis-text">{menuItem.label}</span>
                <img src={rightarrowIcon} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
