import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";

export default function InputNumberBase(props) {
  const { t } = useTranslation();
  let { label, value, index, unit, onChange } = props;
  let inputLayout = { min: 0, max: Number.MAX_SAFE_INTEGER };

  const onInputChange = (val) => {
    onChange(val, index);
  };

  return (
    <>
      <div className="input-title">
        <span
          style={{
            color: "#333333",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {t(label)}
        </span>
      </div>
      <div
        style={{
          border: "1px solid #DEDEDE",
          // width: "350px",
          marginTop: "10px",
        }}
      >
        <InputNumber
          placeholder={t(label)}
          onChange={onInputChange.bind(this)}
          value={value}
          controls={false}
          bordered={false}
          style={{
            width: "90%",
          }}
          {...inputLayout}
        />
        <span
          style={{
            color: "#DEDEDE",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {t(unit)}
        </span>
      </div>
    </>
    // <div className="control-normal-container" key={index}>
    //   <InputNumber maxLength={15} {...inputLayout} value={value} controls={false} bordered={false} placeholder={t(label)} onChange={onInputChange.bind(this)} style={{ width: "100%" }} addonAfter={t(unit)} />
    // </div>
  );
}
