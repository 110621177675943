import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Dropdown,
  List,
  Menu,
  Modal,
  Popover,
  Row,
  Space,
  Image,
  Spin,
  Skeleton,
  Divider,
  Calendar,
} from "antd";
import { MenuOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../../contexts/UserIdProvider";
import "./style/userProfile.less";
import filterIcon from "../../../resource/icons/user-profile/sorting.png";
import {
  applyFriendCreate,
  contactOnLineStatus,
  getContactFriendStatus,
  removeFriend,
  pageFriends,
} from "../../../api/contact";
import {
  companyDetail,
  companyWineryDesc,
  companyImporterDesc,
  getCompanyUsers,
  saveCompanyInfo,
} from "../../../api/company";
import { useTranslation } from "react-i18next";
import {
  FriendApplyStatus,
  ProductStatus,
  statusCode,
} from "../../../constants/statusCode.enum";
import { deepClone, goToProfilePage, groupBy } from "../../../utils/utils";
import {
  dateFormat,
  getDayAgoDate,
  getMonthAgoDate,
  getYearAgoDate,
} from "../../../utils/date";
import {
  createProfileClick,
  getCompanyMainUser,
  userDetail,
} from "../../../api/user";
import UploadImageNormal from "../../common/upload/UploadImageNormal";
import { BrowseType } from "../../../constants/browseType.enum";
import { browseRecordCreate as browseCreate } from "../../../api/browseRecord";
import {
  getFeeds,
  getFeedsCount,
  removeFeed,
  feedImages,
  feedVideos,
} from "../../../api/feed";
import FeedListRow from "../../feed/FeedListRow";
import TenderListRow from "../../tender/TenderListRow";
import ImageListRow from "../../shared/ImageListRow";
import VideoListRow from "../../common/video/VideoListRow";
import {
  getProductConfigNameById,
  getProductPriceRangeText,
  loadProductConfig,
  loadProducts,
  productsFilterItems,
} from "../../../api/product";
import userIcon from "../../../resource/icons/user-profile/user.svg";
import userAddIcon from "../../../resource/icons/user-profile/user-add.svg";
import userRemoveIcon from "../../../resource/icons/user-profile/user-remove.svg";
import msgIcon from "../../../resource/icons/user-profile/msg-white.svg";
import audioCallIcon from "../../../resource/icons/instant-messaging/audio1.svg";
import videoCallIcon from "../../../resource/icons/user-profile/video.svg";
import meetingIcon from "../../../resource/icons/user-profile/meeting.svg";
import moreIcon from "../../../resource/icons/user-profile/more.svg";
import UserItem from "../../shared/UserItem";
import closeIcon from "../../../resource/icons/feed/close.svg";
import InfoCreate from "./components/create/InfoCreate";
import verifiedIcon from "../../../resource/icons/user-profile/verified.svg";
import notVerifyIcon from "../../../resource/icons/user-profile/not_verify.svg";
import toVerifyIcon from "../../../resource/icons/user-profile/to_verify.svg";
import locationIcon from "../../../resource/icons/user-profile/location.svg";
import categoryIcon from "../../../resource/icons/user-profile/category.svg";
import oneWeekMoreIcon from "../../../resource/icons/user-profile/media-filter-more/one_week.svg";
import oneMonthMoreIcon from "../../../resource/icons/user-profile/media-filter-more/one_month.svg";
import sixMonthMoreIcon from "../../../resource/icons/user-profile/media-filter-more/six_month.svg";
import thisYearMoreIcon from "../../../resource/icons/user-profile/media-filter-more/this_year.svg";
import addProductIcon from "../../../resource/icons/user-profile/add_product.svg";
import { toastInfo } from "../../../api/toast";
import {
  checkAllLimit,
  checkLimit,
  getFileTypeByPath,
  hasTheLimit,
} from "../../../api/common";
import {
  localizeImg,
  UploadFileType,
  localizeTitle,
  ProductTableMaxCount,
} from "../../../constants/constants";
import VideoBasic from "../../common/video/VideoBasic";
import UploadBase from "../../common/upload/UploadBase";
import { RoleLimit } from "../../../constants/roleType.enum";
import wineryBanner from "../../../resource/images/user-profile/winery_banner.jpg";
import wineryAvatar from "../../../resource/images/user-profile/winery_logo.jpg";
import { useIMContext } from "../../../contexts/IMProvider";
import { ChatContentsType } from "../../../constants/chatContentsType.enum";
import SkeletonLoader from "tiny-skeleton-loader-react";
import ArticleListRow from "../../feed/article/ArticleListRow";
import { getArticles } from "../../../api/article";
import { UserLabel, UserType } from "../../../constants/usersType.enum";
import cameraIcon from "../../../resource/icons/user-profile/camera.svg";
import playIcon from "../../../resource/icons/user-profile/play.svg";
import { getEventList } from "../../../api/events";
import EventListRow from "../../event/components/EventListRow";
import { getChinaCityNameById, getCountryNameById } from "../../../api/config";
import { CompanyVerifyStatusEnum } from "../../../constants/companyVerifyStatus.enum";
import ProductCardItemForHome from "../../shop/product/ProductCardItemForHome";
import ProductCardItemForShop from "../../shop/product/ProductCardItemForShop";
import feedIcon from "../../../resource/icons/user-profile/menu/feed.svg";
import articleIcon from "../../../resource/icons/user-profile/menu/article.svg";
import eventIcon from "../../../resource/icons/user-profile/menu/event.svg";
import imagesIcon from "../../../resource/icons/user-profile/menu/images.png";
import videoIcon from "../../../resource/icons/user-profile/menu/video.svg";
import myContactIcon from "../../../resource/icons/user-profile/my_contact.svg";
import addContactIcon from "../../../resource/icons/user-profile/add_contact.svg";
import sendMsgIcon from "../../../resource/icons/user-profile/send_msg.svg";
import editProfileIcon from "../../../resource/icons/user-profile/edit_profile.svg";
import CompanyAboutUs from "../../others/CompanyAboutUs";
import LightTextAreaBasic from "../../common/text-area/LightTextAreaBasic";
import FeedImageItem from "../../others/FeedImageItem";

export default function UserProfile() {
  /**
   * Basic setting and data.
   */
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { setChatCallMsg } = useIMContext();
  const { isImporter, user } = useUserId();
  const { companyId } = user.userInfo;
  const [targetUserId, setTargetUserId] = useState(id);
  const currentUserId = user.userId;
  const [isMyself, setIsMyself] = useState(false);
  const [currentUser, setCurrentUser] = useState(user?.userInfo);

  const [isLoading, setIsLoading] = useState(true);
  const [haveRight, setHaveRight] = useState(false);
  const [medias, setMedias] = useState([]);
  const [companyIntro, setCompanyIntro] = useState([]);
  const [companyIntroMore, setCompanyIntroMore] = useState(false);
  const [companyTip, setCompanyTip] = useState();
  const [companyVerified, setCompanyVerified] = useState(false);
  const [companyType, setCompanyType] = useState();
  // const [currentCompanyVerified, setCurrentCompanyVerified] = useState(false);
  const [orgFeedList, setOrgFeedList] = useState([]);
  const [feedList, setFeedList] = useState([]);
  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [productList, setProductList] = useState([]);
  const [oriProductList, setOriProductList] = useState([]);
  const [companyUserList, setCompanyUserList] = useState([]);
  const [myFriendList, setMyFriendList] = useState([]);
  const [friendDetail, setFriendDetail] = useState();

  const filterConstants = {
    posted: "POSTED",
    aboutUs: "ABOUT_US",
    product: "SHOP",
    photo: "PHOTO",
    videos: "VIDEOS",
    event: "EVENT",
  };
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const [selectedNavbarIndex, setSelectedNavbarIndex] = useState(
    filterConstants.posted
  );
  const [filterList, setFilterList] = useState([]);
  const [feedImageList, setFeedImageList] = useState([]);
  const [leftVideoItem, setLeftVideoItem] = useState([]);
  const [leftEventItem, setLeftEventItem] = useState([]);
  const [orgImageList, setOrgImageList] = useState([]);
  const [orgVideoList, setOrgVideoList] = useState([]);
  const [selectedContactIndex, setSelectedContactIndex] = useState(-1);
  const [companyUserOnLineList, setCompanyUserOnLineList] = useState([]);
  const [companyUserOnLineCount, setCompanyUserOnLineCount] = useState(0);
  const [aboutModalVisible, setAboutModalVisible] = useState(false);
  const [
    mainImportingCountriesModalVisible,
    setMainImportingCountriesModalVisible,
  ] = useState(false);
  const [uploadIntroVideoModalVisible, setUploadIntroVideoModalVisible] =
    useState(false);
  const [introVideoPlayModalVisible, setIntroVideoPlayModalVisible] =
    useState(false);
  const [currentLanguage, setCurrentLanguage] = useState();
  const introContentDiv = useRef(null);
  const [contentEllipsis, setContentEllipsis] = useState(false);
  const [editor, setEditor] = useState();
  const onContentChange = (e) => {
    setEditor(e);
  };

  const [selectedProductTypeIndex, setSelectedProductTypeIndex] = useState(1);
  const [productFilterItems, setProductFilterItems] = useState();

  /**
   *  Scrolling index to fetch more data.
   */
  const [pageIndex, setPageIndex] = useState(0);
  const [sort, setSort] = useState(-1);

  /**
   * The profile detail
   */
  const [companyDesc, setCompanyDesc] = useState("");
  const [wineryInfo, setWineryInfo] = useState("");
  const [companyFieldsShow, setCompanyFieldsShow] = useState([]);

  /**
   * The current user data
   */
  const [editProfile, setEditProfile] = useState(false);

  /**
   * Handle the current relation between you and the user
   */
  const [friendStatus, setFriendStatus] = useState(FriendApplyStatus.NOTADDED);
  const [sourceFriendId, setSourceFriendId] = useState();
  const [targetFriendId, setTargetFriendId] = useState();
  const [isFriend, setIsFriend] = useState(false);

  const [lang, setLang] = useState(i18n.language);

  /**
   * Extra tips
   */
  const [contactTipsText, setContactTipsText] = useState("SEND_A_MESSAGE");
  const [showFeedCreate, setShowFeedCreate] = useState(false);
  const [locations, setLocations] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [areas, setAreas] = useState([]);
  const [productTags, setProductTags] = useState([]);
  const [classificationName, setClassificationName] = useState();
  const [feedsNum, setFeedsNum] = useState(0);

  /**
   * Importer
   */
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [mainImportingCountryList, setMainImportingCountryList] = useState([]);

  const [open, setOpen] = useState(false);

  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  useEffect(() => {
    initConfigData();
  }, []);

  useEffect(() => {
    if (!companyIntro || !introContentDiv.current) return;
    const element = introContentDiv.current;
    const clientHeight = element.clientHeight;
    const scrollHeight = element.scrollHeight;
    if (clientHeight < scrollHeight) {
      setContentEllipsis(true);
    } else {
      setContentEllipsis(false);
    }
  }, [companyIntro]);

  useEffect(() => {
    if (companyDesc) {
      let { mainImportingCountry, productCategory } = companyDesc;
      if (mainImportingCountry && mainImportingCountry.length > 0) {
        setMainImportingCountryList(mainImportingCountry);
      }
      if (productCategory && productCategory.length > 0) {
        setProductCategoryList(productCategory);
      }
      if (companyUserList.length == 0) {
        loadCompanyUsers(companyDesc.coId);
      }
    }
  }, [companyDesc]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (!companyIntro) {
      setCompanyIntro(t("NO_ABOUT_US"));
    }
  }, [currentLanguage]);

  useEffect(() => {
    let flag = currentUserId === targetUserId;
    setIsMyself(flag);
    if (isImporter || flag) {
      setHaveRight(true);
    }
  }, [targetUserId]);

  /**
   * Load company profile informations
   */
  useEffect(() => {
    if (id) {
      loadCompanyDesc(id);
    }
  }, [id]);

  useEffect(() => {
    if (targetUserId) {
      loadFriendStatus(currentUserId, targetUserId);
      // initFilterCount();
      initFeedImages();
      initFeedVideos();
      initFeedEvents();
    }
  }, [targetUserId]);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  /**
   * I have no idea what this is for.
   */
  useEffect(async () => {
    if (wineryInfo) {
      let pms = {
        userId: currentUserId,
        busType: BrowseType.WINERY,
        busId: wineryInfo._id,
        name: wineryInfo.nickName,
      };
      await browseCreate(pms);

      initFilterCount();
    }
  }, [wineryInfo]);

  /**
   * Set the current user data
   */
  useEffect(() => {
    if (user?.userInfo) {
      setCurrentUser(user.userInfo);
    }
  }, [user]);

  useEffect(() => {
    if (pageIndex >= 0) {
      switch (selectedFilterIndex) {
        case 0:
          loadFeeds();
          break;
        case 1:
          loadFeedImages();
          break;
        case 2:
          loadFeedVideos();
          break;
        case 3:
          loadEvents();
          break;
        case 4:
          loadArticles();
          break;
        default:
          break;
      }
    }
  }, [targetUserId, selectedFilterIndex, pageIndex, sort]);

  useEffect(() => {
    if (pageIndex >= 0) {
      switch (selectedNavbarIndex) {
        case filterConstants.aboutUs:
          break;
        case filterConstants.posted:
          loadFeeds();
          break;
        case filterConstants.product:
          getProductFilterItems(companyDesc.coId);
          getProducts(companyDesc.coId);
          break;
        case filterConstants.photo:
          loadFeedImages();
          break;
        case filterConstants.videos:
          loadFeedVideos();
          break;
        case filterConstants.event:
          loadEvents();
          break;
        case filterConstants.article:
          loadArticles();
          break;
        case filterConstants.contact:
          loadCompanyUsers(companyDesc.coId);
          break;
        default:
          break;
      }
    }
  }, [selectedNavbarIndex, pageIndex, sort]);

  useEffect(() => {
    if (pageIndex == 0 && wineryInfo.type == UserType.SUPPLIER) {
      setProductList([]);
      setOriProductList([]);
      switch (selectedProductTypeIndex) {
        case 0:
          getProducts(companyDesc.coId);
          break;
        case 1:
          getProducts(companyDesc.coId);
          break;
        case 2:
          getProducts(companyDesc.coId);
          break;
        default:
          break;
      }
    }
  }, [selectedProductTypeIndex, pageIndex]);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations, types, classifications, tags } = configData;
    setLocations(locations);
    setAreas(locations);
    setClassificationName(classifications);
    setProductTags(tags);
    setProductTypes(types);
  };

  const loadCompanyDesc = async (userId) => {
    let userInfo = await userDetail(userId);
    if (!userInfo) {
      let pms = { coId: userId };
      userInfo = await getCompanyMainUser(pms);
      setTargetUserId(userInfo._id);
    }
    if (!userInfo) return;
    setWineryInfo(userInfo);
    const companyInfo = await companyDetail(userInfo.companyId);
    console.log("-------------companyInfo:", companyInfo);
    if (companyInfo.status === CompanyVerifyStatusEnum.VERIFIED) {
      setCompanyVerified(true);
    }
    const coId = companyInfo._id;
    const pms = { coId };
    let data;
    if (userInfo.type == UserType.SUPPLIER) {
      data = await companyWineryDesc(pms);
    } else {
      data = await companyImporterDesc(pms);
    }
    console.log("--------------companyDesc:", data);
    if (data && data.statusCode === statusCode.SUCCESS && data.result) {
      if (data.result.banner) {
        data.result.bannerType = getFileTypeByPath(
          localizeImg(data.result.banner)
        );
      }

      setCompanyType(userInfo.type);
      setCompanyTip(data.result.companyTip);
      setCompanyDesc(data.result);

      initCompanyFieldsShow(userInfo.type, data.result);

      if (data.result.content) {
        setCompanyIntro(data.result.content);
      }
    }

    if (userInfo.type == UserType.SUPPLIER) {
      getProducts(coId);
    }
    loadCompanyUsers(coId);
    loadFriends();
    loadCompanyUserOnLineStatus(coId);
    setIsLoading(false);

    let clickPms = {
      coId: coId,
      userId,
    };
    createProfileClick(clickPms);
  };

  const initCompanyFieldsShow = async (userType, desc) => {
    let configData = await loadProductConfig();
    let { types } = configData;

    console.log("--------------------initCompanyFieldsShow:", desc);

    if (userType == UserType.BUYER) {
      let tempProfileFields = [];
      tempProfileFields.push({
        name: t("COMPANY_CATEGORY"),
        value: t(desc.label),
      });

      setCompanyFieldsShow(tempProfileFields);
    } else if (userType == UserType.SUPPLIER) {
      let tempProfileFields = [];
      tempProfileFields.push({
        name: t("COMPANY_CATEGORY"),
        value: t(desc.label),
      });

      tempProfileFields.push({
        name: t("VINEYARD_SIZE"),
        value: desc.size ? desc.size + t("YARD") : "",
      });

      let productCategoryArr = [];
      desc.productCategory.map((item) => {
        productCategoryArr.push(getProductConfigNameById(types, lang, item));
      });
      let productCategoryStr = productCategoryArr.splice(0, 2).join(",");
      if (productCategoryArr.length > 0) {
        productCategoryStr += "...";
      }
      tempProfileFields.push({
        name: t("PRODUCTTYPE"),
        value: productCategoryStr,
      });
      setCompanyFieldsShow(tempProfileFields);
    }
  };

  const loadFriendStatus = async (sourceId, targetId) => {
    const friendStatusResult = await getContactFriendStatus(sourceId, targetId);
    if (friendStatusResult) {
      const { status, sourceId, targetId } = friendStatusResult;
      setFriendStatus(status);
      setSourceFriendId(sourceId);
      setTargetFriendId(targetId);
      if (status === 5) {
        setIsFriend(true);
        setContactTipsText(t("SEND_MESSAGE"));
      } else if (status === 0) {
        setContactTipsText(t("REQUESTED"));
      } else if (status === 2) {
        setContactTipsText(t("REJECT"));
      }
    }
  };

  const onFriendApply = () => {
    if (!checkLimit(currentUser, RoleLimit.CONTACTS, history)) {
      return;
    }

    const pms = { sourceId: currentUserId, targetId: targetUserId };
    applyFriendCreate(pms).then((data) => {
      if (data && data.statusCode === statusCode.SUCCESS) {
        let list = deepClone(companyUserList);
        list = list.map((item) => {
          if (item._id === targetUserId) {
            item.friendStatus = FriendApplyStatus.APPLIED;
          }
          return item;
        });
        setCompanyUserList(list);
        loadFriendStatus(currentUserId, targetUserId);
      }
    });
  };

  const onBannerLoaded = (data) => {
    if (data.length > 0) {
      let temp = deepClone(companyDesc);
      temp.banner = data[0];
      temp.bannerType = UploadFileType.IMAGE;
      setCompanyDesc(temp);
      onChangeBanner(temp);
    }
  };

  const onBannerLoading = (e) => {
    let temp = deepClone(companyDesc);
    temp.banner = e;
    temp.bannerType = UploadFileType.IMAGE;
    setCompanyDesc(temp);
  };

  const onBannerVideoLoaded = (fileType, url) => {
    if (url) {
      let temp = deepClone(companyDesc);
      temp.video = url;
      // temp.bannerCn = url;
      temp.bannerType = UploadFileType.VIDEO;
      setCompanyDesc(temp);
      onChangeBanner(temp);
    }
  };

  const onChangeBanner = async (pms) => {
    let result = await saveCompanyInfo(pms);
    // if (result.statusCode === 200) {
    //   toastInfo(t("SAVE_SUCCESS"));
    // } else {
    //   toastInfo(t("SAVE_FAILED"));
    // }
  };

  const onAvatarLoaded = (data) => {
    if (data.length > 0) {
      let temp = deepClone(companyDesc);
      // temp.avatarCn = data[0].cn;
      // temp.avatarGlobal = data[0].global;
      temp.avatar = data[0];
      setCompanyDesc(temp);
      onChangeAvatar(temp);
    }
  };

  const onAvatarUploadLoading = (e) => {
    let temp = deepClone(companyDesc);

    temp.avatar = e;
    setCompanyDesc(temp);
  };

  const onMouseEnterAvatar = () => {
    setEditProfile(true);
  };

  const onMouseLeaveAvatar = () => {
    setEditProfile(false);
  };

  const onChangeAvatar = async (pms) => {
    let result = await saveCompanyInfo(pms);
    if (result.statusCode === 200) {
      // toastInfo(t("SAVE_SUCCESS"));
    } else {
      // toastInfo(t("SAVE_FAILED"));
    }
  };

  const onSubmitCompanyContent = async () => {
    setAboutModalVisible(false);
    setOpen(false);
    let content = editor.getData();
    setCompanyIntro(content);
    const pms = {
      content,
    };
    pms.coId = user.userInfo.companyId;
    let result=saveCompanyInfo(pms);

    if (result && result.statusCode === statusCode.SUCCESS) {
      toastInfo(t("SAVE_SUCCESS"));
    } else {
      toastInfo(t("SAVE_FAILED"));
    }
  };

  const initFilterCount = async () => {
    let temp = deepClone(filterList);

    if (
      wineryInfo?.type == UserType.BUYER ||
      wineryInfo?.type == UserType.SUPPLIERBUYER
    ) {
      temp = [
        { name: filterConstants.posted, count: 0, icon: feedIcon },
        { name: filterConstants.aboutUs, count: 0, icon: feedIcon },
        { name: filterConstants.photo, count: 0, icon: imagesIcon },
        { name: filterConstants.videos, count: 0, icon: videoIcon },
        { name: filterConstants.event, count: 0, icon: eventIcon },
      ];
    } else {
      temp = [
        { name: filterConstants.posted, count: 0, icon: feedIcon },
        { name: filterConstants.aboutUs, count: 0, icon: feedIcon },
        { name: filterConstants.product, count: 0, icon: imagesIcon },
        { name: filterConstants.photo, count: 0, icon: imagesIcon },
        { name: filterConstants.videos, count: 0, icon: videoIcon },
        { name: filterConstants.event, count: 0, icon: eventIcon },
      ];
    }
    setFilterList(temp);
  };

  const initFeedImages = async () => {
    let feedImagesPms = {
      userId: targetUserId,
      page: 0,
      per_page: 9,
    };
    let tempFeedImageList = deepClone(feedImageList);
    let feedImageResult = await feedImages(feedImagesPms);
    if (feedImageResult && feedImageResult.total) {
      feedImageResult.result.map((feedItem) => {
        if (feedItem.images && feedItem.images.length > 0) {
          tempFeedImageList.push(...feedItem.images);
        }
      });
      setFeedImageList(tempFeedImageList);
    }
  };

  const initFeedVideos = async () => {
    let feedImagesPms = {
      userId: targetUserId,
      page: 0,
      per_page: 1,
    };
    let feedVideoResult = await feedVideos(feedImagesPms);
    if (feedVideoResult && feedVideoResult.result.length > 0) {
      setLeftVideoItem(feedVideoResult.result[0].videos[0]);
    }
  };

  const initFeedEvents = async () => {
    let pms = { page: 0, per_page: 1, userId: targetUserId };
    let data = await getEventList(pms);
    if (data && data.result && data.result.length > 0) {
      setLeftEventItem(data.result[0]);
    }
  };

  const loadFeeds = async () => {
    setLoading(true);
    let pms = {
      userId: targetUserId,
      page: pageIndex,
      per_page: pageSize,
      sort: sort,
    };
    let data = await getFeeds(pms);
    if (data) {
      let { result } = data;
      if (result.length > 0) {
        if (pageIndex > 0) {
          let _list = deepClone(feedList);
          result = _list.concat(result);
        }
        if (selectedNavbarIndex == filterConstants.posted) {
          setFeedList(result);
          setOrgFeedList(result);
        }
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const loadArticles = async () => {
    let totalArticles = 0;
    setLoading(true);
    let pms = {
      userId: targetUserId,
      page: pageIndex,
      per_page: pageSize,
      sort: sort,
      status: ProductStatus.VERIFYSUCCESS,
    };
    let data = await getArticles(pms);
    console.log("----------load articles:", data);
    if (data && data.total > 0) {
      let { result, total } = data;
      totalArticles = total;
      if (result && result.length > 0) {
        if (pageIndex > 0) {
          let _list = deepClone(feedList);
          result = _list.concat(result);
        }
        if (selectedNavbarIndex == filterConstants.article) {
          setFeedList(result);
        }
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
    setLoading(false);
    return totalArticles;
  };

  const loadEvents = async () => {
    let count = 0;
    setLoading(true);
    let pms = { page: pageIndex, per_page: pageSize, userId: targetUserId };
    let data = await getEventList(pms);
    if (data) {
      let { result, total } = data;
      count = total;
      if (result && result.length > 0) {
        if (pageIndex > 0) {
          let _list = deepClone(feedList);
          result = _list.concat(result);
        }
        // console.log("-----event list ", feedList);
        if (selectedNavbarIndex == filterConstants.event) {
          setFeedList(result);
        }
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
    setLoading(false);
    return count;
  };

  const loadFeedImages = async () => {
    setLoading(true);
    let list = deepClone(orgFeedList);
    // if (hasMore) {
    let pms = {
      userId: targetUserId,
      page: 0,
      per_page: feedsNum,
    };
    let data = await feedImages(pms);
    if (data && data.result && data.result.length > 0) {
      data.result.map((item) => {
        item.createdTime = dateFormat(item.createdAt, "yyyy-MM-dd");
        item.createdAt = dateFormat(item.createdAt, "yyyy-MM");
      });
      // console.log("-----------------------loadFeedImages1:", data.result);
      list = groupBy(data.result, "createdAt");
    } else {
      setFeedList([]);
      setLoading(false);
      return;
    }
    // console.log("-----------------------loadFeedImages:", list);
    let tempResult = [];
    for (const key in list) {
      let imgList = [];
      list[key].map((feedItem) => {
        feedItem.images.map((imgItem) => {
          imgItem.createdTime = feedItem.createdTime;
          imgList.push(imgItem);
        });
      });

      tempResult.push({
        cardType: "IMAGE",
        key: key,
        items: imgList,
      });
    }
    // console.log("-----------------------loadFeedImages:", tempResult);
    if (selectedNavbarIndex == filterConstants.photo) {
      setFeedList(tempResult);
    }
    setOrgImageList(tempResult);
    setLoading(false);
  };

  const onNavbarIndexChange = (e) => {
    setFeedList([]);
    setPageIndex(0);
    setSelectedNavbarIndex(e);
  };

  const loadFeedVideos = async () => {
    setLoading(true);
    let list = deepClone(orgFeedList);
    // if (hasMore) {
    let pms = {
      userId: targetUserId,
      page: 0,
      per_page: feedsNum,
    };
    let data = await feedVideos(pms);
    console.log("-----------------------load videos:", data);
    if (data && data.result && data.result.length > 0) {
      data.result.map((item) => {
        item.createdTime = item.createdTime;
        item.createdAt = dateFormat(item.createdAt, "yyyy-MM");
      });
      console.log("-----------------------loadFeedImages1:", data.result);
      list = groupBy(data.result, "createdAt");
    } else {
      setFeedList([]);
      setLoading(false);
      return;
    }
    let tempResult = [];
    for (const key in list) {
      let imgList = [];
      console.log("-----------------------------list[key]:", list[key]);
      if (list[key]) {
        list[key].map((feedItem) => {
          feedItem.videos.map((imgItem) => {
            imgList.push(imgItem);
          });
        });
      }

      tempResult.push({
        cardType: "VIDEO",
        key: key,
        items: imgList,
      });
    }
    console.log("-----------------------loadFeedImages:", tempResult);
    if (selectedNavbarIndex == filterConstants.videos) {
      setFeedList(tempResult);
    }
    setOrgVideoList(tempResult);
    setLoading(false);
  };

  const getProductFilterItems = async (coId) => {
    let pms = { coId };
    let result = await productsFilterItems(pms);
    // console.log("----------------------------productsFilterItems:", result);
    if ((result.statusCode = statusCode.SUCCESS)) {
      for (const key in result.result) {
        if (result.result[key] && result.result[key].length) {
          for (let index = 0; index < result.result[key].length; index++) {
            if (result.result[key][index].id == "undefined") {
              delete result.result[key][index];
            }
          }
        }
      }
      setProductFilterItems(result.result);
    }
  };

  const getProducts = async (coId) => {
    let pms = {
      page: 0,
      per_page: ProductTableMaxCount,
      coId,
      status: ProductStatus.STOCK,
    };
    let result = await loadProducts(pms);
    console.log("------products list:", result, pms);
    if (result && result.length > 0) {
      let list = [];
      for (let i = 0; i < result.length; i++) {
        list.push(result[i]);
        // if (list.length >= 2) {
        //   break;
        // }
      }
      setProductList(list);
      setOriProductList(list);
    } else {
      setProductList([]);
      setOriProductList([]);
    }
  };

  const loadFriends = async () => {
    let pms = { page: 0, per_page: 6 };
    let result = await pageFriends(user.userId, pms);
    if (result && result.length > 0) {
      setMyFriendList(result);
    }
  };

  const loadCompanyUsers = async (coId) => {
    let pms = { coId: coId };
    let result = await getCompanyUsers(pms);
    if (result && result.length > 0) {
      let list = deepClone(feedList);
      for (let j = 0; j < result.length; j++) {
        let item = result[j];
        item.friendStatus = await getFriendStatus(user.userId, item._id);
        item.cardType = "CONTACT";
        list.push(item);
      }
      setCompanyUserList(list);
      if (selectedNavbarIndex == filterConstants.contact) {
        setFeedList(list);
      }
    }
  };

  const loadCompanyUserOnLineStatus = async (coId) => {
    let pms = { coId };
    let result = await contactOnLineStatus(pms);
    if (result && result.data && result.data.length > 0) {
      let i = 0;
      let list = [];
      result.data.forEach((item) => {
        for (const key in item) {
          if (item[key] === "online") {
            i++;
            list.push(key);
          }
        }
      });
      setCompanyUserOnLineCount(i);
      setCompanyUserOnLineList(list);
    }
  };

  const getUserOnLineStatus = (_id) => {
    return companyUserOnLineList.includes(_id) ? "on" : "off";
  };

  const getCloseIcon = () => {
    return (
      <img alt={"Close icon"} src={closeIcon} className="modal-close-icon" />
    );
  };

  const onCreateFeed = () => {
    setShowFeedCreate(false);
    pageRefresh();
  };

  const getFriendStatus = async (sourceId, targetId) => {
    let friendStautsResult = await getContactFriendStatus(sourceId, targetId);
    if (friendStautsResult) {
      return friendStautsResult.status;
    } else {
      return FriendApplyStatus.NOTADDED;
    }
  };

  const onFriendApply1 = (targetId) => {
    if (!checkLimit(currentUser, RoleLimit.CONTACTS, history)) {
      return;
    }
    let pms = { sourceId: currentUserId, targetId };
    applyFriendCreate(pms);
    let list = deepClone(companyUserList);
    list = list.map((item) => {
      if (item._id === targetId) {
        item.friendStatus = FriendApplyStatus.APPLIED;
      }
      return item;
    });
    setCompanyUserList(list);
  };

  const formatMenuMore = (userItem) => {
    return (
      <Menu
        className="menu-dropdown-container"
        onClick={onChatCall.bind(this, userItem._id)}
      >
        <Menu.Item key="0">
          <img src={audioCallIcon} alt={"audio call"} className="menu-icon" />
          {t("VOICE_CALL")}
        </Menu.Item>
        <Menu.Item key="1">
          <img src={videoCallIcon} className="menu-icon" alt={"video call"} />
          {t("VIDEO_CALL")}
        </Menu.Item>
        <Menu.Item key="2">
          <img
            src={meetingIcon}
            className="menu-icon"
            alt={"conference appointment"}
          />
          {t("CONFERENCE_APPOINTMENT")}
        </Menu.Item>
      </Menu>
    );
  };

  const onChatCall = (friendId, item) => {
    switch (item.key) {
      case "0":
        setChatCallMsg({
          type: ChatContentsType.AUDIOCALL,
          from: currentUserId,
          to: friendId,
        });
        break;
      case "1":
        setChatCallMsg({
          type: ChatContentsType.VIDEOCALL,
          from: currentUserId,
          to: friendId,
        });
        break;
      default:
        break;
    }
  };

  const formatMenuUser = (item) => {
    return (
      <Menu
        className="menu-dropdown-container"
        onClick={onMenuItemClick.bind(this, item._id)}
      >
        <Menu.Item key="0">
          <img
            src={userRemoveIcon}
            className="menu-icon"
            alt={t("DELETE_CONTACT")}
          />
          {t("DELETE_CONTACT")}
        </Menu.Item>
      </Menu>
    );
  };

  const onRemoveFriend = async (friendId) => {
    let pms = {
      sourceId: currentUserId,
      targetId: friendId,
    };
    let pms1 = {
      sourceId: friendId,
      targetId: currentUserId,
    };
    await removeFriend(pms);
    await removeFriend(pms1);
    await loadFriends();
  };

  const onMenuItemClick = (item, friendId) => {
    switch (item.key) {
      case "0":
        onRemoveFriend(friendId);
        break;
      default:
        break;
    }
  };

  const onGoCompanySetting = () => {
    history.push("/company/settings-deprecated");
  };

  const formatContactOperation = (item) => {
    return (
      <div className="company-contact-operation-modal">
        <div className="operation-user">
          <img src={userIcon} className="user-icon" alt={t("USER_ICON")} />
          <div className="user-desc">
            <div className="user-name">{item.nickName}</div>
            <div className="user-signature">{item.signature}</div>
          </div>
        </div>

        <Space size={10} className="operation-btn-container">
          {item.friendStatus === FriendApplyStatus.NOTADDED && (
            <div
              className="btn-default"
              onClick={onFriendApply1.bind(this, item._id)}
            >
              <img src={userAddIcon} className="btn-icon" alt={t("BTN_ICON")} />
              {t("ADD_CONTACT")}
            </div>
          )}
          {item.friendStatus === FriendApplyStatus.ADDED && (
            <>
              <Link
                to={{
                  pathname: "/imchat-deprecated",
                  state: { userId: targetUserId },
                }}
              >
                <div className="btn-default btn-primary">
                  <img
                    alt={t("SEND_MESSAGE")}
                    src={msgIcon}
                    className="btn-icon"
                  />
                  {t("SEND_MESSAGE")}
                </div>
              </Link>
              <Dropdown overlay={formatMenuUser} trigger={["click"]}>
                <div className="btn-default">
                  <img
                    alt={t("DELETE_CONTACT")}
                    src={userAddIcon}
                    className="btn-icon"
                  />
                  {t("DELETE_CONTACT")}
                </div>
              </Dropdown>
            </>
          )}
          {item.friendStatus === FriendApplyStatus.APPLIED && (
            <div className="btn-default">{t("CONTACT_REQUEST_PENDING")}</div>
          )}
          {item.friendStatus === FriendApplyStatus.REFUSED && (
            <div className="btn-default">{t("CONTACT_REQUEST_REJECTED")}</div>
          )}
          {item.friendStatus === FriendApplyStatus.ADDED && (
            <Dropdown overlay={formatMenuMore(item)} trigger={["click"]}>
              <div className="btn-more">
                <MenuOutlined style={{ fontSize: "20px", color: "white" }} />
              </div>
            </Dropdown>
          )}
        </Space>
      </div>
    );
  };

  const onContactClick = (index) => {
    if (selectedContactIndex === index) {
      setSelectedContactIndex(-1);
    } else {
      setSelectedContactIndex(index);
    }
  };

  const onSetSort = (val) => {
    setSort(val);
    setPageIndex(0);
  };

  const menuBannerEdit = (
    <Menu className="menu-dropdown-container">
      <Menu.Item key="0">
        <UploadImageNormal
          onFileLoaded={onBannerLoaded.bind(this)}
          onLoading={onBannerLoading.bind(this)}
          scaleAspect={1038 / 580}
        >
          <div className="banner-btn">
            <span>{t("UPLOAD_IMAGE")}</span>
          </div>
        </UploadImageNormal>
      </Menu.Item>
      <Menu.Item key="1">
        {/* <UploadBase onLoaded={onBannerVideoLoaded.bind(this)}> */}
        <div
          className="banner-btn"
          onClick={() => setUploadIntroVideoModalVisible(true)}
        >
          <span>{t("UPLOAD_VIDEO")}</span>
        </div>
        {/* </UploadBase> */}
      </Menu.Item>
    </Menu>
  );

  const menuProfileInfoMore = (
    <div className="user-profile-more-property-menu-panel">
      {companyFieldsShow.map((mapItem, mapIndex) => {
        return (
          <div key={mapIndex} style={{ marginBottom: "13px" }}>
            <span className="size-title">{mapItem.name}:</span>
            <span className="size-value">{mapItem.value}</span>
          </div>
        );
      })}
    </div>
  );

  const pageRefresh = () => {
    if (pageIndex === 0) {
      setPageIndex(-1);
    }
    setPageIndex(0);
  };

  const onRemoveFeedItem = async (feedItem) => {
    await removeFeed({ feedId: feedItem._id });
    pageRefresh();
  };

  const onWriteArticle = () => {
    if (checkLimit(currentUser, RoleLimit.CONTACTS, history)) {
      history.push("/article/create");
    }
  };

  const seeMoreClick = (e) => {
    setSelectedNavbarIndex(e);
    window.scrollTo({ top: 502 });
  };

  const onImageFilterOneWeek = () => {
    let value = getDayAgoDate(7);
    onImageCalendarSelect(value);
  };

  const onImageFilterOneMonth = () => {
    let value = getMonthAgoDate(1);
    onImageCalendarSelect(value);
  };

  const onImageFilterSixMonth = () => {
    let value = getMonthAgoDate(6);
    onImageCalendarSelect(value);
  };

  const onImageFilterThisYear = () => {
    let value = getYearAgoDate(1);
    onImageCalendarSelect(value);
  };

  const onImageCalendarSelect = (value) => {
    console.log(value);
    // let selectedMonth = value.format("YYYY-MM");
    let temp = deepClone(orgImageList);
    temp = temp.filter(
      (item) =>
        item.items.filter((subFilterItem) => subFilterItem.createdTime >= value)
          .length > 0
    );
    setFeedList(temp);
  };

  const onVideoFilterOneWeek = () => {
    let value = getDayAgoDate(7);
    onVideoCalendarSelect(value);
  };

  const onVideoFilterOneMonth = () => {
    let value = getMonthAgoDate(1);
    onVideoCalendarSelect(value);
  };

  const onVideoFilterSixMonth = () => {
    let value = getMonthAgoDate(6);
    onVideoCalendarSelect(value);
  };

  const onVideoFilterThisYear = () => {
    let value = getYearAgoDate(1);
    onVideoCalendarSelect(value);
  };

  const onVideoCalendarSelect = (value) => {
    let temp = deepClone(orgVideoList);
    temp = temp.filter(
      (item) =>
        item.items.filter((subFilterItem) => subFilterItem.createdTime >= value)
          .length > 0
    );
    setFeedList(temp);
  };

  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  const onPanelChange = (value, mode) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };

  const formatNavbarTips = () => {
    if (
      selectedNavbarIndex == filterConstants.posted ||
      selectedNavbarIndex == filterConstants.photo ||
      selectedNavbarIndex == filterConstants.videos
    ) {
      return (
        <Space size={20}>
          <Dropdown
            overlay={formatMonthMenu(selectedNavbarIndex)}
            trigger={["click"]}
          >
            <img alt={"Filter icon"} src={filterIcon} className="title-icon" />
          </Dropdown>
        </Space>
      );
    }
  };

  const formatMonthMenu = (navIndex) => {
    switch (navIndex) {
      case "POSTED":
        return formatPostSortMenu();
      case "PHOTO":
        return formatImageMonthMenu();
      case "VIDEOS":
        return formatVideoMonthMenu();
      case "EVENTS":
        return formatPostSortMenu();
      case "ARTICLE":
        return formatPostSortMenu();
      case "CONTACT":
        return formatPostSortMenu();
      default:
        break;
    }
  };

  const formatPostSortMenu = () => {
    return (
      <Menu className="menu-dropdown-container">
        <Menu.Item key="0">
          <div className="banner-btn" onClick={onSetSort.bind(this, -1)}>
            <span>{t("ASCENDING_CRONOLOGICAL_ORDER")}</span>
          </div>
        </Menu.Item>
        <Menu.Item key="1">
          <div className="banner-btn" onClick={onSetSort.bind(this, 1)}>
            <span>{t("DESCENDING_CRONOLOGICAL_ORDER")}</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const formatImageMonthMenu = () => {
    return (
      <Menu className="menu-month-dropdown-container">
        <Menu.Item key="0">
          <div
            className="menu-month-item"
            onClick={onImageFilterOneWeek.bind(this)}
          >
            <span>{t("ONE_WEEK")}</span>
            <img src={oneWeekMoreIcon} className="item-icon" />
          </div>
        </Menu.Item>
        <Menu.Item key="1">
          <div
            className="menu-month-item"
            onClick={onImageFilterOneMonth.bind(this)}
          >
            <span>{t("ONE_MONTH")}</span>
            <img src={oneMonthMoreIcon} className="item-icon" />
          </div>
        </Menu.Item>
        <Menu.Item key="2">
          <div
            className="menu-month-item"
            onClick={onImageFilterSixMonth.bind(this)}
          >
            <span>{t("SIX_MONTH")}</span>
            <img src={sixMonthMoreIcon} className="item-icon" />
          </div>
        </Menu.Item>
        <Menu.Item key="3">
          <div
            className="menu-month-item"
            onClick={onImageFilterThisYear.bind(this)}
          >
            <span>{t("THIS_YEAR")}</span>
            <img src={thisYearMoreIcon} className="item-icon" />
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const formatVideoMonthMenu = () => {
    return (
      <Menu className="menu-month-dropdown-container">
        <Menu.Item key="0">
          <div
            className="menu-month-item"
            onClick={onVideoFilterOneWeek.bind(this)}
          >
            <span>{t("ONE_WEEK")}</span>
            <img src={oneWeekMoreIcon} className="item-icon" />
          </div>
        </Menu.Item>
        <Menu.Item key="1">
          <div
            className="menu-month-item"
            onClick={onVideoFilterOneMonth.bind(this)}
          >
            <span>{t("ONE_MONTH")}</span>
            <img src={oneMonthMoreIcon} className="item-icon" />
          </div>
        </Menu.Item>
        <Menu.Item key="2">
          <div
            className="menu-month-item"
            onClick={onVideoFilterSixMonth.bind(this)}
          >
            <span>{t("SIX_MONTH")}</span>
            <img src={sixMonthMoreIcon} className="item-icon" />
          </div>
        </Menu.Item>
        <Menu.Item key="3">
          <div
            className="menu-month-item"
            onClick={onVideoFilterThisYear.bind(this)}
          >
            <span>{t("THIS_YEAR")}</span>
            <img src={thisYearMoreIcon} className="item-icon" />
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const formatAboutCompanyFilterMenu = () => {
    return (
      <Menu>
        <Menu.Item onClick={(e) => e.preventDefault()}>
          <div
            className="modal-content"
            style={{
              width: "650px",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
              position: "absolute",
              left: "-93px",
              top: "0px",
            }}
          >
            <div
              style={{
                padding: "0px 20px",
              }}
            >
              <LightTextAreaBasic
                value={companyIntro}
                showControls={false}
                onInit={onContentChange.bind(this)}
                className="rich-text-container"
                style={{
                  width: "650px",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px 20px",
              }}
            >
              <Button
                className="btn-save-about-us-small"
                onClick={onSubmitCompanyContent.bind(this)}
              >
                {t("SAVE")}
              </Button>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const formatProductFilterMenu = () => {
    return (
      <div className="product-filter-men-container">
        <Row justify="space-between" align="top">
          <Col span={6}>
            <div className="filter-title">{t("COUNTRY")}</div>
            <div className="filter-value-container">
              {productFilterItems && productFilterItems.country && (
                <>
                  {productFilterItems.country.map(
                    (countryItem, countryIndex) => {
                      return (
                        <span
                          className={
                            countryItem.selected
                              ? "filter-value-selected"
                              : "filter-value"
                          }
                          onClick={onProductCountryFilterItemClick.bind(
                            this,
                            countryIndex
                          )}
                        >
                          {getCountryNameById(
                            locations,
                            currentLanguage,
                            countryItem.id
                          )}
                        </span>
                      );
                    }
                  )}
                </>
              )}
            </div>
          </Col>
          <Col span={6}>
            <div className="filter-title">{t("REGION")}</div>
            <div className="filter-value-container">
              {productFilterItems && productFilterItems.region && (
                <>
                  {productFilterItems.region.map((regionItem, regionIndex) => {
                    return (
                      <span
                        className={
                          regionItem.selected
                            ? "filter-value-selected"
                            : "filter-value"
                        }
                        onClick={onProductRegionFilterItemClick.bind(
                          this,
                          regionIndex
                        )}
                      >
                        {getCountryNameById(
                          locations,
                          currentLanguage,
                          regionItem.id
                        )}
                      </span>
                    );
                  })}
                </>
              )}
            </div>
          </Col>
          <Col span={6}>
            <div className="filter-title">{t("PRODUCTTYPE")}</div>
            <div className="filter-value-container">
              {productFilterItems && productFilterItems.type && (
                <>
                  {productFilterItems.type.map((typeItem, typeIndex) => {
                    return (
                      <span
                        className={
                          typeItem.selected
                            ? "filter-value-selected"
                            : "filter-value"
                        }
                        onClick={onProductTypeFilterItemClick.bind(
                          this,
                          typeIndex
                        )}
                      >
                        {getProductConfigNameById(
                          productTypes,
                          lang,
                          typeItem.id
                        )}
                      </span>
                    );
                  })}
                </>
              )}
            </div>
          </Col>
          {/* <Col span={6}>
            <div className="filter-title">{t("PACKAGE")}</div>
          </Col> */}
        </Row>
      </div>
    );
  };

  const onProductCountryFilterItemClick = (index) => {
    let temp = deepClone(productFilterItems);
    temp.country[index].selected = !temp.country[index].selected;
    setProductFilterItems(temp);
    filterProduct(temp);
  };

  const onProductRegionFilterItemClick = (index) => {
    let temp = deepClone(productFilterItems);
    temp.region[index].selected = !temp.region[index].selected;
    setProductFilterItems(temp);
    filterProduct(temp);
  };

  const onProductTypeFilterItemClick = (index) => {
    let temp = deepClone(productFilterItems);
    temp.type[index].selected = !temp.type[index].selected;
    setProductFilterItems(temp);
    filterProduct(temp);
  };

  const filterProduct = (filterItem) => {
    // let tempProducts = deepClone(productList);
    let tempProducts = [];

    let selectedCountries = [];
    let selectedRegions = [];
    let selectedTypes = [];
    if (filterItem.country && filterItem.country.length > 0) {
      selectedCountries = filterItem.country.filter(
        (filterItem) => filterItem.selected
      );
    }
    if (filterItem.region && filterItem.region.length > 0) {
      selectedRegions = filterItem.region.filter(
        (filterItem) => filterItem.selected
      );
    }
    if (filterItem.type && filterItem.type.length > 0) {
      selectedTypes = filterItem.type.filter(
        (filterItem) => filterItem.selected
      );
    }

    if (
      selectedCountries.length > 0 ||
      selectedRegions.length > 0 ||
      selectedTypes.length > 0
    ) {
      oriProductList.map((tempProductItem) => {
        let added = true;

        if (selectedCountries.length > 0) {
          let list = selectedCountries.filter(
            (item) => item.id === tempProductItem.country
          );
          if (list.length === 0) {
            added = false;
          }
        }

        if (added) {
          tempProducts.push(tempProductItem);
        }

        // selectedCountries.map((mapItem) => {
        //   if (tempProductItem.country == mapItem.id) {
        //     tempProducts.push(tempProductItem);
        //     added = true;
        //   }
        // });

        // if (!added) {
        //   selectedRegions.map((mapItem) => {
        //     if (tempProductItem.region == mapItem.id) {
        //       tempProducts.push(tempProductItem);
        //       added = true;
        //     }
        //   });
        // }

        // if (!added) {
        //   selectedCountries.map((mapItem) => {
        //     if (tempProductItem.type == mapItem.id) {
        //       tempProducts.push(tempProductItem);
        //       added = true;
        //     }
        //   });
        // }
      });
    } else {
      tempProducts = oriProductList;
    }

    setProductList(tempProducts);
  };

  const getTabFilterAction = () => {
    if (selectedNavbarIndex == filterConstants.posted) {
      return (
        <div className="title-container">
          <div className="product-type">
            <div className="title-name">{t(selectedNavbarIndex)}</div>
            <div
              className={
                selectedProductTypeIndex == 1
                  ? "type-name type-name-selected"
                  : "type-name"
              }
            >
              {t("FEED")}
            </div>
            <div
              className={
                selectedProductTypeIndex == 1
                  ? "type-name type-name-selected"
                  : "type-name"
              }
            >
              {t("RECENT")}
            </div>
          </div>
          {formatNavbarTips()}
        </div>
      );
    }
    if (selectedNavbarIndex == filterConstants.photo) {
      return (
        <div className="title-container">
          <div className="product-type">
            <div className="title-name">{t(selectedNavbarIndex)}</div>
            <div
              className={
                selectedProductTypeIndex == 1
                  ? "type-name type-name-selected"
                  : "type-name"
              }
            >
              {t("ALL_PHOTOS")}
            </div>
            <div
              className={
                selectedProductTypeIndex == 1
                  ? "type-name type-name-selected"
                  : "type-name"
              }
            >
              {t("RECENT")}
            </div>
          </div>
          {formatNavbarTips()}
        </div>
      );
    }
    if (selectedNavbarIndex == filterConstants.video) {
      return (
        <div className="title-container">
          <div className="product-type">
            <div className="title-name">{t(selectedNavbarIndex)}</div>
            <div
              className={
                selectedProductTypeIndex == 1
                  ? "type-name type-name-selected"
                  : "type-name"
              }
            >
              {t("ALL_VIDEOS")}
            </div>
            <div
              className={
                selectedProductTypeIndex == 1
                  ? "type-name type-name-selected"
                  : "type-name"
              }
            >
              {t("RECENT")}
            </div>
          </div>
          {formatNavbarTips()}
        </div>
      );
    } else if (
      selectedNavbarIndex != filterConstants.product &&
      selectedNavbarIndex != filterConstants.aboutUs
    ) {
      return (
        <div className="title-container">
          <div className="title-name">{t(selectedNavbarIndex)}</div>
          {formatNavbarTips()}
        </div>
      );
    } else if (selectedNavbarIndex == filterConstants.aboutUs) {
      return (
        <div
          className="title-container"
          style={{
            justifyContent: "flex-start",
          }}
        >
          <div className="title-name">{t(selectedNavbarIndex)}</div>
          {isMyself && companyIntro.length == 0 && (
            <Dropdown
              overlay={formatAboutCompanyFilterMenu()}
              trigger={["click"]}
              onOpenChange={handleOpenChange.bind(this)}
              open={open}
            >
              <div className="add-product-panel cursor-pointer">
                <img
                  src={addProductIcon}
                  style={{
                    marginRight: "5px",
                    marginBottom: "2px",
                  }}
                />
                {t("ABOUT_COMPANY")}
              </div>
            </Dropdown>
          )}
        </div>
      );
    } else if (selectedNavbarIndex == filterConstants.product) {
      return (
        <div className="title-container">
          {!isMyself && (
            <>
              <div className="product-type">
                <div className="title-container">
                  <div className="title-name">{t(selectedNavbarIndex)}</div>
                  {formatNavbarTips()}
                </div>
                <div
                  className={
                    selectedProductTypeIndex == 1
                      ? "type-name type-name-selected"
                      : "type-name"
                  }
                  onClick={onProductTypeIndexChange.bind(this, 1)}
                >
                  {t("ALL_PRODUCT")}
                </div>
                <div
                  className={
                    selectedProductTypeIndex == 2
                      ? "type-name type-name-selected"
                      : "type-name"
                  }
                  onClick={onProductTypeIndexChange.bind(this, 2)}
                >
                  {t("NEW_ONLINE_PRODUCT")}
                </div>
              </div>
              {/* <div>
                <Dropdown
                  overlay={formatProductFilterMenu()}
                  trigger={["click"]}
                >
                  <div
                    style={{ marginRight: "20px" }}
                    className="cursor-pointer"
                  >
                    <img
                      alt={"Filter icon"}
                      src={categoryIcon}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "10px",
                      }}
                    />
                    {t("CATEGORY")}
                  </div>
                </Dropdown>
              </div> */}
            </>
          )}
          {isMyself && productList.length > 0 ? (
            <>
              <div className="product-type">
                <div className="title-container">
                  <div className="title-name">{t(selectedNavbarIndex)}</div>
                  {formatNavbarTips()}
                </div>
                <div
                  className={
                    selectedProductTypeIndex == 1
                      ? "type-name type-name-selected"
                      : "type-name"
                  }
                  onClick={onProductTypeIndexChange.bind(this, 1)}
                >
                  {t("ALL_PRODUCT")}
                </div>
                <div
                  className={
                    selectedProductTypeIndex == 2
                      ? "type-name type-name-selected"
                      : "type-name"
                  }
                  onClick={onProductTypeIndexChange.bind(this, 2)}
                >
                  {t("NEW_ONLINE_PRODUCT")}
                </div>
              </div>
              <div>
                <Dropdown
                  overlay={formatProductFilterMenu()}
                  trigger={["click"]}
                >
                  <div
                    style={{ marginRight: "20px" }}
                    className="cursor-pointer"
                  >
                    <img
                      alt={"Filter icon"}
                      src={categoryIcon}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "10px",
                      }}
                    />
                    {t("CATEGORY")}
                  </div>
                </Dropdown>
              </div>
            </>
          ) : (
            <>
              {/* <div
                className="title-container"
                style={{
                  justifyContent: "flex-start",
                }}
              >
                <div className="title-name">{t(selectedNavbarIndex)}</div>
                <div className="add-product-panel cursor-pointer">
                  <img
                    src={addProductIcon}
                    style={{
                      marginRight: "5px",
                      marginBottom: "2px",
                    }}
                  />
                  {t("ADD_PRODUCT")}
                </div>
              </div> */}
            </>
          )}
        </div>
      );
    }
  };

  const onProductTypeIndexChange = (e) => {
    console.log(e);
    setPageIndex(0);
    setSelectedProductTypeIndex(e);
  };

  const getLocationStr = () => {
    return getCountryNameById(locations, currentLanguage, companyDesc.country)
      ? getCountryNameById(locations, currentLanguage, companyDesc.country)
      : t(companyDesc.country) +
        " " +
        getCountryNameById(locations, currentLanguage, companyDesc.region)
      ? getCountryNameById(locations, currentLanguage, companyDesc.region)
      : t(companyDesc.region);
  };

  return (
    <div className="user-profile-page-home">
      {!isLoading ? (
        <div className="banner-container">
          {companyDesc.video && companyDesc.video.cn ? (
            // <VideoBasic
            //   url={localizeImg(companyDesc.video)}
            //   width={"100%"}
            //   height={601}
            // />
            <div
              style={{
                height: "601px",
              }}
            >
              <img
                alt={"Banner"}
                className="banner"
                src={localizeImg(companyDesc.banner) || wineryBanner}
              />
              <img
                src={playIcon}
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "208px",
                }}
                onClick={() => setIntroVideoPlayModalVisible(true)}
              />
            </div>
          ) : (
            <img
              alt={"Banner"}
              className="banner"
              src={localizeImg(companyDesc.banner) || wineryBanner}
            />
          )}
          {isMyself && (
            <div className="banner-btn-container">
              <div
                style={{
                  width: "90%",
                  position: "relative",
                }}
              >
                <div className="banner-btn-panel">
                  <Dropdown overlay={menuBannerEdit} trigger={["click"]}>
                    <div className="banner-btn">
                      <img
                        alt={"Camera icon"}
                        src={cameraIcon}
                        className="btn-icon"
                      />
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="banner-container">
          <SkeletonLoader height={353} />
        </div>
      )}
      <div className="row-container">
        <div style={{ width: "100%", display: "flex" }}>
          <div
            span={6}
            style={{
              width: "396px",
              marginRight: "20px",
            }}
          >
            <div className={"company-info"}>
              <Row justify="center">
                <div className={"avatar-item-upload"}>
                  <img
                    alt={t("AVATAR")}
                    src={
                      localizeImg(companyDesc?.avatar) ||
                      companyDesc.avatarGlobal ||
                      wineryAvatar
                    }
                    className="avatar"
                    onMouseEnter={onMouseEnterAvatar.bind(this)}
                  />
                  <UploadImageNormal
                    onFileLoaded={onAvatarLoaded.bind(this)}
                    onLoading={onAvatarUploadLoading.bind(this)}
                    scaleAspect={1}
                  >
                    <div
                      onMouseLeave={onMouseLeaveAvatar.bind(this)}
                      className={
                        isMyself && editProfile
                          ? "image-title-wrapper"
                          : "image-title-wrapper ele-hide"
                      }
                    >
                      {/* <p>{t("MODIFY_THE_AVATAR")}</p> */}
                      <img src={cameraIcon} />
                    </div>
                  </UploadImageNormal>
                </div>
              </Row>
              <Row justify="center">
                <span className="title">{companyDesc.companyName}</span>
              </Row>
              <Row justify="center">
                {companyDesc?.location && (
                  <div
                    style={{
                      paddingBottom: "20px",
                    }}
                  >
                    {companyVerified && (
                      <div className="verified" style={{ marginBottom: "6px" }}>
                        <img
                          src={verifiedIcon}
                          style={{
                            width: "13.31px",
                            height: "15px",
                            marginRight: "6px",
                            marginBottom: "4px",
                          }}
                        />
                        {t(companyType)}
                      </div>
                    )}
                    {!companyVerified && (
                      <div
                        className="not-verify"
                        style={{ marginBottom: "6px" }}
                      >
                        <img
                          src={notVerifyIcon}
                          style={{
                            width: "13.31px",
                            height: "15px",
                            marginRight: "6px",
                            marginBottom: "4px",
                          }}
                        />
                        {t("COMPANY_NOT_VERIFIED")}
                      </div>
                    )}
                    <div className="location">
                      <img
                        src={locationIcon}
                        style={{
                          width: "12px",
                          height: "14px",
                          marginRight: "6px",
                          marginBottom: "4px",
                        }}
                      />
                      <span
                        className={
                          companyDesc?.location?.country ? "" : "not-verify"
                        }
                      >
                        {t("LOCATION")} :{" "}
                      </span>
                      <span className="location-city">
                        {companyDesc?.location?.country
                          ? getCountryNameById(
                              locations,
                              currentLanguage,
                              companyDesc?.location?.country
                            ) +
                            " " +
                            getChinaCityNameById([
                              companyDesc?.location?.province,
                            ])
                          : ""}
                      </span>
                    </div>
                  </div>
                )}
                {!companyDesc?.location && (
                  <Skeleton active={true} paragraph={{ rows: 1 }} />
                )}
              </Row>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#DEDEDE",
                  width: "296px",
                  margin: "0px auto",
                }}
              ></div>
              <div className="company-info-props-wrapper cursor-pointer">
                {companyFieldsShow.length > 0 &&
                  companyFieldsShow.map((fieldItem) => {
                    return (
                      <Row
                        justify="space-between"
                        style={{ marginBottom: "20px" }}
                      >
                        <span className="size-title">{fieldItem.name}:</span>
                        <span className="size-value">{fieldItem.value}</span>
                      </Row>
                    );
                  })}
                <Row
                  justify="space-between"
                  className="cursor-pointer"
                  style={{ marginBottom: "10px" }}
                >
                  <span className="size-title">{""}</span>
                  <Dropdown
                    overlay={menuProfileInfoMore}
                    trigger={["click"]}
                    placement="bottomLeft"
                  >
                    <span className="size-value">{t("MORE")}</span>
                  </Dropdown>
                </Row>
                {companyFieldsShow.length == 0 && (
                  <div>
                    <div>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </div>
                  </div>
                )}
              </div>
              <div className={"action"}>
                {!isMyself && (
                  <Space direction={"horizontal"} size={10}>
                    {(friendStatus === FriendApplyStatus.NOTADDED ||
                      friendStatus === FriendApplyStatus.IGNORE) && (
                      <div
                        className={"button-add-contact"}
                        onClick={onFriendApply.bind(this)}
                      >
                        <img
                          src={addContactIcon}
                          style={{
                            width: "20px",
                            height: "19.57px",
                            marginRight: "7px",
                            marginTop: "-2px",
                          }}
                        />
                        {t("ADD_CONTACT")}
                      </div>
                    )}
                    {friendStatus === FriendApplyStatus.APPLIED && (
                      <div className="button-add-contact">
                        {t("CONTACT_REQUEST_PENDING")}
                      </div>
                    )}
                    {friendStatus === FriendApplyStatus.ADDED && (
                      <Link
                        to={{
                          pathname: "/imchat-deprecated",
                          state: { userId: targetUserId },
                        }}
                      >
                        <div className={"button-my-contact"}>
                          <img
                            src={myContactIcon}
                            style={{
                              marginBottom: "2px",
                              marginRight: "5px",
                            }}
                          />
                          {t("MY_CONTACT")}
                        </div>
                      </Link>
                    )}
                    <Link
                      to={{
                        pathname: "/imchat-deprecated",
                        state: { userId: targetUserId },
                      }}
                    >
                      <div className={"button-send-msg"}>
                        <img
                          src={sendMsgIcon}
                          style={{
                            marginBottom: "2px",
                            marginRight: "5px",
                          }}
                        />
                        {t("SEND_MESSAGE")}
                      </div>
                    </Link>
                  </Space>
                )}

                {isMyself && (
                  <Space direction={"vertical"} size={10}>
                    <div
                      className="button-to-verify"
                      onClick={() => history.push("/company/verify-deprecated")}
                    >
                      <img
                        src={toVerifyIcon}
                        style={{
                          width: "15px",
                          marginBottom: "2px",
                          marginRight: "5px",
                        }}
                      />
                      {t("GO_TO_VERIFY")}
                    </div>
                    <div
                      className="button-edit-my"
                      onClick={onGoCompanySetting.bind(this)}
                    >
                      <img
                        src={editProfileIcon}
                        style={{
                          width: "15px",
                          marginBottom: "2px",
                          marginRight: "5px",
                        }}
                      />
                      {t("EDIT_MY_PROFILE")}
                    </div>
                  </Space>
                )}
              </div>
            </div>
            <div className="company-desc">
              <div className="left-function-part">
                <div className="title-panel">
                  <div className="contact-title">{t("ABOUT_US")}</div>
                </div>
                {companyIntro && companyIntro.length > 0 && (
                  <div
                    onClick={seeMoreClick.bind(this, "ABOUT_US")}
                    ref={introContentDiv}
                    className={"company-intro-content cursor-pointer"}
                    style={{ height: "auto" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        companyIntro.length > 203
                          ? companyIntro.substring(0, 200) + "..."
                          : "",
                    }}
                  ></div>
                )}
                {(!companyIntro || companyIntro.length == 0) && (
                  <div
                    style={{
                      color: "#838383",
                      textAlign: "center",
                      lineHeight: "80px",
                    }}
                  >
                    {t("NO_DESC_YET")}
                  </div>
                )}
              </div>

              <div className="left-function-part">
                <div className="title-panel">
                  <div className="contact-title">{t("SHOP")}</div>
                  <div
                    className="contact-title-ext cursor-pointer"
                    onClick={seeMoreClick.bind(this, "SHOP")}
                  >
                    <img src={moreIcon} />
                  </div>
                </div>
                {productList.length > 0 ? (
                  <div
                    className={"product-list"}
                    style={{
                      marginTop: "11px",
                    }}
                  >
                    {productList.map((item, index) => {
                      if (index < 2) {
                        return (
                          <div key={index}>
                            <ProductCardItemForHome item={item} />
                          </div>
                        );
                      }
                    })}
                  </div>
                ) : (
                  <div className="no-content-panel">{t("NO_SHOP")}</div>
                )}
              </div>

              <div className="left-function-part">
                <div className="title-panel">
                  <div className="contact-title">{t("ALBUM")}</div>
                  <div
                    className="contact-title-ext cursor-pointer"
                    onClick={seeMoreClick.bind(this, "PHOTO")}
                  >
                    <img src={moreIcon} />
                  </div>
                </div>
                {feedImageList && feedImageList.length > 0 ? (
                  <FeedImageItem
                    images={feedImageList.slice(0, 9)}
                  ></FeedImageItem>
                ) : (
                  <div className="no-content-panel">{t("NO_PICTURES")}</div>
                )}
              </div>

              <div className="left-function-part">
                <div className="title-panel">
                  <div className="contact-title">{t("VIDEO")}</div>
                  <div
                    className="contact-title-ext cursor-pointer"
                    onClick={seeMoreClick.bind(this, "VIDEOS")}
                  >
                    <img src={moreIcon} />
                  </div>
                </div>
                {leftVideoItem && leftEventItem.length > 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <VideoBasic
                      url={localizeImg(leftVideoItem)}
                      width={"100%"}
                      height={"262px"}
                    />
                  </div>
                ) : (
                  <div className="no-content-panel">{t("NO_VIDEO")}</div>
                )}
              </div>

              {leftEventItem && leftEventItem.picture && (
                <div className="left-function-part">
                  <div className="title-panel">
                    <div className="contact-title">{t("EVENTS")}</div>
                    <div
                      className="contact-title-ext cursor-pointer"
                      onClick={seeMoreClick.bind(this, "EVENT")}
                    >
                      <img src={moreIcon} />
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <img
                      src={localizeImg(leftEventItem.picture)}
                      style={{
                        width: "356px",
                        maxHeight: "242px",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                </div>
              )}
              {isMyself && companyUserList.length > 0 && (
                <div className="left-function-part">
                  <div className="title-panel">
                    <div className="contact-title">{t("CONTACTS")}</div>
                    <div
                      className="contact-title-ext cursor-pointer"
                      onClick={seeMoreClick.bind(this, "CONTACT")}
                    >
                      <img src={moreIcon} />
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    {companyUserList.map((item, index) => (
                      // <Popover
                      //   key={index}
                      //   placement="right"
                      //   content={formatContactOperation(item)}
                      //   trigger="click"
                      // >
                      <div
                        className={
                          selectedContactIndex === index
                            ? "contact-item contact-item-selected"
                            : "contact-item"
                        }
                        onClick={onContactClick.bind(this, index)}
                      >
                        <UserItem
                          detail={item}
                          dotStatus={getUserOnLineStatus(item._id)}
                        />
                      </div>
                      // </Popover>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              width: "650px",
            }}
          >
            <div className="business-right">
              {isMyself && (
                <InfoCreate
                  onSuccess={onCreateFeed.bind(this)}
                  // onWriteArticle={onWriteArticle.bind(this)}
                ></InfoCreate>
              )}
              <div className="user-profile-generic-card normal-shadow">
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {filterList.map((item, index) => (
                    <div
                      key={index}
                      className={
                        "filter-item " +
                        (selectedNavbarIndex === item.name &&
                          "filter-item-selected")
                      }
                      style={{
                        borderTopLeftRadius: index == 0 ? "10px" : "0px",
                        borderTopRightRadius:
                          index == filterList.length - 1 ? "10px" : "0px",
                      }}
                      onClick={onNavbarIndexChange.bind(this, item.name)}
                    >
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          minWidth: "90px",
                        }}
                      > */}
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        {t(item.name)}
                      </div>
                      {/* </div> */}
                      {/* <span className="filter-item-number">{item.count}</span> */}
                    </div>
                  ))}
                </div>
                {getTabFilterAction()}
              </div>
              {selectedNavbarIndex == filterConstants.aboutUs && (
                <CompanyAboutUs
                  content={companyIntro}
                  companyDesc={companyDesc}
                  isMyself={isMyself}
                  onEdit={() => setAboutModalVisible(true)}
                ></CompanyAboutUs>
              )}
              {selectedNavbarIndex != filterConstants.aboutUs &&
                selectedNavbarIndex != filterConstants.product && (
                  <InfiniteScroll
                    className="scroll-content company-feeds"
                    dataLength={feedList.length}
                    next={handleInfiniteOnLoad.bind(this)}
                    hasMore={hasMore}
                  >
                    <List
                      itemLayout="vertical"
                      size="large"
                      dataSource={feedList}
                      renderItem={(item, index) => (
                        <div key={index}>
                          {item.cardType === "FEED" && (
                            <div className="feed-list-item-container">
                              <FeedListRow
                                data={item}
                                onRefresh={pageRefresh.bind(this)}
                                onRemoveFeedItem={onRemoveFeedItem.bind(this)}
                              />
                            </div>
                          )}
                          {item.cardType === "TENDER" && (
                            <List.Item>
                              <TenderListRow key={item._id} data={item} />
                            </List.Item>
                          )}
                          {item.cardType === "IMAGE" && (
                            <div
                              style={{
                                backgroundColor: "#FFFFFF",
                                borderRadius: "10px",
                              }}
                            >
                              <ImageListRow data={item} />
                            </div>
                          )}
                          {item.cardType === "VIDEO" && (
                            <div
                              style={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "10px",
                              }}
                            >
                              <VideoListRow data={item} id={index} />
                            </div>
                          )}
                          {item.cardType === "ARTICLE" && (
                            <div
                              style={{
                                backgroundColor: "#FFFFFF",
                              }}
                            >
                              <ArticleListRow data={item} />
                            </div>
                          )}
                          {item.cardType === "EVENT" && (
                            <div
                              style={{
                                backgroundColor: "#FFFFFF",
                              }}
                            >
                              <EventListRow data={item} />
                            </div>
                          )}
                          {/* {item.cardType === "CONTACT" && (
                            <div
                              style={{
                                backgroundColor: "#FFFFFF",
                              }}
                            >
                              <ContactListRow
                                data={item}
                                location={getLocationStr()}
                                showHeader={index == 0}
                              />
                            </div>
                          )} */}
                        </div>
                      )}
                    >
                      {loading && hasMore && (
                        <div>
                          <Skeleton
                            active={true}
                            avatar={true}
                            paragraph={{ rows: 4 }}
                          />
                          <Skeleton
                            active={true}
                            avatar={true}
                            paragraph={{ rows: 4 }}
                          />
                          <Skeleton
                            active={true}
                            avatar={true}
                            paragraph={{ rows: 4 }}
                          />
                        </div>
                      )}
                    </List>
                  </InfiniteScroll>
                )}
              {selectedNavbarIndex == filterConstants.product && (
                <>
                  {/* {isMyself && (
                    <>
                      {user?.userInfo?.subscription ? (
                        <div className="product-list-container">
                          {productList.map((productItem) => {
                            return (
                              <div style={{ marginRight: "5px" }}>
                                <ProductCardItemForShop item={productItem} />
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="product-subscription-tips-panel">
                          <div className="left-part">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: t("APPLY_TO_SELL_NOW_TIPS"),
                              }}
                            ></div>
                            <div className="btn-subscription">
                              {t("SUBSCRIBE_NOW")}
                            </div>
                          </div>
                          <div className="right-part">
                            <div className="right-part-panel">
                              <img src={subscriptionTipsImg} />
                              <div className="btn-add-product">
                                {"+"}
                                {t("ADD_PRODUCT")}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {!isMyself && ( */}
                  <div className="product-list-container">
                    {productList.map((productItem) => {
                      return (
                        <div
                          style={{ marginRight: "5px", marginBottom: "10px" }}
                        >
                          <ProductCardItemForShop item={productItem} />
                        </div>
                      );
                    })}
                  </div>
                  {/* )}*/}
                </>
              )}
            </div>
          </div>
        </div>
        <Row
          gutter={[16, 0]}
          style={{ width: "100%", marginTop: "10px" }}
        ></Row>
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={aboutModalVisible}
        footer={null}
        className="about-us-modal"
        onCancel={() => setAboutModalVisible(false)}
      >
        {/* <AboutCompany content={companyIntro} /> */}
        <div className="modal-content">
          <LightTextAreaBasic
            value={companyIntro}
            onInit={onContentChange.bind(this)}
            //placeholder=""
            className="rich-text-container"
          />
          <br></br>
          <Button
            className="btn-save-about-us"
            onClick={onSubmitCompanyContent.bind(this)}
          >
            {t("SAVE")}
          </Button>
        </div>
      </Modal>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={mainImportingCountriesModalVisible}
        footer={null}
        className="about-company-modal"
        onCancel={() => setMainImportingCountriesModalVisible(false)}
      >
        {mainImportingCountryList.map((item, index) => {
          return (
            <span className="props-value" key={index}>
              {getProductConfigNameById(locations, lang, item)}
              {index < mainImportingCountryList.length - 1 && "  /  "}
            </span>
          );
        })}
      </Modal>
      <Modal
        destroyOnClose={true}
        title={t("UPLOAD_VIDEO")}
        visible={uploadIntroVideoModalVisible}
        footer={null}
        className="upload-video-modal"
        onCancel={() => setUploadIntroVideoModalVisible(false)}
      >
        <div className="upload-video-modal-container">
          <span className="title">{t("UPLOAD_VIDEO")}</span>
          <UploadBase onLoaded={onBannerVideoLoaded.bind(this)}>
            <div className="upload-container">
              <div className="upload-image-border">
                {companyDesc.video ? (
                  <VideoBasic
                    url={localizeImg(companyDesc.video)}
                    className="placeholder-img"
                  />
                ) : (
                  <img src={wineryBanner} className="placeholder-img" />
                )}
                <div className="placeholder-container-bg"></div>
                <div className="placeholder-container">
                  <span>{t("UPLOAD_VIDEO")}</span>
                </div>
              </div>
            </div>
          </UploadBase>
          <span className="title">{t("UPLOAD_COVER_PICTURES")}</span>
          <UploadImageNormal
            onFileLoaded={onBannerLoaded.bind(this)}
            scaleAspect={1038 / 580}
          >
            <div className="upload-container">
              <div className="upload-image-border">
                <img
                  src={localizeImg(companyDesc.banner) || wineryBanner}
                  className="placeholder-img"
                />
                <div className="placeholder-container-bg"></div>
                <div className="placeholder-container">
                  <span>{t("COVER_PICTURES")}</span>
                </div>
              </div>
            </div>
          </UploadImageNormal>
        </div>
      </Modal>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={introVideoPlayModalVisible}
        footer={null}
        width="100vw"
        className="play-intro-video-modal"
        onCancel={() => setIntroVideoPlayModalVisible(false)}
      >
        <VideoBasic
          url={localizeImg(companyDesc.video)}
          width={"100%"}
          height={601}
        />
      </Modal>
    </div>
  );
}
