import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../contexts/UserIdProvider";
import {
  remove as tenderRemove,
  getUserTenderList,
  demandOffers,
  demandOfferPage,
} from "../../api/tender";
import TenderListRow from "./TenderListRow";
import { statusCode } from "../../constants/statusCode.enum";
import ListBasic from "../common/list/ListBasic";
import BreadcrumbNormal from "../common/title/BreadcrumbNormal";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Breadcrumb, Image, Space, Table } from "antd";
import { Link, useHistory } from "react-router-dom";
import { dateFormat } from "../../utils/date";
import { localizeImg } from "../../constants/constants";
import { getProductStatusText } from "../../api/common";
import { ProductStatus } from "../../constants/statusCode.enum";

export default function Tenders() {
  const { t } = useTranslation();
  const history = useHistory();
  const { isWinery, user } = useUserId();
  const [tenders, setTenders] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [statusVisible, setStatusVisible] = useState(false);

  const pageSize = 20;

  const loadData = async () => {
    setLoading(true);
    let list = [];
    if (isWinery) {
      let pms = {
        coId: user.userInfo.companyId,
        page: pageIndex,
        per_page: pageSize,
      };
      let result = await demandOfferPage(pms);
      if (result && result.length > 0) {
        result.forEach((item) => {
          if (item.tenderInfo && item.tenderInfo.length > 0) {
            list.push(item.tenderInfo[0]);
          }
        });
      }
      // console.log('------demandOffers list:');
    } else {
      let pms = { userId: user.userId, page: pageIndex, per_page: pageSize };
      let data = await getUserTenderList(pms);
      // console.log('------tender list:', data);
      if (data && data.total > 0) {
        list = data.result;
      }
      setStatusVisible(true);
    }

    console.log("----------------------------", list);
    if (list && list.length > 0) {
      if (pageIndex > 0) {
        setTenders(tenders.concat(list));
      } else {
        setTenders(list);
      }
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [pageIndex, isWinery]);

  function onRemove(id) {
    let pms = { tenderId: id };
    tenderRemove(pms).then((data) => {
      if (data && data.statusCode === statusCode.SUCCESS) {
        updateTendersAfterRemoveItem(id);
      }
    });
  }

  function updateTendersAfterRemoveItem(id) {
    let list = tenders.filter((item) => {
      return item._id !== id;
    });
    setTenders(list);
  }

  const onFormatItem = (item, index) => {
    return (
      <div key={index}>
        <TenderListRow data={item} notify={statusVisible} />
      </div>
    );
  };

  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  const columns = [
    {
      title: t("TITLE"),
      dataIndex: "title",
      key: "title",
      render: (val, data) => (
        <Link
          to={{
            pathname: "/tender/info",
            state: { detail: data },
          }}
        >
          {val}
        </Link>
      ),
    },
    {
      title: t("PICK_UP_DATE"),
      dataIndex: "pickupDate",
      key: "pickupDate",
      render: (date) => dateFormat(date),
    },
    // {
    //   title: t('ARTICLE_BANNER'),
    //   dataIndex: 'banner',
    //   key: ['banner'],
    //   render: item => <Image
    //     width={150}
    //     height={150}
    //     src={localizeImg(item[0])}
    //   />,
    // },
    {
      title: t("VERIFY_STATUS"),
      dataIndex: "verifyStatus",
      key: "verifyStatus",
      render: (val) => t(getProductStatusText(val)),
    },
    {
      title: t("DEMAND_OFFER_STATUS"),
      dataIndex: "status",
      key: "status",
      render: (val) => (val === 0 ? t("OPENED") : t("SUSPEND")),
    },
    {
      title: t("ACTION"),
      key: "_id",
      dataIndex: "_id",
      render: (id, item) => (
        <Space size="middle">
          {/* <a onClick={toArticleDetail.bind(this, id)}>
            {t("PREVIEW")}
          </a>
          {item.status !== ProductStatus.VERIFYSUCCESS &&
            <>
              <span className="table-action-item-split">-</span>
              <a onClick={onEditArticle.bind(this, id)}>
                {t("EDIT")}
              </a>
            </>
          } 
          <span className="table-action-item-split">-</span>*/}
          {isWinery ? (
            <Link
              to={{
                pathname: "/tender/info",
                state: { detail: item },
              }}
            >
              {t("DETAILS")}
            </Link>
          ) : (
            <a onClick={onRemove.bind(this, id)}>{t("DELETE")}</a>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="common-page">
      <BreadcrumbNormal
        title={isWinery ? t("DEMAND_OFFER") : t("OPPORTUNITY_MANAGEMENT")}
        parent={t("MY_BUSINESS")}
      />
      <div className="common-management">
        <div className="common-management-title">
          {isWinery ? t("DEMAND_OFFER") : t("OPPORTUNITY_MANAGEMENT")}
          {!isWinery && (
            <Button
              className="create-button"
              onClick={() => {
                history.push("/tender/create");
              }}
            >
              + {t("CREATE")}
            </Button>
          )}
        </div>
        <div style={{ marginTop: "10px" }} className="common-management-panel">
          <Table columns={columns} dataSource={tenders} />
        </div>

        {/* <InfiniteScroll
          className="scroll-content"
          dataLength={tenders.length}
          next={handleInfiniteOnLoad.bind(this)}
          hasMore={hasMore}
        >
          <ListBasic
            data={tenders}
            renderItem={onFormatItem.bind(this)}
            loading={loading}
          />
        </InfiniteScroll> */}
      </div>
    </div>
  );
}
