import {
  actionRequest,
  formatActionResponse,
  formatActionResponseArray,
  formatResponse,
} from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";
import { requestGetUrlFormat } from "./common";

/**
 *
 * @param pms
 */
export async function createMessageTranslation(pms: any) {
  let data = await actionRequest(RequestType.POST, apiPath.createMessageTranslation, pms);
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function messageTranslationInfo(pms: any) {
  let data = await actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.messageTranslationInfo, pms));
  return formatResponse(data);
}


/**
 *
 * @param pms
 */
export async function pageFeeds(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.feeds, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function getFeedsCount(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.feedsCount, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function getFeeds(pms: any) {
  if (!pms.sort) {
    pms.sort = -1;
  }
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.feedsGet, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function getFeedsDraft(pms: any) {
  if (!pms.sort) {
    pms.sort = -1;
  }
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.feedsGetDraft, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function feedImages(pms: any) {
  if (!pms.sort) {
    pms.sort = -1;
  }
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.feedImages, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function feedVideos(pms: any) {
  if (!pms.sort) {
    pms.sort = -1;
  }
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.feedVideos, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function recommandFeeds(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.recommandFeeds, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function recommandTenders(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.recommandTenders, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function recommandArticles(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.recommandArticles, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function create(pms: any) {
  let data = await actionRequest(RequestType.POST, apiPath.feedCreate, pms);
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export function detail(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.feedDetail, pms)
  );
}

export function removeFeed(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.feedRemove, pms);
}

/**
 *
 * @param pms
 */
export function feedCommentCreate(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.feedCommentCreate, pms);
}

export function feedCommentUpdate(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.feedCommentUpdate, pms);
}

/**
 *
 * @param pms
 */
export function feedComments(pms: any) {
  return formatActionResponseArray(RequestType.GET, apiPath.feedComments, pms);
}

/**
 *
 * @param pms
 */
export function likes(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.feedLikes, pms)
  );
}

/**
 *
 * @param pms
 */
export function likeCreate(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.feedLikeCreate, pms);
}

/**
 *
 * @param pms
 */
export function forwards(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.feedForwards, pms)
  );
}

/**
 *
 * @param pms
 */
export function recommandUsers(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.recommandUsers, pms);
}

/**
 *
 * @param pms
 */
export function receivedCommentPage(pms: any) {
  return formatActionResponse(
    RequestType.GET,
    apiPath.feedCommentsReceived,
    pms
  );
}

export function receivedMentionPage(pms: any) {
  return formatActionResponse(
    RequestType.GET,
    apiPath.feedMentionReceived,
    pms
  );
}

export function feedMentionUpdate(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.feedMentionUpdate, pms);
}

export function searchPage(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.searchPage, pms);
}

export function searchSelectList(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.searchSelectList, pms);
}

/**
 *
 * @param pms
 */
export async function reportFeed(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.feedReport, pms)
  );
  return formatResponse(data);
}

export async function getUserLikeFeeds(pms: any) {
  return formatActionResponseArray(RequestType.GET, apiPath.feedUserLikes, pms);
}

/**
 *
 * @param pms
 */
export function addFeedFavorite(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.feedFavoriteAdd, pms);
}

/**
 *
 * @param pms
 */
export function removeFeedFavorite(pms: any) {
  return formatActionResponse(
    RequestType.POST,
    apiPath.feedFavoriteRemove,
    pms
  );
}

/**
 *
 * @param pms
 */
export function getFeedFavoriteStatus(pms: any) {
  return formatActionResponse(
    RequestType.POST,
    apiPath.feedFavoriteStatus,
    pms
  );
}

export async function feedFavoriteList(pms: any) {
  return formatActionResponseArray(
    RequestType.GET,
    apiPath.feedFavoriteList,
    pms
  );
}
