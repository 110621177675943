export class PathTools {
  static joinPath(path: string, coname: string) {
    return path.replace(":", "").replace("coname", coname);
  }
}

export const Path = {
  feed: `/feed`,
  dashboard: `/dashboard/:coname`,
  partners: `/partners/:coname`,
  products: `/store/:coname/products`,
  productsAdd: `/store/:coname/products/add`,
  orders: `/store/:coname/orders`,
  supplierBankInfo: `/store/:coname/bank`,
  supplierWarehouse: `/store/:coname/shipping`,
  supplierStock: `/store/:coname/stock`,
  supplierStockAdd: `/store/:coname/stock/add`,
  companyDetail: `/profile/:coname`,
  productInfo: `/product/:coname`,
  productAdd: `/product/add`,
  messaging: `/messaging/:coname`,
  instantMessaging: `/im/:coname`,
  companyInformation: `/store/:coname/company`,
  companyInvoiceSetting: `/store/:coname/invoicing`,
  companyVerify: `/store/:coname/details`,
  shopSearch:`/store/:coname/search`,
  brandSafety: `/services/:coname/brand`,
  settingsUsers: `/settings/:coname/users`,
  settingsSecurity: `/settings/:coname/security`,
  settingsPrivacy: `/settings/:coname/privacy`,
  settingsNotifications: `/settings/:coname/notifications`,
  signIn: `/login`,
  forgotPassword: `/forgot-password`,
  resetPassword: `/setting/:email/reset-password`,
  languageSetting: `/setting/language`,
  wechatBinding: `/setting/wechat-binding`,
  developing: "/developing",
  signUp: `/signup`,
  events: `/events`,
  notifications:`/notifications`,
  vinbipSupplierList:`/vinbip/suppliers`,
  vinbipAddOrUpdateSuplier:`/vinbip/suppliers/addOrUpdate`,
  vinbipBuyerList:`/vinbip/buyers`,
  visitorCompanyDetail: `/visitor/profile/:coname`,
  recommendUsers:`/recommendUsers`,
  emailVerify:`/company-verify/return/:token`,
};
