import moment from "moment";
import React, { createContext, useContext, useReducer } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const stateContext = createContext();

export function StateProvider({ children }) {
  const [count, setCount] = useLocalStorage("count", {
    loaded: false,
    contact: 0,
    feed: 0,
    feedImages: 0,
    feedVideos: 0,
    product: 0,
  });
  const [calendar, setCalendar] = useLocalStorage("calendar", {
    name: "",
    start: moment(),
    end: moment(),
  });
  const initialState = {
    ...count,
    ...calendar,
  };
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case "contact": {
        let result = { ...state, contact: action.value, loaded: true };
        setCount(result);
        return result;
      }
      case "product": {
        let result = { ...state, product: action.value };
        setCount(result);
        return result;
      }
      case "feed": {
        let result = {
          ...state,
          feed: action.total,
          feedImages: action.images,
          feedVideos: action.videos,
        };
        setCount(result);
        return result;
      }
      case "start": {
        let result = { ...state, start: action.value };
        setCalendar(result);
        return result;
      }
      case "end": {
        let result = { ...state, end: action.value };
        setCalendar(result);
        return result;
      }
      case "title": {
        let result = { ...state, title: action.value };
        setCalendar(result);
        return result;
      }

      case "increase": {
        let _count = state.contact + 1;
        let result = { ...state, contact: _count };
        setCount(result);
        return result;
      }
      case "decrease":
        let _count = state.contact - 1;
        let result = { ...state, contact: _count };
        setCount(result);
        return result;
      default:
        return state;
    }
  };

  return (
    <stateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </stateContext.Provider>
  );
}

export function useContextState() {
  return useContext(stateContext);
}
