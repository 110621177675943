export enum ActionType {
    MEETING,
    CONTACT,
    PRODUCT,
    FEED,
    IMAGEFEED,
    VIDEOFEED,
    COUNT,
    NOTIFY,
}
