import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "antd";
import { localizeImg } from "../../constants/constants";
import { useEffect, useState } from "react";
import { Layout } from "antd";
import { oneArticle } from "../../api/article.ts";

export default function ArticleEdit(props) {
  const { t } = useTranslation();
  const { Content } = Layout;
  const [title, setTitle] = useState("");
  const [container, setContainer] = useState("");
  const [desc, setDesc] = useState("");

  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    let pms = {
      language: "en",
      id: props.match.params.id,
    };

    let item = await oneArticle(pms);
    item.map(
      (value) => value._id === props.match.params.id && setTitle(value.title)
    );
    item.map(
      (value) =>
        value._id === props.match.params.id && setContainer(value.banner[0])
    );
    item.map(
      (value) => value._id === props.match.params.id && setDesc(value.content)
    );
  };

  return (
    <Content>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_ACCOUNT")}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/my-news"}>{t("MY_NEWS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management  normal-shadow-padding10-white">
        <div className="common-management-title-show">{title}</div>
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            overflow: "auto",
          }}
        >
          <div>
            <div>
              <img
                alt={title}
                src={localizeImg(container)}
                style={{
                  maxHeight: "300px",
                  maxWidth: "100%",
                  margin: "auto",
                  display: "block",
                  marginTop: "20px",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                padding: "20px",
                marginTop: "20px",
                marginBottom: "20px",
                overflow: "auto",
              }}
              dangerouslySetInnerHTML={{ __html: desc }}
            ></div>
          </div>
        </div>
      </div>
    </Content>
  );
}
