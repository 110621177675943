import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "./RightSider.module.sass";
import Card from "../Card";
import { recommandUsers } from "../../../api/feed";
import { useUserId } from "../../../contexts/UserIdProvider";
import { localizeImg, localizeValue } from "../../../constants/constants";
import { useTranslation } from "react-i18next";
import { Path, PathTools } from "../../../constants/path";
import ModalNews from "../ModalNews";
import AvatarBasic from "../../../components/common/avatar/AvatarBasic";
import { suggestEvents } from "../../../api/events";
import { getProductSearch } from "../../../api/product";

const products = [
  {
    title: "Crypter - NFT UI kit",
    price: "$2,453.80",
    active: true,
    image: "/images/content/product-pic-1.jpg",
    image2x: "/images/content/product-pic-1@2x.jpg",
  },
  {
    title: "Bento Matte 3D illustration 1.0",
    price: "$105.60",
    active: false,
    image: "/images/content/product-pic-2.jpg",
    image2x: "/images/content/product-pic-2@2x.jpg",
  },
  {
    title: "Fleet - travel shopping kit",
    price: "$648.60",
    active: true,
    image: "/images/content/product-pic-3.jpg",
    image2x: "/images/content/product-pic-3@2x.jpg",
  },
  {
    title: "Fleet - travel shopping kit",
    price: "$648.60",
    active: true,
    image: "/images/content/product-pic-4.jpg",
    image2x: "/images/content/product-pic-4@2x.jpg",
  },
  {
    title: "Crypter - NFT UI kit",
    price: "$2,453.80",
    active: true,
    image: "/images/content/product-pic-5.jpg",
    image2x: "/images/content/product-pic-5@2x.jpg",
  },
  {
    title: "Bento Matte 3D illustration 1.0",
    price: "$105.60",
    active: false,
    image: "/images/content/product-pic-2.jpg",
    image2x: "/images/content/product-pic-2@2x.jpg",
  },
  {
    title: "Fleet - travel shopping kit",
    price: "$648.60",
    active: true,
    image: "/images/content/product-pic-3.jpg",
    image2x: "/images/content/product-pic-3@2x.jpg",
  },
  {
    title: "Fleet - travel shopping kit",
    price: "$648.60",
    active: true,
    image: "/images/content/product-pic-4.jpg",
    image2x: "/images/content/product-pic-4@2x.jpg",
  },
];

const RightSider = ({ className }) => {
  const { user, isImporter } = useUserId();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showNewsModal, setShowNewsModal] = useState(false);
  const [newsModel, setNewsModel] = useState({});
  const [events, setEvents] = useState([]);

  const [activeProductId, setActiveProductId] = useState("");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    loadReommandUsers();
    if (isImporter) {
      loadSuggestsProduct();
    }
    loadSuggestEvents();
  }, []);

  const loadReommandUsers = async () => {
    let pms = {
      userId: user?.userId,
    };
    let result = await recommandUsers(pms);
    if (result && result.total > 0) {
      result.result.map((mapItem) => {
        if (mapItem && mapItem.companyInfo && mapItem.companyInfo.length > 0) {
          mapItem.companyDesc = mapItem.companyInfo[0];
        }
        if (mapItem && mapItem.companyDesc && mapItem.companyDesc.length > 0) {
          mapItem.companyDesc = mapItem.companyDesc[0];
        }
      });
      setUsers(
        result.result.slice(0, 4)
      );
    }

    setLoading(false);
  };

  const onCompanyHome = (userId, companyName) => {
    history.push(PathTools.joinPath(Path.companyDetail, companyName), {
      id: userId,
    });
  };

  const loadSuggestEvents = async () => {
    let pms = {
      page: 0,
      per_page: 2,
    };
    let result = await suggestEvents(pms);
    if (result && result.total > 0) {
      setEvents(result.result);
    }
  };

  const loadSuggestsProduct = async () => {
    let page = 0;
    let pms = {
      page,
      per_page: 10,
    };

    let result = await getProductSearch(pms);
    console.log("-----products discover:", result);
    if (result && result.length > 0) {
      setProducts(result);
    }
  };

  const onProductItemClick = (item) => {
    setActiveProductId(item._id);
    onNavigationToProductInfo(item.productId || item._id);
  };

  const onNavigationToProductInfo = (id) => {
    history.push(PathTools.joinPath(Path.productInfo, id));
  };

  const onSeeMoreProducts = () => {
    history.push(
      PathTools.joinPath(Path.shopSearch, user?.userInfo?.companyDesc?.coName)
    );
  };

  return (
    <>
      {events && events.length > 0 && (
        <Card
          className={cn(styles.card, className)}
          title={t("WHATS_NEW")}
          classTitle="title-red"
        >
          <div className={styles.message}>
            <div className={styles.list}>
              {events.map((x, index) => (
                <div
                  className={styles.item}
                  key={index}
                  onClick={() => {
                    setNewsModel(x);
                    setShowNewsModal(true);
                  }}
                >
                  {x.images && x.images.length > 0 && (
                    <div className={styles.newsAvatar}>
                      <img src={localizeImg(x.images[0])} alt="Avatar" />
                    </div>
                  )}
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div className={styles.user}>
                        <span className={styles.title}>{x.title}</span>{" "}
                        <span className={styles.login}>{x.eventDate}</span>
                      </div>
                      {/* <div
                      className={styles.time}
                      onClick={() => history.push(Path.developing)}
                    >
                      {t("SEE_MORE")}
                    </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Link
              className={cn("button-stroke", styles.button)}
              to={Path.events}
            >
              {t("SEE_ALL")}
            </Link>
          </div>
        </Card>
      )}
      {products && products.length > 0 && (
        <Card
          className={cn(styles.card, className)}
          title={t("HOT_PRODUCT")}
          classTitle="title-red"
        >
          <div className={styles.popular}>
            <div className={styles.list}>
              {products.map((x, index) => (
                <div
                  className={styles.item}
                  key={index}
                  onClick={onProductItemClick.bind(this, x)}
                >
                  <div className={styles.preview}>
                    <img
                      srcSet={`${localizeImg(x.images[0])} 2x`}
                      src={localizeImg(x.images[0])}
                      alt="Product"
                    />
                  </div>
                  <div>
                    <div className={styles.title}>
                      {localizeValue(i18n.language, x.name)}
                    </div>
                    <div className={styles.details}>
                      {x.stocks&&<div className={styles.price}>
                        {x.stocks[0]["prices"][0].price}
                        {x.stocks[0].currency}
                      </div>}
                      {/* {x.active ? ( */}
                      {/* <div className={cn("status-green", styles.status)}>
                      {t("RELEASED")}
                    </div> */}
                      {/* ) : (
                    <div className={cn("status-red", styles.status)}>
                      Deactive
                    </div>
                  )} */}
                    </div>
                    <div>
                      {x.vintage}
                      {/* {x.vintage?.map((item) => {
                        return (
                          <div
                            className={cn("status-green", styles.status)}
                            style={{ marginRight: "6px" }}
                          >
                            {item}
                          </div>
                        );
                      })} */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Link
              className={cn("button-stroke", styles.button)}
              onClick={onSeeMoreProducts.bind(this)}
            >
              {t("ALL_PRODUCTS")}
            </Link>
          </div>
        </Card>
      )}
      {/* <Card
        className={cn(styles.card, className)}
        title={
          isImporter
            ? t("YOU_MIGHT_INTERESTED_USERS")
            : t("YOU_MIGHT_INTERESTED_USERS")
        }
        classTitle="title-yellow"
      >
        <div className={styles.message}>
          <div className={styles.list}>
            {users.map((x, index) => (
              <div
                className={styles.item}
                key={index}
                onClick={onCompanyHome.bind(
                  this,
                  x._id,
                  localizeValue(i18n.language, x?.companyDesc?.companyName)
                )}
              >
                <div className={styles.avatar}>
                  <AvatarBasic
                    picture={localizeImg(x?.companyDesc?.avatar)}
                    size={49}
                  />
                </div>
                <div className={styles.details}>
                  <div className={styles.line}>
                    <div className={styles.user}>
                      <span className={styles.title}>
                        {localizeValue(
                          i18n.language,
                          x?.companyDesc?.companyName
                        )}
                      </span>{" "}
                    </div>
                  </div>
                  <div
                    className={styles.content}
                    // dangerouslySetInnerHTML={{
                    //   __html: x.companyDesc?.companyName,
                    // }}
                  >
                    {t(x?.label)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Link
            className={cn("button-stroke", styles.button)}
            to={Path.developing}
          >
            {t("SEE_ALL")}
          </Link>
        </div>
      </Card> */}
      <ModalNews
        visible={showNewsModal}
        news={newsModel}
        onClose={() => setShowNewsModal(false)}
      />
    </>
  );
};

export default RightSider;
