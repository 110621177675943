import { PostDataType } from "@amityco/js-sdk";
import React, { memo, useState } from "react";
import EmptyState from "../../../core/components/EmptyState";
import customizableComponent from "../../../core/hocs/customization";
import LoadMore from "../LoadMore";
import GalleryContent from "../post/GalleryContent";
import * as VideoItem from "../post/GalleryContent/VideoItem";
import * as StreamItem from "../post/GalleryContent/StreamItem";
import useMediaGallery from "../../hooks/useMediaGallery";
import { EmptyIcons, EmptyText, RECORDS_PER_PAGE, tabs } from "./constants";
import { MediaGalleryContainer, Tabs } from "./styles";

const thumbnailRenderers = {
  [PostDataType.VideoPost]: (props) => (
    <VideoItem.Thumbnail {...props} showVideoDuration />
  ),
  [PostDataType.LivestreamPost]: (props) => (
    <StreamItem.Thumbnail {...props} showLivestreamTitle showVideoDuration />
  ),
};

const UserMediaGallery = ({ targetId, targetType, mediaType }) => {
  const [posts, hasMore, loadMore, loading, loadingMore] = useMediaGallery({
    targetId,
    targetType,
    dataType: mediaType,
    limit: RECORDS_PER_PAGE,
  });

  return (
    <MediaGalleryContainer>
      {(loading || loadingMore || posts.length > 0) && (
        <LoadMore
          hasMore={hasMore}
          loadMore={loadMore}
          className="load-more no-border"
        >
          <GalleryContent
            items={posts}
            loading={loading}
            loadingMore={loadingMore}
            showCounter={false}
            truncate={false}
            thumbnailRenderers={thumbnailRenderers}
          />
        </LoadMore>
      )}

      {!loading && !loadingMore && posts.length === 0 && (
        <EmptyState icon={EmptyIcons[mediaType]} title={EmptyText[mediaType]} />
      )}
    </MediaGalleryContainer>
  );
};

export default memo(
  customizableComponent("UserMediaGallery", UserMediaGallery)
);
