// @ts-ignore
import { Modal } from "antd";

export function objectToUrlParams(json: any) {
  return Object.keys(json)
    .map(function (key) {
      return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`;
    })
    .join("&");
}

export function deepClone(obj: any) {
  if (obj === null) return null;
  if (typeof obj !== "object") return obj;
  if (obj.constructor === Date) return new Date(obj);
  var cache: any = [];
  var str = JSON.stringify(obj, function (key, value) {
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null;
  return JSON.parse(str);
}

export function listMap(list: Array<any>, key: string) {
  const result: any[] = [];
  list.forEach((element) => {
    result.push(element[key]);
  });
  return result;
}

/**
 * 获取滚动View高度
 * @param doc :document
 * @returns
 */
export function getScrollHeight(doc: any) {
  // 52: 当前页面框架中Header的高度
  // 30: content 上边距的高度
  // 10: content 下边距的高度
  return doc.body.clientHeight - 52 - 30 - 10 - 5;
}

export function getScrollHeightWithoutNav(doc: any) {
  // this function is used to get scroll height of the page without navbar
  // it get the clientHeight without navbar, nothing more.
  return doc.body.clientHeight - 56;
}

export function getBase64(file: any) {
  file = file.originFileObj;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function confirmModal(
  title: string,
  content: string,
  okText: string,
  cancelText: string,
  onOKCallBack: Function,
  onCancelCallBack?: Function,
  pms?: any
) {
  Modal.confirm({
    title,
    icon: null,
    content,
    okText,
    cancelText,
    onOk() {
      onOKCallBack && onOKCallBack(pms);
    },
    onCancel() {
      onCancelCallBack && onCancelCallBack();
    },
  });
}

export function getTableColumnItem(name: string) {
  const strArray = name.split("");
  let title = "";
  // eslint-disable-next-line array-callback-return
  strArray.map((item: string, index: number) => {
    if (index === 0) {
      title += item.toUpperCase();
    } else {
      title += item;
    }
  });

  return title;
}

/**
 * @description Convert yard to hectare
 * @param number the number to convert to hectare
 */
export function yardToHectare(number: number) {
  return number / 11959.90561;
}

/**
 * @description Convert hectare to yard
 * @param number the number to convert to yard
 */
export function hectareToYard(number: number) {
  return number * 11959.90561;
}

export function landingCost(price: number, quantity: number) {
  const currency = 9;
  const shippingFee = 3;
  const tax = 1.5;
  // return prix * 9 * 1.5 + 3
  return quantity * price * currency * tax + shippingFee;
}

export function marginCalculator(total: number, percentage: number) {
  return total * (percentage / 100);
}

export function getCurrencyAscii(currency: string) {
  switch (currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "JPY":
      return "¥";
    case "CNY":
      return "¥";
    case "AUD":
      return "$";
    case "CAD":
      return "$";
    case "CHF":
      return "Fr";
    case "HKD":
      return "$";
    case "IDR":
      return "Rp";
    case "INR":
      return "₹";
    case "KRW":
      return "₩";
    case "MYR":
      return "RM";
    case "NZD":
      return "$";
    case "PHP":
      return "₱";
    case "SGD":
      return "$";
    case "THB":
      return "฿";
    case "TWD":
      return "NT$";
    case "VND":
      return "₫";
    case "ZAR":
      return "R";
    default:
      return "$";
  }
}

export function removeItemFromArray(array: Array<any>, item: any) {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
}

export function removeItemFromArrayById(array: Array<any>, targetItem: any) {
  let index = -1;
  array.forEach((arrayItem, arrayIndex) => {
    if (arrayItem._id === targetItem?._id) {
      index = arrayIndex;
    }
  });
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
}

export function removeDuplicates(array: Array<any>) {
  return array.filter((item, index) => array.indexOf(item) === index && item !== "" && item !== null && item !== undefined);
}

export function formatVerifyStatus(status: any) {
  let result = "not verify";
  switch (status) {
    case 1:
      result = "verify failed";
      break;
    case 2:
      result = "verify successed";
      break;
    default:
      break;
  }
  return result;
}

/**
 * @param userItem
 * @param history
 */
export function goToProfilePage(userItem: any, history: any) {
  history.push("/profile-deprecated/" + userItem._id, { targetUserId: userItem._id });
}

export function groupBy(arr: any, criteria: any) {
  let result: any = [];
  arr.map((item: any) => {
    let key = item[criteria];
    if (!result.hasOwnProperty(key)) result[key] = [];

    result[key].push(item);
  });

  return result.sort();
}

export const constants = {
  chinaCountryId: "60c85d4e011f981d0823532e",
  amapWebKey: "0aeb583a6e2eaea5bf447efa7807d0a8",
  amapJsKey: "86463c82f4b4e3d42e100c9fbed23aea",
  mapBoxAccessToken:
    "pk.eyJ1IjoidmluYmlwIiwiYSI6ImNsazdnbjZnZzA5OHYzaHA2Zmk3bDhjY3AifQ.g27ajMFeskKozlwRGvrL2Q",
};
