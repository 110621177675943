import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Dropdown, List, Menu, Space, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../../contexts/UserIdProvider";
import cameraIcon from "../../../resource/icons/user-profile/camera.svg";
import filterIcon from "../../../resource/icons/user-profile/filter.svg";
import {
  companyDetail,
  companyMediaDesc,
  saveCompanyInfo,
} from "../../../api/company";
import { useTranslation } from "react-i18next";
import { ProductStatus, statusCode } from "../../../constants/statusCode.enum";
import { deepClone } from "../../../utils/utils";
import { userDetail } from "../../../api/user";
import UploadImageNormal from "../../common/upload/UploadImageNormal";
import { getFeeds } from "../../../api/feed";
import FeedListRow from "../../feed/FeedListRow";
import ImageListRow from "../../shared/ImageListRow";
import VideoListRow from "../../common/video/VideoListRow";
import editIcon from "../../../resource/icons/user-profile/edit1.svg";
import friendIcon from "../../../resource/icons/user-profile/friend.svg";
import { toastInfo } from "../../../api/toast";
import { getFileTypeByPath } from "../../../api/common";
import { localizeImg, UploadFileType } from "../../../constants/constants";
import VideoBasic from "../../common/video/VideoBasic";
import UploadBase from "../../common/upload/UploadBase";
import wineryBanner from "../../../resource/images/user-profile/winery_banner.jpg";
import wineryAvatar from "../../../resource/images/user-profile/winery_logo.jpg";
import SkeletonLoader from "tiny-skeleton-loader-react";
import "./style/media.less";
import { getArticles } from "../../../api/article";
import ArticleListRow from "../../feed/article/ArticleListRow";

export default function MediaPage() {
  /**
   * Basic setting and data.
   */
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { user } = useUserId();
  const { companyId } = user.userInfo;
  const [currentUser, setCurrentUser] = useState(user?.userInfo);
  const [isLoading, setIsLoading] = useState(false);

  const [avatar, setAvatar] = useState();
  const [banner, setBanner] = useState();

  /**
   *  Scrolling index to fetch more data.
   */
  const [pageIndex, setPageIndex] = useState(0);
  const [sort, setSort] = useState(-1);

  /**
   * The profile detail
   */
  const [companyDesc, setCompanyDesc] = useState("");
  const [mediaInfo, setMediaInfo] = useState("");

  /**
   * The current user data
   */
  const [editProfile, setEditProfile] = useState(false);

  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  /**
   * Load company profile informations
   */
  useEffect(async () => {
    if (companyId) {
      setIsLoading(true);
      loadCompanyDesc(id);
      loadFeeds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, companyId]);

  /**
   * Set the current user data
   */
  useEffect(() => {
    if (user?.userInfo) {
      setCurrentUser(user.userInfo);
    }
  }, [user]);

  /**
   * Global isLoading handler
   */
  useEffect(() => {
    if (currentUser && companyDesc) {
      setIsLoading(false);
    }
  }, [currentUser, companyDesc]);

  const loadCompanyDesc = async (userId) => {
    const userInfo = await userDetail(userId);
    setMediaInfo(userInfo);
    const companyInfo = await companyDetail(userInfo.companyId);
    const coId = companyInfo._id;
    const pms = { coId };
    const data = await companyMediaDesc(pms);
    if (data && data.statusCode === statusCode.SUCCESS && data.result) {
      if (data.result.banner) {
        data.result.bannerType = getFileTypeByPath(localizeImg(data.result.banner));
      }
      setBanner(data.result.banner);
      setAvatar(data.result.avatar);
      setCompanyDesc(data.result);
    }
  };

  const loadArticle = async () => {
    setLoading(true);
    let pms = {
      userId: id,
      page: pageIndex,
      per_page: pageSize,
      status: ProductStatus.VERIFYSUCCESS
    };
    let data = await getArticles(pms);
    if (data && data.total > 0) {
      const { result } = data;
      setFeedList(result);
    }
    setLoading(false);
  };

  const onMouseEnterAvatar = () => {
    setEditProfile(true);
  };

  const onMouseLeaveAvatar = () => {
    setEditProfile(false);
  };

  const onBannerLoaded = (data) => {
    if (data.length > 0) {
      let temp = deepClone(companyDesc);
      temp.banner = data[0];
      temp.bannerType = UploadFileType.IMAGE;
      setCompanyDesc(temp);
      onChangeBanner(temp);
    }
  };

  const onBannerVideoLoaded = (fileType, url) => {
    if (url) {
      let temp = deepClone(companyDesc);
      temp.banner = url;
      temp.bannerType = UploadFileType.VIDEO;
      setCompanyDesc(temp);
      onChangeBanner(temp);
    }
  };

  const onChangeBanner = async (pms) => {
    let result = await saveCompanyInfo(pms);
    if (result.statusCode === 200) {
      toastInfo(t("SAVE_SUCCESS"));
    } else {
      toastInfo(t("SAVE_FAILED"));
    }
  };

  const onAvatarLoaded = (data) => {
    if (data.length > 0) {
      let temp = deepClone(companyDesc);
      temp.avatar = data[0];
      setCompanyDesc(temp);
      onChangeAvatar(temp);
    }
  };

  const onChangeAvatar = async (pms) => {
    let result = await saveCompanyInfo(pms);
    if (result.statusCode === 200) {
      toastInfo(t("SAVE_SUCCESS"));
    } else {
      toastInfo(t("SAVE_FAILED"));
    }
  };
  const [orgFeedList, setOrgFeedList] = useState([]);
  const [feedList, setFeedList] = useState([]);
  const [pageTotal, setPageTotal] = useState(0);
  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const filterTitles = ["POSTED", "PHOTO", "VIDEOS", "ARTICLE", "FEED"];

  const loadFeeds = async () => {
    setLoading(true);
    let pms = { userId: id, page: pageIndex, per_page: pageSize, sort: sort };
    let data = await getFeeds(pms);
    if (data) {
      let { result, total } = data;
      if (result.length < pageSize) {
        setHasMore(false);
      }
      if (pageIndex > 0) {
        let _list = deepClone(feedList);
        result = _list.concat(result);
      }
      setPageTotal(total);
      setFeedList(result);
      setOrgFeedList(result);
    } else {
      if (pageIndex > 0) {
        setHasMore(false);
      } else {
        //暂无数据
        //todo implement
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (hasMore) {
      loadFeeds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  const onFeedClick = () => {
    setSelectedFilterIndex(0);
    setSelectedTabIndex(0);
    setFeedList(orgFeedList);
  };

  const onImageClick = () => {
    setSelectedFilterIndex(4);
    setSelectedTabIndex(0);
    let list = deepClone(orgFeedList);
    let tempList = [];
    let childList = [];
    // eslint-disable-next-line array-callback-return
    list.map((item) => {
      if (item.images.length > 0) {
        // eslint-disable-next-line array-callback-return
        item.images.map((imageItem) => {
          childList.push(imageItem);
          if (childList.length === 3) {
            tempList.push({
              cardType: "IMAGE",
              items: childList,
            });
            childList = [];
          }
        });
      }
    });
    if (childList.length < 3 && childList.length > 0) {
      tempList.push({
        cardType: "IMAGE",
        items: childList,
      });
    }
    setFeedList(tempList);
  };
  const onVideoClick = () => {
    setSelectedFilterIndex(2);
    setSelectedTabIndex(0);
    let list = deepClone(orgFeedList);
    let tempList = [];
    let childList = [];
    // eslint-disable-next-line array-callback-return
    list.map((item) => {
      if (item.videos.length > 0) {
        childList.push(item.videos[0]);
        if (childList.length === 3) {
          tempList.push({
            cardType: "VIDEO",
            items: childList,
          });
          childList = [];
        }
      }
    });
    if (childList.length < 3 && childList.length > 0) {
      tempList.push({
        cardType: "VIDEO",
        items: childList,
      });
    }
    setFeedList(tempList);
  };

  const onArticleClick = () => {
    setSelectedFilterIndex(3);
    setSelectedTabIndex(0);
    loadArticle();
  };

  const onGoCompanySetting = () => {
    history.push("/company/settings-deprecated");
  };

  const onSetSort = (val) => {
    setSort(val);
    loadFeeds();
  };

  const menuBannerEdit = (
    <Menu className="menu-dropdown-container">
      <Menu.Item key="0">
        <UploadImageNormal
          onFileLoaded={onBannerLoaded.bind(this)}
          scaleAspect={1038 / 580}
        >
          <div className="banner-btn">
            <span>{t("UPLOAD_IMAGE")}</span>
          </div>
        </UploadImageNormal>
      </Menu.Item>
      <Menu.Item key="1">
        <UploadBase onLoaded={onBannerVideoLoaded.bind(this)}>
          <div className="banner-btn">
            <span>{t("UPLOAD_VIDEO")}</span>
          </div>
        </UploadBase>
      </Menu.Item>
    </Menu>
  );

  const dataListFilter = (
    <Menu className="menu-dropdown-container">
      <Menu.Item key="0">
        <div className="banner-btn" onClick={onSetSort.bind(this, -1)}>
          <span>{t("ASCENDING_CRONOLOGICAL_ORDER")}</span>
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <div className="banner-btn" onClick={onSetSort.bind(this, 1)}>
          <span>{t("DESCENDING_CRONOLOGICAL_ORDER")}</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="media-home">
      {!isLoading ? (
        <div className="banner-container">
          {companyDesc.bannerType === UploadFileType.VIDEO ? (
            <VideoBasic
              url={localizeImg(companyDesc.banner)}
              width="100%"
              height={411}
            />
          ) : (
            <img
              alt={"Banner"}
              className="banner"
              src={localizeImg(companyDesc.banner) || wineryBanner}
            />
          )}
        </div>
      ) : (
        <div className="banner-container">
          <SkeletonLoader height={411} />
        </div>
      )}

      <div className="middle-content">
        <div className="banner-btn-container">
          <div className="banner-btn-panel">
            {id === user.userId && (
              <Dropdown overlay={menuBannerEdit} trigger={["click"]}>
                <div className="banner-btn">
                  <img
                    alt={"Camera icon"}
                    src={cameraIcon}
                    className="btn-icon"
                  />
                  <span>{t("CHANGE_BANNER")}</span>
                </div>
              </Dropdown>
            )}
          </div>
        </div>
        <div className="company-content">
          {!isLoading ? (
            <div className="company-user">
              <div className="avatar-item-upload">
                <img
                  alt={t("AVATAR")}
                  src={
                    localizeImg(companyDesc.avatar) ||
                    companyDesc.avatarGlobal ||
                    wineryAvatar
                  }
                  className="avatar"
                  onMouseEnter={onMouseEnterAvatar.bind(this)}
                />
                <UploadImageNormal
                  onFileLoaded={onAvatarLoaded.bind(this)}
                  scaleAspect={1}
                >
                  <div
                    onMouseLeave={onMouseLeaveAvatar.bind(this)}
                    className={
                      user.userId === id && editProfile
                        ? "image-title-wrapper"
                        : "image-title-wrapper ele-hide"
                    }
                  >
                    <p>{t("MODIFY_THE_AVATAR")}</p>
                  </div>
                </UploadImageNormal>
              </div>
              <div className="title">{companyDesc.displayName}</div>
              <div>
                {mediaInfo.lastName}
                {" " + mediaInfo.firstName}
              </div>
              <div className="desc">
                <img alt={"Friend icon"} src={friendIcon} className="icon" />
                <span></span>
              </div>
            </div>
          ) : (
            <div className="company-user">
              <div className="avatar-item-upload">
                <SkeletonLoader height={200} circle className="avatar" />
              </div>
              <div className="title">
                <SkeletonLoader height={22} width={150} />
              </div>
              <div className="desc">
                <span>
                  <SkeletonLoader height={22} width={110} />
                </span>
              </div>
            </div>
          )}
          <div className="content-detail">
            <div className="content-detail-left">
              {!isLoading ? (
                <>
                  {user.userId === id && (
                    <div
                      className="button-edit"
                      onClick={onGoCompanySetting.bind(this)}
                    >
                      <img
                        alt={"Edit icon"}
                        src={editIcon}
                        className="button-icon"
                      />
                      <span className="button-text">
                        {t("EDIT_INFORMATION")}
                      </span>
                    </div>
                  )}
                  <div className="company-desc-container">
                    <Space direction="vertical" size={5}>
                      <p>todo media info</p>
                    </Space>
                  </div>
                </>
              ) : (
                <>
                  <div className="detail-action">
                    {id !== user.userId && (
                      <Space size={16}>
                        <SkeletonLoader height={32} width={135} />
                        <SkeletonLoader height={32} width={90} />
                      </Space>
                    )}
                  </div>
                  <div className="company-desc-container">
                    <Space direction="vertical" size={5}>
                      <div>
                        <span>
                          <SkeletonLoader height={18} width={153} />
                        </span>
                      </div>
                      <div>
                        <span>
                          <SkeletonLoader height={18} width={200} />
                        </span>
                      </div>
                      <div>
                        <span>
                          <SkeletonLoader height={18} width={130} />
                        </span>
                      </div>
                    </Space>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="business-container-winery">
          <div className="business-left-winery">
            <div className="filter-container-winery">
              <Space size={3} direction="vertical">
                <div
                  className={
                    selectedFilterIndex === 0
                      ? "filter-item filter-item-selected"
                      : "filter-item"
                  }
                  onClick={onFeedClick.bind(this)}
                >
                  {t("POSTED")}
                </div>
                <div
                  className={
                    selectedFilterIndex === 2
                      ? "filter-item filter-item-selected"
                      : "filter-item"
                  }
                  onClick={onVideoClick.bind(this)}
                >
                  {t("VIDEOS")}
                </div>
                <div
                  className={
                    selectedFilterIndex === 3
                      ? "filter-item filter-item-selected"
                      : "filter-item"
                  }
                  onClick={onArticleClick.bind(this)}
                >
                  {t("ARTICLE")}
                </div>
                <div
                  className={
                    selectedFilterIndex === 4
                      ? "filter-item filter-item-selected"
                      : "filter-item"
                  }
                  onClick={onImageClick.bind(this)}
                >
                  {t("PHOTO")}
                </div>
              </Space>
            </div>
          </div>
          <div className="business-right">
            {selectedTabIndex === 0 && (
              <div>
                <div className="title-container">
                  <div className="title-name">
                    {t(filterTitles[selectedFilterIndex])}
                  </div>
                  <Space size={20}>
                    <Dropdown overlay={dataListFilter} trigger={["click"]}>
                      <img
                        style={{
                          fill: "#A19A9A",
                        }}
                        alt={"Filter icon"}
                        src={filterIcon}
                        className="title-icon"
                      />
                    </Dropdown>
                  </Space>
                </div>
                <InfiniteScroll
                  className="scroll-content"
                  dataLength={pageTotal}
                  next={handleInfiniteOnLoad.bind(this)}
                  hasMore={hasMore}
                >
                  <List
                    className="company-feeds"
                    itemLayout="vertical"
                    size="large"
                    dataSource={feedList}
                    renderItem={(item, index) => (
                      <div>
                        {item.cardType === "FEED" && (
                          <div key={index} className="feed-list-item-container">
                            <FeedListRow key={item._id} data={item} />
                          </div>
                        )}
                        {item.cardType === "IMAGE" && (
                          <ImageListRow data={item.items} />
                        )}
                        {item.cardType === "VIDEO" && (
                          <VideoListRow data={item} />
                        )}
                        {item.cardType === "ARTICLE" && (
                          <ArticleListRow data={item} />
                        )}
                      </div>
                    )}
                  >
                    {loading && hasMore && (
                      <div className="loading-container">
                        <Spin />
                      </div>
                    )}
                  </List>
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
