import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { localizeImg } from "../../constants/constants";
import { dateFormat } from "../../utils/date";
import "./styles/activityItem.less";

export default function ActivityItem(props) {
  const { data, onJoin } = props;
  const { t } = useTranslation();
  const [detail, setDetail] = useState(data);

  useEffect(() => {
    setDetail(detail);
  }, [data]);

  return (
    <Row gutter={[0,20]} className="activity-item-container">
      <Col span={4}>
        <img className="act-item-image" src={localizeImg(detail?.picture)} />
      </Col>
      <Col span={8}>
        <div className="act-item-title">{detail?.title}</div>
      </Col>
      <Col span={8}>
        <div className="act-item-title">
          {t("DATE_OF_THE_EVENT")} :
          <span className="act-item-val">
            {dateFormat(detail?.startDate)}
            <span className="content-row-date-middle-text">{t("TO")}</span>
            {dateFormat(detail?.endDate)}
          </span>
        </div>
      </Col>
      <Col span={4}>
        {detail?.joined ? (
          <span>{t("JOINED")}</span>
        ) : (
          <Button
            className="common-primary-button"
            onClick={() => onJoin && onJoin(detail?._id)}
          >
            {t("JOIN_NOW")}
          </Button>
        )}
      </Col>
    </Row>
  );
}
