import React, { useState, useEffect } from "react";
import { Form, Button, Table, Space, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { statusCode } from "../../../constants/statusCode.enum";
import { noteCreate } from "../../../api/user";
import ProductImageUpload from "../../common/upload/ProductImageUpload";
import UploadNormal from "../../common/upload/UploadNormal";
import { localizeImg } from "../../../constants/constants";

export default function NoteCreate(props) {
  const { onComplete, onClose } = props;
  const { t } = useTranslation();
  const { user } = useUserId();

  let [note, setNote] = useState("");
  let [noteUrl, setNoteUrl] = useState("");
  let [name, setName] = useState("");
  let [desc, setDesc] = useState("");

  const onFinish = async () => {
    let pms = {
      coId: user.userInfo.companyId,
      //reward: formatUploadFileFormValues(rewards)[0],
      note: note,
      //proof: formatUploadFileFormValues(proofs)[0],
      name,
      desc,
    };
    let data = await noteCreate(pms);
    if (data) {
      onComplete();
    }
  };

  const onFileLoading = (index, percent, url) => {
    if (percent === 0) {
      setNoteUrl(url);
    }
  };

  const onFileLoaded = (data) => {
    console.log("----file loaded ----", data);
    if (data.length > 0) {
      setNote(data[0]);
      setNoteUrl(localizeImg(data[0]));
    }
  };

  const onCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div>
      <div className="modal-body">
        <div className="modal-title">{t("CREATE_NOTE")}</div>

        <div className="modal-content-column">
          <div className="content-row-label">{t("NOTE_LIST")}</div>
          <UploadNormal
            onLoading={onFileLoading.bind(this, 2)}
            onFileLoaded={onFileLoaded.bind(this)}
            className="awardIcon"
          >
            <ProductImageUpload title={t("NOTE_LIST")} url={noteUrl} />
          </UploadNormal>
        </div>
        <div className="modal-content-column">
          <div className="content-row-label">{t("NAME")}</div>
          <Input
            className="content-row-select"
            onChange={(val) => setName(val.target.value)}
          />
        </div>
        <div className="modal-content-column">
          <div className="content-row-label">{t("DESCRIPTION")}</div>
          <Input
            className="content-row-select"
            onChange={(val) => setDesc(val.target.value)}
          />
        </div>
        <Space size={8} className="modal-confirm-row">
          <Button
            className="modal-confirm-btn"
            onClick={onCloseClick.bind(this)}
          >
            {t("CANCEL")}
          </Button>
          <Button
            htmlType="submit"
            className="modal-confirm-btn-primary"
            onClick={onFinish.bind(this)}
          >
            {t("SAVE")}
          </Button>
        </Space>
      </div>
    </div>
  );
}
