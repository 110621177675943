import { formatActionResponse } from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";

/**
 * @description Get categories
 * @param pms
 */
export async function searchImporterSearch(pms: any) {
  return formatActionResponse(
    RequestType.GET,
    apiPath.searchImporterSearch,
    pms
  );
}

/**
 * @description create product click.
 * @param pms
 */
export async function searchHistoryAdd(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.searchHistoryAdd, pms);
}

/**
 * @description Get search history
 * @param pms
 */
export async function searchHistorys(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.searchHistorys, pms);
}
