import { Input } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style/numberNormal.less";

export default function NumberNormal(props) {
  const { t } = useTranslation();
  const {
    label,
    value,
    onChange,
    disabled,
    placeholder,
    addonAfter,
    inputClassName,
  } = props;
  const [val, setVal] = useState();
  const [iClassName, setIClassName] = useState(inputClassName);
  // console.log('------input normal:', label, value, index);

  useEffect(() => {
    setIClassName(inputClassName);
  }, [inputClassName]);

  useEffect(() => {
    setVal(value);
  }, [value]);
  const onInputChange = (e) => {
    // console.log('------input normal input change:', e.target.value);
    onChange(e.target.value);
    setVal(e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        paddingRight: "10px",
        borderRadius: "5px",
        border: iClassName ? "1px solid #D55053" : "1px solid #DEDEDE",
      }}
      className={iClassName}
    >
      <Input
        bordered={false}
        maxLength={200}
        placeholder={t(placeholder ? placeholder : label)}
        value={val}
        disabled={disabled}
        onChange={onInputChange.bind(this)}
        autocomplete="one-time-code"
        style={{
          width: "70%",
        }}
      />
      <span
        style={{
          color: "#333333",
          fontSize: "14px",
          width: "30%",
          textAlign: "right",
        }}
      >
        {addonAfter}
      </span>
    </div>
  );
}
