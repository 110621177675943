import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  InputNumber,
  Space,
  DatePicker,
  Select,
  Row,
  Col,
} from "antd";
import "./style/demandOfferCreate.less";
import ProductImageUpload from "../common/upload/ProductImageUpload";
import UploadNormal from "../common/upload/UploadNormal";
import { deepClone } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { loadTenderConfig, demandOfferCreate } from "../../api/tender";
import moment from "moment";
import { useUserId } from "../../contexts/UserIdProvider";
import { statusCode } from "../../constants/statusCode.enum";
import { toast } from "react-toastify";
import {
  getProductConfigForSelect,
  getProductConfigSelectNameByValue,
  loadProductConfig,
} from "../../api/product";
import { getCountryForSelect } from "../../api/config";
import { toastError, toastInfo } from "../../api/toast";
import { localizePriceUnit } from "../../constants/constants";

export default function DemandOfferCreate() {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const history = useHistory();
  const { user } = useUserId();
  const { userId, userInfo } = user;
  const { TextArea } = Input;

  const [lang, setLang] = useState(i18n.language);
  const [tempImage1, setTempImage1] = useState();
  const [tempImage2, setTempImage2] = useState();
  const [tempImage3, setTempImage3] = useState();
  const [tempImage4, setTempImage4] = useState();
  const [images, setImages] = useState([]);
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState();
  const [price, setPrice] = useState(); //价格
  const [year, setYear] = useState(2022); //年份
  const [alcohol, setAlcohol] = useState(); //酒精度
  const [volume, setVolume] = useState();
  const [volumes, setVolumes] = useState([]);
  const [tenderId, setTenderId] = useState();
  const [comments, setComments] = useState();

  useEffect(() => {
    if (state) {
      setTenderId(state.tenderId);
    }
  }, []);

  useEffect(() => {
    setLang(i18n.language);
    loadConfig(i18n.language);
  }, [i18n.language]);

  const loadConfig = async (lang) => {
    // let result = await loadTenderConfig();
    let result = await loadProductConfig();
    if (result) {
      let { locations, types, volumes } = result;
      setCountries(getCountryForSelect(locations, lang,user.userInfo.type));
      setTypes(getProductConfigForSelect(types, lang));
      setVolumes(getProductConfigForSelect(volumes, lang));
    }
  };

  const onSubmit = async () => {
    let pms = {
      tenderId,
      coId: userInfo.companyId,
      userId,
      country: {
        _id: country,
        name: getProductConfigSelectNameByValue(countries, country),
      },
      price,
      alcohol,
      bottleSize: {
        _id: volume,
        name: getProductConfigSelectNameByValue(volumes, volume),
      },
      vintage: year,
      type: { _id: type, name: getProductConfigSelectNameByValue(types, type) },
      images,
      comments,
    };
    let result = await demandOfferCreate(pms);
    if (result.statusCode === statusCode.SUCCESS) {
      toastInfo(t("SUCCESS"));
      history.goBack();
    } else {
      toastError(t("ERROR"));
    }
  };

  const onFileLoading = (index, percent, url) => {
    if (percent === 0) {
      switch (index) {
        case 1:
          setTempImage1(url);
          break;
        case 2:
          setTempImage2(url);
          break;
        case 3:
          setTempImage3(url);
          break;
        case 4:
          setTempImage4(url);
          break;
        default:
          break;
      }
    }
  };

  const onFileLoaded = (data) => {
    if (data.length > 0) {
      let tempImages = deepClone(images);
      tempImages.push(data[0]);
      setImages(tempImages);
    }
  };

  const getInputLabel = (text) => {
    return <div className="input-label">{text}</div>;
  };

  const ProductDetailItem = (props) => {
    const { right, label, children } = props;
    return (
      <div className={right && "product-input-item"}>
        <div className="input-label">{label}</div>
        {children}
      </div>
    );
  };

  //#region input select change
  const onPriceChange = async (e) => {
    setPrice(e);
  };

  const onAlcoholChange = async (e) => {
    setAlcohol(e);
  };

  const onVintageChange = (date, dateString) => {
    setYear(dateString);
  };

  const onTypeChange = (e) => {
    setType(e);
  };

  const onVolumeChange = (e) => {
    setVolume(e);
  };

  const onCountryChange = (e) => {
    setCountry(e);
  };
  //#endregion

  return (
    <div className="tender-create-container">
      <div className="create-content">
        <div className="content-title">{t('DEMAND_OFFER')}</div>
        <div className="content">
          <div className="content-item">
            <div className="content-item-title-sub">{t('PRODUCT_DETAIL')}</div>
            <div className="product-detail">
              <Row gutter={[30, 10]}>
                <Col span={24}>
                  {getInputLabel(t("ORIGIN_COUNTRY"))}
                  <Select
                    value={country}
                    className="product-item"
                    options={countries}
                    onChange={onCountryChange.bind(this)}
                  />
                </Col>
                <Col span={12}>
                  {getInputLabel(t("PRODUCT_TYPE"))}
                  <Select
                    value={type}
                    className="product-item"
                    options={types}
                    onChange={onTypeChange.bind(this)}
                  />
                </Col>
                <Col span={12}>
                  {getInputLabel(t("TARGET_PRICE"))}
                  <InputNumber
                    value={price}
                    addonAfter={localizePriceUnit(lang)}
                    className="product-item"
                    onChange={onPriceChange.bind(this)}
                  />
                </Col>
                <Col span={12}>
                  {getInputLabel(t("ALCOHOL_DEGREE"))}
                  <InputNumber
                    value={alcohol}
                    step={0.5}
                    addonAfter="%"
                    className="product-item"
                    onChange={onAlcoholChange.bind(this)}
                  />
                </Col>
                <Col span={12}>
                  {getInputLabel(t("YEARS"))}
                  <DatePicker
                    value={year ? moment(year, "YYYY") : ""}
                    onChange={onVintageChange.bind(this)}
                    picker="year"
                    className="product-item"
                    placeholder={t("PLEASE_WRITE_YEARS")}
                  />
                </Col>
                <Col span={12}>
                  {getInputLabel(t("BOTTLE_SIZE"))}
                  <Select
                    value={volume}
                    className="product-item"
                    options={volumes}
                    onChange={onVolumeChange.bind(this)}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="content-item">
            <div className="content-item-title">{t('PRODUCT_PICTURE')}</div>
            <Space size={16} className="product-images">
              <UploadNormal
                onLoading={onFileLoading.bind(this, 1)}
                onFileLoaded={onFileLoaded.bind(this)}
              >
                <ProductImageUpload
                  title={t("PRODUCT_FRONT_VIEW")}
                  url={tempImage1}
                />
              </UploadNormal>
              <UploadNormal
                onLoading={onFileLoading.bind(this, 2)}
                onFileLoaded={onFileLoaded.bind(this)}
              >
                <ProductImageUpload
                  title={t("PRODUCT_BACK_VIEW")}
                  url={tempImage2}
                />
              </UploadNormal>
              <UploadNormal
                onLoading={onFileLoading.bind(this, 3)}
                onFileLoaded={onFileLoaded.bind(this)}
              >
                <ProductImageUpload title={t('LABEL_INFORMATION')} url={tempImage3} />
              </UploadNormal>
              <UploadNormal
                onLoading={onFileLoading.bind(this, 4)}
                onFileLoaded={onFileLoaded.bind(this)}
              >
                <ProductImageUpload title={t('OTHER')} url={tempImage4} />
              </UploadNormal>
            </Space>
            {/* <div className="content-item-tip">请上传高清商品图</div> */}
          </div>
          <div className="content-item">
            <div className="content-item-title">{t('DESCRIPTION')}</div>
            <TextArea
              value={comments}
              maxLength={500}
              className="form-textarea"
              // placeholder="描述您的其他要求，如同城要货、提供三证等200字以内…"
              placeholder={t('PLEASE_ENTER_THE')}
              onChange={(e) => setComments(e.target.value)}
            />
          </div>
          <Button onClick={onSubmit.bind(this)} className="form-button">
            {t('PUBLISH')}
          </Button>
        </div>
      </div>
    </div>
  );
}
