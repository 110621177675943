import cn from "classnames";
import styles from "./Details.module.sass";
import Icon from "../../../Icon";
import Overview from "./Overview";
import Products from "./Products";
import { useEffect, useState } from "react";

// const navigation = ["Product", "Comments"];
const navigation = ["Product"];

export default function Details(props) {
  const { product,relativeProducts, className, setValue, activeIndex, setActiveIndex } = props;

  const [productInfo, setProductInfo] = useState(product);

  useEffect(() => {
    if (product) {
      setProductInfo(product);
    }
  }, [product]);

  const handleClick = (index) => {
    setActiveIndex(index);
    index === 0 && setValue(false);
    index === 1 && setValue(true);
  };

  return (
    <div className={cn(styles.details, className)}>
      <Overview product={productInfo} />
      <Products products={relativeProducts}/>
    </div>
  );
}
