import { AudioOutlined } from "@ant-design/icons";
import { Avatar, Input, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import defaultAvatarIcon from "../../../../resource/icons/common/default_avatar.svg";
import "./style/salesPanel.less";
import searchIcon from "./images/search_icon.svg";
import filterIcon from "./images/filter.svg";
import { useState } from "react";

export default function SalesPanel(props) {
  const { t, i18n } = useTranslation();

  let [data, setData] = useState([]);

  const colorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
  const { picture, displayName, _id, shape, size } = props;

  /**
   *
   * @param arr An array of hex color
   * @returns a random colors that can be used as background
   */
  const getRandomColor = (arr) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };

  /**
   *
   * @param name
   * @returns 2 Char of that string
   */
  const sliceName = (name) => {
    return name.slice(0, 2).toUpperCase();
  };

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  const columns = [
    { title: t("ORDER_DATE"), dataIndex: "name", key: "name" },
    { title: t("ORDER_NUMBER"), dataIndex: "phoneNumber", key: "phoneNumber" },
    {
      title: t("CLIENT"),
      dataIndex: "location",
      key: "location",
      render: (val) => <span>aa</span>,
    },
    { title: t("ORDER_STATUS"), dataIndex: "zipcode", key: "zipcode" },
    {
      title: t("TOTAL_AMOUNT"),
      dataIndex: "",
      key: "y",
      render: (val) => {
        return (
          <Space size="middle">
            {/* <img src={removeIcon} onClick={onDelete.bind(this, val._id)}/> */}
            {/* <Link
              to={{
                pathname: "/shop/warehouse/create",
                state: { id: val._id, name: val.name, location: val.location },
              }}
            >
              {t("EDIT")}
            </Link>
            <Button type="link" onClick={onDelete.bind(this, val._id)}>
              {t("DELETE")}
            </Button> */}
          </Space>
        );
      },
    },
    { title: t("PAYMENT_STATUS"), dataIndex: "zipcode", key: "zipcode" },
  ];

  return (
    <div className="sales-panel-root">
      <div className="search-panel">
        <div className="search-row">
          <Input
            placeholder={t("DASHBOARD_TOTAL_ORDERS_PLACEHOLDER")}
            className="search-condition-input"
          />
          <img src={searchIcon} className="suffix" />
        </div>
        <img src={filterIcon} className="filter-icon"/>
      </div>
      <div className="content-panel">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          className="orders-panel-table common-management-panel"
        />
      </div>
      <div className="orders-panel-footer">
        <div className="price-container">{t("TOTAL_SALES")} 0$</div>
      </div>
    </div>
  );
}
