import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SupplierList.module.sass";
import Card from "../../core-digital-template/components/Card";
import { numberWithCommas } from "../../core-digital-template/utils.js";
import { useUserId } from "../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "../../constants/statusCode.enum";
import Icon from "../../core-digital-template/components/Icon";
import { useHistory } from "react-router";
import { Path, PathTools } from "../../constants/path";
import { adminSupplierList, userSupplierList } from "../../api/user";
import { localizeValue } from "../../constants/constants";
import { NavLink } from "react-router-dom";
import { removeCompanyInfo } from "../../api/company";
import { deepClone } from "../../utils/utils";
import { toastError, toastInfo } from "../../api/toast";
import { Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const SupplierList = ({ className }) => {
  const { t, i18n } = useTranslation();
  const { user, loadUserInfo, isWinery } = useUserId();

  const history = useHistory();

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    loadData();
  }, [user]);

  const loadData = async () => {
    let pms = { userId: user.userId };
    let result = await adminSupplierList(pms);
    console.log("load userSupplierList:", result);
    console.log("language:", i18n.language);
    if (result && result.length > 0) {
      let temp = deepClone(items);
      temp = result;
      setItems(temp);
    }
  };

  const onEditCompany = (e) => {
    console.log(e);
    history.push(
      PathTools.joinPath(Path.vinbipAddOrUpdateSuplier, e.companyName),
      {
        companyId: e.id || e._id,
      }
    );
  };

  const onDeleteCompany = async (e) => {
    const pms = { coId: e._id };
    console.log("pms", pms);
    await removeCompanyInfo(pms);
    loadData();
  };

  const loginAs = async (item) => {
    if (item?.adminUserId) {
      toastInfo(t("LOG_IN_AS"));
      await loadUserInfo(item?.adminUserId);
    } else {
      toastError(t("INVALID_TEST_SUPPLIER"));
      return;
    }

    setTimeout(() => {
      window.location.reload();
    }, 2 * 1000);
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title={t("SUPPLIER_LIST")}
      classTitle="title-blue"
      head={
        <div onClick={() => history.push(Path.vinbipAddOrUpdateSuplier)}>
          <Icon name="plus-circle" size="24" />
        </div>
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>{t("COMPANY_NAME")}</div>
            <div className={styles.col}>{t("COMPANY_ID")}</div>
            <div className={styles.col}>{t("OPERATIONS")}</div>
          </div>
          {items.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>
                {localizeValue("en", x.companyName)}
              </div>
              <div className={styles.col}>
                {x.companyId}
              </div>
              <div className={styles.col} style={{ cursor: "pointer" }}>
                {/* <span onClick={onEditCompany.bind(this, x)}>{t("EDIT")}</span> */}
                <NavLink
                  to={PathTools.joinPath(
                    Path.visitorCompanyDetail,
                    localizeValue("en", x.companyName)
                  )}
                >
                  {t("VIEW_PROFILE")}
                </NavLink>
                <Popconfirm
                  title={t("CONFIRM_DELETE")}
                  description={t("ARE_YOU_SURE_TO_DELETE_THIS")}
                  okText={t("CONFIRM_DELETE")}
                  cancelText={t("NO")}
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                  onConfirm={onDeleteCompany.bind(this, x)}
                >
                  <span style={{ marginLeft: "10px" }}>{t("DELETE")}</span>
                </Popconfirm>
                <span
                  onClick={loginAs.bind(this, x)}
                  style={{ marginLeft: "10px" }}
                >
                  {t("LOGIN")}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default SupplierList;
