import apiObj from "./apipath";
import { actionRequest, formatRespArray, formatResponse } from "./api";
import { RequestType } from "../constants/requestType.enum";
import { loadProductConfig } from "./product";
import { SysEnvLanguageType } from "../constants/env.enum";
import { chinaCityList } from "../constants/area-data-min";

export async function getProductConfig() {
  let data: any = await actionRequest(RequestType.GET, apiObj.configProduct);
  return formatResponse(data);
}

export async function getLocations() {
  let data: any = await actionRequest(RequestType.GET, apiObj.locations);
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function getCountries<T>(pms: any) {
  let data: any = await actionRequest(
    RequestType.GET,
    apiObj.locationCountries,
    pms
  );
  return await formatRespArray<T>(data);
}

/**
 *
 * @param pms
 */
export async function getCities<T>(pms: any): Promise<Array<T>> {
  let data: any = await actionRequest(
    RequestType.GET,
    apiObj.locationAreas,
    pms
  );
  return await formatRespArray<T>(data);
}

/**
 *
 * @param pms
 */
export async function keywordProducts<T>(pms: any): Promise<Array<T>> {
  let data: any = await actionRequest(
    RequestType.GET,
    apiObj.keywordProducts,
    pms
  );
  return await formatRespArray<T>(data);
}

function getListFromLocations(locations: any) {
  let list = [];
  if (typeof locations === "object" && locations && locations.total > 0) {
    list = locations.result;
  } else if (locations && locations.length > 0) {
    list = locations;
  }
  return list;
}

/**
 *
 * @param locations :{total: 10,result:Array<Location>}
 */
export function getCountryForSelect(
  locations: any,
  lang: string,
  userType: string
) {
  let result = getListFromLocations(locations);
  if (result && result.length > 0) {
    result = result.filter(
      (item: any) =>
        item.type === 2 && item.open === true && item.limit.includes(userType)
    );
    result = formatLocationForSelect(result, lang);
    return result;
  }
  return [];
}

export function getSearchPromoteList(locations: any, lang: string) {
  let result = getListFromLocations(locations);
  if (result && result.length > 0) {
    result = result.filter((item: any) => item.search_highlight === true);
    result = formatLocationForSelect(result, lang);
    result.map((mapItem: any) => {
      let tempParent = getParentItem(locations, mapItem.parentId, lang);
      if (tempParent && tempParent.length > 0) {
        mapItem.parentItem = tempParent[0];
      }
      return mapItem;
    });
    return result;
  }
  return [];
}

export function getCountryAndCityForSelect(locations: any, lang: string) {
  let result = getListFromLocations(locations);
  if (result && result.length > 0) {
    result = result.filter(
      (item: any) => item.type === 2 && item.open === true
    );
    result = formatLocationForSelect(result, lang);
    result = result.map((item: any) => {
      item.children = getLocationsByParentId(locations, item.value, lang);
      return item;
    });
    return result;
  }
  return [];
}

/**
 *
 * @param locations
 * @param parentId
 * @param lang
 * @returns
 */
export function getLocationsByParentId(
  locations: any,
  parentId: string,
  lang: string
) {
  let result = getListFromLocations(locations);
  if (result && result.length > 0) {
    result = result.filter((item: any) => item.parentId === parentId);
    result = formatLocationForSelect(result, lang);
  }
  return result;
}

/**
 *
 * @param locations
 * @param parentId
 * @param lang
 * @returns
 */
export function getParentItem(locations: any, parentId: string, lang: string) {
  let result = getListFromLocations(locations);
  if (result && result.length > 0) {
    result = result.filter((item: any) => item._id === parentId);
    result = formatLocationForSelect(result, lang);
  }
  return result;
}

/**
 *
 * @param list table locations data
 * @param lang localize language
 * @returns
 */
export function formatLocationForSelect(list: Array<any>, lang: string) {
  if (list && list.length > 0) {
    list = list.map((item: any) => {
      return formatLocationItem(item, lang);
    });
    return list.sort((a: any, b: any) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  }
  return [];
}

export async function getLocationNameById(
  id: string,
  lang: string,
  locations?: any
) {
  let result;
  if (id) {
    if (!locations) {
      let configData = await loadProductConfig();
      if (configData) {
        locations = configData.locations;
      }
    }
    if (locations && locations.total > 0) {
      let locationResult = locations.result;
      locationResult = locationResult.filter((item: any) => item._id === id);
      locationResult = formatLocationForSelect(locationResult, lang);
      result = locationResult.length > 0 ? locationResult[0].label : null;
    }
  }
  return result;
}

export function getCountryNameById(locations: any, lang: string, id: string) {
  let result;
  if (locations && locations.total > 0 && id) {
    let locationResult = locations.result;
    locationResult = locationResult.filter((item: any) => item._id === id);
    locationResult = formatLocationForSelect(locationResult, lang);
    result = locationResult.length > 0 ? locationResult[0].label : id;
  }
  if (!result) {
    return "";
  }
  return result;
}

export function getChinaCityNameById(cityIds: any) {
  let cityStr = "";
  if (cityIds && cityIds.length > 0) {
    let level1 = chinaCityList.filter(
      (filterItem) => filterItem.value == cityIds[0]
    );
    if (level1 && level1.length > 0) {
      cityStr = level1[0].label;

      let level2 = level1[0].children.filter(
        (filterItem) => filterItem.value == cityIds[1]
      );
      if (level2 && level2.length > 0) {
        cityStr += level2[0].label;

        let level3 = level2[0].children.filter(
          (filterItem) => filterItem.value == cityIds[2]
        );
        if (level3 && level3.length > 0) {
          cityStr += level3[0].label;
        }
      }
    }
  }

  return cityStr;
}

function formatLocationItem(item: any, lang: string) {
  let label = item.name;
  if (lang === SysEnvLanguageType.ZH) {
    label = item.languages.zh;
  } else if (lang === SysEnvLanguageType.FR) {
    label = item.languages.fr;
  }
  return {
    label: label,
    value: item._id,
    search_highlight: item.search_highlight,
    parentId: item.parentId,
    image: item.image,
  };
}

export function formatConfigForSelect(list: Array<any>, lang: string) {
  if (list && list.length > 0) {
    list = list.map((item: any) => {
      return formatConfigItem(item, lang);
    });
    return list;
  }
  return [];
}

function formatConfigItem(item: any, lang: string) {
  let label = item.name;
  if (lang === SysEnvLanguageType.ZH) {
    label = item.language.zh;
  } else if (lang === SysEnvLanguageType.FR) {
    label = item.language.fr;
  }
  return { label: label, value: item.value };
}
