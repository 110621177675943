import { useState, useEffect } from "react";
import { useUserId } from "../../../../contexts/UserIdProvider";
import closeIcon from "../../../../resource/icons/common/close.svg";
import { useTranslation } from "react-i18next";
import { deepClone } from "../../../../utils/utils";
import "./style/feedScope.less";
import backIcon from "../../../../resource/icons/feed/detail/back.svg";
import publicIcon from "./images/public.svg";
import friendIcon from "./images/friend.svg";
import limitIcon from "./images/limit.svg";
import lockIcon from "./images/lock.svg";
import specialFriendIcon from "./images/special_friend.svg";
import selectedIcon from "./images/selected.svg";
import { FeedScopeType } from "../../../../constants/feed.enum";

export default function FeedScope(props) {
  const { onItemSelected, onClose, selectedFeedScopeItem } = props;
  const { user, isWinery } = useUserId();
  const { userId } = user;
  const coId = user?.userInfo?.companyId;
  const { t } = useTranslation();

  const [scopeMenus, setScopeMenus] = useState([]);

  useEffect(() => {
    console.log("selectedFeedScopeItem change:");
    initReportMenus();
  }, []);

  useEffect(() => {
    if (scopeMenus.length == 0) {
      let temp = deepClone(scopeMenus);
      temp = [];
      temp.push(
        ...[
          {
            label: t("PUBLIC"),
            value: FeedScopeType.PUBLIC,
            desc: t("PUBLIC_SCOPE_DESC"),
            icon: publicIcon,
          },
          {
            label: t("FRIEND"),
            value: FeedScopeType.FRIEND,
            desc: t("YOUR_FRIEND"),
            icon: friendIcon,
          },
          {
            label: t("FRIEND_SCOPE_LIMIT"),
            value: FeedScopeType.FRIEND_SCOPE_LIMIT,
            desc: t("FRIEND_SCOPE_LIMIT_DESC"),
            icon: limitIcon,
          },
          {
            label: t("ONLY_ME"),
            value: FeedScopeType.ONLY_ME,
            icon: lockIcon,
          },
          {
            label: t("SPECIAL_FRIEND"),
            value: FeedScopeType.SPECIAL_FRIEND,
            desc: t("SPECIAL_FRIEND_DESC"),
            icon: specialFriendIcon,
          },
        ]
      );
      setScopeMenus(temp);
      
      temp.map((mapItem) => {
        if (mapItem.value == selectedFeedScopeItem.value) {
          mapItem.selected = true;
        } else {
          mapItem.selected = false;
        }
      });
      setScopeMenus(temp);
    }
  }, [selectedFeedScopeItem]);

  const initReportMenus = () => {
    if (scopeMenus.length == 0) {
      let temp = deepClone(scopeMenus);
      temp = [];
      temp.push(
        ...[
          {
            label: t("PUBLIC"),
            value: FeedScopeType.PUBLIC,
            desc: t("PUBLIC_SCOPE_DESC"),
            icon: publicIcon,
          },
          {
            label: t("FRIEND"),
            value: FeedScopeType.FRIEND,
            desc: t("YOUR_FRIEND"),
            icon: friendIcon,
          },
          {
            label: t("FRIEND_SCOPE_LIMIT"),
            value: FeedScopeType.FRIEND_SCOPE_LIMIT,
            desc: t("FRIEND_SCOPE_LIMIT_DESC"),
            icon: limitIcon,
          },
          {
            label: t("ONLY_ME"),
            value: FeedScopeType.ONLY_ME,
            icon: lockIcon,
          },
          {
            label: t("SPECIAL_FRIEND"),
            value: FeedScopeType.SPECIAL_FRIEND,
            desc: t("SPECIAL_FRIEND_DESC"),
            icon: specialFriendIcon,
          },
        ]
      );
      setScopeMenus(temp);
    }
  };

  const onFeedScopeItemClick = (scopeItem, reportIndex) => {
    let tempReportMenus = deepClone(scopeMenus);
    tempReportMenus.map((mapItem) => {
      mapItem.selected = false;
    });
    tempReportMenus[reportIndex].selected = true;
    setScopeMenus(tempReportMenus);

    onItemSelected && onItemSelected(scopeItem);
  };

  const onCloseModal = () => {
    onClose && onClose();
  };

  return (
    <div className="feed-post-scope-panel">
      <div className="title-panel">
        <img
          src={backIcon}
          style={{ cursor: "pointer" }}
          onClick={onCloseModal.bind(this)}
        />
        <div className="title">{t("SELECT_FEED_SCOPE")}</div>
        <img
          src={closeIcon}
          onClick={onCloseModal.bind(this)}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="content-panel">
        {scopeMenus.map((mapItem, mapIndex) => {
          return (
            <div
              className={
                mapItem.selected
                  ? "feed-scope-item-panel-selected"
                  : "feed-scope-item-panel"
              }
              onClick={onFeedScopeItemClick.bind(this, mapItem, mapIndex)}
            >
              <div className="left-part">
                <img src={mapItem.icon} />
                <div className="scope-desc">
                  <div className="label">{mapItem.label}</div>
                  <div className="scope-desc-desc">{mapItem.desc}</div>
                </div>
              </div>
              {mapItem.selected ? (
                <img src={selectedIcon} />
              ) : (
                <div className="right-dot"></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
