import React, { useState, useEffect } from "react";
import { Input, Button, Space, Tree, Col, Select, Modal } from "antd";
import { useTranslation } from "react-i18next";
import "./style/brandCreate.less";
import { Row } from "antd";
import {
  companyBrandSafetyCreate,
  createStaff,
  updateStaff,
} from "../../../api/company";
import { createRole } from "../../../api/roles";
import { statusCode } from "../../../constants/statusCode.enum";
import { toastError, toastInfo } from "../../../api/toast";
import { useUserId } from "../../../contexts/UserIdProvider";
import { list as fetchUserLimitList } from "../../../api/userlimit";
import completeTipsIcon from "../../../resource/icons/dashboard/brand/complete_tips.svg";
import { constants, deepClone } from "../../../utils/utils";
import UploadBase from "../../common/upload/UploadBase";
import { localizeImg } from "../../../constants/constants";
import { loadProductConfig } from "../../../api/product";
import { getCountryForSelect } from "../../../api/config";
import { chinaCityList } from "../../../constants/area-data-min";
import AddressSelect from "../../common/location/addressSelect/AddressSelect";
import { useHistory } from "react-router";

export default function BrandCreate() {
  const { user } = useUserId();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [stepsIndex, setStepsIndex] = useState(0);
  const [stepsProgress, setStepsProgress] = useState([]);
  const [tradeMarkDetails, setTradeMarkDetails] = useState({});
  const [tradeMarkOwnerDetails, setTradeMarkOwnerDetails] = useState({});

  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const [province, setProvince] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [inChina, setInChina] = useState(true);

  const [completeTipsModal, setCompleteTipsModal] = useState(false);

  useEffect(() => {
    initConfigData();
    initStepsProgress();
  }, []);

  const initStepsProgress = () => {
    let temp = deepClone(stepsProgress);
    temp.push(
      ...[
        {
          label: t("TRADE_MARK_DETAILS"),
          complete: true,
        },
        {
          label: t("TRADE_MARK_OWNER_DETAILS"),
        },
        {
          label: t("PROCESSING"),
        },
      ]
    );
    setStepsProgress(temp);
  };

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setCountries(getCountryForSelect(locations, i18n.language));

    let tempProvinces = deepClone(provinces);
    tempProvinces = deepClone(chinaCityList);
    setProvinces(tempProvinces);
  };

  const setTradMarkDetail = (name, value) => {
    let temp = deepClone(tradeMarkDetails);
    temp[name] = value;
    setTradeMarkDetails(temp);
  };

  const setTradeMarkOwnerProp = (name, value) => {
    let temp = deepClone(tradeMarkOwnerDetails);
    temp[name] = value;
    setTradeMarkOwnerDetails(temp);
  };

  const onBrandLogoLoading = (fileType, url, name) => {
    setTradMarkDetail("logo", { fileType, url, name });
  };

  const onBrandLogoUpload = async (fileType, url, name, size) => {
    let content = { fileType, url, name, size };
    console.log("onBrandLogoUpload:", content);
    setTradMarkDetail("logo", content);
  };

  const onNextStep = async () => {
    if (stepsIndex == 0) {
      if (
        !tradeMarkDetails.name ||
        !tradeMarkDetails.country ||
        !tradeMarkDetails.logo ||
        !tradeMarkDetails.classification
      ) {
        toastInfo(t("PLEASE_INPUT_REAL_INFO"));
        return;
      }

      setStepsIndex(stepsIndex + 1);

      let temp = deepClone(stepsProgress);
      temp[1].complete = true;
      setStepsProgress(temp);
    } else if (stepsIndex == 1) {
      let temp = deepClone(stepsProgress);
      temp[2].complete = true;
      setStepsProgress(temp);

      let pms = {
        coId: user.userInfo.companyId,
        tradeMarkDetails,
        tradeMarkOwnerDetails,
      };
      if (!inChina) {
        delete pms.tradeMarkOwnerDetails.province;
        delete pms.tradeMarkOwnerDetails.city;
      }
      console.log(pms);
      let createResult = await companyBrandSafetyCreate(pms);
      console.log("createResult:", createResult);

      setCompleteTipsModal(true);
    }

    console.log(tradeMarkDetails, tradeMarkOwnerDetails);
  };

  const onCountryChange = (val) => {
    setTradeMarkOwnerProp("country", val);
    setInChina(val == constants.chinaCountryId);
    // setTradeMarkOwnerProp("province", {});
  };

  const onProvinceChange = (val) => {
    setTradeMarkOwnerProp("province", val);
    setTradeMarkOwnerProp("city", {});

    let selectedProvinces = chinaCityList.filter(
      (filterItem) => filterItem.value == val
    );
    if (selectedProvinces && selectedProvinces.length > 0) {
      let tempCities = deepClone(cities);
      tempCities = deepClone(selectedProvinces[0].children);
      setCities(tempCities);
    }
  };

  const onCityChange = (val) => {
    setTradeMarkOwnerProp("city", val);
  };

  const onAddressChange = (val) => {
    setTradeMarkOwnerProp("address", val);
  };

  const onSuccessModalClose = () => {
    setCompleteTipsModal(false);
    history.go(-1);
  };

  return (
    <div className="brand-create-container">
      <div className="steps-panel">
        {stepsProgress.map((mapItem, mapIndex) => {
          return (
            <div className="step-item">
              <div
                className={
                  mapItem.complete ? "step-index-complete" : "step-index"
                }
              >
                {mapIndex + 1}
              </div>
              <div className="label">{mapItem.label}</div>
            </div>
          );
        })}
      </div>
      {stepsIndex == 0 && (
        <div className="steps-1-pane">
          <div className="steps-row">
            <div className="row-name">
              <div>{t("PLEASE_SELECT_COUNTRY_FOR_TRADE_MARK_APPLICATION")}</div>
            </div>
            <Input
              value={tradeMarkDetails.country}
              className={"form-input"}
              onInput={(e) => setTradMarkDetail("country", e.target.value)}
            />
          </div>
          <div className="steps-row">
            <div className="row-name">
              <div>{t("THE_NAME_YOU_WISH_TO_PRODUCT")}</div>
            </div>
            <Input
              value={tradeMarkDetails.name}
              className={"form-input"}
              onInput={(e) => setTradMarkDetail("name", e.target.value)}
            />
          </div>
          <div className="steps-row">
            <div className="row-name">
              <div>{t("IF_YOU_HAVE_LOGO_PLEASE_UPLOAD_YOUR_LOGO_HER")}</div>
            </div>
            <UploadBase
              onLoading={onBrandLogoLoading.bind(this)}
              onLoaded={onBrandLogoUpload.bind(this)}
            >
              {tradeMarkDetails.logo ? (
                <div className="form-upload-placeholder">
                  {" "}
                  <img
                    src={localizeImg(tradeMarkDetails?.logo?.url)}
                    style={{ width: "95px", height: "95px" }}
                  />
                </div>
              ) : (
                <div className="form-upload-placeholder">
                  <div className="upload-placeholder">Vinbip</div>
                  <div className="upload-placeholder-label">
                    {t("CHOOSE_LOGO_AND_DRAG_IT_HERE")}
                  </div>
                </div>
              )}
            </UploadBase>
          </div>
          <div className="steps-row">
            <div className="row-name">
              <div>{t("CHOOSE_PRODUCTS_AND_SERVICES_CLASSIFICATION")}</div>
            </div>
            <Input
              value={tradeMarkDetails.classification}
              className={"form-input"}
              onInput={(e) =>
                setTradMarkDetail("classification", e.target.value)
              }
            />
          </div>
        </div>
      )}
      {stepsIndex == 1 && (
        <div className="steps-2-pane">
          <div className="steps-row">
            <div className="row-name">
              <div>{t("TRADE_MAKE_OWNER_NAME")}</div>
            </div>
            <Input
              value={tradeMarkOwnerDetails.ownerName}
              className={"form-input"}
              onInput={(e) =>
                setTradeMarkOwnerProp("ownerName", e.target.value)
              }
            />
          </div>
          <div className="steps-row">
            <div className="row-name">
              <div>{t("COUNTRY")}</div>
            </div>
            <Select
              className="form-select"
              value={tradeMarkOwnerDetails?.country}
              onChange={onCountryChange.bind(this)}
              placeholder={t("COUNTRY")}
              options={countries}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {inChina && (
              <div className="steps-row">
                <div className="row-name">
                  <div>{t("PROVINCE")}</div>
                </div>
                <Select
                  className="form-select"
                  style={{ width: "371px" }}
                  value={tradeMarkOwnerDetails.province}
                  onChange={onProvinceChange.bind(this)}
                  placeholder={t("PROVINCE")}
                  options={provinces}
                />
              </div>
            )}
            {inChina && (
              <div className="steps-row">
                <div className="row-name">
                  <div>{t("CITY")}</div>
                </div>
                <Select
                  className="form-select"
                  style={{ width: "371px" }}
                  value={tradeMarkOwnerDetails.city}
                  onChange={onCityChange.bind(this)}
                  placeholder={t("CITY")}
                  options={cities}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="steps-row-address">
              <div className="row-name">
                <div>{t("ADDRESS")}</div>
              </div>
              <AddressSelect
                country={tradeMarkOwnerDetails.country}
                onChange={onAddressChange.bind(this)}
              />
            </div>
            <div className="steps-row">
              <div className="row-name">
                <div>{t("ZIPCODE")}</div>
              </div>
              <Input
                value={tradeMarkOwnerDetails.zipcode}
                className={"form-input"}
                style={{ width: "371px" }}
                onInput={(e) =>
                  setTradeMarkOwnerProp("zipcode", e.target.value)
                }
              />
            </div>
          </div>
        </div>
      )}
      <div className="bottom-container">
        <div className="btn-apply" onClick={onNextStep.bind(this)}>
          {t("NEXT")}
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={completeTipsModal}
        footer={null}
        width={583}
        height={306}
        closable={false}
        className="brand-create-success-modal"
        onCancel={onSuccessModalClose.bind(this)}
      >
        <div className="container">
          <div className="label">{t("APPLY_SUBMIT_SUCCESSED")}</div>
          <img src={completeTipsIcon} className="tips-icon" />
        </div>
      </Modal>
    </div>
  );
}
