import { Dropdown, Input, Menu, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getBlockList, pageFriends } from "../../../api/contact";
import { localizeImg } from "../../../constants/constants";
import { useUserId } from "../../../contexts/UserIdProvider";
import AvatarBasic from "../avatar/AvatarBasic";
import "./style/messagePanel.less";
import messageIcon from "./images/message.svg";
import groupIcon from "./images/group.svg";
import sendMsgIcon from "./images/send_msg.svg";
import audioCallIcon from "./images/audio_call.svg";
import videoCallIcon from "./images/video_call.svg";
import quitIcon from "./images/quit.svg";
import { setBlock, setOnTop } from "../../../api/userFriends";
import searchIcon from "./images/search.svg";
import { deepClone, goToProfilePage } from "../../../utils/utils";
import { useHistory } from "react-router";
import { getGroups } from "../../../api/chat";
import { getChinaCityNameById, getCountryNameById } from "../../../api/config";
import { loadProductConfig } from "../../../api/product";
import { useIMContext } from "../../../contexts/IMProvider";
import { ChatContentsType } from "../../../constants/chatContentsType.enum";

export default function MessagePanel(props) {
  const { user } = useUserId();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [lang, setLang] = useState(i18n.language);
  const { setChatCallMsg } = useIMContext();

  const [friends, setFriends] = useState([]);
  const [oriFriends, setOriFriends] = useState([]);

  let keyword = "";
  const [locations, setLocations] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
    initConfigData();
  }, []);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const loadData = async () => {
    setLoading(true);
    await getFriends();
    setLoading(false);
  };

  const getFriends = async (e) => {
    if (e) {
      keyword = e.target.value;
    }
    let list = await loadContacts(keyword);
    console.log("getFriends:", list);
    if (list && list.length > 0) {
      list.map((mapItem) => {
        mapItem.cardType = "FRIEND";
      });
      await concatGroups(list);
    }
  };

  const loadContacts = async (searchVal) => {
    searchVal = searchVal ? searchVal : "";
    let pms = { page: 0, per_page: 20, keyword: searchVal };
    let result = await pageFriends(user.userId, pms);
    if (result) {
      let pms = { userId: user.userId };
      let blackList = await getBlockList(pms);
      result = result.filter((item) => {
        return blackList.filter((b) => b.targetId === item.id).length === 0;
      });
    }
    return result;
  };

  const concatGroups = async (list) => {
    let groupList = await loadGroups();
    list = list.concat(groupList);
    console.log("result friend list:", list);
    setFriends(list);
    setOriFriends(list);
  };

  //#region 群组
  const loadGroups = async () => {
    let pms = { coId: user.userInfo.companyId, memberId: user.userId };
    let result = await getGroups(pms);
    console.log("group result:", result);
    if (result) {
      result = result.filter((group) => (group.imGroupId ? true : false));
      result = result.map((item) => {
        return formatGroupToFriend(item);
      });
    }
    return result;
  };

  const formatGroupToFriend = (item) => {
    let { _id, name, imGroupId, members } = item;
    return { id: imGroupId, serverId: _id, name, cardType: "GROUP", members };
  };

  const setFriendOnTop = async (friendItem) => {
    console.log(friendItem);
    let pms = {
      userId: user.userId,
      friendId: friendItem.id,
    };
    await setOnTop(pms);
  };

  const setFriendBlock = async (friendItem) => {
    console.log(friendItem);
    let pms = {
      userId: user.userId,
      friendId: friendItem.id,
    };
    await setBlock(pms);
  };

  const onSearchContentChange = (e) => {
    let key = e.target.value;

    let temp = deepClone(friends);
    temp = oriFriends.filter(
      (filterItem) => filterItem.name.indexOf(key) != -1
    );
    setFriends(deepClone(temp));
  };

  const onGoToProfile = (friendItem) => {
    if (friendItem.cardType == "FRIEND") {
      friendItem._id = friendItem.id;
      goToProfilePage(friendItem, history);
    }
  };

  const onVoiceCall = (friendItem) => {
    setChatCallMsg({
      type: ChatContentsType.AUDIOCALL,
      from: user.userId,
      to: friendItem.id,
    });
  };

  const onVideoCall = (friendItem) => {
    setChatCallMsg({
      type: ChatContentsType.VIDEOCALL,
      from: user.userId,
      to: friendItem.id,
    });
  };

  const menu_language = (friendItem) => {
    return (
      <Menu className="contact-item-action-panel">
        <Menu.Item
          key="SEND_MESSAGE"
          onClick={() => history.push("/imchat-deprecated", { userId: friendItem.id })}
        >
          <div className="contact-action-panel">
            <img src={sendMsgIcon} className="contact-action-icon" />
            <span>{t("SEND_MESSAGE")}</span>
          </div>
        </Menu.Item>
        {friendItem.cardType == "FRIEND" && (
          <Menu.Item
            key="VOICE_CALL"
            onClick={onVoiceCall.bind(this, friendItem)}
          >
            <div className="contact-action-panel">
              <img src={audioCallIcon} className="contact-action-icon" />
              <span>{t("VOICE_CALL")}</span>
            </div>
          </Menu.Item>
        )}
        {friendItem.cardType == "FRIEND" && (
          <Menu.Item
            key="VIDEO_CALL"
            onClick={onVideoCall.bind(this, friendItem)}
          >
            <div className="contact-action-panel">
              <img src={videoCallIcon} className="contact-action-icon" />
              <span>{t("VIDEO_CALL")}</span>
            </div>
          </Menu.Item>
        )}
        {friendItem.cardType == "GROUP" && (
          <Menu.Item key="QUIT">
            <div className="contact-action-panel">
              <img src={quitIcon} className="contact-action-icon" />
              <span>{t("QUIT")}</span>
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <div className="friends-panel">
      <div
        style={{
          maxHeight: "573px",
          overflowX: "auto",
        }}
      >
        <div className="contact-category-title">{t("ALL_CONTACT")}</div>
        {loading && (
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <Skeleton active={true} avatar={true} paragraph={{ rows: 0 }} />
            <Skeleton active={true} avatar={true} paragraph={{ rows: 0 }} />
          </div>
        )}
        {friends.length == 0 && !loading && (
          <div className="no-content-tips">{t("NO_FRIEND_YET")}</div>
        )}
        {!loading &&
          friends.map((friendItem, friendIndex) => {
            return (
              <div className="friend-item panel-hover" key={friendIndex}>
                <div
                  className="flex-display align-items-center cursor-pointer"
                  onClick={onGoToProfile.bind(this, friendItem)}
                >
                  <AvatarBasic
                    picture={localizeImg(friendItem?.companyInfo?.avatar)}
                    cname="message-avatar"
                    displayName={friendItem.name}
                    size={51}
                    shape={"square"}
                  />
                  <div className="user-name-panel">
                    <div className="user-name">{friendItem.name}</div>
                    <div className="user-role-name">
                      {friendItem?.roleInfo?.name == "ADMIN"
                        ? t(friendItem?.roleInfo?.name)
                        : friendItem?.roleInfo?.name}
                    </div>
                    {friendItem.cardType == "FRIEND" && (
                      <div className="user-location">
                        {t("LOCATION")}
                        {":"}
                        {getCountryNameById(
                          locations,
                          lang,
                          friendItem?.companyInfo?.location?.country
                        )}{" "}
                        {getChinaCityNameById(
                          friendItem?.companyInfo?.location?.city?.slice(0, 1)
                        )}
                      </div>
                    )}
                    {friendItem.cardType == "GROUP" && (
                      <div className="group-info">
                        <img src={groupIcon} className="group-icon" />
                        {friendItem.members.length} {t("PEOPLE")}
                      </div>
                    )}
                  </div>
                </div>
                <Dropdown
                  overlay={menu_language(friendItem)}
                  trigger={["click"]}
                >
                  <img src={messageIcon} />
                </Dropdown>
              </div>
            );
          })}
      </div>
      <div className="friends-panel-footer align-items-center">
        <img src={searchIcon} className="search-icon" />
        <Input
          placeholder={t("SEARCH_CONTACT")}
          className="search-contact"
          onChange={onSearchContentChange.bind(this)}
        />
      </div>
    </div>
  );
}
