import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Menu, Modal, Table, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { getOrders, updateOrder } from "../../../api/orders";
import "./style/buyerDocuments.less";
import { useHistory, useParams } from "react-router-dom";
import { useUserId } from "../../../contexts/UserIdProvider";
import { deepClone } from "../../../utils/utils";
import plusIcon from "../../../resource/icons/dashboard/common/plus_black.svg";
import upArrowIcon from "../../../resource/icons/dashboard/common/up_arrow.svg";
import attachIcon from "../../../resource/icons/dashboard/documents/attach.svg";
import imgPlaceHolderIcon from "../../../resource/icons/dashboard/documents/img_placeholder.svg";
import downArrowIcon from "../../../resource/icons/dashboard/common/down_arrow.svg";
import UploadBase from "../../common/upload/UploadBase";
import { dateFormat } from "../../../utils/date";
import { localizeValue } from "../../../constants/constants";

export default function BuyerDocuments() {
  const { index } = useParams();
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const { isWinery, user } = useUserId();
  const history = useHistory();

  const [tabs, setTabs] = useState([
    {
      label: "ISENDED",
      value: "ISENDED",
      selected: true,
    },
    {
      label: "IRECEIVED",
      value: "IRECEIVED",
    },
  ]);

  const { TabPane } = Tabs;
  const [loading, setLoading] = useState(true);

  const pageSize = 20;
  const [pageIndex, setPageIndex] = useState(0);

  const [orderList, setOrderList] = useState([]);
  const [attachFilesVisible, setAttachFilesVisible] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [selectedOrder, setSelectedOrder] = useState({});
  const [oriDocumentList, setOriDocumentList] = useState([]);
  const [documentList, setDocumentList] = useState([]);

  const [orderDocuments, setOrderDocuments] = useState([]);

  useEffect(() => {
    initOrderDocuments();
  }, [i18n.language]);

  useEffect(() => {
    loadData();
  }, [user]);

  useEffect(() => {
    let temp = supplierList.filter((filterItem) => filterItem.selected);
    if (temp && temp.length > 0) {
      setSelectedSupplier(deepClone(temp[0]));
    }
  }, [supplierList]);

  useEffect(() => {
    let tempDocumentList = orderList.filter(
      (filterItem) => filterItem.files && filterItem.files.length > 0
    );
    console.log("-------------------documentList:", tempDocumentList);
    setDocumentList(tempDocumentList);
    setOriDocumentList(deepClone(tempDocumentList));

    let temp = orderList.filter((filterItem) => filterItem.selected);
    if (temp && temp.length > 0) {
      setSelectedOrder(deepClone(temp[0]));
    }
  }, [orderList]);

  useEffect(() => {
    if (
      selectedOrder &&
      selectedOrder.files &&
      selectedOrder.files.length > 0
    ) {
      let temp = deepClone(orderDocuments);
      temp = selectedOrder.files;
      setOrderDocuments(temp);
    } else {
      initOrderDocuments();
    }
  }, [selectedOrder]);

  const loadData = async () => {
    let pms = { page: pageIndex, per_page: pageSize };
    if (isWinery) {
      pms.coId = user.userInfo.companyId;
    } else {
      pms.userId = user.userId;
    }

    let result = await getOrders(pms);
    console.log("--------orders :", pms, result);
    if (!result) return;
    if (result && result.length > 0) {
      result[0].selected = true;
    }
    setOrderList(result);

    let tempSupplierList = [];
    result.map((mapItem, mapIndex) => {
      if (mapItem.wineryInfo && mapItem.wineryInfo.length > 0) {
        if (mapIndex == 0) {
          mapItem.wineryInfo[0].selected = true;
        }
        tempSupplierList.push(mapItem.wineryInfo[0]);
      }
    });
    setSupplierList(tempSupplierList);

    setLoading(false);
  };

  const initOrderDocuments = () => {
    let temp = deepClone(orderDocuments);
    temp = [];
    temp.push(
      ...[
        {
          label: t("TRADING_CONTRACT"),
          value: "TRADING_CONTRACT",
        },
        {
          label: t("INVOICE"),
          value: "INVOICE",
        },
        {
          label: t("CERTIFICATE_OF_BOTTLING"),
          value: "CERTIFICATE_OF_BOTTLING",
        },
        {
          label: t("CERTIFICATE_OF_TREATMENT_FOR_WOODEN_PACKING"),
          value: "CERTIFICATE_OF_TREATMENT_FOR_WOODEN_PACKING",
        },
        {
          label: t("CERTIFICATE_OF_ORIGION"),
          value: "CERTIFICATE_OF_ORIGION",
        },
        {
          label: t("HEALTHY_CERTIFICATE"),
          value: "HEALTHY_CERTIFICATE",
        },
        {
          label: t("COLOR_SCAN_OF_FRONT_AND_BACK_LABLE"),
          value: "COLOR_SCAN_OF_FRONT_AND_BACK_LABLE",
        },
      ]
    );
    setOrderDocuments(temp);
  };

  const switchTabIndex = (index) => {
    let temp = deepClone(tabs);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setTabs(temp);

    let temp1 = deepClone(documentList);
    temp1 = deepClone(
      oriDocumentList.filter((filterItem) =>
        index == 0
          ? filterItem.coId == user.userInfo.companyId
          : filterItem.coId != user.userInfo.companyId
      )
    );
    setDocumentList(temp1);
  };

  const onSupplierListClick = (index) => {
    let temp = deepClone(supplierList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setSupplierList(temp);
  };

  const onOrderListClick = (index) => {
    let temp = deepClone(orderList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setOrderList(temp);
  };

  const onRemoveAttachedDocument = async (orderItem, fileItem) => {
    console.log(orderItem, fileItem);
    let temp = deepClone(orderList);
    temp.map((mapItem) => {
      if (
        mapItem._id == orderItem._id &&
        mapItem.files &&
        mapItem.files.length > 0
      ) {
        mapItem.files.map((fileMapItem) => {
          if (fileMapItem.value == fileItem.value) {
            delete fileMapItem.file;
          }
        });
        orderItem.files = mapItem.files;
      }
    });
    setOrderList(temp);
    // let temp = deepClone(orderDocuments);
    // // delete temp[index].file;
    // setOrderDocuments(temp);
    console.log(orderItem.files);
    let pms = { _id: orderItem._id, files: orderItem.files };
    let result = await updateOrder(pms);
  };

  const menu_document_action = (orderItem, fileItem) => {
    return (
      <Menu className="discover-dropdown-menu">
        <Menu.Item
          key={0}
          // onClick={onSupplierListClick.bind(this, mapIndex)}
        >
          {t("DOWNLOAD")}
        </Menu.Item>
        <Menu.Item
          key={1}
          onClick={onRemoveAttachedDocument.bind(this, orderItem, fileItem)}
        >
          {t("DELETE")}
        </Menu.Item>
      </Menu>
    );
  };

  const menu_supplier_list = (
    <Menu className="discover-dropdown-menu">
      {supplierList.map((mapItem, mapIndex) => {
        return (
          <Menu.Item
            key={mapIndex}
            onClick={onSupplierListClick.bind(this, mapIndex)}
          >
            {mapItem.companyName}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const menu_order_list = (
    <Menu className="discover-dropdown-menu">
      {orderList.map((mapItem, mapIndex) => {
        return (
          <Menu.Item
            key={mapIndex}
            onClick={onOrderListClick.bind(this, mapIndex)}
          >
            {mapItem.orderNumber}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const onAttachFilesModalClose = () => {
    setAttachFilesVisible(false);
  };

  const onOrderFilesLoading = (e) => {
    // setTradMarkDetail("logo", { fileType, url, name });
  };

  const onOrderFilesLoaded = async (
    fileItem,
    fileIndex,
    fileType,
    url,
    name,
    size
  ) => {
    let content = { fileType, url, name, size };
    console.log("onOrderFilesLoaded:", content);
    let temp = deepClone(orderDocuments);
    temp[fileIndex].file = content;
    temp[fileIndex].coId = user.userInfo.companyId;
    setOrderDocuments(temp);

    let pms = { _id: selectedOrder._id, files: temp };
    let result = await updateOrder(pms);
    loadData();
  };

  const onShowAttachFileModal = () => {
    setAttachFilesVisible(true);
  };

  return (
    <div
      className="common-page"
      style={{
        paddingTop: "25px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div className="company-document-buyer-container common-management ">
        <div className="header-panel">
          <div className="document-tabs">
            {tabs.map((mapItem, mapIndex) => {
              return (
                <div
                  className={
                    mapItem.selected ? "tab-item-selected" : "tab-item"
                  }
                  style={{
                    marginLeft: mapIndex == tabs.length - 1 ? "141px" : "0px",
                  }}
                  onClick={switchTabIndex.bind(this, mapIndex)}
                >
                  {t(mapItem.label)}
                </div>
              );
            })}
          </div>
          <div
            className="attach-panel"
            onClick={onShowAttachFileModal.bind(this)}
          >
            <img src={plusIcon} />
            <div className="label">{t("ATTACH_FILES")}</div>
          </div>
        </div>
        <div className="document-list">
          {documentList.map((mapItem, mapIndex) => {
            return (
              <div className="document-item">
                <div className="order-info">
                  <div className="order-date">
                    {dateFormat(mapItem.createdAt, "MM.dd/yyyy")}
                  </div>
                  <div className="order-info-row">
                    <div className="order-into-item">
                      <div className="label">{t("SUPPLIER")}</div>
                      <div className="value ellipsis-text">
                        {localizeValue(
                          i18n.language,
                          mapItem.wineryInfo[0].companyName
                        )}
                      </div>
                    </div>
                    <div className="order-into-item">
                      <div className="label">{t("ORDER_NUMBER")}</div>
                      <div className="value ellipsis-text">
                        {mapItem.orderNumber}
                      </div>
                    </div>
                    <div className="order-into-item">
                      <div className="label">{t("ORDER_STATUS")}</div>
                      <div className="value ellipsis-text">
                        {t(mapItem.status)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="attach-document-panel">
                  <div className="title">{t("ATTACH_FILES")}</div>
                  <div className="file-panel">
                    {mapItem.files
                      .filter((fileFilterItem) => fileFilterItem.file)
                      .map((fileItem, mapIndex) => {
                        return (
                          <div className="attach-item">
                            <div className="label">{t(fileItem.value)}</div>
                            <div className="attach-info display-flex-row-between">
                              <div className="left-part">
                                <img src={imgPlaceHolderIcon} />
                                <div className="display-flex-column-between">
                                  <div className="name">
                                    {fileItem?.file?.name}
                                  </div>
                                  <div className="size">
                                    {Math.ceil(fileItem?.file?.size)}KB
                                  </div>
                                </div>
                              </div>
                              <Dropdown
                                overlay={menu_document_action(
                                  mapItem,
                                  fileItem
                                )}
                                trigger={["click"]}
                              >
                                <img
                                  src={downArrowIcon}
                                  className="cursor-pointer"
                                />
                              </Dropdown>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={attachFilesVisible}
        closable={false}
        footer={null}
        className="buyer-document-file-upload-modal"
        onCancel={onAttachFilesModalClose.bind(this)}
      >
        <div className="upload-panel">
          <div className="title">{t("UPLOAD_IMPORTING_FILES")}</div>
          <div className="filter-panel">
            <Dropdown overlay={menu_supplier_list} trigger={["click"]}>
              <div className="filter-item-input">
                {selectedSupplier
                  ? localizeValue(i18n.language, selectedSupplier.companyName)
                  : ""}
                <img src={upArrowIcon} />
              </div>
            </Dropdown>
            <div className="filter-item-speator"></div>
            <Dropdown overlay={menu_order_list} trigger={["click"]}>
              <div className="filter-item-input">
                {selectedOrder ? selectedOrder.orderNumber : ""}
                <img src={upArrowIcon} />
              </div>
            </Dropdown>
          </div>
          <div className="file-list-panel">
            {orderDocuments.map((mapItem, mapIndex) => {
              return (
                <div className="file-list-item">
                  <div className="label">{mapItem.label}</div>
                  <div className="attach-panel">
                    <div className="left-part">
                      <img src={attachIcon} />
                      <span className="attach-link">
                        {mapItem.file ? mapItem.file.name : t("ATTACH_FILES")}
                      </span>
                    </div>
                    <UploadBase
                      onLoading={onOrderFilesLoading.bind(
                        this,
                        mapItem,
                        mapIndex
                      )}
                      onLoaded={onOrderFilesLoaded.bind(
                        this,
                        mapItem,
                        mapIndex
                      )}
                    >
                      <div
                        className={
                          mapItem.file ? "btn-submit-disable" : "btn-submit"
                        }
                      >
                        {t("SUBMIT")}
                      </div>
                    </UploadBase>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
}
