import React, { useEffect, useState } from "react";
import styles from "./CountryModal.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";

import { numberWithCommas } from "../../../core-digital-template/utils.js";
import { useTranslation } from "react-i18next";
import {
  getProductCategoryTypeForSelect,
  getProductConfigForSelect,
  loadProductConfig,
} from "../../../api/product";
import { deepClone } from "../../../utils/utils";
import { Modal } from "antd";
import { getCountryAndCityForSelect } from "../../../api/config";

const CountryModal = ({ isShow, onClose, onChange }) => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [dataList, setDataList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    setConfigData();
  }, []);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocationList(locations);
    setDataList(getCountryAndCityForSelect(locations, currentLanguage));
  };

  const onCountryChange = (event, itemIndex) => {
    let temp = deepClone(dataList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[itemIndex].selected = true;
    setDataList(temp);
    onChange && onChange(temp[itemIndex]);
    onClose && onClose();
  };

  return (
    <Modal visible={isShow} footer={null} onCancel={onClose}>
      <div className={styles.distributionChannelSelectModal}>
        <div className={styles.distributionChannelPanel}>
          {dataList.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  mapItem.selected
                    ? styles.channelItemSelected
                    : styles.channelItem
                }
                onClick={onCountryChange.bind(this, mapItem, mapIndex)}
              >
                {mapItem.label}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default CountryModal;
