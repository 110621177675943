import { Input } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style/inputNormal.less";

export default function InputNormal(props) {
  const { t } = useTranslation();
  const { label, value, index, onChange, disabled, placeholder } = props;
  const [val, setVal] = useState();
  // console.log('------input normal:', label, value, index);

  useEffect(() => {
    setVal(value);
  }, [value]);
  const onInputChange = (e) => {
    // console.log('------input normal input change:', e.target.value);
    onChange(e.target.value, index);
    setVal(e.target.value);
  };

  return (
    <>
      <div className="input-title">
        <span
          style={{
            color: "#333333",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {t(label)}
          {t(placeholder)}
        </span>
      </div>
      <div
        style={{
          marginTop: "8px",
        }}
      >
        <Input
          bordered={false}
          maxLength={200}
          placeholder={t(placeholder ? placeholder : label)}
          value={val}
          disabled={disabled}
          onChange={onInputChange.bind(this)}
          autocomplete="one-time-code"
        />
      </div>
    </>
  );
}
