import {
  actionRequest,
  formatActionResponse,
  formatActionResponseArray,
  formatResponse,
  formatResponseArray,
} from "./api";
import { RequestType } from "../constants/requestType.enum";
import { IRegisterUser } from "../interfaces/IRegisterUser";
import apiPath from "./apipath";
import { requestGetUrlFormat } from "./common";

/***
 * check user exists
 */
export async function userExists(pms: any) {
  return actionRequest(RequestType.GET, apiPath.userExists, pms);
}

/***
 * get user page
 */
export async function userPage(pms: any) {
  return formatActionResponseArray(RequestType.GET, apiPath.userPage, pms);
}

export async function userSupplierList(pms: any) {
  return formatActionResponseArray(
    RequestType.GET,
    apiPath.userSupplierList,
    pms
  );
}

export async function adminSupplierList(pms:any) {
  return formatActionResponseArray(
    RequestType.GET,
    apiPath.adminSupplierList,
    pms
  );
}

export async function adminBuyerList(pms:any) {
  return formatActionResponseArray(
    RequestType.GET,
    apiPath.adminBuyerList,
    pms
  );
}

export async function buyerSearch(pms:any) {
  return formatActionResponse(RequestType.POST, apiPath.buyerSearch, pms);
}

export async function getCompanyMainUser(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.userMain, pms);
}
/**
 *
 * @param userid
 */
export async function userDetail(userid: string) {
  let data = await actionRequest(RequestType.GET, apiPath.users + "/" + userid);
  return formatResponse(data);
}

/**
 *
 * @param userid
 */
export async function userNamesById(pms: any) {
  let data = await actionRequest(RequestType.GET, apiPath.userNamesById, pms);
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export function userUpdate(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.userUpdate, pms);
}

/**
 *
 * @param pms
 */
export async function createUser(pms: Partial<IRegisterUser>) {
  return await actionRequest(RequestType.POST, apiPath.users, pms);
}

/**
 *
 * @param pms
 */
export async function createGuest(pms: any) {
  return await actionRequest(RequestType.POST, apiPath.createGuest, pms);
}

/**
 *
 * @param pms
 */
export async function convertGuestToUser(pms: any) {
  return await actionRequest(RequestType.POST, apiPath.convertGuestToUser, pms);
}

/**
 *
 * @param pms
 */
export async function userResetPassword(pms: any) {
  return await actionRequest(RequestType.POST, apiPath.userResetPassword, pms);
}

/**
 *
 * @param pms
 */
export function verifyAdd(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.verifyAdd, pms);
}

/**
 *
 * @param pms
 */
export function verifyUpdate(pms: any) {
  return actionRequest(RequestType.POST, apiPath.verifyUpdate, pms);
}

export function updateCompanyDesc(pms: any) {
  return actionRequest(RequestType.POST, apiPath.updateCompanyDesc, pms);
}

export function userSearch(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.userSearch, pms);
}

/**
 *
 * @param userid
 */
export function verifyFind(userid: string) {
  return actionRequest(RequestType.GET, apiPath.verifyFind + userid);
}

export function getCompanyVerifyStatus(currentUser: any) {
  const currentCompany =
    currentUser && currentUser.companyInfo && currentUser.companyInfo.length > 0
      ? currentUser.companyInfo[0]
      : null;
  const companyVerifyStatus = currentCompany ? currentCompany.status : 0;
  return companyVerifyStatus;
}

/**
 *
 * @param pms
 */
export async function getRecipients(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.recipients, pms)
  );
  return formatResponseArray(data);
}

/**
 *
 * @param pms
 */
export async function recipientCreate(pms: any) {
  let data = await actionRequest(
    RequestType.POST,
    apiPath.recipientCreate,
    pms
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function recipientUpdate(pms: any) {
  let data = await actionRequest(
    RequestType.POST,
    apiPath.recipientUpdate,
    pms
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function recipientRemove(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.recipientRemove, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function getProductAwards(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.awards, pms);
}

/**
 *
 * @param pms
 */
export async function awardCreate(pms: any) {
  let data = await actionRequest(RequestType.POST, apiPath.awardCreate, pms);
  return formatResponse(data);
}

export async function awardUpdate(pms: any) {
  let data = await actionRequest(RequestType.POST, apiPath.awardUpdate, pms);
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function awardRemove(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.awardRemove, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function highRatings(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.highRatings, pms)
  );
  return formatResponseArray(data);
}

/**
 *
 * @param pms
 */
export async function highRatingCreate(pms: any) {
  let data = await actionRequest(
    RequestType.POST,
    apiPath.highRatingCreate,
    pms
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function highRatingRemove(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.highRatingRemove, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function notes(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.notes, pms)
  );
  return formatResponseArray(data);
}

/**
 *
 * @param pms
 */
export async function noteCreate(pms: any) {
  let data = await actionRequest(RequestType.POST, apiPath.noteCreate, pms);
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function noteRemove(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.noteRemove, pms)
  );
  return formatResponse(data);
}
/**
 *
 * @param pms
 */
export async function getDefaultRecipient(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    apiPath.recipientDefault,
    pms
  );
  return formatResponse(data);
}

export async function setDefaultRecipient(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    apiPath.recipientSetDefault,
    pms
  );
  return formatResponse(data);
}

/**
 *
 */
export async function imGetToken() {
  let data = await actionRequest(RequestType.GET, apiPath.imGetToken);
  return formatResponse(data);
}

/**
 *
 */
export async function emailVerify(pms: any) {
  const tokenId = pms.emailVerificationToken;
  let data = await actionRequest(
    RequestType.GET,
    apiPath.emailVerify + "/" + tokenId
  );
  return data;
}

/**
 *
 */
export async function phoneVerify(pms: any) {
  const tokenId = pms.phoneVerificationToken;
  let data = await actionRequest(
    RequestType.GET,
    apiPath.phoneVerify + "/" + tokenId
  );
  return data;
}

export async function closeAccount() {
  let data = await actionRequest(RequestType.GET, apiPath.closeAccount);
  return formatResponse(data);
}

export async function sendEmail(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.sendEmail, pms);
}

export async function createProfileClick(pms: any) {
  return formatActionResponse(
    RequestType.POST,
    apiPath.profileClickCreate,
    pms
  );
}

/**
 *
 * @param pms
 */
export async function fetchProfileClicks(pms: any) {
  return await formatActionResponse(
    RequestType.GET,
    apiPath.profileClicks,
    pms
  );
}
