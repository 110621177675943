import React, { useEffect, useState } from "react";
import styles from "./InstantMessaging.module.sass";
import cn from "classnames";
// import Users from "./Users";
// import Messages from "./Messages";
import { getBlockList, pageFriends } from "../../api/contact";
import { useUserId } from "../../contexts/UserIdProvider";
import { getGroups } from "../../api/chat";
import { useIMContext } from "../../contexts/IMProvider";
import useIM from "../../hooks/useIM";
import { deepClone } from "../../utils/utils";
import {
  AmityUiKitFeed,
  AmityUiKitChat,
  AmityUiKitProvider,
  AmityUiKitSocial,
} from "../../components/amity-uikit";
import { AmityApiKey, localizeImg } from "../../constants/constants";
import AvatarBasic from "../../components/common/avatar/AvatarBasic";
import { PostTargetType, FeedType } from "@amityco/js-sdk";

const navigation = [
  {
    title: "Customers",
    icon: "profile-circle",
  },
  {
    title: "Everyone",
    icon: "lightning",
  },
];

const actions = [
  {
    title: "Mark as read",
    icon: "check",
    action: () => console.log("Mark as read"),
  },
  {
    title: "Delete message",
    icon: "trash",
    action: () => console.log("Delete message"),
  },
];

const parameters = [
  {
    title: "Customer since",
    content: "Sep 2021",
  },
  {
    title: "Purchased",
    content: "21 items",
  },
  {
    title: "Lifetime",
    content: "$1,235.00",
  },
];

const InstantMessaging = () => {
  const apiKey = AmityApiKey;
  const { user } = useUserId();
  const { userId, userInfo } = user;


  const {
    webIM,
    imLoginStatus,
    newMsg,
    sessionMessageList,
    initIMLogin,
    getSessionMessage,
    setChatCallMsg,
  } = useIMContext();
  const {
    messageList,
    newGroup,
    fetchHistoryMessage,
    readChannelMessage,
    sendTextMessage,
    sendCustomMessage,
    createGroup,
    quitGroup,
    dissolveGroup,
    removeGroupMember,
    addGroupMember,
  } = useIM(webIM);

  const [friends, setFriends] = useState([]);
  const [oriFriends, setOriFriends] = useState([]);
  const [activeUser, setActiveUser] = useState({});

  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");

  const [loadContact, setLoadingContact] = useState(false);

  let keyword = "";

  useEffect(() => {
    getFriends();
  }, []);

  useEffect(() => {
    if (webIM) {
      initIMLogin(userInfo);
    }
  }, [webIM, imLoginStatus]);

  useEffect(() => {
    let list = messageList;
    console.log("------messagelist:", messageList, friends);
  }, [messageList]);

  useEffect(() => {
    if (newMsg) {
      let temp = deepClone(friends);
      temp.map((mapItem) => {
        if (mapItem.id == newMsg.from && newMsg.from != activeUser?.id) {
          mapItem.new = true;
        }
      });
      setFriends(temp);
    }
  }, [newMsg]);

  const getFriends = async (e) => {
    setLoadingContact(true);
    if (e) {
      keyword = e.target.value;
    }
    let list = await loadContacts(keyword);
    console.log("getFriends:", list);
    if (list && list.length > 0) {
      setActiveUser(list[0]);
      await concatGroups(list);
    }
    setLoadingContact(false);
  };

  const concatGroups = async (list) => {
    let groupList = await loadGroups();
    list = list.concat(groupList);
    setFriends(list);
    setOriFriends(list);
  };

  const loadContacts = async (searchVal) => {
    searchVal = searchVal ? searchVal : "";
    let pms = { page: 0, per_page: 20, keyword: searchVal };
    let result = await pageFriends(userId, pms);
    if (result) {
      let pms = { userId };
      let blackList = await getBlockList(pms);
      result = result.filter((item) => {
        return blackList.filter((b) => b.targetId === item.id).length === 0;
      });
    }
    return result;
  };

  const loadGroups = async () => {
    let pms = { coId: userInfo.companyId, memberId: userId };
    let result = await getGroups(pms);
    if (result) {
      result = result.filter((group) => (group.imGroupId ? true : false));
      result = result.map((item) => {
        return formatGroupToFriend(item);
      });
    }
    return result;
  };

  const formatGroupToFriend = (item) => {
    let { _id, name, imGroupId } = item;
    return { id: imGroupId, serverId: _id, name, groupStatus: true };
  };

  const handleSubmit = (e) => {
    alert();
  };

  const onSwitchUser = (e) => {
    setActiveUser(e);

    let temp = deepClone(friends);
    temp.map((mapItem) => {
      if (mapItem.id === e.id) {
        mapItem.new = false;
      }
    });
    setFriends(temp);
  };

  return (
    <AmityUiKitProvider
          key={userId}
          apiKey={apiKey}
          userId={userId}
          // displayName={user?.userInfo?.nickName}
          // authToken={"1f97f4c66e8b7512d3fa2603349c94299f66c7a6fce2d732527cf113a7853543f79541804bf9c1795405df7be265ebe5578cdcf36d3bfc697152807422"}
        >
          {/* <AmityUiKitSocial /> */}
          <AmityUiKitChat

          />
          {/* <AmityUiKitFeed
            feedType={FeedType.Published}
            targetId={"64259fbf160bdf0c55d6168b"}
            targetType={PostTargetType.CommunityFeed}
          /> */}
        </AmityUiKitProvider>
    // <div className={cn(styles.wrapper, { [styles.active]: visible })}>
    //   <Users
    //     className={styles.users}
    //     navigation={navigation}
    //     items={friends}
    //     setVisible={setVisible}
    //     search={search}
    //     setSearch={setSearch}
    //     onSubmit={() => handleSubmit()}
    //     onSwitchUser={onSwitchUser.bind(this)}
    //     showSkeleton={loadContact}
    //   />
    //   <Messages
    //     className={styles.messages}
    //     visible={visible}
    //     setVisible={setVisible}
    //     actions={actions}
    //     parameters={parameters}
    //     // messages={messages}
    //     activeUser={activeUser}
    //     showSkeleton={loadContact}
    //   />
    // </div>

  );
};

export default InstantMessaging;
