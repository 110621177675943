import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { localizeImg, localizeValue } from "../../../../constants/constants";
import AvatarBasic from "../../../../components/common/avatar/AvatarBasic";
import { useIMContext } from "../../../../contexts/IMProvider";
import { imClose } from "../../../../utils/imHelper";
import { useTranslation } from "react-i18next";
import { Path, PathTools } from "../../../../constants/path";

export default function User({ className }) {
  const { user } = useUserId();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();

  const { imLoginStatus, webIM } = useIMContext();

  const items = [
    // {
    //   menu: [
    //     {
    //       title: t("VIEW_PROFILE"),
    //       url: PathTools.joinPath(
    //         Path.companyDetail,
    //         localizeValue(
    //           i18n.language,
    //           user?.userInfo?.companyDesc?.companyName
    //         )
    //       ),
    //       params: { id: user.userId },
    //     },
    //     {
    //       title: t("EDIT_PROFILE"),
    //       url: PathTools.joinPath(
    //         Path.companyInformation,
    //         localizeValue(
    //           i18n.language,
    //           user?.userInfo?.companyDesc?.companyName
    //         )
    //       ),
    //       params: {
    //         companyId:
    //           user?.userInfo?.companyDesc.id || user?.userInfo?.companyDesc._id,
    //       },
    //     },
    //   ],
    // },
    // {
    //   menu: [
    //     {
    //       title: "Analytics",
    //       icon: "bar-chart",
    //       url: "/customers/overview",
    //     },
    //     {
    //       title: "Affiliate center",
    //       icon: "ticket",
    //       url: "/affiliate-center",
    //     },
    //     {
    //       title: "Explore creators",
    //       icon: "grid",
    //       url: "/explore-creators",
    //     },
    //   ],
    // },
    // {
    //   menu: [
    //     {
    //       title: "Upgrade to Pro",
    //       icon: "leaderboard",
    //       color: true,
    //       url: "/upgrade-to-pro",
    //     },
    //   ],
    // },
    {
      menu: [
        // {
        //   title: t("ACCOUNT_SETTINGS"),
        //   url: PathTools.joinPath(
        //     Path.settingsSecurity,
        //     localizeValue(
        //       i18n.language,
        //       user?.userInfo?.companyDesc?.companyName
        //     )
        //   ),
        // },
        {
          title: t("LOGOUT"),
          func: logOut,
        },
      ],
    },
  ];

  function logOut() {
    if (imLoginStatus) {
      imClose(webIM);
    }
    localStorage.clear();
    history.push("/login");
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.user, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          {/* <img src={localizeImg(user?.userInfo?.avatar)} alt="Avatar" /> */}
          <AvatarBasic
            picture={
              Array.isArray(user?.userInfo?.companyDesc?.avatar)
                ? localizeImg(user?.userInfo?.companyDesc?.avatar[0])
                : localizeImg(user?.userInfo?.companyDesc?.avatar)
            }
            size={49}
          />
        </button>
        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.color]: x.color,
                      [styles.active]: pathname === x.url,
                    })}
                    to={{ pathname: x.url, state: x.params }}
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size="24" />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={() => (x.func ? x.func() : setVisible(false))}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
}
