import React, { useEffect, useState } from "react";
import styles from "./CategoryModal.module.sass";
import { useTranslation } from "react-i18next";
import {
  getProductCategoryTypeForSelect,
  getProductConfigForSelect,
  loadProductConfig,
} from "../../../api/product";
import { deepClone } from "../../../utils/utils";
import { Modal } from "antd";

const CategoryModal = ({ isShow, onClose, onChange }) => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [categoryList, setCategoryList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    setConfigData();
  }, []);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { types, categories } = configData;
    let tempCategoryList = getProductCategoryTypeForSelect(
      categories,
      types,
      currentLanguage
    );
    if (tempCategoryList && tempCategoryList.length > 0) {
      tempCategoryList[0].selected = true;
    }
    setCategoryList(tempCategoryList);
    setTypeList(getProductConfigForSelect(types, currentLanguage));
  };

  const onCategorykNavigatorClick = (index) => {
    let temp = deepClone(categoryList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setCategoryList(temp);

    let tempTypeList = deepClone(typeList);
    tempTypeList = temp[index].types;
    setTypeList(tempTypeList);
  };

  const onTypeChange = (item, index) => {
    // setType(item.value);
    // setTypeName(item.label);
    // setCategory(null);
    // setWineSelectVisible(false);

    let temp = deepClone(typeList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setTypeList(temp);

    onChange && onChange(temp[index]);
    onClose && onClose();
  };

  return (
    <Modal visible={isShow} footer={null} onCancel={onClose} centered={true}>
      <div className={styles.distributionChannelSelectModal}>
        <div>
          <div className={styles.navigatorPanel}>
            {categoryList.map((mapItem, mapIndex) => {
              return (
                <div
                  onClick={onCategorykNavigatorClick.bind(this, mapIndex)}
                  className={
                    mapItem.selected
                      ? styles.navigatorItemSelected
                      : styles.navigatorItem
                  }
                >
                  {mapItem.label}
                </div>
              );
            })}
          </div>
          <div
            className={styles.distributionChannelPanel}
            style={{ marginTop: "20px" }}
          >
            {typeList.map((mapItem, mapIndex) => {
              return (
                <div
                  className={
                    !mapItem.selected
                      ? styles.channelItem
                      : styles.channelItemSelected
                  }
                  onClick={onTypeChange.bind(this, mapItem, mapIndex)}
                >
                  {mapItem.label}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CategoryModal;
