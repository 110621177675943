import { RequestType } from "../constants/requestType.enum";
import { actionRequest, formatResponse } from "./api";
import apiPath from "./apipath";
import { requestGetUrlFormat } from "./common";

/**
 *
 * @param pms
 */
export function userAdd(pms: any) {
  return actionRequest(RequestType.POST, apiPath.users, pms);
}

/**
 *
 * @param pms
 */
export async function login(pms: any) {
  let data = await actionRequest(RequestType.POST, apiPath.login, pms);
  return formatResponse(data);
}

export async function wxLogin(pms: any) {
  let data = await actionRequest(RequestType.GET, apiPath.wxLogin, pms);
  return formatResponse(data);
}

export async function getWxAccessToken(pms: any) {
  let data = await actionRequest(RequestType.GET, apiPath.wxAccessToken, pms);
  return formatResponse(data);
}

export async function getWxUserInfo(pms: any) {
  let data = await actionRequest(RequestType.GET, apiPath.wxUserInfo, pms);
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function loginByPhone(pms: any) {
  return await actionRequest(RequestType.POST, apiPath.login, pms);
}

/**
 *
 * @param pms
 */
export async function sendSms(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.sendSms, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export function getEmailCapture(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.getEmailCapture, pms)
  );
}

/**
 *
 * @param pms
 */
export function sendResetPasswordEmail(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.sendResetPasswordEmail, pms)
  );
}

/**
 *
 * @param pms
 */
export function resetPasswordByEmail(pms: any) {
  return actionRequest(RequestType.POST, apiPath.resetPasswordByEmail, pms);
}

/**
 *
 * @param pms
 */
export function resetPwd(pms: any) {
  return actionRequest(RequestType.POST, apiPath.resetPwd, pms);
}

/**
 *
 * @param pms
 */
export function passwordSetting(pms: any) {
  return actionRequest(RequestType.POST, apiPath.passwordSetting, pms);
}
