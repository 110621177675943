import React from "react";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { useNavigation } from "../../../social/providers/NavigationProvider";
import { useHistory } from "react-router";
import { Path, PathTools } from "../../../../../constants/path";
import { userDetail } from "../../../../../api/user";
import { localizeValue } from "../../../../../constants/constants";
import { useTranslation } from "react-i18next";

const Highlighted = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const MentionHighlightTag = ({ children, mentionees, highlightIndex }) => {
  const { onClickUser } = useNavigation();
  const { i18n } = useTranslation();

  const history = useHistory();

  const handleClickUser = async (mentioneeId) => {
    let result = await userDetail(mentioneeId);
    history.push(
      PathTools.joinPath(
        Path.companyDetail,
        localizeValue(i18n.language, result?.companyDesc?.companyName)
      ),
      {
        id: mentioneeId,
      }
    );
  };

  if (!isEmpty(mentionees)) {
    const { userId: mentioneeId } = mentionees[highlightIndex];

    return (
      <Highlighted
        data-qa-anchor="mention-hilight-tag"
        onClick={() => handleClickUser(mentioneeId)}
      >
        {children}
      </Highlighted>
    );
  }

  return children;
};

export default MentionHighlightTag;
