import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { BackTop, Layout } from "antd";
import { authValidate } from "../../api/common";
import { logUserAction } from "../../action-loggers/action-loggers";
import { useUserId } from "../../contexts/UserIdProvider";
import "./style/baseprivateroute.less";
import WebsitePage from "../../core-digital-template/components/WebsitePage";

export default function WebsiteRoute({
  component: Component,
  pageTitle,
  ...rest
}) {
  const { user } = useUserId();
  const { userId } = user;

  useEffect(() => {
    if (userId) {
      logUserAction(userId, rest.path, rest?.computedMatch?.params?.id);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <WebsitePage title={pageTitle}>
            <Component {...props} />
          </WebsitePage>
        );
      }}
    />
  );
}
