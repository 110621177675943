import React, { useEffect, useState } from "react";
import { Button, Checkbox, List } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../../contexts/UserIdProvider";
import { deepClone } from "../../../utils/utils";
import { pageFriends } from "../../../api/contact";
import { useTranslation } from "react-i18next";
import PageTitle from "../../common/title/PageTitle";
import "./style/selectFriend.less";
import "../../shared/style/contactItem.less";
import { localizeImg } from "../../../constants/constants";
import AvatarBasic from "../../common/avatar/AvatarBasic";

export default function SelectFriendMultiple(props) {
  const { onSelected } = props;
  let { friends, } = props;
  const { user } = useUserId();
  const { userId } = user;
  const [friendList, setFriendList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    loadData();
  }, [pageIndex]);

  const loadData = async () => {
    setLoading(true);
    let pms = { page: pageIndex, per_page: pageSize, keyword: "" };
    let result = await pageFriends(userId, pms);
    if (result && result.length > 0) {
      setHasMore(true);
      if (pageIndex > 0) {
        let _list = deepClone(friendList);
        result = _list.concat(result);
      }
      if (result && result.length > 0 && friends && friends.length > 0) {
        result = result.map(item => {
          if (friends.indexOf(item._id) > -1) {
            item.checked = true;
          }
          return item;
        });
        friends = null;
      }
      setFriendList(result);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  const onSelectFriend = (index) => {
    let list = deepClone(friendList);
    list[index].checked = list[index].checked === true ? false : true;
    setFriendList(list);
  };

  const onConfirm = () => {
    let list = friendList.filter(item => item.checked === true).map(item => item._id);
    onSelected && onSelected(list);
  };

  return (
    <div className="select-friend-container">
      <PageTitle title={t("MY_CONTACT")} />
      <InfiniteScroll
        className="scroll-content"
        height={500}
        dataLength={friendList.length}
        next={handleInfiniteOnLoad.bind(this)}
        hasMore={hasMore}
      >
        <List
          itemLayout="vertical"
          size="large"
          dataSource={friendList}
          loading={loading}
          renderItem={(item, index) => (
            <div className="contact-item" key={index}>
              <AvatarBasic
                cname="avatar"
                picture={localizeImg(item.picture)}
                displayName={item.name}
              ></AvatarBasic>
              <div className="content">
                <div className="content-name">
                  <div className="name">{item.name}</div>
                  <div className="desc">{item.signature}</div>
                </div>
                <div className="content-action">
                  <Checkbox checked={item.checked} onChange={onSelectFriend.bind(this, index)} />
                </div>
              </div>
            </div>
          )}
        >
        </List>
      </InfiniteScroll>
      {friendList.length > 0 &&
        <div className="button-pannel">
          <Button onClick={onConfirm.bind(this)}>{t('CONFIRM')}</Button>
        </div>
      }
    </div>
  );
}
