import { Upload, message } from "antd";
import { useUserId } from "../../../contexts/UserIdProvider";
import { getUploadFileAction, getFileType } from "../../../api/common";
import { getCacheTokens } from "../../../utils/cache";
import { getBase64 } from "../../../utils/utils";

export default function UploadBasic(props) {
  const { type, children, onLoading, onLoaded } = props;
  const { user } = useUserId();
  const action = getUploadFileAction(user.userId);
  const token = getCacheTokens();
  const tokenValue = token ? token.value : "";

  const authorization = "Bearer " + tokenValue;
  const layout_upload = {
    name: "file",
    action: action,
    headers: { authorization },
    showUploadList: true,
    maxCount: 1,
    onChange: async (info) => {
      let { file } = info;
      let { percent, status, type, name } = file;
      let fileType = getFileType(type);
      if (status === "uploading") {
        if (percent === 0) {
          let previewImage = await getBase64(file);
          onLoading && onLoading(fileType, previewImage);
        }
      } else if (status === "done" || info.file.status === "removed") {
        let { fileList } = info;
        let result = null;
        if (fileList.length > 0) {
          result = fileList[0].response.result;
        }
        console.log("---upload base loaded:", info, result);
        onLoaded && onLoaded(fileType, result, name);
        //dialog.success(`${info.file.name} file uploaded successfully`);
      } else if (status === "error") {
        console.log("file upload fail");
        //dialog.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
    beforeUpload: (file) => {
      let fileType = getFileType(file.type);
      if (type && fileType !== type) {
        message.error(`file type is wrong.`);
        return Upload.LIST_IGNORE;
      }
      return true;
    },
  };

  return (
    <div style={{ width: "300px" }}>
      <Upload {...layout_upload}>{children}</Upload>
    </div>
  );
}
