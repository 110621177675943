import { actionRequest, formatActionResponse, formatResponse } from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";

/**
 * @description Get the carts
 * @param pms
 */
export async function operationScheduleList(pms: any) {
    return formatActionResponse(RequestType.GET, apiPath.operationScheduleList, pms);
}

// export async function getProductCarts(pms: any) {
//     return formatActionResponse(RequestType.GET, apiPath.cartsByProduct, pms);
// }

/**
 * @description Create a cart
 * @param pms
 */
export async function operationScheduleCreate(pms: any) {
    let data: any = await actionRequest(RequestType.POST, apiPath.operationScheduleCreate, pms);
    return formatResponse(data);
}

export async function operationScheduleCancel(pms: any) {
    let data: any = await actionRequest(RequestType.GET, apiPath.operationScheduleCancel, pms);
    return formatResponse(data);
}

export async function operationScheduleRemove(pms: any) {
    let data: any = await actionRequest(RequestType.GET, apiPath.operationScheduleRemove, pms);
    return formatResponse(data);
}

