import { Avatar, Button } from "antd";
import React, { useEffect, useState } from "react";
import "./style/contactNotify.less";
import { applyFriendHandle } from "../../api/contact";
import { statusCode, FriendApplyStatus } from "../../constants/statusCode.enum";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { localizeImg } from "../../constants/constants";
import { goToProfilePage } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import AvatarBasic from "../common/avatar/AvatarBasic";
import agreeIcon from "../../resource/icons/notify/agree.svg";
import refuseIcon from "../../resource/icons/notify/refuse.svg";
import ignoreIcon from "../../resource/icons/notify/ignore.svg";
import agreeSelectedIcon from "../../resource/icons/notify/agree_selected.svg";
import refuseSelectedIcon from "../../resource/icons/notify/refuse_selected.svg";
import ignoreSelectedIcon from "../../resource/icons/notify/ignore_selected.svg";

export default function ContactNotify(props) {
  const { item, onRefresh } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [userItem, setUserItem] = useState();
  const [userName, setUserName] = useState("");
  const [country, setCountry] = useState("");
  const [picture, setPicture] = useState("");
  const [status, setStatus] = useState(0);
  const [targetId, setTargetId] = useState();
  const [sourceId, setSourceId] = useState();

  useEffect(() => {
    let { userInfo, status, targetId, sourceId } = item;
    setUserItem(userInfo);
    setUserName(userInfo.nickName);
    setPicture(userInfo.picture);
    setCountry(userInfo.country);
    setStatus(status);
    setTargetId(targetId);
    setSourceId(sourceId);
  }, [item]);

  const formatStatus = () => {
    let result = <div className="userinfo-btn-selected"></div>;
    switch (status) {
      case FriendApplyStatus.APPLIED:
        result = (
          <div>
            <img
              src={agreeIcon}
              onClick={onHandleApply.bind(this, FriendApplyStatus.ADDED)}
              className="action-icon"
            />
            <img
              src={refuseIcon}
              onClick={onHandleApply.bind(this, FriendApplyStatus.REFUSED)}
              className="action-icon"
            />
            <img
              src={ignoreIcon}
              onClick={onHandleApply.bind(this, FriendApplyStatus.IGNORE)}
              className="action-icon"
            />
            {/* <Button
              className="userinfo-btn"
              onClick={onHandleApply.bind(this, FriendApplyStatus.ADDED)}
            >
              {t("ACCEPT")}
            </Button>
            <Button
              className="userinfo-btn-selected"
              onClick={onHandleApply.bind(this, FriendApplyStatus.REFUSED)}
            >
              {t("REJECT")}
            </Button>
            <Button
              className="userinfo-btn-selected"
              onClick={onHandleApply.bind(this, FriendApplyStatus.IGNORE)}
            >
              {t("IGNORE")}
            </Button> */}
          </div>
        );
        break;
      case FriendApplyStatus.IGNORE:
        // result = <div className="userinfo-btn-selected">{t("IGNORE")}</div>;
        result=<img src={ignoreSelectedIcon}/>;
        break;
      case FriendApplyStatus.REFUSED:
        // result = <div className="userinfo-btn-selected">{t("REJECT")}</div>;
        result=<img src={refuseSelectedIcon}/>;
        break;
      case FriendApplyStatus.ADDED:
        // result = <div className="userinfo-btn-selected">{t("ADDED")}</div>;
        result=<img src={agreeSelectedIcon}/>;
        break;
      default:
        break;
    }
    return result;
  };

  const onHandleApply = (status) => {
    let pms = { sourceId, targetId, status };
    applyFriendHandle(pms).then((data) => {
      console.log("--------applyFriend:", data);
      if (data && data.statusCode === statusCode.SUCCESS) {
        item.status = status;
        onRefresh && onRefresh();
      } else {
        toast.error(
          t("SYS_ERROR", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
      }
    });
  };

  const onCompanyHome = () => {
    goToProfilePage(userItem, history);
  };

  return (
    <div className="contact-notify">
      <div className="contact-notify-userinfo ">
        <div className="userinfo-left">
          <AvatarBasic
            onClick={onCompanyHome.bind(this)}
            cname="userinfo-avatar cursor-pointer"
            picture={localizeImg(picture)}
            displayName={userName}
            size={40}
          ></AvatarBasic>
          <div className="userinfo-desc">
            <div className="userinfo-name">{userName}</div>
          </div>
        </div>
        {formatStatus()}
      </div>
      {/* <div className="contact-notify-row">
        <span>{t("COUNTRY")} : </span>
        <span className="row-item-title">{t(country.toUpperCase())}</span>
      </div> */}
    </div>
  );
}
