import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  List,
  Menu,
  Modal,
  Spin,
  BackTop,
  Carousel,
  Skeleton,
  Input,
  Popover,
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../../contexts/UserIdProvider";
import FeedListRow from "../FeedListRow";
import { constants, deepClone } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { pageFeeds, recommandTenders } from "../../../api/feed";
import { adminBuyerList, userSearch } from "../../../api/user";
import "./style/discoverWinery.less";
import bannerImage1 from "../../../resource/images/discover/banner_1.jpg";
import bannerImage2 from "../../../resource/images/discover/banner_2.jpg";
import bannerImage3 from "../../../resource/images/discover/banner_3.jpg";
import countryIcon from "../../../resource/icons/discover/country.svg";
import cityIcon from "../../../resource/icons/discover/city.svg";
import channelIcon from "../../../resource/icons/discover/channel.svg";
import searchIcon from "../../../resource/icons/discover/search.svg";
import {
  getCountryForSelect,
  getLocationsByParentId,
} from "../../../api/config";
import { loadProductConfig } from "../../../api/product";
import { Link, useHistory } from "react-router-dom";
import { getEventList } from "../../../api/events";
import EventDetail from "../../event/components/EventDetail";
import { toastInfo } from "../../../api/toast";
import ListBasic from "../../common/list/ListBasic";
import RightSider from "../../shared/righ-sider/RightSider";
import UserTile from "../../../core-digital-template/components/UserTile";

export default function DiscoverWinery() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const { userId } = user;
  const [feedList, setFeedList] = useState([]);
  const [tenderList, setTenderList] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [locationList, setLocationList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [country, setCountry] = useState();
  const [region, setRegion] = useState();
  const [channel, setChannel] = useState();
  const [channelName, setChannelName] = useState(t("DISTRIBUTION_CHANNEL"));
  const [countryName, setCountryName] = useState();
  const [regionName, setRegionName] = useState();
  const [selectedChanels, setSelectedChanels] = useState([]);
  const [discoverStatus, setDiscoverStatus] = useState(false);
  const [selectedChanelsValue, setSelectedChanelsValue] = useState([]);
  const [distributionChannels, setdistributionChannels] = useState([
    { label: "RESTAURANT_AND_BAR", value: 0, selected: false },
    { label: "WHOLE_SALE", value: 0, selected: false },
    { label: "HOTEL", value: 0, selected: false },
    { label: "TOBACO_AND_LIQUOR_STORE", value: 0, selected: false },
    { label: "MULTICHANNEL", value: 0, selected: false },
    { label: "E-COMMERCE", value: 0, selected: false },
    { label: "SUPERMARKET", value: 0, selected: false },
    { label: "CORPORATEBUSINESS", value: 0, selected: false },
  ]);
  const [showDetailVisible, setShowDetailVisible] = useState(false);
  const [showCountrySelectVisible, setShowCountrySelectVisible] =
    useState(false);
  const [showRegionSelectVisible, setShowRegionSelectVisible] = useState(false);
  const [showDistributionChannelsVisible, setShowDistributionChannelsVisible] =
    useState(false);

  const [buyerList, setBuyerList] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    vertical: true,
    pauseOnHover: true,
  };

  useEffect(() => {
    loadTenders();
    loadEvents();
    setConfigData();
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (locationList.total > 0) {
      setCountryList(getCountryForSelect(locationList, currentLanguage,user.userInfo.type));
      if (regionList.length > 0) {
        onLoadRegionList(country);
      }
    }
    if (!country) {
      setCountryName(t("COUNTRY"));
    }
    if (!region) {
      setRegionName(t("CITY"));
    }
  }, [currentLanguage, locationList]);

  useEffect(() => {
    if (pageIndex >= 0) {
      loadBuyerList();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (country) {
      setCountryName(getLocationName(countryList, country));
    }
  }, [countryList]);

  useEffect(() => {
    if (region) {
      setRegionName(getLocationName(regionList, region));
    }
  }, [regionList]);

  useEffect(() => {
    if (country) {
      setCountryName(getLocationName(countryList, country));
      setRegion(null);
    } else {
      setCountryName(t("COUNTRY"));
    }
  }, [country]);

  useEffect(() => {
    if (region) {
      setRegionName(getLocationName(regionList, region));
    } else {
      setRegionName(t("CITY"));
    }
  }, [region]);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocationList(locations);
    setCountryList(getCountryForSelect(locations, currentLanguage,user.userInfo.type));
  };

  const getLocationName = (list, val) => {
    list = list.filter((item) => item.value === val);
    return list.length > 0 ? list[0].label : "";
  };

  const onLoadRegionList = (coutry_val) => {
    if (coutry_val) {
      let result = getLocationsByParentId(
        locationList,
        coutry_val,
        currentLanguage
      );
      if (!result || result.length === 0) {
        result.push({
          value: "",
          label: t("ALL"),
        });
      }
      setRegionList(result);
    } else {
      setRegionList([]);
    }
  };

  const loadBuyerList = async () => {
    let result = await adminBuyerList();
    console.log("--------buyer list:", result);
    if (result && result.length > 0) {
      result.push(deepClone(result[0]));
      setBuyerList(result);
    }
  };

  const loadTenders = async () => {
    let pms = { page: 0, per_page: 20, status: 0 };
    let data = await recommandTenders(pms);
    console.log("--------tenders:", data, pms);
    if (data) {
      let { result } = data;
      console.log("--------tenders list:", result, pageIndex);
      setTenderList(result);
    }
  };

  const loadEvents = async () => {
    let pms = { page: pageIndex, per_page: pageSize };
    let data = await getEventList(pms);
    if (data) {
      let { result } = data;
      if (result && result.length > 0) {
        setEventList(result);
      }
    }
  };

  const searchImporter = async (pIndex) => {
    setLoading(true);
    pIndex = pIndex === 0 ? 0 : pageIndex;
    let pms = {
      country,
      region,
      distributionChannels: selectedChanels,
      page: pIndex,
      per_page: pageSize,
    };
    let result = await userSearch(pms);
    console.log("----------Search importer result:", result, pms);
    if (result && result.total > 0) {
      let list = result.result;
      if (pIndex > 0) {
        let tmplist = deepClone(feedList);
        list = tmplist.concat(tmplist);
      }
      console.log("--------------list length:", list.length);
      setFeedList(list);
    } else {
      setHasMore(false);
      if (pIndex === 0) {
        setFeedList([]);
      }
    }
    setLoading(false);
  };

  const onResetDiscover = () => {
    setCountry();
    setRegion();
    let list = deepClone(distributionChannels);
    list = list.map((item) => {
      item.selected = false;
      return item;
    });
    setdistributionChannels(list);
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  const pageRefresh = () => {
    if (pageIndex === 0) {
      setPageIndex(-1);
    }
    setPageIndex(0);
  };

  const onCountryChange = (event, itemIndex) => {
    const { value } = event;
    setCountry(value);
    onLoadRegionList(value);

    let temp = deepClone(countryList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[itemIndex].selected = true;
    setCountryList(temp);
  };

  const onRegionChange = (event, index) => {
    const { value } = event;
    setRegion(value);

    let temp = deepClone(regionList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setRegionList(temp);
  };

  const getDropDownMenuItem = (val, item) => {
    return (
      <div className="drop-item">
        <span className="drop-item-text">{item.label}</span>
      </div>
    );
  };

  const onChanelSelect = (index) => {
    let list = deepClone(distributionChannels);
    list[index].selected = !list[index].selected;
    setdistributionChannels(list);

    let temp = [];
    let tempValue = [];
    list.map((item) => {
      if (item.selected) {
        temp.push(item.label);
        tempValue.push(t(item.label));
      }
    });
    setSelectedChanels(temp);
    setChannelName(
      tempValue.length > 0 ? tempValue.join(",") : t("DISTRIBUTION_CHANNEL")
    );
  };

  const onDiscoverPress = () => {
    setSelectedChanelsValue(selectedChanels);
    searchImporter(0);
    setDiscoverStatus(true);
  };

  /**
   * Currently hard code & load beijing data.
   */
  const onBannerClick = () => {
    onCountryChange({
      key: constants.chinaCountryId,
    });
    setTimeout(() => {
      onRegionChange({
        key: "621cb23e9dbfcd243de48c8a",
      });
    }, 500);

    onDiscoverPress();
  };

  const onRegionMenuClick = () => {
    if (regionList.length === 0) {
      toastInfo(t("PLEASE_SELECT_COUNTRY"));
    } else {
      setShowRegionSelectVisible(true);
    }
  };

  const getSearchResultNameWidth = () => {
    if (i18n.language == "en") {
      return "50%";
    }
    if (i18n.language == "fr") {
      return "80%";
    }
    return "12%";
  };

  const menu_country_select = () => {
    return (
      <div className="distribution-channel-select-modal">
        <div className="distribution-channel-panel">
          {countryList.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  "channel-item " +
                  (mapItem.selected ? "channel-item-selected" : "")
                }
                onClick={onCountryChange.bind(this, mapItem, mapIndex)}
              >
                {getDropDownMenuItem(country, mapItem)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const menu_region_select = () => {
    return (
      <div className="distribution-channel-select-modal">
        <div className="distribution-channel-panel">
          {regionList.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  "channel-item " +
                  (mapItem.selected ? "channel-item-selected" : "")
                }
                onClick={onRegionChange.bind(this, mapItem, mapIndex)}
              >
                {getDropDownMenuItem(country, mapItem)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const menu_distribution_channel_select = () => {
    return (
      <div className="distribution-channel-select-modal">
        <div className="distribution-channel-panel">
          {distributionChannels.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  "channel-item " +
                  (mapItem.selected ? "channel-item-selected" : "")
                }
                onClick={onChanelSelect.bind(this, mapIndex)}
              >
                {t(mapItem.label)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="discover-page">
      <div
        className="discover-content"
        style={{
          overflowY: "auto",
          height: window.innerHeight - 60 + "px",
        }}
      >
        <div className="banner-container">
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              width: "100%",
            }}
          >
            <div className="filter-container">
              <div className="filter-tabs">
                <Dropdown overlay={menu_country_select} trigger={["click"]}>
                  <div
                    className="filter-tab-item"
                    style={{
                      width: "165px",
                    }}
                  >
                    <img
                      alt={t("LANGUAGE_ICON")}
                      src={countryIcon}
                      className="select-icon"
                    />
                    <span className={"select-title "}>{countryName}</span>
                  </div>
                </Dropdown>
                <Divider type="vertical" style={{ height: "auto" }} />
                <Dropdown overlay={menu_region_select} trigger={["click"]}>
                  <div
                    className="filter-tab-item"
                    // onClick={onRegionMenuClick.bind(this)}
                    style={{
                      width: "152px",
                    }}
                  >
                    <img
                      alt={t("LANGUAGE_ICON")}
                      src={cityIcon}
                      className="select-icon"
                    />
                    <span className={"select-title "}>{regionName}</span>
                  </div>
                </Dropdown>
                <Divider type="vertical" style={{ height: "auto" }} />
                <Popover
                  placement="bottom"
                  title={null}
                  content={menu_distribution_channel_select}
                  trigger="click"
                  className="row-panel"
                >
                  <div
                    className="filter-tab-item"
                    // onClick={() => setShowDistributionChannelsVisible(true)}
                    style={{
                      width: "272px",
                    }}
                  >
                    <img
                      alt={t("LANGUAGE_ICON")}
                      src={channelIcon}
                      className="select-icon"
                    />
                    <span className={"select-title "}>{channelName}</span>
                  </div>
                </Popover>
                <Divider type="vertical" style={{ height: "auto" }} />
                <div className="filter-tab-item">
                  <Input
                    className="search-keyword-input"
                    placeholder={t("SEARCH_BY_NAME")}
                    // value={title}
                    // onChange={(val) => setTitle(val.target.value)}
                  />
                  <img
                    src={searchIcon}
                    onClick={onDiscoverPress.bind(this)}
                    style={{ marginRight: "10px" }}
                  />
                </div>
              </div>
            </div>
            <div
              className="content-right-filter-title"
              style={{
                position: "relative",
                zIndex: "99",
                height: "54px",
                marginTop: "56px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {(selectedChanelsValue.length > 0 || country || region) &&
                discoverStatus && (
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 80px",
                      boxShadow: "0 14px 80px rgba(34, 35, 58, 0.2)",
                      borderRadius: "0px 0px 10px 10px",
                      color: "#FFFFFF",
                    }}
                  >
                    <span
                      style={{
                        marginRight: "20px",
                        fontSize: "18px",
                        fontWeight: 400,
                        width: getSearchResultNameWidth(),
                      }}
                    >
                      {t("SEARCHING_RESULT_FROM")} :{" "}
                    </span>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      {selectedChanelsValue.length > 0 &&
                        selectedChanelsValue.map((item, index) => (
                          <span
                            key={index}
                            style={{
                              marginRight: "20px",
                            }}
                          >
                            {t(item)}
                          </span>
                        ))}
                      {country && (
                        <span
                          style={{
                            marginRight: "20px",
                          }}
                        >
                          {countryName}
                        </span>
                      )}
                      {region && (
                        <span
                          style={{
                            marginRight: "20px",
                          }}
                        >
                          {regionName}
                        </span>
                      )}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <Carousel className="banner-pannel" autoplay dots={false}>
            <div onClick={onBannerClick.bind(this)}>
              <img
                alt={t("BANNER_IMAGE")}
                src={bannerImage1}
                className="banner-image-item"
              />
              <div className="banner_item_title">
                {t("BEIJING")} {t("CHINA")}
              </div>
            </div>
            <div onClick={onBannerClick.bind(this)}>
              <img
                alt={t("BANNER_IMAGE")}
                src={bannerImage2}
                className="banner-image-item"
              />
              <div className="banner_item_title">
                {t("BEIJING")} {t("CHINA")}
              </div>
            </div>
            <div onClick={onBannerClick.bind(this)}>
              <img
                alt={t("BANNER_IMAGE")}
                src={bannerImage3}
                className="banner-image-item"
              />
              <div className="banner_item_title">
                {t("BEIJING")} {t("CHINA")}
              </div>
            </div>
          </Carousel>
        </div>
        <div className="content-container">
          <div className="content-right-container">
            {buyerList.map((buyerItem) => {
              return <UserTile item={buyerItem} />;
            })}
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={showDetailVisible}
        footer={null}
        className={"event-detail-modal"}
        onCancel={() => setShowDetailVisible(false)}
      >
        <EventDetail data={eventList[0]} showDetail={true} />
      </Modal>
      {/* <Modal
        destroyOnClose={true}
        title={null}
        visible={showRegionSelectVisible}
        footer={null}
        closable={false}
        className={"distribution-channel-select-modal"}
        onCancel={() => setShowRegionSelectVisible(false)}
      >
        <div className="distribution-channel-panel">
          {regionList.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  "channel-item " +
                  (mapItem.selected ? "channel-item-selected" : "")
                }
                onClick={onRegionChange.bind(this, mapItem, mapIndex)}
              >
                {getDropDownMenuItem(country, mapItem)}
              </div>
            );
          })}
        </div>
      </Modal> */}
      {/* <Modal
        destroyOnClose={true}
        title={null}
        visible={showCountrySelectVisible}
        footer={null}
        closable={false}
        className={"distribution-channel-select-modal"}
        onCancel={() => setShowCountrySelectVisible(false)}
      >
        <div className="distribution-channel-panel">
          {countryList.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  "channel-item " +
                  (mapItem.selected ? "channel-item-selected" : "")
                }
                onClick={onCountryChange.bind(this, mapItem, mapIndex)}
              >
                {getDropDownMenuItem(country, mapItem)}
              </div>
            );
          })}
        </div>
      </Modal> */}
      {/* <Modal
        destroyOnClose={true}
        title={null}
        visible={showDistributionChannelsVisible}
        footer={null}
        closable={false}
        className={"distribution-channel-select-modal"}
        onCancel={() => setShowDistributionChannelsVisible(false)}
      >
        <div className="distribution-channel-panel">
          {distributionChannels.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  "channel-item " +
                  (mapItem.selected ? "channel-item-selected" : "")
                }
                onClick={onChanelSelect.bind(this, mapIndex)}
              >
                {t(mapItem.label)}
              </div>
            );
          })}
        </div>
      </Modal> */}
    </div>
  );
}
