import cn from "classnames";
import styles from "./ProductFiles.module.sass";
import Card from "../../../../../core-digital-template/components/Card";
import { useTranslation } from "react-i18next";
import FileUploader from "../../../../../core-digital-template/components/FileUploader";
import ImageUploader from "../../../../../core-digital-template/components/ImageUploader";

const ProductFiles = ({ className, onChange, dataSource }) => {
  const { t } = useTranslation();

  const onFilesChange = (fields) => {
    onChange && onChange(fields);
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title={t("PRODUCT_DETAIL")}
      classTitle="title-blue"
    >
      <div className={styles.files}>
        <ImageUploader
          className={styles.field}
          title={t("CLICK_OR_DROP_FILE")}
          label=""
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          onChange={onFilesChange.bind(this)}
          dataSource={dataSource}
        />
      </div>
    </Card>
  );
};

export default ProductFiles;
