import { ConnectionStatus } from "@amityco/js-sdk";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  VinbipAdminService,
  localizeValue,
} from "../../../../constants/constants";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../../contexts/UserIdProvider";

const SDKContext = React.createContext({});

export const useSDK = () => useContext(SDKContext);

export const SDKProvider = ({ children, client }) => {
  const [connectionStatus, setConnectionStatus] = useState(
    ConnectionStatus.NotConnected
  );

  const { i18n } = useTranslation();
  const { user } = useUserId();

  const [userId, setUserId] = useState(user.userId);

  useEffect(() => {
    if (client) {
      setConnectionStatus(client.connectionStatus);

      client.on("connectionStatusChanged", ({ newValue }) => {
        setConnectionStatus(newValue);

        let displayName =
          VinbipAdminService[userId] ||
          localizeValue(i18n.language, user?.userInfo?.companyDesc.companyName);
        client.updateCurrentUser({
          displayName: displayName,
        });
      });
    }
  }, [client]);

  const value = useMemo(
    () => ({
      client,
      connected: connectionStatus === ConnectionStatus.Connected,
      currentUserId: client?.currentUser?.model?.userId,
      userRoles: client?.currentUser?.model?.roles,
      networkSettings: client?.networkSettings ?? {},
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      client,
      connectionStatus,
      client?.currentUser?.model?.userId,
      client?.currentUser?.model?.roles,
      client?.networkSettings,
    ]
  );

  return <SDKContext.Provider value={value}>{children}</SDKContext.Provider>;
};

const withSDK = (Component) => (props) => {
  const sdkData = useSDK();

  return <Component {...sdkData} {...props} />;
};

export default withSDK;
