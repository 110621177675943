import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Input, Row, Select } from "antd";
import "./style/bankInfoSetting.less";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { Link, useHistory } from "react-router-dom";
import {
  companyBankInfo,
  companyBankInfoSave,
  getCompanyDescFromUserType,
} from "../../../api/company";
import { statusCode } from "../../../constants/statusCode.enum";
import { toastInfo } from "../../../api/toast";
import { companyBankInfoFields } from "../../../constants/usersType.enum";
import FormNormalW from "../../common/form/FormNormalW";
import { deepClone } from "../../../utils/utils";

export default function BankInfoSetting() {
  const history = useHistory();
  const { user } = useUserId();

  const { t, i18n } = useTranslation();
  const [infoFormData, setInfoFormData] = useState();
  const [fieldInfos, setFieldInfos] = useState([]);

  const [fields, setFields] = useState(companyBankInfoFields);
  const [editModal, setEditModal] = useState(true);

  //初始化编辑状态
  useEffect(() => {
    initEditStatus(user.userInfo.companyId);
  }, []);

  const initEditStatus = async (coId) => {
    let pms = { coId };

    let bankInfo = await companyBankInfo(pms);

    console.log("bankInfo:", bankInfo);

    if (bankInfo && bankInfo.statusCode == statusCode.SUCCESS) {
      let tempSource = deepClone(bankInfo.result);
      setFormValue(tempSource);

      setEditModal(false);

      let result = bankInfo.result;
      let tempFields = deepClone(fields);
      tempFields.map((fieldItem) => {
        for (const key in result) {
          if (fieldItem.name == key) {
            fieldItem.value = result[key];
          }
        }
      });
      setFields(tempFields);
    }
  };

  const onSubmitCompanyBankInfo = async () => {
    let pms = { ...infoFormData };

    pms.coId = user.userInfo.companyId;
    console.log("--------------onSubmitCompanyBankInfo:", pms);
    let result = await companyBankInfoSave(pms);

    if (result && result.statusCode == statusCode.SUCCESS) {
      toastInfo(t("SAVE_SUCCESS"));
      setEditModal(false);

      let tempPms = deepClone(pms);
      setFormValue(pms);
    } else {
      toastInfo(t("SAVE_FAILED"));
    }
  };

  const onFieldsChange = (vals) => {
    console.log("onBankInfoFieldsChange:", vals);
    setFields(vals);
    setComInfoFormData(vals);
  };

  const setComInfoFormData = (vals) => {
    let obj = {};
    vals.map((item) => {
      obj[item.name] = item.value;
    });
    setInfoFormData(obj);
  };

  const setFormValue = (source) => {
    delete source._id;
    delete source.coId;

    let temp = [];
    for (const key in source) {
      if (Object.hasOwnProperty.call(source, key)) {
        const element = source[key];

        let filterResult = fields.filter(
          (filterItem) => filterItem.name == key
        );
        if (filterResult && filterResult.length > 0) {
          temp.push({
            label: filterResult[0].label,
            value: element,
          });
        }
      }
    }
    setFieldInfos(temp);
  };

  return (
    <div className="company-management-page">
      <div className="common-management">
        {editModal && (
          <div className="bank-info-setting-panel">
            <div className="bank-info-setting-container">
              <FormNormalW
                items={fields}
                onChange={onFieldsChange.bind(this)}
              />
            </div>
          </div>
        )}
        {editModal && (
          <div className="bottom-bar">
            <Button
              className="btn-save"
              onClick={onSubmitCompanyBankInfo.bind(this)}
              style={{
                width: "441px",
              }}
            >
              {t("SAVE")}
            </Button>
          </div>
        )}
        {!editModal && (
          <div className="bank-info-setting-info-panel">
            {fieldInfos.map((mapItem, mapIndex) => {
              return (
                <div
                  key={mapIndex}
                  style={{
                    borderBottom: "1px dashed #DEDEDE",
                    height: "41px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#333333",
                      fontSize: "16px",
                      fontFamily: "Inter",
                      width: "50%",
                    }}
                  >
                    {t(mapItem.label)}
                  </span>
                  <span
                    style={{
                      color: "#777777",
                      fontSize: "16px",
                      fontFamily: "Inter",
                      width: "50%",
                    }}
                  >
                    {t(mapItem.value)}
                  </span>
                </div>
              );
            })}
          </div>
        )}
        {!editModal && (
          <div className="bottom-bar">
            <Button
              className="btn-save"
              onClick={() => setEditModal(true)}
              style={{
                width: "441px",
              }}
            >
              {t("EDIT")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
