import React from "react";
import cn from "classnames";
import styles from "./Products.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../Icon";
import Product from "../../../Product";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import Card from "../../../Card";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

export default function Products(props) {
  const { products } = props;

  console.log("Products:", products);

  const { t } = useTranslation();

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: false,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-right" size="24" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-left" size="24" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Card
      title={t("SEE_MORE")}
      classTitle="title-red"
      />
      <div className={styles.products}>
      {products.map((x, index) => (
        <Product
          className={styles.product}
          item={x}
          key={index}
          withoutСheckbox
        />
      ))}
      </div>
    </>
  );
}
