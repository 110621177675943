import React, { useState, useEffect } from "react";
import { Avatar, Spin } from "antd";
import { translate } from "../../../api/translate";
import { useUserId } from "../../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import { DownOutlined, UpOutlined, LoadingOutlined } from "@ant-design/icons";
import { localizeImg, localizeValue } from "../../../constants/constants";
import "./style/feedCommentItem.less";
import AvatarBasic from "../../common/avatar/AvatarBasic";
import ContentEllipsisPanel from "../../common/contentEllipsis/ContentEllipsisPanel";
import { getTransLanguage } from "../../../api/common";
import { getCompanyDescFromUserType } from "../../../api/company";

export default function FeedCommentItem(props) {
  const { data, author } = props;
  const { user } = useUserId();
  const { t, i18n } = useTranslation();
  const { content, comment, userInfo, _id } = data;
  const userItem = userInfo[0];
  const { nickName, firstName, lastName } = userItem;
  const _avatar = localizeImg(userItem.picture);
  const name = nickName || firstName + lastName;
  const [transStatus, setTransStatus] = useState(false);
  const [translatedContent, setTranslatedContent] = useState("");
  const [transLoading, setTransLoading] = useState(false);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [lang, setLang] = useState(i18n.language);
  const [companyDesc, setCompanyDesc] = useState();

  useEffect(() => {
    setLang(i18n.language);
    setTranslatedContent();
    setTransStatus(false);
  }, [i18n.language]);

  useEffect(() => {
    if (userItem && userItem.companyDesc) {
      setCompanyDesc(userItem.companyDesc);
    } else {
      initCompanyDesc();
    }
  }, [userItem]);

  const initCompanyDesc = async () => {
    let pms = { coId: userItem.companyId };
    let result = await getCompanyDescFromUserType(pms, userItem.type);
    console.log("------feedDetail:", result, pms);
    if (result) {
      setCompanyDesc(result);
    }
  };

  const onTranslateMessage = async () => {
    setTransStatus(!transStatus);
    if (!transStatus && !translatedContent) {
      let language = getTransLanguage(lang);
      setTransLoading(true);

      let data = await translate({
        text: comment || content,
        to: language,
      });
      setTranslatedContent(data.result.result);
      setTransLoading(false);
    }
  };

  return (
    <div className="detail-feed-source" key={_id}>
      <div className="detail-feed-source-content-panel">
        <div className="detail-feed-source-info">
          <AvatarBasic
            cname="detail-feed-source-info-avatar"
            size={32}
            picture={localizeImg(companyDesc?.avatar) || _avatar}
            displayName={
              localizeValue(i18n.language, companyDesc?.companyName) ||
              companyDesc?.displayName ||
              name
            }
          ></AvatarBasic>
          <div className="detail-feed-source-info-right">
            <div className="detail-feed-source-author-panel">
              <div className="detail-feed-source-author-name">
                {localizeValue(i18n.language, companyDesc?.companyName) ||
                  companyDesc?.displayName ||
                  name}
              </div>
              {author === userItem._id && (
                <div className="detail-feed-source-author-tag">
                  &nbsp;- &nbsp;{t("AUTHOR")}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="detail-feed-source-content">
          <ContentEllipsisPanel content={comment || content} />
          {/* <div dangerouslySetInnerHTML={{ __html: comment || content }}></div> */}
          <div
            className="feed-trans-button"
            onClick={onTranslateMessage.bind(this)}
          >
            <span>{t("TRANSLATE")}</span>
            {transStatus ? (
              <UpOutlined style={{ fontSize: "10px" }} />
            ) : (
              <DownOutlined style={{ fontSize: "10px" }} />
            )}
          </div>
          {transStatus && (
            <div
              className="feed-trans-content"
              dangerouslySetInnerHTML={{ __html: translatedContent }}
            ></div>
          )}
          {transLoading && (
            <div className="feed-trans-content">
              <Spin indicator={loadingIcon} />
            </div>
          )}
        </div>
        <div className="detail-feed-source-bottom-bar"></div>
      </div>
    </div>
  );
}
