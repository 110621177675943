import React, { useEffect, useState } from "react";
import styles from "./ProductActivity.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Item from "./Item";
import { ProductTableMaxCount } from "../../../../constants/constants";
import { useUserId } from "../../../../contexts/UserIdProvider";
import {
  fetchProductClicks,
  fetchProductFavorites,
  loadProducts,
} from "../../../../api/product";
import { deepClone } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";

const items = [
  {
    title: "25 Sep - 1 Oct",
    products: {
      counter: 8,
      color: "#B5E4CA",
      value: 37.8,
    },
    views: {
      counter: "24k",
      color: "#CABDFF",
      value: 37.8,
    },
    likes: {
      counter: 48,
      color: "#B1E5FC",
      value: -37.8,
    },
    comments: {
      counter: 16,
      color: "#FFD88D",
      value: -56,
    },
  },
  {
    title: "18 Sep - 24 Oct",
    products: {
      counter: 8,
      color: "#EFEFEF",
      value: 37.8,
    },
    views: {
      counter: "24k",
      color: "#EFEFEF",
      value: -37.8,
    },
    likes: {
      counter: 48,
      color: "#EFEFEF",
      value: 12.8,
    },
    comments: {
      counter: 16,
      color: "#EFEFEF",
      value: -14.1,
    },
  },
];

const ProductActivity = () => {
  const intervals = ["Last 2 weeks", "Last 7 days"];

  const { t } = useTranslation();

  const { user } = useUserId();

  const [activeTab, setActiveTab] = useState(intervals[0]);

  let [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    initActivity();
  }, []);

  const initActivity = async () => {
    const productActivityObj = await getProductActive();
    const productClickActivity = await getProductClickActivity();
    const productFavoriteActivity = await getProductFavoriteActivity(
      productActivityObj.ids
    );

    const mergedMap = {};
    Object.keys(productActivityObj.groupedData).forEach((key) => {
      const productCount = {
        products: productActivityObj.groupedData.hasOwnProperty(key)
          ? productActivityObj.groupedData[key].length
          : 0,
        clicks: productClickActivity.hasOwnProperty(key)
          ? productClickActivity[key].length
          : 0,
        favorites: productFavoriteActivity.hasOwnProperty(key)
          ? productFavoriteActivity[key].length
          : 0,
      };

      mergedMap[key] = productCount;
    });

    let temp = deepClone(dataSource);
    temp = [];
    for (const date in mergedMap) {
      if (mergedMap.hasOwnProperty(date)) {
        const productCount = mergedMap[date];

        temp.push({
          title: date,
          products: {
            counter: productCount.products,
            color: "#B5E4CA",
            // value: 37.8,
          },
          views: {
            counter: productCount.clicks,
            color: "#CABDFF",
            // value: 37.8,
          },
          likes: {
            counter: productCount.favorites,
            color: "#B1E5FC",
            // value: -37.8,
          },
        });
        // console.log(`Date: ${date}`);
        // console.log(`Product Map Count: ${productCount.products}`);
        // console.log(`Product Click Map Count: ${productCount.clicks}`);
        // console.log(
        //   `Product Favorite Map Count: ${productCount.favorites}`
        // );
      }
      setDataSource(temp.slice(0.2));
    }
  };

  const getProductActive = async () => {
    let pms = {
      page: 0,
      per_page: ProductTableMaxCount,
      coId: user.userInfo.companyId,
    };
    let result = await loadProducts(pms);
    const ids = result.map((item) => item._id);
    const groupedData = groupArr(result);

    return { groupedData, ids };
  };

  const getProductClickActivity = async () => {
    let pms = {
      coId: user.userInfo.companyId,
    };
    let result = await fetchProductClicks(pms);
    const groupedData = groupArr(result?.result);

    return groupedData;
  };

  const getProductFavoriteActivity = async (productIds) => {
    let favoriteList = [];

    for (let index = 0; index < productIds.length; index++) {
      const element = productIds[index];
      let pms = {
        productId: element,
      };
      let result = await fetchProductFavorites(pms);
      if (result && result.total > 0) {
        favoriteList.push(...result.result);
      }
    }

    const groupedData = groupArr(favoriteList);
    return groupedData;
  };

  const groupArr = (arr) => {
    const groupedData = arr.reduce((groups, data) => {
      const date = new Date(data.createdAt);
      const weekStart = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - date.getDay()
      );
      const weekEnd = new Date(
        weekStart.getFullYear(),
        weekStart.getMonth(),
        weekStart.getDate() + 6
      );
      const key = `${weekStart.getMonth() + 1}/${weekStart.getDate()} - ${
        weekEnd.getMonth() + 1
      }/${weekEnd.getDate()}`;

      if (!groups[key]) {
        groups[key] = []; // Create a new group
      }

      groups[key].push(data); // Add data to the corresponding group

      return groups;
    }, {});
    return groupedData;
  };

  return (
    <Card
      className={styles.card}
      title={t("PRODUCT_ACTIVITY")}
      classTitle="title-red"
      // head={
      //   <Dropdown
      //     className={cn(styles.dropdown, "mobile-hide")}
      //     classDropdownHead={styles.dropdownHead}
      //     value={activeTab}
      //     setValue={setActiveTab}
      //     options={intervals}
      //     small
      //   />
      // }
    >
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>{t("WEEK")}</div>
          <div className={styles.col}>{t("PRODUCTS")}</div>
          <div className={styles.col}>{t("VIEWS")}</div>
          <div className={styles.col}>{t("FAVORITE")}</div>
          {/* <div className={styles.col}>Comments</div> */}
        </div>
        {dataSource.map((x, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>
              <div className={styles.label}>Week</div>
              {x.title}
            </div>
            <div className={styles.col}>
              <div className={styles.label}>Products</div>
              <Item className={styles.item} item={x.products} />
            </div>
            <div className={styles.col}>
              <div className={styles.label}>Views</div>
              <Item className={styles.item} item={x.views} />
            </div>
            <div className={styles.col}>
              <div className={styles.label}>Likes</div>
              <Item className={styles.item} item={x.likes} />
            </div>
            {/* <div className={styles.col}>
              <div className={styles.label}>Comments</div>
              <Item className={styles.item} item={x.comments} />
            </div> */}
          </div>
        ))}
      </div>
      <div className={styles.nav}>
        {intervals.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: x === activeTab,
            })}
            onClick={() => setActiveTab(x)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
    </Card>
  );
};

export default ProductActivity;
