import React from "react";
import styles from "./Orders.module.sass";
import TooltipGlodal from "../../../../core-digital-template/components/TooltipGlodal";
import Overview from "./Overview";
import PayoutHistory from "./PayoutHistory";

const DashboardSupplierOrders = () => {
    return (
        <>
            <div className={styles.section}>
                {/* <Overview className={styles.card} /> */}
                <PayoutHistory />
            </div>
            <TooltipGlodal />
        </>
    );
};

export default DashboardSupplierOrders;
