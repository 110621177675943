import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Modal, Space, Table, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { highRatingRemove, highRatings } from "../../../api/user";
import "./style/highRating.less";
import HightRatingCreate from "../high-rating/HightRatingCreate";
import { Link } from "react-router-dom";
import { localizeImg, localizeValue } from "../../../constants/constants";

export default function HighRating() {
  const { i18n, t } = useTranslation();
  const { user } = useUserId();
  const { TabPane } = Tabs;

  const [data, setData] = useState([]);
  const [products, setProducts] = useState();
  const [selectItem, setSelectItem] = useState();

  const [createModalVisible, setCreateModalVisible] = useState(false);

  useEffect(() => {
    loadData(user.userInfo.companyId);
  }, []);

  const columns = [
    {
      title: t("HIGH_RATING_PHOTO"),
      dataIndex: "proof",
      key: "proof",
      render: (val) => {
        return <img src={localizeImg(val)} className="rewardIcon" />;
      },
    },
    {
      title: t("PRODUCT"),
      dataIndex: "productInfo",
      key: "productInfo",
      render: (val) => {
        console.log("render value:", val[0].title);
        return <div>{localizeValue(i18n.language, val[0].title)}</div>;
      },
    },
    { title: t("TYPE"), dataIndex: "type", key: "type" },
    { title: t("SCORE"), dataIndex: "score", key: "score" },
    {
      title: t("STATUS"),
      dataIndex: "status",
      key: "status",
      render: (val) => {
        return <div>{val == 1 ? "Waiting for verify" : "Verified"}</div>;
      },
    },
    {
      title: t("ACTION"),
      dataIndex: "",
      key: "y",
      render: (val) => {
        return (
          <Space size="middle">
            <Button type="link" onClick={onRemove.bind(this, val._id)}>
              {t("DELETE")}
            </Button>
          </Space>
        );
      },
    },
  ];

  const loadData = async (coId) => {
    let pms = { coId };
    let result = await highRatings(pms);
    console.log("load high rating result:", result);
    setData(result);
  };

  const onRemove = async (id) => {
    let pms = { highRatingId: id };
    await highRatingRemove(pms);
    loadData(user.userInfo.companyId);
  };

  const onEdit = (item) => {
    setSelectItem(item);
    setCreateModalVisible(true);
  };

  const onModelClose = () => {
    setSelectItem(null);
    setCreateModalVisible(false);
    loadData(user.userInfo.companyId);
  };
  const onCreateComplete = () => {
    loadData(user.userInfo.companyId);
    onModelClose();
  };

  const bannerContent = (item) => {
    console.log(item);
    return (
      <>
        <div className="banner-title">
          <span className="banner-title-price">{item.name}</span>
        </div>
        <div className="banner-title-sub">{item.desc}</div>
      </>
    );
  };

  return (
    <div className="high-rating-page-container">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("HIGHG_RATING_LIST")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management normal-shadow-padding10-white">
        <div className="common-management-title">{t("HIGH_RATING_LIST")}</div>
        {products !== 0 && (
          <div className="products-filter">
            <Button
              className="common-primary-button"
              onClick={() => setCreateModalVisible(true)}
            >
              {t("CREATE_HIGH_RATING")}
            </Button>
          </div>
        )}
        <div className="common-management-panel">
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
        <Modal
          destroyOnClose={true}
          title={null}
          visible={createModalVisible}
          footer={null}
          className="discount-create-modal"
          onCancel={onModelClose.bind(this)}
        >
          <HightRatingCreate
            onClose={onModelClose.bind(this)}
            onComplete={onModelClose.bind(this)}
          />
        </Modal>
      </div>
    </div>
  );
}
