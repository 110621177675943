import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../../../core-digital-template/components/Card";
import Icon from "../../../../../core-digital-template/components/Icon";
import TextInput from "../../../../../core-digital-template/components/TextInput";
import Editor from "../../../../../core-digital-template/components/Editor";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../../../core-digital-template/components/Dropdown";
import {
  getProductConfigForSelect,
  getProductGrapes,
  loadProductConfig,
} from "../../../../../api/product";
import {
  ProductConfig,
  ProductPropertiesConfig,
} from "../../../../../constants/constants";
import {
  getCountryForSelect,
  getLocationsByParentId,
} from "../../../../../api/config";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { deepClone } from "../../../../../utils/utils";
import MultiSelect from "../../../../../core-digital-template/components/MultiSelect";
import MultipleSelectBasic from "../../../../../components/common/select/MultipleSelectBasic";
import { translateContent } from "../../../../../api/translate";

export default function NameAndDescription(props) {
  const {
    className,
    onChange,
    dataSource,
    editModel,
    editProductName,
    editProductTechInfo,
    editProductTestingNote,
  } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [formData, setFormData] = useState({});
  const [tastingNote, setTastingNote] = useState("");

  const [classifications, setClassifications] = useState([]);
  const [bodys, setBodys] = useState([]);
  const [acidities, setAcidities] = useState([]);
  const [capsules, setCapsules] = useState([]);
  const [sealeds, setSealeds] = useState([]);
  const [packages, setPackages] = useState([]);
  const [types, setTypes] = useState([]);
  const [grapes, setGrapes] = useState([]);
  const [categories, setCategories] = useState([]);

  const [areas, setAreas] = useState([]);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [subRegions, setSubRegions] = useState([]);

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(150), (val, index) => currentYear - index);
  const [yearsOption, setYearsOptioin] = useState(
    years.map((year) => ({ label: year.toString(), value: year.toString() }))
  );

  const [agePeriod, setAgePeriod] = useState([
    { label: t("NO_OAK_BARREL"), value: "0" },
    { label: "<6 " + t("MONTHS_IN_OAK_BARREL"), value: "<6" },
    { label: "6 " + t("MONTHS_IN_OAK_BARREL"), value: "6" },
    { label: "12 " + t("MONTHS_IN_OAK_BARREL"), value: "12" },
    { label: "18 " + t("MONTHS_IN_OAK_BARREL"), value: "18" },
    { label: "24 " + t("MONTHS_IN_OAK_BARREL"), value: "24" },
    { label: "36 " + t("MONTHS_IN_OAK_BARREL"), value: "36" },
    { label: "48 " + t("MONTHS_IN_OAK_BARREL"), value: "48" },
    { label: ">48 " + t("MONTHS_IN_OAK_BARREL"), value: ">48" },
  ]);

  const languageNavigation = [
    { label: t("LANGUAGE_FRENCH"), key: "fr" },
    { label: t("LANGUAGE_CHINESE"), key: "zh" },
    { label: t("LANGUAGE_ENGLISH"), key: "en" },
  ];
  const [nameActiveTab, setNameActiveTab] = useState(languageNavigation[0]);
  const [tastingNoteActiveTab, setTastingNoteActiveTab] = useState(
    languageNavigation[0]
  );

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (dataSource) {
      let temp = deepClone(formData);
      temp = dataSource;
      console.log("temp:", temp);
      setFormData(temp);
      setTastingNote(temp?.tastingNote);
    }
  }, [dataSource]);

  useEffect(() => {
    // console.log("formData", formData, areas);
    if (formData?.country && areas && areas.length > 0) {
      onCountrySelect(areas, formData.country);
      // setFormDataValue("region", null);
    }

    //reset the classfication when change the country
    // if (formData?.country && formData?.classification) {
    //   let filters = classifications.filter(
    //     (filterItem) => filterItem.value == formData?.classification
    //   );
    //   if (
    //     filters &&
    //     filters.length > 0 &&
    //     filters[0].countryid != formData?.country
    //   ) {
    //     // setFormDataValue("classification", "");
    //   }
    // }
  }, [formData.country]);

  useEffect(() => {
    // console.log("formData", formData, areas);
    if (formData?.region && areas && areas.length > 0) {
      onRegionSelect(areas, formData?.region);
    } else if (!formData?.region) {
      // setFormDataValue("subRegion", null);
    }
  }, [formData.region]);

  // useEffect(() => {
  //   if (formData.region && subRegions.length > 0) {
  //     let filters = areas.filter(
  //       (filterItem) => filterItem.value == formData?.subRegion
  //     );
  //     if (
  //       filters &&
  //       filters.length > 0 &&
  //       filters[0].parentId != formData?.region
  //     ) {
  //       // setFormDataValue("subRegion", null);
  //     }
  //   } else {
  //     // setFormDataValue("subRegion", null);
  //   }
  // }, [subRegions]);

  // useEffect(() => {
  //   if (formData?.category && formData?.type) {
  //     let filterTypes = types.filter(
  //       (typeItem) => typeItem.value == formData?.type
  //     );
  //     if (
  //       filterTypes &&
  //       filterTypes.length > 0 &&
  //       filterTypes[0].parentId != formData?.category
  //     ) {
  //       // setFormDataValue("type", "");
  //     }
  //   }
  // }, [formData.category]);

  useEffect(() => {
    setConfigData();
    initConfigData();
  }, [currentLanguage]);

  useEffect(() => {
    if (formData) {
      setTastingNote(formData.tastingNote);
    }
  }, [tastingNoteActiveTab]);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let {
      locations,
      types,
      bottles,
      capsules,
      sealed,
      classifications,
      categories,
    } = configData;

    loadCountry(locations.result);
    loadSelectData(types, ProductConfig.TYPES);
    loadSelectData(bottles, ProductConfig.BOTTLES);
    loadSelectData(capsules, ProductConfig.CAPSULES);
    loadSelectData(sealed, ProductConfig.SEALEDS);
    loadSelectData(classifications, ProductConfig.CLASSIFICATIONS);
    loadSelectData(categories, ProductConfig.CATEGORIES);

    let grapeList = await initProductGrapes();
    loadSelectData(grapeList, ProductConfig.GRAPES);
  };

  const initConfigData = async () => {
    let tempBodys = deepClone(ProductPropertiesConfig.body);
    tempBodys.map((item) => {
      item.label = t(item.label);
    });
    setBodys(tempBodys);

    // let tempAcidities = deepClone(ProductPropertiesConfig.acidity);
    // tempAcidities.map((item) => {
    //   item.label = t(item.label);
    // });
    // setAcidities(tempAcidities);
    let tempPackages = deepClone(packages);
    tempPackages = [];
    ProductPropertiesConfig.package.map((mapItem) => {
      mapItem.label = t(mapItem.label);
      tempPackages.push(mapItem);
    });
    setPackages(tempPackages);
  };

  const loadSelectData = (data, flag) => {
    let result = getProductConfigForSelect(data, currentLanguage);
    if (result && result.length > 0) {
      let defaultValue = result[0].value;
      switch (flag) {
        case ProductConfig.TYPES:
          setTypes(result);
          break;
        case ProductConfig.BOTTLES:
          break;
        case ProductConfig.CAPSULES:
          setCapsules(result);
          break;
        case ProductConfig.SEALEDS:
          setSealeds(result);
          break;
        case ProductConfig.CLASSIFICATIONS:
          setClassifications(result);
          break;
        case ProductConfig.CATEGORIES:
          setCategories(result);
          break;
        case ProductConfig.GRAPES:
          setGrapes(result);
          break;
        case ProductConfig.TAGS:
          break;
        default:
          break;
      }
    }
  };

  const loadCountry = (locations) => {
    console.log("-------------loadCountry:", locations);
    setAreas(locations);
    let result = getCountryForSelect(
      locations,
      currentLanguage,
      user.userInfo.type
    );
    setCountries(result);
  };

  const onCountrySelect = (locations, id) => {
    // console.log("onCountrySelect:", id, locations.filter((filterItem)=>filterItem.parentId==id));
    let result = getLocationsByParentId(locations, id, currentLanguage);
    setRegions(result);
  };

  const onRegionSelect = (locations, id) => {
    let result = getLocationsByParentId(locations, id, currentLanguage);
    setSubRegions(result);
    if (result && result.length == 0) {
      setFormDataValue("subRegion", null);
    }
  };

  const initProductGrapes = async () => {
    let pms = {
      pms: JSON.stringify({
        page: 0,
        per_page: 1000,
      }),
    };
    let result = await getProductGrapes(pms);
    return result;
  };

  const setFormDataObjValue = (name, objName, value) => {
    console.log("setFormDataObjValue:", name, objName, value);
    let temp = deepClone(formData);
    if (!temp[objName]) {
      temp[objName] = {};
    }
    if (objName == "name" && name == "en") {
      let productId = value.toLowerCase();
      productId = productId.replace(/\s/g, "-");
      productId = productId.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      productId = productId.replace(/[^a-zA-Z0-9]/g, "-");
      productId = productId.replace(/-+/g, "-");
      productId = productId.replace(/-$/, "");

      temp["productId"] =
        user?.userInfo?.companyDesc?.companyId + "-" + productId;
    }
    temp[objName][name] = value;
    setFormData(temp);

    onChange && onChange(temp);
  };

  const setFormDataValue = (name, value) => {
    // console.log("setFormDataValue:", name, value);
    let temp = deepClone(formData);
    temp[name] = value;
    setFormData(temp);

    onChange && onChange(temp);
  };

  const setTastingNoteValue = async (name, value) => {
    console.log("setFormDataValue:", name, value);
    if (!value) {
      return;
    }
    let temp = deepClone(formData);
    if (!temp["tastingNote"]) {
      temp["tastingNote"] = {};
    }
    temp["tastingNote"]["source"] = value;
    setFormData(temp);

    onChange && onChange(temp);
  };

  return (
    <Card
      className={cn(styles.card, styles.productDescription)}
      title={t("PRODUCT_DESCRIPTION")}
      classTitle="title-red"
      head={
        <>
          {!editModel && (
            <Link
              className={cn("button-stroke button-small", styles.button)}
              onClick={() => history.goBack()}
            >
              <Icon name="arrow-left" size="24" />
              <span>{t("BACK")}</span>
            </Link>
          )}
        </>
      }
    >
      <div className={styles.table}>
        {(!editModel || editProductName) && (
          <div className={styles.row}>
            <div className={styles.col}>
              <TextInput
                className={styles.field}
                name="name"
                type="text"
                required
                onChange={setFormDataObjValue.bind(this, "en", "name")}
                value={formData?.name ? formData?.name["en"] : ""}
                label={
                  <div className={styles.labelWithTranslations}>
                    <span>{t("PRODUCT_NAME")}</span>
                  </div>
                }
              />
            </div>
          </div>
        )}

        {(!editModel || editProductName) && (
          <div className={styles.row}>
            <div className={styles.col}>
              <br />
              <i>
                {t("PRODUCT_ID")}: {formData?.productId}
              </i>
            </div>
          </div>
        )}

        {(!editModel || editProductTechInfo) && (
          <>
            <div className="flex flex-wrap justify-between">
              <div className="w-[344px] mb-5">
                <Dropdown
                  label={t("CATEGORY")}
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  value={formData.category}
                  setValue={setFormDataValue.bind(this, "category")}
                  options={categories}
                />
              </div>
              <div className="w-[344px] mb-5">
                <Dropdown
                  label={t("BODY")}
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  value={formData.body}
                  setValue={setFormDataValue.bind(this, "body")}
                  options={bodys}
                />
              </div>
              <div className="w-[344px] mb-5">
                <Dropdown
                  className={styles.dropdown_field}
                  label={t("PRODUCT_TYPE")}
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  value={formData.type}
                  setValue={setFormDataValue.bind(this, "type")}
                  options={types.filter((filterItem) =>
                    formData.category
                      ? filterItem?.parentId == formData.category
                      : true
                  )}
                />
              </div>
              <div className="w-[344px] mb-5">
                <MultipleSelectBasic
                  label={t("GRAPE")}
                  data={grapes}
                  value={formData?.grape}
                  onChange={setFormDataValue.bind(this, "grape")}
                />
              </div>
              <div className="w-[344px] mb-5">
                <Dropdown
                  className={styles.dropdown_field}
                  label={t("COUNTRY")}
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  value={formData.country}
                  setValue={setFormDataValue.bind(this, "country")}
                  options={countries}
                />
              </div>
              <div className="w-[344px] mb-5">
                <TextInput
                  className={styles.field}
                  label={t("AGE_OF_VINE")}
                  name="AGE_OF_VINE"
                  type="number"
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  required
                  onChange={setFormDataValue.bind(this, "ageOfVine")}
                  value={formData?.ageOfVine}
                />
              </div>
              <div className="w-[344px] mb-5">
                <Dropdown
                  className={styles.dropdown_field}
                  label={t("REGION")}
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  value={formData.region}
                  setValue={setFormDataValue.bind(this, "region")}
                  options={regions}
                />
              </div>
              <div className="w-[344px] mb-5">
                <Dropdown
                  label={t("SUB_REGION")}
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  value={formData.subRegion}
                  setValue={setFormDataValue.bind(this, "subRegion")}
                  options={subRegions}
                />
              </div>
              <div className="w-[344px] mb-5">
                {/* <MultiSelect
                  className={styles.dropdown_field}
                  label={t("VINTAGE")}
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  value={formData.vintage}
                  onChange={setFormDataValue.bind(this, "vintage")}
                  options={yearsOption}
                /> */}
                <Dropdown
                  label={t("VINTAGE")}
                  value={formData.vintage}
                  setValue={setFormDataValue.bind(this, "vintage")}
                  options={yearsOption}
                />
              </div>
              <div className="w-[344px] mb-5">
                <Dropdown
                  label={t("CLASSIFICATIONS")}
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  value={formData.classification}
                  setValue={setFormDataValue.bind(this, "classification")}
                  options={classifications.filter((filterItem) =>
                    formData.country
                      ? filterItem.countryid == formData.country
                      : true
                  )}
                />
              </div>
              <div className="w-[344px] mb-5">
                <Dropdown
                  label={t("AGING_PERIOD")}
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  value={formData.agingPeriod}
                  setValue={setFormDataValue.bind(this, "agingPeriod")}
                  options={agePeriod}
                />
              </div>
              <div className="w-[344px] mb-5">
                <TextInput
                  className={styles.field}
                  label={t("ALCOHOL_DEGREE")}
                  name="title"
                  type="number"
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  required
                  currency={"%"}
                  onChange={setFormDataValue.bind(this, "alcoholDegree")}
                  value={formData?.alcoholDegree}
                />
              </div>
              <div className="w-[344px] mb-5">
                <TextInput
                  className={styles.field}
                  label={t("SERVING_TEMPERATURE")}
                  name="title"
                  type="number"
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  required
                  currency={"℃"}
                  onChange={setFormDataValue.bind(this, "servingTemperature")}
                  value={formData?.servingTemperature}
                />
              </div>
              <div className="w-[344px] mb-5">
                <Dropdown
                  label={t("CORK")}
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  value={formData.cork}
                  setValue={setFormDataValue.bind(this, "cork")}
                  options={sealeds}
                />
              </div>
              <div className="w-[344px] mb-5">
                <TextInput
                  className={styles.field}
                  label={t("BOTTLE_VOLUME")}
                  name="volume"
                  type="text"
                  currency={"mL"}
                  required
                  //addonAfter={t("ML")}
                  onChange={setFormDataValue.bind(this, "volume")}
                  value={formData?.volume}
                />
              </div>
              <div className="w-[344px] mb-5">
                <Dropdown
                  label={t("PACKAGE")}
                  tooltip="Maximum 100 characters. No HTML or emoji allowed"
                  value={formData.package}
                  setValue={setFormDataValue.bind(this, "package")}
                  options={packages}
                />
              </div>
            </div>
          </>
        )}
        {(!editModel || editProductTestingNote) && (
          <div className={styles.editorContainer}>
            <Editor
              state={tastingNote?.source}
              onChange={setTastingNoteValue.bind(this, "tastingNote")}
              classEditor={styles.editor}
              // tooltip={t("TASTING_NOTE")}
              label={
                <div className={styles.labelWithTranslations}>
                  <span>{t("TASTING_NOTE")}</span>
                </div>
              }
            />
          </div>
        )}
        {/* {(!editModel || editProductTestingNote) && (
          <div className={styles.editorContainer}>
            {languageNavigation.map((x, index) => (
              <div>
                {x.key === tastingNoteActiveTab.key && (
                  <Editor
                    state={tastingNote?.[tastingNoteActiveTab.key]}
                    onChange={setFormDataObjValue.bind(
                      this,
                      x.key,
                      "tastingNote"
                    )}
                    classEditor={styles.editor}
                    // tooltip={t("TASTING_NOTE")}
                    label={
                      <div className={styles.labelWithTranslations}>
                        <span>{t("TASTING_NOTE")}</span>
                        {languageNavigation.map((x, index) => (
                          <button
                            className={cn(styles.translationLink, {
                              [styles.active]:
                                x.key === tastingNoteActiveTab.key,
                            })}
                            onClick={() => setTastingNoteActiveTab(x)}
                            key={index}
                          >
                            {x.label}
                          </button>
                        ))}
                      </div>
                    }
                  />
                )}
              </div>
            ))}
          </div>
        )} */}
      </div>
    </Card>
  );
}
