import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Result } from "antd";

export default function NotAuthorized() {
  const { t } = useTranslation();

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Link to={"/feed"}>
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  );
}
