import {RequestType} from "../constants/requestType.enum";
import {actionRequest} from "./api";
import {requestGetUrlFormat} from "./common";
import apiPath from "./apipath";

/**
 *
 * @param pms
 */
export function stocks(pms: any) {
    return actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.stocks, pms));
}

/**
 *
 * @param pms
 */
export function stockCreate(pms: any) {
    return actionRequest(RequestType.POST, apiPath.stockCreate, pms);
}

/**
 *
 * @param pms
 */
export function stockUpdate(pms: any) {
    return actionRequest(RequestType.POST, apiPath.stockUpdate, pms);
}

/**
 *
 * @param pms
 */
export function stockRemove(pms: any) {
    return actionRequest(RequestType.GET, requestGetUrlFormat(apiPath.stockRemove, pms));
}
