import cn from "classnames";
import styles from "./ImagesAndVideo.module.sass";
import Card from "../../../../../core-digital-template/components/Card";
import File from "../../../../../core-digital-template/components/File";
import { useTranslation } from "react-i18next";
import VideoUploader from "../../../../../core-digital-template/components/VideoUploader";
import ImageUploader from "../../../../../core-digital-template/components/ImageUploader";

export default function ImagesAndVideo(props) {
  const { dataSource, className, onImagesChange, onVideoChange } = props;
  const { t } = useTranslation();

  const onImgsChange = (fields) => {
    onImagesChange && onImagesChange(fields);
  };

  const onVChange = (fields) => {
    onVideoChange && onVideoChange(fields);
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title={t("PRODUCT_PHOTOS_VIDEO")}
      classTitle="title-red"
    >
      <div className={styles.images}>
        <ImageUploader
          className={styles.field}
          title={t("CLICK_OR_DROP_IMAGE")}
          label={t("PRODUCT_PHOTOS")}
          tooltip={t("PRODUCT_PHOTOS_TIP")}
          onChange={onImgsChange.bind(this)}
          dataSource={dataSource?.images}
        />
        <VideoUploader
          className={styles.field}
          title={t("CLICK_OR_DROP_VIDEO")}
          label={t("PRODUCT_VIDEO")}
          tooltip={t("PRODUCT_VIDEO_TIP")}
          onChange={onVChange.bind(this)}
          dataSource={dataSource?.videos}
        />
      </div>
    </Card>
  );
}
