import React, { useState, useEffect } from "react";
import { Form, Button, Table, Space, Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { statusCode } from "../../../constants/statusCode.enum";
import { highRatingCreate } from "../../../api/user";
import ProductImageUpload from "../../common/upload/ProductImageUpload";
import UploadNormal from "../../common/upload/UploadNormal";
import { localizeImg } from "../../../constants/constants";
import { formatUploadFileFormValues } from "../../../api/common";
import { loadProductsForSelect } from "../../../api/product";
import { localizeValue } from "../../../constants/constants";
import { toastError, toastSuccess } from "../../../api/toast";

export default function HightRatingCreate(props) {
  const { onComplete, onClose } = props;
  const { i18n, t } = useTranslation();
  const { user } = useUserId();

  let [proof, setProof] = useState("");
  let [proofUrl, setProofUrl] = useState("");
  let [score, setScore] = useState("");

  const [type, setType] = useState("");
  const [types, setTypes] = useState([
    {
      label: "Wine Enthusiast",
      value: "Wine Enthusiast",
      min: 0,
      max: 100,
    },
    {
      label: "Wine Advocate",
      value: "Wine Advocate",
      min: 0,
      max: 100,
    },
    {
      label: "Wine Spectator",
      value: "Wine Spectator",
      min: 0,
      max: 100,
    },
    {
      label: "Jancis Robinson",
      value: "Jancis Robinson",
      min: 0,
      max: 20,
    },
    {
      label: "Bettane+Desseauv",
      value: "Bettane+Desseauv",
      min: 0,
      max: 20,
    },
  ]);

  const [productId, setProductId] = useState();
  const [products, setProducts] = useState([]);

  const [currentLanguage, setCurrentLanguage] = useState();

  useEffect(() => {
    setCurrentLanguage(i18n.language);
    loadProductSelectOptions(user.userInfo.companyId);
  }, []);

  const onFinish = async () => {
    let pms = {
      coId: user.userInfo.companyId,
      proof: proof,
      productId,
      score,
      type,
    };

    if (!proof) {
      toastError(t("PROOF_CANNOT_BE_NULL"));
      return;
    }

    if (!type) {
      toastError(t("TYPE_CANNOT_BE_NULL"));
      return;
    }

    if (!productId) {
      toastError(t("PRODUCT_CANNOT_BE_NULL"));
      return;
    }

    types.map((typeItem) => {
      if (typeItem.value == type) {
        if (score < typeItem.min || score > typeItem.max) {
          let errorInfo =
            type +
            " min value is:" +
            typeItem.min +
            " max value is:" +
            typeItem.max;
          toastError(errorInfo);
        }
      }
    });

    //console.log("pms", pms);
    let data = await highRatingCreate(pms);
    if (data) {
      onComplete();
    }
  };

  const onFileLoading = (index, percent, url) => {
    if (percent === 0) {
      //setProof(url);
    }
  };

  const onFileLoaded = (data) => {
    console.log("----file loaded ----", data);
    if (data.length > 0) {
      setProof(data[0]);
      let temp = localizeImg(data[0]);
      console.log(temp);
      setProofUrl(temp);
    }
  };

  const onCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const loadProductSelectOptions = async (coId) => {
    let result = await loadProductsForSelect(coId, currentLanguage);
    //console.log("after localize result:",result);
    setProducts(result);
  };

  return (
    <div>
      <div className="modal-body">
        <div className="modal-title">{t("CREATE_HIGH_RATING")}</div>

        <div className="modal-content-column">
          <div className="content-row-label">{t("HIGH_RATING_LIST")}</div>
          <UploadNormal
            onLoading={onFileLoading.bind(this, 2)}
            onFileLoaded={onFileLoaded.bind(this)}
            className="awardIcon"
          >
            <ProductImageUpload title={t("HIGH_RATING_LIST")} url={proofUrl} />
          </UploadNormal>
        </div>
        <div className="modal-content-column">
          <div className="content-row-label">{t("SCORE")}</div>
          <Input
            className="content-row-select"
            onChange={(val) => setScore(val.target.value)}
          />
        </div>
        {products && products.length > 0 && (
          <div className="modal-content-column">
            <div className="content-row-label">Product</div>
            <Select
              value={productId}
              options={products}
              className="content-row-select"
              onChange={(val) => setProductId(val)}
            />
          </div>
        )}
        <div className="modal-content-column">
          <div className="content-row-label">{t("TYPE")}</div>
          <Select
            value={type}
            options={types}
            className="content-row-select"
            onChange={(val) => setType(val)}
          />
        </div>
        <Space size={8} className="modal-confirm-row">
          <Button
            className="modal-confirm-btn"
            onClick={onCloseClick.bind(this)}
          >
            {t("CANCEL")}
          </Button>
          <Button
            htmlType="submit"
            className="modal-confirm-btn-primary"
            onClick={onFinish.bind(this)}
          >
            {t("SAVE")}
          </Button>
        </Space>
      </div>
    </div>
  );
}
