import { message, Upload } from "antd";
import { useUserId } from "../../../contexts/UserIdProvider";
import { getFileType, getUploadFileAction } from "../../../api/common";
import { getCacheTokens } from "../../../utils/cache";
import { getBase64 } from "../../../utils/utils";
import { UploadFileType } from "../../../constants/constants";
import { useTranslation } from "react-i18next";
import { FileRepository } from "@amityco/js-sdk";

export default function UploadAmity(props) {
  const { children, onLoading, onLoaded, fileType } = props;
  const { t } = useTranslation();
  const { user } = useUserId();
  const action = getUploadFileAction(user.userId);
  const token = getCacheTokens();
  const tokenValue = token ? token.value : "";
  const authorization = "Bearer " + tokenValue;

  const layout_upload = {
    name: "file",
    action: action,
    headers: { authorization },
    showUploadList: false,
    maxCount: 1,
    onChange: async (info) => {
      // let { file } = info;
    },
    beforeUpload: (file) => {
      if (fileType) {
        let _filetype = getFileType(file.type);
        if (fileType === UploadFileType.IMAGE && fileType !== _filetype) {
          message.error(t("IS_NOT_IMAGE_FILE"));
          return Upload.LIST_IGNORE;
        } else if (
          fileType === UploadFileType.VIDEO &&
          fileType !== _filetype
        ) {
          message.error(t("IS_NOT_VIDEO_FILE"));
          return Upload.LIST_IGNORE;
        }
      }
      return true;
    },
  };

  const customRequest = async (props) => {
    const { file } = props;

    const liveObject= FileRepository.createFile({
      file,
      onProgress: ({ currentFile, currentPercent }) => {},
    });

    await new Promise((resolve) => {
      liveObject.once('loadingStatusChanged', resolve);
    });

    onLoaded&&onLoaded(liveObject.model);
  };

  return (
    <Upload {...layout_upload} customRequest={customRequest}>
      {children}
    </Upload>
  );
}
