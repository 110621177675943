import React, { useEffect, useState } from "react";
import { Button, Modal, Row } from "antd";
import { useUserId } from "../../../contexts/UserIdProvider";
import "./style/companyVerify.less";
import { verifyAdd, verifyUpdate } from "../../../api/user";
import { useTranslation } from "react-i18next";
import { toastInfo, toastError, toastSuccess } from "../../../api/toast";
import {
  companyDetail,
  companyImporterDesc,
  companyWineryDesc,
} from "../../../api/company";
import { CompanyVerifyStatusEnum } from "../../../constants/companyVerifyStatus.enum";
import {
  chinaCompanyVerifyFiels,
  UserType,
} from "../../../constants/usersType.enum";
import { getChinaCityNameById } from "../../../api/config";
import { loadProductConfig } from "../../../api/product";
import { deepClone } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import { statusCode } from "../../../constants/statusCode.enum";
import FormNormalW from "../../common/form/FormNormalW";
import basicInfoIcon from "../../../resource/icons/dashboard/verify/basic_info.svg";
import licenseIcon from "../../../resource/icons/dashboard/verify/license.svg";

export default function CompanyVerifyChina(props) {
  const { user } = useUserId();
  const { t, i18n } = useTranslation();
  const verifyId = user?.userInfo?.companyId;

  //企业信息
  const [companyDesc, setCompanyDesc] = useState("");
  //企业认证信息
  const [verfiyCompanyInfo, setVerfiyCompanyInfo] = useState({});
  //基本信息
  const [verifiyBasicInfos, setVerifyBasicInfos] = useState(
    chinaCompanyVerifyFiels.basicInfo
  );
  //证件信息
  const [verifyLicenseInfos, setVerifyLicenseInfos] = useState(
    chinaCompanyVerifyFiels.licenseInfo
  );
  //收货地址
  const [verfiyDeliveryAddressInfos, setVerfiyDeliveryAddressInfos] = useState(
    chinaCompanyVerifyFiels.deliveryAddresses
  );
  const [normalInvoice, setNormalInvoice] = useState(false);
  //发票信息
  const [verifyInvoiceInfos, setVerifyInvoiceInfos] = useState(
    chinaCompanyVerifyFiels.invoiceInfo
  );
  //专票信息
  const [verifySpecialInvoiceInfos, setVerifySpecialInvoiceInfos] = useState(
    chinaCompanyVerifyFiels.specialInvoiceInfo
  );
  //专票收货地址
  const [
    verifySpecialInvoiceDeliveryAddressInfos,
    setVerifySpecialInvoiceDeliveryAddressInfos,
  ] = useState(chinaCompanyVerifyFiels.specialInvoiceDeliveryAddress);
  //添加收货地址信息
  const [verifyAddDeliveryAddressInfos, setVerifyAddDeliveryAddressInfos] =
    useState(chinaCompanyVerifyFiels.addDeliveryAddressInfo);

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [status, setStatus] = useState(0);
  const [isVerified, setIsVerified] = useState(false);

  const [showAddDevliveryAddressModal, setShowAddDevliveryAddressModal] =
    useState(false);

  const [locations, setLocations] = useState();
  const [isSupplier, setIsSupplier] = useState(
    user.userInfo.type === UserType.SUPPLIER
  );

  useEffect(() => {
    getVerify();
    loadCompanyDesc(user?.userInfo?.companyId);
  }, []);

  useEffect(() => {
    if (status === CompanyVerifyStatusEnum.VERIFIED) {
      setIsVerified(true);
    }
  }, [status]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setConfigData();
  }, [currentLanguage]);

  const loadCompanyDesc = async (coId) => {
    const pms = { coId };
    let data;
    if (user.userInfo.type == UserType.SUPPLIER) {
      data = await companyWineryDesc(pms);
    } else {
      data = await companyImporterDesc(pms);
    }
    console.log("--------------companyDesc:", data);
    if (data && data.statusCode === statusCode.SUCCESS && data.result) {
      setCompanyDesc(data.result);
    }
  };

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const getVerify = async () => {
    if (!verifyId) return;
    let result = await companyDetail(verifyId);
    console.log("-------company detail:", result);
    if (result) {
      let item = result;

      setVerfiyCompanyInfo(item);
      setStatus(item.status);

      initVerifyInfo(item);
    }
  };

  const initVerifyInfo = (info) => {
    let {
      basicInfo,
      detailInfo,
      invoiceInfo,
      licenseInfo,
      specialInvoiceDeliveryAddress,
      specialInvoiceInfo,
      deliveryAddresses,
      status,
    } = info;
    setVerifyBasicInfos(
      initVerifyInfoValue(basicInfo, verifiyBasicInfos, status)
    );
    setVerifyLicenseInfos(
      initVerifyInfoValue(licenseInfo, verifyLicenseInfos, status)
    );
    setVerifyInvoiceInfos(
      initVerifyInfoValue(invoiceInfo, verifyInvoiceInfos, status)
    );
    setVerifySpecialInvoiceInfos(
      initVerifyInfoValue(specialInvoiceInfo, verifySpecialInvoiceInfos, status)
    );
    setVerifySpecialInvoiceDeliveryAddressInfos(
      initVerifyInfoValue(
        specialInvoiceDeliveryAddress,
        verifySpecialInvoiceDeliveryAddressInfos,
        status
      )
    );

    //处理收货地址数组
    // let temp = addVerifyDeliveryAddressInTable(verfiyDeliveryAddressInfos);
    let tempDevliryAddress = deepClone(verfiyDeliveryAddressInfos);
    if (!tempDevliryAddress.rows) {
      tempDevliryAddress.rows = [];
      tempDevliryAddress.rowsShow = [];
    }
    let tempResult = [];
    deliveryAddresses.map((deliveryAddressItem) => {
      // tempResult.push(
      let result = addVerifyDeliveryAddressInTable(
        tempResult,
        initVerifyInfoValue(
          deliveryAddressItem,
          verifyAddDeliveryAddressInfos,
          status
        )
      );
      if (result) {
        tempDevliryAddress.rows.push(...result.rows);
        tempDevliryAddress.rowsShow.push(...result.rowsShow);
      }

      // );
    });
    setVerfiyDeliveryAddressInfos(tempDevliryAddress);
  };

  const initVerifyInfoValue = (source, target, status) => {
    let tempVerifyInfos = deepClone(target);
    for (const key in source) {
      for (let index = 0; index < tempVerifyInfos.length; index++) {
        const element = tempVerifyInfos[index];
        if (element.name == key) {
          element.value = source[key];
          // element.disabled =
          //   status == CompanyVerifyStatusEnum.VERIFIED ||
          //   status == CompanyVerifyStatusEnum.WAITING_VERIFY
          //     ? true
          //     : false;
          break;
        }
      }
    }
    return tempVerifyInfos;
  };

  async function save() {
    let pms = deepClone(verfiyCompanyInfo);
    pms.label = user?.userInfo?.label;
    pms.coName = pms.basicInfo.coName;
    pms.coNo = pms.licenseInfo.coNo;
    pms.coId = user.userInfo.companyId;

    console.log("----------------------------pms:", pms);

    // let pms = checkForm();
    if (!pms) return;

    let result;
    // if (!isVerified) {
    if (verifyId) {
      pms._id = verifyId;
      // pms.status = status === 0 ? 1 : status;
      result = await verifyUpdate(pms);
    } else {
      result = await verifyAdd(pms);
    }
    // }

    console.log(result);
    if (result) {
      setIsVerified(true);
      toastSuccess(t("SAVE_SUCCESS"));
    } else {
      toastError(t("SAVE_FAILED"));
    }
  }

  const onVerifyBasicInfoFieldsChange = (vals) => {
    setVerifyBasicInfos(vals);
    setVerifyComInfo("basicInfo", vals);
  };

  const onVerifyLicenseFieldsChange = (vals) => {
    setVerifyLicenseInfos(vals);
    setVerifyComInfo("licenseInfo", vals);
  };

  const onDeliveryAddressFieldsChange = (vals) => {
    setVerifyComInfo(isSupplier ? "shippingAddress" : "deliveryAddress", vals);
    setVerifyAddDeliveryAddressInfos(vals);
  };

  const onVerifyInvoiceInfoFieldsChange = (vals) => {
    setVerifyInvoiceInfos(vals);
    setVerifyComInfo("invoiceInfo", vals);
  };

  const onVerifySpecialInvoiceInfoFieldsChange = (vals) => {
    setVerifySpecialInvoiceInfos(vals);
    setVerifyComInfo("specialInvoiceInfo", vals);
  };

  const onVerifySpecialInvoiceDeliveryAddressFieldsChange = (vals) => {
    setVerifySpecialInvoiceDeliveryAddressInfos(vals);
    setVerifyComInfo("specialInvoiceDeliveryAddress", vals);
  };

  const onAddDeliveryAddressSubmit = () => {
    let temp = addVerifyDeliveryAddressInTable(
      verfiyDeliveryAddressInfos,
      verifyAddDeliveryAddressInfos
    );
    console.log("onAddDeliveryAddressSubmit:", temp);
    setVerfiyDeliveryAddressInfos(temp);
    setShowAddDevliveryAddressModal(false);

    let tempVerifyCompanyInfo = deepClone(verfiyCompanyInfo);
    let tempAddresses = [];
    temp.rows.map((rowItem) => {
      let value = {};
      rowItem.map((mapItem) => {
        // console.log(mapItem);
        value[mapItem.name] = mapItem.value;
      });
      tempAddresses.push(value);
    });
    tempVerifyCompanyInfo["deliveryAddresses"] = tempAddresses;
    setVerfiyCompanyInfo(tempVerifyCompanyInfo);
  };

  const addVerifyDeliveryAddressInTable = (source, locationItem) => {
    let temp = deepClone(source);
    if (!temp.rows) {
      temp.rows = [];
      temp.rowsShow = [];
    }

    temp.rows.push(deepClone(locationItem));

    console.log("locationItem", locationItem);
    let tempItems = deepClone(locationItem);
    let tempShows = [];
    tempShows.push(
      ...tempItems.filter(
        (filterItem) =>
          filterItem.name == "contactUserName" ||
          filterItem.name == "contactPhoneNumber"
      )
    );
    let addressStr = "";
    //国家，城市
    let locationFields = tempItems.filter(
      (filtetItem) => filtetItem.name == "location"
    );
    if (locationFields && locationFields.length > 0) {
      addressStr += getChinaCityNameById([locationFields[0].value.city]);
      addressStr += locationFields[0].value.address.name;
    }

    tempShows.push({ value: addressStr });
    tempShows.push({ action: ["EDIT", "DELETE"] });
    temp.rowsShow.push(tempShows);

    return temp;
  };

  const onDeleteDeliveryAddress = (rowIndex) => {
    let temp = deepClone(verfiyDeliveryAddressInfos);

    temp.rows.splice(rowIndex, 1);
    temp.rowsShow.splice(rowIndex, 1);

    setVerfiyDeliveryAddressInfos(temp);
    setShowAddDevliveryAddressModal(false);
  };

  const setVerifyComInfo = (name, vals) => {
    console.log("setVerifyComInfo:", vals);
    let temp = deepClone(verfiyCompanyInfo);
    let value = {};
    vals.map((mapItem) => {
      // console.log(mapItem);
      value[mapItem.name] = mapItem.value;
    });
    temp[name] = value;
    // console.log(value);
    setVerfiyCompanyInfo(temp);
  };

  const switchInvoiceType = () => {
    setNormalInvoice(!normalInvoice);
  };

  const required_span = <span className="required-tip">*</span>;

  return (
    <div className="company-verify-page">
      <div className="common-management" style={{ paddingBottom: "128px" }}>
        <div className="company-info">
          <div className="company-info-item">
            <span>{t("VINBIP_USERNAME")}:</span>
            <span>
              {user?.userInfo?.firstName}
              {user?.userInfo?.lastName}
            </span>
          </div>
          <div className="company-info-item">
            <span style={{ marginRight: "36px" }}>{t("COMPANY_CATEGORY")}</span>
            <span className="company-type">{t(user?.userInfo?.label)}</span>
            {/* <img
              src={localizeImg(companyDesc?.avatar)}
              className="company-avatar"
            /> */}
          </div>
          <div className="company-info-item">
            <span style={{ marginRight: "36px" }}>{t("VERIFY_TYPE")}</span>
            <span className="company-type">{t(user?.userInfo?.type)}</span>
          </div>
        </div>
        <div className="verify-part-navigator">
          <img src={basicInfoIcon} className="navigator-icon" />
          <span className="navigator-text">{t("BASIC_INFO")}</span>
        </div>
        <div
          style={{
            margin: "10px 149px 20px 60px",
          }}
        >
          <FormNormalW
            disabled={isVerified}
            items={verifiyBasicInfos}
            onChange={onVerifyBasicInfoFieldsChange.bind(this)}
          />
        </div>
        <div className="verify-part-navigator">
          <img src={licenseIcon} className="navigator-icon" />
          <span className="navigator-text">
            {t("UPLOAD_LICENSE_AND_COMPANY_LICENSE")}
          </span>
        </div>
        <div style={{ marginBottom: "20px", marginTop: "20px" }}>
          <FormNormalW
            items={verifyLicenseInfos}
            disabled={isVerified}
            onChange={onVerifyLicenseFieldsChange.bind(this)}
          />
        </div>
      </div>
      <Row
        className="row-submit-button"
        style={{
          width: window.innerWidth - 569 + "px",
        }}
      >
        <Button
          onClick={save.bind(this)}
          className="save-btn"
          disabled={isVerified}
        >
          {t("SUBMIT")}
        </Button>
      </Row>
      <Modal
        destroyOnClose={true}
        title={
          isSupplier ? t("NEW_SHIPPING_ADDRESS") : t("NEW_DELIVERY_ADDRESS")
        }
        visible={showAddDevliveryAddressModal}
        footer={null}
        className="add-delivery-address-modal"
        onCancel={() => setShowAddDevliveryAddressModal(false)}
        width={500}
      >
        <div className="company-verify-page">
          <FormNormalW
            items={verifyAddDeliveryAddressInfos}
            onChange={onDeliveryAddressFieldsChange.bind(this)}
          />
          <Button
            className="btn-add-delivery-address"
            onClick={onAddDeliveryAddressSubmit.bind(this)}
          >
            {t("CREATE")}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
