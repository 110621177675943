import { RequestType } from "../constants/requestType.enum"
import { actionRequest } from "./api"
import apiPath from "./apipath"
import { requestGetUrlFormat } from "./common"

/**
 * Save the company description for an importer
 * @param pms
 */
export async function getAmityChannelId(pms: any) {
    return actionRequest(
      RequestType.GET,
      requestGetUrlFormat(apiPath.amityChannelId, pms),
    )
  }
  
  /**
   * Add the company description for an importer
   * @param pms
   */
  export async function createAmityChannel(pms: any) {
    return actionRequest(RequestType.POST, apiPath.amityCreateChannel, pms)
  }
  
  
  /**
   * Save the company description for an importer
   * @param pms
   */
  export async function amityChannelInfo(pms: any) {
    return actionRequest(
      RequestType.GET,
      requestGetUrlFormat(apiPath.amityChannelInfo, pms),
    )
  }