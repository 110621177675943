import React, { useEffect, useState } from "react";
import styles from "./FilterItem.module.sass";
import { useTranslation } from "react-i18next";
import { Input } from "antd";

const FilterItem = ({ title, showSearch, dataSource, onSelected,onSearch }) => {
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    setDataList(dataSource);
  }, [dataSource]);

  const onItemClick = (item) => {
    onSelected && onSelected(item);
  };

  const onSearchChange=(e)=>{
    onSearch&&onSearch(e.target.value);
  }

  return (
    <div className={styles.typeSelectContainer}>
      <div className={styles.typeTitle}>
        <span>{title}</span>
      </div>
      <div className={[styles.typeContentPannel, styles.discoverDropdownMenu]}>
        {showSearch && (
          <div className={styles.typeSearchPannel}>
            <Input
              bordered={false}
              placeholder={t("SEARCH")}
              onChange={onSearchChange.bind(this)}
              className={styles.searchKeyword}
            />
          </div>
        )}
        <div className={styles.dataSourcePanel}>
          {dataList.map((item) => (
            <div
              className={styles.dataSrouceItem}
              onClick={onItemClick.bind(this, item)}
            >
              <div
                className={
                  item.selected ? styles.dropDotSelected : styles.dropDot
                }
              ></div>
              <span className={styles.dropItemText}>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterItem;
