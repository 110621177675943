import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {loginWithEmail, setBearerToken} from "../../api/api";
import {decodeToken} from "../../utils/jwt";
import {userDetail} from "../../api/user";
import {ITokens, IUserInformation} from "../../interfaces/IUser";
import {IPageInfo} from "../../interfaces/PageInfo";

const tokenCache: ITokens = {
    value: '',
    expireTime: 0
};

const userInfo : IUserInformation = {
    firstName: '',
    lastName: '',
    nickName : '',
    signature : '',
    type: '',
    country:'',
    language:'',
    picture:'',
    phoneNumber : '',
    createdAt: null,
    updatedAt: null,
}

const initialState = {
    userId: '',
    email: '',
    token: tokenCache,
    userInfo: userInfo,
    isFetching: false,
    isSuccess: false,
    isError: false,
    refresh: false,
    errorMessage: "",
}


export const loginUser:any = createAsyncThunk(
    'users/login',
    async ({ email, password }:any, thunkAPI) => {
        try {

            let result: IPageInfo = await loginWithEmail(email, password) as IPageInfo

            if (result.statusCode === 602) {
                return thunkAPI.rejectWithValue(result);
            } else if (result.statusCode === 200) {
                const jsonWebToken = result.result.access_token

                await setBearerToken(jsonWebToken)

                let decoded:any = decodeToken(jsonWebToken);

                let token:ITokens = {
                    value:jsonWebToken,
                    expireTime:decoded.exp
                }


                return {
                    token: token,
                    user: result.result.user
                };
            } else {
                return thunkAPI.rejectWithValue(result);
            }


        } catch (e) {
            console.log('Error', e );
            thunkAPI.rejectWithValue(e );
        }
    }
);

export const refreshUser:any = createAsyncThunk(
    'users/refresh',
    async ({ userId }:any, thunkAPI) => {
        try {
            let data = await userDetail(userId) as IPageInfo;
            return data.result

        }catch (e) {
            console.log('Error', e );
            thunkAPI.rejectWithValue(e );
        }

    }
)


export const usersSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;

            return state;
        },
        refreshUserId: (state,{payload}) =>{
            state.isError = false;
            state.isSuccess = true;
            state.isFetching = false;
            state.userId = payload.userId
        },
        refreshUserToken: (state,{payload}) =>{
            state.isError = false;
            state.isSuccess = true;
            state.isFetching = false;
            state.token.value = payload.token

            setBearerToken(payload.token)
        },
        refreshUserDone: (state,{payload}) =>{
            state.refresh = false;
        },
    },
    extraReducers: {
        [loginUser.fulfilled]: (state, {payload}) => {

            state.userId = payload.user._id;
            state.email = payload.user.email;

            state.userInfo.country = payload.user.country ? payload.user.country : '';
            state.userInfo.language = payload.user.language ? payload.user.language : '';
            state.userInfo.firstName = payload.user.firstName ? payload.user.firstName : '';
            state.userInfo.lastName = payload.user.lastName ? payload.user.lastName : '';
            state.userInfo.type = payload.user.type ? payload.user.type : '';

            state.token = payload.token
            state.isFetching = false;
            state.isSuccess = true;

            return state;
        },
        [loginUser.rejected]: (state, {payload}) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload.message;
        },
        [loginUser.pending]: (state) => {
            state.isFetching = true;
        },

        [refreshUser.fulfilled]: (state , {payload}) => {

            state.userInfo.firstName = payload.firstName;
            state.userInfo.lastName = payload.lastName;
            state.userInfo.language = payload.language;
            state.userInfo.country = payload.country;
            state.userInfo.picture = payload.picture;
            state.userInfo.nickName = payload.nickName;
            state.userInfo.phoneNumber = payload.phoneNumber;
            state.userInfo.createdAt = payload.createdAt;
            state.userInfo.updatedAt = payload.updatedAt;
            state.userInfo.type = payload.type;
            state.email = payload.email;
            state.refresh = true;

        }
    }
})

export const { refreshUserDone } = usersSlice.actions;
export const { refreshUserToken } = usersSlice.actions;
export const { refreshUserId } = usersSlice.actions;
export const { clearState } = usersSlice.actions;


export const userSelector = (state:any) => state.users;
