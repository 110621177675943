import React, { useEffect, useState } from "react";
import styles from "./UserItem.module.sass";
import { useTranslation } from "react-i18next";
import { loadProductConfig } from "../../../api/product";
import { Link, useHistory } from "react-router-dom";
import AvatarBasic from "../../../components/common/avatar/AvatarBasic";
import { Path, PathTools } from "../../../constants/path";
import { localizeImg, localizeValue } from "../../../constants/constants";
import { getCountryNameById } from "../../../api/config";
import locationIcon from "../../../resource/icons/user-profile/location.svg";
import verifiedIcon from "../../../resource/icons/user-profile/verified.svg";

const UserItem = ({ item }) => {
  const { t, i18n } = useTranslation();

  const history = useHistory();

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setConfigData();
  }, []);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const onCompanyHome = (userId, companyName) => {
    history.push(PathTools.joinPath(Path.companyDetail, companyName), {
      id: userId,
    });
  };

  return (
    <Link
      className={styles.item}
      onClick={onCompanyHome.bind(
        this,
        item._id,
        localizeValue(i18n.language, item.companyInfo?.companyName)
      )}
    >
      <div className={styles.avatar}>
        <AvatarBasic
          picture={localizeImg(item?.companyInfo?.avatar)}
          size={64}
        />
      </div>
      <div className={styles.userInfo}>
        <div className={styles.title}>
          {localizeValue(i18n.language, item.companyInfo?.companyName)}
        </div>
        {item?.companyInfo?.location?.country && (
          <div className={styles.userAttchInfo}>
            <div>
              <img
                src={locationIcon}
                style={{
                  width: "8.12px",
                  height: "9.2px",
                  marginRight: "5px",
                }}
              />
            </div>
            <div>
              {getCountryNameById(
                locations,
                i18n.language,
                item?.companyInfo?.location?.country
              )}
            </div>
          </div>
          )}
      </div>
    </Link>
  );
};

export default UserItem;
