import React, { useEffect, useState } from "react";
import { Dropdown, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { getOrders } from "../../../api/orders";
import OrdersListItem from "./OrdersListItem";
import { dateFormat } from "../../../utils/date";
import ListBasic from "../../common/list/ListBasic";
import { OrderStatus } from "../../../constants/statusCode.enum";
import "./style/orders.less";
import BreadcrumbNormal from "../../common/title/BreadcrumbNormal";
import { useParams } from "react-router-dom";
import { getAliPayForm } from "../../../api/aliPay";
import moreIcon from "../../../resource/icons/dashboard/order/more.svg";
import downArrowIcon from "../../../resource/icons/dashboard/order/down_arrow.svg";

export default function Orders() {
  const { index } = useParams();
  const { t } = useTranslation();
  const { isWinery, user } = useUserId();
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [monthOrderList, setMonthOrderList] = useState([]);
  const [waitingPayOrderList, setWaitingPayOrderList] = useState([]);
  const [paiedOrderList, setPaiedOrderList] = useState([]);
  const [unDeliveredOrderList, setUnDeliveredOrderList] = useState([]);
  const [unReceivedOrderList, setUnReceivedOrderList] = useState([]);
  const [cancelOrderList, setCancelOrderList] = useState([]);
  const [completeOrderList, setCompleteOrderList] = useState([]);
  const [deliveredOrderList, setDeliveredOrderList] = useState([]);
  const [receivedOrderList, setReceivedOrderList] = useState([]);
  const pageSize = 20;
  const [pageIndex, setPageIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState("1");
  const [aliPayForm, setAliPayForm] = useState();

  const [filterByMonthContent, setFilterByMonthContent] = useState(
    t("PAST_THREE_MONTH")
  );

  useEffect(() => {
    loadData();
  }, [user]);

  useEffect(() => {
    console.log("---------orders:", index);
    if (index) {
      setTabIndex(index);
    } else {
      setTabIndex("1");
    }
  }, [index]);

  const loadData = async () => {
    let pms = { page: pageIndex, per_page: pageSize };
    if (isWinery) {
      pms.coId = user.userInfo.companyId;
    } else {
      pms.userId = user.userId;
    }

    let result = await getOrders(pms);
    console.log("--------orders :", pms, result);
    if (!result) return;
    setOrderList(result);
    result = result.filter((item) => item.products && item.products.length > 0);

    let nowDate = new Date();
    let currentMonth = dateFormat(nowDate, "yyyy-MM");
    let list = result.filter(
      (item) =>
        dateFormat(item.orderPrice.createdAt, "yyyy-MM") === currentMonth
    );
    setMonthOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.WaitingForPay);
    setWaitingPayOrderList(list);

    list = result.filter((item) => item.payStatus === OrderStatus.Payed);
    setPaiedOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.Confirmed);
    setUnDeliveredOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.Sendout);
    setUnReceivedOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.Cancelled);
    setCancelOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.Complete);
    setCompleteOrderList(list);

    list = result.filter(
      (item) =>
        item.status === OrderStatus.Delivered ||
        item.status === OrderStatus.Transiting
    );
    setDeliveredOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.Received);
    setReceivedOrderList(list);

    setLoading(false);
  };
  const itemLoadData = () => {
    loadData();
  };
  const onRenderOrderItem = (item, index) => {
    return (
      <OrdersListItem
        item={item}
        index={index}
        itemLoadData={itemLoadData.bind(this)}
        onAliPay={onAliPay.bind(this)}
      />
    );
  };

  const onAliPay = async (order) => {
    if (order) {
      let result = await getAliPayForm(order);
      if (result) {
        setAliPayForm(result);
      }
    }
  };

  useEffect(() => {
    if (aliPayForm) {
      let forms = document.forms;
      if (forms && forms.length > 0) {
        forms[0].submit();
      }
    }
  }, [aliPayForm]);

  const filterByMonth = (month, text) => {
    setFilterByMonthContent(t(text));
  };

  const menu_filter_time = () => {
    return (
      <div
        // onClick={onStockChange.bind(this)}
        className="order-listy-filter-select-panel-buyer"
      >
        <div
          className="address-item"
          onClick={filterByMonth.bind(this, 1, "PAST_ONE_MONTH")}
        >
          {t("PAST_ONE_MONTH")}
        </div>
        <div
          className="address-item"
          onClick={filterByMonth.bind(this, 2, "PAST_TWO_MONTH")}
        >
          {t("PAST_TWO_MONTH")}
        </div>
        <div
          className="address-item"
          onClick={filterByMonth.bind(this, 3, "PAST_THREE_MONTH")}
        >
          {t("PAST_THREE_MONTH")}
        </div>
      </div>
    );
  };

  return (
    <div
      className="common-page"
      style={{
        paddingTop: "25px",
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* <BreadcrumbNormal title={t("SHOP_ORDERS")} parent={t("MY_SHOP")} /> */}

      <div className="company-orders-container common-management ">
        {/* <div className="orders-title common-management-title">
          {t("SHOP_ORDERS")}
        </div>
        <div className="orders-banner common-management-panel">
          <div className="orders-banner-item">
            <div className="item-title">{t("THIS_MONTH_ORDER")}</div>
            <div className="item-number">{monthOrderList.length}</div>
          </div>
          <div className="orders-banner-item">
            <div className="item-title">{t("TOTAL_NUMBER_OF_ORDER")}</div>
            <div className="item-number">{orderList.length}</div>
          </div>
          <div className="orders-banner-item">
            <div className="item-title">{t("ALREADY_PAYED_ORDER")}</div>
            <div className="item-number">{paiedOrderList.length}</div>
          </div>
          <div className="orders-banner-item">
            <div className="item-title">{t("ALREADY_COMPLETED_ORDER")}</div>
            <div className="item-number">{completeOrderList.length}</div>
          </div>
        </div> */}
        <Tabs
          activeKey={tabIndex}
          className="orders-tabs"
          onChange={(key) => setTabIndex(key)}
          moreIcon={<img src={moreIcon} />}
        >
          <div className="orders-tabs-filter-panel">
            <span className="label">{t("VIEW_MY_ORDERS_IN")}</span>
            <Dropdown overlay={menu_filter_time()} trigger={["click"]}>
              <div className="time-filter-panel">
                {filterByMonthContent}
                <img src={downArrowIcon} style={{ marginLeft: "11px" }} />
              </div>
            </Dropdown>
          </div>
          {/* <TabPane tab={t("THIS_MONTH_ORDER")} key="1">
            <ListBasic
              data={monthOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane> */}
          <TabPane tab={t("ALL_ORDER")} key="1">
            <ListBasic
              data={orderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("ORDER_WAITING_PAYMENT")} key="2">
            <ListBasic
              data={waitingPayOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("WAITING_TO_DELIVER")} key="3">
            <ListBasic
              data={unDeliveredOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("DELIVERY_SEND")} key="4">
            <ListBasic
              data={unReceivedOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("WAITING_RECEIVED")} key="5">
            <ListBasic
              data={deliveredOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("RECEIVED")} key="6">
            <ListBasic
              data={receivedOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("ORDER_CANCEL")} key="7">
            <ListBasic
              data={cancelOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("ORDER_COMPLETE")} key="8">
            <ListBasic
              data={completeOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
        </Tabs>
      </div>
      <div dangerouslySetInnerHTML={{ __html: aliPayForm }}></div>
    </div>
  );
}
