import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import { useTranslation } from "react-i18next";

const Panel = ({ onPublish }) => {
  const { t } = useTranslation();

  return (
    <div className={cn("panel", styles.panel)}>
      <button
        className={cn("button", styles.button,styles.submitButton)}
        onClick={() => onPublish && onPublish()}
      >
        {t("PUBLISH_NOW")}
      </button>
    </div>
  );
};

export default Panel;
