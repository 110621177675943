import { PlusOutlined } from "@ant-design/icons";
import "./style/productImageUpload.less";
import { useTranslation } from "react-i18next";
import VideoBasic from "../video/VideoBasic";
import { localizeImg } from "../../../constants/constants";
import { useEffect, useState } from "react";
import cameraIcon from "../../../resource/icons/product-create/camera_grey.svg";

export default function ProductVideoUpload(props) {
  const { t } = useTranslation();
  const { title, url } = props;
  const [videoUrl, setVideoUrl] = useState();

  useEffect(() => {
    if (url) {
      setVideoUrl(localizeImg(url));
    } else {
      setVideoUrl(null);
    }
  }, [url]);
  return (
    <>
      {url ? (
        <VideoBasic url={localizeImg(videoUrl)} width={270} height={170} />
      ) : (
        <div className="product-video-upload-container">
          <img src={cameraIcon} className="cameraIcon" />
        </div>
      )}
    </>
  );
}
