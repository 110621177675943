import { create } from "apisauce";
import { IPageInfo } from "../interfaces/PageInfo";
import apiPath from "./apipath";
import { getCache, setCache } from "../utils/cache";
import { RequestType } from "../constants/requestType.enum";
import { statusCode } from "../constants/statusCode.enum";
import jwt_decode from "jwt-decode";
import { SysServiceUrl } from "../constants/constants";

const tokenPre = "Bearer ";
export const baseURL = SysServiceUrl;
const api = create({
  baseURL: baseURL,
  headers: { Accept: "application/json" },
});

/**
 * @description Set the token in the auth header
 * @param token
 */
export async function setBearerToken(token: any) {
  let decoded: any = jwt_decode(token);

  await setCache("token", {
    expireTime: decoded.exp,
    value: token,
  });

  api.setHeader("Authorization", tokenPre + token);
}

/**
 * @description Request to auth with email
 * @param email
 * @param password
 */
export async function loginWithEmail(
  email: string,
  password: string
): Promise<IPageInfo | undefined> {
  let result;

  await api
    .post(apiPath.login, { email: email, password: password })
    .then((value) => {
      result = value.data as IPageInfo;
    })
    .catch(() => {
      result = {
        message: "Error in auth request",
        result: "",
        statusCode: 605,
      };
    });

  return result;
}

/**
 * @description Make a Get request with the token in the header
 * @param url
 * @param data
 */
async function getRequest(url: string, data?: any) {
  return api.get(url, data);
}

/**
 * @description Make a post request with the token in the header
 * @param url
 * @param data
 * @constructor
 */
async function postRequest(url: string, data?: any) {
  return api.post(url, data);
}

async function deleteRequestWithToken(url: string, data?: any) {
  return api.delete(url, data);
}

/**
 * @description Make an HTTP request based on the Request type .
 * @param flag
 * @param url
 * @param pms
 */
export async function actionRequest(flag: RequestType, url: string, pms?: any) {
  let result;

  if (!api.headers.Authorization) {
    let token = await getCache("token");
    if (!token) {
      /**
       * There is no token in local storage
       * Need to implement a refresh token or a force logout
       * todo implement
       */
    } else {
      api.setHeader("Authorization", tokenPre + token.value);
    }
  }

  try {
    switch (flag) {
      case RequestType.GET:
        result = await getRequest(url, pms);
        break;
      case RequestType.POST:
        result = await postRequest(url, pms);
        break;
      case RequestType.FILE:
        /**
         * Todo upload file
         */
        //result = await uploadFile(url, pms);
        break;
      case RequestType.DELETE:
        result = await deleteRequestWithToken(url, pms);
        break;
      default:
        break;
    }
    // @ts-ignore
    result = result.data;
  } catch (error) {
    console.log("action:", url, pms, error);
  }
  return result;
}

/**
 * @description Extract the data from the response.
 * @param data
 */
export function formatResponse(data: any) {
  let result: any;
  if (data && data.statusCode === statusCode.SUCCESS) {
    result = data.result;
  }
  return result;
}

export function formatResponseWithError(data: any) {
  let result = data;
  if (data && data.statusCode === statusCode.SUCCESS) {
    result = data.result;
  }
  return result;
}

/**
 * @description Make a request without token in the header
 * @deprecated The token is automatically handle in the request now.
 * @param flag
 * @param url
 * @param pms
 */
export async function actionRequestNoToken(
  flag: number,
  url: string,
  pms?: any
) {
  let result: any;

  try {
    switch (flag) {
      case RequestType.GET:
        result = await getRequest(url, pms);
        break;
      case RequestType.POST:
        result = await postRequest(url, pms);
        break;
      default:
        break;
    }
    result = result.data;
  } catch (error) {
    console.error("actionrequest", error);
  }
  return result;
}

/**
 * @description format the response inside an array
 * @param data
 */
export function formatResponseArray(data: any) {
  let result = [];
  if (data && data.statusCode === statusCode.SUCCESS) {
    let list = data.result;
    if (list) {
      result = list.result;
    }
  }
  return result;
}

/**
 *
 * @param type
 * @param url
 * @param pms
 */
export async function formatActionResponseArrayLength(
  type: RequestType,
  url: string,
  pms: any
) {
  let data: any = await actionRequest(type, url, pms);
  let result = 0;
  if (data && data.statusCode === statusCode.SUCCESS) {
    let list = data.result;
    if (list) {
      result = list.total;
    }
  }
  return result;
}

/**
 *
 * @param type
 * @param url
 * @param pms
 */
export async function formatActionResponseArray(
  type: RequestType,
  url: string,
  pms: any
) {
  let data = await actionRequest(type, url, pms);
  return formatResponseArray(data);
}

/**
 * @description extract the result from an action request
 * @param type
 * @param url
 * @param pms
 */
export async function formatActionResponse(
  type: RequestType,
  url: string,
  pms: any
) {
  let data = await actionRequest(type, url, pms);
  return formatResponse(data);
}

/**
 *
 * @param data
 */
export function formatRespArray<T>(data: any): Promise<Array<T>> {
  return new Promise((resolve, _reject) => {
    let result = [];
    if (data && data.statusCode === statusCode.SUCCESS) {
      let list = data.result;
      if (list.result) {
        result = list.result;
      } else {
        result = list;
      }
    }
    resolve(result);
  });
}

/**
 * @description Make a Get request with the token in the header
 * @param url
 * @param data
 */
export function openRequest(url: string, data?: any) {
  return api.get(url, data);
}
