import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isEmail } from "../../utils/regex";
import { resetPwd, getEmailCapture, sendSms } from "../../api/login";
import loginBg from "../../resource/images/login/login_bg.png";
import logo from "../../resource/images/logo.png";
import { Button, Divider, message, Image, Input } from "antd";
import "./style/forgotpwd.less";
import { statusCode } from "../../constants/statusCode.enum";
import { useHistory } from "react-router-dom";
import { toastError, toastInfo } from "../../api/toast";
import BackIcon from "../common/back/BackIcon";

export default function ForgotPwd() {
  const { t } = useTranslation();
  const history = useHistory();

  let [email, setEmail] = useState("");
  let [pwd, setPwd] = useState("");
  let [capture, setCapture] = useState("");
  let [captureInput, setCaptureInput] = useState("");
  const [timerStatus, setTimerStatus] = useState(false);
  let countNum = 60;
  const [countDown, setCountDown] = useState(countNum);

  let timer;
  useEffect(() => {
    if (timerStatus) {
      timer = setInterval(() => {
        setCountDown(countDown - 1);
        if (countNum === 0) {
          clearInterval(timer);
          setCountDown(60);
          setTimerStatus(false);
        } else {
          countNum--;
          setCountDown(countNum);
        }
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [timerStatus]);

  const onSendCode = async () => {
    if (email) {
      setTimerStatus(true);
      if (isEmail(email)) {
        let pms = { email };
        let data = await getEmailCapture(pms);
        console.log('-----send email result:', data);
        if (data.statusCode === statusCode.SUCCESS) {
          setCapture(data.result.capture);
          // setCaptureInput(data.result.capture);
        }
      } else {
        let pms = {
          phone: email,
          smsType: "Login",
        };
        let result = await sendSms(pms);
        if (result) {
          setCapture(result.capture);
        }
        console.log('-----send sms result:', result);
      }
    } else {
      toastInfo(t("ACCOUNT_IS_EMPTY"));
    }
  }

  const save = async () => {
    if (!email) {
      toastError(t("ACCOUNT_IS_EMPTY"));
      return;
    }

    if (!pwd) {
      toastError(t("PASSWORD_INPUT_EMPTY"));
      return;
    }
    if (!(capture && captureInput && capture === captureInput)) {
      toastError(t("CAPTURE_ERROR_MESSAGE"));
      return;
    }
    let pms = { password: pwd, capture };
    if (isEmail(email)) {
      pms.email = email;
    } else {
      pms.phone = email;
    }

    let data = await resetPwd(pms);
    console.log('------reset pwd:', data)
    if (data.statusCode === statusCode.SUCCESS) {
      history.replace("/login");
      toastInfo(t("SUCCESS"));
    }else if(data.statusCode === statusCode.NOTEXISTS){
      toastError(t("NOT_THE_USER"));
    } else {
      toastError(t("SAVE_FAILED"));
    }
  };

  return (
    <div className="forgetpwd-page">
      <div className="reg-left-panel">
        <img
          src={loginBg}
          className="login-left-img"
          alt={"People drinking wine"}
        />
      </div>
      <div className="login-right">
        <div className="back-panel">
          <BackIcon />
        </div>
        <div className="login-content-wrapper">
          <div className="login-content">
            <div className="logo-panel">
              <Image width={84} height={84} src={logo} preview={false} />
            </div>
            <div className="login-tips-panel">
              <div className="login-tips">{t("RESET_YOUR_PASSWORD")}</div>
            </div>
            <div className="login-tips-panel">
              <div className="login-sub-tips">
                {t("ALREADY_A_MEMBER")}? <a href="login">{t("SIGN_IN")}</a>
              </div>
            </div>
            <div className="phone-panel">
              <div className="phone-input-before">
                <Input
                  placeholder={t("EMAIL") + " / " + t("PHONE_NUMBER")}
                  type="text"
                  className="login-input"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  autoComplete='new-password'
                />
              </div>
            </div>
            <div className="phone-panel">
              <div className="phone-input-before">
                <Input.Password
                  type="password"
                  placeholder={t("NEW_PASSWORD")}
                  bordered={false}
                  style={{ border: "none" }}
                  className="login-input"
                  onChange={(e) => {
                    setPwd(e.target.value);
                  }}
                  autoComplete='new-password'
                />
                <Input.Password
                  type="password"
                  placeholder={t("NEW_PASSWORD")}
                  bordered={false}
                  style={{ border: "none",display:'none' }}
                  className="login-input"
                  onChange={(e) => {
                    setPwd(e.target.value);
                  }}
                  autoComplete='new-password'
                />
              </div>
            </div>
            <div className="capture-panel">
              <Input
                className="capture-input login-input"
                type="text"
                style={{ border: "none" }}
                name={t("CAPTURE_CODE")}
                placeholder={t("CAPTURE_CODE")}
                value={captureInput}
                onChange={(e) => {
                  setCaptureInput(e.target.value);
                }}
                autoComplete='off'
              />
              <Divider type="vertical" className="" />
              {timerStatus ? (
                <div className="count-down">
                  {t("RETRY_IN")} : {countDown} {t("SECOND")}
                </div>
              ) : (
                <div className="capture-btn" onClick={onSendCode.bind(this)}>
                  {t("SEND_CODE")}
                </div>
              )}
            </div>
            <Button className="btn-login" onClick={save.bind(this)}>
              {t("RESET_PASSWORD")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
