export function getTimeBefore(time: string) {
  if (!time) {
    return null;
  }
  let date = new Date(time);
  let curDate = new Date();
  let currentTime = curDate.getTime();
  let beforeMinutes_1 = new Date(currentTime);
  beforeMinutes_1.setMinutes(beforeMinutes_1.getMinutes() - 1);
  let beforeMinutes_3 = new Date(currentTime);
  beforeMinutes_3.setMinutes(beforeMinutes_3.getMinutes() - 3);
  let beforeMinutes_10 = new Date(currentTime);
  beforeMinutes_10.setMinutes(beforeMinutes_10.getMinutes() - 10);
  let beforeMinutes_30 = new Date(currentTime);
  beforeMinutes_30.setMinutes(beforeMinutes_30.getMinutes() - 30);
  let beforeHours_1 = new Date(currentTime);
  beforeHours_1.setHours(beforeHours_1.getHours() - 1);
  let beforeHours_3 = new Date(currentTime);
  beforeHours_3.setHours(beforeHours_3.getHours() - 3);
  let beforeHours_10 = new Date(currentTime);
  beforeHours_10.setHours(beforeHours_10.getHours() - 10);
  let beforeDays_1 = new Date(currentTime);
  beforeDays_1.setDate(beforeDays_1.getDate() - 1);
  let result;
  if (date > beforeMinutes_1) {
    result = "TIME_JUST";
  } else if (date > beforeMinutes_3) {
    result = "TIME_MINUTE_ONE";
  } else if (date > beforeMinutes_10) {
    result = "TIME_MINUTE_THREE";
  } else if (date > beforeMinutes_30) {
    result = "TIME_MINUTE_TEN";
  } else if (date > beforeHours_1) {
    result = "TIME_HOUR_HALF";
  } else if (date > beforeHours_3) {
    result = "TIME_HOUR_ONE";
  } else if (date > beforeHours_10) {
    result = "TIME_HOUR_THREE";
  } else if (date > beforeDays_1) {
    result = "TIME_HOUR_TEN";
  } else {
    // const dateParts = time.split(' ')
    // const day = dateParts[0]
    result = dateFormat(time, "yyyy-MM-dd hh:mm");
  }
  return result;
}

export function getCurrentTime() {
  let curDate = new Date();
  return dateFormat(curDate, "yyyy-MM-dd hh:mm:ss");
}

export function getTimeStamp() {
  let curDate = new Date();
  return curDate.getTime();
}

export function getOrderNumber() {
  let num = Math.floor(Math.random() * 1000);
  return getTimeStamp() + num.toString();
}

function formatDateTimeString(str: string) {
  let result = str.replace("T", " ").replace(/-/g, "/").replace("Z", "");
  if (result.length === 23) {
    return result.substring(0, 19);
  }
  return result;
}

export function getDateDiff(startDate: any, endDate: any) {
  startDate =
    typeof startDate === "string"
      ? new Date(formatDateTimeString(startDate))
      : startDate;
  endDate =
    typeof endDate === "string"
      ? new Date(formatDateTimeString(endDate))
      : endDate;
  // console.log('----date diff:', startDate, endDate)
  let startTime = startDate.getTime();
  let endTime = endDate.getTime();
  let dayCount = Math.floor((endTime - startTime) / (1000 * 60 * 60 * 24));
  return dayCount;
}

export function dateFormat(inputTime: any, fmt?: string) {
  let date = new Date();
  if (typeof inputTime === "object") {
    date = inputTime;
  } else {
    if (inputTime) {
      inputTime = formatDateTimeString(inputTime);
      date = new Date(inputTime);
    } else {
      return "";
    }
  }
  if (!fmt) {
    fmt = "yyyy/MM/dd";
  }
  return format(date, fmt);
}

function format(date: any, formatStr: string) {
  /*
    函数：填充0字符
    参数：value-需要填充的字符串, length-总长度
    返回：填充后的字符串
    */
  let zeroize = function (value: any, length?: number) {
    if (!length) {
      length = 2;
    }
    value = String(value);
    for (var i = 0, zeros = ""; i < length - value.length; i++) {
      zeros += "0";
    }
    return zeros + value;
  };
  return formatStr.replace(
    /"[^"]*"|'[^']*'|\b(?:d{1,5}|M{1,4}|yy(?:yy)?|([hHmstT])\1?|[lLZ])\b/g,
    function ($0) {
      switch ($0) {
        case "d":
          return date.getDate();
        case "dd":
          return zeroize(date.getDate());
        case "ddddd":
          return ["周日", "周一", "周二", "周三", "周四", "周五", "周六"][
            date.getDay()
          ];
        case "ddd":
          return ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"][
            date.getDay()
          ];
        case "dddd":
          return [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ][date.getDay()];
        case "M":
          return date.getMonth() + 1;
        case "MM":
          return zeroize(date.getMonth() + 1);
        case "MMM":
          return [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ][date.getMonth()];
        case "MMMM":
          return [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ][date.getMonth()];
        case "yy":
          return String(date.getFullYear()).substr(2);
        case "yyyy":
          return date.getFullYear();
        case "h":
          return date.getHours() % 12 || 12;
        case "hh":
          return zeroize(date.getHours() % 12 || 12);
        case "H":
          return date.getHours();
        case "HH":
          return zeroize(date.getHours());
        case "m":
          return date.getMinutes();
        case "mm":
          return zeroize(date.getMinutes());
        case "s":
          return date.getSeconds();
        case "ss":
          return zeroize(date.getSeconds());
        case "l":
          return date.getMilliseconds();
        case "ll":
          return zeroize(date.getMilliseconds());
        case "tt":
          return date.getHours() < 12 ? "am" : "pm";
        case "TT":
          return date.getHours() < 12 ? "AM" : "PM";
      }
    }
  );
}

/**
 * @description: 获取n年前指定日期
 * @param {*} n
 * @return {*} Unix Time Stamp e.g: 1652054400000
 */
export const getYearAgoDate = (n = 0) => {
  let curDate = new Date();
  curDate.setFullYear(curDate.getFullYear() - n);
  const ts = +curDate;
  return format(new Date(ts),"yyyy-MM-dd");
};

/**
 * @description: 获取n月前指定日期
 * @param {*} n
 * @return {*} Unix Time Stamp e.g: 1652054400000
 */
export const getMonthAgoDate = (n = 0) => {
  let curDate = new Date();
  curDate.setMonth(curDate.getMonth() - n);
  const ts = +curDate;
  return format(new Date(ts),"yyyy-MM-dd");
};

/**
 * @description: 获取n天前指定日期
 * @param {*} n
 * @return {*} Unix Time Stamp e.g: 1652054400000
 */
export const getDayAgoDate = (n = 0) => {
  let curDate = new Date();
  let newDate = new Date(curDate - 1000 * 60 * 60 * 24 * n);
  const ts = +newDate;
  return format(new Date(ts),"yyyy-MM-dd");
};
