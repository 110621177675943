import {
  actionRequest,
  formatActionResponse,
  formatRespArray,
  formatResponse,
  formatResponseArray,
} from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";
import { getCache, setCache } from "../utils/cache";
import {
  CacheKey,
  localizeTitle,
  ProductCurrencyType,
} from "../constants/constants";
import { requestGetUrlFormat } from "./common";
import { SysEnvLanguageType } from "../constants/env.enum";
import { getProductConfig } from "./config";
import { ItemRender } from "antd/lib/upload/interface";
import { constants } from "../utils/utils";

export async function productConfig() {
  let data = await actionRequest(RequestType.GET, apiPath.productConfig);
  return formatResponse(data);
}

export async function loadProductConfig() {
  let configData = getCache(CacheKey.ProductConfig);
  if (!configData) {
    configData = await getProductConfig();
    if (configData) {
      setCache(CacheKey.ProductConfig, configData);
    }
  }
  return configData;
}

/**
 *
 * @param data {total: 10,result:Array<any>}
 * @param lang
 * @returns
 */
export function getProductConfigForSelect(data: any, lang: any, flag?: any) {
  let result = [];
  if (data && data.total > 0) {
    result = data.result;
    result = result.map((item: any) => {
      return formatProductConfigItem(item, lang);
    });
    result = result.sort((a: any, b: any) => a.sort - b.sort);
  }
  return result;
}

export function formatProductConfigItem(item: any, lang: any) {
  let label = item.name;
  if (Array.isArray(item.languages) && item.languages.length > 0) {
    label = lang === SysEnvLanguageType.EN ? label : item.languages[0][lang];
  } else if (!Array.isArray(item.languages) && item.languages) {
    label = lang === SysEnvLanguageType.EN ? label : item.languages[lang];
  }
  return {
    label: label,
    value: item._id,
    countryid: item.countryId,
    parentId: item.parentId,
    icon: item.icon,
    sort: item.sort
  };
}

export function getProductCategoryTypeForSelect(
  categories: any,
  types: any,
  lang: any
) {
  console.log('-----cate type :', categories, types, lang)
  let catList = getProductConfigForSelect(categories, lang);
  let typeList = getProductConfigForSelect(types, lang);
  catList = catList.map((item: any) => {
    let list = typeList.filter((t: any) => t.parentId === item.value);
    return { ...item, types: list };
  });
  return catList;
}

export function getProductConfigNameForSelect(data: any, lang: any) {
  let result = [];
  if (data && data.total > 0) {
    result = data.result;
    result = result.map((item: any) => {
      let label = item.name;
      return { label: label, value: label };
    });
  }
  return result;
}

export function getProductConfigSelectNameByValue(
  data: Array<any>,
  value: any
) {
  let result = value;
  let list = data.filter((item) => {
    return item.value === value;
  });
  if (list && list.length > 0) {
    result = list[0].label;
  }
  return result;
}

export function getProductConfigSelectItemByValue(
  data: Array<any>,
  value: any
) {
  let result = null;
  let list = data.filter((item) => {
    return item.value === value;
  });
  if (list && list.length > 0) {
    result = list[0];
  }
  return result;
}

export function getProductConfigNameById(data: any, lang: any, value: string) {
  let result = value;
  if (data && data.total > 0) {
    let list = data.result;
    list = list
      .filter((item: any) => item._id === value)
      .map((item: any) => {
        return formatProductConfigItem(item, lang);
      });
    if (list.length > 0) result = list[0].label;
  }
  if (!result) {
    return ("");
  }
  return result;
}

export function getProductIsLocalStock(volumeList: Array<any>) {
  let flag = false;
  if (volumeList && volumeList.length > 0) {
    volumeList.forEach((item) => {
      let { stockCountry } = item;
      if (stockCountry === constants.chinaCountryId) {
        flag = true;
        return;
      }
    });
  }
  return flag;
}

export function getProductPriceText(item: any) {
  let priceText =
    item.price < item.maxPrice
      ? item.price + " - " + item.maxPrice
      : item.price;
  if (priceText) {
    priceText =
      item.currency === ProductCurrencyType.CNY
        ? item.currency + " " + priceText
        : priceText + " " + item.currency;
  }
  return priceText;
}

export function getProductActivityPriceText(
  item: any,
  discountPercent: number
) {
  let priceText =
    item.price < item.maxPrice
      ? getProductActivityPrice(item.price, discountPercent) +
      " - " +
      getProductActivityPrice(item.maxPrice, discountPercent)
      : getProductActivityPrice(item.price, discountPercent);
  if (priceText) {
    priceText =
      item.currency === ProductCurrencyType.CNY
        ? item.currency + " " + priceText
        : priceText + " " + item.currency;
  }
  return priceText;
}

export function getProductActivityPrice(
  price: number,
  discountPercent: number
) {
  return (price * (1 - discountPercent / 100)).toFixed(0);
}

export function getProductPriceRangeText(volumeList: Array<any>) {
  console.log("-------------------getProductPriceRangeText:", volumeList);
  let priceText = "";
  if (volumeList && volumeList.length > 0) {
    let list: Array<any> = [];
    volumeList.forEach((item) => {
      let { price } = item;
      if (price) {
        list.push(price);
      }
    });
    list = list.sort((a, b) => a - b);
    if (list.length > 1 && list[0] !== list[list.length - 1]) {
      priceText = list[0] + "-" + list[list.length - 1];
    } else {
      priceText = list[0];
    }
  }
  return priceText;
}

export function getProductPriceRangeTextWithCurrency(volumeList: Array<any>, currency: any) {
  console.log("-------------------getProductPriceRangeText:", volumeList);
  let priceText = "";
  if (volumeList && volumeList.length > 0) {
    let list: Array<any> = [];
    volumeList.forEach((item) => {
      let { price } = item;
      if (price) {
        list.push(price);
      }
    });
    list = list.sort((a, b) => a - b);
    if (list.length > 1 && list[0] !== list[list.length - 1]) {
      priceText = list[0] + "-" + list[list.length - 1];

      if (currency === ProductCurrencyType.CNY) {
        priceText = currency + " " + list[0] + "-" + currency + " " + list[list.length - 1];
      } else {
        priceText = list[0] + " " + currency + "-" + list[list.length - 1] + " " + currency;
      }
    } else {
      priceText = currency === ProductCurrencyType.CNY ? currency + " " + list[0] : list[0] + " " + currency;
    }
  }
  return priceText;
}

export function getProductActivityPriceRangeText(volumeList: Array<any>) {
  let priceText = "";
  if (volumeList && volumeList.length > 0) {
    let list: Array<any> = [];
    volumeList.forEach((item) => {
      let { priceRange } = item;
      if (priceRange && priceRange.length > 0) {
        priceRange.forEach((pitem: any) => {
          list.push(pitem.price.activityPrice);
        });
      }
    });
    list = list.sort((a, b) => a - b);
    if (list.length > 1 && list[0] !== list[list.length - 1]) {
      priceText = list[0] + " - " + list[list.length - 1];
    } else {
      priceText = list[0];
    }
  }
  return priceText;
}

export function getProductMinPrice(volumeList: Array<any>) {
  let price = 0;
  if (volumeList && volumeList.length > 0) {
    let list: Array<any> = [];
    volumeList.forEach((item) => {
      let { priceRange } = item;
      if (priceRange && priceRange.length > 0) {
        priceRange.forEach((pitem: any) => {
          list.push(pitem.price.value);
        });
      }
    });
    list = list.sort((a, b) => a - b);
    if (list.length > 0) {
      price = list[0];
    }
  }
  return price;
}

/**
 *
 * @param pms
 */
export async function productCreate(pms: any) {
  let data = await actionRequest(RequestType.POST, apiPath.productCreate, pms);
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export function productDetail(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.productDetail, pms)
  );
}

/**
 *
 * @param pms
 */
export function productUpdate(pms: any) {
  return actionRequest(RequestType.POST, apiPath.productUpdate, pms);
}

/**
 *
 * @param pms
 */
export function productUpdateStatus(pms: any) {
  return formatActionResponse(
    RequestType.POST,
    apiPath.productUpdateStatus,
    pms
  );
}

/**
 *
 * @param pms
 */
export function productRemove(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.productRemove, pms)
  );
}

export async function productTags() {
  /**
   * TODO FIX ME
   */
  return actionRequest(RequestType.GET, apiPath.productTags);
}

export async function loadProductTags() {
  let tagsData = getCache(CacheKey.ProductTags);
  if (!tagsData) {
    tagsData = await productTags();
    setCache(CacheKey.ProductTags, tagsData);
  }
  return tagsData;
}

/**
 *
 * @param pms
 */
export async function loadProducts(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.products, pms);
}

/**
 *
 * @param pms
 */
export async function loadOpenProducts(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.openProducts, pms);
}

/**
 *
 * @param pms
 */
export async function productCount(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.productsCount, pms)
  );
}

/**
 *
 * @param coId
 */
export async function loadProductsForSelect(coId: string, lang: string) {
  let list = [];
  let pms = { page: 0, per_page: 20, coId };
  let result = await loadProducts(pms);
  if (result && result.length > 0) {
    list = result.map((item: any) => {
      let { name, id, _id } = item;
      return { label: localizeTitle(lang, name), value: id || _id };
    });
  }
  return list;
}

/**
 *
 * @param pms
 */
export async function productTypes<T>(pms: any) {
  let data: any = await actionRequest(
    RequestType.GET,
    apiPath.productTypes,
    pms
  );
  return await formatRespArray<T>(data);
}

/**
 *
 * @param pms
 */
export async function productVintages(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.productVintages, pms);
}

/**
 *
 * @param pms
 */
export async function vintageBind(pms: any) {
  let data = await actionRequest(
    RequestType.POST,
    apiPath.productVintageBind,
    pms
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export async function vintageUnBind(pms: any) {
  let data = await actionRequest(
    RequestType.POST,
    apiPath.productVintageUnBind,
    pms
  );
  return formatResponse(data);
}
/**
 * product stock (warehouse info)
 * @param pms
 */
export async function productStocks(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.productStocks, pms)
  );
  return formatResponseArray(data);
}

/**
 * product warehouse stock : find single in productstock table
 * @param pms
 */
export function productWarehouseStock(pms: any) {
  return formatActionResponse(
    RequestType.GET,
    apiPath.productWarehouseStock,
    pms
  );
}

/**
 *
 * @param pms
 */
export async function getProductGrapes(pms: any) {
  return await formatActionResponse(
    RequestType.GET,
    apiPath.productGrapes,
    pms
  );
}

export async function getProductGrapeDetail(pms: any) {
  return await formatActionResponse(
    RequestType.GET,
    apiPath.productGrspeDetail,
    pms
  );
}

export async function getProductGrapeDetailName(pms: any, lang: string) {
  let item = await getProductGrapeDetail(pms);
  let result = "";
  if (item) {
    result = item.name;
    if (lang === SysEnvLanguageType.ZH) {
      result =
        item.languages.length > 0 ? item.languages[0].zh : item.languages.zh;
    } else if (lang === SysEnvLanguageType.FR) {
      result =
        item.languages.length > 0 ? item.languages[0].fr : item.languages.fr;
    }
  }
  return result;
}

export async function getProductSearch(pms: any) {
  return formatActionResponse(RequestType.POST, apiPath.productSearch, pms);
}

export function getProductTotalStock(volumeList: Array<any>) {
  let total = 0;
  if (volumeList && volumeList.length > 0) {
    volumeList.forEach((item) => {
      if (item.stock && item.stock.value) {
        total += parseInt(item.stock.value);
      }
    });
  }
  return total;
}

//#region ratings
export async function getProductRatings(pms: any) {
  return formatActionResponse(RequestType.GET, apiPath.productRatings, pms);
}

export async function getProductRatingDetail(pms: any) {
  return formatActionResponse(
    RequestType.GET,
    apiPath.productRatingDetail,
    pms
  );
}

export async function createProductRating(pms: any) {
  return formatActionResponse(
    RequestType.POST,
    apiPath.productRatingCreate,
    pms
  );
}

export async function updateProductRating(pms: any) {
  return formatActionResponse(
    RequestType.POST,
    apiPath.productRatingUpdate,
    pms
  );
}

export async function removeProductRating(pms: any) {
  return formatActionResponse(
    RequestType.GET,
    apiPath.productRatingRemove,
    pms
  );
}
//#endregion

export async function getExchangeRate(pms: any) {
  return formatActionResponse(
    RequestType.GET,
    apiPath.productExchangeRate,
    pms
  );
}

export async function productsFilterItems(pms: any) {
  return await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.productsFilterItems, pms)
  );
}

export async function createProductClick(pms: any) {
  return formatActionResponse(
    RequestType.POST,
    apiPath.productClickCreate,
    pms
  );
}

/**
 *
 * @param pms
 */
export async function fetchProductClicks(pms: any) {
  return await formatActionResponse(
    RequestType.GET,
    apiPath.productClicks,
    pms
  );
}


export async function productFavorite(pms: any) {
  return formatActionResponse(
    RequestType.POST,
    apiPath.productFavorite,
    pms
  );
}

/**
 *
 * @param pms
 */
export async function fetchProductFavorites(pms: any) {
  return await formatActionResponse(
    RequestType.GET,
    apiPath.productFavorites,
    pms
  );
}

export async function productFavoriteStatus(pms: any) {
  return formatActionResponse(
    RequestType.POST,
    apiPath.productFavoriteStatus,
    pms
  );
}

/**
 *
 * @param pms
 */
export async function productFavoriteCount(pms: any) {
  return await formatActionResponse(
    RequestType.GET,
    apiPath.productFavoriteCount,
    pms
  );
}
