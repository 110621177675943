import React, { useEffect, useState } from "react";
import { Breadcrumb, Button } from "antd";
import { useUserId } from "../../../contexts/UserIdProvider";
import "./style/companyVerify.less";
import verifyIcon from "../../../resource/icons/verify/verify-success.svg";
import { useTranslation } from "react-i18next";
import { createExpressAccount } from "../../../api/stripe";
import { CompanyVerifyStatusEnum } from "../../../constants/companyVerifyStatus.enum";
import { useHistory } from "react-router-dom";

export default function CompanyVerifyGlobal() {
  const { user } = useUserId();
  const { t,i18n } = useTranslation();
  const history = useHistory();
  
  const [verify, setVerify] = useState(1);

  useEffect(() => {
    setVerify(user.userInfo.companyInfo[0].status);
  }, []);

  const onStripe = async () => {
    let data = await createExpressAccount({
      userId: user.userId,
      email: user.userInfo.email,
    });
    if (data.message === "REDIRECT") {
      window.location.href = data.result;
    }
  };

  return (
    <div className="common-page company-verify-page">
      <div className="common-management">
        <div className="common-management-title">
          {t("COMPANY_CERTIFICATION")}
        </div>
        {/** Not verified*/}
        {verify === 0 && (
          <div className="common-management-panel">
            <p style={{ width: 1000 }}>
              {t("PLEASE_PREPARE_THE_FOLLOWING_DOCUMENTS")}
              <br />- {t("ID_CARD")} <br />
            </p>

            <Button className={"button-primary"} onClick={onStripe.bind(this)}>
              {t("VERIFIY_WITH_STRIPE")}
            </Button>
          </div>
        )}
        {/** Not verified*/}
        {verify === 1 && (
          <div className="common-management-panel">
            <p style={{ width: 1000 }}>
              {t("PLEASE_PREPARE_THE_FOLLOWING_DOCUMENTS")}
              <br />- {t("ID_CARD")} <br />
            </p>

            <Button className={"button-primary"} onClick={onStripe.bind(this)}>
              {t("VERIFIY_WITH_STRIPE")}
            </Button>
          </div>
        )}
        {/** Verified fail*/}
        {verify === CompanyVerifyStatusEnum.VERIFY_FAILED && (
          <div className="common-management-panel">
            <p style={{ width: 1000 }}>
              {t("PLEASE_RESUME_THE_VERIFICATION_PROCESS")}
            </p>

            <Button className={"button-primary"} onClick={onStripe.bind(this)}>
              {t("VERIFIY_WITH_STRIPE")}
            </Button>
          </div>
        )}
        {/** Verified Success*/}
        {verify === CompanyVerifyStatusEnum.VERIFIED && (
          <div className="verified common-management-panel">
            <p className="isVerif">{t("YOUR_ACCOUNT_IS_VERIFY")}</p>
            <a className="isVerifA">
              <img className="verif-icon" src={verifyIcon} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
