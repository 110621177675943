import React, { useEffect, useState } from "react";
import { Col, Image, List, Modal, Row } from "antd";
import { useHistory } from "react-router-dom";
import { getTimeBefore } from "../../../utils/date";
import { useTranslation } from "react-i18next";
import closeIcon from "../../../resource/icons/feed/close.svg";
import { localizeImg } from "../../../constants/constants";
import "./style/awardListRow.less";
import { formatKeyword, getProductStatusText } from "../../../api/common";
import { goToProfilePage } from "../../../utils/utils";
import AvatarBasic from "../../common/avatar/AvatarBasic";
import { ProductStatus } from "../../../constants/statusCode.enum";
import agreeSelectedIcon from "../../../resource/icons/notify/agree_selected.svg";
import refuseSelectedIcon from "../../../resource/icons/notify/refuse_selected.svg";

export default function AwardListRow(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { data, notify } = props;
  console.log("-------------------------awards:", data);

  const onCompanyHome = () => {
    history.push("/profile-deprecated/" + data.coId);
  };

  const getStatusIcon = (status) => {
    let result = <div className="userinfo-btn-selected"></div>;
    //处理产品状态
    switch (Number(status)) {
      case ProductStatus.VERIFYWAITING:
        break;
      case ProductStatus.VERIFYSUCCESS:
        result = <img src={agreeSelectedIcon} />;
        break;
      case ProductStatus.VERIFYFAIL:
        result = <img src={refuseSelectedIcon} />;
        break;
      case ProductStatus.STOCK:
        break;
      case ProductStatus.UNSTOCK:
        break;
      default:
        break;
    }
    return result;
  };


  return (
    <List.Item className="award-row-item">
      <div className="award-images-container">
        <Row gutter={[8, 8]} className="award-images">
          <Col span={6}>
            <div className="award-list-image-box">
              <Image
                preview={false}
                src={localizeImg(data.reward)}
                className="award-list-image"
              />
            </div>
          </Col>
          <Col span={16}>
            <div
              style={{
                display: "flex",
                marginLeft: "10px",
              }}
            >
              <div>
                <div className="award-item-info">
                  <span>{t("AWARD_NAME")}:</span>
                </div>
                <div className="award-item-info">
                  <span>{t("PRODUCT")}:</span>
                </div>
                <div className="award-item-info">
                  <span>{t("DESCRIPTION")}:</span>
                </div>
                <div className="award-item-info">
                  <span>{t("VERIFY_STATUS")}:</span>
                </div>
                {data.reason && (
                  <div className="award-item-info">
                    <span>{t("FAIILED_REASON")}:</span>
                  </div>
                )}
              </div>
              <div>
                <div className="award-item-info">
                  <span>{data.name}</span>
                </div>
                <div className="award-item-info">
                  <span>{data.productInfo?.[0].title}</span>
                </div>
                <div className="award-item-info">
                  <span>{data.desc}</span>
                </div>
                <div className="award-item-info">
                  <span>{t(getProductStatusText(data.status))}</span>
                </div>
                {data.reason && (
                  <div className="award-item-info">
                    <span>{data.reason}</span>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col span={1}>
            {data && (
              <div>
                {/* {t(getProductStatusText(data.status))} */}
                {getStatusIcon(data.status)}
              </div>
            )}
          </Col>
        </Row>
      </div>
    </List.Item>
  );
}
