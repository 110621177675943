import React, { useEffect, useState } from "react";
import { List, Spin, Table, Input, Select, Button } from "antd";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import "./style/selectProduct.less";
import { getProductPriceRangeTextWithCurrency, loadProducts as reqProducts } from "../../../../api/product";
import { ProductTableMaxCount } from "../../../../constants/constants";
import { getProductStatusText } from "../../../../api/common";
import { deepClone } from "../../../../utils/utils";

export default function SelectProduct(props) {
  const { onComplete, onCancel, selectedItems } = props;
  const { user } = useUserId();
  const { userId } = user;
  const [productList, setProducts] = useState([]);
  const [oriProductList, setOriProductList] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [selectedRow, setSelectedRow] = useState([]);

  const { Option } = Select;

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setSelectedRow(selectedItems);
  }, [selectedItems]);

  const loadData = async (index) => {
    setLoading(true);
    let pms = {
      page: 0,
      per_page: ProductTableMaxCount,
      coId: user.userInfo.companyId,
    };
    let result = await reqProducts(pms);
    console.log("-----products:", result, pms);
    if (result && result.length > 0) {
      // result = result.map((item) => item._source);
      for (let i = 0; i < result.length; i++) {
        if (result[i].id) {
          result[i].key = result[i].id;
        } else {
          result[i].key = result[i]._id;
        }
      }

      setProducts(result);
      setOriProductList(result);
    }
    setLoading(false);
  };

  const renderProductPrice = (volumes, currency) => {
    return getProductPriceRangeTextWithCurrency(volumes, currency);
    // let price = "";

    // if (volumes.length == 0) {
    //   price = "-";
    // }
    // else if (volumes.length == 1) {
    //   price += volumes[0].price;
    // } else if (volumes.length > 1) {
    //   let volumeItem = volumes[0];
    //   price += volumeItem.price;
    //   price += " ~ ";
    //   volumeItem = volumes[volumes.length - 1];
    //   price += volumeItem.price;
    // }
    // return price;
  };

  const renderProductStock = (volumes) => {
    let stock = "";

    if (volumes.length > 0) {
      let volumeItem = volumes[0];
      stock = volumeItem.stock.value;
    } else {
      stock = "-";
    }

    return stock;
  };

  const renderProductStatus = (status) => {
    return t(getProductStatusText(status));
  };

  const columns = [
    {
      title: t("PRODUCT_TITLE"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("PRICE"),
      dataIndex: "volumes",
      key: "volumes",
      render: (text, item) => <span>{renderProductPrice(text, item.currency)}</span>,
    },
    {
      title: t("STOCKS"),
      dataIndex: "volumes",
      key: "volumes",
      render: (text) => <span>{renderProductStock(text)}</span>,
    },
    {
      title: t("STATUS"),
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{renderProductStatus(text)}</span>,
    },
  ];

  const onSelect = (value) => {
    console.log("-------------onSelect:", value);
    selectedRow.some((i) => i.key === value.key)
      ? setSelectedRow(selectedRow.filter((i) => i.key !== value.key))
      : setSelectedRow([...selectedRow, value]);
  };

  const onSelectAll = (value) => {
    console.log("-------------onSelectAll:", value);
    value
      ? setSelectedRow([
        ...selectedRow,
        ...productList
          .map((i) => ({ ...i, key: i.id }))
          .filter(
            (item) => !selectedRow.map((el) => el.key).includes(item.key)
          ),
      ])
      : setSelectedRow(
        selectedRow.filter(
          (i) => !productList.map((item) => item.id).includes(i.key)
        )
      );
  };

  const selectedRowSelection = {
    type: "checkbox",
    onSelect,
    onSelectAll,
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys, selectedRow) => {
      setSelectedRow([...selectedRowKeys]);
    },
  };

  const onSave = () => {
    onComplete && onComplete(selectedRow);
  };

  const onCancelClick = () => {
    onCancel && onCancel();
  };

  const onProductNameChange = (e) => {
    let temp = deepClone(productList);
    temp = oriProductList.filter(
      (filterItem) => filterItem.title.indexOf(e.target.value) != -1
    );
    setProducts(temp);
    setSearchProductName(e.target.value);
  };

  const onResetSearch = (e) => {
    let temp = deepClone(productList);
    temp = oriProductList;
    setProducts(temp);
    setSearchProductName("");
  };

  return (
    <div className="operation-select-product-container">
      <div className="search-panel">
        <div className="search-panel-row">
          <div className="label">{t("PRODUCT_TITLE")}</div>
          <Input
            className="row-input"
            placeholder={t("PRODUCT_TITLE")}
            value={searchProductName}
            onChange={onProductNameChange.bind(this)}
          />
        </div>
        <div className="search-panel-row" style={{ marginTop: "10px" }}>
          <div className="label">{t("PRODUCTTYPE")}</div>
          <Select
            showSearch
            style={{ width: 250 }}
            // mode="multiple"
            placeholder={t("PRODUCTTYPE")}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            <Option value="1">集团采购</Option>
            <Option value="2">大客户</Option>
            <Option value="3">潜在客户</Option>
            <Option value="4">目标客户</Option>
          </Select>
        </div>
        <div className="searcn-panel-bottom">
          <Button className="btn-search" style={{ marginRight: "40px" }}>
            {t("INQUIRES")}
          </Button>
          <Button className="btn-reset" onClick={onResetSearch.bind(this)}>{t("RESET")}</Button>
        </div>
      </div>
      <div style={{ paddingLeft: "20px" }}>
        <Table
          columns={columns}
          dataSource={productList}
          rowSelection={selectedRowSelection}
        />
      </div>
      <div className="search-bottom">
        <div className="searcn-panel-bottom">
          <Button
            className="btn-reset"
            style={{ marginRight: "40px" }}
            onClick={onCancelClick.bind(this)}
          >
            {t("CANCEL")}
          </Button>
          <Button
            className="btn-search"
            style={{ marginRight: "20px" }}
            onClick={onSave.bind(this)}
          >
            {t("SAVE")}
          </Button>
        </div>
      </div>
    </div>
  );
}
