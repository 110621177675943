import "./style/suggestUserItem.less";
import { useTranslation } from "react-i18next";
import { localizeImg } from "../../constants/constants";
import AvatarBasic from "../common/avatar/AvatarBasic.js";
import { goToProfilePage } from "../../utils/utils";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { loadProductConfig } from "../../api/product";
import { getCountryNameById } from "../../api/config";
import locationIcon from "../../resource/icons/home/location.svg";
import { useUserId } from "../../contexts/UserIdProvider";
import {
  applyFriendCreate,
  contactFollowStatus,
  follow,
} from "../../api/contact";
import { statusCode } from "../../constants/statusCode.enum";
import { useAuth } from "../../contexts/AuthProvider";
import { getCompanyDescFromUserType } from "../../api/company";
import { checkLimit } from "../../api/common";
import { RoleLimit } from "../../constants/roleType.enum";
import { Button } from "antd";
import { toastInfo } from "../../api/toast";

export default function SuggestUserItem(props) {
  const { item, onAddFriendRequest } = props;

  const { user } = useUserId();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [locations, setLocations] = useState();
  const { authTokens } = useAuth();
  const [lang, setLang] = useState(i18n.language);

  const [isFollow, setIsFollow] = useState(false);
  const [companyDesc, setCompanyDesc] = useState();

  useEffect(() => {
    initFollowStatus();
    initConfigData();
  }, []);

  useEffect(() => {
    if (item && item.companyDesc && item.companyDesc.length > 0) {
      setCompanyDesc(item.companyDesc[0]);
    } else {
      initCompanyDesc();
    }
  }, [item]);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const initCompanyDesc = async () => {
    let pms = { coId: item.companyId };
    let result = await getCompanyDescFromUserType(pms, item.type);
    // console.log("------right sider initCompanyDesc:", result, pms);
    if (result) {
      setCompanyDesc(result);
    }
  };

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const initFollowStatus = async () => {
    let pms = { sourceId: user.userId, targetId: item._id };
    let followStatusResult = await contactFollowStatus(pms);
    if (
      followStatusResult &&
      followStatusResult.statusCode === statusCode.SUCCESS &&
      followStatusResult.result
    ) {
      setIsFollow(true);
    }
  };

  const onCompanyHome = (userItem) => {
    if (checkLimit(user?.userInfo, RoleLimit.CONTACTS, history)) {
      goToProfilePage(userItem, history);
    }
  };

  const addFriend = () => {
    if (!authTokens) {
      history.push("/login");
      return;
    }

    if (checkLimit(user?.userInfo, RoleLimit.CONTACTS, history)) {
      applyFriendCreate({ sourceId: user.userId, targetId: item._id }).then(
        (res) => {
          if (res && res.statusCode === statusCode.SUCCESS) {
            toastInfo(t("APPLY_FRIEND_SUCCESS"));
            onAddFriendRequest && onAddFriendRequest(item);
          }
        }
      );
    }
  };

  return (
    <div className="follower-item sub-info-content" key={item._id}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AvatarBasic
          cname="avatar"
          picture={localizeImg(companyDesc?.avatar || item.picture)}
          displayName={item.nickName || item.firstName + item.lastName}
          size={41}
          onClick={onCompanyHome.bind(this, item)}
        ></AvatarBasic>
      </div>
      <div
        className="user-info sub-content"
        onClick={onCompanyHome.bind(this, item)}
      >
        <div className="user-name">{item.nickName}</div>
        {/* <div className="user-type">{t(item.type)}</div> */}
        <div className="user-desc">
          {/* <img
            src={locationIcon}
            style={{
              marginRight: "5px",
              width: "10px",
              height: "13px",
            }}
          /> */}
          {item.country && (
            <span>{getCountryNameById(locations, lang, item.country)}</span>
          )}
          {!item.country && <span>{t("NO_ADDRESS")}</span>}
        </div>
      </div>
      <Button className="btn-add-contact" onClick={addFriend.bind(this)}>
        {t("ADD_CONTACT")}
      </Button>
    </div>
  );
}
