import React, { useEffect, useState } from "react";
import styles from "./EventItem.module.sass";
import cn from "classnames";
import Icon from "../../../core-digital-template/components/Icon";
import { localizeImg } from "../../../constants/constants";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { getChinaCityNameById, getCountryNameById } from "../../../api/config";
import { loadProductConfig } from "../../../api/product";
import ModalNews from "../../../core-digital-template/components/ModalNews";

const EventItem = ({ item }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  const [locations, setLocations] = useState();

  const [showNewsModal, setShowNewsModal] = useState(false);

  useEffect(() => {
    initConfigData();
  }, []);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.item} onClick={() => setShowNewsModal(true)}>
          <div className={styles.preview}>
            <img
              srcSet={`${localizeImg(item.images[0])} 2x`}
              src={localizeImg(item.images[0])}
              alt="Product"
            />
            <div className={styles.icon}>
              <Icon
                name={(item.picture && "image") || (item.video && "video")}
              />
            </div>
          </div>
          <div className={styles.details}>
            <div
              className={styles.post}
              dangerouslySetInnerHTML={{ __html: item.title }}
            ></div>
            <div className={styles.location}>
              <div>
                {t("LOCATION")}
                {":"}
                {getCountryNameById(locations, lang, item?.country)}{" "}
                {getChinaCityNameById(item?.province)}
              </div>
            </div>
            <div className={styles.socials}>
              <div>
                {t("DATE")}:{item.eventDate}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalNews
        visible={showNewsModal}
        news={item}
        onClose={() => setShowNewsModal(false)}
      />
    </div>
  );
};

export default EventItem;
