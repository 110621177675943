import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";
import { actionRequest } from "./api";
import { SysBaseUrl, SysServiceUrl } from "../constants/constants";

/**
 * @description native pay
 * @param pms
 */
async function aliPay(pms: any) {
    return await actionRequest(RequestType.POST, apiPath.aliPay, pms);
}

/**
 * @description button click
 * @param order order detail
 */
export async function getAliPayForm(order: any) {
    let result;
    if (order) {
        let { _id } = order;
        let pms = {
            subject: "vinexwork",//"商品标题/交易标题/订单标题/订单关键字等。 注意：不可使用特殊字符，如 /，=，& 等。",
            out_trade_no: _id,// "商户订单号.--最大长度64",
            // total_amount: (payPrice * 0.01).toString(),//"支付金额(单位:元)",
            total_amount: "0.01",
            notify_url: SysServiceUrl + "Notify/alipaynotify",
            return_url: SysBaseUrl + "order/success",
            //pack_params: { test_id: _id },// "可以不传   自定义商品信息参数 "
        };
        result = await aliPay(pms);
    }
    return result;
}

export async function getAliPayBasicForm(order: any) {
    let result;
    if (order) {
        let { _id } = order;
        let pms = {
            subject: "vinexwork",//"商品标题/交易标题/订单标题/订单关键字等。 注意：不可使用特殊字符，如 /，=，& 等。",
            out_trade_no: _id,// "商户订单号.--最大长度64",
            // total_amount: (payPrice * 0.01).toString(),//"支付金额(单位:元)",
            total_amount: "0.01",
            notify_url: order.notify_url,
            return_url: order.return_url,
            //pack_params: { test_id: _id },// "可以不传   自定义商品信息参数 "
        };
        result = await aliPay(pms);
    }
    return result;
}
