import React, { useEffect, useState } from "react";
import { Tabs, Button, Table, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import "./style/operationCreateIntro.less";
import { useParams, useHistory } from "react-router-dom";
import consumeIcon from "../../../resource/icons/dashboard/operation/consume.svg";
import orderIcon from "../../../resource/icons/dashboard/operation/order.svg";
import editIcon from "../../../resource/icons/dashboard/operation/edit.svg";
import cancelIcon from "../../../resource/icons/dashboard/operation/cancel.svg";
import deleteIcon from "../../../resource/icons/dashboard/operation/delete.svg";
import newIcon from "../../../resource/icons/dashboard/operation/new.svg";
import { OperationScheduleType } from "../../../constants/operation/OperationScheduleType.enum";
import { deepClone } from "../../../utils/utils";
import {
  operationScheduleList,
  operationScheduleRemove,
  operationScheduleCancel,
} from "../../../api/operation";

export default function OperationCreateIntro() {
  const { index } = useParams();
  const { t } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();
  const { TabPane } = Tabs;

  const [tabIndex, setTabIndex] = useState("1");
  const [scheduleList, setScheduleList] = useState([]);

  const [consumeItems, setConsumeItems] = useState([
    {
      title: t("NEW_PRODUCT_ONLINE_NOTIFICATION"),
      desc: t("NEW_PRODUCT_ONLINE_NOTIFICATION_DESC"),
      type: OperationScheduleType.NEW_PRODUCT_ONLINE,
      focused: false,
    },
    {
      title: t("JOINED_ACTIVITY_NOTIFICATION"),
      desc: t("JOINED_ACTIVITY_NOTIFICATION_DESC"),
      type: OperationScheduleType.JOINED_ACTIVITY,
      focused: false,
    },
    {
      title: t("REPLENISH_NOTIFICATION"),
      desc: t("REPLENISH_NOTIFICATION_DESC"),
      type: OperationScheduleType.REPLENISH,
      focused: false,
    },
  ]);

  const [orderItems, setOrderItems] = useState([
    {
      title: t("WAITING_FOR_PAY_NOTIFICATION"),
      desc: t("WAITING_FOR_PAY_NOTIFICATION_DESC"),
      type: OperationScheduleType.WAITING_FOR_PAY,
      focused: false,
    },
    {
      title: t("NEW_ORDER_NOTIFICATION"),
      desc: t("NEW_ORDER_NOTIFICATION_DESC"),
      type: OperationScheduleType.NEW_ORDER,
      focused: false,
    },
    {
      title: t("START_STOCK_NOTIFICATION"),
      desc: t("START_STOCK_NOTIFICATION_DESC"),
      type: OperationScheduleType.START_STOCK,
      focused: false,
    },
    {
      title: t("START_DELIVERY_NOTIFICATION"),
      desc: t("START_DELIVERY_NOTIFICATION_DESC"),
      type: OperationScheduleType.START_DELIVERY,
      focused: false,
    },
    {
      title: t("WAITING_FOR_PICKUP_NOTIFICATION"),
      desc: t("WAITING_FOR_PICKUP_NOTIFICATION_DESC"),
      type: OperationScheduleType.WAITING_FOR_PICKUP,
      focused: false,
    },
    {
      title: t("WAITING_FOR_COMPLETE_NOTIFICATION"),
      desc: t("WAITING_FOR_COMPLETE_NOTIFICATION_DESC"),
      type: OperationScheduleType.WAITING_FOR_COMPLETE,
      focused: false,
    },
  ]);

  useEffect(() => {
    if (index) {
      setTabIndex(index);
    } else {
      setTabIndex("1");
    }
  }, [index]);

  const onTabIndexChange = (e) => {
    setTabIndex(e);
    if (e == 2 && scheduleList.length == 0) {
      loadScheduleList();
    }
  };

  // 新建计划
  const onRenderOrderItem = (item, type, index) => {
    return (
      <div
        className="notification-item cursor-pointer"
        onClick={navigateTo.bind(this, item.type)}
        onMouseEnter={onMouseEnterOrderItem.bind(this, type, index)}
        onMouseLeave={onMouseLeaveOrderItem.bind(this, type, index)}
      >
        <div className="notification-title">{item.title}</div>
        {!item.focused && (
          <div
            className="notification-desc"
            dangerouslySetInnerHTML={{ __html: item.desc }}
          ></div>
        )}
        {item.focused && (
          <Button className="new-schedule-btn">
            <img src={newIcon} style={{ marginRight: "6px" }} />
            <span>{t("NEW_SCHEDULE")}</span>
          </Button>
        )}
      </div>
    );
  };

  const onMouseEnterOrderItem = (type, index) => {
    let temp;
    if (type == "CONSUME") {
      temp = deepClone(consumeItems);
    } else {
      temp = deepClone(orderItems);
    }
    temp[index].focused = true;
    if (type == "CONSUME") {
      setConsumeItems(temp);
    } else {
      setOrderItems(temp);
    }
  };

  const onMouseLeaveOrderItem = (type, index) => {
    let temp;
    if (type == "CONSUME") {
      temp = deepClone(consumeItems);
    } else {
      temp = deepClone(orderItems);
    }
    temp[index].focused = false;
    if (type == "CONSUME") {
      setConsumeItems(temp);
    } else {
      setOrderItems(temp);
    }
  };

  const navigateTo = (type) => {
    history.push("/dashboard/operation-create", { type: type });
  };

  // 计划列表
  const loadScheduleList = async () => {
    let pms = {
      coId: user?.userInfo.companyId,
    };
    let result = await operationScheduleList(pms);
    console.log("schedule list:", result);
    if (result && result.total > 0) {
      setScheduleList(result.result);
    }
  };

  const renderTargetUids = (users) => {
    if (users && users.length > 0) {
      return users[0].nickName + t("ETC") + users.length + t("PEOPLE");
    } else {
      return "-";
    }
  };

  const scheduleListColumns = [
    {
      title: t("SCHEDULE_NAME"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("SCHEDULE_TYPE"),
      dataIndex: "type",
      key: "type",
      render: (text) => <span>{t(text)}</span>,
    },
    {
      title: t("TARGET_USERS"),
      dataIndex: "targetUsers",
      key: "targetUsers",
      render: (text) => <span>{renderTargetUids(text)}</span>,
    },
    {
      title: t("TRIGGER_METHOD"),
      dataIndex: "companyInfo",
      key: "companyInfo",
      render: (text) => <span>{t("AUTOMATIC_TRIGGER")}</span>,
    },
    {
      title: t("SCHEDULE_TIME"),
      dataIndex: "scheduledTime",
      key: "scheduledTime",
      // render: (text) => <span>{text.address}</span>,
    },
    {
      title: t("SCHEDULE_STATUS"),
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{t(text)}</span>,
    },
    {
      title: t("ACTION"),
      key: "action",
      render: (_, record) => (
        <div>
          <img
            src={editIcon}
            style={{ width: "20px", height: "18.56px", marginRight: "18px" }}
            onClick={onEditSchedule.bind(this, record)}
            className="cursor-pointer"
            title={t("EDIT")}
          />
          <img
            src={cancelIcon}
            style={{ width: "21px", height: "18.23px", marginRight: "18px" }}
            onClick={onCancelSchedule.bind(this, record)}
            className="cursor-pointer"
            title={t("CANCEL")}
          />
          <img
            src={deleteIcon}
            style={{ width: "20px", height: "21.77px" }}
            onClick={onDeleteSchedule.bind(this, record)}
            className="cursor-pointer"
            title={t("DELETE")}
          />
        </div>
      ),
    },
  ];

  const onEditSchedule = (scheduleItem) => {
    history.push("/dashboard/operation-create", {
      type: scheduleItem.type,
      scheduleItem: scheduleItem,
    });
  };

  const onCancelSchedule = async (scheduleItem) => {
    console.log(scheduleItem);
    let result = await operationScheduleCancel({ id: scheduleItem._id });
    setScheduleList([]);
    loadScheduleList();
  };

  const onDeleteSchedule = async (scheduleItem) => {
    console.log(scheduleItem);
    let result = await operationScheduleRemove({ id: scheduleItem._id });
    setScheduleList([]);
    loadScheduleList();
  };

  return (
    <div className="common-page">
      <div className="common-management operation-create-intro">
        <div>
          <div className="operation-panel">
            <div className="title-panel">
              <div>
                <img src={consumeIcon} className="title-img" />
              </div>
              <div className="title">{t("CONSUME_NOTIFICATION")}</div>
              <div className="title-line"></div>
            </div>
            <div className="item-panel">
              {consumeItems.map((item, index) => {
                return onRenderOrderItem(item, "CONSUME", index);
              })}
            </div>
          </div>
          <div className="operation-panel">
            <div className="title-panel">
              <div>
                <img src={orderIcon} className="title-img" />
              </div>
              <div className="title">{t("ORDER_NOTIFICATION")}</div>
              <div className="title-line"></div>
            </div>
            <div className="item-panel">
              {orderItems.map((item, index) => {
                return onRenderOrderItem(item, "ORDER", index);
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
