import { useUserId } from "../../../contexts/UserIdProvider";
import { useEffect, useState } from "react";
import NotAuthorized from "../../others/NotAuthorized";
import CompanyVerifyGlobal from "./CompanyVerifyGlobal";
import CompanyVerifyChina from "./CompanyVerifyChina";
import { UserType } from "../../../constants/usersType.enum";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { constants } from "../../../utils/utils";

export default function CompanyVerify() {
  let user = useUserId();
  const { t } = useTranslation();
  const [isChinaCompany, setIsChinaCompany] = useState(false);

  const [haveRight, setHaveRight] = useState(false);

  useEffect(() => {
    if (
      user.user.userInfo.companyDesc.location.country ===
        constants.chinaCountryId ||
      user.user.userInfo.type == UserType.BUYER
    ) {
      setIsChinaCompany(true);
    } else {
      setIsChinaCompany(false);
    }
  });

  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        flex: "1",
      }}
    >
      {!isChinaCompany && <CompanyVerifyGlobal />}
      {isChinaCompany && <CompanyVerifyChina importer={true} />}
      {haveRight && <NotAuthorized />}
    </div>
  );
}
