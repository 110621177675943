import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Image, Menu, Modal, Row, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import { localizeImg } from "../../constants/constants";
import './style/salonListRow.less';

export default function SalonListRow(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const { data } = props;

  useEffect(() => { }, [data]);

  const goToSalon = () => {
    history.push("/product/activities/" + data?._id);
  }

  return (
    <div onClick={goToSalon.bind(this)} className="salon-list-row-container cursor-pointer">
      <img
        src={localizeImg(data?.picture)}
        className="salon-image"
      />
      <div className="banner_item_title">{data?.title}</div>
    </div>
  );
}
