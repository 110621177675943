import React, { useEffect, useState } from "react";
import { List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../../contexts/UserIdProvider";
import { deepClone } from "../../../utils/utils";
import {
  blockUser,
  pageFriends,
  removeFriend,
  getBlockList,
  reportingUser,
} from "../../../api/contact";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./style/companyFavorites.less";
import { getPostPageFavorites } from "../../../api/favorite";
import CompanyItemBasic from "../../shared/CompanyItemBasic";
import { useLocation } from "react-router-dom";
import { userDetail } from "../../../api/user";
import BreadcrumbNormal from "../../common/title/BreadcrumbNormal";

export default function CompanyFavorites() {
  const { state } = useLocation();
  const productId = state?.productId;
  const { t } = useTranslation();
  const { user } = useUserId();
  const { userId } = user;
  const [dataList, setDataList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (productId) {
      loadData();
    }
  }, [pageIndex]);

  const loadData = async () => {
    setLoading(true);
    let pms = { productId, page: pageIndex, per_page: pageSize };
    let result = await getPostPageFavorites(pms);
    if (result) {
      result = result.result;
      let list = [];
      for (let index = 0; index < result.length; index++) {
        const item = result[index];
        if (
          item.companyInfo &&
          item.companyInfo.length > 0 &&
          item.companyInfo[0].contact
        ) {
          let uid = item.companyInfo[0].contact.userId;
          let _userInfo = await userDetail(uid);
          if (_userInfo) {
            _userInfo.name = _userInfo.nickName;
            list.push(_userInfo);
          }
        }
      }
      if (pageIndex > 0) {
        let tempList = deepClone(dataList);
        list = tempList.concat(list);
      }
      setDataList(list);
      console.log("----result : ", list);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div className="common-page">
      <BreadcrumbNormal title={t("Favorite company")} />
      <div
        className={
          "myContact-container common-management normal-shadow-padding10-white"
        }
      >
        <div className={"common-management-title"}>{t("Favorite company")}</div>
        <div className="common-management-panel">
          <InfiniteScroll
            className="scroll-content"
            dataLength={dataList.length}
            next={handleInfiniteOnLoad.bind(this)}
            hasMore={hasMore}
          >
            <List
              className="fensi"
              itemLayout="vertical"
              size="large"
              dataSource={dataList}
              renderItem={(item) => <CompanyItemBasic detail={item} />}
            >
              {loading && hasMore && (
                <div>
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                </div>
              )}
            </List>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
