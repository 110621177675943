import React, { useEffect, useState } from "react";
import { Tabs, Button, Table, Space, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import "./style/operationManagement.less";
import { useParams, useHistory } from "react-router-dom";
import consumeIcon from "../../../resource/icons/dashboard/operation/consume.svg";
import orderIcon from "../../../resource/icons/dashboard/operation/order.svg";
import editIcon from "../../../resource/icons/dashboard/operation/edit.svg";
import cancelIcon from "../../../resource/icons/dashboard/operation/cancel.svg";
import deleteIcon from "../../../resource/icons/dashboard/operation/delete.svg";
import { OperationScheduleType } from "../../../constants/operation/OperationScheduleType.enum";
import { deepClone } from "../../../utils/utils";
import {
  operationScheduleList,
  operationScheduleRemove,
  operationScheduleCancel,
} from "../../../api/operation";

export default function OperationManagement() {
  const { index } = useParams();
  const { t } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();

  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchType, setSearchType] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchTypes, setSearchTypes] = useState([
    {
      label: t("NEW_PRODUCT_ONLINE_NOTIFICATION"),
      value: "NEW_PRODUCT_ONLINE_NOTIFICATION",
    },
    {
      label: t("JOINED_ACTIVITY_NOTIFICATION"),
      value: "JOINED_ACTIVITY_NOTIFICATION",
    },
    {
      label: t("REPLENISH_NOTIFICATION"),
      value: "REPLENISH_NOTIFICATION",
    },
    {
      label: t("WAITING_FOR_PAY_NOTIFICATION"),
      value: "WAITING_FOR_PAY_NOTIFICATION",
    },
    {
      label: t("NEW_ORDER_NOTIFICATION"),
      value: "NEW_ORDER_NOTIFICATION",
    },
    {
      label: t("START_STOCK_NOTIFICATION"),
      value: "START_STOCK_NOTIFICATION",
    },
    {
      label: t("START_DELIVERY_NOTIFICATION"),
      value: "START_DELIVERY_NOTIFICATION",
    },
    {
      label: t("WAITING_FOR_PICKUP_NOTIFICATION"),
      value: "WAITING_FOR_PICKUP_NOTIFICATION",
    },
    {
      label: t("WAITING_FOR_COMPLETE_NOTIFICATION"),
      value: "WAITING_FOR_COMPLETE_NOTIFICATION",
    },
  ]);
  const [searchStatuses, setSearchStatuses] = useState([
    {
      label: t("WAITING"),
      value: "WAITING",
    },
    {
      label: t("COMPLETE"),
      value: "COMPLETE",
    },
    {
      label: t("FAILED"),
      value: "FAILED",
    },
    {
      label: t("CANCELLED"),
      value: "CANCELLED",
    },
    {
      label: t("SUSPEND"),
      value: "SUSPEND",
    },
  ]);

  const [scheduleList, setScheduleList] = useState([]);
  const [oriScheduleList, setOriScheduleList] = useState([]);

  useEffect(() => {
    loadScheduleList();
  }, []);

  // 计划列表
  const loadScheduleList = async () => {
    let pms = {
      coId: user?.userInfo.companyId,
    };
    let result = await operationScheduleList(pms);
    console.log("schedule list:", result);
    if (result && result.total > 0) {
      setScheduleList(result.result);
      setOriScheduleList(deepClone(result.result));
    }
  };

  const renderTargetUids = (users) => {
    if (users && users.length > 0) {
      return users[0].nickName + t("ETC") + users.length + t("PEOPLE");
    } else {
      return "-";
    }
  };

  const scheduleListColumns = [
    {
      title: t("SCHEDULE_NAME"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("SCHEDULE_TYPE"),
      dataIndex: "type",
      key: "type",
      render: (text) => <span>{t(text)}</span>,
    },
    {
      title: t("TARGET_USERS"),
      dataIndex: "targetUsers",
      key: "targetUsers",
      render: (text) => <span>{renderTargetUids(text)}</span>,
    },
    {
      title: t("TRIGGER_METHOD"),
      dataIndex: "companyInfo",
      key: "companyInfo",
      render: (text) => <span>{t("AUTOMATIC_TRIGGER")}</span>,
    },
    {
      title: t("SCHEDULE_TIME"),
      dataIndex: "scheduledTime",
      key: "scheduledTime",
      // render: (text) => <span>{text.address}</span>,
    },
    {
      title: t("SCHEDULE_STATUS"),
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{t(text)}</span>,
    },
    {
      title: t("ACTION"),
      key: "action",
      render: (_, record) => (
        <div>
          <img
            src={editIcon}
            style={{ width: "20px", height: "18.56px", marginRight: "18px" }}
            onClick={onEditSchedule.bind(this, record)}
            className="cursor-pointer"
            title={t("EDIT")}
          />
          <img
            src={cancelIcon}
            style={{ width: "21px", height: "18.23px", marginRight: "18px" }}
            onClick={onCancelSchedule.bind(this, record)}
            className="cursor-pointer"
            title={t("CANCEL")}
          />
          <img
            src={deleteIcon}
            style={{ width: "20px", height: "21.77px" }}
            onClick={onDeleteSchedule.bind(this, record)}
            className="cursor-pointer"
            title={t("DELETE")}
          />
        </div>
      ),
    },
  ];

  const onEditSchedule = (scheduleItem) => {
    history.push("/dashboard/operation-create", {
      type: scheduleItem.type,
      scheduleItem: scheduleItem,
    });
  };

  const onCancelSchedule = async (scheduleItem) => {
    console.log(scheduleItem);
    let result = await operationScheduleCancel({ id: scheduleItem._id });
    setScheduleList([]);
    loadScheduleList();
  };

  const onDeleteSchedule = async (scheduleItem) => {
    console.log(scheduleItem);
    let result = await operationScheduleRemove({ id: scheduleItem._id });
    setScheduleList([]);
    loadScheduleList();
  };

  const search = () => {
    let temp = deepClone(oriScheduleList);
    if (searchKeyword) {
      temp = temp.filter(
        (filterName) => filterName.name.indexOf(searchKeyword) != -1
      );
    }
    if (searchType) {
      temp = temp.filter((filterName) => filterName.type == searchType);
    }
    if (searchStatus) {
      temp = temp.filter((filterName) => filterName.type == searchStatus);
    }

    setScheduleList(temp);
  };

  const resetQurey = () => {
    setSearchKeyword("");
    setSearchType("");
    setSearchStatus("");
    loadScheduleList();
  };

  return (
    <div className="common-page">
      <div className="common-management operation-management">
        <div className="search-panel">
          <div className="search-item">
            <div className="label">{t("SCHEDULE_NAME")}</div>
            <Input
              value={searchKeyword}
              placeholder={t("PLEASE_INPUT_KEYWORD")}
              onInput={(e) => setSearchKeyword(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="search-item">
            <div className="label">{t("SCHEDULE_TYPE")}</div>
            <Select
              placeholder={t("ALL")}
              value={searchType}
              options={searchTypes}
              className="select-class"
              onChange={(val) => setSearchType(val)}
            />
          </div>
          <div className="search-item">
            <span className="label">{t("STATUS")}</span>
            <Select
              placeholder={t("ALL") + t("STATUS")}
              value={searchStatus}
              options={searchStatuses}
              className="select-class"
              onChange={(val) => setSearchStatus(val)}
            />
          </div>
          <div className="action-panel">
            <div className="btn-search" onClick={search.bind(this)}>
              {t("INQUIRES")}
            </div>
            <div className="btn-reset" onClick={resetQurey.bind(this)}>
              {t("RESET")}
            </div>
          </div>
        </div>
        <Table
          columns={scheduleListColumns}
          dataSource={scheduleList}
          className="operation-management-table"
          // rowSelection={selectedRowSelection}
        />
      </div>
    </div>
  );
}
