export const EMOTIONS_NAMES = [{
    name: '[aixin]',
    path: require('../resource/images/emotions/aixin.png'),
  },
  {
    name: '[aohuo]',
    path: require('../resource/images/emotions/aohuo.png'),
  }, {
    name: '[aoman]',
    path: require('../resource/images/emotions/aoman.png'),
  }, {
    name: '[baiyan]',
    path: require('../resource/images/emotions/baiyan.png'),
  }, {
    name: '[baoquan]',
    path: require('../resource/images/emotions/baoquan.png'),
  }, {
    name: '[beishang]',
    path: require('../resource/images/emotions/beishang.png'),
  }, {
    name: '[bianbian]',
    path: require('../resource/images/emotions/bianbian.png'),
  }, {
    name: '[bishi]',
    path: require('../resource/images/emotions/bishi.png'),
  }, {
    name: '[bizui]',
    path: require('../resource/images/emotions/bizui.png'),
  }, {
    name: '[buxie]',
    path: require('../resource/images/emotions/buxie.png'),
  }, {
    name: '[cahan]',
    path: require('../resource/images/emotions/cahan.png'),
  }, {
    name: '[caidao]',
    path: require('../resource/images/emotions/caidao.png'),
  }, {
    name: '[daku]',
    path: require('../resource/images/emotions/daku.png'),
  }, {
    name: '[dangao]',
    path: require('../resource/images/emotions/dangao.png'),
  }, {
    name: '[deyi]',
    path: require('../resource/images/emotions/deyi.png'),
  }, {
    name: '[diaoxie]',
    path: require('../resource/images/emotions/diaoxie.png'),
  }, {
    name: '[fa]',
    path: require('../resource/images/emotions/fa.png'),
  }, {
    name: '[fadai]',
    path: require('../resource/images/emotions/fadai.png'),
  }, {
    name: '[fadou]',
    path: require('../resource/images/emotions/fadou.png'),
  }, {
    name: '[fanu]',
    path: require('../resource/images/emotions/fanu.png'),
  }, {
    name: '[faxiao]',
    path: require('../resource/images/emotions/faxiao.png'),
  }, {
    name: '[fendou]',
    path: require('../resource/images/emotions/fendou.png'),
  }, {
    name: '[ganga]',
    path: require('../resource/images/emotions/ganga.png'),
  }, {
    name: '[gaoxing]',
    path: require('../resource/images/emotions/gaoxing.png'),
  }, {
    name: '[gouying]',
    path: require('../resource/images/emotions/gouying.png'),
  }, {
    name: '[guilian]',
    path: require('../resource/images/emotions/guilian.png'),
  }, {
    name: '[guzhang]',
    path: require('../resource/images/emotions/guzhang.png'),
  }, {
    name: '[haixiu]',
    path: require('../resource/images/emotions/haixiu.png'),
  }, {
    name: '[hanxiao]',
    path: require('../resource/images/emotions/hanxiao.png'),
  }, {
    name: '[haqian]',
    path: require('../resource/images/emotions/haqian.png'),
  }, {
    name: '[heiha]',
    path: require('../resource/images/emotions/heiha.png'),
  }, {
    name: '[heshi]',
    path: require('../resource/images/emotions/heshi.png'),
  }, {
    name: '[hongbao]',
    path: require('../resource/images/emotions/hongbao.png'),
  }, {
    name: '[huaixiao]',
    path: require('../resource/images/emotions/huaixiao.png'),
  }, {
    name: '[ji]',
    path: require('../resource/images/emotions/ji.png'),
  }, {
    name: '[jianxiao]',
    path: require('../resource/images/emotions/jianxiao.png'),
  }, {
    name: '[jiayou]',
    path: require('../resource/images/emotions/jiayou.png'),
  }, {
    name: '[jinkong]',
    path: require('../resource/images/emotions/jinkong.png'),
  }, {
    name: '[aixin]',
    path: require('../resource/images/emotions/aixin.png'),
  },
  {
    name: '[jinya]',
    path: require('../resource/images/emotions/jinya.png')
  },
  {
    name: '[jiong]',
    path: require('../resource/images/emotions/jiong.png')
  },
  {
    name: '[jizhi]',
    path: require('../resource/images/emotions/jizhi.png')
  },
  {
    name: '[kafei]',
    path: require('../resource/images/emotions/kafei.png')
  },
  {
    name: '[kelian]',
    path: require('../resource/images/emotions/kelian.png')
  },
  {
    name: '[kongju]',
    path: require('../resource/images/emotions/kongju.png')
  },
  {
    name: '[koubi]',
    path: require('../resource/images/emotions/koubi.png')
  },
  {
    name: '[kouzhao]',
    path: require('../resource/images/emotions/kouzhao.png')
  },
  {
    name: '[kuaikule]',
    path: require('../resource/images/emotions/kuaikule.png')
  },
  {
    name: '[kulu]',
    path: require('../resource/images/emotions/kulu.png')
  },
  {
    name: '[kun]',
    path: require('../resource/images/emotions/kun.png')
  },
  {
    name: '[liuhan]',
    path: require('../resource/images/emotions/liuhan.png')
  },
  {
    name: '[liulei]',
    path: require('../resource/images/emotions/liulei.png')
  },
  {
    name: '[liwu]',
    path: require('../resource/images/emotions/liwu.png')
  },
  {
    name: '[meigui]',
    path: require('../resource/images/emotions/meigui.png')
  },
  {
    name: '[nanguo]',
    path: require('../resource/images/emotions/nanguo.png')
  },
  {
    name: '[ok]',
    path: require('../resource/images/emotions/ok.png')
  },
  {
    name: '[piezui]',
    path: require('../resource/images/emotions/piezui.png')
  },
  {
    name: '[pijiu]',
    path: require('../resource/images/emotions/pijiu.png')
  },
  {
    name: '[qiang]',
    path: require('../resource/images/emotions/qiang.png')
  },
  {
    name: '[qiaoda]',
    path: require('../resource/images/emotions/qiaoda.png')
  },
  {
    name: '[qingzhu]',
    path: require('../resource/images/emotions/qingzhu.png')
  },
  {
    name: '[qinqin]',
    path: require('../resource/images/emotions/qinqin.png')
  },
  {
    name: '[quantou]',
    path: require('../resource/images/emotions/quantou.png')
  },
  {
    name: '[ruo]',
    path: require('../resource/images/emotions/ruo.png')
  },
  {
    name: '[se]',
    path: require('../resource/images/emotions/se.png')
  },
  {
    name: '[shadai]',
    path: require('../resource/images/emotions/shadai.png')
  },
  {
    name: '[shengli]',
    path: require('../resource/images/emotions/shengli.png')
  },
  {
    name: '[shuai]',
    path: require('../resource/images/emotions/shuai.png')
  },
  {
    name: '[shui]',
    path: require('../resource/images/emotions/shui.png')
  },
  {
    name: '[taiyang]',
    path: require('../resource/images/emotions/taiyang.png')
  },
  {
    name: '[tiaopi]',
    path: require('../resource/images/emotions/tiaopi.png')
  },
  {
    name: '[tiaotiao]',
    path: require('../resource/images/emotions/tiaotiao.png')
  },
  {
    name: '[tu]',
    path: require('../resource/images/emotions/tu.png')
  },
  {
    name: '[tushetou]',
    path: require('../resource/images/emotions/tushetou.png')
  },
  {
    name: '[weiqu]',
    path: require('../resource/images/emotions/weiqu.png')
  },
  {
    name: '[weixiao]',
    path: require('../resource/images/emotions/weixiao.png')
  },
  {
    name: '[woshou]',
    path: require('../resource/images/emotions/woshou.png')
  },
  {
    name: '[wulian]',
    path: require('../resource/images/emotions/wulian.png')
  },
  {
    name: '[xiaoku]',
    path: require('../resource/images/emotions/xiaoku.png')
  },
  {
    name: '[xigua]',
    path: require('../resource/images/emotions/xigua.png')
  },
  {
    name: '[xu]',
    path: require('../resource/images/emotions/xu.png')
  },
  {
    name: '[ye]',
    path: require('../resource/images/emotions/ye.png')
  },
  {
    name: '[yinxian]',
    path: require('../resource/images/emotions/yinxian.png')
  },
  {
    name: '[yiwen]',
    path: require('../resource/images/emotions/yiwen.png')
  },
  {
    name: '[yongbao]',
    path: require('../resource/images/emotions/yongbao.png')
  },
  {
    name: '[youhengheng]',
    path: require('../resource/images/emotions/youhengheng.png')
  },
  {
    name: '[youxian]',
    path: require('../resource/images/emotions/youxian.png')
  },
  {
    name: '[yueliang]',
    path: require('../resource/images/emotions/yueliang.png')
  },
  {
    name: '[yukuai]',
    path: require('../resource/images/emotions/yukuai.png')
  },
  {
    name: '[yun]',
    path: require('../resource/images/emotions/yun.png')
  },
  {
    name: '[zaijian]',
    path: require('../resource/images/emotions/zaijian.png')
  },
  {
    name: '[zhadan]',
    path: require('../resource/images/emotions/zhadan.png')
  },
  {
    name: '[zhouma]',
    path: require('../resource/images/emotions/zhouma.png')
  },
  {
    name: '[zhoumei]',
    path: require('../resource/images/emotions/zhoumei.png')
  },
  {
    name: '[zhuakuang]',
    path: require('../resource/images/emotions/zhuakuang.png')
  },
  {
    name: '[zhutou]',
    path: require('../resource/images/emotions/zhutou.png')
  },
  {
    name: '[ziya]',
    path: require('../resource/images/emotions/ziya.png')
  },
  {
    name: '[zuichun]',
    path: require('../resource/images/emotions/zuichun.png')
  },
  {
    name: '[zuohengheng]',
    path: require('../resource/images/emotions/zuohengheng.png')
  }
  ]
  
  export const EMOTIONS_CANCEL = [
    {
      name: '[ic_emoji_del]',
      path: require('../resource/images/emotions/ic_emoji_del.png')
    },
    //require('../resource/images/emotions/ic_emoji.png'),
  ]