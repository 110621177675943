import React, { useState, useEffect } from "react";
import {
  Radio,
  Button,
  Input,
  InputNumber,
  Select,
  Tag,
  Modal,
  DatePicker,
  Steps,
  Menu,
  Row,
  Col,
} from "antd";
import "./style/productCreate.less";
import {
  productCreate,
  productUpdate,
  loadProductConfig,
  productDetail as getProductInfo,
  getProductGrapes,
  getProductConfigForSelect,
  getProductConfigNameForSelect,
  getProductConfigSelectNameByValue,
  getProductMinPrice,
} from "../../../api/product";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { toastError, toastSuccess } from "../../../api/toast";
import { deepClone } from "../../../utils/utils";
import {
  localizeImg,
  localizeTitle,
  ProductConfig,
  ProductSampleValue,
  UploadFileType,
} from "../../../constants/constants";
import { useUserId } from "../../../contexts/UserIdProvider";
import ProductTagCreate from "../product-tag/ProductTagCreate";
import moment from "moment";
import { ProductStatus, statusCode } from "../../../constants/statusCode.enum";
import UploadPictureCard from "../../common/upload/UploadPictureCard";
import ProductVideoUpload from "../../common/upload/ProductVideoUpload";
import UploadBasic from "../../common/upload/UploadBasic";
import addBtnIcon from "../../../resource/icons/common/add.svg";
import plusBtnIcon from "../../../resource/icons/common/plus.svg";
import deleteIcon from "../../../resource/icons/dashboard/create_product/delete.svg";
import productDescSampleImage from "../../../resource/images/product-create/product_desc_sample.png";
import productSampleImage from "../../../resource/images/product-create/product.jpg";
import downIcon from "../../../resource/icons/common/more.svg";
import cancelIcon from "../../../resource/icons/dashboard/create_product/cancel.svg";
import {
  getCountryForSelect,
  getCountryNameById,
  getLocationsByParentId,
} from "../../../api/config";
import CountryGroup from "../../common/select/CountryGroup";
import InputNumberUnitBase from "../../common/input/InputNumberUnitBase";
import NumberNormal from "../../common/input/numberNormal/NumberNormal";
import NumberCenter from "../../common/input/numberCenter/NumberCenter";
import { warehouses } from "../../../api/warehouse";
import InputNumberBase from "../../common/input/InputNumberBase";
import { BrowseRecordType } from "../../../constants/browseRecord.enum";
import { browseRecordCreate } from "../../../api/browseRecord";
import PictureUploader from "../../common/upload/picture-uploader/PictureUploader";

export default function ProductCreate() {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();
  const { user } = useUserId();
  const [productId, setProductId] = useState();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const { Step } = Steps;
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const productSteps = [
    { title: t("PRODUCT_INFORMATION") },
    { title: t("SELLING_POINTS") },
    { title: t("TRADING_CONDITION") },
    { title: t("PRODUCT_DETAIL") },
    { title: t("VIDEO") },
  ];
  const optionsYesOrNo = [
    { label: t("YES"), value: true },
    { label: t("NO"), value: false },
  ];
  const [optionsUnit, setOptionsUnit] = useState([]);
  const optionsExw = [
    { label: t("EXW"), value: "EXW" },
    { label: t("FOB"), value: "FOB" },
    { label: t("CIF"), value: "CIF" },
  ];
  const { TextArea } = Input;
  const volumePriceItem = {
    priceRange: [{ price: 0, minCount: 0 }],
    price: 0,
    minPrice: 0,
    minCount: 1000,
    stock: 5000,
    preparation: 1,
  };

  //#region attributes
  const [bottleImages, setBottleImages] = useState([]);
  const [wineryImages, setWineryImages] = useState([]);
  const [awardImages, setAwardImages] = useState([]);
  const [organicImages, setOrganicImages] = useState([]);
  const [otherImages, setOtherImages] = useState([]);
  const [descImages, setDescImages] = useState([]);
  const [videos, setVideos] = useState([]);

  const [zhTitle, setZhTitle] = useState(); //标题 - Title
  const [zhTitleError, setZhTitleError] = useState(false);
  const [year, setYear] = useState(2022); //年份 - Year
  const [alcohol, setAlcohol] = useState(10); //年份 - alcohol degree
  const [supportSample, setSupportSample] = useState(false); //是否支持样品 - Support sample
  const [sampleCount, setSampleCount] = useState(0); //样品数量 - number of samples
  const [deposit, setDeposit] = useState(process.env.REACT_APP_DEPOSIT_AMOUNT); //订金比例 - deposit

  /**
   * Country related variables eg. france, canada, china-region
   */
  const [areas, setAreas] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [orgClassifications, setOrgClassifications] = useState([]);

  /**
   * Region related variables eg. bordeaux , south ouest, etc
   */
  const [region, setRegion] = useState();
  const [regions, setRegions] = useState([]);
  const [subRegion, setSubRegion] = useState();
  const [subRegions, setSubRegions] = useState([]);
  const [subSubRegion, setSubSubRegion] = useState();
  const [subSubRegions, setSubSubRegions] = useState([]);

  /**
   * Type of wine related variables eg. red, white, rose, etc
   */
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);

  /**
   * the amount in ml the bottle can contain eg. 500, 750 , 1000
   */
  // const [volume, setVolume] = useState();
  const [volumes, setVolumes] = useState([]);
  const [volumePrice, setVolumePrice] = useState([]);

  /**
   * The appellation of the wine , eg. Chablis , Saint Emilion , AOC , Vin de france
   */
  const [classification, setClassification] = useState();
  const [classifications, setClassifications] = useState([]);

  const [grape, setGrape] = useState([]);
  const [grapes, setGrapes] = useState([]);

  const [category, setCategory] = useState();
  const [categories, setCategories] = useState([]);

  const [oakBarrel, setOakBarrel] = useState(true);
  const [timeAging, setTimeAging] = useState();
  const [timeAgingError, setTimeAgingError] = useState(false);
  const [temperature, setTemperature] = useState();
  const [temperatureError, setTemperatureError] = useState(false);
  const [currency, setCurrency] = useState();
  const [currencys, setCurrencys] = useState();
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState();
  const [preparation, setPreparation] = useState();
  const [productBottleSampleModalVisible, setProductBottleSampleModalVisible] =
    useState(false);
  const [productDescSampleModalVisible, setProductDescSampleModalVisible] =
    useState(false);
  const [stockLocationDropDownVisible, setStockLocationDropDownVisible] =
    useState(false);
  const [volumePriceSelectedIndex, setVolumePriceSelectedIndex] = useState(0);
  const [showCancelConfirmModalVisible, setShowCancelConfirmModalVisible] =
    useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const [shippingAddress, setShippingAddress] = useState("");

  /**
   * Tags related logic.
   */
  const [tagModalVisible, setTagModalVisible] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagData, setTagData] = useState([]);

  const [zhTastingNote, setZhTastingNote] = useState();

  const [status, setStatus] = useState(ProductStatus.DRAFT);
  //#endregion

  useEffect(() => {
    if (state && state.productId) {
      setProductId(state.productId);
      initProductDetail(state.productId);
    } else {
      onAddVolumeItem();
    }
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setConfigData();
    if (country && region && regions.length > 0) {
      onCountrySelect(areas, country);
    }
    if (region && subRegion && subRegions.length > 0) {
      onRegionSelect(areas, region);
    }
    if (subRegion && subSubRegions && subSubRegions.length > 0) {
      onSubRegionSelect(areas, subRegion);
    }
    setOptionsUnit([
      { label: t("BOTTLES"), value: "BOTTLE" },
      { label: t("CONTAINER"), value: "CONTAINER" },
    ]);
  }, [currentLanguage]);

  useEffect(() => {
    if (country) {
      onCountrySelect(areas, country);
    } else {
      setRegions([]);
      setClassifications([]);
    }
  }, [country]);

  useEffect(() => {
    if (region) {
      onRegionSelect(areas, region);
    } else {
      setSubRegion();
    }
  }, [region]);

  useEffect(() => {
    if (subRegion) {
      onSubRegionSelect(areas, subRegion);
    } else {
      setSubSubRegions();
    }
  }, [subRegion]);

  const onPublishProduct = () => {
    onSubmitNewProduct(0);
  };

  const onSaveDraftProduct = () => {
    onSubmitNewProduct(5);
  };

  const onSubmitNewProduct = async (productStatus) => {
    if (!zhTitle) {
      toastError(t("TITLE") + t("IS_REQUIRED"));
      setZhTitleError(true);
      return;
    } else {
      setZhTitleError(false);
    }
    if (!classification) {
      toastError(t("CLASSIFICATIONS") + t("IS_REQUIRED"));
      return;
    }
    if (!category) {
      toastError(t("PRODUCT_CATEGORY") + t("IS_REQUIRED"));
      return;
    }
    if (!grape || grape.length === 0) {
      toastError(t("GRAPE") + t("IS_REQUIRED"));
      return;
    }
    if (!type) {
      toastError(t("PRODUCT_TYPE") + t("IS_REQUIRED"));
      return;
    }
    if (!alcohol || Number(alcohol) <= 0) {
      toastError(t("ALCOHOL_DEGREE") + t("IS_REQUIRED"));
      return;
    }
    if (isNaN(temperature)) {
      toastError(t("SERVING_TEMPERATURE") + t("IS_REQUIRED"));
      setTemperatureError(true);
      return;
    } else {
      setTemperatureError(false);
    }
    if (!country) {
      toastError(t("COUNTRY") + t("IS_REQUIRED"));
      return;
    }
    // if (!region) {
    //   toastError(t("REGION") + t("IS_REQUIRED"));
    //   return;
    // }
    if (!year) {
      toastError(t("YEAR") + t("IS_REQUIRED"));
      return;
    }
    if (oakBarrel && (!timeAging || Number(timeAging) <= 0)) {
      toastError(t("AGING_PERIOD") + t("IS_REQUIRED"));
      setTimeAgingError(true);
      return;
    } else {
      setTimeAgingError(false);
    }

    if (bottleImages.length < 2) {
      toastError(t("PICTURE_OF_THE_WINERIES_OR_VINYARD") + t("IS_REQUIRED"));
      return;
    }

    if (!tags || tags.length === 0) {
      toastError(t("TAGS" + "IS_REQUIRED"));
      return;
    }

    if (!currency) {
      toastError(t("TRADING_CURRENCY") + t("IS_REQUIRED"));
      return;
    }
    if (!paymentMethod || paymentMethod.length === 0) {
      toastError(t("PAYMENT_METHOD_PLACEHOLDER") + t("IS_REQUIRED"));
      return;
    }

    console.log("-------check volumePrice:", volumePrice);
    let list = volumePrice.filter(
      (item) =>
        !item.stockCountry ||
        !item.stockCity ||
        !item.volume ||
        !item.stock ||
        !item.stock.value ||
        Number(item.stock.value) <= 0 ||
        !item.stock.unit ||
        !item.preparation ||
        Number(item.preparation) <= 0
    );
    if (list.length > 0) {
      // toastError(t("PLEASE_INPUT_PRODUCT_PRICE_INFORMATION"));
      // return;
    }
    console.log("-------check price range:", volumePrice);
    // let priceFlag = false;
    // volumePrice.forEach((volItem) => {
    //   if (volItem.priceRange && volItem.priceRange.length > 0) {
    //     let priceList = volItem.priceRange.filter(
    //       (priceItem) =>
    //         !priceItem.price ||
    //         !priceItem.price.value ||
    //         Number(priceItem.price.value) <= 0 ||
    //         !priceItem.price.unit ||
    //         !priceItem.minCount ||
    //         Number(priceItem.minCount) <= 0
    //     );
    //     if (priceList.length > 0) {
    //       priceFlag = true;
    //       return;
    //     }
    //   } else {
    //     priceFlag = true;
    //     return;
    //   }
    // });
    // if (priceFlag) {
    //   toastError(t("PLEASE_INPUT_PRODUCT_PRICE_INFORMATION"));
    //   return;
    // }

    let pms = getProductPms();
    pms.status = productStatus;
    console.log("----product submit:", pms);
    if (pms) {
      if (!productId) {
        console.log("----product create:", pms);
        const createProductId = await productCreate(pms);
        if (createProductId) {
          setProductId(createProductId);
          toastSuccess(t("SAVE_SUCCESS"));
          goWhereWhenActionSuccess();

          let browseRecordPms = {
            coId: user.userInfo.companyId,
            userId: user.userId,
            busType: BrowseRecordType.ADD_PRODUCT,
            busId: createProductId,
          };
          browseRecordCreate(browseRecordPms);
        } else {
          toastError(t("SAVE_FAILED"));
        }
      } else {
        console.log("----product update:", pms);
        let data = await productUpdate(pms);
        if (data) {
          toastSuccess(t("SAVE_SUCCESS"));

          let browseRecordPms = {
            coId: user.userInfo.companyId,
            userId: user.userId,
            busType: BrowseRecordType.EDIT_PRODUCT,
            busId: productId,
          };
          browseRecordCreate(browseRecordPms);

          goWhereWhenActionSuccess();
        } else {
          toastError(t("SAVE_FAILED"));
        }
      }
    }
  };

  const goWhereWhenActionSuccess = () => {
    history.push("/shop/products");
  };

  const getProductPms = () => {
    let pms = null;
    pms = {
      coId: user.userInfo.companyId,
      // title: { zh: zhTitle, en: enTitle, fr: frTitle },
      title: zhTitle,
      category,
      types: type,
      country,
      region,
      subRegion,
      subSubRegion,
      classifications: classification,
      grapes: grape,
      alcohol,
      temperature,
      volumes: volumePrice,
      year,
      oakBarrel,
      timeAging,
      tastingNote: zhTastingNote,
      descImages,
      bottleImages,
      videos,
      tags,
      currency,
      paymentMethods: paymentMethod,
      preparation,
      supportSample: supportSample === true,
      sampleCount,
      deposit,
      stepIndex: 1,
      price: getProductMinPrice(volumePrice),
      status,
      awardImages,
      organicImages,
      otherImages,
    };
    if (productId) {
      pms._id = productId;
    }
    return pms;
  };

  const initProductDetail = async (id) => {
    let pms = { id };
    let data = await getProductInfo(pms);
    console.log("----productinfo:", data);
    if (data.statusCode === statusCode.SUCCESS && data.result) {
      setProductAttributes(data.result);
    }
  };

  const setProductAttributes = (productItem) => {
    setZhTitle(productItem.title);
    setZhTastingNote(localizeTitle(currentLanguage, productItem.tastingNote));

    setCategory(productItem.category);
    setType(productItem.types);
    setGrape(productItem.grapes);

    setCountry(productItem.country);
    setTimeout(() => {
      setRegion(productItem.region);
    }, 300);
    setTimeout(() => {
      setSubRegion(productItem.subRegion);
    }, 600);
    setTimeout(() => {
      setSubSubRegion(productItem.subSubRegion);
    }, 900);
    setClassification(productItem.classifications);

    // formatVolume(productItem.volumes);
    setAlcohol(productItem.alcohol);
    setTemperature(productItem.temperature);
    setYear(productItem.year);
    setOakBarrel(productItem.oakBarrel);
    setTimeAging(productItem.timeAging);
    setDescImages(productItem.descImages);
    setBottleImages(productItem.bottleImages);
    setVideos(productItem.videos);
    setTags(productItem.tags);

    setAwardImages(productItem.awardImages);
    setOrganicImages(productItem.organicImages);
    setOtherImages(productItem.otherImages);

    setCurrency(productItem.currency);
    setPaymentMethod(productItem.paymentMethods);
    setVolumePrice(productItem.volumes);
    setShippingAddress(productItem.shippingAddress);
    setPreparation(productItem.preparation);
    setSupportSample(productItem.supportSample);
    setSampleCount(productItem.sampleCount);
    setDeposit(productItem.deposit);
    setStatus(productItem.status);

    let tempCurrentStepIndex = -1;
    if (productItem.title) {
      tempCurrentStepIndex = 0;
    }
    if (productItem.tags && productItem.tags.length > 0) {
      tempCurrentStepIndex = 1;
    }
    if (productItem.volumes && productItem.volumes.length > 0) {
      tempCurrentStepIndex = 2;
    }
    if (productItem.descImages && productItem.descImages.length > 0) {
      tempCurrentStepIndex = 3;
    }
    if (productItem.videos && productItem.videos.length > 0) {
      tempCurrentStepIndex = 4;
    }

    setCurrentStepIndex(tempCurrentStepIndex);
  };

  const initProductGrapes = async () => {
    let pms = {
      pms: JSON.stringify({
        page: 0,
        per_page: 1000,
      }),
    };
    let result = await getProductGrapes(pms);
    return result;
  };

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let {
      locations,
      types,
      bottles,
      capsules,
      sealed,
      volumes,
      classifications,
      categories,
      tags,
      currencys,
      paymentMethods,
    } = configData;

    loadCountry(locations);
    loadSelectData(types, ProductConfig.TYPES);
    loadSelectData(bottles, ProductConfig.BOTTLES);
    loadSelectData(capsules, ProductConfig.CAPSULES);
    loadSelectData(sealed, ProductConfig.SEALEDS);
    loadSelectData(classifications, ProductConfig.CLASSIFICATIONS);
    loadSelectData(categories, ProductConfig.CATEGORIES);
    loadSelectData(tags, ProductConfig.TAGS);

    let grapeList = await initProductGrapes();
    loadSelectData(grapeList, ProductConfig.GRAPES);

    let list = getProductConfigNameForSelect(volumes, currentLanguage);
    list.push({ label: ProductSampleValue, value: ProductSampleValue });
    list.map((item) => {
      if (item.label == "Sample") {
        item.label = t("SAMPLES");
      } else {
        item.label = item.label + " ML";
      }
    });
    setVolumes(list);

    setCurrencys(currencys);
    setCurrency(currencys[0].value);
    paymentMethods = paymentMethods.map((item) => {
      return { ...item, label: t(item.value) };
    });
    setPaymentMethods(paymentMethods);
    setPaymentMethod([paymentMethods[0].value]);

    let pms = { coId: user.userInfo.companyId };
    let data = await warehouses(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      let list = data.result.result;
      if (list && list.length > 0) {
        list = list.map((item, index) => {
          return { ...item, key: index, label: item.name, value: item._id };
        });
        setWarehouseList(list);
      }
    }
  };

  const loadSelectData = (data, flag) => {
    let result = getProductConfigForSelect(data, currentLanguage);
    if (result && result.length > 0) {
      let defaultValue = result[0].value;
      switch (flag) {
        case ProductConfig.TYPES:
          setTypes(result);
          setType(defaultValue);
          break;
        case ProductConfig.BOTTLES:
          break;
        case ProductConfig.CAPSULES:
          break;
        case ProductConfig.SEALEDS:
          break;
        case ProductConfig.CLASSIFICATIONS:
          setOrgClassifications(result);
          setClassifications(result);
          setClassification(defaultValue);
          break;
        case ProductConfig.CATEGORIES:
          setCategories(result);
          setCategory(defaultValue);
          break;
        case ProductConfig.GRAPES:
          setGrapes(result);
          setGrape([defaultValue]);
          break;
        case ProductConfig.TAGS:
          setTagData(result);
          break;
        default:
          break;
      }
    }
  };

  const loadCountry = (locations) => {
    // console.log("-------------loadCountry:", locations);
    setAreas(locations);
    let result = getCountryForSelect(locations, currentLanguage,user.userInfo.type);
    setCountries(result);
    let countryId = "";
    if (result && result.length > 0) {
      countryId = result[0].value;
      setCountry(countryId);
    }
  };

  const onCountrySelect = (locations, id) => {
    // console.log("------on country select:", locations);
    getClassifications(id);
    let result = getLocationsByParentId(locations, id, currentLanguage);
    setRegions(result);
    setRegion();
    setSubRegion();
    setSubSubRegion();
  };

  const onRegionSelect = (locations, id) => {
    let result = getLocationsByParentId(locations, id, currentLanguage);
    setSubRegions(result);
    setSubRegion();
    setSubSubRegion();
  };

  const onSubRegionSelect = (locations, id) => {
    let result = getLocationsByParentId(locations, id, currentLanguage);
    setSubSubRegions(result);
    setSubSubRegion();
  };

  const getClassifications = (countryId) => {
    let list = orgClassifications.filter(
      (item) => item.countryid === countryId
    );
    setClassifications(list);
  };
  //#endregion

  const getInputLabelInLine = (text) => {
    return (
      <div>
        <span className="input-label">{text}</span>
        {/* <span className="required-tip">*</span> */}
      </div>
    );
  };

  const getInputLabel = (text) => {
    return (
      <>
        <span className="input-label">{text}</span>
        {/* <span className="required-tip">*</span> */}
      </>
    );
  };

  const onBottleImageUpload = (urlList) => {
    setBottleImages(urlList);
  };

  const onWineryImageUpload = (urlList) => {
    setWineryImages(urlList);
  };

  const onAwardImageUpload = (urlList) => {
    setAwardImages(urlList);
  };

  const onOrganicImageUpload = (urlList) => {
    setOrganicImages(urlList);
  };

  const onOtherImageUpload = (urlList) => {
    setOtherImages(urlList);
  };

  const onDescImagesUpload = (urlList) => {
    setDescImages(urlList);
  };

  const onVintageChange = (date, dateString) => {
    setYear(dateString);
  };

  const onTypeChange = (e) => {
    setType(e);
  };

  const onStockChange = (index, e) => {
    console.log("onStockChange:", e);
    let list = deepClone(volumePrice);
    list[index].stock = e;
    setVolumePrice(list);
  };

  const onPreparationChange = async (index, val) => {
    let list = deepClone(volumePrice);
    list[index].preparation = val;
    setVolumePrice(list);
  };

  const onAddTag = (val) => {
    let list = deepClone(tags);
    if (list.indexOf(val) === -1) {
      list.push(val);
    }
    setTags(list);
  };

  const onTagClose = (val, e) => {
    e.preventDefault();
    let list = deepClone(tags);
    list = list.filter((item) => item !== val);
    setTags(list);
  };

  const next = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const onVideoUpLoaded = (fileType, url) => {
    if (url) {
      setVideos([url]);
    }
  };

  const onStockLocationShow = (index) => {
    console.log(index);
    setStockLocationDropDownVisible(true);
    setVolumePriceSelectedIndex(index);
  };

  const onStockLocationSelect = (index, stockCountry, stockCity) => {
    // console.log('-----stock select', index)
    setStockLocationDropDownVisible(false);
    let list = deepClone(volumePrice);
    let curItem = list[index];
    if (curItem.volume) {
      let filterList = list.filter(
        (item) =>
          item.stockCountry === stockCountry &&
          item.stockCity === stockCity &&
          item.volume === curItem.volume
      );
      if (filterList && filterList.length > 0) {
        toastError("data is repeat");
        return;
      }
    }
    list[index].stockCountry = stockCountry;
    list[index].stockCity = stockCity;
    setVolumePrice(list);
  };

  const onAddVolumeItem = () => {
    let list = deepClone(volumePrice);
    list.push(volumePriceItem);
    setVolumePrice(list);
  };

  const onRemoveVolumeItem = (index) => {
    let list = deepClone(volumePrice);
    list = list.filter((item, i) => index !== i);
    setVolumePrice(list);
  };

  const onAddVolumePriceItem = (index) => {
    let list = deepClone(volumePrice);
    list.push({});
    setVolumePrice(list);
  };

  const onRemoveVolumePriceItem = (index, pindex) => {
    let list = deepClone(volumePrice);
    list = list.map((item, i) => {
      if (i === index) {
        item.priceRange = item.priceRange.filter((pitem, j) => pindex !== j);
      }
      return item;
    });
    setVolumePrice(list);
  };

  const onVolumeChange = (index, val) => {
    let list = deepClone(volumePrice);
    let curItem = list[index];
    if (curItem.stockCountry || curItem.stockCity) {
      let filterList = list.filter(
        (item) =>
          item.stockCountry === curItem.stockCountry &&
          item.stockCity === curItem.stockCity &&
          item.volume === val
      );
      if (filterList && filterList.length > 0) {
        toastError("data is repeat");
        return;
      }
    }
    list[index].volume = val;
    setVolumePrice(list);
  };

  const onVolumePriceRangeChange = (index, val) => {
    let list = deepClone(volumePrice);
    list[index].price = val;
    setVolumePrice(list);
  };

  const onVolumeMinCountChange = (index, val) => {
    let list = deepClone(volumePrice);
    list[index].minCount = val;
    setVolumePrice(list);
  };

  const onShippingAddressChange = (index, val) => {
    let list = deepClone(volumePrice);
    list[index].shippingAddress = val;
    setVolumePrice(list);
  };

  const onVolumeCurrencyChange = (index, val) => {
    console.log(val);
    let list = deepClone(volumePrice);
    list[index].currency = val;
    setVolumePrice(list);
  };

  const onPaymentMethodChange = (index, val) => {
    let list = deepClone(volumePrice);
    list[index].paymentMethod = val;
    setVolumePrice(list);
  };

  return (
    <div className="product-create-container">
      <div className="product-create">
        <div className="create-header">
          <span className="create-product-title">{t("CREATE_PRODUCT")}</span>
          <div className="content-title-navigator">
            <Steps current={currentStepIndex}>
              {productSteps.map((item, index) => (
                <Step key={index} title={item.title} />
              ))}
            </Steps>
          </div>
        </div>

        <div className="create-content">
          <div className="content-item step-item">
            <div className="content-item-title">{t("PRODUCT_INFO")}</div>
            <div className="steps-content product-detail">
              <div className="product-detail-row">
                <div className="product-detail-row-item">
                  <div className="product-detail-row-item-name">
                    <div>{getInputLabelInLine(t("PRODUCT_NAME"))}</div>
                  </div>
                  <Input
                    value={zhTitle}
                    placeholder={t("PLEASE_WRITE_THE_PRODUCT_NAME")}
                    className={
                      zhTitleError
                        ? "form-input form-input-error"
                        : "form-input"
                    }
                    onInput={(e) => setZhTitle(e.target.value)}
                  />
                </div>
                <div className="product-detail-row-item">
                  {getInputLabelInLine(t("CLASSIFICATIONS"))}
                  <Select
                    placeholder={t("WINE_CLASSIFICATIONS_PLACEHOLDER")}
                    value={classifications.length > 0 ? classification : null}
                    options={classifications}
                    className="product-type"
                    onChange={(val) => setClassification(val)}
                  />
                </div>
              </div>
              <div className="product-detail-row">
                <div className="product-detail-row-item">
                  {getInputLabelInLine(t("PRODUCT_CATEGORY"))}
                  <Select
                    placeholder={t("CATEGORY_PLACEHOLDER")}
                    value={category}
                    options={categories}
                    className="product-type"
                    onChange={(val) => setCategory(val)}
                  />
                </div>
                <div className="product-detail-row-item">
                  <div>
                    {getInputLabel(t("GRAPE"))}{" "}
                    <span className="normal-unit">{t("MULTIPLE_CHOICE")}</span>
                  </div>
                  <Select
                    placeholder={t("GRAPE")}
                    mode="multiple"
                    allowClear
                    value={grape}
                    options={grapes}
                    className="product-type"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={(val) => setGrape(val)}
                  />
                </div>
              </div>
              <div className="product-detail-row">
                <div className="product-detail-row-item">
                  {getInputLabelInLine(t("PRODUCT_TYPE"))}
                  <Select
                    placeholder={t("PRODUCT_TYPE_PLACEHOLDER")}
                    value={type}
                    options={types}
                    className="product-type"
                    onChange={onTypeChange.bind(this)}
                  />
                </div>
                <div className="product-detail-row-item">
                  <Row className="product-detail-column-item">
                    <Col span={10}>
                      {getInputLabelInLine(t("ALCOHOL_DEGREE"))}
                      <div>
                        <NumberNormal
                          value={alcohol}
                          onChange={(val) => setAlcohol(val)}
                          addonAfter="%"
                        />
                      </div>
                    </Col>
                    <Col span={10}>
                      {getInputLabelInLine(t("SERVING_TEMPERATURE"))}
                      <div>
                        <NumberNormal
                          value={temperature}
                          inputClassName={
                            temperatureError ? "form-input-error" : ""
                          }
                          onChange={(val) => setTemperature(val)}
                          addonAfter="℃"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="product-detail-row">
                <div className="product-detail-row-item">
                  {getInputLabelInLine(t("COUNTRY"))}
                  <Select
                    placeholder={t("COUNTRY_PLACEHOLDER")}
                    value={country}
                    options={countries}
                    className="product-type"
                    onChange={(val) => {
                      setCountry(val);
                    }}
                  />
                </div>
                <div className="product-detail-row-item">
                  {getInputLabelInLine(t("YEARS"))}
                  <div>
                    <DatePicker
                      value={year ? moment(year, "YYYY") : year}
                      onChange={onVintageChange.bind(this)}
                      picker="year"
                      className="product-input-number"
                      placeholder={t("PLEASE_WRITE_YEARS")}
                      disabledDate={(current) => {
                        return current && current.year() > moment().year();
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="product-detail-row">
                <div className="product-detail-row-item">
                  {/* {getInputLabelInLine(t("REGION"))} */}
                  <div className="input-label">{t("REGION")}</div>
                  <Select
                    placeholder={t("REGION_PLACEHOLDER")}
                    value={region}
                    options={regions}
                    className="product-type"
                    onChange={(val) => {
                      setRegion(val);
                      setSubRegion(null);
                    }}
                  />
                </div>
                <div className="product-detail-row-item">
                  <div className="product-detail-column-item">
                    <div className="product-detail-column-item-half">
                      <div className="input-label">
                        {t("AGED_IN_OAK_BARREL")}
                      </div>
                      <Radio.Group
                        options={optionsYesOrNo}
                        onChange={(e) => setOakBarrel(e.target.value)}
                        value={oakBarrel}
                        optionType="button"
                        buttonStyle="solid"
                      />
                    </div>
                    <div className="product-detail-row-item">
                      <div>{getInputLabel(t("AGING_PERIOD"))} </div>
                      <div>
                        <NumberNormal
                          value={timeAging}
                          inputClassName={
                            timeAgingError ? "form-input-error" : ""
                          }
                          onChange={(val) => setTimeAging(val)}
                          addonAfter={t("MONTHS")}
                        />
                        {/* <InputNumber
                          className="product-input-vol"
                          value={timeAging}
                          min={0}
                          max={1200}
                          disabled={!oakBarrel}
                          onChange={(val) => setTimeAging(val)}
                          addonAfter={t("MONTHS")}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-detail-row">
                <div className="product-detail-row-item">
                  <div className="input-label">{t("SUB_REGION")}</div>
                  <Select
                    placeholder={t("SUB_REGION_PLACEHOLDER")}
                    value={subRegions.length > 0 ? subRegion : null}
                    options={subRegions}
                    className="product-type"
                    onChange={(value) => {
                      setSubRegion(value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* )} */}
          <div className={"content-item step-item "}>
            <div className="content-item-title">
              {t("PRODUCT_DETAIL_COVER_IMAGE_TIPS")}
              <span className="required-tip">*</span>
            </div>
            <div className="steps-content">
              <div className="product-detail" style={{ paddingTop: "1px" }}>
                <div className="content-title">
                  <span className="upload-product-image-tips">
                    {t("UPLOAD_PRODUCT_PICTURES")}
                  </span>
                  <span className="upload-product-image-sub-tips">
                    {t("PRODUCT_CREATE_COVER_PICTURE_REQUIREMENT")}{" "}
                  </span>
                  <span
                    className="required-tip cursor-pointer"
                    onClick={() => setProductDescSampleModalVisible(true)}
                    style={{
                      color: "#D55053",
                    }}
                  >
                    {t("EXAMPLE")}
                  </span>
                </div>
                <div style={{ height: "10px" }}></div>
                <div style={{ marginBottom: "10px" }}>
                  <span>{t("UPLOAD")}</span>
                  <span>{t("BOTTLES_PICTURES")},</span>
                  <span>
                    {t("PRODUCT_CREATE_COVER_PICTURE_REQUIREMENT_BOTTLE")}
                  </span>
                </div>
                <PictureUploader
                  maxCount={10 - bottleImages.length}
                  files={bottleImages}
                  onSuccess={onBottleImageUpload.bind(this)}
                />
                <div style={{ height: "10px" }}></div>
                <div style={{ marginBottom: "10px" }}>
                  <span>{t("UPLOAD PICTURE_OF_THE_WINERIES_OR_VINYARD")}</span>
                </div>
                <PictureUploader
                  maxCount={10 - wineryImages.length}
                  files={wineryImages}
                  onSuccess={onWineryImageUpload.bind(this)}
                />
                <div className="content-title">
                  <span className="upload-product-image-tips">
                    {t("PRODUCT_VIDEOS")}
                  </span>
                </div>
                <div style={{ height: "10px" }}></div>
                <UploadBasic
                  type={UploadFileType.VIDEO}
                  onLoaded={onVideoUpLoaded.bind(this)}
                >
                  <ProductVideoUpload
                    url={videos.length === 0 ? null : videos[0]}
                    title={t("VIDEO")}
                  />
                </UploadBasic>
              </div>
            </div>
          </div>
          <div className="content-item step-item">
            <div className="content-item-title">
              {t("SELLING_POINTS")}
              <span className="content-item-sub-title">{t("ADD_TAGS")}</span>
            </div>
            <div className="steps-content">
              <div className="product-tags-row">
                <div className="product-tags-selected">
                  {tags.map((item, index) => (
                    <Tag
                      key={index}
                      closable={false}
                      className="product-tag-item"
                    >
                      {getProductConfigSelectNameByValue(tagData, item)}
                      <img
                        src={cancelIcon}
                        className="cursor-pointer"
                        onClick={onTagClose.bind(this, item)}
                        style={{
                          marginLeft: "10px",
                        }}
                      />
                    </Tag>
                  ))}
                </div>
              </div>
            </div>

            <div className="product-tags-suggested">
              {tagData.map((item, index) => (
                <Button
                  className="product-tag-item"
                  type="text"
                  onClick={onAddTag.bind(this, item.value)}
                >
                  {item.label}
                </Button>
              ))}
              <Button
                className="product-tag-item"
                type="text"
                onClick={() => setTagModalVisible(true)}
              >
                {"+"}
                {t("ADD_CUSTOM_TAGS")}
              </Button>
            </div>
          </div>
          <div className="content-item step-item">
            <div className="content-item-title">{t("TRADING_CONDITION")}</div>
            <div className="product-detail padding-none">
              <div className="sku-table">
                {volumePrice.map((item, index) => (
                  <div
                    key={index}
                    className="steps-content "
                    style={{ margin: "0px 0px 20px 0px" }}
                  >
                    <div className="sku-table-content">
                      <div className="sku-table-item">
                        <div className="label">
                          {t("PRODUCT_PICK_UP_POINT")}
                        </div>
                        <Select
                          placeholder={t("SHIPPING_ADDRESS")}
                          value={item.shippingAddress}
                          options={warehouseList}
                          className="product-currency price-item-pannel"
                          onChange={onShippingAddressChange.bind(this, index)}
                          bordered={true}
                          style={{
                            width: "262px",
                          }}
                        />
                      </div>
                      <div className="sku-table-item">
                        <div className="label">
                          {t("CHOOSE_TRADING_CURRENCY")}
                        </div>
                        <Select
                          placeholder={t("TRADING_CURRENCY")}
                          value={item.currency}
                          options={currencys}
                          className="product-currency price-item-pannel"
                          onChange={onVolumeCurrencyChange.bind(this, index)}
                          bordered={true}
                          style={{
                            width: "262px",
                          }}
                        />
                      </div>
                      <div className="sku-table-item">
                        <div className="label">
                          {t("SELECT_PAYMENT_METHOD")}
                        </div>
                        <Select
                          placeholder={t("PAYMENT_METHOD")}
                          bordered={true}
                          value={item.paymentMethod}
                          options={optionsExw}
                          style={{
                            width: "262px",
                          }}
                          onChange={onPaymentMethodChange.bind(this, index)}
                          className="product-currency price-item-pannel"
                        />
                      </div>
                    </div>
                    <div className="sku-table-content">
                      <div className="sku-table-item">
                        <div className="label">
                          {t("PLEASE_SELECT_VOLUME")}
                        </div>
                        <Select
                          placeholder={t("VOLUME")}
                          value={item.volume}
                          options={volumes}
                          className="product-type"
                          style={{
                            width: "144.5px",
                          }}
                          onChange={onVolumeChange.bind(this, index)}
                        />
                      </div>
                      <div className="sku-table-item">
                        <div className="label">
                          {t("MINIMUM_ORDER")}
                        </div>
                        <NumberCenter
                          value={item.minCount}
                          addonAfter={t("BOTTLES")}
                          onChange={onVolumeMinCountChange.bind(this, index)}
                        />
                      </div>
                      <div className="sku-table-item">
                        <div className="label">
                          {t("PRICE")}
                        </div>
                        <NumberCenter
                          min={0}
                          maxLength={15}
                          value={item.price}
                          className="product-input-number"
                          addonAfter={item.currency}
                          onChange={onVolumePriceRangeChange.bind(this, index)}
                        />
                      </div>
                      <div className="sku-table-item">
                        <div className="label">
                          {t("STOCK_PREPARATION_PERIOD")}
                        </div>
                        <NumberCenter
                          min={0}
                          maxLength={15}
                          value={item.preparation}
                          addonAfter={t("DAYS")}
                          className="product-input-number"
                          onChange={onPreparationChange.bind(this, index)}
                        />
                      </div>
                      <div className="sku-table-item">
                        <div className="label">
                          {t("BOTTLE_AVAILABLE")}
                        </div>
                        <NumberCenter
                          min={0}
                          maxLength={15}
                          value={item.stock}
                          onChange={onStockChange.bind(this, index)}
                          addonAfter={t("BOTTLES")}
                        />
                      </div>
                    </div>
                    <div className="add-new-pickup-point">
                      {index == volumePrice.length - 1 && (
                        <div
                          className="label"
                          onClick={onAddVolumePriceItem.bind(this)}
                        >
                          + {t("ADD_PICK_UP_POINT")}
                        </div>
                      )}
                      <div>
                        <img
                          src={deleteIcon}
                          style={{
                            width: "40px",
                            height: "36px",
                          }}
                          className="cursor-pointer"
                          onClick={onRemoveVolumeItem.bind(this, index)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={"content-item step-item "}>
            <div className="content-item-title">{t("PRODUCT_DETAIL")}</div>
            <div className="steps-content">
              <div className="product-detail" style={{ paddingTop: "1px" }}>
                <div className="content-sub-title">
                  <div className="content-title">
                    <span className="upload-product-image-tips">
                      {t("UPLOAD_PRODUCT_DETAILS")}
                    </span>
                    <span className="upload-product-image-sub-tips">
                      {t("UPLOAD_PRODUCT_DETAILS_PAGE")}
                    </span>
                    <span
                      className="required-tip cursor-pointer"
                      onClick={() => setProductDescSampleModalVisible(true)}
                    >
                      {t("EXAMPLE")}
                    </span>
                  </div>
                </div>
                <div style={{ height: "10px" }}></div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <PictureUploader
                    maxCount={8}
                    files={descImages}
                    onSuccess={onDescImagesUpload.bind(this)}
                  />
                  <div className="product-detail-preview-panel">
                    <div className="product-detail-preview-title">
                      {t("PREVIEW")}
                    </div>
                    <div className="product-detail-preview-image-panel">
                      {descImages.map((fileItem) => {
                        return (
                          <img src={localizeImg(fileItem)} className="img" />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"content-item step-item "}>
            <div className="content-item-title">
              {t("PRODUCT_AWARDS_HONORS")}
            </div>
            <div className="steps-content">
              <div className="product-detail" style={{ paddingTop: "1px" }}>
                <div className="content-title">
                  <span className="upload-product-image-tips">
                    {t("AWARD")}
                  </span>
                </div>
                <div style={{ height: "10px" }}></div>
                <PictureUploader
                  maxCount={9}
                  files={awardImages}
                  onSuccess={onAwardImageUpload.bind(this)}
                />
              </div>
              <div className="product-detail">
                <div className="content-title">
                  <span className="upload-product-image-tips">
                    {t("ORGANIC_CERTIFICATION")}
                  </span>
                </div>
                <div style={{ height: "10px" }}></div>
                <PictureUploader
                  maxCount={9}
                  files={organicImages}
                  onSuccess={onOrganicImageUpload.bind(this)}
                />
              </div>
              <div className="product-detail">
                <div className="content-title">
                  <span className="upload-product-image-tips">
                    {t("PRODUCT_OTHER_CERTIFICATIONS")}
                  </span>
                </div>
                <div style={{ height: "10px" }}></div>
                <PictureUploader
                  maxCount={9}
                  files={otherImages}
                  onSuccess={onOtherImageUpload.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom-container"
        style={{
          width: window.innerWidth - 469 + "px",
        }}
      >
        <Button
          className="form-button-save"
          style={{ width: "300px" }}
          onClick={() => setShowCancelConfirmModalVisible(true)}
        >
          {t("CANCEL")}
        </Button>
        {status != ProductStatus.VERIFYWAITING && (
          <Button
            style={{ width: "300px" }}
            className="form-button-save-draft"
            onClick={onPublishProduct.bind(this)}
          >
            {t("POST")}
          </Button>
        )}
        {/* {status != ProductStatus.VERIFYWAITING && (
            <Button
              className="form-button-save-draft"
              onClick={onSaveDraftProduct.bind(this)}
            >
              {t("SAVE_AS_DRAFT")}
            </Button>
          )} */}
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={tagModalVisible}
        footer={null}
        className="product-tag-modal"
        onCancel={() => setTagModalVisible(false)}
      >
        <ProductTagCreate
          onClose={() => setTagModalVisible(false)}
          onOk={onAddTag.bind(this)}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title={t("EXAMPLE")}
        visible={productDescSampleModalVisible}
        footer={null}
        className="product-desc-modal"
        onCancel={() => setProductDescSampleModalVisible(false)}
      >
        <img
          alt="sample"
          style={{ width: "100%", maxHeight: "600px", marginTop: "15px" }}
          src={productDescSampleImage}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title={t("EXAMPLE")}
        visible={productBottleSampleModalVisible}
        footer={null}
        className="product-desc-modal"
        onCancel={() => setProductBottleSampleModalVisible(false)}
      >
        <img
          alt="sample"
          style={{ width: "100%", maxHeight: "600px", marginTop: "15px" }}
          src={productSampleImage}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={showCancelConfirmModalVisible}
        footer={null}
        className="product-desc-modal"
        onCancel={() => setShowCancelConfirmModalVisible(false)}
      >
        <div className="save-draft-modal-panel">
          <div className="title">{t("SAVE_AS_DRAFT")}</div>
          <div className="desc">{t("SAFE_DRAFT_TIPS")}</div>
          <div className="action-panel">
            <div className="btn-save" onClick={onSaveDraftProduct.bind(this)}>
              {t("SAVE")}
            </div>
            <div className="btn-cancel" onClick={() => history.go(-1)}>
              {t("GIVE_UP")}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
