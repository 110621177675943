import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Table,
  Modal,
  Tabs,
  message,
  Breadcrumb,
  notification,
  Badge,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "./style/productsDraft.less";
// import ProductConnect from "./ProductConnect";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { deepClone } from "../../../../utils/utils";
import {
  productRemove,
  productUpdateStatus,
  loadProducts as reqProducts,
  loadProductConfig,
  getProductConfigNameById,
} from "../../../../api/product";
import { Link, useHistory } from "react-router-dom";
import {
  ProductStatus,
  statusCode,
} from "../../../../constants/statusCode.enum";
import { dateFormat } from "../../../../utils/date";
import {
  localizeTitle,
  ProductTableMaxCount,
} from "../../../../constants/constants";
import { getProductStatusText } from "../../../../api/common";
import { toastInfo } from "../../../../api/toast";
import editIcon from "./images/edit.svg";
import deleteIcon from "./images/delete.svg";

export default function ProductsDraft() {
  const { i18n, t } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();
  const { TabPane } = Tabs;
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [productConnectModalVisible, setProductConnectModalVisible] =
    useState(false);
  let [products, setProducts] = useState([]);
  let [onSaleProducts, setOnSaleProducts] = useState([]);
  let [selectedProductId, setSelectedProductId] = useState("");
  const [productConfig, setProductConfig] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initProducts();
    initProductConfig();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      let onSalePro = products.filter(
        (item) => item.status === ProductStatus.STOCK
      );
      setOnSaleProducts(onSalePro);
    }
  }, [products]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    initProducts();
    initProductConfig();
  }, [currentLanguage]);
  const initProductConfig = async () => {
    let configData = await loadProductConfig();
    setProductConfig(configData);
    return configData;
  };

  const formatProductType = async (val) => {
    let configData = productConfig ? productConfig : await initProductConfig();
    let { types } = configData;
    return getProductConfigNameById(types, currentLanguage, val);
  };

  const initProducts = async () => {
    setLoading(true);
    let pms = {
      page: 0,
      per_page: ProductTableMaxCount,
      coId: user.userInfo.companyId,
    };
    let result = await reqProducts(pms);
    console.log("-----products:", result, pms);
    if (result && result.length > 0) {
      // result = result.map((item) => item._source);
      for (let i = 0; i < result.length; i++) {
        result[i].key = "product" + i;
        if (result[i].id) {
          result[i]._id = result[i].id;
        } else {
          result[i].id = result[i]._id;
        }
        result[i].createdAt = result[i].createdAt
          ? dateFormat(result[i].createdAt, "yyyy-MM-dd")
          : "-";
        result[i].statusText = t(getProductStatusText(result[i].status));
        result[i].typeName = await formatProductType(result[i].types);
      }
      result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      result = result.filter((filterItem) => filterItem.status == 5);
      setProducts(result);
    }
    setLoading(false);
  };

  const onDeleteProduct = async (id) => {
    Modal.confirm({
      title: t("CONFIRM_DELETE"),
      icon: <ExclamationCircleOutlined />,
      content: t("ARE_YOU_SURE_TO_DELETE_THIS"),
      okText: t("CONFIRM"),
      cancelText: t("CANCEL"),
      onOk: async () => {
        let pms = { id: id, lng: i18n.language };
        let data = await productRemove(pms);
        if (data && data.statusCode === statusCode.SUCCESS) {
          toastInfo(t("success"));
          let list = deepClone(products);
          list = list.filter(
            (item) =>
              (item.id && item.id !== id) || (item._id && item._id !== id)
          );
          setProducts(list);
        } else {
          message.error(t("fail"));
        }
      },
    });
  };

  const onUpdateProductStatus = async (id, status) => {
    let pms = {
      _id: id,
      status: status,
    };

    let data = await productUpdateStatus(pms);
    if (data) {
      let list = deepClone(products);
      list = list.map((item) => {
        if (item.id === id || item._id === id) {
          item.status = status;
          item.statusText = t(getProductStatusText(status));
        }
        return item;
      });
      setProducts(list);
      toastInfo(t("success"));
    }
  };

  const toProductDetail = (item) => {
    history.push("/products/detail/" + (item.id || item._id));
  };

  const onBindVintage = (id) => {
    setSelectedProductId(id);
    setProductConnectModalVisible(true);
  };

  const onEditProduct = (item) => {
    // history.push("/shop/products/create", { sourceProductIds: item.languages });
    // console.log("-----edit item:", item);
    history.push("/shop/products/create-deprecated", { productId: item.id || item._id });
  };

  const columns = [
    {
      title: t("PRODUCT_NAME"),
      dataIndex: "title",
      key: "title",
      width:'240px',
      render: (val) => {
        return localizeTitle(currentLanguage, val);
      },
    },
    {
      title: t("PRODUCT_TYPE"),
      dataIndex: "typeName",
      key: "typeName",
      render: (val) => {
        return val;
      },
    },
    {
      title: t("VINTAGE"),
      dataIndex: "year",
      key: "year",
      render: (val) => {
        return val;
      },
    },
    {
      title: t("UNIT_PRICE"),
      dataIndex: "volumes",
      key: "volumes",
      render: (val, record) => {
        if (record.volumes && record.volumes.length > 0) {
          return (
            <div>
              {record.volumes[0].price} {record.volumes[0].currency}-
              {record.volumes[record.volumes.length - 1].price}{" "}
              {record.volumes[record.volumes.length - 1].currency}
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: t("CREATE_AT"),
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: t("ACTION"),
      dataIndex: "action",
      key: "action",
      // width: 280,
      align: "center",
      render: (text, record) => getActionColumn(text, record),
    },
  ];

  const getActionColumn = (text, record) => {
    let id = record.id || record._id;
    return (
      <div className="table-action">
        <div className="table-action-row">
          <img
            src={editIcon}
            onClick={onEditProduct.bind(this, record)}
            style={{ marginRight: "10px" }}
          />
          <img src={deleteIcon} onClick={onDeleteProduct.bind(this, id)} />
        </div>
      </div>
    );
  };

  return (
    <div className="common-page">
      <div className="common-management products-management normal-shadow">
        <Table
          dataSource={products}
          columns={columns}
          className="products-draft-table"
          loading={loading}
          pagination={true}
        />
      </div>
    </div>
  );
}
