import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Modal, Space, Table, Image } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { awardRemove, getProductAwards } from "../../../api/user";
import "./style/awards.less";
import AwardCreate from "../award/AwardCreate";
import { Link } from "react-router-dom";
import { localizeImg, localizeTitle, TableSourceMaxCount } from "../../../constants/constants";
import { deepClone } from "../../../utils/utils";
import { getProductStatusText } from "../../../api/common";

export default function Awards() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const [data, setData] = useState([]);
  const [selectItem, setSelectItem] = useState();
  const [lang, setLang] = useState(i18n.language);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    if (pageIndex >= 0) {
      loadData();
    }
  }, [pageIndex]);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const columns = [
    {
      title: t('AWARD_PHOTO'),
      dataIndex: "reward",
      key: "reward",
      render: val => <img width={100} height={100} src={localizeImg(val)} />,
    },
    {
      title: t('PRODUCT'),
      dataIndex: "productTitle",
      key: "productTitle",
    },
    { title: t("AWARD_NAME"), dataIndex: "name", key: "name" },
    { title: t("DESCRIPTION"), dataIndex: "desc", key: "desc" },
    {
      title: t("STATUS"), dataIndex: "status", key: "status",
      render: val => (t(getProductStatusText(val))),
    },
    {
      title: t("ACTION"),
      dataIndex: "",
      key: "y",
      render: (val) => {
        return (
          <Space size="middle">
            <Button type="link" onClick={onRemove.bind(this, val._id)}>
              {t("DELETE")}
            </Button>
          </Space>
        );
      },
    },
  ];

  const loadData = async () => {
    let pms = { coId: user.userInfo.companyId, page: pageIndex, per_page: TableSourceMaxCount };
    let data = await getProductAwards(pms);
    if (data && data.total > 0) {
      let { result } = data;
      result = result.map(item => {
        let title = '';
        if (item.productInfo && item.productInfo.length > 0) {
          title = localizeTitle(lang, item.productInfo[0].title);
        }
        return { ...item, key: item._id, productTitle: title };
      });
      setData(result);
    }
  };

  const onRemove = async (id) => {
    let pms = { awardId: id };
    await awardRemove(pms);
    let list = deepClone(data);
    list = list.filter(item => item._id !== id);
    setData(list);
  };

  const onEdit = (item) => {
    setSelectItem(item);
    setCreateModalVisible(true);
  };

  const onModelClose = () => {
    setSelectItem(null);
    setCreateModalVisible(false);
  };
  const onCreateComplete = () => {
    onModelClose();
    if (pageIndex > 0) {
      setPageIndex(0);
    } else {
      setPageIndex(-1);
      setPageIndex(0);
    }
  };

  const bannerContent = (item) => {
    console.log(item);
    return (
      <>
        <div className="banner-title">
          <span className="banner-title-price">{item.name}</span>
        </div>
        <div className="banner-title-sub">{item.desc}</div>
      </>
    );
  };

  return (
    <div className="awards-page-container">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("SHOP_MANAGEMENT")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("AWARD_MANAGEMENT")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management">
        <div className="common-management-title">{t("AWARD_MANAGEMENT")}
          <Button
            onClick={() => setCreateModalVisible(true)}
            className="create-button"
          >
            {t("CREATE_AWARD")}
          </Button>
        </div>
        <div style={{ marginTop: '10px' }}>
        </div>
        <div className="common-management-panel">
          <Table columns={columns} dataSource={data} pagination={true} />
        </div>
        <Modal
          destroyOnClose={true}
          title={null}
          visible={createModalVisible}
          footer={null}
          className="discount-create-modal"
          onCancel={onModelClose.bind(this)}
        >
          <AwardCreate
            onClose={onModelClose.bind(this)}
            onComplete={onCreateComplete.bind(this)}
          />
        </Modal>
      </div>
    </div>
  );
}
