import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { Layout } from "antd";
import MyNavbar from "../navbar/MyNavbar";
import "./style/baseprivateroute.less";
import { authValidate } from "../../api/common";
import DashboardLeftSider from "../shared/dashboard-left-sider/DashboardLeftSider";
import { logUserAction } from "../../action-loggers/action-loggers";
import { useUserId } from "../../contexts/UserIdProvider";

export default function MenuPrivateRoute({ component: Component, ...rest }) {
  const validate = authValidate();
  const { Header, Content } = Layout;
  const { user } = useUserId();
  const { userId } = user;

  useEffect(() => {
    logUserAction(userId, rest.path, rest.computedMatch.params.id);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (validate) {
          return (
            <div className="base-route">
              <Header className="header-nav">
                <MyNavbar />
              </Header>
              <Layout className="layout">
                <DashboardLeftSider rest={rest}/>
                <Content
                  className="content-right"
                  style={{
                    backgroundColor: "#F8F8F8",
                    overflowY: "auto",
                    height: window.innerHeight - 60 + "px",
                  }}
                >
                  <Component {...props} />
                </Content>
              </Layout>
            </div>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { refer: props.location, clearToken: true },
              }}
            />
          );
        }
      }}
    />
  );
}
