import { useTranslation } from "react-i18next";
import { Button } from "antd";
import "../../shared/style/contactItem.less";
import { localizeImg } from "../../../constants/constants";
import AvatarBasic from "../../common/avatar/AvatarBasic";

export default function SelectFriendItem(props) {
  const { detail, onSelected } = props;
  const { t } = useTranslation();
  // const { sourceUserInfo,targetUserInfo } = detail;
  // const userItem = targetUserInfo[0];
  let { _id, nickName, name, signature, picture, type } = detail;
  name = name ? name : nickName;

  const onSelectFriend = () => {
    onSelected && onSelected(name, _id, type);
  };

  return (
    <div className="contact-item">
      <AvatarBasic
        cname="avatar"
        picture={localizeImg(picture)}
        displayName={name}
      ></AvatarBasic>
      <div className="content">
        <div className="content-name">
          <div className="name">{name}</div>
          <div className="desc">{signature}</div>
        </div>
        <div className="content-action">
          <Button
            className="button-primary"
            onClick={onSelectFriend.bind(this)}
          >
            {t("SELECT")}
          </Button>
        </div>
      </div>
    </div>
  );
}
