import { createContext, useContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [authTokens, setAuthTokens] = useLocalStorage("tokens", "");

  function createToken(token) {
    setAuthTokens(token);
  }

  return (
    <AuthContext.Provider value={{ authTokens, createToken }}>
      {children}
    </AuthContext.Provider>
  );
}
