export enum BrowseRecordType {
  POST_FEED = 1,
  POST_VIDEO = 2,
  FEED_COMMENT = 3,
  SHARE_FEED = 4,
  SHARE_VIDEO = 5,
  REPLAY_COMMENT = 6,
  ADD_PRODUCT = 7,
  EDIT_PRODUCT = 8,
  DELETE_PRODUCT = 9,
  SEARCH_PRODUCT = 10,
}
