import React, { useState, useEffect } from "react";
import { Button, Tabs, Row, Col, Modal, Layout, Breadcrumb, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { discountRemove, discounts } from "../../../api/discounts";
import { dateFormat } from "../../../utils/date";
import { discountType } from "../../../constants/discountType.enum";
import "./style/discounts.less";
import DiscountCreate from "./DiscountCreate";
import { Link } from "react-router-dom";
import { loadProducts as reqProducts } from "../../../api/product";

export default function Discounts() {
  const { i18n, t } = useTranslation();
  const { user } = useUserId();

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const { TabPane } = Tabs;
  const [selectItem, setSelectItem] = useState();
  const [products, setProducts] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
    loadProducts();
  }, []);

  const loadData = async () => {
    setLoading(true);
    let pms = { coId: user.userInfo.companyId };
    let list = await discounts(pms);
    console.log("---------------discounts : ", list);
    if (list && list.length > 0) {
      list = list.map((item) => {
        return {
          ...item,
          startDate: dateFormat(item.startDate),
          endDate: dateFormat(item.endDate),
          // product: item.products[0].title,
          percent: getPercentValue(item),
        };
      });
      setData(list);
    }
    setLoading(false);
  };

  const loadProducts = async () => {
    let pms = {
      page: 0,
      per_page: 5,
      coId: user.userInfo.companyId,
      params: JSON.stringify({ showBindVintag: true }),
      lng: i18n.language,
    };
    let productsResult = await reqProducts(pms);
    console.log("------productsResult : ", productsResult);
    setProducts(productsResult.length);
  };

  const getPercentValue = (item) => {
    let percent = "";
    switch (item.discountType) {
      case discountType.NORMAL:
        percent = item.normal.percent;
        break;
      case discountType.DESTOCKS:
        percent = item.destocks.percent;
        break;
      case discountType.DEGRESSIVE:
        item.degressives.map((item) => {
          percent += item.count + "|" + item.price + ";";
        });
        break;
      case discountType.EVENT:
        percent = item.event.percent;
        break;
      default:
        break;
    }
    return percent;
  };

  const onRemove = async (id) => {
    let pms = { id };
    await discountRemove(pms);
    loadData();
  };

  const onEdit = (item) => {
    setSelectItem(item);
    setCreateModalVisible(true);
  };

  const onModelClose = () => {
    setSelectItem(null);
    setCreateModalVisible(false);
  };

  const onCreateComplete = () => {
    loadData();
    onModelClose();
  };

  const bannerContent = (item) => {
    let { percent, degressives } = item;
    if (percent && percent > 0) {
      return (
        <>
          <div className="banner-title">
            <span className="banner-title-price">{percent}</span> %
          </div>
          <div className="banner-title-sub">{t(item.discountType)}</div>
        </>
      );
    } else {
      let degressive = degressives[0];
      return (
        <>
          <div className="banner-title">
            € <span className="banner-title-price">{degressive.price}</span>
          </div>
          <div className="banner-title-sub">
            满{degressive.count} 个减{degressive.price}€
          </div>
        </>
      );
    }
  };
  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("DISCOUNT_MANAGEMENT")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="common-management discounts-container">
        <div className="common-management-title">
          {t("DISCOUNT_MANAGEMENT")}
          {products > 0 && (
            <Button
              className="create-button"
              onClick={() => setCreateModalVisible(true)}
            >
              {t("CREATE_DISCOUNT")}
            </Button>
          )}
        </div>
        <Tabs defaultActiveKey="1" className="common-tabs discount-header common-management-panel">
          <TabPane tab={t("MY_DISCOUNT")} key="1">
            <Row gutter={[8, 8]} className="discounts-row">
              {data.map((item, index) => {
                return (
                  <Col span={8} key={index}>
                    <div className="discount-item">
                      <div className="discount-banner">
                        {bannerContent(item)}
                      </div>
                      <div className="discount-desc">
                        <div className="desc-title">{t("DATE")}:</div>
                        <div>
                          {item.startDate} - {item.endDate}
                        </div>
                      </div>
                      <div className="discount-btn-row">
                        <Button
                          className="discount-primary-btn"
                          onClick={onEdit.bind(this, item)}
                        >
                          {t("EDIT")}
                        </Button>
                        <Button
                          className="discount-default-btn"
                          onClick={onRemove.bind(this, item._id)}
                        >
                          {t("DELETE")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            {loading && (
              <div style={{ marginTop: '20px' }}>
                <Skeleton avatar={true} active={true} paragraph={{ rows: 1 }} />
                <Skeleton avatar={true} active={true} paragraph={{ rows: 1 }} />
                <Skeleton avatar={true} active={true} paragraph={{ rows: 1 }} />
              </div>
            )}
          </TabPane>
        </Tabs>
        <Modal
          destroyOnClose={true}
          title={null}
          visible={createModalVisible}
          footer={null}
          className="discount-create-modal"
          onCancel={onModelClose.bind(this)}
        >
          <DiscountCreate
            detail={selectItem}
            onComplete={onCreateComplete.bind(this)}
            onClose={onModelClose.bind(this)}
          />
        </Modal>
      </div>
    </div>
  );
}
