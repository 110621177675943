import {
  actionRequest,
  baseURL,
  formatActionResponse,
  formatActionResponseArray,
} from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";

/**
 * @description page
 * @param pms
 */
export async function uploadFile(url: string, pms: any, callback?: any) {
  return actionRequest(RequestType.FILE, url, pms, callback);
}
