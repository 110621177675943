import React, { useEffect, useState } from "react";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { localizeImg, localizeTitle } from "../../../constants/constants";
import { useUserId } from "../../../contexts/UserIdProvider";
import {
  getProductConfigNameById,
  loadProductConfig,
} from "../../../api/product";
import { getCountryNameById } from "../../../api/config";
import { UserType } from "../../../constants/usersType.enum";
import "./style/ProductListItemForNotify.less";
import { ProductStatus } from "../../../constants/statusCode.enum";
import { getProductStatusText } from "../../../api/common";
import { Col, Image, List, Modal, Row } from "antd";
import agreeSelectedIcon from "../../../resource/icons/notify/agree_selected.svg";
import refuseSelectedIcon from "../../../resource/icons/notify/refuse_selected.svg";

export default function ProductListItemForNotify(props) {
  const { item, notify } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const [isWinery, setIsWinery] = useState(false);
  const [isImporter, setIsImporter] = useState(false);
  const [title, setTitle] = useState();
  const [countryName, setCountryName] = useState();
  const [regionName, setRegionName] = useState();
  const [typeName, setTypeName] = useState();
  const [classificationName, setClassificationName] = useState();
  const [currentLanguage, setCurrentLanguage] = useState();

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, []);

  useEffect(() => {
    if (currentLanguage) {
      setConfigData();
    }
  }, [currentLanguage]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (user.userInfo.type == UserType.SUPPLIER) {
      setIsWinery(true);
    } else if (
      user.userInfo.type == UserType.BUYER ||
      user.userInfo.type == UserType.SUPPLIERBUYER
    ) {
      setIsImporter(true);
    }

    setConfigData();
  }, []);

  const setConfigData = async () => {
    setTitle(localizeTitle(currentLanguage, item.title));
    let configData = await loadProductConfig();
    let { locations, types, classifications, categories, tags } = configData;
    setCountryName(
      getCountryNameById(locations, currentLanguage, item.country)
    );
    setRegionName(getCountryNameById(locations, currentLanguage, item.region));
    setTypeName(getProductConfigNameById(types, currentLanguage, item.types));
    setClassificationName(
      getProductConfigNameById(
        classifications,
        currentLanguage,
        item.classifications
      )
    );
  };

  const onGoDetail = (id) => {
    history.push("/products/detail/" + id);
  };

  const getStatusIcon = (status) => {
    let result = <div className="userinfo-btn-selected"></div>;
    //处理产品状态
    switch (Number(status)) {
      case ProductStatus.VERIFYWAITING:
        break;
      case ProductStatus.VERIFYSUCCESS:
        result = <img src={agreeSelectedIcon} />;
        break;
      case ProductStatus.VERIFYFAIL:
        result = <img src={refuseSelectedIcon} />;
        break;
      case ProductStatus.STOCK:
        break;
      case ProductStatus.UNSTOCK:
        break;
      default:
        break;
    }
    return result;
  };

  return (
    <div className="product-table-item-notify-container">
      <div className="award-images-container">
        <Row gutter={[8, 8]} className="award-images">
          <Col span={6}>
            <div className="award-list-image-box">
              <Image
                preview={false}
                src={localizeImg(item.bottleImages[0])}
                className="award-list-image"
              />
            </div>
          </Col>
          <Col span={16}>
            <div
              style={{
                display: "flex",
                marginLeft: "10px",
              }}
            >
              <div>
                <div className="award-item-info">
                  <span>{t("PRODUCT")}:</span>
                </div>
                <div className="award-item-info">
                  <span>{t("LOCATION")}:</span>
                </div>
                {/* <div className="award-item-info">
                  <span>{t("PRODUCT_YEAR")}:</span>
                </div> */}
                <div className="award-item-info">
                  <span>{t("PRODUCT_TYPE")}:</span>
                </div>
                {/* <div className="award-item-info">
                  <span>{t("CLASSIFICATIONS")}:</span>
                </div> */}
                {item.reason && (
                  <div className="award-item-info">
                    <span>{t("FAIILED_REASON")}:</span>
                  </div>
                )}
              </div>
              <div>
                <div className="award-item-info">
                  <span>{title}</span>
                </div>
                <div className="award-item-info">
                  <span>
                    {countryName}
                    {regionName}
                  </span>
                </div>
                {/* <div className="award-item-info">
                  <span>{item.year}</span>
                </div> */}
                <div className="award-item-info">
                  <span>{typeName}</span>
                </div>
                {/* <div className="award-item-info">
                  <span>{classificationName}</span>
                </div> */}
                {item.reason && (
                  <div className="award-item-info">
                    <span>{item.reason}</span>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col span={1}>
            {item && (
              <div>
                {/* {t(getProductStatusText(data.status))} */}
                {getStatusIcon(item.status)}
              </div>
            )}
          </Col>
        </Row>
      </div>
      {/* <div className="table-content">
        <div className="order-content">
          <div className="product-item">
            <div className="product-info cursor-pointer" onClick={onGoDetail.bind(this, item._id)}>
              <img
                src={localizeImg(item.bottleImages[0])}
                className="product-image"
              />
              <div className="product-desc">
                <div className="product-name">{title}</div>
                <Space size={10}>
                  <span className={"ItemPriceCalc"}>{countryName}</span>
                  <span className={"ItemPriceCalc"}>{regionName}</span>
                </Space>
                <Space size={10}>
                  <span className={"ItemPriceCalc"}>{item.year}</span>
                  <span className="ItemPriceCalc">{typeName}</span>
                  <span className="ItemPriceCalc">{classificationName}</span>
                </Space>
              </div>

            </div>
            {notify &&
              <div className={"verify-result " + (item.status === ProductStatus.VERIFYFAIL && "verify-fail")}>{t(getProductStatusText(item.status))}</div>
            }
          </div>
        </div>
        
      </div> */}
    </div>
  );
}
