import React, { useEffect, useState } from "react";
import { Breadcrumb, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../contexts/UserIdProvider";
import { deepClone } from "../../utils/utils";
import {
  blockUser,
  pageFriends,
  removeFriend,
  getBlockList,
  reportingUser,
  loadFriendsTotal,
} from "../../api/contact";
import ContactItem from "../shared/ContactItem";
import { useTranslation } from "react-i18next";
import "./style/myContacts.less";
import { userPage } from "../../api/user";
import { Link } from "react-router-dom";

export default function MyContacts() {
  const { user } = useUserId();
  const { userId } = user;
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (pageIndex >= 0) {
      loadData();
    } else {
      setPageIndex(0);
    }
  }, [pageIndex]);

  const loadData = async () => {
    setLoading(true);
    let pms = { page: pageIndex, per_page: pageSize, keyword: "" };
    let result = await pageFriends(userId, pms);
    if (result) {
      pms = { userId };
      let blackList = await getBlockList(pms);
      result = result.filter((item) => {
        return blackList.filter((b) => b.targetId === item.id).length === 0;
      });
      if (pageIndex > 0) {
        let _list = deepClone(dataList);
        result = _list.concat(result);
      }
      setDataList(result);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const onRemoveFriend = async (friendId) => {
    let pms = {
      sourceId: userId,
      targetId: friendId,
    };
    let pms1 = {
      sourceId: friendId,
      targetId: userId,
    };
    await removeFriend(pms);
    await removeFriend(pms1);
    setPageIndex(-1);
  };

  const onBlockFriend = async (friendId) => {
    let pms = {
      sourceId: userId,
      targetId: friendId,
    };
    await blockUser(pms);
    setPageIndex(-1);
  };

  const onReportingFriend = async (friendId) => {
    let pms = {
      sourceId: userId,
      targetId: friendId,
    };
    await reportingUser(pms);
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_CONTACT")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("CONTACT_LIST")}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className={
          "myContact-container common-management normal-shadow-padding10-white"
        }
      >
        <div
          className={"common-management-title"}
          onClick={() => setPageIndex(-1)}
        >
          {t("MY_CONTACT")}
        </div>
        <div className="common-management-panel">
          <InfiniteScroll
            className="scroll-content"
            dataLength={dataList.length}
            next={handleInfiniteOnLoad.bind(this)}
            hasMore={hasMore}
          >
            <List
              className="fensi"
              itemLayout="vertical"
              size="large"
              dataSource={dataList}
              renderItem={(item) => (
                <ContactItem
                  detail={item}
                  onRemoveFriend={onRemoveFriend.bind(this)}
                  onBlockFriend={onBlockFriend.bind(this)}
                  onReportingFriend={onReportingFriend.bind(this)}
                  color={true}
                />
              )}
            >
              {loading && hasMore && (
                <div>
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                </div>
              )}
            </List>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
