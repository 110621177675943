import { useEffect, useState } from "react";
import { useUserId } from "../../contexts/UserIdProvider";
import { Avatar, Button, Divider, Input } from "antd";
import "./style/groupChatManage.less";
import { localizeImg } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import AvatarBasic from "../common/avatar/AvatarBasic";
import { useHistory } from "react-router-dom";
import { userDetail } from "../../api/user";

export default function GroupChatManage(props) {
  const {
    group,
    onQuitGroup,
    onDissolveGroup,
    onRemoveGroupMember,
    onAddGroupMember,
    onClose,
    onGroupNameChange,
  } = props;
  const { t } = useTranslation();
  const { user } = useUserId();
  const { userId } = user;
  const [groupName, setGroupName] = useState();
  const [isOwner, setIsOwner] = useState();
  const [groupMembers, setGroupMembers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (group) {
      let { name, owner, members } = group;
      let list = members.filter((item) => item);
      setGroupName(name);
      setIsOwner(userId === owner);
      setGroupMembers(list);
    }
  }, [group]);
  const goHisHome = async (item) => {
    const userInfo = await userDetail(item.memberId);
    if (userInfo) {
      history.push(`profile/${item.memberId}`);
    }
  };
  const onQuit = () => {
    onQuitGroup && onQuitGroup();
    onClose && onClose();
  };

  const onDissolve = () => {
    onDissolveGroup && onDissolveGroup();
    onClose && onClose();
  };

  const onCloseWin = () => {
    onClose && onClose();
  };

  const onConfirm = () => {
    onGroupNameChange && onGroupNameChange(groupName);
    onCloseWin();
  };

  const onKeyDownchange = (e) => {
    if (e.keyCode == 13) {
      if (onGroupNameChange) {
        onGroupNameChange(e.target.value);
      }
    }
  };

  return (
    <div className="group-chat-manage">
      <div className="group-content">
        <div className="group-row-title">{t("GROUP_CHAT_NAME")}</div>
        <div className="group-row-input">
          <Input
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            onKeyDown={(e) => onKeyDownchange(e)}
          />
        </div>
        <Divider />
        <div className="group-member-title">
          <div className="member-title">{t("GROUP_MEMBER")}</div>
          {isOwner && (
            <div>
              <span
                className="member-title-btn"
                onClick={() => {
                  onAddGroupMember && onAddGroupMember();
                }}
              >
                {t("GROUP_ADD_MEMBERS")}
              </span>
              <Divider type="vertical" />
              <span
                className="member-title-btn"
                onClick={() => {
                  onRemoveGroupMember && onRemoveGroupMember();
                }}
              >
                {t("GROUP_REMOVE_MEMBERS")}
              </span>
            </div>
          )}
        </div>
        {groupMembers.map((item, index) => (
          <div className="group-member-item" key={index}>
            <div onClick={goHisHome.bind(this, item)}>
              <AvatarBasic
                cname="member-item-avatar"
                picture={localizeImg(item.userInfo.picture)}
                displayName={item.userInfo.nickName}
              ></AvatarBasic>
            </div>
            <div className="member-item-name">{item.userInfo.nickName}</div>
          </div>
        ))}
      </div>
      <div className="group-bottom">
        {isOwner && (
          <Button className="group-btn-default" onClick={onDissolve.bind(this)}>
            {t("DISBAND_GROUP")}
          </Button>
        )}
        {!isOwner && (
          <Button className="group-btn-default" onClick={onQuit.bind(this)}>
            {t("QUIT")}
          </Button>
        )}
        <Button className="group-btn-default" onClick={onCloseWin.bind(this)}>
          {t("CANCEL")}
        </Button>
        {isOwner && (
          <Button className="group-btn-primary" onClick={onConfirm.bind(this)}>
            {t("CONFIRM")}
          </Button>
        )}
      </div>
    </div>
  );
}
