import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../contexts/UserIdProvider";

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default function TestMarin() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  // const [input]

  /**echarts.use([
    TitleComponent,
    TooltipComponent,
    VisualMapComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
  ]);
  
  const option = {
    backgroundColor: '#2c343c',
    title: {
      text: 'Customized Pie',
      left: 'center',
      top: 20,
      textStyle: {
        color: '#ccc'
      }
    },
    tooltip: {
      trigger: 'item'
    },
    visualMap: {
      show: false,
      min: 80,
      max: 600,
      inRange: {
        colorLightness: [0, 1]
      }
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: '55%',
        center: ['50%', '50%'],
        data: [
          { value: 335, name: t('LOGIN') },
          { value: 310, name: 'Email' },
          { value: 274, name: 'Union Ads' },
          { value: 235, name: 'Video Ads' },
          { value: 400, name: 'Search Engine' }
        ].sort(function (a, b) {
          return a.value - b.value;
        }),
        roseType: 'radius',
        label: {
          color: 'rgba(255, 255, 255, 0.3)'
        },
        labelLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.3)'
          },
          smooth: 0.2,
          length: 10,
          length2: 20
        },
        itemStyle: {
          color: '#c23531',
          shadowBlur: 200,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        },
        animationType: 'scale',
        animationEasing: 'elasticOut',
        animationDelay: function (idx) {
          return Math.random() * 200;
        }
      }
    ]
  };
  
  useEffect(() => {
    console.log('useEffect')


    let chartDom = document.getElementById('piechart');
    let myChart = echarts.init(chartDom);    
    myChart.setOption(option)


    console.log('my chart : ', myChart)

  }, [i18n.language]);

  const loadData = async () => {
    console.log("Data is loaded")

  };

        <div id="piechart"
      style={{ height:800, width: 600 }}

      ></div>

 */

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken:
        "pk.eyJ1IjoiZ29kYXJ0bSIsImEiOiJja3dla3JnY3gwNXJwMzFxdzJjZGp0bXNyIn0.4XkDiyGUkqQp7yrOb8XPZw",
      types: "place,postcode,address,locality",
    });

    geocoder.addTo("#geocoder");

    // Get the geocoder results container.
    const results = document.getElementById("result");

    // Add geocoder result to container.
    geocoder.on("result", (e) => {
      results.innerText = JSON.stringify(e.result, null, 2);
      console.log(e.result);
    });

    // Clear results container when search is cleared.
    geocoder.on("clear", () => {
      results.innerText = "";
    });
  }, []);

  return (
    <div>
      <div id="geocoder"></div>
      <pre id="result"></pre>
    </div>
  );
}
