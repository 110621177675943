import React, { useEffect, useState } from "react";
import styles from "./DashboardProducts.module.sass";
import cn from "classnames";
import Card from "../../../../core-digital-template/components/Card";
import Form from "../../../../core-digital-template/components/Form";
import Dropdown from "../../../../core-digital-template/components/Dropdown";
import Market from "./Market";

import { useTranslation } from "react-i18next";
import {
  ProductTableMaxCount,
  localizeValue,
  log,
} from "../../../../constants/constants";
import { useUserId } from "../../../../contexts/UserIdProvider";
import {
  getProductConfigNameById,
  loadProductConfig,
  loadProducts,
} from "../../../../api/product";
import { getProductStatusText } from "../../../../api/common";
import { dateFormat } from "../../../../utils/date";
import { getCountryNameById } from "../../../../api/config";
import { deepClone } from "../../../../utils/utils";
import { Link, useHistory } from "react-router-dom";
import Icon from "../../../../core-digital-template/components/Icon";
import { Path, PathTools } from "../../../../constants/path";

function DashboardProducts() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const history = useHistory();

  let [products, setProducts] = useState([]);
  let [oriProducts, setOriProducts] = useState([]);
  const [productConfig, setProductConfig] = useState();

  const navigation = [t("ALL_PRODUCTS"), t("UNPUBLISHED_PRODUCTS")];

  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    initProducts();
    initProductConfig();
  }, []);

  useEffect(() => {
    if (search) {
      let temp = deepClone(products);
      temp = oriProducts.filter((item) => {
        return item?.title?.toLowerCase().indexOf(search?.toLowerCase()) !== -1;
      });
      setProducts(temp);
    } else {
      let temp = deepClone(products);
      temp = oriProducts;
      setProducts(temp);
    }
  }, [search]);

  const initProducts = async () => {
    let pms = {
      page: 0,
      per_page: ProductTableMaxCount,
      coId: user.userInfo.companyId,
    };
    // log("-----pms:", pms);
    let result = await loadProducts(pms);
    // log("-----products:", result, pms);
    if (result && result.length > 0) {
      // result = result.map((item) => item._source);
      for (let i = 0; i < result.length; i++) {
        result[i].key = "product" + i;
        if (result[i].id) {
          result[i]._id = result[i].id;
        } else {
          result[i].id = result[i]._id;
        }
        result[i].createdAt = result[i].createdAt
          ? dateFormat(result[i].createdAt, "yyyy-MM-dd")
          : "-";
        result[i].statusText = t(getProductStatusText(result[i].status));
        result[i].typeName = await formatProductType(result[i].type);
        result[i].countryName = await formatProductCountry(result[i].country);
      }
      result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      result.map((mapItem) => {
        mapItem.availableStock = 0;
        mapItem?.prices?.map((volumeItem) => {
          if (volumeItem.stock != "0") {
            mapItem.availableStock += parseInt(volumeItem.stock);
          }
        });
      });
      setProducts(result);
      setOriProducts(deepClone(result));
    }
  };

  const initProductConfig = async () => {
    let configData = await loadProductConfig();
    setProductConfig(configData);
    return configData;
  };

  const formatProductType = async (val) => {
    let configData = productConfig ? productConfig : await initProductConfig();
    let { types } = configData;
    return getProductConfigNameById(types, i18n.language, val);
  };

  const formatProductCountry = async (val) => {
    let configData = productConfig ? productConfig : await initProductConfig();
    let { locations } = configData;
    return getCountryNameById(locations, i18n.language, val);
  };

  const handleSubmit = (e) => {
    alert();
  };

  const onNavigationClick = (index) => {
    setActiveTab(navigation[index]);

    let temp = deepClone(oriProducts);
    temp = index == 0 ? temp : temp.filter((item) => item.status == "5");
    setProducts(temp);
  };

  return (
    <Card
      className={styles.card}
      title={t("MARKET_PLACE")}
      classTitle={cn("title-red", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Link
            className={cn("button", styles.deletebutton)}
            onClick={() =>
              history.push(
                PathTools.joinPath(
                  Path.productAdd,
                  localizeValue(
                    i18n.language,
                    user?.userInfo?.companyDesc.companyName
                  )
                )
              )
            }
          >
            <Icon name="add" size="24" />
            <span>{t("CREATE_PRODUCT")}</span>
          </Link>
          
          {/* <div className={styles.control}>
            <button className={cn("button-stroke button-small", styles.button)}>
              Deleted
            </button>
            <button className={cn("button-stroke button-small", styles.button)}>
              Set status
            </button>
            <div className={styles.counter}>3 selected</div>
          </div> */}
          {/* <div className={cn(styles.dropdown, "tablet-show")}>
            <Dropdown
              classDropdownHead={styles.dropdownHead}
              value={activeTab}
              setValue={setActiveTab}
              options={navigation}
              small
            />
          </div> */}
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.productsHeader}>
          <div className={cn(styles.nav, "tablet-hide")}>
            {navigation.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x === activeTab,
                })}
                onClick={onNavigationClick.bind(this, index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onSubmit={() => handleSubmit()}
            placeholder={t("SEARCH_PRODUCT")}
            type="text"
            name="search"
            icon="search"
          />
        </div>
        <Market items={products} onChange={() => initProducts()} />
      </div>
    </Card>
  );
}

export default DashboardProducts;
