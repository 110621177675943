import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Table,
  Modal,
  Tabs,
  message,
  Breadcrumb,
  notification,
  Badge,
  List,
  Skeleton,
} from "antd";
import { useTranslation } from "react-i18next";
import "./style/feedsDraft.less";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { Link, useHistory } from "react-router-dom";
import { getFeeds, getFeedsDraft } from "../../../../api/feed";
import FeedListRow from "../../../feed/FeedListRow";
import InfiniteScroll from "react-infinite-scroll-component";

export default function FeedsDraft() {
  const { i18n, t } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  let [feeds, setFeeds] = useState([]);
  const [loading, setLoading] = useState(false);

  const pageSize = 10;
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    initFeedDraftList();
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    initFeedDraftList();
  }, [currentLanguage]);

  const initFeedDraftList = async () => {
    setLoading(true);
    let pms = {
      userId: user.userId,
      page: pageIndex,
      per_page: pageSize,
      sort: -1,
    };
    let data = await getFeedsDraft(pms);
    console.log("----------initFeedDraftList:",data);
    if (data) {
      let { result } = data;
      if (result.length > 0) {
        setFeeds(result);
      }
    } else {
    }
    setLoading(false);
  };

  return (
    <div className="common-page" >
      <div className="common-management feeds-draft-management normal-shadow">
        {/* <Table
          dataSource={feeds}
          columns={columns}
          className="feeds-draft-table"
          loading={loading}
          pagination={true}
        /> */}
        <div className="btn-manage">{t("MANAGE")}</div>
        <div className="draft-list"></div>
        {feeds.map((mapItem)=>{

        })}
        <InfiniteScroll
          className="scroll-content company-feeds"
          dataLength={feeds.length}
          // next={handleInfiniteOnLoad.bind(this)}
          // hasMore={hasMore}
        >
          <List
            itemLayout="vertical"
            size="large"
            dataSource={feeds}
            renderItem={(item, index) => (
              <div key={index}>
                <div className="feed-list-item-container">
                  <FeedListRow
                    data={item}
                    // onRefresh={pageRefresh.bind(this)}
                    // onRemoveFeedItem={onRemoveFeedItem.bind(this)}
                  />
                </div>
              </div>
            )}
          >
            {loading && (
              <div>
                <Skeleton active={true} avatar={true} paragraph={{ rows: 4 }} />
                <Skeleton active={true} avatar={true} paragraph={{ rows: 4 }} />
                <Skeleton active={true} avatar={true} paragraph={{ rows: 4 }} />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </div>
    </div>
  );
}
