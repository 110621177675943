import { useTranslation } from "react-i18next";
import { Breadcrumb, Button, Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import { useUserId } from "../../../contexts/UserIdProvider";
import { dateFormat } from "../../../utils/date";
import {
  getSubscriptions,
  subscriptionUpdate,
} from "../../../api/subscription";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { sendEmail } from "../../../api/user";
import { SYS_ENV_PLATFORM_MAIL } from "../../../constants/env.enum";
import "./style/mySubscription.less";
import { deepClone, confirmModal } from "../../../utils/utils";
import { toastInfo } from "../../../api/toast";

export default function MySubscription() {
  const history = useHistory();
  const { user } = useUserId();
  const { userInfo } = user;
  const { t } = useTranslation();
  const subscription = userInfo.subscription;
  const [subList, setSubList] = useState([]);

  useEffect(() => {
    initSubscriptions();
  }, []);

  const initSubscriptions = async () => {
    let pms = { coId: userInfo.companyId, page: 0, per_page: 20 };
    let result = await getSubscriptions(pms);
    if (result && result.length > 0) {
      result = result.filter((item) => item.status === 1);
      setSubList(result);
    }
  };

  const onSubscribe = async () => {
    history.push("/subscription-logged");
  };

  const onApplyInvoice = (id, index) => {
    // let content = userInfo.companyInfo[0].coName;
    // let title = t("APPLY_FOR_INVOICE");
    // let from = userInfo.email;
    // let pms = {
    //   email: SYS_ENV_PLATFORM_MAIL,
    //   from: from,
    //   content: content,
    //   title: title,
    // };
    // sendEmail(pms);
    // toastInfo(t('APPLIED_INVOICE'));
    let pms = { id, index };
    confirmModal(
      t("APPLY_FOR_INVOICE"),
      t("APPLY_INVOICE_CONTENT") + SYS_ENV_PLATFORM_MAIL,
      t("CONFIRM"),
      t("CANCEL"),
      onSubscribeUpdate,
      null,
      pms
    );
  };

  const onSubscribeUpdate = async ({ id, index }) => {
    let pms = { _id: id, invoiceStatus: 1 };
    let result = await subscriptionUpdate(pms);
    console.log("----------onSubscribeUpdate:", result, id, index);
    let list = deepClone(subList);
    list[index].invoiceStatus = 1;
    setSubList(list);
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_SETTINGS")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_SUBSCRIPTION")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={"common-management my-subscription-page"}>
        <div className="common-management-title">
          {t("MY_SUBSCRIPTION")}
          {!subscription && (
            <Button className="create-button" onClick={onSubscribe.bind(this)}>
              {t("SUBSCRIBE_NOW")}
            </Button>
          )}
        </div>
        <div className="common-management-panel">
          <Row style={{ marginTop: "10px" }}>
            <Col span={6}>
              <Row>
                <div style={{ display: "flex", flexFlow: "column nowrap" }}>
                  <h3>{t("PERSONAL_INFORMATION")}</h3>
                </div>
              </Row>
              {/* <Row>
              <Button>{t("CANCEL_SUBSCRIPTION")}</Button>
            </Row> */}
            </Col>
            <Col span={18}>
              <Row className="row-item">
                <p>
                  {t("EMAIL")} : {userInfo.email}
                </p>
                <Link to={"/usercenter/me"}>
                  {t("EDIT") + " " + t("EMAIL")}
                </Link>
              </Row>
              <Row className="row-item">
                <p>{t("PASSWORD")} : ******</p>
                <Link to={"/usercenter/change-password"}>
                  {t("EDIT") + " " + t("PASSWORD")}
                </Link>
              </Row>
              <Row className="row-item">
                <p>
                  {t("PHONE_NUMBER")} : {userInfo.phoneNumber}
                </p>
                <Link to={"/usercenter/me"}>
                  {t("EDIT") + " " + t("PHONE_NUMBER")}
                </Link>
              </Row>
              <Divider type={"horizontal"} />
              {/* {subscription &&
              <Row className="row-item">
                <p>{t("PAYMENT_METHOD")} : {subscription.payType === PaymentType.WXPAY ? t('WECHAT') : t('ALIPAY')}  </p>
              </Row>
            } */}
              {subscription && (
                <Row className="row-item">
                  <p>
                    {t("NEXT_BILLING_DATE")} :{" "}
                    {dateFormat(subscription.endDate)}{" "}
                  </p>
                </Row>
              )}
            </Col>
          </Row>
        </div>
        {subList.length > 0 && (
          <>
            <Divider type={"horizontal"} style={{ borderTopWidth: "3px" }} />
            <Row>
              <h3>{t("PACKAGE_DETAILS")}</h3>
            </Row>
            {subList.map((item, index) => {
              return (
                <Row align="middle" key={index}>
                  <Col span={5}>
                    <div>
                      {t("PACKAGE_TYPE")} : {t(item.subInfo[0].name)}
                    </div>
                  </Col>
                  <Col span={5}>
                    <div>
                      {t("START_DATE")} : {dateFormat(item.startDate)}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>
                      {t("NEXT_BILLING_DATE")} : {dateFormat(item.endDate)}
                    </div>
                  </Col>
                  <Col span={4}>
                    <div>
                      {t("PRICE")} : {item.subInfo[0].price}
                    </div>
                  </Col>
                  {!item.invoiceStatus && (
                    <Col span={4}>
                      <Button
                        className="create-button"
                        // style={{ marginLeft: "20px", marginTop: "20px" }}
                        onClick={onApplyInvoice.bind(this, item._id, index)}
                      >
                        {t("APPLY_FOR_INVOICE")}
                      </Button>
                    </Col>
                  )}
                  {item.invoiceStatus === 1 && (
                    <Col span={4}>
                      <span>{t("APPLIED_INVOICE")}</span>
                    </Col>
                  )}
                  {item.invoiceStatus === 2 && (
                    <Col span={4}>
                      <span>{t("MADE_INVOICE")}</span>
                    </Col>
                  )}
                </Row>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
