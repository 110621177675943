import React, { useEffect, useState } from "react";
import { List, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../../contexts/UserIdProvider";
import { deepClone } from "../../../utils/utils";
import { pageFriends } from "../../../api/contact";
import { useTranslation } from "react-i18next";
import SelectFriendItem from "./SelectFriendItem";
import PageTitle from "../../common/title/PageTitle";
import "./style/selectFriend.less";

export default function SelectFriend(props) {
  const { onSelected } = props;
  const { user } = useUserId();
  const { userId } = user;
  const [friendList, setFriendList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    loadData();
  }, [pageIndex]);

  const loadData = async (index) => {
    setLoading(true);
    let pms = { page: pageIndex, per_page: pageSize, keyword: "" };
    let result = await pageFriends(userId, pms);
    if (result && result.length > 0) {
      if (index > 0) {
        let _list = deepClone(friendList);
        result = _list.concat(result);
      }
      setFriendList(result);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  const onSelectFriend = (name, id) => {
    onSelected && onSelected(name, id);
  };

  return (
    <InfiniteScroll
      className="scroll-content"
      height={500}
      dataLength={friendList.length}
      next={handleInfiniteOnLoad.bind(this)}
      hasMore={hasMore}
    >
      <div className="select-friend-container">
        <PageTitle title={t("MY_CONTACT")} />
        <List
          itemLayout="vertical"
          size="large"
          dataSource={friendList}
          renderItem={(item) => (
            <SelectFriendItem
              detail={item}
              onSelected={onSelected.bind(this)}
            />
          )}
        >
          {loading && hasMore && (
            <div className="loading-container">
              <Spin />
            </div>
          )}
        </List>
      </div>
    </InfiniteScroll>
  );
}
