import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./WebsiteHeader.module.sass";
import { Link, NavLink } from "react-router-dom";
import Icon from "../Icon";
import Search from "./Search";
import Messages from "./Messages";
import Notification from "./Notification";
import User from "./User";
import { useUserId } from "../../../contexts/UserIdProvider";
import { Path, PathTools } from "../../../constants/path";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import PostCreator from "../../../components/amity-uikit/social/components/post/Creator";
import { AmityApiKey, localizeImg } from "../../../constants/constants";
import { AmityUiKitProvider } from "../../../components/amity-uikit";
import Dropdown from "../Dropdown";
import { UserType } from "../../../constants/usersType.enum";
import { userSupplierList } from "../../../api/user";
import { getCache, setCache } from "../../../utils/cache";
import { useIMContext } from "../../../contexts/IMProvider";
import { AudioCallStatus, ChatContentsType } from "../../../constants/chatContentsType.enum";
import notificationSound from "../../../resource/audio/notification.mp3";
import useAgora from "../../../hooks/useAgora";
import AudioCall from "../../../components/imchat/AudioCall";
import VideoCall from "../../../components/imchat/VideoCall";
import VinbipLogo from "../VinbipLogo";
import LanguageSelect from "../../../components/common/languages/LanguageSelect";

const WebsiteHeader = ({ onOpen }) => {
  const { user, loadUserInfo } = useUserId();
  const { t } = useTranslation();

  const {
    imLoginStatus,
    webIM,
    agoraClient,
    sessionMessageList,
    newMsg,
    chatCallMsg,
    initIMLogin,
    getSessionMessage,
    setChatCallMsg,
    setChatNewMsg,
  } = useIMContext();
  // const { leave } = useAgora(agoraClient);

  // const apiKey = AmityApiKey;
  // const loginFromAdmin = "LOGIN_IN_FROM_ADMIN";

  const [suppliers, setSuppliers] = useState([]);
  const [showSupplierList, setShowSupplierList] = useState(false);

  const [visible, setVisible] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  
  const [navigation, setNavigation] = useState([]);
  const [navigationIndex, setNavigationIndex] = useState(0);

  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  // // 音频通话
  // const [callMessage, setCallMessage] = useState();
  // const [meetingGroupId, setMeetingGroupId] = useState();
  // const [callStatus, setCallStatus] = useState();
  // const [audioModalVisible, setAudioModalVisible] = useState(false);
  // // 视频通话
  // const [videoModalVisible, setVideoModalVisible] = useState(false);

  // useEffect(() => {
  //   let isLoginFromAdmin = getCache(loginFromAdmin);

  //   console.log("Header user:", isLoginFromAdmin, user);
  //   if (
  //     user &&
  //     user.userInfo &&
  //     (user.userInfo.type == UserType.ADMIN || isLoginFromAdmin)
  //   ) {
  //     setCache(loginFromAdmin, true);
  //     setShowSupplierList(true);
  //     loadSuppliers(user?.userId);
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (chatCallMsg) {
  //     setCallMessage(chatCallMsg);
  //     setCallStatus(AudioCallStatus.CALLING);
  //     if (chatCallMsg.type === ChatContentsType.AUDIOCALL) {
  //       setAudioModalVisible(true);
  //     } else {
  //       setVideoModalVisible(true);
  //     }
  //   }
  // }, [chatCallMsg]);

  // useEffect(() => {
  //   if (newMsg) {
  //     if (newMsg.contentsType === ChatContentsType.TEXT) {
  //       new Audio(notificationSound).play();
  //     }
  //     if (newMsg.contentsType === ChatContentsType.CUSTOM) {
  //       switch (newMsg.customExts.fileType) {
  //         case ChatContentsType.AUDIOCALL:
  //           setCallMessage(newMsg);
  //           setCallStatus(AudioCallStatus.WAITING);
  //           setAudioModalVisible(true);
  //           break;
  //         case ChatContentsType.VIDEOCALL:
  //           setCallMessage(newMsg);
  //           setCallStatus(AudioCallStatus.WAITING);
  //           setVideoModalVisible(true);
  //           break;
  //         case ChatContentsType.CANCELCALL:
  //           onCallModalClose();
  //           break;
  //         case ChatContentsType.REFUSECALL:
  //           leave();
  //           onCallModalClose();
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //     // setChatNewMsg(null);
  //   }
  // }, [newMsg]);


  const loadSuppliers = async (userId) => {
    let pms = {
      userId: userId,
    };

    let result = await userSupplierList(pms);
    if (result && result.length > 0) {
      let temp = [];
      result.map((mapItem) => {
        if (
          mapItem &&
          mapItem.roleInfo &&
          mapItem.roleInfo.length > 0 &&
          mapItem.roleInfo[0].name == UserType.ADMIN
        ) {
          mapItem.companyDesc = mapItem.companyDesc[0];

          temp.push({
            label: mapItem?.companyDesc?.companyName,
            value: mapItem._id,
            userItem: mapItem,
          });
        }
      });
      setSuppliers(temp);
    }
    console.log("loadSuppliers:", result);
  };

  const loginAs = async (item) => {
    let result = suppliers.filter((filterItem) => filterItem.value == item);
    console.log("loginAs:", result[0].value);

    await loadUserInfo(result[0].value);

    setTimeout(() => {
      window.location.reload();
    }, 2 * 1000);
  };

  // const onCallModalClose = () => {
  //   leave();
  //   setChatCallMsg(null);
  //   setChatNewMsg(null);
  //   setAudioModalVisible(false);
  //   setVideoModalVisible(false);
  //   getSessionMessage();
  // };

  const onNavigationItemClick = (index) => {
    setNavigationIndex(index);
  };

  return (
    <header className={styles.header}>
      <Link className={styles.logowithtext} to="/">
        <VinbipLogo withText />
      </Link>
      
      <Search className={cn(styles.search, { [styles.visible]: visible })} />
      <button
        className={cn(styles.buttonSearch, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <Icon name="search" size="24" />
      </button>
      
      <div className={styles.control} onClick={() => setVisible(false)}>
        <LanguageSelect />
        <button className={styles.burger} onClick={() => handleClick()}></button>
        {showSupplierList && (
          <div
            style={{
              width: "244px",
            }}
          >
            <Dropdown
              // label={t("CATEGORY")}
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              value={user?.userInfo?._id}
              setValue={loginAs.bind(this)}
              options={suppliers}
            />
          </div>
        )}
        <Link className={cn("button-stroke-call-to-action", styles.button)} to="/register">
          <span>{t("REGISTER")}</span>
          <Icon name="arrow-right" size="24" />
        </Link>

        {/* <Messages className={styles.messages} /> */}
        {/* <Notification className={styles.notification} /> */}
        {/* <User className={styles.user} /> */}
      </div>
      
    </header>
  );
};

export default WebsiteHeader;
