import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Input, Table, Button, Space } from "antd";
import "../shared/style/productConnect.less";
import { toast } from "react-toastify";
import { deepClone } from "../../utils/utils";

export default function SelectFriends(props) {
  const {
    selectedFriend,
    friends,
    group,
    onClose,
    onCreateGroup,
    onAddGroupMember,
  } = props;
  const { t } = useTranslation();
  const { Search } = Input;
  let [oriDataList, setOriDataList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [selectFriends, setSelectFriends] = useState([]);
  const [selectFriendKeys, setSelectFriendKeys] = useState([]);

  useEffect(() => {
    let _list = friends
      .filter((item) => (item.groupStatus ? false : true))
      .map((item) => {
        return { ...item, key: item.id };
      });

    if (selectedFriend) {
      let tempSF = deepClone(selectFriends);
      tempSF.push(selectedFriend);
      setSelectFriendKeys([selectedFriend.id]);
      setSelectFriends(tempSF);
    }

    if (group) {
      let { members } = group;
      _list = _list.filter((item) => {
        let tempList = members.filter((m) => m.memberId === item.id);
        if (tempList && tempList.length > 0) {
          return false;
        } else {
          return true;
        }
      });
    }

    setDataList(_list);
    setOriDataList(_list);
  }, [group]);

  const columns = [
    {
      title: t("NAME"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("SIGNATURE"),
      dataIndex: "signature",
      key: "signature",
    },
  ];

  const rowSelection = {
    type: "checkbox",
    selectedRowKeys: selectFriendKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectFriendKeys(selectedRowKeys);
      setSelectFriends(selectedRows);
    },
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const onSubmit = async () => {
    if (!selectFriendKeys || selectFriendKeys.length == 0) {
      toast.info("NO_CONTACT_WAS_SELECTED", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (group) {
      onAddGroupMember && onAddGroupMember(selectFriendKeys, selectFriends);
    }
    if (onCreateGroup) {
      onCreateGroup("群聊", "测试群", selectFriendKeys);
    }
    toast.success(t("SUCCESS"), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (onClose) {
      onClose();
    }
  };

  const unBindFriends = (item) => {
    let _list = selectFriendKeys.filter((n) => item.id !== n);
    setSelectFriendKeys(_list);
    _list = selectFriends.filter((n) => item.id !== n.id);
    setSelectFriends(_list);
  };

  const onSearchChange = (e) => {
    if (e) {
      let keyword = e.target.value;

      if (keyword) {
        const list = oriDataList.filter((item) => {
          return item.name.indexOf(keyword) != -1;
        });
        setDataList(list);
      } else {
        setDataList(oriDataList);
      }
    } else {
      setDataList(oriDataList);
    }
  };

  return (
    <div className="product-connect-friend">
      <div className="connect-title">{t("CREATE_A_GROUP_CHAT")}</div>
      <div className="connect-content">
        <div className="content-list">
          <Search
            placeholder={t("SEARCH_CONTACT")}
            className="content-search"
            onChange={onSearchChange.bind(this)}
          />
          <Table
            dataSource={dataList}
            columns={columns}
            className="content-table"
            rowSelection={rowSelection}
          />
        </div>
        <div className="content-result">
          <div className="result-title">
            {t("PLEASE_SELECT_AT_LEAST_2_CONTACTS")}
          </div>
          {selectFriends.map((item, index) => {
            return (
              <div className="result-item" key={index}>
                <span>{item.name}</span>
                <span
                  className="table-action-button"
                  onClick={unBindFriends.bind(this, item)}
                >
                  {t("DELETE")}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <Space size={8} className="modal-confirm-row">
        <Button className="modal-confirm-btn" onClick={onCancel.bind(this)}>
          {t("CANCEL")}
        </Button>
        <Button
          className="modal-confirm-btn-primary"
          onClick={onSubmit.bind(this)}
        >
          {t("SAVE")}
        </Button>
      </Space>
    </div>
  );
}
