import React, { useState, useEffect } from "react";
import { useUserId } from "../../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CompanyVerifyStatusEnum } from "../../../constants/companyVerifyStatus.enum";
import {
  subscriptionCreate,
  subscriptionTypes,
} from "../../../api/subscription";
import { UserType } from "../../../constants/usersType.enum";
import { Button, Modal } from "antd";
import { toastError } from "../../../api/toast";
import { basicWxPayCode } from "../../../api/wxPay";
import { getAliPayBasicForm } from "../../../api/aliPay";
import { toastInfo } from "../../../api/toast";
import { createCheckoutSession } from "../../../api/stripe";
import arrow from "../../../resource/icons/subscription/arrow.svg";
import "../../shared/style/middlePage.less";
import "./style/subscription.less";
import {
  localizePriceUnit,
  SysBaseUrl,
  SysServiceUrl,
} from "../../../constants/constants";
import { getCompanyVerifyStatus } from "../../../api/user";

export default function SubscriptionLogged() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const [currentUser, setCurrentUser] = useState(user?.userInfo);
  const [companyVerifyStatus, setCompanyVerifyStatus] = useState();
  const history = useHistory();
  const [dataList, setDataList] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [aliPayForm, setAliPayForm] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [stripePriceId, setStripePriceId] = useState();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (user?.userInfo) {
      setCurrentUser(user.userInfo);
    }
  }, [user]);

  useEffect(() => {
    if (currentUser) {
      setCompanyVerifyStatus(getCompanyVerifyStatus(currentUser));
    }
  }, [currentUser]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (aliPayForm) {
      let forms = document.forms;
      if (forms && forms.length > 0) {
        forms[0].submit();
      }
    }
  }, [aliPayForm]);

  const loadData = async () => {
    const userFamilyType = currentUser?.type;
    let pms = { type: userFamilyType };
    let result = await subscriptionTypes(pms);

    if (currentUser.subscription !== undefined) {
      result.forEach((element) => {
        if (element._id === currentUser.subscription.subId) {
          element.disabled = true;
        }
      });
    }

    if (result && result.length > 0) {
      setDataList(result);
    }
  };

  const formatSubscription = (item, index) => {
    let result;
    if (item.type != UserType.BUYER) {
      if (item.meeting) {
        result = (
          <ul>
            <li>
              <span className="verion-right">
                {t("SUBSCRIPTION_TIPS_ECOMMERCE")}
              </span>
            </li>
            <li>
              <span className="verion-right">
                {t("SUBSCRIPTION_TIPS_TENDER_LIMIT")}
              </span>
            </li>
            {/* <li>
              <span className="verion-right">
                可发送 {item.sampleNum} 瓶样酒
              </span>
            </li> */}
            <li>
              <span className="verion-right">
                {t("SUBSCRIPTION_TIPS_VIDEO_CALL_HD")}
              </span>
            </li>
            <li>
              <span className="verion-right">
                {t("SUBSCRIPTION_TIPS_MEETING")}
              </span>
            </li>
          </ul>
        );
      } else {
        result = (
          <ul>
            <li>
              <span className="verion-right">
                {t("SUBSCRIPTION_TIPS_ECOMMERCE")}
              </span>
            </li>
            <li>
              <span className="verion-right">
                {t("SUBSCRIPTION_TIPS_TENDER_LIMIT")}
              </span>
            </li>
            {/* <li>
              <span className="verion-right">
                可发送 {item.sampleNum} 瓶样酒
              </span>
            </li> */}
            <li>
              <span className="verion-right">
                {t("SUBSCRIPTION_TIPS_MEETING")}
              </span>
            </li>
          </ul>
        );
      }
    } else {
      if (item.price > 0) {
        result = (
          <ul>
            <li>
              <span className="verion-right">
                {t("SUBSCRIPTION_TIPS_TENDER_LIMIT")}
              </span>
            </li>
            <li>
              <span className="verion-right">
                {t("SUBSCRIPTION_TIPS_VIDEO_CALL_HD")}
              </span>
            </li>

            <li>
              <span className="verion-right">
                {t("SUBSCRIPTION_TIPS_EMPLOYEE_LIMIT")}
              </span>
            </li>
            {/* <li>{item.price}</li> */}
          </ul>
        );
      } else {
        result = (
          <ul>
            <li>
              <span className="verion-right">
                员工数量限制在 {item.employeeNum} 人
              </span>
            </li>
          </ul>
        );
      }
    }
    return result;
  };

  const onSelectItem = (item) => {
    if (item.price > 0) {
      setSelectedItem(item);
    }
  };

  const onSubscriptionCreate = async () => {
    if (companyVerifyStatus !== CompanyVerifyStatusEnum.VERIFIED) {
      toastInfo(t("YOU_NEED_TO_VERIFY_YOUR_COMPANY_FIRST"));
      return null;
    }
    let result;
    if (selectedItem) {
      let pms = {
        coId: currentUser.companyId,
        subId: selectedItem._id,
        status: 0,
        renew: false,
      };
      let _id = await subscriptionCreate(pms);
      result = { _id, price: selectedItem.price };
    }
    return result;
  };

  const onWxPay = async () => {
    let order = await onSubscriptionCreate();
    if (order) {
      let result = await basicWxPayCode(order);
      if (result) {
        order.wxcode = result;
        history.push("/subscription/wxpay", {
          ...order,
          name: selectedItem.name,
        });
      } else {
        toastError(t("PAY_ERROR"));
      }
    }
  };

  const onAliPay = async () => {
    let order = await onSubscriptionCreate();
    if (order) {
      order.return_url = SysBaseUrl + "subscription/success";
      order.notify_url = SysServiceUrl + "Notify/alipaynotify";
      let result = await getAliPayBasicForm(order);
      if (result) {
        setAliPayForm(result);
      }
    }
  };

  const onStripe = async () => {
    let order = await onSubscriptionCreate();
    if (order) {
      let data = await createCheckoutSession({
        priceId: stripePriceId,
        orderId: order._id,
      });
      if (data.message === "REDIRECT") {
        window.location.href = data.result;
      }
    }
  };

  const onLinkLogin = () => {
    if (!user) {
      history.push("/login", { refer: "/subscription", clearToken: true });
    }
  };

  const onSubscription = (item) => {
    if (!user) {
      history.push("/login", { refer: "/subscription", clearToken: true });
    } else {
      setIsModalVisible(true);
      setStripePriceId(item.stripePriceId);
    }
  };

  return (
    <div>
      <div className="subscription-page-container">
        <div className="sub-top-desc-container">
          <div className="h1">
            {t("EXPLORE_THE_RIGHT_PLAN_FOR_YOUR_BUSINESS")}
          </div>
        </div>
        <div
          style={{
            height: "128px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "24px",
          }}
        >
          <h2 style={{ fontWeight: "600", fontSize: "32px" }}>
            {t("COMPARE_SUBSCRIPTION")}
          </h2>
        </div>
        <div className="sub-container">
          {dataList.map((item, index) => (
            <div
              key={index}
              className={
                selectedItem?._id === item._id
                  ? "sub-item-container sub-item-container-selected"
                  : "sub-item-container"
              }
              onClick={onSelectItem.bind(this, item)}
            >
              <div
                style={{
                  height: "125px",
                  backgroundColor: index == 0 ? "#DAFFEB" : "#FFF5DF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: index == 0 ? "#0B5525" : "#875802",
                }}
                className="h2"
              >
                {t(item.name)}
              </div>
              <p
                style={{
                  textAlign: "center",
                  marginTop: "16px",
                  marginBottom: "24px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Colfax-Bold,Helvetica,Arial,sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "1.5",
                  }}
                >
                  {t("START_AT")} {item.price} {localizePriceUnit()}
                </span>
              </p>

              <p style={{ textAlign: "center", marginBottom: "24px" }}>
                <span
                  style={{
                    fontSize: "12px",
                    color: "#4a6592",
                  }}
                >
                  {t("TAXES_AND_OVERAGES_MAY_APPLY")}
                </span>
              </p>

              {formatSubscription(item, index)}

              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  marginTop: "36px",
                }}
              >
                <Button
                  type="button"
                  title="Subscription"
                  className="btn-sub"
                  disabled={item.disabled}
                  onClick={onSubscription.bind(this, item)}
                >
                  {item.disabled ? t("ALREADY_SUBSCRIBED") : t("SUBSCRIBE_NOW")}
                </Button>
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div className="ha">
            <div className="text">{t("FULL_FEATURE_BREAKDOWN")}</div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                backgroundColor: "#892c4e",
                marginTop: "10px",
                height: "40px",
              }}
            >
              <div className="text5">{t("SUBSCRIPTION_TIPS_ECOMMERCE")}:</div>
              <div className="text6">
                <img className="arrow" src={arrow} />
              </div>
              <div className="text6">
                <img className="arrow" src={arrow} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: "10px",
              }}
            >
              <div className="text5">
                {t("SUBSCRIPTION_TIPS_TENDER_LIMIT")}:
              </div>
              <div className="text6">
                <img className="arrow" src={arrow} />
              </div>
              <div className="text6">
                <img className="arrow" src={arrow} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                backgroundColor: "#892c4e",
                marginTop: "10px",
                height: "40px",
              }}
            >
              <div className="text5">{t("SUBSCRIPTION_TIPS_SAMPLE")}:</div>
              <div className="text6">12</div>
              <div className="text6">24</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: "10px",
              }}
            >
              <div className="text5">
                {t("SUBSCRIPTION_TIPS_VIDEO_CALL_HD")}
              </div>
              <div className="text6"></div>
              <div className="text6">
                <img className="arrow" src={arrow} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                backgroundColor: "#892c4e",
                marginTop: "10px",
                height: "40px",
              }}
            >
              <div className="text5">{t("SUBSCRIPTION_TIPS_MEETING")}:</div>
              <div className="text6"></div>
              <div className="text6">
                <img className="arrow" src={arrow} />
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="Pay Type"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="page-content pay-content">
            <Button className="pay-primary" onClick={onAliPay.bind(this)}>
              {t("ALIPAY")}
            </Button>
            <Button className="pay-primary" onClick={onWxPay.bind(this)}>
              {t("WECHAT_PAY")}
            </Button>
            <Button className="pay-primary" onClick={onStripe.bind(this)}>
              {t("BANK_CARD")}
            </Button>
          </div>
        </Modal>
      </div>
      <div dangerouslySetInnerHTML={{ __html: aliPayForm }}></div>
    </div>
  );
}
