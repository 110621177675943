import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import {
  staffList,
  removeStaff,
  updateStaff,
  createStaff,
} from "../../../api/company";
import StaffCreatePanel from "./StaffCreatePanel";
import RoleCreate from "./RoleCreate";
import { format } from "date-fns";
import { toastError, toastInfo } from "../../../api/toast";
import editIcon from "../../../resource/icons/common/edit.svg";
import removeIcon from "../../../resource/icons/common/remove.svg";
import addIcon from "../../../resource/icons/common/add.svg";
import { isEmail } from "../../../utils/regex";
import { deepClone } from "../../../utils/utils";
import { statusCode } from "../../../constants/statusCode.enum";
import { sendEmail } from "../../../api/user";
import "./style/StaffManagement.less";
import { createRole } from "../../../api/roles";

export default function StaffManagement() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const [data, setData] = useState([]);
  const [currentStaff, setCurrentStaff] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showOnCreateRole, setShowOnCreateRole] = useState(false);
  const [refreshRoleList, setRefreshRoleList] = useState(false);
  const [currentRole, setCurrentRole] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    let pms = { coId: user.userInfo.companyId };
    let list = await staffList(pms);
    if (list && list.result.total > 0) {
      list = list.result.result
        .filter((item) => item.isStaff)
        .map((item, index) => {
          return {
            ...item,
            key: index,
            createdAt: format(new Date(item.createdAt), t("DATE_FORMAT_SMALL")),
          };
        });
      setData(list);
    }
    setLoading(false);
  };

  const columns = [
    { title: t("USER_NAME"), dataIndex: "userName", key: "userName" },
    {
      title: t("ROLE"),
      dataIndex: "roleInfo",
      key: "roleInfo",
      render: (val) => {
        if (val && val.length > 0) {
          return val[0].name;
        }
      },
    },
    { title: t("PHONE_NUMBER"), dataIndex: "phoneNumber", key: "phoneNumber" },
    { title: t("EMAIL"), dataIndex: "email", key: "email" },
    { title: t("CREATE_AT"), dataIndex: "createdAt", key: "createdAt" },
    {
      title: t("ACTION"),
      dataIndex: "",
      key: "y",
      render: (val) => {
        return (
          <Space size="middle">
            {val.isStaff && (
              <>
                <img
                  src={editIcon}
                  className="action-button"
                  onClick={showDrawer.bind(this, val)}
                />
                <img
                  src={removeIcon}
                  className="action-button"
                  onClick={onRemove.bind(this, val._id)}
                />
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const onRemove = async (staffId) => {
    let pms = { staffId };
    let result = await removeStaff(pms);
    if (result) {
      toastInfo(t("DELETE_SUCCESS"));
      loadData();
    } else {
      toastInfo(t("DELETE_FAILED"));
    }
  };

  const showDrawer = (item) => {
    console.log("now language...", i18n.language);
    setCurrentStaff(item ? item : null);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onComplete = () => {
    loadData();
    onClose();
  };

  const onCreateRoleClose = () => {
    setShowOnCreateRole(false);
    setCurrentRole();
  };

  const onRoleCreateComplete = () => {
    setRefreshRoleList(!refreshRoleList);
    setShowOnCreateRole(false);
    setCurrentRole();
  };

  const onStaffChange = (pms) => {
    setCurrentStaff(pms);
  };

  const onStaffCreate = async () => {
    let {
      _id,
      userName,
      password,
      email,
      phoneNumber,
      phone,
      roleId,
      country,
    } = currentStaff;
    if (!userName) {
      toastError(t("USER_NAME") + t("IS_REQUIRED"));
      return;
    }
    if (!email) {
      toastError(t("EMAIL") + t("IS_REQUIRED"));
      return;
    }
    if (email && !isEmail(email)) {
      toastError(t("INVALID_EMAIL"));
      return;
    }
    if (!phoneNumber) {
      toastError(t("PHONE_NUMBER") + t("IS_REQUIRED"));
      return;
    }
    // if (phoneNumber && !isMobile(phoneNumber)) {
    //   toastError(t("INVALID_PHONE_NUMBER"));
    //   return;
    // }
    if (!country) {
      toastError(t("COUNTRY") + t("IS_REQUIRED"));
      return;
    }
    if (!roleId) {
      toastError(t("ROLE") + t("IS_REQUIRED"));
      return;
    }
    let pms = {
      userName,
      password,
      email,
      phoneNumber,
      phone,
      roleId,
      country,
    };
    let result;
    if (_id) {
      pms._id = _id;
      result = await updateStaff(pms);
    } else {
      pms.userName = pms.userName;
      pms.type = user.userInfo.type;
      pms.companyId = user.userInfo.companyId;
      pms.password = password;
      pms.isStaff = true;
      result = await createStaff(pms);
    }
    console.log("--------save user staff:", result);
    if (result.statusCode === statusCode.SUCCESS) {
      toastInfo(t("SAVE_SUCCESS"));
      if (!_id) {
        sendStaffEmail(email);
      }
      onComplete();
    } else if (result.statusCode === statusCode.PhoneExists) {
      toastError(t("PHONE_ALREADY_EXIST"));
    } else if (result.statusCode === statusCode.EmailExists) {
      toastError(t("EMAIL_ALREADY_EXIST"));
    } else if (result.statusCode === statusCode.CreateIMUserFailed) {
      toastError(t("CREATE_IMUSER_ERROR"));
    } else {
      toastError("SAVE_FAILED");
    }
  };

  const sendStaffEmail = async (email) => {
    let content = t("COMPANY_REGISTER_STAFF_ACCOUNT_CONTENT");
    let title = t("COMPANY_REGISTER_STAFF_ACCOUNT_TITLE");
    let pms = {
      email,
      content: content,
      title: title,
    };
    let data = await sendEmail(pms);
    console.log("-----send email result:", data, pms);
    // if (data.statusCode === statusCode.SUCCESS) {
    //   setCapture(data.result.capture);
    // }
  };

  const onRoleChange = (pms) => {
    console.log("----", pms);
    setCurrentRole(pms);
  };
  const onRoleCreate = async () => {
    let { _id, name, desc, limits } = currentRole;
    if (!name) {
      toastError(t("ROLE_NAME") + t("ROLE_DESC"));
      return;
    }
    if (!desc) {
      toastError(t("ROLE_NAME") + t("ROLE_DESC"));
      return;
    }

    let pms = { name, desc, limits, coId: user.userInfo.companyId };
    let result;
    if (_id) {
      pms._id = _id;
      result = await updateStaff(pms);
    } else {
      result = await createRole(pms);
    }
    console.log("-------------------update role result:", result);
    if (result.statusCode === statusCode.SUCCESS) {
      toastInfo(t("SAVE_SUCCESS"));
      onRoleCreateComplete();
    }
  };

  return (
    <div className="common-page">
      <div className="common-management staff-management-panel">
        <div className="products-filter">
          <span className="filter-search" />
        </div>
        <Table
          dataSource={data}
          columns={columns}
          className="staff-table common-management-panel"
          loading={loading}
          pagination={true}
        />
        <div className="staff-create-account-panel">
          <Button onClick={showDrawer.bind(this)} className="create-button">
            <span className="add-icon">+</span>
            {t("ADD_A_USER")}
          </Button>
        </div>
        <Drawer
          destroyOnClose={true}
          title={t("ADD_OR_UPDATE_STAFF")}
          visible={visible}
          onClose={onClose.bind(this)}
          width={603}
          push={false}
          closable={false}
          className="add-user-drawer-container"
          // extra={
          //   <div className="drawer-title">{t("ADD_OR_UPDATE_STAFF")}</div>
          // }
          footer={
            <Space size={8} className="modal-confirm-row">
              <Button
                className="modal-confirm-btn-primary"
                onClick={onStaffCreate.bind(this)}
              >
                {t("SAVE")}
              </Button>
              <Button
                className="modal-confirm-btn"
                onClick={onClose.bind(this)}
              >
                {t("CANCEL")}
              </Button>
            </Space>
          }
        >
          <StaffCreatePanel
            onComplete={onComplete.bind(this)}
            currentStaff={currentStaff}
            onClose={onClose.bind(this)}
            refreshRoleList={refreshRoleList}
            onStaffChange={onStaffChange.bind(this)}
            onRoleCreate={() => setShowOnCreateRole(true)}
          ></StaffCreatePanel>
          <Drawer
            destroyOnClose={true}
            width={603}
            closable={true}
            onClose={onCreateRoleClose.bind(this)}
            visible={showOnCreateRole}
            className="add-user-drawer-container"
            extra={
              <div className="drawer-title">{t("ADD_OR_UPDATE_STAFF")}</div>
            }
            footer={
              <Space size={24} className="modal-confirm-row">
                <Button
                  className="modal-confirm-btn-primary"
                  onClick={onRoleCreate.bind(this)}
                >
                  {t("SAVE")}
                </Button>
                <Button
                  className="modal-confirm-btn"
                  onClick={onCreateRoleClose.bind(this)}
                >
                  {t("CANCEL")}
                </Button>
              </Space>
            }
          >
            <RoleCreate
              currentRole={currentRole}
              onRoleChange={onRoleChange.bind(this)}
            ></RoleCreate>
          </Drawer>
        </Drawer>
      </div>
    </div>
  );
}
