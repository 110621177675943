import { useState, useEffect } from "react";
import { Button, Modal, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useUserId } from "../../../contexts/UserIdProvider";
import "./style/uploadPictureCard.less";
import { getUploadFileAction } from "../../../api/common";
import { getCacheTokens } from "../../../utils/cache";
import { localizeImg } from "../../../constants/constants";
import { toastError } from "../../../api/toast";
import { useTranslation } from "react-i18next";

export default function UploadFileCard(props) {
  const { title, maxCount, files, onSuccess } = props;
  const { user } = useUserId();
  const { t } = useTranslation();
  const action = getUploadFileAction(user.userId);
  const token = getCacheTokens();
  const [_fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [maxNum, setMaxNum] = useState(9);

  useEffect(() => {
    if (maxCount) {
      setMaxNum(maxCount);
    }
  }, [maxCount]);

  useEffect(() => {
    // console.log('----files:', files);
    if (files && files.length > 0) {
      let list = files.map((item) => {
        return {
          url: localizeImg(item),
          response: { result: item },
          name: item.name
        };
      });
      setFileList(list);
    } else {
      setFileList([]);
    }
  }, [files]);

  const tokenPre = "Bearer ";
  const layout_upload = {
    action: action,
    headers: {
      authorization: tokenPre + token.value,
    },
    onChange(info) {
      setUploading(true);
      setFileList(info.fileList);
      if (info.file.status === "done" || info.file.status === "removed") {
        console.log("-----onchange info", info);
        const { fileList, file } = info;
        const pathList = fileList.map((file) => {
          return { ...file.response.result, name: file.name };
        });
        onSuccess && onSuccess(pathList);
        setUploading(false);
      } else if (info.file.status === "error") {
        toastError(`${info.file.name} file upload failed.`);
        setUploading(false);
      }
    },
    beforeUpload: (file) => {
    }
  };

  return (
    <Upload
      {...layout_upload}
      fileList={_fileList}
      maxCount={maxNum}
      multiple
    >
      {_fileList.length < maxNum && !uploading && (
        <Button icon={<UploadOutlined />}>{title? title: t('UPLOAD')}</Button>
      )}
    </Upload>
  );
}
