import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./ModalProduct.module.sass";
import Icon from "../Icon";
import Product from "./Product";
import { log } from "../../../constants/constants";
import {
  createProductClick,
  getProductConfigNameById,
  getProductPriceRangeTextWithCurrency,
  getProductTotalStock,
  loadProductConfig,
  loadProducts,
  productDetail,
} from "../../../api/product";
import { ProductStatus, statusCode } from "../../../constants/statusCode.enum";
import { useUserId } from "../../../contexts/UserIdProvider";
import { UserType } from "../../../constants/usersType.enum";
import { getCountryNameById } from "../../../api/config";
import { useTranslation } from "react-i18next";
import { Path, PathTools } from "../../../constants/path";

const ModalProduct = ({ productId, visible, onClose }) => {
  const { user } = useUserId();
  const { t, i18n } = useTranslation();
  const { userId, userInfo } = user;
  const history = useHistory();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [product, setProduct] = useState({});
  const [relativeProducts, setRelativeProducts] = useState([]);

  useEffect(() => {
    if (productId && visible) {
      initProduct(productId);
    }
  }, [productId, visible]);

  const initProduct = async (id) => {
    let pms = { id };
    let data = await productDetail(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      let productInfo = data.result;
      console.log("setProduct:",productInfo);
      if(productInfo&&productInfo.companyInfo&&productInfo.companyInfo.length>0){
        productInfo.companyInfo=productInfo.companyInfo[0];
      }
      setProduct(productInfo);

      loadRelativeProducts(productInfo.coId, productInfo._id);

      let clickPms = {
        coId: productInfo.coId,
        productId: productInfo._id,
        userId,
      };
      createProductClick(clickPms);
    }
  };

  const loadRelativeProducts = async (coId, id) => {
    let configData = await loadProductConfig();
    let { locations, categories } = configData;

    let pms = {
      page: 0,
      per_page: 100,
      coId,
      params: JSON.stringify({ showBindVintag: false }),
      lng: i18n.language,
    };
    let result = await loadProducts(pms);
    if (result && result.length > 0) {
      let list = [];
      for (let i = 0; i < result.length; i++) {
        let item = result[i];
        let flag =
          (userInfo.type != UserType.SUPPLIER &&
            item.status === ProductStatus.STOCK) ||
          userInfo.type == UserType.SUPPLIER;
        if (flag && item.id !== id) {
          item.countryName = getCountryNameById(
            locations,
            currentLanguage,
            item.country
          );
          item.categoryName = getProductConfigNameById(
            categories,
            currentLanguage,
            item.category
          );
          item.stock = getProductTotalStock(item.volumes);
          list.push(item);
        }
      }
      setRelativeProducts(list);
    }
  };

  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal-product");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  return createPortal(
    visible && (
      <div id="modal-product" className={styles.modal}>
        <div className={styles.control}>
          <button className={styles.close} onClick={onClose}>
            <Icon name="close" size="40" />
          </button>
        </div>
        <div className={styles.outer}>
          <Product product={product} relativeProducts={relativeProducts} />
        </div>
      </div>
    ),
    document.body
  );
};

export default ModalProduct;
