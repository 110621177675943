import React, { useEffect, useState } from "react";
import { Breadcrumb, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserId } from "../../contexts/UserIdProvider";
import { contactUserFans } from "../../api/contact";
import { useTranslation } from "react-i18next";
import "./style/myContacts.less";
import { Link } from "react-router-dom";
import { statusCode } from "../../constants/statusCode.enum";
import UserItem from "../shared/UserItem";

export default function MyFollowers() {
  const { user } = useUserId();
  const { userId } = user;
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadFans();
  }, []);

  const loadFans = async () => {
    let pms = { userId };
    let result = await contactUserFans(pms);
    if (result) {
      if (result.statusCode === statusCode.SUCCESS && result.result.total > 0) {
        const list = [];

        result.result.result.map((data) => {
          //Omg clean this "result.result.result"
          console.log("data.sourceUserInfo[0]", data.sourceUserInfo[0]);

          list.push(data.sourceUserInfo[0]);
        });
        setDataList(list);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_CONTACT")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_FOLLOWER")}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className={
          "myContact-container common-management normal-shadow-padding10-white"
        }
      >
        <div
          className={"common-management-title"}
          onClick={() => setPageIndex(-1)}
        >
          {t("MY_FOLLOWER")}
        </div>
        <div className="common-management-panel">
          <InfiniteScroll
            className="scroll-content"
            dataLength={dataList.length}
            next={handleInfiniteOnLoad.bind(this)}
          >
            <List
              className="fensi"
              itemLayout="vertical"
              size="large"
              dataSource={dataList}
              renderItem={(item) => <UserItem detail={item} />}
            >
              {loading && (
                <div>
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton
                    avatar={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                  />
                </div>
              )}
            </List>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
