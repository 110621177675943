import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Space, Dropdown, Menu, Modal, Table } from "antd";
import "./style/orderInfoEdit.less";
import { useLocation, useHistory } from "react-router-dom";
import {
  getLogisticsList,
  orderConfirm,
  orderDetail,
  updateOrder,
} from "../../../../../api/orders";
import { dateFormat } from "../../../../../utils/date";
import {
  OrderStatus,
  statusCode,
} from "../../../../../constants/statusCode.enum";
import { toastError, toastInfo } from "../../../../../api/toast";
import { getNativePayCode } from "../../../../../api/wxPay";
import { getAliPayForm } from "../../../../../api/aliPay";
import {
  localizeImg,
  localizeTitle,
  OrderProofFiles,
  PayType,
  ProductCurrencyType,
} from "../../../../../constants/constants";
import { useUserId } from "../../../../../contexts/UserIdProvider";
// import BankTransfer from "./BankTransfer";
import UploadFileNormal from "../../../../common/upload/UploadFileNormal";
import { deepClone } from "../../../../../utils/utils";
import rightIcon from "../../../../../resource/icons/common/right.svg";
import leftIcon from "../../../../../resource/icons/common/left.svg";
import backIcon from "../../../../../resource/icons/dashboard/order/back.svg";
import { formatPhone } from "../../../../../api/common";
import {
  productDetail,
  loadProductConfig,
  getProductConfigNameById,
} from "../../../../../api/product";
import { getCountryNameById } from "../../../../../api/config";
import UploadBase from "../../../../common/upload/UploadBase";
import attachedDocumentIcon from "../../../../../resource/icons/dashboard/order/attached_document.svg";
import downArrowIcon from "../../../../../resource/icons/shop/info/down_arrow.svg";

export default function OrderInfoEdit(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();
  const { isWinery, user } = useUserId();

  const [orderId, setOrderId] = useState();
  const [order, setOrder] = useState();
  const [address, setAddress] = useState();
  const [orderProducts, setOrderProducts] = useState([]);
  const [logisticsList, setLogisticsList] = useState([]);
  const [aliPayForm, setAliPayForm] = useState();
  const [processIndex, setProcessIndex] = useState(1);
  const [theOrderStatus, setOrderStatus] = useState(OrderStatus.WaitingForPay);
  const [files, setFiles] = useState([]);
  const [bankTransferModalVisible, setBankTransferModalVisible] =
    useState(false);
  const [firstPaymentVouchers, setFirstPaymentVouchers] = useState([]);
  const orderProgress = useRef();
  const [orderProgressScroll, setOrderProgressScroll] = useState(false);
  const [shippingPoint, setShippingPoint] = useState("");

  const [orderStatusMenu, setOrderStatusMenu] = useState([]);

  useEffect(() => {
    if (state && state.orderId) {
      setOrderId(state.orderId);
    }
    if (props && props.orderId) {
      setOrderId(props.orderId);
    }
  }, []);

  useEffect(() => {
    console.log("-----------orderProgress out:", orderProgress);
    if (orderProgress && orderProgress.current) {
      let outDiv = orderProgress.current;
      console.log(
        "-----------orderProgress in:",
        outDiv.scrollWidth,
        outDiv.clientWidth,
        outDiv.offsetWidth
      );
      if (
        outDiv.scrollWidth > outDiv.clientWidth ||
        outDiv.offsetWidth > outDiv.clientWidth
      ) {
        setOrderProgressScroll(true);
      }
    }
  }, [orderProgress]);

  useEffect(() => {
    if (orderId) {
      loadOrderDetails();
      loadLogistics();
    }
  }, [orderId]);

  useEffect(() => {
    formatProcessIndex(theOrderStatus);
  }, [theOrderStatus]);

  const loadLogistics = async () => {
    let pms = { orderId };
    let list = await getLogisticsList(pms);
    setLogisticsList(list);
  };

  const loadOrderDetails = async () => {
    let pms = { orderId };
    let result = await orderDetail(pms);
    console.log("---------order detail :", result);
    setOrder(result);
    setOrderStatus(result.status);
    initOrderSattusMenu(result.status);
    if (result.files && result.files.length > 0) {
      setFiles(result.files);
    } else {
      setFiles(OrderProofFiles);
    }
    let { recipientInfo, orderProducts, products } = result;
    if (recipientInfo && recipientInfo.length > 0) {
      let _temp = recipientInfo[0];
      setAddress({
        name: _temp.recipientUser.userName,
        phone: formatPhone(_temp.recipientUser.phone),
        address:
          _temp.recipientAddress.addressLineOne +
          " " +
          _temp.recipientAddress.city +
          " " +
          _temp.recipientAddress.state +
          " " +
          _temp.recipientAddress.country,
        zipcode: _temp.recipientAddress.zipcode,
      });
    }
    if (orderProducts && orderProducts.length > 0) {
      let list = [];
      let configData = await loadProductConfig();
      let { locations, types, classifications } = configData;

      setShippingPoint(
        getCountryNameById(
          locations,
          i18n.language,
          result?.orderProducts[0]?.volume?.shippingAddressInfo?.location
            ?.country
        )
      );

      for (let i = 0; i < orderProducts.length; i++) {
        const item = orderProducts[i];
        let title, image, price, currency;
        let _temp = products.filter((pro) => pro._id === item.productId);
        if (_temp && _temp.length > 0) {
          // console.log("temps  ", _temp);
          title = localizeTitle(i18n.language, _temp[0].title);
          price = item.price;
          currency = _temp[0].currency;
          image =
            _temp[0].bottleImages.length > 0
              ? _temp[0].bottleImages[0]
              : _temp[0].images[0];
        }
        //产品详情
        let pms = { id: item.productId };
        let data = await productDetail(pms);
        if (data && data.statusCode === statusCode.SUCCESS) {
          let productInfo = data.result;
          console.log("--------product info:", productInfo);
          item.year = productInfo.year;
          item.countryName = getCountryNameById(
            locations,
            i18n.language,
            productInfo.country
          );
          item.regionName = getCountryNameById(
            locations,
            i18n.language,
            productInfo.region
          );
          item.typeName = getProductConfigNameById(
            types,
            i18n.language,
            productInfo.types
          );

          item.classificationName = getProductConfigNameById(
            classifications,
            i18n.language,
            productInfo.classifications
          );
        }
        list.push({
          ...item,
          title,
          price,
          image,
          currency,
        });
      }
      console.log("item", list);
      setOrderProducts(list);
    }
  };

  const initOrderSattusMenu = (status) => {
    console.log("-------------initOrderSattusMenu:", status);
    let temp = deepClone(orderStatusMenu);
    temp = [];
    if (status == OrderStatus.WaitingForPay) {
      temp.push(
        ...[
          {
            label: t("CONFIRM_ORDER"),
            value: OrderStatus.Confirmed,
          },
          {
            label: t("REFUSE_ORDER"),
            value: OrderStatus.Refused,
          },
          {
            label: t(OrderStatus.Payed),
            value: OrderStatus.Payed,
          },
        ]
      );
    } else if (status == OrderStatus.Payed) {
      temp.push(
        ...[
          {
            label: t("SEND_OUT"),
            value: OrderStatus.Sendout,
          },
        ]
      );
    }
    console.log("-------------initOrderSattusMenu:", temp);
    setOrderStatusMenu(temp);
  };

  const onUpdateOrder = async (status) => {
    let pms = { _id: orderId, status };
    let result = await updateOrder(pms);
    onRefreshPage(result);
  };

  const onRefreshPage = (result) => {
    if (result) {
      toastInfo(t("ACTION_SUCCESS"));
      loadOrderDetails();
    } else {
      toastError(t("ACTION_FAIL"));
    }
  };

  const formatProcessIndex = (status) => {
    let result = 1;
    switch (status) {
      case OrderStatus.WaitingForPay:
        result = 1;
        break;
      case OrderStatus.Expired:
        result = 2;
        break;
      case OrderStatus.Payed:
        result = 3;
        break;
      case OrderStatus.Refused:
        result = 4;
        break;
      case OrderStatus.Confirmed:
        result = 5;
        break;
      case OrderStatus.Sendout:
        result = 6;
        break;
      case OrderStatus.Transiting:
        result = 7;
        break;
      case OrderStatus.Delivered:
        result = 8;
        break;
      case OrderStatus.Received:
        result = 9;
        break;
      case OrderStatus.Cancelled:
        result = 10;
        break;
      case OrderStatus.Complete:
        result = 11;
        break;
      default:
        break;
    }
    setProcessIndex(result);
  };

  const onWxPay = async () => {
    if (order) {
      let result = await getNativePayCode(order);
      if (result) {
        order.wxcode = result;
        history.push("/order/wxpay", { ...order });
      } else {
        toastError(t("WECHAT_PAY_FAILED"));
      }
    }
  };

  const onAliPay = async () => {
    if (order) {
      let result = await getAliPayForm(order);
      if (result) {
        setAliPayForm(result);
      }
    }
  };

  useEffect(() => {
    if (aliPayForm) {
      let forms = document.forms;
      if (forms && forms.length > 0) {
        forms[0].submit();
      }
    }
  }, [aliPayForm]);

  const onMenuItemClick = (item) => {
    switch (item.key) {
      case "1":
        onWxPay();
        break;
      case "2":
        onAliPay();
        break;
      case "3":
        onBankTransfer();
        break;
      default:
        break;
    }
  };

  const menuPay = (
    <Menu onClick={onMenuItemClick.bind(this)}>
      <Menu.Item key="1">
        <span>{t("WECHAT_PAY")}</span>
      </Menu.Item>
      <Menu.Item key="2">
        <span>{t("PAY_WITH_ALIPAY")}</span>
      </Menu.Item>
      <Menu.Item key="3">
        <span>{t("BANK_TRANSFER")}</span>
      </Menu.Item>
    </Menu>
  );

  const onFilesLoaded = async (fileList) => {
    console.log("---- file list:", fileList);
    setFiles(fileList);
    let pms = { _id: orderId, files: fileList };
    let result = await updateOrder(pms);
    console.log("-----update order:", result, pms);
  };

  const onFileLoaded = async (index, fileList) => {
    console.log("---- file:", fileList);
    let list = deepClone(files);
    list[index].file = fileList.length > 0 ? fileList[0] : null;
    setFiles(list);
    let pms = { _id: orderId, files: list };
    let result = await updateOrder(pms);
    console.log("-----update order:", result, pms);
  };

  const onAttachedDocumentUploaded = async (
    attachedName,
    fileType,
    url,
    name,
    size
  ) => {
    let content = { fileType, url, name, size };
    console.log(content);
    // console.log("---- file:", fileList);
    let list = deepClone(files);
    // list[index].file = fileList.length > 0 ? fileList[0] : null;
    list.push({
      name: attachedName,
      file: content,
    });
    setFiles(list);
    let pms = { _id: orderId, files: list };
    let result = await updateOrder(pms);
    console.log("-----update order:", result, pms);
  };

  const onRemoveAttachedDocument = async (index) => {
    let list = deepClone(files);
    // list[index].file = fileList.length > 0 ? fileList[0] : null;
    list = list.splice(index, 1);
    setFiles(list);
    let pms = { _id: orderId, files: list };
    let result = await updateOrder(pms);
    console.log("-----update order:", result, pms);
  };

  const onBankTransferPay = async (fileList) => {
    console.log("---- file list:", fileList);
    setFirstPaymentVouchers(fileList);
    onBankTransferClose();
    // let pms = { orderId, payType: PayType.Bank };
    // if (order.relationId) {
    //   pms = { relationId: order.relationId, payType: PayType.Bank };
    // }
    let pms = {
      _id: orderId,
      firstPaymentVouchers: fileList,
      payType: PayType.Bank,
    };
    let result = await updateOrder(pms);
    // let result = await orderConfirm(pms);
    onRefreshPage(result);
  };

  const onBankTransfer = () => {
    setBankTransferModalVisible(true);
  };

  const onBankTransferClose = () => {
    setBankTransferModalVisible(false);
  };

  const onOrderProgressScrollRight = () => {
    if (orderProgress.current) {
      let outDiv = orderProgress.current;
      outDiv.scrollLeft += 50;
    }
  };

  const onOrderProgressScrollLeft = () => {
    if (orderProgress.current) {
      let outDiv = orderProgress.current;
      outDiv.scrollLeft -= 50;
    }
  };

  const columns = [
    {
      title: t("PRODUCT_NAME"),
      dataIndex: "title",
      key: "createdAt",
      width: "120px",
      render: (val, record) => {
        return <div>{localizeTitle(i18n.language, val)}</div>;
      },
    },
    {
      title: t("ORIGIN_PRICE"),
      dataIndex: "price",
      key: "price",
      width: "120px",
      render: (val, record) => {
        return (
          <div>
            {val}
            {record.currency}
          </div>
        );
      },
    },
    {
      title: t("PRICE_SOLD"),
      dataIndex: "price",
      key: "price",
      width: "120px",
      render: (val, record) => {
        return (
          <div>
            {val}
            {record.currency}
          </div>
        );
      },
    },
    {
      title: t("PURCHASING_BOTTLE"),
      dataIndex: "count",
      key: "count",
      width: "120px",
    },
    {
      title: t("TOTAL_PRICE"),
      dataIndex: "count",
      key: "count",
      width: "120px",
      render: (val, record) => {
        return (
          <div>
            {record.count * record.price}
            {record.currency}
          </div>
        );
      },
    },
    // {
    //   title: t("YEAR"),
    //   dataIndex: "year",
    //   key: "year",
    // },
    // {
    //   title: t("PRICE_MIN"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     if (val && val.length > 0) {
    //       return (
    //         <div>
    //           {val[0].price} {val[0].currency}
    //         </div>
    //       );
    //     } else {
    //       return <div>-</div>;
    //     }
    //   },
    // },
    // {
    //   title: t("PRICE_MAX"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     if (val && val.length > 0) {
    //       return (
    //         <div>
    //           {val[val.length - 1].price} {val[val.length - 1].currency}
    //         </div>
    //       );
    //     } else {
    //       return <div>-</div>;
    //     }
    //   },
    // },
    // {
    //   title: t("SHIPPING_ADDRESS"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     return (
    //       <div>
    //         {val.map((valItem) => {
    //           return <div>{valItem.shippingAddressName}</div>;
    //         })}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: t("STOCK_AVAILABLE"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     let availableStock = 0;
    //     if (val && val.length > 0) {
    //       val.map((valItem) => {
    //         availableStock += parseInt(valItem.stock);
    //       });
    //     }
    //     return (
    //       <div>
    //         {availableStock}
    //         {t("BOTTLES")}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: t("SOLD_VOLUME"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     return <div>0{t("BOTTLES")}</div>;
    //   },
    // },
    // // {
    // //   title: t("TYPE"),
    // //   dataIndex: "typeName",
    // //   key: "typeName",
    // // },
    // {
    //   title: t("CREATE_AT"),
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    // },
    // {
    //   title: t("PRODUCT_STATUS"),
    //   dataIndex: "statusText",
    //   key: "statusText",
    //   // filters: [
    //   //   { text: t("ON_SALE"), value: ProductStatus.STOCK },
    //   //   { text: t("OFF_SALE"), value: ProductStatus.UNSTOCK },
    //   // ],
    //   onFilter: (value, record) => record.status === value,
    //   render: (val, record) => {
    //     if (record.status === ProductStatus.VERIFYFAIL) {
    //       return (
    //         <Badge dot={true} size="default">
    //           <span
    //             className="cursor-pointer"
    //             // onClick={openNotification.bind(this, record.reason)}
    //           >
    //             {val}
    //           </span>
    //         </Badge>
    //       );
    //     } else {
    //       return val;
    //     }
    //   },
    // },
    // {
    //   title: t("ACTION"),
    //   dataIndex: "action",
    //   key: "action",
    //   // width: 280,
    //   align: "center",
    //   // render: (text, record) => getActionColumn(text, record),
    // },
  ];

  const menu_order_status = (
    <div
      // onClick={onBrowseRecordDelete.bind(this)}
      className="order-info-edit-menu-panel"
    >
      {orderStatusMenu.map((mapItem, mapIndex) => {
        return (
          <div
            key={mapIndex}
            className="menu-item"
            onClick={onUpdateOrder.bind(this, mapItem.value)}
          >
            {mapItem.label}
          </div>
        );
      })}
    </div>
  );

  const menu_attached_document = (index) => {
    return (
      <div className="order-info-edit-menu-panel">
        <div
          className="menu-item"
          onClick={onRemoveAttachedDocument.bind(index)}
        >
          {t("DELETE")}
        </div>
      </div>
    );
  };

  return (
    <div className="common-page">
      <div className="order-info-edit-container">
        {order && (
          <div className="order-content">
            <div className="header-panel">
              <div className="order-date">
                {t("ORDER_DATE")}:{dateFormat(order.createdAt, "MM/dd/yyyy")}
              </div>
              <div className="order-info-row">
                <div className="order-info-item">
                  <div className="label">{t("CLIENT")}:</div>
                  <div className="label">{order.userInfo[0].nickName}</div>
                </div>
                <div className="order-info-item">
                  <div className="label">{t("SHIPPING_STATUS")}:</div>
                  <div className="value" style={{ color: "#3E6FA4" }}>
                    {order.shippingStatus ? t(order.shippingStatus) : "-"}
                  </div>
                </div>
              </div>
              <div className="order-info-row">
                <div className="order-info-item">
                  <div className="label">{t("ORDER_NUMBER")}:</div>
                  <div className="value">{order.orderNumber}</div>
                </div>
                <div className="order-info-item">
                  <div className="label">{t("PAYMENT_STATUS")}:</div>
                  <div className="value" style={{ color: "#D55053" }}>
                    {t(order.payStatus)}
                  </div>
                </div>
              </div>
              <div className="order-info-row">
                <div className="order-info-item">
                  <div className="label">{t("SHIPPING_POINT")}:</div>
                  <div className="value">{shippingPoint}</div>
                </div>
                <div className="order-info-item">
                  <div className="label">{t("ORDER_STATUS")}:</div>
                  <Dropdown overlay={menu_order_status} trigger={["click"]}>
                    <div
                      className="value"
                      style={{ color: "#D55053", cursor: "pointer" }}
                    >
                      {t(order.status)}
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
            <Table
              dataSource={orderProducts}
              columns={columns}
              className="orders-details-table"
              pagination={false}
            />
            <div className="bottom-container">
              <div style={{ margin: "0px 20px", textAlign: "right" }}>
                {t("TOTAL_AMOUNT")}:{order?.orderPrice?.totalPrice}$
              </div>
              <UploadBase
                onLoaded={onAttachedDocumentUploaded.bind(this, "PACKING_LIST")}
              >
                <div className="upload-btn">{t("UPLOAD_PACKING_LIST")}</div>
              </UploadBase>
            </div>
            {files.filter((filterItem) => filterItem.file).length > 0 && (
              <div className="attached-document-panel">
                <div className="title-panel">
                  <div className="title">{t("ATTACHED_DOCUMENT")}</div>
                  <img src={attachedDocumentIcon} />
                </div>
                <div className="attached-panel">
                  {files
                    .filter((filterItem) => filterItem.file)
                    .map((fileItem, fileIndex) => {
                      return (
                        <div className="attached-item">
                          <div className="left-part">
                            <div className="banner"></div>
                            <div className="attach-info">
                              <div>
                                {t(fileItem.name)}-{fileItem?.file?.name}
                              </div>
                              <div>
                                {Math.ceil(fileItem?.file?.size / 1000)}
                                {" KB"}
                              </div>
                            </div>
                          </div>
                          <Dropdown
                            overlay={menu_attached_document(fileIndex)}
                            trigger={["click"]}
                          >
                            <img
                              src={downArrowIcon}
                              style={{ cursor: "pointer" }}
                            />
                          </Dropdown>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div dangerouslySetInnerHTML={{ __html: aliPayForm }}></div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={bankTransferModalVisible}
        footer={null}
        className="address-create-modal"
        onCancel={onBankTransferClose.bind(this)}
      >
        {/* <BankTransfer
          files={firstPaymentVouchers}
          onComplete={onBankTransferPay.bind(this)}
          onClose={onBankTransferClose.bind(this)}
        /> */}
      </Modal>
    </div>
  );
}
