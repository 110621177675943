import { PlusOutlined } from "@ant-design/icons";
import "./style/articleImageUpload.less";
import { useTranslation } from "react-i18next";

export default function ArticleImageUpload(props) {
  const { t } = useTranslation();
  let { title, url } = props;
  return (
    <div className="article-image-item">
      {url ? (
        <img src={url} className="image-item" />
      ) : (
        <div className="image-upload">
          <PlusOutlined
            style={{ 
              fontSize: "32px", 
              color: "#DDDDDD", 
            }}
          />
          <div className="image-upload-text">{t("UPLOAD_IMAGE")}</div>
        </div>
      )}
      <div className="image-title">{title}</div>
    </div>
  );
}
