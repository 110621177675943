import { Input } from "antd";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import React, { useEffect } from "react";
import { getTimeStamp } from "../../../utils/date";
import { getEditorUploadFileAction } from "../../../api/common";
import { useUserId } from "../../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";

export default function LightTextAreaBasic(props) {
  const { t, i18n } = useTranslation();
  const { TextArea } = Input;
  const { id, value, placeholder, className, onInit, showControls } = props;
  const editorID = getTimeStamp() + (id ? id : "");

  const { user } = useUserId();
  const action = getEditorUploadFileAction(user.userId);

  useEffect(() => {
    ClassicEditor.create(document.querySelector("#editor" + editorID), {
      toolbar: showControls
        ? [
            // 标题 加粗 斜体 撤销 重做 超链接 项目符号列表 项目编号列表
            "bold",
            "italic",
            "undo",
            "redo",
          ]
        : [],
    })
      .then((editor) => {
        const doc = editor.model.document;
        onInit && onInit(editor);
      })
      .catch((error) => {
        console.error("-----create ckeditor error: ", error);
      });
  }, []);

  return (
    <TextArea
      id={"editor" + editorID}
      value={value}
      placeholder={t(placeholder)}
      maxLength={500}
      bordered={false}
      className={"form-textarea " + className}
    />
  );
}
