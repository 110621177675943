import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Modal, Table, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { getOrders } from "../../../../../api/orders";
import OrdersListItem from "./OrdersListItem";
import { dateFormat } from "../../../../../utils/date";
import ListBasic from "../../../../common/list/ListBasic";
import {
  OrderStatus,
  ProductStatus,
} from "../../../../../constants/statusCode.enum";
import "./style/supplierDocuments.less";
import { useHistory, useParams } from "react-router-dom";
import { getAliPayForm } from "../../../../../api/aliPay";
import moreIcon from "../../../../../resource/icons/dashboard/order/more.svg";
import downArrowIcon from "../../../../../resource/icons/shop/info/down_arrow.svg";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { localizeTitle } from "../../../../../constants/constants";
import OrderInfo from "../../OrderInfo";
import OrderInfoEdit from "../order-info-edit/OrderInfoEdit";

export default function SupplierDocuments() {
  const { index } = useParams();
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const { isWinery, user } = useUserId();
  const history = useHistory();
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [monthOrderList, setMonthOrderList] = useState([]);
  const [waitingPayOrderList, setWaitingPayOrderList] = useState([]);
  const [paiedOrderList, setPaiedOrderList] = useState([]);
  const [unDeliveredOrderList, setUnDeliveredOrderList] = useState([]);
  const [unReceivedOrderList, setUnReceivedOrderList] = useState([]);
  const [cancelOrderList, setCancelOrderList] = useState([]);
  const [completeOrderList, setCompleteOrderList] = useState([]);
  const [deliveredOrderList, setDeliveredOrderList] = useState([]);
  const [receivedOrderList, setReceivedOrderList] = useState([]);
  const pageSize = 20;
  const [pageIndex, setPageIndex] = useState(0);
  // const [tabIndex, setTabIndex] = useState("1");
  // const [aliPayForm, setAliPayForm] = useState();

  // const [filterByMonthContent, setFilterByMonthContent] = useState(
  //   t("PAST_THREE_MONTH")
  // );
  const [currentOrderId, setCurrentOrderId] = useState("");
  const [orderDetailVisible, setOrderDetailVisible] = useState(false);

  useEffect(() => {
    loadData();
  }, [user]);

  // useEffect(() => {
  //   console.log("---------orders:", index);
  //   if (index) {
  //     setTabIndex(index);
  //   } else {
  //     setTabIndex("1");
  //   }
  // }, [index]);

  const loadData = async () => {
    let pms = { page: pageIndex, per_page: pageSize };
    if (isWinery) {
      pms.coId = user.userInfo.companyId;
    } else {
      pms.userId = user.userId;
    }

    let result = await getOrders(pms);
    console.log("--------orders :", pms, result);
    if (!result) return;
    setOrderList(result);
    result = result.filter((item) => item.products && item.products.length > 0);

    let nowDate = new Date();
    let currentMonth = dateFormat(nowDate, "yyyy-MM");
    let list = result.filter(
      (item) =>
        dateFormat(item.orderPrice.createdAt, "yyyy-MM") === currentMonth
    );
    setMonthOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.WaitingForPay);
    setWaitingPayOrderList(list);

    list = result.filter((item) => item.payStatus === OrderStatus.Payed);
    setPaiedOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.Confirmed);
    setUnDeliveredOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.Sendout);
    setUnReceivedOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.Cancelled);
    setCancelOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.Complete);
    setCompleteOrderList(list);

    list = result.filter(
      (item) =>
        item.status === OrderStatus.Delivered ||
        item.status === OrderStatus.Transiting
    );
    setDeliveredOrderList(list);

    list = result.filter((item) => item.status === OrderStatus.Received);
    setReceivedOrderList(list);

    setLoading(false);
  };

  const columns = [
    {
      title: t("ORDER_DATE"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: "120px",
      render: (val, record) => {
        return <div>{dateFormat(val, "yyyy.MM/dd")}</div>;
      },
    },
    {
      title: t("CLIENT"),
      dataIndex: "userInfo",
      key: "userInfo",
      width: "120px",
      render: (val, record) => {
        if (val && val.length > 0) {
          return (
            <div
              style={{
                color: "#3E6FA4",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
              onClick={() => history.push("/profile-deprecated/" + val[0]._id)}
            >
              {val[0].nickName}
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: t("ATTACHED_FILE"),
      dataIndex: "files",
      key: "files",
      width: "120px",
      render: (val, record) => {
        if (val && val.length > 0) {
          for (let index = 0; index < val.length; index++) {
            const element = val[index];
            if (element && element.file) {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        backgroundColor: "#3E6FA4",
                        width: "38px",
                        height: "38px",
                        display: "flex",
                        borderRadius: "5px",
                        marginRight: "5px",
                      }}
                    ></div>
                    <div>
                      <div
                        style={{
                          color: "#3E6FA4",
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => history.push("/profile-deprecated/" + val[0]._id)}
                      >
                        {t(element.name)}
                        {" - "}
                        {element?.file.name}
                      </div>
                      <div>
                        {Math.ceil(element?.file?.size)}
                        {" KB"}
                      </div>
                    </div>
                  </div>
                  <img src={downArrowIcon} style={{ cursor: "pointer" }} />
                </div>
              );
            }
          }
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: t("ORDER_NUMBER"),
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: "120px",
      render: (val, record) => {
        return (
          <div
            style={{ color: "#3E6FA4", cursor: "pointer" }}
            onClick={() => onGoDetail(record._id)}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: t("ORDER_STATUS"),
      dataIndex: "status",
      key: "status",
      width: "120px",
      render: (val, record) => {
        return (
          <div style={{ color: "#3E6FA4", cursor: "pointer" }}>{t(val)}</div>
        );
      },
    },
    {
      title: t("ACTION"),
      dataIndex: "action",
      key: "action",
      render: (text, record) => getActionColumn(text, record),
    },
  ];

  const getActionColumn = (text, record) => {
    let id = record.id || record._id;
    return (
      <div
        style={{
          color: "#3E6FA4",
          fontSize: "14px",
          fontFamily: "Inter",
          cursor: "pointer",
        }}
        onClick={showEditModal.bind(this, record)}
      >
        {t("EDIT")}
      </div>
    );
  };

  const showEditModal = (record) => {
    setCurrentOrderId(record._id);
    setOrderDetailVisible(true);
  };

  const onGoDetail = (orderId) => {
    history.push("/company/order", { orderId });
  };

  return (
    <div
      className="common-page"
      style={{
        paddingTop: "25px",
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* <BreadcrumbNormal title={t("SHOP_ORDERS")} parent={t("MY_SHOP")} /> */}

      <div className="company-document-supplier-container common-management ">
        {/* <div className="search-panel">
          <div className="search-row">
            <div className="search-item">
              <div className="label">{t("ORDER_DATE")}</div>
            </div>
          </div>
        </div> */}
        <Table
          dataSource={orderList}
          columns={columns}
          className="documents-supplier-table"
          loading={loading}
          pagination={false}
        />
        {/* <div className="orders-title common-management-title">
          {t("SHOP_ORDERS")}
        </div>
        <div className="orders-banner common-management-panel">
          <div className="orders-banner-item">
            <div className="item-title">{t("THIS_MONTH_ORDER")}</div>
            <div className="item-number">{monthOrderList.length}</div>
          </div>
          <div className="orders-banner-item">
            <div className="item-title">{t("TOTAL_NUMBER_OF_ORDER")}</div>
            <div className="item-number">{orderList.length}</div>
          </div>
          <div className="orders-banner-item">
            <div className="item-title">{t("ALREADY_PAYED_ORDER")}</div>
            <div className="item-number">{paiedOrderList.length}</div>
          </div>
          <div className="orders-banner-item">
            <div className="item-title">{t("ALREADY_COMPLETED_ORDER")}</div>
            <div className="item-number">{completeOrderList.length}</div>
          </div>
        </div> */}
        {/* <Tabs
          activeKey={tabIndex}
          className="orders-tabs"
          onChange={(key) => setTabIndex(key)}
          moreIcon={<img src={moreIcon} />}
        >
          <div className="orders-tabs-filter-panel">
            <span className="label">{t("VIEW_MY_ORDERS_IN")}</span>
            <Dropdown overlay={menu_filter_time()} trigger={["click"]}>
              <div className="time-filter-panel">
                {filterByMonthContent}
                <img src={downArrowIcon} style={{ marginLeft: "11px" }} />
              </div>
            </Dropdown>
          </div>
          <TabPane tab={t("ALL_ORDER")} key="1">
            <ListBasic
              data={orderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("ORDER_WAITING_PAYMENT")} key="2">
            <ListBasic
              data={waitingPayOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("WAITING_TO_DELIVER")} key="3">
            <ListBasic
              data={unDeliveredOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("DELIVERY_SEND")} key="4">
            <ListBasic
              data={unReceivedOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("WAITING_RECEIVED")} key="5">
            <ListBasic
              data={deliveredOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("RECEIVED")} key="6">
            <ListBasic
              data={receivedOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("ORDER_CANCEL")} key="7">
            <ListBasic
              data={cancelOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("ORDER_COMPLETE")} key="8">
            <ListBasic
              data={completeOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
        </Tabs> */}
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: aliPayForm }}></div> */}
      <Modal
        destroyOnClose={true}
        title={null}
        visible={orderDetailVisible}
        closable={false}
        footer={null}
        className="select-friend-modal"
        onCancel={() => setOrderDetailVisible(false)}
      >
        <OrderInfoEdit orderId={currentOrderId}></OrderInfoEdit>
      </Modal>
    </div>
  );
}
