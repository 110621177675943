import { actionRequest, formatResponse, formatResponseArray } from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";

export async function getRoles(pms: any) {
  let data: any = await actionRequest(RequestType.GET, apiPath.roles, pms);
  return formatResponseArray(data);
}

/**
 *
 * @param pms
 */
export async function createRole(pms: any) {
  return await actionRequest(RequestType.POST, apiPath.roleCreate, pms);
}

export async function companyRoles(pms: any) {
  let data: any = await actionRequest(
    RequestType.GET,
    apiPath.rolesCompanyRoles,
    pms
  );
  return formatResponseArray(data);
}
