import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Table,
  Space,
  Select,
  InputNumber,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { discountTypeArray, localizeTitle } from "../../../constants/constants";
import InputNumberBasic from "../../common/input/InputNumberBasic";
import "./style/DiscountCreate.less";
import { discountType } from "../../../constants/discountType.enum";
import {
  discountCreate,
  discountEvents,
  discountUpdate,
} from "../../../api/discounts";
import { loadProductsForSelect } from "../../../api/product";
import { deepClone } from "../../../utils/utils";
import { getActivityList } from "../../../api/activity";

export default function DiscountCreate(props) {
  const { detail, onComplete, onClose } = props;
  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const [discountList, setDiscountList] = useState([]);
  const [products, setProducts] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [discountTypeValue, setDiscountTypeValue] = useState();
  const [degressives, setDegressives] = useState([]);
  const [degressiveForm] = Form.useForm();
  const [_id, setID] = useState();
  const [percent, setPercent] = useState();
  const [product, setProduct] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [eventType, setEventType] = useState();

  useEffect(() => {
    let list = discountTypeArray.map((item) => {
      return { ...item, label: t(item.label) };
    });
    setDiscountTypeValue(list[0].value);
    setDiscountList(list);
    loadProductSelectOptions(user.userInfo.companyId);
    loadEventTypes();
    if (detail) {
      loadDetail();
    }
  }, []);

  const degressiveColumns = [
    { title: "Count", dataIndex: "count", key: "count" },
    { title: "Price", dataIndex: "price", key: "price" },
    {
      title: "Action",
      dataIndex: "",
      key: "y",
      render: (val) => {
        return (
          <Button type="link" onClick={onRemove.bind(this, val.key)}>
            {t("DELETE")}
          </Button>
        );
      },
    },
  ];

  const onRemove = (key) => {
    let list = deepClone(degressives);
    list = list.filter((item) => item.key !== key);
    setDegressives(list);
  };

  const onAdd = () => {
    degressiveForm.validateFields().then((values) => {
      let { count, price } = values;
      let list = deepClone(degressives);
      let filterList = list.filter((item) => item.count === count);
      if (filterList.length === 0) {
        list.push({ key: ++degressives.length, count, price });
        setDegressives(list);
      }
      degressiveForm.setFieldsValue({ count: "", price: "" });
    });
  };

  const loadDetail = async () => {
    let { productIds, event, _id, isAll } = detail;
    setID(_id);
    setDiscountTypeValue(detail.discountType);
    if (!isAll && productIds) {
      setProduct(productIds);
    }
    setStartDate(detail.startDate);
    setEndDate(detail.endDate);
    setPercent(getPercent());
    if (event) {
      setEventType(event.eventId);
    }
    if (detail.degressives) {
      setDegressives(detail.degressives);
    }
  };

  const getPercent = () => {
    let { normal, destocks, event } = detail;
    let percent = "";
    switch (detail.discountType) {
      case discountType.NORMAL:
        percent = normal.percent;
        break;
      case discountType.DESTOCKS:
        percent = destocks.percent;
        break;
      case discountType.EVENT:
        percent = event.percent;
        break;
      default:
        break;
    }
    return percent;
  };

  const loadProductSelectOptions = async (coId) => {
    let result = await loadProductsForSelect(coId, i18n.language);
    // console.log('------loadProductsForSelect:', result);
    setProducts(result);
  };

  const loadEventTypes = async () => {
    let pms = { from: "1", page: 0, per_page: 10 };
    let result = await getActivityList(pms);
    let list = result.map((item) => {
      return { label: item.title, value: item._id };
    });
    setEventTypes(list);
  };

  const onFinish = async () => {
    let pms = {
      coId: user.userInfo.companyId,
      discountType: discountTypeValue,
      startDate,
      endDate,
    };
    if (product.length === 0) {
      pms["isAll"] = true;
    } else {
      pms["productIds"] = product;
      pms["isAll"] = false;
    }

    switch (discountTypeValue) {
      case discountType.NORMAL:
        pms.normal = {
          percent: percent,
        };
        break;
      case discountType.DESTOCKS:
        pms.destocks = {
          percent: percent,
        };
        break;
      case discountType.DEGRESSIVE:
        pms.degressives = degressives;
        break;
      case discountType.EVENT:
        pms.event = {
          eventId: eventType,
          percent: percent,
        };
        break;
      default:
        break;
    }
    let result;
    if (_id) {
      pms._id = _id;
      result = await discountUpdate(pms);
    } else {
      result = await discountCreate(pms);
    }
    onComplete && onComplete();
  };

  const onDiscountTypeChange = (val) => {
    setDiscountTypeValue(val);
  };

  const onCloseClick = () => {
    onClose && onClose();
  };

  const onProductChange = (val) => {
    setProduct(val);
  };

  return (
    <div className="discount-create-container">
      <div className="modal-body">
        <div className="modal-title">{t("CREATE_DISCOUNT")}</div>

        <div className="modal-content-column">
          <div className="content-row-label">{t("DISCOUNT_TYPE")}</div>
          <Select
            value={discountTypeValue}
            options={discountList}
            onChange={onDiscountTypeChange.bind(this)}
            className="content-row-select"
          />
        </div>
        {discountTypeValue === discountType.EVENT && (
          <div className="modal-content-column">
            <div className="content-row-label">{t("NAME_OF_THE_EVENT")}</div>
            <Select
              value={eventType}
              options={eventTypes}
              className="content-row-select"
              onChange={(val) => setEventType(val)}
            />
          </div>
        )}

        {products && products.length > 0 && (
          <div className="modal-content-column">
            <div className="content-row-label">{t("PRODUCT")}</div>
            <Select
              mode="multiple"
              value={product}
              options={products}
              className="content-row-select"
              onChange={onProductChange.bind(this)}
            />
          </div>
        )}

        {discountTypeValue !== discountType.DEGRESSIVE && (
          <div className="modal-content-column">
            <span className="content-row-label">{t("DISCOUNT_PERCENT")}</span>
            <InputNumber
              value={percent}
              // placeholder={t("PLEASE_WRITE_WAREHOUSE_NAME")}
              className="content-row-input-number"
              min="0"
              max="100"
              onChange={(val) => setPercent(val)}
              // onInput={onWarehouseNameChange.bind(this)}
            />
          </div>
          // <InputNumberBasic label="Percent" name="percent" />
        )}
        <div className="content-date-panel">
          <div className="content-row-label">{t("DISCOUNT_DATE")}</div>
          <DatePicker
            value={startDate && moment(startDate, "YYYY-MM-DD")}
            className="content-row-date"
            onChange={(e, val) => setStartDate(val)}
          />
          <span className="content-row-date-middle-text">{t("TO")}</span>
          <DatePicker
            value={endDate && moment(endDate, "YYYY-MM-DD")}
            className="content-row-date"
            onChange={(e, val) => setEndDate(val)}
          />
        </div>
        {/* <RangeDatePickerBasic
            label="RangeDate"
            name="rangeDate"
            required={true}
          /> */}
        {discountTypeValue === discountType.DEGRESSIVE && (
          <Form form={degressiveForm}>
            <Space size="middle" align="baseline">
              <InputNumberBasic label="Count" name="count" />
              <InputNumberBasic label="Price" name="price" />
              <Button
                type="dashed"
                htmlType="button"
                icon={<PlusOutlined />}
                onClick={onAdd.bind(this)}
              >
                Add
              </Button>
            </Space>
            <Table
              columns={degressiveColumns}
              dataSource={degressives}
              pagination={false}
            />
          </Form>
        )}
        <Space size={8} className="modal-confirm-row">
          <Button
            className="modal-confirm-btn"
            onClick={onCloseClick.bind(this)}
          >
            {t("CANCEL")}
          </Button>
          <Button
            htmlType="submit"
            className="modal-confirm-btn-primary"
            onClick={onFinish.bind(this)}
          >
            {t("SAVE")}
          </Button>
        </Space>
      </div>
    </div>
  );
}
