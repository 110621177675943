import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../contexts/UserIdProvider";
import "./style/browseRecord.less";
import { browseRecordList, browseRecordRemove } from "../../api/browseRecord";
import { useLocation } from "react-router-dom";
import { dateFormat } from "../../utils/date";
import ProductItem from "../shop/product/ProductItem";

export default function ProductBrowseRecord() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const { userId } = user;
  const { state } = useLocation();
  const [busType, setBusType] = useState();
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 20;

  useEffect(() => {
    if (state) {
      setBusType(state.type);
    } else {
      loadData();
    }
  }, [state]);

  useEffect(() => {
    if (busType) {
      loadData();
    }
  }, [busType]);

  const loadData = async () => {
    let pms = { userId, busType, page: pageIndex, per_page: pageSize };
    let list = await browseRecordList(pms);
    if (list && list.length > 0) {
      list = filterList(list);
      setData(list);
    }
  };

  const filterList = (list) => {
    let result = [];
    list.forEach((item) => {
      if (item.productInfo.length > 0 && !result.includes(item)) {
        let element = result.find((item) => item.busId === item.busId);
        if (!element) {
          result.push(item);
        }
      }
    });
    return result;
  };

  return (
    <div className="common-management browse-container">
      <div className="common-management-title">浏览记录</div>
      <div className="record-content common-management-panel">
        {data.map((item) => (
          <ProductItem item={item.productInfo[0]} />
        ))}
      </div>
    </div>
  );
}
