import React, { useEffect, useState } from "react";
import { List, Spin, Table, Input, Select, Button } from "antd";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { deepClone } from "../../../../utils/utils";
import { pageFriends } from "../../../../api/contact";
import { useTranslation } from "react-i18next";
import "./style/selectFriend.less";
import { localizeValue } from "../../../../constants/constants";

export default function SelectFriend(props) {
  const { onComplete, onCancel, selectedItems } = props;
  const { user } = useUserId();
  const { userId } = user;
  const [friendList, setFriendList] = useState([]);
  const [oriFriendList, setOriFriendList] = useState([]);
  const [searchUserName, setSearchUserName] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 10;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { t,i18n } = useTranslation();

  const [selectedRow, setSelectedRow] = useState([]);

  const { Option } = Select;

  useEffect(() => {
    loadData();
  }, [pageIndex]);

  useEffect(() => {
    setSelectedRow(selectedItems);
  }, [selectedItems]);

  const loadData = async (index) => {
    setLoading(true);
    let pms = { page: pageIndex, per_page: pageSize, keyword: "" };
    let result = await pageFriends(userId, pms);
    console.log("-------------------select friends:", result);
    if (result && result.length > 0) {
      result.map((item) => {
        item.key = item.id;
      });
      if (index > 0) {
        let _list = deepClone(friendList);
        result = _list.concat(result);
      }
      setFriendList(result);
      setOriFriendList(result);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const renderCompanyName = (info) => {
    return localizeValue(i18n.language,info.companyName);
  };

  const columns = [
    {
      title: t("COMPANY_NAME"),
      dataIndex: "companyInfo",
      key: "companyInfo",
      render: (text) => <span>{renderCompanyName(text)}</span>,
    },
    {
      title: t("NAME"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("JOB_POSITION"),
      dataIndex: "roleInfo",
      key: "roleInfo",
      render: (record) => <span>{record.name == "ADMIN" ? t(record.name) : record.name}</span>,
    },
    {
      title: t("ADDRESS"),
      dataIndex: "companyInfo",
      key: "companyInfo",
      render: (text) => <span>{text.address}</span>,
    },
  ];

  const onSelect = (value) => {
    console.log("-------------onSelect:", value);
    selectedRow.some((i) => i.key === value.key)
      ? setSelectedRow(selectedRow.filter((i) => i.key !== value.key))
      : setSelectedRow([...selectedRow, value]);
  };

  const onSelectAll = (value) => {
    console.log("-------------onSelectAll:", value);
    value
      ? setSelectedRow([
        ...selectedRow,
        ...friendList
          .map((i) => ({ ...i, key: i.id }))
          .filter(
            (item) => !selectedRow.map((el) => el.key).includes(item.key)
          ),
      ])
      : setSelectedRow(
        selectedRow.filter(
          (i) => !friendList.map((item) => item.id).includes(i.key)
        )
      );
  };

  const selectedRowSelection = {
    type: "checkbox",
    onSelect,
    onSelectAll,
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys, selectedRow) => {
      setSelectedRow([...selectedRowKeys]);
    },
  };

  const onSave = () => {
    onComplete && onComplete(selectedRow);
  };

  const onCancelClick = () => {
    onCancel && onCancel();
  };

  const onUserNameChange = (e) => {
    let temp = deepClone(friendList);
    temp = oriFriendList.filter(
      (filterItem) => filterItem.name.indexOf(e.target.value) != -1
    );
    setFriendList(temp);
    setSearchUserName(e.target.value);
  };

  const onResetSearch = (e) => {
    let temp = deepClone(friendList);
    temp = oriFriendList;
    setFriendList(temp);
    setSearchUserName("");
  };

  return (
    <div className="operation-select-friend-container">
      <div className="search-panel">
        <div className="search-panel-row">
          <div className="label">{t("CONTACT_USER_NAME")}</div>
          <Input
            className="row-input"
            placeholder={t("CONTACT_USER_NAME")}
            value={searchUserName}
            onChange={onUserNameChange.bind(this)}
          />
        </div>
        <div className="search-panel-row">
          <div className="label">{t("FRIENDS_LABEL")}</div>
          <Select
            showSearch
            style={{ width: 250 }}
            // mode="multiple"
            placeholder={t("FRIENDS_LABEL")}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            <Option value="1">集团采购</Option>
            <Option value="2">大客户</Option>
            <Option value="3">潜在客户</Option>
            <Option value="4">目标客户</Option>
          </Select>
        </div>
      </div>
      <div className="searcn-panel-bottom">
        <Button className="btn-search" style={{ marginRight: "20px" }}>
          {t("INQUIRES")}
        </Button>
        <Button className="btn-reset" onClick={onResetSearch.bind(this)}>
          {t("RESET")}
        </Button>
      </div>
      <div style={{ paddingLeft: "20px" }}>
        <Table
          columns={columns}
          dataSource={friendList}
          className="operation-create-friend-select-table"
          rowSelection={selectedRowSelection}
        />
      </div>
      <div className="search-bottom">
        <div className="searcn-panel-bottom">
          <Button
            className="btn-reset"
            style={{ marginRight: "40px" }}
            onClick={onCancelClick.bind(this)}
          >
            {t("CANCEL")}
          </Button>
          <Button
            className="btn-search"
            style={{ marginRight: "20px" }}
            onClick={onSave.bind(this)}
          >
            {t("SAVE")}
          </Button>
        </div>
      </div>
    </div>
  );
}
