import React from "react";
import styles from "./ProductStock.module.sass";
import TooltipGlodal from "../../../../core-digital-template/components/TooltipGlodal";
import StockList from "./StockList";

const ProductStock = () => {
    return (
        <>
            <div className={styles.section}>
                <StockList />
            </div>
            <TooltipGlodal />
        </>
    );
};

export default ProductStock;
