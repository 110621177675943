import { useUserId } from "../../../contexts/UserIdProvider";
import { useEffect, useState } from "react";
import DashboardLeftSiderWinery from "./DashboardLeftSiderWinery";
import DashboardLeftSiderImporter from "./DashboardLeftSiderImporter";
import { UserType } from "../../../constants/usersType.enum";
import VMenu from "../../common/dashboard/menu/VMenu";
import LeftSiderSmall from "../left-sider-small/LeftSiderSmall";

export default function DashboardLeftSider(props) {
  let user = useUserId();
  let {rest}=props;
  const [isWinery, setIsWinery] = useState(false);
  const [isImporter, setIsImporter] = useState(false);
  const [haveRight, setHaveRight] = useState(false);

  useEffect(() => {
    if (user && user.user.userInfo) {
      if (user.user.userInfo.type == UserType.SUPPLIER) {
        setIsWinery(true);
      } else if (
        user.user.userInfo.type == UserType.BUYER ||
        user.user.userInfo.type == UserType.SUPPLIERBUYER
      ) {
        setIsImporter(true);
      } else {
        setHaveRight(true);
      }
    }
  }, [user]);

  return (
    <>
      <LeftSiderSmall></LeftSiderSmall>
      <VMenu rest={rest}></VMenu>
      {/* {isWinery && <DashboardLeftSiderWinery />}
      {isImporter && <DashboardLeftSiderImporter />} */}
    </>
  );
}
