import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {IFriend} from "../../interfaces/IFriend";
import {pageFriends} from "../../api/contact";


const friends:Array<IFriend> = []

const initialState = {
    friends  : friends,
}

export const getAllFriends:any = createAsyncThunk(
    "/friends",
    async ({ userId }:any, thunkAPI) => {
        try {

            let pms = { page: 0, per_page: 500 };
            return pageFriends(userId,pms)

        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)

export const friendsSlice = createSlice({
    name: 'friends',
    initialState,
    reducers: {
        //Reducer comes here
    },
    extraReducers:{
        [getAllFriends.fulfilled]: (state, {payload}) => {

            state.friends = payload;

        },
        [getAllFriends.pending]: (state) => {
            //state.isFetching = true;
        },
        [getAllFriends.rejected]: (state, {payload}) => {
            //   state.isFetching = false;
            //   state.isError = true;
            //   state.errorMessage = payload.message;
        },
    }
})


export const friendsSelector = (state:any) => state.friends;
