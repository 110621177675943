import { useAuth } from "../../contexts/AuthProvider";
import NavbarLogged from "./NavbarLogged";
import NavbarNotLogged from "./NavbarNotLogged";

export default function MyNavbar() {
  const { authTokens } = useAuth();

  // return authTokens ? <NavbarLogged /> : <NavbarNotLogged />;
  return <NavbarLogged />;
}
