import React, { useEffect, useState } from "react";
import styles from "./WebsiteSidebar.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
//import Image from "../Image";
import { useUserId } from "../../../contexts/UserIdProvider";
import { companyDetail } from "../../../api/company";
import { statusCode } from "../../../constants/statusCode.enum";
import { getFileTypeByPath } from "../../../api/common";
import { localizeImg } from "../../../constants/constants";
import { deepClone } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { Path, PathTools } from "../../../constants/path";
import { UserType } from "../../../constants/usersType.enum";
import VinbipLogo from "../VinbipLogo";
import { useIMContext } from "../../../contexts/IMProvider";
import useIM from "../../../hooks/useIM";

function WebsiteSidebar({ className, onClose }) {
  const { t } = useTranslation();
  const { user, isWinery } = useUserId();
  const { pathname } = useLocation();

  const {
    webIM,
    imLoginStatus,
    newMsg,
    sessionMessageList,
    initIMLogin,
    getSessionMessage,
    setChatCallMsg,
  } = useIMContext();
  const {
    messageList,
    newGroup,
    fetchHistoryMessage,
    readChannelMessage,
    sendTextMessage,
    sendCustomMessage,
    createGroup,
    quitGroup,
    dissolveGroup,
    removeGroupMember,
    addGroupMember,
  } = useIM(webIM);

  const [userCompanyDetail, setUserCompanyDetail] = useState({});

  const [navigation, setNavigation] = useState([]);
  const [navigationIndex, setNavigationIndex] = useState(0);

  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    loadCompany();
  }, [user]);

  useEffect(() => {
    if (userCompanyDetail && userCompanyDetail._id) {
      initNavigation(userCompanyDetail);
    }
  }, [userCompanyDetail]);

  // useEffect(() => {
  //   if (webIM) {
  //     initIMLogin(user?.userInfo);
  //   }
  // }, [webIM, imLoginStatus]);

  // useEffect(() => {
  //   if (sessionMessageList.length > 0) {
  //     let tempNum = 0;
  //     sessionMessageList.filter((item) => {
  //       tempNum += item.unread_num;
  //     });

  //     setMessageUnreadNum(tempNum);
  //   }
  // }, [sessionMessageList]);

  // useEffect(() => {
  //   if (newMsg && navigation.length > 2 && navigationIndex != 2) {
  //     console.log("Sidebar newMsg:", newMsg);
  //     setMessageUnreadNum(navigation[2].counter + 1);
  //   }
  // }, [newMsg]);

  // const setMessageUnreadNum = (num) => {
  //   let tempNavigation = deepClone(navigation);
  //   tempNavigation[2].counter = num;
  //   setNavigation(tempNavigation);
  // };

  const loadCompany = async () => {
    let data = await companyDetail(user?.userInfo?.companyId);
    if (data) {
      if (data.banner) {
        data.bannerType = getFileTypeByPath(localizeImg(data.banner));
      }
      setUserCompanyDetail(data);
    }
  };

  const initNavigation = () => {
    let tempNavigation = deepClone(navigation);
    const navigationVal = [
      {
        title: t("HOME"),
        icon: "home",
        url: "/",
        //iconType: "svg",
      },
      // TODO:Comment for V1.0
      {
        title: t("EXPLORE"),
        icon: "partners",
        url: PathTools.joinPath(
          isWinery ? Path.partners : Path.shopSearch,
          userCompanyDetail?.coName
        ),
      },
      // TODO:Comment for V1.0
      {
        title: t("MESSAGING"),
        icon: "message",
        url: PathTools.joinPath(Path.messaging, userCompanyDetail?.coName),
        counter: 0,
        colorCounter: "#00BC99",
      },
      // Profile moved to the top menu
      // {
      //   title: t("MY_PROFILE"),
      //   icon: "profile-circle",
      //   url: PathTools.joinPath(
      //     Path.companyDetail,
      //     userCompanyDetail?.coName
      //   ),
      //   params: { id: user.userId },
      //   // iconType: "svg",
      // },
      {
        title: t("MY_STORE"),
        icon: "store",
        url: PathTools.joinPath(Path.products, userCompanyDetail?.coName),
      },
      {
        title: t("ORDERS"),
        icon: "pallet",
        url: PathTools.joinPath(Path.orders, userCompanyDetail?.coName),
      },
      {
        title: t("WAREHOUSES"),
        icon: "warehouse",
        url: PathTools.joinPath(
          Path.supplierWarehouse,
          userCompanyDetail?.coName
        ),
      },
      // {
      //   title: t("MY_STORE"),
      //   slug: "products",
      //   icon: "store",
      //   // iconType: "svg",
      //   add: false,
      //   dropdown: [
      //     {
      //       title: t("PRODUCTS"),
      //       // url: "/products/dashboard",
      //       url: PathTools.joinPath(Path.products, userCompanyDetail?.coName),
      //     },
      //     // TODO:Comment for V1.0
      //     {
      //       title: t("ORDERS"),
      //       url: PathTools.joinPath(Path.orders, userCompanyDetail?.coName),
      //       //counter: "2",
      //       colorCounter: "#00BC99",
      //       developing: true,
      //     },
      //     {
      //       title: t("SHIPPING_LOCATIONS"),
      //       url: PathTools.joinPath(
      //         Path.supplierWarehouse,
      //         userCompanyDetail?.coName
      //       ),
      //       //counter: "2",
      //       colorCounter: "#00BC99",
      //     },
      //     {
      //       title: t("STORE_DETAILS"),
      //       url: PathTools.joinPath(
      //         Path.companyVerify,
      //         userCompanyDetail?.coName
      //       ),
      //       ////counter: "2",
      //       colorCounter: "#00BC99",
      //     },
      //     {
      //       title: t("COMPANY_INFORMATION"),
      //       url: PathTools.joinPath(
      //         Path.companyInformation,
      //         userCompanyDetail?.coName
      //       ),
      //       ////counter: "2",
      //       colorCounter: "#00BC99",
      //     },
      //     {
      //       title: t("BANK_INFORMATION"),
      //       url: PathTools.joinPath(
      //         Path.supplierBankInfo,
      //         userCompanyDetail?.coName
      //       ),
      //       ////counter: "2",
      //       colorCounter: "#00BC99",
      //     },
      //     {
      //       title: t("INVOICING_INFORMATION"),
      //       url: PathTools.joinPath(
      //         Path.companyInvoiceSetting,
      //         userCompanyDetail?.coName
      //       ),
      //       ////counter: "2",
      //       colorCounter: "#00BC99",
      //     },
      //     //   {
      //     //     title: t("DRAFT"),
      //     //     url: "/products/drafts",
      //     //     ////counter: "2",
      //     //     colorCounter: "#FFBC99",
      //     //   },
      //     //   {
      //     //     title: "Released",
      //     //     url: "/products/released",
      //     //   },
      //     //   {
      //     //     title: "Comments",
      //     //     url: "/products/comments",
      //     //   },
      //     //   {
      //     //     title: "Scheduled",
      //     //     url: "/products/scheduled",
      //     //     counter: "8",
      //     //     colorCounter: "#B5E4CA",
      //     //   },
      //   ],
      // },
      // TODO:Comment for V1.0
      {
        title: t("VINBIP_SERVICES"),
        slug: "vinbip_services",
        icon: "diamond",
        developing: true,
      },
      // dropdown: [
      //   {
      //     title: t("MEMBERSHIP"),
      //     url: "/customers/overview",
      //     developing: true,
      //   },
      //   {
      //     title: t("SAMPLES_DISTRIBUTION"),
      //     url: "/customers/customer-list",
      //     developing: true,
      //   },
      //   {
      //     title: t("BRAND_SAFETY"),
      //     url: PathTools.joinPath(
      //       Path.brandSafety,
      //       userCompanyDetail?.coName
      //     ),
      //     developing: true,
      //   },
      //   {
      //     title: t("VINBIP_WAREHOUSES"),
      //     url: "/customers/customer-list",
      //     developing: true,
      //   },
      //   // {
      //   //   title: t("ADVERTISING"),
      //   //   url: "/customers/customer-list",
      //   // },
      //   // {
      //   //   title: t("TRANSLATIONS"),
      //   //   url: "/customers/customer-list",
      //   // },
      // ],
      // },
      // TODO:Comment for V1.0
      {
        title: t("INSIGHTS"),
        icon: "leaderboard",
        url: PathTools.joinPath(Path.dashboard, userCompanyDetail?.coName),
      },
      {
        title: t("Settings"),
        slug: "settings",
        icon: "setting",
        url: PathTools.joinPath(
          Path.settingsSecurity,
          userCompanyDetail?.coName
        ),
      },
      // {
      //   title: t("Settings"),
      //   slug: "settings",
      //   icon: "setting",
      //   // iconType: "svg",
      //   dropdown: [
      //     // TODO:hiddend on V1.0 need reopen on V2.0
      //     // {
      //     //   title: t("USERS"),
      //     //   url: PathTools.joinPath(
      //     //     Path.settingsUsers,
      //     //     userCompanyDetail?.coName
      //     //   ),
      //     // },
      //     {
      //       title: t("SECURITY"),
      //       url: PathTools.joinPath(
      //         Path.settingsSecurity,
      //         userCompanyDetail?.coName
      //       ),
      //     },
      //     {
      //       title: t("DATA_PRIVACY"),
      //       url: PathTools.joinPath(
      //         Path.settingsPrivacy,
      //         userCompanyDetail?.coName
      //       ),
      //     },
      //     {
      //       title: t("NOTIFICATIONS"),
      //       url: PathTools.joinPath(
      //         Path.settingsNotifications,
      //         userCompanyDetail?.coName
      //       ),
      //     },
      //   ],
      // },
      //   {
      //     title: "Promote",
      //     icon: "promotion",
      //     url: "/promote",
      //   },
    ];

    if (user.userInfo.type == UserType.ADMIN) {
      navigationVal.push({
        title: t("SUPPLIER_LIST"),
        icon: "leaderboard",
        url: PathTools.joinPath(Path.vinbipSupplierList, userCompanyDetail?.coName),
      });
    }

    tempNavigation = navigationVal;
    setNavigation(tempNavigation);
  };

  const onNavigationItemClick = (index) => {
    onClose && onClose();
    setNavigationIndex(index);
    // if (index == 2) {
    //   setMessageUnreadNum(0);
    // }
  };

  return (
    <>
      <div
        className={cn(styles.sidebar, className
          , {[styles.active]: visible,}
        )}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        {/* <Link className={styles.logowithtext} to="/" onClick={onClose}>
          <VinbipLogo withText />
        </Link> */}
        <Link className={styles.logo} to="/" onClick={onClose}>
          <VinbipLogo />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={cn(styles.item, {
                  [styles.active]: pathname === x.url,
                })}
                to={{
                  pathname: x.developing ? Path.developing : x.url,
                  state: x.params,
                }}
                key={index}
                onClick={onNavigationItemClick.bind(this, index)}
              >
                {x.iconType == "svg" ? (
                  <img
                    src={x.icon}
                    width={24}
                    style={{ marginRight: "12px" }}
                  />
                ) : (
                  <Icon name={x.icon} size="24" />
                )}
                {x.title}
                {x.counter != 0 && (
                  <div
                    className={styles.counter}
                    style={{ backgroundColor: x.colorCounter }}
                  >
                    {x.counter}
                  </div>
                )}
              </NavLink>
            ) : (
              <></>
              // <Dropdown
              //   className={styles.dropdown}
              //   visibleSidebar={visible}
              //   setValue={setVisible}
              //   key={index}
              //   item={x}
              //   onClose={onClose}
              // />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        {/* <div className={styles.foot}>
          <button className={styles.link} onClick={() => setVisibleHelp(true)}>
            <Icon name="help" size="24" />
            {t("HELP_GETTING_STARTED")}
          </button>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div> */}
      </div>
      {/* <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div> */}
    </>
  );
}

export default WebsiteSidebar;
