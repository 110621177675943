import styles from "./Editor.module.sass";
import cn from "classnames";
import { Editor as ReactEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Tooltip from "../Tooltip";
import { useEffect, useState } from "react";
import { convertToHTML, convertFromHTML } from "draft-convert";
import { EditorState, ContentState } from "draft-js";

const Editor = ({
  state,
  onChange,
  classEditor,
  label,
  classLabel,
  tooltip,
  place,
  button,
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    onChange(html);
  }, [editorState]);

  useEffect(() => {
    if (state) {
      const blocksFromHTML = convertFromHTML(state);
      console.log("editor:",blocksFromHTML);
    //   const content = ContentState.createFromBlockArray(
    //     blocksFromHTML.contentBlocks,
    //     blocksFromHTML.entityMap
    //   );
      setEditorState(EditorState.createWithContent(blocksFromHTML));
    }
  }, [state]);

  return (
    <div
      className={cn(
        styles.editor,
        { [styles.editorButton]: button },
        classEditor
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <ReactEditor
        editorState={editorState}
        toolbarClassName={styles.editorToolbar}
        wrapperClassName={styles.editorWrapper}
        editorClassName={styles.editorMain}
        onEditorStateChange={setEditorState}
        stripPastedStyles="true"
        toolbar={{
          options: ["inline", "list", "remove"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          // blockType: {
          //   options: ['Normal', 'H1', 'H2', 'H3'],
          // },
        }}
      />
      {button && (
        <button className={cn("button-small", styles.button)}>{button}</button>
      )}
    </div>
  );
};

export default Editor;
