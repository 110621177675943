import React, { useEffect, useState } from "react";
import styles from "./LanguageSetting.module.sass";
import NameAndDescription from "./NameAndDescription";
import { deepClone } from "../../utils/utils";
import { useUserId } from "../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import { statusCode } from "../../constants/statusCode.enum";
import { useLocation } from "react-router";
import { addCompanyInfo, saveCompanyInfo } from "../../api/company";
import { toastInfo } from "../../api/toast";

export default function LanguageSetting(props) {
  const { state } = useLocation();

  const { user } = useUserId();
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({});

  const [productDetailInfo, setProductDetailInfo] = useState({});

  const [companyId, setCompanyId] = useState(user?.userInfo?.companyId);

  const onNameAndDescriptionChange = (fields) => {
    setFormDataValue(fields);
  };

  const setFormDataValue = (fields) => {
    let clone = deepClone(formData);
    clone = Object.assign({}, clone, fields);
    setFormData(clone);

    onSubmitNewCompany(clone);
  };

  const onSubmitNewCompany = async (clone) => {
    let pms = clone;

    pms.connected = false;
    // pms.type = "SUPPLIER";
    pms.userId = user?.userId;

    console.log("--------------onSubmitCompanyInfo:", pms);
    let result;
    if (companyId) {
      result = await saveCompanyInfo(pms);

      console.log("--------------onSubmitCompanyInfo result:", result);
      if (result && result.statusCode == statusCode.SUCCESS) {
        i18n.changeLanguage(pms.language);
        toastInfo(t("SAVE_SUCCESS"));
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        toastInfo(t("SAVE_FAILED"));
      }
    }
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription
            className={styles.card}
            onChange={onNameAndDescriptionChange.bind(this)}
            dataSource={productDetailInfo}
            companyId={companyId}
          />
        </div>
      </div>
    </>
  );
}
