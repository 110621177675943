import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ShopSearch.module.sass";
import Profile from "./Profile";
import FilterPanel from "./FilterPanel";
import Card from "../../core-digital-template/components/Card";
import Filters from "../../core-digital-template/components/Filters";
import Product from "../../core-digital-template/components/Product";

// data
import { useUserId } from "../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import {
  getProductConfigForSelect,
  getProductSearch,
  loadProductConfig,
} from "../../api/product";
import { Carousel, Skeleton } from "antd";
import exploreImage1 from "../../resource/images/discover/explore_1.jpg";
import exploreImage2 from "../../resource/images/discover/explore_2.jpg";
import exploreImage3 from "../../resource/images/discover/explore_3.jpg";
import exploreImage4 from "../../resource/images/discover/explore_4.jpg";
import CategoryModal from "./CategoryModal";
import CountryModal from "./CountryModal";
import StockModal from "./StockModal";
import PriceModal from "./PriceModal";
import { BrowseRecordType } from "../../constants/browseRecord.enum";
import { browseRecordCreate } from "../../api/browseRecord";
import { CardType } from "../../constants/cardType.enum";
import { deepClone } from "../../utils/utils";
import Slider from "react-slick";
import RegionModal from "./RegionModal";
import { ProductConfig, localizeImg } from "../../constants/constants";
import Icon from "../../core-digital-template/components/Icon";
import { getCountryForSelect, getSearchPromoteList } from "../../api/config";

export default function ShopSearch() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const coId = user.userInfo.companyId;

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [navigation, setNavigation] = useState([
    { label: t("COUNTRY"), name: "country" },
    { label: t("REGION"), name: "region" },
    { label: t("CATEGORY"), name: "type" },
    //{ label: t("STOCK_LOCATION"), name: "stock" },
    { label: t("PRICE"), name: "priceRange" },
  ]);

  // const [sliderList, setSliderList] = useState([
  //   {
  //     url: exploreImage1,
  //     keyword: "Medoc",
  //     country: "France",
  //     id: "64dc30586cde865c5685c8e0",
  //   },
  //   {
  //     url: exploreImage2,
  //     keyword: "Southwest",
  //     country: "France",
  //     id: "64dc30a16cde865c5685c8e1",
  //   },
  //   {
  //     url: exploreImage3,
  //     keyword: "Central Valley",
  //     country: "Chile",
  //     id: "64dc316d6cde865c5685c8e3",
  //   },
  //   {
  //     url: exploreImage4,
  //     keyword: "Napa Valley",
  //     country: "USA",
  //     id: "64dc32056cde865c5685c8e5",
  //   },
  // ]);

  const [promoteList, setPromoteList] = useState([]);

  const [showCountryFilterModal, setShowCountryFilterModal] = useState(false);
  const [showRegionFilterModal, setShowRegionFilterModal] = useState(false);
  const [showCategoryFilterModal, setShowCategoryFilterModal] = useState(false);
  const [showStockFilterModal, setShowStockFilterModal] = useState(false);
  const [showPriceFilterModal, setShowPriceFilterModal] = useState(false);

  const intervals = ["Most recent", "Most new", "Most popular"];

  const [activeIndex, setActiveIndex] = useState(-1);
  const [activePromoteIndex, setActivePromoteIndex] = useState(-1);
  const [sorting, setSorting] = useState(intervals[0]);

  const [filterModel, setFilterModel] = useState({});
  const [productList, setProductList] = useState([]);

  const [selectedChanelsValue, setSelectedChanelsValue] = useState([]);

  const [price, setPrice] = useState([0, 880]);
  const [priceStep, setPriceStep] = useState(80);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 10;

  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedRegion, setSelectedRegion] = useState({});

  const [productTags, setProductTags] = useState([]);

  const [onLoading, setOnLoading] = useState(true);
  const [onProductLoading, stOnProductLoading] = useState(false);

  useEffect(() => {
    onProductDiscover(0);
    initProductTags();
  }, []);

  useEffect(() => {
    onProductDiscover(pageIndex);
  }, [filterModel]);

  const initProductTags = async () => {
    let configData = await loadProductConfig();
    let { tags, locations } = configData;

    let tempPromoteList = getSearchPromoteList(locations, i18n.language);
    console.log("ShopSearch locations:", tempPromoteList);
    setPromoteList(tempPromoteList);
    setOnLoading(false);

    let tempTags = getProductConfigForSelect(tags, currentLanguage);

    if (tempTags && tempTags.length > 0) {
      setProductTags(tempTags);
    }
  };

  const onFilterModelChange = (name, e) => {
    console.log("onFilterModelChange:", e);
    let temp = deepClone(filterModel);
    temp[name] = e.value;
    setFilterModel(temp);

    let tempNavigation = deepClone(navigation);
    tempNavigation.map((mapItem) => {
      if (mapItem.name === name) {
        mapItem["label"] = e.label;
      }
    });
    setNavigation(tempNavigation);

    if (name == "country") {
      let tempSelectedCountry = deepClone(selectedCountry);
      tempSelectedCountry = deepClone(e);
      setSelectedCountry(tempSelectedCountry);
    } else if (name == "region") {
      let tempSelectedRegion = deepClone(selectedRegion);
      tempSelectedRegion = deepClone(e);
      setSelectedRegion(tempSelectedRegion);
    }
  };

  const onFilterPriceChange = (e) => {
    let temp = deepClone(filterModel);
    temp["priceBegin"] = e.prices[0];
    temp["priceEnd"] = e.prices[1];
    setFilterModel(temp);

    const name = "priceRange";

    let tempNavigation = deepClone(navigation);
    tempNavigation.map((mapItem) => {
      console.log("onFilterModelChange", mapItem.name, name);

      if (mapItem.name === name) {
        mapItem["label"] = e.prices.join("-") + e.currency;
      }
    });
    setNavigation(tempNavigation);
  };

  const onProductDiscover = async (index) => {
    // setSelectedChanelsValue([
    //   selectedCountry,
    //   typeName,
    //   stockName,
    //   filterModel?.price?.label,
    // ]);

    stOnProductLoading(true);

    // setLoading(true);
    let page = index === 0 ? 0 : pageIndex;
    let pms = {
      page,
      per_page: pageSize,
      ...filterModel,
      // alcoholBegin: filterModel?.alcohol[0],
      // alcoholEnd: filterModel?.alcohol[1],
    };
    if (price[0] > 0) {
      pms["priceBegin"] = price[0];
    }
    if (priceStep * 11 > price[1] && price[1] >= price[0]) {
      pms["priceEnd"] = price[1];
    }

    if (filterModel?.types) {
      pms["types"] = filterModel?.types;
    }

    if (filterModel?.subRegion) {
      pms["subRegion"] = filterModel?.subRegion;
    }

    console.log("-----products pms:", pms);
    let result = await getProductSearch(pms);
    console.log("-----products discover:", result);
    if (result && result.length > 0) {
      // result = result.map((item) => item._source);
      result.map((item) => {
        item.cardType = CardType.PRODUCT;
      });
      let temp = deepClone(productList);
      result = result.filter((item) => item.cardType === CardType.PRODUCT);
      temp = result;
      // if (page > 0) {
      //   let list = deepClone(productList);
      //   result = list.concat(result);
      // }

      // let tempResultList = await loadActivities(result);
      setProductList(temp);
    } else {
      // setHasMore(false);
      // if (page === 0) {
      setProductList([]);
      // }
    }

    stOnProductLoading(false);

    // setLoading(false);

    let browseRecordPms = {
      coId,
      userId: user.userId,
      busType: BrowseRecordType.SEARCH_PRODUCT,
      content: pms,
    };
    browseRecordCreate(browseRecordPms);
  };

  const onSearchConfirm = (e) => {
    let temp = deepClone(filterModel);
    const mergedForm = Object.assign({}, temp, e);
    setFilterModel(mergedForm);
  };

  const onResetConfirm = () => {};

  const onNavigationItemClick = (index) => {
    setActiveIndex(index);
    switch (index) {
      case 0:
        setShowCountryFilterModal(true);
        break;
      case 1:
        setShowRegionFilterModal(true);
        break;
      case 2:
        setShowCategoryFilterModal(true);
        break;
      // case 3:
      //   setShowStockFilterModal(true);
      //   break;
      case 3:
        setShowPriceFilterModal(true);
        break;
    }
  };

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={styles.PrevArrow} onClick={onClick}>
        <Icon name="arrow-left" size="18" />
      </div>
    );
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={styles.NextArrow} onClick={onClick}>
        <Icon name="arrow-right" size="18" />
      </div>
    );
  }

  const settings = {
    infinite: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const onProductTagClick = (index) => {
    let temp = deepClone(productTags);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setProductTags(temp);

    onFilterModelChange("tags", [temp[index].value]);
  };

  const onSliderItemClick = (item, index) => {
    setActivePromoteIndex(index);

    let temp = deepClone(filterModel);
    temp["subRegion"] = item.value;
    setFilterModel(temp);
  };

  const handleClearNavigationFilter = (event, index) => {
    event.stopPropagation();

    setActiveIndex(-1);

    let temp = deepClone(filterModel);
    temp = {};
    setFilterModel(temp);

    let tempNavigation = deepClone(navigation);
    tempNavigation = [
      { label: t("COUNTRY"), name: "country" },
      { label: t("REGION"), name: "region" },
      { label: t("CATEGORY"), name: "types" },
      // { label: t("STOCK_LOCATION"), name: "stock" },
      { label: t("PRICE"), name: "priceRange" },
    ];
    setNavigation(tempNavigation);
  };

  return (
    <>
      <Card
        className={cn(styles.explore)}
        title={t("HOT_WINE_REGIONS_OF_THE_WORLD")}
        classTitle="title-red"
      >
        <div className={styles.sliderContainer}>
          {onLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "20px",
              }}
            >
              {Array.from({ length: 4 }, (_, index) => index + 1).map(() => {
                return <Skeleton.Image active={true} />;
              })}
            </div>
          )}
          {!onLoading && (
            <Slider {...settings}>
              {promoteList.map((x, index) => (
                <div
                  className={cn(styles.imgWrapper, {
                    [styles.imgWrapperActive]: index === activePromoteIndex,
                  })}
                  key={index}
                  onClick={onSliderItemClick.bind(this, x, index)}
                >
                  <div className={styles.gradient}/>
                  <div className={styles.imgInfo}>
                    <span>{x.label}<br/>
                    {x.parentItem?.label}</span>
                  </div>
                  <div className={styles.preview}>
                    <img src={localizeImg(x.image)} alt="Gallery" />
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </Card>
      <div className={styles.control}>
        <div className={[styles.navigationBar]}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              style={{ position: "relative" }}
              onClick={onNavigationItemClick.bind(this, index)}
              key={index}
            >
              <span>{x.label}</span>
              {index == activeIndex && (
                <span
                  className={styles.clearNavigationFilter}
                  onClick={(event) =>
                    handleClearNavigationFilter(event, index)
                  }
                >
                  x
                </span>
              )}
            </button>
          ))}
        </div>
        <Filters className={styles.filters} title={t("MORE_FILTER")}>
          <FilterPanel
            onConfirm={onSearchConfirm.bind(this)}
            onReset={onResetConfirm.bind(this)}
            filterRegion={selectedRegion}
          />
        </Filters>
      </div>
      <div className={styles.wrap}>
        {onProductLoading && (
          <div className={styles.products}>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </div>
        )}
        {!onProductLoading && (
          <div className={styles.products}>
            {productList.map((x, index) => (
              <Product
                className={styles.product}
                item={x}
                key={index}
                withoutСheckbox
              />
            ))}
          </div>
        )}
      </div>
      <CountryModal
        isShow={showCountryFilterModal}
        onClose={() => setShowCountryFilterModal(false)}
        onChange={onFilterModelChange.bind(this, "country")}
      />
      <RegionModal
        isShow={showRegionFilterModal}
        filterCountry={selectedCountry}
        onClose={() => setShowRegionFilterModal(false)}
        onChange={onFilterModelChange.bind(this, "region")}
      />
      <CategoryModal
        isShow={showCategoryFilterModal}
        onClose={() => setShowCategoryFilterModal(false)}
        onChange={onFilterModelChange.bind(this, "types")}
      />
      <StockModal
        isShow={showStockFilterModal}
        onClose={() => setShowStockFilterModal(false)}
        onChange={onFilterModelChange.bind(this, "stock")}
      />
      <PriceModal
        isShow={showPriceFilterModal}
        onClose={() => setShowPriceFilterModal(false)}
        onChange={onFilterPriceChange.bind(this)}
      />
    </>
  );
}
