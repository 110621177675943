import React, { useEffect, useState } from "react";
import "./style/userItem.less";
import { useHistory } from "react-router-dom";
import { localizeImg, localizeValue } from "../../constants/constants";
import { goToProfilePage } from "../../utils/utils";
import AvatarBasic from "../common/avatar/AvatarBasic";
import { useTranslation } from "react-i18next";

export default function CompanyItemNormal(props) {
  const history = useHistory();
  const { detail } = props;
  const { i18n } = useTranslation();
  const [companyInfo, setCompanyInfo] = useState(detail);

  useEffect(() => {
    let info = detail;
    info.companyName =
      localizeValue(i18n.language, info.companyName) || info.displayName;
    setCompanyInfo(info);
  }, [detail]);

  const onCompanyHome = () => {
    const userItem = {
      _id: detail.coId,
      type: detail.type,
    };
    goToProfilePage(userItem, history);
  };

  return (
    <div
      className="user-item-container cursor-pointer"
      onClick={onCompanyHome.bind(this)}
    >
      <AvatarBasic
        cname="avatar"
        picture={localizeImg(companyInfo?.avatar)}
        displayName={
          localizeValue(i18n.language, companyInfo?.companyName) || "LO"
        }
      ></AvatarBasic>
      <div className="content">
        <div className="content-name">
          <div className="name">
            {localizeValue(i18n.language, companyInfo?.companyName)}
          </div>
          {/* <div className="desc">{detail?.signature}</div> */}
        </div>
      </div>
    </div>
  );
}
