import { Breadcrumb } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function BreadcrumbNormal(props) {
  let { title, parent } = props;
  const { t } = useTranslation();
  return (
    <Breadcrumb style={{ margin: "16px 0" }}>
      <Breadcrumb.Item>
        <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
      </Breadcrumb.Item>
      {parent &&
        <Breadcrumb.Item>{parent}</Breadcrumb.Item>
      }
      <Breadcrumb.Item>{title}</Breadcrumb.Item>
    </Breadcrumb>
  );
}
