import React from "react";
import { Button, Breadcrumb } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toastInfo } from "../../api/toast";
import { closeAccount } from "../../api/user";

export default function CloseAccount() {
  const { t } = useTranslation();

  function handleClick() {
    toastInfo("CLOSE_ACCOUNT_SEND");
    closeAccount();
  }

  return (
    <div>
        <p>{t("CLOSE_ACCOUNT_MESSAGE")}</p>
        <div className="close-button">
          <Button
            type="default"
            className={"button-primary"}
            onClick={handleClick}
          >
            <div className="close-button">
              {t("CONFIRM_AND_CLOSE_MY_ACCOUNT")}
            </div>
          </Button>
        </div>
    </div>
  );
}
