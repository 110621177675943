import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Table,
  Modal,
  Tabs,
  message,
  Breadcrumb,
  notification,
  Badge,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "./style/products.less";
import ProductConnect from "./ProductConnect";
import { useUserId } from "../../../contexts/UserIdProvider";
import { deepClone } from "../../../utils/utils";
import {
  productRemove,
  productUpdateStatus,
  loadProducts as reqProducts,
  loadProductConfig,
  getProductConfigNameById,
} from "../../../api/product";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { ProductStatus, statusCode } from "../../../constants/statusCode.enum";
import { dateFormat } from "../../../utils/date";
import {
  localizeTitle,
  ProductTableMaxCount,
} from "../../../constants/constants";
import { getProductStatusText } from "../../../api/common";
import { toastInfo } from "../../../api/toast";

export default function Products() {
  const { i18n, t } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();
  const { state } = useLocation();
  const { TabPane } = Tabs;
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [productConnectModalVisible, setProductConnectModalVisible] =
    useState(false);
  let [products, setProducts] = useState([]);
  let [onSaleProducts, setOnSaleProducts] = useState([]);
  let [selectedProductId, setSelectedProductId] = useState("");
  const [productConfig, setProductConfig] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(state&&state.jump){
      history.push(state.jump);
    }
  }, []);

  useEffect(() => {
    initProducts();
    initProductConfig();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      let onSalePro = products.filter(
        (item) => item.status === ProductStatus.STOCK
      );
      setOnSaleProducts(onSalePro);
    }
  }, [products]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    initProducts();
    initProductConfig();
  }, [currentLanguage]);
  const initProductConfig = async () => {
    let configData = await loadProductConfig();
    setProductConfig(configData);
    return configData;
  };

  const formatProductType = async (val) => {
    let configData = productConfig ? productConfig : await initProductConfig();
    let { types } = configData;
    return getProductConfigNameById(types, currentLanguage, val);
  };

  const initProducts = async () => {
    setLoading(true);
    let pms = {
      page: 0,
      per_page: ProductTableMaxCount,
      coId: user.userInfo.companyId,
    };
    let result = await reqProducts(pms);
    console.log("-----products:", result, pms);
    if (result && result.length > 0) {
      // result = result.map((item) => item._source);
      for (let i = 0; i < result.length; i++) {
        result[i].key = "product" + i;
        if (result[i].id) {
          result[i]._id = result[i].id;
        } else {
          result[i].id = result[i]._id;
        }
        result[i].createdAt = result[i].createdAt
          ? dateFormat(result[i].createdAt, "yyyy-MM-dd")
          : "-";
        result[i].statusText = t(getProductStatusText(result[i].status));
        result[i].typeName = await formatProductType(result[i].types);
      }
      result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setProducts(result);
    }
    setLoading(false);
  };

  const onDeleteProduct = async (id) => {
    Modal.confirm({
      title: t("CONFIRM_DELETE"),
      icon: <ExclamationCircleOutlined />,
      content: t("ARE_YOU_SURE_TO_DELETE_THIS"),
      okText: t("CONFIRM"),
      cancelText: t("CANCEL"),
      onOk: async () => {
        let pms = { id: id, lng: i18n.language };
        let data = await productRemove(pms);
        if (data && data.statusCode === statusCode.SUCCESS) {
          toastInfo(t("success"));
          let list = deepClone(products);
          list = list.filter(
            (item) =>
              (item.id && item.id !== id) || (item._id && item._id !== id)
          );
          setProducts(list);
        } else {
          message.error(t("fail"));
        }
      },
    });
  };

  const onUpdateProductStatus = async (id, status) => {
    let pms = {
      _id: id,
      status: status,
    };

    let data = await productUpdateStatus(pms);
    if (data) {
      let list = deepClone(products);
      list = list.map((item) => {
        if (item.id === id || item._id === id) {
          item.status = status;
          item.statusText = t(getProductStatusText(status));
        }
        return item;
      });
      setProducts(list);
      toastInfo(t("success"));
    }
  };

  const onProductConnectClose = () => {
    setProductConnectModalVisible(false);
  };

  const onNewProduct = () => {
    history.push("/shop/products/create-deprecated");
  };

  const toProductDetail = (item) => {
    history.push("/products/detail/" + (item.id || item._id));
  };

  const onBindVintage = (id) => {
    setSelectedProductId(id);
    setProductConnectModalVisible(true);
  };

  const onEditProduct = (item) => {
    // history.push("/shop/products/create", { sourceProductIds: item.languages });
    // console.log("-----edit item:", item);
    history.push("/shop/products/create-deprecated", { productId: item.id || item._id });
  };

  const openNotification = (content) => {
    content = content ? content : t("THERE_IS_NO_DATA_HERE");
    notification["warning"]({
      message: t("VERIFY_RESULT"),
      description: content,
    });
  };

  const columns = [
    {
      title: t("PRODUCT_NAME"),
      dataIndex: "title",
      key: "title",
      width: "80px",
      render: (val) => {
        return (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "80px",
            }}
          >
            {localizeTitle(currentLanguage, val)}
          </div>
        );
      },
    },
    {
      title: t("YEAR"),
      dataIndex: "year",
      key: "year",
    },
    {
      title: t("PRICE_MIN"),
      dataIndex: "volumes",
      key: "volumes",
      render: (val, record) => {
        if (val && val.length > 0) {
          return (
            <div>
              {val[0].price} {val[0].currency}
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: t("PRICE_MAX"),
      dataIndex: "volumes",
      key: "volumes",
      render: (val, record) => {
        if (val && val.length > 0) {
          return (
            <div>
              {val[val.length - 1].price} {val[val.length - 1].currency}
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: t("SHIPPING_ADDRESS"),
      dataIndex: "volumes",
      key: "volumes",
      render: (val, record) => {
        return (
          <div>
            {val.map((valItem) => {
              return <div>{valItem.shippingAddress}</div>;
            })}
          </div>
        );
      },
    },
    {
      title: t("STOCK_AVAILABLE"),
      dataIndex: "volumes",
      key: "volumes",
      render: (val, record) => {
        let availableStock = 0;
        if (val && val.length > 0) {
          val.map((valItem) => {
            availableStock += parseInt(valItem.stock);
          });
        }
        return (
          <div>
            {availableStock}
            {t("BOTTLES")}
          </div>
        );
      },
    },
    {
      title: t("SOLD_VOLUME"),
      dataIndex: "volumes",
      key: "volumes",
      render: (val, record) => {
        return <div>0{t("BOTTLES")}</div>;
      },
    },
    // {
    //   title: t("TYPE"),
    //   dataIndex: "typeName",
    //   key: "typeName",
    // },
    {
      title: t("CREATE_AT"),
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: t("PRODUCT_STATUS"),
      dataIndex: "statusText",
      key: "statusText",
      // filters: [
      //   { text: t("ON_SALE"), value: ProductStatus.STOCK },
      //   { text: t("OFF_SALE"), value: ProductStatus.UNSTOCK },
      // ],
      onFilter: (value, record) => record.status === value,
      render: (val, record) => {
        if (record.status === ProductStatus.VERIFYFAIL) {
          return (
            <Badge dot={true} size="default">
              <span
                className="cursor-pointer"
                onClick={openNotification.bind(this, record.reason)}
              >
                {val}
              </span>
            </Badge>
          );
        } else {
          return val;
        }
      },
    },
    {
      title: t("ACTION"),
      dataIndex: "action",
      key: "action",
      // width: 280,
      align: "center",
      render: (text, record) => getActionColumn(text, record),
    },
  ];

  const getActionColumn = (text, record) => {
    let id = record.id || record._id;
    return (
      <div className="table-action">
        <div className="table-action-row">
          <span onClick={toProductDetail.bind(this, record)}>
            {t("DETAIL")}
          </span>
          {record.status == ProductStatus.STOCK && (
            <>
              <span className="table-action-item-split">-</span>
              <span
                onClick={onUpdateProductStatus.bind(
                  this,
                  id,
                  ProductStatus.UNSTOCK
                )}
              >
                {t("OFF_SALE")}
              </span>
            </>
          )}
          {(record.status === ProductStatus.VERIFYSUCCESS ||
            record.status === ProductStatus.UNSTOCK) && (
            <>
              <span className="table-action-item-split">-</span>
              <span
                onClick={onUpdateProductStatus.bind(
                  this,
                  id,
                  ProductStatus.STOCK
                )}
              >
                {t("ON_SALE")}
              </span>
            </>
          )}
        </div>
        <div className="table-action-row">
          <span onClick={onEditProduct.bind(this, record)}>{t("EDIT")}</span>
          <span className="table-action-item-split">-</span>
          <span onClick={onDeleteProduct.bind(this, id)}>{t("DELETE")}</span>
          {/* {products.length > 1 && (
            <>
              <span className="table-action-item-split">-</span>
              <span onClick={onBindVintage.bind(this, id)}>
                {t("BIND_PRODUCTS")}
              </span>
            </>
          )} */}
        </div>
      </div>
    );
  };

  const stepStock = [
    {
      target:
        ".products-management .products-tabs .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn ",
      content: t("THIS_IS_YOUR_STOCK_MANAGEMENT"),
    },
    {
      target: ".stock-create-button",
      content: t("FIRST_YOU_NEED_TO_CREATE_A_WAREHOUSE"),
    },
    {
      target: ".house-item",
      content: t("THEN_YOU_NEED_TO_SELECT_YOUR_WAREHOUSE"),
    },
    {
      target: ".house-actions",
      content: t("THEN_YOU_JUST_NEED_TO_ADD_YOUR_PRODUCT_STOCK"),
    },
  ];

  return (
    <div className="common-page">
      <div className="common-management products-management normal-shadow">
        {/* <Tabs defaultActiveKey="1" className="products-tabs my-first-step common-management-panel">
          <TabPane tab={t("ALL_PRODUCTS")} key="1"> */}
        <Table
          dataSource={products}
          columns={columns}
          className="products-table"
          loading={loading}
          pagination={false}
        />
        <div className="product-create-panel">
          <Button onClick={onNewProduct.bind(this)} className="create-button">
            <span className="add-icon">+</span>
            {t("CREATE_PRODUCT")}
          </Button>
        </div>
        {/* </TabPane>
          <TabPane tab={t("PRODUCT_AVAILABLE")} key="2">
            <Table
              dataSource={onSaleProducts}
              columns={columns}
              className="products-table"
            />
          </TabPane>
        </Tabs> */}

        <Modal
          title={null}
          visible={productConnectModalVisible}
          footer={null}
          className="product-connect-modal"
          onCancel={() => setProductConnectModalVisible(false)}
          destroyOnClose={true}
        >
          <ProductConnect
            products={products}
            productId={selectedProductId}
            onClose={onProductConnectClose.bind(this)}
          />
        </Modal>
      </div>
    </div>
  );
}
