import React, { useEffect, useState } from "react";
import { Breadcrumb, Skeleton, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { Link, useHistory } from "react-router-dom";
import ListBasic from "../../common/list/ListBasic";
import "../product/style/products.less";
import { getPageFavorites } from "../../../api/favorite";
import ProductListItem from "../product/ProductListItem";
import { deepClone } from "../../../utils/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  localizeTitle,
  ProductTableMaxCount,
} from "../../../constants/constants";
import {
  getProductConfigNameById,
  loadProductConfig,
} from "../../../api/product";
import { getCountryNameById, getLocationNameById } from "../../../api/config";

export default function FavoriteProducts() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user } = useUserId();
  const [productList, setProductList] = useState([]);
  const pageSize = 20;
  const [pageIndex, setPageIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [productConfig, setProductConfig] = useState();

  useEffect(() => {
    setCurrentLanguage(i18n.language);
    initProductConfig();
  }, [i18n.language]);

  useEffect(() => {
    loadData();
  }, [pageIndex]);

  const initProductConfig = async () => {
    let configData = await loadProductConfig();
    setProductConfig(configData);
    return configData;
  };

  const loadData = async () => {
    setLoading(true);
    let pms = {
      coId: user.userInfo.companyId,
      page: pageIndex,
      per_page: ProductTableMaxCount,
    };
    let result = await getPageFavorites(pms);
    console.log("----favorite list:", result, pms);
    if (result) {
      result = result
        .filter((item) => item.productInfo && item.productInfo.length > 0)
        .map((item) => {
          return { ...item.productInfo[0] };
        });
      for (let i = 0; i < result.length; i++) {
        result[i].key = "product" + i;
        if (result[i].id) {
          result[i]._id = result[i].id;
        } else {
          result[i].id = result[i]._id;
        }
        result[i] = await formatProductAttr(result[i]);
      }
      if (pageIndex > 0) {
        let list = deepClone(productList);
        result = list.concat(result);
      }
      setProductList(result);
    } else {
      setHasMore(false);
    }

    setLoading(false);
  };

  const formatProductAttr = async (item) => {
    let configData = productConfig ? productConfig : await initProductConfig();
    let { types, locations, classifications } = configData;
    item.typeName = getProductConfigNameById(
      types,
      currentLanguage,
      item.types
    );
    item.countryName = getCountryNameById(
      locations,
      currentLanguage,
      item.country
    );
    item.classificationName = getProductConfigNameById(
      classifications,
      currentLanguage,
      item.classifications
    );
    return item;
  };

  const columns = [
    {
      title: t("PRODUCT_NAME"),
      dataIndex: "title",
      key: "title",
      render: (val) => {
        return localizeTitle(currentLanguage, val);
      },
      width: '30%'
    },
    {
      title: t("COUNTRY"),
      dataIndex: "countryName",
      key: "countryName",
    },
    {
      title: t("TYPE"),
      dataIndex: "typeName",
      key: "typeName",
    },
    {
      title: t("CLASSIFICATIONS"),
      dataIndex: "classificationName",
      key: "classificationName",
    },
    {
      title: t("YEAR"),
      dataIndex: "year",
      key: "year",
    },
    {
      title: t("ACTION"),
      dataIndex: "action",
      key: "action",
      // width: 280,
      align: "center",
      render: (text, record) => getActionColumn(text, record),
    },
  ];

  const toProductDetail = (item) => {
    history.push("/products/detail/" + (item.id || item._id));
  };

  const toIMchat = (item) => {
    history.push("/imchat-deprecated", { userId: item.userId });
  };

  const getActionColumn = (text, record) => {
    let id = record.id || record._id;
    return (
      <div className="table-action">
        <div className="table-action-row">
          <span onClick={toIMchat.bind(this, record)}>
            {t("CONTACT_THE_SELLER")}
          </span>
        </div>
        <div className="table-action-row">
          <span onClick={toProductDetail.bind(this, record)}>
            {t("PRODUCT_DETAIL")}
          </span>
        </div>
      </div>
    );
  };

  const onRenderOrderItem = (item, index) => {
    return <ProductListItem item={item} index={index} />;
  };

  /**
   * scroll load
   */
  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("MY_SHOP")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("FAVORITE_PRODUCTS")}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="common-management products-management">
        <div className="orders-title common-management-title">
          {t("FAVORITE_PRODUCTS")}
        </div>

        <div className="margin-top-10 common-management-panel">
          <Table
            dataSource={productList}
            columns={columns}
            className="products-table"
            loading={loading}
          />
        </div>
        {/* <InfiniteScroll
          className="scroll-content"
          dataLength={productList.length}
          next={handleInfiniteOnLoad.bind(this)}
          hasMore={hasMore}
        >
          <ListBasic
            data={productList}
            renderItem={onRenderOrderItem.bind(this)}
            loading={loading}
          />
        </InfiniteScroll> */}
      </div>
    </div>
  );
}
