import React, { useEffect, useState } from "react";
import styles from "./RegionModal.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";

import { numberWithCommas } from "../../../core-digital-template/utils.js";
import { useTranslation } from "react-i18next";
import {
  getProductCategoryTypeForSelect,
  getProductConfigForSelect,
  loadProductConfig,
} from "../../../api/product";
import { deepClone } from "../../../utils/utils";
import { Modal } from "antd";
import {
  getCountryAndCityForSelect,
  getLocationsByParentId,
} from "../../../api/config";

const RegionModal = ({ isShow, filterCountry, onClose, onChange }) => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [countryList, setCountryList] = useState([]);
  const [regionList, setRegionList] = useState([]);

  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    setConfigData();
  }, []);

  useEffect(() => {
    if (countryList && countryList.length > 0) {
      if (filterCountry) {
        let ids = [filterCountry?.value];
        onLoadAllRegionList(ids);
      } else {
        let ids = [];
        countryList.map((item) => {
          ids.push(item.value);
        });
        onLoadAllRegionList(ids);
      }
    }
  }, [countryList, filterCountry]);

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocationList(locations);
    setCountryList(getCountryAndCityForSelect(locations, currentLanguage));
  };

  const onRegionChange = (event, itemIndex) => {
    console.log(itemIndex, regionList);
    let temp = deepClone(regionList);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[itemIndex].selected = true;
    setRegionList(temp);
    onChange && onChange(temp[itemIndex]);
    onClose && onClose();
  };

  const onLoadAllRegionList = (coutry_vals) => {
    let temp = deepClone(regionList);
    temp = [];
    coutry_vals.map((coutry_val) => {
      let result = getLocationsByParentId(
        locationList,
        coutry_val,
        currentLanguage
      );
      temp.push(...result);
    });
    console.log(temp);
    setRegionList(temp);
  };

  return (
    <Modal
      visible={isShow}
      footer={null}
      onCancel={onClose}
      destroyOnClose={true}
    >
      <div className={styles.distributionChannelSelectModal}>
        <div className={styles.distributionChannelPanel}>
          {regionList.map((mapItem, mapIndex) => {
            return (
              <div
                className={
                  mapItem.selected
                    ? styles.channelItemSelected
                    : styles.channelItem
                }
                onClick={onRegionChange.bind(this, mapItem, mapIndex)}
              >
                {mapItem.label}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default RegionModal;
