import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    imToken: '',
}


const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        //Reducer comes here
    },
    extraReducers:{
        //extra reducer come here
    }
})


export default chatSlice.reducer
