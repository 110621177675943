import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../../../core-digital-template/components/Card";
import Icon from "../../../../../core-digital-template/components/Icon";
import Editor from "../../../../../core-digital-template/components/Editor";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { deepClone } from "../../../../../utils/utils";

export default function NameAndDescription(props) {
  const { className, onChange, onClose, dataSource } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();

  const [formData, setFormData] = useState({});

  const languageNavigation = [
    { label: t("LANGUAGE_FRENCH"), key: "fr" },
    { label: t("LANGUAGE_CHINESE"), key: "zh" },
    { label: t("LANGUAGE_ENGLISH"), key: "en" },
  ];
  const [descActiveTab, setDescActiveTab] = useState(languageNavigation[0]);
  const [desc, setDesc] = useState("");

  useEffect(() => {
    if (dataSource) {
      let temp = deepClone(formData);
      temp = dataSource;
      setFormData(temp);

      setTimeout(() => {
        setDesc(temp.desc);
      }, 1000);
    }
  }, [dataSource]);

  useEffect(() => {
    if (formData) {
      setDesc(formData.desc);
    }
  }, [descActiveTab]);

  const setContentFormDataValue = (value) => {
    if (value == "<p></p>") {
      return;
    }

    let temp = deepClone(formData);
    if (!temp["desc"]) {
      temp["desc"] = {};
    }
    temp["desc"]["source"] = value;
    setFormData(temp);
    onChange && onChange(temp);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={t("ABOUT_US")}
        classTitle="title-red"
        head={
          <Link
            className={cn("button-small", styles.button)}
            onClick={() => onClose && onClose()}
          >
            <Icon name="arrow-left" size="24" />
            <span>{t("CLOSE")}</span>
          </Link>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              {/* {languageNavigation.map((x, index) => (
                <div>
                  {x.key === descActiveTab.key && ( */}
              <Editor
                state={desc?.["source"]}
                onChange={setContentFormDataValue.bind(this)}
                classEditor={styles.editor}
                label={
                  <div className={styles.labelWithTranslations}>
                    <span>{t("ABOUT_COMPANY")}</span>
                  </div>
                }
                //tooltip={t("ABOUT_COMPANY_TIPS")}
              />
              {/* )}
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
