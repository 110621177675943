import { getCache } from "../utils/cache";
import { ITokens } from "../interfaces/IUser";
import apiPath from "./apipath";
import { objectToUrlParams } from "../utils/utils";
import { RequestType } from "../constants/requestType.enum";
import { actionRequest, baseURL } from "./api";
import { getTimeStamp } from "../utils/date";
import { UploadFileType } from "../constants/constants";
import { UserType } from "../constants/usersType.enum";
import { SysEnvLanguageType } from "../constants/env.enum";
import { CompanyVerifyStatusEnum } from "../constants/companyVerifyStatus.enum";
import {
  companyVerifyTips,
  editCompanyInfoTips,
  subscriptionTips,
} from "./toast";
import { ProductStatus, VerifyStatusText } from "../constants/statusCode.enum";
import { getCompanyVerifyStatus } from "./user";
import { openRequest } from "./openApi";

export function authValidate() {
  let validate = false;
  let tokenCache: ITokens = getCache("tokens");
  if (tokenCache) {
    let currentTime = getTimeStamp();
    validate = currentTime < tokenCache.expireTime;
  }
  return validate;
}

/**
 *
 * @param userId
 */
export function getUploadFileAction(userId: string) {
  let pms = { userId };

  return requestGetUrlFormat(baseURL + apiPath.uploadFile, pms);
}

/**
 *
 * @param userId
 */
export function getCloudflareFileAction(userId: string) {
  let pms = { userId };

  return requestGetUrlFormat(baseURL + apiPath.uploadToCloudflare, pms);
}

/**
 *
 * @param userId
 */
export function getOpenUploadFileAction(userId: string) {
  let pms = { userId };

  return requestGetUrlFormat(baseURL + apiPath.openuploadFile, pms);
}

/**
 *
 * @param userId
 */
export function getEditorUploadFileAction(userId: string) {
  let pms = { userId };

  return requestGetUrlFormat(baseURL + apiPath.editorupload, pms);
}

/**
 *
 * @param data
 */
export function formatUploadFileFormValues(data: any) {
  let result = [];
  if (data && data.length > 0) {
    result = data.map((item: any) => {
      return item.response.result;
    });
  }
  return result;
}

/**
 * Get请求路径拼接
 * @param url
 * @param pms
 * @param 例如：user/0101001
 * @returns
 */
export function requestGetUrlFormat(url: string, pms: any, urlPart?: string) {
  if (urlPart) {
    url += "/" + urlPart;
  }
  if (pms) {
    url += "?" + objectToUrlParams(pms);
  }
  return url;
}

/**
 *
 * @param pms
 * @param files
 */
export function atUploadFile(pms: any, files: any) {
  return actionRequest(
    RequestType.FILE,
    requestGetUrlFormat(apiPath.uploadFile, pms),
    files
  );
}

/**
 *
 * @param data
 */
export function formatDefaultArrayForSelect(data: any) {
  return data.map((item: any) => ({ label: item, value: item }));
}

/**
 *
 * @param data
 */
export function formatWineCategoryArrayForSelect(data: any) {
  return data.map((item: any) => ({ label: item.value, value: item.value }));
}

export function getFileTypeByPath(path: any) {
  let result = UploadFileType.IMAGE;
  if (typeof path === "string") {
    let str = path.substring(path.lastIndexOf(".") + 1, path.length);
    let images = ["png", "jpg", "jpeg", "svg"];
    let videos = ["mp4", "rmvb", "wav", "mov"];
    if (images.includes(str)) {
      result = UploadFileType.IMAGE;
    } else if (videos.includes(str)) {
      result = UploadFileType.VIDEO;
    }
  }
  return result;
}

export const getFileType = (type: string) => {
  if (type.indexOf("image") > -1) {
    return UploadFileType.IMAGE;
  } else if (type.indexOf("video") > -1) {
    return UploadFileType.VIDEO;
  } else {
    return UploadFileType.FILE;
  }
};

export function hasTheLimit(userInfo: any, limit: string) {
  if (userInfo?.roleInfo && userInfo.roleInfo.length > 0) {
    let user_limits = userInfo.roleInfo[0].limits;
    if (user_limits.indexOf(limit) >= 0) {
      return true;
    }
  }
  return false;
}

export function formatKeyword(title: string, keyword: string) {
  title = title.replace(
    keyword,
    '<span class="keyword-label">' + keyword + "</span>"
  );
  return title;
}

export function formatUserType(type: string) {
  let result = type;
  switch (type) {
    case UserType.SUPPLIER:
      result = UserType.SUPPLIER;
      break;
    case UserType.BUYER:
      result = UserType.BUYER;
      break;
    case UserType.SUPPLIERBUYER:
      result = UserType.SUPPLIERBUYER;
      break;
    default:
      break;
  }
  return result;
}

export function getTransLanguage(user_lang: string) {
  let language = user_lang;
  if (language === SysEnvLanguageType.ZH) {
    language = SysEnvLanguageType.ZH_Hans;
  }
  return language;
}

export function checkCompanyVerifyAndSubscribe(currentUser: any, history: any) {
  let companyVerifyStatus = getCompanyVerifyStatus(currentUser);
  if (companyVerifyStatus !== CompanyVerifyStatusEnum.VERIFIED) {
    let msg = "YOU_NEED_TO_VERIFY_YOUR_COMPANY_FIRST";
    switch (companyVerifyStatus) {
      case CompanyVerifyStatusEnum.WAITING_VERIFY:
        msg = "YOUR_COMPANY_IS_BEING_VERIFYING";
        break;
      case CompanyVerifyStatusEnum.VERIFY_FAILED:
        msg = "YOUR_COMPANY_VERIFY_WAS_FAILED";
        break;
      case CompanyVerifyStatusEnum.VERIFIED_OUT_OF_DATE:
        msg = "YOUR_COMPANY_VERIFY_WAS_OUT_OF_DATE";
        break;
      default:
        break;
    }
    companyVerifyTips(msg, history);
    return false;
  }

  // TO DO:临时关闭订阅检查
  // if (currentUser.type != UserType.BUYER && !currentUser.subscription) {
  //   subscriptionTips("YOU_NEED_TO_SUBSCRIBE_FIRST", history);
  //   return false;
  // }
  return true;
}

export function checkUserLimit(limit: any, history: any) {
  if (!limit) {
    companyVerifyTips("YOU_NEED_TO_HAVE_LIMIT_FIRST", history);
    return false;
  }
  return true;
}

export function checkAllLimit(limit: any, currentUser: any, history: any) {
  let result = checkCompanyVerifyAndSubscribe(currentUser, history);
  if (result && checkUserLimit(limit, history)) {
    return true;
  }
  return false;
}

export function checkLimit(userInfo: any, operation: string, history: any) {
  if (!authValidate()) {
    history.push("/login");
    return;
  }

  if (!userInfo || !userInfo?.companyDesc?.label) {
    editCompanyInfoTips("YOU_NEED_TO_COMPLETE_COMPANY_INFO", history);
    return false;
  }

  let result = checkCompanyVerifyAndSubscribe(userInfo, history);
  let roleLimit = hasTheLimit(userInfo, operation);
  if (result && checkUserLimit(roleLimit, history)) {
    return true;
  }
  return false;
}

export function getProductStatusText(status: any) {
  let result = VerifyStatusText.VERIFYWAITING;
  //处理产品状态
  switch (Number(status)) {
    case ProductStatus.VERIFYWAITING:
      result = VerifyStatusText.VERIFYWAITING;
      break;
    case ProductStatus.VERIFYSUCCESS:
      result = VerifyStatusText.VERIFYSUCCESS;
      break;
    case ProductStatus.VERIFYFAIL:
      result = VerifyStatusText.VERIFYFAIL;
      break;
    case ProductStatus.STOCK:
      result = VerifyStatusText.STOCK;
      break;
    case ProductStatus.UNSTOCK:
      result = VerifyStatusText.UNSTOCK;
      break;
    case ProductStatus.DRAFT:
      result = VerifyStatusText.DRAFT;
      break;
    default:
      break;
  }
  return result;
}

export function getCompanyVerifyStatusText(status: any) {
  let result = VerifyStatusText.VERIFYWAITING;
  switch (Number(status)) {
    case CompanyVerifyStatusEnum.WAITING_VERIFY:
      result = VerifyStatusText.VERIFYWAITING;
      break;
    case CompanyVerifyStatusEnum.VERIFIED:
      result = VerifyStatusText.VERIFYSUCCESS;
      break;
    case CompanyVerifyStatusEnum.VERIFY_FAILED:
      result = VerifyStatusText.VERIFYFAIL;
      break;
    default:
      break;
  }
  return result;
}

export function formatPhone(phone: any) {
  let result = "";
  if (phone) {
    if (typeof phone === "string") {
      result = phone;
    } else {
      result = "+" + phone.code + " " + phone.phone;
    }
  }
  return result;
}

/**
 *
 * @param keyword
 */
export async function searchAddressByAMap(keyword: string) {
  return await openRequest(apiPath.amapSearch, { keywords: keyword });
}
