import React, { useState, useEffect } from "react";
import {
  Divider,
  Button,
  message,
  Modal,
  DatePicker,
  Input,
  Dropdown,
  Menu,
} from "antd";
import {
  getExchangeRate,
  getProductConfigNameById,
  loadProductConfig,
  productDetail,
} from "../../../../api/product";
import {
  getOrderBills,
  orderCreate,
  removeOrderBill,
} from "../../../../api/orders";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { OrderedListOutlined, PlusOutlined } from "@ant-design/icons";
import "./style/orderConfirmPanel.less";
import { useParams } from "react-router-dom";
import { deepClone } from "../../../../utils/utils";
import { recipientRemove, getRecipients } from "../../../../api/user";
import { cartRemove } from "../../../../api/carts";
import { statusCode } from "../../../../constants/statusCode.enum";
import { useTranslation } from "react-i18next";
import { getNativePayCode } from "../../../../api/wxPay";
import { useHistory } from "react-router-dom";
import { getAliPayForm } from "../../../../api/aliPay";
import AddressCreate from "../../../company/address/AddressCreate";
import { toastError } from "../../../../api/toast";
import InputCountBasic from "../../../common/input/InputCountBasic";
import {
  getCountryNameById,
  getChinaCityNameById,
} from "../../../../api/config";
import {
  discountProductDetail,
  getDiscountDetail,
} from "../../../../api/discounts";
import { discountType } from "../../../../constants/discountType.enum";
import {
  ExchangeRateType,
  localizeImg,
  localizePriceUnit,
  localizeTitle,
  PayType,
  ProductConfig,
  ProductCurrencyType,
  ProductSampleValue,
} from "../../../../constants/constants";
import moment from "moment";
import { SYS_ENV_PRODUCT_DEPOSIT } from "../../../../constants/env.enum";
import InvoiceCreate from "../../../company/invoice/InvoiceCreate";
import { getOrderNumber, getTimeStamp } from "../../../../utils/date";
import { formatPhone } from "../../../../api/common";
import { toastInfo } from "../../../../api/toast";
import BankTransfer from "../bankTransfer/BankTransfer";
import { warehouses } from "../../../../api/warehouse";
import rightArrowIcon from "../../../../resource/icons/shop/order/right_arrow.svg";
import { companyDetail } from "../../../../api/company";

export default function OrderConfirmPanel(props) {
  const { orderData } = props;
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { params } = useParams();
  const { user } = useUserId();
  const { userId, userInfo } = user;
  const [orgOrderBills, setOrgOrderBills] = useState([]);
  const [orderBills, setOrderBills] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [products, setProducts] = useState([]);
  const [groupProducts, setGroupProducts] = useState([]);
  const [productConfigs, setProductConfigs] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [payPrice, setPayPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [aliPayForm, setAliPayForm] = useState();
  const [orderIds, setOrderIds] = useState([]);
  const [inloading, setInloading] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [createInvoiceModalVisible, setCreateInvoiceModalVisible] =
    useState(false);
  const [addressDetail, setAddressDetail] = useState();
  const [invoiceDetail, setInvoiceDetail] = useState();
  const [pickDate, setPickDate] = useState();
  const [pickService, setPickService] = useState(false);
  const [billType, setBillType] = useState(0);
  const [companyName, setCompanyName] = useState();
  const [taxCode, setTaxCode] = useState();
  const [companyAddress, setCompanyAddress] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [bankAccount, setBankAccount] = useState();
  const [bankName, setBankName] = useState();
  const [billContent, setBillContent] = useState();
  const [bankTransferModalVisible, setBankTransferModalVisible] =
    useState(false);
  const [firstPaymentVouchers, setFirstPaymentVouchers] = useState([]);

  const [orderModel, setOrderModel] = useState({});
  const [warehouseList, setWarehouseList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [companyInvoices, setCompanyInvoices] = useState({});

  useEffect(() => {
    loadOrderBills();
    loadRecipients();
    setConfigData();
    loadOrderRecipientInfo();
    initConfigData();
  }, []);

  useEffect(() => {
    console.log("-------------------------orderModel", orderModel);
  }, [orderModel]);

  useEffect(() => {
    if (productConfigs) {
      const orderParams = orderData;
      if (Array.isArray(orderParams)) {
        if (orderParams[0].orderId) {
          let temparr = [];
          orderParams.map((item) => {
            temparr.push(item.orderId);
          });
          setOrderIds(temparr);
        }
        loadProductDetails(orderParams);
        // console.log('------order pms:', orderParams);
      }
    }
  }, [productConfigs, i18n.language]);

  useEffect(() => {
    onGroupProductsChange();
  }, [groupProducts]);

  useEffect(() => {
    if (orgOrderBills.length > 0) {
      let list = orgOrderBills.filter((item) => item.billType === billType);
      if (list.length > 0) {
        list[0].selected = true;
        setInvoiceDetail(list[0]);
      }
      setOrderBills(list);
    }
  }, [orgOrderBills, billType]);

  const loadOrderRecipientInfo = async () => {
    let tempOrderModel = deepClone(orderModel);

    let warehousePms = { coId: user.userInfo.companyId };
    let warehouseResult = await warehouses(warehousePms);
    if (warehouseResult && warehouseResult.statusCode === statusCode.SUCCESS) {
      let list = warehouseResult.result.result;
      if (list && list.length > 0) {
        list = list.map((item, index) => {
          return { ...item, key: index };
        });
        setWarehouseList(list);
        tempOrderModel.recipient = list[0];
      }
    }

    let companyInfoResult = await companyDetail(userInfo?.companyId);

    if (companyInfoResult) {
      let item = companyInfoResult;

      let temp = deepClone(companyInvoices);
      temp.specialInvoiceInfo = {
        specialInvoiceInfo: item.specialInvoiceInfo,
        specialInvoiceDeliveryAddress: item.specialInvoiceDeliveryAddress,
      };
      temp.invoiceInfo = item.invoiceInfo;

      if (temp.specialInvoiceInfo) {
        tempOrderModel.specialInvoiceInfo = temp.specialInvoiceInfo;
        setOrderModelPro("specialInvoiceInfo");
      } else if (temp.invoiceInfo) {
        tempOrderModel.invoiceInfo = temp.invoiceInfo;
      }
    }

    console.log("-------loadOrderRecipientInfo:", tempOrderModel);
    setOrderModel(tempOrderModel);
  };

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocationList(locations);
  };

  const onGroupProductsChange = async () => {
    if (groupProducts && groupProducts.length > 0) {
      let tempTotalCount = 0;
      let tempTotalPrice = 0;
      let tempPayPrice = 0;
      let tempDiscountPrice = 0;
      for (let i = 0; i < groupProducts.length; i++) {
        let g = groupProducts[i];
        let gTotal = 0;
        let gCount = 0;
        groupProducts[i].productCount = 0;
        for (let j = 0; j < g.products.length; j++) {
          let productInfo = g.products[j];
          let { volume } = productInfo;
          let itemPrice = productInfo.count * productInfo.price;
          tempTotalCount += productInfo.count;
          itemPrice = await getExchangeRatePrice(
            itemPrice,
            productInfo.currency
          );
          tempTotalPrice += itemPrice;
          productInfo.discountPrice = getDiscountPrice(productInfo, itemPrice);
          tempDiscountPrice += productInfo.discountPrice;
          console.log("--------discountPrice:", productInfo.discountPrice);
          let itemPayPrice = itemPrice - productInfo.discountPrice;
          tempPayPrice +=
            itemPayPrice *
            (volume.volume === ProductSampleValue
              ? 1
              : SYS_ENV_PRODUCT_DEPOSIT);
          gTotal += tempTotalPrice;
          gCount += productInfo.count;
        }
        g.productCNY = gTotal.toFixed(2);
        g.productCount = gCount;
      }
      setGroupProducts(groupProducts);
      setTotalCount(tempTotalCount);
      setTotalPrice(tempTotalPrice.toFixed(2));
      setDiscountPrice(tempDiscountPrice.toFixed(2));
      setPayPrice(tempPayPrice.toFixed(2));
    }
  };

  const getExchangeRatePrice = async (itemPrice, currency) => {
    if (
      currency === ProductCurrencyType.EUR ||
      currency === ProductCurrencyType.USD
    ) {
      let pms = {
        from:
          currency === ProductCurrencyType.EUR
            ? ExchangeRateType.EUR
            : ExchangeRateType.USD,
        to: ExchangeRateType.CNY,
      };
      let rate = await getExchangeRate(pms);
      if (rate > 0) {
        itemPrice = itemPrice * rate;
      }
    }
    return itemPrice;
  };

  const loadProductDetails = async (carts) => {
    let list = [];
    let gList = [];
    for (let i = 0; i < carts.length; i++) {
      const {
        productId,
        count,
        moq,
        price,
        volume,
        discountId,
        discountPercent,
      } = carts[i];
      let pms = { id: productId, lng: i18n.language };
      let result = await productDetail(pms);
      if (result.statusCode === statusCode.SUCCESS) {
        let productInfo = result.result;
        productInfo.isSample = volume.volume === ProductSampleValue;
        productInfo.title = localizeTitle(i18n.language, productInfo.title);
        if (productInfo.isSample) {
          productInfo.title = "【" + t("SAMPLES") + "】" + productInfo.title;
        }
        productInfo.count = count;
        productInfo.moq = moq;
        productInfo.price = price;
        productInfo.volume = volume;
        if (discountId) {
          let pms = { id: discountId };
          let discount_detail = await getDiscountDetail(pms);
          if (discount_detail) {
            console.log("---------getDiscountDetail:", discount_detail);
            discount_detail.selected = true;
            productInfo.discounts = [discount_detail];
          }
        }

        productInfo.classificationName = getConfigName(
          productInfo.classifications,
          ProductConfig.CLASSIFICATIONS
        );
        productInfo.typeName = getConfigName(
          productInfo.types,
          ProductConfig.TYPES
        );
        let fList = gList.filter((item) => item.coId === productInfo.coId);
        if (fList.length === 0) {
          gList.push({
            coId: productInfo.coId,
            companyName:
              productInfo.companyInfo.displayName ||
              productInfo.companyInfo.companyName,
          });
        }
        list.push(productInfo);
      }
    }
    gList = gList.map((item) => {
      let fList = list.filter((f) => f.coId === item.coId);
      item.products = fList;
      return item;
    });
    setGroupProducts(gList);
    setProducts(list);
    console.log("------product list", gList, list);
  };

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    setProductConfigs(configData);
  };

  const getConfigName = (val, flag) => {
    let result = "";
    let {
      locations,
      types,
      bottles,
      capsules,
      sealed,
      classifications,
      categories,
      tags,
    } = productConfigs;
    switch (flag) {
      case ProductConfig.CLASSIFICATIONS:
        result = getProductConfigNameById(classifications, i18n.language, val);
        break;
      case ProductConfig.TYPES:
        result = getProductConfigNameById(types, i18n.language, val);
        break;
      default:
        break;
    }
    return result;
  };

  const loadRecipients = async () => {
    let pms = { userId };
    let result = await getRecipients(pms);
    if (result.length > 0) {
      // for (let i = 0; i < result.length; i++) {
      //   result[i].selected = false;
      //   let _phone = formatPhone(result[i].recipientUser.phone);
      //   result[i].recipientUser.phoneHide = _phone.replace(
      //     _phone.substring(3, 7),
      //     "****"
      //   );
      // }
      result[0].selected = true;
      setAddressDetail(result[0]);
      setRecipients(result);
    } else {
      setRecipients([]);
    }
  };

  const loadOrderBills = async () => {
    let pms = { coId: userInfo.companyId, page: 0, per_page: 20 };
    let result = await getOrderBills(pms);
    console.log("------getordersbills:", result);
    if (result.length > 0) {
      result = result.map((item) => {
        return { ...item, selected: false };
      });
      setOrgOrderBills(result);
    } else {
      setOrgOrderBills([]);
    }
  };

  const loadProductDiscount = async (id) => {
    let pms = { productId: id, page: 0, per_page: 3 };
    return await discountProductDetail(pms);
  };

  const switchOrderBill = (index) => {
    const list = deepClone(orderBills);
    for (let i = 0; i < list.length; i++) {
      list[i].selected = false;
    }
    list[index].selected = true;
    setOrderBills(list);
  };

  const switchAddress = (index) => {
    const list = deepClone(recipients);
    for (let i = 0; i < list.length; i++) {
      list[i].selected = false;
    }
    list[index].selected = true;
    setRecipients(list);
  };

  const submitOrder = async (isBankTransfer) => {
    console.log("----------------submitOrder:", groupProducts);
    console.log("----------------orderModel:", orderModel);
    // if (!checkOrderForm()) return;
    let timeId = getTimeStamp().toString();
    let flag = true;
    for (let g = 0; g < groupProducts.length; g++) {
      const item = groupProducts[g];
      let oProducts = [];
      let tprice = 0;
      let pPrice = 0;
      for (let i = 0; i < item.products.length; i++) {
        let {
          _id,
          coId,
          count,
          isSample,
          volume,
          moq,
          price,
          discountPrice,
          discount,
          currency,
        } = item.products[i];
        oProducts.push({
          productId: _id,
          productCoId: coId,
          count: count,
          isSample: isSample ? true : false,
          volume,
          moq,
          price,
          discountPrice: discountPrice,
          discountId: discount?._id,
        });
        let itemPrice = await getExchangeRatePrice(price * count, currency);
        tprice += itemPrice;
        pPrice +=
          itemPrice *
          (volume.volume === ProductSampleValue ? 1 : SYS_ENV_PRODUCT_DEPOSIT);
      }
      console.log("-------orderconfirm oProducts:", JSON.stringify(oProducts));

      let pms = {
        orderNumber: "VXO" + getOrderNumber(),
        userId: userId,
        coId: userInfo.companyId,
        // recipientId: addressDetail._id,
        orderProducts: oProducts,
        orderPrice: {
          totalPrice: tprice,
          payPrice: pPrice,
        },
        pickService,
        pickDate,
        firstPaymentVouchers,
        deposit: SYS_ENV_PRODUCT_DEPOSIT,
        // invoiceId: invoiceDetail._id,
        invoiceType: billType,
        relationId: timeId,
        ...orderModel,
      };
      console.log("--------------------create order pms:", pms);
      const result = await orderCreate(pms);
      console.log("-------------order create:", pms, result);
      if (!result) {
        flag = false;
        return;
      }
    }
    if (flag) {
      toastInfo(
        isBankTransfer
          ? t("ORDER_SUBMISSION_SUCCEED")
          : t("ORDER_SUBMISSION_SUCCEED_PLEASE_PAY_ASAP")
      );
    } else {
      toastInfo(t("ORDER_SUBMISSION_FAILED"));
      return;
    }
    removePOI();
    return { _id: timeId, payPrice };
  };

  const checkOrderForm = () => {
    if (!invoiceDetail) {
      toastError(t("INVOICE") + t("IS_REQUIRED"));
      setInloading(false);
      return false;
    }
    if (!pickService && !pickDate) {
      toastError(t("PICK_UP_DATE") + t("IS_REQUIRED"));
      setInloading(false);
      return;
    }
    if (!addressDetail) {
      toastError(t("DELIVERY_ADDRESS") + t("IS_REQUIRED"));
      setInloading(false);
      return;
    }
    return true;
  };

  const onOrderConfirm = async () => {
    if (inloading) {
      return;
    } else {
      setInloading(true);
      let order = await submitOrder();
      if (order) {
        history.push("/shop/orders");
      }
    }
  };

  const onWxPay = async () => {
    if (inloading) {
      return;
    } else {
      setInloading(true);
      let order = await submitOrder();
      console.log("------wxpay order result:", order);
      if (order) {
        let result = await getNativePayCode(order);
        console.log("-------wxpay result:", result);
        if (result) {
          order.wxcode = result;
          history.push("/order/wxpay", { ...order });
        } else {
          setInloading(false);
          toastError(t("WECHAT_PAY_FAILED"));
        }
      }
    }
  };

  const onAliPay = async () => {
    if (inloading) {
      return;
    } else {
      setInloading(true);
      let order = await submitOrder();
      console.log("----------alipay:", order);
      if (order) {
        let result = await getAliPayForm(order);
        if (result) {
          setAliPayForm(result);
        }
      }
    }
  };

  const removePOI = async () => {
    if (orderIds.length > 0) {
      for (let i in orderIds) {
        const pms = { cartId: orderIds[i] };
        const result = await cartRemove(pms);
      }
    }
  };

  useEffect(() => {
    if (aliPayForm) {
      let forms = document.forms;
      if (forms && forms.length > 0) {
        forms[0].submit();
      }
    }
  }, [aliPayForm]);

  const onCreateAddressFinished = (address) => {
    setCreateModalVisible(false);
    loadRecipients();
  };

  const onDeleteAddress = async (id) => {
    let pms = { recipientId: id };
    await recipientRemove(pms);
    loadRecipients();
  };

  const onUpdateAddress = (detail) => {
    setAddressDetail(detail);
    setCreateModalVisible(true);
  };

  const onCreateAddress = () => {
    setAddressDetail(null);
    setCreateModalVisible(true);
  };

  const onCreateInvoice = () => {
    setInvoiceDetail(null);
    setCreateInvoiceModalVisible(true);
  };

  const onCreateInvoiceFinished = (address) => {
    setCreateInvoiceModalVisible(false);
    loadOrderBills();
  };

  const onDeleteInvoice = async (id) => {
    let pms = { id: id };
    await removeOrderBill(pms);
    loadOrderBills();
  };

  const onUpdateInvoice = (detail) => {
    setInvoiceDetail(detail);
    setCreateInvoiceModalVisible(true);
  };

  const onBack = () => {
    history.push("/shopping-cart");
  };

  const onCountChange = (gindex, index, val) => {
    let list = deepClone(groupProducts);
    list[gindex].products[index].count = val;
    setGroupProducts(list);
  };

  const formatDiscount = (item) => {
    let content = "";
    if (item) {
      switch (item.discountType) {
        case discountType.NORMAL:
          content = t("PRICE_REDUCTION") + item.normal.percent + "%";
          break;
        case discountType.DESTOCKS:
          content = t("PRICE_REDUCTION") + item.destocks.percent + "%";
          break;
        case discountType.EVENT:
          content = t("PRICE_REDUCTION") + item.event.percent + "%";
          break;
        case discountType.DEGRESSIVE:
          content = item.degressives.map((d) => (
            <p>
              {t("QUANTITY_REACHES")} {d.count} {t("PRICE_REDUCTION")} {d.price}{" "}
              {localizePriceUnit()}
            </p>
          ));
          break;
        default:
          break;
      }
    }
    return content;
  };

  const onDiscountChange = (index, pIndex, gIndex) => {
    let list = deepClone(groupProducts);
    console.log("---------discount change:", index, pIndex, gIndex, list);
    list[gIndex].products[pIndex].discounts = list[gIndex].products[
      pIndex
    ].discounts.map((item, i) => {
      item.selected = i === index ? true : false;
      return item;
    });
    setGroupProducts(list);
  };

  const getDiscountPrice = (item, price) => {
    let discountPrice = 0;
    if (item.discounts && item.discounts.length > 0) {
      let list = item.discounts.filter((item) => item.selected);
      if (list.length > 0) {
        const discount = list[0];
        if (discount) {
          switch (discount.discountType) {
            case discountType.NORMAL:
              discountPrice = price * (discount.normal.percent / 100);
              break;
            case discountType.DESTOCKS:
              discountPrice = price * (discount.destocks.percent / 100);
              break;
            case discountType.DEGRESSIVE:
              discount.degressives.forEach((d) => {
                if (item.count > d.count) {
                  discountPrice = d.price;
                } else if (item.count === d.count) {
                  discountPrice = d.price;
                }
              });
              break;
            case discountType.EVENT:
              discountPrice = price * (discount.event.percent / 100);
              break;
            default:
              break;
          }
        }
      }
    }

    return discountPrice;
  };

  const onProductDetail = (id) => {
    history.push("/products/detail/" + id);
  };

  const onBankTransferPay = async (fileList) => {
    console.log("---- file list:", fileList);
    setFirstPaymentVouchers(fileList);
    onBankTransferClose();
    let order = await submitOrder(true);
    if (order) {
      history.push("/order/success?out_trade_no=" + order._id);
    }
  };

  const onBankTransfer = () => {
    if (checkOrderForm()) {
      setBankTransferModalVisible(true);
    }
  };

  const onBankTransferClose = () => {
    setBankTransferModalVisible(false);
  };

  const menu_address = () => {
    return (
      <div
        // onClick={onStockChange.bind(this)}
        className="order-confirm-address-select-panel"
      >
        {warehouseList.map((item, index) => (
          <div
            key={index}
            className="address-item"
            onClick={() => setOrderModelPro("recipient", warehouseList[index])}
          >
            {getCountryNameById(
              locationList,
              i18n.language,
              item.location.country
            )}
            {getChinaCityNameById(item.location.city)}
            {item.location.address.name}
          </div>
        ))}
      </div>
    );
  };

  const menu_invoice = () => {
    return (
      <div
        // onClick={onStockChange.bind(this)}
        className="order-confirm-address-select-panel"
      >
        <div
          className="address-item"
          onClick={() =>
            setOrderModelPro(
              "specialInvoiceInfo",
              orderModel.specialInvoiceInfo
            )
          }
        >
          <div>
            {t("TITAL")}
            {":"}
            {orderModel.specialInvoiceInfo.specialInvoiceInfo.coName}
          </div>
          <div>
            {t("TAX_NUMBER")}
            {":"}
            {orderModel.specialInvoiceInfo.specialInvoiceInfo.coNo}
          </div>
        </div>
      </div>
    );
  };

  const setOrderModelPro = (key, value) => {
    let temp = deepClone(orderModel);
    temp[key] = value;
    console.log("setOrderModelPro:", temp);
    setOrderModel(temp);
  };

  return (
    <div className="order-confirm-panel-container">
      <div className="order-confirm">
        <div className="order-confirm-title">{t("CONFIRM_ORDER")}</div>
        <div className="order-info-panel">
          <div className="title">{t("ADDRESS")}</div>
          {orderModel && orderModel.recipient && (
            <Dropdown overlay={menu_address()} trigger={["click"]}>
              <div className="address-panel">
                <div className="address">
                  {getCountryNameById(
                    locationList,
                    i18n.language,
                    orderModel.recipient.location.country
                  )}
                  {getChinaCityNameById(orderModel.recipient.location.city)}
                  {orderModel.recipient.location.address.name}
                </div>
                <img src={rightArrowIcon} />
              </div>
            </Dropdown>
          )}
          {orderModel && orderModel.recipient && (
            <div className="recipient-user-info">
              {orderModel.recipient.name} {orderModel.recipient.phone.phone}
            </div>
          )}
          <div className="divider"></div>
        </div>
        <div className="order-info-panel">
          <div className="title">{t("INVOICE_DETAIL")}</div>
          {orderModel && orderModel.specialInvoiceInfo && (
            <Dropdown overlay={menu_invoice()} trigger={["click"]}>
              <div className="address-panel">
                <div className="address">
                  {t("TITAL")}
                  {":"}
                  {orderModel.specialInvoiceInfo.specialInvoiceInfo.coName}
                </div>
                <img src={rightArrowIcon} />
              </div>
            </Dropdown>
          )}
          {orderModel && orderModel.specialInvoiceInfo && (
            <div className="recipient-user-info">
              {t("TAX_NUMBER")}
              {":"}
              {orderModel.specialInvoiceInfo.specialInvoiceInfo.coNo}
            </div>
          )}
          <div className="divider"></div>
        </div>
        {/* <div className="order-confirm-content-panel">
          <div className="order-confirm-pickservice order-confirm-address">
            <div className="title">{t("INVOICE_DETAIL")}</div>
            <div className="content">
              <div
                className={
                  "pickservice-item " +
                  (billType === 0 && "pickservice-item-selected")
                }
                onClick={() => setBillType(0)}
              >
                {t("ELECTRONIC_ORDINARY_INVOICE")}
              </div>
              <div
                className={
                  "pickservice-item " +
                  (billType === 1 && "pickservice-item-selected")
                }
                onClick={() => setBillType(1)}
              >
                {t("VAT_SPECIAL_INVOICE")}
              </div>
            </div>
            <div className="content1">
              {orderBills.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      "address-item " +
                      (item.selected && "address-item-selected")
                    }
                    onClick={switchOrderBill.bind(this, index)}
                  >
                    <div className="address-item-desc">
                      <div className="username">{item.companyName}</div>
                      <div className="phone">{item.taxCode}</div>
                    </div>
                    <div className="action">
                      <span onClick={onUpdateInvoice.bind(this, item)}>
                        {t("EDIT")}
                      </span>
                      <Divider type="vertical" />
                      <span onClick={onDeleteInvoice.bind(this, item._id)}>
                        {t("DELETE")}
                      </span>
                    </div>
                  </div>
                );
              })}
              <div className="address-add" onClick={onCreateInvoice.bind(this)}>
                <PlusOutlined style={{ fontSize: "16px", color: "#CCCCCC" }} />
                <div className="address-add-title">{t("CREATE_INVOICE")}</div>
              </div>
            </div>
          </div>
          <div className="order-confirm-pickservice">
            <div className="title">{t("PICK_DETAIL")}</div>
            <div className="content">
              <div
                className={
                  "pickservice-item " +
                  (!pickService && "pickservice-item-selected")
                }
                onClick={() => setPickService(false)}
              >
                {t("SELF_PICK_UP")}
              </div>
              <div
                className={
                  "pickservice-item " +
                  (pickService && "pickservice-item-selected")
                }
                onClick={() => setPickService(true)}
              >
                {t("VINBIP_LOGISTIC")}
              </div>
            </div>
            {!pickService && (
              <div className="form-row">
                <DatePicker
                  value={pickDate && moment(pickDate, "YYYY-MM-DD")}
                  placeholder={t("PICK_UP_DATE")}
                  className="select"
                  format="YYYY-MM-DD"
                  onChange={(val, dateString) => setPickDate(dateString)}
                />
              </div>
            )}
          </div>
          <div className="order-confirm-address">
            <div className="title">{t("DELIVERY_ADDRESS")}</div>
            <div className="content1">
              {recipients.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      "address-item " +
                      (item.selected && "address-item-selected")
                    }
                    onClick={switchAddress.bind(this, index)}
                  >
                    <div className="address-item-desc">
                      <div className="username">
                        {item.recipientUser.userName}
                      </div>
                      <div className="phone">
                        {formatPhone(item.recipientUser.phone)}
                      </div>
                      <div className="phone">
                        {item.recipientAddress.country}
                        {item.recipientAddress.state}
                        {item.recipientAddress.addressLineOne}
                      </div>
                    </div>
                    <div className="action">
                      <span onClick={onUpdateAddress.bind(this, item)}>
                        {t("EDIT")}
                      </span>
                      <Divider type="vertical" />
                      <span onClick={onDeleteAddress.bind(this, item._id)}>
                        {t("DELETE")}
                      </span>
                    </div>
                  </div>
                );
              })}
              <div className="address-add" onClick={onCreateAddress.bind(this)}>
                <PlusOutlined style={{ fontSize: "16px", color: "#CCCCCC" }} />
                <div className="address-add-title">{t("CREATE_ADDRESS")}</div>
              </div>
            </div>
          </div>
          <div className="order-confirm-product">
            <div className="product-title">{t("DETAIL_OF_THE_ORDER")}</div>
            <div className="product-content">
              {groupProducts.map((g, gindex) => (
                <div className="product-item-panel" key={gindex}>
                  <div className="company-name">{g.companyName}</div>
                  {g.products.map((item, index) => {
                    return (
                      <div className="product-item" key={index}>
                        <div className="product-info">
                          <div className="product-image-panel">
                            <img
                              alt={item.title}
                              src={localizeImg(
                                item.images.length > 0
                                  ? item.images[0]
                                  : item.bottleImages[0]
                              )}
                              className="info-image cursor-pointer"
                              onClick={onProductDetail.bind(this, item._id)}
                            />
                          </div>
                          <div className="info-desc">
                            <div
                              className="info-name cursor-pointer"
                              onClick={onProductDetail.bind(this, item._id)}
                            >
                              {item.title}
                            </div>
                            <div className="info-other">
                              {item.typeName}
                              <Divider type="vertical" />
                              <span>
                                {item.volume.volume === ProductSampleValue
                                  ? t("SAMPLES")
                                  : item.volume.volume + "ML"}
                              </span>
                              <Divider type="vertical" />
                              {item.classificationName}
                              <Divider type="vertical" />
                              {item.year}
                            </div>
                          </div>
                        </div>
                        <div className="product-info-right">
                          <div className="product-count">
                            {item.isSample ? (
                              <div className="product-price">{item.count}</div>
                            ) : (
                              <InputCountBasic
                                count={item.count}
                                maxCount={item.volume.stock.value}
                                minCount={item.moq}
                                onChange={onCountChange.bind(
                                  this,
                                  gindex,
                                  index
                                )}
                              />
                            )}
                          </div>
                          <div className="product-price">
                            {item.currency === ProductCurrencyType.CNY &&
                              item.currency}
                            {item.price}
                            {item.currency !== ProductCurrencyType.CNY &&
                              item.currency}
                          </div>
                          {item.discounts && (
                            <div className="product-discount">
                              {item.discounts.map((d, dindex) => (
                                <div
                                  key={dindex}
                                  className={
                                    "product-discount-item " +
                                    (d.selected &&
                                      "product-discount-item-selected")
                                  }
                                  onClick={onDiscountChange.bind(
                                    this,
                                    dindex,
                                    index,
                                    gindex
                                  )}
                                >
                                  * {t(d.discountType)}: {formatDiscount(d)}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {groupProducts.length >= 0 && (
                    <div className="product-blance">
                      <div className="blance-item">
                        <div className="item">
                          {t("SUBTOTAL_MONETARY_VALUE")}:
                        </div>
                      </div>
                      <div className="blance-item">
                        <div className="item">
                          {ProductCurrencyType.CNY} {g.productCNY}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="product-blance">
              <div className="blance-item">
                <div className="item">{t("NUMBER_OF_BOTTLE")}:</div>
                <div className="item">{t("TOTAL_PRICE")}:</div>
                {discountPrice > 0 && (
                  <div className="item">{t("DISCOUNT_PRICE")}:</div>
                )}
                <div className="item price-title">{t("DEPOSIT_AMOUNT")}:</div>
              </div>
              <div className="blance-item">
                <div className="item">{totalCount}</div>
                <div className="item">
                  {ProductCurrencyType.CNY} {totalPrice}{" "}
                </div>
                {discountPrice > 0 && (
                  <div className="item">
                    {ProductCurrencyType.CNY}{" "}
                    {(totalPrice - discountPrice).toFixed(2)}{" "}
                  </div>
                )}
                <div className="item price-value">
                  {ProductCurrencyType.CNY} {payPrice}{" "}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="order-confirm-btn">
          <Button
            className="btn-confirm-order"
            onClick={onOrderConfirm.bind(this)}
          >
            {t("CONFIRM_ORDER")}
          </Button>
          {/* <Button className="btn-default" onClick={onBack.bind(this)}>
            {t("BACK_TO_SHOPPING_CART")}
          </Button>
          <Button
            className={inloading ? "btn-loading" : "btn-primary"}
            onClick={onAliPay.bind(this)}
          >
            {t("PAY_WITH_ALIPAY")}
          </Button>
          <Button
            className={inloading ? "btn-loading" : "btn-primary"}
            onClick={onWxPay.bind(this)}
          >
            {t("WECHAT_PAY")}
          </Button>
          <Button className="btn-primary" onClick={onBankTransfer.bind(this)}>
            {t("BANK_TRANSFER")}
          </Button>
          <Button
            className={inloading ? "btn-loading" : "btn-primary"}
            onClick={onOrderConfirm.bind(this)}
          >
            {t("CONFIRMATION_OF_THE_ORDER")}
          </Button> */}
        </div>
        {/* <div dangerouslySetInnerHTML={{ __html: aliPayForm }}></div> */}
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={createModalVisible}
        footer={null}
        className="address-create-modal"
        onCancel={() => setCreateModalVisible(false)}
      >
        <AddressCreate
          detail={addressDetail}
          isDefault={recipients.length === 0}
          onComplete={onCreateAddressFinished.bind(this)}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={createInvoiceModalVisible}
        footer={null}
        className="address-create-modal"
        onCancel={() => setCreateInvoiceModalVisible(false)}
      >
        <InvoiceCreate
          detail={invoiceDetail}
          isDefault={true}
          billType={billType}
          onComplete={onCreateInvoiceFinished.bind(this)}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={bankTransferModalVisible}
        footer={null}
        className="address-create-modal"
        onCancel={onBankTransferClose.bind(this)}
      >
        <BankTransfer
          files={firstPaymentVouchers}
          onComplete={onBankTransferPay.bind(this)}
          onClose={onBankTransferClose.bind(this)}
        />
      </Modal>
    </div>
  );
}
