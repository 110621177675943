import React, { useEffect, useState } from "react";
import { Button, Table, List, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../contexts/UserIdProvider";
import "./style/browseRecord.less";
import { browseRecordList, browseRecordRemove } from "../../api/browseRecord";
import { useLocation } from "react-router-dom";
import { dateFormat } from "../../utils/date";
import FeedListRow from "../feed/FeedListRow";
import "../feed/style/feed.less";

export default function FeedBrowseRecord() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const { userId } = user;
  const { state } = useLocation();
  const [busType, setBusType] = useState();
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 20;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (state) {
      setBusType(state.type);
    } else {
      loadData();
    }
  }, [state]);

  useEffect(() => {
    if (busType) {
      loadData();
    }
  }, [busType]);

  const loadData = async () => {
    let pms = { userId, busType, page: pageIndex, per_page: pageSize };
    let list = await browseRecordList(pms);
    if (list && list.length > 0) {
      list = filterList(list);
      setData(list);
    }
  };

  const filterList = (list) => {
    let result = [];
    list.forEach((item) => {
      if (item.feedInfo.length > 0 && !result.includes(item)) {
        let element = result.find((item) => item.busId === item.busId);
        if (!element) {
          result.push(item);
        }
      }
    });
    return result;
  };

  return (
    <div className="common-management browse-container">
      <div className="common-management-title">浏览记录</div>
      <div className="record-content feed common-management-panel">
        <List
          itemLayout="vertical"
          size="large"
          dataSource={data}
          renderItem={(item, index) => (
            <div key={index} className="feed-list-item-container">
              <FeedListRow data={item.feedInfo[0]} />
            </div>
          )}
        >
          {loading && hasMore && (
            <div className="loading-container">
              <Spin />
            </div>
          )}
        </List>
      </div>
    </div>
  );
}
