import React, { useState } from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "../../../Icon";
import Modal from "../../../Modal";
import Share from "./Share";
import { localizeImg, localizeValue } from "../../../../../constants/constants";
import editIcon from "../../../../../resource/icons/common/edit.svg";
import { Path, PathTools } from "../../../../../constants/path";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

export default function Panel(props) {
  const { i18n } = useTranslation();

  const { product, className } = props;

  const history = useHistory();

  const [visibleModal, setVisibleModal] = useState(false);

  const editProduct = () => {
    history.push(
      PathTools.joinPath(
        Path.productsAdd,
        localizeValue(i18n.language, product?.companyInfo?.companyName)
      ),
      {
        productId: product._id,
      }
    );
  };

  return (
    <>
      <div className={cn(styles.panel, className)}>
        <div className={styles.avatar}>
          <img
            src={
              Array.isArray(product?.companyInfo?.avatar)
                ? localizeImg(product?.companyInfo?.avatar[0])
                : localizeImg(product?.companyInfo?.avatar)
            }
            alt="Avatar"
          />
        </div>
        <div className={styles.brand}>
          <img
            src={editIcon}
            alt="Figma"
            style={{ width: "24px", cursor: "pointer" }}
            onClick={editProduct.bind(this)}
          />
        </div>
        {/* <div className={styles.brand}>
          <img src="/images/content/figma.png" alt="Figma" />
          <div className={styles.counter}>3</div>
        </div> */}
        {/* <button className={styles.share} onClick={() => setVisibleModal(true)}>
          <Icon name="share" size="24" />
        </button> */}
      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Share />
      </Modal>
    </>
  );
}
