import { Form, Select } from "antd";
import "./style/multiSelectBasic.less";

export default function MultipleSelectBasic(props) {
  let { data, label, value, name, onChange, required, message, disabled } =
    props;
  let layout = { label, name };
  if (required) {
    layout.rules = [{ required: true, message }];
  }

  let selectLayout = { options: data };
  if (disabled) {
    selectLayout.disabled = true;
  }

  return (
    <div>
      {/* <Form.Item {...layout}> */}
      {label && <div className="label">{label} </div>}
      <Select
        mode="multiple"
        // {...selectLayout}
        options={data}
        style={{ display: "block", width: "100%" }}
        placeholder=""
        size="large"
        optionFilterProp="label"
        value={value}
        onChange={(value) => {
          onChange && onChange(value);
        }}
      />
      {/* </Form.Item> */}
    </div>
  );
}
