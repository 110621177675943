import React, { useEffect, useState } from "react";
import { likes } from "../../../api/feed";
import { statusCode } from "../../../constants/statusCode.enum";
import { Avatar, Col, Divider, Row } from "antd";
import { useTranslation } from "react-i18next";
import { localizeImg, localizeValue } from "../../../constants/constants";
import AvatarBasic from "../../common/avatar/AvatarBasic";
import UserItemBasic from "../../shared/UserItemBasic";

export default function FeedLikeList(props) {
  const { t, i18n } = useTranslation();

  let initLikes = [];
  let [feedLikes, setFeedLikes] = useState(initLikes);
  const feedId = props.feedId;
  const pageSize = 20;
  useEffect(() => {
    loadFeedLikes(0);
  }, []);

  function loadFeedLikes(index) {
    let pms = { feedId, page: 0, per_page: pageSize };
    likes(pms).then(function (data) {
      if (data && data.statusCode === statusCode.SUCCESS) {
        let list = data.result.result;
        if (index > 0 && list.length > 0) {
          list = feedLikes.concat(list);
        }
        setFeedLikes(list);
        console.log("----------feed likes list:", list);
      }
    });
  }
  //
  return (
    <div>
      <div>
        <h3> {t("LIKE")} </h3>
        <Divider />
        {feedLikes.length === 0 && <div>{t("THERE_IS_NOT_LIKE")}</div>}
        {feedLikes.map((data, index) => {
          return (
            <Row align="center" key={index}>
              <Col lg={2}>
                {data.userInfo[0].companyDesc &&
                data.userInfo[0].companyDesc.length > 0 ? (
                  <AvatarBasic
                    size={32}
                    picture={localizeImg(
                      data.userInfo[0].companyDesc[0].avatar
                    )}
                    displayName={
                      data.userInfo[0].companyDesc[0].displayName ||
                      localizeValue(
                        i18n.language,
                        data.userInfo[0].companyDesc[0].companyName
                      )
                    }
                  />
                ) : (
                  <AvatarBasic
                    size={32}
                    picture={localizeImg(data.userInfo[0].picture)}
                    displayName={data.userInfo[0].nickName}
                  />
                )}
              </Col>
              <Col lg={22}>
                <span>{data.userInfo[0].nickName}</span>
              </Col>
              <Divider />
            </Row>
          );
        })}
      </div>
    </div>
  );
}
