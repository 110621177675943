import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { localizeImg } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import "./style/groupChatManage.less";
import AvatarBasic from "../common/avatar/AvatarBasic";
import { PlusOutlined } from "@ant-design/icons";
import { userDetail } from "../../api/user";

export default function FriendChatManage(props) {
  const { friend, onCreateGroup, onClose, onAddBlackList, onRemoveFriendItem } =
    props;
  const { t } = useTranslation();
  const history = useHistory();

  const onCloseWin = () => {
    onClose && onClose();
  };
  const goHisHome = async () => {
    const userInfo = await userDetail(friend.id);
    if (userInfo) {
      history.push(`profile/${friend.id}`);
    }
  };

  return (
    <div className="group-chat-manage">
      <div className="group-content">
        <div className="group-member-item">
          <div onClick={goHisHome.bind(this)}>
            <AvatarBasic
              cname="member-item-avatar"
              picture={localizeImg(friend.picture)}
              displayName={friend.name}
            ></AvatarBasic>
          </div>
          <div className="member-item-name">{friend.name}</div>
        </div>
        <div className="group-member-item">
          <div
            className="group-add-btn"
            onClick={() => onCreateGroup && onCreateGroup()}
          >
            <PlusOutlined style={{ fontSize: "32px", color: "#DDDDDD" }} />
          </div>
        </div>
      </div>
      <div className="group-bottom">
        <Button
          className="group-btn-default"
          onClick={() => onRemoveFriendItem && onRemoveFriendItem()}
        >
          {t("DELETE_CONTACT")}
        </Button>
        <Button
          className="group-btn-primary"
          onClick={() => onAddBlackList && onAddBlackList()}
        >
          {t("BLOCK_CONTACT")}
        </Button>
      </div>
    </div>
  );
}
