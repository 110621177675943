import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Messages.module.sass";
import Panel from "./Panel";
import Message from "./Message";
import Send from "./Send";
import useIM from "../../../../hooks/useIM";
import { useIMContext } from "../../../../contexts/IMProvider";
import { deepClone } from "../../../../utils/utils";
import { useUserId } from "../../../../contexts/UserIdProvider";
import { getTimeStamp } from "../../../../utils/date";
import { ChatContentsType } from "../../../../constants/chatContentsType.enum";
import { Modal } from "antd";
import AddMeeting from "../../../../components/meeting/AddMeeting";

const Messages = ({
  className,
  visible,
  setVisible,
  actions,
  parameters,
  // messages,
  activeUser,
  companyInfo
}) => {
  const {
    webIM,
    imLoginStatus,
    newMsg,
    sessionMessageList,
    initIMLogin,
    getSessionMessage,
    setChatCallMsg,
  } = useIMContext();
  const {
    messageList,
    newGroup,
    fetchHistoryMessage,
    readChannelMessage,
    sendTextMessage,
    sendCustomMessage,
    createGroup,
    quitGroup,
    dissolveGroup,
    removeGroupMember,
    addGroupMember,
  } = useIM(webIM);

  const { user } = useUserId();
  const { userId, userInfo } = user;

  const listRef = useRef(null);

  const [messages, setMessages] = useState([]);

  const [meetingModalVisible, setMeetingModalVisible] = useState(false);

  useEffect(() => {
    console.log("onSwitchUser:", activeUser);
    if (activeUser && activeUser.id) {
      setMessages([]);
      fetchHistoryMessage(activeUser.id, true, activeUser.groupStatus);
    }
  }, [activeUser]);

  useEffect(() => {
    if (webIM) {
      initIMLogin(userInfo);
    }
  }, [webIM, imLoginStatus]);

  useEffect(() => {
    let list = messageList;
    // if (!newMsgStatus) {
    //   let res = deepClone(messages);
    //   list = list.concat(res);
    // }
    setMessages(list);
  }, [messageList]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (newMsg) {
      console.log("onReceivedMsg body:", newMsg);
      if (
        newMsg.type === "chat" &&
        activeUser &&
        activeUser.id === newMsg.from
      ) {
        let res = deepClone(messages);
        res.push(newMsg);
        setMessages(res);
      } else if (
        newMsg.type === "groupchat" &&
        activeUser &&
        activeUser.groupStatus &&
        activeUser.id === newMsg.to
      ) {
        let res = deepClone(messages);
        res.push(newMsg);
        setMessages(res);
      }
    }
  }, [newMsg]);

  const onTextMessage = (e) => {
    console.log(e);

    sendTextMessage(activeUser.id, e, activeUser.groupStatus);
    addTextMsg(e);
  };

  const onSendCustomMessage = (fileType, url, name) => {
    let content = { fileType, url, name };
    sendCustomMessage(activeUser.id, content, activeUser.groupStatus);
    if (fileType === ChatContentsType.FILE) {
      console.log("----chat file :", fileType, url, name);
      let msg = {
        customExts: { url, fileType, name },
        contentsType: ChatContentsType.CUSTOM,
      };
      addMessage(msg);
    }
  };

  const addTextMsg = (e) => {
    let msg = { data: e, contentsType: ChatContentsType.TEXT, sourceMsg: e };
    addMessage(msg);
  };

  const addMessage = (newMsg) => {
    let msg = {
      id: getTimeStamp(),
      from: userId,
      to: activeUser.id,
      ...newMsg,
    };
    let list = deepClone(messages);
    console.log("addMessage:", newMsg);
    list.push(msg);
    // setNewMsgStatus(true);
    setMessages(list);
  };

  const onAudioCall = () => {
    if (activeUser) {
      if (activeUser.groupStatus) {
        setMeetingModalVisible(true);
      } else {
        setChatCallMsg({
          type: ChatContentsType.AUDIOCALL,
          from: userId,
          to: activeUser.id,
        });
      }
    }
  };

  const onVideoCall = () => {
    if (activeUser) {
      if (activeUser.groupStatus) {
        setMeetingModalVisible(true);
      } else {
        setChatCallMsg({
          type: ChatContentsType.VIDEOCALL,
          from: userId,
          to: activeUser.id,
        });
      }
    }
  };

  const onMeetingAdd = async (meeting) => {
    if (meeting) {
      // meeting.groupId = activeUser.serverId;
      // let list = [];
      // // let owner;
      // let { members } = selectedGroup;
      // members.map((item) => {
      //   list.push(item.memberId);
      //   // if (item.isOwner === "1") {
      //   //   owner = item.memberId;
      //   // }
      // });
      // meeting.owner = userId;
      // meeting.status = "0";
      // meeting.members = list;
      // await createMeeting(meeting);
      // updateMeetings();
    }
  };

  return (
    <div className={cn(className, styles.messages, { [styles.show]: visible })}>
      <Panel
        actions={actions}
        parameters={parameters}
        setVisible={setVisible}
        activeUser={activeUser}
        companyInfo={companyInfo}
      />
      <div className={styles.wrapper}>
        {/* <button className={cn("button-stroke button-small", styles.button)}>
          Load conversation
        </button> */}
        <div className={styles.list} ref={listRef}>
          {messages.map((x, index) => (
            <Message item={x} key={index} activeUser={activeUser} />
          ))}
        </div>
        <Send
          onTextMessage={onTextMessage.bind(this)}
          activeUser={activeUser}
          onAddMessage={addMessage.bind(this)}
          onSendCustomMessage={onSendCustomMessage.bind(this)}
          onAudioCall={onAudioCall.bind(this)}
          onVideoCall={onVideoCall.bind(this)}
        />
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={meetingModalVisible}
        footer={null}
        maskClosable={false}
        className="imchat-select-friend-modal"
        onCancel={() => setMeetingModalVisible(false)}
      >
        <AddMeeting
          onClose={() => setMeetingModalVisible(false)}
          onSubmit={onMeetingAdd.bind(this)}
        />
      </Modal>
    </div>
  );
};

export default Messages;
