import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../Card";
import Icon from "../../../Icon";
import TextInput from "../../../TextInput";
import Editor from "../../../Editor";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../Dropdown";
import {
  getProductConfigForSelect,
  getProductGrapes,
  loadProductConfig,
} from "../../../../../api/product";
import {
  ProductConfig,
  localizeValue,
} from "../../../../../constants/constants";
import {
  getCountryForSelect,
  getLocationsByParentId,
} from "../../../../../api/config";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { deepClone } from "../../../../../utils/utils";
import MultiSelect from "../../../MultiSelect";
import {
  companyDetail,
  getCompanyDescFromUserType,
} from "../../../../../api/company";
import { LanguagesKey } from "../../../../../constants/languages.enum";
import ImageUploader from "../../../ImageUploader";
import AddressSelect from "../../../../../components/common/location/addressSelect/AddressSelect";
import { UserType } from "../../../../../constants/usersType.enum";

export default function NameAndDescription(props) {
  const {
    className,
    onChange,
    onAddressChange,
    dataSource,
    companyId,
    onClose,
  } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [formData, setFormData] = useState({});
  const [addressFormData, setAddressFormData] = useState({});

  const [companyLabels, setCompanyLabels] = useState([
    { label: "PRODUCER", value: "PRODUCER" },
    // { label: "WINERY", value: "WINERY" },
    // { label: "COOPERATIVE", value: "COOPERATIVE" },
    { label: "NEGOCIANT", value: "NEGOCIANT" },
  ]);

  const [buyerLabels, setBuyerLabels] = useState([
    { label: t("RESTAURANT_AND_BAR"), value: "RESTAURANT_AND_BAR" },
    { label: t("WHOLE_SALE"), value: "WHOLE_SALE" },
    { label: t("HOTEL"), value: "HOTEL" },
    { label: t("TOBACO_AND_LIQUOR_STORE"), value: "TOBACO_AND_LIQUOR_STORE" },
    { label: t("MULTICHANNEL"), value: "MULTICHANNEL" },
    { label: t("E-COMMERCE"), value: "E-COMMERCE" },
    { label: t("SUPERMARKET"), value: "SUPERMARKET" },
    { label: t("CORPORATEBUSINESS"), value: "CORPORATEBUSINESS" },
  ]);

  const [types, setTypes] = useState([]);

  const [areas, setAreas] = useState([]);
  const [countries, setCountries] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [grapes, setGrapes] = useState([]);
  const [regions, setRegions] = useState([]);
  // const [subRegions, setSubRegions] = useState([]);
  const [languages, setLanguages] = useState([
    {
      label: LanguagesKey.ZH,
      value: "zh",
    },
    {
      label: LanguagesKey.EN,
      value: "en",
    },
    {
      label: LanguagesKey.FR,
      value: "fr",
    },
  ]);
  const languageNavigation = [
    { label: t("LANGUAGE_FRENCH"), key: "fr" },
    { label: t("LANGUAGE_CHINESE"), key: "zh" },
    { label: t("LANGUAGE_ENGLISH"), key: "en" },
  ];
  const [nameActiveTab, setNameActiveTab] = useState(languageNavigation[0]);
  const [descActiveTab, setDescActiveTab] = useState(languageNavigation[0]);
  const [teamDescActiveTab, setTeamDescActiveTab] = useState(
    languageNavigation[0]
  );
  const [wineActiveTab, setWineActiveTab] = useState(languageNavigation[0]);
  const [awardActiveTab, setAwardActiveTab] = useState(languageNavigation[0]);
  const [desc, setDesc] = useState("");
  const [teamContent, setTeamContent] = useState("");
  const [wineContent, setWineContent] = useState("");

  const [userType, setUserType] = useState("");

  useEffect(() => {
    if (companyId) {
      loadCompanyDesc(companyId);
    } else if (user?.userInfo?.type != UserType.ADMIN) {
      loadCompanyDesc(user?.userInfo?.companyId);
    }
  }, [companyId]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (dataSource) {
      let temp = deepClone(formData);
      temp = dataSource;
      console.log("temp:", temp);
      setFormData(temp);
    }
  }, [dataSource]);

  useEffect(() => {
    if (formData) {
      setDesc(formData.desc);

      console.log("formData:", formData.team?.content);

      if (formData.team) {
        setTeamContent(formData?.team?.content);
      }

      if (formData.wine) {
        setWineContent(formData?.wine?.content);
      }
    }
  }, [descActiveTab, teamDescActiveTab, wineActiveTab]);

  useEffect(() => {
    if (formData?.location?.country && areas && areas.length > 0) {
      onCountrySelect(areas, formData?.location?.country);
    }
  }, [formData?.location?.country]);

  // useEffect(() => {
  //   if (formData?.location?.region && areas && areas.length > 0) {
  //     onRegionSelect(areas, formData?.location?.region);
  //   }
  // }, [formData?.location?.region]);

  useEffect(() => {
    setConfigData();
    initConfigData();
  }, [currentLanguage]);

  const loadCompanyDesc = async (coId) => {
    let tempCompanyDesc = await companyDetail(coId);
    console.log("tempCompanyDesc:", tempCompanyDesc);

    setDesc(tempCompanyDesc.desc);

    if (tempCompanyDesc.team) {
      setTeamContent(tempCompanyDesc?.team?.content);
    }

    if (tempCompanyDesc.wine) {
      setWineContent(tempCompanyDesc?.wine?.content);
    }

    setUserType(tempCompanyDesc.type);
    setFormData(tempCompanyDesc);
  };

  const setConfigData = async () => {
    let configData = await loadProductConfig();
    let {
      locations,
      types,
      bottles,
      capsules,
      sealed,
      classifications,
      categories,
    } = configData;

    loadCountry(locations.result);
    loadSelectData(types, ProductConfig.TYPES);
    loadSelectData(classifications, ProductConfig.CLASSIFICATIONS);

    let grapeList = await initProductGrapes();
    loadSelectData(grapeList, ProductConfig.GRAPES);
  };

  const initConfigData = async () => {
    let temp = deepClone(companyLabels);
    temp.map((mapItem) => {
      mapItem.label = t(mapItem.value);
    });
    setCompanyLabels(temp);
  };

  const loadSelectData = (data, flag) => {
    let result = getProductConfigForSelect(data, currentLanguage);
    if (result && result.length > 0) {
      switch (flag) {
        case ProductConfig.TYPES:
          setTypes(result);
          break;
        case ProductConfig.CLASSIFICATIONS:
          setClassifications(result);
          break;
        case ProductConfig.GRAPES:
          setGrapes(result);
          break;
        default:
          break;
      }
    }
  };

  const initProductGrapes = async () => {
    let pms = {
      pms: JSON.stringify({
        page: 0,
        per_page: 1000,
      }),
    };
    let result = await getProductGrapes(pms);
    return result;
  };

  const loadCountry = (locations) => {
    setAreas(locations);
    let result = getCountryForSelect(
      locations,
      currentLanguage,
      user.userInfo.type
    );
    setCountries(result);
  };

  const onCountrySelect = (locations, id) => {
    let result = getLocationsByParentId(locations, id, currentLanguage);
    setRegions(result);
  };

  const setFormDataValue = (name, value) => {
    let temp = deepClone(formData);
    temp[name] = value;
    setFormData(temp);

    onChange && onChange(temp);

    console.log("setFormDataValue:", temp);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={t("DETAILS")}
        classTitle="title-red"
        head={
          <Link
            className={cn("button-small", styles.button)}
            onClick={() => onClose && onClose()}
          >
            <Icon name="arrow-left" size="24" />
            <span>{t("CLOSE")}</span>
          </Link>
        }
      >
        <div className={styles.table}>
          {userType != UserType.BUYER && (
            <>
              <div className="flex flex-wrap justify-between">
                <div className="w-[344px] mb-5">
                  <Dropdown
                    label={t("COMPANY_CATEGORY")}
                    tooltip=""
                    value={formData.label}
                    setValue={setFormDataValue.bind(this, "label")}
                    options={companyLabels}
                  />
                </div>
                {formData.label == "PRODUCER" && (
                  <div className="w-[344px] mb-5">
                    <TextInput
                      className={styles.field}
                      label={t("TOTAL_PRODUCTION")}
                      placeholder={t("TOTAL_PRODUCTION")}
                      name="production"
                      type="number"
                      tooltip=""
                      currency={t("BOTTLES")}
                      required
                      onChange={setFormDataValue.bind(this, "production")}
                      value={formData?.production}
                    />
                  </div>
                )}
                <div className="w-[344px] mb-5">
                  <MultiSelect
                    className={styles.dropdown_field}
                    label={
                      t("PRODUCT_TYPE") + " (" + t("MULTIPLE_CHOICE") + ")"
                    }
                    placeholder={
                      t("PRODUCT_TYPE") + " (" + t("MULTIPLE_CHOICE") + ")"
                    }
                    tooltip=""
                    value={formData.productCategory}
                    onChange={setFormDataValue.bind(this, "productCategory")}
                    options={types}
                  />
                </div>
                <div className="w-[344px] mb-5">
                  <TextInput
                    className={styles.field}
                    label={t("NUMBER_OF_BRAND")}
                    placeholder={t("NUMBER_OF_BRAND")}
                    name="brandAmount"
                    type="number"
                    tooltip=""
                    required
                    onChange={setFormDataValue.bind(this, "brandAmount")}
                    value={formData?.brandAmount}
                  />
                </div>
                <div className="w-[344px] mb-5">
                  <MultiSelect
                    className={styles.dropdown_field}
                    label={t("MAIN_MARKET") + " (" + t("MULTIPLE_CHOICE") + ")"}
                    placeholder={
                      t("MAIN_MARKET") + " (" + t("MULTIPLE_CHOICE") + ")"
                    }
                    tooltip=""
                    value={formData.market}
                    onChange={setFormDataValue.bind(this, "market")}
                    options={countries}
                  />
                </div>
                {formData.label == "PRODUCER" && (
                  <div className="w-[344px] mb-5">
                    <TextInput
                      className={styles.field}
                      label={t("VINEYARD_SIZE")}
                      placeholder={t("VINEYARD_SIZE")}
                      name="VINEYARD_SIZE"
                      type="number"
                      currency={t("HECTARE")}
                      required
                      onChange={setFormDataValue.bind(this, "size")}
                      value={formData?.size}
                    />
                  </div>
                )}
                {formData.label == "NEGOCIANT" && (
                  <div className="w-[344px] mb-5">
                    <TextInput
                      className={styles.field}
                      label={t("TURNOVERS")}
                      placeholder={t("TURNOVERS")}
                      name="TURNOVERS"
                      type="number"
                      onChange={setFormDataValue.bind(this, "annualTurnover")}
                      value={formData?.annualTurnover}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {userType == UserType.BUYER && (
            <>
              <div className="flex flex-wrap justify-between">
                <div className="w-[344px] mb-5">
                  <Dropdown
                    label={t("COMPANY_CATEGORY")}
                    tooltip=""
                    value={formData.label}
                    setValue={setFormDataValue.bind(this, "label")}
                    options={buyerLabels}
                  />
                </div>
                <div className="w-[344px] mb-5">
                  <TextInput
                    className={styles.field}
                    label={t("WEBSITE")}
                    placeholder={t("WEBSITE")}
                    name="webSite"
                    tooltip=""
                    required
                    onChange={setFormDataValue.bind(this, "webSite")}
                    value={formData?.webSite}
                  />
                </div>
                <div className="w-[344px] mb-5">
                  <TextInput
                    className={styles.field}
                    label={t("INSTAGRAM")}
                    placeholder={t("INSTAGRAM")}
                    name="instagram"
                    tooltip=""
                    required
                    onChange={setFormDataValue.bind(this, "instagram")}
                    value={formData?.instagram}
                  />
                </div>
                <div className="w-[344px] mb-5">
                  <TextInput
                    className={styles.field}
                    label={t("FACEBOOK")}
                    placeholder={t("FACEBOOK")}
                    name="facebook"
                    tooltip=""
                    required
                    onChange={setFormDataValue.bind(this, "facebook")}
                    value={formData?.facebook}
                  />
                </div>
                <div className="w-[344px] mb-5">
                  <TextInput
                    className={styles.field}
                    label={t("TWITTER")}
                    placeholder={t("TWITTER")}
                    name="twitter"
                    tooltip=""
                    required
                    onChange={setFormDataValue.bind(this, "twitter")}
                    value={formData?.twitter}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Card>
    </>
  );
}
