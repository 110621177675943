import React from "react";
import chongqing from "../../../resource/images/china-region/chongqing.jpg";
import beijing from "../../../resource/images/china-region/beijing.webp";
import tianjing from "../../../resource/images/china-region/tianjing.webp";
import shanghai from "../../../resource/images/china-region/shanghai.webp";
import neimeng from "../../../resource/images/china-region/neimeng.webp";
import guangxi from "../../../resource/images/china-region/guangxi.webp";
import xizang from "../../../resource/images/china-region/xizang.webp";
import ningxia from "../../../resource/images/china-region/ningxia.webp";
import xinjiang from "../../../resource/images/china-region/xinjiang.webp";
import xianggang from "../../../resource/images/china-region/xianggang.webp";
import aomen from "../../../resource/images/china-region/aomen.webp";
import hebei from "../../../resource/images/china-region/hebei.webp";
import shanxi from "../../../resource/images/china-region/shanxi.webp";
import liaoning from "../../../resource/images/china-region/liaoning.webp";
import jilin from "../../../resource/images/china-region/jilin.webp";
import heilongjiang from "../../../resource/images/china-region/heilongjiang.webp";
import jiangsu from "../../../resource/images/china-region/jiangsu.webp";
import zhejiang from "../../../resource/images/china-region/zhejiang.webp";
import fujian from "../../../resource/images/china-region/fujian.webp";
import anhui from "../../../resource/images/china-region/anhui.webp";
import jiangxi from "../../../resource/images/china-region/jiangxi.webp";
import shandong from "../../../resource/images/china-region/shandong.webp";
import henan from "../../../resource/images/china-region/henan.webp";
import hubei from "../../../resource/images/china-region/hubei.webp";
import hunan from "../../../resource/images/china-region/hunan.webp";
import guangdong from "../../../resource/images/china-region/guangdong.webp";
import hainan from "../../../resource/images/china-region/hainan.webp";
import sichuan from "../../../resource/images/china-region/sichuan.webp";
import guizhou from "../../../resource/images/china-region/guizhou.webp";
import yunnan from "../../../resource/images/china-region/yunnan.webp";
import shanxisheng from "../../../resource/images/china-region/shanxisheng.webp";
import gansu from "../../../resource/images/china-region/gansu.webp";
import qinghai from "../../../resource/images/china-region/qinghai.webp";
import taiwan from "../../../resource/images/china-region/taiwan.webp";
import "./style/ExploreChineseImporterRegion.less";

export default function ExploreChineseImporterRegion() {
  return (
    <div className="china-index">
      <div className="china-banner">
        <div className="banner-title">中国</div>
      </div>
      <div className="china-content">
        <div className="content-title">
          <h1 className="title">中国地区</h1>
        </div>
        <div className="content-img">
          <div className="middle-content">
            <img
              src={beijing}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">北京</div>
          </div>
          <div className="middle-content">
            <img
              src={tianjing}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">天津</div>
          </div>
          <div className="middle-content">
            <img
              src={shanghai}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">上海</div>
          </div>
          <div className="middle-content">
            <img
              src={neimeng}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">内蒙</div>
          </div>
          <div className="middle-content">
            <img
              src={guangxi}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">广西</div>
          </div>
        </div>
        <div className="content-img">
          <div className="middle-content">
            <img
              src={xizang}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">西藏</div>
          </div>
          <div className="middle-content">
            <img
              src={ningxia}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">宁夏</div>
          </div>
          <div className="middle-content">
            <img
              src={xinjiang}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">新疆</div>
          </div>
          <div className="middle-content">
            <img
              src={xianggang}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">香港</div>
          </div>
          <div className="middle-content">
            <img
              src={aomen}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">澳门</div>
          </div>
        </div>
        <div className="content-img">
          <div className="middle-content">
            <img
              src={chongqing}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">重庆</div>
          </div>
          <div className="middle-content">
            <img
              src={hebei}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">河北</div>
          </div>
          <div className="middle-content">
            <img
              src={shanxi}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">山西</div>
          </div>
          <div className="middle-content">
            <img
              src={liaoning}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">辽宁</div>
          </div>
          <div className="middle-content">
            <img
              src={jilin}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">吉林</div>
          </div>
        </div>
        <div className="content-img">
          <div className="middle-content">
            <img
              src={heilongjiang}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">黑龙江</div>
          </div>
          <div className="middle-content">
            <img
              src={jiangsu}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">江苏</div>
          </div>
          <div className="middle-content">
            <img
              src={zhejiang}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">浙江</div>
          </div>
          <div className="middle-content">
            <img
              src={anhui}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">安徽</div>
          </div>
          <div className="middle-content">
            <img
              src={fujian}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">福建</div>
          </div>
        </div>
        <div className="content-img">
          <div className="middle-content">
            <img
              src={jiangxi}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">江西</div>
          </div>
          <div className="middle-content">
            <img
              src={shandong}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">山东</div>
          </div>
          <div className="middle-content">
            <img
              src={henan}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">河南</div>
          </div>
          <div className="middle-content">
            <img
              src={hubei}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">湖北</div>
          </div>
          <div className="middle-content">
            <img
              src={hunan}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">湖南</div>
          </div>
        </div>
        <div className="content-img">
          <div className="middle-content">
            <img
              src={guangdong}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">广东</div>
          </div>
          <div className="middle-content">
            <img
              src={hainan}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">海南</div>
          </div>
          <div className="middle-content">
            <img
              src={sichuan}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">四川</div>
          </div>
          <div className="middle-content">
            <img
              src={guizhou}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">贵州</div>
          </div>
          <div className="middle-content">
            <img
              src={yunnan}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">云南</div>
          </div>
        </div>
        <div className="content-img">
          <div className="middle-content">
            <img
              src={shanxisheng}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">陕西</div>
          </div>
          <div className="middle-content">
            <img
              src={gansu}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">甘肃</div>
          </div>
          <div className="middle-content">
            <img
              src={qinghai}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">青海</div>
          </div>
          <div className="middle-content">
            <img
              src={taiwan}
              alt="landscape-china"
              className="landscape-china"
            />
            <div className="middle-content-zi">台湾</div>
          </div>
        </div>
      </div>
    </div>
  );
}
