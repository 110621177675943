import React, { useState, useEffect } from "react";
import VideoBasic from "./VideoBasic";
import { localizeImg } from "../../../constants/constants";
import "./style/videoListRow.less";
import { useTranslation } from "react-i18next";

export default function VideoListRow(props) {
  const { data } = props;

  const { t, i18n } = useTranslation();

  let [month, setMonth] = useState("");
  let [year, setYear] = useState("");

  useEffect(() => {
    setYear(data.key.substr(0, 4));
    setMonth(data.key.substr(5, 2));
  }, [data]);

  return (
    <div className="video-list-item-container">
      <div className="feed-images-index">
        <div className="image-month">
          {year}
          {t("YEAR")}
          {month} {t("MONTH")}
        </div>
      </div>
      <div className="feed-images">
        {data.items.map((item, index) => {
          return (
            <div className="feed-list-image-box">
              <VideoBasic url={localizeImg(item)} width={"100%"} />
            </div>
          );
        })}
      </div>
    </div>
    // <div className="video-list-item-container">
    //   <VideoBasic url={localizeImg(data.items[0])} width={"100%"} height={350} />
    // </div>
  );
}
