export const SysEnvLanguageType = {
    ZH: 'zh',
    EN: 'en',
    FR: 'fr',
    ZH_Hans: 'zh-Hans',
}

export const SYS_ENV_LANGUAGE = process.env.REACT_APP_SYS_HOME;

export const SYS_ENV_PRODUCT_DEPOSIT = isNaN(Number(process.env.REACT_APP_DEPOSIT_AMOUT)) ? 1 : (Number(process.env.REACT_APP_DEPOSIT_AMOUT));

export const SYS_ENV_BANK_ACCOUNT_NAME = process.env.REACT_APP_BANK_ACCOUNT_NAME;
export const SYS_ENV_BANK_ACCOUNT_NUMBER = process.env.REACT_APP_BANK_ACCOUNT_NUMBER;
export const SYS_ENV_BANK_OF_DEPOSIT = process.env.REACT_APP_BANK_OF_DEPOSIT;
export const SYS_ENV_PHONE_NUMBER = process.env.REACT_APP_PHONE_NUMBER;
export const SYS_ENV_PLATFORM_MAIL = process.env.REACT_APP_PLATFORM_MAIL;

export const GLOBAL_IMAGE_URL_PREFIX = "https://media.vinbip.com/"
export const CN_IMAGE_URL_PREFIX = "https://media.vinbip.cn/"
export const FORCE_IMAGE_URL_PREFIX = ""
//don't commit with a FORCE value, only for dev
//export const FORCE_IMAGE_URL_PREFIX = "https://media.vinbip.cn/"