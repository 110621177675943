import React, { useState } from "react";
import cn from "classnames";
import styles from "./Search.module.sass";
import Icon from "../../Icon";
import Item from "./Item";
import Suggestion from "./Suggestion";
import { searchSelectList } from "../../../../api/feed";
import { CardType } from "../../../../constants/cardType.enum";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../../contexts/UserIdProvider";
import ModalNews from "../../ModalNews";
import { getEventDetail } from "../../../../api/events";
import { Path, PathTools } from "../../../../constants/path";
import { useHistory } from "react-router-dom";
import { searchHistoryAdd, searchHistorys } from "../../../../api/search";
import { localizeImg, localizeValue } from "../../../../constants/constants";
import { UserType } from "../../../../constants/usersType.enum";

const result = [
  {
    title: "Put your title here",
    content: "Small caption",
    image: "/images/content/product-pic-3.jpg",
    image2x: "/images/content/product-pic-3@2x.jpg",
  },
  {
    title: "Put your title here",
    content: "Small caption",
    image: "/images/content/product-pic-4.jpg",
    image2x: "/images/content/product-pic-4@2x.jpg",
  },
];

const suggestions = [
  {
    title: "Put your title here",
    content: "Small caption",
    icon: "photos",
  },
  {
    title: "Put your title here",
    content: "Small caption",
    icon: "photos",
  },
];

const Search = ({ className }) => {
  const { t, i18n } = useTranslation();
  const { isImporter, isWinery, user } = useUserId();

  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [visibleModalEvent, setVisibleModalEvent] = useState(false);

  const [searchHistory, setSearchHistory] = useState([]);
  const [searchList, setSearchList] = useState([]);

  //Selected value
  const [activeProductId, setActiveProductId] = useState("");
  const [activeEvent, setActiveEvent] = useState({});

  const [timer, setTimer] = useState(null);

  const onSearchKeywordChange = async (e) => {
    setVisible(true);

    let val = e.target.value;

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      // Trigger the search function after 0.5 seconds
      onSearch(val);
      console.log("Begin Search...");
    }, 500);

    setTimer(newTimer);
  };

  const onSearch = async (val) => {
    // getSearchHistory(val);

    // setSearchKey(val);
    let pms = { keyword: val };
    let result = await searchSelectList(pms);
    console.log("searchSelectList:", result);
    let list = [];
    result.forEach((item) => {
      let searchItem;
      if (item.cardType === CardType.FEED) {
        searchItem = {
          label: item.card.content,
          value: item.card._id,
          cardType: item.cardType,
          icon: "message",
        };
      }
      if (item.cardType === CardType.COMPANY) {
        searchItem = {
          label: localizeValue(i18n.language, item.card.companyName),
          value: item.card.adminUserId,
          cardType: item.cardType,
          icon: item.card.avatar
            ? localizeImg(item.card.avatar)
            : "profile-circle",
        };
      }
      if (item.cardType === CardType.BUYER) {
        searchItem = {
          label: item.card.nickName,
          value: item.card._id,
          cardType: item.cardType,
          icon:
            item.card.companyDesc && item.card.companyDesc.avatar
              ? localizeImg(item.card.companyDesc.avatar)
              : "profile-circle",
        };
      }
      if (item.cardType === CardType.SUPPLIER) {
        searchItem = {
          label: item.card.nickName,
          value: item.card._id,
          cardType: item.cardType,
          icon:
            item.card.companyDesc && item.card.companyDesc.avatar
              ? localizeImg(item.card.companyDesc.avatar)
              : "store",
        };
      }
      if (item.cardType === CardType.TENDER) {
        searchItem = {
          label: item.card.title,
          value: item.card._id,
          cardType: item.cardType,
          icon: "notification",
        };
      }
      if (
        item.cardType === CardType.PRODUCT &&
        user?.userInfo?.type == UserType.BUYER
      ) {
        searchItem = {
          label: localizeValue(i18n.language, item.card.name),
          value: item.card._id,
          cardType: item.cardType,
          icon: "bottle",
        };
      }
      if (item.cardType === CardType.EVENT) {
        searchItem = {
          label: item.card.title,
          value: item.card._id,
          cardType: item.cardType,
          icon: "notification",
        };
      }
      if (searchItem && searchItem.label) {
        // if (!isWinery && item.cardType === CardType.TENDER) {
        //   return;
        // }
        //searchItem.label = t(item.cardType) + "-" + searchItem.label;
        //searchItem.label = searchItem.label;
        list.push(searchItem);
      }
    });
    setSearchList(list.slice(0, 4));
  };

  const getSearchHistory = async (label) => {
    const result = await searchHistorys({ userId: user.userId, label: label });
    if (result && result.total > 0) {
      result.result.map((mapItem) => {
        mapItem.cardType = mapItem.type;

        if (mapItem.cardType === CardType.FEED) {
          mapItem.icon = "message";
        }
        if (mapItem.cardType === CardType.BUYER) {
          mapItem.icon = "profile-circle";
        }
        if (mapItem.cardType === CardType.SUPPLIER) {
          mapItem.icon = "store";
        }
        if (mapItem.cardType === CardType.TENDER) {
          mapItem.icon = "notification";
        }
        if (mapItem.cardType === CardType.PRODUCT && isImporter) {
          mapItem.icon = "bottle";
        }
        if (mapItem.cardType === CardType.EVENT) {
          mapItem.icon = "notification";
        }
      });
      setSearchHistory(result.result.slice(0, 2));
    }
  };

  const onSearchItemClick = async (item) => {
    setVisible(false);
    addSearchHistory(item);
    if (item.cardType == CardType.PRODUCT) {
      setActiveProductId(item.value);
      onNavigationToProductInfo(item.productId || item.value);
    } else if (item.cardType == CardType.EVENT) {
      const eventResult = await getEventDetail({ _id: item.value });
      if (eventResult) {
        setActiveEvent(eventResult);
        setVisibleModalEvent(true);
      }
    } else if (
      item.cardType == CardType.SUPPLIER ||
      item.cardType == CardType.BUYER ||
      item.cardType == CardType.COMPANY
    ) {
      history.push(PathTools.joinPath(Path.companyDetail, item.label), {
        id: item.value,
      });
    }
  };

  const onNavigationToProductInfo = (id) => {
    history.push(PathTools.joinPath(Path.productInfo, id));
  };

  const addSearchHistory = async (params) => {
    const pms = {
      userId: user.userId,
      coId: user.userInfo.companyId,
      type: params.cardType,
      ...params,
    };
    await searchHistoryAdd(pms);
    console.log(result);
  };

  return (
    <>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name="search" size="24" />
          </button>
          <button className={styles.direction}>
            <Icon name="arrow-left" size="24" />
          </button>
          <input
            className={styles.input}
            type="text"
            placeholder={t("SEARCH_PLACEHOLDER")}
            onChange={onSearchKeywordChange.bind(this)}
          />
          {/* <button className={styles.result}>⌘ F</button> */}
          <button className={styles.close} onClick={() => setVisible(false)}>
            <Icon name="close-circle" size="24" />
          </button>
        </div>
        <div
          className={styles.body}
          style={{
            overflowY: "auto",
          }}
        >
          {/* <div className={styles.box}>
            <div className={styles.category}>{t("RECENT_SEARCH")}</div>
            <div className={styles.list}>
              {searchHistory.map((x, index) => (
                <Suggestion
                  className={styles.item}
                  item={x}
                  key={index}
                  onClick={onSearchItemClick.bind(this, x)}
                />
              ))}
            </div>
          </div> */}
          <div className={styles.box}>
            <div className={styles.category}>{t("SUGGESTIONS")}</div>
            <div className={styles.list}>
              {searchList.map((x, index) => (
                <Suggestion
                  className={styles.item}
                  item={x}
                  key={index}
                  onClick={onSearchItemClick.bind(this, x)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <ModalNews
        visible={visibleModalEvent}
        news={activeEvent}
        onClose={() => setVisibleModalEvent(false)}
      />
    </>
  );
};

export default Search;
