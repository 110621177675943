import { createCheckoutSession } from "../../api/stripe";

export default function BrandRegister() {
  const submitToStripe = async () => {
    let data = await createCheckoutSession({
      priceId: "price_1JjMrDCTcstZ5itJSFWq6jVA",
    });
    if (data.message === "REDIRECT") {
      window.location.href = data.result;
    }
  };

  //price_1JjMrDCTcstZ5itJSFWq6jVA
  return (
    <section>
      <div className="product">
        <img
          src="https://i.imgur.com/EHyR2nP.png"
          alt="The cover of Stubborn Attachments"
        />
        <div className="description">
          <h3>Brand Registration</h3>
          <h5>€79.99</h5>
        </div>
      </div>
      <button onClick={submitToStripe}>Checkout</button>
    </section>
  );
}
