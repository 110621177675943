import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../core-digital-template/components/Card";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../core-digital-template/components/Dropdown";
import { useUserId } from "../../../contexts/UserIdProvider";
import { deepClone } from "../../../utils/utils";
import { companyDetail } from "../../../api/company";
import { LanguagesKey } from "../../../constants/languages.enum";

export default function NameAndDescription(props) {
  const { className, onChange, dataSource, companyId } = props;

  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const history = useHistory();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [formData, setFormData] = useState({});

  const [languages, setLanguages] = useState([
    {
      label: LanguagesKey.ZH,
      value: "zh",
    },
    {
      label: LanguagesKey.EN,
      value: "en",
    },
    {
      label: LanguagesKey.FR,
      value: "fr",
    },
  ]);

  useEffect(() => {
    if (companyId) {
      loadCompanyDesc(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const loadCompanyDesc = async (coId) => {
    let tempCompanyDesc = await companyDetail(coId);
    console.log("tempCompanyDesc:", tempCompanyDesc);

    setFormData(tempCompanyDesc);
  };

  const setFormDataValue = (name, value) => {
    let temp = deepClone(formData);
    temp[name] = value;
    setFormData(temp);

    onChange && onChange(temp);

    console.log("setFormDataValue:", temp);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={t("LANGUAGE_SETTINGS")}
        classTitle="title-red"
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Dropdown
                className={styles.dropdown_field}
                label={t("LANGUAGE")}
                tooltip=""
                value={formData?.language}
                setValue={setFormDataValue.bind(this, "language")}
                options={languages}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
