import { Dropdown, Input, Menu } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style/addressSelect.less";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { searchAddressByAMap } from "../../../../api/common";
import { constants, deepClone } from "../../../../utils/utils";

export default function AddressSelect(props) {
  const { t } = useTranslation();
  const { label, value, index, onChange, disabled, country, language } = props;
  const [val, setVal] = useState();

  //"60c85d4e011f981d0823532e"
  const [useAMap, setUserAmap] = useState(true);

  const [amapSuggestions, setAmapSuggestions] = useState([]);

  useEffect(() => {
    if (value) setVal(value.name);
  }, [value]);

  useEffect(() => {
    console.log("---------------------country:", country);
    if (country) {
      setUserAmap(country == constants.chinaCountryId ? true : false);
    } else {
      setUserAmap(true);
    }
  }, [country]);

  useEffect(() => {
    if (!useAMap) {
      const geocoder = new MapboxGeocoder({
        accessToken: constants.mapBoxAccessToken,
        types: "place,postcode,address,locality",
      });

      geocoder.addTo("#geocoder");

      // Get the geocoder results container.
      // const results = document.getElementById("result");

      // Add geocoder result to container.
      geocoder.on("result", (e) => {
        // results.innerText = JSON.stringify(e.result, null, 2);
        console.log(e.result);
        onChange(
          {
            name: e.result.place_name,
            location: e.result.center.join(","),
          },
          index
        );
        setVal(e.result.place_name);
      });

      // Clear results container when search is cleared.
      geocoder.on("clear", () => {
        // results.innerText = "";
        setVal("");
        onChange(
          {
            name: "",
            location: "",
          },
          index
        );
      });

      document
        .getElementsByClassName("mapboxgl-ctrl-geocoder--input")
        ["0"].setAttribute(
          val && val.length > 0 ? "value" : "placeholder",
          val || t(label)
        );
    }
  }, [useAMap]);

  const onInputChange = async (e) => {
    setVal(e.target.value);
    let result = await searchAddressByAMap(e.target.value);
    console.log("amap search result", result);
    if (
      result.status == 200 &&
      result.data.tips &&
      result.data.tips.length > 0
    ) {
      let temp = deepClone(amapSuggestions);
      temp = result.data.tips;
      setAmapSuggestions(temp);
    }
  };

  const onSuggestionItemClick = (item) => {
    console.log(item.name);
    onChange(
      {
        name: item.address + item.name,
        location: item.location,
      },
      index
    );
    setVal(item.address + item.name);
  };

  const menuSuggestions = (
    <Menu
      style={{
        width: "350px",
      }}
    >
      {amapSuggestions.map((item, index) => (
        <Menu.Item
          key={index}
          className="mapboxgl-ctrl-geocoder--suggestion"
          onClick={onSuggestionItemClick.bind(this, item)}
        >
          <span className="mapboxgl-ctrl-geocoder--suggestion-title">
            {item.name}
          </span>
          <span className="mapboxgl-ctrl-geocoder--suggestion-address">
            {item.address}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    // <div>
    //   <div id="geocoder"></div>
    //   <pre id="result"></pre>
    // </div>
    <>
      <div
        className="input-title"
        style={
          {
            // marginBottom: "10px",
          }
        }
      >
        <span
          style={{
            color: "#333333",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {t(label)}
        </span>
      </div>
      <div>
        {!useAMap ? (
          <div
            id="geocoder"
            style={{
              height: "48px !important",
            }}
          ></div>
        ) : (
          <Dropdown overlay={menuSuggestions} trigger={["click"]}>
            <Input
              bordered={false}
              maxLength={200}
              placeholder={t(label)}
              value={val}
              disabled={disabled}
              onChange={onInputChange.bind(this)}
              autocomplete="one-time-code"
            />
          </Dropdown>
        )}
      </div>
    </>
    // <>
    //   <div className="input-title">
    //     <span
    //       style={{
    //         color: "#333333",
    //         fontSize: "14px",
    //         fontWeight: "400",
    //       }}
    //     >
    //       {t(label)}
    //     </span>
    //   </div>
    //   <div>
    //     <Input
    //       bordered={false}
    //       maxLength={200}
    //       placeholder={t(label)}
    //       value={val}
    //       disabled={disabled}
    //       onChange={onInputChange.bind(this)}
    //       autocomplete="one-time-code"
    //     />
    //   </div>
    // </>
  );
}
