import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./PartnerSearch.module.sass";
import Profile from "./Profile";
import FilterPanel from "./FilterPanel";
import Card from "../../core-digital-template/components/Card";
import Filters from "../../core-digital-template/components/Filters";
import Product from "../../core-digital-template/components/Product";

// data
import { useUserId } from "../../contexts/UserIdProvider";
import { useTranslation } from "react-i18next";
import {
  getProductConfigForSelect,
  getProductSearch,
  loadProductConfig,
} from "../../api/product";
import { Carousel } from "antd";
import exploreImage1 from "../../resource/images/discover/explore_1.jpg";
import exploreImage2 from "../../resource/images/discover/explore_2.jpg";
import exploreImage3 from "../../resource/images/discover/explore_3.jpg";
import exploreImage4 from "../../resource/images/discover/explore_4.jpg";
import CategoryModal from "./CategoryModal";
import CountryModal from "./CountryModal";
import StockModal from "./StockModal";
import PriceModal from "./PriceModal";
import { BrowseRecordType } from "../../constants/browseRecord.enum";
import { browseRecordCreate } from "../../api/browseRecord";
import { CardType } from "../../constants/cardType.enum";
import { deepClone } from "../../utils/utils";
import Slider from "react-slick";
import RegionModal from "./RegionModal";
import { ProductConfig } from "../../constants/constants";
import Icon from "../../core-digital-template/components/Icon";
import { adminBuyerList, buyerSearch } from "../../api/user";
import UserTile from "../../core-digital-template/components/UserTile";

export default function PartnerSearch() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const coId = user.userInfo.companyId;

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [navigation, setNavigation] = useState([
    { label: t("COUNTRY"), name: "country" },
    { label: t("REGION"), name: "region" },
    // { label: t("CATEGORY"), name: "type" },
    // { label: t("STOCK_LOCATION"), name: "stock" },
    // { label: t("PRICE"), name: "priceRange" },
    // t("STOCK_LOCATION"),
  ]);

  const [sliderList, setSliderList] = useState([
    {
      url: exploreImage1,
      keyword: "Medoc",
      country: "France",
      id: "64dc30586cde865c5685c8e0",
    },
    {
      url: exploreImage2,
      keyword: "Southwest",
      country: "France",
      id: "64dc30a16cde865c5685c8e1",
    },
    {
      url: exploreImage3,
      keyword: "Central Valley",
      country: "Chile",
      id: "64dc316d6cde865c5685c8e3",
    },
    {
      url: exploreImage4,
      keyword: "Napa Valley",
      country: "USA",
      id: "64dc32056cde865c5685c8e5",
    },
  ]);

  const [showCountryFilterModal, setShowCountryFilterModal] = useState(false);
  const [showRegionFilterModal, setShowRegionFilterModal] = useState(false);
  const [showCategoryFilterModal, setShowCategoryFilterModal] = useState(false);
  const [showStockFilterModal, setShowStockFilterModal] = useState(false);
  const [showPriceFilterModal, setShowPriceFilterModal] = useState(false);

  const intervals = ["Most recent", "Most new", "Most popular"];

  const [activeIndex, setActiveIndex] = useState(-1);
  const [sorting, setSorting] = useState(intervals[0]);

  const [filterModel, setFilterModel] = useState({});
  const [productList, setProductList] = useState([]);

  const [buyerList, setBuyerList] = useState([]);

  const [selectedChanelsValue, setSelectedChanelsValue] = useState([]);

  const [price, setPrice] = useState([0, 880]);
  const [priceStep, setPriceStep] = useState(80);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 10;

  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedRegion, setSelectedRegion] = useState({});

  const [productTags, setProductTags] = useState([]);

  const [distributionChannels, setdistributionChannels] = useState([
    {
      label: t("RESTAURANT_AND_BAR"),
      value: "RESTAURANT_AND_BAR",
      icon: "restaurant",
      selected: false,
    },
    {
      label: t("WHOLE_SALE"),
      value: "WHOLE_SALE",
      icon: "forklift",
      selected: false,
    },
    { label: t("HOTEL"), value: "HOTEL", icon: "hotel", selected: false },
    {
      label: t("TOBACO_AND_LIQUOR_STORE"),
      value: "TOBACO_AND_LIQUOR_STORE",
      icon: "tobacco",
      selected: false,
    },
    { label: t("MULTICHANNEL"), value: "MULTICHANNEL", selected: false },
    { label: t("E-COMMERCE"), value: "E-COMMERCE", selected: false },
    { label: t("SUPERMARKET"), value: "SUPERMARKET", selected: false },
    {
      label: t("CORPORATEBUSINESS"),
      value: "CORPORATEBUSINESS",
      selected: false,
    },
  ]);

  useEffect(() => {
    loadBuyerList();
    initProductTags();
  }, []);

  useEffect(() => {
    loadBuyerList();
  }, [filterModel]);

  const initProductTags = async () => {
    let configData = await loadProductConfig();
    let { tags } = configData;

    let tempTags = getProductConfigForSelect(tags, currentLanguage);

    if (tempTags && tempTags.length > 0) {
      setProductTags(tempTags);
    }
  };

  const onFilterModelChange = (name, e) => {
    let temp = deepClone(filterModel);
    temp[name] = e.value;
    setFilterModel(temp);

    let tempNavigation = deepClone(navigation);
    tempNavigation.map((mapItem) => {
      if (mapItem.name === name) {
        mapItem["label"] = e.label;
      }
    });
    setNavigation(tempNavigation);

    if (name == "country") {
      let tempSelectedCountry = deepClone(selectedCountry);
      tempSelectedCountry = deepClone(e);
      setSelectedCountry(tempSelectedCountry);
    } else if (name == "region") {
      let tempSelectedRegion = deepClone(selectedRegion);
      tempSelectedRegion = deepClone(e);
      setSelectedRegion(tempSelectedRegion);
    }
  };

  const onFilterPriceChange = (e) => {
    let temp = deepClone(filterModel);
    temp["priceBegin"] = e.prices[0];
    temp["priceEnd"] = e.prices[1];
    setFilterModel(temp);

    const name = "priceRange";

    let tempNavigation = deepClone(navigation);
    tempNavigation.map((mapItem) => {
      if (mapItem.name === name) {
        mapItem["label"] = e.prices.join("-") + e.currency;
      }
    });
    setNavigation(tempNavigation);
  };

  const loadBuyerList = async () => {
    let pms = { ...filterModel };

    let result = await buyerSearch(pms);
    console.log("loadBuyerList:", result);
    if (result && result.total > 0) {
      setBuyerList(result.result);
    } else {
      setBuyerList([]);
    }
  };

  const onNavigationItemClick = (index) => {
    setActiveIndex(index);
    switch (index) {
      case 0:
        setShowCountryFilterModal(true);
        break;
      case 1:
        setShowRegionFilterModal(true);
        break;
      case 2:
        setShowCategoryFilterModal(true);
        break;
      case 3:
        setShowStockFilterModal(true);
        break;
      case 4:
        setShowPriceFilterModal(true);
        break;
    }
  };

  const settings = {
    infinite: true,
    className: "center",
    lazyLoad: true,
    //autoplaySpeed: 5000,
    //speed: 1000,
    //autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    //centerMode: true,
    variableWidth: true,
    adaptiveHeight: true,
    swipeToSlide: true,
    arrows: false,
  };

  const onDistributionChannelClick = (index) => {
    let temp = deepClone(distributionChannels);
    temp.map((mapItem) => {
      mapItem.selected = false;
    });
    temp[index].selected = true;
    setdistributionChannels(temp);

    onFilterModelChange("label", temp[index]);
  };

  const onSliderItemClick = (item) => {
    let temp = deepClone(filterModel);
    temp["subRegion"] = item.id;
    setFilterModel(temp);
  };

  const handleClearNavigationFilter = (event, index) => {
    event.stopPropagation();

    setNavigation([
      { label: t("COUNTRY"), name: "country" },
      { label: t("REGION"), name: "region" },
    ]);

    setActiveIndex(-1);

    setdistributionChannels([
      {
        label: t("RESTAURANT_AND_BAR"),
        value: "RESTAURANT_AND_BAR",
        icon: "restaurant",
        selected: false,
      },
      {
        label: t("WHOLE_SALE"),
        value: "WHOLE_SALE",
        icon: "forklift",
        selected: false,
      },
      { label: t("HOTEL"), value: "HOTEL", icon: "hotel", selected: false },
      {
        label: t("TOBACO_AND_LIQUOR_STORE"),
        value: "TOBACO_AND_LIQUOR_STORE",
        icon: "tobacco",
        selected: false,
      },
      { label: t("MULTICHANNEL"), value: "MULTICHANNEL", selected: false },
      { label: t("E-COMMERCE"), value: "E-COMMERCE", selected: false },
      { label: t("SUPERMARKET"), value: "SUPERMARKET", selected: false },
      {
        label: t("CORPORATEBUSINESS"),
        value: "CORPORATEBUSINESS",
        selected: false,
      },
    ]);
  };

  return (
    <Card className={styles.card}>
      <div className={styles.control}>
        <div className={[styles.nav, "navigationBar"]}>
          <span className={styles.filtertitle}>{t("FILTER")}</span>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              style={{ position: "relative" }}
              onClick={onNavigationItemClick.bind(this, index)}
              key={index}
            >
              {x.label}
              {index === activeIndex && (
                <span
                  className={styles.clearNavigationFilter}
                  onClick={(event) => handleClearNavigationFilter(event, index)}
                >
                  x
                </span>
              )}
            </button>
          ))}
        </div>
        {/* <>
              <div className={styles.dropdownBox}>
                <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={sorting}
                  setValue={setSorting}
                  options={intervals}
                  small
                />
              </div>

              <Filters className={styles.filters} title={t("MORE_FILTER")}>
                <FilterPanel
                  onConfirm={onSearchConfirm.bind(this)}
                  onReset={onResetConfirm.bind(this)}
                  filterRegion={selectedRegion}
                />
              </Filters>
            </>  */}
      </div>
      <div className={styles.wrap}>
        <div className={styles.tags}>
          {distributionChannels.map((mapItem, index) => {
            return (
              <div
                className={
                  mapItem.selected ? styles.tagItemSelected : styles.tagItem
                }
                onClick={onDistributionChannelClick.bind(this, index)}
              >
                <Icon size={24} name={mapItem?.icon} />
                <span
                  className={
                    mapItem.selected ? styles.tagLabelSelected : styles.tagLabel
                  }
                >
                  {mapItem.label}
                  {mapItem.selected && (
                    <span
                      className={styles.clearNavigationFilter}
                      onClick={(event) =>
                        handleClearNavigationFilter(event, index)
                      }
                    >
                      x
                    </span>
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.partners}>
          {buyerList.map((buyerItem) => {
            return <UserTile item={buyerItem} />;
          })}
        </div>
      </div>
      <CountryModal
        isShow={showCountryFilterModal}
        onClose={() => setShowCountryFilterModal(false)}
        onChange={onFilterModelChange.bind(this, "country")}
      />
      <RegionModal
        isShow={showRegionFilterModal}
        filterCountry={selectedCountry}
        onClose={() => setShowRegionFilterModal(false)}
        onChange={onFilterModelChange.bind(this, "region")}
      />
      <CategoryModal
        isShow={showCategoryFilterModal}
        onClose={() => setShowCategoryFilterModal(false)}
        onChange={onFilterModelChange.bind(this, "type")}
      />
      <StockModal
        isShow={showStockFilterModal}
        onClose={() => setShowStockFilterModal(false)}
        onChange={onFilterModelChange.bind(this, "stock")}
      />
      <PriceModal
        isShow={showPriceFilterModal}
        onClose={() => setShowPriceFilterModal(false)}
        onChange={onFilterPriceChange.bind(this)}
      />
    </Card>
  );
}
