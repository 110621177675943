import { Form, InputNumber } from "antd";
import React, { useState, useEffect } from "react";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import enWorldJson from "world_countries_lists/data/countries/en/world.json";
import frWorldJson from "world_countries_lists/data/countries/fr/world.json";
import zhWorldJson from "world_countries_lists/data/countries/zh/world.json";
import { useTranslation } from "react-i18next";

export default function InputPhoneNumber(props) {
  let { label, value, index, onChange, onBlur, showErr, errMsg } = props;
  const { t, i18n } = useTranslation();
  const [currentPhoneTranslation, setCurrentPhoneTranslation] =
    useState(enWorldJson);
  let layout = value ? { value } : {};
  useEffect(() => {
    let language = i18n.language;
    if (language === "en") {
      setCurrentPhoneTranslation(enWorldJson);
    }
    if (language === "fr") {
      setCurrentPhoneTranslation(frWorldJson);
    }
    if (language === "zh") {
      setCurrentPhoneTranslation(zhWorldJson);
    }
  }, [i18n.language]);

  const [showErrInfo, setShowErrorInfo] = useState(showErr);
  const [errInfo, setErrInfo] = useState(errMsg);

  useEffect(() => {
    setShowErrorInfo(showErr);
  }, [showErr]);

  useEffect(() => {
    setErrInfo(errMsg);
  }, [errMsg]);

  const onInputChange = (val) => {
    onChange(index, val);
  };
  const handlePhoneChange = (value) => {
    if (value) {
      onChange(value, index);
      // setPhone(`${value.code}${value.phone}`);
    }
  };

  const onInputBlur = (e) => {
    onBlur && onBlur(e.target.value);
  };

  return (
    <div>
      <div className="control-normal-container" key={index}>
        <ConfigProvider locale={currentPhoneTranslation}>
          <CountryPhoneInput
            {...layout}
            className="common-select"
            placeholder={t(label)}
            bordered={false}
            onChange={(value) => handlePhoneChange(value)}
            onBlur={onInputBlur.bind(this)}
          />
        </ConfigProvider>
      </div>
      {showErrInfo && <div style={{ color: "red" }}>{errInfo}</div>}
    </div>
  );
}
