import { Input } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style/numberCenter.less";

export default function NumberCenter(props) {
  const { t } = useTranslation();
  const { label, value, onChange, disabled, placeholder, addonAfter, width } =
    props;
  const [val, setVal] = useState();
  // console.log('------input normal:', label, value, index);

  useEffect(() => {
    setVal(value);
  }, [value]);
  const onInputChange = (e) => {
    // console.log('------input normal input change:', e.target.value);
    onChange(e.target.value);
    setVal(e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        paddingRight: "10px",
        borderRadius: "5px",
        justifyContent: "space-between",
        border: "1px solid #DEDEDE",
        width: width ? width : "155px",
      }}
    >
      <Input
        bordered={false}
        maxLength={200}
        placeholder={t(placeholder ? placeholder : label)}
        value={val}
        disabled={disabled}
        onChange={onInputChange.bind(this)}
        autocomplete="one-time-code"
      />
      <span
        style={{
          color: "#333333",
          fontSize: "14px",
          textAlign: "right",
        }}
      >
        {addonAfter}
      </span>
    </div>
  );
}
