import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./VideoUploader.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { getUploadFileAction } from "../../../api/common";
import { useUserId } from "../../../contexts/UserIdProvider";
import { uploadFile } from "../../../api/file";
import { statusCode } from "../../../constants/statusCode.enum";
import { localizeImg } from "../../../constants/constants";
import { deepClone } from "../../../utils/utils";
import ProductVideoUpload from "../../../components/common/upload/ProductVideoUpload";
import { Upload } from "antd";
import UploadBase from "../../../components/common/upload/UploadBase";
import { useTranslation } from "react-i18next";

export default function VideoUploader(props) {
  const { className, label, tooltip, title, onChange, dataSource } = props;

  const { t } = useTranslation();
  const { user } = useUserId();

  const [imgUrlList, setImgUrlList] = useState([]);

  const [uploadTitle, setUploadTitle] = useState(title);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (dataSource) {
      let temp = deepClone(imgUrlList);
      temp = dataSource;
      setImgUrlList(temp);
    }
  }, [dataSource]);

  useEffect(() => {
    onChange && onChange(imgUrlList);
  }, [imgUrlList]);

  useEffect(() => {
    setUploadTitle(title);
  }, [title]);

  const onBrandLogoLoading = (fileType, url, name) => {
    setIsUploading(true);
    // console.log("onBrandLogoLoading", { fileType, url, name });
    // if (imgUrlList.length == 0) {
    //   let temp = deepClone(imgUrlList);
    //   temp.push(url);
    //   setImgUrlList(temp);
    // }
  };

  const onBrandLogoUpload = async (fileType, url, name, size) => {
    let content = { fileType, url, name, size };
    console.log("onBrandLogoUpload:", content);
    // setTradMarkDetail("logo", content);
    let temp = deepClone(imgUrlList);
    temp.push(url);
    setImgUrlList(temp);
    setIsUploading(false);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    console.log(file);
    formData.append("file", file);

    // const reader = new FileReader();
    // reader.onload = (event) => {
    //   const dataUrl = event.target.result;
    // };
    // reader.readAsDataURL(file);

    try {
      let url = getUploadFileAction(user.userId);
      let data = await uploadFile(url, formData, (progress) => {
        console.log(progress);
      });

      console.log("uploadFile result:", data);
      if (data.statusCode == statusCode.SUCCESS) {
        let temp = deepClone(imgUrlList);
        temp.push(data.result);
        setImgUrlList(temp);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = () => {
    let temp2 = deepClone(imgUrlList);
    temp2 = [];
    setImgUrlList(temp2);
  };

  return (
    <>
      <div className={cn(styles.file, className)}>
        {label && (
          <div className={styles.label}>
            {label}{" "}
            {tooltip && (
              <Tooltip
                className={styles.tooltip}
                title={tooltip}
                icon="info"
                place="right"
              />
            )}
          </div>
        )}
        <div className={styles.wrap}>
          {/* <input
            className={styles.input}
            type="file"
            onChange={handleFileUpload}
          /> */}
          {imgUrlList.length > 0 && (
            <div style={{ position: "relative" }}>
              <ProductVideoUpload
                url={imgUrlList.length === 0 ? null : imgUrlList[0]}
                title={"VIDEO"}
              />
              <div onClick={handleDelete.bind(this)}>
                <Icon
                  name="close-circle"
                  size="24"
                  className={styles.closeIcon}
                  style={{ position: "absolute", right: "10px", top: "10px" }}
                />
              </div>
            </div>
          )}
          <div className={styles.input}>
            <div className={styles.box}>
              {!isUploading && (
                <>
                  <UploadBase
                    onLoading={onBrandLogoLoading.bind(this)}
                    onLoaded={onBrandLogoUpload.bind(this)}
                  >
                  </UploadBase>
                  <Icon name="upload" size="24" />
                  {uploadTitle}
                </>
              )}
              {isUploading && (
                <>
                  <Icon name="upload" size="24" />
                  {t("UPLOADING")}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
