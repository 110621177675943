import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import {
  warehouseCreate,
  warehouseRemove,
  warehouses,
  warehouseUpdate,
} from "../../../api/warehouse";
import { statusCode } from "../../../constants/statusCode.enum";
import "./style/warehouse.less";
import WarehouseCreate from "./WarehouseCreate";
import { deepClone } from "../../../utils/utils";
import { getCountryNameById } from "../../../api/config";
import { loadProductConfig } from "../../../api/product";
import removeIcon from "../../../resource/icons/dashboard/address/remove.svg";

export default function Warehouse() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  let [data, setData] = useState([]);

  const [currentWarehouse, setCurrentWarehouse] = useState({});
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    initConfigData();
    loadData();
  }, [user.userId]);

  const loadData = async () => {
    let pms = { coId: user.userInfo.companyId };
    let data = await warehouses(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      let list = data.result.result;
      if (list && list.length > 0) {
        list = list.map((item, index) => {
          return { ...item, key: index };
        });
        console.log("--------------loadData:", list);
        setData(list);
      }
    }
  };

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const onDelete = async (id) => {
    let pms = { warehouseId: id };
    let data = await warehouseRemove(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      loadData();
    }
  };

  const showDrawer = (item) => {
    setCreateModalVisible(true);
    // console.log("now language...", i18n.language);
    // setCurrentStaff(item ? item : null);
    // setVisible(true);
  };

  const onComplete = async () => {
    let tempPms = deepClone(currentWarehouse);
    tempPms.location = {
      country: tempPms.country,
      city: [tempPms.province, tempPms.city],
      address: tempPms.address,
    };
    let pms = {
      userId: user.userId,
      coId: user.userInfo.companyId,
      ...tempPms,
    };
    console.log("pms:", pms);
    let data;
    if (currentWarehouse._id) {
      data = await warehouseUpdate(pms);
    } else {
      data = await warehouseCreate(pms);
    }
    console.log("result:", data);
    if (data && data.statusCode === statusCode.SUCCESS) {
      loadData(user.userId);
      setCreateModalVisible(false);
    }
  };

  const onClose = () => {};

  const onChange = (val) => {
    console.log("onChange:", val);
    setCurrentWarehouse(val);
  };

  return (
    <div className="common-page">
      {/* <div>
        <Link to={{ pathname: "/warehouse/create" }}>Add</Link>
      </div> */}
      <div className="common-management warehouse-buyer-management-panel">
        <div className="warehouse-address-supplier-panel">
          <div className="warehouse-supplier-create-panel">
            <Button onClick={showDrawer.bind(this)} className="create-button">
              {t("NEW_DELIVERY_ADDRESS")}
            </Button>
            <span className="desc">
              {"("}
              {t("YOU_HAVE_CREATED")}
              {data.length}
              {t("SHIPPING_ADDRESS_UP_TO_10_CAN_BE_CREATED")}
              {")"}
            </span>
          </div>
          {data.map((mapItem, mapIndex) => {
            return (
              <div className="warehouse-address-item" key={mapIndex}>
                <div className="title">
                  <span>
                    {mapItem.name}{" "}
                    {getCountryNameById(
                      locations,
                      i18n.language,
                      mapItem.location.country
                    )}
                  </span>
                  <img
                    src={removeIcon}
                    onClick={onDelete.bind(this, mapItem._id)}
                  />
                </div>
                <div className="address-row">
                  <span className="label">{t("CONTACT_NAME")}</span>
                  <span className="value">{mapItem.name}</span>
                </div>
                <div className="address-row">
                  <span className="label">{t("PHONE_NUMBER")}</span>
                  <span className="value">{mapItem.phoneNumber}</span>
                </div>
                <div className="address-row">
                  <span className="label">{t("COUNTRY")}</span>
                  <span className="value">
                    {getCountryNameById(
                      locations,
                      i18n.language,
                      mapItem.location.country
                    )}
                  </span>
                </div>
                <div
                  className="address-row"
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex" }}>
                    <span className="label">{t("ADDRESS")}</span>
                    <span className="value">
                      {mapItem.location.address.name}
                    </span>
                  </div>
                  <div className="btn-edit" onClick={showDrawer.bind(this)}>
                    {t("EDIT")}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Modal
          destroyOnClose={true}
          title={t("ADD_SHIPPING_ADDRESS")}
          visible={createModalVisible}
          footer={null}
          width={501}
          height={756}
          closable={false}
          className="warehouse-create-modal"
          onCancel={() => setCreateModalVisible(false)}
        >
          <WarehouseCreate
            onComplete={onComplete.bind(this)}
            onClose={onClose.bind(this)}
            onChange={onChange.bind(this)}
            currentWarehouse={currentWarehouse}
          ></WarehouseCreate>
        </Modal>
      </div>
    </div>
  );
}
