import React, { useState, useEffect } from "react";
import { Button, Space, Select, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import "./style/SendSampleCreate.less";
import { loadProductsForSelect } from "../../../api/product";
import { createSendSample, updateSendSample } from "../../../api/orders";
import { TableSourceMaxCount } from "../../../constants/constants";
import { pageFriends } from "../../../api/contact";

export default function SendSampleCreate(props) {
  const { detail, onComplete, onClose } = props;
  const { t, i18n } = useTranslation();
  const { user } = useUserId();
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState();
  const [_id, setID] = useState();
  const [product, setProduct] = useState([]);
  const [friendCoId, setFriendCoId] = useState();
  const [friendUserId, setFriendUserId] = useState();
  const [friends, setFriends] = useState([]);

  useEffect(() => {
    loadProductSelectOptions(user.userInfo.companyId);
    loadFriends();
    if (detail) {
      loadDetail();
    }
  }, []);

  const loadDetail = async () => {
    let { productId, _id, count, userId, coId } = detail;
    setID(_id);
    setCount(count);
    setProduct(productId);
    setFriendCoId(coId);
    setFriendUserId(userId);
  };

  const loadProductSelectOptions = async (coId) => {
    let result = await loadProductsForSelect(coId, i18n.language);
    // console.log('------loadProductsForSelect:', result);
    setProducts(result);
  };

  const loadFriends = async () => {
    let pms = { page: 0, per_page: TableSourceMaxCount };
    let result = await pageFriends(user.userId, pms);
    if (result) {
      result = result.map((item) => {
        return { label: item.name, value: item._id, coId: item.companyId };
      });
      setFriends(result);
    }
  };

  const onFinish = async () => {
    let pms = {
      coId: friendCoId,
      userId: friendUserId,
      productCoId: user.userInfo.companyId,
      productId: product,
      count,
    };

    let result;
    if (_id) {
      pms._id = _id;
      result = await updateSendSample(pms);
    } else {
      result = await createSendSample(pms);
    }
    onComplete && onComplete();
  };

  const onCloseClick = () => {
    onClose && onClose();
  };

  const onProductChange = (val) => {
    setProduct(val);
  };

  const onFriendChange = (val, item) => {
    setFriendUserId(val);
    setFriendCoId(item.coId);
  };

  return (
    <div className="send-sample-create-container">
      <div className="modal-body">
        <div className="modal-title">{t("SEND_SAMPLE")}</div>
        <div className="modal-content-column">
          <div className="content-row-label">{t("PRODUCT")}</div>
          <Select
            value={product}
            options={products}
            className="content-row-select"
            onChange={onProductChange.bind(this)}
          />
        </div>
        <div className="modal-content-column">
          <div className="content-row-label">{t("CONTACT")}</div>
          <Select
            value={friendUserId}
            options={friends}
            className="content-row-select"
            onChange={onFriendChange.bind(this)}
          />
        </div>
        <div className="modal-content-column">
          <span className="content-row-label">{t("QUANTITY")}</span>
          <InputNumber
            value={count}
            className="content-row-input-number"
            min="0"
            max="100"
            onChange={(val) => setCount(val)}
          />
        </div>
        <Space size={8} className="modal-confirm-row">
          <Button
            className="modal-confirm-btn"
            onClick={onCloseClick.bind(this)}
          >
            {t("CANCEL")}
          </Button>
          <Button
            htmlType="submit"
            className="modal-confirm-btn-primary"
            onClick={onFinish.bind(this)}
          >
            {t("SAVE")}
          </Button>
        </Space>
      </div>
    </div>
  );
}
