import {
  actionRequest,
  formatActionResponse,
  formatActionResponseArray,
  formatResponse,
} from "./api";
import { RequestType } from "../constants/requestType.enum";
import apiPath from "./apipath";
import { requestGetUrlFormat } from "./common";

/**
 *
 * @param pms
 */
export async function openFeeds(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.openFeeds, pms)
  );
  return formatResponse(data);
}

/**
 *
 * @param pms
 */
export function openRecommandUsers() {
  return formatActionResponse(RequestType.GET, apiPath.openRecommandUsers, {});
}

/**
 * @description Get the company details
 * @param coId Company ID
 */
export async function openCompanyDetail(coId: string) {
  let data = await actionRequest(
    RequestType.GET,
    apiPath.openCompanyInfo + "/" + coId
  );
  console.log("openCompanyDetail", data);
  return formatResponse(data);
}

/**
 * @description Get the company details
 * @param coId Company ID
 */
export async function openCompanyInfoByName(pms: any) {
  let data = await actionRequest(
    RequestType.GET,
    apiPath.companyInfoByName,
    pms
  );
  return formatResponse(data);
}

/**
 * @description Get the company description of a winery
 * @param pms
 */
export async function openCompanyWineryDesc(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.openWineryDescInfo, pms)
  );
}

/**
 * @description Get the company description of an importer
 * @param pms
 */
export async function openCompanyImporterDesc(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.openImporterDescInfo, pms)
  );
}

/**
 *
 * @param pms
 */
export async function openSponsorPage(pms: any) {
  return formatActionResponseArray(RequestType.GET, apiPath.openSponrsors, pms);
}

/**
 *
 * @param pms
 */
export async function openShopifyStorefrontAccesstoken(pms: any) {
  return actionRequest(
    RequestType.GET,
    requestGetUrlFormat(apiPath.openShopifyStorefrontAccesstoken, pms)
  );
}
