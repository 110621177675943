export const CardType = {
  FEEDDIVDER: "FEEDDIVDER",
  FEED: "FEED",
  FEED_COMMENT: "FEED_COMMENT",
  TENDERDIVDER: "TENDERDIVDER",
  TENDER: "TENDER",
  PRODUCTDIVDER: "PRODUCTDIVDER",
  PRODUCT: "PRODUCT",
  ARTICLEDIVDER: "ARTICLEDIVDER",
  ARTICLE: "ARTICLE",
  PRODUCTCLASSIFICATION: "PRODUCT_CLASSIFICATION",
  USER: "USER",
  EVENT: "EVENT",
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  WINERY: "WINERY",
  NEGOCIANT: "NEGOCIANT",
  COPERATIVE: "COPERATIVE",
  IMPORTER: "IMPORTER",
  CORPORATEBUSINESS: "CORPORATEBUSINESS",
  DISTRIBUTOR: "DISTRIBUTOR",
  SALON: "SALON",
  BUYER: "BUYER",
  SUPPLIER: "SUPPLIER",
  COMPANY:"COMPANY"
};
