import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useUserId } from "../../../contexts/UserIdProvider";
import { deepClone } from "../../../utils/utils";
import "./style/notificationSetting.less";
import selectedIcon from "../../../resource/icons/dashboard/notification/selected.svg";
import unSelectedIcon from "../../../resource/icons/dashboard/notification/unselected.svg";
import downArrowIcon from "../../../resource/icons/dashboard/notification/down_arrow.svg";
import { Dropdown, Menu } from "antd";

export default function NotificationSetting() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const { index } = useParams();

  const [settingItems, setSettingItems] = useState([]);
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    initSettingItems();
    initMenuList();
  }, [index]);

  const initMenuList = () => {
    let temp = deepClone(menuList);
    temp=[];
    temp.push(
      ...[
        {
          label: t("CLOSE"),
          value: "CLOSE",
        },
        {
          label: t("BY_SMS"),
          value: "BY_SMS",
        },
        {
          label: t("BY_EMAIL"),
          value: "BY_EMAIL",
        },
        {
          label: t("PUSH_NOTIFICATION"),
          value: "PUSH_NOTIFICATION",
        },
      ]
    );
    setMenuList(temp);
  };

  const initSettingItems = () => {
    let temp = deepClone(settingItems);
    temp = [];
    if (index == 0) {
      temp.push(
        ...[
          {
            label: t("ON_VINBIP_NETWORK"),
            value: "ON_VINBIP_NETWORK",
            children: [
              { label: t("NEW_CONTACT_REQUEST"), value: "NEW_CONTACT_REQUEST" },
              { label: t("MENTIONED_BY_PEOPLE"), value: "MENTIONED_BY_PEOPLE" },
              {
                label: t("UPDATE_FROM_YOUR_SUPPLIERS"),
                value: "UPDATE_FROM_YOUR_SUPPLIERS",
              },
              {
                label: t("UPDATE_FROM_YOUR_CONTACT"),
                value: "UPDATE_FROM_YOUR_CONTACT",
              },
              {
                label: t("UPDATE_FROM_YOUR_COLLEAGUES"),
                value: "UPDATE_FROM_YOUR_COLLEAGUES",
              },
              { label: t("MESSAGE"), value: "MESSAGE" },
              { label: t("COMMENTS"), value: "COMMENTS" },
            ],
          },
          {
            label: t("VINBIP_MARKET_PLACE"),
            value: "VINBIP_MARKET_PLACE",
            children: [
              {
                label: t("ORDER_UPDATE_BY_SUPPLIERS"),
                value: "ORDER_UPDATE_BY_SUPPLIERS",
              },
              {
                label: t("MESSAGES_PHONE_CALLS_FROM_SUPPLIERS"),
                value: "MESSAGES_PHONE_CALLS_FROM_SUPPLIERS",
              },
            ],
          },
        ]
      );
    } else {
      temp.push(
        ...[
          { label: t("EVENTS"), value: "EVENTS" },
          { label: t("RECOMMANDATIONS"), value: "RECOMMANDATIONS" },
          {
            label: t("FEEDS"),
            value: "FEEDS",
          },
          {
            label: t("ANY_EMERGENCY_ALERTS"),
            value: "ANY_EMERGENCY_ALERTS",
          },
        ]
      );
    }

    console.log(temp);
    setSettingItems(temp);
  };

  const menu_notification_setting = (
    <Menu>
      {menuList.map((mapItem, mapIndex) => {
        return (
          <Menu.Item
            key={mapIndex}
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* {mapItem.selected ? (
              <img src={selectedIcon} />
            ) : (
              <img src={unSelectedIcon} />
            )}{" "} */}
            {mapItem.label}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className="common-page">
      <div className="common-management notification-setting-management-panel">
        <div className="content-panel">
          {index == 0 &&
            settingItems.length > 0 &&
            settingItems.map((mapItem, mapIndex) => {
              return (
                <div
                  className="map-type"
                  style={{
                    marginTop:
                      mapIndex == settingItems.length - 1 ? "20px" : "0px",
                  }}
                >
                  <div>{mapItem.label}</div>
                  <div className="map-children">
                    {mapItem.children &&
                      mapItem.children.map((subMapItem, subMapIndex) => {
                        return (
                          <div className="sub-map-row">
                            <div>{subMapItem.label}</div>
                            <Dropdown
                              overlay={menu_notification_setting}
                              trigger={["click"]}
                            >
                              <img
                                src={downArrowIcon}
                                style={{ cursor: "pointer" }}
                              />
                            </Dropdown>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          {index == 1 &&
            settingItems.length > 0 &&
            settingItems.map((mapItem, mapIndex) => {
              return (
                <div className="sub-map-row">
                  <div>{mapItem.label}</div>
                  <Dropdown
                    overlay={menu_notification_setting}
                    trigger={["click"]}
                  >
                    <img src={downArrowIcon} style={{ cursor: "pointer" }} />
                  </Dropdown>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
