import { useUserId } from "../../../contexts/UserIdProvider";
import {UserType} from "../../../constants/usersType.enum";
import DiscoverWinery from "./DiscoverWinery";
import DiscoverImporter from "./DiscoverImporter";
import "./style/feed-discover.less";

export default function Discover() {
  const { user } = useUserId();
  const userType = user.userInfo.type;

  return (
    <>
      {userType==UserType.SUPPLIER ?
        <DiscoverWinery />
        :
        <DiscoverImporter />
      }
    </>
  );
}
