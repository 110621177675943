import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export default function InputEmail(props) {
  const { t } = useTranslation();
  const { label, value, index, onChange, onBlur, showErr, errMsg, disabled } =
    props;

  const [showErrInfo, setShowErrorInfo] = useState(showErr);
  const [errInfo, setErrInfo] = useState(errMsg);

  useEffect(() => {
    setShowErrorInfo(showErr);
  }, [showErr]);

  useEffect(() => {
    setErrInfo(errMsg);
  }, [errMsg]);

  // console.log('------input normal:', label, value, index);
  const onInputChange = (e) => {
    onChange(e.target.value, index);
  };

  const onInputBlur = (e) => {
    onBlur && onBlur(e.target.value);
  };

  return (
    <>
      <div className="input-title">
        <span
          style={{
            color: "#333333",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {t(label)}
        </span>
      </div>
      <div>
        <Input
          bordered={false}
          maxLength={200}
          value={value}
          disabled={disabled}
          placeholder={t(label)}
          onChange={onInputChange.bind(this)}
          onBlur={onInputBlur.bind(this)}
        />
      </div>
    </>
  );
}
