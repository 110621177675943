import { Avatar } from "antd";
import defaultAvatarIcon from "../../../resource/icons/common/default_avatar.svg";

export default function AvatarBasic(props) {
  const colorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
  const { picture, displayName, _id, shape, size } = props;

  /**
   *
   * @param arr An array of hex color
   * @returns a random colors that can be used as background
   */
  const getRandomColor = (arr) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };

  /**
   *
   * @param name
   * @returns 2 Char of that string
   */
  const sliceName = (name) => {
    return name.slice(0, 2).toUpperCase();
  };

  return (
    <Avatar
      className={props.cname}
      shape={shape ? shape : "circle"}
      style={{
        // backgroundColor: getRandomColor(colorList),
        // verticalAlign: 'middle',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // borderColor: "#dedede",
        // borderWidth: "1px",
        // borderStyle: "solid",
      }}
      size={size ? size : 25}
      src={picture ? picture : defaultAvatarIcon}
      onClick={props.onClick}
    >
      {/* {displayName && displayName.length <= 5 && displayName}
      {displayName && displayName.length >= 6 && sliceName(displayName)} */}
    </Avatar>
  );
}
