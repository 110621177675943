import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style/productCardItemForHome.less";
import {
  localizeImg,
  localizeTitle,
  ProductCurrencyType,
} from "../../../constants/constants";
import imagePlaceholder from "../../../resource/images/wine-botlle/vin-001.jpeg";
import {
  getProductConfigNameById,
  getProductPriceRangeText,
  loadProductConfig,
  getProductTotalStock,
  getProductPriceRangeTextWithCurrency,
} from "../../../api/product";

export default function ProductCardItemForHome(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { item } = props;
  // console.log("item.volumes : ", item.volumes)
  const [productTitle, setProductTitle] = useState();
  const [productId, setProductId] = useState();
  const [currentLanguage, setCurrentLanguage] = useState();
  const [myCategories, setMyCategories] = useState();
  const [productPrice, setProductPrice] = useState();

  useEffect(() => {
    if(item){
      const { title, volumes, currency } = item;
      setProductTitle(localizeTitle(i18n.language, title));
      setProductPrice(getPriceRangeText(volumes, currency));
      setProductId(item.id || item._id);
      setConfigData();
    }
  }, [item]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const toProductDetail = () => {
    history.push("/products/detail/" + productId);
  };

  const getPriceRangeText = (volumeList, currency) => {
    let priceText = getProductPriceRangeTextWithCurrency(volumeList,currency);
    return priceText;
  };

  const setConfigData = async () => {
    const configData = await loadProductConfig();
    const { categories } = configData;
    setMyCategories(categories);
  };

  return (
    <div
      className="profile-product-display cursor-pointer"
      onClick={toProductDetail.bind(this)}
    >
      {item && (
        <div className="other-image-container">
          <img
            src={
              item.bottleImages && item.bottleImages.length > 0
                ? localizeImg(item.bottleImages[0])
                : imagePlaceholder
            }
            className="other-image"
          />
        </div>
      )}
      {item && (
        <div className="other-desc">
          <div className="other-title ellipsis-text">{productTitle}</div>
          <div className="other-sub-title">
            {getProductConfigNameById(
              myCategories,
              currentLanguage,
              item.category
            )}
          </div>
          <div className="other-desc-content">
            {productPrice && (
              <div className="other-desc-price">{productPrice}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
