import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Button, Input, Space, DatePicker } from "antd";

export default function AddMeeting(props) {
  const { onClose, onSubmit } = props;
  const { t } = useTranslation();
  const [name, setName] = useState();
  const [date, setDate] = useState();
  const [comments, setComments] = useState();

  const onCancel = () => {
    onClose && onClose();
  };

  const onSure = () => {
    let meeting = { name, date, comments };
    onSubmit && onSubmit(meeting);
    onCancel();
  };

  function onDateChange(value, dateString) {
    // console.log('Selected Time: ', value);
    // console.log('Formatted Selected Time: ', dateString);
    setDate(dateString);
  }

  return (
    <div className="modal-body">
      <div className="modal-title">添加会议</div>
      <div className="modal-content-row">
        <span className="content-row-label">会议主题</span>
        <Input
          className="content-row-input"
          onInput={(e) => setName(e.target.value)}
        />
      </div>
      <div className="modal-content-row">
        <div className="content-row-label">会议时间</div>
        <DatePicker
          className="content-row-input"
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          onChange={onDateChange.bind(this)}
        />
      </div>
      <div className="modal-content-row">
        <span className="content-row-label">备注</span>
        <Input.TextArea
          row={4}
          className="content-row-textarea"
          onInput={(e) => setComments(e.target.value)}
        />
      </div>
      <Space size={8} className="modal-confirm-row">
        <Button className="modal-confirm-btn" onClick={onCancel.bind(this)}>
          {t("CANCEL")}
        </Button>
        <Button
          className="modal-confirm-btn-primary"
          onClick={onSure.bind(this)}
        >
          {t("SAVE")}
        </Button>
      </Space>
    </div>
  );
}
