import React, { useState } from "react";
import { Input, Button, Space } from "antd";
import { useTranslation } from "react-i18next";

export default function ProductTagCreate(props) {
  const { onClose, onOk } = props;

  const [name, setName] = useState("");
  const { t } = useTranslation();

  const onFinish = async () => {
    onOk && onOk(name);
    onClose && onClose();
  };

  const onCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="modal-body">
      <div className="modal-title">{t("ADD_TAGS")}</div>
      <div className="modal-content-row">
        <span className="content-row-label">{t("TAG_NAME")}</span>
        <Input
          value={name}
          placeholder={t("PLEASE_ENTER_A_TAG_NAME")}
          className="content-row-input"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <Space size={8} className="modal-confirm-row">
        <Button
          className="modal-confirm-btn"
          style={{
            width: "140px",
          }}
          onClick={onCloseClick.bind(this)}
        >
          {t("CANCEL")}
        </Button>
        <Button
          className="modal-confirm-btn-primary"
          style={{
            width: "140px",
          }}
          onClick={onFinish.bind(this)}
        >
          {t("CONFIRM")}
        </Button>
      </Space>
    </div>
  );
}
