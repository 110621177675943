import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import { getOrders } from "../../../api/orders";
import { Link } from "react-router-dom";
import OrdersListItem from "../order/OrdersListItem";
import ListBasic from "../../common/list/ListBasic";
import "../order/style/orders.less";
import { deepClone } from "../../../utils/utils";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Samples() {
  const { t } = useTranslation();
  const { isWinery, user } = useUserId();
  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const pageSize = 20;
  const [pageIndex, setPageIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let pms = { isSample: true, page: pageIndex, per_page: pageSize };
    if (isWinery) {
      pms.coId = user.userInfo.companyId;
    } else {
      pms.userId = user.userId;
    }
    let result = await getOrders(pms);
    console.log('-------orders samples:', pms, result);
    if (result) {
      result = result.filter((item) => item.products && item.products.length > 0);
      if (pageIndex > 0) {
        let list = deepClone(orderList);
        result = list.concat(result);
      }
      setOrderList(result);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const onRenderOrderItem = (item, index) => {
    return <OrdersListItem item={item} index={index} />;
  };

  const handleInfiniteOnLoad = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div className="common-page">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to={"/dashboard"}>{t("DASHBOARDS")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("SEND_SAMPLE")}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="company-orders-container common-management ">
        <div className="orders-title common-management-title">
          {t("SEND_SAMPLE")}
        </div>
        <InfiniteScroll
          className="scroll-content common-management-panel"
          dataLength={orderList.length}
          next={handleInfiniteOnLoad.bind(this)}
          hasMore={hasMore}
        >
          <ListBasic
            data={orderList}
            renderItem={onRenderOrderItem.bind(this)}
            loading={loading}
          />
        </InfiniteScroll>

      </div>
    </div>
  );
}
