import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./UserTile.module.sass";
import Control from "./Control";
import Icon from "../Icon";
import Checkbox from "../Checkbox";
import { localizeImg, localizeValue } from "../../../constants/constants";
import { useTranslation } from "react-i18next";
import AvatarBasic from "../../../components/common/avatar/AvatarBasic";
import {
  getProductConfigNameById,
  loadProductConfig,
  productFavoriteCount,
  productFavoriteStatus,
} from "../../../api/product";
import { getCountryNameById } from "../../../api/config";
import { companyDetail } from "../../../api/company";
import { useUserId } from "../../../contexts/UserIdProvider";
import { useHistory } from "react-router";
import { Path, PathTools } from "../../../constants/path";

const UserTile = ({
  className,
  item,
  value,
  onChange,
  released,
  withoutСheckbox,
  isMyself,
}) => {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [regionName, setRegionName] = useState("");
  const [subRegionName, setSubRegionName] = useState("");
  const [classificationName, setClassificationName] = useState();

  const [companyInfo, setCompanyInfo] = useState({});

  const [favoriteCount, setFavoriteCount] = useState(0);
  const [favoriteStatus, setFavoriteStatus] = useState(false);

  const onNavToComProfile = (companyName) => {
    history.push(
      PathTools.joinPath(
        Path.companyDetail,
        localizeValue(i18n.language, companyName)
      )
    );
  };

  console.log(item?.avatar);

  return (
    <div
      className={cn(styles.user, className, {
        [styles.active]: visible,
      })}
      onClick={onNavToComProfile.bind(this, item?.companyName)}
    >
      <div className={styles.line}>
        <div className={styles.bgimage}>
          {item?.medias && (
            <img
              srcSet={`${localizeImg(item?.medias[0])} 2x`}
              src={localizeImg(item?.medias[0])}
              alt="Product"
            />
          )}
        </div>
      </div>
      {item && (
        <div className={styles.line}>
          <div className={styles.avatar}>
            <AvatarBasic picture={localizeImg(item?.avatar[0])} size={80} />
          </div>
        </div>
      )}
      <div className={styles.line}>
        <div className={styles.company}>
          {localizeValue(i18n.language, item?.companyName)}
        </div>
      </div>
      <div className={styles.line}>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: localizeValue(i18n.language, item?.desc)?.substring(0, 130),
          }}
        ></div>
      </div>
      <div className={styles.line}>
        <div className={styles.rating}>
          <span className={styles.counter}>{t("VIEW_COMPANY_PROFILE")}</span>
          <Icon name="arrow-next" size="24" />
        </div>
      </div>
    </div>
  );
};

export default UserTile;
