import React from "react";
import landscapeFrance from "../../../resource/images/france-region/landscape-france.jpg";
import "./style/exploreFranceWineryRegion.less";
import { useTranslation } from "react-i18next";
import ChampagneArdennes from "../../../resource/images/france-region/champagne-ardennes.jpeg";
import Bordeaux from "../../../resource/images/france-region/bordeaux.jpeg";
import Bourgogne from "../../../resource/images/france-region/Bourgogne.jpeg";
import ValleeDuRhone from "../../../resource/images/france-region/vallee-du-Rhone.jpeg";
import ValleeDeLaLoire from "../../../resource/images/france-region/vallee_de_la_loire.jpeg";
import Provence from "../../../resource/images/france-region/provence.jpeg";
import LanguedocRoussillon from "../../../resource/images/france-region/languedoc-Roussillon.jpeg";
import SudOuest from "../../../resource/images/france-region/sud-ouest.jpeg";
import Beaujolais from "../../../resource/images/france-region/beaujolais.jpeg";
import Alsace from "../../../resource/images/france-region/alsace.jpeg";
import IleDeFrance from "../../../resource/images/france-region/ile-de-france.jpeg";
import Cognac from "../../../resource/images/france-region/cognac.jpeg";

export default function ExploreFranceWineryRegion() {
  const { t } = useTranslation();
  return (
    <div className="explore-france-region-page">
      <div className="explore-banner">
        <div className="explore-title">Explore wine regions in France</div>
      </div>
      <div className="explore-content">
        <div className="title">
          <h1 className="title-content">{t("发现法国的葡萄酒产区")}</h1>
        </div>
        <div className="img">
          <div className="middle-content">
            <img
              src={ChampagneArdennes}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-zi">{t("BORDEAUX")}</div>
          </div>
          <div className="middle-content">
            <img
              src={Bordeaux}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-zi">{t("BURGUNDY")}</div>
          </div>
          <div className="middle-content">
            <img
              src={Bourgogne}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-zi">{t("BURGUNDY")}</div>
          </div>
          <div className="middle-content">
            <img
              src={ValleeDuRhone}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-zi">{t("RHONE")}</div>
          </div>
        </div>
        <div className="img">
          <div className="middle-content">
            <img
              src={ValleeDeLaLoire}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-zi">{t("LOIRE")}</div>
          </div>
          <div className="middle-content">
            <img
              src={Provence}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-zi">{t("PROVENCE")}</div>
          </div>
          <div className="middle-content">
            <img
              src={LanguedocRoussillon}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-zi">{t("LANGUEDOC")}</div>
          </div>
          <div className="middle-content">
            <img
              src={SudOuest}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-zi">{t("SOUTH_WEST")}</div>
          </div>
        </div>
        <div className="img">
          <div className="middle-content">
            <img
              src={Beaujolais}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-zi">{t("BEAUJOLAIS")}</div>
          </div>
          <div className="middle-content">
            <img
              src={Alsace}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-zi">{t("ALSACE")}</div>
          </div>
          <div className="middle-content">
            <img
              src={IleDeFrance}
              alt="landscape-france"
              className="landscape-france"
            />
            <div className="middle-content-zi">{t("ILE_DE_FRANCE")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
