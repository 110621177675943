import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Search from "./Search";
import Messages from "./Messages";
import Notification from "./Notification";
import User from "./User";
import { useUserId } from "../../../contexts/UserIdProvider";
import { Path, PathTools } from "../../../constants/path";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import PostCreator from "../../../components/amity-uikit/social/components/post/Creator";
import {
  AmityApiKey,
  localizeImg,
  localizeValue,
} from "../../../constants/constants";
import { AmityUiKitProvider } from "../../../components/amity-uikit";
import Dropdown from "../Dropdown";
import { UserType } from "../../../constants/usersType.enum";
import { userSupplierList } from "../../../api/user";
import { getCache, setCache } from "../../../utils/cache";
import { useIMContext } from "../../../contexts/IMProvider";
import {
  AudioCallStatus,
  ChatContentsType,
} from "../../../constants/chatContentsType.enum";
import notificationSound from "../../../resource/audio/notification.mp3";
import useAgora from "../../../hooks/useAgora";
import AudioCall from "../../../components/imchat/AudioCall";
import VideoCall from "../../../components/imchat/VideoCall";
import { PostTargetType } from '@amityco/js-sdk';
import VinbipLogo from "../VinbipLogo";


const Header = ({ onOpen }) => {
  const { user, loadUserInfo, isWinery } = useUserId();
  const { t, i18n } = useTranslation();

  const {
    imLoginStatus,
    webIM,
    agoraClient,
    sessionMessageList,
    newMsg,
    chatCallMsg,
    initIMLogin,
    getSessionMessage,
    setChatCallMsg,
    setChatNewMsg,
  } = useIMContext();
  const { leave } = useAgora(agoraClient);

  const apiKey = AmityApiKey;
  const loginFromAdmin = "LOGIN_IN_FROM_ADMIN";

  const [suppliers, setSuppliers] = useState([]);
  const [showSupplierList, setShowSupplierList] = useState(false);

  const [visible, setVisible] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  // 音频通话
  const [callMessage, setCallMessage] = useState();
  const [meetingGroupId, setMeetingGroupId] = useState();
  const [callStatus, setCallStatus] = useState();
  const [audioModalVisible, setAudioModalVisible] = useState(false);
  // 视频通话
  const [videoModalVisible, setVideoModalVisible] = useState(false);

  useEffect(() => {
    let isLoginFromAdmin = getCache(loginFromAdmin);

    if (
      user &&
      user.userInfo &&
      (user.userInfo.type == UserType.ADMIN || isLoginFromAdmin)
    ) {
      setCache(loginFromAdmin, true);
    }
  }, [user]);

  useEffect(() => {
    if (chatCallMsg) {
      setCallMessage(chatCallMsg);
      setCallStatus(AudioCallStatus.CALLING);
      if (chatCallMsg.type === ChatContentsType.AUDIOCALL) {
        setAudioModalVisible(true);
      } else {
        setVideoModalVisible(true);
      }
    }
  }, [chatCallMsg]);

  useEffect(() => {
    if (newMsg) {
      if (newMsg.contentsType === ChatContentsType.TEXT) {
        new Audio(notificationSound).play();
      }
      if (newMsg.contentsType === ChatContentsType.CUSTOM) {
        switch (newMsg.customExts.fileType) {
          case ChatContentsType.AUDIOCALL:
            setCallMessage(newMsg);
            setCallStatus(AudioCallStatus.WAITING);
            setAudioModalVisible(true);
            break;
          case ChatContentsType.VIDEOCALL:
            setCallMessage(newMsg);
            setCallStatus(AudioCallStatus.WAITING);
            setVideoModalVisible(true);
            break;
          case ChatContentsType.CANCELCALL:
            onCallModalClose();
            break;
          case ChatContentsType.REFUSECALL:
            leave();
            onCallModalClose();
            break;
          default:
            break;
        }
      }
      // setChatNewMsg(null);
    }
  }, [newMsg]);

  const onCallModalClose = () => {
    leave();
    setChatCallMsg(null);
    setChatNewMsg(null);
    setAudioModalVisible(false);
    setVideoModalVisible(false);
    getSessionMessage();
  };

  return (
    <header className={styles.header}>
      <Link className={styles.logowithtext} to="/" >
          <VinbipLogo withText />
      </Link>
      <button className={styles.burger} onClick={() => handleClick()}></button>
      
      <div className={styles.control} onClick={() => setVisible(false)}>
      <Search className={cn(styles.search, { [styles.visible]: visible })} />
      <button
        className={cn(styles.buttonSearch, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <Icon name="search" size="24" />
      </button>
        {isWinery && (
          <Link
            className={cn("button", styles.button)}
            onClick={() => setCreateVisible(true)}
            // to={PathTools.joinPath(
            //   Path.productsAdd,
            //   user?.userInfo?.companyDesc?.companyName
            // )}
          >
            <Icon name="add" size="24" />
            <span>{t("New_POST")}</span>
          </Link>
        )}
        {/* <Messages className={styles.messages} /> */}
        {/* <Notification className={styles.notification} /> */}
        <User className={styles.user} />
      </div>
      {/* <div className={styles.btns}>
        <Link className={styles.link} to="/login">
          Sign in
        </Link>
        <Link className={cn("button", styles.button)} to="/sign-up">
          Sign up
        </Link>
      </div> */}
      <Modal
        visible={createVisible}
        footer={null}
        closable={false}
        closeIcon={null}
        destroyOnClose={true}
        title={null}
        onCancel={() => setCreateVisible(false)}
      >
        {/* <div style={{ marginTop: "30px" }}> */}

        <AmityUiKitProvider
          key={user.userId}
          apiKey={apiKey}
          userId={user.userId}
          // displayName={user.userId}
        >
          <PostCreator
            data-qa-anchor="feed-post-creator-textarea"
            targetType={PostTargetType.CommunityFeed}
            targetId={"64259fbf160bdf0c55d6168b"}
            targetUserId={user.userId}
            communities={[]}
            enablePostTargetPicker={false}
            hasMoreCommunities={false}
            // loadMoreCommunities={loadMoreCommunities}
            // onCreateSuccess={onPostCreated}
            onClosePopup={() => setCreateVisible(false)}
            avatar={localizeImg(user?.userInfo?.companyDesc?.avatar)}
          />
        </AmityUiKitProvider>
        {/* </div> */}
      </Modal>
      <Modal
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        visible={audioModalVisible}
        title={null}
        footer={null}
        className="audio-modal"
        onCancel={onCallModalClose.bind(this)}
      >
        <AudioCall
          callMessage={callMessage}
          callStatus={callStatus}
          onClose={onCallModalClose.bind(this)}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={videoModalVisible}
        footer={null}
        maskClosable={false}
        className="video-modal"
        onCancel={onCallModalClose.bind(this)}
      >
        <VideoCall
          callMessage={callMessage}
          callStatus={callStatus}
          onClose={onCallModalClose.bind(this)}
        />
      </Modal>
    </header>
  );
};

export default Header;
