import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Input, Button, message, Modal, Divider, Checkbox } from "antd";
import { useAuth } from "../../contexts/AuthProvider";
import { useUserId } from "../../contexts/UserIdProvider";
import { LoginType, WxAppId, WxAppSecret } from "../../constants/constants";
import loginBg from "../../resource/images/login/login_bg1.png";
import phonelogin from "../../resource/icons/user/phonelogin.svg";
import passwordlogin from "../../resource/icons/user/passwordlogin.svg";
import userPrefixIcon from "../../resource/icons/user/login/user_prefix.svg";
import passwordPrefixIcon from "../../resource/icons/user/login/password_prefix.svg";
import seePasswordIcon from "../../resource/icons/user/login/see_password.svg";
import wechat from "../../resource/icons/user/login/wechat.svg";
import { getTokenExpireTime } from "../../utils/jwt";
import { setBearerToken } from "../../api/api";
import { browseRecordCreate as browseCreate } from "../../api/browseRecord";
import { BrowseType } from "../../constants/browseType.enum";
import {
  getWxAccessToken,
  getWxUserInfo,
  login,
  wxLogin,
  loginByPhone,
  sendSms,
} from "../../api/login";
import { useTranslation } from "react-i18next";
import { toastError, toastInfo } from "../../api/toast";
import { isEmail } from "../../utils/regex";
import GoogleLogin from "./GoogleLogin";
import FacebookLogin from "@greatsumini/react-facebook-login";
import "./style/login.less";
import WxLogin from "./WxLogin";
import { statusCode } from "../../constants/statusCode.enum";
import InputPhoneNumberSimple from "../common/input/InputPhoneNumberSimple";
import { SysEnvLanguageType, SYS_ENV_LANGUAGE } from "../../constants/env.enum";
import LanguageSelect2 from "../common/languages/LanguageSelect2";

const qs = require("query-string");

export default function Login() {
  const { t } = useTranslation();
  const history = useHistory();
  const { state, search } = useLocation();
  const { createToken } = useAuth();
  const { loadUserInfo } = useUserId();
  const [referUrl, setReferUrl] = useState("/");
  const [type, setType] = useState(
    SYS_ENV_LANGUAGE === SysEnvLanguageType.ZH ? "phone" : "email"
  );
  const [account, setAccount] = useState();
  const [loginType, setLoginType] = useState(LoginType.ACCOUNTNUMBER);
  const [password, setPassword] = useState("");
  const [wxloginVisible, setWxloginVisible] = useState(false);
  const [phone, setPhone] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [capture, setCapture] = useState("");
  const [timerStatus, setTimerStatus] = useState(false);
  let countNum = 60;
  const [countDown, setCountDown] = useState(countNum);

  const [termsAgreed, setTermsAgreed] = useState(false);

  let timer;

  useEffect(() => {
    if (state) {
      let { refer, clearToken } = state;
      if (clearToken) {
        localStorage.clear();
      }
      if (refer) {
        setReferUrl(refer);
      }
    }
  }, []);

  useEffect(() => {
    if (search) {
      let searchObj = qs.parse(search);
      let { code } = searchObj;
      if (code) {
        getWxOpenId(code);
      }
    }
  }, [search]);

  useEffect(() => {
    if (timerStatus) {
      timer = setInterval(() => {
        setCountDown(countDown - 1);
        if (countNum === 0) {
          clearInterval(timer);
          setCountDown(60);
          setTimerStatus(false);
        } else {
          countNum--;
          setCountDown(countNum);
        }
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [timerStatus]);

  const getWxOpenId = async (code) => {
    let pms = { appid: WxAppId, appsecret: WxAppSecret, code };
    let result = await getWxAccessToken(pms);
    if (result && result.data) {
      let { openid, access_token } = result.data;
      if (openid && access_token) {
        loadWxUserInfo(access_token, openid);
      }
    }
  };

  const loadWxUserInfo = async (token, openid) => {
    let pms = { token, openid };
    let result = await getWxUserInfo(pms);
    console.log("-------wx userinfo:", result);
    if (result && result.data) {
      let { nickname, headimgurl } = result.data;
      onWxLogin(openid, nickname, headimgurl);
    }
  };

  const onWxLogin = async (openId, nickName, picture) => {
    let pms = { openId };
    let result = await wxLogin(pms);
    if (result) {
      onStoreUser(result);
    } else {
      toastInfo("not regist yet");
      history.replace("/sign-up", { openId, nickName, picture });
    }
  };

  const onWxScan = () => {
    // history.push('wxlogin');
    setWxloginVisible(true);
  };
  const onLoginTypeChange = () => {
    setLoginType(
      loginType === LoginType.ACCOUNTNUMBER
        ? LoginType.PHONECAPTURE
        : LoginType.ACCOUNTNUMBER
    );
  };
  /**
   * callback
   * @param {string} result :server return(包含token和userid)
   */
  const onStoreUser = async (result) => {
    let { access_token, user } = result;
    let userId = user._id;

    await setBearerToken(access_token);

    let expireTime = getTokenExpireTime();
    createToken({ value: access_token, expireTime });
    await loadUserInfo(userId);
    addLoginRecord(userId);
    history.replace(referUrl);
  };

  const addLoginRecord = (userId) => {
    let pms = {
      userId,
      busType: BrowseType.LOGIN,
    };
    browseCreate(pms);
  };

  const onChange = (event) => {
    setType(event.target.value);
  };

  const onFinish = async () => {
    if (!termsAgreed) {
      toastError(t("NEED_AGREE_USER_TERMS"));
      return;
    }

    if (!account) {
      toastError(t("ACCOUNT_IS_EMPTY"));
      return;
    }
    if (!password) {
      toastError(t("PASSWORD_IS_EMPTY"));
      return;
    }
    let pms;
    if (account.indexOf("@") >= 0) {
      let email = account.trim();
      if (!isEmail(email)) {
        toastError(t("INVALID_EMAIL"));
        return;
      }
      pms = { email, password };
    } else {
      let phoneNumber = account.trim();
      // if (!isMobile(account)) {
      //   toastError(t("INVALID_PHONE_NUMBER"));
      //   return;
      // }
      pms = { phoneNumber, password };
    }

    let result = await login(pms);
    if (result) {
      console.log("login res...", result);
      onStoreUser(result);
    } else {
      toastError(t("ACCOUNT_OR_PASSWORD_INCORRECT"));
    }
  };

  const onValueChange = async (e) => {
    let temp = e.target.value;
    setAccount(temp);
  };

  const onPasswordChange = async (e) => {
    let temp = e.target.value;
    setPassword(temp);
  };
  const onFinishPhone = async () => {
    if (!phoneNumber) {
      toastInfo(t("PHONE_NUMBER_IS_EMPTY"));
      return;
    }
    if (isNaN(parseInt(phoneNumber))) {
      toastError(t("INVALID_PHONE_NUMBER"));
      return;
    }
    if (!capture) {
      toastInfo(t("CAPTURE_REQUIRED"));
      return;
    }

    let pms = { phoneNumber: phone, capture };
    // let result = await login(pms);
    let result = await loginByPhone(pms);
    console.log("phone login res...", result);
    if (result && result.statusCode == statusCode.NOTEXISTS) {
      toastError(t("PHONE_NOT_EXIST"));
    } else if (result && result.statusCode == statusCode.SUCCESS) {
      onStoreUser(result.result);
    } else {
      toastError(t("CAPTURE_ERROR_MESSAGE"));
    }
  };

  const onValueChangePhone = async (e) => {
    let temp = e.target.value;
    setPhone(temp);
  };

  const onCaptureChange = async (e) => {
    let temp = e.target.value;
    setCapture(temp);
  };

  const onSendSms = async () => {
    if (!phoneNumber) {
      toastInfo(t("PHONE_NUMBER_IS_EMPTY"));
    } else if (isNaN(parseInt(phoneNumber))) {
      toastError(t("INVALID_PHONE_NUMBER"));
      return;
    } else {
      toastInfo(t("CODE_SEND_SUCCESS"));
      setTimerStatus(true);
      let pms = {
        phone: phoneNumber, //'+' + phone?.code +
        smsType: "Login",
      };
      await sendSms(pms);
      // let result = await sendSms(pms);
      // console.log('----------phone sms :', result, pms);
    }
  };

  const onPhoneChange = (val) => {
    setPhone(val);
    setPhoneNumber(val.phone);
  };

  const onAgreeTermsChange = (e) => {
    console.log("-------onAgreeTermsChange:", e.target.checked);
    setTermsAgreed(e.target.checked);
  };

  const inputPrefixEmpty = () => {
    return (
      <div
        style={{
          width: "4px",
          height: "32px",
          display: "flex",
          position: "relative",
        }}
      ></div>
    );
  };

  return (
    <div className="login1-page">
      <img className="login-bg-img" src={loginBg} />
      <div className="login-bg-container">
        <div className="login-content">
          <div className="lang-panel">
            <LanguageSelect2 />
          </div>
          <div className="reg-left-panel">
            <img src={loginBg} className="login-left-img" />
            <div className="login-left-content">
              <div className="login-left-title">
                <div>WINE B2B</div>
                <div>TRADING</div>
                <div>NETWORKING</div>
              </div>
            </div>
          </div>
          <div className="login-right">
            <div className="login-content-wrapper">
              <div className="login-tips-panel">
                <div className="login-tips">Vinbip</div>
              </div>
              <div className="phone-panel">
                <div className="phone-input-before">
                  {/* {loginType === LoginType.ACCOUNTNUMBER ? (
                    <div> */}
                  <div className="input-label">{t("USER_NAME")}</div>
                  <span style={{ marginRight: "5px" }}>:</span>
                  <Input
                    bordered={false}
                    value={account}
                    prefix={
                      <div
                        style={{
                          width: "39px",
                          height: "32px",
                          display: "flex",
                          position: "relative",
                        }}
                      >
                        <img
                          src={userPrefixIcon}
                          style={{
                            width: "18px",
                            height: "18px",
                            marginLeft: "10px",
                            marginTop: "7px",
                          }}
                        ></img>
                        <div
                          style={{
                            width: "1px",
                            height: "18px",
                            backgroundColor: "#D9D9D9",
                            position: "absolute",
                            right: "0px",
                            top: "7px",
                          }}
                        ></div>
                      </div>
                    }
                    className="login-input"
                    placeholder={t("EMAIL") + " / " + t("PHONE_NUMBER")}
                    onInput={onValueChange.bind(this)}
                  />
                  {/* </div>
                  ) : ( */}
                  {/* // <>
                    //   <span className="phone-tip">+86</span>
                    //   <Input
                    //     value={phone}
                    //     type="text"
                    //     className="login-input"
                    //     placeholder={t("PHONE_NUMBER")}
                    //     onInput={onValueChangePhone.bind(this)}
                    //   />
                    // </>
                  //   <InputPhoneNumberSimple
                  //     value={phone}
                  //     onChange={onPhoneChange.bind(this)}
                  //   />
                  // )} */}
                </div>
              </div>
              {loginType === LoginType.ACCOUNTNUMBER ? (
                <div className="phone-panel">
                  <div className="phone-input-before">
                    <div className="input-label">{t("PASSWORD")}</div>
                    <span style={{ marginRight: "5px" }}>:</span>
                    <Input.Password
                      bordered={false}
                      className="login-input"
                      style={{ border: "none" }}
                      placeholder={t("PASSWORD")}
                      onInput={onPasswordChange.bind(this)}
                      prefix={
                        <div
                          style={{
                            width: "39px",
                            height: "32px",
                            display: "flex",
                            position: "relative",
                          }}
                        >
                          <img
                            src={passwordPrefixIcon}
                            style={{
                              width: "18px",
                              height: "18px",
                              marginLeft: "10px",
                              marginTop: "7px",
                            }}
                          ></img>
                          <div
                            style={{
                              width: "1px",
                              height: "18px",
                              backgroundColor: "#D9D9D9",
                              position: "absolute",
                              right: "0px",
                              top: "7px",
                            }}
                          ></div>
                        </div>
                      }
                      iconRender={(visible) => <img src={seePasswordIcon} />}
                    />
                  </div>
                </div>
              ) : (
                <div className="capture-panel">
                  <div style={{ display: "flex" }}>
                    <div className="input-label">{t("CAPTURE_CODE")}</div>
                    <span style={{ marginRight: "5px" }}>:</span>
                  </div>
                  <Input
                    bordered={false}
                    className=" login-input"
                    type="text"
                    style={{ border: "none", paddingLeft: "10px !important" }}
                    placeholder={t("VERIFICATION_CODE")}
                    onInput={onCaptureChange.bind(this)}
                    prefix={inputPrefixEmpty()}
                  />
                  <Divider type="vertical" className="" />
                  {timerStatus ? (
                    <div className="count-down">
                      {t("RETRY_IN")} : {countDown} {t("SECOND")}
                    </div>
                  ) : (
                    <div className="capture-btn" onClick={onSendSms.bind(this)}>
                      {t("SEND_CODE")}
                    </div>
                  )}
                </div>
              )}
              <div className="forgot-password-panel">
                {/* {loginType === LoginType.ACCOUNTNUMBER && ( */}
                <Link to="/forgot-password" className="forgot-password">
                  {t("FORGOT_PASSWORD")}
                </Link>
                {/* )} */}
              </div>
              {/* {loginType === LoginType.ACCOUNTNUMBER ? ( */}
              <Button onClick={onFinish.bind(this)} className="btn-login">
                {t("LOGIN")}
              </Button>
              {/* ) : (
                <Button
                  onClick={onFinishPhone.bind(this)}
                  className="btn-login"
                >
                  {t("LOGIN")}
                </Button>
              )} */}

              <div className="login-other-type-pannel">
                {SYS_ENV_LANGUAGE !== "zh" && (
                  <>
                    <GoogleLogin />
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                      onSuccess={(response) => {
                        console.log("Login Success!", response);
                      }}
                      onFail={(error) => {
                        console.log("Login Failed!", error);
                      }}
                      onProfileSuccess={(response) => {
                        console.log("Get Profile Success!", response);
                      }}
                    />
                  </>
                )}
                {SYS_ENV_LANGUAGE === "zh" && (
                  <>
                    <img
                      src={wechat}
                      className="other-icon"
                      onClick={onWxScan.bind(this)}
                    />
                    <img
                      src={
                        loginType === LoginType.ACCOUNTNUMBER
                          ? phonelogin
                          : passwordlogin
                      }
                      className="other-icon"
                      onClick={onLoginTypeChange.bind(this)}
                    />
                  </>
                )}
              </div>
              {/* <Button
                className="btn-reg"
                onClick={() => history.push("reg-intro")}
              >
                {t("NOT_MEMBER")} {t("SIGN_UP_TO_VINBIP")}
              </Button> */}
              <div className="terms-privacy-checkbox">
                <Checkbox onChange={onAgreeTermsChange.bind(this)}>
                  {t("AGREE_TERMS")}
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose={true}
        title={null}
        visible={wxloginVisible}
        footer={null}
        className="select-friend-modal"
        onCancel={() => setWxloginVisible(false)}
      >
        <WxLogin />
      </Modal>
    </div>
  );
}
