import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import {
  warehouseCreate,
  warehouseRemove,
  warehouses,
  warehouseUpdate,
} from "../../../api/warehouse";
import { statusCode } from "../../../constants/statusCode.enum";
import "./style/warehouse.less";
import WarehouseCreate from "./WarehouseCreate";
import { deepClone } from "../../../utils/utils";
import { getCountryNameById } from "../../../api/config";
import { loadProductConfig } from "../../../api/product";
import removeIcon from "../../../resource/icons/common/remove.svg";

export default function WarehouseSupplier() {
  const { t, i18n } = useTranslation();
  const { user } = useUserId();

  let [data, setData] = useState([]);

  const [currentWarehouse, setCurrentWarehouse] = useState({});
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    initConfigData();
    loadData();
  }, [user.userId]);

  const columns = [
    { title: t("NAME"), dataIndex: "name", key: "name" },
    { title: t("PHONE_NUMBER"), dataIndex: "phoneNumber", key: "phoneNumber" },
    {
      title: t("LOCATION"),
      dataIndex: "location",
      key: "location",
      render: (val) => (
        <span style={{ color: "#3E6FA4" }}>
          {getCountryNameById(locations, i18n.language, val.country)}
          {val.address.name}
        </span>
      ),
    },
    { title: t("ZIPCODE"), dataIndex: "zipcode", key: "zipcode" },
    {
      title: t("ACTION"),
      dataIndex: "",
      key: "y",
      render: (val) => {
        return (
          <Space size="middle">
            <img src={removeIcon} onClick={onDelete.bind(this, val._id)} />
            {/* <Link
              to={{
                pathname: "/shop/warehouse/create",
                state: { id: val._id, name: val.name, location: val.location },
              }}
            >
              {t("EDIT")}
            </Link>
            <Button type="link" onClick={onDelete.bind(this, val._id)}>
              {t("DELETE")}
            </Button> */}
          </Space>
        );
      },
    },
  ];

  const loadData = async () => {
    let pms = { coId: user.userInfo.companyId };
    let data = await warehouses(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      let list = data.result.result;
      if (list && list.length > 0) {
        list = list.map((item, index) => {
          return { ...item, key: index };
        });
        setData(list);
      }
    }
  };

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const onDelete = async (id) => {
    let pms = { warehouseId: id };
    let data = await warehouseRemove(pms);
    if (data && data.statusCode === statusCode.SUCCESS) {
      loadData();
    }
  };

  const showDrawer = (item) => {
    setCreateModalVisible(true);
    // console.log("now language...", i18n.language);
    // setCurrentStaff(item ? item : null);
    // setVisible(true);
  };

  const onComplete = async () => {
    let tempPms = deepClone(currentWarehouse);
    tempPms.location = {
      country: tempPms.country,
      city: [tempPms.province, tempPms.city],
      address: tempPms.address,
    };
    let pms = {
      userId: user.userId,
      coId: user.userInfo.companyId,
      ...tempPms,
    };
    console.log("pms:", pms);
    let data;
    if (currentWarehouse._id) {
      data = await warehouseUpdate(pms);
    } else {
      data = await warehouseCreate(pms);
    }
    console.log("result:", data);
    if (data && data.statusCode === statusCode.SUCCESS) {
      loadData(user.userId);
      setCreateModalVisible(false);
    }
  };

  const onClose = () => {};

  const onChange = (val) => {
    console.log("onChange:", val);
    setCurrentWarehouse(val);
  };

  return (
    <div className="common-page">
      {/* <div>
        <Link to={{ pathname: "/warehouse/create" }}>Add</Link>
      </div> */}
      <div className="common-management warehouse-management-panel">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          className="warehouse-table common-management-panel"
        />
        <div className="warehouse-create-panel">
          <Button onClick={showDrawer.bind(this)} className="create-button">
            <span className="add-icon">+</span>
            {t("ADD_SHIPPING_ADDRESS")}
          </Button>
        </div>
        <Modal
          destroyOnClose={true}
          title={t("ADD_SHIPPING_ADDRESS")}
          visible={createModalVisible}
          footer={null}
          width={501}
          height={756}
          closable={false}
          className="warehouse-create-modal"
          onCancel={() => setCreateModalVisible(false)}
        >
          <WarehouseCreate
            onComplete={onComplete.bind(this)}
            onClose={onClose.bind(this)}
            onChange={onChange.bind(this)}
            currentWarehouse={currentWarehouse}
          ></WarehouseCreate>
        </Modal>
      </div>
    </div>
  );
}
