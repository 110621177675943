import React, { useEffect, useState } from "react";
import { Button, Modal, Space } from "antd";
import { useTranslation } from "react-i18next";
import "./style/OrdersListItemForNotify.less";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { orderRemove } from "../../../api/orders";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  localizeImg,
  localizeTitle,
  ProductCurrencyType,
} from "../../../constants/constants";
import { useUserId } from "../../../contexts/UserIdProvider";
import { OrderStatus } from "../../../constants/statusCode.enum";

export default function OrdersListItemForNotify(props) {
  const { item, index, itemLoadData } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { isWinery, user } = useUserId();

  const onGoDetail = (orderId) => {
    history.push("/company/order", { orderId });
  };
  const delOrder = async (order) => {
    let pms = { orderId: order._id };

    let result = await orderRemove(pms);
    if (result) {
      Modal.confirm({
        title: t("CONFIRM_DELETE"),
        icon: <ExclamationCircleOutlined />,
        content: t("ARE_YOU_SURE_TO_DELETE_THIS"),
        okText: t("CONFIRM"),
        cancelText: t("CANCEL"),
        onOk: async () => {
          itemLoadData();
        },
      });
    }
  };
  const onProductDetail = (productId) => {
    history.push("/products/detail/" + productId);
  };

  const onGetProductCount = (orderProducts, productId) => {
    let result = "1";
    if (orderProducts && orderProducts.length > 0) {
      let list = orderProducts.filter((item) => item.productId === productId);
      if (list && list.length > 0) {
        result = list[0].count;
      }
    }
    return result;
  };

  const onGetProductPrice = (orderProducts, productId) => {
    let result = "1";
    if (orderProducts && orderProducts.length > 0) {
      let list = orderProducts.filter((item) => item.productId === productId);
      if (list && list.length > 0) {
        result = list[0].price;
      }
    }
    return result;
  };

  return (
    <div className="order-table-item-notify-container" key={index}>
      <div
        className="table-content cursor-pointer"
        onClick={onGoDetail.bind(this, item._id)}
      >
        <div className="order-content">
          <div className="table-header">
            <div className="order-info">
              <div className="order-no">
                {moment(item.orderPrice.createdAt).format(
                  "YYYY-MM-DD hh:mm:ss"
                )}
                {"    "}
                {t("ORDER_NUMBER")} :{item.orderNumber}
              </div>
            </div>
          </div>
          {item.products &&
            item.products.map((product, pindex) => (
              <div
                className="product-item"
                key={pindex}
                style={{
                  alignItems: "flex-start",
                }}
              >
                <div className="product-info">
                  <div
                    style={{
                      width: "80px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={localizeImg(
                        product.images.length > 0
                          ? product.images[0]
                          : product.bottleImages[0]
                      )}
                      className="product-image  cursor-pointer"
                      // onClick={onProductDetail.bind(this, product._id)}
                    />
                  </div>
                  <div
                    className="product-name cursor-pointer"
                    onClick={onProductDetail.bind(this, product._id)}
                  >
                    {localizeTitle(i18n.language, product.title)}
                  </div>
                </div>
                <div className={"ItemPriceCalc"}>
                  <div>
                    ${onGetProductPrice(item.orderProducts, product._id)}
                  </div>
                  <div>
                    {onGetProductCount(item.orderProducts, product._id)}
                  </div>
                  <div>
                    $
                    {onGetProductPrice(item.orderProducts, product._id) *
                      onGetProductCount(item.orderProducts, product._id)}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="order-columns">
          {/* <div className="column-item">
            <div className="table-header">
              <div className="header-info">
                <div className="header-item1">{t("DEPOSIT_AMOUNT")}</div>
              </div>
            </div>
            <span className="item-price">{item.orderPrice.payPrice}{ProductCurrencyType.CNY}</span>
          </div> */}
          <div className="column-item">
            {/* <div className="table-header">
              <div className="header-info">
                <div className="header-item2">{t("ORDER_STATUS")}</div>
              </div>
            </div> */}
            <span className="item-price">{t(item.status)}</span>
          </div>
          {/* <div className="column-item">
            <Space size={20} direction="vertical">
              {!isWinery && (
                <Link to={{ pathname: "/imchat-deprecated", state: { userId: item.userId } }}>
                  <div className="item-action">{t("CONTACT_THE_SELLER")}</div>
                </Link>
              )}
              {item.status == OrderStatus.WaitingForPay || item.status == OrderStatus.Complete ? (
                <div className="item-del-action" onClick={delOrder.bind(this, item)}>{t("DELETE")}</div>
              ) : ''}
              <div className="item-action" onClick={onGoDetail.bind(this, item._id)}>
                {t("DETAIL_OF_THE_ORDER")}
              </div>
            </Space>
          </div> */}
        </div>
      </div>
    </div>
  );
}
