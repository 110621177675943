import { useUserId } from "../../contexts/UserIdProvider";
import { useEffect, useState } from "react";
import NotAuthorized from "../others/NotAuthorized";
import ExploreWinery from "./ExploreWinery";
import ExploreImporter from "./ExploreImporter";
import {
  UserType,
} from "../../constants/usersType.enum";

export default function Explore() {
  let user = useUserId();
  const [isWinery, setIsWinery] = useState(false);
  const [isImporter, setIsImporter] = useState(false);
  const [haveRight, setHaveRight] = useState(false);

  useEffect(() => {
    if (user.user.userInfo.type == UserType.SUPPLIER) {
      setIsWinery(true);
    } else if (
      user.user.userInfo.type == UserType.BUYER ||
      user.user.userInfo.type == UserType.SUPPLIERBUYER
    ) {
      setIsImporter(true);
    } else {
      setHaveRight(true);
    }
  });

  return (
    <div>
      {isImporter && <ExploreWinery />}
      {isWinery && <ExploreImporter />}
      {haveRight && <NotAuthorized />}
    </div>
  );
}
