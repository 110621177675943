import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import "./style/audioChat.less";
import answerIcon from "../../resource/icons/instant-messaging/answer.svg";
import hangUpIcon from "../../resource/icons/instant-messaging/hang-up.svg";
import {
  ChatContentsType,
  AudioCallStatus,
} from "../../constants/chatContentsType.enum";
import ringTones from "../../resource/audio/phone-ringing.mp3";
import MediaPlayer from "../common/media-player/MediaPlayer";
import { useUserId } from "../../contexts/UserIdProvider";
import { userDetail } from "../../api/user";
import useAgora from "../../hooks/useAgora";
import useIM from "../../hooks/useIM";
import { useIMContext } from "../../contexts/IMProvider";
import { localizeImg } from "../../constants/constants";
import AvatarBasic from "../common/avatar/AvatarBasic";

export default function VideoCall(props) {
  const { callMessage, callStatus, onClose } = props;
  const { from, to, id } = callMessage;
  const [friend, setFriend] = useState();
  const [_callStatus, setCallStatus] = useState(callStatus);
  const { t } = useTranslation();
  const { user } = useUserId();
  const { webIM, agoraClient } = useIMContext();
  const {
    localVideoTrack,
    localAudioTrack,
    remoteUsers,
    joinVideo,
  } = useAgora(agoraClient);
  const { customServerMsgId, sendCustomMessage, recallMessage } = useIM(webIM);

  useEffect(() => {
    if (callStatus === AudioCallStatus.CALLING) {
      joinVideo(from + to);
      sendCallMessage(to, ChatContentsType.VIDEOCALL);
    }
    let friendId = from === user.userId ? to : from;
    initFriend(friendId);
  }, []);

  const sendCallMessage = (uid, fileType) => {
    let content = { fileType };
    sendCustomMessage(uid, content);
  };

  const initFriend = async (id) => {
    let result = await userDetail(id);
    if (result) {
      setFriend(result);
    }
  };

  useEffect(() => {
    if (
      _callStatus === AudioCallStatus.CALLING &&
      remoteUsers &&
      remoteUsers.length > 0
    ) {
      setCallStatus(AudioCallStatus.CONNECTING);
    }
    if (
      _callStatus === AudioCallStatus.CONNECTING &&
      remoteUsers &&
      remoteUsers.length === 0
    ) {
      onHangOff();
    }
  }, [remoteUsers]);

  const onHangOff = async () => {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    onClose && onClose();
  };

  const onCancelClick = () => {
    if (customServerMsgId) {
      recallMessage(customServerMsgId, to);
    }
    sendCallMessage(to, ChatContentsType.CANCELCALL);
    onHangOff();
  };

  const onIgnore = () => {
    if (id) {
      recallMessage(id, to);
    }
    sendCallMessage(from, ChatContentsType.REFUSECALL);
    onHangOff();
  };

  const onHangUp = () => {
    setCallStatus(AudioCallStatus.CONNECTING);
    joinVideo(from + to);
  };

  useEffect(() => {
    const audioEl = document.getElementsByClassName("audio-element")[0];
    if (
      _callStatus === AudioCallStatus.WAITING ||
      _callStatus === AudioCallStatus.CALLING
    ) {
      audioEl.play();
    } else {
      audioEl.pause();
    }
  }, [_callStatus]);

  return (
    <div className="audio-chat video-chat">
      {_callStatus !== AudioCallStatus.CONNECTING && (
        <>
          <div className="audio-chat-row audio-avatar-row">
            <AvatarBasic
              cname="audio-avatar"
              picture={localizeImg(friend?.companyDesc.avatar)}
              displayName={friend?.nickName || (friend?.firstName + friend?.lastName)}
            ></AvatarBasic>
          </div>
          <div className="audio-chat-row audio-name">{friend?.nickName}</div>
        </>
      )}
      {_callStatus === AudioCallStatus.CALLING && (
        <div className="audio-chat-row audio-desc">{t('WAITING_VIDEO_CALL_RESPONSE')}</div>
      )}
      {_callStatus === AudioCallStatus.WAITING && (
        <div className="audio-chat-row audio-desc">{t('INVITED_JOIN_VIDEO_CALL')}</div>
      )}
      {remoteUsers.map((user) => (
        <div className="video-remote">
          <MediaPlayer
            videoTrack={user.videoTrack}
            audioTrack={user.audioTrack}
          ></MediaPlayer>
        </div>
      ))}
      {_callStatus === AudioCallStatus.CONNECTING && (
        <div className="video-current">
          <MediaPlayer videoTrack={localVideoTrack} />
        </div>
      )}
      <div className="audio-icon-row">
        {_callStatus === AudioCallStatus.CALLING && (
          <div className="audio-icon-item" onClick={onCancelClick.bind(this)}>
            <img src={hangUpIcon} className="audio-icon" />
            <div className="audio-icon-desc">{t('CANCEL')}</div>
          </div>
        )}
        {_callStatus === AudioCallStatus.WAITING && (
          <div className="audio-icon-item" onClick={onIgnore.bind(this)}>
            <img src={hangUpIcon} className="audio-icon" />
            <div className="audio-icon-desc">{t('IGNORE')}</div>
          </div>
        )}
        {_callStatus === AudioCallStatus.WAITING && (
          <div className="audio-icon-item" onClick={onHangUp.bind(this)}>
            <img src={answerIcon} className="audio-icon" />
            <div className="audio-icon-desc">{t('ANSWER')}</div>
          </div>
        )}
      </div>
      {_callStatus === AudioCallStatus.CONNECTING && (
        <div className="audio-icon-row video-icon-row">
          <div className="audio-icon-item" onClick={onHangOff.bind(this)}>
            <img src={hangUpIcon} className="audio-icon" />
            <div className="audio-icon-desc video-icon-desc">{t('HANG_UP')}</div>
          </div>
        </div>
      )}
      <div>
        <audio className="audio-element">
          <source src={ringTones}></source>
        </audio>
      </div>
    </div>
  );
}
