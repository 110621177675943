import { useState } from "react";
import { getCurrentTime } from "../utils/date";
import { createGroup as createGroup_server } from "../api/chat";

export default function useIM(WebIM) {
  const [messageList, setMessageList] = useState([]);
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState();
  const [customServerMsgId, setCustomServerMsgId] = useState();

  const addBlackList = (toId) => {
    WebIM.conn.addToBlackList({
      name: [toId],
    });
  };

  const fetchHistoryMessage = (uid, reset, isGroup, count) => {
    let options = {
      queue: uid,
      isGroup: isGroup ? true : false,
      count: count ? count : 20,
      success: function (res) {
        console.log("离线消息获取成功", res);
        setMessageList(res);
      },
      fail: function (err) {},
    };
    if (reset) {
      WebIM.conn.mr_cache = []; //重置拉取历史消息的游标
    }
    WebIM.conn.fetchHistoryMessages(options);
  };

  const readChannelMessage = (toId, isGroup) => {
    var msg = new WebIM.message("channel", WebIM.conn.getUniqueId());
    let content = { to: toId };
    if (isGroup) {
      content.chatType = "groupChat";
    }
    msg.set(content);
    WebIM.conn.send(msg.body);
  };

  const sendTextMessage = (to, content, groupStatus) => {
    let id = WebIM.conn.getUniqueId();
    let msg = new WebIM.message("txt", id);
    let options = {
      msg: content,
      to,
      ext: {
        time: getCurrentTime(),
      },
      success: function (id, serverMsgId) {
        console.log("发送文本消息成功");
      },
      fail: function (err) {
        console.log("发送文本消息失败", err);
      },
    };
    if (groupStatus) {
      options.chatType = "groupChat";
    }
    msg.set(options);
    WebIM.conn.send(msg.body);
  };

  const sendCustomMessage = (to, content, groupStatus) => {
    let id = WebIM.conn.getUniqueId(); // 生成本地消息id
    let msg = new WebIM.message("custom", id); // 创建自定义消息
    let customEvent = "customEvent"; // 创建自定义事件
    let customExts = content; // 消息内容对象｛｝，key/value 需要 string 类型
    let options = {
      to,
      customEvent,
      customExts,
      //ext: {},                                  // 消息扩展
      //roomType: false,
      success: function (id, serverMsgId) {
        setCustomServerMsgId(serverMsgId);
        // console.log("发送自定义消息成功", id, serverMsgId);
      },
      fail: function (err) {
        console.log("发送自定义消息失败", err);
      },
    };
    if (groupStatus) {
      options.chatType = "groupChat";
    }
    msg.set(options);
    WebIM.conn.send(msg.body);
  };

  const recallMessage = (mid, to, type) => {
    let options = {
      mid,
      to,
      type: type ? type : "chat",
      success: function (id, serverMsgId) {
        console.log("消息撤回成功");
      },
      fail: function (err) {
        console.log("消息撤回失败", err);
      },
    };
    console.log("----消息撤回:", options);
    WebIM.conn.recallMessage(options);
  };

  const createGroup = (groupname, desc, members, pms) => {
    console.log("-----create group params:", groupname, members);
    let options = {
      data: {
        groupname, // 群组名
        desc, // 群组描述
        members, // 用户名组成的数组
        public: true, // pub等于true时，创建为公开群
        approval: false, // approval为true，加群需审批，为false时加群无需审批
        allowinvites: false, // true：允许群成员邀请人加入此群，false：只有群主才可以往群里加人 注意公开群（public：true),则不允许群成员邀请别人加入此群
        inviteNeedConfirm: false, // 邀请加群，被邀请人是否需要确认。true 为需要被邀请者同意才会进群
      },
      success: async (res) => {
        console.log("create group success:", res);
        pms.imGroupId = res.data.groupid;
        let result = await createGroup_server(pms);
        if (result) {
          pms._id = result;
          setNewGroup(pms);
        }
      },
      error(err) {
        console.log("create group err:", err);
      },
    };
    WebIM.conn.createGroupNew(options).then((res) => {
      console.log(res);
    });
  };

  const getGroups = () => {
    // 列出当前登录用户加入的所有群组
    WebIM.conn.getGroup().then((res) => {
      if (res && res.data) {
        setGroups(res.data);
      }
    });
  };

  // const getGroupMembers = async (groupId) => {
  //   const localConn = await getConn();

  //   let pageNum = 1;
  //   const pageSize = 1000;
  //   let options = {
  //     pageNum,
  //     pageSize,
  //     groupId,
  //   };
  //   localConn.listGroupMember(options).then((res) => {
  //     console.log("group member", res);
  //     // if (res && res.data) {
  //     //   setGroupMembers(res.data);
  //     // }
  //   });
  // };

  const quitGroup = (groupId) => {
    // 成员主动退出群
    let option = {
      groupId,
    };
    WebIM.conn.quitGroup(option).then((res) => {
      console.log("quit group res", res);
    });
  };

  const dissolveGroup = (groupId) => {
    //  解散一个群组
    let option = {
      groupId,
    };
    WebIM.conn.dissolveGroup(option).then((res) => {
      console.log("dissolveGroup res", res);
    });
  };

  const removeGroupMember = (groupId, userid) => {
    //  移除群组成员
    let option = {
      groupId,
      username: userid,
    };
    WebIM.conn.removeSingleGroupMember(option).then((res) => {
      console.log("removeGroupMember res", res);
    });
  };

  const addGroupMember = (groupId, users) => {
    let option = {
      users,
      groupId,
    };
    WebIM.conn.inviteToGroup(option).then((res) => {
      console.log("add group member:", res);
    });
  };

  return {
    groups,
    messageList,
    customServerMsgId,
    newGroup,
    fetchHistoryMessage,
    readChannelMessage,
    sendTextMessage,
    sendCustomMessage,
    recallMessage,
    createGroup,
    getGroups,
    quitGroup,
    dissolveGroup,
    removeGroupMember,
    addGroupMember,
    addBlackList,
  };
}
