import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import "./style/companyAboutUs.less";
import editIcon from "../../resource/icons/user-profile/edit_profile.svg";
import sampleMapImg from "../../resource/images/user-profile/sample_map.png";
import { constants } from "../../utils/utils";

import AMapLoader from "@amap/amap-jsapi-loader";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default function CompanyAboutUs(props) {
  const { content, isMyself, companyDesc, onEdit } = props;
  const introContentDiv = useRef(null);
  const { t, i18n } = useTranslation();

  const [companyIntro, setCompanyIntro] = useState(content);
  // const [companyInfo, setCompanyInfo] = useState(_companyInfo);
  // const [companyInfos, setCompanyInfos] = useState([]);
  // const [companyDesc, setCompanyDesc] = useState("");

  const [currentLanguage, setCurrentLanguage] = useState();

  const [useAmap, setUseAMap] = useState(true);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom, setZoom] = useState(11);

  let amap = null;

  // const [lang, setLang] = useState(i18n.language);

  // const [locations, setLocations] = useState([]);
  // const [productTypes, setProductTypes] = useState([]);

  // /**
  //  * Importer
  //  */
  // const [productCategoryList, setProductCategoryList] = useState([]);
  // const [mainImportingCountryList, setMainImportingCountryList] = useState([]);

  /*
  useEffect(() => {
    initConfigData();
  }, []);

  
  useEffect(() => {
    setCompanyDesc(_companyDesc);
    if (_companyDesc) {
      let { mainImportingCountry, productCategory } = _companyDesc;
      if (mainImportingCountry && mainImportingCountry.length > 0) {
        setMainImportingCountryList(mainImportingCountry);
      }
      if (productCategory && productCategory.length > 0) {
        setProductCategoryList(productCategory);
      }
    }
  }, [_companyDesc]);
  */

  // useEffect(() => {
  //   setCompanyInfo(_companyInfo);
  // }, [_companyInfo]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setCompanyIntro(content);
  }, [content]);

  useEffect(() => {
    console.log(
      "---------------------CompanyAbousUs companyDesc:",
      companyDesc
    );
    if (companyDesc && companyDesc.location) {
      setUseAMap(companyDesc.location.country == constants.chinaCountryId);
      console.log(companyDesc.location.country == constants.chinaCountryId);
    }
  }, [companyDesc]);

  useEffect(() => {
    const address = companyDesc?.location?.address?.name;
    let lnglat = companyDesc?.location?.address?.location?.split(",");

    if (!lnglat || lnglat.length == 0) {
      lnglat = ["116.38", "39.9"];
    }

    if (!useAmap && companyDesc) {
      mapboxgl.accessToken = constants.mapBoxAccessToken;

      if (map.current) return; // initialize map only once.
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: lnglat,
        zoom: zoom,
      });

      map.current.addControl(
        new mapboxgl.Marker({
          draggable: false,
          color: "#C4321D",
        })
          .setLngLat(lnglat)
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }).setHTML(
              "<h3>" + address + "</h3>"
            )
          )
          .addTo(map.current)
      );
    } else if (companyDesc) {
      AMapLoader.load({
        key: constants.amapJsKey, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          amap = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "2D", //是否为3D地图模式
            zoom: 16, //初始化地图级别
            center: lnglat, //初始化地图中心点位置
          });

          // 创建一个 Marker 实例:
          var marker = new AMap.Marker({
            position: new AMap.LngLat(lnglat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: address,
            // icon:sampleMapImg
          });

          // 将创建的点标记添加到已有的地图实例:
          amap.add(marker);
          // marker.setMap(amap);
        })
        .catch((e) => {
          console.log("amap error:", e);
        });
    }
  }, [useAmap]);

  /*
  useEffect(() => {
    if (!companyIntro || !introContentDiv.current) return;
    setShowProfileInfos();
  }, [introContentDiv, companyIntro]);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations, types, classifications, tags } = configData;
    setLocations(locations);
    setProductTypes(types);
  };
  
  const setShowProfileInfos = () => {
    if (companyInfo.type != UserType.BUYER) {
      setWineryInfos();
    } else {
      setImporterInfos();
    }
  };

  const setWineryInfos = () => {
    let temp = deepClone(companyInfos);
    temp = [];

    let locationValue = getCountryNameById(
      locations,
      currentLanguage,
      companyDesc.country
    )
      ? getCountryNameById(locations, currentLanguage, companyDesc.country)
      : t(companyDesc.country) +
        " " +
        getCountryNameById(locations, currentLanguage, companyDesc.region)
      ? getCountryNameById(locations, currentLanguage, companyDesc.region)
      : t(companyDesc.region);
    let vinyardSize = companyDesc?.size
      ? companyDesc?.size
      : t("N/A") + t("YARD");
    temp.push([
      { key: t("LOCATION"), value: locationValue },
      { key: t("VINEYARD_SIZE"), value: vinyardSize },
    ]);

    let productionValue = "";
    if (
      companyDesc &&
      companyDesc.production &&
      companyDesc.production.number
    ) {
      if (companyDesc.production.number) {
        productionValue = (
          companyDesc.production.number.toString() +
          " " +
          t(companyDesc.production.unit)
        ).toString();
      } else {
        productionValue = t("N/A");
      }
    } else {
      if (companyDesc && companyDesc.production) {
        productionValue = companyDesc.production;
      } else {
        productionValue = t("N/A") + " " + t("NORMAL_BOTTLE_SIZE");
      }
    }
    let brandValue = "";
    if (companyDesc && companyDesc.brandAmount) {
      brandValue =
        companyDesc?.brandAmount +
        (currentLanguage === SysEnvLanguageType.ZH && " 个");
    } else {
      brandValue = t("N/A");
    }
    temp.push([
      { key: t("TOTAL_PRODUCTION"), value: productionValue },
      { key: t("NUMBER_OF_BRAND"), value: brandValue },
    ]);

    temp.push([
      { key: t("MAIN_MARKET"), value: locationValue },
      {
        key: t("PRIVATE_LABEL"),
        value: companyInfo.privateLabel ? t("YES") : t("NO"),
      },
    ]);

    let productType = "";
    productCategoryList.map((item, index) => {
      productType += getProductConfigNameById(productTypes, lang, item) + ",";
    });
    temp.push([{ key: t("PRODUCT_TYPE"), value: productType.trimEnd(",") }]);
    setCompanyInfos(temp);
  };

  const setImporterInfos = () => {
    let temp = deepClone(companyInfos);
    temp = [];

    temp.push([
      { key: t("TURNOVERS"), value: companyDesc.annualTurnover },
      {
        key: t("NUMBER_OF_EMPLOYEES"),
        value: companyDesc.numberOfEmployees,
      },
    ]);

    let productType = "";
    productCategoryList.map((item, index) => {
      productType += getProductConfigNameById(productTypes, lang, item) + ",";
    });
    temp.push([
      {
        key: t("PRIVATE_LABEL"),
        value: companyInfo.privateLabel ? t("YES") : t("NO"),
      },
      {
        key: t("NUMBER_OF_BRAND"),
        value: companyDesc.numberOfSku,
      },
    ]);

    temp.push([
      {
        key: t("OWN_BRAND"),
        value: companyDesc.BrandAlready ? t("YES") : t("NO"),
      },
      {
        key: t("MAIN_MARKET"),
        value: companyDesc.market ? companyDesc.market : t("N/A"),
      },
    ]);

    let channelValue = "";
    mainImportingCountryList.map((item, index) => {
      channelValue += getProductConfigNameById(locations, lang, item) + ",";
    });
    temp.push([
      {
        key: t("DISTRIBUTION_CHANNEL"),
        value: channelValue.trimEnd(","),
      },
      { key: t("PRODUCT_TYPE"), value: productType.trimEnd(",") },
    ]);

    setCompanyInfos(temp);
  };

  const onIntroVideoLoaded = (fileType, url, abc) => {
    console.log("---------------------------onIntroVideoLoaded:", url);
    onUploadVideo && onUploadVideo(url);
  };

  const getUserInfoValueRowItem = (infoItem, rowIndex, colIndex) => {
    let lastRowIndex1 = 2;
    let lastRowIndex2 = 3;

    if (
      companyInfo.type == UserType.BUYER ||
      companyInfo.type == UserType.SUPPLIERBUYER
    ) {
      lastRowIndex1 = 3;
      lastRowIndex2 = 3;
    }

    if (
      (rowIndex == lastRowIndex1 && colIndex == 1) ||
      rowIndex == lastRowIndex2
    ) {
      return (
        <div className="content-value right-border bottom-border">
          {infoItem.value}
        </div>
      );
    }
    return <div className="content-value right-border">{infoItem.value}</div>;
  };

  const getUserInfoKeyRowItem = (infoItem, rowIndex, colIndex) => {
    let lastRowIndex1 = 2;
    let lastRowIndex2 = 3;

    if (
      companyInfo.type == UserType.BUYER ||
      companyInfo.type == UserType.SUPPLIERBUYER
    ) {
      lastRowIndex1 = 3;
      lastRowIndex2 = 3;
    }

    if (
      (rowIndex == lastRowIndex1 && colIndex == 1) ||
      rowIndex == lastRowIndex2
    ) {
      return <div className="content-label bottom-border">{infoItem.key}</div>;
    }
    return <div className="content-label">{infoItem.key}</div>;
  };
  */

  return (
    <div>
      <div
        className={"company-intro company-about-us "}
        style={{ marginTop: "20px", borderRadius: "10px" }}
      >
        <div className="user-profile-company-about-us-map-container">
          {!useAmap ? (
            <div ref={mapContainer} className="map-container" />
          ) : (
            <div
              id="container"
              className="map"
              style={{ height: "388px" }}
            ></div>
          )}
        </div>
        {/* <img src={sampleMapImg} /> */}
        {companyIntro && companyIntro.length > 0 && (
          <div className="company-intro-container">
            <div
              style={{
                color: "#333333",
                fontSize: "16px",
                fontWeight: "400",
                marginBottom: "10px",
              }}
            >
              {t("ABOUT_US")}
            </div>
            <div
              ref={introContentDiv}
              className={"company-intro-content"}
              style={{ height: "auto" }}
              dangerouslySetInnerHTML={{ __html: companyIntro }}
            ></div>
            {isMyself && (
              <div
                className="company-intro-panel"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  className="button-company-gallery-edit"
                  onClick={() => onEdit && onEdit()}
                  style={{
                    backgroundColor: "#434343",
                    height: "20px",
                    borderRadius: "15px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingTop: "0px",
                  }}
                >
                  <img
                    src={editIcon}
                    style={{
                      width: "10px",
                      height: "11px",
                      marginRight: "5px",
                      marginBottom: "2px",
                    }}
                  />
                  {t("EDIT")}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {/* <div className="modal-panel normal-shadow">
        <div className="modal-header">
          <div className="user-type">{t(companyInfo?.type?.toUpperCase())}</div>
        </div>
        <div
          style={{
            padding: "20px",
          }}
        >
          {companyInfos.map((infoItems, infoItemsIndex) => {
            return (
              <Row className="modal-content">
                {infoItems.map((infoItem, infoItemIndex) => {
                  return (
                    <>
                      <Col span={6}>
                        {getUserInfoKeyRowItem(
                          infoItem,
                          infoItemsIndex,
                          infoItemIndex
                        )}
                      </Col>
                      <Col span={6}>
                        {getUserInfoValueRowItem(
                          infoItem,
                          infoItemsIndex,
                          infoItemIndex
                        )}
                      </Col>
                    </>
                  );
                })}
              </Row>
            );
          })}
        </div>
      </div> */}
    </div>
  );
}
