export function imLogin(WebIM, userInfo) {
  if (WebIM) {
    if (userInfo === undefined) {
      localStorage.clear(); // We force the logout of the user, a relogin should fix this case.
      return;
    }
    const { password, _id } = userInfo;
    const pwd = password;
    const options = {
      user: _id,
      pwd,
      apiUrl: WebIM.config.apiURL,
      appKey: WebIM.config.appkey,
    };
    WebIM.conn.open(options);
  }
}

export function imClose(WebIM) {
  WebIM.conn.close();
}
