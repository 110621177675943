import { useState, useEffect } from "react";
import { Modal, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useUserId } from "../../../../contexts/UserIdProvider";
import "./style/pictureUploader.less";
import { getUploadFileAction } from "../../../../api/common";
import { getCacheTokens } from "../../../../utils/cache";
import { getBase64 } from "../../../../utils/utils";
import { toast } from "react-toastify";
import { localizeImg } from "../../../../constants/constants";

export default function PictureUploader(props) {
  const { maxCount, files, onSuccess } = props;
  const { user } = useUserId();
  const action = getUploadFileAction(user.userId);
  const token = getCacheTokens();
  const [_fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [maxNum, setMaxNum] = useState(9);

  useEffect(() => {
    if (maxCount) {
      setMaxNum(maxCount);
    }
  }, [maxCount]);

  useEffect(() => {
    // console.log('----files:', files);
    if (files) {
      let list = files.map((item) => {
        return {
          url: localizeImg(item), //Ϊ��ʼ����ʾ��
          response: { result: item }, //Ϊ����onchange��
        };
      });
      setFileList(list);
    } else {
      setFileList([]);
    }
  }, [files]);

  const tokenPre = "Bearer ";
  const layout_upload = {
    action: action,
    headers: {
      authorization: tokenPre + token.value,
    },
    isImageUrl(file) {
      // console.log('------is image url:',file)
      return true;
    },
    onChange(info) {
      // console.log("-----onchange info", info);
      setUploading(true);
      setFileList(info.fileList);
      if (info.file.status === "done" || info.file.status === "removed") {
        const { fileList } = info;
        const pathList = fileList.map((file) => file.response.result);
        onSuccess && onSuccess(pathList);
        setUploading(false);
        //dialog.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        toast.error(`${info.file.name} file upload failed.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUploading(false);
      }
    },
    beforeUpload: (file) => {
      const imageType = "image/png,image/jpeg,image/gif,image/jpg,image/svg";
      const validate = imageType.indexOf(file.type) === -1;
      if (validate) {
        toast.error(`${file.name} is not a image file`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onPreview: (file) => {
      console.log("-----onPreview info", file);
      let imageUrl = file.url;
      if (!imageUrl) {
        imageUrl = file.thumbUrl;
      }
      setPreviewImage(imageUrl);
      setPreviewVisible(true);
    },
  };

  return (
    <div className="picture-uploader-panel">
      <Upload
        {...layout_upload}
        listType="picture-card"
        fileList={_fileList}
        maxCount={maxNum}
        multiple
      >
        {_fileList.length < maxNum && !uploading && (
          <PlusOutlined style={{ fontSize: "32px", color: "#C8CAD4" }} />
        )}
      </Upload>
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img
          alt="example"
          style={{ width: "100%", maxHeight: "500px", marginTop: "15px" }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
}
