import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import QRCode from "qrcode";
import { getOrderDetailsByRelationId, orderDetail } from "../../../api/orders";
import wxpic from "../../../resource/images/wechat/wxpic.png";
import "../../shared/style/middlePage.less";
import "./style/wxNativePay.less";
import { OrderStatus } from "../../../constants/statusCode.enum";

export default function WxNativePay() {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();
  const { wxcode, payPrice, orderNumber, _id } = state;
  const orderNum = orderNumber;
  const [dataUrl, setDataUrl] = useState();
  const [payStatus, setPayStatus] = useState(false);

  let orderTimer;
  useEffect(() => {
    generateQR(wxcode);
    orderTimer = setInterval(getOrder, 30000);

    return () => {
      clearInterval(orderTimer);
    };
  }, []);

  useEffect(() => {
    if (payStatus) {
      history.push("/shop/orders");
    }
  }, [payStatus]);

  const generateQR = async (text) => {
    try {
      let result = await QRCode.toDataURL(text);
      setDataUrl(result);
    } catch (err) {
      console.error("generateQR error", err);
    }
  };

  const getOrder = async () => {
    if (_id) {
      let pms = { relationId: _id };
      let data = await getOrderDetailsByRelationId(pms);
      console.log("-----getorders,", data);
      if (data && data.total > 0) {
        let { result } = data;
        if (result[0].payStatus === OrderStatus.Payed) {
          clearInterval(orderTimer);
          setPayStatus(true);
        }
      }
    }
  };

  return (
    <div className="middle-page-container">
      <div className="middle-page wxpay-page-container">
        <div className="page-title">微信支付</div>
        <div className="order-desc">
          <div>订单提交成功，请尽快支付！订单号:{orderNum}</div>
          <div>
            应付金额 <span className="order-price">{payPrice}</span> 元
          </div>
        </div>
        <div className="pay-content">
          <div className="wxpay-code-container ">
            {dataUrl && <img src={dataUrl} className="code-image" />}
            <div className="wxpay-tip">请使用微信扫描二维码支付</div>
          </div>
          <img src={wxpic} className="wxpay-image" />
        </div>
      </div>
    </div>
  );
}
