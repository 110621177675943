import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Input, Row, Select } from "antd";
import "./style/companySetting.less";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../contexts/UserIdProvider";
import { Link, useHistory } from "react-router-dom";
import {
  companyDetail,
  getCompanyDescFromUserType,
  saveCompanyInfo,
} from "../../api/company";
import { statusCode } from "../../constants/statusCode.enum";
import { toastInfo } from "../../api/toast";
import {
  companySettingBuyerBasicFields,
  companySettingSupplierBasicFields,
  UserLabel,
  userLabelFields,
  UserType,
  userTypeFields,
} from "../../constants/usersType.enum";
import { constants, deepClone, goToProfilePage } from "../../utils/utils";
import {
  formatConfigForSelect,
  formatLocationForSelect,
  getCities,
  getCountries,
} from "../../api/config";
import {
  getProductConfigForSelect,
  loadProductConfig,
} from "../../api/product";
import {
  AchiveData,
  DistributionChannelData,
  KnowVinbipData,
} from "../../constants/config";
import FormNormal from "../common/form/FormNormal";
import {
  fieldValueToString,
  formatCompanyFieldsValue,
  initCompanyFieldsData,
  initCompanyFieldsValue,
} from "../../constants/userTypeTools";
import { CompanyVerifyStatusEnum } from "../../constants/companyVerifyStatus.enum";
import FormNormalW from "../common/form/FormNormalW";
import AddressInput from "../common/location/addressInput/AddressInput";
import { localizeValue } from "../../constants/constants";

export default function CompanySettings() {
  const history = useHistory();
  const { user } = useUserId();

  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [status, setStatus] = useState(0);
  const [isVerified, setIsVerified] = useState(false);
  const [userLabel, setUserLabel] = useState(UserLabel.IMPORTER);

  const [companyCityData, setCompanyCityData] = useState([]);
  const [companyBasicFormData, setCompanyBasicFormData] = useState({});
  const [companyInfoFormData, setCompanyInfoFormData] = useState();

  const [currentUser, setCurrentUser] = useState(user?.userInfo);

  const [lang, setLang] = useState(i18n.language);
  const [displayName, setDisplayName] = useState();

  const [fieldInfos, setFieldInfos] = useState([]);
  const [companyBasicFields, setCompanyBasicFields] = useState(
    companySettingSupplierBasicFields
  );
  const [companyFields, setCompanyFields] = useState([]);
  const [editModal, setEditModal] = useState(true);

  const [companyDesc, setCompanyDesc] = useState();

  //初始化编辑状态
  useEffect(() => {
    setUserLabel(user.userInfo.label);
    initEditStatus(user.userInfo.companyId);
  }, []);

  //初始化公用信息
  useEffect(() => {
    let temp = deepClone(companyBasicFields);
    if (user.userInfo.type == UserType.SUPPLIER) {
      temp = deepClone(companySettingSupplierBasicFields);
      temp.map((mapItem) => {
        if (mapItem.name == "label") {
          mapItem.data.map((dataItem) => {
            dataItem.label = t(dataItem.label);
          });
        }
      });
    } else {
      temp = deepClone(companySettingBuyerBasicFields);
      temp.map((mapItem) => {
        if (mapItem.name == "label") {
          mapItem.data.map((dataItem) => {
            dataItem.label = t(dataItem.label);
          });
        }
      });
    }
    if (companyDesc && companyDesc.coName) {
      temp[0].value = companyDesc.coName;
    }
    console.log("temp:", temp);
    setCompanyBasicFields(temp);
  }, [user, i18n.language, companyDesc]);

  //设置企业信息
  useEffect(() => {
    console.log("----------------current label:", userLabel);
    let list = [];
    if (userLabel) {
      if (userLabel == UserLabel.IMPORTER) {
        list = deepClone(userLabelFields.buyer.importerFields);
      } else if (userLabel == UserLabel.DISTRIBUTOR) {
        list = deepClone(userLabelFields.buyer.distributorFields);
      } else if (userLabel == UserLabel.CORPORATEBUSINESS) {
        list = deepClone(userLabelFields.buyer.businessFields);
      } else if (
        userLabel == UserLabel.WINERY ||
        userLabel == UserLabel.FAMILY_ESTATE
      ) {
        list = deepClone(userLabelFields.supplier.wineryFields);
      } else if (userLabel == UserLabel.COOPERATIVE) {
        list = deepClone(userLabelFields.supplier.cooperativeFields);
      } else if (userLabel == UserLabel.NEGOCIANT) {
        list = deepClone(userLabelFields.supplier.negociantFields);
      }
    } else {
      if (user.userInfo.type == UserType.BUYER) {
        list = deepClone(userLabelFields.buyer.importerFields);
      } else {
        list = deepClone(userLabelFields.supplier.wineryFields);
      }
    }
    setCompanyFields(list);
    initCompanyFieldsConfigData(list);
  }, [userLabel]);

  useEffect(() => {
    if (status === CompanyVerifyStatusEnum.VERIFIED) {
      setIsVerified(true);
    }
  }, [status]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (companyFields.length > 0) {
      initCompanyFieldsConfigData(companyFields);
    }
  }, [lang]);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  //初始化配置信息
  const initCompanyFieldsConfigData = async (sourceList) => {
    let params = {};

    let configData = await loadProductConfig();

    let businessCountryData = await getCountries();
    businessCountryData = formatLocationForSelect(
      businessCountryData,
      currentLanguage
    );

    let pms = { countryId: constants.chinaCountryId };
    let businessCityData = await getCities(pms);
    businessCityData = formatLocationForSelect(
      businessCityData,
      currentLanguage
    );

    let { types } = configData;
    let categoryList = getProductConfigForSelect(types, currentLanguage);

    params.businessCountryData = businessCountryData;
    params.companyCountryData = deepClone(businessCountryData);
    params.businessCityData = businessCityData;
    params.companyCityData = deepClone(companyCityData);
    params.categoryList = categoryList;
    params.knowVinbipList = formatConfigForSelect(
      KnowVinbipData,
      currentLanguage
    );
    params.achiveList = formatConfigForSelect(AchiveData, currentLanguage);
    params.distributionChannelList = formatConfigForSelect(
      DistributionChannelData,
      currentLanguage
    );

    let list = initCompanyFieldsData(sourceList, params);

    console.log("-------------------------initCompanyFieldsData:", sourceList);
    // setCompanyFields(list);
    let tempComapnyFields = await loadCompanyDesc(
      user.userInfo.companyId,
      list
    );
    let tempFieldInfos = fieldValueToString(tempComapnyFields, params);
    console.log("-------------fieldInfos:", tempFieldInfos);
    setFieldInfos(tempFieldInfos);
  };

  const onCompanyHome = (userItem) => {
    goToProfilePage(userItem, history);
  };

  const loadCompanyDesc = async (coId, sourceList) => {
    let pms = { coId };

    let tempCompanyDesc = await getCompanyDescFromUserType(
      pms,
      user.userInfo.type
    );
    console.log(tempCompanyDesc, user.userInfo.type);

    if (tempCompanyDesc && tempCompanyDesc.label && !editModal) {
      setEditModal(false);
    }

    setCompanyDesc(tempCompanyDesc);

    console.log(
      "----------------------------------tempCompanyDesc:",
      tempCompanyDesc
    );
    let tempComapnyFields = initCompanyFieldsValue(sourceList, tempCompanyDesc);
    if (companyInfoFormData) {
      tempComapnyFields = initCompanyFieldsValue(
        tempComapnyFields,
        companyInfoFormData
      );
    }
    console.log(
      "----------------------------------tempComapnyFields:",
      tempComapnyFields
    );
    setCompanyFields(tempComapnyFields);

    let result = await companyDetail(coId);
    if (result) {
      let item = result;

      setStatus(item.status);
    }

    /**
     * Common information
     */
    setDisplayName(
      tempCompanyDesc.displayName ||
        localizeValue(i18n.language, tempCompanyDesc.companyName)
    );

    return deepClone(tempComapnyFields);
  };

  const initEditStatus = async (coId) => {
    let pms = { coId };

    let tempCompanyDesc = await getCompanyDescFromUserType(
      pms,
      user.userInfo.type
    );

    if (tempCompanyDesc && tempCompanyDesc.label) {
      setEditModal(false);
    }
  };

  const onSubmitCompanyInfo = async () => {
    let pms = { ...companyBasicFormData };
    companyFields.map((mapItem) => {
      pms[mapItem.name] = mapItem.value;
    });

    pms.coId = user.userInfo.companyId;
    console.log("--------------onSubmitCompanyInfo:", pms);
    let result = await saveCompanyInfo(pms);
    if (result && result.statusCode == statusCode.SUCCESS) {
      onCompanyHome(currentUser);
      toastInfo(t("SAVE_SUCCESS"));
    } else {
      toastInfo(t("SAVE_FAILED"));
    }
  };

  const onCompanyBasicFieldsChange = (vals) => {
    //设置label
    let userLabelItems = vals.filter(
      (filterItem) => filterItem.name == "label"
    );
    if (
      userLabelItems &&
      userLabelItems.length > 0 &&
      userLabelItems[0].value &&
      userLabel != userLabelItems[0].value
    ) {
      setUserLabel(userLabelItems[0].value);
    }

    setCompanyBasicFields(vals);
    setComBasicFormData(vals);
  };

  const onCompanyUsersFieldsChange = (vals) => {
    console.log("onCompanyUsersFieldsChange:", vals);

    setCompanyFields(vals);
    setComInfoFormData(vals);
  };

  const setComInfoFormData = (vals) => {
    let obj = {};
    vals.map((item) => {
      obj[item.name] = item.value;
    });
    setCompanyInfoFormData(obj);
  };

  const setComBasicFormData = (vals) => {
    let obj = {};
    vals.map((item) => {
      obj[item.name] = item.value;
    });
    setCompanyBasicFormData(obj);
  };

  const required_span = <span className="required-tip">*</span>;

  return (
    <div className="company-management-page">
      <div className="common-management">
        {editModal && (
          <div className="company-setting-panel">
            <div className="company-setting-container">
              <FormNormalW
                items={companyBasicFields}
                onChange={onCompanyBasicFieldsChange.bind(this)}
              ></FormNormalW>
              <FormNormalW
                items={companyFields}
                onChange={onCompanyUsersFieldsChange.bind(this)}
              />
            </div>
          </div>
        )}
        {editModal && (
          <div className="bottom-bar">
            <Button
              className="btn-save"
              onClick={onSubmitCompanyInfo.bind(this)}
            >
              {t("SAVE")}
            </Button>
          </div>
        )}
        {!editModal && (
          <div className="company-setting-info-panel">
            {fieldInfos.map((mapItem, mapIndex) => {
              return (
                <div
                  key={mapIndex}
                  style={{
                    borderBottom: "1px dashed #DEDEDE",
                    height: "41px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#333333",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      width: "50%",
                    }}
                  >
                    {t(mapItem.label)}
                  </span>
                  <span
                    style={{
                      color: "#777777",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      width: "50%",
                    }}
                  >
                    {t(mapItem.value)}
                  </span>
                </div>
              );
            })}
          </div>
        )}
        {!editModal && (
          <div className="bottom-bar">
            <Button className="btn-save" onClick={() => setEditModal(true)}>
              {t("EDIT")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
