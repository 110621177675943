import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Modal, Select, Table, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { getOrders } from "../../../../../api/orders";
import OrdersListItem from "./OrdersListItem";
import { dateFormat } from "../../../../../utils/date";
import ListBasic from "../../../../common/list/ListBasic";
import {
  OrderStatus,
  ProductStatus,
} from "../../../../../constants/statusCode.enum";
import "./style/supplierOrders.less";
import { useHistory, useParams } from "react-router-dom";
import { getAliPayForm } from "../../../../../api/aliPay";
import moreIcon from "../../../../../resource/icons/dashboard/order/more.svg";
import downArrowIcon from "../../../../../resource/icons/dashboard/order/down_arrow.svg";
import { useUserId } from "../../../../../contexts/UserIdProvider";
import { localizeTitle } from "../../../../../constants/constants";
import OrderInfo from "../../OrderInfo";
import OrderInfoEdit from "../order-info-edit/OrderInfoEdit";
import { deepClone } from "../../../../../utils/utils";
import { getCountryNameById } from "../../../../../api/config";
import { loadProductConfig } from "../../../../../api/product";

export default function SupplierOrders() {
  const { index } = useParams();
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const { isWinery, user } = useUserId();
  const history = useHistory();
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState(true);

  const [dateFilterList, setDateFilterList] = useState([]);
  const [clientFilterList, setClientFilterList] = useState([]);
  const [shippingLocationFilterList, setShippingLocationFilterList] = useState(
    []
  );
  const [orderStatusFilterList, setOrderStatusFilterList] = useState([]);
  const [paymentStatusFilterList, setPaymentStatusFilterList] = useState([]);
  const [shippingDestinationFiterList, setShippingDestionationFilterList] =
    useState([]);

  const [orderList, setOrderList] = useState([]);
  const pageSize = 20;
  const [pageIndex, setPageIndex] = useState(0);

  const [currentOrderId, setCurrentOrderId] = useState("");
  const [orderDetailVisible, setOrderDetailVisible] = useState(false);

  const [locations, setLocations] = useState();

  useEffect(() => {
    loadData();
    initConfigData();
  }, [user]);

  const initConfigData = async () => {
    let configData = await loadProductConfig();
    let { locations } = configData;
    setLocations(locations);
  };

  const loadData = async () => {
    let pms = { page: pageIndex, per_page: pageSize };
    if (isWinery) {
      pms.coId = user.userInfo.companyId;
    } else {
      pms.userId = user.userId;
    }

    let result = await getOrders(pms);
    console.log("--------orders :", pms, result);
    if (!result) return;
    setOrderList(result);
    result = result.filter((item) => item.products && item.products.length > 0);

    let tempDateFilterList = deepClone(dateFilterList);
    let tempClientFilterList = deepClone(clientFilterList);
    let tempLocationFilterList = deepClone(shippingLocationFilterList);
    let tempOrderStatusFilterList = deepClone(orderStatusFilterList);
    let tempPaymentStatusFilterList = deepClone(paymentStatusFilterList);
    let tempDestinationFilterList = deepClone(shippingDestinationFiterList);

    tempDateFilterList = [];
    tempClientFilterList = [];
    tempLocationFilterList = [];
    tempOrderStatusFilterList = [];
    tempPaymentStatusFilterList = [];
    tempDestinationFilterList = [];

    result.map((mapItem) => {
      let dateValue = dateFormat(mapItem.createdAt, "MM/dd/yyyy");
      if (
        tempDateFilterList.filter(
          (filterItem) => filterItem.value == filterItem.value
        ).length == 0
      ) {
        tempDateFilterList.push({
          label: dateValue,
          value: dateValue,
        });
      }

      let clientIdValue = mapItem.userInfo[0]._id;
      if (
        tempClientFilterList.filter(
          (filterItem) => filterItem.value == clientIdValue
        ).length == 0
      ) {
        tempClientFilterList.push({
          label: mapItem.userInfo[0].nickName,
          value: clientIdValue,
        });
      }

      let shippingLocationId =
        mapItem.orderProducts[0].volume.shippingAddressInfo.location.country;
      if (
        tempLocationFilterList.filter(
          (filterItem) => filterItem.value == shippingLocationId
        ).length == 0
      ) {
        tempLocationFilterList.push({
          label: getCountryNameById(
            locations,
            i18n.language,
            mapItem.orderProducts[0].volume.shippingAddressInfo.location.country
          ),
          value: shippingLocationId,
        });
      }

      let shippingDestinationId = mapItem.recipient.location.country;
      if (
        tempLocationFilterList.filter(
          (filterItem) => filterItem.value == shippingDestinationId
        ).length == 0
      ) {
        tempLocationFilterList.push({
          label: getCountryNameById(
            locations,
            i18n.language,
            mapItem.recipient.location.country
          ),
          value: shippingDestinationId,
        });
      }
      setShippingDestionationFilterList(tempLocationFilterList);
    });

    setDateFilterList(tempDateFilterList);
    setClientFilterList(tempClientFilterList);
    setShippingLocationFilterList(tempLocationFilterList);

    tempOrderStatusFilterList.push(
      ...[
        {
          label: t(OrderStatus.Confirmed),
          value: OrderStatus.Confirmed,
        },
        {
          label: t(OrderStatus.Sendout),
          value: OrderStatus.Sendout,
        },
        {
          label: t(OrderStatus.Cancelled),
          value: OrderStatus.Cancelled,
        },
        {
          label: t(OrderStatus.Complete),
          value: OrderStatus.Complete,
        },
        {
          label: t(OrderStatus.Delivered),
          value: OrderStatus.Delivered,
        },
        {
          label: t(OrderStatus.Transiting),
          value: OrderStatus.Transiting,
        },
        {
          label: t(OrderStatus.Received),
          value: OrderStatus.Received,
        },
      ]
    );
    setOrderStatusFilterList(tempOrderStatusFilterList);

    tempPaymentStatusFilterList.push(
      ...[
        {
          label: t(OrderStatus.WaitingForPay),
          value: OrderStatus.WaitingForPay,
        },
        {
          label: t(OrderStatus.Payed),
          value: OrderStatus.Payed,
        },
      ]
    );
    setPaymentStatusFilterList(tempPaymentStatusFilterList);

    setLoading(false);
  };
  // const itemLoadData = () => {
  //   loadData();
  // };
  // const onRenderOrderItem = (item, index) => {
  //   return (
  //     <OrdersListItem
  //       item={item}
  //       index={index}
  //       itemLoadData={itemLoadData.bind(this)}
  //       onAliPay={onAliPay.bind(this)}
  //     />
  //   );
  // };

  // const onAliPay = async (order) => {
  //   if (order) {
  //     let result = await getAliPayForm(order);
  //     if (result) {
  //       setAliPayForm(result);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (aliPayForm) {
  //     let forms = document.forms;
  //     if (forms && forms.length > 0) {
  //       forms[0].submit();
  //     }
  //   }
  // }, [aliPayForm]);

  // const filterByMonth = (month, text) => {
  //   setFilterByMonthContent(t(text));
  // };

  // const menu_filter_time = () => {
  //   return (
  //     <div
  //       // onClick={onStockChange.bind(this)}
  //       className="order-listy-filter-select-panel-buyer"
  //     >
  //       <div
  //         className="address-item"
  //         onClick={filterByMonth.bind(this, 1, "PAST_ONE_MONTH")}
  //       >
  //         {t("PAST_ONE_MONTH")}
  //       </div>
  //       <div
  //         className="address-item"
  //         onClick={filterByMonth.bind(this, 2, "PAST_TWO_MONTH")}
  //       >
  //         {t("PAST_TWO_MONTH")}
  //       </div>
  //       <div
  //         className="address-item"
  //         onClick={filterByMonth.bind(this, 3, "PAST_THREE_MONTH")}
  //       >
  //         {t("PAST_THREE_MONTH")}
  //       </div>
  //     </div>
  //   );
  // };

  const columns = [
    {
      title: t("ORDER_DATE"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: "120px",
      render: (val, record) => {
        return <div>{dateFormat(val, "yyyy-MM-dd")}</div>;
      },
    },
    {
      title: t("ORDER_NUMBER"),
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: "120px",
      render: (val, record) => {
        return (
          <div
            style={{ color: "#3E6FA4", cursor: "pointer" }}
            onClick={() => onGoDetail(record._id)}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: t("CLIENT"),
      dataIndex: "userInfo",
      key: "userInfo",
      width: "120px",
      render: (val, record) => {
        if (val && val.length > 0) {
          return (
            <div
              style={{ color: "#3E6FA4", cursor: "pointer" }}
              onClick={() => history.push("/profile-deprecated/" + val[0]._id)}
            >
              {val[0].nickName}
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: t("ORDER_STATUS"),
      dataIndex: "status",
      key: "status",
      width: "120px",
      render: (val, record) => {
        return <div>{t(val)}</div>;
      },
    },
    {
      title: t("TOTAL_AMOUNT"),
      dataIndex: "orderPrice",
      key: "orderPrice",
      width: "120px",
      render: (val, record) => {
        return <div>{val.totalPrice}</div>;
      },
    },
    {
      title: t("PAYMENT_STATUS"),
      dataIndex: "payStatus",
      key: "payStatus",
      width: "120px",
      render: (val, record) => {
        return <div>{t(val)}</div>;
      },
    },
    {
      title: t("OPERATIONS"),
      dataIndex: "action",
      key: "action",
      // width: 280,
      align: "center",
      render: (text, record) => getActionColumn(text, record),
    },
    // {
    //   title: t("YEAR"),
    //   dataIndex: "year",
    //   key: "year",
    // },
    // {
    //   title: t("PRICE_MIN"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     if (val && val.length > 0) {
    //       return (
    //         <div>
    //           {val[0].price} {val[0].currency}
    //         </div>
    //       );
    //     } else {
    //       return <div>-</div>;
    //     }
    //   },
    // },
    // {
    //   title: t("PRICE_MAX"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     if (val && val.length > 0) {
    //       return (
    //         <div>
    //           {val[val.length - 1].price} {val[val.length - 1].currency}
    //         </div>
    //       );
    //     } else {
    //       return <div>-</div>;
    //     }
    //   },
    // },
    // {
    //   title: t("SHIPPING_ADDRESS"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     return (
    //       <div>
    //         {val.map((valItem) => {
    //           return <div>{valItem.shippingAddressName}</div>;
    //         })}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: t("STOCK_AVAILABLE"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     let availableStock = 0;
    //     if (val && val.length > 0) {
    //       val.map((valItem) => {
    //         availableStock += parseInt(valItem.stock);
    //       });
    //     }
    //     return (
    //       <div>
    //         {availableStock}
    //         {t("BOTTLES")}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: t("SOLD_VOLUME"),
    //   dataIndex: "volumes",
    //   key: "volumes",
    //   render: (val, record) => {
    //     return <div>0{t("BOTTLES")}</div>;
    //   },
    // },
    // // {
    // //   title: t("TYPE"),
    // //   dataIndex: "typeName",
    // //   key: "typeName",
    // // },
    // {
    //   title: t("CREATE_AT"),
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    // },
    // {
    //   title: t("PRODUCT_STATUS"),
    //   dataIndex: "statusText",
    //   key: "statusText",
    //   // filters: [
    //   //   { text: t("ON_SALE"), value: ProductStatus.STOCK },
    //   //   { text: t("OFF_SALE"), value: ProductStatus.UNSTOCK },
    //   // ],
    //   onFilter: (value, record) => record.status === value,
    //   render: (val, record) => {
    //     if (record.status === ProductStatus.VERIFYFAIL) {
    //       return (
    //         <Badge dot={true} size="default">
    //           <span
    //             className="cursor-pointer"
    //             // onClick={openNotification.bind(this, record.reason)}
    //           >
    //             {val}
    //           </span>
    //         </Badge>
    //       );
    //     } else {
    //       return val;
    //     }
    //   },
    // },
    // {
    //   title: t("ACTION"),
    //   dataIndex: "action",
    //   key: "action",
    //   // width: 280,
    //   align: "center",
    //   // render: (text, record) => getActionColumn(text, record),
    // },
  ];

  const getActionColumn = (text, record) => {
    let id = record.id || record._id;
    return (
      <div
        style={{
          color: "#3E6FA4",
          fontSize: "14px",
          fontFamily: "Inter",
          cursor: "pointer",
        }}
        onClick={showEditModal.bind(this, record)}
      >
        {t("EDIT")}
      </div>
    );
  };

  const showEditModal = (record) => {
    setCurrentOrderId(record._id);
    setOrderDetailVisible(true);
  };

  const onGoDetail = (orderId) => {
    history.push("/company/order", { orderId });
  };

  const onSelectFilterChange = (list, setFunc, e) => {
    let temp = deepClone(list);
    temp.map((mapItem) => {
      if (mapItem.value == e) {
        mapItem.selected = true;
      } else {
        mapItem.selected = false;
      }
    });
    setFunc(temp);
  };

  return (
    <div
      className="common-page"
      style={{
        paddingTop: "25px",
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* <BreadcrumbNormal title={t("SHOP_ORDERS")} parent={t("MY_SHOP")} /> */}

      <div className="company-orders-supplier-container common-management ">
        <div className="filter-panel">
          <div className="filter-item">
            <div className="label">{t("ORDER_DATE")}</div>
            <Select
              // value={classifications.length > 0 ? classification : null}
              options={dateFilterList}
              className="filter-select"
              onChange={onSelectFilterChange.bind(
                this,
                dateFilterList,
                setDateFilterList
              )}
            />
          </div>
          <div className="filter-item">
            <div className="label">{t("CLIENT")}</div>
            <Select
              // value={classifications.length > 0 ? classification : null}
              options={clientFilterList}
              className="filter-select"
              onChange={onSelectFilterChange.bind(
                this,
                clientFilterList,
                setClientFilterList
              )}
            />
          </div>
          <div className="filter-item">
            <div className="label">{t("SHIPPING_ADDRESS")}</div>
            <Select
              // value={classifications.length > 0 ? classification : null}
              options={shippingLocationFilterList}
              className="filter-select"
              onChange={onSelectFilterChange.bind(
                this,
                shippingLocationFilterList,
                setShippingLocationFilterList
              )}
            />
          </div>
          <div className="filter-item">
            <div className="label">{t("ORDER_STATUS")}</div>
            <Select
              // value={classifications.length > 0 ? classification : null}
              options={orderStatusFilterList}
              className="filter-select"
              onChange={onSelectFilterChange.bind(
                this,
                orderStatusFilterList,
                setOrderStatusFilterList
              )}
            />
          </div>
          <div className="filter-item">
            <div className="label">{t("PAYMENT_STATUS")}</div>
            <Select
              // value={classifications.length > 0 ? classification : null}
              options={paymentStatusFilterList}
              className="filter-select"
              onChange={onSelectFilterChange.bind(
                this,
                paymentStatusFilterList,
                setPaymentStatusFilterList
              )}
            />
          </div>
          <div className="filter-item">
            <div className="label">{t("SHIPPING_DESTINATION")}</div>
            <Select
              // value={classifications.length > 0 ? classification : null}
              options={shippingDestinationFiterList}
              className="filter-select"
              onChange={onSelectFilterChange.bind(
                this,
                shippingDestinationFiterList,
                setShippingDestionationFilterList
              )}
            />
          </div>
        </div>
        <div className="btn-search">{t("SEARCH")}</div>
        {/* <div className="search-panel">
          <div className="search-row">
            <div className="search-item">
              <div className="label">{t("ORDER_DATE")}</div>
            </div>
          </div>
        </div> */}
        <Table
          dataSource={orderList}
          columns={columns}
          className="orders-supplier-table"
          loading={loading}
          pagination={false}
        />
        {/* <div className="orders-title common-management-title">
          {t("SHOP_ORDERS")}
        </div>
        <div className="orders-banner common-management-panel">
          <div className="orders-banner-item">
            <div className="item-title">{t("THIS_MONTH_ORDER")}</div>
            <div className="item-number">{monthOrderList.length}</div>
          </div>
          <div className="orders-banner-item">
            <div className="item-title">{t("TOTAL_NUMBER_OF_ORDER")}</div>
            <div className="item-number">{orderList.length}</div>
          </div>
          <div className="orders-banner-item">
            <div className="item-title">{t("ALREADY_PAYED_ORDER")}</div>
            <div className="item-number">{paiedOrderList.length}</div>
          </div>
          <div className="orders-banner-item">
            <div className="item-title">{t("ALREADY_COMPLETED_ORDER")}</div>
            <div className="item-number">{completeOrderList.length}</div>
          </div>
        </div> */}
        {/* <Tabs
          activeKey={tabIndex}
          className="orders-tabs"
          onChange={(key) => setTabIndex(key)}
          moreIcon={<img src={moreIcon} />}
        >
          <div className="orders-tabs-filter-panel">
            <span className="label">{t("VIEW_MY_ORDERS_IN")}</span>
            <Dropdown overlay={menu_filter_time()} trigger={["click"]}>
              <div className="time-filter-panel">
                {filterByMonthContent}
                <img src={downArrowIcon} style={{ marginLeft: "11px" }} />
              </div>
            </Dropdown>
          </div>
          <TabPane tab={t("ALL_ORDER")} key="1">
            <ListBasic
              data={orderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("ORDER_WAITING_PAYMENT")} key="2">
            <ListBasic
              data={waitingPayOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("WAITING_TO_DELIVER")} key="3">
            <ListBasic
              data={unDeliveredOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("DELIVERY_SEND")} key="4">
            <ListBasic
              data={unReceivedOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("WAITING_RECEIVED")} key="5">
            <ListBasic
              data={deliveredOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("RECEIVED")} key="6">
            <ListBasic
              data={receivedOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("ORDER_CANCEL")} key="7">
            <ListBasic
              data={cancelOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
          <TabPane tab={t("ORDER_COMPLETE")} key="8">
            <ListBasic
              data={completeOrderList}
              renderItem={onRenderOrderItem.bind(this)}
              loading={loading}
            />
          </TabPane>
        </Tabs> */}
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: aliPayForm }}></div> */}
      <Modal
        destroyOnClose={true}
        title={null}
        visible={orderDetailVisible}
        closable={false}
        footer={null}
        className="select-friend-modal"
        onCancel={() => setOrderDetailVisible(false)}
      >
        <OrderInfoEdit orderId={currentOrderId}></OrderInfoEdit>
      </Modal>
    </div>
  );
}
