import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useUserId } from "../../../contexts/UserIdProvider";
import "./style/savedList.less";
import {
  browseRecordList,
  browseRecordRemove,
} from "../../../api/browseRecord";
import { useLocation } from "react-router-dom";
import { dateFormat } from "../../../utils/date";
import ListBasic from "../../common/list/ListBasic";
import { feedFavoriteList } from "../../../api/feed";
import FeedListRow from "../FeedListRow";

export default function SavedList() {
  const { t } = useTranslation();
  const { user } = useUserId();
  const { userId } = user;
  const { state } = useLocation();
  const [busType, setBusType] = useState();
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 20;

  useEffect(() => {
    if (state) {
      setBusType(state.type);
    } else {
      loadData();
    }
  }, [state]);

  useEffect(() => {
    if (busType) {
      loadData();
    }
  }, [busType]);

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    // { title: "BusId", dataIndex: "busId", key: "busId" },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (val, item) => {
        return (
          <span key={item._id}>{dateFormat(val, "yyyy-MM-dd HH:mm:ss")}</span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "y",
      render: (item) => {
        return (
          <Button
            key={item._id}
            type="link"
            onClick={onDelete.bind(this, item._id)}
          >
            {t("DELETE")}
          </Button>
        );
      },
    },
  ];

  const loadData = async () => {
    let pms = {
      coId: user.userInfo.companyId,
      page: pageIndex,
      per_page: pageSize,
    };
    let list = await feedFavoriteList(pms);
    console.log("loadData:", list);
    if (list && list.length > 0) {
      // setData(list);
      let temp=[];
      list.map((mapItem)=>{
        if(mapItem&&mapItem.feedInfos&&mapItem.feedInfos.length>0){
          delete mapItem.feedInfos[0].sourceFeedId;
          temp.push(mapItem.feedInfos[0]);
        }
      });
      setData(temp);
    }
  };

  const onDelete = async (id) => {
    let pms = { id };
    await browseRecordRemove(pms);
    await loadData();
  };

  return (
    <div className="browse-record-panel">
      <div className="browse-record-title">{t("SAVED")}</div>
      <div
        className="common-management-panel"
        style={{ overflowY: "auto", height: window.innerHeight - 110 + "px" }}
      >
        {/* <Table columns={columns} dataSource={data} pagination={false} /> */}
        <ListBasic
          data={data}
          renderItem={(item) => (
            <div className="feed-list-item-container">
              <FeedListRow
                data={item}
                // onRefresh={pageRefresh.bind(this)}
                // onRemoveFeedItem={onRemoveFeedItem.bind(this)}
              />
            </div>
          )}
        ></ListBasic>
      </div>
    </div>
  );
}
